import React, { useMemo, useState } from 'react';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';
import VerticalBarChart from '@shared/components/CustomRecharts/charts/VerticalBarChart';

import {
    Container,
    CustomLegend,
    Item,
    ItemContainer,
    ItemLabel,
} from './styles';

const NPSResponsibleData: React.FC = () => {

    const [donutChartData] = useState<any[]>([
        { name: '42', percent: 42, color: '#FF3D71', label: 'Detratoras (6 a 0)' },
        { name: '88', percent: 88, color: '#FFAA00', label: 'Neutras (8 a 7)' },
        { name: '753', percent: 753, color: '#00D68F', label: 'Promotoras (10 a 9)' },
    ]);

    const [barChartData] = useState([
        { name: 'Muito Satisfeito', percent: 80 },
        { name: 'Satisfeito', percent: 10 },
        { name: 'Pouco Satisfeito', percent: 3 },
        { name: 'Insatisfeito', percent: 2 }
    ]);

    const [barsChartConfig] = useState([
        { dataKey: 'percent', color: '#42AAFF' }
    ]);

    const [defaultProps] = useState({
        data: barChartData,
        barsConfig: barsChartConfig,
        height: 70,
        margin: { top: 40, right: 50, left: 40, bottom: 40 },
        showYAxis: true
    });

    const getCustomLegendData = useMemo(() => {
        const total = donutChartData
            .map((d) => d.percent)
            .reduce((ac, value) => ac + value, 0);

        return [
            {
                name: total,
                percent: total,
                color: '#C5CEE0',
                labelColor: '#222B45',
                label: 'Total de Respostas'
            },
            ...donutChartData
        ];

    }, [donutChartData]);

    return (
        <Container>
            <DonutChart
                data={donutChartData}
                contentCenter={<p>NPS<br />0,48</p>}
            />

            <CustomLegend>
                {getCustomLegendData.map((item, i) =>
                    <ItemContainer key={i}>
                        <ItemLabel>{item.label}</ItemLabel>
                        <Item
                            color={item.color}
                            labelColor={item?.labelColor}
                        >
                            {item.name}
                        </Item>
                    </ItemContainer>
                )}
            </CustomLegend>

            <AccordionInfoItem title='Adequado ás suas Necessidades'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Conteúdo das Aulas'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Atuação do Professor'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Infraestrutura do Ambiente Físico'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

        </Container>
    );
}

export default NPSResponsibleData;