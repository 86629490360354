import React, { useCallback, useEffect, useState } from "react"
import Icon from 'react-eva-icons';
import { ButtonFooterContainer } from "../styles";
import { Button, H1, H3, H6, P, Pill, Spinner, useToast } from "react-alicerce-components"
import { Content, CurrentBlockContainer, ValidateLearningTargerFormWarningContainer } from "./styles";
import { MultiStepComponentProps } from "../../../../shared/components/MultiStep";
import { SkillContainer } from "../../components/SkillContainer";
import { RadioOption } from "../../../../shared/components/RadioOption";
import { AccordionSkillsContent } from "../../../launchers/components/AccordionSkills/AccordionSkillsContent";
import { AccordionSkillsHeader } from "../../../launchers/components/AccordionSkills/AccordionSkillsHeader";
import { ISkillInfo, IStudentWithGrades } from "../../../launchers/interfaces/IStudentsWithGrades";
import CustomAccordion from "../../components/CustomAccordion";
import CheckboxCustom from "../../../../shared/components/CheckboxCustom";
import { ModalWindowsChildrenProps } from "../../../../shared/components/Modalv1";
import { useIsMounted } from "../../../../shared/hooks/useIsMounted";
import useAuth from "../../../../shared/store/auth/hook";
import ListSkillsFromValidationProcessService, { IListSkillsFromValidationProcess } from "../../services/ListSkillsFromValidationProcessService";
import { ISkill } from "../../../../shared/interfaces/ISkill";

type SkillFragmentForm = {
    skill_ids: number[]
}

type ValidateLearningTargetFormSelectSkillFragment = {
    skills?: ISkillInfo[]
    studentData: IStudentWithGrades
    finalData?: any
} & MultiStepComponentProps & ModalWindowsChildrenProps

export const ValidateLearningTargetFormSelectSkillFragment = (props: ValidateLearningTargetFormSelectSkillFragment) => {
    const { onBack, finalData, studentData, handleSubmit, setValueCallback } = props
    const [res, setRes] = useState<IListSkillsFromValidationProcess>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const newMetaBlockName = finalData?.new_meta_block?.name
    const newMetaBlockColor = finalData?.new_meta_block?.color
    const currentMetaBlockName = studentData.gradeInfo?.meta_block?.name
    const currentMetaBlockId = Number(studentData.gradeInfo?.meta_block?.id)
    const newMetaBlockId = Number(finalData?.new_meta_block?.id)
    const currentMetaBlockFound = studentData.gradeInfo?.blocks?.find((item) => (Number(item.id) === Number(currentMetaBlockId!)))
    const currentMetaBlockSkillsFiltered = currentMetaBlockFound?.skills.filter(skill => Number(skill.id) !== -1)
    const isSameBlockSelected = newMetaBlockId === currentMetaBlockId
    const isMounted = useIsMounted();
    const { signedUser } = useAuth();
    const { addToast } = useToast();

    const listBlockSkills = useCallback(async () => {
        setIsLoading(true)
        const res = await new ListSkillsFromValidationProcessService(signedUser?.token).execute({ source_block_id: currentMetaBlockId, destination_block_id: newMetaBlockId });
        setIsLoading(false)
        if (!res) {
            addToast({
                status: 'danger',
                title: 'Competências',
                description: 'Error ao listar competências.',
            });
            return
        }
        if (!isMounted()) return;
        setRes(res);
    }, [addToast, currentMetaBlockId, isMounted, newMetaBlockId, signedUser]);

    const resultado = res?.source?.skills?.map(skill => {
        const correspondente = currentMetaBlockSkillsFiltered?.find(item => item.id === skill.id);
        if (correspondente) {
            return { ...correspondente, ...skill };
        }
        return skill;
    });

    useEffect(() => {
        listBlockSkills();
    }, [listBlockSkills]);

    const [skillFragmentForm, setSkillFragmentForm] = useState<SkillFragmentForm>(finalData || {})

    const getStatusColor = (status: string) => {
        const statusMap = {
            basic: '#E4E9F2',
            danger: '#FF3D71',
            success: '#00D68F',
            warning: '#FFAA00',
            info: '#0095FF'
        }
        const color = statusMap[status] || '#6749BA'
        return color
    }

    const canGoNext = async () => {

        handleSubmitT()
    }

    const handleSubmitT = () => {
        handleSubmit && handleSubmit()
    }

    const handleChange = useCallback(
        (event) => {
            const checkbox = event.target;
            const id = Number(checkbox.id);
            const checked = checkbox.checked;

            const newCheckedItems = [...(skillFragmentForm?.skill_ids || [])];

            if (checked) {
                newCheckedItems.push(id);
            } else {
                newCheckedItems.splice(newCheckedItems.indexOf(id), 1);
            }

            setSkillFragmentForm(({ skill_ids: newCheckedItems }));
            setValueCallback && setValueCallback(({ skill_ids: newCheckedItems }))
        },
        [setValueCallback, skillFragmentForm]
    );

    return (
        <ValidateLearningTargerFormWarningContainer>
            <div style={{ marginTop: '.8rem', marginBottom: '.8rem' }}>
                <div className='icon-container'>
                    <Icon name="alert-circle-outline" fill="#F2C94C" />
                </div>
                <H1 className='title'>Competências Já Dominadas!</H1>
                <Content>
                    <CustomAccordion>
                        <CustomAccordion.AccordionHeader>
                            <AccordionSkillsHeader currentMetaBlockName={currentMetaBlockName} />
                        </CustomAccordion.AccordionHeader>
                        <CustomAccordion.AccordionContent>
                            {resultado && <AccordionSkillsContent
                                data={resultado!}
                                render={(item: ISkill) => <Pill
                                    className='skill-pill radio-skill'
                                    backgroundColor={isSameBlockSelected ? getStatusColor(item?.status) : getStatusColor(item?.status === 'success' ? 'success' : 'info')}
                                    borderColor={getStatusColor(item?.status)}
                                    key={`${item?.id}`}>
                                    {item?.name}
                                </Pill>} />}

                        </CustomAccordion.AccordionContent>
                    </CustomAccordion>
                    <P className='description'>Com base na sua avaliação, qual o bloco que o estudante se encontra no momento?</P>

                    <SkillContainer>
                        <CurrentBlockContainer>
                            <H3 className="current-block">Competências do Bloco de Trabalho Escolhido</H3>
                            <RadioOption
                                name={"subtitle"}
                                hideRadio
                                radioWidth="fit-content"
                                optionColor={newMetaBlockColor || '#B85EC4'}
                                round
                                id={'subtitle'}
                            >
                                <H6 className="current-block">{newMetaBlockName}</H6>
                            </RadioOption>
                        </CurrentBlockContainer>
                    </SkillContainer>
                    {isLoading && <Spinner />}
                    {res?.destination?.skills?.map((skill) =>
                        <div key={skill.id}>
                            <CheckboxCustom id={skill.id} label={`${skill?.name}`} handleOnChange={handleChange}>
                                {skill.is_essential && <>
                                    <Icon name='star' fill='#FAC50D' />
                                    Essencial
                                </>}
                            </CheckboxCustom>
                            <P className="skill-description">{skill.descriptions}</P>
                        </div>
                    )}
                </Content>
            </div>

            <ButtonFooterContainer>
                <Button
                    shaded
                    transform="none"
                    style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
                    onClick={() => onBack?.()}
                >
                    Voltar
                </Button>

                <Button
                    shaded
                    transform="none"
                    style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
                    onClick={canGoNext}
                >
                    Continuar
                </Button>
            </ButtonFooterContainer>
        </ValidateLearningTargerFormWarningContainer>
    );
}