import ITag from '@modules/tags/interfaces/ITag';
import { ISelectOption } from 'react-alicerce-components';

export type IGenericSelectOption<T = any> = ISelectOption & {
  value: T;
};

export const defaultActivityTheme = (type?: ITag, types?: ITag[]): IGenericSelectOption<ITag>[] => {
  if (!types) return [];

  const formatedTypes = types.map((t) => ({
    value: t,
    name: t.name,
    selected: Number(t.id) === Number(type?.id),
  })) as IGenericSelectOption<ITag>[]

  formatedTypes.push({ name: 'Nenhum', value: undefined as any, selected: false });
  
  return formatedTypes;
};
