import * as Yup from 'yup';

export const workedHourSchema = Yup.object().shape({
  date_minutes: Yup.array()
    .of(
      Yup.object({
        date: Yup.string().required({ name: 'date', message: 'O campo é obrigatório' }),
        total_minutes: Yup.number()
          .min(1, { name: 'total_hours', message: 'Valor inválido' })
          .required({ name: 'total_hours', message: 'O campo é obrigatório' }),
      })
    )
    .required({ name: 'date_hour', message: 'É necessário colocar pelo menos uma data e hora' }),
  issued_by: Yup.number().required({ name: 'issued_by', message: 'O campo é obrigatório' }),
  group_id: Yup.number().required({ name: 'group', message: 'O campo é obrigatório' }),
  category_tag_id: Yup.number().required({ name: 'category_tag_id', message: 'O campo é obrigatório' }),
  modality_tag_id: Yup.number().optional(),
  project_tag_id: Yup.number().optional(),
  description: Yup.string().optional(),
});
