import IRoute from "@shared/interfaces/IRoute";

import ActivitiesList from "@modules/activities-portfolio/views/ActivitiesPortfolioList";
import ActivitiesPortfolioCreate from "@modules/activities-portfolio/views/ActivitiesPortfolioCreate";
import ActivitiesPortfolioEdit from "@modules/activities-portfolio/views/ActivitiesPortfolioEdit";
import ActivitiesPortfolioClassesSelection from "@modules/activities-portfolio/views/ActivitiesPortfolioClassesSelection";
import ActivitiesPortfolioInfo from "@modules/activities-portfolio/views/ActivitiesPortfolioInfo";

import { ActivitiesPortfolioProvider } from "@modules/activities-portfolio/context/ActivitiesPortfolioContext";
import { ActivitiesPortfolioStudentsRelation } from "@modules/activities-portfolio/views/ActivitiesPortfolioStudentsRelation";
import { activityPortfolioKeys } from "@modules/activities-portfolio/keys";
import { classesKeys } from "@modules/classes/keys";
import { studentsKeys } from "@modules/students/keys";

import { combineContext } from "@shared/utils/combineContext";
import {IModuleRoutes} from "@shared/interfaces/IModuleRoutes";

const routes: IRoute[] = [
    {
        path: "/portfolio-atividades/contexto/turmas",
        key: "ACTIVITIES_PORTFOLIO_CONTEXT_SELECTION_CLASSES_PATH",
        exact: true,
        component: ActivitiesPortfolioClassesSelection,
        permissions: [
            classesKeys.CLASSES_READ,
        ]
    },
    {
        path: "/portfolio-atividades/turma/:classId",
        key: "ACTIVITIES_PORTFOLIO_PATH",
        exact: true,
        component: ActivitiesList,
        permissions: [
            activityPortfolioKeys.ACTIVITIES_PORTFOLIO_READ
        ]
    },
    {
        path: "/portfolio-atividades/turma/:classId/criar",
        key: "ACTIVITIES_PORTFOLIO_CREATE_PATH",
        exact: true,
        component: ActivitiesPortfolioCreate,
        permissions: [
            activityPortfolioKeys.ACTIVITIES_PORTFOLIO_CREATE
        ]
    },
    {
        path: "/portfolio-atividades/:portfolioActivityId/turma/:classId/informacoes",
        key: "ACTIVITIES_PORTFOLIO_INFO_PATH",
        exact: true,
        component: ActivitiesPortfolioInfo,
        permissions: [
            activityPortfolioKeys.ACTIVITIES_PORTFOLIO_READ
        ]
    },
    {
        path: "/portfolio-atividades/:portfolioActivityId/turma/:classId/editar",
        key: "ACTIVITIES_PORTFOLIO_EDIT_PATH",
        exact: true,
        component: ActivitiesPortfolioEdit,
        permissions: [
            activityPortfolioKeys.ACTIVITIES_PORTFOLIO_UPDATE,
        ]
    },
    {
        path: '/portfolio-atividades/turma/:classId/selecionar/alunos',
        key: 'ACTIVITIES_PORTFOLIO_STUDENTS_RElATION_PATH',
        exact: true,
        component: ActivitiesPortfolioStudentsRelation,
        permissions: [
            activityPortfolioKeys.ACTIVITIES_PORTFOLIO_UPDATE,
            studentsKeys.STUDENTS_READ
        ]
    },
];

const providers = [
    ActivitiesPortfolioProvider
];

export default {
    key: 'ACTIVITIES_PORTFOLIO_MODULE',
    providers: combineContext(...providers),
    routes,
} as IModuleRoutes;