import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .main-navbar {
    height: 130px;
    align-items: center;
    z-index: 0;
  }

  [role='tablist-container'] {
    background: linear-gradient(135deg, #ffa33a 0%, #6e3fa5 100%);
  }

  [role='tabpanel'] {
    height: calc(100vh - 130px);
    border-radius: 0;
  }
`;
