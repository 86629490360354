import { ValidateLearningTargerFormWarningContainer } from "@modules/learning-targets/forms/ValidateLearningTargerForm/styles";
import { ButtonFooterContainer } from "@modules/learning-targets/forms/styles";
import { Button, Grid, H1 } from "react-alicerce-components";
import Icon from 'react-eva-icons';
import React from 'react';

export type ClassStepModalProps = {
  title: string;
  children: JSX.Element;
  description?: JSX.Element;
  flag? : JSX.Element;
  onCloseModal?: () => void;
  onConfirm: () => void;
  saveButtonText: string;
}

export const ClassStepModal = ({ title, children, onCloseModal, onConfirm, description, saveButtonText, flag }: ClassStepModalProps) => {
  return (
    <ValidateLearningTargerFormWarningContainer>
      <Grid mt4>
        <div className="icon-container">
          <Icon name="alert-circle-outline" fill="#F2C94C" />
        </div>
        <H1 className="title">{title}</H1>
        {description}
        {flag}
      </Grid>
      {children}
      <ButtonFooterContainer>
        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
          onClick={() => onCloseModal?.()}
        >
          Cancelar
        </Button>

        <Button shaded transform="none" style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }} onClick={onConfirm}>
          {saveButtonText}
        </Button>
      </ButtonFooterContainer>
    </ValidateLearningTargerFormWarningContainer>
  );
};
