import React from 'react';

import Layout from '@modules/groups/components/Layout';
import ButtonGradient from '@shared/components/ButtonGradient';

const GroupReport: React.FC = () => {
  return (
    <Layout openTab={4}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1rem' }}>
        <ButtonGradient disabled type={4}>Dash de Frequência</ButtonGradient>
        <ButtonGradient disabled type={3}>Dash de Aprendizagem</ButtonGradient>
        {/* <ButtonGradient type={12}>Dash de Indicadores</ButtonGradient> */}
      </div>
    </Layout>
  );
};

export default GroupReport;
