import React, { Fragment, useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router';

import Icon from 'react-eva-icons';
import { To } from 'react-router-dom';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import Navbar from '@shared/components/Headers/Navbar';
import { IMenuKebab, MenuKebab } from '../../MenuKebab';

import { Container } from './styles';

export interface IHeaderDirectoryProps {
  title: string;
  pathToReturn?: string | number;
  pathToReturnOptions?: { state: Object };
  status?: ElementStatus;
  statusNumber?: number;
  hideGoBack?: boolean;
  backgroundHeader?: string;
  menuKebab?: IMenuKebab;
  onClickReturn?: () => void;
}

const HeaderDirectory: React.FC<IHeaderDirectoryProps> = ({
  title,
  pathToReturn,
  statusNumber = 400,
  status = 'primary',
  backgroundHeader,
  pathToReturnOptions,
  menuKebab,
  hideGoBack,
  onClickReturn,
}) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    if (onClickReturn) return onClickReturn();
    pathToReturn ? navigate(pathToReturn as To, pathToReturnOptions) : navigate(-1);
  }, [navigate, onClickReturn, pathToReturn, pathToReturnOptions]);

  const GoBack = useCallback(() => {
    if (hideGoBack) return <Fragment />;
    return (
      <div onClick={handleOnClick}>
        <Icon name="arrow-ios-back" />
      </div>
    );
  }, [handleOnClick, hideGoBack]);

  const Right = useMemo(() => {
    return <MenuKebab {...menuKebab} />;
  }, [menuKebab]);

  return (
    <Container
      className="header-navigation-container"
      statusNumber={statusNumber}
      status={status}
      backgroundHeader={backgroundHeader}
    >
      <Navbar title={title} left={<GoBack />} right={Right} />
    </Container>
  );
};

export default HeaderDirectory;
