import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-add-hours {
    > div {
      width: 125px;

      input {
        width: 65px;
        text-align: center;
      }
    }
  }  
  
  .container-date-hours{
    display: flex;
    gap: 5%;
  }

  .date-container{
    width: 250px;
  }

  input {
    width: 65px;
    text-align: center;
  }

  .container-InputPill {
    margin-bottom: 2rem;
  }

  .parent-render-container {
    padding-top: unset;
    margin-bottom: 2rem;
  }

  .input-container {
    margin-top: unset;
    margin-bottom: 2rem;
  }

  .button-gradient-outline {
    span {
      color: #ffffff;
    }
  }
`;
