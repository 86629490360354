import styled, { css } from "styled-components";

type RadioWidth = string | 'auto' | 'fit-content' | 'max-content' | 'min-content'
export type RadioOptionProps = {
    optionColor?: string
    radioWidth?: RadioWidth
}
const dotStepModifiers = {
    radioWidth: (radioWidth: RadioWidth) => css`
        width: ${radioWidth || 'auto'};
    `,
}
export const Container = styled.div<RadioOptionProps>`
${({ optionColor, radioWidth }) => css`
    background-color:${optionColor ? 'transparent' : '#EDF1F7'};
    border-radius: 16px;
    color: #2E3A59;
    border-radius: 16px;
    border: ${optionColor ? '1px' : '0px'} solid ${optionColor ? optionColor : 'transparent'};
    padding-right: 0.4rem;

    input + div {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .5rem;
        background-color: #E6EAF0;
    }

    input:checked + div {
        background-color: ${optionColor ? optionColor : '#6749BA'};;
        border: 6px solid #E6EAF0;
        outline: 2px solid ${optionColor ? optionColor : '#6749BA'};
    }
    ${!!radioWidth && dotStepModifiers.radioWidth(radioWidth)};
`}`;
