import { styled } from 'react-alicerce-components';

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  background: #e9d7f9;
  z-index: 10;
  left: 0;
  right: 0;
  height: 50vh;
  transition: all 0.2s;
  bottom: ${({ isOpen }) => (isOpen ? '0' : 'calc(-50vh + 36px)')};
  padding: 0 22px 11px 22px;
  border-radius: 25px 25px 0px 0px;
  overflow: auto;

  .activities-tray-message {
    margin: 3rem 0 1rem 0;
    text-align: center;
    font-size: 14px;
  }

  &:before {
    content: 'Bandeja de Atividades';
    position: fixed;
    left: 0;
    right: 0;
    background-color: #b85ec4;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 25px 25px 0px 0px;
    color: #fff;
    font-weight: 800;
    font-size: 15px;
    z-index: 4;
  }
`;

export const ContainerActivityCardInTray = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  > div:first-child {
    flex: 1;

    .custom-card {
      > div:first-child {
        margin: 0 0 1rem 0;
      }
    }
  }

  .remove-card {
    cursor: pointer;
    width: 30px;
    aspect-ratio: 1;
    background: red;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
