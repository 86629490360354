import React, { Fragment } from 'react';
import { CSSProperties } from 'styled-components';

import { InputErrorMessageContainer } from './styles';

interface IInputErrorMessage {
  error?: string;
  customStyles?: CSSProperties;
}

const InputErrorMessage: React.FC<IInputErrorMessage> = ({ error, customStyles }) => {
  if (!error) return <Fragment />;
  return (
    <InputErrorMessageContainer style={customStyles} className="input-error-message-container">
      {error}
    </InputErrorMessageContainer>
  );
};

export default InputErrorMessage;
