import React from 'react';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import { useLocation } from 'react-router-dom';
import { ILocationState } from '@shared/interfaces/ILocationState';

import { Container } from './styles';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const { state } = useLocation() as { state: ILocationState };

  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Lista de Tarefas"
        pathToReturn={`/turma/${state.id}/agenda`}
        backgroundHeader="linear-gradient(105.34deg, #6E3FA5 1.9%, #542E91 98.48%);"
        menuKebab={{
          tooltip: { view: 'TasksPendingList' },
          menuBackHome: true,
        }}
      />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Pendentes" onClick={() => navigate(`/tarefas/pendentes`, { state })} />
          <Tab title="Atrasadas" onClick={() => navigate(`/tarefas/atrasadas`, { state })} />
          <Tab title="Feitas" onClick={() => navigate(`/tarefas/feitas`, { state })} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default Layout;
