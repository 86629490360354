import React, { useMemo } from 'react';
import { Container, ContainerAttendanceStatus } from './styles';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';

interface ITodayClassCardProps {
  todayClass: IDailyClass;
  todayClassesSelected?: IDailyClass;
  canSelect?: boolean;
  showAttendanceStatus?: boolean;
  handleOnClick?: (tdClass: any) => Promise<void>;
  disabled?: boolean;
}

export const TodayClassCard: React.FC<ITodayClassCardProps> = ({ todayClass, todayClassesSelected, canSelect = false, handleOnClick, disabled, showAttendanceStatus }) => {
  const isSelected = useMemo(() => {
    return todayClassesSelected?.id === todayClass.id && canSelect ? true : false;
  }, [canSelect, todayClass.id, todayClassesSelected]);

  return (
    <Container
      className="daily-class-information"
      onClick={handleOnClick && !disabled ? () => handleOnClick(todayClass) : undefined}
      selected={isSelected}
      disabled={disabled}
    >
      <div className="text">Horário da Aula</div>

      <div className="daily-class-time">
        <span>{todayClass.start_time ? todayClass.start_time.slice(0, 5) + 'h' : 'Missingvalue'}</span>
        <span>-</span>
        <span>{todayClass.end_time ? todayClass.end_time.slice(0, 5) + 'h' : 'Missingvalue'}</span>
      </div>

      {showAttendanceStatus && (
        <ContainerAttendanceStatus hasAttendance={todayClass.attendance_given ? todayClass.attendance_given : false}>
          <input type="checkbox" defaultChecked={!todayClass.attendance_given || todayClass.attendance_given} />
          <span className="checkmark"></span>
        </ContainerAttendanceStatus>
      )}
    </Container>
  );
};
