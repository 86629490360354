import React, { useCallback, useMemo, useState } from 'react';
import { Divider, Grid, P, Select, Textarea } from 'react-alicerce-components';

import { IInputErrorsFormat, beforeSubmitForm, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';
import CustomForm from '@shared/components/CustomForm';

import { defaultTeacherRequestDetails, defaultUnenrollmentReasons } from '@modules/classes/utils/validData/validUnenrollmentTeacherReason';
import { ClassStepModal } from '@modules/classes/components/ClassStepModal';

import { UnenrollmentOrDisconnectStepProps } from './UnenrollmentOrDisconnectStep';
import Icon from 'react-eva-icons';
import { DisconnectSchema, DisconnectUnenrollmentForm, UnenrollmentSchema } from '@modules/classes/schemas/DisconnectAndUnenrollmentSchema';

export type IUnenrollmentDisconnectTeacherFormStepProps = {
  form?: DisconnectUnenrollmentForm;
  setForm: (value: any) => void
} & UnenrollmentOrDisconnectStepProps;

export const UnenrollmentDisconnectTeacherFormStep = ({ onNext, onCloseModal, selectedRadio, handleSubmit, form, setForm }: IUnenrollmentDisconnectTeacherFormStepProps) => {
  const [errors, setErrors] = useState<IInputErrorsFormat[]>();

  const handleSelectChange = useCallback(
    (option, selectName) => {
      if (selectName === 'reason') {
        setForm((oldState: any) => {
          if (oldState?.deatil) delete oldState['detail']
          return { ...oldState, [selectName]: option.value };
        });
      }
      setForm((oldState: any) => {
        return { ...oldState, [selectName]: option.value };
      });
    },
    [setForm]
  );

  const dynamicLabel = useMemo(() => {
    return selectedRadio === 'unenrollment' ? 'do Desenturmamento' : 'da Desistência';
  }, [selectedRadio]);

  const unenrollmentReasonDetails = defaultTeacherRequestDetails(form?.reason, form?.detail);

  const validateForm = async () => {
    if (form?.reason !== 'teacher_request' && form?.reason !== 'alicerce_request') {
      return await beforeSubmitForm(form, UnenrollmentSchema)
    }
    return await beforeSubmitForm(form, DisconnectSchema)
  };

  const canSubmit = async () => {
    let formErrors = await validateForm();
    setErrors(formErrors);
    if (formErrors.length !== 0) return;
    handleSubmit && handleSubmit();
  };

  return (
    <ClassStepModal
    title={`Deseja ${selectedRadio === 'unenrollment' ? 'Desenturmar' : 'Desligar'} Líder?`}
    onCloseModal={onCloseModal}
    onConfirm={canSubmit}
    saveButtonText='Confirmar'
    flag={
        <Grid textCenter>
          <Divider width="100%" />
            <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
              <Icon name="alert-triangle" fill="#F2C94C" />
              <P fontSize={'12px !important'} fontWeight={'700'}>ATENÇÃO</P>
            </div>
            <P fontSize={'12px !important'} className="description">Essa ação só terá efeito quando você clicar em <b>Salvar</b> na edição da Turma.</P>
          <Divider width="100%" />
        </Grid>
      }
      description={
        <Grid mt4>
          {selectedRadio === 'unenrollment' ? (
            <P fontSize={'12px !important'} className="description">
              Ao prosseguir você desligará o <b>líder apenas desta turma</b>.
            </P>
          ) : (
            <P fontSize={'12px !important'} className="description">
              Este líder faz parte de outras <b>XX turmas</b>. Ao prosseguir você o desligará de todas estas
              turmas.
            </P>
          )}
          <P fontSize={'12px !important'} className="description">Antes você obrigatoriamente precisa justificar esta ação:</P>
        </Grid>
      }
    >
      <CustomForm>
        <Select
          name="reason"
          label={`Motivo ${dynamicLabel}`}
          iconName="arrow-down"
          placeholder="Selecione Motivo"
          fullWidth={true}
          handleSelectChange={(option) => handleSelectChange(option, 'reason')}
          options={defaultUnenrollmentReasons(form?.reason)}
          required
          error={errorMessageBuilder('reason', errors)}
        />

        {(form?.reason === 'teacher_request' || form?.reason === 'alicerce_request') && (
          <Select
            key={form.detail}
            name="detail"
            label={`Detalhe ${dynamicLabel}`}
            iconName="arrow-down"
            placeholder="Selecione Detalhe"
            fullWidth={true}
            handleSelectChange={(option) => handleSelectChange(option, 'detail')}
            options={unenrollmentReasonDetails}
            required
            error={errorMessageBuilder('detail', errors)}
          />
        )}

        <Textarea
          name="observation"
          label={`Observações ${dynamicLabel}`}
          placeholder="Adicione Observações"
          value={form?.observation || ''}
          onChange={(e) => {
            setForm((oldState: any) => {
              return { ...oldState, observation: e.currentTarget.value };
            });
          }}
          rows={4}
          error={errorMessageBuilder('observation', errors)}
        />
      </CustomForm>
    </ClassStepModal>
  );
};
