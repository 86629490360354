import { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import ITag from '@modules/tags/interfaces/ITag';

class CreateTagService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(tag: ITag): Promise<{ success: boolean; errMessage: string | null }> {
    const { parent, ...body } = tag;
    try {
      await this.api.post('/tag', body);
      return { success: true, errMessage: null };
    } catch (error: any) {
      const { message } = error.response.data;
      return { success: false, errMessage: message };
    }
  }
}

export default CreateTagService;
