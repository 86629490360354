import React, { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from 'react-alicerce-components';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import WrapperContent from '@modules/schedules/components/WrapperContent';
import Schedule from '@modules/schedules/components/Schedule';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container, ContainerScheduleButton } from './styles';
import { useSchedules } from '../../context/SchedulesContext';

const ScheduleEvents: React.FC = () => {
  const navigate = useNavigate();
  const { state, scheduleCurrent, clearScheduleEventCurrent, clearScheduleSprintCurrent, clearScheduleCurrent } = useSchedules();

  useEffect(() => {
    if (!scheduleCurrent?.id) return navigate('/agenda/locais', { state: state });
  }, [navigate, scheduleCurrent, state]);

  const handleGoBackCallback = useCallback(() => {
    clearScheduleCurrent();
    navigate('/agenda/locais', { state: state });
  }, [clearScheduleCurrent, navigate, state]);

  const handleCreateEventButton = useCallback(() => {
    clearScheduleEventCurrent();
    navigate(`/agenda/evento/criar`, { state: state });
  }, [clearScheduleEventCurrent, navigate, state]);

  const handleCreateSprintButton = useCallback(() => {
    clearScheduleSprintCurrent();
    navigate(`/agenda/sprint/criar`, { state: state });
  }, [clearScheduleSprintCurrent, navigate, state]);

  return (
    <Container>
      <HeaderDirectory title="Agendas" onClickReturn={handleGoBackCallback} />
      <DirectoryContainer style={{ margin: '0', padding: '0' }} footer>
        <WrapperContent>
          <div style={{ padding: '1rem' }}>
            <ContainerScheduleButton>
              <Button
                transform="none"
                color="#fff"
                status="basic"
                statusNumber={600}
                shaded
                noBorder
                onClick={() => navigate(`/agenda/editar`, { state: state })}
              >
                Editar Agenda
              </Button>
            </ContainerScheduleButton>

            <Schedule />
          </div>
        </WrapperContent>
        <PageFooter>
          <Button transform="none" color="#fff" status="basic" statusNumber={600} shaded noBorder onClick={handleCreateSprintButton}>
            Criar Sprint
          </Button>
          <Button transform="none" color="#fff" status="basic" statusNumber={600} shaded noBorder onClick={handleCreateEventButton}>
            Criar Evento
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export default ScheduleEvents;
