import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-page-footer button {
    border-color: #85abf8;
    background: #85abf8;
  }

  .box-title-create {
    display: flex;
    gap: 5px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    padding: 15px;
    border-bottom: 2px solid #edf1f7;
  }
`;
