import React, { useState, useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import Icon from 'react-eva-icons';

import layoutConfig from '@config/layout';

import useAuth from '@shared/store/auth/hook';
import AllActions from '@shared/store/allactions';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useHeaderMain } from '@shared/components/Headers/HeaderMain/context/HeaderMainContext';
import { GetVersionService } from '@shared/services/GetVersionService';

import { Container, ContainerSideBar, WrapperSideBar, Group, GroupItems, GroupItem, ToggleButton } from './styles';
import { useToast } from 'react-alicerce-components';
import { appendReadOffToLogout, redirectToAuth } from '@shared/utils/authRedirect';
const { version } = require('../../../../../../package.json');

const SideBar: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isShowSideBar, toggleSideBar } = useHeaderMain();
  const [resources, setResources] = useState<string>('');
  const [backVersion, setBackVersion] = useState<string>('');

  const GetVersion = useCallback(async () => {
    const res = await new GetVersionService(signedUser?.token, newCancelToken()).execute();
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Upload',
        description: 'Erro ao encontrar versão da back-end',
      });
      return;
    }
    setBackVersion(res.data);
  }, [addToast, newCancelToken, signedUser]);

  useEffect(() => {
    if (isShowSideBar) GetVersion();
  }, [isShowSideBar, GetVersion]);

  const handleUserLogout = useCallback(() => {
    dispatch({ type: AllActions.auth.LOGOUT_SIGNED_USER });
    appendReadOffToLogout('remove');
    redirectToAuth();
  }, [dispatch]);

  useEffect(() => setResources(location.pathname), [location.pathname]);

  return (
    <Container className="sidebar-container" isShow={isShowSideBar}>
      <ToggleButton isShow={isShowSideBar} onClick={toggleSideBar}>
        <Icon name="menu" />
      </ToggleButton>
      <ContainerSideBar isShow={isShowSideBar}>
        <WrapperSideBar>
          <div>
            <Group className="group-header-sidebar">
              <div className="close-sidebar" onClick={toggleSideBar}>
                <Icon name="close-outline" fill="#222B45" />
              </div>
            </Group>

            <Group>
              <GroupItems>
                {layoutConfig.menu.map((item) => (
                  <Link to={item.resource} key={item.resource}>
                    <GroupItem active={Boolean(resources?.includes(item.resource))} className="group-item-body">
                      <div style={{ background: item.color }}>
                        <Icon name={item.icon} />
                      </div>
                      <span>{item.title}</span>
                    </GroupItem>
                  </Link>
                ))}
              </GroupItems>
            </Group>
          </div>

          <Group className="group-footer-sidebar">
            <GroupItem onClick={handleUserLogout} className="group-item-footer">
              <div className="button-leave-lms" onClick={handleUserLogout}>
                <Icon name="log-out-outline" />
              </div>
              <span>Sair do LMS</span>
            </GroupItem>
            <span className="project-version-front"> Version: {version}</span>
            <span className="project-version-back"> Back-end: {backVersion}</span>
          </Group>
        </WrapperSideBar>
      </ContainerSideBar>
    </Container>
  );
};

export default SideBar;
