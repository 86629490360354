import { styled } from 'react-alicerce-components';

export const Container = styled.div`
    margin-top: 1.5rem;
    height: 100%;
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    border: 2px dashed #8F9BB3;
    border-radius: 16px;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 32px;
        height: 32px;
      }
    }

    p {
        margin:1.0rem 0 1.0rem 0;
        color: #8F9BB3;
        font-size: 14px;
        font-family: 'Open Sans';
        line-height: 24px;
        font-weight: 700;
    }

    button {
        height: '40px';
        i {
            display: flex;
            align-items: center;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
`;