import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';

export const formatActivityLibrary = (activity: IActivityLibrary) => {
  return {
    title: activity.title,
    description: activity.description,
    duration: activity.duration ? activity.duration.slice(0, 5) : undefined,
    age_groups: activity.age_groups,
    student_amount: activity.student_amount,
    modality: activity.modality,
    cognitive_tasks: activity.cognitive_tasks,
    materials: activity.materials ? activity.materials : undefined,
    preparation: activity.preparation ? activity.preparation : undefined,
    moments: activity.moments,
    links: activity.links ? activity.links : [],
    skill_ids: activity.skills ? activity.skills?.map((sk) => sk.id || '').filter(Boolean) : [],
    tag_ids: activity.tags ? activity.tags?.map((t) => t.id || '').filter(Boolean) : [],
    type_tag_id: activity.type?.id ? activity.type?.id : undefined,
    theme_tag_id: activity.theme ? activity.theme.id : null,
    subtheme_tag_id: activity.subtheme ? activity.subtheme.id : null,
    thematic_tag_id: activity.thematic ? activity.thematic.id : null,
    attachments: activity.attachments ? activity.attachments : [],
    status: activity.status ? activity.status : 'private',
  };
};
