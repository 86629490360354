import React, { useCallback, useMemo } from "react";
import { useGroup } from "@modules/groups/context/GroupContext";
import UserList from "@modules/users/components/UsersList";
import { IUser } from "@modules/users/interfaces/IUser";

const GroupUsersRelationEdit: React.FC = () => {
  const { setGroupCurrent ,groupCurrent } = useGroup();

  const setSelectedItens = useCallback(
    (users: IUser[]) => {
      setGroupCurrent((oldState: any) => {
        return { ...oldState, lms_users: users };
      });
    },
    [setGroupCurrent]
  );

  const selectedItens = useMemo(() => {
    return groupCurrent.lms_users;
  }, [groupCurrent]);

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      pathRedirect={`/grupo/${groupCurrent.id}/editar`}
      pathToReturn={`/grupo/${groupCurrent.id}/editar`}
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelect={"many"}
      handleOnClick={setSelectedItens}
    />
  );
};

export default GroupUsersRelationEdit;
