import React, { useCallback, useState } from 'react';
import { ModalWindowsChildrenProps } from '../../../Modalv1';
import { ButtonFooterContainer, LearningTagertFormContainer } from './styles';
import { Button, H1, P, Textarea } from 'react-alicerce-components';
import * as Yup from 'yup';
import Icon from 'react-eva-icons';
import { IInputErrorsFormat, beforeSubmitForm, errorMessageBuilder } from '../../../../utils/beforeSubmitForm';

interface IJustificationTargetForm extends ModalWindowsChildrenProps {
  handleSave?: (value?: string) => any;
  isPreviousBlock?: boolean
}

type JustificationMetaBlock = {
  justification: string | undefined;
};

export const JustificationTargetForm: React.FC<IJustificationTargetForm> = (props) => {
  const { onCloseModal, handleSave, isPreviousBlock = false } = props;
  const [justificationForm, setJustificationForm] = useState<JustificationMetaBlock>();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>();

  const JustificationTargetSchema = Yup.object().shape({
    justification: Yup.string().required({ name: 'justification', message: 'O campo é obrigatório' }),
  });

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setJustificationForm((oldState: any) => {
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setJustificationForm]
  );

  const canSubmit = async () => {
    let formErrors = await beforeSubmitForm(justificationForm, JustificationTargetSchema);
    setErrors(formErrors);
    if (formErrors.length !== 0) return;
    handleSave && handleSave(justificationForm?.justification);
  };

  const isPreviousTitle = isPreviousBlock ? 'Deseja Confirmar Bloco Anterior ?' : 'Deseja Confirmar a Nova Meta do Estudante?'
  const isPreviousDescription = isPreviousBlock ? 'bloco anterior' : 'bloco superior'

  return (
    <LearningTagertFormContainer>
      <div className="icon-container">
        <Icon name="alert-circle-outline" fill="#F2C94C" />
      </div>
      <H1 className="title">{isPreviousTitle}</H1>
      <P className="description">{`Vimos que escolheu um ${isPreviousDescription}. Poderia compartilhar a motivação para sua escolha?`}</P>
      <Textarea
        name="justification"
        placeholder="Adicione Motivo"
        rows={6}
        onChange={(value) => handleChangeInput(value)}
        useTooltipError={false}
        error={errorMessageBuilder('justification', errors)}
        required
      />
      <ButtonFooterContainer>
        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
          onClick={() => onCloseModal?.()}
        >
          Cancelar
        </Button>

        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
          onClick={canSubmit}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </LearningTagertFormContainer>
  );
};
