import React, { useMemo } from 'react';
import { uuid } from 'uuidv4';

import { IFormatQuestion } from '@modules/applications/utils/formatApplication';

import { Container, Question, Header, Body, Label, Box } from './styles';
import { Pill } from 'react-alicerce-components';
import { answersDataHv } from '@modules/applications/mocks/alternativesHv';
import { normalizeQuestionsIds } from '../../utils/normalizeQuestionsIds';

interface IQuestionResumeProps {
  question: IFormatQuestion;
}

const QuestionResume: React.FC<IQuestionResumeProps> = ({ question }) => {
  const getAnswersData = useMemo(() => {
    if (question.answer_type === "dissertative") {
      return <Box
        key={uuid()}
        dangerouslySetInnerHTML={{ __html: question.response?.text! }}
      />
    }
    if (question?.answers_data) {
      const alternatives = normalizeQuestionsIds(question)
      const normalizedId = Number(question.response?.alternativeId) + 1;
      return alternatives.map((alternative) => (
        <Box
          bgColor={Number(alternative?.value) === 100 ? '#00D68F' : `${alternative?.id}` === `${normalizedId}` ? '#FF4676' : '#6749BA'}
          key={uuid()}
          dangerouslySetInnerHTML={{ __html: alternative?.html! }}
        />
      ));
    } else {
      const alternatives = !Array.isArray(answersDataHv) ? [answersDataHv] : answersDataHv;
      const selectedAlternative = alternatives.find((alternative) => `${alternative.value}` === `${question.response?.alternativeValue}`);
      return <Box bgColor={'#6749BA'} key={uuid()} dangerouslySetInnerHTML={{ __html: selectedAlternative?.html! }} />;
    }
  }, [question]);

  return (
    <Container>
      <Question>
        <Header>
          <span>Questão {question.id}</span>
          {question?.response?.skillName && (
            <span className="compPill">
              Competência:
              <Pill color="rgba(34, 43, 69, 1)" backgroundColor="rgba(255, 201, 77, 1)" size={'small'} marginLeft={'10px'}>
                {question?.response?.skillName}
              </Pill>
            </span>)}
        </Header>
        <Body>
          <Label>Enunciado</Label>
          {question?.statement_data?.html && <Box dangerouslySetInnerHTML={{ __html: `${question?.statement_data?.html}` }} />}
          {!question?.statement_data?.html && <Box dangerouslySetInnerHTML={{ __html: `${question?.statement_data}` }} />}
          <Label>Resposta</Label>
          {getAnswersData}
          {question.response?.alternativeId === -1 && <Box bgColor="#FFD736">Não Aprendi ainda</Box>}
          <Label>Tempo de Resposta</Label>
          <Box>{question.answerTime}</Box>
        </Body>
      </Question>
    </Container>
  );
};

export default QuestionResume;
