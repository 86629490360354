import React from 'react';
import { ClassCardEnrollmentBodyContainer } from './styles';

export interface ClassCardEnrollmentBodyProps {
  isTrailsOpen: boolean;
}

const ClassCardEnrollmentBody: React.FC<ClassCardEnrollmentBodyProps> = ({ isTrailsOpen, children }) => {
  return <ClassCardEnrollmentBodyContainer isTrailsOpen={isTrailsOpen}>{children}</ClassCardEnrollmentBodyContainer>;
};

export { ClassCardEnrollmentBody };
