import React, { useCallback, useEffect, useState } from 'react';

import IClass from '@modules/classes/interfaces/IClass';
import ClassesList from '@modules/classes/components/ClassesList';
import { useProjects } from '@modules/projects/context/ProjectsContext';

const ProjectClassRelationCreate: React.FC = () => {
  const { setProjectCurrent, projectCurrent } = useProjects();
  const [selectedClass, setSelectedClass] = useState<IClass[]>([]);

  useEffect(() => {
    setSelectedClass(projectCurrent?.classes || []);
  }, [projectCurrent]);

  const handleAddRelationClass = useCallback(
    (classSelected: IClass[] | undefined) => {
      if (classSelected && classSelected.length > 0) {
        setProjectCurrent((oldState: any) => {
          const updatedClasses = [...(oldState.classes || []), ...classSelected];
          return { ...oldState, classes: updatedClasses };
        });
      }
    },
    [setProjectCurrent]
  );


  const setSelectedItens = useCallback(
    (c: IClass[]) => {
      setSelectedClass(c);
    }, [setSelectedClass]);

  return (
    <ClassesList
      headerTitle="Diretório de Turmas"
      btnText="Adicionar Turma"
      pathRedirect="/diretorio/projetos/criar"
      pathToReturn="/diretorio/projetos/criar"
      canSelect='many'
      selectedClass={selectedClass}
      setSelectedClass={setSelectedItens}
      handleOnClick={handleAddRelationClass}
    />
  );
};

export default ProjectClassRelationCreate;
