export function cleanObjectToQuery(obj: any) {
    const clean = (o: any) => {
      for (const key in o) {
        if (
          o[key] === '' ||
          (typeof o[key] === 'object' &&
            !Array.isArray(o[key]) &&
            Object.keys(o[key]).length === 0)
        ) {
          delete o[key]
        } else if (typeof o[key] === 'object') {
          clean(o[key])
        }
      }
    }
  
    const data = { ...obj }
    clean(data)
    return data
  }
  