import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { Input, Spinner } from 'react-alicerce-components';

import { useLaunchers } from '@modules/launchers/context/LaunchersContext';
import IClass from '@modules/classes/interfaces/IClass';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const LauncherClassesSelection: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  const { setClassSelected } = useLaunchers();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleClickActivities = useCallback(
    (classClicked: IClass) => {
      setClassSelected(classClicked);
      // TODO: redirecionar pra o local correto e set estado necessario
      navigate(`/lancador/avaliacao/${classClicked.id}`);
    },
    [navigate, setClassSelected]
  );

  const filterClassesActivities = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <>
      <HeaderDirectory
        title="Seleção de Contexto"
        status="basic"
        pathToReturn="/"
        backgroundHeader="#8F9BB3"
        menuKebab={{
          menuBackHome: true,
        }}
      />

      <DirectoryContainer footer={false} style={{ marginTop: '0px' }}>
        {isLoading && <Spinner />}
        <div style={{ marginBottom: '2rem' }}>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => filterClassesActivities(event.target.value)}
          />
        </div>
        <ClassesSelection handleOnClick={handleClickActivities} searchValue={searchValue} />
      </DirectoryContainer>
    </>
  );
};

export { LauncherClassesSelection };
