import React, { useCallback, useEffect, useState } from 'react';
import PlaceList from '@modules/places/components/PlaceList';
import IPlace from '@modules/places/interfaces/IPlace';
import { useClass } from '@modules/classes/context/ClassContext';

const ClassPlacesRelation: React.FC = () => {
  const { setClassCurrent, classCurrent } = useClass();
  const [selectedPlace, setSelectedPlaceCreate] = useState<IPlace>();

  useEffect(() => {
    setSelectedPlaceCreate(classCurrent.place);
  }, [classCurrent.place]);

  const handleAddRelationPlace = useCallback(
    (placeSelect: IPlace[] | undefined) => {
      setClassCurrent((oldState: any) => {
        if (placeSelect) return { ...oldState, place: placeSelect[0] };
      });
    },
    [setClassCurrent]
  );

  const setSelectedItens = useCallback(
    (place: IPlace[]) => {
      setSelectedPlaceCreate(place[0]);
    },
    [setSelectedPlaceCreate]
  );

  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Adicionar Local"
      listOptions={{ categories: ['room'], group_id: classCurrent.group ? classCurrent.group.id : undefined }}
      canSelectPlace="one"
      handleOnClick={setSelectedItens}
      selectedItens={selectedPlace ? [selectedPlace] : []}
      setSelectedItens={handleAddRelationPlace}
      isAdvancedFilterDisabled={true}
    />
  );
};

export { ClassPlacesRelation };
