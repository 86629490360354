import React, { useCallback, useEffect, useState } from 'react';

import Divider from '@shared/components/Divider';

import { HabilidadesParaVidaIndividualLayout as Layout } from '@modules/dashboards/components/HabilidadesParaVidaIndividualLayout';
import WeekSelect, { IChangeWeek } from '@modules/dashboards/components/WeekSelect';
import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';

import { Container } from './styles';

const HabilidadesParaVidaIndividualCurrent: React.FC = () => {

    const [axis, setAxis] = useState<any[]>([]);
    const [columns] = useState<IDynamicTableColumnsGroup[]>([
        {
            columns: [
                { name: 'Eixos', dataname: 'axisname' }
            ],
            fixed: true
        },
        {
            columns: [
                { name: 'Nunca', dataname: 'never' },
                { name: 'Quase Nunca', dataname: 'occasionally' },
                { name: 'Quase Sempre', dataname: 'sometimes' },
                { name: 'Sempre', dataname: 'always' },
                { name: 'N/A', dataname: 'nothing' },
            ],
            fixed: false
        }
    ]);

    const handleOnChangeWeek = useCallback(async (data: IChangeWeek) => {
        console.log('data', data);
    }, []);

    const hadleOnSelectRowData = useCallback((data: any) => {
        console.log(data);
        setAxis((oldState) => oldState.map(a => ({ ...a, selected: a.id === data?.id ? !Boolean(a.selected) : a.selected })));
    }, []);

    const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
        const orderBy = sort.sortName as string;

        setAxis((oldState) => {
            let aux = [...oldState]
            aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
            return aux;
        });
    }, []);

    const handleSubItems = useCallback((data: any) => {
        console.log(data);
        setAxis((oldState) => oldState.map(a => ({ ...a, isOpen: a.id === data?.id ? !Boolean(a.isOpen) : a.isOpen })));
    }, []);

    useEffect(() => {
        async function load() {
            setAxis([
                {
                    id: 1,
                    axisname: 'Pensar',
                    never: '49 Ocorrências',
                    occasionally: '81 Ocorrências',
                    sometimes: '20 Ocorrências',
                    always: '189 Ocorrências',
                    nothing: '5 Ocorrências',

                    neverBgColor: '#F2F8FF',
                    occasionallyBgColor: '#C7E2FF',
                    sometimesBgColor: '#F2F8FF',
                    alwaysBgColor: '#94CBFF',
                    nothingBgColor: '#FF3D71',

                    subItems: [
                        {
                            axisname: 'Competencia 1',
                            never: '16 Ocorrências',
                            occasionally: '60 Ocorrências',
                            sometimes: '20 Ocorrências',
                            always: '100 Ocorrências',
                            nothing: '1 Ocorrências',

                            neverBgColor: '#CCFCE3',
                            occasionallyBgColor: '#2CE59B',
                            sometimesBgColor: '#00D68F',
                            alwaysBgColor: '#8CFAC7',
                            nothingBgColor: '#FFF2F2',
                        },
                        {
                            axisname: 'Competencia 2',
                            never: '4 Ocorrências',
                            occasionally: '10 Ocorrências',
                            sometimes: '0 Ocorrências',
                            always: '41 Ocorrências',
                            nothing: '1 Ocorrências',

                            neverBgColor: '#F0FFF5',
                            occasionallyBgColor: '#F0FFF5',
                            sometimesBgColor: '#FFFFFF',
                            alwaysBgColor: '#CCFCE3',
                            nothingBgColor: '#FFF2F2',
                        }
                    ]
                },
                {
                    id: 2,
                    axisname: 'Sentir',
                    never: '49 Ocorrências',
                    occasionally: '81 Ocorrências',
                    sometimes: '20 Ocorrências',
                    always: '189 Ocorrências',
                    nothing: '3 Ocorrências',

                    neverBgColor: '#94CBFF',
                    occasionallyBgColor: '#0095FF',
                    sometimesBgColor: '#C7E2FF',
                    alwaysBgColor: '#0095FF',
                    nothingBgColor: '#FFA8B4',

                    subItems: [
                        {
                            axisname: 'Competencia 1',
                            never: '16 Ocorrências',
                            occasionally: '60 Ocorrências',
                            sometimes: '20 Ocorrências',
                            always: '100 Ocorrências',
                            nothing: '1 Ocorrências',

                            neverBgColor: '#CCFCE3',
                            occasionallyBgColor: '#2CE59B',
                            sometimesBgColor: '#00D68F',
                            alwaysBgColor: '#8CFAC7',
                            nothingBgColor: '#FFF2F2',
                        },
                        {
                            axisname: 'Competencia 2',
                            never: '4 Ocorrências',
                            occasionally: '10 Ocorrências',
                            sometimes: '0 Ocorrências',
                            always: '41 Ocorrências',
                            nothing: '1 Ocorrências',

                            neverBgColor: '#F0FFF5',
                            occasionallyBgColor: '#F0FFF5',
                            sometimesBgColor: '#FFFFFF',
                            alwaysBgColor: '#CCFCE3',
                            nothingBgColor: '#FFF2F2',
                        }
                    ]
                }
            ])
        }

        load();
    }, []);

    return (
        <Container>
            <Layout openTab={0}>

                <WeekSelect handleChangeWeek={handleOnChangeWeek} />

                <Divider className='divider' width='100%' />

                <DynamicTable
                    columnsGroup={columns}
                    data={axis}
                    handleClickRow={hadleOnSelectRowData}
                    handleClickSort={handleOnClickSort}
                    handleSubItems={handleSubItems}
                    canSelect
                    containSubItems
                />

            </Layout>
        </Container >
    );
}

export default HabilidadesParaVidaIndividualCurrent;