import { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';

class InvalidateApplicationService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(applicationId: number, body: { invalidation_reason: string }): Promise<boolean> {
    try {
      await this.api.patch(`/application/${applicationId}`, body);
      return true;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}

export { InvalidateApplicationService };
