import { styled } from 'react-alicerce-components';

export const Container = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    //width: 40%;
    min-width: 32%;
    max-width: 59%;
  }
`;
