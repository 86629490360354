import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, useToast } from 'react-alicerce-components';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import WorkGroupForm from '@modules/work-group/components/WorkGroupForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { useWorkGroup } from '@modules/work-group/context/WorkGroupContext';
import GetWorkGroupService from '@modules/work-group/services/GetWorkGroupService';
import { workGroupSchema } from '@modules/work-group/utils/workGroupSchema';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { formatWorkGroup } from '@modules/work-group/utils/formatWorkGroup';
import Icon from 'react-eva-icons';
import ConfirmModal from '@shared/components/ConfirmModal';
import { IFormattedWorkGroupBody } from '@modules/work-group/interfaces/IFormattedWorkGroupBody';
import EditWorkGroupService from '@modules/work-group/services/EditWorkGroupService';

const WorkGroupEdit: React.FC = () => {
  const { workGroupId, classId } = useParams<{ workGroupId: string; classId: string }>();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { setWorkGroupCurrent, workGroupCurrent } = useWorkGroup();
  const [formattedWorkGroupBody, setFormattedWorkGroupBody] = useState<IFormattedWorkGroupBody>();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const { addToast } = useToast();

  const canEditWorkGroup = useCallback(async () => {
    if (!workGroupCurrent || !classId) return;
    const formatedBody = formatWorkGroup({ ...workGroupCurrent, class_id: classId });
    let formErros = await beforeSubmitForm(formatedBody, workGroupSchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    setShowEditModal(true);
    setFormattedWorkGroupBody(formatedBody);
  }, [classId, workGroupCurrent]);

  const editWorkGroup = useCallback(async () => {
    if (!workGroupId || !formattedWorkGroupBody) return;
    setShowEditModal(false);
    setIsLoading(true);
    const res = await new EditWorkGroupService(signedUser?.token, newCancelToken()).execute(workGroupId, formattedWorkGroupBody);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Grupo de Trabalho',
        description: 'Error ao Editar Grupo De Trabalho.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Grupo de Trabalho',
      description: 'Grupo De Trabalho Editado com Sucesso.',
    });

    navigate(`/grupo-de-trabalho/${workGroupId}/informacoes/turma/${classId}`);

    setWorkGroupCurrent(res);
  }, [addToast, classId, formattedWorkGroupBody, navigate, newCancelToken, setIsLoading, setWorkGroupCurrent, signedUser, workGroupId]);

  const getWorkGroup = useCallback(async () => {
    if (!workGroupId) throw new Error('Error ao Listar Work Groups');
    setIsLoading(true);
    const res = await new GetWorkGroupService(signedUser?.token, newCancelToken()).execute(workGroupId);
    if (!res) throw new Error('Error ao Listar Work Groups');
    setIsLoading(false);
    if (!isMounted()) return;
    setWorkGroupCurrent(res);
  }, [isMounted, newCancelToken, setIsLoading, setWorkGroupCurrent, signedUser, workGroupId]);

  useEffect(() => {
    !workGroupCurrent && getWorkGroup();
  }, [getWorkGroup, workGroupCurrent]);

  return (
    <Fragment>
      <HeaderDirectory title="Grupo de Trabalho" backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)" />
      <DirectoryContainer footer={true}>
        <WorkGroupForm
          pathEnvolvedBlocks={`/grupo-de-trabalho/selecionar/blocos/turma/${classId}`}
          errors={errors}
          pathEnvolvedStudents={`/grupo-de-trabalho/selecionar/alunos/turma/${classId}`}
        />
        <PageFooter>
          <Button transform="none" outline shaded onClick={() => navigate(-1)} className="button-cancel-blocks">
            Cancelar
          </Button>
          <Button transform="none" status="quaternary" shaded onClick={() => canEditWorkGroup()} className="mochileiro-color">
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(false)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={editWorkGroup}
      />
    </Fragment>
  );
};

export { WorkGroupEdit };
