import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import Icon from 'react-eva-icons';
import { BsCheckLg } from 'react-icons/bs';
import { Button, Checkbox, Input, Label, Pagination, Spinner } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import PageFooter from '@shared/components/PageFooter';

import IClass from '@modules/classes/interfaces/IClass';
import ListClassesService from '@modules/classes/services/ListClassesService';
import ClassCard from '@modules/classes/components/ClassCard';

import { Container, ContainerCard, ContainerSelectAll, WrapperContent } from './styles';

const ClassReportCreate: React.FC = () => {
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const [classes, setClasses] = useState<IClass[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalOfPages, setTotalOfPages] = useState<number | string>(0);

  const listClasses = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListClassesService(signedUser.token, newCancelToken()).execute(search, { page, size: 10 });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res) {
        setClasses(res.items);
        setCurrentPage(res.currentPage);
        setTotalOfPages(res.totalPages);
      }
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listClasses();
  }, [listClasses]);

  const handleClickBtn = useCallback(() => {
  }, []);


  const handleClickClassCard = useCallback(
    (classClicked: IClass) => {
    },
    []
  );

  const handleCheckAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked: boolean = event.target.checked;
      console.log(isChecked);
    },
    []
  );

  const handleOnChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, sub: IClass) => {
      // const checked: boolean = event.target.checked;
      // const classId: string = event.target.id;
    },
    []
  );

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Seleção de Turmas"
        backgroundHeader='linear-gradient(45deg, #328DA1 0%, #25BBAB 100%);'
      />

      <DirectoryContainer style={{ marginTop: 0 }} footer={true}>
        <WrapperContent>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => listClasses(event.target.value)}
          />
          <ContainerSelectAll>
            <Label>Selecionar Todos</Label>
            <Checkbox
              semiRound
              name="select-all"
              id="select-all"
              onChange={(event) => handleCheckAll(event)}
              icon={BsCheckLg}
              hideIconUncheck
            />
          </ContainerSelectAll>

          {classes.map((c) => (
            <ContainerCard key={c.id}>
              <ClassCard
                handleOnClick={handleClickClassCard}
                classInfo={c}
                canSelect
                classesSelected={[]}
              />
              <Checkbox
                key={`${c.id}`}
                name={`${c.id}`}
                id={`${c.id}`}
                semiRound
                icon={BsCheckLg}
                hideIconUncheck
                onChange={(event) => handleOnChangeCheckbox(event, c)}
              // defaultChecked={!!c.find((cs) => cs.id === sub.id)}
              />
            </ContainerCard>
          ))}

          {!!classes.length && (
            <Pagination
              totalOfPages={totalOfPages}
              currentPage={currentPage}
              callbackGetListData={listClasses}
              status="quaternary"
            />
          )}
        </WrapperContent>

        <PageFooter>
          <Button
            transform="none"
            noBorder
            fill='linear-gradient(45deg, #328DA1 0%, #25BBAB 100%);'
            onClick={handleClickBtn}
            shaded
          >
            Gerar Relatório
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  )
};

export default ClassReportCreate;
