import React, { useCallback } from 'react';
import { Input, InputDatePicker, Spinner } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import CustomForm from '@shared/components/CustomForm';
import ParentRender from '@shared/components/ParentRender';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { datePickerFormat } from '@shared/utils/datePickerFormat';
import PlaceCard from '@modules/places/components/PlaceCard';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';

import { ClassPlannerFormContainer } from './styles';
import { IDailyClass } from '../../../attendances/interfaces/IDailyClass';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { formatDate } from '@shared/utils/formatDate';

type IClassPlannerForm = {
  errors: IInputErrorsFormat[];
  pathEnvolvedPlace: string;
};

const ClassPlannerForm: React.FC<IClassPlannerForm> = ({ errors, pathEnvolvedPlace, children }) => {
  const navigate = useNavigate();
  const { dailyClass, setDailyClass } = useScheduleGrid();
  const { isLoading } = useGlobal();

  const handleClassPlannerChangeDate = useCallback(
    (date: string) => {
      setDailyClass((oldState: IDailyClass) => {
        return { ...oldState, start_date: date };
      });
    },
    [setDailyClass]
  );

  const handleChangeClassPannerInput = useCallback(
    (value: string, key: string) => {
      setDailyClass((oldState) => {
        return { ...oldState, [key]: value };
      });
    },
    [setDailyClass]
  );

  const removePlace = useCallback(() => {
    setDailyClass((oldState) => {
      return { ...oldState, place: undefined, place_id: undefined };
    });
  }, [setDailyClass]);

  const isEditButtonDisabled = useCallback(() => {
    if (!dailyClass?.plan?.status) return;
    if (dailyClass.plan.status === 'not_planned') return false;
    return true;
  }, [dailyClass]);

  return (
    <ClassPlannerFormContainer>
      {isLoading && <Spinner fixed />}
      <CustomForm customInputColorText="#2E3A59">
        <InputDatePicker
          disabled={isEditButtonDisabled()}
          handleChangeDate={(date) => handleClassPlannerChangeDate(datePickerFormat(date))}
          defaultValue={formatDate(dailyClass?.start_date, 'numeric')}
          input={{
            required: true,
            label: 'Data da Aula:',
            name: 'start_date',
            placeholder: 'Selecione uma data',
            useTooltipError: false,
            error: errorMessageBuilder('start_date', errors),
          }}
        />

        <div className="container-initial-final-date">
          <Input
            disabled={isEditButtonDisabled()}
            label="Horário de Início:"
            name="start_time"
            placeholder="00:00"
            mask="##:##"
            onChange={(event) => handleChangeClassPannerInput(event.target.value, 'start_time')}
            defaultValue={dailyClass?.start_time}
            required
            error={errorMessageBuilder('start_time', errors)}
            useTooltipError={false}
          />

          <Input
            disabled={isEditButtonDisabled()}
            label="Horário de Término:"
            name="end_time"
            placeholder="00:00"
            mask="##:##"
            onChange={(event) => handleChangeClassPannerInput(event.target.value, 'end_time')}
            defaultValue={dailyClass?.end_time}
            required
            error={errorMessageBuilder('end_time', errors)}
            useTooltipError={false}
          />
        </div>

        <ParentRender
          handleOnClick={!isEditButtonDisabled() ? () => navigate(pathEnvolvedPlace) : undefined}
          label="Local Envolvido:"
          hasParent={!!dailyClass?.place}
          customStyles={{ margin: '1.5rem 0' }}
          error={errorMessageBuilder('place_id', errors)}
        >
          {dailyClass?.place && (
            <PlaceCard
              isInputCard={true}
              canDeleteCard={!isEditButtonDisabled() ? { removeCallback: () => removePlace() } : undefined}
              customStyle={{ margin: '1rem' }}
              place={dailyClass.place}
            />
          )}
        </ParentRender>
        {children}
      </CustomForm>
    </ClassPlannerFormContainer>
  );
};

export default ClassPlannerForm;
