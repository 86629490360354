import React from 'react';
import { Spinner } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import AttendanceForm from '@modules/attendances/components/AttendanceForm';

import { Container } from './styles';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useParams } from 'react-router-dom';

export const AttendancesCreate: React.FC = () => {
  const { isLoading } = useGlobal();
  const { id } = useParams<{ id: string }>();

  return (
    <Container>
      {isLoading && <Spinner />}

      <HeaderDirectory
        title="Presença"
        pathToReturn={`/turma/${id}`}
        backgroundHeader="linear-gradient(45deg, #482B85 0%, #6749BA 100%)"
        menuKebab={{
          tooltip: { view: 'AttendancesCreate' },
          menuBackHome: true,
        }}
      />
      <DirectoryContainer footer>
        <AttendanceForm />
      </DirectoryContainer>
    </Container>
  );
};
