import React, { useCallback, useEffect, useState } from 'react';

import Icon from 'react-eva-icons';

import { Button, Pagination, Spinner } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { TagsListProvider, useTagsList } from './context/TagsListContext';

import ListTagsService from '@modules/tags/services/ListTagsService';
import ITag from '@modules/tags/interfaces/ITag';

import TagListItem from '../TagListItem';

import { Container } from './styles';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { DebounceInput } from '@shared/components/DebounceInput';

interface ITagsListCoreProps {
  useSearch?: boolean;
  canQuantitySelect?: number | 'many';
  overideOnTagClick?: (tag: ITag) => void;
  handleOnChangeTagsSelected?: (tags: ITag[]) => void;
  selectedTags?: ITag[];
  pathToReturn?: string;
  pathToReturnOptions?: { state: Object };
  buttonText: string;
  buttonCallback: () => void;
  tag_type?: string;
}

const TagListCore: React.FC<ITagsListCoreProps> = ({
  handleOnChangeTagsSelected,
  overideOnTagClick,
  useSearch = false,
  selectedTags,
  pathToReturn,
  pathToReturnOptions,
  buttonText,
  buttonCallback,
  tag_type,
}) => {
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();

  const { canSelect, tagsSelected, changeTagsSelected } = useTagsList();
  const [tags, setTags] = useState<ITag[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalOfPages, setTotalOfPages] = useState<number | string>(0);
  const [inputSearch, setInputSearch] = useState<string>('');

  const listTags = useCallback(
    async (page = 0) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new ListTagsService(signedUser.token, newCancelToken()).execute(inputSearch, { page, size: 10, type: tag_type, node: 0 });
      if (res === 'canceling') return;

      setIsLoading(false);

      if (res) {
        setTags(res.items);
        setCurrentPage(res.currentPage);
        setTotalOfPages(res.totalPages);
      }
    },
    [inputSearch, newCancelToken, setIsLoading, signedUser, tag_type]
  );

  useEffect(() => {
    listTags();
  }, [listTags]);

  useEffect(() => {
    selectedTags && changeTagsSelected(selectedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleOnChangeTagsSelected) {
      handleOnChangeTagsSelected(tagsSelected);
    }
  }, [tagsSelected, handleOnChangeTagsSelected]);

  return (
    <Container>
      <HeaderDirectory
        status="basic"
        statusNumber={600}
        title="Diretório de Tags"
        pathToReturn={pathToReturn}
        pathToReturnOptions={pathToReturnOptions}
        backgroundHeader="linear-gradient(45deg, #282674 0%, #145496 100%)"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'TagDirectory' },
        }}
      />
      <DirectoryContainer footer>
        {isLoading && <Spinner fixed />}

        {useSearch && (
          <DebounceInput
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => setInputSearch(event.target.value)}
          />
        )}

        <div className="container-all-tags">
          {tags.map((tag) => {
            return <TagListItem key={tag.id} tag={tag} clickable={canSelect} overideOnTagClick={overideOnTagClick}  />;
          })}
        </div>

        {!!tags.length && (
          <Pagination totalOfPages={totalOfPages} currentPage={currentPage} callbackGetListData={(_, page) => listTags(page)} status="secondary" />
        )}

        <PageFooter>
          <Button transform="none" status="secondary" statusNumber="800" color="#fff" shaded noBorder onClick={buttonCallback}>
            {buttonText}
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

type ITagsListProps = ITagsListCoreProps & {
  canQuantitySelect?: number | 'many' | string;
};

const TagList: React.FC<ITagsListProps> = ({ canQuantitySelect = 0, ...rest }) => (
  <TagsListProvider quantitySelect={canQuantitySelect}>
    <TagListCore {...rest} />
  </TagsListProvider>
);

export default TagList;
