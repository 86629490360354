import { ICalendarData } from '@modules/classes/interfaces/IClass';

/**
 * Organiza em ordem crescente os horarios das aulas
 * @param times
 * @returns
 */
export const sortTime = (times: ICalendarData[]): ICalendarData[] => {
  if (!times) return [];
  times.sort((a, b) => {
    if (a.start > b.start) return 1;
    else if (a.start === b.start) return 0;
    else return -1;
  });
  return times;
};

/**
 * Valida inputs de inicio de fim antes de Salvar
 * @param calendarData
 * @returns
 */
export const validatePeriodTime = ({ start, end }): { name: string; message: string } | undefined => {
  if (!start || !end) return { name: 'time', message: 'Horários vazios.' };

  // Transforma a string '00:00' para '0000'
  let startTime = start.split(':').join('');
  let endTime = end.split(':').join('');

  // Verifica se o usuário digitou o formato certo que é xx:xx
  // Certo: 23:30
  // Errado: 23:0
  if (startTime.length !== 4 || endTime.length !== 4) return { name: 'time', message: 'Formato da hora deve ser XX:XX' };

  // Verifica se o usuário não digitou nenhum caracter que seja diferente de números.
  // Certo: 23:00
  // Errado: dd:30
  if (isNaN(+startTime) || isNaN(+endTime)) return { name: 'time', message: 'Horário(s) de início e/ou fim invalido(s)' };

  // Verifica se a HORA digitada está entre 00 e 23
  // Certo: 09:00
  // Errado: 25:00
  if (+startTime < 0 || +startTime > 2399 || +endTime < 0 || +endTime > 2399) return { name: 'time', message: 'Hora deve ser entre 00 e 23' };

  // Verifica o tempo Inicial e Tempo final são iguais
  // Certo: inicio: 09:10 -- fim: 09:50
  // Errado: inicio 09:10 -- fim: 09:10
  if (startTime === endTime) return { name: 'time', message: 'Tempo inicial e final não podem ser iguais' };

  // Verifica de Hora Inicio é maior que Hora Fim
  // Certo: inicio: 09:10 -- fim: 09:50
  // Errado: inicio 09:10 -- fim: 08:30
  if (startTime > endTime) return { name: 'time', message: 'Hora do fim deve ser maior que hora do inicio' };

  // Verifica se a MINUTO digitado está entre 00 e 59
  // Certo: 09:14
  // Errado: 09:80
  if (+startTime.slice(2, 4) > 59 || +endTime.slice(2, 4) < 0 || +endTime.slice(2, 4) > 59)
    return { name: 'time', message: 'Minuto deve ser entre 00 e 59' };
};

/**
 * Verifica se o valor que está sendo digitado nos inputs Hora Inicio e Hora Fim tem algum conflito com as horas já existentes na turma
 * @param datesAlreadyRegistered
 * @param newClassDate
 * @returns
 */
export const checkTimePeriodConflicts = (datesAlreadyRegistered: ICalendarData[], newClassDate: ICalendarData) => {
  return datesAlreadyRegistered.map((dateRegistred) => {
    // Verifica se 'Hora Inicio' está entre o Inicio e Fim de uma turma ja criada
    if (dateRegistred.start < newClassDate.start && dateRegistred.end > newClassDate.start) return { ...dateRegistred, conflict: true };
    // Verifica se 'Hora inicio' é igual a Inicio de uma turma ja criada
    else if (newClassDate.start === dateRegistred.start) return { ...dateRegistred, conflict: true };
    // Verifica se Fim de nova classe é maior que Inicio e menor que Final de dateRegistred
    else if (dateRegistred.start < newClassDate.end && dateRegistred.end > newClassDate.end) return { ...dateRegistred, conflict: true };
    // Verifica se Inicio de nova classe é menor que Inicio de dateRegistred && Fim de nova classe é maior que FInal de dateRegistred
    else if (dateRegistred.start > newClassDate.start && dateRegistred.end < newClassDate.end) return { ...dateRegistred, conflict: true };
    // Se Fim de nova classe é maior que Inicio de dateRegistred && se Fim de nova classe é menor ou igual a dateRegistred Fim
    else if (newClassDate.end > dateRegistred.start && newClassDate.end <= dateRegistred.end) return { ...dateRegistred, conflict: true };
    else return { ...dateRegistred, conflict: false };
  });
};

export const convertMinutesToHour = (min: number = 0) => {
  const hours = min / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes).toString();
  return `${String(rhours).padStart(2, '0')}:${String(rminutes).padStart(2, '0')}`; // xx:xx
};

export const convertStringTimeHourToMinutes = (hour: string = '00:00') => {
  const hourFormatRegex = /^(\d{2}|\d{1}):(\d{2}|\d{1})$/;
  const isFormatValid = hour.match(hourFormatRegex);

  if (!isFormatValid) {
    console.log('❌ ~ Hora no formato incorreto: ', hour);
    return 0;
  }

  const [hours, minutes] = hour.split(':');
  const totalInMinutes = Number(hours) * 60 + Number(minutes);
  return totalInMinutes;
};
