import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import styled from 'styled-components';

export const GridsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Grid = styled.div<{ isCanSelect?: boolean }>`
  height: 100%;
  min-width: 110px;
  width: 110px;
  position: relative;
  border: 3px solid transparent;
  margin-top: ${({ isCanSelect }) => (isCanSelect ? '5px' : '0')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    border: 1px dashed #8f9bb3;
    z-index: 0;
  }

  .checkbox-container {
    background: #edf1f7;
    margin-top: -1px;

    label {
      margin: 0 auto;

      input + div {
        border-radius: 5px;
        border: 2px solid #c5cee0;
      }

      input:checked + div {
        background-color: #dee3ec;
      }

      svg {
        width: 14px;
        height: 14px;
        fill: #2e3a59;
      }
    }

    &[disabled] {
      input:checked + div,
      input + div {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
`;

export const GridName = styled.div`
  position: relative;

  h6 {
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: 800;
    text-align: center;
    color: ${({ color }) => color};
    text-transform: uppercase;
  }
`;

export const ActiveContainer = styled.div<{ isMain?: boolean; color?: string }>`
  padding: 0.4rem;
  margin-top: 10px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${(props) =>
    props.isMain &&
    `
      background-color: rgba(0,0,0,.1);

      ${GridName} {
        h6 {
            background-color: ${props.color};
            color: #fff;
        }
      }
    `}
`;

export const SkillsContainer = styled.div<{ isShowLabel: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ isShowLabel }) => (!!isShowLabel ? '70px' : '10px')};
  gap: 10px;
  position: relative;
`;

export const SkillStatus = styled.div<{ status?: ElementStatus | string }>`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: transparent;
  text-shadow: 0 0 0 white;
  font-weight: bold;
  width: 70%;
  border-radius: 8px;
  height: 38px;
  background-color: ${({ status }) => themeGet(`colors.${status}500`)};
  border: 2px solid
    ${({ status }) =>
      status === 'basic' ? '#8F9BB3' : status === 'undef' ? 'none' : status !== 'basic' || 'undef' ? themeGet(`colors.${status}500`) : 'none'};
`;

export const SkillName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  i {
    display: flex;
    align-items: center;
    svg {
      stroke-width: 0.5px;
      stroke: #f7652b;
      width: 14px;
      height: 14px;
    }
  }
`;
