import { IModuleRoutes } from "@shared/interfaces/IModuleRoutes";
import IRoute from "@shared/interfaces/IRoute";

import { SprintClassSelection } from "@modules/sprint/views/SprintClassSelection";
import { SprintCreate } from "@modules/sprint/views/SprintCreate";
import { SprintList } from "@modules/sprint/views/SprintList";
import { SprintHistory } from "@modules/sprint/views/SprintHistory";
import { SprintContextProvider } from "@modules/sprint/context/SprintContext";
import { combineContext } from "@shared/utils/combineContext";

import { sprintKeys } from "@modules/sprint/keys";
import { classesKeys } from "@modules/classes/keys";

const routes: IRoute[] = [
    {
        path: "/planejador-sprint/contexto",
        key: "SPRINT_CLASS_SELECTION_PATH",
        exact: true,
        component: SprintClassSelection,
        permissions: [
            classesKeys.CLASSES_READ,
        ]
    },
    {
        path: "/planejador-sprint/:classId",
        key: "SPRINT_LIST_PATH",
        exact: true,
        component: SprintList,
        permissions: [
            sprintKeys.SPRINT_READ
        ]
    },
    {
        path: "/planejador-sprint/:classId/criar",
        key: "SPRINT_CREATE_PATH",
        exact: true,
        component: SprintCreate,
        permissions: [
            sprintKeys.SPRINT_READ,
            sprintKeys.SPRINT_CREATE
        ]
    },
    {
        path: "/planejador-sprint/:classId/historico",
        key: "SPRINT_HISTORY_PATH",
        exact: true,
        component: SprintHistory,
        permissions: [
            sprintKeys.SPRINT_READ
        ]
    },
];

const providers = [SprintContextProvider];


export default {
    key: 'SPRINT_MODULE',
    providers: combineContext(...providers),
    routes,
} as IModuleRoutes;