import React from 'react'
import { OptionSelectBlockContainer } from './styles';
type GroupedItemsProps = {
    data: any[]
    render: (item: any) => React.ReactNode;
}

export function GroupedItems(props: GroupedItemsProps) {
    const { data, render } = props

    if (!data || !data.length) return <div>Sem dados para mostrar.</div>

    return (
        <OptionSelectBlockContainer>
            {data.map(render)}
        </OptionSelectBlockContainer>
    );
}