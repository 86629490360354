import React, { createContext, useState, useContext } from 'react';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';

interface IWorkGroupContextState {
  workGroupCurrent?: IWorkGroup;
  setWorkGroupCurrent: (workGroup: any) => void;
}

const WorkGroupContext = createContext<IWorkGroupContextState>({} as IWorkGroupContextState);

const WorkGroupProvider: React.FC = ({ children }) => {
  const [workGroupCurrent, setWorkGroupCurrent] = useState<IWorkGroup>();

  return <WorkGroupContext.Provider value={{ workGroupCurrent, setWorkGroupCurrent }}>{children}</WorkGroupContext.Provider>;
};

function useWorkGroup() {
  const context = useContext(WorkGroupContext);
  if (!context) {
    throw new Error('useWorkGroup must be used within a WorkGroupProvider');
  }

  return context;
}

export { WorkGroupProvider, useWorkGroup };
