import React, { useCallback } from 'react';

import IClass from '@modules/classes/interfaces/IClass';
import IProject from '@modules/projects/interfaces/IProject';

import { ISelectOption } from 'react-alicerce-components';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { CheckboxTagItemChange } from '@shared/components/CheckboxTag';
import { getLocalTimezoneDate } from '@shared/utils/getLocalTimezoneDate';

export const useProjectForm = () => {
    const { setProjectCurrent } = useProjects();

    const handleOnChangeCheckbox = useCallback((field: string, state: CheckboxTagItemChange) => {
        setProjectCurrent((prevFilter: IProject) => {
            const currentArrayAux = prevFilter[field] || [];
            const foundIndex = currentArrayAux.findIndex((item) => item === state.name);
            if (foundIndex === -1) {
                return {
                    ...prevFilter,
                    [field]: [...currentArrayAux, state.name]
                };
            } else {
                return {
                    ...prevFilter,
                    [field]: currentArrayAux.filter((selectedItem) => selectedItem !== state.name)

                };
            }
        });
    }, [setProjectCurrent]);

    const handleChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, subLevel?: string) => {
            const inputValue = event.target.value;
            const inputName = event.target.name;
            setProjectCurrent((oldState) => {
                if (subLevel) return { ...oldState, [subLevel]: { ...oldState[subLevel], [inputName]: inputValue } };
                return { ...oldState, [inputName]: inputValue };
            });
        },
        [setProjectCurrent]
    );

    const handleSelectChange = useCallback(
        (option: ISelectOption | null) => {
            if (!option) return
            setProjectCurrent((oldState: IProject) => ({ ...oldState, [String(option?.selectname)]: option.value }));
        },
        [setProjectCurrent]
    );

    const handleRemoveField = (key: string) => {
        setProjectCurrent((oldState: IProject) => ({ ...oldState, [key]: undefined }));
    }

    const handleRemoveFieldArray = (key: string, index: number) => {
        setProjectCurrent((oldState: IProject) => {
          const updatedArray = [...(oldState[key] || [])];
          updatedArray.splice(index, 1);
          return { ...oldState, [key]: updatedArray };
        });
      };

    const handleRemoveClass = (c: IClass) => {
        setProjectCurrent((oldState: IProject) => {
            const classes = oldState.classes?.filter(i => i.id !== c.id)
            return {
                ...oldState,
                classes
            }
        });
    }

    const handleCheckboxImplementationStage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = Number(event.target.id);
        setProjectCurrent(oldState => {
            const updatedImplementationStages = oldState?.implementation_stage?.map(stage => {
                if (stage.id === id) {
                    return {
                        ...stage,
                        completed_at: stage.completed_at ? null : getLocalTimezoneDate().now().toISOString()
                    };
                }
                return stage;
            });

            return {
                ...oldState,
                implementation_stage: updatedImplementationStages
            };
        });
    }

    const handleRemoveContract = (c: IClass) => { }

    return {
        handleChangeInput,
        handleSelectChange,
        handleRemoveField,
        handleRemoveFieldArray,
        handleRemoveClass,
        handleRemoveContract,
        handleOnChangeCheckbox,
        handleCheckboxImplementationStage
    }
}
