import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { WorkedHoursAdvancedFilterContainer } from './styles';
import { Checkbox, Input, Label, Select } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { ICategoryHours, IWorkedHourTypes, useWorkedHour } from '../../context/WorkedHourContext';
import ListTagsService from '../../../tags/services/ListTagsService';
import { formatTagsSelect } from '../../utils/formatTagsSelect';
import TagsRender from '../../../tags/components/TagsRender';
import GroupCard from '../../../groups/components/GroupCard';
import ITag from '../../../tags/interfaces/ITag';

import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ParentRender from '@shared/components/ParentRender';
import CustomForm from '@shared/components/CustomForm';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';
import { uuid } from 'uuidv4';
import { FiCheck } from 'react-icons/fi';

interface IWorkedHoursAdvancedFilter {
  handleOnClickApplyFilterCallback: () => void;
  status?: 'approved' | 'reproved' | 'pending';
  onChangeMainInputCallback: (newValue: string) => void;
}

const WorkedHoursAdvancedFilter: React.FC<IWorkedHoursAdvancedFilter> = ({ handleOnClickApplyFilterCallback, status, onChangeMainInputCallback }) => {
  const { setSelectTags, selectTags, workedHoursFilter } = useWorkedHour();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const navigate = useNavigate();
  const [render, setRender] = useState<string>(uuid());

  const handleResetFilter = () => {
    workedHoursFilter.current = {};
    handleOnClickApplyFilterCallback();
    setRender(uuid);
  };

  const handleOnChangeInput = (newValue: string, keyName: string) => {
    const value = !!newValue.length ? newValue : undefined;
    workedHoursFilter.current[keyName] = value;
  };

  const selectTagsStateManagement = useCallback(
    (workedHourType: IWorkedHourTypes, tags: ITag[]) => {
      const foundTags = tags && tags.length > 0 ? tags : undefined;

      if (workedHourType === 'category') {
        setSelectTags({ category: foundTags });
      }

      if (workedHourType === 'modality') {
        setSelectTags((oldState: ICategoryHours) => {
          return { ...oldState, modality: foundTags };
        });
      }
    },
    [setSelectTags]
  );

  const loadTags = useCallback(
    async (workedHourType: IWorkedHourTypes, parentId?: string) => {
      setIsLoading(true);
      const res = await new ListTagsService(signedUser?.token).execute(undefined, {
        type: 'work_hour',
        parent_id: parentId,
        node: parentId ? undefined : 1,
        size: 9999,
      });
      setIsLoading(false);
      if (res === 'canceling' || !res) return;

      selectTagsStateManagement(workedHourType, res.items);
    },
    [selectTagsStateManagement, setIsLoading, signedUser]
  );

  const handleSelectChange = useCallback(
    (option, _selectName: string, workedHourType: IWorkedHourTypes) => {
      if (workedHourType === 'category') {
        const foundTag = selectTags?.category?.find((tag) => Number(tag.id) === Number(option.value));
        workedHoursFilter.current['category'] = foundTag;
        workedHoursFilter.current['modality'] = undefined;
        loadTags('modality', option.value);
      }

      if (workedHourType === 'modality') {
        const foundTag = selectTags?.modality?.find((tag) => Number(tag.id) === Number(option.value));
        workedHoursFilter.current['modality'] = foundTag;
      }
    },
    [loadTags, selectTags, workedHoursFilter]
  );

  const handleOnChangeMainInput = useCallback(
    (newInputValue: string) => {
      onChangeMainInputCallback(newInputValue);
    },
    [onChangeMainInputCallback]
  );

  const handleRemoveGroup = () => {
    workedHoursFilter.current.group = undefined;
    setRender(uuid());
  };

  const handleRemoveTag = () => {
    workedHoursFilter.current.project = undefined;
    setRender(uuid());
  };

  const handleCheckNoProjectTag = (event) => {
    /** null filtrará no banco por project_tag_id = null */
    workedHoursFilter.current.project = event.target.checked ? null : undefined;
    setRender(uuid());
  };

  useEffect(() => {
    !selectTags?.category && loadTags('category');
  }, [loadTags, selectTags]);

  return (
    <AdvancedFilter
      onChangeMainInput={(newValue) => handleOnChangeMainInput(newValue)}
      handleApplyFilter={handleOnClickApplyFilterCallback}
      handleResetFilter={handleResetFilter}
      applyFilterButtonBackground="#FF9F6D"
    >
      <WorkedHoursAdvancedFilterContainer key={render}>
        <section>
          <label>Quantidade de Horas:</label>
          <Input
            name="total_hours"
            placeholder="00:00h"
            onChange={(event) => handleOnChangeInput(event.target.value, event.target.name)}
            defaultValue={workedHoursFilter?.current.total_hours}
            end={<Icon name="clock" fill="#8F9BB3" />}
            mask="##:##"
          />
        </section>

        <Divider width="100%" />

        {status !== 'pending' && (
          <Fragment>
            <ParentRender
              handleOnClick={() => navigate('/horas-trabalhadas/selecionar/grupo')}
              label="Grupo Relacionado:"
              hasParent={!!workedHoursFilter?.current.group}
              customStyles={{ margin: '1.5rem 0' }}
            >
              {workedHoursFilter.current.group && (
                <GroupCard
                  canSelect={false}
                  group={workedHoursFilter.current.group}
                  inputCard={true}
                  canDeleteCard={{
                    removeCallback: () => handleRemoveGroup(),
                  }}
                  customStyle={{ margin: '1rem' }}
                />
              )}
            </ParentRender>
            <Divider width="100%" />
          </Fragment>
        )}

        <CustomForm>
          {selectTags?.category && (
            <Select
              label="Categoria da Horas:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'category_tag_id', 'category')}
              options={formatTagsSelect(selectTags.category, Number(workedHoursFilter?.current.category?.id))}
              handleOnReset={() => console.log('')}
            />
          )}

          {selectTags?.modality && (
            <Select
              label="Modalidade da Categoria:"
              iconName="arrow-down"
              placeholder="Selecione"
              customStyle={{ marginTop: '1rem' }}
              handleSelectChange={(option) => handleSelectChange(option, 'modality_tag_id', 'modality')}
              options={formatTagsSelect(selectTags?.modality, Number(workedHoursFilter?.current.modality?.id))}
            />
          )}
        </CustomForm>
        {workedHoursFilter.current.project !== null && (
          <TagsRender
            label="Projeto:"
            tags={workedHoursFilter.current.project ? [workedHoursFilter.current.project] : undefined}
            pathToRedirect="/horas-trabalhadas/selecionar/tag/projeto"
            canRemoveTag={true}
            placeholder="Adicione tag de projeto"
            tagCallback={handleRemoveTag}
          />
        )}
      </WorkedHoursAdvancedFilterContainer>

      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
        <Label>Sem tag de Projeto:</Label>
        <Checkbox
          hideIconUncheck
          icon={FiCheck}
          status="basic"
          semiRound
          name="no_project_tag"
          defaultChecked={workedHoursFilter.current.project === null}
          onChange={handleCheckNoProjectTag}
        />
      </div>
    </AdvancedFilter>
  );
};

export default WorkedHoursAdvancedFilter;
