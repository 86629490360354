import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .input-container {
    margin: 1.5rem 0 0 0;

    > div input::placeholder {
      font-weight: 500;
      color: #c5cee0;
    }
  }

  .pagination-content-icon {
    background-color: #ffba95 !important;

    i svg {
      fill: #fff !important;
    }
  }

  .trail-box {
    &:first-child {
      margin-top: 10px;
    }
  }

  .main-navbar h6 {
    max-width: 200px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  .custom-card > div {
    margin: 10px 0;
  }
`;
