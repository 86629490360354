import { styled } from 'react-alicerce-components';

export const Container = styled.div`
    
    .divider {
        margin-top: 0;
    }

    .accordion-header {
        justify-content: flex-start;
        background-color: #E4E9F2;
    }

    .accordion-content {
        padding-top: 1rem;
    }

    .box-container {
        display: grid;
        grid-template-columns: 40% repeat(2, 1fr);
        gap: 12px;
        margin-top: 1.5rem;
    }

    .box-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        background-color: #E4E9F2;
        text-align: center;
        font-size: 12px;
        font-weight: 800;
        color: #222B45;
        border-radius: 5px;
        padding: 10px;
        text-transform: uppercase;
    }

    .box-value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        background-color: #C5CEE0;
        text-align: center;
        font-size: 16px;
        color: #222B45;
        border-radius: 5px;
    }

    .nps-media-value-container {
        margin-top: 1.5rem;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        justify-content: space-between;

        .nps-media-value-info {
            
            div:nth-child(1),
            div:nth-child(3){
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-weight: 800;
                color: #fff;
                height: 26px;
                padding-right: 5px;
            }

            div:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #EDF1F7;
                border: 1px solid #E4E9F2;
                font-size: 25px;
                font-weight: 800;
                color: #222B45;
                height: 54px;
            }

            div:nth-child(1) {
                text-transform: uppercase;
                border: 1px solid #E4E9F2;
                background: #8F9BB3;
                border-radius: 0px 5px 0px 0px;
                font-size: 12px;
            }

            div:nth-child(3) {
                background: #00D68F;
                border: 1px solid #E4E9F2;
                border-radius: 0px 0px 5px 0px;
                font-size: 10px;
            }
        }
    }

    .buttons-container {
        margin: 1.5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const BoxColor = styled.div<{color: string}>`
    height: 70px;
    background-color: ${({color}) => color};

    font-size: 20px;
    color: #fff;
    font-weight: 800;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;