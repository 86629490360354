import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IPeriod } from '@modules/schedules/interfaces/IPeriod';

class GetCurrentPeriodService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(sprint_id: number): Promise<IPeriod | false> {
    try {
      const res = await this.api.get(`/period/current?sprint_id=${sprint_id}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return error.response.status;
    }
  }
}

export default GetCurrentPeriodService;