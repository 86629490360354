import { styled } from "react-alicerce-components";

export const AttachedToolsContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  z-index: 6;
  display: ${p => (p.isOpen ? "block" : "none")};
`;

export const AttachedToolsHeader = styled.div`
  background-color: #8f9bb3;
  height: 90px;
  display: flex;
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  align-items: flex-end;
  padding-bottom: 24px;

  .left-content {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .middle-content {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-content {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AttachedTooldBody = styled.div`
  font-weight: 700;
  color: #2e3a59;
  font-size: 18px;
  font-family: var(--secondary-font);
  padding: 0px 20px;
  overflow-y: auto;
  height: calc(100vh - 85px);

  .tool-check {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #e4e9f2;
    padding: 30px 0px;

    div {
      margin: 0;
    }

    span {
      margin-left: 10px;
    }
  }

  .span-text {
    height: 25px;
  }

  input:checked + div {
    border-color: transparent;
    svg {
      color: #ffffff;
    }
  }
`;
