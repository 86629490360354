import styled from 'styled-components';

interface IAlertMessage {
  process?: string;
}

export const AlertContainer = styled.div<IAlertMessage>`
  ${({ process }) => (process === 'enrollment' ? 
    `color: #2E3A59; 
    border: 2px dashed #2E3A59;` : 
    `color: #8F9BB3; 
    border: 2px dashed #8F9BB3;`)}
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  margin-top: 8px;
  border-radius: 999px;
  font-weight: 700;
  .eva-hover{
    display: flex;
  }
`;
