import React from 'react'
import { Checkbox } from 'react-alicerce-components'
import { Container, ImplementationCheckBoxContainer, ImplementationContent } from './styles'
import { BsCheckLg } from 'react-icons/bs';
import { ProjectImplementationStage } from '@modules/projects/interfaces/IProject';
import { formatDateUtcToTimeZone } from '@shared/utils/formatDateUtcToTimeZone';

type ImplemetationStageCardProps = {
  disable?: boolean
  implementationStage: ProjectImplementationStage
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ImplemetationStageCard = (props: ImplemetationStageCardProps) => {
  const { implementationStage, handleOnChange, disable = false } = props

  const RenderCompletedAt = () => {
    if (implementationStage.completed_at) {
      return <p>Realizado em: {formatDateUtcToTimeZone({date: `${implementationStage.completed_at}`, dateStyle: 'medium'})}</p>
    }
    return <p>Pendente</p>
  }
  return (
    <Container>
      <ImplementationContent>
        <div className='title-implementation-content'>
          <h6>{implementationStage.title}</h6>
        </div>
        <div className='footer-implementation-content'>
          <RenderCompletedAt />
        </div>
      </ImplementationContent>
      <ImplementationCheckBoxContainer>
        <Checkbox
          disabled={disable}
          id={`${implementationStage.id}`}
          name={`${implementationStage.id}`}
          semiRound
          onChange={(event) => handleOnChange?.(event)}
          icon={BsCheckLg}
          hideIconUncheck
          defaultChecked={Boolean(implementationStage?.completed_at)}

        />
      </ImplementationCheckBoxContainer>
    </Container>
  )
}

