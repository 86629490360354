import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { objectToQuery } from '@shared/utils/objectToQuery';

interface IGetCurrentSprintOptions {
  class_id?: number;
  schedule_id?: number;
}
class GetCurrentSprintService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options: IGetCurrentSprintOptions): Promise<ISprint | false> {
    let searchParams = objectToQuery({ ...options });
    try {
      const res = await this.api.get(`/sprint/current?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return error.response.status;
    }
  }
}

export default GetCurrentSprintService;
