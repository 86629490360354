import React, { useCallback, useMemo } from 'react';
import { IStudent } from '@modules/students/interfaces/IStudent';
import StudentList from '@modules/students/components/StudentsList';
import { useActivitiesPortfolio } from '../../context/ActivitiesPortfolioContext';
import { useParams } from 'react-router-dom';

const ActivitiesPortfolioStudentsRelation: React.FC = () => {
  const { activityPortfolioCurrent, setActivityPortfolioCurrent } = useActivitiesPortfolio();
  const { classId } = useParams();

  const setSelectedItens = useCallback(
    (students: IStudent[]) => {
      setActivityPortfolioCurrent((oldState: any) => {
        return { ...oldState, students: students };
      });
    },
    [setActivityPortfolioCurrent]
  );

  const selectedItens = useMemo(() => {
    return activityPortfolioCurrent.students;
  }, [activityPortfolioCurrent.students]);

  return (
    <StudentList
      classId={classId}
      headerTitle="Diretório de Aluno"
      btnText="Adicionar Aluno"
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelectStudent={'many'}
      handleOnClick={setSelectedItens}
    />
  );
};

export { ActivitiesPortfolioStudentsRelation };
