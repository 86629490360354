import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';

const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.set({ page: `${process.env.REACT_APP_ENV || 'localhost'}:${location.pathname}` });
        ReactGA.pageview(location.pathname);
    }, [location]);

    return <div></div>;
};

export default RouteChangeTracker;
