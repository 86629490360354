import React, { useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useActivity } from '@modules/activities-library/context/ActivityContext';

const ActivityLibraryThemeRelationCreate: React.FC = () => {
  const navigate = useNavigate();
  const { activityLibraryCurrent, setActivityLibraryCurrent } = useActivity();

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setActivityLibraryCurrent((oldState: any) => {
        return { ...oldState, theme: tags[0] };
      });
    },
    [setActivityLibraryCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={1}
      useSearch
      selectedTags={activityLibraryCurrent.theme ? [activityLibraryCurrent?.theme] : undefined}
      buttonCallback={() => navigate(-1)}
      buttonText="Adicionar Tag"
    />
  );
};

export { ActivityLibraryThemeRelationCreate };
