import React, { createContext, useContext, useState } from 'react';
import { IAttatchedButtons } from '@shared/utils/ButtonInfos';

export type IHomeContextData = {
  attachedTools?: IAttatchedButtons[];
  setAttachedTools: (attachedTools: IAttatchedButtons[]) => void;
};

const HomeContext = createContext<IHomeContextData>({} as IHomeContextData);

const HomeProvider: React.FC = ({ children }) => {
  const [attachedTools, setAttachedTools] = useState<IAttatchedButtons[]>();

  return <HomeContext.Provider value={{ attachedTools, setAttachedTools }}>{children}</HomeContext.Provider>;
};

function useHome() {
  const context = useContext(HomeContext);

  if (!context) {
    throw new Error('useHome must be used within a HomeProvider');
  }

  return context;
}

export { HomeProvider, useHome };
