import styled from 'styled-components';

export const Container = styled.div`
  .textarea-container,
  .input-container {
    textarea::placeholder,
    > div input::placeholder {
      font-family: 'Open Sans Semibold';
      font-weight: 500;
      color: #c5cee0;
    }
  }

  .select-container {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }

  .error-time,
  .error-dates {
    position: relative;
    color: #c53030;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--primary-font);
    position: relative;
    top: -20px;
    grid-column: 1/3;
  }
`;

export const HourContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 5%;

  .input-container input {
    display: flex;
    width: 100%;
  }
`;
