import React from 'react';
import { H6 } from 'react-alicerce-components';
import { CSSProperties } from 'styled-components';

import CustomCard from '@shared/components/CustomCard';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';

import HeadlightSelect from './HeadlightSelect';

import { Container, HeadlightContainer } from './styles';
import { HeadlightStatusOptions } from '@modules/projects/schemas/Headlight/partials/HeadlightStatus';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { errorMessageBuilder } from '@shared/utils/beforeSubmitForm';

interface HeadlightCardSelectProps {
  selected?: any[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
  category: string;
  categoryDescription?: string;
  handleOnClick?: (clicked: any) => void;
  status: {
    status: HeadlightStatusOptions
    description: string
  };
  checkedStatus?:HeadlightStatusOptions
  readOnly?: boolean;
};


const HeadlightCardSelect: React.FC<HeadlightCardSelectProps> = ({
  category,
  categoryDescription = '',
  status,
  checkedStatus,
  readOnly = false
}) => {
  const { headlightErrors, currentHeadlight } = useHeadlights();

  const filledCategory = currentHeadlight?.data?.find((s) => s.category === category)

  return (
    <Container>
      <CustomCard header={<H6>{category}</H6>}>
        <AccordionInfoItem title='Descrição da Categoria'>
          {categoryDescription}
        </AccordionInfoItem>
        <HeadlightContainer>
          {headlightErrors && !filledCategory &&
            <span>{errorMessageBuilder('data', headlightErrors) || errorMessageBuilder('category_status', headlightErrors)}</span>
          }
          <HeadlightSelect categoryDescription={categoryDescription} category={category} status={status} name={category} checkedStatus={checkedStatus} readOnly={readOnly}/>
        </HeadlightContainer>
      </CustomCard>
    </Container>
  );
};

export default HeadlightCardSelect;
