export const HEADLIGHT_TYPE_DEPLOYMENT_CONTRAINS = 'deployment' // Implantação
export const HEADLIGHT_TYPE_EXECUTION_CONTRAINS = 'execution' // Execução

export const HEADLIGHT_TYPE_CONTRAINS = [
    HEADLIGHT_TYPE_DEPLOYMENT_CONTRAINS,
    HEADLIGHT_TYPE_EXECUTION_CONTRAINS
];

export type HeadlightType =
  typeof HEADLIGHT_TYPE_DEPLOYMENT_CONTRAINS |
  typeof HEADLIGHT_TYPE_EXECUTION_CONTRAINS