import React from 'react';

import Layout from '@modules/contracts/components/Layout';

import { ContractInfoFragment } from '@modules/contracts/components/ContractForm/partials/ContractInfoFragment';
import { ContractDefaultStylesContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';

const ContractInfo: React.FC = () => {
  return (
    <Layout openTab={0} >
      <ContractDefaultStylesContainer>
        <ContractInfoFragment disabled={true} />
      </ContractDefaultStylesContainer>
    </Layout>
  );
};

export default ContractInfo;
