import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 5;
`;

export const ContainerData = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  max-width: 90%;
  min-width: 230px;
  padding-bottom: 1rem;

  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    i {
      margin: 8px;
    }
  }

  .badge-modal-line {
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 10px;
  }

  .icon-start-container {
    margin-top: 10px;
    i {
      display: flex;
      svg {
        width: 90px;
        height: 90px;
      }
    }
  }

  .show-close-button-tooltip-modal {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
    padding-right: 2px;
    z-index: 10;
    width: min-content;
    cursor: pointer;

    i {
      display: flex;
      cursor: pointer;

      > svg {
        fill: #2e3a59 !important;
      }
    }
  }

  .title-tooltip-modal {
    text-align: start;
    padding: 0px 15px;
    margin-top: 25px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #222b45;
  }

  .margin-wihout-start {
    margin-top: 15px;
  }

  .subTitle-tooltip-modal {
    color: #2e3a59;
    width: 85%;
    margin-top: 5px;
    text-align: start;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow-y: scroll;
    white-space: pre-line;
  }
`;

export const ButtonModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 15px 0 0 0;

  button {
    max-width: 130px;
    height: 40px;
    font-size: 0.75rem;
    i {
      display: flex;
      margin: 0px;
    }
  }
`;
