import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Spinner } from 'react-alicerce-components';

import ButtonGradient from '@shared/components/ButtonGradient';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';

import { Container, ContainerButtons } from './styles';
const ApplicationSelection: React.FC = () => {
  const { classId } = useParams<{ classId: string }>();
  const { isLoading } = useGlobal();
  const navigate = useNavigate();

  const ButtonInfos = [
    {
      name: 'Lançar MAPA',
      key: 'mapa_launcher',
      colorType: 16,
      path: `/lancador/mapa/${classId}`,
    },
    {
      name: 'Lançar MiniMAPA',
      key: 'mini_mapa_launcher',
      colorType: 17,
      path: `/lancador/minimapa/selecao-trilha/${classId}`,
    },
    // {
    //   name: 'Lançar Hab para a vida',
    //   key: 'life_skills_launcher',
    //   colorType: 17,
    //   path: `/lancador/minimapa/selecao-trilha/${classId}`,
    // }
  ];

  const pathToReturn = classId ? `/turma/${classId}` : '/lancador/avaliacao/contexto/turmas'

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Lançador de Avaliação"
        pathToReturn={pathToReturn}
        backgroundHeader=" linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <ContainerButtons>
        {ButtonInfos &&
          ButtonInfos.map((button, i) => (
            <ButtonGradient key={i} type={button.colorType} onClick={() => navigate(button.path)}>
              {button.name}
            </ButtonGradient>
          ))}
      </ContainerButtons>
      <PageFooter>
        <Button onClick={() => navigate(`/aplicacoes/turma/${classId}`)} status="quaternary" transform="none" noBorder shaded>
          Aplicações
        </Button>
      </PageFooter>
    </Container>
  );
};

export { ApplicationSelection };
