import { styled } from 'react-alicerce-components';
import { ILearningTargetsStudentCardBody } from '.';


export const LearningTargetsStudentCardContainer = styled.div<ILearningTargetsStudentCardBody>`
  background: #e9d7f9;
  min-height: 60px;
  padding: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #222b45;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    transform: ${(p) => (p.isTrailsOpen ? `rotate(90deg)` : `rotate(0deg)`)};
    transition: 0.3s;
    i {
      display: flex;
      cursor: pointer;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
`;
