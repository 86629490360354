import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { SkillsList } from '../../components/SkillsList';
import { useAdvancedFilterController } from '../../context/AdvancedFilterControllerContext';
import { ISkill } from '@shared/interfaces/ISkill';

export const ActivityLibrarySkillsAdvancedFilter: React.FC = () => {
  const navigate = useNavigate();
  const { setOpenTab, setIsAdvancedFilterOpen } = useAdvancedFilterController();
  const { activityLibraryFilter } = useActivity();

  const handleSkillsSelected = useCallback(
    (skills: ISkill[]) => {
      setOpenTab('activity');
      setIsAdvancedFilterOpen(true);
      activityLibraryFilter.current.skills = skills;
    },
    [activityLibraryFilter, setIsAdvancedFilterOpen, setOpenTab]
  );

  return (
    <SkillsList
      handleSkillsSelected={(skills) => handleSkillsSelected(skills)}
      selectedSkills={activityLibraryFilter.current?.skills ? activityLibraryFilter.current?.skills : []}
      buttonText="Salvar Competencias"
      buttonCallback={() => navigate(-1)}
    />
  );
};
