import { styled } from 'react-alicerce-components';

export const ActivityLibraryFormContainer = styled.div`
  .checkbox-container {
    margin: 0;
    > label {
      margin: 0;
      > div {
        margin: 0 4px 0 0;
      }
    }
  }

  .h6-activity-library {
    color: #b85ec4;
    margin: 1.5rem 0;
  }

  .first-h6-page {
    margin-top: 0;
  }

  .select-container {
    margin: 1.5rem 0 0 0;
    .select-option-text {
      > span {
        background: #e4e9f2;
        color: #2e3a59;
      }
    }
  }

  .container-split-info {
    margin: 1.5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;

    .input-container {
      margin: 0;
      flex: 1;
      height: 70px;
    }

    .container-modality-checkbox {
      height: 70px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      .container-checkbox-options {
        display: flex;
        gap: 1rem;
      }

      .input-error-message-container {
        position: absolute;
        bottom: -20px;
        left: 0;
      }
    }
  }

  .select-container .select-options-list {
    position: relative;
  }

  .textarea-size {
    width: 100%;
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 1.5rem;

  .checkboxes  {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0.5rem 0 0 0;
  }
`;