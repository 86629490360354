import React, { Fragment, useCallback, useState } from 'react';
import { useGroup } from '@modules/groups/context/GroupContext';
import { useToast } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CreateGroupService from '@modules/groups/services/CreateGroupService';
import { formatGroup } from '@modules/groups/utils/formatGroup';
import ConfirmModal from '@shared/components/ConfirmModal';
import GroupForm from '@modules/groups/components/GroupForm';

const GroupCreate: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { groupCurrent, clearGroupContextData } = useGroup();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const handleCreateGroup = useCallback(async () => {
    setShowModal(false);
    if (!signedUser) return;
    const formatedBody = formatGroup(groupCurrent);
    setIsLoading(true);
    const res = await new CreateGroupService(signedUser.token).execute(formatedBody);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Grupos',
        description: 'Grupo criado com sucesso.',
      });
      clearGroupContextData();
      return navigate('/diretorio/grupos');
    }

    addToast({
      status: 'danger',
      title: 'Grupos',
      description: 'Não foi possível criar o Grupo.',
    });
  }, [addToast, clearGroupContextData, groupCurrent, navigate, setIsLoading, signedUser]);

  return (
    <Fragment>
      <GroupForm
        saveModalCallback={setShowModal}
        title="Criar Grupo"
        pathEnvolvedPlaces="/diretorio/grupos/criar/selecionar/locais"
        pathEnvolvedUsers="/diretorio/grupos/criar/selecionar/usuarios"
        pathParentGroup="/diretorio/grupos/criar/selecionar"
        pathToReturn="/diretorio/grupos"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Deseja criar o Grupo?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={() => handleCreateGroup()}
      />
    </Fragment>
  );
};

export default GroupCreate;
