import { styled } from 'react-alicerce-components';

export const PlaceFormContainer = styled.div`
  .wrapper-input {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.8rem;
    max-width: 100%;
    .input-container {
      margin: 0;
    }

    > div:first-child {
      width: 70%;
    }
    > div:last-child {
      width: 30%;
    }

    input {
      width: inherit;
    }
  }

  .select-container {
    max-width: unset;
  }

  .container-online-place {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .checkbox-container {
      margin: 0 16px 0 0;
      > label > div {
        margin: 0 4px 0 0;
      }
    }
    .input-container {
      flex: 1;
      margin: 0;
    }
  }

  .textarea-container {
    margin-top: 1.5rem;
  }

  .container-parent-place {
    position: relative;
    margin-top: 15px;
  }

  .add-parent-place {
    position: absolute;
    z-index: 2;
    right: 0;
    width: fit-content;

    i {
      width: 25px;
      height: 25px;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }

  .container-button {
    cursor: pointer;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #c5cee0;
      border-radius: 8px;

      svg {
        fill: #fff;
      }
    }
  }

  .add-parent {
    position: relative;
    z-index: 2;
    width: fit-content;
    margin: 15px 0 -36px auto;

    i {
      width: 25px;
      height: 25px;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }

  .add-managers {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 30px;

    i {
      width: 40px;
      height: 40px;

      svg {
        width: 35px;
        height: 35px;
      }
    }

    .text-add-button {
      font-family: var(--secondary-font);
      font-weight: bold;
      font-size: 15px;
      display: flex;
      color: #2e3a59;
    }
  }
`;
