import { convertHexToHsl } from '@shared/utils/convertHexToHsl';
import { css, styled } from 'react-alicerce-components';

interface IContainer {
  heightState: string;
}

export const Container = styled.div<IContainer>`
  display: block;
  cursor: pointer;
  background: #edf1f7;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  max-height: ${({ heightState }) => (heightState ? heightState : '0px')};

  .trail {
    padding: 1.5rem;
    position: relative;
    
    :not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        width: 90%;
        background-color: #e4e9f2;
        margin: 0 auto;
      }
    }
  }

  .trail-content {
    display: flex;
    justify-content: space-between;

    @media (max-width: 420px) {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }

  .trail-name {
    font-weight: 800;
    font-size: 14px;
    color: #2e3a59;
    display: flex;
    align-items: center;
  }

  .empty-block {
    font-weight: 600;
    font-size: 14px;
    color: #2e3a59;
    display: flex;
    align-items: center;
    text-transform: none;
  }

  .trail-level {
    font-weight: 600;
    font-size: 12px;
    color: #222b45;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

export const InitialLevel = styled.div<{ initialColor: string, colorVariation: number }>`
  ${({ colorVariation, initialColor }) => css`
    padding: 5px 10px;
    border-radius: 5px;
    background-color: hsla${convertHexToHsl(initialColor!, colorVariation)};
  `}
`;
