import React, { useCallback, useEffect, useRef, useState } from 'react';
import { H6, Pagination } from 'react-alicerce-components';
import ClassCard from '@modules/classes/components/ClassCard';
import ListClassesService, { IClassesReponse } from '@modules/classes/services/ListClassesService';
import ListClassesUserManagesService from '@modules/classes/services/ListClassesUserManagesService';
import Divider from '@shared/components/Divider';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import axios, { CancelTokenSource } from 'axios';
import IClass from '@modules/classes/interfaces/IClass';
import { Modifier } from './styles';

interface IClassesSelectionProps {
  handleOnClick: (classClicked: IClass) => void;
  searchValue: string;
  orderClassesUserManages?: boolean;
  orderMyClasses?: boolean;
}

const ClassesSelection: React.FC<IClassesSelectionProps> = ({ handleOnClick, searchValue, orderClassesUserManages, orderMyClasses }) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [myClasses, setMyClasses] = useState<IClassesReponse>();
  const [managedClasses, setManagedClasses] = useState<IClassesReponse>();
  const isMounted = useIsMounted();
  const cancelRequest = useRef<CancelTokenSource>();
  const cancelRequestManaged = useRef<CancelTokenSource>();

  const listMyClasses = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      cancelRequest.current && cancelRequest.current.cancel('Cancelling search');
      const newCancelToken = () => {
        cancelRequest.current = axios.CancelToken.source();
        return cancelRequest.current;
      };
      const res = await new ListClassesService(signedUser.token, newCancelToken()).execute(searchValue ? searchValue : search, {
        page,
        size: 4,
        teacher_id: signedUser.lmsUser.id,
        ...(!!orderMyClasses && { order: "class.status" }),
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res && isMounted()) setMyClasses(res);
    },
    [isMounted, orderMyClasses, searchValue, setIsLoading, signedUser]
  );

  const listClassesUserManages = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      cancelRequestManaged.current && cancelRequestManaged.current.cancel('Cancelling search');
      const newCancelToken = () => {
        cancelRequestManaged.current = axios.CancelToken.source();
        return cancelRequestManaged.current;
      };
      const res = await new ListClassesUserManagesService(signedUser.token, newCancelToken()).execute(searchValue ? searchValue : search, {
        page,
        size: 4,
        ...(!!orderClassesUserManages && { order: "c.status" }),
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res && isMounted()) setManagedClasses(res);
    },
    [isMounted, orderClassesUserManages, searchValue, setIsLoading, signedUser]
  );

  useEffect(() => {
    listMyClasses();
  }, [listMyClasses]);

  useEffect(() => {
    listClassesUserManages();
  }, [listClassesUserManages]);

  return (
    <>
      <H6 fontWeight={800} textAlign="center" status="quaternary">
        TURMAS QUE DOU AULA
      </H6>
      <div>
        {myClasses?.items.map((c, i) => (
          <ClassCard key={i} classInfo={c} handleOnClick={handleOnClick} />
        ))}
      </div>

      {!!myClasses?.items.length && (
        <Modifier>
          <Pagination totalOfPages={myClasses.totalPages} currentPage={myClasses.currentPage} callbackGetListData={listMyClasses} />
        </Modifier>
      )}

      <Divider />

      <H6 fontWeight={800} textAlign="center" status="quaternary">
        TURMAS QUE COORDENO
      </H6>
      <div>
        {managedClasses?.items.map((c, i) => (
          <ClassCard key={i} classInfo={c} handleOnClick={handleOnClick} />
        ))}
      </div>
      {!!managedClasses?.items.length && (
        <Modifier>
          <Pagination
            totalOfPages={managedClasses.totalPages}
            currentPage={managedClasses.currentPage}
            callbackGetListData={listClassesUserManages}
          />
        </Modifier>
      )}
    </>
  );
};

export default ClassesSelection;
