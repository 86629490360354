import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import IListOptions from '../../../shared/interfaces/IListOptions';
import { IWorkedHourFilterFormatted } from '../utils/formatWorkedHoursFilter';

export interface IWorkedHourReponse extends IPaginatedResponse {
  items: IWorkedHour[];
}
export type IListWorkedHoursStatusOptions = 'approved' | 'reproved' | 'pending'
interface IListWorkedHoursOptions extends IListOptions, IWorkedHourFilterFormatted {
  issued_by?: number | string;
  status?: IListWorkedHoursStatusOptions[];
  query?: string;
}

export class ListWorkedHoursService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options?: IListWorkedHoursOptions): Promise<IWorkedHourReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ ...options }, true);
      const res = await this.api.get(`/worked-hour?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}
