import React, { useCallback, useMemo } from "react";

import PlaceList from "@modules/places/components/PlaceList";
import { usePlaces } from "@modules/places/context/PlacesContext";
import IPlace from "@modules/places/interfaces/IPlace";

const PlaceListParentCreate: React.FC = () => {
  const { setPlaceCurrent, placeCurrent } = usePlaces();

  const handleOnClick = useCallback(
    (newPlace: IPlace) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, parent: newPlace };
      });
    },
    [setPlaceCurrent]
  );

  const setSelectedItens = useCallback(
    (newPlaces: IPlace[]) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, parent: newPlaces[0] };
      });
    },
    [setPlaceCurrent]
  );

  const selectItens = useMemo(() => {
    const aux = [] as IPlace[];
    placeCurrent?.parent && aux.push(placeCurrent.parent);
    return aux;
  }, [placeCurrent]);

  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Adicionar Local Mãe"
      pathRedirect="/diretorio/locais/criar"
      pathToReturn="/diretorio/locais/criar"
      canSelectPlace={"one"}
      handleOnClick={handleOnClick}
      selectedItens={selectItens}
      setSelectedItens={setSelectedItens}
    />
  );
};

export default PlaceListParentCreate;
