import React from 'react';

import { formatDate, Grid, P } from 'react-alicerce-components';
import CustomCard from '@shared/components/CustomCard';

import Tag from '@modules/tags/components/Tag';

import { Container, FooterContainer } from './styles';
import ISteering from '@modules/projects/interfaces/ISteering';
import { getSteeringThermometerColor, translateSteeringThermometerStatus } from '@modules/projects/schemas/Steering/partials/SteeringThermometer';
import { translateSteeringChannel } from '@modules/projects/schemas/Steering/partials/SteeringChannel';
import { translateSteeringType } from '@modules/projects/schemas/Steering/partials/SteeringType';

type ISteeringCardProps = {
  steering: ISteering;
  handleOnClick?: (steering: ISteering) => void;
};

const SteeringCard: React.FC<ISteeringCardProps> = ({ steering, handleOnClick, ...rest }) => {
  const RenderMeetingDate = () => {
    if (steering?.meeting_date) {
      return <b>{formatDate( new Date(steering?.meeting_date), 'long' )}</b>;
    }
    return null;
  };

  const RenderThermometer = () => {
    const thermometer = steering?.thermometer;
    const status = getSteeringThermometerColor(thermometer);
    const isNeutral = thermometer === 'neutral' && '#2e3a59';

    return (
      <Tag
        style={{ borderRadius: '5px', color: isNeutral ? isNeutral : '#ffffff' }}
        backgroundColor={status}
        tag={{ name: translateSteeringThermometerStatus(thermometer) }}
        size="small"
      />
    );
  };

  return (
    <Container className="steering-card-container">
      <CustomCard
        handleOnClick={() => handleOnClick && handleOnClick(steering)}
        header={
          <h6 style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <b>Temperatura:</b>
            {steering.thermometer ? <RenderThermometer /> : <P>Steering ainda Não Realizada</P>}
          </h6>
        }
        footer={
          <FooterContainer>
            <Grid>
              <b>Canal:</b>
              <Tag tag={{ name: translateSteeringChannel(steering?.meeting_channel) }} size="small" />
            </Grid>
            <Grid>
              <b>Tipo:</b>
              <Tag tag={{ name: translateSteeringType(steering?.type) }} size="small" />
            </Grid>
          </FooterContainer>
        }
        {...rest}
      >
        <Grid dFlex flexColumn alignItemsStart>
          Data de realização:
          <RenderMeetingDate />
        </Grid>
      </CustomCard>
    </Container>
  );
};

export default SteeringCard;
