import React, { useMemo, useEffect, useCallback } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';

import APP_MODULES_ROUTES from './app.routes';
import AUTH_MODULES_ROUTES from './auth.routes';

import Error403 from '@modules/errors/views/Error403';
import Error404 from '@modules/errors/views/Error404';

import IRoute from '@shared/interfaces/IRoute';

import { ProviderRender } from '@shared/infra/http/routes/helper.routes';

const AppRouter: React.FC = () => {
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { search } = useLocation();

  const urlCodeParam = useMemo(() => new URLSearchParams(search).get('code'), [search]);
  const isSigned = useMemo(() => (signedUser && !urlCodeParam ? true : false), [signedUser, urlCodeParam]);
  const modules = useMemo(() => (isSigned ? APP_MODULES_ROUTES : AUTH_MODULES_ROUTES), [isSigned]);

  const isValidPermissions = useCallback((userPermissions: string[], route: IRoute) => {
    let isFoundedAll = true;

    if (!route.permissions) {
      console.error(`Route permission (${route.path}) permission is empty`);
      return false;
    }

    if (route.permissions !== 'public') {
      route.permissions.forEach((permission) => {
        if (!userPermissions.includes(permission)) {
          isFoundedAll = false;
        }
      });
    }

    return isFoundedAll;
  }, []);

  const renderRoutes = useCallback(
    (r) =>
      r?.map((route: IRoute) => {
        const permissionGranted = signedUser?.is_admin ? true : isValidPermissions(signedUser?.permissions as string[], route);
        return permissionGranted ? (
          <Route key={route.key} path={route.path} element={<route.component />} />
        ) : (
          <Route key={route.key} path={route.path} element={<Error403 />} />
        );
      }),
    [isValidPermissions, signedUser]
  );

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      addToast({
        status: 'warning',
        description: 'Ative os cookies do navegador para continuar navegando.',
        duration: 0,
      });
    }
  }, [addToast]);

  return (
    <Routes>
      {modules.map((m) => {
        if (!m.providers) return renderRoutes(m.routes);
        return (
          <Route path="/" key={m.key} element={<ProviderRender provider={m.providers} />}>
            {renderRoutes(m.routes)}
          </Route>
        );
      })}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRouter;
