import IRoute from "@shared/interfaces/IRoute";

import ScheduleEvents from "@modules/schedules/views/ScheduleEvents";
import ConsolidatedSchedule from "@modules/schedules/views/ConsolidatedSchedule";
import LocalSchedule from "@modules/schedules/views/LocalSchedule";
import { ExternalSchedule } from "@modules/schedules/views/ExternalSchedule";
import ScheduleCreate from "@modules/schedules/views/ScheduleCreate";
import ScheduleSprintCreate from "@modules/schedules/views/ScheduleSprintCreate";
import ScheduleSprintEdit from "@modules/schedules/views/ScheduleSprintEdit";
import ScheduleEventCreate from "@modules/schedules/views/ScheduleEventCreate";
import ScheduleEventEdit from "@modules/schedules/views/ScheduleEventEdit";
import ScheduleEdit from "@modules/schedules/views/ScheduleEdit";
import { ExternalSchedulesProvider } from "@modules/schedules/views/ExternalSchedule/context/ExternalScheduleContext";

import { SchedulesProvider } from "@modules/schedules/context/SchedulesContext";
import { combineContext } from "@shared/utils/combineContext";
import {IModuleRoutes} from "@shared/interfaces/IModuleRoutes";

import { schedulesKeys } from "@modules/schedules/keys";
import { sprintKeys } from "@modules/sprint/keys";

const routes: IRoute[] = [
    {
        path: "/agenda/consolidada",
        key: "SCHEDULE_GROUP_PATH",
        exact: true,
        component: ConsolidatedSchedule,
        permissions: [
            sprintKeys.SPRINT_READ,
            schedulesKeys.SCHEDULES_EVENT_READ,
        ]
    },
    {
        path: "/agenda/locais",
        key: "LOCAL_SCHEDULE_PATH",
        exact: true,
        component: LocalSchedule,
        permissions: [
            schedulesKeys.SCHEDULES_READ,
        ]
    },
    {
        path: "/agenda/externas",
        key: "EXTERNAL_SCHEDULE_PATH",
        exact: true,
        component: ExternalSchedule,
        permissions: [
            schedulesKeys.SCHEDULES_READ,
        ]
    },
    {
        path: "/agenda",
        key: "SCHEDULE_SHOW_PATH",
        exact: true,
        component: ScheduleEvents,
        permissions: [
            sprintKeys.SPRINT_READ,
            schedulesKeys.SCHEDULES_EVENT_READ,
        ]
    },
    {
        path: "/agenda/criar",
        key: "SCHEDULE_CREATE_PATH",
        exact: true,
        component: ScheduleCreate,
        permissions: [
            schedulesKeys.SCHEDULES_CREATE
        ]
    },
    {
        path: "/agenda/editar",
        key: "SCHEDULE_EDIT_PATH",
        exact: true,
        component: ScheduleEdit,
        permissions: [
            schedulesKeys.SCHEDULES_UPDATE
        ]
    },
    {
        path: "/agenda/evento/criar",
        key: "SCHEDULE_EVENTO_CREATE_PATH",
        exact: true,
        component: ScheduleEventCreate,
        permissions: [
            schedulesKeys.SCHEDULES_EVENT_CREATE
        ]
    },
    {
        path: "/agenda/evento/editar",
        key: "SCHEDULE_EVENTO_EDIT_PATH",
        exact: true,
        component: ScheduleEventEdit,
        permissions: [
            schedulesKeys.SCHEDULES_EVENT_UPDATE,
            schedulesKeys.SCHEDULES_EVENT_DELETE
        ]
    },
    {
        path: "/agenda/sprint/criar",
        key: "SCHEDULE_SPRINT_CREATE_PATH",
        exact: true,
        component: ScheduleSprintCreate,
        permissions: [
            sprintKeys.SPRINT_CREATE,
        ]
    },
    {
        path: "/agenda/sprint/editar",
        key: "SCHEDULE_SPRINT_EDIT_PATH",
        exact: true,
        component: ScheduleSprintEdit,
        permissions: [
            sprintKeys.SPRINT_UPDATE,
            sprintKeys.SPRINT_DELETE,
        ]
    }
];

const providers = [
    SchedulesProvider,
    ExternalSchedulesProvider
];

export default {
    key: 'SCHEDULES_MODULE',
    providers: combineContext(...providers),
    routes,
} as IModuleRoutes;