import { styled } from 'react-alicerce-components';

export const CardInformation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 1rem;

  .index-activity {
    color: white;
    width: 28px;
    height: 28px;
    background: #568cf7;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 800;
  }

  .is-already-in-calendar {
    background: #568cf7;
    border-radius: 9px;
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
    padding: 3px 10px;
  }
`;
