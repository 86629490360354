import React, { useEffect, useState } from 'react';
import IClass from '@modules/classes/interfaces/IClass';
import { IStudent } from '@modules/students/interfaces/IStudent';
import ClassesList from '@modules/classes/components/ClassesList';
import { useStudent } from '../../context/StudentContext';

const StudentClassesRelation: React.FC = () => {
  const { studentCurrent, setStudentCurrent } = useStudent();
  const [selectedClasses, setSelectedClasses] = useState<IClass[]>([]);

  const handleAddRelationClass = () => {
    setStudentCurrent((oldState: IStudent) => {
      const currentClasses = (oldState.enrollments || []).flatMap((c) => (c.class.id ? c.class.id : []));

      const enrollments = selectedClasses.flatMap((sc) => {
        const doesClassAlreadyExists = currentClasses.find((cc) => String(cc) === String(sc.id));
        if (!doesClassAlreadyExists) return { class: sc };
        return [];
      });
      oldState.enrollments = [...(oldState.enrollments || []), ...enrollments];
      return { ...oldState };
    });
  };

  const setSelectedItens = (c: IClass[]) => {
    setSelectedClasses(c);
  };

  useEffect(() => {
    const classes = studentCurrent.enrollments ? studentCurrent.enrollments.map((enrollment) => enrollment.class) : [];
    setSelectedClasses(classes);
  }, [studentCurrent.enrollments]);

  return (
    <ClassesList
      headerTitle="Diretório de Turmas"
      btnText="Adicionar Turma"
      selectedClass={selectedClasses}
      setSelectedClass={setSelectedItens}
      canSelect="many"
      handleOnClick={handleAddRelationClass}
    />
  );
};

export { StudentClassesRelation };
