export const dragOver = (e) => {
    e.preventDefault();
    return false;
};

export const dragEnter = (e, groupIdCurrent) => {
    const groupId = e.target.id;
    const { group_marker_index, grid_index } = e.target.dataset
    const accept = group_marker_index <= grid_index && groupId === groupIdCurrent;

    if (accept) {
        e.target.classList.add('over');
        e.target.style.cursor = 'move';
        return;
    }

    if (!accept) {
        e.target.style.cursor = 'no-drop';
        return;
    }

};

export const dragLeave = (e) => {
    e.target.classList.remove('over');
};

