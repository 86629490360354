import React, { ComponentProps, ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction, HTMLAttributes, useCallback, useEffect, useImperativeHandle, useState } from "react";
import HeadlightSelectCard from "@modules/projects/components/HeadlightSelectCard";
import { useProjects } from "@modules/projects/context/ProjectsContext";
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import ListHeadlightCategoriesService, { IHeadlightCategories } from "@modules/projects/services/Headlight/ListHeadlightCategoriesService";
import { Grid } from "react-alicerce-components";
import { useHeadlights } from "@modules/projects/components/Headlights/context/HeadlightsContext";
import IHeadlight from "@modules/projects/interfaces/IHeadlight";
import { useParams } from "react-router-dom";
import { Container } from "./styles";

export type CurrentHeadlightLaunchHandle = ComponentPropsWithoutRef<'div'> & {
    getheadlightCategories: () => IHeadlightCategories[]
}

const BaseCurrentHeadlightLaunch: ForwardRefRenderFunction<CurrentHeadlightLaunchHandle, HTMLAttributes<HTMLDivElement>> = ({
    children,
    ...rest
}, forwardRef) => {
    const { projectCurrent } = useProjects();
    const { setCurrentHeadlight } = useHeadlights();
    const { setIsLoading, isLoading } = useGlobal();
    const { signedUser } = useAuth();
    const [headlightCategories, setHeadlightCategories] = useState<IHeadlightCategories[]>();
    const { id: projectId } = useParams<{ id: string }>()

    useImperativeHandle(forwardRef, () => ({
        getheadlightCategories: () => headlightCategories || []
    }))

    const listHeadlightCategories = useCallback(async (projectCurrent) => {
        if (!signedUser) return;

        setIsLoading(true);

        const headlightCategories = await new ListHeadlightCategoriesService(signedUser.token).execute({ type: projectCurrent?.status });
        if (!headlightCategories) return;
        setHeadlightCategories(headlightCategories);
        setIsLoading(false);
    }, [setIsLoading, signedUser, setHeadlightCategories]);

    useEffect(() => {
        if (projectCurrent?.id === projectId && projectCurrent?.status) {
            listHeadlightCategories(projectCurrent)
            setCurrentHeadlight((oldState: IHeadlight) => {
                return { ...oldState, project_id: projectCurrent.id, type: projectCurrent.status }
            })
        }
    }, [projectCurrent, projectId, listHeadlightCategories, setCurrentHeadlight])

    return (
        <Container>
            {!isLoading &&
                (headlightCategories
                    ?
                    headlightCategories.map((_h, i) => <HeadlightSelectCard key={i} category={_h.category} categoryDescription={_h.description} status={_h.status} />)
                    :
                    <Grid textCenter>
                        {`Não é possível lançar farol para o projeto com o status atual.`}
                    </Grid>)
            }
        </Container>
    )

}

export const CurrentHeadlightLaunch = forwardRef(BaseCurrentHeadlightLaunch)

export type CurrentHeadlightLaunchProps = ComponentProps<typeof CurrentHeadlightLaunch>