import { ILevel } from '@modules/activities-library/interfaces/ILevel';
import { IBlock } from '@shared/interfaces/IBlock';
import { ITrail } from '@shared/interfaces/ITrail';

export const formatOptions = (objectToFormat: ITrail[] | ILevel[] | IBlock[] | undefined) => {
  if (!objectToFormat) return;
  return objectToFormat.map((obj) => {
    return { name: obj.name, value: obj.id };
  });
};
