import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm } from '@shared/utils/beforeSubmitForm';

import ProjectForm from '@modules/projects/components/ProjectForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { formatProject } from '@modules/projects/utils/formatProject';
import { ProjectSchema } from '@modules/projects/schemas/Project';
import CreateProjectService from '@modules/projects/services/Project/CreateProjectService';

const ProjectCreate: React.FC = () => {
  const dirPath = '/diretorio/projetos'

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { projectCurrent, clearProjectCreate, setErros } = useProjects();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    setErros([])
    clearProjectCreate();
    navigate(dirPath);
  }, [clearProjectCreate, navigate, setErros]);

  const handleCreateButton = useCallback(async () => {
    if (!projectCurrent) return;
    const formatedBody = formatProject(projectCurrent);
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [projectCurrent, setErros]);

  const handleCreateProject = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !projectCurrent) return;
    setIsLoading(true);
    const formatedBody = formatProject(projectCurrent);
    const res = await new CreateProjectService(signedUser.token).execute(formatedBody);
    setIsLoading(false);
    if (!res) return addToast({
      status: 'danger',
      title: 'Projetos',
      description: 'Não foi possível criar o Projeto.',
    });

    addToast({
      status: 'success',
      title: 'Projetos',
      description: 'Projeto criado com sucesso.',
    });
    clearProjectCreate();
    return navigate(dirPath)

  }, [signedUser, projectCurrent, setIsLoading, addToast, clearProjectCreate, navigate]);

  useEffect(() => {
    if (projectCurrent && projectCurrent.id) {
      clearProjectCreate()
    }
  }, [clearProjectCreate, projectCurrent, setErros])

  return (
    <Fragment>
      <ProjectForm
        headerTitle="Criar Projeto"
        headerPathToReturn={dirPath}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
        pathAddContract="/diretorio/projetos/criar/selecionar/contrato"
        pathAddClasses="/diretorio/projetos/criar/selecionar/turmas"
        pathAddGroup="/diretorio/projetos/criar/selecionar/grupo"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar este Projeto?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateProject}
      />
    </Fragment>
  );
};

export default ProjectCreate;
