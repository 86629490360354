import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  overflow-x: hidden;

   .main-navbar {
    height: 130px;
  }

  [role='tabpanel'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px !important;
    height: fit-content;
    overflow: hidden;
  }

  .accordion-header {
    justify-content: flex-start;
    background-color: #e4e9f2;
  }

  .accordion-content {
    padding-top: 1rem;
  }

  button {
    display: flex;
    margin: 0 auto;
  }
`;
