import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'react-alicerce-components';

import PageFooter from '@shared/components/PageFooter';

import Layout from '@modules/projects/components/Layout';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectInfoFragment } from '@modules/projects/components/ProjectForm/partials/ProjectInfoFragment';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';

const ProjectInfo: React.FC = () => {
  const navigate = useNavigate();
  const { getProject } = useProjects();
  const { id: projectId } = useParams<{ id: string }>();
  useEffect(() => {
    getProject(projectId)
  }, [getProject, projectId])

  return (
    <Layout openTab={0} footer>
      <ProjectDefaultStylesContainer>
        <ProjectInfoFragment disabled />
        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() => navigate(`/projeto/${projectId}/editar`)}
          >
            Editar
          </Button>
        </PageFooter>
      </ProjectDefaultStylesContainer>
    </Layout>
  );
};

export default ProjectInfo;
