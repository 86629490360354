import IClass from '@modules/classes/interfaces/IClass';
import { ITrails } from '@shared/interfaces/ITrail';
import React, { createContext, useContext, useRef, useState } from 'react';
import { ITrailItems } from '../interfaces/IStudentsWithGrades';
import InitialCreateApplication from '../views/MapaLauncher';

export type ILaunchersContextData = {
  classSelected: IClass | undefined;
  skillsToTest: { id: number; questions: number }[] | undefined;
  trails: ITrails[];
  setSelectedStudentsMiniMapa: (student: any) => void;
  selectedStudentsMiniMapa: InitialMiniMapaData;
  setClassSelected: (classSelected: IClass) => void;
  setSkillsToTest: (e: any) => void;
  setTrailId: (e: any) => void;
  trailId: string | undefined;
  setIsGlobalChecked: (e: any) => void;
  isGlobalChecked: boolean | undefined;
  trailItems: ITrailItems[];
  setTrailItems: (e: any) => void;
  setSelectedStudentsMapa: React.Dispatch<React.SetStateAction<InitialCreateApplication>>;
  selectedStudentsMapa: InitialCreateApplication;
  selectedStudentsMapaRef: React.MutableRefObject<InitialCreateApplication>;
  setCurrentClass: React.Dispatch<React.SetStateAction<IClass | undefined>>
  currentClass: IClass | undefined
};
interface InitialMiniMapaData {
  applications: any[];
}

const initialMiniMapaData = { applications: [] };

const LaunchersContext = createContext<ILaunchersContextData>({} as ILaunchersContextData);
const initialData = {
  applications: [],
};
const LaunchersProvider: React.FC = ({ children }) => {
  const [classSelected, setClassSelected] = useState<IClass>();
  const [currentClass, setCurrentClass] = useState<IClass>();
  const [isGlobalChecked, setIsGlobalChecked] = useState<boolean>(false);
  const [skillsToTest, setSkillsToTest] = useState<{ id: number; questions: number }[]>();
  const [selectedStudentsMiniMapa, setSelectedStudentsMiniMapa] = useState<InitialMiniMapaData>(initialMiniMapaData);
  const [trailId, setTrailId] = useState<string>();
  const [trailItems, setTrailItems] = useState<ITrailItems[]>([
    { name: 'Inglês', trailId: 9, checked: false },
    { name: 'Leitura', trailId: 2, checked: false },
    { name: 'Matemática', trailId: 3, checked: false },
    { name: 'Redação', trailId: 4, checked: false },
    { name: 'Hab. para vida', trailId: 5, checked: false },
    /*  { name: 'Todos', trailId: -1 }, */
  ]);
  const [selectedStudentsMapa, setSelectedStudentsMapa] = useState<InitialCreateApplication>(initialData);
  const selectedStudentsMapaRef = useRef<InitialCreateApplication>(initialData);

  const [trails] = useState<ITrails[]>([
    {
      name: 'Matemática',
      blocks: [],
      value: `3`,
    },
    {
      name: 'Leitura',
      blocks: [],
      value: `2`,
    },
    /*     {
      name: 'Redação',
      blocks: [],
      value: `4`,
    }, */
    {
      name: 'Inglês',
      blocks: [],
      value: `9`,
    },
  ]);

  return (
    <LaunchersContext.Provider
      value={{
        classSelected,
        setClassSelected,
        trails,
        skillsToTest,
        setSkillsToTest,
        selectedStudentsMiniMapa,
        setSelectedStudentsMiniMapa,
        trailId,
        setTrailId,
        isGlobalChecked,
        selectedStudentsMapaRef,
        setIsGlobalChecked,
        trailItems,
        setTrailItems,
        setSelectedStudentsMapa,
        selectedStudentsMapa,
        currentClass,
        setCurrentClass
      }}
    >
      {children}
    </LaunchersContext.Provider>
  );
};

function useLaunchers() {
  const context = useContext(LaunchersContext);

  if (!context) {
    throw new Error('useLaunchers must be used within a LaunchersProvider');
  }

  return context;
}

export { LaunchersProvider, useLaunchers };
