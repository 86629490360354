import styled from 'styled-components';

export const Container = styled.div`
  .textarea-container,
  .select-container,
  .input-container {
    margin: 1.5rem 0 0 0;
  }

  .select-container {
    min-width: 100%;
  }

  .select-container .select-option-text > span {
    text-align: center;
    margin: 0 15%;
  }

  .input-container > div input::placeholder {
    font-weight: 500;
    color: #c5cee0;
    font-size: 12px;
  }

  .accordion-header {
    background-color: #c7e2ff;
    justify-content: flex-start;
  }

  label {
    font-size: 12px !important;
  }

  .select-all-students-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1.5rem 0 1.5rem 0;
    color: #2e3a59;
    > div label {
    margin: 0;
    input + div {
      margin: 0;
      width: 25px;
      height: 25px;
    }
  }
  }
`;

interface IContainerAttachButtonsProps {
  isEdit?: boolean;
  containAttach?: boolean;
  containMedia?: boolean;
}

export const ContainerMedias = styled.div<IContainerAttachButtonsProps>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 45% 45%;
  margin: 5% 0;
`;

export const ContainerLinks = styled.div<IContainerAttachButtonsProps>`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const DowloadContainer = styled.div<IContainerAttachButtonsProps>`
  display: ${({ containMedia }) => (!containMedia ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  margin: 1.8rem;
`;

export const ContainerOccurencesCheckbox = styled.div`
  .custom-card {
> div {
      margin: 0;
    }
  }

> div label {
    margin: 0;
    input + div {
      margin: 0;
      width: 25px;
      height: 25px;
    }
  }

> div:first-child {
    flex: 1;
  }

  display: flex;
  margin: 2rem 0;
  column-gap: 10px;
;
`