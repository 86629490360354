import React, { useCallback, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { ProjectSchema } from '@modules/projects/schemas/Project';
import { useContracts } from '@modules/contracts/context/ContractContext';
import { formatContract } from '@modules/contracts/utils/formatContract';
import ContractForm from '@modules/contracts/components/ContractForm';

const ContractCreate: React.FC = () => {
  const dirPath = '/diretorio/contratos'

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { contractCurrent, clearContractCreate } = useContracts();

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    clearContractCreate();
    navigate(dirPath);
  }, [clearContractCreate, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!contractCurrent) return;
    const formatedBody = formatContract(contractCurrent);
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [contractCurrent]);

  const handleCreateContract = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !contractCurrent) return;
    setIsLoading(true);
    // const formatedBody = formatContract(contractCurrent);
    // const res = await new CreatePlaceService(signedUser.token).execute(formatedBody);
    const res = await true
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Contratos',
        description: 'Contrato criado com sucesso.',
      });
      clearContractCreate();
      return navigate(dirPath);
    }

    addToast({
      status: 'danger',
      title: 'Contratos',
      description: 'Não foi possível criar o Contrato.',
    });
  }, [addToast, clearContractCreate, navigate, setIsLoading, contractCurrent, signedUser]);

  return (
    <Fragment>
      <ContractForm
        headerTitle="Criar Contrato"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
        pathAddProjects="/diretorio/contratos/criar/selecionar/projeto"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar este Contrato?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateContract}
      />
    </Fragment>
  );
};

export default ContractCreate;
