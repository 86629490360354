import React, { useCallback, useMemo } from 'react';
import UserList from '@modules/users/components/UsersList';
import { IUser } from '@modules/users/interfaces/IUser';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import ISteering from '@modules/projects/interfaces/ISteering';

const SteeringParticipantAlicerceRelationCreate: React.FC = () => {
  const { steeringCurrent, setSteeringCurrent } = useProjects();

  const setSelectedItens = useCallback(
    (users: IUser[]) => {
      setSteeringCurrent((oldState: ISteering) => {
        return { ...oldState, lms_users: users };
      });
    },
    [setSteeringCurrent]
  );

  const selectedItens = useMemo(() => {
    if (!steeringCurrent) return;
    return steeringCurrent.lms_users;
  }, [steeringCurrent]);

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelect={'many'}
      handleOnClick={setSelectedItens}
    />
  );
};

export default SteeringParticipantAlicerceRelationCreate;
