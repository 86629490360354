import React, { createContext, useCallback, useContext, useState } from 'react';
import { IEvent } from '@modules/schedules/interfaces/IEvent';

export type ITaskContextData = {
  taskCurrent: IEvent;
  setTaskCurrent: (value: any) => void;
  clearTaskContextData: () => void;
};

const initialState = { name: '', age_groups: [], calendar_data: [] };

const TaskContext = createContext<ITaskContextData>({} as ITaskContextData);

const TaskProvider: React.FC = ({ children }) => {
  const [taskCurrent, setTaskCurrent] = useState<IEvent>(initialState);

  const clearTaskContextData = useCallback(() => {
    setTaskCurrent(initialState);
  }, []);

  return <TaskContext.Provider value={{ taskCurrent, setTaskCurrent, clearTaskContextData }}>{children}</TaskContext.Provider>;
};

function useTask() {
  const context = useContext(TaskContext);

  if (!context) {
    throw new Error('useTask must be used within a TaskProvider');
  }

  return context;
}

export { TaskProvider, useTask };
