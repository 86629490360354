import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import ContractList from '@modules/contracts/components/ContractList';
import IContract from '@modules/contracts/interfaces/IContract';
import ListContractsService, { IListContractBusinessOptions, IContractResponse } from '@modules/contracts/services/ListContractService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { getLocalTimezoneDate } from '@shared/utils/getLocalTimezoneDate';
import { ContractDefaultStylesContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { Spinner, Grid } from 'react-alicerce-components';
import IProject from '@modules/projects/interfaces/IProject';

const ProjectContractRelation: React.FC = () => {
  const contractBusinessFilter = useRef<IListContractBusinessOptions>({ end_date: { end: undefined, start: undefined } });
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const [contractList, setContractList] = useState<IContractResponse>();
  const [selectedContracts, setSelectedContracts] = useState<IContract[]>([]);
  const listContracts = useCallback(
    async (page = 0) => {
      const start = contractBusinessFilter.current.end_date?.start
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.start).startOfDay().toISOString()
        : undefined;

      const end = contractBusinessFilter.current.end_date?.end
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.end).endOfDay().toISOString()
        : undefined;

      if (!signedUser) return;
      setIsLoading(true);
      const contracts = await new ListContractsService(signedUser.token, newCancelToken()).execute('', {
        page,
        size: 10,
        ...contractBusinessFilter.current,
        end_date: { start, end }
      });
      if (contracts === 'canceling') return;
      setIsLoading(false);
      if (!contracts) return;
      setContractList(contracts);
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listContracts();
  }, [listContracts]);

  const { setProjectCurrent, projectCurrent } = useProjects();

  useEffect(() => {
    if (projectCurrent?.gif_contract_businesses) {
      setSelectedContracts(projectCurrent.gif_contract_businesses);
    }
  }, [projectCurrent]);

  const handleAddRelationContract = useCallback(
    (contractsSelected: IContract[] | undefined) => {
      setProjectCurrent((oldState: IProject) => ({
        ...oldState,
        gif_contract_businesses: contractsSelected
      }));
    },
    [setProjectCurrent]
  );

  const setSelectedItens = useCallback(
    (contracts: IContract[]) => {
      setSelectedContracts(contracts);
    },
    [setSelectedContracts]
  );

  return (
    <ContractDefaultStylesContainer>
      <HeaderDirectory
        title='Diretório de Contrato'
        menuKebab={{ tooltip: { view: 'ContractDirectory' }, menuBackHome: true }}
      />
      {isLoading && <Spinner fixed />}
      <DirectoryContainer footer={true}>
        <Grid container>
          <ContractList
            contractList={contractList}
            btnText="Adicionar Contrato"
            listContracts={listContracts}
            contractBusinessFilter={contractBusinessFilter}
            selectedItens={selectedContracts}
            setSelectedItens={setSelectedItens}
            handleOnClick={handleAddRelationContract}
            canSelectContract="many"
          />
        </Grid>
      </DirectoryContainer>
    </ContractDefaultStylesContainer>
  );
};

export { ProjectContractRelation };