import { Spinner, Button, useToast } from 'react-alicerce-components';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from './styles';
import Icon from 'react-eva-icons';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import PageFooter from '@shared/components/PageFooter';

import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import DeleteActivityService from '@modules/activities-library/services/DeleteActivityService';
import Divider from '@shared/components/Divider';
import GetActivityWithWorkGroupAndStudentsService from '@modules/activities-library/services/GetActivityWithWorkGroupAndStudentsService';
import { WorkGroupAndStudentsInfo } from '../../components/WorkGroupAndStudentsInfo';
import { IStudent } from '../../../students/interfaces/IStudent';
import CreatePlannedActivityService from '../../services/CreatePlannedActivityService';
import { ActivityLibraryForm } from '../../../activities-library/components/ActivityLibraryForm';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';
import { FavoriteToggleActivityService } from '../../../activities-library/services/FavoriteToggleActivityService';
import { IActivityLibrary } from '../../../activities-library/interfaces/IActivityLibrary';

const ClassPlannerActivityLibraryInfo: React.FC = () => {
  const { activityLibraryCurrent, setActivityLibraryCurrent } = useActivity();
  const { setAvailableStudents, setAvailableWorkGroups } = useScheduleGrid();
  const { activityId, classId, dailyClassId } = useParams<{ dailyClassId: string; activityId: string; classId: string }>();
  const [selectedStudents, setSelectedStudents] = useState<IStudent[]>([]);
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { isLoading, setIsLoading } = useGlobal();

  const deleteActivity = useCallback(async () => {
    setShowModal(false);
    setIsLoading(true);
    const res = await new DeleteActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Deletar Atividade.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Atividade',
      description: 'Atividade Deletada com Sucesso.',
    });
    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`);
    return;
  }, [activityLibraryCurrent.id, addToast, classId, dailyClassId, navigate, setIsLoading, signedUser]);

  const handleSaveActivityInClass = useCallback(async () => {
    if (!activityId) return console.log(`activityId is undefined`);
    if (!dailyClassId) return console.log(`dailyClassId is undefined`);

    const student_ids = selectedStudents.flatMap((student) => (!student.id ? [] : +student.id));

    setIsLoading(true);
    const res = await new CreatePlannedActivityService(signedUser?.token).execute({
      student_ids: student_ids,
      daily_class_id: Number(dailyClassId),
      activity_id: Number(activityId),
    });
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Planejar Atividade',
        description: 'Error ao Planejar Atividade.',
      });
      return;
    }

    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`);
  }, [activityId, addToast, classId, dailyClassId, navigate, selectedStudents, setIsLoading, signedUser]);

  const getActivityWithWorkGroupsAndStudents = useCallback(async () => {
    if (!activityId || !classId) return;
    setIsLoading(true);

    const res = await new GetActivityWithWorkGroupAndStudentsService(signedUser?.token).execute({
      activity_id: activityId,
      class_id: classId,
    });

    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Recuperar Atividade.',
      });
      navigate(-1);
      return;
    }

    if (!isMounted()) return;

    const { activity, students, workGroups } = res;
    setActivityLibraryCurrent(activity);
    setAvailableStudents(students);
    setAvailableWorkGroups(filterWorkedGroups(students, workGroups));
  }, [
    activityId,
    addToast,
    classId,
    isMounted,
    navigate,
    setActivityLibraryCurrent,
    setAvailableStudents,
    setAvailableWorkGroups,
    setIsLoading,
    signedUser,
  ]);

  const filterWorkedGroups = (students: IStudent[], workGroups: IWorkGroup[]) => {
    const studentsToRemove = students.map((s) => s.id);
    const filteredWorkGroups = workGroups.map((wp) => ({
      ...wp,
      students: wp.students?.filter((student) => studentsToRemove.includes(student.id)),
    }));

    return filteredWorkGroups;
  };
  const toggleFavoriteActivity = useCallback(async () => {
    setIsLoading(true);
    const res = await new FavoriteToggleActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Favoritar/Desfavoritar Atividade.',
      });
      return;
    }
    if (!isMounted()) return;
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      return { ...oldState, favorited: res.favorited };
    });
  }, [activityLibraryCurrent.id, addToast, isMounted, setActivityLibraryCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    getActivityWithWorkGroupsAndStudents();
  }, [getActivityWithWorkGroupsAndStudents]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`}
        menuKebab={{
          favorited: { favoritedCallback: () => toggleFavoriteActivity(), isFavorited: activityLibraryCurrent.favorited || false },
        }}
      />
      <DirectoryContainer footer={true}>
        {activityId === activityLibraryCurrent.id && <ActivityLibraryForm errors={[]} formAction="watching" />}

        <Divider width="100%" />

        <WorkGroupAndStudentsInfo selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents} formAction="editing" />
        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() =>
              navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${activityLibraryCurrent.id}/clonar`)
            }
            className="mochileiro-color"
          >
            Clonar Atividade
          </Button>
          <Button disabled={!!!selectedStudents.length} transform="none" shaded onClick={handleSaveActivityInClass} className="mochileiro-color">
            Adicionar na Bandeja
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Deseja Excluir Atividade?  "
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={deleteActivity}
        subTitle={'Você só pode excluir esta atividade pois ela está no seu banco pessoal e ainda não foi usada em nenhum Planejador de Aula.'}
      />
    </Container>
  );
};

export { ClassPlannerActivityLibraryInfo };
