import { styled } from "react-alicerce-components";

export const Container = styled.div`
  .pagination-content .pagination-content-icon {
    background-color: #25BBAB;
  }
`;

export const WrapperContent = styled.div`
  .custom-card > div {
    margin: 0 !important;
  }

  .input-container {
    margin-bottom: 45px;
    div {
      border-color: #c5cee0 !important;
    }

    input::placeholder {
      font-weight: 500;
      color: #C5CEE0;
    } 
  }

  .checkbox-container {
      margin: 0;
      
      label {
        margin: 0;
      }

      input + div {
        width: 30px;
        height: 30px;
        margin: 0;
        background: rgba(143, 155, 179, 0.16);
      }
    }
`;

export const ContainerSelectAll = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > label {
    font-weight: bold;
    font-size: 14px;
    color: #2e3a59;
  }

`;

export const ContainerCard = styled.div`
  display: flex;
  gap: 1rem;
  margin: var(--card-distance);

  & > div:first-child {
    flex: 1;
  }
`;
