import { styled } from 'react-alicerce-components';

export const RemoveOptionWrapperContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;

  .input-container {
    flex: 1;
    margin: 0rem 0rem 0rem 0rem;
  }

  .textarea-container {
    margin-top: 0;
  }

  .remove {
    background: red;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
