import React, { useCallback } from 'react';
import Icon from 'react-eva-icons';

import { Input } from 'react-alicerce-components';

import { Container, DeleteIconContainer } from './styles';

export interface ILinkAttachProps {
  link: string;
  canDelete?: boolean;
  handleOnDelete?: () => void;
  handleOnChange: (newValue: string) => void;
}

const LinkAttach: React.FC<ILinkAttachProps> = ({ link, handleOnChange, handleOnDelete, canDelete = false }) => {
  const handleDelete = useCallback(() => {
    if (canDelete && handleOnDelete) {
      handleOnDelete();
    }
  }, [canDelete, handleOnDelete]);

  return (
    <Container canDelete={canDelete}>
      <Input onChange={(event) => handleOnChange(event.target.value)} name={link} defaultValue={link} disabled={!canDelete} />
      {canDelete && (
        <DeleteIconContainer onClick={handleDelete}>
          <Icon name="close" />
        </DeleteIconContainer>
      )}
    </Container>
  );
};

export default LinkAttach;
