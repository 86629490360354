import React from 'react';
import { Checkbox } from 'react-alicerce-components';
import { BsCheckLg } from 'react-icons/bs';
import { ClassAttendeesConatainer, ContainerAttendanceRetroactiveCheckbox, ContainerCards, Label } from './styles';

import { IAttendance } from '@modules/attendances/interfaces/IAttendance';
import StudentCard from '@modules/students/components/StudentCard';
import UserCard from '@modules/users/components/UserCard';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';

type ClassAttendeesProps = {
  attendances: (IAttendance & { id: number })[];
  handleOnClickOnSelectAllLmsUsers: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnClickOnSelectAllStudents: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnToggleAttendance: (attendanceId: number, presence: boolean) => void;
};

export const ClassAttendees: React.FC<ClassAttendeesProps> = (props) => {
  const { attendances, handleOnClickOnSelectAllLmsUsers, handleOnClickOnSelectAllStudents, handleOnToggleAttendance } = props;
  console.log('atte', attendances)
  return (
    <ClassAttendeesConatainer>
      <Label>PARTICIPANTES DA AULA</Label>

      <AccordionInfoItem
        status="quaternary"
        title={`Líderes (${attendances.reduce((previousValue, currentValue) => (currentValue.lms_user_id ? previousValue + 1 : previousValue), 0)})`}
      >
        <ContainerCards>
          <Label>Selecionar Todos</Label>
          <Checkbox
            name="select-all"
            id="select-all"
            onChange={(event) => handleOnClickOnSelectAllLmsUsers(event)}
            icon={BsCheckLg}
            hideIconUncheck
          />
        </ContainerCards>
        {attendances.map(
          (at) =>
            at.lmsUser && (
              <ContainerAttendanceRetroactiveCheckbox key={`${at.lmsUser.id}-leader-${at.id}}`} className="attendance-checkbox">
                <UserCard
                  handleOnClick={() => handleOnToggleAttendance(at.id, !at.presence)}
                  userSelected={!!at.presence}
                  user={at.lmsUser}
                  canSelect
                />
                <Checkbox
                  name={`user-${at.lmsUser.id}-attendance-${at.id}`}
                  key={`user-${at.lmsUser.id}-attendance-${at.id}`}
                  id={`user-${at.lmsUser.id}-attendance-${at.id}`}
                  icon={BsCheckLg}
                  hideIconUncheck
                  checked={!!at.presence}
                  onChange={() => handleOnToggleAttendance(at.id, !at.presence)}
                />
              </ContainerAttendanceRetroactiveCheckbox>
            )
        )}
      </AccordionInfoItem>

      <AccordionInfoItem
        status="quaternary"
        title={`Alunos (${attendances.reduce((previousValue, currentValue) => (currentValue.student_id ? previousValue + 1 : previousValue), 0)})`}
      >
        <ContainerCards>
          <Label>Selecionar Todos</Label>
          <Checkbox
            name="select-all-st"
            id="select-all-st"
            onChange={(event) => handleOnClickOnSelectAllStudents(event)}
            icon={BsCheckLg}
            hideIconUncheck
          />
        </ContainerCards>
        {attendances.map(
          (at) =>
            at.student && (
              <ContainerAttendanceRetroactiveCheckbox key={`${at.student.id}-student-${at.id}-algo}`} className="attendance-checkbox">
                <StudentCard
                  student={at.student}
                  canSelect
                  studentSelected={!!at.presence}
                  handleOnClick={() => handleOnToggleAttendance(at.id, !at.presence)}
                />
                <Checkbox
                  name={`student-${at.student.id}-attendance-${at.id}`}
                  key={`student-${at.student.id}-attendance-${at.id}`}
                  id={`student-${at.student.id}-attendance-${at.id}`}
                  icon={BsCheckLg}
                  hideIconUncheck
                  checked={!!at.presence}
                  onChange={() => handleOnToggleAttendance(at.id, !at.presence)}
                />
              </ContainerAttendanceRetroactiveCheckbox>
            )
        )}
      </AccordionInfoItem>
    </ClassAttendeesConatainer>
  );
};
