import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { P, useToast } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import Divider from '@shared/components/Divider';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';
import VerticalBarChart from '@shared/components/CustomRecharts/charts/VerticalBarChart';

import { IndicatorsLayout as Layout } from '@modules/dashboards/components/IndicatorsLayout';
import SprintSelect, { ISprintData } from '@modules/dashboards/components/SprintSelect';
import { formatResource } from '@modules/dashboards/utils/formatResource';
import GetLearningMiniMapaService from '@modules/dashboards/services/GetLearningMiniMapaService';
import useAuth from '@shared/store/auth/hook';
import { formatLearningMiniMapa, IFormattedLearningMiniMapa } from '@modules/dashboards/utils/formatLearningMiniMapa';

import { Container, Subtitle } from './styles';

const LearningIndicator: React.FC = () => {
    const { signedUser } = useAuth();
    const { addToast } = useToast();
    const { setIsLoading } = useGlobal();

    const { resource, resourceId } = useParams()
    const [dashData, setDashData] = useState<IFormattedLearningMiniMapa>();
    const [sprintData, setSprintData] = useState<ISprintData>();

    const laodDashData = useCallback(async (sprintId?: number) => {
        if (!resourceId || !Number(resourceId) || !resource) return;
        const formattedResource = formatResource(resource);
        if (!formattedResource) return console.log(`invalid resource`);

        let options = { [`${formattedResource}_id`]: Number(resourceId) }

        if (sprintId) options = { ...options, sprintId }

        setIsLoading(true);
        const res = await new GetLearningMiniMapaService(signedUser?.token).execute(options);
        setIsLoading(false);

        if (!res) {
            addToast({
                status: 'danger',
                title: 'Aprendizagem Mini MAPA',
                description: 'Error ao Recuperar Aprendizagem Mini MAPA.',
            });
            return;
        }

        setSprintData({ ...res.sprint, nextSprintId: res?.nextSprintId, previousSprintId: res.previousSprintId } as ISprintData)
        setDashData(formatLearningMiniMapa(res));
    }, [addToast, resource, resourceId, setIsLoading, signedUser]);

    useEffect(() => {
        laodDashData();
    }, [laodDashData]);

    return (
        <Container>
            <Layout openTab={1}>

                {sprintData && (
                    <SprintSelect
                        sprintData={sprintData}
                        handleOnChangeSprint={laodDashData}
                    />
                )}

                <Divider className='divider' width='100%' />

                <P fontWeight={800} color='#8F9BB3'>RESULTADOS DOS MINIMAPAS</P>

                {dashData?.periods.map((data, i) => (
                    <AccordionInfoItem key={`accordion-${i}`} title={data.name}>
                        <VerticalBarChart
                            leftLabel='Range de Notas'
                            bottomLabel='Quantidade de Aplicações'
                            data={data.barChartData}
                            barsConfig={[{ dataKey: 'percent', color: '#C7E2FF' }]}
                            isPercent={false}
                            height={42}
                            showYAxis
                            dataKey=''
                        />
                        <Subtitle>Meta Conquistada:</Subtitle>

                        <DonutChart
                            data={data.donutChartData.data}
                            contentCenter={data.donutChartData.total}
                            bottomLabel='Legenda das Notas'
                            showPercentOnParts
                            legendType='square'
                        />
                    </AccordionInfoItem>
                ))}

            </Layout>
        </Container>
    );
}

export default LearningIndicator;