import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .input-container {
    margin: 1.5rem 0 0 0;

    > div input::placeholder {
      font-weight: 500;
      color: #C5CEE0;
    }
  }

`;

export const Comment = styled.div`
  background: #EDF1F7;
  border: 1px solid #C5CEE0;
  border-radius: 5px;
  font-size: 12;
  font-weight: 600;
  color: #2E3A59;
  padding: 12px;
  margin: 15px 0;
`;

export const Footer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-content-icon {
    background-color: #8F9BB3 !important;
    
    i svg {
        fill: #FFF !important;
    }
  }
`;