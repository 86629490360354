import React from 'react';
import { ILegend } from '../index';

import { Container, Item } from './styles';

export interface SquareProps {
    items: ILegend[];
}

const Square: React.FC<SquareProps> = ({ items }) => {
    return (
        <Container>
            {items.map((item, i) => <Item key={i} color={item.color} >{item.name}</Item>)}
        </Container>
    )
}

export default Square;