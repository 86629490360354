import React from 'react';

import { P } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';

import { IStudentApplication } from '@modules/applications/services/ListStudentApplicationFromClassService';

import { Container, FooterContainer, AgeGroup, Status } from './styles';

type IStudentApplicationCardProps = ICustomCardProps & {
  application?: IStudentApplication;
  statusNumber?: number;
};

const StudentApplicationCard: React.FC<IStudentApplicationCardProps> = ({ application, status = 'basic', statusNumber = 500, ...rest }) => {
  return (
    <Container status={status} statusNumber={statusNumber}>
      <CustomCard
        status={status}
        statusFooter={status}
        header={<AgeGroup>{application?.age_group}</AgeGroup>}
        footer={
          <FooterContainer>
            <Status value={application?.applications_pending || 0} color="#F9514C">
              Não Feito
            </Status>

            <Status value={application?.applications_ongoing || 0} color="#FFD74E">
              Em Progresso
            </Status>

            <Status value={application?.applications_finalized || 0} color="#6DCC1F">
              Feito
            </Status>
          </FooterContainer>
        }
        {...rest}
      >
        <P fontSize={14}>{application?.name}</P>
      </CustomCard>
    </Container>
  );
};

export default StudentApplicationCard;
