import React from 'react';
import { Grid, GridName, CategoryStatus, GridsContainer, ActiveContainer, CategoriesContainer, MarkCategory } from './styles';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { getDay } from '@shared/utils/getDay';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { IPeriod } from '@modules/schedules/interfaces/IPeriod';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import { HeadlightType } from '@modules/projects/schemas/Headlight/partials/HeadlightType';

type GridsProps = {
  sprintHeadlights?: IHeadlight[];
  sprint: ISprint;
  headlightType?: HeadlightType;
};

type MarkCategoryData = {
  title: string;
  date: string;
  status: ElementStatus | string;
  formatDate?: (date: string) => string;
};

const renderMarkCategory = (date: string, startDate: string, endDate: string, data: Omit<MarkCategoryData, 'date'>) => {
  const { title, status, formatDate } = data;

  if (date && date >= startDate && date <= endDate) {
    const formattedDate = formatDate ? formatDate(date) : getDay(date);

    return (
      <MarkCategory status={status}>
        <GridName className="grid-name" style={{ backgroundColor: 'transparent' }}>
          <h6>{title}</h6>
          <p>{formattedDate}</p>
        </GridName>
      </MarkCategory>
    );
  }
  return null;
};

export const Grids: React.FC<GridsProps> = ({ sprintHeadlights, sprint, headlightType }) => {
  const { currentPeriod, setCurrentPeriod } = useHeadlights();
  const { projectCurrent } = useProjects();

  const changePeriodSelected = (period: IPeriod) => {
    setCurrentPeriod(period);
  };

  const reportDate = String(projectCurrent?.reports.find((r) => new Date(r.created_at) <= new Date(sprint.end_date))?.created_at);
  const steeringDate = String(projectCurrent?.steerings.find((s) => new Date(s.meeting_date) <= new Date(sprint.end_date))?.meeting_date);

  const formatedDate = (date: string) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <GridsContainer className="grids-container">
      {sprint?.periods?.map((period, gridIndex) => {
        const startDate = period?.start_date ?? new Date().toISOString();
        const endDate = period?.end_date ?? new Date().toISOString();

        return (
          <Grid id={`grid-${gridIndex}`} key={`grid-${gridIndex}`} className="grid">
            {
              <ActiveContainer
                className="active-container"
                periodSelected={period.id === currentPeriod?.id}
                onClick={() => changePeriodSelected(period)}
              >
                <GridName className="grid-name">
                  <h6>{period?.name}</h6>
                  <p>{getDay(period.start_date)}</p>
                  <p>{getDay(period.end_date)}</p>
                </GridName>

                <CategoriesContainer className="categories-container">
                  {sprintHeadlights
                    ?.find((h) => h.period_id === period.id)
                    ?.data?.map((d, i) => (
                      <CategoryStatus key={`category-${i}`} status={d.status}>
                        {d.category}
                      </CategoryStatus>
                    ))}

                  {headlightType === 'execution' &&
                    renderMarkCategory(reportDate, startDate, endDate, {
                      title: 'Report',
                      status: 'primary',
                    })}

                  {renderMarkCategory(steeringDate, startDate, endDate, {
                    title: 'Steering',
                    status: 'secondary',
                    formatDate: formatedDate,
                  })}
                </CategoriesContainer>
              </ActiveContainer>
            }
          </Grid>
        );
      })}
    </GridsContainer>
  );
};
