import { IMapaFilter } from '../views/MapaLauncher';

interface IFormattedMapaFilter {
  status?: string[];
  trail_id?: number[];
  first_application?: boolean;
}

export const formatMapaFilter = (mapaFilter: IMapaFilter): IFormattedMapaFilter => {
  return {
    status: mapaFilter.status && mapaFilter.status.length > 0 ? mapaFilter.status : undefined,
    trail_id: mapaFilter.trails && mapaFilter.trails.length > 0 ? mapaFilter.trails : undefined,
    first_application: !!mapaFilter.first_application || mapaFilter.first_application === false ? mapaFilter.first_application : undefined,
  };
};
