import React from 'react'
import { H3, H6 } from 'react-alicerce-components'
import { RadioOption } from '../../../../shared/components/RadioOption'
import { AccordionSkillsHeaderContainer, CustomAccordionArrow } from './styles'

type AccordionSkillsHeaderProps = { currentMetaBlockName?: string } & { isOpen?: boolean }

export const AccordionSkillsHeader = (props: AccordionSkillsHeaderProps) => {
    const { isOpen, currentMetaBlockName } = props
    return (
        <AccordionSkillsHeaderContainer>
            <div className='block-wrapper'>
                <H3 className="current-block">Competências do Bloco de Domínio</H3>
                <RadioOption
                    name={"subtitle"}
                    hideRadio
                    radioWidth="fit-content"
                    optionColor={"#222B45"}
                    round
                    id={'subtitle'}
                >
                    <H6 className="current-block">{currentMetaBlockName}</H6>
                </RadioOption>
            </div>
            <CustomAccordionArrow isOpen={isOpen} />
        </AccordionSkillsHeaderContainer>
    )
}

