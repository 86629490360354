import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import layoutConfig from '@config/layout';

interface ISideBarProps {
  isShow: boolean | undefined;
}

interface IMenuProps {
  active?: boolean;
}

export const Container = styled.aside<ISideBarProps>`
  position: absolute;
  top: 10%;
  left: 12px;
  z-index: 3;
`;

export const ContainerSideBar = styled.div<ISideBarProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 5;
  left: ${({ isShow }) => (isShow ? 0 : `-${layoutConfig.sideBarWidth}px`)};
  width: ${() => `${layoutConfig.sideBarWidth}px`};
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
  max-height: 100vh;
  background: #fff;
  border: 1px solid #dedede;
`;

export const ToggleButton = styled.button<ISideBarProps>`
  display: none;
  z-index: 5;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;

  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;

  svg {
    width: 24px;
    height: 24px;
    fill: #fff;
  }
`;

export const Group = styled.div`
  position: relative;
  padding: 25px;
  padding-top: 0;

  .project-version-front {
    position: absolute;
    right: 5px;
    bottom: 12px;
    font-size: 12px;
  }
  .project-version-back {
    position: absolute;
    right: 5px;
    bottom: -5px;
    font-size: 12px;
  }

  .close-sidebar {
    display: flex;
    justify-content: flex-end;

    i svg {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }

  .info-wrapper {
    margin-left: 8px;
  }

  .info-wrapper h6 {
    font-size: 13px;
    color: #333333;
  }

  .info-wrapper p {
    color: #828282;
  }

  a {
    text-decoration: none;
  }

  .group-item-body {
    span {
      font-size: 13px;
      font-weight: 700;
      margin-left: 12px;
    }
  }
  .group-item-footer {
    margin-top: 12px;
    span {
      font-size: 16px;
      font-weight: 700;
      margin-left: 12px;
    }
  }
`;

export const WrapperSideBar = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: 100%;

  div .group-header-sidebar {
    padding: 25px 15px 0px 25px;
  }

  .group-footer-sidebar {
    padding: 0;
    margin-bottom: 5px;
  }
`;

export const GroupItems = styled.ul`
  a:first-child div {
    border-top: none;
  }
  a:first-child li {
    margin-top: 0.8rem;
  }
`;

export const GroupItem = styled.div<IMenuProps>`
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 1.7rem 0;
  max-height: 30px;
  cursor: pointer;
  border-top: 2px solid #e4e9f2;

  i {
    display: flex;
  }

  > div {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    i,
    svg {
      width: 22px;
      height: 22px;
    }
  }

  span {
    color: ${({ active }) => (active ? themeGet('colors.primary800') : '#222B45')};
    font-size: 12px;
    margin-left: 4px;
    flex: 1;
    font-weight: 600;
  }

  &:hover {
    span {
      color: ${({ active }) => (active ? themeGet('colors.primary800') : themeGet('colors.primary900'))};
    }
  }

  a.user-info {
    width: 100%;

    &:hover {
      svg {
        fill: ${themeGet('colors.primary800')};
      }
    }
  }

  .button-leave-lms {
    background-color: rgb(219, 44, 102);
    margin-left: 25px;
  }
`;
