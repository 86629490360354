import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IPlace from '@modules/places/interfaces/IPlace';
import IListOptions from '../../../shared/interfaces/IListOptions';

export interface IPlaceReponse extends IPaginatedResponse {
  items: IPlace[];
}

export interface IListPlaceOptions extends IListOptions {
  parent_id?: number;
  admin_id?: number;
  favorited?: boolean;
  fixed?: boolean;
  categories?: string[];
  virtual_place?: boolean;
  group_id?: string
}
class ListPlacesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListPlaceOptions): Promise<IPlaceReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);

      const res = await this.api.get(`/place?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListPlacesService;
