import React, { Fragment, useCallback, useState } from 'react';
import Layout from '@modules/home/components/Layout';
import PageFooter from '@shared/components/PageFooter';
import { Button, Input, H6 } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import { Container } from './styles';
import NavigationBox from '@shared/components/NavigationBox';
import Divider from '@shared/components/Divider';
import { useNavigate } from 'react-router-dom';
import ListPlacesService, { IPlaceReponse } from '@modules/places/services/ListPlacesService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import ListPlacesUserManagesService from '@modules/places/services/ListPlacesUserManagesService';
import ControllerNavigationBox from '@shared/components/ControllerNavigationBox';
import PlaceRender from '@modules/places/components/PlaceRender';

export type ICategoryPlaces = 'region' | 'city' | 'location' | 'room';

const MyPlaces: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [places, setPlaces] = useState<IPlaceReponse>();
  const [selectedCategory, setSelectedCategory] = useState<ICategoryPlaces>();
  const [openedDiv, setOpenedDiv] = useState<number>();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const listFavoritedPlaces = useCallback(
    async (search = '', page = 0, category?: ICategoryPlaces) => {
      if (!signedUser) return false;
      setIsLoading(true);
      const categories = category ? [category] : selectedCategory ? [selectedCategory] : undefined;
      const res = await new ListPlacesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        favorited: true,
        categories,
      });
      if (res === 'canceling') return false;
      setIsLoading(false);
      if (!res) return false;
      isMounted() && setPlaces(res);
      return !!res.items.length;
    },
    [isMounted, newCancelToken, selectedCategory, setIsLoading, signedUser]
  );

  const listPlacesUserManages = useCallback(
    async (search = '', page = 0, category?: ICategoryPlaces) => {
      if (!signedUser) return false;
      setIsLoading(true);
      const categories = category ? [category] : selectedCategory ? [selectedCategory] : undefined;
      const res = await new ListPlacesUserManagesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        categories,
      });
      if (res === 'canceling') return false;
      setIsLoading(false);
      if (!res) return false;
      isMounted() && setPlaces(res);
      return !!res.items.length;
    },
    [isMounted, newCancelToken, selectedCategory, setIsLoading, signedUser]
  );

  const handleOnClick = useCallback(
    async (isOpen: boolean, divToShow: number, category: ICategoryPlaces) => {
      if (isOpen) {
        setOpenedDiv(undefined);
        return true;
      }

      setPlaces(undefined);

      if (divToShow === 1) {
        const favorited = await listFavoritedPlaces('', 0, category);
        if (!favorited) return false;
      } else if (divToShow === 2) {
        const managed = await listPlacesUserManages('', 0, category);
        if (!managed) return false;
      }
      setOpenedDiv(divToShow);
      setSelectedCategory(category);
      return true;
    },
    [listFavoritedPlaces, listPlacesUserManages]
  );

  const filterPlaces = useCallback(
    async (search: string, page = 0) => {
      if (openedDiv === 1) {
        await listFavoritedPlaces(search, page);
      } else if (openedDiv === 2) {
        await listPlacesUserManages(search, page);
      }
    },
    [listFavoritedPlaces, listPlacesUserManages, openedDiv]
  );

  return (
    <Fragment>
      <Layout openTab={3} footer={true}>
        <Container>
          {openedDiv && (
            <div style={{ marginBottom: '2rem' }}>
              <Input
                placeholder="Buscar"
                name="search"
                end={<Icon name="funnel" fill="#8F9BB3" />}
                onChange={(event) => filterPlaces(event.target.value)}
              />
            </div>
          )}

          {(openedDiv === 1 || openedDiv === undefined) && (
            <>
              <H6 fontWeight={800} status="primary" textAlign="center">
                LOCAIS FAVORITOS
              </H6>
              <ControllerNavigationBox>
                <NavigationBox title="CIDADES" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'city')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
                <NavigationBox title="REGIÕES" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'region')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
                <NavigationBox title="UNIDADES" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'location')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
                <NavigationBox title="SALAS" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'room')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
              </ControllerNavigationBox>
            </>
          )}

          {openedDiv === undefined && <Divider />}

          {(openedDiv === 2 || openedDiv === undefined) && (
            <>
              <H6 fontWeight={800} status="primary" textAlign="center">
                LOCAIS QUE ADMINISTRO
              </H6>
              <ControllerNavigationBox>
                <NavigationBox title="CIDADES" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'city')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
                <NavigationBox title="REGIÕES" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'region')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
                <NavigationBox title="UNIDADES" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'location')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
                <NavigationBox title="SALAS" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'room')}>
                  <PlaceRender places={places} filterPlaces={filterPlaces} />
                </NavigationBox>
              </ControllerNavigationBox>
            </>
          )}
        </Container>

        <PageFooter>
          <Button transform="none" status="primary" shaded onClick={() => navigate('/diretorio/locais')}>
            Diretório de Locais
          </Button>
        </PageFooter>
      </Layout>
    </Fragment>
  );
};

export default MyPlaces;
