import styled from 'styled-components';

export const Container = styled.div`
  .textarea-container,
  .input-container {
    textarea::placeholder,
    > div input::placeholder {
      font-family: 'Open Sans Semibold';
      font-weight: 500;
      color: #c5cee0;
    }
  }

  h6 {
    color: #8f9bb3;
  }

  .error-dates {
    position: relative;
    color: #c53030;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--primary-font);
    position: relative;
    top: -20px;
    grid-column: 1/3;
  }
`;
