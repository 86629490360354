import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Select, Spinner } from 'react-alicerce-components';
import { Container } from './styles';
import Icon from 'react-eva-icons';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { ITrail } from '@shared/interfaces/ITrail';
import { IBlock } from '@shared/interfaces/IBlock';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';

import { ListAllMapa4ModulesService } from '@shared/services/ListAllMapa4ModulesService';
import { formatOptions } from '@modules/activities-library/utils/formatOptions';
import { ILevel } from '@modules/activities-library/interfaces/ILevel';
import { IFilterSkills } from '@modules/activities-library/components/SkillsList';

interface IFilterSkillsProps {
  appliedFilters: IFilterSkills;
  callbackAppliedFilters: (filterToApply: IFilterSkills) => void;
}

const FilterSkills: React.FC<IFilterSkillsProps> = ({ appliedFilters, callbackAppliedFilters }) => {
  const [insideFilter, setInsideFilter] = useState<IFilterSkills>(appliedFilters);
  const [allTrails, setAllTrails] = useState<ITrail[]>();
  const [allBlocks, setAllBlocks] = useState<IBlock[]>();
  const [allLevels, setAllLevels] = useState<ILevel[]>();
  const refTrail = React.createRef<any>();
  const refLevel = React.createRef<any>();
  const refBlock = React.createRef<any>();
  const refDesc = useRef<HTMLInputElement>(null);
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const isMounted = useIsMounted();

  const listFilterSkills = useCallback(async () => {
    if (!signedUser) return;
    setIsLoading(true);
    const res = await new ListAllMapa4ModulesService(signedUser.token).execute();
    setIsLoading(false);
    if (!res) return;
    if (isMounted()) {
      setAllTrails(res.trails);
      setAllLevels(res.levels);
      setAllBlocks(res.blocks);
    }
  }, [isMounted, setIsLoading, signedUser]);

  useEffect(() => {
    listFilterSkills();
  }, [listFilterSkills]);

  useEffect(() => {
    setInsideFilter(appliedFilters);
  }, [appliedFilters]);

  const getBlockOptionsSelect = useCallback(() => {
    if (!insideFilter.level_id || !insideFilter.trail_id) return;
    const filtred = allBlocks?.filter((ab) => ab.level_id === insideFilter.level_id && ab.trail_id === insideFilter.trail_id);
    return formatOptions(filtred);
  }, [allBlocks, insideFilter.level_id, insideFilter.trail_id]);

  const handleSelectChange = useCallback(
    (keyName: string, keyValue: any | null) => {
      if (!keyValue) return;
      if (keyName === 'trail_id') {
        refLevel.current.reset();
        refBlock.current.reset();
        if (refDesc.current) refDesc.current.value = '';
        setInsideFilter((oldState) => {
          return { ...oldState, level_id: undefined, block_id: undefined, query: undefined };
        });
      }
      if (keyName === 'level_id') {
        refBlock.current.reset();
        if (refDesc.current) refDesc.current.value = '';
        setInsideFilter((oldState) => {
          return { ...oldState, block_id: undefined, query: undefined };
        });
      }
      setInsideFilter((oldState) => {
        return { ...oldState, [keyName]: keyValue.value };
      });
    },
    [refBlock, refLevel]
  );

  const handleInputChange = useCallback((keyName: string, keyValue: string | null) => {
    setInsideFilter((oldState) => {
      return { ...oldState, [keyName]: keyValue };
    });
  }, []);

  const handleReset = useCallback(() => {
    if (refDesc.current) refDesc.current.value = '';
    refTrail.current.reset();
    refLevel.current.reset();
    refBlock.current.reset();
    setInsideFilter((oldState) => {
      return { ...oldState, trail_id: undefined, level_id: undefined, block_id: undefined, query: undefined };
    });
  }, [refBlock, refLevel, refTrail]);

  const handleApplyFilters = useCallback(() => {
    callbackAppliedFilters(insideFilter);
  }, [callbackAppliedFilters, insideFilter]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <AccordionInfoItem status="info" title="Filtrar Competências" colorHeader="#DAE3F6">
        <div className="container-accordion-content">
          <div className="teste">
            <Select
              label="Trilha"
              iconName="arrow-down"
              placeholder="Selecione"
              fullWidth
              ref={refTrail}
              options={formatOptions(allTrails)}
              handleSelectChange={(option) => handleSelectChange('trail_id', option)}
            />

            <Select
              label="Nivel"
              iconName="arrow-down"
              placeholder="Selecione"
              fullWidth
              ref={refLevel}
              options={formatOptions(allLevels)}
              disabled={!!!insideFilter.trail_id}
              handleSelectChange={(option) => handleSelectChange('level_id', option)}
            />

            <Select
              label="Bloco"
              iconName="arrow-down"
              placeholder="Selecione"
              fullWidth
              ref={refBlock}
              options={getBlockOptionsSelect()}
              disabled={!!!insideFilter.level_id}
              handleSelectChange={(option) => handleSelectChange('block_id', option)}
            />

            <Input
              name="query"
              label="Buscar por Descrição"
              disabled={!!!insideFilter.block_id}
              onChange={(event) => handleInputChange('query', event.target.value)}
              inputRef={refDesc}
              end={<Icon name="search-outline" fill="#8F9BB3" />}
            />
          </div>

          <Divider width="100%" />

          <div className="container-buttons-filter">
            <Button transform="none" noBorder shaded onClick={handleReset} disabled={!!!insideFilter.trail_id?.length}>
              Resetar Filtros
            </Button>
            <Button transform="none" noBorder shaded onClick={handleApplyFilters}>
              Aplicar Filtros
            </Button>
          </div>
        </div>
      </AccordionInfoItem>
    </Container>
  );
};

export { FilterSkills };
