import styled from "styled-components";
import { ElementStatus } from "react-alicerce-components/dist/shared/theme/colors";
import themeGet from "@styled-system/theme-get";

interface IContainerProps {
  status?: ElementStatus;
  statusNumber?: number;
  canSelect?: boolean;
  isSelected?:boolean;
}

export const Time = styled.div`
  border-radius: 5px;
  font-size: 12px;
  color: #222B45;
  font-weight: bold;
  height: 100%;
  padding: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
`;

export const Name = styled.div`
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  height: 100%;
  padding: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 .4rem;
  border: 2px solid transparent;
`;

export const Container = styled.div<IContainerProps>`
  > div {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    input + div {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background: rgba(143, 155, 179, 0.16);
    }
  }

  ${Time} {
    background-color: ${({status, statusNumber }) =>  themeGet(`colors.${status}${statusNumber}`)};

    ${props => props.isSelected && `
      background-color: #FF708D;
      color: #fff;
      border-color: #FF3D71;
    `}
  }

  ${Name} {
    background-color: ${({status, statusNumber = 300}) => themeGet(`colors.${status}${statusNumber+300}`)};
    
    ${props => props.isSelected && `
      background-color: #FF708D;
      color: #fff;
      border-color: #FF3D71;
    `}
  }
`;

