import React, { useCallback } from 'react';

import { Button, Grid, Label, Pagination } from 'react-alicerce-components';

import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { CheckboxTagItemChange, CheckboxTagsGroup } from '@shared/components/CheckboxTag';
import IContract from '@modules/contracts/interfaces/IContract';

import ContractCard from '../ContractCard';

import { InputDateContainer, ContractDefaultStylesContainer } from '../ContractDefaultStyles/styles';
import { IContractResponse, IListContractBusinessOptions } from '@modules/contracts/services/ListContractService';
import { useNavigate } from 'react-router-dom';
import { CONTRACT_BUSINESS_STATUS__FILTER_OPTIONS_VALUES_CONSTRAINTS } from '@modules/projects/schemas/Project/partials/ProjectStatus';
import PageFooter from '@shared/components/PageFooter';

interface IContractList {
  pathToReturn?: string;
  pathRedirect?: string;
  canSelectContract?: 'one' | 'many';
  btnText?: string;
  selectedItens?: IContract[];
  listOptions?: any;
  isAdvancedFilterDisabled?: boolean;
  handleOnClick?: (placeCurrent: any) => void;
  pathStateOptions?: { state: any };
  setSelectedItens?: (placeCurrent: IContract[]) => void;
  contractList?: IContractResponse | undefined //@Todo retirar esse opcional
  listContracts?: (page?: any) => Promise<void> //@Todo retirar esse opcional
  contractBusinessFilter?: React.MutableRefObject<IListContractBusinessOptions>
}

const ContractList: React.FC<IContractList> = ({
  isAdvancedFilterDisabled,
  contractList,
  btnText,
  listContracts,
  pathStateOptions,
  contractBusinessFilter,
  selectedItens,
  canSelectContract,
  pathRedirect,
  handleOnClick,
  setSelectedItens
}) => {
  const navigate = useNavigate()
  const handleResetFilter = () => {
    if (contractBusinessFilter) {
      contractBusinessFilter.current = { end_date: { end: undefined, start: undefined } };
      listContracts?.();
    }
  };

  const checkSelectedGroups = useCallback((selectedItens: IContract[], clickedItem: IContract): false | IContract[] => {
    const found = selectedItens.findIndex((p) => p.id === clickedItem.id);
    if (found === -1) return false;
    selectedItens.splice(found, 1);
    return selectedItens;
  }, []);

  const handleClickContractCard = useCallback(
    (contract: IContract) => {
      if (!canSelectContract) navigate(`/contrato/${contract.id}/informacoes`);

      if (canSelectContract === 'one' && selectedItens && setSelectedItens) {
        if (selectedItens[0]?.id === contract.id) return setSelectedItens([]); // desmarca o grupo
        setSelectedItens([contract]);
      } else if (canSelectContract === 'many' && selectedItens && setSelectedItens) {
        const res = checkSelectedGroups(selectedItens, contract);
        if (!res) return setSelectedItens([...selectedItens, contract]);
        setSelectedItens([...res]);
      }
    },
    [canSelectContract, checkSelectedGroups, navigate, selectedItens, setSelectedItens]
  );
  const handleClickBtn = useCallback(() => {
    if (canSelectContract === 'one' && selectedItens) {
      handleOnClick && handleOnClick(selectedItens[0]);
    } else if (canSelectContract === 'many' && selectedItens) {
      handleOnClick && handleOnClick(selectedItens);
    }
    pathRedirect ? navigate(pathRedirect, pathStateOptions) : navigate(-1);
  }, [canSelectContract, handleOnClick, navigate, pathRedirect, pathStateOptions, selectedItens]);

  const handleOnChangeCheckbox = (state: CheckboxTagItemChange) => {
    const currentArrayAux = contractBusinessFilter?.current.status || []
    if (contractBusinessFilter) {
      const foundIndex = currentArrayAux?.findIndex((item) => item === state.name)
      if (foundIndex === -1) {
        currentArrayAux?.push(state.name)
        contractBusinessFilter.current.status = currentArrayAux
      } else {
        contractBusinessFilter.current.status = currentArrayAux?.filter((selectedItem) => selectedItem !== state.name);
      }
    }
  }

  const handleChangeDate = (value: string, fieldName: string) => {
    if (contractBusinessFilter?.current) {
      contractBusinessFilter.current.end_date[fieldName] = value
    }
  }

  return (
    <ContractDefaultStylesContainer>
      <Grid container>
        <AdvancedFilter
          disabled={isAdvancedFilterDisabled}
          applyFilterButtonBackground="#8D593B"
          handleResetFilter={handleResetFilter}
          handleApplyFilter={() => listContracts?.()}
        >
          <Grid row mt4>
            <Label mb={2}>Status do Contrato</Label>
            <CheckboxTagsGroup
              handleOnChange={handleOnChangeCheckbox}
              disabled={false}
              itens={CONTRACT_BUSINESS_STATUS__FILTER_OPTIONS_VALUES_CONSTRAINTS}
            />
          </Grid>

          <Grid row mt4>
            <Label mb={2}>Duração do Contrato Entre</Label>
            <InputDateContainer>
              <input
                type="date"
                name="start"
                onChange={(event) => handleChangeDate(event.target.value, 'start')}
              />
              <label>até</label>
              <input
                type="date"
                name="end"
                onChange={(event) => handleChangeDate(event.target.value, 'end')}
              />
            </InputDateContainer>
          </Grid>
        </AdvancedFilter>

        <Grid row>
          {contractList?.items?.map((contract) => (
            <Grid sm={12} md={4} key={contract.id}>
              <ContractCard
                handleOnClick={handleClickContractCard}
                contract={contract}
                contractsSelected={selectedItens}
                canSelect={!!canSelectContract}
              />
            </Grid>
          ))}
        </Grid>
        {contractList &&
          <Pagination
            totalOfPages={contractList?.totalPages}
            currentPage={contractList?.currentPage}
            callbackGetListData={async (_, page) => listContracts?.(page)}
          />}

        {btnText &&
          <PageFooter>
            <Button transform="none" status="tertiary" shaded onClick={handleClickBtn}>
              {btnText}
            </Button>
          </PageFooter>
        }
      </Grid>
    </ContractDefaultStylesContainer>
  );
};

export default ContractList;
