import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IEvent } from '@modules/schedules/interfaces/IEvent';
import IListOptions from '../../../shared/interfaces/IListOptions';

export interface IEventResponse extends IPaginatedResponse {
  items: IEvent[];
}

interface IListEventsOptions extends IListOptions {
  week_days?: string[];
  start_date?: string;
  end_date?: string;
}
class ListEventsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListEventsOptions): Promise<IEventResponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);

      const res = await this.api.get(`/event?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListEventsService;
