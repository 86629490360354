import { ILearningMiniMapa } from "../interfaces/ILearningMiniMapa";

interface IChartData {
  name: string, 
  percent: number | string;
  color?: string;
}

interface IPeriod {
  name: string;
  barChartData: IChartData[];
  donutChartData: {
    total: string;
    data: IChartData[]
  };
}

export interface IFormattedLearningMiniMapa {
  periods: IPeriod[];
}  

export const formatLearningMiniMapa = (data: ILearningMiniMapa): IFormattedLearningMiniMapa => {
  const colors = [
    '#FF3D71',
    '#0095FF',
    '#FFAA00',
    '#00D68F'
  ];

  const periods:IPeriod[] = data.period.map((dp) => {

    const barChartData: IChartData[] = [];
    dp.bar_chart.data.forEach((d) => barChartData.push({
      name: `${d.min}-${d.max}`,
      percent: d.count
    }));

    const data: IChartData[] = [];
    const total = dp.pie_chart.data.reduce((a, b) => ({count: a.count + b.count}), { count: 0 });

    dp.pie_chart.data.forEach((d, i) => data.push({
      name: `${d.min}-${d.max}`,
      percent: !d.count ?  '' : ((d.count * 100) / total.count),
      color: colors[i]
    }));

    return {
      name: dp.name,
      barChartData,
      donutChartData: {total: `${total.count}`, data}
    }

  });

  return { periods };
}

