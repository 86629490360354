import { styled } from 'react-alicerce-components';
import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

export const WrapperStudentForm = styled.div`
  .parent-render-container {
    .input-container {
      margin: 8px 0 0 0;
    }
  }
  .select-container {
    max-width: unset;
    margin-bottom: 1rem;
    .select-options-list {
      position: relative;
    }
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.8rem;
  max-width: 100%;
  margin: 1.5rem 0rem;

  .input-container {
    margin: 0;
  }

  > div:first-child {
    width: 70%;
  }
  > div:last-child {
    width: 25%;
  }
`;

export const CheckboxWrapper = styled.div`
  > div {
    margin: 1rem 0 1rem 0;
    label div {
      margin: 0 5px 0 0;
    }
  }
`;

export const WrapperCard = styled.div<{ status: ElementStatus }>`
  margin-top: 10px;
  color: var(--black);

  .card-header,
  .card-footer {
    padding: 0;
  }

  .card {
    padding: 10px;
    background-color: ${({ status }) => themeGet(`colors.${status}600`)};
  }

  .card.header {
    border-radius: 5px 5px 0px 0px;
  }
  .card-body {
    font-weight: 800;
    font-size: 14px;
    background-color: ${({ status }) => themeGet(`colors.${status}300`)};
  }
  .card.footer {
    border-radius: 0px 0px 5px 5px;
  }

  .tag {
    color: inherit;
    border-radius: 4px;
    margin-bottom: 5px;
  }
`;

export const WrapperSubscription = styled.div`
  display: flex;
`;
