import styled from "styled-components";
import { ElementStatus } from "react-alicerce-components/dist/shared/theme/colors";
import themeGet from "@styled-system/theme-get";

export const Time = styled.div``;

export const Name = styled.div``;


export const Container = styled.div<{ status:ElementStatus }>`
    height: 40px;
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
    padding: 0 .5rem;

    ${Time} {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: ${({status}) => themeGet(`colors.${status}100`)};
        width: 25%;
        font-size: 12px;
        font-weight: 700;
    }

    ${Name} {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 75%;
        background-color: ${({status}) => themeGet(`colors.${status}400`)};
        color: ${({status}) => themeGet(`colors.${status}Contrast`)};
        font-size: 14px;
        font-weight: 800;
    }
`;

