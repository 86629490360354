import { ISprint } from '../interfaces/ISprint';

export const formatSprintsForFullCalendar = (sprints: ISprint[]): any => {
  return sprints.map((s: ISprint) => ({
    id: s.id,
    title: s.name,
    description: s.description,
    type: 'sprint',
    data: s,
    start: new Date(`${s.start_date}T00:00`).toISOString(),
    end: new Date(`${s.end_date}T00:00`).toISOString(),
    allDay: true,
  }));
};
