import { IZdpBlockStudent } from '@modules/subscriptions/interfaces/IZdpBlockStudent';
import React, { useEffect, useRef, useState } from 'react';

import { Container, InitialLevel } from './styles';

export interface ISubscriptionCardTrails {
  isOpen?: boolean;
  zdpBlockStudent: IZdpBlockStudent[]
  handleOnClick: (value: any) => void;
}

const SubscriptionCardTrails: React.FC<ISubscriptionCardTrails> = ({ isOpen, zdpBlockStudent, handleOnClick }) => {
  const [heightState, setHeightState] = useState<string>('0px');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setHeightState(`max-content`);
    } else {
      setHeightState('0px');
    }
  }, [isOpen]);

  return (
    <Container ref={ref} heightState={heightState} onClick={handleOnClick} >
      {zdpBlockStudent.map((block) => (
        <div className="trail" key={block.trail.id}>
          <div className="trail-content">
            <div className="trail-name">{block.trail.name}</div>
            <div className="trail-level">
              {block.block && block.block.color_variation && (
                <InitialLevel initialColor={block.block.level.color} colorVariation={block.block.color_variation}>
                  {block.block.name}
                </InitialLevel>
              )}

              {(!block.block) && (
                <h1 className='empty-block'>Sem MAPA realizado</h1>
              ) }
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};

export { SubscriptionCardTrails };
