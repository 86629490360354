import { styled } from 'react-alicerce-components';

export const ContainerCheck = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.563rem;
  }
`;
