import { Input, Label } from 'react-alicerce-components';
import React from 'react';

import { DateFilterContainer } from './styles';
import { getLocalTimezoneDate } from '@shared/utils/getLocalTimezoneDate';

interface IDateRangeFilter {
  filters: React.MutableRefObject<any>;
  fieldName?: string;
}

const DateRangeFilter: React.FC<IDateRangeFilter> = ({ filters, fieldName = 'created_at' }) => {
  const handleChangeFilters = (value: string, nestedFieldName: string) => {
    const inputDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const MIN_YEAR = 999;

    const isValid = inputDateRegex.test(value) && +value.split('-')[0] > MIN_YEAR;

    let formatedValue = '';

    if (value && isValid)
      formatedValue = (nestedFieldName === 'start' ? getLocalTimezoneDate(value).startOfDay() : getLocalTimezoneDate(value).endOfDay()).toISOString();

    filters.current[fieldName] = { ...filters.current[fieldName], [nestedFieldName]: formatedValue };
  };

  return (
    <DateFilterContainer>
      <Input name="start" type="date" onChange={(event) => handleChangeFilters(event.target.value, event.target.name)} required />
      <Label>Até</Label>
      <Input name="end" type="date" onChange={(event) => handleChangeFilters(event.target.value, event.target.name)} required />
    </DateFilterContainer>
  );
};

export default DateRangeFilter;
