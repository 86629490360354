import React, { useState } from 'react';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';

import VerticalBarChart from '@shared/components/CustomRecharts/charts/VerticalBarChart';
import Dot from '@shared/components/CustomRecharts/elements/Legend/Dot';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';

const HabilidadesParaVidaGroupEvolutionDetails: React.FC = () => {

    const [barChartData] = useState([
        { name: 'Sempre', percent1: 100, percent2: 47 },
        { name: 'Quase Sempre', percent1: 85, percent2: 33 },
        { name: 'Quase Nunca', percent1: 51, percent2: 77 },
        { name: 'Nunca', percent1: 96, percent2: 47 },
        { name: 'Sem Identificação', percent1: 100, percent2: 47 },
    ]);

    const [barsChartConfig] = useState([
        { name: '01/02/2021', dataKey: 'percent1', color: '#C7E2FF' },
        { name: '02/03/2021', dataKey: 'percent2', color: '#42AAFF' },
    ]);

    const [defaultProps] = useState({
        data: barChartData,
        barsConfig: barsChartConfig,
        height: 100,
        margin: { top: 30, right: 50, left: 20, bottom: 30 },
        showYAxis: true
    });

    return (
        <Container>
            <HeaderDirectory title='Pensar' />
            <div>
                <Dot items={barsChartConfig} />
                <AccordionInfoItem title='Competência 1'>
                    <VerticalBarChart {...defaultProps} />
                </AccordionInfoItem>

                <AccordionInfoItem title='Competência 2'>
                    <VerticalBarChart {...defaultProps} />
                </AccordionInfoItem>

                <AccordionInfoItem title='Competência 3'>
                    <VerticalBarChart {...defaultProps} />
                </AccordionInfoItem>

                <AccordionInfoItem title='Competência 4'>
                    <VerticalBarChart {...defaultProps} />
                </AccordionInfoItem>

                <AccordionInfoItem title='Competência 5'>
                    <VerticalBarChart {...defaultProps} />
                </AccordionInfoItem>

            </div>
        </Container>
    );
}

export default HabilidadesParaVidaGroupEvolutionDetails;