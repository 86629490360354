import React from 'react';

import { Button, Spinner, Tab, Tabs } from 'react-alicerce-components';

import { hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { ProjectReportInfoFragment } from './partials/ProjectReportInfoFragment';
import { ProjectReportAttachments } from './partials/ProjectReportAttachments';

import { ProjectDefaultStylesContainer } from '../ProjectDefaultStyles/styles';
import { ProjectReportFormContainer } from './styles';

interface ProjectReportFormProps {
  headerTitle: string;
  headerPathToReturn?: string;
  errors?: IInputErrorsFormat[];
  disabled?: boolean;
  editing?: boolean;
  footer?: JSX.Element;
  handleCancel?: () => void;
  deleteCallback?: () => void;
  handleConfirmButton?: () => Promise<void>;
}

const ProjectReportForm: React.FC<ProjectReportFormProps> = ({
  errors,
  footer,
  disabled,
  editing,
  headerTitle,
  headerPathToReturn,
  handleCancel,
  deleteCallback,
  handleConfirmButton,
}) => {
  const { isLoading } = useGlobal();

  const projectReportDescriptionHasError = hasError(['description'], errors);
  const badgeProps = (hasError: boolean) => {
    return {
      badge: hasError,
      badgeSide: hasError && 'left',
      badgeColor: hasError && '#fff',
      badgeContent: hasError && '!',
      badgeBg: hasError && '#c53030',
    };
  };

  return (
    <ProjectDefaultStylesContainer>
      <ProjectReportFormContainer>
        {isLoading && <Spinner />}
        <HeaderDirectory title={headerTitle} pathToReturn={headerPathToReturn} onClickReturn={handleCancel} menuKebab={{ deleteCallback }} />
        <DirectoryContainer footer={true}>
          <CustomTabs footer>
            <Tabs horizontalScroll>
              <Tab title="Informação" {...badgeProps(projectReportDescriptionHasError)}>
                <ProjectReportInfoFragment editing={editing} errors={errors} disabled={disabled} />
              </Tab>
              <Tab title="Anexos">
                <ProjectReportAttachments disabled={disabled} />
              </Tab>
            </Tabs>
          </CustomTabs>
          <PageFooter>
            {footer}
            {!footer && (
              <>
                <Button className="btn-cancel" transform="none" onClick={handleCancel} shaded outline>
                  Cancelar
                </Button>
                <Button transform="none" shaded noBorder onClick={handleConfirmButton}>
                  Salvar
                </Button>
              </>
            )}
          </PageFooter>
        </DirectoryContainer>
      </ProjectReportFormContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectReportForm;
