import { styled } from 'react-alicerce-components';

export const Container = styled.div`
   .main-navbar {
    height: 130px;
  }

  [role='tablist-container'] {
    background: linear-gradient(45deg, #328da1 0%, #25bbab 100%);
  }

  [role='tabpanel'] {
    height: calc(100vh - 130px);
    border-radius: 0;
  }
`;
