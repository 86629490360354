import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Input, P } from 'react-alicerce-components';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';

import { HabilidadesParaVidaGroupLayout as Layout } from '@modules/dashboards/components/HabilidadesParaVidaGroupLayout';
import VerticalBarChart from '@shared/components/CustomRecharts/charts/VerticalBarChart';
import Dot from '@shared/components/CustomRecharts/elements/Legend/Dot';

import { Container } from './styles';

const HabilidadesParaVidaGroupEvolution: React.FC = () => {
    const navigate = useNavigate();

    const [barChartData] = useState([
        { name: 'Sempre', percent1: 100, percent2: 47 },
        { name: 'Quase Sempre', percent1: 85, percent2: 33 },
        { name: 'Quase Nunca', percent1: 51, percent2: 77 },
        { name: 'Nunca', percent1: 96, percent2: 47 },
        { name: 'Sem Identificação', percent1: 100, percent2: 47 },
    ]);

    const [barsChartConfig] = useState([
        { name: 'Data 1', dataKey: 'percent1', color: '#C7E2FF' },
        { name: 'Data 2', dataKey: 'percent2', color: '#42AAFF' },
    ]);

    const [defaultProps] = useState({
        data: barChartData,
        barsConfig: barsChartConfig,
        height: 100,
        margin: { top: 30, right: 50, left: 20, bottom: 30 },
        showYAxis: true
    });

    return (
        <Layout openTab={1}>
            <Container>
                <P fontWeight={800} color='#8F9BB3'>COMPARE AS DATAS</P>

                <div
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        margin: '1rem 0',
                    }}
                >
                    <Input
                        label="Data 1"
                        name="start_date"
                        type="date"
                        useTooltipError={false}
                    />

                    <Input
                        label="Data 2"
                        name="end_date"
                        type="date"
                        useTooltipError={false}
                    />
                </div>

                <Dot items={barsChartConfig} />
                <AccordionInfoItem title='Pensar'>
                    <VerticalBarChart {...defaultProps} />
                    <Button
                        status='basic'
                        transform="none"
                        color='#fff'
                        statusNumber={600}
                        noBorder
                        shaded
                        onClick={() => navigate(`/dash/habilidadesparavida/grupo/evolucao/pensar/detalhes`)}
                    >
                        Ver Detalhes
                    </Button>
                </AccordionInfoItem>

                <AccordionInfoItem title='Sentir'>
                    <VerticalBarChart {...defaultProps} />
                    <Button
                        status='basic'
                        transform="none"
                        color='#fff'
                        statusNumber={600}
                        noBorder
                        shaded
                        onClick={() => navigate(`/dash/habilidadesparavida/grupo/evolucao/pensar/detalhes`)}
                    >
                        Ver Detalhes
                    </Button>
                </AccordionInfoItem>

                <AccordionInfoItem title='Autoconhecer'>
                    <VerticalBarChart {...defaultProps} />
                    <Button
                        status='basic'
                        transform="none"
                        color='#fff'
                        statusNumber={600}
                        noBorder
                        shaded
                        onClick={() => navigate(`/dash/habilidadesparavida/grupo/evolucao/pensar/detalhes`)}
                    >
                        Ver Detalhes
                    </Button>
                </AccordionInfoItem>

                <AccordionInfoItem title='Retribuir e Contribuir'>
                    <VerticalBarChart {...defaultProps} />
                    <Button
                        status='basic'
                        transform="none"
                        color='#fff'
                        statusNumber={600}
                        noBorder
                        shaded
                        onClick={() => navigate(`/dash/habilidadesparavida/grupo/evolucao/pensar/detalhes`)}
                    >
                        Ver Detalhes
                    </Button>
                </AccordionInfoItem>

                <AccordionInfoItem title='Autosuperar'>
                    <VerticalBarChart {...defaultProps} />
                    <Button
                        status='basic'
                        transform="none"
                        color='#fff'
                        statusNumber={600}
                        noBorder
                        shaded
                        onClick={() => navigate(`/dash/habilidadesparavida/grupo/evolucao/pensar/detalhes`)}
                    >
                        Ver Detalhes
                    </Button>
                </AccordionInfoItem>

            </Container>
        </Layout>
    );
}

export default HabilidadesParaVidaGroupEvolution;