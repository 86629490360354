import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import OccurrenceForm from '@modules/occurrences/components/OccurrenceForm';
import { Container } from './styles';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { occurrenceSchema } from '@modules/occurrences/utils/occurrenceSchema';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { formatOccurrence } from '@modules/occurrences/utils/formatOccurrence';
import ConfirmModal from '@shared/components/ConfirmModal';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { DateHelper } from '@shared/helpers/DateHelper';

const OccurrenceCreate: React.FC = () => {
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { occurrenceCurrent, contextSelected, clearOccurrenceCurrent, createOccurrence } = useOccurrences();
  const [occurrenceValidated, setOccurrenceValidated] = useState<IOccurrence>();
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  const canSaveOccurence = useCallback(async () => {
    const times = occurrenceCurrent?.time ? occurrenceCurrent.time.split(':').map(Number) : undefined;

    const formatedBody = formatOccurrence(occurrenceCurrent || {}) as IOccurrence;
    let formErros = await beforeSubmitForm(formatedBody, occurrenceSchema);
    console.log(formatedBody, 'create occurrence body para backend');

    if(times && times[0] > 23) {
      formErros = [...formErros, { name: 'time', message: 'O valor das horas deve estar entre 00 e 23. (Ex: 23h59)'}]
    }

    if(times && times[1] > 59) {
      formErros = [...formErros, { name: 'time', message: 'O valor dos minutos deve estar entre 00 e 59. (Ex: 23h59)'}]
    }

    if (!formatedBody.group_id && !formatedBody.place_id && !formatedBody.class_id) {
      formErros = [...formErros, { name: 'context', message: 'É necessário adicionar pelo menos uma Turma, Grupo ou Lugar' }];
    }

    if (formatedBody.date && formatedBody.date > DateHelper.getUTCDate(new Date())) {
      formErros = [...formErros, { name: 'date', message: 'A combinação do Dia e Hora não devem ser maior do que tempo atual' }];
    }

    if (formErros.length === 0) {
      setOccurrenceValidated(formatedBody);
      setShowModal(true);
    }

    setErrors(formErros);
  }, [occurrenceCurrent]);

  const handleCreateOccurrence = useCallback(
    async (occurrenceBody: IOccurrence) => {
      setShowModal(false);
      await createOccurrence(occurrenceBody);
      if (contextSelected) {
        navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/pendentes`);
      } else {
        navigate(`/ocorrencias/contexto/turmas`);
      }
    },
    [createOccurrence, contextSelected, navigate]
  );

  const handleCancelCreation = useCallback(() => {
    clearOccurrenceCurrent();
    navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/pendentes`);
  }, [clearOccurrenceCurrent, contextSelected, navigate]);

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Ocorrências"
        backgroundHeader="linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)"
        onClickReturn={handleCancelCreation}
      />

      <OccurrenceForm errors={errors} handleOnFinish={handleCreateOccurrence} />

      <PageFooter>
        <ButtonGradientOutline onClick={handleCancelCreation} status="occurrence" textColor="#fff">
          Cancelar
        </ButtonGradientOutline>

        <Button onClick={canSaveOccurence} style={{ background: '#568CF7', border: 'none', whiteSpace: 'break-spaces' }} transform="none" shaded>
          Salvar
        </Button>
      </PageFooter>

      {occurrenceValidated && (
        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showModal}
          closeCallback={() => setShowModal(!showModal)}
          title="Deseja criar a Ocorrencia?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={() => handleCreateOccurrence(occurrenceValidated)}
        />
      )}
    </Container>
  );
};

export default OccurrenceCreate;
