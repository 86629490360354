import React from 'react';
import Icon from 'react-eva-icons';

import { Button, IButtonProps } from 'react-alicerce-components';

import { Container, Content } from './styles';

type IButtonIconProps = IButtonProps & {
    iconName?: string;
    iconColor?: string;
    content?: string;
    bgColor?: string;
    width?: string;
    height?: string;
    iconSize?: string;
}

const ButtonIcon: React.FC<IButtonIconProps> = ({
    iconName,
    content,
    bgColor,
    width = '60px',
    height = '75px',
    iconSize = '60px',
    shaded = true,
    noBorder = true,
    iconColor = '#fff',
    ...rest
}) => {
    return (
        <Container
            bgColor={bgColor}
            width={width}
            height={height}
            iconSize={iconSize}
        >
            <Button {...rest} shaded={shaded} noBorder={noBorder} >
                {iconName && <Icon name={iconName} fill={iconColor} />}
                <Content>{content}</Content>
            </Button>
        </Container>
    );
}

export default ButtonIcon;