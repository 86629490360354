import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { ISprintResponse } from '../interfaces/ISprintResponse';
import IListOptions from '@shared/interfaces/IListOptions';

interface IListSprintsPlanOptions extends IListOptions {
  initial_date?: string;
  final_date?: string;
}
class ListSprintsPlanService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListSprintsPlanOptions): Promise<ISprintResponse | false | 'canceling'> {
    let searchParams = objectToQuery({ query, ...options });

    try {
      const res = await this.api.get(`/sprint/planned?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListSprintsPlanService;
