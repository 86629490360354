import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IStudent } from '../../students/interfaces/IStudent';
import { IWorkGroup } from '../../work-group/interfaces/IWorkGroup';
import { IActivityLibrary } from '../interfaces/IActivityLibrary';

type IGetActivityWithWorkGroupAndStudentsService = {
  class_id: string;
  activity_id: string;
};

export interface IGetActivityWithWorkGroupAndStudentsServiceResponse {
  activity: IActivityLibrary;
  students: IStudent[];
  workGroups: IWorkGroup[];
}

class GetActivityWithWorkGroupAndStudentsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options: IGetActivityWithWorkGroupAndStudentsService): Promise<IGetActivityWithWorkGroupAndStudentsServiceResponse | false> {
    try {
      let searchParams = objectToQuery(options);
      const res = await this.api.get(`/daily-class-plan/activity?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetActivityWithWorkGroupAndStudentsService;
