import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import { ICalendarData } from '@modules/classes/interfaces/IClass';

export interface IWeekdayItems {
  id: string;
  name: string;
  calendar_data: ICalendarData;
}

export interface IWeekdayResponse extends IPaginatedResponse {
  items: IWeekdayItems[];
}

class ListClassesByWeekdayService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IQueryOptions): Promise<IWeekdayResponse | false> {
    try {
      let searchParams = objectToQuery({
        query,
        order: 'class.name',
        ...options,
      });

      const res = await this.api.get(`/class/weekday?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListClassesByWeekdayService;
