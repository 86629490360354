import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Grid, ISelectOption, Select } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import HeadlightCard from '@modules/projects/components/HeadlightCard';
import { Container } from './styles';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import ListSprintsService from '@modules/schedules/services/ListSprintsService';
import { IPeriod } from '@modules/schedules/interfaces/IPeriod';
import { getDay } from '@shared/utils/getDay';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import ListHeadlightsService from '@modules/projects/services/Headlight/ListHeadlightsService';
import { useParams } from 'react-router-dom';
import { useProjects } from '@modules/projects/context/ProjectsContext';

interface HeadlightsHistoryProps { }

declare type IAction = 'forward' | 'back';

const HeadlightsHistory: React.FC<HeadlightsHistoryProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { id: projectId } = useParams<{ id: string }>();
  const { projectCurrent } = useProjects();
  const [sprints, setSprints] = useState<(ISprint)[]>([]);
  const [currentSprintPeriods, setCurrentSprintPeriods] = useState<(IPeriod)[]>([]);
  const [currentSprint, setCurrentSprint] = useState<(ISprint)>();
  const [currentPeriod, setCurrentPeriod] = useState<(IPeriod)>();
  const [hasNextPeriod, setHasNextPeriod] = useState(true);
  const [hasPreviousPeriod, setHasPreviousPeriod] = useState(true);
  const [currentHeadlight, setCurrentHeadlight] = useState<(IHeadlight)>();

  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();

  const listSprints = useCallback(async (scheduleId) => {
    if (!signedUser) return;

    setIsLoading(true);

    const sprints = await new ListSprintsService(signedUser.token).execute('', { schedule_id: scheduleId, periods: true });

    const isCancel = sprints === 'canceling';
    const isFail = !sprints;

    if (isFail || isCancel) return;

    setSprints([...sprints.items]);

    setIsLoading(false);
  }, [setIsLoading, signedUser]);

  useEffect(() => {
    listSprints(projectCurrent?.schedule_id)
  }, [listSprints, projectCurrent]);

  const handleSelectChange = useCallback(
    (option, sprints: ISprint[]) => {
      const selectedSprint = sprints.find(sprint => sprint.id === option.value);
      setCurrentSprint(selectedSprint);
      const periods = selectedSprint && selectedSprint.periods;
      if (periods) {
        setCurrentSprintPeriods(periods);
        setCurrentPeriod(periods[0]);
      }
    },
    [setCurrentSprintPeriods, setCurrentPeriod]
  );

  const defaultSprintOptions = (sprints: ISprint[]): ISelectOption[] | undefined => {
    return sprints.map((sprint) => ({ name: sprint.name, value: String(sprint.id) }))
  };

  const changePeriod = useCallback((action: IAction): void => {
    const isForward = action === 'forward';

    const currentPeriodId = Number(currentPeriod?.id);
    let newPeriodId: number;

    if (isForward) {
      setHasPreviousPeriod(true);
      const hasNextPeriod = currentSprintPeriods.find(period => Number(period.id) === (currentPeriodId + 1));
      if (!hasNextPeriod) {
        setHasNextPeriod(false);
        return;
      } else {
        newPeriodId = currentPeriodId + 1;
      }

    } else {
      setHasNextPeriod(true);
      const hasPreviousPeriod = currentSprintPeriods.find(period => Number(period.id) === (currentPeriodId - 1));
      if (!hasPreviousPeriod) {
        setHasPreviousPeriod(false);
        return;
      } else {
        newPeriodId = currentPeriodId - 1;
      }
    }

    const newPeriod = currentSprintPeriods.find(period => Number(period.id) === newPeriodId);
    setCurrentPeriod(newPeriod);

  }, [currentPeriod, currentSprintPeriods]);


  const getPeriod = useMemo(() => {
    const periodName = currentPeriod?.name;
    const startDate = currentPeriod?.start_date && getDay(currentPeriod.start_date);
    const endDate = currentPeriod?.end_date && getDay(currentPeriod.end_date);

    return `${periodName}: ${startDate} - ${endDate} `
  }, [currentPeriod]);

  const getHeadlight = useCallback(async (currentSprint, currentPeriod, projectId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (currentSprint && currentPeriod && projectId) {
      const headlight = await new ListHeadlightsService(signedUser.token).execute({ sprint_id: currentSprint.id, project_id: projectId, period_id: currentPeriod.id });
      const isCancel = headlight === 'canceling';
      const isFail = !headlight;

      if (isFail || isCancel) return;

      setCurrentHeadlight(headlight.items[0]);
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser]);


  useEffect(() => {
    getHeadlight(currentSprint, currentPeriod, projectId)
  }, [currentPeriod, currentSprint, getHeadlight, projectId]);

  return (
    <Container className='headlights-history-container' isOpen={isOpen}>
      <div className='headlights-history-header' onClick={() => setIsOpen((oldState) => !oldState)}>
        Histórico de Faróis
        <div className='styled-icon'>
          <Icon name='chevron-up-outline' />
        </div>
      </div>
      <div className='headlights-history-content'>
        <div>
          <Select
            label="Sprint:"
            iconName="arrow-down"
            placeholder="Selecione"
            fullWidth
            required
            handleSelectChange={(option) => handleSelectChange(option, sprints)}
            options={defaultSprintOptions(sprints)}
          />
          <div className='period-container'>
            <label className={`period-select-icon ${!hasPreviousPeriod ? 'disabled' : ''}`} onClick={() => changePeriod('back')}>
              <Icon name='arrow-ios-back' />
            </label>
            <span>
              {currentPeriod ? getPeriod : "Nenhum período correspondente"}
            </span>
            <label className={`period-select-icon ${!hasNextPeriod ? 'disabled' : ''}`} onClick={() => changePeriod('forward')}>
              <Icon name='arrow-ios-forward' />
            </label>
          </div>
          <Divider width='100%' />
          {currentHeadlight ?
            <>
              <AccordionInfoItem title='Diagnóstico'>
                {currentHeadlight.diagnostics}
              </AccordionInfoItem>
              <Divider width='100%' />
            </> :
            <Grid textCenter>Nenhum Farol correspondente para a sprint e período selecionados.</Grid>
          }
          {currentHeadlight && currentHeadlight.data?.map((d, i) => <HeadlightCard key={i} category={d.category} status={d.status} status_description={d.status_description} />)}
        </div>
      </div>
    </Container>
  );
};

export default HeadlightsHistory;


