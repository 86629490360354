import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { IDailyClassPlannComment } from '../interfaces/IDailyClassPlannComment';

export interface IDailyClassPlannCommentsReponse extends IPaginatedResponse {
  items: IDailyClassPlannComment[];
}

class ListDailyClassPlannedCommentsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options: Object): Promise<IDailyClassPlannCommentsReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ ...options });
      const res = await this.api.get(`/daily-class-plan/comment?${searchParams}`);
      return res.data.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListDailyClassPlannedCommentsService;
