import React, { useCallback } from 'react';

import {
  Container,
  Time,
  Content
} from './styles';

export interface IClassPlannerScheduleItem {
  id?: string | number;
  name?: string;
  start?: string;
  end?: string;
}

interface IClassPlannerScheduleItemProps {
  classPlannerSchedule?: IClassPlannerScheduleItem;
  contentBgColor?: string;
  timeBgColor?: string;
  handleOnClick?: (classPlannerSchedule: IClassPlannerScheduleItem) => void;
};

const ClassPlannerScheduleItem: React.FC<IClassPlannerScheduleItemProps> = ({
  classPlannerSchedule,
  handleOnClick,
  timeBgColor = '#E9D7F9',
  contentBgColor = '#B85EC4 '
}) => {

  const handleOnClickClass = useCallback(() => {
    if (handleOnClick && classPlannerSchedule) {
      handleOnClick({ ...classPlannerSchedule });
    }
  }, [handleOnClick, classPlannerSchedule]);

  return (
    <Container
      timeBgColor={timeBgColor}
      contentBgColor={contentBgColor}
      onClick={handleOnClickClass}
    >
      {classPlannerSchedule && (
        <div>
          <Time>
            {classPlannerSchedule.start} - {classPlannerSchedule.end}
          </Time>
          <Content>{classPlannerSchedule.name}</Content>
        </div>
      )}
    </Container>
  );
};

export default ClassPlannerScheduleItem;
