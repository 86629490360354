import React, { useCallback, useMemo, useState } from 'react';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useNavigate } from 'react-router-dom';
import GroupsSelection from '@shared/components/GroupsSelection';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { Button, Input, Spinner } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useWorkedHour } from '@modules/worked-hours/context/WorkedHourContext';

export const WorkedHoursGroupsRelation: React.FC = () => {
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [searchValue, setSearchValue] = useState<string>('');
  const { setWorkedHourCurrent } = useWorkedHour();
  const { isLoading } = useGlobal();
  const navigate = useNavigate();

  const handleClickBtn = useCallback(() => {
    setWorkedHourCurrent((oldState) => {
      return { ...oldState, group: selectedGroup };
    });
    navigate('/horas-trabalhadas/criar');
  }, [navigate, selectedGroup, setWorkedHourCurrent]);

  const selectedGroups = useMemo(() => {
    let aux = [] as IGroup[];
    if (selectedGroup) aux.push(selectedGroup);
    return aux;
  }, [selectedGroup]);

  return (
    <>
      <HeaderDirectory status="tertiary" title="Diretório de Grupos" />
      {isLoading && <Spinner />}
      <DirectoryContainer footer={true}>
        <div style={{ marginBottom: '1.5rem' }}>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
        <GroupsSelection searchValue={searchValue} handleOnClick={setSelectedGroup} canSelect={true} selectedGroups={selectedGroups} />
        <PageFooter>
          <Button transform="none" status="tertiary" shaded onClick={handleClickBtn}>
            Adicionar Grupo
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </>
  );
};
