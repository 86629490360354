import { Textarea } from 'react-alicerce-components';
import React, { Fragment, useCallback, useEffect } from 'react';

import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { IAwnsersString, IAwnsersStringParams, useSprint } from '@modules/sprint/context/SprintContext';
import { useParams } from 'react-router-dom';

interface SprintForm {
  errors: IInputErrorsFormat[];
  sprintPlan?: IAwnsersString[];
}

const QuestionPhrase = ['Quem eu vou ensinar?', 'O que eu vou ensinar?', 'Como eu vou ensinar?'];

const SprintForm: React.FC<SprintForm> = ({ errors, sprintPlan }) => {
  const { setSprintAnswersCurrent, sprintAnswersCurrent } = useSprint();
  const { classId } = useParams<{ classId: string }>();

  const handleArrayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;

      setSprintAnswersCurrent((oldState: IAwnsersStringParams) => {
          let aux = oldState;
          aux.plan[index].answer = inputValue;
          aux.plan[index].question = inputName;
          return { ...oldState };
        });
    },
    [setSprintAnswersCurrent]
  );

  useEffect(() => {
    setSprintAnswersCurrent((oldState: IAwnsersStringParams) => {
      return { ...oldState, class_id: classId };
    });
  }, [classId, setSprintAnswersCurrent]);

  console.log(`sprintAnswersCurrent >> `, sprintAnswersCurrent);
  return (
    <>
      {sprintPlan && (
        <Fragment>
          <Textarea name="answer" label={sprintPlan[0].question} value={sprintPlan[0].answer} rows={4} disabled />
          <Textarea name="answer" label={sprintPlan[1].question} value={sprintPlan[1].answer} rows={4} disabled />
          <Textarea name="answer" label={sprintPlan[2].question} value={sprintPlan[2].answer} rows={4} disabled />
        </Fragment>
      )}

      {!sprintPlan && !!sprintAnswersCurrent?.class_id && (
        <Fragment>
          <Textarea
            name={QuestionPhrase[0]}
            label={QuestionPhrase[0]}
            placeholder="Adicione Objetivo da Atividade"
            value={sprintAnswersCurrent?.plan[0].answer}
            rows={4}
            error={errorMessageBuilder('answer', errors)}
            onChange={(event) => handleArrayChange(event, 0)}
            useTooltipError={false}
          />

          <Textarea
            name={QuestionPhrase[1]}
            label={QuestionPhrase[1]}
            placeholder="Adicione Objetivo da Atividade"
            value={sprintAnswersCurrent?.plan[1].answer}
            rows={4}
            error={errorMessageBuilder('answer', errors)}
            onChange={(event) => handleArrayChange(event, 1)}
            useTooltipError={false}
          />

          <Textarea
            name={QuestionPhrase[2]}
            label={QuestionPhrase[2]}
            placeholder="Adicione Objetivo da Atividade"
            value={sprintAnswersCurrent?.plan[2].answer}
            rows={4}
            error={errorMessageBuilder('answer', errors)}
            onChange={(event) => handleArrayChange(event, 2)}
            useTooltipError={false}
          />
        </Fragment>
      )}
    </>
  );
};

export { SprintForm };
