import { EventInput } from '@fullcalendar/react';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';
import { IWeekDailyClasses } from '../services/ListDailyClassesPlanService';

export type FormatDailyClassForFullCalendar = {
  events: EventInput
  dailyClasses: IDailyClass[]
}

export const formatDailyClassForFullCalendar = (dailyClasses?: IWeekDailyClasses[]): FormatDailyClassForFullCalendar => {
  const formatted: FormatDailyClassForFullCalendar = {
    events: [],
    dailyClasses: []
  };

  if (dailyClasses) {
    dailyClasses
      .reduce((p, n) => p.concat(n.weekDailyClasses), [] as IDailyClass[])
      .reverse()
      .forEach((d) => {
        const newEvent = {
          id: d.id,
          data: `${d?.start_date}T${d.start_time}`,
          start: `${d?.start_date}T${d.start_time}`,
          end: `${d?.end_date}T${d.end_time}`,
          display: 'background'
        }
        formatted.events.push(newEvent)
        if (d.plan?.activities && d.plan?.activities?.length > 0) {
          d?.plan?.activities?.forEach((a) => {
            const newEvent = {
              id: a.id,
              title: a.activity_data.title,
              description: a.activity_data.description,
              data: `${d?.start_date}T${a.start_time}`,
              start: `${d?.start_date}T${a.start_time}`,
              end: `${d?.end_date}T${a.end_time}`,
              type: 'event',
            }
            formatted.events.push(newEvent)
          })
        }
        formatted.dailyClasses.push(d)
      });

  }

  return formatted
};
