import React, { useCallback, useMemo } from "react";
import { usePlaces } from "@modules/places/context/PlacesContext";
import UserList from "@modules/users/components/UsersList";
import { IUser } from "@modules/users/interfaces/IUser";

const PlaceUsersRelationCreate: React.FC = () => {
  const { setPlaceCurrent, placeCurrent } = usePlaces();

  const handleOnClick = useCallback(
    (users: IUser[]) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, admins: users };
      });
    },
    [setPlaceCurrent]
  );

  const selectedItens = useMemo(() => {
    return placeCurrent?.admins ? placeCurrent?.admins : [];
  }, [placeCurrent]);

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      pathRedirect="/diretorio/locais/criar"
      pathToReturn=""
      selectedItens={selectedItens}
      setSelectedItens={handleOnClick}
      canSelect={"many"}
      handleOnClick={handleOnClick}
    />
  );
};

export default PlaceUsersRelationCreate;
