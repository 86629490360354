import { css, styled } from 'react-alicerce-components';

export const Wrapper = styled.div<{ positionRight?: string; positionTop?: string; isAbsolute?: boolean }>`
  ${({ positionRight, positionTop, isAbsolute }) => css`
    background-color: #c5cee0;
    border-radius: 8px;
    position: ${isAbsolute ? 'absolute' : 'unset'};
    top: ${positionTop ? positionTop : '30px'};
    right: ${positionRight ? positionRight : '20px'};
    z-index: 5;
    cursor: pointer;

    i {
      display: flex;
      svg {
        fill: #2e3a59 !important;
        width: 25px;
        height: 25px;
      }
    }
  `}
`;
