import React from 'react';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { CardDirectoryContentContainer } from './styles';
import { translateString } from '@shared/utils/translateString';

interface ICardDirectory {
  cardInfo: IGroup;
}

const CardDirectoryContent: React.FC<ICardDirectory> = ({ cardInfo }) => {
  return (
    <CardDirectoryContentContainer>
      <div className="content">
        <span>Categoria do grupo: </span>
        <span className="content-bold">{translateString(cardInfo.category)}</span>
      </div>
    </CardDirectoryContentContainer>
  );
};

export default CardDirectoryContent;
