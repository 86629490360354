import themeGet from "@styled-system/theme-get";
import { ElementStatus } from "react-alicerce-components/dist/shared/theme/colors";
import styled from "styled-components";

export const Container = styled.div<{status?:ElementStatus; statusNumber?: number}>`
    position: relative;
    display: flex;

    label {
        margin-top: 1.8rem;
    }

    .custom-card {
        width: 100%;
        margin: 0;
    }

    .card-footer,
    .card-header {
        height: 50px;
        padding: 12px 10px;
        background-color: ${({ status, statusNumber }) => themeGet(`colors.${status}${statusNumber}`)};
    }

    .card-body {
        padding: 20px;

        p {
            font-size: 14px;
            font-weight: 800;
            color: #222B45;
        }
    }
`;

export const AgeGroup = styled.div`
    background-color: #E4E9F2;
    color: #222B45;
    font-size: 12px;
    font-weight: 600;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 40px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Status = styled.div<{color: string; value: number}>`
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;

    &:before {
        content: "${({ value }) => value < 100 ? value : '+99'}";
        background: ${({ color }) => color};
        width: 20px;
        height: 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9.2px;
        font-weight: 700;
        color: #fff;
        margin-right: 4px;
    }

`;