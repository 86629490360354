import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Label, Pagination, Spinner } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import { BsCheckLg } from 'react-icons/bs';

import { ContainerCard, ContainerSelectAll, PendingSubscriptionsContainer } from './styles';

import ListSubscriptionsTreeGroupService, { ISubscriptionReponse } from '@modules/subscriptions/services/ListSubscriptionsTreeGroupService';
import { SubscriptionCard } from '@modules/subscriptions/components/SubscriptionCard';

import { removeDuplicatesFromArray } from '@shared/utils/removeDuplicatesFromArray';
import { ISubscription } from '@modules/subscriptions/interfaces/ISubscription';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import CustomForm from '@shared/components/CustomForm';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';
import { useSubscription } from '@modules/subscriptions/context/SubscriptionContext';
import { SubscriptionsAdvancedFilter } from '../../components/SubscriptionsAdvancedFilter';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
const PendingSubscriptions: React.FC = () => {
  const { signedUser } = useAuth();
  const { setIsLoading, isLoading } = useGlobal();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();
  const groupInPath = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [subscription, setSubscriptions] = useState<ISubscriptionReponse>();
  const { checkedSubscriptions, setCheckedSubscriptions, subscriptionsFilter } = useSubscription();

  const listSubscriptions = useCallback(
    async (search: string = '', page: number = 0) => {
      if (!signedUser || !groupInPath.id) return;
      setIsLoading(true);
      const res = await new ListSubscriptionsTreeGroupService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        group_id: Number(groupInPath.id),
        active: 'false',
        ...subscriptionsFilter.current,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return;
      if (!isMounted()) return;
      setSubscriptions(res);
    },
    [groupInPath.id, isMounted, newCancelToken, setIsLoading, signedUser, subscriptionsFilter]
  );

  const handleCheckAllSubscriptions = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!subscription) return;
      const isChecked: boolean = event.target.checked;

      if (isChecked) {
        const newCheckedAray = removeDuplicatesFromArray([...checkedSubscriptions, ...subscription.items]);
        setCheckedSubscriptions(newCheckedAray);
        return;
      }

      const remaningChecked = checkedSubscriptions.filter((sub) => !subscription.items.find((s) => s.id === sub.id));
      setCheckedSubscriptions(remaningChecked);
    },

    [checkedSubscriptions, setCheckedSubscriptions, subscription]
  );

  const handleOnChangeAttendenceCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, sub: ISubscription) => {
      const checked: boolean = event.target.checked;
      const subscriptionId: string = event.target.id;

      if (!checked) {
        setCheckedSubscriptions((oldState) => {
          return oldState.filter((s) => String(s.id) !== subscriptionId);
        });
        return;
      }

      setCheckedSubscriptions((oldState) => [...oldState, sub]);
    },
    [setCheckedSubscriptions]
  );

  const handleOnClickEnrollment = useCallback(() => {
    navigate(`/assinaturas/grupo/${groupInPath.id}/pendentes/enturmar`, { state: checkedSubscriptions });
  }, [checkedSubscriptions, groupInPath.id, navigate]);

  useEffect(() => {
    listSubscriptions();
  }, [listSubscriptions]);

  useEffect(() => {
    return function cleanup() {
      subscriptionsFilter.current = {};
    };
  }, [subscriptionsFilter]);

  const isSelectAllChecked = useMemo(() => {
    return subscription?.items.length === checkedSubscriptions.length;
  }, [checkedSubscriptions.length, subscription]);

  return (
    <PendingSubscriptionsContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Assinaturas Pendentes"
        status="quaternary"
        pathToReturn="/assinaturas/contexto/grupos"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'PendingSubscriptions' },
        }}
      />

      <DirectoryContainer footer={true} style={{ padding: '20px 22px' }}>
        <CustomForm>
          <SubscriptionsAdvancedFilter handleOnChangeMainInput={listSubscriptions} handleOnClickApplyFilterCallback={listSubscriptions} />

          {subscription && (
            <ContainerSelectAll>
              <Label>Selecionar Todos</Label>
              <Checkbox
                key={checkedSubscriptions.length}
                semiRound
                name="select-all"
                id="select-all"
                onChange={(event) => handleCheckAllSubscriptions(event)}
                icon={BsCheckLg}
                hideIconUncheck
                defaultChecked={isSelectAllChecked}
              />
            </ContainerSelectAll>
          )}

          {subscription && subscription.items && (
            <Fragment>
              {subscription.items.map((sub, index) => (
                <ContainerCard key={index}>
                  <SubscriptionCard className="subscription-card" subscription={sub} />
                  <Checkbox
                    key={`${sub.id}-${checkedSubscriptions}`}
                    name={`${sub.id}`}
                    id={`${sub.id}`}
                    semiRound
                    icon={BsCheckLg}
                    hideIconUncheck
                    onChange={(event) => handleOnChangeAttendenceCheckbox(event, sub)}
                    defaultChecked={!!checkedSubscriptions.find((cs) => cs.id === sub.id)}
                  />
                </ContainerCard>
              ))}

              <Pagination
                status="quaternary"
                totalOfPages={subscription.totalPages}
                currentPage={subscription.currentPage}
                callbackGetListData={listSubscriptions}
              />
            </Fragment>
          )}

          <PageFooter>
            <Button onClick={() => navigate(`/assinaturas/grupo/${groupInPath.id}/criar/turma`)} status="quaternary" transform="none" noBorder shaded>
              Criar Turma
            </Button>
            <Button onClick={handleOnClickEnrollment} status="quaternary" transform="none" noBorder shaded disabled={!!!checkedSubscriptions.length}>
              Enturmar
            </Button>
          </PageFooter>
        </CustomForm>
      </DirectoryContainer>
    </PendingSubscriptionsContainer>
  );
};

export { PendingSubscriptions };
