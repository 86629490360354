import React from 'react';
import IProjectReport from '@modules/projects/interfaces/IProjectReport';
import { AttachmentCounter, Container } from './styles';
import Icon from 'react-eva-icons';
import CustomCard from '@shared/components/CustomCard';
import { formatDate, Grid, P } from 'react-alicerce-components';

type IReportCardProps = {
  report: IProjectReport;
  handleOnClick?: (report: IProjectReport) => void;
};

const ReportCard: React.FC<IReportCardProps> = ({ report, handleOnClick, ...rest }) => {
  const RenderCreatedAtDate = () => {
    if (report.created_at) {
      return <b>{formatDate( new Date(report.created_at), 'long' )}</b>;
    }
    return null;
  };

  return (
    <Container>
      <CustomCard
        handleOnClick={() => handleOnClick && handleOnClick(report)}
        header={
          <h6 style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Icon className='attach-icon' name="attach-2" />
            <P>Quantidade de Anexos:</P>
            <AttachmentCounter>{report.attachments?.length || 0 }</AttachmentCounter>
          </h6>
        }
        {...rest}
      >
        <Grid dFlex flexColumn alignItemsStart>
          + Criado em
          <RenderCreatedAtDate />
        </Grid>
      </CustomCard>
    </Container>
  );
};

export default ReportCard;
