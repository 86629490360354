import React, { useCallback, useState, useEffect } from 'react';

import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import GetClassFrequencyPercentService from '@modules/dashboards/services/GetClassFrequencyPercentService';
import { formatGroupFrequencyPercent } from '@modules/dashboards/utils/formatGroupFrequencyPercent';

import { Container, Content } from './styles';

interface IGroupFrequencyPercentProps {
    classId?: string;
}

const ClassFrequencyPercent: React.FC<IGroupFrequencyPercentProps> = ({
    classId
}) => {

    const { signedUser } = useAuth();
    const { setIsLoading } = useGlobal();
    const [students, setStudents] = useState<any[]>([]);
    const [columns] = useState<IDynamicTableColumnsGroup[]>([
        {
            columns: [
                { name: 'Alunos', dataname: 'name' },
                { name: 'Idade', dataname: 'date' },
                { name: '% de Presença', dataname: 'percent' }
            ],
            fixed: true
        }
    ]);
    const load = useCallback(async () => {

        if (!signedUser) return;

        setIsLoading(true);
        const res = await new GetClassFrequencyPercentService(signedUser.token)
            .execute(`${classId}`);

        setIsLoading(false);

        if (!res) return;
        setStudents(() => res.map(formatGroupFrequencyPercent));

    }, [classId, setIsLoading, signedUser]);


    useEffect(() => {
        load();
    }, [load]);

    const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
        const orderBy = sort.sortName as string;

        setStudents((oldState) => {
            let aux = [...oldState]
            aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
            return aux;
        });
    }, []);

    return (
        <Container>
            <Content>
                <DynamicTable
                    columnsGroup={columns}
                    data={students}
                    handleClickSort={handleOnClickSort}
                />
            </Content>
        </Container>
    );
}

export default ClassFrequencyPercent;