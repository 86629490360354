// grau de escolaridade
export const FUNDAMENTAL_COMPLETO = { name: 'Ensino Fundamental Completo', value: 'fundamental_completo' };
export const FUNDAMENTAL_INCOMPLETO = { name: 'Ensino Fundamental Incompleto', value: 'fundamental_incompleto' };
export const MEDIO_COMPLETO = { name: 'Ensino Médio Completo', value: 'medio_completo' };
export const MEDIO_INCOMPLETO = { name: 'Ensino Médio Incompleto', value: 'medio_incompleto' };
export const SUPERIOR_COMPLETO = { name: 'Ensino Superior Completo', value: 'superior_completo' };
export const SUPERIOR_INCOMPLETO = { name: 'Ensino Superior Incompleto', value: 'superior_incompleto' };

export const EDUCATION_LEVEL_CONSTRAINTS = [
  FUNDAMENTAL_COMPLETO,
  FUNDAMENTAL_INCOMPLETO,
  MEDIO_COMPLETO,
  MEDIO_INCOMPLETO,
  SUPERIOR_COMPLETO,
  SUPERIOR_INCOMPLETO,
];

export const defaultEducationLevel = (educatgionLevel?: string | null) => {
  return [
    { name: FUNDAMENTAL_COMPLETO.name, value: FUNDAMENTAL_COMPLETO.value, selected: educatgionLevel === FUNDAMENTAL_COMPLETO.value },
    { name: FUNDAMENTAL_INCOMPLETO.name, value: FUNDAMENTAL_INCOMPLETO.value, selected: educatgionLevel === FUNDAMENTAL_INCOMPLETO.value },
    { name: MEDIO_COMPLETO.name, value: MEDIO_COMPLETO.value, selected: educatgionLevel === MEDIO_COMPLETO.value },
    { name: MEDIO_INCOMPLETO.name, value: MEDIO_INCOMPLETO.value, selected: educatgionLevel === MEDIO_INCOMPLETO.value },
    { name: SUPERIOR_COMPLETO.name, value: SUPERIOR_COMPLETO.value, selected: educatgionLevel === SUPERIOR_COMPLETO.value },
    { name: SUPERIOR_INCOMPLETO.name, value: SUPERIOR_INCOMPLETO.value, selected: educatgionLevel === SUPERIOR_INCOMPLETO.value },
  ];
};
