import React from 'react';
import Icon from 'react-eva-icons';

import { Container, Box, BoxIcon } from './styles';

const Legend: React.FC = () => {
  return (
    <Container>
      <Box bgColor="#C5CEE0" borderColor="#8F9BB3" value="Não avaliado" />
      <Box bgColor="#00D68F" value="Dominado" />
      <Box bgColor="#FFAA00" value="Semi-dominado" />
      <Box bgColor="#FF3D71" value="Não dominado" />
      <Box bgColor="#0095FF" value="Avaliado pelo Instrutor" />
      <BoxIcon value="Competência Essencial">
        <Icon name="star" fill="#F2C94C" />
      </BoxIcon>
    </Container>
  );
};

export { Legend };
