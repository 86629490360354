import React from 'react';

import { formatDate, P, Pill } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';
import { IApplication } from '@modules/applications/interfaces/IApplication';

import { Container } from './styles';

type IApplicationCardProps = ICustomCardProps & {
  application?: IApplication;
  statusNumber?: number;
};

const ApplicationCard: React.FC<IApplicationCardProps> = ({ application, status = 'basic', statusNumber = 500, ...rest }) => {
  console.log(application, 'application');
  return (
    <Container className="application-card-container" type={application?.state?.type}>
      <CustomCard
        header={
          <>
            <h6>
              {application?.state?.type} {application?.trail?.name}
            </h6>
            {!!application?.invalidation_reason && (
              <Pill color="rgba(34, 43, 69, 1)" backgroundColor="rgba(255, 201, 77, 1)" size={'small'} marginLeft={'10px'}>
                Invalidando
              </Pill>
            )}
          </>
        }
        {...rest}
      >
        <P fontSize={14} textAlign="center">
          {application?.state?.appliedAt && (
            <>
              Aplicado em: <b>{formatDate(new Date(application?.state?.appliedAt), `numeric`)}</b> <br />
            </>
          )}
          {application?.state?.startedAt && (
            <>
              Iniciado em: <b>{formatDate(new Date(application?.state?.startedAt), `numeric`)}</b> <br />
            </>
          )}
          {application?.state?.finalizedAt && (
            <>
              Finalizado em: <b>{formatDate(new Date(application?.state?.finalizedAt), `numeric`)}</b> <br />
            </>
          )}
        </P>
      </CustomCard>
    </Container>
  );
};

export default ApplicationCard;
