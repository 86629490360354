import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner, Tabs, Tab, Button } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import CustomTabs from '@shared/components/CustomTabs';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { CurrentHeadlightLaunch, CurrentHeadlightLaunchHandle } from '@modules/projects/components/CurrentHeadlightLaunch';
import CurrentSprintAndPeriod from '@modules/projects/components/CurrentSprintAndPeriod';
import CurrentHeadlightDiagnostic from '@modules/projects/components/CurrentHeadlightDiagnostic';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { useToast } from 'react-alicerce-components';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { formatHeadlight } from '@modules/projects/utils/formatHeadlight';
import CreateHeadlightService from '@modules/projects/services/Headlight/CreateHeadlightService';
import { beforeSubmitForm, hasError } from '@shared/utils/beforeSubmitForm';
import ConfirmModal from '@shared/components/ConfirmModal';
import { headlightSchema } from '@modules/projects/schemas/Headlight';
import HeadlightsHistory from '@modules/projects/components/HeadlightsHistory';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import GetCurrentPeriodService from '@modules/periods/services/GetCurrentPeriodService';
import * as Yup from 'yup';

const ProjectHeadlightLaunch: React.FC = () => {

  const { isLoading, setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const { currentHeadlight, clearHeadlightCreate, setHeadlightErros, setCurrentSprint, setCurrentPeriod, headlightErrors, setCurrentHeadlight } = useHeadlights();
  const { getProject, projectCurrent } = useProjects();
  const { id: projectId } = useParams<{ id: string }>();
  const currentHeadlightLaunchRef = useRef<CurrentHeadlightLaunchHandle>(null)
  const diagnosticHasError = hasError(['diagnostics'], headlightErrors)
  const categoriesHeadlightHasError = hasError(['data', 'category_status'], headlightErrors)

  useEffect(() => {
    getProject(projectId)
  }, [getProject, projectId])

  const getCurrentSprintAndPeriod = useCallback(async (scheduleId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (scheduleId) {
      const sprint = await new GetCurrentSprintService(signedUser.token).execute({ schedule_id: Number(scheduleId) });
      if (!sprint) return;
      setCurrentSprint(sprint);
      setCurrentHeadlight((oldState: IHeadlight) => {
        return { ...oldState, sprint_id: sprint.id }
      })

      const period = await new GetCurrentPeriodService(signedUser.token).execute(Number(sprint.id));
      if (!period) return;
      setCurrentPeriod(period);
      setCurrentHeadlight((oldState: IHeadlight) => {
        return { ...oldState, period_id: period.id }
      })
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser, setCurrentSprint, setCurrentHeadlight, setCurrentPeriod]);

  useEffect(() => {
    projectCurrent && getCurrentSprintAndPeriod(projectCurrent?.schedule_id)
  }, [getCurrentSprintAndPeriod, projectCurrent]);

  const handleCreateButton = useCallback(async () => {
    if (!currentHeadlight) return;
    const formatedBody = formatHeadlight(currentHeadlight);
    const headlightCategoriesNumber = currentHeadlightLaunchRef.current?.getheadlightCategories().length || 0;
    const headlightCurrentSchema = headlightSchema.shape({
      data: headlightSchema.fields.data.concat(
        Yup.array().min(headlightCategoriesNumber, { name: "category_status", message: 'O campo é obrigatório' })
      )
    });
    const canSubmitForm = await beforeSubmitForm(formatedBody, headlightCurrentSchema);
    setHeadlightErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [currentHeadlight, setHeadlightErros]);

  const handleCreateHeadlight = useCallback(async (currentHeadlight) => {
    setShowCreateModal(false);
    if (!signedUser || !currentHeadlight) return;
    setIsLoading(true);
    const formatedBody = formatHeadlight(currentHeadlight);

    const res = await new CreateHeadlightService(signedUser.token).execute(formatedBody);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Farol',
        description: 'Farol lançado com sucesso.',
      });
      clearHeadlightCreate();
      return navigate('/projetos/lancar-farois');
    }

    addToast({
      status: 'danger',
      title: 'Farol',
      description: 'Não foi possível lançar o Farol.',
    });
  }, [navigate, setIsLoading, signedUser, addToast, clearHeadlightCreate]);

  return (
    <ProjectDefaultStylesContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title={'Lançador de Farol'}
        onClickReturn={() => navigate('/projetos/lancar-farois')}
        menuKebab={{ tooltip: { view: 'HeadlightsLaunch' }, menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        <CustomTabs footer>
          <Tabs horizontalScroll>

            <Tab
              title="Faróis"
              badge={categoriesHeadlightHasError}
              badgeSide={categoriesHeadlightHasError && "left"}
              badgeColor={categoriesHeadlightHasError && "#fff"}
              badgeContent={categoriesHeadlightHasError && "!"}
              badgeBg={categoriesHeadlightHasError && '#c53030'}
            >
              <CurrentSprintAndPeriod />
              <CurrentHeadlightLaunch ref={currentHeadlightLaunchRef} />
            </Tab>

            <Tab
              title="Diagnóstico"
              badge={diagnosticHasError}
              badgeSide={diagnosticHasError && "rigth"}
              badgeColor={diagnosticHasError && "#fff"}
              badgeContent={diagnosticHasError && "!"}
              badgeBg={diagnosticHasError && '#c53030'}
            >
              <CurrentSprintAndPeriod />
              <CurrentHeadlightDiagnostic />
            </Tab>

          </Tabs>
          <HeadlightsHistory />
        </CustomTabs>
        <PageFooter>
          <Button
            transform="none"
            shaded
            noBorder
            onClick={handleCreateButton}
          >
            Fazer Lançamento
          </Button>
          <ConfirmModal
            start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
            showModal={showCreateModal}
            closeCallback={() => setShowCreateModal(false)}
            title="Deseja fazer o lançamento do farol?"
            textConfirmButton="Confirmar"
            textCloseButton="Cancelar"
            confirmCallBack={() => handleCreateHeadlight(currentHeadlight)}
          />
        </PageFooter>
      </DirectoryContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectHeadlightLaunch;
