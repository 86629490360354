import { styled } from "react-alicerce-components";

interface IContainer {
  opened: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  ${({ opened }) => opened && `flex-direction: row-reverse;`}
  justify-content: ${({ opened }) => (opened ? "flex-end" : "flex-start")};
  align-items: center;
  margin: 0;
  background: #edf1f7;
  border-radius: 5px;
  padding: 5px 10px 5px 20px;
  column-gap: 1rem;
  cursor: pointer;

  .text-name {
    font-weight: bold;
    font-size: 12px;
    color: #2e3a59;
    text-transform: uppercase;
    width: 60px;
  }

  i {
    display: flex;
    svg {
      width: 25px;
      height: 25px;
    }
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .styled-icon {
    transform: rotateY(0deg);
    transition: 0.5s;
  }

  .rotate {
    transform: rotateY(180deg);
  }
`;
