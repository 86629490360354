export const COGNITIVE_TASK_UNDERSTAND = { name: 'Entender', value: 'understand' };
export const COGNITIVE_TASK_PRACTICE = { name: 'Praticar', value: 'practice' };
export const COGNITIVE_TASK_APPLY = { name: 'Aplicar', value: 'apply' };

export const COGNITIVE_TASKS_CONSTRAINTS = [COGNITIVE_TASK_UNDERSTAND, COGNITIVE_TASK_PRACTICE, COGNITIVE_TASK_APPLY];

export const defaultCognitiveTasks = (cognitiveTask?: string | null) => {
  return [
    { name: COGNITIVE_TASK_UNDERSTAND.name, value: COGNITIVE_TASK_UNDERSTAND.value, selected: cognitiveTask === COGNITIVE_TASK_UNDERSTAND.value },
    { name: COGNITIVE_TASK_PRACTICE.name, value: COGNITIVE_TASK_PRACTICE.value, selected: cognitiveTask === COGNITIVE_TASK_PRACTICE.value },
    { name: COGNITIVE_TASK_APPLY.name, value: COGNITIVE_TASK_APPLY.value, selected: cognitiveTask === COGNITIVE_TASK_APPLY.value },
  ];
};
