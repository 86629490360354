import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';
import { RETRACT_SIZE } from '../../CustomTabs';

import { IHeaderModuleProps } from './index';

export const Container = styled.div<IHeaderModuleProps>`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 100vw;
  background: url(${({ bgHeaderImg }) => bgHeaderImg}) no-repeat;
  background-size: 100%;
  background-position-y: -160px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: ${({ bgHeaderImg }) => (bgHeaderImg ? '.8' : '1')};
    background: ${({ bgHeaderColor, status }) => bgHeaderColor || themeGet(`colors.${status}500`)};
  }

  .main-navbar {
    /* background: ${({ bgHeaderColor, status }) => bgHeaderColor || themeGet(`colors.${status}500`)}; */
    background: transparent;
    position: relative;
    z-index: 999;
    margin: 1rem 1rem 0rem 1rem;
  }

  ${(props) =>
    props.compressOnScroll &&
    props.isScrolled &&
    `
        min-height: calc(200px - ${RETRACT_SIZE}px);

        .right,
        .additional-info {
            display: none;
        }

        .header-module-content {
          margin-top: -35px;
        }
    `}

    // RESPONSIVE - DESKTOP
    @media (min-width: 992px){
      min-height: 200px !important;
      
      .right,
      .additional-info {
        display: initial !important;
      }

      .header-module-content {
        height: 100%;
        width: 100%;
        align-items: flex-start;
        margin-top: 4px;
        gap: 4px;

        h6 {
          font-size: 1.2rem !important;
        }

        p {
          font-size: 1rem !important;
        }
      }
    }
  
`;

export const HeaderModuleDefaultContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: 100%; */
  padding: 0 1.2rem;
  z-index: 1;
  margin: 0px auto auto auto;
`;

export const HeaderModuleTitle = styled.h6<{ titleColor: string }>`
  text-transform: uppercase;
  font-weight: 800;
  color: ${({ titleColor }) => titleColor};
  font-size: 1.6rem;
`;

export const HeaderModuleSubTitle = styled.p`
  font-weight: 400;
  color: #fff;
  font-size: 0.9rem;
`;

export const HeaderModuleAdditionalInfo = styled.p`
  font-weight: 800;
  color: #fff;
  font-size: 0.9rem;
`;
