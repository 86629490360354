import * as Yup from 'yup';
import { HEADLIGHT_TYPE_CONTRAINS } from './partials/HeadlightType';
import { HEADLIGHT_STATUS_OPTION_CONTRAINS } from './partials/HeadlightStatus';

export const headlightSchema = Yup.object().shape({
  type: Yup.string().oneOf(HEADLIGHT_TYPE_CONTRAINS, { name: 'type', message: `Campo deve ser ${HEADLIGHT_TYPE_CONTRAINS} ` }).required({ name: 'type', message: 'O campo é obrigatório' }),
  diagnostics: Yup.string().required({ name: 'diagnostics', message: 'O campo é obrigatório' }),
  data: Yup.array().of(Yup.object().shape({
    category: Yup.string()
      .required({ name: 'category', message: `O campo é obrigatório` }),
    category_description: Yup.string().optional().nullable(),
    status_description: Yup.string().optional().nullable(),
    status: Yup.string()
      .oneOf(HEADLIGHT_STATUS_OPTION_CONTRAINS, { name: 'status', message: `Campo deve ser ${HEADLIGHT_STATUS_OPTION_CONTRAINS}` })
      .required({ name: 'status', message: 'O campo é obrigatório' })
  })).required({ name: 'data', message: 'O campo é obrigatório' }),
  lms_user_id: Yup.number().optional().nullable(),
  approved_lms_user_id: Yup.number().optional().nullable(),
  previous_headlights_id: Yup.number().optional().nullable(),
  project_id: Yup.number().required({ name: 'project_id', message: 'O campo é obrigatório' }),
  sprint_id: Yup.number().required({ name: 'sprint_id', message: 'O campo é obrigatório' }),
  period_id: Yup.number().required({ name: 'period_id', message: 'O campo é obrigatório' })
});