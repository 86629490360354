
import styled from 'styled-components';
import { Button } from 'react-alicerce-components';

import { IButtonGradientOutlineProps } from './index';

export const StyledButton = styled(Button)<IButtonGradientOutlineProps>`
    /* .button-inner {
        z-index: 1 !important;
    } */
`;
