import React, { useMemo, useState, useCallback } from 'react';

interface IControllerNavigationBoxProps {
  children?: React.ReactNode | JSX.Element;
  style?: React.CSSProperties;
}

const ControllerNavigationBox: React.FC<IControllerNavigationBoxProps> = ({ children }) => {
  const [currentBox, setCurrentBox] = useState<number>();

  const handleSetCurrent = useCallback((index: number | undefined) => {
    setCurrentBox(index);
  }, []);

  const getChildren = useMemo(() => {
    const boxes = children as Array<any>;
    let newBoxes: Array<any>;

    if (!Array.isArray(boxes)) return [boxes]

    newBoxes = boxes.map((box, index) => ({
      ...box,
      props: {
        index,
        handleSetCurrent,
        currentBox,
        ...box.props,
      },
    }));

    return newBoxes;
  }, [children, currentBox, handleSetCurrent]);

  return <div className="controlled-boxes-list">{getChildren}</div>;
};

export default ControllerNavigationBox;
