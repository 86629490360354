import React, { useCallback } from 'react';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import CustomCard from '@shared/components/CustomCard';
import CardDirectoryContent from '@modules/groups/components/CardDirectoryContent';
import { CSSProperties } from 'styled-components';

interface IGroupCard {
  group: IGroup;
  handleOnClick?: (group: IGroup) => void;
  groupsSelected?: IGroup[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
}

const GroupCard: React.FC<IGroupCard> = ({ customStyle, canDeleteCard, group, handleOnClick, groupsSelected, canSelect, inputCard }) => {
  const cardShadow = useCallback(() => {
    if (canSelect && groupsSelected?.find((p) => p.id === group.id)) return 'drop-shadow(0px 0px 28px #82F210)';
    return 'none';
  }, [canSelect, group.id, groupsSelected]);

  const cardShadowBox = useCallback(() => {
    if (canSelect && groupsSelected?.find((p) => p.id === group.id)) return '0px 0px 5px #82F210';
    return 'none';
  }, [canSelect, group.id, groupsSelected]);

  return (
    <CustomCard
      canDeleteCard={canDeleteCard}
      inputCard={inputCard}
      key={group.id}
      status="tertiary"
      handleOnClick={() => handleOnClick && handleOnClick(group)}
      header={<span>{group.name}</span>}
      customStyles={{
        border: 'none',
        filter: `${cardShadow()}`,
        boxShadow: `${cardShadowBox()}`,
        zIndex: -1,
        ...customStyle,
      }}
    >
      <CardDirectoryContent cardInfo={group} />
    </CustomCard>
  );
};

export default GroupCard;
