import React from 'react';
import { H6 } from 'react-alicerce-components';
import { CSSProperties } from 'styled-components';
import CustomCard from '@shared/components/CustomCard';
import { Container } from './styles';
import { HeadlightType } from '@modules/projects/utils/headlightsConfig';

interface HeadlightCardProps {
  selected?: any[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
  category: string;
  status: string;
  status_description: string;
  handleOnClick?: (clicked: any) => void;
}

const HeadlightCard: React.FC<HeadlightCardProps> = ({
  category,
  status,
  status_description
}) => {

  return (
    <Container headlightType={status as HeadlightType}>
      <CustomCard header={<H6>{category}</H6>}>
        <span />
        {status_description}
      </CustomCard>
    </Container>
  );
};

export default HeadlightCard;
