import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IBlock } from '@shared/interfaces/IBlock';
import { ITrail } from '@shared/interfaces/ITrail';
import { ILevel } from '@modules/activities-library/interfaces/ILevel';
import { objectToQuery } from '../utils/objectToQuery';

export interface ListAllMapa4ModulesReponse {
  trails: ITrail[];
  levels: ILevel[];
  blocks?: IBlock[];
}

export interface ListAllMapa4ModulesOptions {
  trail?: boolean;
  level?: boolean;
  block?: boolean;
}

export class ListAllMapa4ModulesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options?: ListAllMapa4ModulesOptions): Promise<ListAllMapa4ModulesReponse | false> {
    let searchParams = objectToQuery({ ...options });

    try {
      const res = await this.api.get(`/external/mapa4/listAll?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return false;
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}
