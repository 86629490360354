import { AxiosInstance, AxiosResponse } from 'axios';
import IAuth from '@modules/home/interfaces/IAuth';
import IApiError from '@shared/interfaces/IApiError';
import ApiFactory from '@shared/factories/ApiFactory';

export interface SignInWithTokenResponse {
  user?: IAuth;
  status: number;
  message?: string;
  error?: IApiError;
}

const apiUrl = process.env.REACT_APP_AUTH_URL ? process.env.REACT_APP_AUTH_URL : null;
const systemKey = process.env.REACT_APP_SYSTEM_KEY ? process.env.REACT_APP_SYSTEM_KEY : null;
const systemSecret = process.env.REACT_APP_SYSTEM_SECRET ? process.env.REACT_APP_SYSTEM_SECRET : null;

class SignInWithTokenService {
  private api: AxiosInstance;

  constructor() {
    if (!apiUrl || !systemKey || !systemSecret) {
      throw new Error('REACT_APP_AUTH_URL or REACT_APP_SYSTEM_KEY or REACT_APP_SYSTEM_SECRET is not setup.');
    }

    this.api = ApiFactory.create({ config: { baseURL: apiUrl } });
  }

  public async execute(code: string): Promise<SignInWithTokenResponse> {
    try {
      const response = await this.api.post('/api/token', { system_key: systemKey, code: code, system_secret: systemSecret });

      let user = response?.data as IAuth;
      const message = response?.data?.message as string;

      return {
        user,
        message,
        status: 200,
      };
    } catch (error: any) {
      if (error.isAxiosError && error.message !== 'Network Error') {
        const res = error.response as AxiosResponse;
        return {
          status: res.status,
          message: res.data.message,
          error: res.data,
        };
      }

      return {
        status: 500,
        message: error.message,
        error: {
          message: error.message,
        },
      };
    }
  }
}

export default new SignInWithTokenService();
