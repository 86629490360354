import React from "react";
import Icon from 'react-eva-icons';
import { Container } from "@shared/components/RedirectSuggestionAlert/styles";
import { Button } from "react-alicerce-components";
import { useNavigate } from "react-router-dom";


interface IRedirectSuggestionAlert {
  message: string;
  buttonName: string;
  redirectTo: string;
  buttonIcon?: JSX.Element;
  buttonColor?: string;
}

const RedirectSuggestionAlert: React.FC<IRedirectSuggestionAlert> = ({ message, buttonName, redirectTo, buttonIcon, buttonColor }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Icon name="alert-triangle" fill="#8F9BB3"/>
            <p>{message}</p>
            <Button
                start={buttonIcon}
                transform="none"
                color="#FFF"
                style={{ borderRadius: '16px', background: buttonColor }}
                noBorder
                onClick={() => navigate(redirectTo)}
            >
                {buttonName}
            </Button>
        </Container>
    );
};

export { RedirectSuggestionAlert };