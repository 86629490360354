import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, H6, P } from 'react-alicerce-components';

import Layout from '@modules/places/components/Layout';
import Divider from '@shared/components/Divider';

import ActivityItem from '@shared/components/ActivityItem';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';

import { usePlaces } from '@modules/places/context/PlacesContext';
import { ITask } from '@modules/tasks/interfaces/ITask';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import TasksList from '@modules/tasks/components/TasksListManager/TaskList';
import ListEventsService from '@modules/schedules/services/ListEventsService';
import ListSprintsService from '@modules/schedules/services/ListSprintsService';
import ListPendingTasksService from '@modules/tasks/services/ListPendingTasksService';

import { Container } from './styles';
import { DateHelper } from '@shared/helpers/DateHelper';
import { getWeekdayFromWeekNumber } from '../../../classes/utils/getWeekdayFromWeekNumber';

const PlaceSchedule: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { placeCurrent } = usePlaces();
  const { setIsLoading } = useGlobal();

  const [events, setEvents] = useState<any[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);

  const listEvents = useCallback(async () => {
    if (!signedUser || !placeCurrent?.id) return;

    const filterEventsBy = {
      place_id: placeCurrent.id,
      start_date: DateHelper.extractDate(),
      end_date: DateHelper.extractDate(),
      size: 9999,
    };

    const filterTasksBy = { place_id: placeCurrent.id, due_today: true, size: 3 };

    const promises: any[] = [];
    promises.push(
      new ListPendingTasksService(signedUser.token).execute('', filterTasksBy),
      new ListSprintsService(signedUser.token).execute('', filterEventsBy),
      new ListEventsService(signedUser.token).execute('', {
        ...filterEventsBy,
        week_days: [getWeekdayFromWeekNumber(new Date().getDay())],
      })
    );

    const [resTasks, resSprints, resEvents] = await Promise.all(promises);

    const isCancel = resEvents === 'canceling' || resSprints === 'canceling' || resTasks === 'canceling';
    const isFail = !resEvents || !resSprints || !resTasks;

    if (isFail || isCancel) return;

    setTasks(resTasks.items);
    setEvents([...resEvents.items.filter((e) => e.type !== 'task'), ...resSprints.items]);
  }, [placeCurrent, signedUser]);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      await listEvents();
      setIsLoading(false);
    }

    load();
  }, [listEvents, setIsLoading]);

  const getTime = useCallback((data: any) => {
    return !data?.type || data?.type === 'holiday' ? 'Dia todo' : `${data.start_time.substr(0, 5)} - ${data.end_time.substr(0, 5)}`;
  }, []);

  return (
    <Layout openTab={2} footer>
      <Container>
        {!tasks.length && !events.length && <P textAlign="center">Não existe nenhuma Tarefa, Sprint ou Evento para ser exibido.</P>}

        {!!tasks.length && (
          <Fragment>
            <H6 fontWeight={800} textAlign="center" status="primary" marginBottom={'2rem'}>
              TAREFAS PENDENTES
            </H6>
            <TasksList tasks={tasks} handleOnConclude={listEvents} canConclude dateKey={'end_date'} prefixDate={'Concluir em:'} />
          </Fragment>
        )}

        {!!(tasks.length && events.length) && <Divider />}

        {!!events.length && (
          <Fragment>
            <H6 fontWeight={800} textAlign="center" status="primary">
              ATIVIDADES PARA HOJE
            </H6>

            {events.map((event) => (
              <ActivityItem key={event.id} name={event.name} time={getTime(event)} status="primary" />
            ))}
          </Fragment>
        )}
      </Container>

      <PageFooter>
        <Button
          transform="none"
          status="primary"
          shaded
          onClick={() =>
            navigate(`/agenda/consolidada`, {
              state: { resource: 'place_id', id: placeCurrent?.id, pathToReturn: `/local/${placeCurrent?.id}/agenda` },
            })
          }
        >
          Agenda do Local
        </Button>
        <Button
          transform="none"
          status="primary"
          shaded
          onClick={() => navigate('/tarefas/pendentes', { state: { resource: 'place_id', id: placeCurrent?.id } })}
        >
          Lista de Tarefas
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default PlaceSchedule;
