import React, { useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import ScheduleForm from '@modules/schedules/components/ScheduleForm';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import CreateScheduleService from '@modules/schedules/services/CreateScheduleService';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

import { Container } from './styles';

const ScheduleCreate: React.FC = () => {
  const { state } = useSchedules();

  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();

  const handleCreateSchedule = useCallback(
    async (dataValidated: ISchedule) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new CreateScheduleService(signedUser.token).execute({
        ...dataValidated,
        [`${state?.resource}`]: state?.id,
      });

      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Agenda',
          description: 'Falha ao tentar criar Agenda. Tente novamente.',
        });
        return;
      }

      navigate(`/agenda/locais`, { state: state });
    },
    [signedUser, setIsLoading, state, navigate, addToast]
  );

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Criar Agenda" />

      <ScheduleForm handleOnFinish={handleCreateSchedule} formRef={formRef} />

      <PageFooter>
        <ButtonGradientOutline onClick={() => navigate(-1)}>Cancelar</ButtonGradientOutline>

        <Button onClick={() => formRef?.current?.submitForm()} color="#fff" transform="none" status="basic" statusNumber={600} shaded>
          Salvar
        </Button>
      </PageFooter>
    </Container>
  );
};

export default ScheduleCreate;
