import React, { useCallback, useState, useEffect } from 'react';
import { ISelectOption, Select } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';

import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import { ListTrailsService } from '@shared/services/ListTrailsService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import Divider from '@shared/components/Divider';

import GetClassLearningHistoryService from '@modules/dashboards/services/GetClassLearningHistoryService';
import { formatGroupLearningHistory, IFormattedGroupLearningHistory } from '@modules/dashboards/utils/formatGroupLearningHistory';

import { Container, Content, Form } from './styles';

interface IParams {
    trail_id?: string;
    month?: string;
    year?: string;
}

interface IGroupLearningCurrentProps {
    classId?: string;
}

const ClassLearningHistory: React.FC<IGroupLearningCurrentProps> = ({
    classId
}) => {

    const { setIsLoading } = useGlobal();
    const { signedUser } = useAuth();

    const [state, setState] = useState<IFormattedGroupLearningHistory>();

    const [params, setParams] = useState<IParams>();

    const [trails, setTrails] = useState<ISelectOption[]>();
    const [months] = useState<ISelectOption[]>([
        { name: 'Janeiro', value: '01' },
        { name: 'Fevereiro', value: '02' },
        { name: 'Março', value: '03' },
        { name: 'Abril', value: '04' },
        { name: 'Maio', value: '05' },
        { name: 'Junho', value: '06' },
        { name: 'Julho', value: '07' },
        { name: 'Agosto', value: '08' },
        { name: 'Setembro', value: '09' },
        { name: 'Outubro', value: '10' },
        { name: 'Novembro', value: '11' },
        { name: 'Dezembro', value: '12' },
    ]);

    const [years] = useState<ISelectOption[]>(() => {
        const init = 2015;
        const currentYear = new Date().getFullYear();
        const y: ISelectOption[] = [];
        for (var i = init; i <= currentYear; i++) y.push({ name: i, value: `${i}` } as unknown as ISelectOption)
        return y;
    });

    const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
        const orderBy = sort.sortName as string;

        setState((oldState) => {
            let aux = [...oldState?.data]
            aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
            return {
                ...oldState,
                data: aux
            } as IFormattedGroupLearningHistory;
        });
    }, []);

    const handleOnChangeSelect = useCallback((objKey: string, newValue?: string) => {
        setParams((oldState) => ({ ...oldState, [objKey]: newValue }));
    }, []);

    const listTrails = useCallback(async () => {
        if (!signedUser) return;
        setIsLoading(true);
        const res = await new ListTrailsService(signedUser.token).execute();
        setIsLoading(false);
        if (!res || res === 'canceling') return;
        setTrails(res.items.map(trail => ({ name: trail.name, value: trail.id } as ISelectOption)));
    }, [setIsLoading, signedUser]);

    const load = useCallback(async (options?: any) => {

        if (!signedUser) return;

        setIsLoading(true);

        const res = await new GetClassLearningHistoryService(signedUser.token).execute(`${classId}`, { ...options });
        setState(res ? await formatGroupLearningHistory(res) : undefined);
        setIsLoading(false);

    }, [classId, setIsLoading, signedUser]);

    useEffect(() => {
        listTrails();
    }, [listTrails]);

    useEffect(() => {
        if (!params?.month || !params?.trail_id || !params?.year) return;
        load(params);
    }, [load, params]);

    return (
        <Container>
            <Form>
                <Select
                    label="Escolha o Mês:"
                    iconName="arrow-down"
                    placeholder="Selecione uma opção"
                    options={months}
                    handleSelectChange={(opt) => handleOnChangeSelect('month', opt?.value)}
                />
                <Select
                    label="Escolha o Ano:"
                    iconName="arrow-down"
                    placeholder="Selecione uma opção"
                    options={years}
                    handleSelectChange={(opt) => handleOnChangeSelect('year', opt?.value)}
                />

                <Divider width="100%" />
                <Select
                    label="Escolha a Trilha:"
                    iconName="arrow-down"
                    placeholder="Selecione uma opção"
                    options={trails}
                    handleSelectChange={(opt) => handleOnChangeSelect('trail_id', opt?.value)}
                />
            </Form>
            <Content>
                {state?.columns && state?.data &&
                    <DynamicTable
                        columnsGroup={state?.columns as IDynamicTableColumnsGroup[]}
                        data={state?.data}
                        handleClickSort={handleOnClickSort}
                    />
                }
            </Content>
        </Container>
    );
}

export default ClassLearningHistory;