import React, { useCallback } from 'react';

import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { SubscriptionsAdvancedFilterContainer } from './styles';
import { Checkbox } from 'react-alicerce-components';
import { BsCheckLg } from 'react-icons/bs';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { SHIFT_CONSTRAINTS } from '../../../classes/utils/validData/validShift';
import { CLASS_TYPE_CONSTRAINTS } from '../../../classes/utils/validData/validClassType';
import { useSubscription } from '../../context/SubscriptionContext';
import { AGE_GROUP_CONSTRAINTS } from '../../../../shared/utils/validData/validAgeGroup';

interface ISubscriptionsAdvancedFilter {
  handleOnClickApplyFilterCallback: () => void;
  handleOnChangeMainInput?: (inputValue: string) => void;
}

const SubscriptionsAdvancedFilter: React.FC<ISubscriptionsAdvancedFilter> = ({ handleOnClickApplyFilterCallback, handleOnChangeMainInput }) => {
  const { subscriptionsFilter } = useSubscription();

  const handleResetFilter = () => {
    subscriptionsFilter.current = {};
    handleOnClickApplyFilterCallback();
  };

  const handleClickFilterCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
      const checked = event.target.checked;
      const value = event.target.id;
      const auxiliar = subscriptionsFilter.current[objFilterKey] ? [...subscriptionsFilter.current[objFilterKey], value] : [value];

      if (checked) {
        subscriptionsFilter.current = { ...subscriptionsFilter.current, [objFilterKey]: auxiliar };
      } else {
        const filtered = auxiliar.filter((aux) => aux !== value);
        subscriptionsFilter.current = { ...subscriptionsFilter.current, [objFilterKey]: filtered };
      }
    },
    [subscriptionsFilter]
  );

  return (
    <AdvancedFilter
      onChangeMainInput={(newValue) => handleOnChangeMainInput && handleOnChangeMainInput(newValue)}
      handleApplyFilter={handleOnClickApplyFilterCallback}
      handleResetFilter={handleResetFilter}
      applyFilterButtonBackground="linear-gradient(45deg, #482B85 0%, #6749BA 100%)"
    >
      <SubscriptionsAdvancedFilterContainer>
        <AccordionInfoItem status="quaternary" title="Turno">
          {SHIFT_CONSTRAINTS.map((s) => (
            <Checkbox
              key={s.value}
              defaultChecked={!!subscriptionsFilter.current.shifts?.find((shift) => shift === s.value)}
              id={s.value}
              name={s.name}
              label={s.name}
              semiRound
              icon={BsCheckLg}
              hideIconUncheck
              onChange={(event) => {
                handleClickFilterCheckbox(event, 'shifts');
              }}
            />
          ))}
        </AccordionInfoItem>

        <AccordionInfoItem status="quaternary" title="Tipo da Turma">
          {CLASS_TYPE_CONSTRAINTS.map((s) => (
            <Checkbox
              key={s.value}
              defaultChecked={!!subscriptionsFilter.current.types?.find((type) => type === s.value)}
              id={s.value}
              name={s.name}
              label={s.name}
              semiRound
              icon={BsCheckLg}
              hideIconUncheck
              onChange={(event) => {
                handleClickFilterCheckbox(event, 'types');
              }}
            />
          ))}
        </AccordionInfoItem>

        <AccordionInfoItem status="quaternary" title="Grupo Etário">
          {AGE_GROUP_CONSTRAINTS.map((s) => (
            <Checkbox
              key={s.value}
              defaultChecked={!!subscriptionsFilter.current.age_groups?.find((age_group) => age_group === s.value)}
              id={s.value}
              name={s.name}
              label={s.name}
              semiRound
              icon={BsCheckLg}
              hideIconUncheck
              onChange={(event) => {
                handleClickFilterCheckbox(event, 'age_groups');
              }}
            />
          ))}
        </AccordionInfoItem>
      </SubscriptionsAdvancedFilterContainer>
    </AdvancedFilter>
  );
};

export { SubscriptionsAdvancedFilter };
