import { styled } from 'react-alicerce-components';

export const LearningTargetsStudentCardBorderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #e9d7f9;
  min-height: 40px;
  align-items: center;
  justify-content: space-around;
  padding: 10px;

  .button-application-pending {
    border-top: 2px solid #6749ba;
    margin-top: 1rem;
    width: 95%;
    button {
      display: flex;
      margin: 1rem auto 0 auto;
      span {
        white-space: wrap;
      }
    }
  }
`;

export const AplicationInfoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const ValidatedDays = styled.div`
  p {
    font-size: smaller;
  }

  b {
    font-size: smaller;
  }
  max-width: 8rem;
`;

export const ApplicationTypeContainer = styled.div`
  max-width: 8rem;
`;

export const ApplicationStatusContainer = styled.div`
  max-width: 8rem;
`;

export const ValidationTypeStatusContainer = styled.div`
  border-top: 2px solid #6749ba;
  margin-top: 1rem;
  padding-top: 1rem;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  b {
    width: 30px;
    font-size: smaller;
  }

  Pill {
    max-width: 8rem;
  }
`;
