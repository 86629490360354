import React from 'react';
import Icon from 'react-eva-icons';

import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import { Container, Time, Date, Break } from './styles';

export interface IDateTimeRenderProps {
  timeStart?: string;
  timeEnd?: string;
  timeBreak?: string;
  date?: string;
  days?: string;
  icon?: string;
  iconColor?: string;
  status?: ElementStatus;
  color?: string;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  className?: string;
}

const DateTimeRender: React.FC<IDateTimeRenderProps> = ({
  children,
  timeStart,
  timeEnd,
  timeBreak,
  date,
  days,
  icon = 'clock',
  status = 'basic',
  color = '#222B45',
  canDeleteCard,
  className,
  ...props
}) => {
  return (
    <Container status={status} color={color} {...props} className={`date-time-render ${className ? className : ''}`}>
      <div className="time-container">
        <div>
          <Icon name={icon} />
        </div>
        <div>
          <Time>
            <span className="start">{timeStart}</span>
            {timeEnd && <span className="end">{timeEnd}</span>}
          </Time>
          <Break>{timeBreak && <span className="break">Intervalo: {timeBreak}</span>}</Break>
          <Date>{date || days}</Date>
          {children}
        </div>
      </div>
      {canDeleteCard && (
        <div className="remove-card" onClick={() => canDeleteCard.removeCallback()}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </Container>
  );
};

export default DateTimeRender;
