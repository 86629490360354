import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'react-alicerce-components';

import { ILocationState } from '@shared/interfaces/ILocationState';
import Layout from '@modules/tasks/components/Layout';
import TasksListManager from '@modules/tasks/components/TasksListManager';
import PageFooter from '@shared/components/PageFooter';

const TasksPendingList: React.FC = () => {
  const navigate = useNavigate();

  const { state } = useLocation() as { state: ILocationState };

  return (
    <Layout openTab={0}>
      <TasksListManager dateKey={'end_date'} prefixDate="Concluir em:" {...state} status="DOING" />
      <PageFooter>
        <Button status="quaternary" transform="none" noBorder shaded onClick={() => navigate(`/tarefas/criar`, { state })}>
          Criar Tarefa
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default TasksPendingList;
