import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: #f7f9fc;
  border: 1px solid #8f9bb3;
  border-radius: 5px;

  .schedule-problem {
    margin: 1rem auto;
  }
`;

// export const ButtonsContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;

//   button {
//     width: 40%;
//   }
// `;

// export const ProblemsListContainer = styled.div`
//   background: #f7f9fc;
//   border: 1px solid #8f9bb3;
//   box-sizing: border-box;
//   border-radius: 5px;
//   margin-top: 2rem;
//   padding: 16px 12px;
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// `;
