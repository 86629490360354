import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from './styles';

import { useLaunchers } from '@modules/launchers/context/LaunchersContext';
import WrapperContent from '@modules/launchers/components/WrapperContent';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { TrailDesign } from '@shared/components/TrailDesign';

import { Spinner } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import GetClassService from '@modules/classes/services/GetClassService';
import { ITrails } from '@shared/interfaces/ITrail';

const TrailSelection: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { id } = useParams<{ id: string }>();

  const { trails, setCurrentClass, currentClass } = useLaunchers();

  const getClass = useCallback(async () => {
    if (!id) return navigate(`/lancador/avaliacao/contexto/turmas`);
    setIsLoading(true);
    const res = await new GetClassService(signedUser?.token, newCancelToken()).execute(id);
    setIsLoading(false);
    if (!res) return navigate('/minhas-turmas');
    setCurrentClass(res);
  }, [id, navigate, newCancelToken, setCurrentClass, setIsLoading, signedUser]);

  const normalizeStrToParams = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  const redirect = (trailParam: string, id: string) => {
    const stringNormalize = normalizeStrToParams(trailParam);
    navigate(`/lancador/minimapa/${stringNormalize}/${id}`);
  };

  const getTrailsByClassType = (currentClassType: string, trails: ITrails[]) => {
    if (currentClassType === 'english') return trails.filter((trail) => trail.name === 'Inglês');
    if (currentClassType === 'core') return trails.filter((trail) => trail.name === 'Matemática' || trail.name === 'Leitura');
    return trails;
  };

  useEffect(() => {
    if (!id) return;
    getClass();
  }, [id, getClass]);

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title={`MiniMapa`}
        backgroundHeader="linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'MiniMapaLauncher' },
        }}
      />

      <DirectoryContainer style={{ marginTop: '0', paddingTop: 0 }} footer={false}>
        <WrapperContent>
          {currentClass?.type &&
            getTrailsByClassType(currentClass?.type, trails).map((trail, i) => (
              <div className="trail-box" key={`trail-design-${i}`}>
                <TrailDesign title={`MiniMapa`} trail={trail} marginBottom="2rem" handleOnClick={() => redirect(trail.name, id!)} />
              </div>
            ))}
        </WrapperContent>
      </DirectoryContainer>
    </Container>
  );
};

export { TrailSelection };
