import { styled } from 'react-alicerce-components';

export const TagInfoFormContainer = styled.div`
  > div {
    .container-checkbox {
      display: flex;
      justify-content: space-between;
      .checkbox-container {
        margin: 1.5rem 0;
      }
    }
  }
`;
