import IRoute from '@shared/interfaces/IRoute';

import UserDirectory from '@modules/users/views/UserDirectory';
import UserInfo from '@modules/users/views/UserInfo';
import UserHistory from '@modules/users/views/UserHistory';
import UserEdit from '@modules/users/views/UserEdit';
import { UserProvider } from '@modules/users/context/UserContext';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { usersKeys } from '@modules/users/keys';
import { groupsKeys } from '@modules/groups/keys';
import { placesKeys } from '@modules/places/keys';
import { classesKeys } from '@modules/classes/keys';
import UserCreate from '../views/UserCreate';

const routes: IRoute[] = [
  {
    path: '/diretorio/usuarios',
    key: 'DIR_USERS_PATH',
    exact: true,
    component: UserDirectory,
    permissions: [usersKeys.LMS_USER_READ],
  },
  {
    path: '/usuario/:id/informacoes',
    key: 'INFO_USERS_PATH',
    exact: true,
    component: UserInfo,
    permissions: [
      usersKeys.LMS_USER_READ,
      groupsKeys.GROUP_READ, 
      placesKeys.PLACE_READ, 
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: '/usuario/:id/historico',
    key: 'HISTORY_USERS_PATH',
    exact: true,
    component: UserHistory,
    permissions: 'public', // TO DO
  },
  {
    path: '/diretorio/usuario/criar',
    key: 'STUDENT_CREATE_PATH',
    exact: true,
    component: UserCreate,
    permissions: [
      usersKeys.USER_CREATE,
      usersKeys.USER_PROFILE_CREATE
    ],
  },
  {
    path: '/usuario/:id/editar',
    key: 'EDIT_USERS_PATH',
    exact: true,
    component: UserEdit,
    permissions: [
      usersKeys.LMS_USER_UPDATE
    ],
  },
];

const providers = [UserProvider];

export default {
  key: 'USERS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
