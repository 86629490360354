import { IDailyClass } from '../../attendances/interfaces/IDailyClass';

export const formatDailyClass = (dailyClass?: IDailyClass) => {
  if (!dailyClass) return {};
  return {
    start_date: dailyClass.start_date,
    place_id: dailyClass.place_id ? dailyClass.place_id : undefined,
    start_time: dailyClass.start_time && dailyClass.start_time.length > 5 ? dailyClass.start_time.slice(0, 5) : dailyClass.start_time,
    end_time: dailyClass.end_time && dailyClass.end_time.length > 5 ? dailyClass.end_time.slice(0, 5) : dailyClass.end_time,
    teachers_id: dailyClass.plan?.teachers ? dailyClass.plan?.teachers.map((teacher) => teacher.id ) : undefined
  };
};
