import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IDailyClass } from '../../attendances/interfaces/IDailyClass';

export interface IDailyClassPlanResponse extends IPaginatedResponse {
  items: IWeekDailyClasses[];
}

export type IWeekDailyClasses = {
  weekDailyClasses: IDailyClass[];
  weekStartsOn: string;
};

class ListDailyClassesPlanService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query: string = '', options: IQueryOptions = {}, classId: string): Promise<IDailyClassPlanResponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options });
      const res = await this.api.get(`/daily-class-plan/list/${classId}?${searchParams}`); 
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListDailyClassesPlanService;
