import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Icon from 'react-eva-icons';

import { Button, Checkbox, Input, Label, useToast } from 'react-alicerce-components';
import returnStringFunction from '@shared/utils/returnStringFunction';
import Divider from '@shared/components/Divider';

import { getFilters } from '@modules/applications/utils/getFilters';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { ListTrailsService } from '@shared/services/ListTrailsService';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import {
    Container,
    FilterContainer,
    CheckboxContainer,
    DateFilterContainer
} from './styles';


export interface IInputDateFilter {
    applied_between: { start: string; end: string; };
    started_between: { start: string; end: string; };
    finalized_between: { start: string; end: string; };
}

export interface IInputFilterData {
    types?: object;
    trails?: any[];
    age_groups?: object;
    dateFilter?: IInputDateFilter;
}

interface InputFilterProps {
    filters?: string[]; // 'age_group' | 'applied_between' | 'started_between' | 'finalized_between' | 'type' | 'trails'
    handleOnFilterOptions?: (data: IInputFilterData) => void;
    handleOnChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFilter: React.FC<InputFilterProps> = ({
    handleOnChange,
    handleOnFilterOptions,
    filters = []
}) => {

    const { addToast } = useToast();
    const { newCancelToken } = useCancelRequest();
    const { signedUser } = useAuth();
    const { setIsLoading } = useGlobal();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [ageGroups, setAgeGroups] = useState<object>();
    const [types, setTypes] = useState<object>();
    const [trails, setTrails] = useState<any[]>();
    const [dateFilter, setDateFilter] = useState<IInputDateFilter>({
        applied_between: { start: '', end: '' },
        started_between: { start: '', end: '' },
        finalized_between: { start: '', end: '' },
    });

    const defaultProps = useMemo(() => ({
        hideIconUncheck: true,
        semiRound: true,
        status: 'basic',
    }), []);

    const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        event.persist();
        setDateFilter((oldState: any) => ({ ...oldState, [name]: { ...oldState[name], [event.target.name]: event.target.value } }));
    }, []);

    const handleChangeAgeGroups = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setAgeGroups((oldState: any) => ({ ...oldState, [event.target.name]: event.target.checked }));
    }, []);

    const handleChangeTypes = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const type = event.target.name.toUpperCase();
        setTypes((oldState: any) => ({ ...oldState, [type]: event.target.checked }));
    }, []);

    const handleChangeTrails = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setTrails((oldState: any) => (oldState.map((trail) => `${event.target.id}` !== `${trail.id}` ? trail : { ...trail, checked: event.target.checked })));
    }, []);

    const handleFilter = useCallback(() => {

        let isValidDates = true;
        Object.entries(dateFilter).forEach(([key, dates]) => {
            const toastError = {
                status: 'danger',
                title: `Data ${key === 'applied_between' ? 'Aplicada' : (key === 'started_between' ? 'Inicada' : 'Finalizada')}`
            }

            if ((!dates.start && dates.end) || (!dates.end && dates.start)) {
                addToast({ ...toastError, description: 'Data em branco.' });
                isValidDates = false;
                return;
            }

            if (new Date(dates.start) > new Date(dates.end)) {
                addToast({ ...toastError, description: 'Data de início é maior que data final.' });
                isValidDates = false;
                return;
            }
        });

        if (handleOnFilterOptions && isValidDates) {
            handleOnFilterOptions(getFilters({ age_groups: ageGroups, types, trails, dateFilter }));
        }

    }, [addToast, ageGroups, dateFilter, handleOnFilterOptions, trails, types]);


    const rendeAgeGroups = useMemo(() => {
        if (filters.includes('age_group'))
            return (
                <Fragment>
                    <Label>Grupos Etários:</Label>
                    <CheckboxContainer>
                        <Checkbox
                            {...defaultProps}
                            id='G1'
                            name='G1'
                            label='G1'
                            onChange={handleChangeAgeGroups}
                        />
                        <Checkbox
                            {...defaultProps}
                            id='G2'
                            name='G2'
                            label='G2'
                            onChange={handleChangeAgeGroups}
                        />
                        <Checkbox
                            {...defaultProps}
                            id='G3'
                            name='G3'
                            label='G3'
                            onChange={handleChangeAgeGroups}
                        />
                        <Checkbox
                            {...defaultProps}
                            id='G4'
                            name='G4'
                            label='G4'
                            onChange={handleChangeAgeGroups}
                        />
                        <Checkbox
                            {...defaultProps}
                            id='G5'
                            name='G5'
                            label='G5'
                            onChange={handleChangeAgeGroups}
                        />
                        <Checkbox
                            {...defaultProps}
                            id='G6'
                            name='G6'
                            label='G6'
                            onChange={handleChangeAgeGroups}
                        />
                    </CheckboxContainer>
                </Fragment>
            )
    }, [defaultProps, filters, handleChangeAgeGroups]);

    const renderTypes = useMemo(() => {
        if (filters.includes('type'))
            return (
                <Fragment>
                    <Label>Tipo:</Label>
                    <CheckboxContainer>
                        <Checkbox
                            {...defaultProps}
                            id='MAPA'
                            name='MAPA'
                            label='MAPA'
                            onChange={handleChangeTypes}
                        />
                        <Checkbox
                            {...defaultProps}
                            id='MiniMAPA'
                            name='MiniMAPA'
                            label='MiniMAPA'
                            onChange={handleChangeTypes}
                        />
                    </CheckboxContainer>
                </Fragment>
            )
    }, [defaultProps, filters, handleChangeTypes]);


    const renderTrails = useMemo(() => {
        if (filters.includes('trails'))
            return (
                <Fragment>
                    <Label>Trilhas do MAPA:</Label>
                    <CheckboxContainer>
                        {trails?.map(trail => (
                            <Checkbox
                                {...defaultProps}
                                key={`trail-${trail.id}`}
                                id={`${trail.id}`}
                                name={trail.name}
                                label={trail.name}
                                onChange={handleChangeTrails}
                            />
                        ))}
                    </CheckboxContainer>
                </Fragment>
            )
    }, [defaultProps, filters, handleChangeTrails, trails]);

    const renderAppliedBetween = useMemo(() => {
        if (filters.includes('applied_between'))
            return (
                <Fragment>
                    <Label>Aplicado entre:</Label>
                    <DateFilterContainer>
                        <Input
                            name="start"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event, 'applied_between')}
                            defaultValue={returnStringFunction(dateFilter?.applied_between.start)}
                            required
                        />
                        <Label>Até</Label>
                        <Input
                            name="end"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event, 'applied_between')}
                            defaultValue={returnStringFunction(dateFilter?.applied_between.end)}
                            required
                        />
                    </DateFilterContainer>
                </Fragment>
            )
    }, [dateFilter, filters, handleChangeInput]);

    const renderStartedBetween = useMemo(() => {
        if (filters.includes('started_between'))
            return (
                <Fragment>
                    <Label>Iniciado entre:</Label>
                    <DateFilterContainer>
                        <Input
                            name="start"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event, 'started_between')}
                            defaultValue={returnStringFunction(dateFilter?.started_between.start)}
                            required
                        />
                        <Label>Até</Label>
                        <Input
                            name="end"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event, 'started_between')}
                            defaultValue={returnStringFunction(dateFilter?.started_between.end)}
                            required
                        />
                    </DateFilterContainer>
                </Fragment>
            )
    }, [dateFilter, filters, handleChangeInput]);

    const renderFinalizedBetween = useMemo(() => {
        if (filters.includes('finalized_between'))
            return (
                <Fragment>
                    <Label>Finalizado entre:</Label>
                    <DateFilterContainer>
                        <Input
                            name="start"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event, 'finalized_between')}
                            defaultValue={returnStringFunction(dateFilter?.finalized_between.start)}
                            required
                        />
                        <Label>Até</Label>
                        <Input
                            name="end"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event, 'finalized_between')}
                            defaultValue={returnStringFunction(dateFilter?.finalized_between.end)}
                            required
                        />
                    </DateFilterContainer>
                </Fragment>
            )
    }, [dateFilter, filters, handleChangeInput]);

    const listTrails = useCallback(
        async (search = '', page = 0) => {
            if (!signedUser) return;
            setIsLoading(true);
            const res = await new ListTrailsService(signedUser.token, newCancelToken()).execute(search, { page });
            setIsLoading(false);
            if (!res || res === 'canceling') return;
            setTrails(res.items);
        },
        [newCancelToken, setIsLoading, signedUser]
    );

    useEffect(() => {
        listTrails();
    }, [listTrails]);

    return (
        <Container>
            <Input
                name="search"
                type="text"
                placeholder="Buscar"
                onChange={handleOnChange}
                end={
                    <div onClick={() => setIsShow(oldState => !oldState)}>
                        <Icon name="funnel" size="large" fill="#8F9BB3" />
                    </div>
                }
            />
            {isShow &&
                <FilterContainer>
                    <div className="content">
                        <div className='close' onClick={() => setIsShow(false)}>
                            <Icon name="close" size="large" fill="#2E3A59" />
                        </div>
                        {rendeAgeGroups}
                        {renderTypes}
                        {renderTrails}
                        {filters.includes('date') && <Divider width="100%" />}
                        {renderAppliedBetween}
                        {renderStartedBetween}
                        {renderFinalizedBetween}
                    </div>
                    <div className="footer">
                        <Button
                            status='quaternary'
                            transform="none"
                            noBorder
                            shaded
                            onClick={handleFilter}
                        >
                            Aplicar Filtros
                        </Button>
                    </div>
                </FilterContainer>
            }
        </Container>
    );
}

export default InputFilter;