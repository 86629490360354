import { IBlockInfo } from "../../../launchers/interfaces/IStudentsWithGrades";

export const groupByLevelId = (data: IBlockInfo[]): IBlockInfo[][] => {
    const result = {};

    data.forEach(item => {
        const levelId = item.level_id || '1';

        if (!result[levelId]) {
            result[levelId] = [];
        }
        result[levelId].push(item);
    });

    return Object.values(result);
}