import { useRef, useCallback } from "react";
import axios, { CancelTokenSource } from "axios";

const useCancelRequest = () => {
  const tokenRequest = useRef<CancelTokenSource>();

  const newCancelToken = useCallback(() => {
    tokenRequest.current && tokenRequest.current.cancel("Requisition cancelled");
    tokenRequest.current = axios.CancelToken.source();
    return tokenRequest.current;
  }, []);

  return { newCancelToken };
};

export default useCancelRequest;
