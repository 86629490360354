import React from 'react';
import { useParams } from 'react-router-dom';

import LayoutStudentApplication from '@modules/applications/components/LayoutStudentApplication';

import StudentApplicationListManager from '@modules/applications/components/StudentApplicationListManager';

const StudentFinalizedApplication: React.FC = () => {
  const { studentId } = useParams();

  return (
    <LayoutStudentApplication openTab={2}>
      <StudentApplicationListManager
        status="FINALIZED"
        filters={['type', 'trails', 'applied_between', 'finalized_between']}
        studentIds={[studentId as string]}
      />
    </LayoutStudentApplication>
  );
};

export default StudentFinalizedApplication;
