import styled from 'styled-components';

export const Container = styled.div`
  .textarea-container,
  .input-container {
    color: red;
    textarea::placeholder,
    > div input::placeholder {
      font-family: 'Open Sans Semibold';
      font-weight: 500;
      color: #c5cee0;
    }
  }
`;
