import { H6, Input, Textarea, Select, Label, Checkbox, ISelectOption, Button } from 'react-alicerce-components';
import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';

import { errorMessageBuilder, hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { ButtonAddFiles } from '@shared/components/ButtonAddFiles';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import CustomForm from '@shared/components/CustomForm';
import InputPill from '@shared/components/InputPill';
import Divider from '@shared/components/Divider';

import { RemoveOptionWrapper } from '@modules/activities-library/components/RemoveOptionWrapper';
import { defaultStudentAmmount } from '@modules/activities-library/utils/validStudentAmount';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { ActivityLibraryFormContainer, CheckboxContainer } from './styles';
import { FiCheck } from 'react-icons/fi';
import ListTagsService from '@modules/tags/services/ListTagsService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import ITag from '@modules/tags/interfaces/ITag';

import { defaultActivityType } from '@modules/activities-library/utils/validActivityType';
import { defaultActivityTheme, IGenericSelectOption } from '@modules/activities-library/utils/validActivityTheme';
import ListTagTreeByTagNameService from '@modules/tags/services/ListTagTreeByTagNameService';
import { COGNITIVE_TASKS_CONSTRAINTS } from '../../utils/validCognitiveTasks';
import { DowloadContainer } from '@modules/activities-portfolio/components/ActivityPortfolioForm/styles';
import TagsRender from '@modules/tags/components/TagsRender';

interface ActivityLibraryForm {
  errors: IInputErrorsFormat[];
  pathToCreateSkillRelation?: string;
  pathToCreateTagsRelation?: string;
  formAction: 'creating' | 'editing' | 'watching';
  downloadAllFilesCallback?: () => void;
  downlodSingleFileCallback?: (file: File | IAttachment) => void;
}

export interface IActivityTheme {
  mainThemes?: ITag[];
  subThemes?: ITag[];
  thematicTheme?: ITag[];
}

const ActivityLibraryForm: React.FC<ActivityLibraryForm> = ({
  errors,
  downloadAllFilesCallback,
  downlodSingleFileCallback,
  formAction,
  pathToCreateTagsRelation,
  ...props
}) => {
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();

  const { activityLibraryCurrent, setActivityLibraryCurrent } = useActivity();
  const [activityTypes, setActivityTypes] = useState<ITag[]>([]);
  const [activityTheme, setActivityTheme] = useState<IActivityTheme>();

  const redirectHandling = (params: string) => {
    if (params.includes('http')) return params;
    return `http://${params}`;
  };

  const isWatching = useMemo(() => formAction === 'watching', [formAction]);
  const containMedia = useMemo(() => !!activityLibraryCurrent?.attachments?.length, [activityLibraryCurrent]);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setActivityLibraryCurrent((oldState: IActivityLibrary) => {
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setActivityLibraryCurrent]
  );

  const handleAddToArray = useCallback(
    (inputName) => {
      setActivityLibraryCurrent((oldState: any) => {
        if (!oldState[inputName]) return { ...oldState, [inputName]: [''] };
        return { ...oldState, [inputName]: [...oldState[inputName], ''] };
      });
    },
    [setActivityLibraryCurrent]
  );

  const handleSelectChange = useCallback(
    (keyName: string, keyValue?: any) => {
      setActivityLibraryCurrent((oldState: IActivityLibrary) => {
        return { ...oldState, [keyName]: keyValue };
      });
    },
    [setActivityLibraryCurrent]
  );

  const handleOnChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, inputKey: string) => {
      const checked: boolean = event.target.checked;
      const value: string = event.target.id;
      const auxiliar: string[] = activityLibraryCurrent[inputKey] ? [...activityLibraryCurrent[inputKey]] : [];

      if (!checked) {
        let filtered = auxiliar.filter((s) => String(s) !== value);
        setActivityLibraryCurrent((oldState: IActivityLibrary) => {
          return { ...oldState, [inputKey]: filtered };
        });
      } else {
        auxiliar.push(value);
        setActivityLibraryCurrent((oldState: IActivityLibrary) => {
          return { ...oldState, [inputKey]: auxiliar };
        });
      }
    },
    [activityLibraryCurrent, setActivityLibraryCurrent]
  );

  const handleOnChangeInputOption = useCallback(
    (value: string, inputName, index: number) => {
      setActivityLibraryCurrent((oldState: any) => {
        let aux = [...oldState[inputName]];
        aux[index] = value;
        return { ...oldState, [inputName]: aux };
      });
    },
    [setActivityLibraryCurrent]
  );

  const handleRemoveOption = useCallback(
    (indexArray: number, objectKey: string) => {
      const filtered = removeItemFromArrayByIndex(indexArray, activityLibraryCurrent[objectKey] || []);
      setActivityLibraryCurrent((oldState: IActivityLibrary) => {
        return { ...oldState, [objectKey]: filtered };
      });
    },
    [activityLibraryCurrent, setActivityLibraryCurrent]
  );

  const handleOnAddNewFile = (files: (File | IAttachment)[]) => {
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      oldState['attachments'] = files;
      return { ...oldState };
    });
  };

  const handleOnRemoveFile = (indexToRemove: number) => {
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      oldState['attachments'] = oldState['attachments']?.filter((_, index) => Number(index) !== Number(indexToRemove));
      return { ...oldState };
    });
  };

  const getOptionsTree = useCallback(
    async (tag_name = '') => {
      if (!signedUser) return false;

      setIsLoading(true);
      const res = await new ListTagTreeByTagNameService(signedUser.token).execute({ tag_name });
      setIsLoading(false);
      if (!res) return false;

      return res ? res : false;
    },
    [setIsLoading, signedUser]
  );

  const getChildrenOptions = useCallback(
    async (tag_name = '', parent_id?: string) => {
      if (!signedUser) return false;
      if (!parent_id) return [];

      setIsLoading(true);
      const res = await new ListTagsService(signedUser.token).execute(tag_name, { parent_id });
      setIsLoading(false);

      if (!res || res === 'canceling') return false;

      return res?.items ? res.items : false;
    },
    [setIsLoading, signedUser]
  );

  const handleChangeTheme = useCallback(
    async (type: string, option: ISelectOption | null) => {
      const value = option?.value as ITag | undefined;

      setActivityLibraryCurrent((oldState: IActivityLibrary) => {
        oldState[type] = value;
        if (type === 'theme') {
          oldState['subtheme'] = undefined;
          oldState['thematic'] = undefined;
        } else if (type === 'subtheme') {
          oldState['thematic'] = undefined;
        }

        return { ...oldState };
      });

      const children = await getChildrenOptions(option?.name, value?.id);
      if (children) {
        setActivityTheme((oldState) => {
          if (!oldState) return oldState;
          if (type === 'theme') {
            oldState['subThemes'] = children;
            oldState['thematicTheme'] = [];
          } else if (type === 'subtheme') {
            oldState['thematicTheme'] = children;
          }

          return { ...oldState };
        });
      }
    },
    [getChildrenOptions, setActivityLibraryCurrent]
  );

  const loadActivityTypes = useCallback(async () => {
    const firstNode = await getOptionsTree('Tipo de Atividade');
    if (firstNode) setActivityTypes(firstNode);
  }, [getOptionsTree]);

  const loadActivityThemes = useCallback(async () => {
    const firstNode = await getOptionsTree('Temas de Atividade');
    if (firstNode) {
      const isThemeSelected = activityLibraryCurrent.theme ? String(activityLibraryCurrent.theme.id) : undefined;
      const isSubThemeSelected = activityLibraryCurrent.subtheme ? String(activityLibraryCurrent.subtheme.id) : undefined;

      const mainThemes = firstNode.filter((f) => f.node === 2);
      setActivityTheme({
        mainThemes: mainThemes ? mainThemes : [],
        subThemes: isThemeSelected ? firstNode.filter((f) => f.parent_id === isThemeSelected) : [],
        thematicTheme: isSubThemeSelected ? firstNode.filter((f) => f.parent_id === isSubThemeSelected) : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityLibraryCurrent.id, getOptionsTree]);

  useEffect(() => {
    loadActivityTypes();
    loadActivityThemes();
  }, [loadActivityThemes, loadActivityTypes]);

  return (
    <ActivityLibraryFormContainer>
      <CustomForm customInputColorText="#2E3A59">
        <H6 fontWeight={800} className="h6-activity-library first-h6-page">
          INFORMAÇÕES BÁSICAS
        </H6>
        <Input
          disabled={isWatching}
          label="Nome da Atividade:"
          name="title"
          placeholder="Adcione Nome da Atividade"
          required
          defaultValue={activityLibraryCurrent.title}
          onChange={(event) => handleChangeInput(event)}
          error={errorMessageBuilder('title', errors)}
          useTooltipError={false}
        />
        <Textarea
          disabled={isWatching}
          name="description"
          label="Objetivo da Atividade:"
          placeholder="Adicione Objetivo da Atividade"
          value={activityLibraryCurrent.description}
          rows={4}
          onChange={(event) => handleChangeInput(event)}
          useTooltipError={false}
          error={errorMessageBuilder('description', errors)}
          required
        />

        <CheckboxContainer>
          <Label className="required">Grupo Etário:</Label>
          <div className="checkboxes">
            {AGE_GROUP_CONSTRAINTS.map((age_group) => (
              <Checkbox
                disabled={isWatching}
                key={`${age_group.value}-${activityLibraryCurrent.age_groups}`}
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                semiRound
                id={age_group.value}
                name={age_group.name}
                label={age_group.name}
                defaultChecked={
                  activityLibraryCurrent.age_groups &&
                  !!activityLibraryCurrent.age_groups.find((activityAgeGroup) => activityAgeGroup === age_group.value)
                }
                onChange={(event) => handleOnChangeCheckbox(event, 'age_groups')}
              />
            ))}
          </div>
          {errorMessageBuilder('age_groups', errors) && <InputErrorMessage error={errorMessageBuilder('age_groups', errors) || ''} />}
        </CheckboxContainer>

        <Select
          disabled={isWatching}
          label="Quantidade de Alunos:"
          iconName="arrow-down"
          placeholder="Quantidade de Alunos"
          fullWidth
          required
          options={defaultStudentAmmount(activityLibraryCurrent.student_amount)}
          handleSelectChange={(option) => handleSelectChange('student_amount', option?.value)}
          error={errorMessageBuilder('student_amount', errors)}
        />

        <div className="container-split-info">
          <Input
            disabled={isWatching}
            label="Tempo de Duração:"
            name="duration"
            placeholder="00:00"
            mask="##:##"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={activityLibraryCurrent.duration}
            required
            error={errorMessageBuilder('duration', errors)}
            useTooltipError={false}
          />

          <div className="container-modality-checkbox">
            <Label className="required">Modalidade da Atividade: </Label>
            <div className="container-checkbox-options">
              <Checkbox
                disabled={isWatching}
                key={`inperson-${activityLibraryCurrent.modality?.length}`}
                id="inperson"
                name="Presencial"
                label="Presencial"
                semiRound
                icon={BsCheckLg}
                hideIconUncheck
                onChange={(event) => handleOnChangeCheckbox(event, 'modality')}
                defaultChecked={!!activityLibraryCurrent.modality?.find((m) => m === 'inperson')}
              />

              <Checkbox
                disabled={isWatching}
                key={`digital-${activityLibraryCurrent.modality?.length}`}
                id="digital"
                label="Digital"
                name="Digital"
                semiRound
                icon={BsCheckLg}
                hideIconUncheck
                onChange={(event) => handleOnChangeCheckbox(event, 'modality')}
                defaultChecked={!!activityLibraryCurrent.modality?.find((m) => m === 'digital')}
              />
            </div>
            {errorMessageBuilder('modality', errors) && <InputErrorMessage error={errorMessageBuilder('modality', errors) || ''} />}
          </div>
        </div>

        <Divider width="100%" />

        <H6 fontWeight={800} className="h6-activity-library">
          DETALHES DA ATIVIDADE
        </H6>

        <TagsRender
          tagCallback={isWatching ? undefined : (indexTag) => handleRemoveOption(indexTag, 'tags')}
          canRemoveTag={!isWatching}
          label="Tags: "
          status="info"
          tags={activityLibraryCurrent.tags}
          pathToRedirect={isWatching ? undefined : pathToCreateTagsRelation}
        />

        <div style={{ position: 'relative' }}>
          <Select
            disabled={isWatching}
            label="Tipo de Atividade: "
            placeholder="Escolha o Tipo de Atividade"
            iconName="arrow-down"
            options={defaultActivityType(activityLibraryCurrent.type, activityTypes)}
            error={errorMessageBuilder('type', errors)}
            handleSelectChange={(option) => handleSelectChange('type', option?.value)}
            fullWidth
            required
          />

          {!!activityTheme?.mainThemes?.length && (
            <Select
              disabled={isWatching}
              label="Tema:"
              placeholder="Escolha o Tema"
              iconName="arrow-down"
              options={defaultActivityTheme(activityLibraryCurrent.theme, activityTheme.mainThemes)}
              handleSelectChange={(option: null | IGenericSelectOption<ITag>) => handleChangeTheme('theme', option)}
              fullWidth
            />
          )}

          {!!activityTheme?.subThemes?.length && (
            <Select
              disabled={isWatching}
              label="Subtema:"
              placeholder="Escolha o Subtema"
              iconName="arrow-down"
              key={`${activityLibraryCurrent.subtheme?.name}`}
              options={defaultActivityTheme(activityLibraryCurrent.subtheme, activityTheme.subThemes)}
              handleSelectChange={(option: null | IGenericSelectOption<ITag>) => handleChangeTheme('subtheme', option)}
              fullWidth
            />
          )}

          {!!activityTheme?.thematicTheme?.length && (
            <Select
              disabled={isWatching}
              label="Temática:"
              placeholder="Escolha a temática"
              iconName="arrow-down"
              key={activityLibraryCurrent.thematic?.name}
              options={defaultActivityTheme(activityLibraryCurrent.thematic, activityTheme.thematicTheme)}
              handleSelectChange={(option: null | IGenericSelectOption<ITag>) => handleSelectChange('thematic', option?.value)}
              error={errorMessageBuilder('thematic', errors)}
              fullWidth
            />
          )}
        </div>

        <div style={{ position: 'relative' }} className="accordion-wrapper">
          <CheckboxContainer>
            <Label className="required">Tarefas Cognitivas:</Label>
            <div className="checkboxes">
              {COGNITIVE_TASKS_CONSTRAINTS.map((ct) => (
                <Checkbox
                  disabled={isWatching}
                  key={`${ct.value}-${activityLibraryCurrent.cognitive_tasks}`}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={ct.value}
                  name={ct.name}
                  label={ct.name}
                  defaultChecked={
                    activityLibraryCurrent.cognitive_tasks && !!activityLibraryCurrent.cognitive_tasks.find((cogtask) => cogtask === ct.value)
                  }
                  onChange={(event) => handleOnChangeCheckbox(event, 'cognitive_tasks')}
                />
              ))}
            </div>
            {errorMessageBuilder('cognitive_tasks', errors) && <InputErrorMessage error={errorMessageBuilder('cognitive_tasks', errors) || ''} />}
          </CheckboxContainer>

          <InputPill
            pathToRedirect={isWatching ? undefined : props.pathToCreateSkillRelation}
            label="Competências"
            inputPillData={activityLibraryCurrent.skills?.map((skill) => skill.name)}
            color="#C8FBF2"
            error={errorMessageBuilder('skills', errors)}
            removeCallback={isWatching ? undefined : (index) => handleRemoveOption(index, 'skills')}
          />
        </div>
        <Divider width="100%" />

        <H6 fontWeight={800} className="h6-activity-library">
          MOMENTOS DA ATIVIDADE
        </H6>

        <AccordionInfoItem
          status="info"
          title={`Materiais (${activityLibraryCurrent?.materials ? activityLibraryCurrent?.materials.length : 0})`}
          colorHeader="#DAE3F6"
          hasErrors={hasError(['materials'], errors)}
        >
          {!isWatching && <ButtonAddCard text="Adicionar Novo Material" handleOnClick={() => handleAddToArray('materials')} />}

          {activityLibraryCurrent.materials &&
            activityLibraryCurrent.materials.map((material, index) => (
              <RemoveOptionWrapper
                handleDeleteCallback={isWatching ? undefined : () => handleRemoveOption(index, 'materials')}
                key={`${index}-${activityLibraryCurrent.materials?.length}`}
              >
                <Input
                  disabled={isWatching}
                  name="materials"
                  placeholder="Adicione Material para Atividade"
                  defaultValue={material}
                  onChange={(event) => handleOnChangeInputOption(event.target.value, event.target.name, index)}
                  error={material.length !== 0 ? undefined : errorMessageBuilder('materials', errors)}
                  useTooltipError={false}
                />
              </RemoveOptionWrapper>
            ))}
        </AccordionInfoItem>

        <AccordionInfoItem
          status="info"
          title={`Momentos (${activityLibraryCurrent.moments ? activityLibraryCurrent.moments.length : 0})`}
          colorHeader="#DAE3F6"
          hasErrors={hasError(['moments'], errors)}
          isShow
        >
          {!isWatching && <ButtonAddCard text="Adicionar Novo Momento" handleOnClick={() => handleAddToArray('moments')} />}

          <Textarea
            disabled={isWatching}
            name="preparation"
            label="Preparação da Atividade:"
            placeholder="Adicione Preparação da Atividade"
            value={activityLibraryCurrent.preparation}
            rows={6}
            error={errorMessageBuilder('preparation', errors)}
            onChange={(event) => handleChangeInput(event)}
          />

          {activityLibraryCurrent.moments &&
            activityLibraryCurrent.moments.map((moment, index) => (
              <RemoveOptionWrapper
                handleDeleteCallback={isWatching ? undefined : () => handleRemoveOption(index, 'moments')}
                key={`${index}-${activityLibraryCurrent.moments?.length}`}
              >
                <div className="textarea-size">
                  <Textarea
                    disabled={isWatching}
                    required
                    label={`Momento ${index + 1}:`}
                    name="moments"
                    placeholder={`Adicione Momento ${index} da Atividade`}
                    rows={6}
                    value={moment}
                    onChange={(event) => handleOnChangeInputOption(event.target.value, event.target.name, index)}
                    error={moment.length !== 0 ? undefined : errorMessageBuilder('moments', errors)}
                    useTooltipError={false}
                  />
                </div>
              </RemoveOptionWrapper>
            ))}
        </AccordionInfoItem>

        <AccordionInfoItem
          status="info"
          title={`Links(${activityLibraryCurrent?.links ? activityLibraryCurrent?.links.length : 0}) e  
            Anexos(${activityLibraryCurrent.attachments ? activityLibraryCurrent.attachments.length : 0})`}
          colorHeader="#DAE3F6"
          hasErrors={hasError(['links'], errors)}
        >
          {!isWatching && (
            <Fragment>
              <ButtonAddCard text="Adicionar Novo Link" handleOnClick={() => handleAddToArray('links')} />

              {activityLibraryCurrent.links &&
                activityLibraryCurrent.links.map((link, index) => (
                  <RemoveOptionWrapper
                    handleDeleteCallback={() => handleRemoveOption(index, 'links')}
                    key={`${index}-${activityLibraryCurrent.links?.length}`}
                  >
                    <Input
                      name="links"
                      placeholder="Adicione Link á Atividade"
                      defaultValue={link}
                      onChange={(event) => handleOnChangeInputOption(event.target.value, event.target.name, index)}
                      error={link.length !== 0 ? undefined : errorMessageBuilder('links', errors)}
                      useTooltipError={false}
                    />
                  </RemoveOptionWrapper>
                ))}
            </Fragment>
          )}

          {isWatching &&
            activityLibraryCurrent?.links?.map((link, index) => (
              <a href={redirectHandling(link)} target="_blank" rel="noopener noreferrer" key={index}>
                <Input key={index} name="links" defaultValue={link} readOnly />
              </a>
            ))}
{/* @TODO: jaqueline - pode deixar visualizar o arquivo ou deixa como tava antes ? */}
          <ButtonAddFiles
            iconName="attach-2-outline"
            text="Anexar Arquivos"
            colorBackgroundIcon="#B85EC4"
            colorBackgroundText="#E9D7F9"
            seeFileButton
            filesToSave={activityLibraryCurrent.attachments}
            callbackFilesToSave={(files) => handleOnAddNewFile(files)}
            callbackFilesToRemove={isWatching ? undefined : (index) => handleOnRemoveFile(index)}
          />

          {isWatching && downloadAllFilesCallback && (
            <DowloadContainer containMedia={containMedia}>
              <Button onClick={downloadAllFilesCallback} status="info" transform="none" noBorder shaded>
                Baixar Tudo
              </Button>
            </DowloadContainer>
          )}
        </AccordionInfoItem>
      </CustomForm>
    </ActivityLibraryFormContainer>
  );
};

export { ActivityLibraryForm };
