import ITag from '@modules/tags/interfaces/ITag';

export const formatTagsSelect = (tags: ITag[], tagSelected?: number) => {
  return tags.map((t) => {
    return {
      value: String(t.id),
      name: String(t.name),
      selected: Number(tagSelected) === Number(t.id),
    };
  });
};
