import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import { IStudentWithGradesCharts } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { objectToQuery } from '@shared/utils/objectToQuery';

class ListStudentFromClassWithChartsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    student_name: string,
    classId: string | number,
    generate_grades: boolean,
    options?: IQueryOptions
  ): Promise<IStudentWithGradesCharts | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ student_name: !!student_name.length ? student_name : undefined, generate_grades, ...options }, true);

      const res = await this.api.get(`/student/class/${classId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListStudentFromClassWithChartsService;
