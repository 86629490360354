import React, { useState } from "react";
import Icon from 'react-eva-icons';
import { PaginatedInMemoryWrapper } from "./styles";
import { RadioOption } from "../RadioOption";
import { GroupedItems } from "./GroupedItems";
import { IBlockInfo } from "../../../modules/launchers/interfaces/IStudentsWithGrades";

type PaginationInMemoryProps = {
    data: IBlockInfo[][]
    dataCallback?: (event: any, object?: any) => void
    defaultSelected?: number
    metaBlockName?: string
}
export const PaginationInMemory = (props: PaginationInMemoryProps) => {
    const { data, dataCallback, defaultSelected, metaBlockName } = props
    const findIndexOfMetaBlock = (blockArray: IBlockInfo[]): number => {
        return blockArray.findIndex((block, index) => {
            if (block?.name === metaBlockName) {
                return true;
            }
            return false;
        });
    };
    let initialIndex = 0;
    for (let i = 0; i < data.length; i++) {
        const blockArray = data[i];
        const indexInBlock = findIndexOfMetaBlock(blockArray);
        if (indexInBlock !== -1) {
            initialIndex = i;
            break;
        }
    }
    const initialIndexState = initialIndex >= 0 ? initialIndex : 0
    const [currentGroupIndex, setCurrentGroupIndex] = useState(initialIndexState);

    const goToPreviousGroup = () => {
        setCurrentGroupIndex(Math.max(currentGroupIndex - 1, 0));
    };

    const goToNextGroup = () => {
        setCurrentGroupIndex(Math.min(currentGroupIndex + 1, data?.length - 1));
    };
    const blockColor = data[currentGroupIndex][0]?.color || '#B85EC4'

    return (
        <div>
            <PaginatedInMemoryWrapper bgColor={blockColor}>
                <div className="arrow" onClick={goToPreviousGroup}> <Icon name="arrow-ios-back-outline" fill="white" /></div>
                <div>Blocos</div>
                <div className="arrow" onClick={goToNextGroup}>
                    <Icon name="arrow-ios-forward-outline" fill="white" />
                </div>
            </PaginatedInMemoryWrapper>
            <GroupedItems
                data={data[currentGroupIndex] as any}
                render={(item => (
                    <RadioOption
                        radioWidth="fit-content"
                        name='block_id'
                        optionColor={item.color}
                        label={`${item?.name}`}
                        round
                        id={item.id}
                        defaultChecked={Number(defaultSelected) === Number(item.id)}
                        key={`${item.id}${item?.name}`}
                        onChange={(e) => dataCallback?.(e, item)}
                    />))} />
        </div>
    );
}