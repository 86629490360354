import styled from "styled-components";

export const ActivityPortfolioCardContainer = styled.div`
  position: relative;
  .card-footer {
    padding: 12px 10px;
  }

  .card-body {
    justify-content: flex-start;
    padding: 20px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .tag {
    border-radius: 4px;
    margin: 2.5px;
  }
`;