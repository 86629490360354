import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { H6, Button, ISelectedDaysChange, Select, Input, Spinner } from 'react-alicerce-components';

import Icon from 'react-eva-icons';

import { DaysOfWeekSelect } from '@shared/components/DaysOfWeekSelect';
import { CustomCalendar as Calendar } from '@shared/components/CustomCalendar';
import Divider from '@shared/components/Divider';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import CustomForm from '@shared/components/CustomForm';
import PageFooter from '@shared/components/PageFooter';

import { Container, HoursContainer } from './styles';
import { defaultShift } from '@modules/classes/utils/validData/validShift';
import { useNavigate } from 'react-router-dom';
import { calendarSchema } from '@modules/classes/utils/calendarSchema';
import { beforeSubmitForm, errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import PlaceCard from '@modules/places/components/PlaceCard';
import { useClass } from '@modules/classes/context/ClassContext';
import { ICalendarData } from '@modules/classes/interfaces/IClass';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import ListClassesByWeekdayService, { IWeekdayResponse } from '@modules/classes/services/ListClassesByWeekdayService';
import ConfirmModal from '@shared/components/ConfirmModal';
import { getWeekdayFromWeekNumber } from '@modules/classes/utils/getWeekdayFromWeekNumber';
import CalendarTodayClasse from '../CalendarTodayClasses';
import { checkTimePeriodConflicts, validatePeriodTime, sortTime } from '@shared/helpers/DayHourHelper';

export interface IDayHoursFormProps {
  pathToRedirect?: string;
  pathToSelectPlace?: string;
  handleSaveCallback: (newClass: ICalendarData) => void;
}

const initialState = { start: '', end: '', shift: '', week_days: [], name: '', conflict: false };

const DaysAndHourForm: React.FC<IDayHoursFormProps> = ({ pathToRedirect, handleSaveCallback }) => {
  const [newClass, setNewClass] = useState<ICalendarData>(initialState);
  const [todayClasses, setTodayClasses] = useState<ICalendarData[]>([]);
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setIsLoading, isLoading } = useGlobal();
  const { classCurrent } = useClass();
  const { signedUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classCurrent.place) navigate('/diretorio/turmas');
  }, [classCurrent.calendar_data, classCurrent.place, navigate]);

  const checkConflicts = useCallback((classToday: ICalendarData[], classNew: ICalendarData) => {
    const newCalendarData = checkTimePeriodConflicts(classToday, classNew);
    setTodayClasses(newCalendarData);
  }, []);

  const formatClassToClassData = useCallback((classToFormat: IWeekdayResponse) => {
    return classToFormat.items.map((c) => {
      return { ...c.calendar_data, conflict: false, name: c.name };
    });
  }, []);

  const handleSelectedDaysChange = useCallback(
    async (data: ISelectedDaysChange) => {
      if (data.dateCurrent === '') return;
      const weekday = getWeekdayFromWeekNumber(new Date(data.dateCurrent).getDay());
      if (!classCurrent.place || !classCurrent.place.id) return;
      setIsLoading(true);
      const res = await new ListClassesByWeekdayService(signedUser?.token).execute('', {
        size: 9999,
        place_id: classCurrent.place?.id,
        week_day: weekday,
      });
      setIsLoading(false);
      if (!res) return;
      const formatedDayClass = formatClassToClassData(res);
      setTodayClasses(formatedDayClass ? formatedDayClass : []);
      checkConflicts(formatedDayClass, newClass);
    },
    [classCurrent.place, setIsLoading, signedUser, formatClassToClassData, checkConflicts, newClass]
  );

  const handleSelectChange = useCallback((option, selectName) => {
    setNewClass((oldState: any) => {
      return { ...oldState, [selectName]: option.value };
    });
  }, []);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setNewClass((oldState: any) => {
        return { ...oldState, [inputName]: inputValue };
      });
      if (inputName === 'start') checkConflicts(todayClasses, { ...newClass, start: inputValue });
      else if (inputName === 'end') checkConflicts(todayClasses, { ...newClass, end: inputValue });
    },
    [checkConflicts, newClass, todayClasses]
  );

  const handleCheckboxDays = useCallback(
    (value: string, status: boolean) => {
      if (status) {
        setNewClass((oldState: any) => {
          return { ...oldState, week_days: [...oldState.week_days, value] };
        });
      } else {
        const newWeekDays = newClass.week_days.filter((day) => day !== value);
        setNewClass((oldState: any) => {
          return { ...oldState, week_days: newWeekDays || [] };
        });
      }
    },
    [newClass.week_days, setNewClass]
  );

  const canSaveCalendar = useCallback(async () => {
    console.log(newClass, 'create newClass body para backend');
    let formErros = await beforeSubmitForm(newClass, calendarSchema);
    const isTimeInvalid = validatePeriodTime({
      start: newClass.start,
      end: newClass.end,
    });

    if (isTimeInvalid) formErros = [...formErros, isTimeInvalid];
    setErros(formErros);
    if (formErros.length > 0) return;

    const conflictsCount = todayClasses.reduce((previousValue, currentValue) => {
      if (currentValue.conflict) return previousValue + 1;
      else return previousValue;
    }, 0);

    if (conflictsCount > 0) return setShowModal(true);

    handleSaveCallback(newClass);
    pathToRedirect ? navigate(pathToRedirect) : navigate(-1);
  }, [newClass, todayClasses, handleSaveCallback, navigate, pathToRedirect]);

  const handleConfirmModal = useCallback(() => {
    handleSaveCallback(newClass);
    pathToRedirect ? navigate(pathToRedirect) : navigate(-1);
  }, [handleSaveCallback, navigate, newClass, pathToRedirect]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory status="quaternary" title="Dias e Horários" pathToReturn={undefined} />
      <DirectoryContainer footer={true}>
        <CustomForm>
          {classCurrent.place && <PlaceCard place={classCurrent.place} isInputCard={true} customStyle={{ margin: '1rem' }} />}

          <Divider width="100%" />
          {classCurrent.place && (
            <Fragment>
              <H6 fontWeight={800} textAlign="center" status="quaternary">
                {classCurrent.name.length > 0 ? classCurrent.name : 'Nome da turma não inserido'}
              </H6>
              <Calendar handleSelectedDaysChange={handleSelectedDaysChange} />

              <Divider width="100%" />

              <H6 fontWeight={800} status="quaternary" margin="2rem 0">
                AULAS DO DIA
              </H6>
              {sortTime(todayClasses).map((c, i) => (
                <CalendarTodayClasse
                  id={`today-classes-${i}`}
                  key={`today-classes-${i}`}
                  name="today_classes"
                  status="quaternary"
                  todayClasse={c}
                  statusNumber={100}
                  isSelectedInitialState={c.conflict}
                />
              ))}

              <Divider width="100%" />

              <H6 fontWeight={800} status="quaternary" margin="2rem 0">
                HORÁRIOS
              </H6>

              <Select
                label="Turno:"
                iconName="arrow-down"
                placeholder="Escolha um Turno"
                handleSelectChange={(option) => handleSelectChange(option, 'shift')}
                options={defaultShift(newClass && newClass?.shift)}
                error={errorMessageBuilder('shift', errors)}
              />

              <HoursContainer>
                <Input
                  id="start"
                  name="start"
                  label="Início:"
                  placeholder="00:00"
                  mask="##:##"
                  end={<Icon name="clock" fill="#8F9BB3" />}
                  onChange={(event) => handleChangeInput(event)}
                  error={errorMessageBuilder('start', errors)}
                  useTooltipError={false}
                />
                <Input
                  id="end"
                  name="end"
                  label="Fim:"
                  mask="##:##"
                  placeholder="00:00"
                  end={<Icon name="clock" fill="#8F9BB3" />}
                  onChange={(event) => handleChangeInput(event)}
                  error={errorMessageBuilder('end', errors)}
                  useTooltipError={false}
                />

                <Input
                  id="break"
                  name="break"
                  label="Tempo de Intervalo:"
                  mask="##:##"
                  defaultValue={"00:00"}
                  placeholder="00:15"
                  end={<Icon name="clock" fill="#8F9BB3" />}
                  onChange={(event) => handleChangeInput(event)}
                  useTooltipError={false}
                />
              </HoursContainer>

              <DaysOfWeekSelect errors={errors} label="Repetir Horários:" onClickCheckbox={(value, status) => handleCheckboxDays(value, status)} />
            </Fragment>
          )}
        </CustomForm>

        <PageFooter>
          <Button transform="none" outline status="quaternary" shaded onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <Button transform="none" status="quaternary" shaded onClick={canSaveCalendar}>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#FF708D" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Este horário conflita com outras aulas, deseja continuar?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleConfirmModal}
      />
    </Container>
  );
};

export default DaysAndHourForm;
