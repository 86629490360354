import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { ISprint } from '../interfaces/ISprint';

class EditScheduleSprintService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(sprint: ISprint): Promise<ISprint | false> {
    try {
      const res = await this.api.put(`/sprint/${sprint.id}`, {
        name: sprint.name,
        description: sprint.description,
        start_date: sprint.start_date,
        end_date: sprint.end_date
      });

      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditScheduleSprintService;
