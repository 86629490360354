import React, { Fragment, useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import DirectoryContainer from '@shared/components/DirectoryContainer';

import ScheduleEventForm from '@modules/schedules/components/ScheduleEventForm';
import CreateScheduleEventService from '@modules/schedules/services/CreateScheduleEventService';
import { IEvent } from '@modules/schedules/interfaces/IEvent';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

const ScheduleEventCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const { state, clearScheduleEventCurrent } = useSchedules();

  const handleCreateEvent = useCallback(
    async (dataValidated: IEvent) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new CreateScheduleEventService(signedUser.token).execute(dataValidated);

      setIsLoading(false);

      if (!res) {
        addToast({
          status: 'danger',
          title: 'Evento',
          description: 'Falha ao tentar criar Evento. Tente novamente.',
        });
        return;
      }

      clearScheduleEventCurrent();
      navigate('/agenda', { state: state });
    },
    [signedUser, setIsLoading, clearScheduleEventCurrent, navigate, state, addToast]
  );

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Criar Evento" />
      <DirectoryContainer style={{ margin: '0', padding: '0 0 2rem 0' }} footer>
        <ScheduleEventForm handleOnFinish={handleCreateEvent} formRef={formRef} canUserEdit={true} />

        <PageFooter>
          <ButtonGradientOutline onClick={() => navigate('/agenda', { state: state })}>Cancelar</ButtonGradientOutline>

          <Button onClick={() => formRef?.current?.submitForm()} color="#fff" transform="none" status="basic" statusNumber={600} shaded>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Fragment>
  );
};

export default ScheduleEventCreate;
