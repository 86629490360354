import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';
import CustomTabs from '@shared/components/CustomTabs';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface IIndicatorsLayoutProps {
  openTab: number;
  footer?: boolean;
}

export const IndicatorsLayout: React.FC<IIndicatorsLayoutProps> = ({ openTab = 0, footer = false, children }) => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  const { resource, resourceId } = useParams();

  useEffect(() => {
    console.log(resourceId, 'resourceId');
  }, [resourceId]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Dash de Indicadores" pathToReturn={`/turma/${resourceId}/relatorios`} backgroundHeader="#8F9BB3" />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="ADERÊNCIA" onClick={() => navigate(`/dash/indicadores/aderencia/${resource}/${resourceId}`)} />
          <Tab title="APRENDIZAGEM" onClick={() => navigate(`/dash/indicadores/aprendizagem/${resource}/${resourceId}`)} />
          <Tab title="QUALIDADE" disabled onClick={() => navigate(`/dash/indicadores/qualidade/${resource}/${resourceId}`)} />
          <Tab title="NPS" disabled onClick={() => navigate(`/dash/indicadores/nps/${resource}/${resourceId}`)} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};
