import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { uuid } from 'uuidv4';

import { Container, THeadOverGroup, THead, TData } from './styles';

export interface IColumn {
  name: string;
  dataname: string;
  notCanSort?: boolean;
}

export interface IDynamicTableColumnsGroup {
  name?: string;
  columns: IColumn[];
  fixed?: boolean;
}

export interface IData {
  selected?: boolean;
  subItems?: any[];
  isOpen?: boolean;
  [key: string]: any;
}

export interface IDynamicTableSort {
  sortName?: string;
  sortByAsc?: boolean;
}

export interface IDynamicTableProps {
  columnsGroup: IDynamicTableColumnsGroup[];
  data: IData[];
  canSelect?: boolean;
  containSubItems?: boolean;
  handleClickRow?: (data: any) => void;
  handleClickSort?: (sort: IDynamicTableSort) => void;
  handleSubItems?: (data: any) => void;
}

const DynamicTable: React.FC<IDynamicTableProps> = ({
  columnsGroup,
  data,
  canSelect = false,
  containSubItems = false,
  handleClickRow,
  handleClickSort,
  handleSubItems,
}) => {
  const [columnsFormatted, setColumnsFormatted] = useState<IColumn[]>([]);
  const [columnsGroupFormatted, setColumnsGroupFormatted] = useState<IDynamicTableColumnsGroup[]>([]);
  const [sort, setSort] = useState<IDynamicTableSort>({});

  const handleOnClickColumnSort = useCallback((column: IColumn) => {
    const { dataname } = column;
    if (!column.notCanSort && dataname) {
      setSort((oldState) => {
        let aux = {};
        const isEqual = oldState.sortName === dataname;

        if (isEqual) {
          aux = { ...oldState, sortByAsc: !oldState.sortByAsc };
        }

        if (!isEqual) {
          aux = { sortName: dataname, sortByAsc: true };
        }

        return aux;
      });
    }
  }, []);

  const handleOnClickRow = useCallback(
    (data: any) => {
      if (canSelect && handleClickRow) {
        handleClickRow(data);
      }

      if (containSubItems && handleSubItems) {
        handleSubItems(data);
      }
    },
    [canSelect, containSubItems, handleClickRow, handleSubItems]
  );

  useEffect(() => {
    setColumnsGroupFormatted(() => {
      let fixed: IDynamicTableColumnsGroup[] = [];
      let notFixed: IDynamicTableColumnsGroup[] = [];
      columnsGroup.forEach((c) => {
        // console.log(c, fixed);
        fixed = c.fixed ? [...fixed, c] : fixed;
        notFixed = !c.fixed ? [...notFixed, c] : notFixed;
      });
      return [...fixed, ...notFixed];
    });
  }, [columnsGroup]);

  useEffect(() => {
    setColumnsFormatted(() => {
      let newState: IColumn[] = [];
      columnsGroupFormatted.map((c) => (newState = [...newState, ...c.columns]));
      return newState;
    });
  }, [columnsGroupFormatted]);

  useEffect(() => {
    if (handleClickSort) {
      handleClickSort(sort);
    }
  }, [handleClickSort, sort]);

  const getTotalColumnsFixed = useMemo(() => {
    let columns: IColumn[] = [];
    columnsGroupFormatted.forEach((c) => {
      if (c.fixed) {
        columns = [...columns, ...c.columns];
      }
    });
    return columns;
  }, [columnsGroupFormatted]);

  const isShowOverColumn = useMemo(() => !!columnsGroupFormatted.filter((c) => c.name).length, [columnsGroupFormatted]);

  return (
    <Container className="dynamic-table-container" totalColumnsFixed={getTotalColumnsFixed} canSelect={canSelect}>
      <div />
      <table>
        <thead>
          {isShowOverColumn && (
            <tr className="over-column">
              {columnsGroupFormatted.map((c, i) => (
                <THeadOverGroup key={`over-column-${i}`} quantityColumns={c.columns.length} isShow={!!c?.name}>
                  {c?.name}
                </THeadOverGroup>
              ))}
            </tr>
          )}
          <tr>
            {columnsFormatted.map((c, i) => (
              <THead
                key={i}
                notCanSort={c.notCanSort}
                isSorted={c?.dataname === sort.sortName}
                sortByAsc={sort.sortByAsc}
                onClick={() => handleOnClickColumnSort(c)}
                groupsWidth={c.dataname === 'group'}
              >
                <span>{c.name}</span>
              </THead>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <Fragment key={`${i}`}>
              <tr
                className={`${d?.subItems ? `contain-sub-items ${d.isOpen ? 'opened' : ''}` : ''} ${d?.selected ? 'selected' : ''}`}
                onClick={() => handleOnClickRow(d)}
              >
                {columnsFormatted.map((c, j, { length }) => (
                  <TData key={uuid()} bgColor={d[`${c.dataname}BgColor`]} groupsWidth={c.dataname === 'group'}>
                    <span>
                      {d[c.dataname || '']}
                      {j + 1 === length && c.dataname === 'percent' ? '%' : ''}
                    </span>
                  </TData>
                ))}
              </tr>
              {d.subItems &&
                d.isOpen &&
                d.subItems.map((s, j) => (
                  <tr key={uuid()}>
                    {columnsFormatted.map((c, j) => (
                      <TData key={uuid()} bgColor={s[`${c.dataname}BgColor`]}>
                        <span>{s[c.dataname || '']}</span>
                      </TData>
                    ))}
                  </tr>
                ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default DynamicTable;
