import React, { useCallback, useEffect, useRef, useState } from 'react';
import Layout from '@modules/students/components/Layout';
import { Label, P, Pagination, useToast } from 'react-alicerce-components';
import EventCard from '@shared/components/EventCard';
import { useParams } from 'react-router-dom';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { IHistoricReponse, ListHistoricService } from '@shared/services/ListHistoricService';
import useAuth from '@shared/store/auth/hook';
import { cleanObjectToQuery } from '@shared/utils/cleanObjetctToQuery';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';

import DateRangeFilter from '@shared/components/DateRangeFitler';

type IClassHistoricFilter = {
  created_at?: { start: string; end: string };
};

const StudentHistory: React.FC = () => {
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { setIsLoading } = useGlobal();
  const { id } = useParams();
  const [historicResponse, setHistoricResponse] = useState<IHistoricReponse>();
  const [inputSearch, setInputSearch] = useState<string>('');

  const historicFilters = useRef<IClassHistoricFilter>({
    created_at: { start: '', end: '' },
  });

  const listHistoric = useCallback(
    async (page: number = 0) => {
      if (!id) return;
      setIsLoading(true);

      const res = await new ListHistoricService(signedUser?.token).execute(
        'Student',
        Number(id),
        cleanObjectToQuery({ page, size: 10, ...historicFilters.current }),
        inputSearch
      );

      setIsLoading(false);

      if (!res) {
        addToast({
          status: 'danger',
          title: 'Histórico',
          description: 'Erro ao recupear histórico.',
        });
        return;
      }

      setHistoricResponse(res);
    },
    [addToast, id, inputSearch, setIsLoading, signedUser]
  );

  useEffect(() => {
    listHistoric();
  }, [listHistoric]);

  const historicDate = (createdDate: string) => {
    const date = new Date(createdDate);
    return new Intl.DateTimeFormat('pt-BR').format(date);
  };

  return (
    <Layout openTab={1} footer={false}>
      <AdvancedFilter
        onChangeMainInput={(newValue) => setInputSearch(newValue)}
        applyFilterButtonBackground="#57CCE0"
        handleApplyFilter={listHistoric}
      >
        <Label>Eventos Ocorridos entre:</Label>
        <DateRangeFilter filters={historicFilters}/>
      </AdvancedFilter>

      {historicResponse?.items.map((historic) => (
        <EventCard header={<h6>{historic.title}</h6>} status="secondary" date={historicDate(historic.created_at)} key={historic.id}>
          <P textAlign="center">{historic.description}</P>
        </EventCard>
      ))}

      {historicResponse && historicResponse.totalPages > 1 && (
        <div style={{ padding: '1rem' }}>
          <Pagination
            status="quaternary"
            totalOfPages={historicResponse.totalPages}
            currentPage={historicResponse.currentPage}
            callbackGetListData={(_, page) => listHistoric(page)}
          />
        </div>
      )}
    </Layout>
  );
};

export default StudentHistory;
