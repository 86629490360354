import { IProfile } from '@modules/users/interfaces/IProfile';

export const LMSUSER_CATEGORY_TEACHER = { name: 'Líder LMS', value: 'teacher' };
export const LMSUSER_CATEGORY_MANAGER = { name: 'Administrador LMS', value: 'manager' };

export const LMSUSER_CATEGORY_CONSTRAINTS = [LMSUSER_CATEGORY_TEACHER, LMSUSER_CATEGORY_MANAGER];

/* export const defaultLmsUserCategory = (lmsUserCategoryName?: string | null): ISelectOption[] | undefined => {
  return [
    { name: LMSUSER_CATEGORY_TEACHER.name, value: LMSUSER_CATEGORY_TEACHER.value, selected: lmsUserCategoryName === LMSUSER_CATEGORY_TEACHER.value },
    { name: LMSUSER_CATEGORY_MANAGER.name, value: LMSUSER_CATEGORY_MANAGER.value, selected: lmsUserCategoryName === LMSUSER_CATEGORY_MANAGER.value },
  ];
}; */

export const defaultLmsUserCategoryLms = (userProfiles?: IProfile[]) /* : ISelectOption[] | undefined */ => {
  return userProfiles?.map((profile) => {
    return { name: profile.name, value: profile.id };
  });
};

export const LMSUSER_CATEGORY_VALUES = LMSUSER_CATEGORY_CONSTRAINTS.map((a) => a.value);
