import axios, { AxiosInstance, CancelTokenSource } from "axios";

import ApiFactory from "@shared/factories/ApiFactory";

class EditReportService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(reportId: string, reportBody: any): Promise<boolean> {
    try {
      await this.api.put(`/report/${reportId}`, { ...reportBody });
      return true;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditReportService;