import { IGradeIndividualHistory } from "../interfaces/IGradeIndividualHistory";
import { format } from "date-fns";

interface IData {
  date: number;
  dateFormatted: string;
  level: number;
  color: string;
}

export interface ITrailData {
  data: IData[];
  color: string;
  name: string;
}

export type IFormattedIndividualLearningHistory = ITrailData[];

export const formatIndividualLearningHistory = (data: IGradeIndividualHistory[]): IFormattedIndividualLearningHistory => {

  const formattedDates = data.map((trail) => {

    const data = trail.data ? trail.data.map(d => {

      const convertDate = new Date(`${d.date}`);

      return {
        ...d,
        date: convertDate.getTime(),
        dateFormatted: format(convertDate, "dd/MM"),
        color: trail.color
      }

    }) : null;

    return {
      ...trail,
      data,
    } as ITrailData
  })

  return formattedDates;
}

