import { styled } from 'react-alicerce-components';

export const Container = styled.div`
   .main-navbar {
    height: 130px;
    align-items: flex-start;
  }

  .pagination-container {
    .pagination-content-icon {
      background-color: #b85ec4 !important;

      i svg {
        fill: #fff !important;
      }
    }
  }
`;
