import React from 'react';
import { P, Pagination } from "react-alicerce-components";
import { IHeadlightResponse } from "@modules/projects/services/Headlight/ListHeadlightsService";
import { DiagnosticContainer, SprintDiagnosticsContainer } from "./styles";
import { getDay } from '@shared/utils/getDay';

type DiagnosticsProps = {
    sprintHeadlights?: IHeadlightResponse;
    listHeadlights?: (page?: number) => Promise<void>
}

const Diagnostics: React.FC<DiagnosticsProps> = ({
    sprintHeadlights,
    listHeadlights
}) => {
    return (
        <SprintDiagnosticsContainer>
            {sprintHeadlights?.items?.map((h) => 
                <div>
                    <div>Diagnóstico realizado em: <span>{getDay(h.created_at)} | {h.period?.name}</span></div>
                    <div>Diagnóstico realizado por: <span>{h.creator?.name}</span></div>
                    <DiagnosticContainer>{h.diagnostics}</DiagnosticContainer>
                </div>
            )}

            {sprintHeadlights?.items?.length !== 0 ?
                <Pagination
                    totalOfPages={sprintHeadlights?.totalPages || 0}
                    currentPage={sprintHeadlights?.currentPage || 0}
                    callbackGetListData={async (_, page) => listHeadlights?.(page)}
                /> :
                <P textAlign='center' marginTop='1.5rem'>Nenhum dado para exibir.</P>
            }

        </SprintDiagnosticsContainer>
    );
};

export { Diagnostics };