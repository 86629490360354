import { styled } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

export const AdvancedFilterContainer = styled.div`
  margin: 1.5rem 0;

  .input-container {
    > label {
      color: #2e3a59;
      font-family: var(--secondary-font);
      line-height: 2rem;
      text-transform: initial;
      letter-spacing: 0;
      font-size: 13px;
    }
  }

  .label-container {
    color: #2e3a59;
    font-family: var(--secondary-font);
    font-weight: 700;
    text-transform: initial;
    letter-spacing: 0;
    font-size: 13px;
  }

  > .input-container {
    margin-bottom: 0 !important;
    > div {
      cursor: pointer;
      > input {
        cursor: pointer;
      }
    }
  }

  > div:nth-child(2) {
    .input-container {
      margin-bottom: 0 !important;
      > div {
        cursor: auto;
        > input {
          cursor: auto;
        }
      }
    }
  }
`;

interface IFilterContainer {
  isOpen: boolean;
  backgroundColorProp?: string;
  status?: ElementStatus;
}

export const FilterContainer = styled.div<IFilterContainer>`
  border-top: transparent;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  transition: 0.2s ease all;
  opacity: ${(p) => (p.isOpen ? '1' : '0')};
  visibility: ${(p) => (p.isOpen ? 'visible' : 'hidden')};
  margin-bottom: ${(p) => (p.isOpen ? '1.5rem' : '0')};
  border: ${(p) => (p.isOpen ? '2px solid #e4e9f2;' : 'none')};
  padding: ${(p) => (p.isOpen ? 'calc(5px + 1rem) 1rem 1rem 1rem' : '0rem 1rem')};
  height: ${(p) => (p.isOpen ? 'auto' : '0px')};

  .advanced-filter-children {
    margin: ${(p) => (p.isOpen ? '1rem 0rem' : 'none')};
  }

  .radio-button {
    padding: 0.5rem;
    display: flex;
    align-items: center;

    input {
      cursor: pointer;
    }

    label {
      margin-left: 0.5rem;
    }
  }

  .checkbox-section {
    display: flex;
    flex-wrap: wrap;
  }

  .filter-text {
    font-weight: 800;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2e3a59;

    > label i {
      display: flex;
      cursor: pointer;
      svg {
        width: 30px;
        height: 30px;
        fill: #2e3a59;
      }
    }
  }

  .button-add-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    button {
      margin: auto;
      padding: 0.7rem 1.4rem;
      &:hover {
        background: ${({ backgroundColorProp }) => backgroundColorProp && backgroundColorProp};
      }
    }
  }
`;
