import styled from 'styled-components';

export const Header = styled.div<{ isCanSelect?: boolean }>`
  background-color: #a35ec0;
  padding: 0.6rem;
  border-radius: 5px;
  z-index: 3;
  margin-bottom: 10px;

  h6 {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #fff;
  }
`;

export const Text = styled.div`
  color: #2e3a59;
  text-align: justify;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
`;
