import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Spinner, Textarea, formatDate } from 'react-alicerce-components';
import { Container } from './styles';
import Icon from 'react-eva-icons';

import { GetWorkedHourService } from '@modules/worked-hours/services/GetWorkedHourService';
import { useWorkedHour } from '@modules/worked-hours/context/WorkedHourContext';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import GroupCard from '@modules/groups/components/GroupCard';
import UserCard from '@modules/users/components/UserCard';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import Divider from '../../../../shared/components/Divider';
import ParentRender from '@shared/components/ParentRender';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import CustomForm from '@shared/components/CustomForm';
import InputPill from '@shared/components/InputPill';
import { getTime } from '@shared/utils/getTime';
import useAuth from '@shared/store/auth/hook';
import ClassCard from '../../../classes/components/ClassCard';

export const WorkedHoursReproved: React.FC = () => {
  const { isLoading, setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const { id } = useParams();

  const [workedHour, setWorkedHour] = useState<IWorkedHour>();
  const { setWorkedHourCurrent } = useWorkedHour();

  const getWorkedHour = useCallback(async () => {
    if (!signedUser || !id) return;
    setIsLoading(true);
    const res = await new GetWorkedHourService(signedUser.token).execute(id);
    if (res && isMounted()) {
      setWorkedHour(res);
      setWorkedHourCurrent(res);
    }
    isMounted() && setIsLoading(false);
  }, [id, isMounted, setIsLoading, setWorkedHourCurrent, signedUser]);

  useEffect(() => {
    getWorkedHour();
  }, [getWorkedHour]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aprovar Horas Trabalhadas"
        status="primary"
        backgroundHeader="linear-gradient(284.92deg, #F47920 0%, #0097B1 100%);"
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={false}>
        {workedHour && workedHour.issuer && workedHour.group && (
          <CustomForm status="primary">
            <ParentRender label="Hora Reprovada Por:" hasParent={true} required={false}>
              <UserCard user={workedHour?.reprover!} canSelect={false} isInputCard={true} />
            </ParentRender>
            <div className="container-date-hours">
              <div className="date-container">
                <Input
                  label="Data da Reprovação:"
                  name="date"
                  disabled
                  defaultValue={workedHour ? formatDate(new Date(`${workedHour?.completed_at}`), 'long') : ''}
                />
              </div>
              <div className="hour-container">
                <Input
                  disabled
                  label="Horário da Reprovação:"
                  name="hours"
                  mask="##:##"
                  placeholder="00:00h"
                  defaultValue={getTime(new Date(`${workedHour.completed_at}`))}
                  end={<Icon name="clock" fill="#8F9BB3" />}
                />
              </div>
            </div>
            {workedHour.justification && (
              <Textarea name="description" label="Motivo da Reprovação:" value={workedHour?.justification} rows={4} disabled />
            )}
            <Divider />
            <InputPill label="Modalidade da Categoria" inputPillData={workedHour?.category ? [workedHour?.category.name] : []} />
            {workedHour.modality && (
              <InputPill label="Modalidade da Categoria" inputPillData={workedHour?.modality ? [workedHour?.modality.name] : []} />
            )}
            <InputPill label="Projeto" inputPillData={workedHour?.project ? [workedHour?.project.name] : []} />
            <Textarea name="description" label="Detalhes da Hora:" value={workedHour?.description} rows={4} disabled />
            <ParentRender label="Nome do Usuário:" hasParent={true}>
              <UserCard user={workedHour?.issuer} canSelect={false} isInputCard={true} />
            </ParentRender>
            <ParentRender label="Grupo Relacionado:" hasParent={true} required={false}>
              <GroupCard inputCard={true} group={workedHour?.group} customStyle={{ margin: '1rem' }} />
            </ParentRender>
            {workedHour?.class &&
              <ParentRender label="Turma Relacionado:" hasParent={true} required={false}>
                <ClassCard inputCard={true} classInfo={workedHour?.class} customStyle={{ margin: '1rem' }} />
              </ParentRender>
            }
            <Input
              label="Data da Hora Lançada:"
              name="date"
              disabled
              defaultValue={workedHour ? formatDate(new Date(`${workedHour?.date}`), 'long') : ''}
            />
            <div className="container-add-hours">
              <Input
                disabled
                label="Horas Adicionadas:"
                name="hours"
                mask="##:##"
                placeholder="00:00h"
                defaultValue={convertMinutesToHour(workedHour?.total_minutes) + 'h'}
                end={<Icon name="clock" fill="#8F9BB3" />}
              />
            </div>
          </CustomForm>
        )}
      </DirectoryContainer>
    </Container>
  );
};
