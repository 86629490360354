import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IStudent } from '../interfaces/IStudent';
import IListOptions from '../../../shared/interfaces/IListOptions';

export interface IStudentReponse extends IPaginatedResponse {
  items: IStudent[];
}

interface IListStudentsOptions extends IListOptions {
  age_groups?: string[];
  status?: string[];
  relationshio_types?: string[];
  class_id?: string;
}

class ListStudentsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListStudentsOptions): Promise<IStudentReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      const res = await this.api.get(`/student?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListStudentsService;
