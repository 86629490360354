export const ACTIVITY_PROGRAM_STATUS_PRIVATE = { name: 'Privado', value: 'private' };
export const ACTIVITY_PROGRAM_STATUS_PENDING = { name: 'Pendente', value: 'pending' };
export const ACTIVITY_PROGRAM_STATUS_PUBLIC = { name: 'Público', value: 'public' };

export const ACTIVITY_PROGRAM_STATUS_CONSTRAINTS = [ACTIVITY_PROGRAM_STATUS_PRIVATE, ACTIVITY_PROGRAM_STATUS_PENDING, ACTIVITY_PROGRAM_STATUS_PUBLIC];

export type ACTIVITY_PROGRAM_STATUS = 'private' | 'public' | 'pending';

export const defaultActivityStatus = (activityStatus?: string | null) => {
  return [
    { name: ACTIVITY_PROGRAM_STATUS_PRIVATE.name, value: ACTIVITY_PROGRAM_STATUS_PRIVATE.value, selected: activityStatus === ACTIVITY_PROGRAM_STATUS_PRIVATE.value },
    { name: ACTIVITY_PROGRAM_STATUS_PENDING.name, value: ACTIVITY_PROGRAM_STATUS_PENDING.value, selected: activityStatus === ACTIVITY_PROGRAM_STATUS_PENDING.value },
    { name: ACTIVITY_PROGRAM_STATUS_PUBLIC.name, value: ACTIVITY_PROGRAM_STATUS_PUBLIC.value, selected: activityStatus === ACTIVITY_PROGRAM_STATUS_PUBLIC.value },
  ];
};
