import React, { useCallback, useState } from 'react';
import { ButtonFooterContainer, LearningTagertFormContainer } from './styles';
import { Button, H1, P, Textarea } from 'react-alicerce-components';
import * as Yup from 'yup';
import Icon from 'react-eva-icons';
import { ModalWindowsChildrenProps } from '../../../../shared/components/Modalv1';
import { IInputErrorsFormat, beforeSubmitForm, errorMessageBuilder } from '../../../../shared/utils/beforeSubmitForm';
import { MultiStepComponentProps } from '../../../../shared/components/MultiStep';

type IJustificationLessBlockTargetForm = {
  handleSave?: (value?: string) => any;
  isPreviousBlock?: boolean

} & ModalWindowsChildrenProps & MultiStepComponentProps

type JustificationMetaBlock = {
  justification: string | undefined;
};

export const JustificationLessBlockTargetForm: React.FC<IJustificationLessBlockTargetForm> = (props) => {
  const { setValueCallback, handleSubmit, onBack } = props;
  const [justificationForm, setJustificationForm] = useState<JustificationMetaBlock>();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>();

  const JustificationTargetSchema = Yup.object().shape({
    justification: Yup.string().required({ name: 'justification', message: 'O campo é obrigatório' }),
  });


  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setJustificationForm((oldState: any) => {
        return { ...oldState, [inputName]: inputValue };
      });
      setValueCallback && setValueCallback(({ justification: inputValue }))

    },
    [setValueCallback]
  );

  const canSubmit = async () => {
    let formErrors = await beforeSubmitForm(justificationForm, JustificationTargetSchema);
    setErrors(formErrors);
    if (formErrors.length !== 0) return;
    handleSubmit && handleSubmit()
  };

  return (
    <LearningTagertFormContainer>
      <div className="icon-container">
        <Icon name="alert-circle-outline" fill="#F2C94C" />
      </div>
      <H1 className="title">Deseja Confirmar Bloco Anterior ?</H1>
      <P className="description">{`Vimos que escolheu um bloco anterior. Poderia compartilhar a motivação para sua escolha?`}</P>
      <Textarea
        name="justification"
        placeholder="Adicione Motivo"
        rows={6}
        onChange={(value) => handleChangeInput(value)}
        useTooltipError={false}
        error={errorMessageBuilder('justification', errors)}
        required
      />
      <ButtonFooterContainer>
        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
          onClick={() => onBack?.()}
        >
          Voltar
        </Button>

        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
          onClick={canSubmit}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </LearningTagertFormContainer>
  );
};
