import IRoute from '@shared/interfaces/IRoute';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { ApplicationsProvider } from '@modules/applications/context/ApplicationsContext';

import StudentsApplications from '@modules/applications/views/StudentsApplications';
import StudentPedingApplication from '@modules/applications/views/StudentPedingApplication';
import StudentOnGoingApplication from '@modules/applications/views/StudentOnGoingApplication';
import StudentFinalizedApplication from '@modules/applications/views/StudentFinalizedApplication';
import ApplicationResume from '@modules/applications/views/ApplicationResume';
import ApplicationGrades from '@modules/applications/views/ApplicationGrades';

import { applicationsKeys } from '@modules/applications/keys';

const routes: IRoute[] = [
  {
    path: '/aplicacoes/turma/:classId',
    key: 'APPLICATIONS_PATH',
    exact: true,
    component: StudentsApplications,
    permissions: [
      applicationsKeys.APPLICATIONS_STUDENTS_READ,
    ]
  },
  {
    path: '/aplicacoes/turma/:classId/aluno/:studentId/pendentes',
    key: 'STUDENT_PENDING_APPLICATION_PATH',
    exact: true,
    component: StudentPedingApplication,
    permissions: [
      applicationsKeys.APPLICATIONS_READ,
    ]
  },
  {
    path: '/aplicacoes/turma/:classId/aluno/:studentId/andamento',
    key: 'STUDENT_DOING_APPLICATION_PATH',
    exact: true,
    component: StudentOnGoingApplication,
    permissions: [
      applicationsKeys.APPLICATIONS_READ,
    ]
  },
  {
    path: '/aplicacoes/turma/:classId/aluno/:studentId/finalizado',
    key: 'STUDENT_FINSHED_APPLICATION_PATH',
    exact: true,
    component: StudentFinalizedApplication,
    permissions: [
      applicationsKeys.APPLICATIONS_READ,
    ]
  },
  {
    path: '/aplicacoes/:applicationId/turma/:classId/aluno/:studentId/gabarito',
    key: 'APPLICATION_RESUME_PATH',
    exact: true,
    component: ApplicationResume,
    permissions: [
      applicationsKeys.APPLICATIONS_READ,
    ]
  },
  {
    path: '/aplicacoes/:applicationId/turma/:classId/aluno/:studentId/notas',
    key: 'APPLICATION_GRADES_PATH',
    exact: true,
    component: ApplicationGrades,
    permissions: [
      applicationsKeys.APPLICATIONS_READ,
    ]
  }
];

const providers = [ApplicationsProvider];

export default {
  key: 'APPLICATIONS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
