import React, { useCallback, useEffect, useRef, useState } from 'react';

import { P, Pagination, Select } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import InputFilter, { IInputFilterDate } from '@modules/tasks/components/InputFilter';
import TasksList from './TaskList';

import ListPendingTasksService, { ITasksReponse } from '@modules/tasks/services/ListPendingTasksService';

import { Container } from './styles';
import { ITask } from '@modules/tasks/interfaces/ITask';

type ISort = 'ASC' | 'DESC';
export type ITaskStatus = 'DOING' | 'LATE' | 'DONE';
export type ITaskMessageDateKey = 'start_date' | 'end_date' | 'updated_at';

interface ITasksListManagerProps {
  id: string | number;
  resource: string;
  status?: ITaskStatus;
  canConclude?: boolean;
  filterByConcluder?: boolean;
  handleOnClickTask?: (task: ITask) => void;
  dateBgColor?: string;
  dateKey: ITaskMessageDateKey;
  prefixDate: string;
}

type IListTasKFilter = {
  due_start_date?: string;
  due_end_date?: string;
  concluder_name?: string;
};

const TasksListManager: React.FC<ITasksListManagerProps> = ({
  id,
  resource,
  dateBgColor,
  canConclude = true,
  filterByConcluder,
  status = 'DOING',
  handleOnClickTask,
  dateKey,
  prefixDate,
}) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();

  const [sort, setSort] = useState<ISort>('DESC');
  const [tasks, setTasks] = useState<ITasksReponse>();
  const [search, setSearch] = useState<string>('');
  const taskFilter = useRef<IListTasKFilter>({});

  const listTasks = useCallback(
    async (page: number = 0) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new ListPendingTasksService(signedUser.token, newCancelToken()).execute(search, {
        sort,
        page,
        order: 'event.end_date',
        [resource]: id,
        status,
        ...taskFilter.current,
      });

      if (res === 'canceling') return;

      setIsLoading(false);

      if (!res) return;

      setTasks(res);
    },
    [id, newCancelToken, resource, search, setIsLoading, signedUser, sort, status]
  );

  const handleOnFilter = useCallback(
    (date: IInputFilterDate) => {
      if (date.start_date) taskFilter.current.due_start_date = date.start_date;
      if (date.end_date) taskFilter.current.due_end_date = date.end_date;
      listTasks();
    },
    [listTasks]
  );

  const handleOnChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  }, []);

  const handleConcluderNameChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      taskFilter.current.concluder_name = ev.target.value;
      listTasks();
    },
    [listTasks]
  );

  useEffect(() => {
    listTasks();
  }, [listTasks, sort]);

  return (
    <Container>
      <InputFilter
        handleOnChange={handleOnChange}
        handleOnFilterDate={handleOnFilter}
        handleOnFilterConcluder={handleConcluderNameChange}
        filterByConcluder={filterByConcluder}
      />

      <CustomForm style={{ padding: '.5rem' }}>
        <Select
          label="Ordernar por: "
          iconName="arrow-down"
          placeholder="Quantidade de Alunos"
          fullWidth
          options={[
            { name: 'Mais antigo', value: 'DESC', selected: sort === 'DESC' },
            { name: 'Mais novo', value: 'ASC', selected: sort === 'ASC' },
          ]}
          handleSelectChange={(option) => setSort(option?.value as ISort)}
        />

        {!tasks?.items.length && (
          <P textAlign="center" marginTop="1.5rem">
            Nenhuma tarefa para exibir
          </P>
        )}

        {tasks?.items && (
          <TasksList
            dateBgColor={dateBgColor}
            tasks={tasks?.items}
            handleOnConclude={listTasks}
            canConclude={canConclude}
            handleOnClickTask={handleOnClickTask}
            dateKey={dateKey}
            prefixDate={prefixDate}
          />
        )}
      </CustomForm>

      {tasks && tasks.totalPages > 1 && (
        <div style={{ padding: '1rem' }}>
          <Pagination
            status="quaternary"
            totalOfPages={tasks?.totalPages}
            currentPage={tasks?.currentPage}
            callbackGetListData={(_, page) => listTasks(page)}
          />
        </div>
      )}
    </Container>
  );
};

export default TasksListManager;
