import { DateHelper } from '@shared/helpers/DateHelper';
import { IPortfolioActivity } from '../interfaces/IPortfolioActivity';

export const formatPortfolioActivity = (portfolioActivity: IPortfolioActivity) => {
  return {
    activity_name: portfolioActivity.activity_name,
    description: portfolioActivity.description,
    comments: portfolioActivity.comments,
    delivery_date: portfolioActivity.delivery_date ? DateHelper.getUSDate(portfolioActivity.delivery_date) : undefined,
    type: portfolioActivity.type,
    students_ids: portfolioActivity.students?.length ? portfolioActivity.students?.map((s) => s.id) : undefined,
    links: portfolioActivity.links?.length ? portfolioActivity.links : undefined,
    attachments: portfolioActivity.attachments?.length ? portfolioActivity.attachments : undefined,
  };
};
