import React from 'react';
import {
    BoxContainer,
    Container,
    Header,
    Info,
    Category,
    Color,
    Description,
    ContainerList,
    Status
} from './styles';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import headlightsConfig from '@modules/projects/utils/headlightsConfig';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { getDay } from '@shared/utils/getDay';

type CategoryDescriptionProps = {
    sprintHeadlights?: IHeadlight[]
};

const CategoryDescription: React.FC<CategoryDescriptionProps> = ({ sprintHeadlights }) => {
    const { currentPeriod } = useHeadlights();

    return (
        <>
            {currentPeriod && (
                <Container>
                    <Header>
                        <h6>{currentPeriod?.name}</h6>
                        <p>{`(${getDay(currentPeriod?.start_date)} - ${getDay(currentPeriod?.end_date)})`}</p>
                    </Header>
                    <ContainerList>
                        {sprintHeadlights?.find((h) => h.period_id === currentPeriod?.id)
                            ?
                            sprintHeadlights?.find((h) => h.period_id === currentPeriod?.id)
                                ?.data?.map((d, i) => (
                                    <BoxContainer key={`block-description-${i}`}>
                                        <Color status={d?.status as ElementStatus} />
                                        <Info>
                                            <Category>{d?.category}</Category>
                                            <Description>{d?.category_description}</Description>

                                            <Status>{`${headlightsConfig[d?.status].label}:`}</Status>
                                            <Description>{d?.status_description}</Description>
                                        </Info>
                                    </BoxContainer>
                                ))
                            :
                            <p>Nenhum dado para exibir.</p>
                        }
                    </ContainerList>
                </Container>
            )}
        </>
    );
}

export { CategoryDescription };