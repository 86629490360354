import styled from 'styled-components';

interface IContainer {
  selected: boolean;
  isDisabled?: JSX.Element | boolean;
}

export const StudentCardContainerFooter = styled.div`
  display: flex;
  padding: 10px 8px 8px 8px;
  min-height: 34px;
  background-color: #5dc4ea;
  border-radius: 0 0 0.31rem 0.31rem;
`;

export const StudentCardContainerFooterStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 8px 8px 8px;
  min-height: 34px;
`;

export const PillWrapper = styled.div`
  > div {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Container = styled.div<IContainer>`
  position: relative;
  .custom-card {
    ${({ selected }) => selected && 'filter: drop-shadow(0px 0px 28px #49C56C);'}
    ${({ isDisabled }) => isDisabled && 'filter: grayscale(1); opacity: 0.5;'}
  }
`;
