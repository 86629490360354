import React from 'react';

import PlaceList from '@modules/places/components/PlaceList';

const PlaceDirectory: React.FC = () => {
  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Criar Local"
      canSelectPlace={undefined}
      pathRedirect="/diretorio/locais/criar"
      pathToReturn="/meus-locais"
    />
  );
};

export default PlaceDirectory;
