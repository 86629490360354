import React from 'react';
import { ContainerPageFooter } from './styles';

const PageFooter: React.FC<{ height?: string }> = ({ children, height }) => {
  return (
    <ContainerPageFooter height={height} className="container-page-footer">
      {children}
    </ContainerPageFooter>
  );
};

export default PageFooter;
