import React from 'react';
import { P, TextEllipsis } from 'react-alicerce-components';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { Container } from './styles';
import { useNavigate } from 'react-router-dom';
import { getDay } from '@shared/utils/getDay';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';
import { getTime } from '@shared/utils/getTime';

type IOccurrenceCardProps = ICustomCardProps & {
  occurrence: IOccurrence;
};

const OccurrenceCard: React.FC<IOccurrenceCardProps> = ({ occurrence, status = 'primary' }) => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate(`/ocorrencia/${occurrence.id}/informacoes`)}>
      <CustomCard
        status={status}
        header={
          <div className="container-header-occurrence">
            <div className="container-header-time">
              <span>{getDay(occurrence.date)}</span>
              <span>{getTime(occurrence.date)}</span>
            </div>
            <div className="received-header">
              <TextEllipsis lineClamp={1} lineHeight={1} boxOrient="horizontal">
                {occurrence.title}
              </TextEllipsis>
            </div>
          </div>
        }
      >
        <P textAlign="start">{occurrence.description}</P>
      </CustomCard>
    </Container>
  );
};

export default OccurrenceCard;
