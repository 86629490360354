import React, { RefObject, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Textarea, Input, H6, InputDatePicker } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import Divider from '@shared/components/Divider';

import { Container } from './styles';
import ScheduleEventCard from '../ScheduleEventCard';
import { beforeSubmitForm, errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { scheduleSprintSchema } from '@modules/schedules/utils/scheduleSprintSchema';
import { validateDateLessThanAnother } from '@shared/helpers/DateHelper';
import { extractDate } from '@modules/schedules/utils/extractDate';

interface ISprintFormProps {
  formRef?: RefObject<FormHandles>;
  handleOnFinish?: (tag) => void;
  canUserEdit: boolean;
}

const SprintForm: React.FC<ISprintFormProps> = ({ formRef, handleOnFinish, canUserEdit }) => {
  const { sprintCurrent, scheduleCurrent, setSprintCurrent } = useSchedules();
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setSprintCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setSprintCurrent]
  );

  const handleChangeDatePicker = useCallback(
    (inputValue: string, inputName: string) => {
      setSprintCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setSprintCurrent]
  );

  const handleSubimit = useCallback(
    async (data) => {
      let sprintEditOrCreate = { ...sprintCurrent, ...data } as ISprint;
      let formErros = await beforeSubmitForm(sprintEditOrCreate, scheduleSprintSchema);
      const isDatesInvalid = validateDateLessThanAnother({
        start: sprintEditOrCreate.start_date,
        end: sprintEditOrCreate.end_date,
      });

      if (isDatesInvalid) formErros = [...formErros, isDatesInvalid];

      setErros(formErros);
      if (formErros.length > 0) return;

      if (handleOnFinish) {
        handleOnFinish({
          ...sprintEditOrCreate,
          schedule_id: scheduleCurrent?.id,
          start_date: extractDate(sprintEditOrCreate.start_date),
          end_date: extractDate(sprintEditOrCreate.end_date),
        });
      }
    },
    [handleOnFinish, scheduleCurrent, sprintCurrent]
  );

  return (
    <Container>
      <CustomForm style={{ padding: '1rem' }}>
        <Form ref={formRef} onSubmit={handleSubimit}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <Input
            id="name"
            name="name"
            label="Titulo a Sprint:"
            placeholder="Adicione Nome a Sprint"
            onChange={(event) => handleChangeInput(event)}
            error={errorMessageBuilder('name', errors)}
            defaultValue={sprintCurrent?.name}
            useTooltipError={false}
            disabled={!canUserEdit}
          />

          <Textarea
            label="Descrição da Sprint:"
            name="description"
            placeholder="Adicione Descrição da Sprint"
            error={errorMessageBuilder('description', errors)}
            value={sprintCurrent?.description}
            useTooltipError={false}
            onChange={(event) => handleChangeInput(event)}
            rows={4}
            disabled={!canUserEdit}
          />

          <Divider width="100%" />

          <H6 fontWeight={800} status="basic">
            DATAS
          </H6>

          <InputDatePicker
            handleChangeDate={(date) => handleChangeDatePicker(date, 'start_date')}
            defaultValue={sprintCurrent?.start_date}
            disabled
            input={{
              label: 'Data de Início:',
              name: 'start_date',
              placeholder: 'Dia/Mês/Ano',
              required: true,
              useTooltipError: false,
              error: errorMessageBuilder('start_date', errors),
            }}
          />

          <InputDatePicker
            handleChangeDate={(date) => handleChangeDatePicker(date, 'end_date')}
            defaultValue={sprintCurrent?.end_date}
            disabled
            input={{
              label: 'Data de Fim:',
              name: 'end_date',
              placeholder: 'Dia/Mês/Ano',
              required: true,
              useTooltipError: false,
              error: errorMessageBuilder('end_date', errors),
            }}
          />

          <label className="error-dates">{errorMessageBuilder('dates', errors)}</label>

          {scheduleCurrent && scheduleCurrent.id && (
            <>
              <Divider width="100%" />
              <H6 fontWeight={800} status="basic">
                AGENDA RELACIONADA
              </H6>
              <ScheduleEventCard schedule={scheduleCurrent} />
            </>
          )}
        </Form>
      </CustomForm>
    </Container>
  );
};

export default SprintForm;
