import React from "react";
import Icon from 'react-eva-icons';
import { ButtonFooterContainer } from "../styles";
import { H1, P, Button } from "react-alicerce-components";
import { MultiStepComponentProps } from "../../../../shared/components/MultiStep";
import { ModalWindowsChildrenProps } from "../../../../shared/components/Modalv1";
import { ValidateLearningTargerFormWarningContainer } from "./styles";

type ValidateLearningTargerFormWarningFragmentProps = MultiStepComponentProps & ModalWindowsChildrenProps

export const ValidateLearningTargerFormWarningFragment = (props: ValidateLearningTargerFormWarningFragmentProps) => {
    const { onNext, onCloseModal } = props
    return (
        <ValidateLearningTargerFormWarningContainer>
            <div style={{ marginTop: '20px', marginBottom: '28px' }}>
                <div className='icon-container'>
                    <Icon name="alert-circle-outline" fill="#F2C94C" />
                </div>
                <H1 className='title'>Deseja Definir Meta?</H1>
                <P className='description'>Você tem certeza de que deseja definir a meta atual sem aplicar a avaliação para esse aluno? Você deverá indicar qual foi o recurso pedagógico utilizado para a escolha do bloco da meta.</P>
            </div>
            <ButtonFooterContainer>
                <Button
                    shaded
                    transform="none"
                    style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
                    onClick={() => onCloseModal?.()}
                >
                    Cancelar
                </Button>

                <Button
                    shaded
                    transform="none"
                    style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
                    onClick={onNext}
                >
                    Continuar
                </Button>
            </ButtonFooterContainer>
        </ValidateLearningTargerFormWarningContainer>
    );
}