import React, { useCallback } from 'react';
import { IUpdateTeachersStudentsForm, useClass } from '@modules/classes/context/ClassContext';
import StudentList from '@modules/students/components/StudentsList';
import { IStudent } from '@modules/students/interfaces/IStudent';
import IClass from '@modules/classes/interfaces/IClass';
import { IEnrollment } from '@modules/subscriptions/interfaces/IEnrollment';

const ClassStudentsRelation: React.FC = () => {
  const { setClassCurrent, classCurrent } = useClass();

  const setSelectedItens = useCallback(
    (students: IStudent[]) => {
      setClassCurrent((oldState: IClass & IUpdateTeachersStudentsForm) => {
        const updateStudents = oldState.update_students;
        const newStudents = students
          .filter((s) => !oldState.enrollments?.some((oldStudentID) => Number(oldStudentID.student?.id) === Number(s.id)))
          .map((student) => {
            return { student };
          }) as IEnrollment[];
        const newStudentsIds = newStudents.map((enrollment) => Number(enrollment.student?.id));
        const formatedOldEnrollments = oldState.enrollments?.flatMap((en) => (en.student ? {student: en.student} : []) ) as IEnrollment[]

        oldState.enrollments = oldState.enrollments ? [...formatedOldEnrollments, ...newStudents] : newStudents;

        return { ...oldState, update_students: { ...updateStudents, enroll: newStudentsIds } };
      });
    },
    [setClassCurrent]
  );

  return (
    <StudentList
      headerTitle="Diretório de Alunos"
      btnText="Adicionar Aluno"
      selectedItens={classCurrent.enrollments ? classCurrent.enrollments.flatMap((enrollment) => (enrollment.student ? enrollment.student : [])) : []}
      setSelectedItens={setSelectedItens}
      canSelectStudent="many"
      handleOnClick={setSelectedItens}
    />
  );
};

export { ClassStudentsRelation };
