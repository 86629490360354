import React from 'react';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';

import {
    Container,
    Header,
    Date,
} from './styles';

export type IEventCardProps = ICustomCardProps & {
    date?: string;
}

const EventCard: React.FC<IEventCardProps> = ({
    date,
    header,
    status = 'primary',
    ...rest
}) => {

    return (
        <Container className='event-card'>
            <CustomCard
                {...rest}
                status={status}
                header={
                    <Header status={status}>
                        <Date className='date-container'>{date}</Date>
                        <div className='received-header'>
                            {header}
                        </div>
                    </Header>
                }
            />
        </Container>
    );
}

export default EventCard;