import { H6 } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  .input-container {
    margin: 0;

    input {
      font-weight: bolder;
      color: #222b45;
    }
  }

  input + div {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: rgba(143, 155, 179, 0.16);
  }

  input:checked + div {
    border-color: transparent;

    svg {
      color: #fff;
    }
  }

  .container-daily-class {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .title-name-classes {
    font-weight: 800;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #542e91;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .container-retroactive-button {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    flex-direction: row;

    button {
      height: 40px;
      margin: unset;
      max-width: 280px;
      width: 280px;
    }
  }
  .label-related-users {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #2e3a59;
    margin-bottom: 1rem;
  }

  .container-select-all {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;

    > div label {
    margin: 0;
    input + div {
      margin: 0;
      width: 25px;
      height: 25px;
    }
  }
  }

  @media (max-width: 570px) {
    .container-retroactive-button {
      flex-direction: column;
    }
  }
`;

export const Label = styled(H6)`
  margin: 1rem 0;
  font-weight: 800;
  font-size: 14px;
  color: #482b85;
`;

export const ContainerAttendenceCheckbox = styled.div`
  /* .custom-card {
    > div {
      margin: 0;
    }
  }

  > div label {
    margin: 0;
    input + div {
      margin: 0;
      width: 25px;
      height: 25px;
    }
  }

  > div:first-child {
    flex: 1;
  }

  display: flex;
  margin: 2rem 0;
  column-gap: 10px; */
`;
