import React from 'react';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import IndividualLearningCurrent from '@modules/dashboards/components/IndividualLearningCurrent';
import IndividualLearningHistory from '@modules/dashboards/components/IndividualLearningHistory';

import { Container } from './styles';

const IndividualLearning: React.FC = () => {
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aprendizagem Individual"
        backgroundHeader='#8F9BB3'
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'IndividualLearning' },
        }}
      />
      <CustomTabs>
        <Tabs>
          <Tab title="ATUAL">
            <IndividualLearningCurrent />
          </Tab>
          <Tab title="HISTÓRICO">
            <IndividualLearningHistory />
          </Tab>
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default IndividualLearning;
