import React, { Fragment, useCallback, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Button, Spinner } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import { ActivityPortfolioForm } from '@modules/activities-portfolio/components/ActivityPortfolioForm';
import { useActivitiesPortfolio } from '@modules/activities-portfolio/context/ActivitiesPortfolioContext';
import { GetPortfolioActivitiesService } from '@modules/activities-portfolio/services/GetPortfolioActivitiesService';
const ActivitiesPortfolioInfo: React.FC = () => {
  const { setActivityPortfolioCurrent, activityPortfolioCurrent, clearPortfolioActivityCurrent } = useActivitiesPortfolio();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const { classId, portfolioActivityId } = useParams();

  const { signedUser } = useAuth();
  const { isLoading, setIsLoading, handleDownloadAllFiles, handleDownloadSingleFile } = useGlobal();
  const navigate = useNavigate();

  const handleClickGoBack = useCallback(() => {
    clearPortfolioActivityCurrent();
    navigate(`/portfolio-atividades/turma/${classId}`);
  }, [classId, clearPortfolioActivityCurrent, navigate]);

  const handleOnClickEdit = useCallback(() => {
    navigate(`/portfolio-atividades/${portfolioActivityId}/turma/${classId}/editar`);
  }, [classId, navigate, portfolioActivityId]);

  const getPortfolioActivity = useCallback(async () => {
    if (!portfolioActivityId) return;
    setIsLoading(true);
    const res = await new GetPortfolioActivitiesService(signedUser?.token, newCancelToken()).execute(portfolioActivityId);
    if (res === 'canceling' || !isMounted()) return;
    setIsLoading(false);

    if (!res) return console.log('Error ao Recuperar Atividade do Portfolio');
    setActivityPortfolioCurrent(res);
  }, [isMounted, newCancelToken, portfolioActivityId, setActivityPortfolioCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    getPortfolioActivity();
  }, [getPortfolioActivity]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Portfólio"
        backgroundHeader="linear-gradient(135deg, #FFBC6F 0%, #43A2E4 100%)"
        onClickReturn={handleClickGoBack}
        menuKebab={{ menuBackHome: true }}
      />

      <DirectoryContainer style={{ marginTop: '1rem', paddingTop: 0 }} footer>
        <ActivityPortfolioForm
          downlodSingleFileCallback={handleDownloadSingleFile}
          downloadAllFilesCallback={() => handleDownloadAllFiles(activityPortfolioCurrent.attachments)}
          formAction="watching"
          key={`${activityPortfolioCurrent}`}
          errors={[]}
        />

        <PageFooter>
          <Button status="info" disabled transform="none" noBorder shaded>
            Publicar no Aliquest
          </Button>
          <Button status="info" transform="none" noBorder shaded onClick={handleOnClickEdit}>
            Editar
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Fragment>
  );
};

export default ActivitiesPortfolioInfo;
