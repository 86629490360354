import React, { Fragment, useCallback, useState } from 'react';

import { Button, Spinner, useToast } from 'react-alicerce-components';

import { useStudent } from '@modules/students/context/StudentContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useNavigate } from 'react-router-dom';

import Icon from 'react-eva-icons';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { formatStudent } from '@modules/students/utils/formatStudent';
import StudentForm from '@modules/students/components/StudentForm';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { studentSchema } from '../../utils/studentSchema';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import CreateStudentService from '../../services/CreateStudentService';

const StudentCreate: React.FC = () => {
  const { studentCurrent, clearStudentContextData } = useStudent();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const refResponsiblePicking = React.createRef<any>();
  const refPersonalAcademicData = React.createRef<any>();
  const refAlicerceMainData = React.createRef<any>();
  const { setIsLoading, isLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { addToast } = useToast();
  const isMounted = useIsMounted();

  const updateAllHeigths = useCallback(() => {
    refResponsiblePicking.current.updateHeight();
    refPersonalAcademicData.current.updateHeight();
    refAlicerceMainData.current.updateHeight();
  }, [refAlicerceMainData, refPersonalAcademicData, refResponsiblePicking]);

  const handleCreateStudent = useCallback(async () => {
    if (!signedUser) return;
    setShowCreateModal(false);
    const formatedBody = formatStudent(studentCurrent, 'creating');
    console.log('edit student formated body => ', formatedBody);
    setIsLoading(true);
    const res = await new CreateStudentService(signedUser.token, newCancelToken()).execute(formatedBody);
    if (!isMounted) return;
    setIsLoading(false);

    if (res) {
      addToast({
        status: 'success',
        title: 'Alunos',
        description: 'Aluno criado com sucesso.',
      });
      return navigate(`/aluno/${res.res.id}/informacoes`);
    }

    addToast({
      status: 'danger',
      title: 'Alunos',
      description: 'Não foi possível Criar o Aluno.',
    });
  }, [signedUser, studentCurrent, setIsLoading, newCancelToken, isMounted, addToast, navigate]);

  const handleCancel = useCallback(() => {
    clearStudentContextData();
    navigate(`/aluno/${studentCurrent.id}/informacoes`);
  }, [clearStudentContextData, navigate, studentCurrent.id]);

  const canSaveStudent = useCallback(async () => {
    updateAllHeigths();
    const formatedBody = formatStudent(studentCurrent, 'creating');
    const formErros = await beforeSubmitForm(formatedBody, studentSchema);
    setErros(formErros);
    if (formErros.length === 0) setShowCreateModal && setShowCreateModal(true);
  }, [studentCurrent, updateAllHeigths]);

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory status="secondary" title="Criar Aluno" />
      <DirectoryContainer footer={true}>
        <StudentForm
          form_action="creating"
          errors={errors}
          refResponsiblePicking={refResponsiblePicking}
          refPersonalAcademicData={refPersonalAcademicData}
          refAlicerceMainData={refAlicerceMainData}
          pathOnboardingGroup="/diretorio/alunos/selecionar/onboarding/grupo"
          pathEnvolvedClasses="/diretorio/alunos/selecionar/turma"
        />
        <PageFooter>
          <Button transform="none" outline status="secondary" shaded onClick={handleCancel}>
            Cancelar
          </Button>
          <Button transform="none" status="secondary" shaded onClick={() => canSaveStudent()}>
            Salvar
          </Button>
        </PageFooter>
        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showCreateModal}
          closeCallback={() => setShowCreateModal(!showCreateModal)}
          title="Deseja Criar Aluno ?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={() => handleCreateStudent()}
        />
      </DirectoryContainer>
    </Fragment>
  );
};

export default StudentCreate;
