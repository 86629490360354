import React, { createContext, useCallback, useContext, useState } from 'react';
import { IGroup } from '@modules/groups/interfaces/IGroup';

export type IGroupContextData = {
  selectedGroup: IGroup | undefined;
  setSelectedGroup: (groupCurrent: IGroup | undefined) => void;
  groupCurrent: IGroup;
  setGroupCurrent: (value: any) => void;
  clearGroupContextData: () => void;
};

const initialState = {
  name: '',
  category: '',
  parent_id: undefined,
  tags: [],
  places: [],
  lms_users: []
};

const GroupContext = createContext<IGroupContextData>({} as IGroupContextData);

const GroupProvider: React.FC = ({ children }) => {
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [groupCurrent, setGroupCurrent] = useState<IGroup>(initialState);

  const clearGroupContextData = useCallback(() => {
    setSelectedGroup(undefined)
    setGroupCurrent(initialState);
  }, []);

  return (
    <GroupContext.Provider value={{ setSelectedGroup, selectedGroup, groupCurrent, setGroupCurrent, clearGroupContextData }}>
      {children}
    </GroupContext.Provider>
  );
};

function useGroup() {
  const context = useContext(GroupContext);

  if (!context) {
    throw new Error('useGroup must be used within a GroupProvider');
  }

  return context;
}

export { GroupProvider, useGroup };
