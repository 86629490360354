import React from 'react';
import { AgeContainer, InputAgeContainer } from './styles';

interface IInputAge {
  label?: string;
}

export const InputAge: React.FC<IInputAge> = ({ children, label }) => {
  return (
    <InputAgeContainer>
      <div className='input-age-label'>{label}</div>
      <AgeContainer className="input-age">{children}</AgeContainer>
    </InputAgeContainer>
  );
};

export default InputAge;
