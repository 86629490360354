import React, { useCallback, useEffect, useState } from 'react';

import { BsCheckLg } from 'react-icons/bs';

import { Form } from '@unform/web';

import { Checkbox, Label, Pagination, useToast } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import Layout from '@modules/schedules/components/Layout';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import WrapperContent from '@modules/schedules/components/WrapperContent';
import ScheduleEventCard from '@modules/schedules/components/ScheduleEventCard';
import useAuth from '@shared/store/auth/hook';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import ListExternalSchedulesService from '@modules/schedules/services/ListExternalSchedulesService';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import SubscribeScheduleService from '@modules/schedules/services/SubscribeScheduleService';

import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import UnsubscribeScheduleService from '../../services/UnsubscribeScheduleService';
import { useExternalSchedules } from './context/ExternalScheduleContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { AdvancedFilterContainer, Container } from './styles';
import { FiCheck } from 'react-icons/fi';
import { uuid } from 'uuidv4';
import useCancelRequest from '@shared/hooks/useCancelRequest';

const ExternalSchedule: React.FC = () => {
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { state } = useSchedules();
  const [render, setRender] = useState<string>(uuid());
  const { newCancelToken } = useCancelRequest();

  const { externalScheduleFilter } = useExternalSchedules();

  const { setIsLoading } = useGlobal();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [search, setSearch] = useState<string>('');

  const formatFilter = useCallback(() => {
    const { not_subscribed, subscribed } = externalScheduleFilter.current;
    if ((not_subscribed && subscribed) || (!not_subscribed && !subscribed)) return undefined;
    if (subscribed) return true;
    if (not_subscribed) return false;
  }, [externalScheduleFilter]);

  const listSchedules = useCallback(
    async (page: number = 0) => {
      if (!signedUser || !state) return;
      const isSubscribed = formatFilter();
      setIsLoading(true);
      const res = await new ListExternalSchedulesService(signedUser.token, newCancelToken()).execute(search, {
        page: page,
        size: 5,
        is_subscribed: isSubscribed,
        [`${state.resource}`]: state.id as string,
      });

      if (res === 'canceling') return;
      setIsLoading(false);

      if (!res) return console.log('ERROR: Error ao listar schedules');

      setSchedules(res.items);

      setCurrentPage(res.currentPage);
      setTotalPages(res.totalPages);
    },
    [formatFilter, newCancelToken, search, setIsLoading, signedUser, state]
  );

  const handleChangeAdvancedFilterCheckbox = (event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
    const isChecked = event.target.checked;
    externalScheduleFilter.current[objFilterKey] = isChecked;
  };

  const handleChangeCheckbox = useCallback(
    async (schedule: ISchedule) => {
      if (!signedUser || !schedule.id) return;

      const data = {
        schedule_ids: [parseInt(schedule.id)],
        [`${state?.resource}`]: state?.id as string,
      };

      setIsLoading(true);
      let res = false;
      if (schedule.is_subscribed) res = await new UnsubscribeScheduleService(signedUser.token).execute(data);
      if (!schedule.is_subscribed) res = await new SubscribeScheduleService(signedUser.token).execute(data);
      if (res) await listSchedules();
      setIsLoading(false);
      if (!res) {
        addToast({
          status: 'danger',
          title: 'Agendas',
          description: 'Error ao inscrever/desinscrever em agenda.',
        });
      }
    },
    [addToast, listSchedules, setIsLoading, signedUser, state]
  );

  const handleResetFilter = () => {
    externalScheduleFilter.current = {};
    listSchedules();
    setRender(uuid());
  };

  useEffect(() => {
    listSchedules();
  }, [listSchedules]);

  return (
    <Layout openTab={2}>
      <DirectoryContainer style={{ height: 'auto', margin: '0', overflow: 'initial', padding: '0' }} footer={false}>
        <WrapperContent>
          {/* START FILTER */}
          <AdvancedFilter
            onChangeMainInput={(newValue) => setSearch(newValue)}
            handleResetFilter={handleResetFilter}
            applyFilterButtonBackground="#8F9BB3"
            handleApplyFilter={listSchedules}
          >
            <AdvancedFilterContainer key={render}>
              <Label>Mostrar Agendas:</Label>

              <div className="subscription-checkbox-container">
                <Checkbox
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id="subscribed"
                  name="Inscritas"
                  label="Inscritas"
                  defaultChecked={externalScheduleFilter.current.subscribed}
                  onChange={(event) => handleChangeAdvancedFilterCheckbox(event, 'subscribed')}
                />

                <Checkbox
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id="not_subscribed"
                  name="Não Inscritas"
                  label="Não Inscritas"
                  defaultChecked={externalScheduleFilter.current.not_subscribed}
                  onChange={(event) => handleChangeAdvancedFilterCheckbox(event, 'not_subscribed')}
                />
              </div>
            </AdvancedFilterContainer>
          </AdvancedFilter>
          {/* END FILTER */}

          <Container>
            <CustomForm style={{ padding: '.5rem' }}>
              <Form onSubmit={() => {}}  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {schedules.map((schedule, i) => (
                  <ScheduleEventCard
                    key={`${schedule.id}`}
                    schedule={schedule}
                    icon={BsCheckLg}
                    canSelect
                    hideIconUncheck
                    checkbox={{
                      name: 'schedules',
                      id: `schedule-${schedule.id}`,
                      checked: schedule.is_subscribed,
                      onChange: () => handleChangeCheckbox(schedule),
                    }}
                  />
                ))}
              </Form>
            </CustomForm>
          </Container>
          {!!schedules.length && (
            <Pagination totalOfPages={totalPages} currentPage={currentPage} callbackGetListData={(_, page) => listSchedules(page)} status="basic" />
          )}
        </WrapperContent>
      </DirectoryContainer>
    </Layout>
  );
};

export { ExternalSchedule };
