import { H6 } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  input + div {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: rgba(143, 155, 179, 0.16);
  }

  input:checked + div {
    border-color: transparent;

    svg {
      color: #fff;
    }
  }
`;

export const Label = styled(H6)`
  font-size: 14px;
  color: #8f9bb3;
  margin: 1rem 0;
`;

export const ContainerSelectAll = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AdvancedFilterContainer = styled.div`
  /* .parent-render-container .custom-card .remove-card {
    margin: 0;
  } */

  .subscription-checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
`;
