import React, { useCallback, useState } from 'react';
import { Input, Spinner } from 'react-alicerce-components';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import Icon from 'react-eva-icons';
import { Container } from './styles';
import { useNavigate } from 'react-router-dom';
import GroupsSelection from '@shared/components/GroupsSelection';
import { IGroup } from '@modules/groups/interfaces/IGroup';

export const WorkedHoursContextApprove: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleOnClick = useCallback(
    (groupClicked: IGroup) => {
      navigate(`/horas-trabalhadas/grupo/${groupClicked.id}/aprovar`);
    },
    [navigate]
  );

  const filterGroups = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Seleção de Contexto" status="basic" pathToReturn="/" backgroundHeader="#8F9BB3" menuKebab={{ menuBackHome: true }} />
      <DirectoryContainer footer={false}>
        <Input
          placeholder="Buscar"
          name="search"
          end={<Icon name="funnel" fill="#8F9BB3" />}
          onChange={(event) => filterGroups(event.target.value)}
        />

        <GroupsSelection handleOnClick={handleOnClick} searchValue={searchValue} />
      </DirectoryContainer>
    </Container>
  );
};
