import styled from 'styled-components';

export const Marker = styled.div<{name?: string, isAfter?: boolean}>`
    font-family: var(--primary-font);
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    width: 100%;
    text-align: center;
    min-height: 25px;
    position: relative;
    display: flex;
    
    &::after{
        content: "${({name}) => name}";
        border-radius: 12px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .1rem;
        margin-bottom: 2px;
        padding: 4px 0;
    }

    ${props => props.isAfter && `
       &::before{
            background-color: #C5CEE0 !important;
        }
    `}
`;

export const GroupMarker = styled.div<{isHere?: boolean,isFixed?: boolean,color?: string,}>`
    position: relative;
    margin-bottom: 4px;
    cursor: not-allowed;

    ${props => !props.isFixed && `
        cursor: move;
    `}

    &::after{
        content: '';
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({color}) => color};
        filter: opacity(10%);
    }

    ${Marker} {
        ${props => props.isHere && `
            &::after{
                background-color: ${props.color};
            }
        `}

        ${props => !props.isHere && `
            position: relative;

            &::before{
                position: absolute;
                top: calc(50% - 2px);
                left: 0;
                right: 0;
                content: '';
                background-color: ${props.color};
                height: 4px;
                width: 100%;
            }

            &::after{
                opacity: 0;
            }
        `}
    }


    ${Marker} + ${Marker} {
        display: flex;
        align-items: center;
        justify-content: center;

        ${props => props.isHere && `
            &::before {
                content: '';
                position: absolute;
                height: 6px;
                width: 80%;
                top: -4px;
                background-color: ${props.color} !important;
                filter: brightness(80%);
  
            }
        `}
    }
`;

export const LocationContainer = styled.div`
    position: relative;

    ${GroupMarker} + ${GroupMarker} {
        margin-bottom: 8px;
    }
`;