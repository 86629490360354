import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  overflow-x: hidden;

   .main-navbar {
    height: 130px;
  }

  [role='tabpanel'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 !important;
    height: fit-content;
    overflow: hidden;
  }

  .pagination-content-icon {
    background-color: #8f9bb3 !important;

    i svg {
      fill: #fff !important;
    }
  }
`;
