import React, { useCallback, useMemo } from 'react';

import { SpaceProps } from 'styled-system';

import Icon from 'react-eva-icons';
import { ITrail } from '@shared/interfaces/ITrail';
import { getTrailDesignInfo } from './utils/getTrailDesignInfo';

import { Container, Left, Right, Img, Title, TrailName } from './styles';
import { useNavigate } from 'react-router-dom';

export type ITrailDesignProps = SpaceProps & {
  trail: ITrail;
  title: string;
  handleOnClick?: (trail: ITrail) => void;
  pathToReturn?: string;
  pathToReturnOptions?: { state: Object };
  arrowBack?: boolean;
  borderRadius?: boolean;
};

const TrailDesign: React.FC<ITrailDesignProps> = ({
  trail,
  title,
  handleOnClick,
  arrowBack,
  pathToReturn,
  pathToReturnOptions,
  borderRadius = true,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (handleOnClick) {
      handleOnClick(trail);
    }
  }, [handleOnClick, trail]);

  const handlePathToReturn = useCallback(() => {
    pathToReturn ? navigate(pathToReturn, pathToReturnOptions) : navigate(-1);
  }, [navigate, pathToReturn, pathToReturnOptions]);

  const GoBack = useCallback(() => {
    return (
      <div onClick={handlePathToReturn} className="arrow-back">
        <Icon name="arrow-ios-back" />
      </div>
    );
  }, [handlePathToReturn]);

  const trailDesignInfo = useMemo(() => getTrailDesignInfo(trail), [trail]);

  return (
    <Container onClick={handleClick} bgColor={trailDesignInfo.bgColor} borderRadius={borderRadius} {...rest}>
      <Left>
        {!!arrowBack && <GoBack />}
        <Title>{title}</Title>
        <TrailName color={trailDesignInfo.color}>{trail.name}</TrailName>
      </Left>
      <Right>
        <Img src={trailDesignInfo.img} />
      </Right>
    </Container>
  );
};

export { TrailDesign };
