import GroupListComponent from "@modules/groups/components/GroupsList";
import { useGroup } from "@modules/groups/context/GroupContext";
import { IGroup } from "@modules/groups/interfaces/IGroup";
import { deleteObjectKeys } from "@shared/utils/deleteObjectKeys";
import React, { useCallback, useMemo } from "react";

const GroupListParentCreate: React.FC = () => {
  const { setGroupCurrent, setSelectedGroup, selectedGroup } = useGroup();

  const handleAddParentGroup = useCallback(
    (groupSelect: IGroup | undefined) => {
      setGroupCurrent((oldState: any) => {
        if (groupSelect) return { ...oldState, parent: groupSelect };
        return deleteObjectKeys(oldState, ["parent"]);
      });
    },
    [setGroupCurrent]
  );

  const setSelectedItens = useCallback(
    (group: IGroup[]) => {
      setSelectedGroup(group[0]);
    },
    [setSelectedGroup]
  );

  const selectedItens = useMemo(() => {
    const aux = [] as IGroup[];
    selectedGroup && aux.push(selectedGroup);
    return aux;
  }, [selectedGroup]);

  return (
    <GroupListComponent
      headerTitle="Selecione um Grupo"
      pathToReturn="/diretorio/grupos/criar"
      canSelect="one"
      handleOnClick={handleAddParentGroup}
      btnText="Adicionar Grupo Mãe"
      selectedGroups={selectedItens}
      setSelectedGroups={setSelectedItens}
      pathRedirect="/diretorio/grupos/criar"
    />
  );
};

export default GroupListParentCreate;
