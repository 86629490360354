import { ISelectOption } from 'react-alicerce-components';

// turnos
export const SHIFT_MATUTINAL = { name: 'Matutino', value: 'matutinal' };
export const SHIFT_VERPERTINE = { name: 'Vespertino', value: 'vespertine' };
export const SHIFT_NOCTURNAL = { name: 'Noturno', value: 'nocturnal' };

export const SHIFT_CONSTRAINTS = [SHIFT_MATUTINAL, SHIFT_VERPERTINE, SHIFT_NOCTURNAL];

export const defaultShift = (classShift?: string | null): ISelectOption[] | undefined => {
  return [
    { name: SHIFT_MATUTINAL.name, value: SHIFT_MATUTINAL.value, selected: classShift === SHIFT_MATUTINAL.value },
    { name: SHIFT_VERPERTINE.name, value: SHIFT_VERPERTINE.value, selected: classShift === SHIFT_VERPERTINE.value },
    { name: SHIFT_NOCTURNAL.name, value: SHIFT_NOCTURNAL.value, selected: classShift === SHIFT_NOCTURNAL.value },
  ];
};
