import styled, { css } from "styled-components";

export const PaginatedInMemoryWrapper = styled.div<{bgColor: string}>`
${({bgColor}) => css`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0.8rem;
    color: white;
    background-color: ${bgColor};
    border-radius: 1rem;

    .arrow {
        cursor: pointer;
    }
`}
i {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
`