import React from 'react';
import { useParams } from 'react-router-dom';

import { H6, Spinner } from 'react-alicerce-components';

import { SprintForm } from '@modules/sprint/components/sprintForm';
import { useSprint } from '@modules/sprint/context/SprintContext';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { getDay } from '@shared/utils/getDay';

import { Container } from './styles';

export const SprintHistory: React.FC = () => {
  const { classId } = useParams<{ classId: string }>();
  const { currentSprint } = useSprint();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Plano de Ensino"
        pathToReturn={`/planejador-sprint/${classId}`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{
          menuBackHome: true,
        }}
      />
      <DirectoryContainer footer={true}>
        <div className="box-title-create">
          <H6 fontWeight={800} fontSize={'14px'} color={'#2E3A59'} status="secondary" className="title-sprint-create">
            {currentSprint && <>{`${currentSprint.sprint.name}:`}</>}
          </H6>
          <H6 fontWeight={600} fontSize={'14px'} color={'#2E3A59'} status="secondary" className="title-sprint-create">
            {currentSprint && `${getDay(currentSprint?.sprint.start_date)} a ${getDay(currentSprint?.sprint.end_date)}`}
          </H6>
        </div>
        <SprintForm errors={[]} sprintPlan={currentSprint?.sprint_plan} />
      </DirectoryContainer>
    </Container>
  );
};
