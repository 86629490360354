
import { styled } from "react-alicerce-components";

export const Container = styled.div`
    .button-open-upload {
        background-color: #568CF7;
        border-color: #568CF7;
    }

    .pagination-container {
        margin-top: 2rem;

        .pagination-content-icon {
            background-color: #B85EC4 !important;
            
            i svg {
                fill: #FFF !important;
            }
        }
    }
`

export const WrapperContent = styled.div`
    padding-bottom: 3rem;

    .input-container {
        margin: 4rem 22px;

        > div input::placeholder {
            font-family: var(--primary-font);
            font-weight: 500;
        }
    }
`

export const MediaCurrentContainer = styled.div`
   position: relative;
`

export const List = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`