import React from 'react';

import { ProgramCardBodyContainer } from './styles';

const ProgramCardBody: React.FC = (props) => {
  return (
    <ProgramCardBodyContainer >
      {props.children}
    </ProgramCardBodyContainer>
  );
};

export { ProgramCardBody };
