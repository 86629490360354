import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';
import { useTags } from '@modules/tags/context/TagsContext';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';

const TagListParentEdit: React.FC = () => {
  const navigate = useNavigate();
  const { setTagCurrent, tagCurrent } = useTags();

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setTagCurrent((oldState: ITag) => {
        return { ...oldState, parent: tags[0], parent_id: tags[0] ? tags[0].id : undefined };
      });
    },
    [setTagCurrent]
  );

  const selectedTagsEdit = useMemo(() => {
    const aux = [] as ITag[];
    if (tagCurrent?.parent) aux.push(tagCurrent?.parent);
    return aux;
  }, [tagCurrent]);

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={1}
      useSearch
      selectedTags={selectedTagsEdit}
      pathToReturn={`/diretorio/tags/${tagCurrent?.id}/editar`}
      buttonText="Adicionar Tags"
      buttonCallback={() => navigate(`/diretorio/tags/${tagCurrent?.id}/editar`)}
    />
  );
};

export default TagListParentEdit;
