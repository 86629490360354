import React from 'react';

import { Label } from 'react-alicerce-components';

import { CardsRenderErrorMessage, Container } from './styles';

export interface ICardsRenderProps {
  label?: string;
  error?: string;
  margin?: string;
}

const CardsRender: React.FC<ICardsRenderProps> = ({ label, children, margin, error }) => {
  return (
    <div style={{ margin: margin ? margin : '1rem 0 0 0' }}>
      <Label>{label}</Label>
      <Container>{children}</Container>
      {error && <CardsRenderErrorMessage>{error}</CardsRenderErrorMessage>}
    </div>
  );
};

export default CardsRender;
