import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';

export class GetWorkedHourService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(workedHourId: string | number): Promise<IWorkedHour | false> {
    try {
      const res = await this.api.get(`/worked-hour/${workedHourId}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}
