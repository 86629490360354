import { IUser } from '@modules/users/interfaces/IUser';

export const formatUserToUpdate = (lmsUser: IUser) => {
  return {
    name: lmsUser.name ? lmsUser.name : undefined,
    email: lmsUser.email ? lmsUser.email : undefined,
    phone: lmsUser.phone ? lmsUser.phone : undefined,
    address_data: lmsUser.address_data ? lmsUser.address_data : undefined,
    activation_date: lmsUser.activation_date ? lmsUser.activation_date : undefined,
    birthdate: lmsUser.birthdate ? lmsUser.birthdate : undefined,
    gender: lmsUser.gender ? lmsUser.gender : undefined,
    education_level: lmsUser.education_level ? lmsUser.education_level : undefined,
    university_type: lmsUser.university_type ? lmsUser.university_type : undefined,
    academic_info: lmsUser.academic_info ? lmsUser.academic_info : undefined,
    status: lmsUser.status ? lmsUser.status : undefined,
    category: lmsUser.category ? lmsUser.category : undefined,
  };
};
