import React, { useCallback, useState } from 'react';
import Icon from 'react-eva-icons';

import { ActivityDescription, ActivityLibraryCardContainer } from './styles';
import ActivityLibraryCardBorder from '@modules/activities-library/components/ActivityLibraryCard/ActivityLibraryCardBorder';
import { ActivityLibraryCardBody } from '@modules/activities-library/components/ActivityLibraryCard/ActivityLibraryCardBody';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import { Button, useToast } from 'react-alicerce-components';
import { CardTag } from './ActivityLibraryCardBody/styles';

interface IActivityLibraryCardProps {
  activityLibraryCurrent?: IActivityLibrary;
  className?: string;
  canDeleteCard?: { arrayId: number; removeCallback: (arrayId: number) => void };
  isSelected?: boolean;
  handleSelectedCardsCallback?: (activityCard: IActivityLibrary) => void;
  draggable?: boolean;
}

const ActivityLibraryCard: React.FC<IActivityLibraryCardProps> = ({ activityLibraryCurrent, handleSelectedCardsCallback, ...props }) => {
  const [isDescriptionOpen, setisDescriptionOpen] = useState<boolean>(false);
  const { addToast } = useToast()

  const defaultMessage = `
Título: ${activityLibraryCurrent?.title}
Tipo: ${activityLibraryCurrent?.type?.name}
Duração: ${activityLibraryCurrent?.duration}
Competências da Atividade: ${activityLibraryCurrent?.skills?.map((skill) => skill.name).join(', ')}
Descrição da Atividade: ${activityLibraryCurrent?.description}
Momentos: ${activityLibraryCurrent?.moments?.join(', ')}
`

  const handleClickCardContainer = useCallback(() => {
    if (!handleSelectedCardsCallback || !activityLibraryCurrent) return;
    handleSelectedCardsCallback(activityLibraryCurrent);
  }, [activityLibraryCurrent, handleSelectedCardsCallback]);


  const handleCopyActivityData = () => {
    navigator.clipboard.writeText(defaultMessage)

    addToast({
      status: 'success',
      title: 'Copiar Atividade',
      description: 'Atividade copiada',
    })
  }

  return (
    <ActivityLibraryCardContainer
      className={`activity-library-card-container ${props.className || ''}`}
      isSelected={props.isSelected}
      canDeleteCard={!!props.canDeleteCard}
      isDescriptionOpen={isDescriptionOpen}
    >
      <div className="card-container">
        <ActivityLibraryCardBorder handleOnClick={() => handleClickCardContainer()} location="header">
          <CardTag style={{ color: '#568CF7', padding: '5px 10px 2px 10px' }}>
            <Icon name="bookmark" fill="#568CF7" />
            <b>{activityLibraryCurrent?.type?.name}</b>
          </CardTag>

          <CardTag style={{ color: '#568CF7', padding: '5px 10px 2px 10px' }}>
            <Icon name="clock" fill="#568CF7" />
            <b>{activityLibraryCurrent?.duration?.slice(0, 5).replace(':', 'h')}m</b> Duração sugerida
          </CardTag>
        </ActivityLibraryCardBorder>
        <ActivityLibraryCardBody isDescriptionOpen={isDescriptionOpen}>
          <b className="activity-title" onClick={() => handleClickCardContainer()}>
            {activityLibraryCurrent?.title}
          </b>

          <div className="separator"></div>

          <div className="skills-container" onClick={() => handleClickCardContainer()}>
            {activityLibraryCurrent?.skills && activityLibraryCurrent.skills.map((skill, index) => <CardTag key={index}>{skill.name}</CardTag>)}
          </div>

          <div className="separator"></div>
          <Button
            style={{ backgroundColor: '#B85EC4', textTransform: 'none', border: 'none', borderRadius: '16px' }}
            onClick={handleCopyActivityData}
          >
            <Icon name="copy" fill="#FFFF" />
            Copiar Conteúdo
          </Button>
        </ActivityLibraryCardBody>
        <ActivityLibraryCardBorder
          isDescriptionOpen={isDescriptionOpen}
          handleOnClick={() => setisDescriptionOpen((oldState) => !oldState)}
          location="footer"
        >
          <span style={{ display: 'flex', gap: '3px'}}>
            <Icon name="file-text" fill="#2E3A59" />
            Descrição da Atividade
          </span>
          <div className="icon-container">
            <Icon name="arrow-ios-forward-outline" fill="#2E3A59" />
          </div>
        </ActivityLibraryCardBorder>
        <ActivityDescription isDescriptionOpen={isDescriptionOpen}>
          <label>{activityLibraryCurrent?.description}</label>
        </ActivityDescription>
      </div>

      {props.canDeleteCard && (
        <div className="remove-card" onClick={() => props.canDeleteCard?.removeCallback(props.canDeleteCard.arrayId)}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </ActivityLibraryCardContainer>
  );
};

export { ActivityLibraryCard };
