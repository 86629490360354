import React, { useCallback, useRef, useState } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { uuid } from 'uuidv4';
import Icon from 'react-eva-icons';

import { Checkbox } from 'react-alicerce-components';

import { useLocationStudent } from '@shared/components/LocationStudent/context/LocationStudentContext';
import Markers from '@shared/components/LocationStudent/partials/Markers';

import { Grid, GridName, SkillStatus, GridsContainer, ActiveContainer, SkillsContainer, SkillName } from './styles';

type GridsProps = {
  isOpen?: Boolean;
};

const Grids: React.FC<GridsProps> = ({ isOpen }) => {
  const { isMain, trailCurrent, isCanSelect, setTrailCurrent, handleSelectChange, isShowLabel } = useLocationStudent();
  const [render, setRender] = useState<string>(uuid());
  const checkRef = useRef<boolean>(false);

  const getValuesFromCheckbox = useCallback(
    (gridIndex) => {
      if (isMain(gridIndex)) checkRef.current = false;
      if (!isMain(gridIndex)) checkRef.current = true;
      setTrailCurrent((oldState) => {
        if (isMain(gridIndex)) {
          return {
            ...oldState,
            blocks: oldState.blocks.map((block, gIndex) => ({ ...block, checked: isMain(gridIndex, checkRef.current) && gridIndex === gIndex })),
          };
        }

        const newState = {
          ...oldState,
          blocks: oldState.blocks.map((block, gIndex) => {
            return { ...block, checked: gridIndex === gIndex };
          }),
        };
        return newState;
      });

      const block1 = trailCurrent?.blocks;
      const arrayDeBlocks: any = [];
      let arrayDeBlocksDefinitivo: any = [];

      for (let index = 0; index < block1!.length; ++index) {
        if (isMain(index)) {
          const b1 = block1![index];
          const b2 = block1![index + 1];
          const b3 = block1![index + 2];
          arrayDeBlocks.push({ block: b1, index }, { block: b2, index: index + 1 }, { block: b3, index: index + 2 });
        }
      }

      if (gridIndex === arrayDeBlocks[0].index) {
        arrayDeBlocksDefinitivo = [];
        arrayDeBlocksDefinitivo.push(arrayDeBlocks[0].block);
      }
      if (gridIndex === arrayDeBlocks[1].index) {
        arrayDeBlocksDefinitivo = [];
        arrayDeBlocksDefinitivo.push(arrayDeBlocks[1].block);
      }
      if (gridIndex === arrayDeBlocks[2].index) {
        arrayDeBlocksDefinitivo = [];
        arrayDeBlocksDefinitivo.push(arrayDeBlocks[2].block);
      }

      setRender(uuid());
      handleSelectChange && handleSelectChange(arrayDeBlocksDefinitivo);
    },
    [handleSelectChange, isMain, setTrailCurrent, trailCurrent]
  );

  return (
    <GridsContainer className="grids-container" key={render}>
      {trailCurrent?.blocks.map((block, gridIndex) => (
        <Grid id={`grid-${gridIndex}`} key={`grid-${gridIndex}`} className="grid" isCanSelect={isCanSelect}>
          {isCanSelect && (
            <Checkbox
              id={`grid-${uuid()}`}
              name={`grid-${uuid()}`}
              hideIconUncheck
              icon={BsCircleFill}
              defaultChecked={block.checked ? block.checked : isMain(gridIndex, checkRef.current)}
              disabled={!(isMain(gridIndex) || isMain(gridIndex - 1) || isMain(gridIndex - 2))}
              onChange={() => getValuesFromCheckbox(gridIndex)}
            />
          )}

          {isOpen && (
            <>
              <ActiveContainer
                className="active-container"
                isMain={block.checked ? block.checked : isMain(gridIndex, checkRef.current)}
                color={block?.color}
                key={`${render}${block?.color}${block.id}`}
              >
                <GridName className="grid-name" color={block?.color}>
                  <h6>{block?.name}</h6>
                </GridName>
                <SkillsContainer className="skills-container" isShowLabel={!!isShowLabel}>
                  {block?.skills?.map((skill, i) => (
                    <SkillStatus key={`skill-${i}${skill.status}${render}`} status={skill.status}>
                      {skill.name && (
                        <SkillName>
                          {skill.is_essential && <Icon name="star" fill="#F2C94C" />}
                          {skill.name}
                        </SkillName>
                      )}
                    </SkillStatus>
                  ))}
                </SkillsContainer>
              </ActiveContainer>
              <Markers gridIndex={gridIndex} blockName={block?.name} />
            </>
          )}
        </Grid>
      ))}
    </GridsContainer>
  );
};

export const MemoGrids = Grids;
