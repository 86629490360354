import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, H6, Input, ISelectOption, Pagination, Select } from 'react-alicerce-components';
import { ActivityListContainer } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import Icon from 'react-eva-icons';

import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { IActivityLibrarysReponse } from '@modules/activities-library/services/ListActivityLibrariesService';
import { IActivityFilterFormatted, IActivityLibraryFilter, useActivity } from '@modules/activities-library/context/ActivityContext';
import { ListWorkGroupsWithSkillsService } from '@modules/work-group/services/ListWorkGroupsWithSkillsService';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { COGNITIVE_TASKS_CONSTRAINTS } from '@modules/activities-library/utils/validCognitiveTasks';
import { STUDENT_AMOUNT_CONSTRAINTS } from '@modules/activities-library/utils/validStudentAmount';
import { ActivityLibraryCard } from '@modules/activities-library/components/ActivityLibraryCard';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import { MODALITY_CONSTRAINTS } from '@modules/activities-library/utils/validModality';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';

import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import PageFooter from '@shared/components/PageFooter';
import InputPill from '@shared/components/InputPill';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';
import { formatActivityLibraryFilter } from '../../utils/formatActivityLibraryFilter';
import { useAdvancedFilterController } from '../../context/AdvancedFilterControllerContext';
import { extractSkillsFromWorkGroup } from '../../utils/extractSkillsFromWorkGroup';
import { uuid } from 'uuidv4';
import { ACTIVITY_PROGRAM_STATUS } from '@modules/activities-library/utils/validActivityStatus';
import ITag from '../../../tags/interfaces/ITag';
import ListTagsService from '../../../tags/services/ListTagsService';
import ListTagTreeByTagNameService from '../../../tags/services/ListTagTreeByTagNameService';
import { IActivityTheme } from '../ActivityLibraryForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { defaultActivityTheme, IGenericSelectOption } from '../../utils/validActivityTheme';
import { defaultActivityType } from '../../utils/validActivityType';

interface IActivityList {
  canSelectCard?: 'one' | 'many';
  pathOnClickCard?: string;
  filterByWorkGroup?: boolean;

  buttonText: string;
  pathOnClickButton?: string;
  handleOnClickButton?: (value: any[]) => void;

  listActivitiesCallback: (search: string, page: number, fomattedFilter?: IActivityFilterFormatted) => Promise<IActivityLibrarysReponse | undefined>;

  statusToFilter?: ACTIVITY_PROGRAM_STATUS;
}

const ActivityList: React.FC<IActivityList> = ({ listActivitiesCallback, statusToFilter, ...props }) => {
  const { clearActivityLibraryContextData, activityLibraryFilter } = useActivity();
  const { isAdvancedFilterOpen } = useAdvancedFilterController();
  const { classId } = useParams<{ classId: string }>();
  const { newCancelToken } = useCancelRequest();
  const { programCurrent } = useProgram();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [lastFilterActivityOption, setLastFilterActivityOption] = useState<IActivityLibraryFilter>();
  const [selectedActivityCards, setSelectedActivityCards] = useState<IActivityLibrary[]>([]);
  const [activityLibraries, setActivityLibraries] = useState<IActivityLibrarysReponse>();
  const [filterParams, setFilterParams] = useState<IActivityFilterFormatted>();
  const [activityTheme, setActivityTheme] = useState<IActivityTheme>();
  const [activityTypes, setActivityTypes] = useState<ITag[]>([]);
  const [workGroups, setWorkGroups] = useState<IWorkGroup[]>();
  const [render, setRender] = useState<string>(uuid());
  const [search, setsearch] = useState<string>('');

  const isWorkGroupCheckboxChecked = useCallback(
    (workGroup: IWorkGroup) => {
      let areAllSkillsFiltered: boolean = true;
      const extractedSkillIds = extractSkillsFromWorkGroup(workGroup).map((sk) => String(sk.id));
      const find = activityLibraryFilter.current.skills?.filter((filteredSkill) => !extractedSkillIds.includes(String(filteredSkill.id)));
      if (!find) areAllSkillsFiltered = false;
      return areAllSkillsFiltered;
    },
    [activityLibraryFilter]
  );

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
      const value = event.target.value;

      if (objFilterKey === 'duration' && value.match(/^\d{2}:\d{2}$/)) {
        activityLibraryFilter.current[objFilterKey] = value;
      } else if (objFilterKey === 'duration') {
        activityLibraryFilter.current[objFilterKey] = '';
      }
    },
    [activityLibraryFilter]
  );

  const handleButton = useCallback(() => {
    clearActivityLibraryContextData();

    props.handleOnClickButton && props.handleOnClickButton(selectedActivityCards);
    props.pathOnClickButton ? navigate(props.pathOnClickButton) : navigate(-1);
  }, [clearActivityLibraryContextData, navigate, props, selectedActivityCards]);

  const handleSelectedCards = useCallback(
    (activity: IActivityLibrary) => {
      if (props.canSelectCard === 'one') throw new Error('NOT IMPLEMENTED');

      if (props.canSelectCard === 'many') {
        setSelectedActivityCards((oldState: IActivityLibrary[]) => {
          const found = oldState.find((ac) => ac.id === activity.id);
          if (!found) return [...oldState, activity];
          return oldState.filter((os) => os.id !== activity.id);
        });
      }

      props.pathOnClickCard && navigate(props.pathOnClickCard.replace(/\/:[A-z]+\//, `/${activity.id}/`)); // -> /:something/ to /12/
    },
    [navigate, props.canSelectCard, props.pathOnClickCard]
  );

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
      const isChecked = event.target.checked;
      const value = event.target.id;
      const auxiliar = activityLibraryFilter.current[objFilterKey] || [];

      if (value === 'my_questions') {
        if (isChecked) activityLibraryFilter.current.created_by = Number(signedUser?.lmsUser.id);
        if (!isChecked) delete activityLibraryFilter.current.created_by;
        return;
      } else if (isChecked) {
        activityLibraryFilter.current[objFilterKey] = [...auxiliar, value];
      } else if (!isChecked) {
        const filtered = auxiliar.filter((aux) => aux !== value);
        activityLibraryFilter.current[objFilterKey] = filtered;
      }
    },
    [activityLibraryFilter, signedUser]
  );

  const listActivities = useCallback(
    async (page: number = 0) => {
      const res = await listActivitiesCallback(search, page, { ...filterParams, status: statusToFilter });
      if (!res || !isMounted()) return;
      setActivityLibraries(res);
    },
    [filterParams, isMounted, listActivitiesCallback, search, statusToFilter]
  );

  const handleChangeWorkGroupCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, workGroup: IWorkGroup) => {
      const isChecked = event.target.checked;
      const extractedSkills = extractSkillsFromWorkGroup(workGroup);

      if (isChecked) {
        activityLibraryFilter.current.skills = [...(activityLibraryFilter.current.skills || []), ...extractedSkills];
        const params = formatActivityLibraryFilter(activityLibraryFilter.current);
        setFilterParams(params);
      } else {
        const workGroupSkillIds = extractedSkills.map((sk) => String(sk.id));
        const filtered = activityLibraryFilter.current.skills?.filter((filteredSkill) => !workGroupSkillIds.includes(String(filteredSkill.id)));
        activityLibraryFilter.current.skills = filtered;
        const params = formatActivityLibraryFilter(activityLibraryFilter.current);
        setFilterParams(params);
      }
    },
    [activityLibraryFilter]
  );

  const handleResetFilter = () => {
    activityLibraryFilter.current = {};
    setFilterParams(activityLibraryFilter.current);
    setRender(uuid());
  };

  const handleApplyFilter = () => {
    const params = formatActivityLibraryFilter({ ...activityLibraryFilter.current, ...lastFilterActivityOption });
    setFilterParams(params);
  };

  useEffect(() => {
    listActivities();
  }, [listActivities]);

  const listWorkGroups = useCallback(
    async (search: string = '', page: number = 0) => {
      if (!classId) return console.log('classId is undefined', 'classId >> ', classId);
      const res = await new ListWorkGroupsWithSkillsService(signedUser?.token, newCancelToken()).execute(search, { class_id: `${classId}`, page });
      if (!res) return console.log('Error ao listar WORKGROUPS');
      if (res === 'canceling') return;
      if (!isMounted()) return;
      setWorkGroups(res.items);
    },
    [classId, isMounted, newCancelToken, signedUser]
  );

  useEffect(() => {
    props.filterByWorkGroup && !workGroups && listWorkGroups();
  }, [listWorkGroups, props.filterByWorkGroup, workGroups]);

  useEffect(() => {
    const lastUrlPath = window.location.href.split('/').reverse()[0];
    if (lastUrlPath === 'banco-atividades') return;
    setSelectedActivityCards(programCurrent.activities || []);
  }, [programCurrent.activities]);

  const getOptionsTree = useCallback(
    async (tag_name = '') => {
      if (!signedUser) return false;

      setIsLoading(true);
      const res = await new ListTagTreeByTagNameService(signedUser.token).execute({ tag_name });
      setIsLoading(false);
      if (!res) return false;

      return res ? res : false;
    },
    [setIsLoading, signedUser]
  );

  const getChildrenOptions = useCallback(
    async (tag_name = '', parent_id?: string) => {
      if (!signedUser) return false;
      if (!parent_id) return [];

      setIsLoading(true);
      const res = await new ListTagsService(signedUser.token).execute(tag_name, { parent_id });
      setIsLoading(false);

      if (!res || res === 'canceling') return false;

      return res?.items ? res.items : false;
    },
    [setIsLoading, signedUser]
  );

  const handleChangeTheme = useCallback(
    async (type: string, option: ISelectOption | null) => {
      const value = option?.value as ITag | undefined;
      setLastFilterActivityOption({ [type]: option?.value });

      const children = await getChildrenOptions(option?.name, value?.id);
      if (children) {
        setActivityTheme((oldState) => {
          if (!oldState) return oldState;
          if (type === 'theme') {
            oldState['subThemes'] = children;
            oldState['thematicTheme'] = [];
          } else if (type === 'subtheme') {
            oldState['thematicTheme'] = children;
          }

          return { ...oldState };
        });
      }
    },
    [getChildrenOptions]
  );

  const loadActivityTypes = useCallback(async () => {
    const firstNode = await getOptionsTree('Tipo de Atividade');
    if (firstNode) setActivityTypes(firstNode);
  }, [getOptionsTree]);

  const loadActivityThemes = useCallback(async () => {
    const firstNode = await getOptionsTree('Temas de Atividade');
    if (firstNode) {
      const isThemeSelected = activityLibraryFilter.current.theme ? String(activityLibraryFilter.current.theme.id) : undefined;
      const isSubThemeSelected = activityLibraryFilter.current.subtheme ? String(activityLibraryFilter.current.subtheme.id) : undefined;

      const mainThemes = firstNode.filter((f) => f.node === 2);
      setActivityTheme({
        mainThemes: mainThemes ? mainThemes : [],
        subThemes: isThemeSelected ? firstNode.filter((f) => f.parent_id === isThemeSelected) : [],
        thematicTheme: isSubThemeSelected ? firstNode.filter((f) => f.parent_id === isSubThemeSelected) : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptionsTree]);

  const handleSelectChange = useCallback((keyName: string, keyValue?: any) => {
    setLastFilterActivityOption({ [keyName]: keyValue });
  }, []);

  useEffect(() => {
    loadActivityTypes();
    loadActivityThemes();
  }, [loadActivityThemes, loadActivityTypes]);

  return (
    <Fragment>
      <ActivityListContainer>
        {/* START FILTER */}
        <AdvancedFilter
          firstRenderOpenStatus={isAdvancedFilterOpen}
          onChangeMainInput={(newValue) => setsearch(newValue)}
          applyFilterButtonBackground="#96B6F7"
          handleApplyFilter={handleApplyFilter}
          handleResetFilter={handleResetFilter}
        >
          <div className="container-filter-options" key={render}>
            <Input
              label="Duração Sugerida: "
              name="Duração Sugerida"
              placeholder="00h00m"
              defaultValue={activityLibraryFilter.current.duration}
              onChange={(event) => handleChangeInput(event, 'duration')}
              end={<Icon name="clock" fill="#8F9BB3" />}
              mask="##:##"
              useTooltipError={false}
            />
            <div className="my-questions-container">
              <label>Ver Questões do Meu Banco</label>
              <Checkbox
                semiRound
                name="Minhas Questões"
                id="my_questions"
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                defaultChecked={!!activityLibraryFilter.current.created_by}
                onChange={(event) => handleChangeCheckbox(event, 'my_questions')}
              />
            </div>
          </div>
          <AccordionInfoItem title="Grupo Etário" key={1} colorHeader="#DAE3F6">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {AGE_GROUP_CONSTRAINTS.map((age_group) => (
                <Checkbox
                  key={age_group.value}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={age_group.value}
                  name={age_group.name}
                  label={age_group.name}
                  defaultChecked={!!activityLibraryFilter.current?.age_groups?.find((ag) => ag === age_group.value)}
                  onChange={(event) => handleChangeCheckbox(event, 'age_groups')}
                />
              ))}
            </div>
          </AccordionInfoItem>

          <AccordionInfoItem title="Quantidade de Alunos" key={2} colorHeader="#DAE3F6">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {STUDENT_AMOUNT_CONSTRAINTS.map((student_amount) => (
                <Checkbox
                  key={student_amount.value}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={student_amount.value}
                  name={student_amount.name}
                  label={student_amount.name}
                  defaultChecked={!!activityLibraryFilter?.current?.student_amount?.find((st) => st === student_amount.value)}
                  onChange={(event) => handleChangeCheckbox(event, 'student_amount')}
                />
              ))}
            </div>
          </AccordionInfoItem>

          <AccordionInfoItem title="Modalidade da Atividade" key={3} colorHeader="#DAE3F6">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {MODALITY_CONSTRAINTS.map((modality) => (
                <Checkbox
                  key={modality.value}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={modality.value}
                  name={modality.name}
                  label={modality.name}
                  defaultChecked={!!activityLibraryFilter?.current?.modality?.find((mod) => mod === modality.value)}
                  onChange={(event) => handleChangeCheckbox(event, 'modality')}
                />
              ))}
            </div>
          </AccordionInfoItem>

          <AccordionInfoItem title="Tarefas Cognitivas" key={4} colorHeader="#DAE3F6">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {COGNITIVE_TASKS_CONSTRAINTS.map((cognitive_task) => (
                <Checkbox
                  key={cognitive_task.value}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={cognitive_task.value}
                  name={cognitive_task.name}
                  label={cognitive_task.name}
                  defaultChecked={!!activityLibraryFilter?.current?.cognitive_tasks?.find((ct) => ct === cognitive_task.value)}
                  onChange={(event) => handleChangeCheckbox(event, 'cognitive_tasks')}
                />
              ))}
            </div>
          </AccordionInfoItem>

          <Divider width="100%" />

          <div style={{ position: 'relative' }}>
            <Select
              label="Tipo de Atividade: "
              placeholder="Escolha o Tipo de Atividade"
              iconName="arrow-down"
              options={defaultActivityType(activityLibraryFilter.current.type, activityTypes)}
              handleSelectChange={(option) => handleSelectChange('type', option?.value)}
              fullWidth
            />

            {!!activityTheme?.mainThemes?.length && (
              <Select
                label="Tema:"
                placeholder="Escolha o Tema"
                iconName="arrow-down"
                options={defaultActivityTheme(activityLibraryFilter.current.theme, activityTheme.mainThemes)}
                handleSelectChange={(option: null | IGenericSelectOption<ITag>) => handleChangeTheme('theme', option)}
                fullWidth
              />
            )}

            {!!activityTheme?.subThemes?.length && (
              <Select
                label="Subtema:"
                placeholder="Escolha o Subtema"
                iconName="arrow-down"
                key={`${activityLibraryFilter.current.subtheme?.name}`}
                options={defaultActivityTheme(activityLibraryFilter.current.subtheme, activityTheme.subThemes)}
                handleSelectChange={(option: null | IGenericSelectOption<ITag>) => handleChangeTheme('subtheme', option)}
                fullWidth
              />
            )}

            {!!activityTheme?.thematicTheme?.length && (
              <Select
                label="Temática:"
                placeholder="Escolha a temática"
                iconName="arrow-down"
                key={activityLibraryFilter.current.thematic?.name}
                options={defaultActivityTheme(activityLibraryFilter.current.thematic, activityTheme.thematicTheme)}
                handleSelectChange={(option: null | IGenericSelectOption<ITag>) => handleSelectChange('thematic', option?.value)}
                fullWidth
              />
            )}
          </div>
          <InputPill
            pathToRedirect="/banco-atividades/selecionar/competencias"
            label="Competências da Atividade"
            placeholder="Adicione Competências da Atividade"
            inputPillData={activityLibraryFilter?.current?.skills?.map((skill) => skill.name)}
            color="#C8FBF2"
          />
        </AdvancedFilter>
        {/* END FILTER */}

        {/* START FILTER WORKEDGROUP */}
        {props.filterByWorkGroup && (
          <Fragment>
            <H6 fontWeight={800} color="#B85EC4">
              FILTRAR POR GRUPOS DE TRABALHO
            </H6>
            <AccordionInfoItem title={`Grupos de Trabalho (${workGroups ? workGroups.length : 0}) `} colorHeader="#DAE3F6">
              <div className="work-group-checkboxes-wrapper">
                {(workGroups || []).map((wg) => (
                  <div className="work_group_wrapper" key={wg.id}>
                    <Checkbox
                      key={wg.id}
                      hideIconUncheck
                      icon={FiCheck}
                      status="basic"
                      semiRound
                      id={`${wg.id}`}
                      name={`${wg.name}`}
                      defaultChecked={isWorkGroupCheckboxChecked(wg)}
                      className="checkbox-container"
                      onChange={(event) => handleChangeWorkGroupCheckbox(event, wg)}
                    />

                    <div className="work-group-info">
                      <span>{wg.name}</span>
                      <span>{wg.students_count} membros</span>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionInfoItem>
          </Fragment>
        )}
        {/* END FILTER WORKEDGROUP */}

        {!!activityLibraries?.items.length && (
          <Fragment>
            <div className="activity-library-card-list">
              {activityLibraries?.items.map((activity: IActivityLibrary, index: number) => (
                <ActivityLibraryCard
                  handleSelectedCardsCallback={(activity) => handleSelectedCards(activity)}
                  activityLibraryCurrent={activity}
                  key={index}
                  isSelected={!!selectedActivityCards.find((s) => s.id === activity.id)}
                />
              ))}
            </div>

            <Pagination
              totalOfPages={activityLibraries?.totalPages}
              currentPage={activityLibraries?.currentPage}
              callbackGetListData={(_, page) => listActivities(page)}
            />
          </Fragment>
        )}
      </ActivityListContainer>
      <PageFooter>
        <Button transform="none" status="primary" shaded onClick={handleButton} className="mochileiro-color">
          {props.buttonText}
        </Button>
      </PageFooter>
    </Fragment>
  );
};

export { ActivityList };
