import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .input-container {
    margin-bottom: 2rem;

  }

  .pagination-content-icon {
    background-color: #8f9bb3 !important;

    i svg {
      fill: #ffffff !important;
    }
  }

  h6 {
    text-align: center;
    margin: 1rem 0;
  }
`;

export const Modifier = styled.div`
margin-bottom: 2rem;

input {
  ::-webkit-input-placeholder {
    font-weight: 400 !important;
  }
  ::-moz-placeholder {
    font-weight: 400 !important;
  }
  :-ms-input-placeholder {
    font-weight: 400 !important;
  }
  :-moz-placeholder {
    font-weight: 400 !important;
  }
} 
`;
