import useCancelRequest from '@shared/hooks/useCancelRequest';
import GetInfoTooltipService, { IInfoTooltipResponse } from '@shared/components/TooltipModal/services/GetInfoTooltipService';
import useAuth from '@shared/store/auth/hook';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import Icon from 'react-eva-icons';

import { ContainerData, ButtonModal, ModalContainer } from './styles';
import QuestionMark from '../QuestionMark';

interface ITooltipModal {
  status?: ElementStatus;
  view: string;
  isAbsolute?: boolean;
  top?: string;
  right?: string;
}

const TooltipModal: React.FC<ITooltipModal> = ({ status = 'primary', view, isAbsolute, top, right, children }) => {
  const [tooltipData, setTooltipData] = useState<IInfoTooltipResponse>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();

  const redirectHandling = (params: string) => {
    if (params.includes('http')) return params;
    return `http://${params}`;
  };

  const getInfo = useCallback(async () => {
    const res = await new GetInfoTooltipService(signedUser?.token, newCancelToken()).execute(view);
    if (!res || res === 'canceling') return;
    setTooltipData(res);
  }, [newCancelToken, signedUser, view]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <Fragment>
      <QuestionMark onClickCallback={() => setIsOpen(true)} isAbsolute={isAbsolute} top={top} right={right} />
      {isOpen && (
        <ModalContainer className="tooltip-modal-container">
          <ContainerData className="container-tooltip-modal">
            <div className="container-header">
              <div className="title-tooltip-modal">{tooltipData?.title}</div>

              <div className="show-close-button-tooltip-modal" onClick={() => setIsOpen(false)}>
                <div onClick={() => setIsOpen(false)} style={{ zIndex: 10 }}>
                  <Icon name="close" size="xlarge" fill="#2E3A59" />
                </div>
              </div>
            </div>

            <div className="badge-modal-line" />
            <div className={`subTitle-tooltip-modal `}>{tooltipData?.text}</div>
            {children}

            {tooltipData?.link && (
              <ButtonModal className={`buttons-confirm-modal`}>
                <a href={redirectHandling(tooltipData?.link!)} target="_blank" rel="noopener noreferrer">
                  <Button className="button-accept-tooltip-modal" shaded status={status}>
                    Ver mais
                  </Button>
                </a>
              </ButtonModal>
            )}
          </ContainerData>
        </ModalContainer>
      )}
    </Fragment>
  );
};

export default TooltipModal;
