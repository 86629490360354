import { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';

import { IApplication } from '@modules/applications/interfaces/IApplication';

class GetApplicationByIdService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(applicationId: string | number): Promise<IApplication | false> {
    try {
      const res = await this.api.get(`/application/${applicationId}`);
      return res.data;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetApplicationByIdService;
