import { styled } from 'react-alicerce-components';

interface IContainer {
  color: string;
}

export const InputPillContainer = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  > div {
    > label {
      display: flex;
      align-items: flex-end;
      color: #2e3a59;
      font-family: var(--secondary-font);
      font-weight: 700;
    }

    display: flex;
    justify-content: space-between;
  }

  .label-data-text {
    color: #2e3a59;
    font-family: var(--secondary-font);
    text-transform: unset;
    margin: 4px 0;
    letter-spacing: 0px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  .container-pill-input {
    margin-top: 4px;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    background-color: #f7f9fc;
    padding: 7px;
    width: 100%;
    max-width: 100%;
    appearance: none;
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 2px;
    transition: all 0.3s ease-in-out;
    background-color: #f7f9fc;
    border-color: #e4e9f2;
    cursor: default;
    min-height: 40px;

    .input-pill-placeholder {
      font-weight: 600;
      font-size: 12px;
      color: #c5cee0;
      padding: 2px;
      font-weight: 800;
    }
  }

  .container-pill-box {
    border-radius: 5px;
    color: #2e3a59;
    font-weight: 700;
    font-size: 14px;

    ${({ color }) => `background-color: ${color};`}
    ${({ color }) => `border: 1px solid ${color};`}
  }

  .container-pill-remove {
    display: flex;
    align-items: center;
    background-color: #c8fbf2;
    border: 1px solid #c8fbf2;
    border-radius: 5px;
  }

  .container-delete-pill {
    margin-left: -3px;
    padding-right: 5px;
    i {
      cursor: pointer;
      display: flex;
      padding-top: 2px;
    }
  }
`;
