import styled from "styled-components";

interface IContainerProps {
  contentBgColor?: string;
  timeBgColor?: string;
}

export const Time = styled.div`
  border-radius: 5px;
  font-size: 12px;
  color: #222B45;
  font-weight: bold;
  height: 100%;
  padding: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
`;

export const Content = styled.div`
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  height: 100%;
  padding: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 .4rem;
  border: 2px solid transparent;
`;

export const Container = styled.div<IContainerProps>`
  > div {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input + div {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background: rgba(143, 155, 179, 0.16);
    }
  }

  ${Time} {
    background-color: ${({ timeBgColor }) =>  timeBgColor};
  }

  ${Content} {
    background-color: ${({ contentBgColor }) => contentBgColor};
  }
`;

