import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .card-footer {
    //padding: 7px 5px 5px 5px;
    min-height: 34px;
  }

  .accordion-header {
    z-index: 0 !important;
  }
`;
