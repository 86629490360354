import React, { useCallback, useRef, MouseEvent, useState, useEffect } from "react";

import { Spinner } from 'react-alicerce-components';

import Media from '@modules/recordings/components/Media';
import IMedia from '@shared/interfaces/IMedia';
import TagsRender from "@modules/tags/components/TagsRender";

import {
    Container,
    WrapperClick,
    MediaContainer,
    MediaInfoContainer,
    Date,
    Name,
    TagsRenderContainer
} from './styles';

interface IMediaListItemProps {
    media: IMedia;
    handleOnClick?: () => void;
}

const MediaListItem: React.FC<IMediaListItemProps> = ({
    media,
    handleOnClick
}) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClickOut = useCallback((e: MouseEvent<HTMLDivElement>) => {
        let current = containerRef?.current;
        const node: Node = e.target as Node;
        if (current && current === node && handleOnClick) {
            handleOnClick();
        }
    }, [handleOnClick]);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 2000);
    }, []);

    return (
        <Container>
            <WrapperClick onClick={handleClickOut} />
            <MediaContainer>
                {isLoading && <Spinner className="spinner" />}
                <Media media={media} />
            </MediaContainer>
            <MediaInfoContainer>
                <Date>{media.date}</Date>
                <Name>{media.name}</Name>
                <TagsRenderContainer>
                    <TagsRender
                        size='tiny'
                        className='tags-render-list'
                        tags={media.tags}
                    />
                </TagsRenderContainer>
            </MediaInfoContainer>
        </Container>
    );
}

export default MediaListItem;