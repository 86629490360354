import React, { Fragment, useMemo } from 'react';
import { WorkedHoursCardFooter } from '@modules/worked-hours/components/WorkedHoursCard/WorkedHoursCardFooter';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import { Card, Pill, formatDate } from 'react-alicerce-components';
import { Container } from './styles';
import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';

interface IWorkedHoursCardProps {
  handleOnClick?: () => void;
  workedHour: IWorkedHour;
  showName?: boolean;
}

const WorkedHoursCard: React.FC<IWorkedHoursCardProps> = ({ handleOnClick, workedHour, showName = false }) => {
  const status = useMemo(() => {
    if (workedHour.approved_by) return 'Aprovação';
    if (workedHour.reproved_by) return 'Reprovação';
    return 'Must set approved_by or reproved_by';
  }, [workedHour.approved_by, workedHour.reproved_by]);

  return (
    <Container onClick={handleOnClick} canSelect={!!handleOnClick}>
      <Card
        status="basic"
        header={
          <div className="card header">
            {workedHour.group && (
              <Pill size="small" marginLeft="4px" className="tag" key={workedHour.group.id} backgroundColor="#E4E9F2">
                {workedHour.group.name}
              </Pill>
            )}
          </div>
        }
        footer={<WorkedHoursCardFooter workedHour={workedHour} />}
      >
        {workedHour.issuer?.name && showName && <div className="container-worked-hour-issuer">{workedHour.issuer.name}</div>}
        <Fragment>
          <div style={{ display: 'flex' }}>
            <span className="container-date">{workedHour.date ? formatDate(new Date(workedHour.date), 'long') : ''}</span>
            <span className="container-hours">: {convertMinutesToHour(workedHour.total_minutes)} horas lançadas</span>
          </div>
          <div style={{ display: 'flex' }}>
            {workedHour.completed_at && (
              <Fragment>
                <span className="container-date">{workedHour.completed_at ? formatDate(new Date(workedHour.completed_at), 'long') : ''}</span>
                <span className="container-hours">: Horario de {status}</span>
              </Fragment>
            )}
          </div>
        </Fragment>
      </Card>
    </Container>
  );
};

export default WorkedHoursCard;
