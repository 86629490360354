import React from 'react';

import { Button, Spinner, Tab, Tabs } from 'react-alicerce-components';

import { hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { SteeringInfoFragment } from './partials/SteeringInfoFragment';
import { SteeringAtaFragment } from './partials/SteeringAtaFragment';
import { SteeringActionPlanFragment } from './partials/SteeringActionPlanFragment';

import { ProjectDefaultStylesContainer } from '../ProjectDefaultStyles/styles';
import { SteeringFormContainer } from './styles';
import { EditingInfoSteeringSchema } from '@modules/projects/schemas/Steering/EditSteering';
import { getSchemaFieldNames } from '@shared/utils/getSchemaFieldNames';
import { CreatingInfoSteeringSchema } from '@modules/projects/schemas/Steering/CreateSteering';

interface SteeringFormProps {
  headerTitle: string;
  headerPathToReturn?: string;
  pathParticipatingAlicerce?: string;
  errors?: IInputErrorsFormat[];
  disabled?: boolean;
  editing?: boolean;
  footer?: JSX.Element;
  handleCancel?: () => void;
  deleteCallback?: () => void;
  handleConfirmButton?: () => Promise<void>;
}

const SteeringForm: React.FC<SteeringFormProps> = ({
  headerTitle,
  headerPathToReturn,
  pathParticipatingAlicerce,
  errors,
  disabled,
  editing,
  footer,
  handleCancel,
  deleteCallback,
  handleConfirmButton,
}) => {
  const { isLoading } = useGlobal();

  const InfoSteeringFieldNames = getSchemaFieldNames(editing ? EditingInfoSteeringSchema : CreatingInfoSteeringSchema);

  const steeringInfoHasError = hasError(InfoSteeringFieldNames, errors);
  const steeringAtaHasError = hasError(['points_of_meeting'], errors);
  const steeringActionPlanHasError = hasError(['action_plan_of_meeting'], errors);

  const badgeProps = (hasError: boolean) => {
    return {
      badge: hasError,
      badgeSide: hasError && 'left',
      badgeColor: hasError && '#fff',
      badgeContent: hasError && '!',
      badgeBg: hasError && '#c53030',
    };
  };

  return (
    <ProjectDefaultStylesContainer>
      <SteeringFormContainer>
        {isLoading && <Spinner />}
        <HeaderDirectory title={headerTitle} pathToReturn={headerPathToReturn} onClickReturn={handleCancel} menuKebab={{ deleteCallback }} />
        <DirectoryContainer footer={true}>
          <CustomTabs footer>
            <Tabs horizontalScroll>
              <Tab
                title="Informação"
                {...badgeProps(steeringInfoHasError)}
              >
                <SteeringInfoFragment editing={editing} errors={errors} pathParticipatingAlicerce={pathParticipatingAlicerce} disabled={disabled} />
              </Tab>
              <Tab
                title="Ata da Steering"
                {...badgeProps(steeringAtaHasError)}
              >
                <SteeringAtaFragment errors={errors} editing={editing} disabled={disabled} />
              </Tab>
              <Tab
                title="Plano de Ação"
                {...badgeProps(steeringActionPlanHasError)}
              >
                <SteeringActionPlanFragment errors={errors} editing={editing} disabled={disabled} />
              </Tab>
            </Tabs>
          </CustomTabs>
          <PageFooter>
            {footer}
            {!footer && (
              <>
                <Button className="btn-cancel" transform="none" onClick={handleCancel} shaded outline>
                  Cancelar
                </Button>
                <Button transform="none" shaded noBorder onClick={handleConfirmButton}>
                  Salvar
                </Button>
              </>
            )}
          </PageFooter>
        </DirectoryContainer>
      </SteeringFormContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default SteeringForm;
