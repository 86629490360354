import React, { useCallback, useState, useEffect } from 'react';
import { P } from 'react-alicerce-components';

import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { changeDays, DateHelper, getMonday } from '@shared/helpers/DateHelper';
import GetClassFrequencyHistoryService from '@modules/dashboards/services/GetClassFrequencyHistoryService';
import { formatGroupFrequencyHistory, IFormattedGroupFrequencyHistory } from '@modules/dashboards/utils/formatGroupFrequencyHistory';

import WeekSelect, { IChangeWeek } from '../WeekSelect';

import { Container, Content } from './styles';

interface IGroupFrequencyHistoryProps {
    classId?: string;
}

const ClassFrequencyHistory: React.FC<IGroupFrequencyHistoryProps> = ({
    classId
}) => {

    const { signedUser } = useAuth();
    const { setIsLoading } = useGlobal();

    const [state, setState] = useState<IFormattedGroupFrequencyHistory>();

    const load = useCallback(async (options?: any) => {

        if (!signedUser) return;

        setIsLoading(true);

        const res = await new GetClassFrequencyHistoryService(signedUser.token)
            .execute(`${classId}`, { ...options });

        setState(res ? await formatGroupFrequencyHistory(res) : undefined);
        setIsLoading(false);

    }, [classId, setIsLoading, signedUser]);

    useEffect(() => {
        const monday = getMonday(new Date());
        load({
            class_date: {
                start: DateHelper.extractDate(monday),
                end: DateHelper.extractDate(changeDays(monday, 6))
            }
        });
    }, [load]);

    const handleOnChangeWeek = useCallback(async (data: IChangeWeek) => {
        load({
            class_date: {
                start: DateHelper.extractDate(data.startDate),
                end: DateHelper.extractDate(data.endDate)
            }
        });
    }, [load]);

    const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
        const orderBy = sort.sortName as string;

        setState((oldState) => {
            let aux = [...oldState?.data]
            aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
            return {
                ...oldState,
                data: aux
            } as IFormattedGroupFrequencyHistory;
        });
    }, []);

    return (
        <Container>
            <WeekSelect handleChangeWeek={handleOnChangeWeek} />
            <Divider className='divider' />

            <Content>
                {state?.columns && state?.data &&
                    <DynamicTable
                        columnsGroup={state?.columns as IDynamicTableColumnsGroup[]}
                        data={state?.data}
                        handleClickSort={handleOnClickSort}
                    />
                }

                {!state && <P textAlign='center' marginTop='1.5rem'>Nenhum dado para exibir</P>}
            </Content>
        </Container>
    );
}

export default ClassFrequencyHistory;