import React, { useState, useCallback, useEffect } from 'react';
import { Button, Spinner } from 'react-alicerce-components';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import Comments from './Comments';
import CommentCreate from './CommentCreate';

import { Container } from './styles';
import useAuth from '@shared/store/auth/hook';
import { useParams } from 'react-router-dom';
import CreateDailyClassPlanCommentService from '../../services/CreateDailyClassPlanCommentService';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import ListDailyClassPlannedCommentsService, { IDailyClassPlannCommentsReponse } from '../../services/ListDailyClassPlannedCommentsService';

const ClassPlannerComments: React.FC = () => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>();

  const { newCancelToken } = useCancelRequest();
  const { dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();
  const { signedUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [commentsResponse, setCommentsResponse] = useState<IDailyClassPlannCommentsReponse>();

  const listDailyClassComments = useCallback(
    async (search?: string, page?: number, options?: string) => {
      if (!dailyClassId) return;
      setIsLoading(true);
      const res = await new ListDailyClassPlannedCommentsService(signedUser?.token, newCancelToken()).execute({
        daily_class_id: dailyClassId,
        page: page,
        size: 2,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return console.log(`error ao listar daily class comments`);
      setCommentsResponse(res);
    },
    [dailyClassId, newCancelToken, signedUser]
  );

  useEffect(() => {
    listDailyClassComments();
  }, [listDailyClassComments]);

  const handleOnClickButton = useCallback(async () => {
    if (!isCreate) {
      setIsCreate(true);
      return;
    }

    if (!dailyClassId || !newComment) return;
    setIsLoading(true);
    const res = await new CreateDailyClassPlanCommentService(signedUser?.token).execute(dailyClassId, newComment);
    setIsLoading(false);
    if (!res) return console.log(`error ao criar novo comentario`);

    setCommentsResponse((oldState) => {
      if (!oldState) return undefined;
      return { ...oldState, items: [res, ...oldState['items']] };
    });

    setNewComment(undefined);

    setIsCreate(false);
  }, [dailyClassId, isCreate, newComment, signedUser]);

  const isButtonDisabled = useCallback(() => {
    if (isCreate && !newComment) return true;
    return false;
  }, [isCreate, newComment]);

  return (
    <Container className="class-planner-comments-container">
      {isLoading && <Spinner fixed />}
      <DirectoryContainer style={{ margin: 0, padding: '0 0 6rem 0', height: '100%' }} footer>
        {isCreate && <CommentCreate setNewCommentCallback={setNewComment} />}
        {!isCreate && commentsResponse && <Comments commentsResponse={commentsResponse} listDailyClassCommentsCallback={listDailyClassComments} />}

        <PageFooter>
          <Button transform="none" fill="#85ABF8" shaded onClick={handleOnClickButton} disabled={isButtonDisabled()}>
            {!isCreate ? 'Novo Comentário' : 'Salvar Comentário'}
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export default ClassPlannerComments;
