import { styled } from 'react-alicerce-components';

export const WorkGroupStudentsRelationCreateContainer = styled.div`
  > div {
    .dynamic-table-container {
      width: calc(100vw - 44px);
      height: 65vh;

      table {
        width: 90vw;
      }
    }
  }

  .select-all-students-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    color: #2e3a59;
  }
`;
