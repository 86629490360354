import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-add-hours {
    > div {
      width: 125px;

      input {
        width: 65px;
        text-align: center;
      }
    }
  }

  .input-container {
    margin-top: 4px;
  }

  .select-container {
    margin-bottom: 2rem;
  }

  .parent-render-container {
    padding-top: unset;
    margin-bottom: 2rem;
  }

  .container-dashed-box {
    margin-bottom: 1rem;
  }
`;
