import React, { createContext, useContext } from 'react';

export type IProblemDashboards = string;

interface IDashboardsContextState {

}

export type IDashboardsContextData = IDashboardsContextState & {

};

const DashboardsContext = createContext<IDashboardsContextData>({} as IDashboardsContextData);

const DashboardsProvider: React.FC = ({ children }) => {


  return (
    <DashboardsContext.Provider
      value={{}}
    >
      {children}
    </DashboardsContext.Provider>
  );
};

function useDashboards() {
  const context = useContext(DashboardsContext);

  if (!context) {
    throw new Error('useDashboards must be used within a DashboardsProvider');
  }

  return context;
}

export { DashboardsProvider, useDashboards };
