import { IProfile } from '../interfaces/IProfile';

export const formatProfilesSelectOption = (profiles: IProfile[]) => {
  return profiles.map((profile) => {
    return { name: profile.name, value: profile.id };
  });
};

export const profilesMockedData = [
  {
    id: '61',
    system_id: '3',
    name: 'Lideres LMS',
    description: 'Perfil para lideres do LMS',
    is_admin: false,
    created_at: '2022-04-07T17:10:52.885Z',
    updated_at: '2022-04-07T17:10:52.885Z',
    deleted_at: null,
  },
  {
    id: '3',
    system_id: '3',
    name: 'Administrador LMS',
    description: 'Perfil de administrador do LMS',
    is_admin: true,
    created_at: '2021-06-21T14:45:09.062Z',
    updated_at: '2021-07-16T17:10:57.655Z',
    deleted_at: null,
  },
];
