import CustomForm from '@shared/components/CustomForm';
import { styled } from 'react-alicerce-components';

export const Container = styled.div`
    padding-top: 40px;

    .pagination-container {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const Content = styled.div`
    height: calc(100vh - 130px - 80px - 40px); // screen height - header height - footer height - padding top
`;

export const Form = styled(CustomForm)`
    padding: 0 22px 22px 22px;

    .select-container {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;

        .select-option-text {
            span {
                flex: 1;
                margin: 0 22% 0 0;
                text-align: center;
                background-color: #E4E9F2;
            }
        }
    }
`;

