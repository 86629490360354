import { styled } from "react-alicerce-components";

export const Container = styled.div`
    .input-container {
        margin: 1.5rem 0 0 0;

        > div input::placeholder {
            font-weight: 500;
            color: #C5CEE0;
        }
    }


  .pagination-content-icon {
    background-color: #FFBA95 !important;
    
    i svg {
        fill: #FFF !important;
    }
  }
`

export const List = styled.div`
    display: flex;
    flex-direction: column;

    .custom-card > div {
        margin: 10px 0;
    }
`

export const ContainerTrails = styled.div`
    margin: 0 1rem;
    border-top: transparent; 
    box-sizing: border-box;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    visibility: visible;
    margin-bottom: 1.5rem;
    border: 2px solid #e4e9f2;
    padding: calc(5px + 1rem) 1rem 1rem 1rem;

  .filter-text {
    font-weight: 800;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2e3a59;

    > label i {
      display: flex;
      cursor: pointer;
      svg {
        width: 30px;
        height: 30px;
        fill: #2e3a59;
      }
    }
  }

`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  padding: 0.5rem;
  flex-wrap: wrap;

  > div {
    >label{
      >div{
        background-color: rgb(205 210 220 / 48%);
      }
    }
  }

  label {
    font-weight: 700;
    font-size: 12px;
    color: #2E3A59;
    font-family: var(--secondary-font);
  }

  input + div {
    background: rgba(143, 155, 179, 0.48);
    border: 1px solid #c5cee0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    margin-right: 1rem;
  }
`;
