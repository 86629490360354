export const HEADLIGHT_STATUS_OPTION_SUCCESS_CONTRAINS = 'success' // Sucesso | Chart cor Verde
export const HEADLIGHT_STATUS_OPTION_WARNING_CONTRAINS = 'warning' // Atenção | Chart cor amarelo
export const HEADLIGHT_STATUS_OPTION_DANGER_CONTRAINS = 'danger' // Perigo | Chart cor Vermelha
export const HEADLIGHT_STATUS_OPTION_BASIC_CONTRAINS = 'basic' // basico | Chart cor cinza

export type HeadlightStatusOptions =
  typeof HEADLIGHT_STATUS_OPTION_SUCCESS_CONTRAINS |
  typeof HEADLIGHT_STATUS_OPTION_WARNING_CONTRAINS |
  typeof HEADLIGHT_STATUS_OPTION_DANGER_CONTRAINS |
  typeof HEADLIGHT_STATUS_OPTION_BASIC_CONTRAINS

export const HEADLIGHT_STATUS_OPTION_CONTRAINS = [
    HEADLIGHT_STATUS_OPTION_SUCCESS_CONTRAINS,
    HEADLIGHT_STATUS_OPTION_WARNING_CONTRAINS,
    HEADLIGHT_STATUS_OPTION_DANGER_CONTRAINS,
    HEADLIGHT_STATUS_OPTION_BASIC_CONTRAINS
]

export type HeadlightStatus = {
  category: string
  category_description: string
  status: HeadlightStatusOptions
  status_description: string
}