import React, { useState, useCallback, useEffect, useRef, Fragment } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button, Checkbox, Pagination, Spinner } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import { ActivityPortfolioCard } from '@modules/activities-portfolio/components/ActivityPortfolioCard';
import WrapperContent from '@modules/activities-portfolio/components/WrapperContent';
import PageFooter from '@shared/components/PageFooter';

import { ActivitiesPortfolioListContainer, CheckboxContainer, List } from './styles';
import {
  IListPortfolioActivitiesServiceServiceReponse,
  IValidTypes,
  ListPortfolioActivitiesServiceService,
} from '../../services/ListPortfolioActivitiesService';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import Divider from '@shared/components/Divider';
import { FiCheck } from 'react-icons/fi';
import { IGenericLocationState } from '@shared/interfaces/ILocationState';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import { useActivitiesPortfolio } from '../../context/ActivitiesPortfolioContext';
import { InputDateContainer } from '../../../worked-hours/components/WorkedHoursAdvancedFilter/styles';

export interface IPortfolioActivityFilter {
  types?: IValidTypes[];
  delivery_date?: {
    start?: string;
    end?: string;
  };
}

const ActivitiesPortfolioList: React.FC = () => {
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  let { classId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as IGenericLocationState;

  const { clearPortfolioActivityCurrent } = useActivitiesPortfolio();
  const [portfolioActivities, setPortfolioActivities] = useState<IListPortfolioActivitiesServiceServiceReponse>();
  const portfolioActivitiesFilter = useRef<IPortfolioActivityFilter>({});
  const [dateFilterErrors, setDateFilterErrors] = useState<IInputErrorsFormat[]>([]);

  const handleGoBack = useCallback(() => {
    console.log({state})
    if (state && state.pathToReturn) return navigate(state.pathToReturn);
    navigate('/portfolio-atividades/contexto/turmas');
  }, [navigate, state]);

  const resetActivityFilter = () => {
    portfolioActivitiesFilter.current = {};
    listPortfolioActivities();
    setDateFilterErrors([]);
  };

  const handleClickFilterCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
      const checked = event.target.checked;
      const value = event.target.id;
      const auxiliar = portfolioActivitiesFilter.current[objFilterKey] ? [...portfolioActivitiesFilter.current[objFilterKey], value] : [value];

      if (checked) {
        portfolioActivitiesFilter.current = { ...portfolioActivitiesFilter.current, [objFilterKey]: auxiliar };
      } else {
        const filtered = auxiliar.filter((aux) => aux !== value);
        portfolioActivitiesFilter.current = { ...portfolioActivitiesFilter.current, [objFilterKey]: filtered };
      }
    },
    [portfolioActivitiesFilter]
  );

  const handleSelectDateFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
    event.persist();
    const value = event.target.value;
    if (!portfolioActivitiesFilter.current.delivery_date) portfolioActivitiesFilter.current.delivery_date = {};
    portfolioActivitiesFilter.current.delivery_date[objFilterKey] = value;

    const { start, end } = portfolioActivitiesFilter.current.delivery_date;

    if (start && !end) {
      return setDateFilterErrors([{ name: 'end', message: 'Preencha o campo data final' }]);
    }

    if (end && !start) {
      return setDateFilterErrors([{ name: 'start', message: 'Preencha o campo data inicial' }]);
    }

    if (new Date(start!) > new Date(end!)) {
      return setDateFilterErrors([{ name: 'delivery_date', message: 'Data inicial não pode ser maior que data final' }]);
    }

    setDateFilterErrors([]);
  }, []);

  const handleAddNewActivity = () => {
    clearPortfolioActivityCurrent();
    navigate(`/portfolio-atividades/turma/${classId}/criar`);
  };

  const listPortfolioActivities = useCallback(
    async (searchInput: string = '', page: number = 0) => {
      if (!classId) return;
      setIsLoading(true);

      const res = await new ListPortfolioActivitiesServiceService(signedUser?.token, newCancelToken()).execute(searchInput, {
        page: page,
        class_id: Number(classId),
        ...portfolioActivitiesFilter.current,
      });

      if (res === 'canceling' || !isMounted()) return;
      setIsLoading(false);

      if (!res) return console.log('Error ao Listar Atividades do Portfolio');
      setPortfolioActivities(res);
    },
    [classId, isMounted, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listPortfolioActivities();
  }, [listPortfolioActivities]);

  return (
    <ActivitiesPortfolioListContainer>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Portfólio"
        backgroundHeader="linear-gradient(135deg, #FFBC6F 0%, #43A2E4 100%)"
        onClickReturn={handleGoBack}
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'ActivitiesPortfolioList' },
        }}
      />
      <DirectoryContainer style={{ marginTop: '2rem', paddingTop: 0 }} footer>
        <WrapperContent>
          {<InputErrorMessage error={errorMessageBuilder('start', dateFilterErrors)} />}
          {<InputErrorMessage error={errorMessageBuilder('end', dateFilterErrors)} />}
          {<InputErrorMessage error={errorMessageBuilder('delivery_date', dateFilterErrors)} />}

          <AdvancedFilter
            handleApplyFilter={dateFilterErrors.length === 0 ? listPortfolioActivities : () => {}}
            handleResetFilter={resetActivityFilter}
            applyFilterButtonBackground="#42AAFF"
            onChangeMainInput={(newValue) => listPortfolioActivities(newValue)}
          >
            <section>
              <label>Data de Entrega entre:</label>
              <InputDateContainer>
                <input
                  type="date"
                  defaultValue={portfolioActivitiesFilter.current?.delivery_date?.start}
                  name="date"
                  onChange={(event) => handleSelectDateFilter(event, 'start')}
                />
                <label>até</label>
                <input
                  type="date"
                  defaultValue={portfolioActivitiesFilter.current?.delivery_date?.end}
                  name="date"
                  onChange={(event) => handleSelectDateFilter(event, 'end')}
                />
              </InputDateContainer>
            </section>

            <Divider />

            <section>
              <label>Tipo do Entregável:</label>
              <CheckboxContainer>
                <Checkbox
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id="checkpoint"
                  name="Checkpoint"
                  label="Checkpoint"
                  defaultChecked={portfolioActivitiesFilter.current?.types?.map((type) => type).includes('checkpoint')}
                  onChange={(event) => handleClickFilterCheckbox(event, 'types')}
                />
                <Checkbox
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id="pdv"
                  name="PDV"
                  label="PDV"
                  defaultChecked={portfolioActivitiesFilter.current?.types?.map((type) => type).includes('pdv')}
                  onChange={(event) => handleClickFilterCheckbox(event, 'types')}
                />
              </CheckboxContainer>
            </section>
          </AdvancedFilter>

          {!!portfolioActivities?.items.length && (
            <Fragment>
              <List>
                {portfolioActivities?.items.map((activity, i) => (
                  <ActivityPortfolioCard key={`activity-${i}`} portfolio_activity={activity} />
                ))}
              </List>
              <Pagination
                totalOfPages={portfolioActivities?.totalPages}
                currentPage={portfolioActivities?.currentPage}
                callbackGetListData={listPortfolioActivities}
                status="basic"
              />
            </Fragment>
          )}
        </WrapperContent>
        <PageFooter>
          <Button onClick={handleAddNewActivity} status="info" transform="none" noBorder shaded>
            Adicionar Atividade
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </ActivitiesPortfolioListContainer>
  );
};

export default ActivitiesPortfolioList;
