import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import SteeringForm from '@modules/projects/components/SteeringForm';

const SteeringInfo: React.FC = () => {
  const navigate = useNavigate();
  const { getSteering, steeringCurrent, clearSteeringCreate } = useProjects();
  const { id: steeringId } = useParams<{ id: string }>();
  const dirPath = `/projeto/${steeringCurrent?.project_id}/steerings`

  const handleCancel = useCallback(() => {
    clearSteeringCreate();
    navigate(dirPath);
  }, [clearSteeringCreate, dirPath, navigate]);

  useEffect(() => {
    getSteering(steeringId)
  }, [getSteering, steeringId])

  return (
    <SteeringForm
      headerPathToReturn={dirPath}
      handleCancel={handleCancel}
      headerTitle="Steering"
      disabled
      footer={
        <Button
          transform="none"
          shaded
          onClick={() => navigate(`/diretorio/projetos/steering/${steeringId}/editar`)}
        >
          Editar
        </Button>
      }
    />
  );
};

export default SteeringInfo;
