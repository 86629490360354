import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { combineContext } from '@shared/utils/combineContext';
import IRoute from '@shared/interfaces/IRoute';

import { SubscriptionGroupSelection } from '@modules/subscriptions/views/SubscriptionGroupSelection';
import { EnrollmentSubscriptions } from '@modules/subscriptions/views/EnrollmentSubscriptions';
import { SubscriptionProvider } from '@modules/subscriptions/context/SubscriptionContext';
import { PendingSubscriptions } from '@modules/subscriptions/views/PendingSubscriptions';
import { subscriptionsKeys } from '@modules/subscriptions/keys';
import { groupsKeys } from '@modules/groups/keys';
import { classesKeys } from '@modules/classes/keys';
import { SubscriptionsCreateClass } from '../views/SubscriptionCreateClass';
const routes: IRoute[] = [
  {
    path: '/assinaturas/contexto/grupos',
    key: 'HOME_SUBSCRIPTION_PATH',
    exact: true,
    component: SubscriptionGroupSelection,
    permissions: [
      groupsKeys.GROUP_READ
    ],
  },
  {
    path: '/assinaturas/grupo/:id/pendentes',
    key: 'PENDING_SUBSCRIPTION_PATH',
    exact: true,
    component: PendingSubscriptions,
    permissions: [
      subscriptionsKeys.SUBSCRIPTIONS_READ
    ],
  },
  {
    path: '/assinaturas/grupo/:id/pendentes/enturmar',
    key: 'GROUPING_SUBSCRIPTION_PATH',
    exact: true,
    component: EnrollmentSubscriptions,
    permissions: [
      subscriptionsKeys.SUBSCRIPTIONS_ENROLLMENT_CREATE,
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: '/assinaturas/grupo/:groupId/criar/turma',
    key: 'GROUPING_SUBSCRIPTION_CLASS_CREATE_PATH',
    exact: true,
    component: SubscriptionsCreateClass,
    permissions: [
      classesKeys.CLASSES_CREATE
    ],
  },
];

const providers = [SubscriptionProvider];

export default {
  key: 'SUBSCRIPTIONS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
