import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .card-header {
    padding: unset;
    background: #568cf7;
  }

  .card-body {
    background: #dae3f6;
  }

  .container-header-occurrence {
    font-size: 12px;
    font-weight: 800;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    column-gap: 0.625rem;
  }

  .container-header-time {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    line-height: 16px;
    background: #96b6f7;
    border-radius: 5px 5px 0px 0px;
    height: 40px;
    padding: 0 5px;
  }

  .received-header {
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    span {
      line-height: 30px;
    }
  }
`;
