import { styled } from 'react-alicerce-components';

export const WrapperContentContainer = styled.div`
  .custom-card > div {
    margin: var(--card-distance);
  }

  .input-container {
    div {
      border-color: #c5cee0 !important;
    }

    > div input::placeholder {
      font-weight: 500;
      color: #c5cee0;
    }
  }

  .pagination-container {
    margin: 0px 0px 20px 0px;
  }

  .fc-direction-ltr .fc-toolbar-chunk:first-child .fc-button-group > .fc-button:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 4px;
  }

  .fc-direction-ltr .fc-toolbar-chunk:first-child > button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .fc-direction-ltr .fc-toolbar-chunk:first-child .fc-button-group > .fc-button:not(:first-child) {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .fc-toolbar > * > :not(:first-child) {
    margin-left: 0 !important;
  }

  .fc .fc-button .fc-icon {
    width: 0.5em;
    height: 0.5em;
    line-height: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fc-toolbar-chunk {
    display: flex;

    .fc-toolbar-title {
      font-size: 0.8em;
      margin: 0 0.5em;
    }

    .fc-button {
      padding: 0.2em 0.65em;
      font-size: 12px;
    }
  }
`;
