import { styled } from 'react-alicerce-components';

export const ProgramInfoContainer = styled.div`
  div {
    .container-page-footer {
      button {
        background-color: #96b6f7;
      }
    }
  }
`;
