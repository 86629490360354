import { styled } from 'react-alicerce-components';

export const ContractFormContainer = styled.div`
    .focal-point-container {
        border: 1px dashed #C5CEE0;
        margin-top: 1rem;
        border-radius: 1rem;
        padding: 1rem 1rem 2rem 1rem;
        border-width: 2px;
        position: relative;

        .remove-focal-point-container {
            position: absolute;
            right: .5rem;
            top: .5rem;
            cursor: pointer;
            width: 30px;
            aspect-ratio: 1;
            background: red;
            border-radius: 100px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        
            i {
              display: flex;
              align-items: center;
              svg {
                width: 25px;
                height: 25px;
              }
            }
          }
    }
`;
