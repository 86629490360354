export function redirectToAuth() {
  const authUrl = process.env.REACT_APP_AUTH_URL ? process.env.REACT_APP_AUTH_URL : null;
  const systemKey = process.env.REACT_APP_SYSTEM_KEY ? process.env.REACT_APP_SYSTEM_KEY : null;
  const urlCallback = process.env.REACT_APP_URL_CALLBACK ? process.env.REACT_APP_URL_CALLBACK : 'http://localhost:3000/auth/callback';

  const encodedUrl = encodeURIComponent(urlCallback);
  const searchParams = new URLSearchParams(window.location.search);

  let urlToRedirect = `${authUrl}/app/signin?system_key=${systemKey}&callback_url=${encodedUrl}`;

  for (const [key, value] of searchParams.entries()) {
    if (key === 'session_action') urlToRedirect += `&session_action=${value}`;
  }

  window.location.href = urlToRedirect;
}

export function appendReadOffToLogout(sessionAction: 'keep' | 'remove') {
  const currentUrl = new URL(window.location.href);
  const currentSearchParams = new URLSearchParams(currentUrl.search);
  currentSearchParams.append('session_action', sessionAction);
  currentUrl.search = currentSearchParams.toString();
  window.history.replaceState(null, '', currentUrl.href);
}
