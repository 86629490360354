import React from 'react';

import { Grid, Spinner } from 'react-alicerce-components';
import ProjectList from '@modules/projects/components/ProjectList';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useParams } from 'react-router-dom';

interface ProjectProjectsFragmentProps {
    disabled?: boolean
    pathAddProjects?: string
}

export const ContractProjectsFragment: React.FC<ProjectProjectsFragmentProps> = () => {
    const { isLoading} = useGlobal();
    const { id } = useParams<{ id: string }>();

    return (
        <Grid row textCenter>
            {isLoading && <Spinner fixed />}
            <ProjectList contract_ids={[Number(id)]}/>
        </Grid>
    );
}