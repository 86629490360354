import React, { useCallback } from 'react';
import { H6, Input } from 'react-alicerce-components';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import CustomForm from '@shared/components/CustomForm';
import { useScheduleGrid } from '../../../class-planner/context/ScheduleGridContext';
import TagsRender from '../../../tags/components/TagsRender';
import { useProgram } from '../../context/ProgramContext';
import { IActivityLibrary } from '../../interfaces/IActivityLibrary';
import { ActivityLibraryCard } from '../ActivityLibraryCard';
import { CardsContainer } from '../ProgramForm/styles';
import { CardInformation } from './styles';

interface IProgramFormInfo {
  handleClickOnActivityCard?: (activityCard: IActivityLibrary) => void;
}

const ProgramFormInfo: React.FC<IProgramFormInfo> = ({ handleClickOnActivityCard }) => {
  const { programCurrent } = useProgram();
  const { plannedActivities } = useScheduleGrid();

  const isActivityInCalendar = useCallback(
    (activity: IActivityLibrary) => {
      if (!plannedActivities) return;
      const found = [...plannedActivities.in_tray, ...plannedActivities.in_calendar].find((a) => `${a.activity_id}` === `${activity.id}`);
      if (!found) return false;
      return true;
    },
    [plannedActivities]
  );

  return (
    <CustomForm customInputColorText="#2E3A59">
      <H6 color="#B85EC4" fontWeight={800}>
        INFORMAÇÕES DO PROGRAMA
      </H6>
      <Input disabled defaultValue={programCurrent.title} label="Nome do Programa:" name="title" placeholder="Adicione Nome do Programa" />

      <TagsRender label="Tags do Programa:" tags={programCurrent.tags} />

      <AccordionInfoItem
        colorHeader="#DAE3F6"
        status="tertiary"
        title={`Atividades do Programa (${programCurrent.activities ? programCurrent.activities.length : 0})`}
      >
        <CardsContainer lockScroll={false} className="cards-container">
          {programCurrent.activities &&
            programCurrent.activities.map((ac, arrayId) => (
              <div key={ac.id} onClick={() => handleClickOnActivityCard && handleClickOnActivityCard(ac)}>
                <CardInformation className="index-cards-container">
                  <div className="index-activity"> {arrayId + 1} </div>
                  {isActivityInCalendar(ac) && <div className="is-already-in-calendar"> Atividade Inserida no Planejamento </div>}
                </CardInformation>
                <ActivityLibraryCard key={ac.id} className="activity-library-card-drag" activityLibraryCurrent={ac} />
              </div>
            ))}
        </CardsContainer>
      </AccordionInfoItem>
    </CustomForm>
  );
};

export { ProgramFormInfo };
