import { css, styled } from 'react-alicerce-components';

export const WrapperContent = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
  `}

  audio {
    width: -webkit-fill-available;
    height: 54px;
  }
`;

export const Footer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: 2px solid #8d593b;
    border-radius: 16px;
  }
  i {
    display: flex;
    align-items: center;
  }
  svg {
    fill: #482b85;
    width: 12px;
    height: 12px;
  }
`;
