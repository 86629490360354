import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Layout from '@modules/home/components/Layout';
import Divider from '@shared/components/Divider';
import ButtonGradient from '@shared/components/ButtonGradient';
import { H6, Spinner } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { AttachButton, ContainerButtons } from './styles';
import AttachedTools from '@shared/components/AttachedTools';
import { Outlet, useNavigate } from 'react-router-dom';
import ListGroupsService from '@modules/groups/services/ListGroupsService';
import useAuth from '@shared/store/auth/hook';
import ListPlacesService from '@modules/places/services/ListPlacesService';
import ListClassesService from '@modules/classes/services/ListClassesService';
import IClass from '@modules/classes/interfaces/IClass';
import IPlace from '@modules/places/interfaces/IPlace';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import PlaceCard from '@modules/places/components/PlaceCard';
import ClassCard from '@modules/classes/components/ClassCard';
import GroupCard from '@modules/groups/components/GroupCard';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import GetLoggedLmsUserService from '@modules/home/services/GetLoggedLmsUserService';
import formatButtonAttatched from '@shared/utils/formatButtonAttatched';
import { useHome } from '@modules/home/context/HomeContext';

const Home: React.FC = () => {
  const [isAttachToolsOpen, setIsAttachToolsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const { setAttachedTools, attachedTools } = useHome();
  const [fixedClasses, setFixedClasses] = useState<IClass[]>([]);
  const [fixedGroups, setFixedGroups] = useState<IGroup[]>([]);
  const [fixedPlaces, setFixedPlaces] = useState<IPlace[]>([]);
  const isMounted = useIsMounted();

  const loadFixedClass = useCallback(async () => {
    if (!signedUser) return;
    setIsLoading(true);
    const res = await new ListClassesService(signedUser.token).execute('', { fixed: true, size: 9999 });
    if (res === 'canceling') return;
    if (res && isMounted()) setFixedClasses(res.items);
  }, [isMounted, setIsLoading, signedUser]);

  const loadFixedGroups = useCallback(async () => {
    if (!signedUser) return;
    const res = await new ListGroupsService(signedUser.token).execute('', { fixed: true, size: 9999 });
    if (res === 'canceling') return;
    if (res && isMounted()) setFixedGroups(res.items);
  }, [isMounted, signedUser]);

  const loadFixedPlaces = useCallback(async () => {
    if (!signedUser) return;
    const res = await new ListPlacesService(signedUser.token).execute('', { fixed: true, size: 9999 });
    if (res === 'canceling') return;
    if (res && isMounted()) setFixedPlaces(res.items);
  }, [isMounted, signedUser]);

  const loadAttatchedButtons = useCallback(async () => {
    if (!signedUser) return;
    const res = await new GetLoggedLmsUserService(signedUser.token).execute();
    if (!res || !isMounted()) return;
    const formatedButtons = formatButtonAttatched(res.lmsUser.fixed_tools?.fixed_button_keys);
    setAttachedTools(formatedButtons);
    setIsLoading(false);
  }, [isMounted, setAttachedTools, setIsLoading, signedUser]);

  useEffect(() => {
    loadFixedClass();
    loadFixedGroups();
    loadFixedPlaces();
    loadAttatchedButtons();
  }, [loadAttatchedButtons, loadFixedClass, loadFixedGroups, loadFixedPlaces]);

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <AttachedTools isOpen={isAttachToolsOpen} setIsAttachToolsOpen={setIsAttachToolsOpen} loadAttatchedButtons={loadAttatchedButtons} />
      <Layout openTab={0}>
        <AttachButton className="attach-tools" onClick={() => setIsAttachToolsOpen(true)}>
          <Icon name="edit-2" fill="#FFFFFF" />
        </AttachButton>

        <ContainerButtons>
          {attachedTools &&
            attachedTools.map((button, i) => (
              <ButtonGradient disabled={button.disabled} key={i} type={button.colorType} onClick={() => navigate(button.path)}>
                {button.name}
              </ButtonGradient>
            ))}
        </ContainerButtons>

        <Divider />

        <H6 fontWeight={800} status="quaternary" textAlign="center">
          TURMAS FIXADAS
        </H6>
        <div className="container-fixed-classes">
          {fixedClasses &&
            fixedClasses.map((c) => (
              <ClassCard key={c.id} classInfo={c} handleOnClick={() => navigate(`/turma/${c.id}`, { state: { pathToReturn: '/' } })} />
            ))}
        </div>

        <Divider />

        <H6 fontWeight={800} status="tertiary" textAlign="center">
          GRUPOS FIXADOS
        </H6>
        <div className="container-fixed-groups">
          {fixedGroups &&
            fixedGroups.map((g) => (
              <GroupCard key={g.id} group={g} handleOnClick={() => navigate(`/grupo/${g.id}`, { state: { pathToReturn: '/' } })} />
            ))}
        </div>

        <Divider />

        <H6 fontWeight={800} status="primary" textAlign="center">
          LOCAIS FIXADOS
        </H6>
        <div className="container-fixed-places">
          {fixedPlaces &&
            fixedPlaces.map((p) => (
              <PlaceCard key={p.id} place={p} handleOnClick={() => navigate(`/local/${p.id}`, { state: { pathToReturn: '/' } })} />
            ))}
        </div>
      </Layout>
      <Outlet />
    </Fragment>
  );
};

export default Home;
