import React from 'react';
import { Pill } from 'react-alicerce-components';
import { SprintCardBorderContainer } from './styles';

export interface ISprintCardBorder {
  name?: String[];
  data?: string[];
  location: 'header' | 'footer';
  actual?: boolean;
}

const SprintCardBorder: React.FC<ISprintCardBorder> = (props) => {
  return (
    <SprintCardBorderContainer location={props.location}>
      {props.name?.map((CurrentName, index) => (
        <Pill borderColor="none" key={index}>
          {CurrentName}
        </Pill>
      ))}
      {props.data?.map((CurrentDate, index) => (
        <Pill backgroundColor="#ffc94d" key={index}>
          {CurrentDate}
        </Pill>
      ))}
      {props.actual && <Pill backgroundColor="#8CFAC7">Vigente</Pill>}
    </SprintCardBorderContainer>
  );
};

export default SprintCardBorder;
