import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PlaceList from '@modules/places/components/PlaceList';
import IPlace from '@modules/places/interfaces/IPlace';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

const OccurencePlacesRelationCreate: React.FC = () => {
  const { setOccurrenceCurrent, occurrenceCurrent } = useOccurrences();
  const [selectedPlace, setSelectedPlace] = useState<IPlace>();

  useEffect(() => {
    if (!occurrenceCurrent) return;
    setSelectedPlace(occurrenceCurrent.place);
  }, [occurrenceCurrent]);

  const handleAddRelationPlace = useCallback(
    (placeSelect: IPlace[] | undefined) => {
      setOccurrenceCurrent((oldState: any) => {
        if (placeSelect) return { ...oldState, place: placeSelect[0] };
      });
    },
    [setOccurrenceCurrent]
  );

  const setSelectedItens = useCallback(
    (place: IPlace[]) => {
      setSelectedPlace(place[0]);
    },
    [setSelectedPlace]
  );

  const selectedItens = useMemo(() => {
    const aux = [] as IPlace[];
    selectedPlace && aux.push(selectedPlace);
    return aux;
  }, [selectedPlace]);

  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Adicionar Local"
      pathRedirect="/ocorrencias/criar"
      pathToReturn="/ocorrencias/criar"
      canSelectPlace={'one'}
      handleOnClick={setSelectedItens}
      selectedItens={selectedItens}
      setSelectedItens={handleAddRelationPlace}
    />
  );
};

export default OccurencePlacesRelationCreate;
