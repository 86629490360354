import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /*====  RESET ============================ */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  /*====  VARIABLES ============================ */
  :root {
    --primary-font: 'Open Sans', sans-serif;
    --secondary-font: 'Open Sans Condensed', sans-serif;
    --card-distance: 2rem 0rem;
    --black: #222B45;

  }
  
  html {
    scroll-behavior: smooth;
  }

  .input-container {
    > div {
      .input-end-container i {
          display: flex;
          svg {
            width: 23px;
            height: 23px;
          }
      }
      input {
        font-family: var(--primary-font);
        font-weight: 800;
        ::placeholder {
          font-family: var(--primary-font);
          font-weight: 800;
        }
      }
    }
  }

  .pagination-content{
    color: #2E3A59;
    font-weight: 700;
    margin-top: 0px ;
  }

  textarea::placeholder  {
    font-family: var(--primary-font) !important;
  }

  // FIX - REFATORACAO APOS UTILIZACAO DOS GRIDS PARA DESKTOP
  .custom-form {
    .grid.row-true {
      align-items: flex-end !important;

      label,
      .select-container,
      .input-container {
        margin: 0 !important;
      }

      .select-container {
        max-width: 100%;
      }
    }
  }
  

  @media screen and (max-width: 330px) {
    button.button-gradient {
      padding: .5rem;
    }
  }  

  // RESPONSIVE - DESKTOP
  @media (min-width: 992px){
    html {
      font-size: 18px;

      *::placeholder,
      .text-add-button,
      label {
        font-size: .9rem !important;
      }

      h6 {
        font-size: 1.1rem !important;
      }
    }

    .accordion-header {
      .accordion-title {
        font-weight: 700 !important;
      }
      .styled-icon i svg {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
    
    .container-page-footer {
      justify-content: space-between !important;
    }
  };



`;

export default GlobalStyle;
