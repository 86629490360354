import IRoute from "@shared/interfaces/IRoute";
import PlaceCreate from "@modules/places/views/PlaceCreate";
import PlaceHistory from "@modules/places/views/PlaceHistory";
import PlaceHome from "@modules/places/views/PlaceHome";
import PlaceInfo from "@modules/places/views/PlaceInfo";
import PlaceReport from "@modules/places/views/PlaceReport";
import PlaceSchedule from "@modules/places/views/PlaceSchedule";
import PlaceDirectory from "@modules/places/views/PlaceDirectory";
import PlaceListParentCreate from "@modules/places/views/PlaceListParentCreate";
import PlaceUsersRelationCreate from "@modules/places/views/PlaceUsersRelationCreate";
import PlaceEdit from "@modules/places/views/PlaceEdit";
import PlaceListParentEdit from "@modules/places/views/PlaceListParentEdit";
import PlaceUsersRelationEdit from "@modules/places/views/PlaceUsersRelationEdit";
import PlaceTagsRelationCreate from "@modules/places/views/PlaceTagsRelationCreate";
import PlaceTagsRelationEdit from "@modules/places/views/PlaceTagsRelationEdit";

import { PlacesProvider } from "@modules/places/context/PlacesContext";
import { combineContext } from "@shared/utils/combineContext";
import { IModuleRoutes } from "@shared/interfaces/IModuleRoutes";

import { placesKeys } from "@modules/places/keys";
import { usersKeys } from "@modules/users/keys";
import { tagsKeys } from "@modules/tags/keys";

const routes: IRoute[] = [
  {
    path: "/local/:id",
    key: "HOME_PLACES_PATH",
    exact: true,
    component: PlaceHome,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/local/:id/informacoes",
    key: "INFO_PLACES_PATH",
    exact: true,
    component: PlaceInfo,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/local/:id/editar",
    key: "EDIT_PLACES_PATH",
    exact: true,
    component: PlaceEdit,
    permissions: [
      placesKeys.PLACE_READ,
      placesKeys.PLACE_UPDATE,
    ]
  },
  {
    path: "/local/:id/agenda",
    key: "SCHEDULE_PLACES_PATH",
    exact: true,
    component: PlaceSchedule,
    permissions: [
      placesKeys.PLACE_READ
    ]
  },
  {
    path: "/local/:id/historico",
    key: "HISTORY_PLACES_PATH",
    exact: true,
    component: PlaceHistory,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/local/:id/relatorios",
    key: "REPORT_PLACES_PATH",
    exact: true,
    component: PlaceReport,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/diretorio/locais",
    key: "DIRECTORY_PLACES_PATH",
    exact: true,
    component: PlaceDirectory,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/diretorio/locais/criar",
    key: "CREATE_PLACES_PATH",
    exact: true,
    component: PlaceCreate,
    permissions: [
      placesKeys.PLACE_CREATE,
    ]
  },
  {
    path: "/diretorio/locais/criar/selecionar",
    key: "ADD_PLACES_PLACES_PATH",
    exact: true,
    component: PlaceListParentCreate,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/diretorio/locais/editar/selecionar",
    key: "DIRECTORY_PLACES_EDIT_SELECT_PATH",
    exact: true,
    component: PlaceListParentEdit,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: "/diretorio/locais/criar/selecionar/usuarios",
    key: "ADD_PLACES_USER_PATH",
    exact: true,
    component: PlaceUsersRelationCreate,
    permissions: [
      usersKeys.LMS_USER_READ,
    ]
  },
  {
    path: "/diretorio/locais/editar/selecionar/usuarios",
    key: "EDIT_PLACES_USER_PATH",
    exact: true,
    component: PlaceUsersRelationEdit,
    permissions: [
      usersKeys.LMS_USER_READ,
    ]
  },
  {
    path: "/diretorio/locais/criar/selecionar/tags",
    key: "TAGS_CREATE_SELECT_PATH",
    exact: true,
    component: PlaceTagsRelationCreate,
    permissions: [
      tagsKeys.TAGS_READ,
    ]
  },
  {
    path: "/diretorio/locais/editar/selecionar/tags",
    key: "TAGS_EDIT_SELECT_PATH",
    exact: true,
    component: PlaceTagsRelationEdit,
    permissions: [
      tagsKeys.TAGS_READ,
    ]
  },
];

const providers = [
  PlacesProvider
];

export default {
  key: 'PLACES_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
