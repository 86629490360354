import { styled } from 'react-alicerce-components';

export const MenuKebabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  cursor: pointer;

  i {
    display: flex;
    padding: 0.5rem;
    svg {
      width: 25px;
      height: 25px;
    }
  }

  .dropdown-more-options {
    position: absolute;
    top: 35px;
    right: 10px;
    box-shadow: 0 2px 5px 0 rgb(11 20 26 / 26%), 0 2px 10px 0 rgb(11 20 26 / 16%);
    cursor: pointer;
    z-index: 999;

    .option {
      background: #fff;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      gap: 1rem;
      padding: 8px 16px;

      svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        background: #dadada;
      }
    }
  }
`;

export const MoreOptionsStyles = styled.div`
  position: relative;
`;