import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';

import { objectToQuery } from '@shared/utils/objectToQuery';
import IListOptions from '@shared/interfaces/IListOptions';
import { IPortfolioActivity } from '../interfaces/IPortfolioActivity';

export interface IListPortfolioActivitiesServiceServiceReponse extends IPaginatedResponse {
  items: IPortfolioActivity[];
}

export type IValidTypes = 'checkpoint' | 'pdv';

export interface IListPortfolioActivities extends IListOptions {
  class_id: number;
  delivery_date?: {
    start?: string;
    end?: string;
  };
  types?: IValidTypes[];
}

class ListPortfolioActivitiesServiceService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    query: string,
    options?: IListPortfolioActivities
  ): Promise<IListPortfolioActivitiesServiceServiceReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      const res = await this.api.get(`/portfolio?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export { ListPortfolioActivitiesServiceService };
