import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-page-footer button {
    border-color: #85abf8;
    background: #85abf8;
  }

  .box-title-create {
    display: flex;
    gap: 5px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    padding: 15px;
    border-bottom: 2px solid #edf1f7;
    margin-bottom: 30px;
  }

  .container-text-modal {
    .span-text-modal {
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #222b45;
      padding: 3px 15px;
    }

    > div {
      margin: 4px auto;
      width: max-content;

      label span {
        margin-left: 10px;
      }
    }
  }
`;
