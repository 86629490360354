import { styled } from 'react-alicerce-components';

export const ActivityLibraryCardBorderContainer = styled.div<{ location: 'header' | 'footer', isDescriptionOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  background: #96b6f7;
  min-height: 40px;
  position: relative;
  z-index: 1;


  ${(p) =>
    p.location === 'header' &&
    `
    border-radius:16px 16px 0px 0px;
    
    >div {
      background: #E4E9F2;
    }
    `}

  ${(p) =>
    (p.location === 'footer' && !p.isDescriptionOpen) &&
    `
    justify-content: space-between;
    border-radius:0px 0px 16px 16px;
    >div {
      background: #FFC94D;
    }

  `}
  ${(p) =>
    p.location === 'footer' &&
    `
    justify-content: space-between;
  `}
`;
