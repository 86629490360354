export const literate = { name: 'Alfabetizado', value: 'is_literate' };
export const notLiterate = { name: 'Não Alfabetizado', value: 'not_literate' };
export const literateNotCollected = { name: 'Não Coletado', value: 'not_collected' };

export type IValidLiterate = 'is_literate' | 'not_literate' | 'not_collected';

export const LITERATE_CONSTRAINTS = [literate, notLiterate, literateNotCollected];

export const defaultLiterate = (ageGroup?: string | null) => {
  return [
    { name: literate.name, value: literate.value, selected: ageGroup === literate.value },
    { name: notLiterate.name, value: notLiterate.value, selected: ageGroup === notLiterate.value },
    { name: literateNotCollected.name, value: literateNotCollected.value, selected: ageGroup === literateNotCollected.value },
  ];
};

export const LITERATE_VALUES = LITERATE_CONSTRAINTS.map((a) => a.value);
