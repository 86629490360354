import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';

import { Label, Button, Input, Select, Textarea, InputDatePicker, Spinner, Checkbox } from 'react-alicerce-components';

import { useActivitiesPortfolio } from '@modules/activities-portfolio/context/ActivitiesPortfolioContext';

import CustomForm from '@shared/components/CustomForm';
import StudentCard from '@modules/students/components/StudentCard';
import LinkAttach from '@shared/components/LinkAttach';

import { Container, ContainerLinks, ContainerOccurencesCheckbox, DowloadContainer } from './styles';
import { defaultActivityPortfolioType } from '../../utils/validPortfolioActivityType';
import { IPortfolioActivity } from '../../interfaces/IPortfolioActivity';
import { errorMessageBuilder, hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { ButtonAddFiles } from '@shared/components/ButtonAddFiles';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { datePickerFormat } from '../../../../shared/utils/datePickerFormat';
import { IStudent } from '@modules/students/interfaces/IStudent';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import ListStudentsService, { IStudentReponse } from '@modules/students/services/ListStudentsService';
import { BsCheckLg } from 'react-icons/bs';
interface IErrors {
  [key: string]: string;
}

interface IActivityFormProps {
  errors: IInputErrorsFormat[];
  formAction: 'creating' | 'editing' | 'watching';
  downlodSingleFileCallback?: (file: File | IAttachment) => void;
  downloadAllFilesCallback?: () => void;
}

const ActivityPortfolioForm: React.FC<IActivityFormProps> = ({ formAction, errors, downloadAllFilesCallback, downlodSingleFileCallback }) => {
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const [studentList, setStudentList] = useState<IStudentReponse>();
  const { activityPortfolioCurrent, setActivityPortfolioCurrent } = useActivitiesPortfolio();
  const { classId } = useParams();

  const listStudents = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const students = await new ListStudentsService(signedUser.token, newCancelToken()).execute(undefined, {
        page,
        size: 99,
        class_id: classId,
      });
      if (students === 'canceling') return;
      setIsLoading(false);
      if (!students) return;
      setStudentList(students);
    },
    [classId, newCancelToken, setIsLoading, signedUser]
  );

  const handleOnChangeField = useCallback(
    (objKey: string, newValue?: string) => {
      setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
        return { ...oldState, [objKey]: newValue };
      });
    },
    [setActivityPortfolioCurrent]
  );

  const handleOnChangeArrayField = useCallback(
    (objKey: string, arrayIndexChanged: number, newValue?: any) => {
      setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
        let aux = [...oldState[objKey]];
        aux[arrayIndexChanged] = newValue;
        return { ...oldState, [objKey]: aux };
      });
    },
    [setActivityPortfolioCurrent]
  );

  const handleAddLinks = useCallback(() => {
    setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
      if (!oldState.links) return { ...oldState, links: [''] };
      return { ...oldState, links: [...oldState.links, ''] };
    });
  }, [setActivityPortfolioCurrent]);

  const handleRemoveFromArray = useCallback(
    (arrayIndexToRemove: number, objKey: string) => {
      setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
        const filtered = removeItemFromArrayByIndex(arrayIndexToRemove, oldState[objKey]);
        return { ...oldState, [objKey]: filtered };
      });
    },
    [setActivityPortfolioCurrent]
  );

  const containMedia = useMemo(() => !!activityPortfolioCurrent?.attachments?.length, [activityPortfolioCurrent]);
  const containLinks = useMemo(() => !!activityPortfolioCurrent?.links?.length, [activityPortfolioCurrent]);

  const handleOnAddNewFile = (files: (File | IAttachment)[]) => {
    setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
      oldState['attachments'] = files;
      return { ...oldState };
    });
  };

  const handleOnRemoveFile = (indexToRemove: number) => {
    setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
      oldState['attachments'] = oldState['attachments']?.filter((_, index) => Number(index) !== Number(indexToRemove));
      return { ...oldState };
    });
  };

  useEffect(() => {
    listStudents();
  }, [listStudents]);

  const handleSelectStudents = useCallback(
    (student: IStudent, event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const auxiliar = activityPortfolioCurrent?.students ? activityPortfolioCurrent?.students : [];

      if (checked) {
        setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
          return { ...oldState, students: [...auxiliar, student] };
        });
      } else {
        const filtered = auxiliar.filter((aux) => aux.id !== student.id);
        setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
          return { ...oldState, students: filtered };
        });
      }
    },
    [activityPortfolioCurrent, setActivityPortfolioCurrent]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      if (!studentList) return;

      if (checked) {
        setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
          return { ...oldState, students: [...studentList?.items] };
        });
      }

      if (!checked) {
        setActivityPortfolioCurrent((oldState: IPortfolioActivity) => {
          return { ...oldState, students: [] };
        });
      }
    },
    [setActivityPortfolioCurrent, studentList]
  );

  return (
    <Container>
      {isLoading && <Spinner />}
      <CustomForm style={{ padding: '.5rem', minHeight: '80vh' }}>
        <Input
          disabled={formAction === 'watching'}
          label="Nome da Atividade:"
          name="activity_name"
          placeholder="Adicione Nome da Atividade"
          useTooltipError={false}
          error={errorMessageBuilder('activity_name', errors)}
          defaultValue={activityPortfolioCurrent.activity_name}
          onChange={(event) => handleOnChangeField('activity_name', event.target.value)}
        />
        <Select
          disabled={formAction === 'watching'}
          label="Tipo do Entregável:"
          iconName="arrow-down"
          placeholder="Escolha um Tipo"
          options={defaultActivityPortfolioType(activityPortfolioCurrent.type)}
          error={errorMessageBuilder('type', errors)}
          handleSelectChange={(opt) => handleOnChangeField('type', opt?.value)}
        />

        {formAction !== 'watching' ? (
          <InputDatePicker
            key={activityPortfolioCurrent?.delivery_date}
            handleChangeDate={(date) => handleOnChangeField('delivery_date', date)}
            defaultValue={activityPortfolioCurrent?.delivery_date ? datePickerFormat(activityPortfolioCurrent?.delivery_date, '/', '-') : undefined}
            input={{
              label: 'Data da Realização:',
              name: 'delivery_date',
              placeholder: 'Dia/Mês/Ano',
              useTooltipError: false,
              required: true,
              error: errorMessageBuilder('delivery_date', errors),
            }}
          />
        ) : (
          <Input
            disabled
            label="Data da Realização:"
            name="delivery_date"
            defaultValue={activityPortfolioCurrent?.delivery_date ? datePickerFormat(activityPortfolioCurrent?.delivery_date, '/', '-') : undefined}
          />
        )}

        {formAction !== 'creating' && activityPortfolioCurrent.created_at && (
          <Input disabled type="date" label="Data de Entrega:" name="created_at" defaultValue={activityPortfolioCurrent.created_at.split('T')[0]} />
        )}

        <Textarea
          disabled={formAction === 'watching'}
          name="description"
          label="Descrição da Atividade:"
          placeholder="Adicione Descrição da Atividade"
          value={activityPortfolioCurrent?.description}
          rows={4}
          useTooltipError={false}
          error={errorMessageBuilder('description', errors)}
          onChange={(event) => handleOnChangeField('description', event.target.value)}
        />
        <Textarea
          disabled={formAction === 'watching'}
          name="comments"
          label="Comentários:"
          placeholder="Adicione Comentários da Atividade"
          value={activityPortfolioCurrent?.comments}
          rows={4}
          useTooltipError={false}
          error={errorMessageBuilder('comments', errors)}
          onChange={(event) => handleOnChangeField('comments', event.target.value)}
        />
        <AccordionInfoItem
          hasErrors={hasError(['students_ids'], errors)}
          status="info"
          title={`Alunos Selecionados (${activityPortfolioCurrent?.students?.length || 0})`}
        >
          <div className="select-all-students-container">
            <Label>Selecionar Todos</Label>
            <Checkbox
              semiRound
              icon={BsCheckLg}
              hideIconUncheck
              name={'select-all'}
              id={'select-all '}
              onChange={(event) => handleSelectAll(event)}
            />
          </div>
          {studentList?.items?.map((student, i) => (
            <ContainerOccurencesCheckbox key={`${student?.id}`}>
              <StudentCard student={student} key={`student-${student.id}`} canSelect={false} />

              <Checkbox
                semiRound
                name={`user-${student.id}`}
                id={`user-${student.id}`}
                onChange={(event) => handleSelectStudents(student, event)}
                icon={BsCheckLg}
                hideIconUncheck
                checked={!!activityPortfolioCurrent?.students?.find((foo: IStudent) => foo.id === student.id)}
              />
            </ContainerOccurencesCheckbox>
          ))}
        </AccordionInfoItem>
        <AccordionInfoItem
          status="info"
          title={`
            Links(${activityPortfolioCurrent.links ? activityPortfolioCurrent.links.length : 0}) e 
            Anexos(${activityPortfolioCurrent.attachments ? activityPortfolioCurrent.attachments.length : 0})
          `}
          hasErrors={hasError(['links'], errors)}
        >
          {formAction !== 'watching' && <InputErrorMessage error={errorMessageBuilder('links', errors)} />}
          {formAction !== 'watching' && <ButtonAddCard text="Anexar Links" handleOnClick={handleAddLinks} />}
          {containLinks && (
            <ContainerLinks>
              <Label>Links: </Label>
              {activityPortfolioCurrent?.links?.map((link, i) => (
                <LinkAttach
                  handleOnChange={(newValue) => handleOnChangeArrayField('links', i, newValue)}
                  key={`${i}-${activityPortfolioCurrent?.links?.length}`}
                  link={link}
                  canDelete={formAction !== 'watching'}
                  handleOnDelete={() => handleRemoveFromArray(i, 'links')}
                />
              ))}
            </ContainerLinks>
          )}

          <ButtonAddFiles
            iconName="attach-2-outline"
            text="Anexar Arquivos"
            colorBackgroundIcon=" #FFBA95"
            colorBackgroundText="#FFE7D9"
            seeFileButton
            filesToSave={activityPortfolioCurrent.attachments}
            callbackFilesToSave={(files) => handleOnAddNewFile(files)}
            callbackFilesToRemove={formAction === 'watching' ? undefined : (index) => handleOnRemoveFile(index)}
            downloadSingleFileCallback={downlodSingleFileCallback}
          />

          {downloadAllFilesCallback && (
            <DowloadContainer containMedia={containMedia}>
              <Button onClick={downloadAllFilesCallback} status="info" transform="none" noBorder shaded>
                Baixar Tudo
              </Button>
            </DowloadContainer>
          )}
        </AccordionInfoItem>
      </CustomForm>
    </Container>
  );
};

export { ActivityPortfolioForm };
