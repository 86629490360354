import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container } from './styles';

import { Input, Spinner } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';

import useAuth from '@shared/store/auth/hook';

import ListStudentFromClassWithChartsService from '@modules/learning-targets/services/ListStudentFromClassWithChartsService';
import { IStudentWithGradesCharts } from '@modules/launchers/interfaces/IStudentsWithGrades';
import WrapperContent from '@modules/launchers/components/WrapperContent';
import { getTrailId } from '@modules/launchers/utils/getTrail';
import { LearningTargetsStudentCard } from '@modules/learning-targets/components/LearningTargetsStudentCard';
import Icon from 'react-eva-icons';

interface ILearningTargetFilter {
  meta_block_status?: boolean;
  age_groups?: string[];
}

export type ILearningTargetsProps = {
  trail?: string;
  id?: string;
};

const LearningTargets: React.FC<ILearningTargetsProps> = ({ trail, id }) => {
  const [studentFromClass, setStudentFromClass] = useState<IStudentWithGradesCharts>();
  const learningTargetFilter = useRef<ILearningTargetFilter>({});

  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();

  const listStudentFromClass = useCallback(
    async (search = '', trail_id = getTrailId(trail)) => {
      if (!id) return;
      setIsLoading(true);

      const res = await new ListStudentFromClassWithChartsService(signedUser?.token, newCancelToken()).execute(search, id, true, {
        trail_id: trail_id,
        ...learningTargetFilter.current,
      });

      if (res === 'canceling') return;
      setIsLoading(false);
      if (!!!res || !isMounted()) return;
      if (!res) return;
      setStudentFromClass(res);
    },
    [id, isMounted, newCancelToken, setIsLoading, signedUser, trail]
  );

  useEffect(() => {
    listStudentFromClass();
  }, [listStudentFromClass, trail]);

  return (
    <Container>
      {isLoading && <Spinner fixed />}
      <Input
        placeholder="Buscar"
        name="search"
        end={<Icon name="funnel" fill="#8F9BB3" />}
        onChange={(event) => listStudentFromClass(event.target.value)}
      />
      <WrapperContent customStyle={{ paddingBottom: '2rem' }}>
        <div className="student-container">
          {studentFromClass?.students_charts?.map((studentData) => (
            <LearningTargetsStudentCard
              key={`${studentData.student.id}-${trail}`}
              studentData={studentData}
              className="subscription-card"
              trails
              listStudentFromClass={listStudentFromClass}
            />
          ))}
        </div>
      </WrapperContent>
    </Container>
  );
};
export { LearningTargets };
