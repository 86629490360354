import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

const OccurrenceTagRelation: React.FC = () => {
  const navigate = useNavigate();
  const { occurrenceCurrent, setOccurrenceCurrent } = useOccurrences();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, tags: tags };
      });
    },
    [setOccurrenceCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={occurrenceCurrent ? occurrenceCurrent.tags : []}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
      buttonCallback={() => navigate(-1)}
    />
  );
};

export default OccurrenceTagRelation;
