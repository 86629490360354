import { getRgbaToHex } from "@shared/utils/getRgbaToHex";
import { IGroupFrenquencyPercent } from "../interfaces/IGroupFrenquencyPercent";
import { getYearsOld } from "./getYearsOld";

export interface IFormattedGroupFrequencyPercent {
  id?: string | number;
  name?: string;
  date?: string;
  selected?: boolean;
  percent?: number;
  percentBgColor?: string | number;
}  

export const formatGroupFrequencyPercent = (data: IGroupFrenquencyPercent): IFormattedGroupFrequencyPercent => {
  const transparency = data.presence_percentage <= 10 ? .1 :  Number(data.presence_percentage / 100);
  const percentBgColor = getRgbaToHex([0, 214, 143, transparency]);
  return {
    id: data?.student?.id,
    name: data?.student?.name,
    date: getYearsOld(`${data.student.birthdate}`),
    selected: false,
    percent: data.presence_percentage,
    percentBgColor,
  }
}
