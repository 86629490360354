import styled from 'styled-components';

export const Container = styled.div`
    background-color: #EDF1F7;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;

    .over {
        border: 3px dotted #666;
        transition: ease all .2s;
    }

`;

export const CanSelectInfo = styled.div<{content?:string}>`
    padding: 2rem 1rem;

    &:after {
        content: "${({ content }) => content}";
        position: absolute;
        top: 2rem;
        left: .5rem;
        right: .5rem;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #2E3A59;
        font-family: var(--secondary-font);
    }
`;

export const Wrapper = styled.div<{lockedScroll?:boolean}>`
    min-height: 500px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: ${({ lockedScroll }) => lockedScroll ? 'hidden' : 'scroll'};
`;

export const Header = styled.div`
    position: absolute; 
    top: 25px;
    left: 5px;
    right: 5px;
    background-color: #BE3A3A;
    padding: .5rem;
    border-radius: 6px;
    z-index: 3;

    h6 {
        font-size: 1rem;
        font-weight: 800;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }

    p {
        font-size: .7rem;
        text-align: center;
        color: #fff;
        font-weight: 600;
    }
`;