import React, { ChangeEvent, useCallback } from 'react';
import { AttachedTooldBody, AttachedToolsContainer, AttachedToolsHeader } from './styles';
import Icon from 'react-eva-icons';
import { Checkbox } from 'react-alicerce-components';
import { ButtonInfos } from '@shared/utils/ButtonInfos';
import { FiCheck } from 'react-icons/fi';
import AttatchButtonsService from '@modules/users/services/AttatchButtonsService';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useHome } from '../../../modules/home/context/HomeContext';
import formatButtonAttatched from '../../utils/formatButtonAttatched';
import { useIsMounted } from '../../hooks/useIsMounted';

interface IAttachedTools {
  isOpen: boolean;
  setIsAttachToolsOpen: (value: boolean) => void;
  loadAttatchedButtons: () => Promise<void>;
}

const AttachedTools: React.FC<IAttachedTools> = ({ isOpen, setIsAttachToolsOpen, loadAttatchedButtons }) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { setAttachedTools, attachedTools } = useHome();
  const isMounted = useIsMounted();

  const handleOnClick = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!signedUser) return;
      setIsLoading(true);
      let attachedKeys = attachedTools ? attachedTools.map((button) => button.key) : [];

      if (event.target.checked) {
        attachedKeys.push(event.target.name);
      } else {
        attachedKeys = attachedKeys.filter((b) => b !== event.target.name);
      }

      const res = await new AttatchButtonsService(signedUser.token).execute(attachedKeys);
      setIsLoading(false);
      if (!res || !isMounted()) return;

      const formatedButtons = formatButtonAttatched(attachedKeys);
      setAttachedTools(formatedButtons);
    },
    [signedUser, setIsLoading, attachedTools, isMounted, setAttachedTools]
  );

  return (
    <AttachedToolsContainer className="attach-tools-container" isOpen={isOpen}>
      <AttachedToolsHeader className="attach-tools-header">
        <div className="left-content" onClick={() => setIsAttachToolsOpen(false)}>
          <Icon name="arrow-ios-back" />
        </div>
        <div className="middle-content">
          <span>Fixar Ferramentas</span>
        </div>
        <div className="right-content"></div>
      </AttachedToolsHeader>

      <AttachedTooldBody>
        {ButtonInfos.map((tool, id) => (
          <div className="tool-check" key={id}>
            <Checkbox
              id={tool.key}
              key={`${tool.key}-${attachedTools}`}
              name={tool.key}
              semiRound={true}
              defaultChecked={attachedTools ? attachedTools.includes(tool) : false}
              hideIconUncheck
              icon={FiCheck}
              status="basic"
              onChange={(event) => handleOnClick(event)}
            />
            <span className="span-text">{tool.name}</span>
          </div>
        ))}
      </AttachedTooldBody>
    </AttachedToolsContainer>
  );
};

export default AttachedTools;
