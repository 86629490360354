import React, { Fragment } from 'react';
import { Input, P, Textarea, Pagination } from 'react-alicerce-components';
import Divider from '@shared/components/Divider';
import { getDay } from '@shared/utils/getDay';

import { CommentsContainer } from './styles';
import { IDailyClassPlannCommentsReponse } from '../../../services/ListDailyClassPlannedCommentsService';

interface IComment {
  commentsResponse: IDailyClassPlannCommentsReponse;
  listDailyClassCommentsCallback: (search: string, page: number, option?: any) => Promise<void>;
}

const Comments: React.FC<IComment> = ({ commentsResponse, listDailyClassCommentsCallback }) => {
  return (
    <CommentsContainer>
      {commentsResponse &&
        commentsResponse.items.map((comment, i) => (
          <Fragment key={`comment-${comment.id}`}>
            <div className="comment-user-name-container">
              <P>{comment.created_at && `Adicionado em ${getDay(new Date(comment.created_at))}`}</P>
              <Input
                label="Nome do Usuário:"
                name={`input-comment-leader-${i}`}
                key={comment.creator?.name}
                defaultValue={comment.creator?.name}
                disabled
              />
            </div>

            <Textarea label="Comentário:" name={`input-comment-content-${i}`} value={comment.comment} rows={4} disabled />
            <Divider className="divider" width="100%" />
          </Fragment>
        ))}

      {commentsResponse && commentsResponse.items.length > 0 ? (
        <Pagination
          totalOfPages={commentsResponse?.totalPages}
          currentPage={commentsResponse?.currentPage}
          callbackGetListData={listDailyClassCommentsCallback}
        />
      ) : (
        'Nao existem comentarios nessa Aula'
      )}
    </CommentsContainer>
  );
};

export default Comments;
