import React, { useCallback } from 'react';
import { Button, Checkbox, H6, Input, Textarea } from 'react-alicerce-components';
import { FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { useClass } from '@modules/classes/context/ClassContext';
import { CLASS_TYPE_CONSTRAINTS } from '@modules/classes/utils/validData/validClassType';
import { CLASS_STATUS_CONSTRAINTS } from '@modules/classes/utils/validData/validClassStatus';
import { AgeGroupClass } from '@modules/classes/components/ClassForm/styles';
import { translateDays } from '@modules/classes/utils/translateDays';
import { IStudent } from '@modules/students/interfaces/IStudent';

import Layout from '@modules/classes/components/Layout';
import TagsRender from '@modules/tags/components/TagsRender';
import StudentCard from '@modules/students/components/StudentCard';
import UserCard from '@modules/users/components/UserCard';
import GroupCard from '@modules/groups/components/GroupCard';
import PlaceCard from '@modules/places/components/PlaceCard';

import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import ParentRender from '@shared/components/ParentRender';
import DateTimeRender from '@shared/components/DateTimeRender';
import InputPill from '@shared/components/InputPill';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';
import CustomForm from '@shared/components/CustomForm';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';

const ClassesInfo: React.FC = () => {
  const { clearClassContextData, classCurrent } = useClass();
  const navigate = useNavigate();

  const handleClickBtn = useCallback(() => {
    clearClassContextData();
    navigate(`/turma/${classCurrent.id}/editar`);
  }, [classCurrent.id, clearClassContextData, navigate]);

  const handleClickStudent = useCallback(
    (student: IStudent) => {
      navigate(`/aluno/${student.id}/informacoes`);
    },
    [navigate]
  );

  return (
    <Layout openTab={1} footer={true}>
      <H6 fontWeight={800} status="quaternary">
        DADOS PRINCIPAIS
      </H6>
      <AccordionInfoItem title="Dados Principais">
        <CustomForm style={{ minHeight: '380px' }} status="quaternary" customInputColorText="#542E91">
          <Input disabled label="Nome da Turma:" name="className" defaultValue={classCurrent.name} />
          <Input disabled label="Alunos Previstos:" name="expectedStudents" defaultValue={classCurrent.expected_students} />
          <Checkbox
            key={`${classCurrent.customization}`}
            id={`${classCurrent.customization}`}
            name="Turma com Modificações"
            label="Turma com Modificações"
            icon={FiCheck}
            status="basic"
            defaultChecked={!!classCurrent.customization}
            disabled
            hideIconUncheck
            semiRound
          />
          {!!classCurrent.customization && (
            <Textarea
              label="Modificações da Turma:"
              name="customization"
              value={classCurrent.customization ? classCurrent.customization : ''}
              rows={4}
              useTooltipError={false}
              disabled
            />
          )}
          <InputPill label="Tipo da Turma" inputPillData={[CLASS_TYPE_CONSTRAINTS.find((c) => c.value === classCurrent.type)?.name || '']} />
          <InputPill label="Status da Turma" inputPillData={[CLASS_STATUS_CONSTRAINTS.find((c) => c.value === classCurrent.status)?.name || '']} />

          <AgeGroupClass>
            <label className="title">Grupo Etário:</label>
            <div className="all-age-groups-checkbox">
              {AGE_GROUP_CONSTRAINTS.map((age_group) => (
                <Checkbox
                  disabled
                  key={`${age_group.value} ${classCurrent.age_groups}`}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={age_group.value}
                  name={age_group.value}
                  label={age_group.name}
                  defaultChecked={classCurrent.age_groups.includes(age_group.value)}
                />
              ))}
            </div>
          </AgeGroupClass>

          <Input disabled label="Data de Início:" name="start_date" defaultValue={classCurrent.start_date} type="date" useTooltipError={false} />

          <TagsRender label="Tags: " status="quaternary" tags={classCurrent.tags} />
        </CustomForm>
      </AccordionInfoItem>

      <AccordionInfoItem
        title={`Horário (${classCurrent.calendar_data && classCurrent.calendar_data.length > 0 ? classCurrent.calendar_data?.length : 0})`}
      >
        {classCurrent.calendar_data?.map((classTime, index) => (
          <DateTimeRender key={index} timeStart={classTime.start} timeEnd={classTime.end} date={translateDays(classTime.week_days)} />
        ))}
      </AccordionInfoItem>

      <Divider width="100%" />

      <H6 fontWeight={800} status="quaternary">
        RELACIONADOS
      </H6>
      <ParentRender customStyles={{ padding: 0, marginBottom: '2rem' }} label="Local da Turma" hasParent={!!classCurrent.place}>
        {classCurrent.place && (
          <PlaceCard
            handleOnClick={() => navigate(`/local/${classCurrent.place?.id}`)}
            key={classCurrent.place?.id}
            place={classCurrent.place}
            isInputCard={true}
            customStyle={{ margin: '1rem', cursor: 'pointer' }}
          />
        )}
      </ParentRender>

      <ParentRender customStyles={{ padding: 0, marginBottom: '2rem' }} label="Grupo da Turma" hasParent={!!classCurrent.group}>
        {classCurrent.group && (
          <GroupCard
            handleOnClick={() => navigate(`/grupo/${classCurrent.group?.id}`)}
            key={classCurrent.group?.id}
            group={classCurrent.group}
            inputCard={true}
            customStyle={{ margin: '1rem', cursor: 'pointer' }}
          />
        )}
      </ParentRender>

      <Divider width="100%" />

      <H6 fontWeight={800} status="quaternary">
        PARTICIPANTES/MEMBROS
      </H6>
      <AccordionInfoItem title={`Líderes (${classCurrent.teachers ? classCurrent.teachers.length : 0})`}>
        {classCurrent.teachers?.map((user) => (
          <UserCard handleOnClick={() => navigate(`/usuario/${user.id}/informacoes`)} user={user} canSelect={false} key={user.id} />
        ))}
      </AccordionInfoItem>
      <AccordionInfoItem title={`Alunos (${classCurrent.enrollments ? classCurrent.enrollments.filter((enroll) => enroll.student).length : 0})`}>
        {classCurrent.enrollments &&
          classCurrent.enrollments.map(
            (en) => en.student && <StudentCard handleOnClick={handleClickStudent} student={en.student} key={en.student.id} />
          )}
      </AccordionInfoItem>

      <PageFooter>
        <Button transform="none" status="quaternary" shaded onClick={handleClickBtn}>
          Editar
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default ClassesInfo;
