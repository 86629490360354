import { styled } from 'react-alicerce-components';
import { WorkGroupCardBodyProps } from '.';

export const WorkGroupCardBodyContainer = styled.div<WorkGroupCardBodyProps>`
  background: #E9D7F9;
  min-height: 60px;
  padding: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #222b45;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    transform: ${(p) => (p.isDescriptionOpen ? `rotate(90deg)` : `rotate(0deg)`)};
    transition: 0.3s;
    i {
      display: flex;
      cursor: pointer;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
`;
