import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import Icon from 'react-eva-icons';

import { Button, Checkbox, Label, Pill, useToast } from 'react-alicerce-components';
import ConfirmModal from '../../../../../shared/components/ConfirmModal';

import { IStudentWithGrades } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { formateMinimapaBody } from '@modules/launchers/utils/formateMinimapaBody';

import { useLaunchers } from '@modules/launchers/context/LaunchersContext';

import { Footer, Header, StudentCardLauncherBorderContainer, StudentCardLauncherFooterContainer, When } from './styles';
import { TSkillsAndQuestions } from '..';
import { DotStatus } from '@modules/launchers/components/StudentCardLauncher/styles';
import useAuth from '@shared/store/auth/hook';
import { ValidatedContainer } from '../../../../learning-targets/components/LearningTargetsStudentCard/LearningTargetsStudentCardBorder/styles';
import { IApplication } from '../../../../applications/interfaces/IApplication';
import DeleteApplicationService from '../../../services/DeleteApplicationService';
import useCancelRequest from '../../../../../shared/hooks/useCancelRequest';
import { applicationDate } from '../../../utils/formateDate';

export interface IStudentCardLauncherBorder {
  checkBoxToPrint?: IStudentWithGrades;
  studentData?: IStudentWithGrades;
  displayCheckboxHeader?: boolean;
  data?: string[];
  location: 'header' | 'footer';
  skillsAndQuestions?: TSkillsAndQuestions;
  isPrintble: any;
  setIsPrintble: any;
  isApply: any;
  setIsApply: any;
  lastApplication?: IApplication;
  showLastApplication?: boolean;
  isOpen?: boolean;
  getLastApplicationFromStudent?: () => void;
}

const StudentCardLauncherBorder: React.FC<IStudentCardLauncherBorder> = (props) => {
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const lmsUserId = Number(signedUser?.lmsUser.id);
  const [aplicationIdCancel, setAplicationIdCancel] = useState<string>('');
  const { trailId, selectedStudentsMapaRef, isGlobalChecked, currentClass } = useLaunchers();
  const [showCancelApplicationModal, setShowCancelApplicationModal] = useState<boolean>(false);

  const handleOnClick = (event: ChangeEvent<HTMLInputElement>) => {
    const checked: boolean = event.target.checked;
    const skillsAndQuestions: { id: number; questions: number }[] = [];
    if (!props.studentData?.gradeInfo?.skill_descriptions) return;

    props.studentData.gradeInfo.skill_descriptions.forEach((skills) => {
      const obj = { id: Number(skills.id), questions: 2 };
      skillsAndQuestions.push(obj);
    });
    const skillsAndQ = props.skillsAndQuestions ? props.skillsAndQuestions : skillsAndQuestions;
    if (!trailId || !currentClass) return;
    const studentWithMiniMapa = formateMinimapaBody(lmsUserId, props?.studentData?.student, +trailId, skillsAndQ, currentClass);
    if (checked) {
      selectedStudentsMapaRef.current.applications.push(studentWithMiniMapa);
    }
    if (checked === false) {
      props.setIsApply(false);
      props.setIsPrintble(false);

      selectedStudentsMapaRef.current.applications = selectedStudentsMapaRef.current.applications.filter(
        (app) => Number(app.lms_student_id) !== Number(props?.studentData?.student.id)
      );
    }
  };

  const deleteApplication = useCallback(
    async (AplicationId: string) => {
      if (!signedUser) return;
      const res = await new DeleteApplicationService(signedUser?.token, newCancelToken()).execute(AplicationId);
      props.getLastApplicationFromStudent?.();
      if (res) {
        return addToast({
          status: 'success',
          title: 'MAPA',
          description: 'Mini M.A.P.A cancelado com sucesso.',
        });
      }

      if (!res) {
        return addToast({
          status: 'danger',
          title: 'MAPA',
          description: 'Falha ao tentar cancelar Mini M.A.P.A.',
        });
      }
    },
    [addToast, newCancelToken, props.getLastApplicationFromStudent, signedUser]
  );

  const handleIdApplication = (id: string) => {
    setShowCancelApplicationModal(true);
    setAplicationIdCancel(id);
  };

  const handleCancelApplicationModal = (id: string) => {
    deleteApplication && deleteApplication(id);
    setShowCancelApplicationModal(false);
  };

  const getStatus = (status): { status: string; color: string } =>
    ({
      FINALIZED: { status: 'Feito', color: '#6DCC1F' },
      ONGOING: { status: 'Em Progresso', color: '#FFD74E' },
      REVISION: { status: 'Em Progresso', color: 'FFD74E' },
      PENDING: { status: 'Pendente', color: '#F63E3C' },
      undefined: { status: 'Sem Aplicação', color: '#8F9BB3' },
    }[status]);


  return (
    <StudentCardLauncherBorderContainer location={props.location}>
      {props.displayCheckboxHeader && (
        <Header>
          {props.studentData?.gradeInfo?.is_meta_block_validated && (
            <Fragment>
              <Checkbox
                defaultChecked={
                  !!selectedStudentsMapaRef.current.applications.find((app) => Number(app.lms_student_id) === Number(props.studentData?.student.id))
                }
                semiRound
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                onChange={(e) => handleOnClick(e)}
                key={`${isGlobalChecked} ${props.isApply} ${props.isPrintble} ${selectedStudentsMapaRef.current.applications.find(
                  (app) => Number(app.lms_student_id) === Number(props.studentData?.student.id)
                )} h`}
                name={`${props.studentData?.student.name} h`}
                id={`${props.studentData?.student.id} h`}
                label={`Aplicar MiniMAPA`}
              />
              <div className="divisor"></div>
            </Fragment>
          )}

          {props.data?.map((d, index) => (
            <Pill className="pill" key={index}>
              {d}
            </Pill>
          ))}
        </Header>
      )}

      {props.checkBoxToPrint && (
        <Footer>
          {props.checkBoxToPrint.gradeInfo ? (
            <>
              {props.checkBoxToPrint?.gradeInfo?.is_meta_block_validated ? (
                <ValidatedContainer valid={props.checkBoxToPrint?.gradeInfo?.is_meta_block_validated} color="#2E3A59">
                  <span />
                  <p style={{ color: '#FFFF' }}>Definido</p>
                </ValidatedContainer>
              ) : (
                <>
                  <DotStatus />
                  <Label color="#FFFF">ATENÇÃO: Definir primeiro a Meta de Aprendizagem</Label>
                </>
              )}
            </>
          ) : (
            <div className="pill">
              <Pill>Este aluno não possui nota!</Pill>
            </div>
          )}
        </Footer>
      )}
      {props.showLastApplication && (
        <StudentCardLauncherFooterContainer>
          <When>
            MiniMapa:
            <Pill backgroundColor={getStatus(props.lastApplication?.status).color} color="#ffff" size="small">
              {getStatus(props.lastApplication?.status).status}
            </Pill>
            <p>{applicationDate(props.lastApplication)}</p>
          </When>
          {(props.lastApplication?.status === 'PENDING' || props.lastApplication?.status === 'ONGOING') && (
            <Button
              start={<Icon name="close" size={'medium'} />}
              fill="#FF3D71"
              onClick={() => handleIdApplication(`${props.lastApplication?.id}`)}
              size={'tiny'}
              rounded
              status={'danger'}
              className="button-cancel-activity"
            >
              Cancelar
            </Button>
          )}
        </StudentCardLauncherFooterContainer>
      )}
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCancelApplicationModal}
        closeCallback={() => setShowCancelApplicationModal(false)}
        title="Deseja Cancelar Aplicação?"
        subTitle="ATENÇÃO: Ao cancelar aplicação atual, o estado de “feito” da aplicação anterior volta a aparecer."
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        status="quaternary"
        confirmCallBack={() => handleCancelApplicationModal(aplicationIdCancel)}
      />
    </StudentCardLauncherBorderContainer>
  );
};

export default StudentCardLauncherBorder;
