import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 2rem;
  padding: 2rem 1rem 1rem 2rem;
  background-color: #edf1f7;
`;

export const Box = styled.div<{ value?: string; borderColor?: string; bgColor?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;

  &::before {
    content: '';

    margin: 4px;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    border: 2px solid ${({ bgColor, borderColor }) => borderColor || bgColor};
    background: ${({ bgColor }) => bgColor};
  }

  &::after {
    content: '${({ value }) => value}';
    font-size: 12px;
    font-weight: 700;
    color: #222b45;
  }
`;

export const BoxIcon = styled.div<{ value?: string; borderColor?: string; bgColor?: string }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 5px;

  &::after {
    content: '${({ value }) => value}';
    font-size: 12px;
    font-weight: 700;
    color: #222b45;
  }

  i {
    display: flex;
    align-items: center;
    svg {
      stroke-width: 0.5px;
      stroke: #f7652b;
      width: 40px;
      height: 40px;
    }
  }
`;
