import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Container } from './styles';

interface ICustomTabsProps {
  footer?: boolean;
  dynamicHeaderHeight?: number;
  dynamicFooterHeight?: number;
  handleScrollChange?: (data: ICustomTabsScrollContainerChange) => void;
}

export interface ICustomTabsScrollContainerChange {
  isScrolled: boolean;
  event: React.UIEventHandler<HTMLDivElement>;
}

export const RETRACT_SIZE = 75;

const CustomTabs: React.FC<ICustomTabsProps> = ({ children, footer, handleScrollChange, dynamicHeaderHeight = 120, dynamicFooterHeight = 89 }) => {
  const [scrolled, setScrolled] = useState<boolean>(false);

  const customTabContainerRef = useRef<HTMLDivElement>(null);

  const RESPONSIVE_BREAK_POINT = 992;
  const HEADER_HEIGHT_AT_DESKTOP = 212;

  const handleScrollOnChange = useCallback(
    (event) => {
      if (handleScrollChange) {
        const distanceScrollTop = event.target.scrollTop;
        const shouldScroll = distanceScrollTop >= 150 ? true : distanceScrollTop === 0 ? false : undefined;

        if (shouldScroll !== undefined) {
          handleScrollChange({ isScrolled: shouldScroll, event });
          setScrolled(shouldScroll);
        }
      }
    },
    [handleScrollChange]
  );

  const isAtDesktopWidth = 
    customTabContainerRef.current && customTabContainerRef.current.clientWidth > RESPONSIVE_BREAK_POINT;

  const calculateTabPanelHeight = useMemo(() => {
    const valueToRetract = isAtDesktopWidth ? 0 : RETRACT_SIZE;
    
    if (footer && scrolled) return dynamicHeaderHeight + dynamicFooterHeight - valueToRetract;
    if (scrolled) return isAtDesktopWidth ? HEADER_HEIGHT_AT_DESKTOP - valueToRetract : dynamicHeaderHeight - valueToRetract;
    if (footer) return dynamicHeaderHeight + dynamicFooterHeight;
    return isAtDesktopWidth ? HEADER_HEIGHT_AT_DESKTOP : dynamicHeaderHeight;
  }, [dynamicFooterHeight, dynamicHeaderHeight, footer, scrolled, isAtDesktopWidth]);

  return (
    <Container ref={customTabContainerRef} key={dynamicHeaderHeight} calculateTabPanelHeight={calculateTabPanelHeight} className="custom-tabs" onScroll={handleScrollOnChange}>
      {children}
    </Container>
  );
};

export default CustomTabs;
