import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import { SkillsList } from '../../components/SkillsList';
import { ISkill } from '@shared/interfaces/ISkill';

export const ActivityLibrarySkillsRelation: React.FC = () => {
  const navigate = useNavigate();
  const { activityLibraryCurrent, setActivityLibraryCurrent } = useActivity();

  const handleSkillsSelected = useCallback(
    (skills: ISkill[]) => {
      setActivityLibraryCurrent((oldState: IActivityLibrary) => {
        return { ...oldState, skills: skills };
      });
    },
    [setActivityLibraryCurrent]
  );

  return (
    <SkillsList
      handleSkillsSelected={(skills) => handleSkillsSelected(skills)}
      selectedSkills={activityLibraryCurrent.skills}
      buttonText="Salvar Competencias"
      buttonCallback={() => navigate(-1)}
    />
  );
};
