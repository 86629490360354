import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { IUser } from '@modules/users/interfaces/IUser';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import GetUserService from '@modules/users/services/GetUserService';

export type IUserContextData = {
  userCurrent?: IUser;
  setUserCurrent: (value: any) => void;
  getUser: (userID: string) => Promise<boolean>;
  userRef: React.MutableRefObject<IUserRef>;
};

const UserContext = createContext<IUserContextData>({} as IUserContextData);
export const initalDataUserCreate = { academic_info: { name: '', course_name: '', end_date: '' }, address_data: { address: '', city: '', uf: '' } };
export interface IUserRef {
  name?: string;
  contact_email?: string;
  perfil?: any;
  email?: string;
  phone?: string;
  address_data: {
    address: string;
    city: string;
    uf: string;
  };
  birthdate?: Date;
  gender?: string;
  education_level?: string;
  university_type?: string;
  academic_info: {
    name: string;
    course_name: string;
    end_date: string;
  };
  activation_date?: Date;
  status?: string;
  category?: { name: string; id: string };

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}
const UserProvider: React.FC = ({ children }) => {
  const [userCurrent, setUserCurrent] = useState<IUser>();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const userRef = useRef<IUserRef>(initalDataUserCreate);

  const getUser = useCallback(
    async (userID: string) => {
      setUserCurrent(undefined);
      if (!signedUser) return false;
      setIsLoading(true);
      const res = await new GetUserService(signedUser.token).execute(userID);
      setIsLoading(false);
      if (!res) return false;
      setUserCurrent(res);
      return true;
    },
    [setIsLoading, signedUser]
  );

  return <UserContext.Provider value={{ userCurrent, setUserCurrent, getUser, userRef }}>{children}</UserContext.Provider>;
};

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

export { UserProvider, useUser };
