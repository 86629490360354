import React, { useMemo } from 'react';
import { IconBaseProps } from 'react-icons';

import { CheckboxForm, formatDate, ICheckboxProps, P } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';
import { ITask } from '@modules/tasks/interfaces/ITask';

import { Container, Header, DateRender, Footer } from './styles';
import { ITaskMessageDateKey } from '../TasksListManager';

type ITaskCardProps = ICustomCardProps & {
  id?: string | number;
  hideIconUncheck?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  checkbox?: ICheckboxProps;
  canConclude?: boolean;
  task: ITask;
  dateBgColor?: string;
  dateKey: ITaskMessageDateKey;
  prefixDate: string;
};

const TaskCard: React.FC<ITaskCardProps> = ({
  id,
  task,
  icon,
  hideIconUncheck,
  checkbox,
  status = 'basic',
  canConclude = false,
  prefixDate = '',
  dateBgColor,
  dateKey = 'end_date',
  ...rest
}) => {
  const header = useMemo(() => {
    return (
      <Header status={status} dateBgColor={dateBgColor}>
        <DateRender>
          {prefixDate}
          <br />
          {formatDate(new Date(`${dateKey === 'updated_at' ? task[dateKey] : task.event?.[dateKey]}`), 'numeric')}
        </DateRender>
        <div>{task?.event?.name}</div>
      </Header>
    );
  }, [dateBgColor, dateKey, prefixDate, status, task]);

  const footer = useMemo(() => {
    if (!task.concluder) return;
    return (
      <Footer>
        Concluido por: <b>{task.concluder.name}</b>
      </Footer>
    );
  }, [task]);

  return (
    <Container status={status} canConclude={canConclude}>
      <CustomCard status={status} statusFooter={status} footer={footer} header={header} {...rest}>
        <P fontSize={14} textAlign="center">
          {task?.event?.description}
        </P>
      </CustomCard>
      {canConclude && <CheckboxForm {...checkbox} id={id} icon={icon} hideIconUncheck={hideIconUncheck} />}
    </Container>
  );
};

export default TaskCard;
