import React, { useCallback } from 'react';
import { Input, Spinner, Textarea } from 'react-alicerce-components';
import CustomForm from '@shared/components/CustomForm';
import InputPill from '@shared/components/InputPill';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';
import { useWorkGroup } from '@modules/work-group/context/WorkGroupContext';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import StudentCard from '@modules/students/components/StudentCard';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { WorkGroupFormContainer } from './styles';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';

interface IWorkGroupForm {
  pathEnvolvedBlocks: string;
  pathEnvolvedStudents: string;
  errors: IInputErrorsFormat[];
}

const WorkGroupForm: React.FC<IWorkGroupForm> = ({ pathEnvolvedBlocks, ...props }) => {
  const { workGroupCurrent, setWorkGroupCurrent } = useWorkGroup();
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  const handleInputChange = useCallback(
    (inputValue: string, inputName: string) => {
      setWorkGroupCurrent((oldState: IWorkGroup) => {
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setWorkGroupCurrent]
  );

  const handleRemoveBlock = useCallback(
    (index: number) => {
      setWorkGroupCurrent((oldState: IWorkGroup) => {
        const remainingBlocks = removeItemFromArrayByIndex(index, oldState?.blocks);
        return { ...oldState, blocks: remainingBlocks };
      });
    },
    [setWorkGroupCurrent]
  );

  const removeStudentCard = useCallback(
    (index: number) => {
      const remainingStudents = removeItemFromArrayByIndex(index, workGroupCurrent?.students);
      setWorkGroupCurrent((oldState: IWorkGroup) => {
        return { ...oldState, students: remainingStudents };
      });
    },
    [setWorkGroupCurrent, workGroupCurrent]
  );

  console.log(props.errors)
  return (
    <WorkGroupFormContainer>
      <CustomForm>
        {isLoading && <Spinner fixed />}
        <Input
          onChange={(event) => handleInputChange(event.target.value, 'name')}
          defaultValue={workGroupCurrent ? workGroupCurrent.name : undefined}
          label="Nome do Grupo:"
          name="name"
          required
          placeholder="Adicione Nome do Grupo"
          useTooltipError={false}
          error={errorMessageBuilder('name', props.errors)}
        />

        <InputPill
          pathToRedirect={pathEnvolvedBlocks}
          label="Blocos"
          inputPillData={workGroupCurrent?.blocks?.map((block) => block.name)}
          color="#C8FBF2"
          removeCallback={(index: number) => handleRemoveBlock(index)}
          placeholder="Adicionar Blocos ao Grupo de Trabalho"
        />

        <Textarea
          name="description"
          label="Descrição do Grupo:"
          placeholder="Adicionar Descrição do Grupo de Trabalho"
          value={workGroupCurrent ? workGroupCurrent.description : undefined}
          rows={4}
          required
          onChange={(event) => handleInputChange(event.target.value, 'description')}
          useTooltipError={false}
          error={errorMessageBuilder('description', props.errors)}
        />

        <AccordionInfoItem
          colorHeader="#DAE3F6"
          title={`Membros (${workGroupCurrent && workGroupCurrent.students ? workGroupCurrent.students.length : 0}) `}
        >
          <ButtonAddCard text="Adicionar Estudantes" handleOnClick={() => navigate(props.pathEnvolvedStudents)} />
          <div className="cards-container">
            {workGroupCurrent &&
              workGroupCurrent.students?.map((student, index) => (
                <StudentCard key={student.id} student={student} canDeleteCard={{ indexId: index, removeCallback: () => removeStudentCard(index) }} />
              ))}
          </div>
        </AccordionInfoItem>
        {errorMessageBuilder('student_ids', props.errors) && <InputErrorMessage error={errorMessageBuilder('student_ids', props.errors) || ''} />}
      </CustomForm>
    </WorkGroupFormContainer>
  );
};

export default WorkGroupForm;
