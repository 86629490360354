import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import IListOptions from '@shared/interfaces/IListOptions';
import { IActivityFilterFormatted } from '../context/ActivityContext';

export interface IActivityLibrarysReponse extends IPaginatedResponse {
  items: IActivityLibrary[];
}

interface IListActivityLibrariesOptions extends IListOptions, IActivityFilterFormatted {
  favorited?: boolean;
}

export class ListActivityLibrariesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListActivityLibrariesOptions): Promise<IActivityLibrarysReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);

      const res = await this.api.get(`/activity?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}
