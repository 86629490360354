import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IOccurrence } from '../interfaces/IOccurrence';

class SolveOccurrenceService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(occurrenceId: string | number): Promise<IOccurrence | false> {
    try {
      const res = await this.api.patch(`/occurrence/${occurrenceId}/solve`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default SolveOccurrenceService;
