import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import { convertStringTimeHourToMinutes } from '@shared/helpers/DayHourHelper';
import { DateHelper } from '../../../shared/helpers/DateHelper';

export interface IFormattedWorkdHour {
  category_tag_id?: number;
  modality_tag_id?: number;
  project_tag_id?: number;
  group_id?: number;
  class_id?: number;
  issued_by: number;
  description?: string;
  date_minutes?: {
    date: Date;
    total_minutes: number;
  }[];
}

export const formatWorkedHour = (workedHour: IWorkedHour, lmsUserId: string | number): IFormattedWorkdHour => {
  return {
    category_tag_id: workedHour.category ? Number(workedHour.category.id) : undefined,
    modality_tag_id: workedHour.modality ? Number(workedHour.modality.id) : undefined,
    project_tag_id: workedHour.project ? Number(workedHour.project.id) : undefined,
    group_id: workedHour.group ? Number(workedHour.group.id) : undefined,
    class_id: workedHour.class ? Number(workedHour.class.id) : undefined,
    issued_by: Number(lmsUserId),
    description: workedHour.description ? workedHour.description : undefined,
    date_minutes: workedHour.date_hour?.map((dh) => {
      const date = DateHelper.getUSDate(dh.date);
      return {
        date: DateHelper.getUTCDate(new Date(date)),
        total_minutes: convertStringTimeHourToMinutes(dh.total_hours),
      };
    }),
  };
};
