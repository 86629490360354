import React from 'react';
import { HeadlightsProvider, IHeadlightsProviderProps } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { SprintHeadlightsContainer } from './partials/SprintHeadlightsContainer';
import { CategoryDescription } from './partials/CategoryDescription';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { HeadlightType } from '@modules/projects/schemas/Headlight/partials/HeadlightType';

type HeadlightsProps = {
  sprint?: ISprint;
  sprintHeadlights?: IHeadlight[]
  headlightType?: HeadlightType;
} & IHeadlightsProviderProps;

const Headlights: React.FC<HeadlightsProps> = ({
  isOpen = true,
  sprint,
  sprintHeadlights,
  headlightType,
  ...rest
}) => {
  return (
    <HeadlightsProvider {...rest}>
      <SprintHeadlightsContainer  sprint={sprint} sprintHeadlights={sprintHeadlights} headlightType={headlightType}/>
      <CategoryDescription sprintHeadlights={sprintHeadlights}/>
    </HeadlightsProvider>
  );
};

export { Headlights };
