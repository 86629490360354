import React, { ComponentPropsWithoutRef, forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useState } from "react";
import HeadlightSelectCard from "@modules/projects/components/HeadlightSelectCard";
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import ListHeadlightCategoriesService, { IHeadlightCategories } from "@modules/projects/services/Headlight/ListHeadlightCategoriesService";
import { useHeadlights } from "@modules/projects/components/Headlights/context/HeadlightsContext";
import { useParams } from "react-router-dom";
import { Container } from "./styles";

export type HeadlightViewHandle = ComponentPropsWithoutRef<'div'> & {
    getheadlightCategories: () => IHeadlightCategories[];
}

export type HeadlightViewProps = {
    readOnly?: boolean
}

const BaseHeadlightView: ForwardRefRenderFunction<HeadlightViewHandle, HeadlightViewProps> = ({ 
    children,
    readOnly = false 
}, forwardRef) => {
    const { currentHeadlight } = useHeadlights();
    const { setIsLoading, isLoading } = useGlobal();
    const { signedUser } = useAuth();
    const [headlightCategories, setHeadlightCategories] = useState<IHeadlightCategories[]>();
    const { id: headlightId } = useParams<{ id: string }>()

    useImperativeHandle(forwardRef, () => ({
        getheadlightCategories: () => headlightCategories || []
    }))

    const listHeadlightCategories = useCallback(async (currentHeadlight) => {
        if (!signedUser) return;

        setIsLoading(true);

        const headlightCategories = await new ListHeadlightCategoriesService(signedUser.token).execute({ type: currentHeadlight?.type });
        if (!headlightCategories) return;
        setHeadlightCategories(headlightCategories);
        setIsLoading(false);
    }, [setIsLoading, signedUser, setHeadlightCategories]);

    const headlightType = currentHeadlight?.type;
    useEffect(() => {
        if (currentHeadlight?.id === headlightId && headlightType) {
            listHeadlightCategories(currentHeadlight)
        }
        // eslint-disable-next-line
    }, [headlightId, listHeadlightCategories, headlightType]);

    const statusMap = (currentHeadlight?.data ?? []).reduce((acc, item) => {
        acc[item.category] = item.status;
        return acc;
    }, {});

    return (
        <Container>
            {!isLoading &&
                (headlightCategories
                    &&
                    headlightCategories.map((_h, i) => <HeadlightSelectCard key={i} category={_h.category} categoryDescription={_h.description} checkedStatus={statusMap[_h.category]} status={_h.status} readOnly={readOnly} />)
                )
            }
        </Container>
    )

}

export const HeadlightView = forwardRef(BaseHeadlightView)

