import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  overflow-x: hidden;

  .main-navbar {
    height: 130px;
  }

  .pagination-content-icon {
    background-color: #8f9bb3 !important;

    i svg {
      fill: #fff !important;
    }
  }
`;
