import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';
import { RETRACT_SIZE } from '../../../CustomTabs';

import { IHeaderMainProps } from './index';

interface ISidebarContainerProps {
  isOpened?: boolean;
  compressOnScroll?: boolean;
  isScrolled?: boolean;
}

interface IHeaderMainUserIconProps {
  userIcon?: string;
}

export const HeaderMainUserIcon = styled.div<IHeaderMainUserIconProps>`
  background: url(${({ userIcon }) => userIcon}) no-repeat;
  width: 100px;
  height: 100%;
  background-size: 60px;
  background-position-y: 50%;
  background-position-x: 100%;
  z-index: 1;
`;

export const HeaderMainUserInfo = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;

  h6 {
    text-transform: uppercase;
    font-weight: 800;
    color: ${themeGet('colors.quaternary900')};
    font-size: 1.6rem;
  }

  p {
    font-weight: normal;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  p:last-child {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    font-family: var(--secondary-font);
  }
`;

export const Container = styled.div<IHeaderMainProps>`
  position: relative;
  display: flex;
  min-height: 200px;
  width: 100vw;
  background: url(${({ bgHeader }) => bgHeader}) no-repeat;
  background-size: 100%;
  background-position-y: -160px;
  /* transition: ease all 0.2s; */

  .menu-kebab {
    position: absolute;
    top: 15px;
    right: calc(100vw - 140px);
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ bgColor }) => (bgColor ? bgColor : 'linear-gradient(180deg, #6E3FA5 0%, #2484C7 98.12%)')};
    opacity: 0.8;
  }

  .main-navbar {
    background-color: transparent;
    position: absolute;
  }

  .main-navbar i svg {
    fill: #000;
  }

  ${(props) =>
    props.compressOnScroll &&
    props.isScrolled &&
    `
        min-height: calc(200px - ${RETRACT_SIZE}px);

        ${HeaderMainUserInfo},
        ${HeaderMainUserIcon} {
            display: none;
        }
    `}
`;

export const SidebarContainer = styled.div<ISidebarContainerProps>`
  ${(props) =>
    props.isOpened &&
    `
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 5; 

        &::after{
            content: '';
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            opacity: .4;
        }
    `}
`;

export const HeaderMainDefaultContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.2rem;
  position: relative;

  .return-icon-left {
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 1.5rem 0rem;

    i {
      display: flex;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
