import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";

import ITag from "@modules/tags/interfaces/ITag";

export type ITagsListContextData = {
  tagsSelected: ITag[];
  canSelect: boolean;
  canQuantitySelect: number | 'many';
  changeTagsSelected: (tags: ITag[]) => void;
};

const TagsListContext = createContext<ITagsListContextData>({} as ITagsListContextData);

interface ITagsListProvider {
  quantitySelect: number | 'many';
}
const TagsListProvider: React.FC<ITagsListProvider> = ({ quantitySelect, children }) => {

  const [tagsSelected, setTagsSelected] = useState<ITag[]>([]);
  const [canQuantitySelect] = useState<number | 'many'>(quantitySelect);

  const canSelect = useMemo(() => (
    canQuantitySelect === 'many' || 
    (canQuantitySelect > 0 && tagsSelected.length < canQuantitySelect) ? true : false 
  ), [canQuantitySelect, tagsSelected]);

  const changeTagsSelected = useCallback((tags: ITag[]) => {
    setTagsSelected(tags);
  }, [setTagsSelected]);

  return (
    <TagsListContext.Provider
      value={{
        canSelect,
        tagsSelected,
        canQuantitySelect,
        changeTagsSelected,
      }}
    >
      {children}
    </TagsListContext.Provider>
  );
};

function useTagsList() {
  const context = useContext(TagsListContext);

  if (!context) {
    throw new Error("useTagsList must be used within a TagsListProvider");
  }

  return context;
}

export { TagsListProvider, useTagsList };
