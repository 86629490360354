import React, { useCallback } from 'react';
import { Button, useToast } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import ScheduleGrid from '../ScheduleGrid';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';

import { Container, ButtonsContainer } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdvancedFilterController } from '@modules/activities-library/context/AdvancedFilterControllerContext';
import UpdatePlannedActivitiesService from '@modules/class-planner/services/UpdatePlannedActivitiesService';
import { PlannedActivityHelper } from '@modules/class-planner/helpers/PlannedActivityHelper';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { isDailyClassEditDisable } from '../../utils/isDailyClassEditDisable';

const ClassPlannerCalendar: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();

  const { classId, dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();
  const { resetActivityFilter } = useActivity();
  const { setIsAdvancedFilterOpen, setOpenTab } = useAdvancedFilterController();
  const { canSavePlannedClass, plannedActivities, setProblems, setCanSavePlannedClass, dailyClass } = useScheduleGrid();

  const handleAddNewActivity = useCallback(async () => {
    if (!dailyClassId) return;
    if (canSavePlannedClass && (plannedActivities?.in_calendar.length || plannedActivities?.in_tray.length)) {
      const formattedPlannedActivityBody = PlannedActivityHelper.formatUpdateDisket(plannedActivities?.in_calendar);
      const body = { daily_class_id: dailyClassId, planned_activities: formattedPlannedActivityBody };
      setIsLoading(true);
      const res = await new UpdatePlannedActivitiesService(signedUser?.token).execute(body);
      setIsLoading(false);
      if (!res)
        return addToast({
          status: 'danger',
          title: 'Planejador de Aula',
          description: 'Error ao salvar planejamento, tente novamente.',
        });

      setProblems(res.pending_problems);
      setCanSavePlannedClass(false);
    }

    resetActivityFilter();
    setOpenTab('activity');
    setIsAdvancedFilterOpen(false);
    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`);
  }, [
    addToast,
    canSavePlannedClass,
    classId,
    dailyClassId,
    navigate,
    plannedActivities,
    resetActivityFilter,
    setCanSavePlannedClass,
    setIsAdvancedFilterOpen,
    setIsLoading,
    setOpenTab,
    setProblems,
    signedUser,
  ]);

  const isButtonDisable = isDailyClassEditDisable({ dailyClass });

  return (
    <Container>
      <ButtonsContainer>
        <Button transform="none" fill="#85ABF8" shaded onClick={() => navigate(`/planejamento-aula/turma/${classId}/editar/aula/${dailyClassId}`)}>
          Editar Aula
        </Button>
        <Button transform="none" fill="#85ABF8" shaded onClick={handleAddNewActivity} disabled={isButtonDisable}>
          {canSavePlannedClass && (plannedActivities?.in_calendar.length || plannedActivities?.in_tray.length)
            ? 'Salvar e Escolher Atividade'
            : 'Escolher Atividade'}
        </Button>
      </ButtonsContainer>
      {plannedActivities && <ScheduleGrid />}
    </Container>
  );
};

export default ClassPlannerCalendar;
