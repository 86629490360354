import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Textarea } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import Icon from 'react-eva-icons';

import { ContainerData, ButtonModal, ModalContainer } from './styles';

interface IConfirmModal {
  showModal: boolean;
  showClose?: boolean;
  closeCallback: () => void;
  title: string;
  subTitle?: string;
  start?: JSX.Element;
  textConfirmButton: string;
  textCloseButton?: string;
  confirmCallBack: () => any;
  showButtonReturn?: boolean;
  status?: ElementStatus;
  justification?: (value: any) => void;
  errors?: IInputErrorsFormat[];
}

const ConfirmModalJustification: React.FC<IConfirmModal> = ({
  showModal,
  showClose = false,
  closeCallback,
  confirmCallBack,
  title,
  subTitle,
  start,
  textConfirmButton,
  textCloseButton = 'Voltar',
  showButtonReturn = true,
  status = 'basic',
  justification,
  errors,
  ...rest
}) => {
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true);
  const classnameWithoutStart = useMemo(() => {
    return start ? '' : 'margin-wihout-start';
  }, [start]);

  const handleInputChange = useCallback(
    (value: string | null) => {
      const valueTrimmed = value?.trim()
      if (valueTrimmed?.length === 0) {
        setDisableConfirm(true);
      } else {
        setDisableConfirm(false);
      }
      justification && justification(value);
    },
    [justification]
  );


  return (
    <ModalContainer showModal={showModal}>
      <ContainerData className="container-confirm-modal">
        {showClose && (
          <div className="show-close-button-confirm-modal">
            <div onClick={closeCallback}>
              <Icon name="close" size="xlarge" fill="#2E3A59" />
            </div>
          </div>
        )}
        {start && <div className="icon-start-container">{start}</div>}
        <div className={`title-confirm-modal ${classnameWithoutStart}`}>{title}</div>
        {subTitle && (
          <Textarea
            label={subTitle}
            name="invalidation_reason"
            useTooltipError={false}
            rows={6}
            placeholder={'Adicione Justificativa da Reprovação'}
            onChange={(event) => handleInputChange(event.target.value)}
            error={errors ? errorMessageBuilder('invalidation_reason', errors) : ''}
          />
        )}

        {rest.children}
        <div className="badge-modal-line" />
        <ButtonModal className="buttons-confirm-modal" twoButtons={showButtonReturn}>
          {showButtonReturn && (
            <Button className="button-cancel-confirm-modal" onClick={closeCallback} shaded status={status} outline>
              {textCloseButton}
            </Button>
          )}
          <Button className="button-accept-confirm-modal" onClick={confirmCallBack} shaded status={status} disabled={disableConfirm}>
            {textConfirmButton}
          </Button>
        </ButtonModal>
      </ContainerData>
    </ModalContainer>
  );
};

export default ConfirmModalJustification;
