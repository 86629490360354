import React from 'react';

import { Tab, Tabs, useTabs } from 'react-alicerce-components';
import { useParams } from 'react-router';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import CustomTabs from '@shared/components/CustomTabs';

import { Container } from './styles';
import { ActivityProgramList } from '@modules/activities-library/views/ActivityProgramList';
const ClassPlannerActivityLibraryContextSelection: React.FC = () => {
  const { classId, dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();
  const { handleSetTabCurrent, tabCurrent } = useTabs();

  return (
    <Container>
      <HeaderDirectory
        pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`}
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <CustomTabs footer>
        <Tabs openTab={tabCurrent} handleTabChange={(tab) => handleSetTabCurrent(tab.index)} horizontalScroll>
          <Tab title="Início">
            <ActivityProgramList
              pathToClickButtonActivity={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/atividade`}
              pathClickCardActivity={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/:activityId/informacoes`}
              activityTextButton={'Criar Atividade'}
              pathToClickButtonProgram={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/programa`}
              pathClickCardProgram={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/programa/:programId/informacoes`}
              programTextButton={'Criar Programa '}
              filterByWorkgroup={true}
            />
          </Tab>
          <Tab title="Favoritas">
            <ActivityProgramList
              listFavorites={true}
              pathToClickButtonActivity={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/atividade`}
              pathClickCardActivity={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/:activityId/informacoes`}
              activityTextButton={'Criar Atividade'}
              pathToClickButtonProgram={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/programa`}
              pathClickCardProgram={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/programa/:programId/informacoes`}
              programTextButton={'Criar Programa'}
              filterByWorkgroup={true}
            />
          </Tab>
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export { ClassPlannerActivityLibraryContextSelection };
