import React, { createContext, useCallback, useContext, useState } from 'react';
import IClass from '../interfaces/IClass';

export type IClassContextData = {
  classCurrent: IClass & IUpdateTeachersStudentsForm;
  setClassCurrent: (value: any) => void;
  clearClassContextData: () => void;
  pathToReturn: string;
  setPathToReturn: (value: string) => void;
};

export type IUpdateTeachersStudentsForm = {
  update_teachers?: {
    unenroll: {
      type: 'unenrollment';
      id: number;
      reason: string;
      detail: string;
      observation: string;
    }[];
    enroll: number[];
  };
  update_students?: {
    unenroll: {
      type: 'unenrollment' | 'disconnect';
      id: number;
      reason: string;
      detail: string;
      observation: string;
    }[];
    enroll: number[];
  };
};

const initialState = { name: '', age_groups: [], calendar_data: [], customization: undefined };

const ClassContext = createContext<IClassContextData>({} as IClassContextData);

const ClassProvider: React.FC = ({ children }) => {
  const [classCurrent, setClassCurrent] = useState<IClass & IUpdateTeachersStudentsForm>(initialState);
  const [pathToReturn, setPathToReturn] = useState<string>('')

  const clearClassContextData = useCallback(() => {
    setClassCurrent(initialState);
  }, []);

  return (
    <ClassContext.Provider
      value={{  classCurrent, setClassCurrent, clearClassContextData, pathToReturn, setPathToReturn }}
    >
      {children}
    </ClassContext.Provider>
  );
};

function useClass() {
  const context = useContext(ClassContext);

  if (!context) {
    throw new Error('useClass must be used within a ClassProvider');
  }

  return context;
}

export { ClassProvider, useClass };
