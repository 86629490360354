import React, { useMemo, useState } from 'react';
import Icon from 'react-eva-icons';

import moment from 'moment';
import 'moment/locale/pt-br';

import { IMediaProps, Media as MediaLib, TextEllipsis } from 'react-alicerce-components';

import IMedia from '@shared/interfaces/IMedia';
import TagsRender from '@modules/tags/components/TagsRender';

import {
    Container,
    MediaContainer,
    MediaLength,
    RealTime,
    Info,
    Name,
    Date as DateTxt,
    TagsRenderContainer
} from './styles';

type ICustomMediaProps = IMediaProps & {
    showRealTimeAlert?: boolean;
    media: IMedia;
}
const Media: React.FC<ICustomMediaProps> = ({
    media,
    showRealTimeAlert = false,
    ...rest
}) => {

    const [isShowInfo, setIsShowInfo] = useState<boolean>(false);

    const formatDate = useMemo(() => {
        const newDate = media.date?.split('/') as string[];
        return moment(`${newDate[2]}-${newDate[1]}-${newDate[0]}`).format('DD [de] MMMM [de] YYYY');
    }, [media]);

    return (
        <Container className='custom-media-container'>
            <MediaContainer>
                <MediaLib {...rest} media={media} />
                <MediaLength>{media.time}</MediaLength>
            </MediaContainer>
            {isShowInfo &&
                <Info>
                    <Name>
                        <TextEllipsis
                            lineClamp={1} lineHeight={1}
                            boxOrient='horizontal'
                        >
                            {media.name}
                        </TextEllipsis>
                    </Name>
                    <DateTxt>{formatDate}</DateTxt>
                    <TagsRenderContainer>
                        <TagsRender
                            className='tags-render-list'
                            tags={media.tags}
                        />
                    </TagsRenderContainer>
                </Info>
            }
            {showRealTimeAlert &&
                <RealTime
                    isShowIcon={isShowInfo}
                    onClick={() => setIsShowInfo(!isShowInfo)}
                >
                    <Icon name='chevron-left-outline' fill='#2E3A59' />
                    <div /> <span>Tempo Real</span>
                </RealTime>
            }
        </Container >
    );
}

export default Media;