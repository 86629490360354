import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import HeaderMain from '@shared/components/Headers/HeaderMain';
import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import { Container } from './styles';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  const tooltipView = useMemo(() => {
    const pathName = window.location.pathname;
    if (pathName === '/') return { view: 'Home' };
    if (pathName === '/minhas-turmas') return { view: 'MyClasses' };
    if (pathName === '/meus-grupos') return { view: 'MyGroups' };
    if (pathName === '/meus-locais') return { view: 'MyPlaces' };
    return undefined;
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}

      <HeaderMain useDefaultNav isScrolled={isScrolled} compressOnScroll menuKebab={{ tooltip: tooltipView }} />
      <CustomTabs footer={footer} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Início" onClick={() => navigate('/')} />
          <Tab title="Minhas Turmas" onClick={() => navigate('/minhas-turmas')} />
          <Tab title="Meus Grupos" onClick={() => navigate('/meus-grupos')} />
          <Tab title="Meus Locais" onClick={() => navigate('/meus-locais')} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default Layout;
