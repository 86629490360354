import * as Yup from 'yup';

export type DisconnectUnenrollmentForm = {
  reason: string | undefined,
  detail?: string | undefined,
  observation?: string | undefined
}

export const DisconnectSchema = Yup.object().shape({
  reason: Yup.string().required({ name: 'reason', message: 'O campo é obrigatório' }),
  detail: Yup.string().required({ name: 'detail', message: 'O campo é obrigatório' }).nullable(),
  observation: Yup.string().nullable(),
});

export const UnenrollmentSchema = Yup.object().shape({
  reason: Yup.string().required({ name: 'reason', message: 'O campo é obrigatório' }),
  observation: Yup.string().nullable(),
});