import React, { Fragment, useEffect, useRef, useState } from 'react';
import Icon from 'react-eva-icons';
import { Container } from './styles';

interface INavigationBox {
  title: string;
  handleOnClick: (isOpen: boolean) => Promise<boolean>;
  index?: number;
  currentBox?: number;
  handleSetCurrent?: (index: number | undefined) => void;
}

const NavigationBox: React.FC<INavigationBox> = ({ title, handleOnClick, index, currentBox, handleSetCurrent, children }) => {
  const [text, setText] = useState<string>(title);
  const [opened, setOpened] = useState<boolean>(false);
  const [shouldHide, setShouldHide] = useState<boolean>(false);

  const nodeRef = useRef<HTMLDivElement>(null);

  const toggleTransition = async () => {
    const res = await handleOnClick(opened);
    if (res) {
      setText(text === title ? 'INÍCIO' : title);
      setOpened(text === title ? true : false);
      handleSetCurrent && handleSetCurrent(Number(index));
      if (text === 'INÍCIO' && handleSetCurrent) handleSetCurrent(undefined);
    }
  };

  useEffect(() => {
    if ((currentBox || currentBox === 0) && currentBox !== index) {
      return setShouldHide(true);
    }
    setShouldHide(false);
  }, [currentBox, index, title]);

  if (shouldHide) return <Fragment />;

  return (
    <Fragment>
      <Container opened={opened} onClick={toggleTransition}>
        <div className="text-name" ref={nodeRef}>
          {text}
        </div>

        <div className={`styled-icon ${opened ? 'rotate' : ''}`}>
          <Icon name="chevron-right-outline" fill="#2E3A59" />
        </div>
      </Container>
      {opened && children}
    </Fragment>
  );
};

export default NavigationBox;
