import React from 'react';
import { Container } from './styles';

export type ICheckboxProps = {
  label?: string;
  handleOnChange?: any;
  children?: any;
  width?: string;
} & React.InputHTMLAttributes<HTMLInputElement>

const CheckboxCustom: React.FC<ICheckboxProps> = ({ label, handleOnChange, children, width, ...rest }) => {

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChange && handleOnChange(event);
  };

  return (
    <Container width={width}>
      <label className="checkbox">
        <input {...rest} type="checkbox" onChange={handleCheckboxChange} />
        <div className="checkbox__checkmark"></div>
      </label>
      <div className='wrapper'>
        <label>{label}</label>

        <div className='body'>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default CheckboxCustom;
