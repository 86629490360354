import IClass from '@modules/classes/interfaces/IClass';
import { differenceInYears, parseISO } from 'date-fns';
import { IStudent } from '../../students/interfaces/IStudent';

export const formateMapaBody = (student: IStudent, trailId, zpd: string | null | undefined, classEntity: IClass, created_by: number) => {
  //Pega duas semanas a partir de hoje.
  const twoWeeks = new Date(Date.now() + 6.048e8 * 2);
  const applicationType = {
    9: 'MAPA',
    2: 'MAPA',
    3: 'MAPA',
    4: 'DISSERTATIVE',
    5: 'OBSERVATION',
  };

  return {
    lms_student_id: Number(student.id),
    trail_id: Number(trailId),
    state: {
      created_by: created_by,
      ...(Number(trailId) === 4 && {
        dissertativeData: {
          birthDate: `${student.birthdate}`,
          trailId: Number(trailId),
          ...(!!zpd && { zdp: zpd }),
        },
      }),
      ...(Number(trailId) !== 4 &&
        Number(trailId) !== 5 && {
        mapaData: {
          birthDate: `${student.birthdate}`,
          isLiterate: student.literate_status || 'not_collected',
          trailId: Number(trailId),
          ...(!!zpd && { zdp: zpd }),
        },
      }),
      type: applicationType[Number(trailId)],
      status: 'PENDING',
      class_id: Number(classEntity.id),
      appliedAt: new Date(),
      sprintId: classEntity.period?.sprint_id ?? null,
      periodId: classEntity.period?.id ?? null,
      studentAge: calcAgeInYears(student?.birthdate),
      studentAgeGroup: student?.age_group ?? null,
      valideUntil: twoWeeks,
      user: {
        id: Number(student.id),
        lms_student: Number(student.id),
        atributes: [
          {
            atribute: 'Nome',
            value: `${student.name}`,
          },
          {
            atribute: 'Nascimento',
            value: `${student.birthdate}`,
          },
          {
            atribute: 'Telefone',
            value: `${student.phone}`,
          },
        ],
      },
    },
  };
};


export const calcAgeInYears = (birthDate?: string | null): number | null => {
  if (birthDate) {
    const birthDateParsedIso = parseISO(birthDate);
    const currentDate = new Date();
    return differenceInYears(currentDate, birthDateParsedIso);
  }
  return null
}