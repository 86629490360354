import React from 'react';
import { Wrapper } from './styles';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';

type Props = {
  icon?: JSX.Element;
  positionRight?: string;
  positionTop?: string
  isAbsolute?: boolean
};

const ButtonBackHome: React.FC<Props> = ({ icon, positionRight, positionTop, isAbsolute = true }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/')
  };
  return <Wrapper onClick={handleRedirect} positionRight={positionRight} positionTop={positionTop} isAbsolute={isAbsolute}>{!!icon ? icon : <Icon name="home" fill="#231F20" />}</Wrapper>;
};

export default ButtonBackHome;
