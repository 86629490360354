import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IEvent } from '../interfaces/IEvent';

class EditScheduleEventService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(event: IEvent): Promise<IEvent | false> {
    try {
      const res = await this.api.put(`/event/${event.id}`, {
        name: event.name,
        description: event.description,
        week_days: event.week_days,
        type: event.type,
        start_date: event.start_date,
        end_date: event.end_date,
        start_time: event.start_time,
        end_time: event.end_time
      });

      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditScheduleEventService;
