import React, { useCallback } from 'react';
import { CSSProperties } from 'styled-components';

import CustomCard from '@shared/components/CustomCard';

import IContract from '@modules/contracts/interfaces/IContract';
import Tag from '@modules/tags/components/Tag';

import { ContractName, ContractPartner, Container, HeaderContainer, DurationContainer } from './styles';
import { format } from 'date-fns';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { contractStatusTranslator } from '@modules/projects/schemas/Project/partials/ProjectStatus';
import { getTimezoneOffsetString } from '@shared/utils/formateDateDifferenceDay';

export interface ContractCardProps {
  contract: IContract;
  contractsSelected?: IContract[];
  canSelect?: boolean;
  inputCard?: boolean;
  customStyle?: CSSProperties;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  handleOnClick?: (clicked: IContract) => void;
  hideDescription?: boolean

}

const ContractCard: React.FC<ContractCardProps> = ({
  contract,
  contractsSelected,
  canSelect,
  inputCard,
  canDeleteCard,
  customStyle,
  handleOnClick,
  hideDescription = false
}) => {
  const cardShadow = useCallback(() => {
    if (canSelect && contractsSelected?.find((p) => p.id === contract.id)) return 'drop-shadow(0px 0px 28px #49C56C)';
    return 'none';
  }, [contractsSelected, canSelect, contract]);

  const RenderContactCompany = () => {
    if (contract.gic_contact_company) {
      return <ContractPartner>Parceiro: <b>{contract.gic_contact_company.name}</b></ContractPartner>
    }
    return null
  }

  const RenderTriggerDate = () => {
    if (contract.renewal_trigger_date) {
      return <div><p>Gatilho de Renovação: <b>{contract.renewal_trigger_date && format(new Date(contract.renewal_trigger_date), "dd/MM/yyyy")}</b></p></div>
    }
    return null
  }

  const RenderSignatureDate = () => {
    if (contract.signature_date) {
      return <b>{format(new Date(contract.signature_date), "dd/MM/yyyy")}</b>
    }
    return null
  }

  const RenderEndDate = () => {
    if (contract.end_date) {
      return <b>{format(new Date(contract.end_date), "dd/MM/yyyy")}</b>
    }
    return null
  }

  const formateDateDifference = (startDateStr: string, endDateStr: string): string => {
    const getDateWithOffset = (dateStr: string): Date => {
      const date = new Date(`${dateStr.replace('Z', getTimezoneOffsetString(new Date()))}`);
      date.setHours(0, 0, 0, 0);
      return date;
    };

    const startDate = getDateWithOffset(startDateStr);
    const endDate = getDateWithOffset(endDateStr);

    const differenceInMs = endDate.getTime() - startDate.getTime();
    const differenceInDays = Math.round(differenceInMs / (24 * 60 * 60 * 1000));
    const differenceInMonths = Math.round(differenceInDays / 30);

    return differenceInMonths === 1 ? "1 mês" : `${differenceInMonths} meses`;
  };

  const RenderDuration = () => {
    if (contract.signature_date && contract.end_date) {
      return <b>{`(${formateDateDifference(String(contract.signature_date), String(contract.end_date))})`}</b>;
    }
    return null
  }

  return (
    <Container>
      <CustomCard
        handleOnClick={() => handleOnClick && handleOnClick(contract)}
        header={
          <HeaderContainer>
            <Tag tag={{ color: '#E4E9F2', name: contractStatusTranslator(contract.status) }} />
          </HeaderContainer>
        }
        footer={
          <>
            <DurationContainer>
              <div>
                <p>Duração: <RenderSignatureDate /> - <RenderEndDate /></p>
                <p><RenderDuration /></p>
              </div>
              <RenderTriggerDate />
            </DurationContainer>
            {!hideDescription &&
              <AccordionInfoItem title='Descrição do Contrato'>
                {contract.description}
              </AccordionInfoItem>
            }
          </>
        }
        canDeleteCard={canDeleteCard}
        inputCard={inputCard}
        key={contract.id}
        customStyles={{
          border: 'none',
          filter: `${cardShadow()}`,
          ...customStyle,
        }}
      >
        <ContractName>{contract.name}</ContractName>
        <RenderContactCompany />
      </CustomCard>
    </Container >
  );
};

export default ContractCard;
