import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { usePlaces } from '@modules/places/context/PlacesContext';

const PlaceTagsRelationCreate: React.FC = () => {
  const navigate = useNavigate();
  const { placeCurrent, setPlaceCurrent } = usePlaces();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, tags: tags };
      });
    },
    [setPlaceCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={placeCurrent?.tags}
      pathToReturn="/diretorio/locais/criar"
      buttonCallback={() => navigate('/diretorio/locais/criar')}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
    />
  );
};

export default PlaceTagsRelationCreate;
