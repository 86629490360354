import React from 'react';
import { TagContainer, TagItem, TagItemProps, TagMessageError } from './styles';

interface ThermometerTagProps {
  items: TagItemProps[];
  disabled?: boolean;
  currentSelected?: string;
  hasErrors?: boolean;
  errorMessage?: string;
  handleOnChange: (value: string) => void;
}

export const ThermometerTag: React.FC<ThermometerTagProps> = ({
  items,
  currentSelected,
  handleOnChange,
  disabled,
  hasErrors = false,
  errorMessage = 'O campo é obrigatório',
}) => {
  return (
    <div>
      <TagContainer>
        {items.length &&
          items.map((i, k) => (
            <TagItem
              {...i}
              disabled={disabled}
              key={`${k} - ${i.value}`}
              selected={currentSelected === i.value}
              onClick={() => handleOnChange(i.value)}
            >
              {i.label}
            </TagItem>
          ))}
      </TagContainer>
      {hasErrors && <TagMessageError>{errorMessage}</TagMessageError>}
    </div>
  );
};
