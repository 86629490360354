import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner, Grid, Button } from 'react-alicerce-components';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import IContract from '@modules/contracts/interfaces/IContract';
import ContractList from '@modules/contracts/components/ContractList';
import { ContractDefaultStylesContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import ListContractsService, { IContractResponse, IListContractBusinessOptions } from '@modules/contracts/services/ListContractService';
import { getLocalTimezoneDate } from '@shared/utils/getLocalTimezoneDate';

interface ContractDirectoryProps {
  title: string;
  btnText: string;
  pathToReturn?: string;
  pathRedirect?: string;
  canSelectPlace?: 'one' | 'many';
  selectedItens?: IContract[];
  listOptions?: any;
  isAdvancedFilterDisabled?: boolean;
  handleOnClick?: (placeCurrent: any) => void;
  setSelectedItens?: (placeCurrent: IContract[]) => void;
}

const ContractDirectory: React.FC<ContractDirectoryProps> = ({
  pathToReturn = '/diretorio/contratos',
  btnText,
  title = 'Diretório de Contratos'
}) => {
  const contractBusinessFilter = useRef<IListContractBusinessOptions>({ end_date: { end: undefined, start: undefined } });
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const [contractList, setContractList] = useState<IContractResponse>()
  const listContracts = useCallback(
    async (page = 0) => {
      const start = contractBusinessFilter.current.end_date?.start
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.start).startOfDay().toISOString()
        : undefined

      const end = contractBusinessFilter.current.end_date?.end
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.end).endOfDay().toISOString()
        : undefined

      if (!signedUser) return;
      setIsLoading(true);
      const contracts = await new ListContractsService(signedUser.token, newCancelToken()).execute('', {
        page,
        size: 10,
        ...contractBusinessFilter.current,
        end_date: { start, end }
      });
      if (contracts === 'canceling') return;
      setIsLoading(false);
      if (!contracts) return;
      setContractList(contracts);
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listContracts()
  }, [listContracts])

  return (
    <ContractDefaultStylesContainer>
      <HeaderDirectory
        title={title}
        pathToReturn={pathToReturn}
        menuKebab={{ tooltip: { view: 'ContractDirectory' }, menuBackHome: true }}
      />
      {isLoading && <Spinner fixed />}
      <DirectoryContainer footer={false}>
        <Grid container>
          <ContractList
            contractList={contractList}
            listContracts={listContracts}
            contractBusinessFilter={contractBusinessFilter}
          />
          {btnText && <PageFooter>
            <Button
              shaded
              transform="none"
              style={{ margin: '0 auto' }}
            >
              {btnText}
            </Button>
          </PageFooter>}

        </Grid>
      </DirectoryContainer>
    </ContractDefaultStylesContainer>
  );
};

export default ContractDirectory;
