import React, { useCallback, useEffect, useState } from 'react';

import { P } from 'react-alicerce-components';

import EventCard from '@shared/components/EventCard';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';

import { AccordionProblemItem, Container, WeekDay, DateComponent } from './styles';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import { getWeekDay } from '@shared/utils/getWeekDay';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { DateHelper } from '@shared/helpers/DateHelper';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ListEventsService from '../../../schedules/services/ListEventsService';
import { IEvent } from '../../../schedules/interfaces/IEvent';
import ListSprintsService from '../../../schedules/services/ListSprintsService';
import { ISprint } from '../../../schedules/interfaces/ISprint';
import { getWeekdayFromWeekNumber } from '../../../classes/utils/getWeekdayFromWeekNumber';

const ClassPlannerHeader: React.FC = () => {
  const { dailyClass, problems } = useScheduleGrid();
  const { classId } = useParams();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();

  const [events, setEvents] = useState<(IEvent | ISprint)[]>([]);
  // const [tasks, setTasks] = useState<ITask[]>([]);

  const listEvents = useCallback(async () => {
    if (!signedUser || !classId || !dailyClass?.start_date) return;

    const filterBy = {
      class_id: classId,
      start_date: DateHelper.extractDate(),
      end_date: DateHelper.extractDate(),
      size: 9999,
    };

    // const filterTasksBy = {
    //   class_id: classId,
    //   due_today: true,
    //   size: 3,
    // };

    // const resTasks = await new ListPendingTasksService(signedUser.token).execute('', filterTasksBy);
    const resEvents = await new ListEventsService(signedUser.token).execute('', {
      ...filterBy,
      week_days: [getWeekdayFromWeekNumber(new Date(`${dailyClass?.start_date}T${dailyClass.start_time}`).getDay())],
    });

    const resSprints = await new ListSprintsService(signedUser.token).execute('', filterBy);

    // const isCancel = resEvents === 'canceling' || resSprints === 'canceling' || resTasks === 'canceling';
    // const isFail = !resEvents || !resSprints || !resTasks;

    const isCancel = resEvents === 'canceling' || resSprints === 'canceling';
    const isFail = !resEvents || !resSprints;

    if (isFail || isCancel) return;

    // setTasks(resTasks.items);
    setEvents([...resEvents.items, ...resSprints.items]);
  }, [classId, dailyClass, signedUser]);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      await listEvents();
      setIsLoading(false);
    }

    load();
  }, [listEvents, setIsLoading]);

  return (
    <Container>
      <DateComponent>{dailyClass?.start_date ? moment(dailyClass?.start_date).format('DD [de] MMMM [de] YYYY') : 'Carregando...'}</DateComponent>
      <WeekDay>{dailyClass?.start_date ? getWeekDay(dailyClass?.start_date) : 'Carregando...'}</WeekDay>
      <AccordionInfoItem colorHeader="#96B6F7" className="accordion-events" title={`Eventos de Hoje (${events.length ? events.length : 0})`}>
        {events.map((event) => {
          const createdAt = new Intl.DateTimeFormat('pt-BR').format(event.created_at ? new Date(event.created_at) : new Date());
          return (
            <EventCard key={event.id} header={<h6>{event.name}</h6>} status="basic" date={`Criado em: ${createdAt} `}>
              <P textAlign="center">{event.description}</P>
            </EventCard>
          );
        })}
      </AccordionInfoItem>

      {problems && problems.length > 0 && (
        <AccordionInfoItem title={`Problemas (${problems.length})`} colorHeader="#BB7DC2">
          <div className="problems">
            {problems.map((p, i) => (
              <AccordionProblemItem key={i}>{p}</AccordionProblemItem>
            ))}
          </div>
        </AccordionInfoItem>
      )}
    </Container>
  );
};

export default ClassPlannerHeader;
