import * as Yup from 'yup';
import { EVENT_TYPE_CONSTRAINTS } from './validData/validEventTypes';
import { WEEK_DAYS_CONSTRAINTS } from '@shared/utils/validData/validWeekdays';

const WEEKDAYS = WEEK_DAYS_CONSTRAINTS.map((c) => c.value);
const EVENT_TYPES = EVENT_TYPE_CONSTRAINTS.map((c) => c.value);

export const scheduleEventSchema = Yup.object().shape({
  name: Yup.string()
    .required({ name: 'name', message: 'O campo é obrigatório' })
    .min(3, { name: 'name', message: 'No minimo 3 caracteres' })
    .max(50, { name: 'name', message: 'No maximo 50 caracteres' }),
  type: Yup.string()
    .required({ name: 'type', message: 'Data inicial é obrigatória.' })
    .oneOf(EVENT_TYPES, { name: 'type', message: `Valor do campo inválido` }),
  schedule_id: Yup.string().required({ name: 'schedule_id', message: `É necessário adicionar uma Agenda` }),
  description: Yup.string().required({ name: 'description', message: `O campo é obrigatório` }),
  week_days: Yup.array().of(Yup.string().oneOf(WEEKDAYS, { name: 'week_days', message: `Campo deve ser ${WEEKDAYS} ` })),
  start_date: Yup.string().required({ name: 'start_date', message: 'Data inicial é obrigatória.' }),
  end_date: Yup.string().required({ name: 'end_date', message: 'Data inicial é obrigatória.' }),
});
