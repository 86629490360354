export const initalDataBlockRange = [
    {
      trail: {
        id: '',
        name: '',
        version: '',
      },
      block_min: {
        id: '',
        name: '',
        order: '',
        trail_id: '',
        level_id: '',
        color_variation:1,
        level: {
          id: 1,
          name: '',
          order: 1,
          color: '',
        },
      },
      block_max: {
        id: '',
        name: '',
        order: '',
        trail_id: '',
        level_id: '',
        color_variation:1,
        level: {
          id: 1,
          name: '',
          order: 1,
          color: '',
        },
      },
    },
  ];