import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IPortfolioActivity } from '../interfaces/IPortfolioActivity';
import { IFormatedPortfolioActivity } from '../interfaces/IFormatedPortfolioActivity';

class EditPortfolioActivityService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    portfolioActivityBody: IFormatedPortfolioActivity,
    portfolioActivityId: string
  ): Promise<IPortfolioActivity | false> {
    try {
      const res = await this.api.put(`/portfolio/${portfolioActivityId}`, { ...portfolioActivityBody });
      return res.data as IPortfolioActivity;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export { EditPortfolioActivityService };
