import { IProgram } from '@modules/activities-library/interfaces/IProgram';

export const formatProgramCurrent = (programCurrent: IProgram) => {
  return {
    title: programCurrent.title,
    tag_ids: programCurrent.tags ? programCurrent.tags?.map((tag) => (tag.id ? tag.id : '')).filter(Boolean) : [],
    activity_ids: programCurrent.activities ? programCurrent.activities?.map((tag) => (tag.id ? `${tag.id}` : '')).filter(Boolean) : [],
    status: programCurrent.status ? programCurrent.status : 'private',
  };
};
