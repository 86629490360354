import React, { useEffect } from 'react';

import { formatDate } from 'react-alicerce-components';
import { LocationStudent } from '@shared/components/LocationStudent';
import { uuid } from 'uuidv4';

import { Container, Header } from './styles';
import { IApplicationChart } from '@modules/applications/interfaces/IApplicationChart';

interface IStudentGradeProps {
  applicationData?: IApplicationChart;
}

const StudentGrade: React.FC<IStudentGradeProps> = ({ applicationData }) => {
  const applicationChart = applicationData?.application_data.application_chart;
  const application = applicationData?.application_data;

  useEffect(() => {
    const transformaArray = (blocksAndSkills) => {
      const sortValuesAsc = JSON.parse(JSON.stringify(blocksAndSkills))?.sort((a, b) => {
        if (a.skills.length < b.skills.length) return 1;
        if (a.skills.length > b.skills.length) return -1;
        return 0;
      });

      const maxLengthOfArray = sortValuesAsc[0].skills.length;

      blocksAndSkills?.forEach((el) => {
        while (el.skills.length < maxLengthOfArray) {
          el.skills.push({ id: -1, status: 'undef' });
        }
      });
    };

    !!applicationChart && transformaArray(applicationChart.chart.gradeInfo.blocks);
  }, [applicationChart, applicationData]);

  const blockIndex = applicationChart?.blockIndex;

  return (
    <Container>
      <Header type={application?.application.type}>
        <h6>
          <b>{`${application?.application.type} ${application?.application.trail_name}`}</b> |{' '}
          {application?.application.appliedAt && <>Aplicado em: {formatDate(new Date(application?.application.appliedAt), `numeric`)}</>}
        </h6>
      </Header>
      {!!applicationChart && (
        <LocationStudent
          groupMarkers={[
            {
              id: uuid(),
              color: application?.application.type === 'MAPA' ? '#0097B1' : '#F47920',
              isFixed: true,
              isMain: true,
              gridIndex: blockIndex!,
              markers: [{ name: application?.application.type === 'MAPA' ? 'M.A.P.A' : 'miniM.A.P.A' }],
            },
          ]}
          trail={{
            name: application?.level.name,
            blocks: applicationChart.chart.gradeInfo.blocks,
            skill_descriptions: applicationData.application_data.application_chart.chart.gradeInfo.skill_descriptions,
            meta_block: applicationData.application_data.application_chart.chart.gradeInfo.meta_block,
          }}
          handleSelectChange={(obj) => console.log('changed', obj)}
          showBlockDescription={true}
          showLegend={false}
          showLabel={false}
          canSelect={false}
        />
      )}
    </Container>
  );
};

export default StudentGrade;
