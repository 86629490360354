import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { useAdvancedFilterController } from '../../context/AdvancedFilterControllerContext';

const ActivityLibraryTypeAdvancedFilter: React.FC = () => {
  const navigate = useNavigate();
  const [choosenTag, setChoosenTag] = useState<ITag[]>([]);
  const { setOpenTab, setIsAdvancedFilterOpen } = useAdvancedFilterController();
  const { activityLibraryFilter } = useActivity();

  const handleOnChangeTagsSelected = useCallback((tags: ITag[]) => {
    if (tags.length === 0) {
      setChoosenTag([]);
      return;
    }
    setChoosenTag([tags[0]]);
  }, []);

  const handleClickButton = useCallback(() => {
    setIsAdvancedFilterOpen(true);
    setOpenTab('activity');
    if (choosenTag.length === 0) {
      delete activityLibraryFilter.current.type;
    }
    activityLibraryFilter.current.type = choosenTag[0];
    navigate(-1);
  }, [activityLibraryFilter, choosenTag, navigate, setIsAdvancedFilterOpen, setOpenTab]);

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={1}
      useSearch
      selectedTags={activityLibraryFilter.current.type ? [activityLibraryFilter.current.type] : undefined}
      pathToReturn="/banco-atividades"
      buttonCallback={handleClickButton}
      buttonText="Adicionar Tag"
    />
  );
};

export { ActivityLibraryTypeAdvancedFilter };
