import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Pagination, Spinner } from 'react-alicerce-components';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import PageFooter from '@shared/components/PageFooter';

import ListSprintsPlanService from '@modules/sprint/services/ListSprintsPlanService';

import { SprintCard } from '@modules/sprint/components/SprintCard';
import { useSprint } from '@modules/sprint/context/SprintContext';
import { ISprintItemsResponse } from '@modules/sprint/interfaces/ISprintResponse';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';

import { Container, InputDateContainer } from './styles';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';

interface ISprintFilter {
  initial_date?: string;
  final_date?: string;
}
export const SprintList: React.FC = () => {
  const { setSprintResponseCurrent, sprintResponseCurrent, setCurrentprint } = useSprint();
  const sprintFilter = useRef<ISprintFilter>({});

  const { isLoading, setIsLoading } = useGlobal();

  const { classId } = useParams<{ classId: string }>();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const navigate = useNavigate();

  const listSprints = useCallback(
    async (search: string = '', page: number = 0) => {
      if (!signedUser) return;

      const params = {
        size: 10,
        page,
        class_id: Number(classId),
        ...sprintFilter.current,
      };

      setIsLoading(true);
      const res = await new ListSprintsPlanService(signedUser.token, newCancelToken()).execute(search, params);
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return;
      setSprintResponseCurrent(res);
    },
    [classId, newCancelToken, setIsLoading, setSprintResponseCurrent, signedUser]
  );

  const handleClickButton = useCallback(
    (sprint: ISprintItemsResponse) => {
      setCurrentprint(sprint);
      navigate(`/planejador-sprint/${classId}/historico`);
    },
    [classId, navigate, setCurrentprint]
  );

  const handleOnChangeInputDate = (newValue: string, keyName: string) => {
    const value = newValue.length > 0 ? newValue : undefined;
    sprintFilter.current[keyName] = value;
  };

  const handleCreateClickButton = useCallback(() => {
    navigate(`/planejador-sprint/${classId}/criar`);
  }, [classId, navigate]);

  const resetFilter = useCallback(() => {
    sprintFilter.current = {};
    listSprints();
  }, [listSprints]);

  useEffect(() => {
    listSprints();
  }, [listSprints]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Plano de Ensino"
        pathToReturn={`/turma/${classId}`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'SprintList' },
        }}
      />
      <DirectoryContainer footer={true} className="directory-container-list">
        <div>
          <AdvancedFilter
            handleResetFilter={resetFilter}
            applyFilterButtonBackground="#96B6F7"
            handleApplyFilter={listSprints}
            onChangeMainInput={(newValue) => listSprints(newValue)}
          >
            <section>
              <InputDateContainer>
                <input
                  type="date"
                  defaultValue={sprintFilter.current.initial_date}
                  name="initial_date"
                  onChange={(event) => handleOnChangeInputDate(event.target.value, event.target.name)}
                />
                <label>até</label>
                <input
                  type="date"
                  defaultValue={sprintFilter.current.final_date}
                  name="final_date"
                  onChange={(event) => handleOnChangeInputDate(event.target.value, event.target.name)}
                />
              </InputDateContainer>
            </section>
          </AdvancedFilter>

          {sprintResponseCurrent && (
            <Fragment>
              <div className="sprint-card-list">
                {sprintResponseCurrent.items.map((sprint: ISprintItemsResponse, index: number) => (
                  <SprintCard sprint={sprint} handleOnClick={handleClickButton} key={index} actual={index === 0} />
                ))}
              </div>
            </Fragment>
          )}
        </div>

        {sprintResponseCurrent && (
          <Pagination
            totalOfPages={Number(sprintResponseCurrent.totalPages)}
            currentPage={Number(sprintResponseCurrent.currentPage)}
            callbackGetListData={listSprints}
            status="quaternary"
          />
        )}
      </DirectoryContainer>
      <PageFooter>
        <Button transform="none" status="quaternary" shaded onClick={handleCreateClickButton}>
          Adicionar Planejamento
        </Button>
      </PageFooter>
    </Container>
  );
};
