import React, { useMemo } from 'react';

import {
    Button,
    IButtonProps
} from 'react-alicerce-components';

type IButtonGradientProps = IButtonProps & {
    type?: number;
}

const ButtonGradient: React.FC<IButtonGradientProps> = ({
    style,
    transform = 'none',
    fill,
    type = 1,
    shaded = true,
    className = '',
    children,
    ...rest
}) => {

    const getFill = useMemo(() => {
        const gradients = [
            '',
            'linear-gradient(180deg, #6E3FA5 0%, #2484C7 98.12%)',  // = 1
            'linear-gradient(284.92deg, #FFA33A 0%, #2AB1C8 100%)', // = 2
            'linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)',    // = 3
            'linear-gradient(135deg, #2AB1C8 0%, #2484C7 100%)',    // = 4
            'linear-gradient(284.92deg, #F47920 0%, #0097B1 100%)', // = 5
            'linear-gradient(135deg, #F47920 0%, #542E91 100%)',    // = 6
            'linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)', // = 7
            'linear-gradient(135deg, #FFBC6F 0%, #885EB9 100%)',    // = 8
            'linear-gradient(284.92deg, #885EB9 0%, #43A2E4 100%)', // = 9
            'linear-gradient(135deg, #F47920 0%, #0072BC 100%)',    // = 10
            'linear-gradient(284.92deg, #542E91 0%, #0072BC 100%)', // = 11
            'linear-gradient(135deg, #2AB1C8 0%, #2484C7 100%)',    // = 12
            'linear-gradient(45deg, #482B85 0%, #6749BA 100%)',    // = 13
            'linear-gradient(135deg, #FFBC6F 0%, #43A2E4 100%)',    // = 14
            'linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)', // = 15
            'linear-gradient(180deg, #A35EC0 0%, #A35EC0 98.12%)', //16
            'linear-gradient(180deg, #698BF4 0%, #698BF4 98.12%)', //17
            'linear-gradient(45deg, #BE3A3A 0%, #F7652B 100%)' //18 
        ];
        return gradients[type];
    }, [type]);

    return (
        <Button
            style={{
                height: '80px',
                whiteSpace: 'pre-wrap',
                borderRadius: '10px',
                ...style
            }}
            className={`button-gradient ${className}`}
            transform={transform}
            fill={fill || getFill}
            shaded={shaded}
            noBorder
            {...rest}
        >
            {children}
        </Button>
    );
}

export default ButtonGradient;