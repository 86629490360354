import { styled } from 'react-alicerce-components';

export const Container = styled.div`
   .main-navbar {
    height: 130px;
  }

  [role='tabpanel'] {
    height: calc(100vh - 210px);
    border-radius: 0;
  }
`;
