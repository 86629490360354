import { ProjectCardProps } from '@modules/projects/components/ProjectCard';
import { P } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .custom-card {
    border: none;
  }

  .card-header {
    padding: 1rem;
    background-color: #BE3A3A;
  }

  .card-body {
    border-radius: 0;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #F3C8C8;
    padding: 1rem;
  }

  .card-footer {
    border-radius: 0;
    border: none;

    .accordion-info-item-container {
      margin: 0;

     .accordion-header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.31rem;
      border-bottom-right-radius: 0.31rem;
      padding: 0 1rem;
      background-color: #F3C8C8;
     }

     .accordion-content {
        background-color: #F3C8C8;
        border-radius: 0;
        margin-top: -3px;
        padding: 1rem;
        border-bottom-left-radius: 0.31rem;
        border-bottom-right-radius: 0.31rem;
     }
    }
  }

  .tag {
    border-radius: 4px;
    padding: 3px 6px;
  }

  .remove-card {
    top: 1.5rem;
  }
`;

export const HeaderContainer = styled.div<{ canDeleteCard?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: 600;
    margin-right: ${({ canDeleteCard }) => canDeleteCard ? '2rem' : 0};
  }
`;

export const DurationContainer = styled.div<Pick<ProjectCardProps, 'hideDescription'>>`
  display: flex;
  justify-content: center;
  background-color: #BE3A3A;
  flex-direction: column;
  padding: 1rem;
  border-bottom-left-radius:  ${({ hideDescription }) => hideDescription ? '6px' : 0};
  border-bottom-right-radius: ${({ hideDescription }) => hideDescription ? '6px' : 0};

  p {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
  }

  > div {
    display: flex;
    align-items: center;
  }

`;

export const Name = styled(P)`
  font-weight: 900;
`;

export const Info = styled(P)`
  font-size: 12px;
`;