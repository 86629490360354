import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IPlannedActivityData } from './ListPlannedActivityService';

export interface IUpdatePlannedActivities {
  daily_class_id: string;
  planned_activities: IPlannedActivities[];
}

export interface IPlannedActivities {
  planned_activity_id: string;
  start_time: string;
  end_time: string;

  //in case to update a single plannedActivity
  student_ids?: number[];
  activity_data?: IPlannedActivityData;
}

export interface IUpdatePlannedActivitiesServiceResponse {
  id: string;
  pending_problems: string[];
  status: string;
}

class UpdatePlannedActivitiesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(plannedActivities: IUpdatePlannedActivities): Promise<IUpdatePlannedActivitiesServiceResponse | false> {
    try {
      const res = await this.api.put(`/daily-class-plan`, { ...plannedActivities });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default UpdatePlannedActivitiesService;
