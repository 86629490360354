import { styled } from 'react-alicerce-components';

export const Container = styled.div`

    > div {
        padding: 40px 20px 20px 20px;
    }

    .accordion-header {
        justify-content: flex-start;
        background-color: #E4E9F2;
    }

    .accordion-content {
        padding-top: 1rem;
    }
    
`;