import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ConfirmModal from '@shared/components/ConfirmModal';
import { formatGroup } from '@modules/groups/utils/formatGroup';
import { useToast } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { useGroup } from '@modules/groups/context/GroupContext';
import EditGroupService from '@modules/groups/services/EditGroupService';
import Icon from 'react-eva-icons';
import DeleteGroupService from '@modules/groups/services/DeleteGroupService';
import GroupForm from '@modules/groups/components/GroupForm';
import GetGroupService from '@modules/groups/services/GetGroupService';

const GroupEdit: React.FC = () => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { groupCurrent, setGroupCurrent } = useGroup();
  const navigate = useNavigate();
  const groupIdInPath = useParams<{ id: string }>();

  const getGroup = useCallback(async () => {
    if (!groupIdInPath.id) return navigate('/meus-grupos');
    setIsLoading(true);
    const groupFound = await new GetGroupService(signedUser?.token).execute(groupIdInPath.id);
    setIsLoading(false);
    if (!groupFound) return navigate('/meus-grupos');
    setGroupCurrent(groupFound);
  }, [groupIdInPath.id, navigate, setGroupCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    if (!groupCurrent.id) getGroup();
  }, [getGroup, groupCurrent.id]);

  const handleEditGroup = useCallback(async () => {
    if (!signedUser || !groupCurrent || !groupCurrent.id) return;
    setShowEditModal(false);
    const formatedBody = formatGroup(groupCurrent);
    setIsLoading(true);
    const res = await new EditGroupService(signedUser.token, newCancelToken()).execute(groupCurrent.id, formatedBody);
    if (!isMounted) return;
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Grupos',
        description: 'Grupo editado com sucesso.',
      });
      return navigate(`/grupo/${groupCurrent.id}/informacoes`);
    }

    addToast({
      status: 'danger',
      title: 'Grupos',
      description: 'Não foi possível editar o Grupo.',
    });
  }, [addToast, groupCurrent, navigate, isMounted, newCancelToken, setIsLoading, signedUser]);

  const handleDeleteGroup = useCallback(async () => {
    setShowDeleteModal(false);
    if (!groupCurrent.id) return;
    setIsLoading(true);
    const res = await new DeleteGroupService(signedUser?.token).execute(groupCurrent.id);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Grupos',
        description: 'Grupo deletado com sucesso.',
      });
      return navigate(`/diretorio/grupos`);
    }

    addToast({
      status: 'danger',
      title: 'Grupos',
      description: 'Não foi possível deletar o Grupo.',
    });
  }, [addToast, groupCurrent.id, navigate, setIsLoading, signedUser]);

  return (
    <Fragment>
      <GroupForm
        saveModalCallback={setShowEditModal}
        deleteModalCallback={setShowDeleteModal}
        title="Editar Grupo"
        pathEnvolvedPlaces="/diretorio/grupos/editar/selecionar/locais"
        pathEnvolvedUsers="/diretorio/grupos/editar/selecionar/usuarios"
        pathParentGroup="/diretorio/grupos/editar/selecionar"
        pathToReturn={`/grupo/${groupCurrent.id}/informacoes`}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(!showEditModal)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={() => handleEditGroup()}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(!showDeleteModal)}
        title="Deseja deletar o Grupo?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={() => handleDeleteGroup()}
      />
    </Fragment>
  );
};

export default GroupEdit;
