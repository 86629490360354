import { styled } from 'react-alicerce-components';


export const Container = styled.div<{ isOpen: boolean }>`
  min-height: 56px;
  position: fixed;
  width: 100%;
  z-index: 5;
  bottom: ${({ isOpen }) => isOpen ? '0' : '90px'};
  padding-bottom: ${({ isOpen }) => isOpen ? '90px' : '0'};

  @media (max-width: 991px) {
    bottom: ${({ isOpen }) => isOpen ? '0' : '110px'};
  }
  
  .headlights-history-header{
    width: 100%;
    height: 56px;
    padding: 1rem;
    background-color: #F7652B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
  
    .styled-icon {
      transform: ${({ isOpen }) => isOpen ? 'rotate(180deg)' : 0};
      i svg {
        width: 50px;
        height: 50px;
        margin-top: 4px;
        transition: 0.3s;
      }
    }
  }

  .headlights-history-content {
    display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
    height: ${({ isOpen }) => isOpen ? 'calc(100vh - (178px + 90px))' : 0};
    flex-direction: column;
    background-color: #EDF1F7;
    padding: 1rem;

    .accordion-info-item-container {
      width: 100%;
      margin: 0;

      > div {
        background: transparent;

        .accordion-content {
          margin-top: 1rem;
          border: 2px solid #E4E9F2;
          padding: 1rem;
          font-size: 12px;
          font-weight: 800;
          color: #BE3A3A;
          background: #fff;
        }
      }
    }

    > div {
      overflow-y: scroll;
    }
  }
  
  .period-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 14px;
        font-weight: 600;
    }

    .period-select-icon {
      cursor: pointer;
      margin: 0px 10px;

      i {
        display: flex;
        align-items: center;
        font-weight: 600;
        width: 16px;
        height: 16px;

        svg {
          width: 16px;
          height: 16px;
          fill: #2E3A59;
        }
      }
    }

    .period-select-icon.disabled {
      opacity: 0.5;
      pointer-events: none; 
    }
  }
`;
