/* eslint-disable no-restricted-globals */
import { Spinner, Button, Checkbox, useToast } from 'react-alicerce-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsCheckLg } from 'react-icons/bs';
import Icon from 'react-eva-icons';

import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import PageFooter from '@shared/components/PageFooter';

import { ActivityLibraryForm } from '@modules/activities-library/components/ActivityLibraryForm';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { activityLibrarySchema } from '@modules/activities-library/utils/activityLibrarySchema';
import useAuth from '@shared/store/auth/hook';
import { IFormatedActivity } from '@modules/activities-library/interfaces/IFormatedActivity';
import { formatActivityLibrary } from '@modules/activities-library/utils/formatActivityLibrary';
import { IActivityLibrary } from '../../interfaces/IActivityLibrary';
import DeleteActivityService from '../../services/DeleteActivityService';
import { FavoriteToggleActivityService } from '../../services/FavoriteToggleActivityService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import GetActivityService from '../../services/GetActivityService';
import EditActivityService from '../../services/EditActivityService';
import UploadService from '@shared/services/UploadFile';
import { formatedFileUpload } from '../../utils/formatedFileUpload';
import { ActivityLibraryEditContainer } from './styles';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { splitAttachmentFiles } from '@shared/utils/splitAttachmentFiles';

const ActivityLibraryEdit: React.FC = () => {
  const { isLoading, setIsLoading } = useGlobal();
  const { activityLibraryCurrent, clearActivityLibraryContextData, setActivityLibraryCurrent } = useActivity();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const formatedActivityBody = useRef<IFormatedActivity>();
  const { resetActivityFilter } = useActivity();
  const { activityId } = useParams<{ activityId: string }>();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();

  // issue 802 rermove a opção de enviar para o pedagogico
  const showPedagogicOption = false;

  const handleCancel = useCallback(() => {
    clearActivityLibraryContextData();
    navigate(-1)
  }, [clearActivityLibraryContextData, navigate]);

  const getActivity = useCallback(async () => {
    if (!activityId) return;
    setIsLoading(true);
    const res = await new GetActivityService(signedUser?.token).execute(activityId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Recuperar Atividade.',
      });
      return navigate(-2);
    }

    if (!isMounted()) return;
    setActivityLibraryCurrent(res);
  }, [activityId, addToast, isMounted, navigate, setActivityLibraryCurrent, setIsLoading, signedUser]);

  const canSaveNewActivity = useCallback(async () => {
    if (!activityLibraryCurrent) return;
    const formatedBody = formatActivityLibrary(activityLibraryCurrent);
    console.log(formatedBody, 'formatedBody');
    let formErros = await beforeSubmitForm(formatedBody, activityLibrarySchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    const auxFormattedBody = formatedBody as IFormatedActivity;
    setShowModalEdit(true);
    formatedActivityBody.current = auxFormattedBody;
  }, [activityLibraryCurrent]);

  const editProgram = useCallback(async () => {
    if (!formatedActivityBody || !signedUser || !formatedActivityBody.current) return;
    setShowModalEdit(false);
    setIsLoading(true);
    let formattedAttachmentsToSave: IAttachment[] = [];

    const { newFiles, oldFiles } = splitAttachmentFiles(activityLibraryCurrent.attachments);

    if (newFiles && newFiles.length > 0) {
      const res = await new UploadService(signedUser?.token).uploadFile(newFiles, 'activities_library/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      const formattedAttachments = formatedFileUpload(newFiles);
      formattedAttachmentsToSave = formattedAttachments.map((attachment, i) => ({ ...attachment, url: res[i].dbURL }));
    }

    if (formattedAttachmentsToSave.length > 0) formatedActivityBody.current.attachments = [...oldFiles, ...formattedAttachmentsToSave];

    const res = await new EditActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`, formatedActivityBody.current);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividades',
        description: 'Erro ao criar o atividade.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Atividades',
      description: 'Atividade editada com sucesso.',
    });
    resetActivityFilter();
    clearActivityLibraryContextData();
    navigate(-2);
  }, [
    activityLibraryCurrent.attachments,
    activityLibraryCurrent.id,
    addToast,
    clearActivityLibraryContextData,
    formatedActivityBody,
    navigate,
    resetActivityFilter,
    setIsLoading,
    signedUser,
  ]);

  const toggleFavoriteActivity = useCallback(async () => {
    setIsLoading(true);
    const res = await new FavoriteToggleActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Favoritar/Desfavoritar Atividade.',
      });
      return;
    }
    if (!isMounted()) return;
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      return { ...oldState, favorited: res.favorited };
    });
  }, [activityLibraryCurrent.id, addToast, isMounted, setActivityLibraryCurrent, setIsLoading, signedUser]);

  const deleteActivity = useCallback(async () => {
    setShowModalDelete(false);
    setIsLoading(true);
    const res = await new DeleteActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Deletar Atividade.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Atividade',
      description: 'Atividade Deletada com Sucesso.',
    });
    navigate(`/banco-atividades`);
    return;
  }, [activityLibraryCurrent.id, addToast, navigate, setIsLoading, signedUser]);

  const handleSendToPedagogic = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      const isChecked = event.target.checked;
      const value = isChecked ? 'pending' : 'private';
      oldState.status = value;
      return { ...oldState };
    });
  };

  useEffect(() => {
    if (!activityLibraryCurrent.id) getActivity();
  }, [activityLibraryCurrent.id, getActivity]);

  useEffect(() => {
    if (activityLibraryCurrent.status === 'public') {
      addToast({
        status: 'warning',
        title: 'Atividade',
        description: 'Não é permitido editar uma atividade pública',
      });
      navigate(-1);
      return;
    }
  }, [activityLibraryCurrent.status, addToast, navigate]);

  const isEditButtonDisabled = useMemo(() => {
    if (Number(activityLibraryCurrent.created_by) !== Number(signedUser?.lmsUser.id)) return true;
    if (activityLibraryCurrent.status === 'public') return true;
  }, [activityLibraryCurrent.created_by, activityLibraryCurrent.status, signedUser]);

  return (
    <ActivityLibraryEditContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{
          deleteCallback: () => setShowModalDelete(true),
          favorited: { favoritedCallback: () => toggleFavoriteActivity(), isFavorited: activityLibraryCurrent.favorited || false },
        }}
      />
      <DirectoryContainer footer={true}>
        <ActivityLibraryForm
          formAction="editing"
          errors={errors}
          pathToCreateTagsRelation="/banco-atividades/atividade/selecionar/tags"
          pathToCreateSkillRelation="/banco-atividades/criar/competencias"
        />

        <PageFooter>
          <Button transform="none" outline shaded onClick={handleCancel} className="button-cancel-activity">
            Cancelar
          </Button>
          <Button
            disabled={isEditButtonDisabled}
            transform="none"
            status="quaternary"
            shaded
            onClick={canSaveNewActivity}
            className="mochileiro-color"
          >
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalEdit}
        closeCallback={() => setShowModalEdit(false)}
        title="Salvar no Meu Banco!"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={editProgram}
      >
        {showPedagogicOption && (
          <div className="container-text-modal">
            <span className="span-text-modal">
              Ao continuar, você salvará a atividade no seu banco pessoal. Caso queira também enviar para o pedagógico, selecione abaixo:
            </span>
            <Checkbox
              key={0}
              name="Pedagógico"
              id="Pedagógico"
              label="Enviar Atividade para Pedagógico"
              semiRound
              defaultChecked={activityLibraryCurrent.status === 'pending'}
              onChange={(event) => handleSendToPedagogic(event)}
              icon={BsCheckLg}
              hideIconUncheck
            />
          </div>
        )}
      </ConfirmModal>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalDelete}
        closeCallback={() => setShowModalDelete(!showModalDelete)}
        title="Deseja Excluir Atividade?  "
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={deleteActivity}
        subTitle={'Você só pode excluir esta atividade pois ela está no seu banco pessoal e ainda não foi usada em nenhum Planejador de Aula.'}
      />
    </ActivityLibraryEditContainer>
  );
};

export { ActivityLibraryEdit };
