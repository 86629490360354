import { styled } from 'react-alicerce-components';

const gap = '.5rem';
const arrowSize = 12;
const arrowOffset = 3;
const ballSize = '25px';
const columnGridWidth = '70px';
const columnGridHeigth = '60px';
const fixedColumnWidth = '120px';
const fixedColumnHeigth = '40px';

export const Container = styled.div`
    div.legend-container {
        padding: 20px;
    }
`;

export const GridWrapper = styled.div`
    position: relative;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
`;


export const FixedContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(${fixedColumnWidth} + ${gap});
    position: absolute;
    top: 0;
    left: 0;
    gap: ${gap};
    background: #fff;
    padding-top: calc(${fixedColumnHeigth} + ${gap});
`;

export const GF = styled.div`
    width: ${fixedColumnWidth};
    height: ${columnGridHeigth};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C5CEE0;
    border-radius: 5px;
    font-size: 12px;
    color: #222B45;
    font-weight: 800;
`;


export const ContentContainer = styled.div`
    margin-left: calc(${fixedColumnWidth} + ${gap});
    width: 100%;
    overflow-x: scroll;

    > div {
        width: fit-content;
    }
`;


export const GridHeader = styled.div`
    height: ${fixedColumnHeigth};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${gap};
    margin-bottom: ${gap};
`;

export const GH = styled.div`
    width: ${columnGridWidth};
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8F9BB3;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    font-weight: 800;
    padding: 4px 8px;
`;

export const GridsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: ${gap};
    background-color: #EDF1F7;
    border: 1px solid #E4E9F2;
    border-radius: 5px;
    overflow: hidden;
`;

export const GR = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    gap: ${gap};
`;

export const GD = styled.div`
    position: relative;
    width: ${columnGridWidth};
    height: ${columnGridHeigth};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 12px;
    color: #222B45;
    font-weight: 800;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        border: 1px dashed #8F9BB3;
        z-index: 0;
        height: 110%;
    }
    
`;

export const Ball = styled.div<{showBall?: boolean; bgColor?: string; isDouble?: boolean;}>`
    ${props => props.showBall && `
        width: ${ballSize};
        height: ${ballSize};
        border-radius: ${ballSize};
        background: ${props.bgColor};
        border: 1px solid #C5CEE0;
        z-index: 1;
    `}

    ${props => props.showBall && props.isDouble && `
        background: #C7E2FF;
        position: relative;
        margin-left: -8px;

        &::after{
            content: '';
            position: absolute;
            top: -1px;
            width: ${ballSize};
            height: ${ballSize};
            border-radius: ${ballSize};
            background: #94CBFF;
            border: 1px solid #C5CEE0;
            z-index: 1;
        }
    `}
`;

export const Arrow = styled.div<{invert?: boolean;}>`
    width: 100%;
    height: ${arrowSize}px;
    position: absolute;
    left: calc(50% + ${arrowSize}px);
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${arrowSize + arrowOffset}" height="${arrowSize}"><image width="${arrowSize}" height="${arrowSize}" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAYAAACwXJejAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACjSURBVHgBjY7bDYIwFIb/1vLuCI7iAGrCBpD0xTe7AQxAGp6Ml4QRMOi73QRGYICmtURNCFLhS05y2vOdCzAHeX3U2bmK/jlEXu72kytoHYt92Awl2svXYKx2kwt5LFc+6Y21EYLgmZ2qw9i6kWNIY4xJKSagTmSeWusiF3yTdI9fiSDHQiciDtvvV19SLlLBt2rYx7rjACME393gQxblEhO8AIUfMLAh6tmGAAAAAElFTkSuQmCC" /></svg>');
    background-position: left center;
    background-repeat: repeat-x;

    ${props => props.invert && `
        transform: rotate(180deg);
    `}

`;