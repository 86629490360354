import React, {
    createContext,
    useCallback,
    useContext,
    useReducer,
    useEffect,
} from 'react';

import DetectReducer, { Types as EventsTypes } from './DetectReducer';

export interface IDetectContextState {
    isOnline: boolean;
}

export type IDetectContextData = IDetectContextState & {
    setIsOnline: (isOnline: boolean) => void;
}

const initialState: IDetectContextState = {
    isOnline: window.navigator.onLine
};

const DetectContext = createContext<IDetectContextData>({} as IDetectContextData);

const DetectProvider: React.FC = ({ children }) => {

    const [state, dispatch] = useReducer(DetectReducer, initialState);

    const setIsOnline = useCallback((isOnline: boolean) => {
        dispatch({ type: EventsTypes.SET_IS_ONLINE, payload: isOnline });
    }, []);

    const handleNetworkChange = useCallback(() => {
        setIsOnline(window.navigator.onLine);
    }, [setIsOnline])

    useEffect(() => {
        window.addEventListener('offline', handleNetworkChange);
        window.addEventListener('online', handleNetworkChange);
    }, [handleNetworkChange]);

    return (
        <DetectContext.Provider
            value={{
                ...state,
                setIsOnline
            }}
        >
            {children}
        </DetectContext.Provider>
    );
};

function useDetect() {
    const context = useContext(DetectContext);

    if (!context) {
        throw new Error('useDetect must be used within a DetectProvider');
    }

    return context;
}

export { DetectProvider, useDetect };

