import React from 'react';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import {
    Container,
    Time,
    Name
} from './styles';

export interface IActivityItemProps {
    status?: ElementStatus;
    time?: string;
    name?: string;
}

const ActivityItem: React.FC<IActivityItemProps> = ({
    time,
    name,
    status = 'primary'
}) => {
    return (
        <Container status={status}>
            <Time>{time}</Time>
            <Name>{name}</Name>
        </Container>
    );
}

export default ActivityItem;