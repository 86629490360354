import React, { useCallback } from 'react';
import { Divider, P } from 'react-alicerce-components';
import CustomCard from '@shared/components/CustomCard';

import { ClassName, ClassPlace, Container, HeaderContainer } from './styles';
import IClass from '@modules/classes/interfaces/IClass';
import { CSSProperties } from 'styled-components';
import Tag from '@modules/tags/components/Tag';
import { translateClassType } from '@modules/dashboards/utils/translateClassType';

interface ProjectClassCardProps {
  classInfo: IClass;
  classesSelected?: IClass[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
  handleOnClick?: (clickedClass: IClass) => void;
}

const ProjectClassCard: React.FC<ProjectClassCardProps> = ({
  classInfo,
  classesSelected,
  canSelect,
  inputCard,
  canDeleteCard,
  customStyle,
  handleOnClick,
}) => {

  const cardShadow = useCallback(() => {
    if (canSelect && classesSelected?.find((p) => p.id === classInfo.id)) return 'drop-shadow(0px 0px 28px #49C56C)';
    return 'none';
  }, [canSelect, classesSelected, classInfo.id]);

  const RenderTeachersList = () => {
    if (classInfo.teachers && classInfo.teachers.length > 0) {
      return <div>
        {classInfo.teachers.map((item, index) => (
          <>
            <P >Líder {index + 1}: <b>{item.name}</b></P>
            {index !== (classInfo?.teachers?.length || 0) - 1 && <Divider width='100%' />}
          </>
        ))}
      </div>
    }
    return null
  }

  const RenderClassTags = () => {
    if (classInfo.tags && classInfo.tags.length > 0 && classInfo.tags.length <= 3) {
      return <div>
        {classInfo.tags.map((tag) => <Tag tag={{ color: '#FFC94D', name: tag?.name }} />
        )}
      </div>
    }

    if (classInfo.tags && classInfo.tags.length > 0) {
      const displayedTags = classInfo.tags.slice(0, 3);
      const hiddenTagsCount = classInfo.tags.length - 3;
      return <div>
        {displayedTags?.slice(0, 3).map((tag, index) => (
          <Tag key={`list-tags${index}`} tag={{ color: '#FFC94D', name: tag?.name }} />
        ))}
        {hiddenTagsCount && hiddenTagsCount > 0 && (
          <Tag key={`tag-${hiddenTagsCount}`} tag={{ color: '#FFC94D', name: `+ ${hiddenTagsCount}` }} />
        )}
      </div>
    }


    return <div style={{ padding: '19px' }} />
  }

  return (
    <Container>
      <CustomCard
        status="quaternary"
        handleOnClick={() => handleOnClick && handleOnClick(classInfo)}
        header={
          <HeaderContainer>
            <div>
              {classInfo.type && <Tag tag={{ color: '#E4E9F2', name: translateClassType(classInfo.type) }} />}
            </div>
            <div>
              Alunos:&nbsp;<b>{classInfo.students?.length ?? 0}</b>
            </div>
          </HeaderContainer>
        }
        footer={
          <>
            <RenderClassTags />
            <RenderTeachersList />
          </>
        }
        canDeleteCard={canDeleteCard}
        inputCard={inputCard}
        key={classInfo.id}
        customStyles={{
          border: 'none',
          filter: `${cardShadow()}`,
          ...customStyle,
        }}
      >
        <ClassName>{classInfo.name}</ClassName>
        <ClassPlace>{classInfo.place?.address_data?.address}</ClassPlace>
      </CustomCard>
    </Container >
  );
};

export default ProjectClassCard;
