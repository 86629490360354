import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  margin-top: 2rem;

  .input-container {
    margin-top: unset;
    margin-bottom: 2rem;
  }

  .container-checkbox {
    display: flex;
    margin: 2rem 0 0 0;
    column-gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .textarea-container {
    margin: 2rem 0;
    margin-bottom: 0px;
  }
`;

export const ColorPickerContainer = styled.div`
  margin-top: 1.5rem;
  > div {
    align-items: center;
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      grid-template-columns: unset;
    }

    .color-picker-button-container > button {
      margin: 0;
      height: 40px;
      width: 40px;
      background: #145496;
      border-color: #145496;

      i {
        display: flex;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
`;
