import ButtonAdd from '@shared/components/ButtonAdd';
import React, { CSSProperties } from 'react';
import { Label, Pill } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';
import InputErrorMessage from '../ErrorMessages/InputErrorMessage';

import { InputPillContainer } from './styles';

interface IInputPillProps {
  label: string;
  inputPillData?: (string | undefined)[];
  color?: string;
  customStyle?: CSSProperties;
  pathToRedirect?: string;
  error?: string;
  removeCallback?: (index: number) => void;
  placeholder?: string;
  required?: boolean;
  addButton?: () => void
}

const InputPill: React.FC<IInputPillProps> = ({
  pathToRedirect,
  label,
  inputPillData,
  color = '#c5cee0',
  customStyle,
  error,
  removeCallback,
  placeholder,
  required = false,
  addButton
}) => {
  const navigate = useNavigate();

  return (
    <InputPillContainer className="container-InputPill" color={color} style={{ ...customStyle }}>
      <div>
        <Label className={required ? 'required' : ''}>{label}:</Label>
        {pathToRedirect && <ButtonAdd handleOnClick={() => navigate(pathToRedirect)} />}
        {addButton && <ButtonAdd handleOnClick={addButton} />}
      </div>
      <div className="container-pill-input">
        {!!!inputPillData?.length && <span className="input-pill-placeholder">{placeholder}</span>}
        {inputPillData &&
          inputPillData.map((data, index) => (
            <div className="container-pill-remove" key={index}>
              <Pill status="basic" size="small" className="container-pill-box">
                {data}
              </Pill>
              {removeCallback && (
                <div onClick={() => removeCallback(index)} className="container-delete-pill">
                  <Icon name="close" fill="222b45" />
                </div>
              )}
            </div>
          ))}
      </div>
      {error && <InputErrorMessage error={error} />}
    </InputPillContainer>
  );
};

export default InputPill;
