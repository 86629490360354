import { styled } from 'react-alicerce-components';

export const UserFormContainer = styled.div`
  .select-container {
    margin-bottom: 1.5rem;
    max-width: unset;
    .select-options-list {
      position: relative;
    }
  }

  .wrapper-input {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.8rem;
    max-width: 100%;
    margin: 0 0 1.5rem 0;
    > div {
      margin: 0;
    }

    > div:first-child {
      width: 70%;
    }
    > div:last-child {
      width: 30%;
      > div {
        padding: 0.4375rem 0.1rem 0.4375rem 0.5rem;
      }
    }

    input {
      width: inherit;
    }
  }

  .container-button {
    cursor: pointer;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #c5cee0;
      border-radius: 8px;

      svg {
        fill: #fff;
      }
    }
  }

  .add-managers {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 30px;

    i {
      width: 40px;
      height: 40px;

      svg {
        width: 35px;
        height: 35px;
      }
    }

    .text-add-button {
      font-family: var(--secondary-font);
      font-weight: bold;
      font-size: 15px;
      display: flex;
      color: #2e3a59;
    }
  }

  .container-accordion-input {
    margin-top: 10px;
  }
`;
