import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IStudentWithGrades } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { formateMinimapaBody } from '@modules/launchers/utils/formateMinimapaBody';
import { useLaunchers } from '@modules/launchers/context/LaunchersContext';
import { getTrailId } from '@modules/launchers/utils/getTrail';

import { LocationStudent } from '@shared/components/LocationStudent';

import { uuid } from 'uuidv4';

import { Container } from './styles';
import useAuth from '@shared/store/auth/hook';

export interface IStudentCardLauncherLocation {
  handleOnClick?: (value: any) => void;
  studentData: IStudentWithGrades;
  colorVariation?: number;
  initialColor?: string;
  blocksAndSkills: any;
  finalColor?: string;
  isOpen?: boolean;
  setSkillsAndQuestions?: (obj: any) => void;
  handleClickOpenTrail: () => void;
}

const StudentCardLauncherLocation: React.FC<IStudentCardLauncherLocation> = ({
  handleOnClick,
  blocksAndSkills,
  setSkillsAndQuestions,
  handleClickOpenTrail,
  studentData,
  isOpen,
}) => {
  const { signedUser } = useAuth();
  const {trail } = useParams<{ id: string; trail: string }>();

  const [heightState, setHeightState] = useState<string>('0px');
  const { trailId, selectedStudentsMapaRef, currentClass } = useLaunchers();

  const lmsUserId = Number(signedUser?.lmsUser.id);
  const handleOnClickCheckBox = useCallback(
    (blockSkills) => {
      const skillList: { id: number; questions: number }[] = [];
      blockSkills.forEach((block) => {
        block.skills.forEach((skill: any) => {
          if (skill.id === -1) return;
          const skillToTest = { id: Number(skill?.id), questions: 2 };
          skillList.push(skillToTest);
        });
      });
      setSkillsAndQuestions && setSkillsAndQuestions(skillList);
      const studentFound = selectedStudentsMapaRef.current.applications.find(
        (student) => Number(student.lms_student_id) === Number(studentData.student.id)
      );

      if (!!studentFound) {
        selectedStudentsMapaRef.current.applications.map((st) => {
          if (Number(st.lms_student_id) === Number(studentData.student.id)) {
            st.state.miniMapaData = { trailIds: [getTrailId(trail)], miniMapaSkillsAndQuestions: skillList };
          }
          return st;
        });
      }

      if (!studentFound) {
        if (!trailId || !currentClass) return;
        const studentWithMiniMapa = formateMinimapaBody(lmsUserId, studentData.student, +trailId, skillList, currentClass);

        selectedStudentsMapaRef.current.applications.push(studentWithMiniMapa);
      }
    },
    [currentClass, lmsUserId, selectedStudentsMapaRef, setSkillsAndQuestions, studentData.student, trail, trailId]
  );

  useEffect(() => {
    if (isOpen) {
      setHeightState(`max-content`);
    } else {
      setHeightState('0px');
    }
  }, [isOpen]);

  useEffect(() => {
    const transformaArray = (blocksAndSkills) => {
      const sortValuesAsc = JSON.parse(JSON.stringify(blocksAndSkills))?.blocks.sort((a, b) => {
        if (a.skills.length < b.skills.length) return 1;
        if (a.skills.length > b.skills.length) return -1;
        return 0;
      });

      const maxLengthOfArray = sortValuesAsc[0].skills.length;

      blocksAndSkills?.blocks?.forEach((el) => {
        while (el.skills.length < maxLengthOfArray) {
          el.skills.push({ id: -1, status: 'undef' });
        }
      });
    };

    !!blocksAndSkills && transformaArray(blocksAndSkills);
  }, [blocksAndSkills]);

  const ZDPBlockIndex = blocksAndSkills?.zdp_block?.index;
  const metaBlockIndex = blocksAndSkills?.meta_block.index;

  return (
    <Container heightState={heightState} onClick={handleOnClick}>
      {!!blocksAndSkills && (
        <LocationStudent
          isOpen={isOpen}
          showLabel={false}
          groupMarkers={[
            {
              id: uuid(),
              color: '#0097B1',
              isFixed: true,
              gridIndex: ZDPBlockIndex ? ZDPBlockIndex : 0,
              markers: [{ name: 'M.A.P.A' }],
            },
            {
              id: uuid(),
              color: '#F47920',
              isFixed: true,
              isMain: true,
              gridIndex: metaBlockIndex,
              markers: [{ name: 'Objetivo Pedagógico' }, { name: 'miniM.A.P.A' }],
            },
          ]}
          trail={{
            name: 'DESBRAVADOR',
            blocks: blocksAndSkills.blocks,
            skill_descriptions: blocksAndSkills.skill_descriptions,
            meta_block: blocksAndSkills.meta_block,
          }}
          handleSelectChange={(e) => handleOnClickCheckBox(e)}
          canSelect
        />
      )}
    </Container>
  );
};
//export const MemoStudentCardLauncherLocation = React.memo(StudentCardLauncherLocation);
export default StudentCardLauncherLocation;
