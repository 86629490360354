import ITag from "@modules/tags/interfaces/ITag";
import { ISelectOption } from "react-alicerce-components";

export const defaultActivityType = (type?: ITag, types?: ITag[]): ISelectOption[] => {
  if(!types) return [];
  
  return types.map(t => ({
    value: t,
    name: t.name,
    selected: Number(t.id) === Number(type?.id)
  } as ISelectOption))
};
