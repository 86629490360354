import React, { Fragment, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, useToast } from 'react-alicerce-components';

import AllActions from '@shared/store/allactions';

import SignInWithTokenService from '@modules/home/services/SignInWithTokenService';

import { useNavigate, useLocation } from 'react-router';
import GetLoggedLmsUserService from '@modules/home/services/GetLoggedLmsUserService';
import { STORAGE_KEY } from '@shared/components/App';
import LocalStorageService from '@shared/services/LocalStorageService';
import { appendReadOffToLogout, redirectToAuth } from '@shared/utils/authRedirect';

const LoginCallback: React.FC = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;

  const urlCodeParam = useMemo(() => {
    return new URLSearchParams(search).get('code');
  }, [search]);

  const handleLogin = useCallback(async () => {
    dispatch({ type: AllActions.global.SET_LOADING, payload: true });

    try {
      if (!urlCodeParam)
        throw new Error('Código de Authorização(code) não foi encontrado no Search Params da URL.', { cause: { sessionAction: 'keep' } });

      const signInRes = await SignInWithTokenService.execute(urlCodeParam);
      if (!signInRes.user) throw new Error('Public User não foi encontrado na resposta do ExchangeToken.', { cause: { sessionAction: 'keep' } });

      const lmsUser = await new GetLoggedLmsUserService(signInRes.user.token).execute();
      if (!lmsUser) throw new Error('O Public User não possui um usuário LMS.', { cause: { sessionAction: 'keep' } });

      dispatch({ type: AllActions.auth.SET_SIGNED_USER, payload: { ...signInRes.user, lmsUser: lmsUser.lmsUser } });

      navigate('/');
    } catch (error: any) {
      addToast({ status: 'danger', title: 'Login', description: error.message });
      const sessionAction = (error.cause.sessionAction || 'keep') as 'keep' | 'remove';
      setTimeout(() => {
        LocalStorageService.remove(STORAGE_KEY);
        appendReadOffToLogout(sessionAction);
        redirectToAuth();
      }, 3000);
    }

    dispatch({ type: AllActions.global.SET_LOADING, payload: false });
  }, [addToast, dispatch, navigate, urlCodeParam]);

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  return (
    <Fragment>
      <Spinner />
    </Fragment>
  );
};

export default LoginCallback;
