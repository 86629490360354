import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';

import { objectToQuery } from '@shared/utils/objectToQuery';
import { IStudent } from '@modules/students/interfaces/IStudent';
import IListOptions from '@shared/interfaces/IListOptions';

export interface IListStudentClassWithApplicationReponse extends IPaginatedResponse {
  items: IStudentApplication[];
}

export interface IStudentApplication {
  student: IStudent;
  applications: any[];
}

export interface IListStudentFromClassWithApplicationsOptions extends IListOptions {
  status?: string[];
  trail_id?: number[];
  application_type?: string;
}

class ListStudentFromClassWithApplicationService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    classId: string | number,
    query: string,
    options: IListStudentFromClassWithApplicationsOptions
  ): Promise<IListStudentClassWithApplicationReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      const res = await this.api.get(`/student/applications/${classId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListStudentFromClassWithApplicationService;
