import { css, styled } from 'react-alicerce-components';

export const LearningTagertFormContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
  `}

  .icon-container {
    margin-top: 10px;
    i {
      display: flex;
      justify-content: center;
      svg {
        width: 90px;
        height: 90px;
      }
    }
  }
`;

export const ButtonFooterContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    border-top: 1px solid #c5cee0;
    padding: 18px 16px;

    button {
      border: 2px solid #8d593b;
      border-radius: 16px;
    }
  `}
`;
