import React, { Fragment, useCallback, useState } from 'react';
import { Button, useToast } from 'react-alicerce-components';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import WorkGroupForm from '@modules/work-group/components/WorkGroupForm';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '@shared/components/ConfirmModal';
import Icon from 'react-eva-icons';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { IInputErrorsFormat, beforeSubmitForm } from '@shared/utils/beforeSubmitForm';
import { useWorkGroup } from 'modules/work-group/context/WorkGroupContext';
import { IFormattedWorkGroupBody } from 'modules/work-group/interfaces/IFormattedWorkGroupBody';
import { formatWorkGroup } from 'modules/work-group/utils/formatWorkGroup';
import { workGroupSchema } from 'modules/work-group/utils/workGroupSchema';
import CreateWorkGroupService from 'modules/work-group/services/CreateWorkGroupService';

const WorkGroupCreate: React.FC = () => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const navigate = useNavigate();
  const { workGroupCurrent } = useWorkGroup();
  const [formattedWorkGroupBody, setFormattedWorkGroupBody] = useState<IFormattedWorkGroupBody>();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const { classId } = useParams<{ classId: string }>();

  const canCreateWorkGroup = useCallback(async () => {
    if (!classId) return;
    const wgCurrent = workGroupCurrent ? workGroupCurrent : {};
    const formatedBody = formatWorkGroup({ ...wgCurrent, class_id: classId });
    console.log(formatedBody);
    let formErros = await beforeSubmitForm(formatedBody, workGroupSchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    setShowCreateModal(true);
    setFormattedWorkGroupBody(formatedBody);
  }, [classId, workGroupCurrent]);

  const createWorkGroup = useCallback(async () => {
    if (!formattedWorkGroupBody) return;
    setShowCreateModal(false);
    setIsLoading(true);
    const res = await new CreateWorkGroupService(signedUser?.token, newCancelToken()).execute(formattedWorkGroupBody);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Grupo de Trabalho',
        description: 'Error ao Criar Grupo De Trabalho.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Grupo de Trabalho',
      description: 'Grupo De Trabalho Criado com Sucesso.',
    });
    navigate(`/grupo-de-trabalho/turma/${classId}`);
  }, [addToast, classId, formattedWorkGroupBody, navigate, newCancelToken, setIsLoading, signedUser]);

  return (
    <Fragment>
      <HeaderDirectory title="Grupo de Trabalho" backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)" />
      <DirectoryContainer footer={true}>
        <WorkGroupForm
          pathEnvolvedBlocks={`/grupo-de-trabalho/selecionar/blocos/turma/${classId}`}
          errors={errors}
          pathEnvolvedStudents={`/grupo-de-trabalho/selecionar/alunos/turma/${classId}`}
        />
        <PageFooter>
          <Button transform="none" outline shaded onClick={() => navigate(-1)} className="button-cancel-blocks">
            Cancelar
          </Button>
          <Button transform="none" status="quaternary" shaded onClick={() => canCreateWorkGroup()} className="mochileiro-color">
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar Grupo de Trabalho?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={createWorkGroup}
      />
    </Fragment>
  );
};

export { WorkGroupCreate };
