import { HtmlHTMLAttributes } from 'react';
import { styled } from 'react-alicerce-components';

type IContainerProps = HtmlHTMLAttributes<HTMLDivElement> & {
  footer: boolean;
  pageFooterHeight?: number;
  isScrollBlocked?: boolean;
};

export const Container = styled.div<IContainerProps>`
  padding: 40px 22px;

  height: calc(100vh - ${(p) => (p.footer ? '180px' : '90px')});
  width: 100%;
  overflow-y: ${({ isScrollBlocked }) => (isScrollBlocked ? 'hidden' : 'auto')};
  overflow-x: hidden;

  > div .custom-card > div {
    margin-left: 0px;
    margin-right: 0px;
  }

  textarea {
    cursor: auto;
  }

  input:checked + div {
    border-color: transparent;
    svg {
      color: #ffffff;
    }
  }

  .mochileiro-color {
    border-color: #85abf8;
    background: #85abf8;
  }

  .container-page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    button {
      border-width: 3px;
      border-radius: 5px;
    }
  }
`;
