import { styled } from "react-alicerce-components";

interface IContainerProps {
    bgColor?: string;
    width?: string;
    height?: string;
    iconSize?: string;
}
export const Container= styled.div<IContainerProps>`
    button {
        border-color: ${({ bgColor }) => bgColor};
        background: ${({ bgColor }) => bgColor};
        border-radius: 12px;
        
        span {
            display: grid;
            flex-direction: column;
            justify-content: space-around;
            align-items: space-around;
            width: ${({ width }) => width} !important;
            height: ${({ height }) => height}  !important;

            i svg {
                width: ${({ iconSize }) => iconSize};
                height: ${({ iconSize }) => iconSize};
            }
        }

        &:hover {
            background-color: ${({ bgColor }) => bgColor};
            border-color: ${({ bgColor }) => bgColor};
            filter: brightness(90%);
        }
    }
`

export const Content = styled.p`
    font-size: 12px;
    margin: 0;
    font-weight: 800;
    font-family: var(--secondary-font);
    text-transform: capitalize;
`