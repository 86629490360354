import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 2rem;
  padding-bottom: 2rem;

  th[role='presentation'] {
    display: none;
  }

  .save-container {
    position: absolute;
    top: 0;
    right: 20px;

    svg {
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
  }

  .save-container.disabled {
    opacity: 0.2;
  }

  .fc {
    .fc-col-header,
    .fc-license-message,
    .fc-header-toolbar {
      display: none !important;
    }

    .fc-timegrid-col-events {
      margin-right: 1px !important;
    }

    td.fc-timegrid-slot {
      background: #fff !important;
    }
  }

  .on-remove-event {
    background: #e4e9f2;
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #93a1b7;
    font-size: 1rem;
    font-weight: 900;
    z-index: 5;
    cursor: pointer;
  }
`;