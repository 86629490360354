import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { BsCheckLg } from 'react-icons/bs';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import { ITask } from '@modules/tasks/interfaces/ITask';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';

import TaskCard from '@modules/tasks/components/TaskCard';
import CustomForm from '@shared/components/CustomForm';
import ConfirmModal from '@shared/components/ConfirmModal';

import ConcludeTaskService from '@modules/tasks/services/ConcludeTaskService';
import { ITaskMessageDateKey } from '..';

interface ITasksListProps {
  tasks: ITask[];
  canConclude?: boolean;
  handleOnConclude?: () => any;
  handleOnClickTask?: (task: ITask) => void;
  dateBgColor?: string;
  dateKey: ITaskMessageDateKey;
  prefixDate: string;
}

const TasksList: React.FC<ITasksListProps> = ({
  dateKey,
  prefixDate,
  tasks,
  dateBgColor = '#AD83DE',
  canConclude = false,
  handleOnConclude,
  handleOnClickTask,
}) => {
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const [showModalConclude, setShowModalConclude] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);

  const handleConfirm = useCallback(async () => {
    if (!signedUser || !selectedTask?.id) return;

    setShowModalConclude(false);
    setIsLoading(true);
    const res = await new ConcludeTaskService(signedUser?.token).execute(selectedTask?.id);
    setIsLoading(false);

    addToast({
      status: !res ? 'danger' : 'success',
      title: 'Tarefas',
      description: `${!res ? 'Erro' : 'Sucesso'} ao Concluir Tarefa.`,
    });

    if (handleOnConclude) {
      handleOnConclude();
    }
  }, [addToast, handleOnConclude, selectedTask, setIsLoading, signedUser]);

  const handleChangeCheckbox = useCallback(async (task: ITask, event) => {
    event.target.checked = false;
    setSelectedTask(task);
    setShowModalConclude(true);
  }, []);

  return (
    <CustomForm
      style={{
        padding: '.5rem',
        minHeight: tasks.length > 0 ? '30vh' : '',
      }}
    >
      <Form onSubmit={() => { }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        {tasks.map((task, i) => (
          <TaskCard
            key={`${task}-${task.id}-${i}`}
            hideIconUncheck
            icon={BsCheckLg}
            task={task}
            status="quaternary"
            canConclude={canConclude}
            handleOnClick={() => handleOnClickTask && handleOnClickTask(task)}
            checkbox={{
              name: 'tasks',
              id: `task-${i}`,
              onChange: (event) => handleChangeCheckbox(task, event),
            }}
            prefixDate={prefixDate}
            dateBgColor={dateBgColor}
            dateKey={dateKey}
          />
        ))}
      </Form>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        title="Deseja Concluir essa Tarefa?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleConfirm}
        showModal={showModalConclude}
        closeCallback={() => setShowModalConclude(!showModalConclude)}
        status="quaternary"
      />
    </CustomForm>
  );
};

export default TasksList;
