import React, { useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useWorkedHour } from '../../context/WorkedHourContext';

const WorkedHourAdvancedFilterTagRelation: React.FC = () => {
  const navigate = useNavigate();
  const { workedHoursFilter, workedHourCurrent } = useWorkedHour();

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      const tagToAdd = tags[0];
      workedHoursFilter.current.project = tagToAdd;
      workedHourCurrent.project = tagToAdd;
    },
    [workedHoursFilter, workedHourCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={1}
      useSearch
      selectedTags={workedHoursFilter.current.project ? [workedHoursFilter.current.project] : undefined}
      buttonCallback={() => navigate(-1)}
      buttonText="Adicionar Tag"
      tag_type="project"
    />
  );
};

export { WorkedHourAdvancedFilterTagRelation };
