import React, { useCallback, useEffect, useState } from 'react';
import DynamicTable, { IDynamicTableColumnsGroup } from '@shared/components/DynamicTable';
import { Divider, Grid, Radio, Spinner, Button } from 'react-alicerce-components';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import CurrentSprintAndPeriod from '@modules/projects/components/CurrentSprintAndPeriod';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import GetCurrentPeriodService from '@modules/periods/services/GetCurrentPeriodService';
import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { HeadlightType } from '@modules/projects/schemas/Headlight/partials/HeadlightType';
import ListHeadlightCategoriesService from '@modules/projects/services/Headlight/ListHeadlightCategoriesService';
import ListProjectsHeadlightsByGroupService, { IProjectResponse } from '@modules/projects/services/Project/ListProjectsHeadlightsByGroupService';
import IProject from '@modules/projects/interfaces/IProject';
import PageFooter from '@shared/components/PageFooter';
import ConfirmModal from '@shared/components/ConfirmModal';
import Icon from 'react-eva-icons';
import { translateString } from '@shared/utils/translateString';
import { useToast } from 'react-alicerce-components';
import ApproveHeadlightsService from '@modules/projects/services/Headlight/ApproveHeadlightsService';

interface ConsolidateHeadlightsForApprovalProps { }
interface IFormattedProjectHeadlights {
    id?: string | number;
    projects?: string;
    headlightId?: string | number;
    type?: string;
    frequênciaBgColor?: string;
    npsBgColor?: string;
    ocupaçãoBgColor?: string;
    reserva_de_instrutoresBgColor?: string;
    aprendizagemBgColor?: string;
    regulaçãoBgColor?: string;
    governançaBgColor?: string;
    instrutoresBgColor?: string;
    localBgColor?: string;
    alunosBgColor?: string;
    conteúdoBgColor?: string;
}

type IProjectHeadlightsResponseFormatted = Omit<IProjectResponse, 'items'> & {
    items: IFormattedProjectHeadlights[];
}

export const formatProjectHeadlights = (data: IProject): IFormattedProjectHeadlights => {

    const statusConfig = {
        success: { bgColor: '#00D68F' },
        warning: { bgColor: '#FFAA00' },
        danger: { bgColor: '#FF3D71' },
        basic: { bgColor: '#C5CEE0' }
    };

    const headlightsData = data?.headlights && data?.headlights[0]?.data;

    const bgColorByCategory = (category: string) => {
        if (headlightsData) {
            const categoryData = headlightsData.find(item => item.category === category);
            if (categoryData) {
                const status = categoryData.status;
                return statusConfig[status]?.bgColor || undefined;
            }
        }
        return undefined;
    };

    return {
        id: data?.id,
        projects: data?.name,
        headlightId: data?.headlights && data?.headlights[0]?.id,
        frequênciaBgColor: bgColorByCategory("Frequência"),
        npsBgColor: bgColorByCategory("NPS"),
        ocupaçãoBgColor: bgColorByCategory("Ocupação"),
        reserva_de_instrutoresBgColor: bgColorByCategory("Reserva de Instrutores"),
        aprendizagemBgColor: bgColorByCategory("Aprendizagem"),
        regulaçãoBgColor: bgColorByCategory("Regulação"),
        governançaBgColor: bgColorByCategory("Governança"),
        instrutoresBgColor: bgColorByCategory("Instrutores"),
        localBgColor: bgColorByCategory("Local"),
        alunosBgColor: bgColorByCategory("Alunos"),
        conteúdoBgColor: bgColorByCategory("Conteúdo"),
    };
}

const ConsolidateHeadlightsForApproval: React.FC<ConsolidateHeadlightsForApprovalProps> = () => {
    const { isLoading, setIsLoading } = useGlobal();
    const { signedUser } = useAuth();
    const { currentSprint, currentPeriod, setCurrentSprint, setCurrentPeriod, setCurrentHeadlight } = useHeadlights();
    const { id: groupId } = useParams<{ id: string }>();
    const [headlightType, setHeadlightType] = useState<(HeadlightType)>('execution');
    const [columns, setColumns] = useState<IDynamicTableColumnsGroup[]>([
        {
            columns: [
                { name: 'Projetos', dataname: 'projects' }
            ],
            fixed: true
        }
    ]);
    const [projectHeadlights, setProjectHeadlights] = useState<IProjectHeadlightsResponseFormatted>();
    const navigate = useNavigate();
    const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
    const { addToast } = useToast();
    const [headlightIdsForApproval, setHeadlightIdsForApproval] = useState<number[]>([]);

    const getCurrentSprintAndPeriod = useCallback(async (scheduleId) => {
        if (!signedUser) return;

        setIsLoading(true);

        if (scheduleId) {
            const sprint = await new GetCurrentSprintService(signedUser.token).execute({ schedule_id: Number(scheduleId) });
            if (!sprint) return;
            setCurrentSprint(sprint);
            setCurrentHeadlight((oldState: IHeadlight) => {
                return { ...oldState, sprint_id: sprint.id }
            })

            const period = await new GetCurrentPeriodService(signedUser.token).execute(Number(sprint.id));
            if (!period) return;
            setCurrentPeriod(period);
            setCurrentHeadlight((oldState: IHeadlight) => {
                return { ...oldState, period_id: period.id }
            })
        }
        setIsLoading(false);
    }, [setIsLoading, signedUser, setCurrentSprint, setCurrentHeadlight, setCurrentPeriod]);

    useEffect(() => {
        getCurrentSprintAndPeriod(1)
    }, [getCurrentSprintAndPeriod]);

    const listHeadlightCategories = useCallback(async (headlightType) => {
        if (!signedUser) return;

        setIsLoading(true);

        const headlightCategories = await new ListHeadlightCategoriesService(signedUser.token).execute({ type: headlightType });
        if (!headlightCategories) return;

        let columnsBuild = [
            {
                columns: [
                    { name: 'Projetos', dataname: 'projects' }
                ],
                fixed: true
            }
        ]

        headlightCategories.forEach((c) => {
            columnsBuild[0].columns.push({
                name: c.category,
                dataname: c.category.toLowerCase().replace(/\s+/g, '_')
            });
        });

        setColumns(columnsBuild);
        setIsLoading(false);
    }, [setIsLoading, signedUser, setColumns]);

    useEffect(() => {
        listHeadlightCategories(headlightType)
    }, [headlightType, listHeadlightCategories])

    const redirectToHeadlightView = (data: IFormattedProjectHeadlights) => {
        if (data.headlightId) {
            navigate(`/projetos/farol/${data.headlightId}`)
        } else {
            addToast({
                status: 'danger',
                title: 'Visualizar Farol',
                description: 'Farol não lançado, não é possível direcionar para visualização.',
            });
        }
    }

    const listProjectsHeadlights = useCallback(async (groupId, sprintId, periodId, headlightType) => {
        if (!signedUser) return;

        setIsLoading(true);

        const projectHeadlights = await new ListProjectsHeadlightsByGroupService(signedUser.token).execute(groupId, { period_id: Number(periodId), sprint_id: Number(sprintId), type: headlightType });
        if (!projectHeadlights) return;

        setProjectHeadlights(() => ({
            ...projectHeadlights,
            items: projectHeadlights?.items.map(formatProjectHeadlights)
        }) as IProjectHeadlightsResponseFormatted)

        setIsLoading(false);

    }, [setIsLoading, signedUser]);

    useEffect(() => {
        currentSprint && currentPeriod && listProjectsHeadlights(groupId, currentSprint?.id, currentPeriod?.id, headlightType)
    }, [headlightType, groupId, currentSprint, currentPeriod, listProjectsHeadlights])

    const handleApproveHeadlights = useCallback(() => {
        const headlightIds = projectHeadlights?.items.reduce((acc, p) => {
            if (p.headlightId) {
                acc.push(Number(p.headlightId));
            }
            return acc;
        }, [] as (number)[]) || [];
        if (headlightIds?.length > 0) {
            setHeadlightIdsForApproval(headlightIds);
            setShowApprovalModal(true);
        } else {
            addToast({
                status: 'danger',
                title: 'Aprovar Faróis',
                description: 'Nenhum farol lançado para aprovação.',
            });
        }
    }, [projectHeadlights, addToast]);

    const approveHeadlights = useCallback(async () => {
        if (!signedUser || !headlightIdsForApproval) return;
        setIsLoading(true);
        const res = await new ApproveHeadlightsService(signedUser.token).execute(headlightIdsForApproval);
        setIsLoading(false);
        if (res) {
            addToast({
                status: 'success',
                title: 'Aprovar Faróis',
                description: 'Faróis aprovados com sucesso.',
            });
            setShowApprovalModal(false);
        } else {
            addToast({
                status: 'danger',
                title: 'Aprovar Faróis',
                description: 'Não foi possível aprovar os faróis.',
            });
        }
    }, [setIsLoading, signedUser, addToast, headlightIdsForApproval]);

    return (
        <ProjectDefaultStylesContainer>
            {isLoading && <Spinner />}
            <HeaderDirectory
                title={'Aprovar Faróis'}
                onClickReturn={() => navigate('/projetos/aprovar-farois')}
                menuKebab={{ tooltip: { view: 'HeadlightsApproval' }, menuBackHome: true }}
            />
            <DirectoryContainer footer={false}>
                <CurrentSprintAndPeriod />
                <Grid row justifyContentStart>
                    <Grid column={6}>
                        <Radio
                            label='F. de Execução'
                            placeholder='F. de Execução'
                            round
                            name='headlight_status'
                            id='execution'
                            status='primary'
                            onChange={() => setHeadlightType('execution')}
                            defaultChecked={true} />
                    </Grid>
                    <Grid column={6}>
                        <Radio
                            label='F. de Implantação'
                            placeholder='F. de Implantação'
                            round
                            name='headlight_status'
                            id='deployment'
                            onChange={() => setHeadlightType('deployment')}
                            status='primary' />
                    </Grid>
                </Grid>
                <Divider width='100%' />
                <DynamicTable
                    columnsGroup={columns}
                    data={projectHeadlights?.items || []}
                    canSelect
                    handleClickRow={redirectToHeadlightView}
                />
                <PageFooter>

                    <Button
                        transform="none"
                        shaded
                        noBorder
                        onClick={handleApproveHeadlights}
                    >
                        Aprovar Faróis
                    </Button>
                </PageFooter>
            </DirectoryContainer>
            <ConfirmModal
                start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
                showModal={showApprovalModal}
                closeCallback={() => setShowApprovalModal(!showApprovalModal)}
                title={`Deseja aprovar todos os faróis lançados para os projetos de ${translateString(headlightType)} deste grupo?`}
                textConfirmButton="Confirmar"
                textCloseButton="Cancelar"
                confirmCallBack={approveHeadlights}
                subTitle='Ao confirmar, serão aprovados todos os faróis lançados para a sprint e o período atuais. Caso seja necessário editar algum farol, faça-o antes da aprovação. Se algum projeto não teve farol lançado, este só poderá ser aprovado depois do lançamento. Alterne entre as abas de execução e implantação para aprovação dos faróis de ambos os tipos de projeto.'
            />
        </ProjectDefaultStylesContainer>
    );
}

export default ConsolidateHeadlightsForApproval;