import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Header = styled.div`
  background: #6749ba;
  width: 100%;
  height: 40px;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-size: 14px;
  color: #fff;
  font-weight: 800;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 99;

  .title {
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 34px;
  }

  .date {
    margin-right: 15px;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const Grade = styled.div`
  background: #f7f9fc;
  border: 2px solid #e4e9f2;
  border-radius: 5px;
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 30px;
  position: relative;

  span {
    font-size: 14px;
    font-weight: 800;
    color: #2e3a59;
    margin-right: 4px;
  }

  i {
    line-height: 1;
    margin-top: 4px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
