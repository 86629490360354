import { styled } from 'react-alicerce-components';

export const Container = styled.div`
   .main-navbar {
    height: 130px;
  }

  [role='tabpanel'] {
    padding: 20px;
    overflow-x: hidden;
  }

  .divider {
    margin-top: 0;
  }

  .accordion-header {
    justify-content: flex-start;
    background-color: #e4e9f2;
  }

  .accordion-content {
    padding-top: 1rem;
  }
`;
