import React, { useCallback, useEffect } from 'react';
import { Button, Spinner, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import GetProgramService from '@modules/activities-library/services/GetProgramService';
import { ProgramInfoContainer } from './styles';
import { ProgramFormInfo } from '@modules/activities-library/components/ProgramFormInfo';
import { IActivityLibrary } from '../../../activities-library/interfaces/IActivityLibrary';
import { useScheduleGrid } from '../../context/ScheduleGridContext';

const ClassPlannerProgramInfo: React.FC = () => {
  const { programCurrent, setProgramCurrent, clearProgramContextData } = useProgram();
  const { programId, classId, dailyClassId } = useParams<{ dailyClassId: string; programId: string; classId: string }>();
  const { plannedActivities } = useScheduleGrid();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { isLoading, setIsLoading } = useGlobal();

  const getProgram = useCallback(async () => {
    if (!programId) return;
    setIsLoading(true);
    const res = await new GetProgramService(signedUser?.token).execute(programId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Recuperar Programa.',
      });
      return navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`);
    }

    if (!isMounted()) return;
    setProgramCurrent(res);
  }, [addToast, classId, dailyClassId, isMounted, navigate, programId, setIsLoading, setProgramCurrent, signedUser]);

  const handleOnClickActivityProgramCard = useCallback(
    (activityCard: IActivityLibrary) => {
      navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${activityCard.id}/informacoes`);
    },
    [classId, dailyClassId, navigate]
  );

  useEffect(() => {
    getProgram();
  }, [clearProgramContextData, getProgram]);

  useEffect(() => {
    if (!plannedActivities) return navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`);
  }, [classId, dailyClassId, navigate, plannedActivities]);

  return (
    <ProgramInfoContainer>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory
        title="Programa"
        pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        {programId === programCurrent.id && (
          <ProgramFormInfo handleClickOnActivityCard={(activityCard) => handleOnClickActivityProgramCard(activityCard)} />
        )}
        <PageFooter>
          <Button
            transform="none"
            noBorder
            shaded
            onClick={() => navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/programa/${programCurrent.id}/clonar`)}
          >
            Clonar Programa
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </ProgramInfoContainer>
  );
};

export { ClassPlannerProgramInfo };
