import React from 'react';
import GroupListComponent from '@modules/groups/components/GroupsList';

const GroupDirectory: React.FC = () => {
  return (
    <GroupListComponent headerTitle="Diretório de Grupos" btnText="Criar Grupo" pathRedirect="/diretorio/grupos/criar" pathToReturn="/meus-grupos" />
  );
};

export default GroupDirectory;
