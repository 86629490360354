import { IStudent } from '@modules/students/interfaces/IStudent';
import ITag from '@modules/tags/interfaces/ITag';
import CustomCard from '@shared/components/CustomCard';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { Label, Pill } from 'react-alicerce-components';
import StudentCardDirectoryContent from '../StudentCardDirectoryContent';
import { Container, PillWrapper, StudentCardContainerFooter, StudentCardContainerFooterStatus } from './styles';
import { findConstraintName } from '@modules/students/utils/constraintName';
import { STUDENT_STATUS_CONSTRAINTS } from '@modules/students/utils/validData/validStatus';

interface IStudentCard {
  student: IStudent;
  handleOnClick?: (student: IStudent) => void;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  studentsSelected?: IStudent[];
  canSelect?: boolean;
  customStyle?: CSSProperties;
  studentSelected?: boolean;
  customForm?: JSX.Element;
  isDisabled?: JSX.Element | boolean;
}

interface IStudentTags {
  id: string;
  student_id: string;
  tag_id: string;
  type: string;
  tag: ITag;
}

const StudentCard: React.FC<IStudentCard> = ({ customStyle, studentsSelected, canSelect, canDeleteCard, student, handleOnClick, inputCard, studentSelected = false, customForm, isDisabled }) => {
  const isSelected = useMemo(() => {
    return studentsSelected?.find((p) => p.id === student.id) && canSelect ? true : false;
  }, [canSelect, student.id, studentsSelected]);

  const returnPill = useCallback((studentTags: IStudentTags[]) => {
    const tagsWithName = studentTags.filter((tag) => tag.tag);
    if (tagsWithName.length <= 2) {
      return tagsWithName.map((tagItem) => {
        if (tagItem.tag)
          return (
            <PillWrapper key={tagItem.id}>
              <Pill style={{ fontWeight: '600' }} color="#222B45" size="small" marginLeft="4px" className="tag" backgroundColor={`#FFC94D`}>
                {tagItem.tag.name}
              </Pill>
            </PillWrapper>
          );

        return undefined;
      });
    }

    return (
      <>
        <PillWrapper>
          <Pill color="#222B45" size="small" marginLeft="4px" className="tag" key={tagsWithName[0].id} backgroundColor={`#FFC94D`}>
            {tagsWithName[0].tag.name}
          </Pill>
        </PillWrapper>
        <PillWrapper>
          <Pill color="#222B45" size="small" marginLeft="4px" className="tag" key={tagsWithName[1].id} backgroundColor={`#FFC94D`}>
            {tagsWithName[1].tag.name}
          </Pill>
        </PillWrapper>
        <PillWrapper>
          <Pill color="#222B45" size="small" marginLeft="4px" className="tag" key={tagsWithName[1].id} backgroundColor={`#FFC94D`}>
            {`+${tagsWithName.length - 2}`}
          </Pill>
        </PillWrapper>
      </>
    );
  }, []);

  const getStatus = (status): { status: string; color: string } =>
  ({
    active: { status: 'Ativo', color: ' #00D68F' },
    adaptation: { status: 'Em Adaptação', color: '#FFD74E' },
    experimentation: { status: 'Em Experimentação', color: '#FFD74E' },
    not_enroll_a_adapt: {
      status: 'Não matriculado após adaptação',
      color: '#FF7773 ',
    },
    not_enroll_a_exp: {
      status: 'Não matriculado após experimentação',
      color: '#FF7773 ',
    },
    paused: {
      status: 'Pausado',
      color: '#FF7773',
    },
    quitter: { status: 'Desistente', color: '#8F9BB3' },
  }[status] || { status: 'Desconhecido', color: '#8F9BB3' });

  return (
    <Container onClick={() => handleOnClick && handleOnClick(student)} selected={isSelected || studentSelected } isDisabled={isDisabled}>
      <CustomCard
        canDeleteCard={canDeleteCard}
        inputCard={inputCard}
        key={student.id}
        status="secondary"
        statusFooter="secondary"
        header={<span>{student.name}</span>}
        customForm={!isDisabled && customForm}
        footer={
          <>
            <StudentCardContainerFooterStatus>
              <Label color="#fff">Status:</Label>
              <Pill color="#222B45" size="small" marginLeft="10px" className="tag" backgroundColor={student?.status && getStatus(student?.status)?.color}>
                {findConstraintName(STUDENT_STATUS_CONSTRAINTS, (student.status)) || 'Desconhecido'}
              </Pill>
            </StudentCardContainerFooterStatus>
            {!!student.tags?.length && <StudentCardContainerFooter>{student.tags && returnPill(student.tags)}</StudentCardContainerFooter>}
          </>
        }

        customStyles={{
          border: 'none',
          ...customStyle,
        }}
      >
        <StudentCardDirectoryContent student={student} />
      </CustomCard>
    </Container>
  );
};

export default StudentCard;
