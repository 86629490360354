import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { IFormatedGroup } from '@modules/groups/interfaces/IFormatedGroup';

class EditGroupService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(groupId: string, groupBody: IFormatedGroup): Promise<IGroup | false> {
    try {
      const res = await this.api.put(`/group/${groupId}`, { ...groupBody });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditGroupService;
