import { styled } from 'react-alicerce-components';

export const SubscriptionsAdvancedFilterContainer = styled.div`
  label {
    font-family: var(--secondary-font);
    color: var(--black);
    text-transform: unset;
    letter-spacing: 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  .accordion-content {
    display: flex;
    flex-wrap: wrap;
  }
`;
