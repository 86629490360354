import React from 'react';
import { CSSProperties } from 'styled-components';
import { WrapperContentContainer } from './styles';

interface IWrapperContentProps {
  customStyle?: CSSProperties
}

const WrapperContent: React.FC<IWrapperContentProps> = ({ children, customStyle }) => {
  return <WrapperContentContainer style={{ ...customStyle }} className='container-launchers-wrapper'>{children}</WrapperContentContainer>;
};

export default WrapperContent;
