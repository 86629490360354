import React, { createContext, useContext, useState } from 'react';
import { IStudentApplication } from '@modules/applications/services/ListStudentApplicationFromClassService';
import { IApplication } from '@modules/applications/interfaces/IApplication';

export type IApplicationsContextData = {
  studentApplicationsCurrent: IStudentApplication;
  applicationCurrent: IApplication;
  setStudentApplicationsCurrent: (value: any) => void;
  setApplicationCurrent: (value: any) => void;
};

const ApplicationsContext = createContext<IApplicationsContextData>({} as IApplicationsContextData);

const ApplicationsProvider: React.FC = ({ children }) => {
  const [studentApplicationsCurrent, setStudentApplicationsCurrent] = useState<IStudentApplication>({} as IStudentApplication);
  const [applicationCurrent, setApplicationCurrent] = useState<IApplication>({} as IApplication);

  return (
    <ApplicationsContext.Provider
      value={{
        studentApplicationsCurrent,
        setStudentApplicationsCurrent,
        applicationCurrent,
        setApplicationCurrent
      }}
    >
      {children}
    </ApplicationsContext.Provider>
  );
};

function useApplications() {
  const context = useContext(ApplicationsContext);

  if (!context) {
    throw new Error('useApplications must be used within a ApplicationsProvider');
  }

  return context;
}

export { ApplicationsProvider, useApplications };
