import { ISelectOption } from 'react-alicerce-components';

// constrainsts de tipo de escola
export const STUDENT_PUBLIC_SCHOOL = { name: 'Publica', value: 'public' }; // Publica
export const STUDENT_PRIVATE_SCHOOL = { name: 'Particular', value: 'private' }; // Particular
export const STUDENT_PRIVATE_FULL_SCHOLARSHIP_SCHOOL = { name: 'Particular com bolsa integral', value: 'full_scholarship' }; // Particular com bolsa integral
export const STUDENT_NOT_APPLICABLE_SCHOOL = { name: 'Não se Aplica', value: 'not_applicable' }; // Não se Aplica
export const STUDENT_NOT_COLLECTED_SCHOOL = { name: 'Não Coletado', value: 'not_collected' }; // Não Coletado (Padrao)

export const STUDENT_SCHOOL_TYPES_CONSTRAINTS = [
  STUDENT_PUBLIC_SCHOOL,
  STUDENT_PRIVATE_SCHOOL,
  STUDENT_PRIVATE_FULL_SCHOLARSHIP_SCHOOL,
  STUDENT_NOT_APPLICABLE_SCHOOL,
  STUDENT_NOT_COLLECTED_SCHOOL,
];

export const defaultShoolType = (studentSchoolType?: string | null): ISelectOption[] | undefined => {
  return STUDENT_SCHOOL_TYPES_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: studentSchoolType === foo.value };
  });
};

export const SCHOOL_TYPES = STUDENT_SCHOOL_TYPES_CONSTRAINTS.map((a) => a.value);
