import { styled } from 'react-alicerce-components';

export const WrapperContentContainer = styled.div`
  .custom-card > div {
    margin: var(--card-distance);
  }

  .input-error-message-container {
    width: 100%;
    text-align: right;
    display: inline-block;
  }

  .advanced-filter-structure-container {
    margin-top: 0;
    .input-container {
      margin-top: 0;
    }
  }

  .input-container {
    div {
      border-color: #c5cee0 !important;
    }
  }

  .pagination-container {
    margin: 0px 0px 20px 0px;
  }
`;
