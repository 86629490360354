import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .pagination-content-icon {
    background-color: #8f9bb3 !important;

    i svg {
      fill: #ffffff !important;
    }
  }

  h6 {
    text-align: center;
    margin: 1rem 0;
  }
`;
