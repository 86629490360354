export const PROJECT_JANITORIAL_TYPE_JANITORIAL_PARTNER_CONTRAINS = 'janitorial_partner' //Zelador Parceiro
export const PROJECT_JANITORIAL_TYPE_JANITORIAL_COOPERATIVE_CONTRAINS = 'janitorial_cooperative' //Zelador Cooperativa
export const PROJECT_JANITORIAL_TYPE_LEADER_JANITOR_CONTRAINS = 'leader_janitor' //Líder Zelador

export const PROJECT_JANITORIAL_TYPE_JANITORIAL_PARTNER = { name: 'Zelador Parceiro', value: PROJECT_JANITORIAL_TYPE_JANITORIAL_PARTNER_CONTRAINS };
export const PROJECT_JANITORIAL_TYPE_COOPERATIVE = { name: 'Zelador Cooperativa', value: PROJECT_JANITORIAL_TYPE_JANITORIAL_COOPERATIVE_CONTRAINS };
export const PROJECT_JANITORIAL_TYPE_LEADER_JANITOR = { name: 'Líder Zelador', value: PROJECT_JANITORIAL_TYPE_LEADER_JANITOR_CONTRAINS };

export const PROJECT_JANITORIAL_TYPE_CONSTRAINTS = [
    PROJECT_JANITORIAL_TYPE_JANITORIAL_PARTNER_CONTRAINS,
    PROJECT_JANITORIAL_TYPE_JANITORIAL_COOPERATIVE_CONTRAINS,
    PROJECT_JANITORIAL_TYPE_LEADER_JANITOR_CONTRAINS
];

export const PROJECT_JANITORIAL_TYPE_CONSTRAINTS_OPTIONS = [
    PROJECT_JANITORIAL_TYPE_JANITORIAL_PARTNER,
    PROJECT_JANITORIAL_TYPE_COOPERATIVE,
    PROJECT_JANITORIAL_TYPE_LEADER_JANITOR
];

export const TYPE_PROJECT_VALUES = PROJECT_JANITORIAL_TYPE_CONSTRAINTS_OPTIONS.map((c) => c.value);

export const defaultProjectJanitorialType = (janitorialType?: string | null) => PROJECT_JANITORIAL_TYPE_CONSTRAINTS_OPTIONS.map(i => ({ ...i, selected: janitorialType === i.value }))
