import React, { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
import { useSchedules } from '../../context/SchedulesContext';
import { useGlobal } from '../../../../shared/contexts/global/GlobalContext';

interface ILayoutProps {
  openTab?: number;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children }) => {
  const navigate = useNavigate();
  const { state } = useSchedules();
  const { isLoading } = useGlobal();

  const handleGoBackCallback = useCallback(() => {
    if (state && state.pathToReturn) return navigate(state.pathToReturn);
    return navigate(-1);
  }, [navigate, state]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) body.style.overflow = 'hidden';

    return () => {
      const body = document.querySelector('body');
      if (body) body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Container className="layout-container">
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Agenda"
        onClickReturn={handleGoBackCallback}
        backgroundHeader="#8F9BB3"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'ConsolidatedSchedule' },
        }}
      />
      <CustomTabs>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="AGENDA CONSOLIDADA" onClick={() => navigate('/agenda/consolidada', { state: state })} />
          <Tab title="AGENDAS LOCAIS" onClick={() => navigate('/agenda/locais', { state: state })} />
          <Tab title="AGENDAS EXTERNAS" onClick={() => navigate('/agenda/externas', { state: state })} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default Layout;
