import { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';

interface IFavoritedToggleResponse {
  program_id: string;
  favorited: boolean;
}

export class FavoriteToggleProgramsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(programId: string): Promise<IFavoritedToggleResponse | false> {
    try {
      const res = await this.api.patch(`/program/${programId}/favorited`);
      return res.data;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}
