import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .input-container {
    margin: 1.5rem 0 0 0;

    > div input::placeholder {
      font-weight: 500;
      color: #c5cee0;
    }
  }

  .pagination-content-icon {
    background-color: #ffba95 !important;

    i svg {
      fill: #fff !important;
    }
  }
  .search-bar {
    margin-bottom: 2rem;
  }

  .student-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .checkbox-invert {
      direction: rtl;
      display: flex;
      justify-content: flex-start;
      color: #2e3a59;
    }

    .invert-pill {
      direction: rtl;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  .custom-card > div {
    margin: 10px 0;
  }
`;
