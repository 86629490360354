import { styled } from 'react-alicerce-components';

export const ContainerPageFooter = styled.div<{ height?: string }>`
  display: flex;
  background: #f7f9fc;
  padding: 1.5rem 1rem;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  flex-wrap: wrap;
  height: ${(props) => (props.height ? props.height : '90px')};
  gap: 1rem;

  .mochileiro-color {
    border-color: #85abf8;
    background: #85abf8;
  }

  .button-cancel-activity,
  .button-cancel-blocks {
    border-color: #96b6f7;
    background: linear-gradient(180deg, #cddaf6 2.26%, #96b6f7 100%);
    color: #fff;
  }

  .button-program,
  .button-class-planner {
    background-color: #96b6f7;
    border-color: #96b6f7;
    :not(.button-gradient-outline) {
      &:hover {
        background-color: #96b6f7;
        border-color: #96b6f7;
      }
    }
  }

  button {
    min-width: 135px;
    height: 45px;
  }

  @media (max-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;
