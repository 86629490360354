import { IAttachment } from '../interfaces/IAttachment';

export const splitAttachmentFiles = (attFiles?: (File | IAttachment)[]) => {
  if (!attFiles) return { oldFiles: [], newFiles: [] };

  const oldFiles = attFiles.flatMap((af: any) => (af.url ? af : [])) as IAttachment[];
  const newFiles = attFiles.flatMap((af: any) => (af.url ? [] : af)) as File[];

  return { oldFiles, newFiles };
};
