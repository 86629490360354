import React from "react";
import piquito403 from "./piquito403.png";

import { Wrapper, Image, Text } from "./styles";

interface Error403Props {
  topMessage?: string;
  bottomMessage?: string;
}

const Error403: React.FC<Error403Props> = ({
  topMessage = "Oops...",
  bottomMessage = "Você não tem permissão para isso..."
}) => {
  return (
    <Wrapper>
      <Text>{topMessage}</Text>
      <Image src={piquito403} alt="Erro 403" />
      <Text>{bottomMessage}</Text>
    </Wrapper>
  );
};

export default Error403;
