import React from 'react';

import { ActivityLibraryCardBodyContainer } from './styles';

export interface ActivityLibraryCardBodyProps {
  isDescriptionOpen: boolean;
}

const ActivityLibraryCardBody: React.FC<ActivityLibraryCardBodyProps> = (props) => {
  return (
    <ActivityLibraryCardBodyContainer {...props} className='activity-library-card-body-container' >
      {props.children}
    </ActivityLibraryCardBodyContainer>
  );
};

export { ActivityLibraryCardBody };
