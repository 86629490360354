import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPlace from '@modules/places/interfaces/IPlace';

class GetPlaceService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(placeId: string | number): Promise<IPlace | false> {
    try {
      const res = await this.api.get(`/place/${placeId}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetPlaceService;
