import IRoute from "@shared/interfaces/IRoute";

import GroupDirectory from "@modules/groups/views/GroupDirectory";
import GroupListParentCreate from "@modules/groups/views/GroupListParentCreate";
import GroupPlacesRelationCreate from "@modules/groups/views/GroupPlacesRelationCreate";
import GroupUsersRelationCreate from "@modules/groups/views/GroupUsersRelationCreate";
import GroupHome from "@modules/groups/views/GroupHome";
import GroupInfo from "@modules/groups/views/GroupInfo";
import GroupReport from "@modules/groups/views/GroupReport";
import GroupSchedule from "@modules/groups/views/GroupSchedule";
import GroupHistory from "@modules/groups/views/GroupHistory";
import GroupListParentEdit from "@modules/groups/views/GroupListParentEdit";
import GroupPlacesRelationEdit from "@modules/groups/views/GroupPlacesRelationEdit";
import GroupUsersRelationEdit from "@modules/groups/views/GroupUsersRelationEdit";
import GroupEdit from "@modules/groups/views/GroupEdit";
import GroupCreate from "@modules/groups/views/GroupCreate";
import GroupTagRelation from "@modules/groups/views/GroupTagRelation";
import { GroupProvider } from "@modules/groups/context/GroupContext";
import { combineContext } from "@shared/utils/combineContext";
import {IModuleRoutes} from "@shared/interfaces/IModuleRoutes";

import { groupsKeys } from "@modules/groups/keys";
import { tagsKeys } from "@modules/tags/keys";
import { usersKeys } from "@modules/users/keys";
import { placesKeys } from "@modules/places/keys";

const routes: IRoute[] = [
    {
        path: "/grupo/:id",
        key: "GROUP_PREVIEW_HOME",
        exact: true,
        component: GroupHome,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/grupo/:id/informacoes",
        key: "GROUP_PREVIEW_INFO",
        exact: true,
        component: GroupInfo,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/grupo/:id/relatorios",
        key: "GROUP_PREVIEW_REPORTS",
        exact: true,
        component: GroupReport,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/grupo/:id/agenda",
        key: "GROUP_PREVIEW_SCHEDULE",
        exact: true,
        component: GroupSchedule,
        permissions: [
            groupsKeys.GROUP_READ
        ]
    },
    {
        path: "/grupo/:id/historico",
        key: "GROUP_PREVIEW_HISTORIC",
        exact: true,
        component: GroupHistory,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/grupo/:id/editar",
        key: "GROUP_EDIT",
        exact: true,
        component: GroupEdit,
        permissions: [
            groupsKeys.GROUP_READ,
            groupsKeys.GROUP_UPDATE,
            groupsKeys.GROUP_DELETE,
        ]
    },
    {
        path: "/diretorio/grupos",
        key: "DIRECTORY_GROUPS_PATH",
        exact: true,
        component: GroupDirectory,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/diretorio/grupos/criar",
        key: "CREATE_GROUPS_PATH",
        exact: true,
        component: GroupCreate,
        permissions: [
            groupsKeys.GROUP_CREATE,
        ]
    },
    {
        path: "/diretorio/grupos/criar/selecionar",
        key: "ADD_GROUPS_PATH",
        exact: true,
        component: GroupListParentCreate,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/diretorio/grupos/editar/selecionar",
        key: "ADD_EDIT_GROUPS_PATH",
        exact: true,
        component: GroupListParentEdit,
        permissions: [
            groupsKeys.GROUP_READ,
        ]
    },
    {
        path: "/diretorio/grupos/criar/selecionar/locais",
        key: "ADD_GROUPS_PLACES_PATH",
        exact: true,
        component: GroupPlacesRelationCreate,
        permissions: [
            placesKeys.PLACE_READ,
        ]
    },
    {
        path: "/diretorio/grupos/editar/selecionar/locais",
        key: "ADD_EDIT_GROUPS_PLACES_PATH",
        exact: true,
        component: GroupPlacesRelationEdit,
        permissions: [
            placesKeys.PLACE_READ,
        ]
    },
    {
        path: "/diretorio/grupos/criar/selecionar/usuarios",
        key: "ADD_GROUPS_USERS_PATH",
        exact: true,
        component: GroupUsersRelationCreate,
        permissions: [
            usersKeys.LMS_USER_READ,
        ]
    },
    {
        path: "/diretorio/grupos/editar/selecionar/usuarios",
        key: "ADD_EDIT_GROUPS_USERS_PATH",
        exact: true,
        component: GroupUsersRelationEdit,
        permissions: [
            usersKeys.LMS_USER_READ,
        ]
    },
    {
        path: "/diretorio/grupos/selecionar/tags",
        key: "DIRECTORY_GROUPS_SELECT_TAGS_SELECT_PATH",
        exact: true,
        component: GroupTagRelation,
        permissions: [
            tagsKeys.TAGS_READ,
        ]
    },
];

const providers = [
    GroupProvider
];

export default {
    key: 'GROUPS_MODULE',
    providers: combineContext(...providers),
    routes,
} as IModuleRoutes;