import React from 'react';
import Icon from 'react-eva-icons';

import { ISubscription } from '@modules/subscriptions/interfaces/ISubscription';

import { SprintCardContainer } from './styles';

import { SprintCardBody } from './SprintCardBody';
import SprintCardBorder from './SprintCardBorder';
import { ISprintItemsResponse } from '@modules/sprint/interfaces/ISprintResponse';
import { getDay } from '@shared/utils/getDay';

interface ISubscriptionCardProps {
  subscription?: ISubscription;
  className?: string;
  canDeleteCard?: { indexId: number; removeCallback: (indexId: number) => void };
  sprint: ISprintItemsResponse;
  handleOnClick: (sprint: ISprintItemsResponse) => void;
  actual: boolean;
}

const SprintCard: React.FC<ISubscriptionCardProps> = ({ sprint, handleOnClick, ...props }) => {
  return (
    <SprintCardContainer className={props.className || undefined} onClick={() => sprint.id && handleOnClick(sprint)}>
      <div className="card-container">
        <SprintCardBorder name={[`Criado por: ${sprint.creator.name}`]} location="header" />

        <SprintCardBody isTrailsOpen={false}>
          <b>{sprint.sprint.name}</b>
        </SprintCardBody>

        <SprintCardBorder data={[`Data de Submissão: ${getDay(sprint.created_at as unknown as Date)}`]} actual={props.actual} location="footer" />
      </div>

      {props.canDeleteCard && (
        <div className="remove-card" onClick={() => props.canDeleteCard?.removeCallback(props.canDeleteCard.indexId)}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </SprintCardContainer>
  );
};

export { SprintCard };
