import styled from 'styled-components';

export const ContainerCheckboxTagsGroup = styled.div`
  .label-container {
    > label {
      display: flex;
      align-items: flex-end;
      line-height: 1.5rem;
    }

    display: flex;
    justify-content: space-between;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 7px 16px;
  border-radius: 4px;
  margin-top: 4px;
  flex-wrap: wrap;
  row-gap: 4px;
`;
