import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
`;

export const Item = styled.div<{color: string}>`
    font-size: 12px;
    color: #222B45;

    display: flex;
    align-items: center;

    &:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 20px;
        margin-right: 4px;
        background-color: ${({color}) => color};
    }
    
    & + div {
        margin: 12px 0;
    }
`;