import styled from 'styled-components';

interface IContainer {
  selected: boolean;
  isInputCard: boolean;
  isDisabled?: JSX.Element | boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;

  .custom-card {
    ${({ selected }) => selected && 'filter: drop-shadow(0px 0px 28px #49C56C);'}
    > div {
      ${({ isInputCard }) => isInputCard && 'margin: 1rem !important;'}
    }
    ${({ isDisabled }) => isDisabled && 'filter: grayscale(1); opacity: 0.5;'}
  }

  .container-user-card-info {
    font-size: 13px;
    text-transform: capitalize;
    font-family: var(--primary-font);
    font-weight: 400;

    .data-user-card-info {
      font-weight: 700;
    }
  }
`;
