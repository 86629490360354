import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IStudent } from '../../students/interfaces/IStudent';
import { IWorkGroup } from '../../work-group/interfaces/IWorkGroup';
import { IPlannedActivity } from './ListPlannedActivityService';

type IGetPlannedActivityWithWorkGroupAndStudentsService = {
  class_id: string;
  planned_activity_id?: string;
};

export interface IGetPlannedActivityWithWorkGroupAndStudentsServiceResponse {
  plannedActivity?: IPlannedActivity;
  students: IStudent[];
  workGroups: IWorkGroup[];
}

//@TODO Serviço Duplicado
class GetActivityWithWorkGroupAndStudentsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    options: IGetPlannedActivityWithWorkGroupAndStudentsService
  ): Promise<IGetPlannedActivityWithWorkGroupAndStudentsServiceResponse | false> {
    try {
      let searchParams = objectToQuery(options);
      const res = await this.api.get(`/daily-class-plan/activity?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetActivityWithWorkGroupAndStudentsService;
