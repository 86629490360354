import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm } from '@shared/utils/beforeSubmitForm';

import ProjectForm from '@modules/projects/components/ProjectForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { formatProject } from '@modules/projects/utils/formatProject';
import { ProjectSchema } from '@modules/projects/schemas/Project';
import EditProjectService from '@modules/projects/services/Project/EditProjectService';

const ProjectUpdate: React.FC = () => {

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { projectCurrent, clearProjectCreate, getProject, setErros } = useProjects();  
  const { id: projectId } = useParams<{ id: string }>();

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const dirPath = `/projeto/${projectId}/informacoes`

  const handleCancel = useCallback(() => {
    clearProjectCreate();
    navigate(dirPath);
  }, [clearProjectCreate, dirPath, navigate]);

  const handleEditButton = useCallback(async () => {
    if (!projectCurrent) return;
    const formatedBody = formatProject(projectCurrent);
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [projectCurrent, setErros]);

  const handleEditProject = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !projectCurrent || !projectId) return;
    setIsLoading(true);
    const formatedBody = formatProject(projectCurrent);
    const res = await new EditProjectService(signedUser.token).execute(projectId,formatedBody);
    setIsLoading(false);
    if (!res) return addToast({
      status: 'danger',
      title: 'Projetos',
      description: 'Não foi possível editar o Projeto.',
    });

    addToast({
      status: 'success',
      title: 'Projetos',
      description: 'Projeto editado com sucesso.',
    });
    clearProjectCreate();
    return navigate(dirPath)

  }, [signedUser, projectCurrent, setIsLoading, projectId, addToast, clearProjectCreate, navigate, dirPath]);

  useEffect(() => {
    getProject(projectId)
  },[getProject, projectId])

  return (
    <Fragment>
      <ProjectForm
        headerTitle="Editar Projeto"
        headerPathToReturn={dirPath}
        handleCancel={handleCancel}
        handleConfirmButton={handleEditButton}
        pathAddContract="/diretorio/projetos/criar/selecionar/contrato"
        pathAddClasses="/diretorio/projetos/criar/selecionar/turmas"
        pathAddGroup="/diretorio/projetos/criar/selecionar/grupo"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Editar este Projeto?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleEditProject}
      />
    </Fragment>
  );
};

export default ProjectUpdate;
