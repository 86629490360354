import React, { createContext, useContext, useRef } from 'react';
export type IExternalSchedulesContextData = {
  externalScheduleFilter: React.MutableRefObject<IExternalSchedulesFilter>;
};

const ExternalSchedulesContext = createContext<IExternalSchedulesContextData>({} as IExternalSchedulesContextData);

export interface IExternalSchedulesFilter {
  subscribed?: boolean;
  not_subscribed?: boolean;
}

const ExternalSchedulesProvider: React.FC = ({ children }) => {
  const externalScheduleFilter = useRef<IExternalSchedulesFilter>({});

  return (
    <ExternalSchedulesContext.Provider
      value={{
        externalScheduleFilter,
      }}
    >
      {children}
    </ExternalSchedulesContext.Provider>
  );
};

function useExternalSchedules() {
  const context = useContext(ExternalSchedulesContext);

  if (!context) {
    throw new Error('useExternalSchedules must be used within a ExternalSchedulesProvider');
  }

  return context;
}

export { ExternalSchedulesProvider, useExternalSchedules };
