import IRoute from '@shared/interfaces/IRoute';

import ClassDirectory from '@modules/classes/views/ClassDirectory';
import ClassHistory from '@modules/classes/views/ClassHistory';
import ClassHome from '@modules/classes/views/ClassHome';
import ClassInfo from '@modules/classes/views/ClassInfo';
import ClassReport from '@modules/classes/views/ClassReport';
import ClassSchedule from '@modules/classes/views/ClassSchedule';
import ClassCreate from '@modules/classes/views/ClassCreate';
import ClassEdit from '@modules/classes/views/ClassEdit';
import ClassTagRelation from '@modules/classes/views/ClassTagRelation';
import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { classesKeys } from '@modules/classes/keys';
import { placesKeys } from '@modules/places/keys';
import { usersKeys } from '@modules/users/keys';
import { groupsKeys } from '@modules/groups/keys';
import { tagsKeys } from '@modules/tags/keys';
import { ClassPlacesRelation } from '../views/ClassPlacesRelation';
import { ClassUsersRelation } from '../views/ClassUsersRelation';
import { DaysAndHourRelation } from '../views/DaysAndHoursRelation';
import { ClassGroupRelation } from '../views/ClassGroupsRelation';
import { ClassStudentsRelation } from '../views/ClassStudentsRelation';
import { studentsKeys } from '../../students/keys';

const routes: IRoute[] = [
  {
    path: 'diretorio/turmas',
    key: 'DIRECTORY_CLASSES_PATH',
    exact: true,
    component: ClassDirectory,
    permissions: [
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: 'diretorio/turmas/criar',
    key: 'DIRECTORY_CREATE_CLASSES_PATH',
    exact: true,
    component: ClassCreate,
    permissions: [
      classesKeys.CLASSES_CREATE
    ],
  },
  {
    path: 'turma/:id/editar',
    key: 'DIRECTORY_EDIT_CLASSES_PATH',
    exact: true,
    component: ClassEdit,
    permissions: [
      classesKeys.CLASSES_READ, 
      classesKeys.CLASSES_UPDATE, 
      classesKeys.CLASSES_DELETE
    ],
  },
  {
    path: 'turma/:id',
    key: 'HOME_CLASSES_PATH',
    exact: true,
    component: ClassHome,
    permissions: [
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: 'turma/:id/informacoes',
    key: 'INFO_CLASSES_PATH',
    exact: true,
    component: ClassInfo,
    permissions: [
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: 'turma/:id/agenda',
    key: 'SCHEDULE_CLASSES_PATH',
    exact: true,
    component: ClassSchedule,
    permissions: [
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: 'turma/:id/historico',
    key: 'HISTORY_CLASSES_PATH',
    exact: true,
    component: ClassHistory,
    permissions: [
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: 'turma/:id/relatorios',
    key: 'REPORT_CLASSES_PATH',
    exact: true,
    component: ClassReport,
    permissions: [
      classesKeys.CLASSES_READ
    ],
  },
  {
    path: 'diretorio/turmas/selecionar/locais',
    key: 'ADD_CLASS_PLACES_PATH',
    exact: true,
    component: ClassPlacesRelation,
    permissions: [placesKeys.PLACE_READ],
  },
  {
    path: 'diretorio/turmas/selecionar/lideres',
    key: 'ADD_USERS_PLACES_PATH',
    exact: true,
    component: ClassUsersRelation,
    permissions: [usersKeys.LMS_USER_READ],
  },
  {
    path: 'diretorio/turmas/selecionar/alunos',
    key: 'ADD_STUDENTS_PLACES_PATH',
    exact: true,
    component: ClassStudentsRelation,
    permissions: [studentsKeys.STUDENTS_READ],
  },
  {
    path: 'diretorio/turmas/selecionar/grupos',
    key: 'ADD_STUDENTS_CREATE_GROUPS_PATH',
    exact: true,
    component: ClassGroupRelation,
    permissions: [groupsKeys.GROUP_READ],
  },

  {
    path: 'diretorio/turmas/selecionar/tags',
    key: 'ADD_TAGS_PLACES_PATH',
    exact: true,
    component: ClassTagRelation,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: 'diretorio/turmas/selecionar/dias-e-horarios',
    key: 'ADD_DAYS_AND_HOURS_PATH',
    exact: true,
    component: DaysAndHourRelation,
    permissions: [classesKeys.CLASSES_READ],
  },
];

const providers = [];

export default {
  key: 'CLASS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
