import { formatTime } from '../../../shared/interfaces/formatTime';
import { ISkill } from '../../../shared/interfaces/ISkill';
import { IStudent } from '../../students/interfaces/IStudent';
import ITag from '../../tags/interfaces/ITag';
import { IActivityScheduleGrid, IPlannedActivityGrid } from '../context/ScheduleGridContext';
import { IPlannedActivity } from '../services/ListPlannedActivityService';
import { IPlannedActivities } from '../services/UpdatePlannedActivitiesService';

export class PlannedActivityHelper {
  public static formatPlannedActivityStructure(plannedActivities: IPlannedActivity[]): IPlannedActivityGrid {
    let in_calendar: IActivityScheduleGrid[] = [];
    let in_tray: Omit<IActivityScheduleGrid, 'start_time' | 'end_time'>[] = [];

    plannedActivities.forEach((pa) => {
      //common.data
      let planned = {
        planned_activity: pa,
        activity_id: Number(pa.activity_id),
        name: pa.activity_data.title || 'Atividade sem título',
        planned_activity_id: pa.id,
        infos: [
          `Tipo: ${pa.activity_data.type?.name}`,
          `Duração Sugerida: ${pa.activity_data.duration} horas`,
          `Quantidade de Alunos: ${pa.students?.length}`,
        ],
      };

      // in.calendar
      if (pa.start_time || pa.end_time) {
        in_calendar.push({
          ...planned,
          start_time: pa.start_time,
          end_time: pa.end_time,
        });
      } else {
        //in.tray
        in_tray.push(planned);
      }
    });

    return { in_calendar, in_tray };
  }

  public static formatUpdatePlannedActivityCurrent(plannedActivity: IPlannedActivity, selectedStudents: IStudent[]): IPlannedActivities {
    const { end_time, start_time, id, activity_data } = plannedActivity;

    const formattedRelationedTags = activity_data.tags ? activity_data.tags.flatMap((t) => (t ? this.formatTag(t) : [])) : [];
    const formatedRelationedSkills = activity_data.skills ? activity_data.skills.flatMap((sk) => (sk ? this.formatSkill(sk) : [])) : [];

    return {
      end_time: formatTime(end_time),
      start_time: formatTime(start_time),
      planned_activity_id: `${id}`,
      student_ids: selectedStudents.map((s) => Number(s.id)),
      activity_data: {
        ...activity_data,
        skills: formatedRelationedSkills,
        tags: formattedRelationedTags,
        type: activity_data.type ? this.formatTag(activity_data.type) : null,
        theme: activity_data.theme ? this.formatTag(activity_data.theme) : null,
        subtheme: activity_data.subtheme ? this.formatTag(activity_data.subtheme) : null,
        thematic: activity_data.thematic ? this.formatTag(activity_data.thematic) : null,
        duration: activity_data.duration.slice(0, 5),
        description: activity_data.description ? activity_data.description : '',
        preparation: activity_data.preparation ? activity_data.preparation : '',
        links: activity_data.links ? activity_data.links : [],
        attachments: activity_data.attachments ? activity_data.attachments : [],
      },
    };
  }

  public static formatUpdateDisket(plannedActivities: IActivityScheduleGrid[]): Omit<IPlannedActivities, 'student_ids' | 'activity_data'>[] {
    const formatted = plannedActivities.map((pa) => {
      return {
        end_time: formatTime(pa.end_time),
        start_time: formatTime(pa.start_time),
        planned_activity_id: `${pa.planned_activity_id}`,
      };
    });

    return formatted;
  }

  public static formatSkill(skill: Partial<ISkill>) {
    return {
      id: skill.id,
      name: skill.name,
      descriptions: skill.descriptions,
      block: {
        id: skill.block?.id,
        name: skill.block?.name || '',
      },
    };
  }

  public static formatTag(tag: ITag) {
    return { id: tag.id, name: tag.name, color: tag.color };
  }
}
