import { combineReducers } from 'redux';

import globalReducer from './global/reducers';
import authReducer from './auth/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;