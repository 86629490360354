import { IInputFilterData } from "../components/InputFilter";

export const getFilters = (data: IInputFilterData): any => {

    let filters: any = {};

    if(data.age_groups) {
        Object.entries(data.age_groups).forEach(([key, value]) => {
            if(value) {
                filters = {
                    ...filters,
                    age_groups: filters?.age_groups ? [...filters?.age_groups, key] : [key]
                }
            }
        });
    }

    if(data.types) {
        Object.entries(data.types).forEach(([key, value]) => {
            if(value) {
                filters = {
                    ...filters,
                    type: filters?.type ? [...filters?.type, key] : [key]
                }
            }
        });
    }

    if(data.trails) {
        const trail_ids = data.trails.filter((trail) => trail?.checked).map(t => t.id);
         if(trail_ids.length > 0) filters = { ...filters, trail_ids };
    }
  
    if(data.dateFilter?.applied_between.start  && data.dateFilter?.applied_between.end) {
        filters = {...filters, applied_between: data.dateFilter?.applied_between}
    }

    if(data.dateFilter?.started_between.start  && data.dateFilter?.started_between.end) {
        filters = {...filters, started_between: data.dateFilter?.started_between}
    }

    if(data.dateFilter?.finalized_between.start  && data.dateFilter?.finalized_between.end) {
        filters = {...filters, finalized_between: data.dateFilter?.finalized_between}
    }

    return filters;
  };
  