import React, { useCallback, useEffect, useState } from "react";
import { Spinner, Input, Pagination } from "react-alicerce-components";
import DirectoryContainer from "@shared/components/DirectoryContainer";
import HeaderDirectory from "@shared/components/Headers/HeaderDirectory";
import { useGlobal } from "@shared/contexts/global/GlobalContext";
import useCancelRequest from "@shared/hooks/useCancelRequest";
import useAuth from "@shared/store/auth/hook";
import { ProjectDefaultStylesContainer } from "@modules/projects/components/ProjectDefaultStyles/styles";
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { IGroupReponse } from "@modules/groups/services/ListGroupsService";
import CustomForm from "@shared/components/CustomForm";
import Icon from 'react-eva-icons';
import GroupCard from '@modules/groups/components/GroupCard';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import ListGroupsUserManagesService from "@modules/groups/services/ListGroupsUserManagesService";
import { useHeadlights } from "@modules/projects/components/Headlights/context/HeadlightsContext";
import { useNavigate } from "react-router-dom";

export type ICategoryGroups = 'tribe' | 'collective' | 'nation';

const HeadlightsApproval: React.FC = () => {
    const { isLoading, setIsLoading } = useGlobal();
    const { newCancelToken } = useCancelRequest();
    const navigate = useNavigate();
    const { signedUser } = useAuth();
    const [groupList, setGroupList] = useState<IGroupReponse>();
    const [inputSearch, setInputSearch] = useState<string>('');
    const isMounted = useIsMounted();
    const { setGroupCategoryHeadlightApproval } = useHeadlights();

    const handleClickOnGroup = useCallback(
        (group: IGroup) => {
            setGroupCategoryHeadlightApproval(group.category)
              navigate(`/projetos/grupo/${group.id}/aprovar-farois`);
        },
        [navigate, setGroupCategoryHeadlightApproval]
    );

    const listGroupsUserManages = useCallback(
        async (inputSearch, page = 0, category?: ICategoryGroups[]) => {
            if (!signedUser) return;
            setIsLoading(true);
            const groupCategory = category ? category : undefined;
            const res = await new ListGroupsUserManagesService(signedUser.token, newCancelToken()).execute(inputSearch, {
                page,
                size: 10,
                categories: groupCategory,
            });
            if (res === 'canceling') return;
            setIsLoading(false);
            if (!res) return;
            isMounted() && setGroupList(res);
        },
        [isMounted, newCancelToken, setIsLoading, signedUser]
    );

    useEffect(() => {
        listGroupsUserManages(inputSearch, 0, ['collective']);
    }, [listGroupsUserManages, inputSearch]);

    console.log(groupList)

    return (
        <ProjectDefaultStylesContainer>
            <HeaderDirectory
                title={'Aprovar Faróis'}
                onClickReturn={() => navigate('/')}
                menuKebab={{ tooltip: { view: 'HeadlightsApproval' }, menuBackHome: true }}
            />
            {isLoading && <Spinner fixed />}
            <DirectoryContainer footer={false}>
                <CustomForm>
                    <Input
                        placeholder="Buscar"
                        name="search"
                        end={<Icon name="funnel" fill="#8F9BB3" />}
                        onChange={(event) => setInputSearch(event.target.value)}
                    />

                    {groupList?.items &&
                        groupList.items.map((group) => <GroupCard handleOnClick={() => handleClickOnGroup(group)} key={group.id} group={group} />)}

                    {groupList && (
                        <Pagination
                            totalOfPages={groupList.totalPages}
                            currentPage={groupList.currentPage}
                            callbackGetListData={listGroupsUserManages}
                        />
                    )}
                </CustomForm>
            </DirectoryContainer>
        </ProjectDefaultStylesContainer>
    );
}

export default HeadlightsApproval;