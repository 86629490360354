import React, { useCallback } from 'react';

import { ISelectOption } from 'react-alicerce-components';

import IContract from '@modules/contracts/interfaces/IContract';
import IProject from '@modules/projects/interfaces/IProject';

import { useContracts } from '@modules/contracts/context/ContractContext';

export const useContractForm = () => {
    const { setContractCurrent } = useContracts();

    const handleChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, subLevel?: string) => {
            const inputValue = event.target.value;
            const inputName = event.target.name;
            setContractCurrent((oldState: IContract) => ({ ...oldState, [inputName]: inputValue }));
        },
        [setContractCurrent]
    );

    const handleSelectChange = useCallback(
        (option: ISelectOption | null) => {
            if (!option) return
            setContractCurrent((oldState: IContract) => ({ ...oldState, [String(option?.selectname)]: option.value }));
        },
        [setContractCurrent]
    );

    const handleRemoveField = (key: string) => {
        setContractCurrent((oldState: any) => {
            delete oldState[key]
            return oldState
        });
    }

    const handleRemoveProject = (p: IProject) => {
        setContractCurrent((oldState: IContract) => {
            const projects = oldState.projects?.filter(i => i.id !== p.id)
            return {
                ...oldState,
                projects
            }
        });
    }

    return {
        handleChangeInput,
        handleSelectChange,
        handleRemoveField,
        handleRemoveProject
    }
}
