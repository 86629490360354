import { formatDate } from "react-alicerce-components";
import { IIndividualFrenquencyHistory } from "../interfaces/IIndividualFrenquencyHistory";
import { translateClassType } from "./translateClassType";

export interface IFormattedIndividualFrequencyHistory {
  id?: string | number;
  classname?: string;
  type?: string;
  selected?: boolean;
  lessons?: string,
  presence?: string,
  presenceBgColor?: string;
  lessonsBgColor?: string;
}
export const formatIndividualFrequencyHistory = (data: IIndividualFrenquencyHistory): IFormattedIndividualFrequencyHistory => {

  const startTime = `${data?.dailyClass?.start_time}`.substring(0, 5);
  const endTime = `${data?.dailyClass?.end_time}`.substring(0, 5);
  const presenceKey = data.presence ? 'presence' : (!data.presence && !data.dailyClass.attendance_given ? 'noPresence' : 'ausence');
  const presenceConfig = {
    presence: { name: 'Presente', bgColor: '#00D68F' },
    ausence: { name: 'Ausente', bgColor: '#FF3D71' },
    noPresence :{ name: 'Sem Presença', bgColor: '#C5CEE0' },
  };
  
  return {
    id: data?.dailyClass.class?.id,
    classname: data?.dailyClass.class?.name,
    type: translateClassType(`${data?.dailyClass.class?.type}`),
    selected: false,
    lessons: `${formatDate(new Date(`${data.dailyClass.end_date}`), 'numeric')} ${startTime} - ${endTime}`,
    lessonsBgColor: '#C5CEE0',
    presence: presenceConfig[presenceKey].name,
    presenceBgColor: presenceConfig[presenceKey]?.bgColor
  }
}

