import IRoute from '@shared/interfaces/IRoute';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { TaskProvider } from '@modules/tasks/context/TaskContext';
import { SchedulesProvider } from '@modules/schedules/context/SchedulesContext';

import TasksPendingList from '@modules/tasks/views/TasksPendingList';
import TasksLateList from '@modules/tasks/views/TasksLateList';
import TasksDoneList from '@modules/tasks/views/TasksDoneList';
import TaskCreate from '@modules/tasks/views/TaskCreate';
import TaskViewer from '@modules/tasks/views/TaskViewer';
import LocalScheduleSelect from '@modules/tasks/views/LocalScheduleSelect';
import { schedulesKeys } from '@modules/schedules/keys';

const routes: IRoute[] = [
  {
    path: 'tarefas/pendentes',
    key: 'TASKS_PENDING_LIST_PATH',
    exact: true,
    component: TasksPendingList,
    permissions: [
      schedulesKeys.SCHEDULES_EVENT_READ
    ]
  },
  {
    path: 'tarefas/atrasadas',
    key: 'TASKS_LATE_LIST_PATH',
    exact: true,
    component: TasksLateList,
    permissions: [
      schedulesKeys.SCHEDULES_EVENT_READ
    ]
  },
  {
    path: 'tarefas/feitas',
    key: 'TASKS_DONE_LIST_PATH',
    exact: true,
    component: TasksDoneList,
    permissions: [
      schedulesKeys.SCHEDULES_EVENT_READ
    ]
  },
  {
    path: 'tarefas/criar',
    key: 'TASK_CREATE_LIST_PATH',
    exact: true,
    component: TaskCreate,
    permissions: [
      schedulesKeys.SCHEDULES_EVENT_CREATE
    ]
  },
  {
    path: 'tarefas/selecionar/agenda',
    key: 'TASK_SCHEDULE_SELECT_PATH',
    exact: true,
    component: LocalScheduleSelect,
    permissions: [
      schedulesKeys.SCHEDULES_READ
    ]
  },
  {
    path: 'tarefas/visualizar',
    key: 'TASK_VIEWER_PATH',
    exact: true,
    component: TaskViewer,
    permissions: 'public'
  }
];

const providers = [
  TaskProvider,
  SchedulesProvider
];

export default {
  key: 'TASKS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
