import * as Yup from 'yup';

export const scheduleSprintSchema = Yup.object().shape({
  name: Yup.string()
    .required({ name: 'name', message: 'O campo é obrigatório' })
    .min(3, { name: 'name', message: 'No minimo 3 caracteres' })
    .max(200, { name: 'name', message: 'No maximo 200 caracteres' }),
  description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório.' }),
  start_date: Yup.string().required({ name: 'start_date', message: 'O campo é obrigatório.' }),
  end_date: Yup.string().required({ name: 'end_date', message: 'O campo é obrigatório.' }),
});
