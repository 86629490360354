import axios, { AxiosInstance, CancelTokenSource } from "axios";
import ApiFactory from "@shared/factories/ApiFactory";
import { IUser } from "@modules/users/interfaces/IUser";

export type ILoggedLmsUser = IUser & {
  lmsUser: IUser;  
}

class GetLoggedLmsUserService {
  private api: AxiosInstance;


  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(): Promise<ILoggedLmsUser | false> {
    try {
      const res = await this.api.get(`/user`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetLoggedLmsUserService;
