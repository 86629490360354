//format time to de database requirements -> 21:5:00 to 21:05 or

export const formatTime = (time?: string) => {
  if (!time) return '';
  if (time.length < 5) throw new Error('invalid format when using formatTime function');
  return time
    .split(':')
    .map((s) => s.padStart(2, '0'))
    .join(':')
    .slice(0, 5);
};
