import ISteering from '../interfaces/ISteering';

export const formatSteering = (steering: ISteering, projectId: number) => {
  const formated = {
    meeting_date: steering.meeting_date,
    meeting_next_date: steering.meeting_next_date,
    type: steering.type,
    status: steering.status || 'scheduled',
    meeting_channel: steering.meeting_channel,
    meeting_link: steering.meeting_link,
    external_participants: steering.external_participants,
    points_of_meeting: steering.points_of_meeting,
    action_plan_of_meeting: steering.action_plan_of_meeting,
    attachments: steering.attachments || [],
    thermometer: steering.thermometer,
    lms_participants_ids: steering.lms_users && steering.lms_users.map((u) => u.id),
    project_id: projectId
  }
  return formated;
};