import React from 'react';
import { useLocation } from 'react-router-dom';
import Icon from 'react-eva-icons';

import {
  Input,
  Select,
  Textarea,
  H6,
  formatDate,
  Label,
} from 'react-alicerce-components';

import { Form } from '@unform/web';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import CustomForm from '@shared/components/CustomForm';
import Divider from '@shared/components/Divider';
import { DaysOfWeekSelect } from '@shared/components/DaysOfWeekSelect';
import { ILocationState } from '@shared/interfaces/ILocationState';

import ScheduleEventCard from '@modules/schedules/components/ScheduleEventCard';
import { defaultEventTypes } from '@modules/schedules/utils/validData/validEventTypes';
import UserCard from '@modules/users/components/UserCard';

import {
  Container,
  HourContainer,
  ScheduleContainer,
  ConcluderContainer
} from './styles';

const TaskViewer: React.FC = () => {

  const { state } = useLocation() as { state: ILocationState };

  return (
    <Container>
      <HeaderDirectory
        title="Tarefa"
        backgroundHeader='linear-gradient(105.34deg, #6E3FA5 1.9%, #542E91 98.48%);'
      />
      <DirectoryContainer style={{ padding: '20px', marginTop: 0 }} footer={false}>
        <CustomForm>
          <Form onSubmit={() => { }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {state?.task?.concluder && (
              <ConcluderContainer>
                <Label>Tarefa Concluída Por:</Label>
                <UserCard user={state?.task?.concluder} canSelect={false} />
              </ConcluderContainer>
            )}

            <Input
              id="name"
              name="name"
              label="Titulo do Evento:"
              placeholder="Adicione Nome ao Evento"
              defaultValue={state?.task?.event?.name}
              disabled
            />

            <Select
              label="Tipo do Entregável:"
              iconName="arrow-down"
              placeholder="Escolha um Tipo"
              options={defaultEventTypes(state?.task?.event?.type)}
              disabled
            />

            <Textarea
              label="Descrição do Evento:"
              name="description"
              placeholder="Adicione Descrição do Evento"
              value={state?.task?.event?.description}
              rows={4}
              disabled
            />

            <Divider width="100%" />

            <H6
              fontWeight={800}
              status='quaternary'
            >
              DATAS E HORÁRIOS
            </H6>

            <Input
              name="start_date"
              placeholder="Dia/Mês/Ano"
              label="Data de Início:"
              mask="##/##/####"
              defaultValue={formatDate(new Date(`${state?.task?.event?.start_date}`), `numeric`)}
              end={<Icon name="calendar" fill="#8F9BB3" />}
              disabled
            />

            <Input
              name="end_date"
              placeholder="Dia/Mês/Ano"
              label="Data de Fim:"
              mask="##/##/####"
              defaultValue={formatDate(new Date(`${state?.task?.event?.end_date}`), `numeric`)}
              end={<Icon name="calendar" fill="#8F9BB3" />}
              disabled
            />

            <HourContainer>
              <Input
                id="start_time"
                name="start_time"
                label="Horário de Início:"
                placeholder="00:00"
                mask="##:##"
                end={<Icon name="clock" fill="#8F9BB3" />}
                defaultValue={state?.task?.event?.start_time ? state?.task?.event?.start_time.slice(0, 5) : ''}
                disabled
              />

              <Input
                id="end_time"
                name="end_time"
                label="Horário de Término:"
                placeholder="00:00"
                mask="##:##"
                end={<Icon name="clock" fill="#8F9BB3" />}
                defaultValue={state?.task?.event?.end_time ? state?.task?.event?.end_time.slice(0, 5) : ''}
                disabled
              />
            </HourContainer>

            <DaysOfWeekSelect
              status='quaternary'
              statusNumber={500}
              label="Repetir Horários:"
              daysChecked={state?.task?.event?.week_days}
            />

            {state?.task?.event?.schedule && (
              <ScheduleContainer>
                <Divider width='100%' />
                <H6
                  fontWeight={800}
                  status='quaternary'
                >
                  AGENDA RELACIONADA
                </H6>
                <ScheduleEventCard schedule={state?.task?.event?.schedule} />
              </ScheduleContainer>
            )}
          </Form>
        </CustomForm>
      </DirectoryContainer>
    </Container>
  );
};

export default TaskViewer;
