import React from 'react';
import { Pill } from 'react-alicerce-components';

import { ProgramCardBorderContainer } from './styles';

export interface IProgramCardBorder {
  data: string[];
  location: 'header' | 'footer';
}

const ProgramCardBorder: React.FC<IProgramCardBorder> = (props) => {
  return (
    <ProgramCardBorderContainer location={props.location}>
      {props.data.map((d, index) => (
        <Pill key={index} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {d}
        </Pill>
      ))}
    </ProgramCardBorderContainer>
  );
};

export default ProgramCardBorder;
