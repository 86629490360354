import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, ISelectOption, Pagination, Select } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';

import ListReportsService, { IReportResponse } from '@modules/projects/services/Report/ListReportsService';
import ListSprintsService from '@modules/schedules/services/ListSprintsService';
import IProjectReport from '@modules/projects/interfaces/IProjectReport';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import ReportCard from '@modules/projects/components/ReportCard';
import { ISprint } from '@modules/schedules/interfaces/ISprint';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';

const ProjectReports: React.FC = () => {
  const navigate = useNavigate();
  const { projectCurrent } = useProjects();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const [sprintReports, setSprintReports] = useState<IReportResponse>();
  const [selectedSprint, setSelectedSprint] = useState<ISprint>();
  const [sprints, setSprints] = useState<ISprint[]>([]);

  const listSprints = useCallback(
    async (scheduleId) => {
      if (!signedUser) return;
      setIsLoading(true);
      const sprints = await new ListSprintsService(signedUser.token).execute('', { schedule_id: scheduleId, periods: true });

      const isCancel = sprints === 'canceling';
      const isFail = !sprints;

      if (isFail || isCancel) return;

      setSprints([...sprints.items]);

      setIsLoading(false);
    },
    [setIsLoading, signedUser]
  );

  useEffect(() => {
    listSprints(projectCurrent?.schedule_id);
  }, [listSprints, projectCurrent]);

  const defaultSprintOptions = (sprints: ISprint[]): ISelectOption[] | undefined => {
    return sprints.map((sprint) => ({ name: sprint.name, value: String(sprint.id) }));
  };

  const handleSelectChange = useCallback(
    (option, sprints: ISprint[]) => {
      const selectedSprint = sprints.find((sprint) => sprint.id === option.value);
      setSelectedSprint(selectedSprint);
    },
    [setSelectedSprint]
  );

  const listReports = useCallback(
    async (page = 0) => {
      if (selectedSprint && projectCurrent) {
        setIsLoading(true);
        const reports = await new ListReportsService(signedUser?.token).execute({
          page,
          size: 4,
          sprint_id: selectedSprint.id,
          project_id: projectCurrent.id,
        });
        const isCancel = reports === 'canceling';
        const isFail = !reports;

        if (isFail || isCancel) return;

        setSprintReports(reports);
      }
      setIsLoading(false);
    },
    [projectCurrent, selectedSprint, setIsLoading, signedUser]
  );

  const handleClickReportCard = useCallback(
    (report: IProjectReport) => {
      return navigate(`/diretorio/projetos/report/${report?.id}`);
    },
    [navigate]
  );

  const RenderReportsList = () => {
    if (sprintReports && sprintReports.items.length > 0) {
      return (
        <Grid>
          {sprintReports?.items.map((report, i) => (
            <ReportCard key={i} report={report} handleOnClick={handleClickReportCard} />
          ))}
        </Grid>
      );
    }

    if (sprintReports && sprintReports.items.length === 0) {
      return (
        <Grid>
          <p>Sem Reports para mostrar...</p>
        </Grid>
      );
    }
    return null;
  };

  useEffect(() => {
    listReports();
  }, [listReports, selectedSprint]);

  return (
    <>
      <Select
        label="Sprint"
        iconName="arrow-down"
        placeholder="Selecione"
        fullWidth
        handleSelectChange={(option) => handleSelectChange(option, sprints)}
        options={defaultSprintOptions(sprints)}
      />

      <RenderReportsList />
      {sprintReports && (
        <Pagination
          totalOfPages={sprintReports?.totalPages}
          currentPage={sprintReports?.currentPage}
          callbackGetListData={async (_, page) => listReports(page)}
        />
      )}

      <PageFooter>
        <Button transform="none" shaded onClick={() => navigate(`/diretorio/projetos/${projectCurrent?.id}/report/criar`)}>
          Criar Report
        </Button>
      </PageFooter>
    </>
  );
};

export default ProjectReports;
