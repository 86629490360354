import React, { CSSProperties, useRef } from 'react';
import { Container } from './styles';

interface IDirectoryContainer {
  footer: boolean;
  style?: CSSProperties;
  className?: string;
  pageFooterDynamicHeight?: number;
  isScrollBlocked?: boolean;
  dirRef?: React.RefObject<HTMLDivElement>;
}

const DirectoryContainer: React.FC<IDirectoryContainer> = ({ children, className, ...props }) => {
  const directoryContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={props.dirRef ? props.dirRef : directoryContainerRef} className={className} {...props}>
      {children}
    </Container>
  );
};

export default DirectoryContainer;
