import { styled } from 'react-alicerce-components';

export const WorkGroupCardCardBorderContainer = styled.div<{ location: 'header' | 'footer' }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  background: #B85EC4;
  min-height: 40px;
  position: relative;
  z-index: 1;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px;
    color: #222b45;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    border: none;
  }

  ${(p) =>
    p.location === 'header' &&
    `
    border-radius:5px 5px 0px 0px;
    
    >div {
      background: #E4E9F2;
    }
    `}

  ${(p) =>
    p.location === 'footer' &&
    `
    border-radius:0px 0px 5px 5px;
    >div {
      background: #FFC94D;
    }

  `}
`;
