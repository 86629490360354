import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import styled from 'styled-components';

interface IHeaderProps {
  status?: ElementStatus;
  statusNumber: number;
  backgroundHeader?: string;
}

export const Container = styled.div<IHeaderProps>`
  .main-navbar {
    position: relative;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 90px;
    width: 100%;
    padding: 1rem;
    background: ${({ backgroundHeader, status }) => backgroundHeader || themeGet(`colors.${status}600`)};

    h6 {
      font-size: 20px;
      font-weight: 800;
      color: white;
    }
  }

  .main-navbar i {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: white;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  // RESPONSIVE - DESKTOP
  @media (min-width: 768px){
    .main-navbar h6 {
      font-size: 1.5rem !important;
    }

    .main-navbar i svg {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  };

  // @media (min-width: 576px) {};

  // @media (min-width: 768px){}

  // @media (min-width: 992px){};

  // @media (min-width: 1200px){};

  // @media (min-width: 1400px){};
`;
