import React from 'react'
import { AccordionSkillsContentContainer } from './styles';

type AccordionSkillsContentProps = { data: any[], render: (item: any) => React.ReactNode; }

export const AccordionSkillsContent = (props: AccordionSkillsContentProps) => {
    const { data, render } = props
    if (!data || data.length === 0) return <div>Competências não Disponíveis</div>

    return (
        <AccordionSkillsContentContainer>{data.map(render)}</AccordionSkillsContentContainer>
    )
}