import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import { IFormatedActivity } from '@modules/activities-library/interfaces/IFormatedActivity';

import ApiFactory from '@shared/factories/ApiFactory';

class EditActivityService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(activityId: string, activityBody: IFormatedActivity): Promise<boolean> {
    try {
      await this.api.put(`/activity/${activityId}`, { ...activityBody });
      return true;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditActivityService;
