import React, { Fragment, useCallback, useState } from 'react';
import { Container, StyledButtonContainer, ContainerMediaPreview, ImageEmpty, ImageContainer, ImageZoom } from './styles';
import Icon from 'react-eva-icons';
import { IAttachment } from '../../interfaces/IAttachment';
import ImageAttach from '../ImageAttach';
import { Button, Grid, Image } from 'react-alicerce-components';

interface IButtonAddImagesProps {
  filesToSave?: (File | IAttachment)[];
  callbackFilesToSave: (files: (File | IAttachment)[]) => void;
  callbackFilesToRemove?: (indexInArray: number) => void;
  isDisabled?: boolean;
  iconName: string;
  text: string;
  downloadSingleFileCallback?: (file: File | IAttachment) => void;
}

const ButtonAddImages: React.FC<IButtonAddImagesProps> = ({
  filesToSave = [],
  callbackFilesToSave,
  iconName,
  isDisabled,
  text,
  callbackFilesToRemove,
  downloadSingleFileCallback,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const filesToSaveUrl = filesToSave as IAttachment[];
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);

  const proxyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList | null = event.target.files;

      if (files && files.length) {
        const fileNames: File[] = [];

        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);
          file && fileNames.push(new File([file], file.name, { type: file.type, lastModified: file.lastModified }));
        }

        callbackFilesToSave([...fileNames, ...(filesToSave || [])]);
        if (hiddenFileInput.current) hiddenFileInput.current.value = '';
      }
    },
    [callbackFilesToSave, filesToSave]
  );

  const handleNext = () => {
    if (currentStartIndex + maxImages < filesToSave.length) {
      setCurrentStartIndex(currentStartIndex + maxImages);
    }
  };

  const handlePrevious = () => {
    if (currentStartIndex > 0) {
      setCurrentStartIndex(currentStartIndex - maxImages);
    }
  };

  const maxImages = 6;
  const visibleImages = filesToSaveUrl.slice(currentStartIndex, currentStartIndex + maxImages);
  const emptySlots = maxImages - visibleImages.length;

  const renderPlaceholders = (count: number) => {
    const placeholdersArray: JSX.Element[] = [];

    for (let i = 0; i < count; i++) {
      placeholdersArray.push(<ImageEmpty key={`empty-${i}`} />);
    }

    return placeholdersArray;
  };

  const selectedImage = selectedIndex !== null && filesToSave[selectedIndex];
  const selectedImageUrl =
    selectedImage && 'url' in selectedImage ? selectedImage.url : selectedImage instanceof File ? URL.createObjectURL(selectedImage) : '';

  const handleOpenInNewTab = () => {
    if (selectedImage) {
      const finalURL = selectedImageUrl || URL.createObjectURL(filesToSave[selectedIndex] as File);
      window.open(finalURL, '_blank');
    }
  };

  const handleDonwloadImage = () => {
    const selectedFile = visibleImages.find((file) => file === selectedImage);
    if (selectedFile && downloadSingleFileCallback) {
      downloadSingleFileCallback(selectedFile);
    }
  };

  return (
    <Grid>
      <Container style={{ marginTop: isDisabled ? 0 : '30px' }}>
        {callbackFilesToRemove && (
          <Fragment>
            <input type="file" accept="image/jpeg, image/png, image/jpg" multiple ref={hiddenFileInput} onChange={proxyChange} />
            <StyledButtonContainer onClick={() => hiddenFileInput.current?.click()}>
              <Icon name={iconName} />
            </StyledButtonContainer>
            <div className="text-add-button">{text}</div>
          </Fragment>
        )}
      </Container>
      <Grid dFlex flexWrap>
        <ContainerMediaPreview>
          <ImageZoom style={{ position: 'relative' }}>
            {selectedImage && (
              <Grid>
                <Image
                  src={selectedImageUrl}
                  alt="foto"
                  style={{
                    height: '100%',
                    borderRadius: '1rem',
                    position: 'absolute',
                  }}
                />
              </Grid>
            )}

            {isDisabled && (
              <Grid className="button-container" dFlex justifyContentBetween flexWrap>
                <Button start={<Icon name="search" />} rounded transform="none" size="small" onClick={handleOpenInNewTab}>
                  Ver Imagem
                </Button>
                <Button
                  start={<Icon name="download" />}
                  rounded
                  size="small"
                  transform="none"
                  onClick={selectedImage ? handleDonwloadImage : undefined}
                >
                  Baixar Imagem
                </Button>
              </Grid>
            )}
          </ImageZoom>
        </ContainerMediaPreview>
        <ContainerMediaPreview>
          <Button onClick={handlePrevious} round icon={<Icon name="arrow-ios-back-outline" />} />

          <ImageContainer>
            {visibleImages.map((file, index) => {
              const imageIndex = currentStartIndex + index;
              const imageUrl = file.url || URL.createObjectURL(filesToSave[imageIndex] as File);
              const isSelected = imageIndex === selectedIndex;

              return (
                <Container
                  key={imageIndex}
                  onClick={() => {
                    setSelectedIndex(imageIndex);
                  }}
                >
                  <ImageAttach
                    isSelected={isSelected}
                    media={file}
                    url={imageUrl}
                    handleOnDelete={
                      callbackFilesToRemove
                        ? () => {
                            callbackFilesToRemove(imageIndex);
                          }
                        : undefined
                    }
                  />
                </Container>
              );
            })}
            {renderPlaceholders(emptySlots)}
          </ImageContainer>

          <Button onClick={handleNext} round icon={<Icon name="arrow-ios-forward-outline" />} />
        </ContainerMediaPreview>
      </Grid>
    </Grid>
  );
};

export { ButtonAddImages };
