import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
interface ILayoutApplicationProps {
  openTab?: number;
  footer?: boolean;
}

type LocationState = {
  classId: string;
};

const LayoutApplication: React.FC<ILayoutApplicationProps> = ({ openTab = 0, footer = false, children }) => {
  const { applicationId, studentId, classId } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aplicações"
        pathToReturn={`/aplicacoes/turma/${classId}/aluno/${studentId}/finalizado`}
        backgroundHeader="linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Gabarito" onClick={() => navigate(`/aplicacoes/${applicationId}/turma/${classId}/aluno/${studentId}/gabarito`)} />
          <Tab title="Notas" onClick={() => navigate(`/aplicacoes/${applicationId}/turma/${classId}/aluno/${studentId}/notas`)} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default LayoutApplication;
