import React, { useState } from 'react';
import { Input } from 'react-alicerce-components';
import ButtonGradient from '@shared/components/ButtonGradient';

import Layout from '@modules/report-explorer/components/Layout';

import { Container, ContainerButtons } from './styles';

const SavedReports: React.FC = () => {
  const [colors] = useState([
    'linear-gradient(180deg, #A35EC0 0%, #A35EC0 98.12%);',
    'linear-gradient(180deg, #698BF4 0%, #698BF4 98.12%);',
    'linear-gradient(180deg, #FF4676 0%, #FF4676 98.12%);',
    'linear-gradient(180deg, #6FC6CE 0%, #6FC6CE 98.12%);',
    'linear-gradient(180deg, #85B6FF 0%, #85B6FF 98.12%);',
    'linear-gradient(180deg, #B76DB8 0%, #B76DB8 98.12%);',
    'linear-gradient(180deg, #061A40 0%, #061A40 98.12%);',
    'linear-gradient(180deg, #0072BC 0%, #0072BC 98.12%);',
    'linear-gradient(180deg, #CE1A1A 0%, #CE1A1A 98.12%);',
    'linear-gradient(180deg, #92DC6C 0%, #92DC6C 98.12%);'
  ]);

  return (
    <Layout openTab={1}>
      <Container>
        <div style={{ marginBottom: '2rem' }}>
          <Input
            placeholder="Buscar"
            name="search"
          />
        </div>
        <ContainerButtons>
          {colors.map((color, i) => (
            <ButtonGradient fill={color}>
              Relatório Salvo {i + 1}
            </ButtonGradient>
          ))}
        </ContainerButtons>
      </Container>
    </Layout >
  );
};

export default SavedReports;
