import React, { useCallback, useEffect, useState } from 'react';
import GroupList from '@modules/groups/components/GroupsList';
import { useClass } from '@modules/classes/context/ClassContext';
import { IGroup } from '@modules/groups/interfaces/IGroup';

const ClassGroupRelation: React.FC = () => {
  const { setClassCurrent, classCurrent } = useClass();
  const [selectedGroup, setSelectedGroupCreate] = useState<IGroup>();

  useEffect(() => {
    setSelectedGroupCreate(classCurrent.group);
  }, [classCurrent.group]);

  const handleAddRelationGroup = useCallback(
    (groupSelect: IGroup | undefined) => {
      setClassCurrent((oldState: any) => {
        return { ...oldState, group: groupSelect };
      });
    },
    [setClassCurrent]
  );

  const setSelectedItens = useCallback(
    (group: IGroup[]) => {
      setSelectedGroupCreate(group[0]);
    },
    [setSelectedGroupCreate]
  );

  return (
    <GroupList
      headerTitle="Diretório de Grupos"
      btnText="Adicionar Grupo"
      selectedGroups={selectedGroup ? [selectedGroup] : []}
      setSelectedGroups={setSelectedItens}
      listOptions={{ place_id: classCurrent.place ? String(classCurrent.place.id) : undefined }}
      canSelect="one"
      handleOnClick={handleAddRelationGroup}
    />
  );
};

export { ClassGroupRelation };
