import styled from 'styled-components';
import { ICardsRenderProps } from './index';

export const Container = styled.div<ICardsRenderProps>`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f7f9fc;
  border: 2px solid #e4e9f2;
  margin-top: 4px;
  flex-wrap: wrap;
  row-gap: 4px;

  > div {
    width: 100%;
  }

  .custom-card > div {
    margin: 0;
  }
`;

export const CardsRenderErrorMessage = styled.div`
  position: relative;
  color: #c53030;
  font-size: 12px;
  font-weight: 600;
`;
