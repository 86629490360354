import IRoute from "@shared/interfaces/IRoute";

import TagDirectory from "@modules/tags/views/TagDirectory";
import TagListParentCreate from "@modules/tags/views/TagListParentCreate";
import TagEdit from "@modules/tags/views/TagEdit";
import TagCreate from "@modules/tags/views/TagCreate";
import TagListParentEdit from "@modules/tags/views/TagListParentEdit";
import { TagInfo } from "@modules/tags/views/TagInfo";
import { TagsProvider } from "@modules/tags/context/TagsContext";

import { combineContext } from "@shared/utils/combineContext";
import {IModuleRoutes} from "@shared/interfaces/IModuleRoutes";

import { tagsKeys } from "@modules/tags/keys";

const routes: IRoute[] = [
    {
        path: "/diretorio/tags",
        key: "TAGS_DIRECTORY_PATH",
        exact: true,
        component: TagDirectory,
        permissions: [
            tagsKeys.TAGS_READ
        ]
    },
    {
        path: "/diretorio/tags/criar",
        key: "TAGS_CREATE_PATH",
        exact: true,
        component: TagCreate,
        permissions: [
            tagsKeys.TAGS_CREATE
        ]
    },
    {
        path: "/diretorio/tags/:id/informacoes",
        key: "TAGS_INFO_PATH",
        exact: true,
        component: TagInfo,
        permissions: [
            tagsKeys.TAGS_READ
        ]
    },
    {
        path: "/diretorio/tags/:id/editar",
        key: "TAGS_EDIT_PATH",
        exact: true,
        component: TagEdit,
        permissions: [
            tagsKeys.TAGS_READ,
            tagsKeys.TAGS_UPDATE,
            tagsKeys.TAGS_DELETE
        ]
    },
    {
        path: "/diretorio/tags/criar/selecionar",
        key: "DIRECTORY_TAGS_CREATE_SELECT_PATH",
        exact: true,
        component: TagListParentCreate,
        permissions: [
            tagsKeys.TAGS_READ
        ]
    },
    {
        path: "/diretorio/tags/editar/selecionar",
        key: "TAGS_EDIT_SELECT_PATH",
        exact: true,
        component: TagListParentEdit,
        permissions: [
            tagsKeys.TAGS_READ
        ]
    }
];

const providers = [
    TagsProvider
];
  
export default {
    key: 'TAGS_MODULE',
    providers: combineContext(...providers),
    routes,
} as IModuleRoutes;
  