import React from 'react';
import { WorkGroupCardBodyContainer } from './styles';


export interface WorkGroupCardBodyProps {
  isDescriptionOpen: boolean;
}

const WorkGroupCardBody: React.FC<WorkGroupCardBodyProps> = (props) => {
  return (
    <WorkGroupCardBodyContainer {...props} className='work-group-card-body-container' >
      {props.children}
    </WorkGroupCardBodyContainer>
  );
};

export { WorkGroupCardBody };
