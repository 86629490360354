import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import data from './data.json'
import ApiFactory from '@shared/factories/ApiFactory';

export interface IInfoTooltipResponse {
  view?: string
  link?: string,
  title: string,
  text: string,
}

class GetInfoTooltipService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(viewName: string): Promise<IInfoTooltipResponse | false | 'canceling'> {
    try {
      //const res = await this.api.get(`/tooltips/${view}`);
      const res = {
        data: data.find(({view}) => view === viewName) || {title: 'Codigo 404', text: 'Tooltip não encontrado.'} 
      }
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default GetInfoTooltipService;
