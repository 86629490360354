import { styled } from 'react-alicerce-components';
import { ILearningTargetsStudentCardBorder } from '.';

export const LearningTargetsStudentCardBorderContainer = styled.div<{ location: 'header' | 'footer' }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  background: #6749ba;
  min-height: 40px;
  align-items: center;

  .label-obj {
    border-left: 1px solid white;
    padding: 5px;
  }

  > div {
    padding: 5px 10px;
    color: #222b45;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
  }

  ${(p) =>
    p.location === 'header' &&
    `
    border-radius:5px 5px 0px 0px;
    padding: 10px;
    
    >div {
      background: #E4E9F2;
    }
    `}

  ${(p) =>
    p.location === 'footer' &&
    `
    justify-content: center;

  `}
`;

export const Footer = styled.div`
  background: #6749ba;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  flex-wrap: wrap;

  label {
    font-weight: 700;
    font-size: 12px;
    color: #f7f9fc;
    font-family: var(--secondary-font);
  }

  input + div {
    background: rgba(143, 155, 179, 0.48);
    border: 1px solid #c5cee0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    margin-right: 1rem;
  }
`;

export const ValidatedContainer = styled.div<Pick<ILearningTargetsStudentCardBorder, 'valid'> & { color?: string }>`
  display: flex;
  gap: 0.625rem;
  align-items: center;

  p {
    padding-right: 2rem;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    color: #f7f9fc;
    width: 8rem;
  }
  span {
    height: 20px;
    width: 20px;
    background: ${(p) =>
      p.valid ? `linear-gradient(180deg, #6DCC1F 36.46%, #5BB610 97.4%);` : `linear-gradient(180deg, #f9514c 36.46%, #f42727 97.4%);`};

    border-radius: 50%;
    display: inline-block;
  }
`;

export const ValidatedContainerLearningTarget = styled.div<Pick<ILearningTargetsStudentCardBorder, 'valid'> & { color?: string }>`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  border: ${(p) => (p.valid ? '3px solid #5bb610' : 'none')};
  border-radius: 20px;
  margin: 10px;
  padding: ${(p) => (p.valid ? '1rem 3rem' : '0.5rem')};

  background: ${(p) => !p.valid && 'linear-gradient(180deg, #F63E3C, #FF7773)'};

  span {
    text-align: center;
    color: #f7f9fc;
  }

  p {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: ${(p) => (p.valid ? '14px' : '12px')};
    color: #f7f9fc;
    margin: 5px;

    i {
      display: flex;
      align-items: center;
      margin-right: 0.3rem;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  b {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: #f7f9fc;
    margin: 5px;
  }

  .notValidated {
    display: flex;
    max-width: fit-content;
    justify-content: center;
    flex-wrap: wrap;

    p {
      display: flex;
      align-items: center;
    }
  }
`;

export const ValidatedDays = styled.div`
  margin-top: 0.2rem;
  display: flex;
  gap: 0.625rem;
  align-items: flex-start;
`;
