import { IHVGroupGrades } from "../interfaces/IHVGradeGroupCurrent";

export interface IFormatedHVGroupGrades {
    selected?: boolean;
    isOpen?: boolean;
    id: number,
    axisname: string,
    never: string,
    occasionally: string,
    sometimes: string,
    always: string,
    nothing: string,

    neverBgColor: string,
    occasionallyBgColor: string,
    sometimesBgColor: string,
    alwaysBgColor: string,
    nothingBgColor: string,

    subItems: {
        axisname: string,
        never: string,
        occasionally: string,
        sometimes: string,
        always: string,
        nothing: string,

        neverBgColor: string,
        occasionallyBgColor: string,
        sometimesBgColor: string,
        alwaysBgColor: string,
        nothingBgColor: string,
    }[]
}

export const formatHVGradeGroupData = (data: IHVGroupGrades) => {
    const collors = [
        '#ffffff',
        '#f8fff2',
        '#eaffd9',
        '#ddffbf',
        '#cfffa6'
    ]


    const calculateColor = (freq: 'Nunca' | 'Quase Nunca' | 'Quase Sempre' | 'Sempre' | 'N/A', param: string, skill: boolean) => {
        const els = data.observations.filter(o => skill ? o.skill === param : o.block === param)
        const nCount = els.filter(o => o.observation === 'Nunca').length
        const qnCount = els.filter(o => o.observation === 'Quase Nunca').length
        const qsQunt = els.filter(o => o.observation === 'Quase Sempre').length
        const sCount = els.filter(o => o.observation === 'Sempre').length
        const naCount = els.filter(o => o.observation === 'N/A').length

        const fCount = els.filter(o => o.observation === freq).length

        const max = [nCount, qnCount, qsQunt, sCount, naCount].reduce((a, b) => a > b ? a : b)
        const min = [nCount, qnCount, qsQunt, sCount, naCount].reduce((a, b) => a < b ? a : b)

        const coef = (fCount-min)/(max-min)
        return collors[coef]
    }
    const formated: IFormatedHVGroupGrades[] = [
        {
            id: 1,
            axisname: 'Pensar',

            never: `${data.observations.filter(o => o.block === 'PENSAR' && o.observation === 'Nunca').length} Ocorrências`,
            occasionally: `${data.observations.filter(o => o.block === 'PENSAR' && o.observation === 'Quase Nunca').length} Ocorrências`,
            sometimes: `${data.observations.filter(o => o.block === 'PENSAR' && o.observation === 'Quase Sempre').length} Ocorrências`,
            always: `${data.observations.filter(o => o.block === 'PENSAR' && o.observation === 'Sempre').length} Ocorrências`,
            nothing: `${data.observations.filter(o => o.block === 'PENSAR' && o.observation === 'N/A').length} Ocorrências`,

            neverBgColor: calculateColor("Nunca", 'PENSAR', false),
            occasionallyBgColor: calculateColor("Quase Nunca", 'PENSAR', false),
            sometimesBgColor: calculateColor("Quase Sempre", 'PENSAR', false),
            alwaysBgColor: calculateColor("Sempre", 'PENSAR', false),
            nothingBgColor: calculateColor("N/A", 'PENSAR', false),

            subItems: [
                {
                    axisname: 'H01',

                    never: `${data.observations.filter(o => o.skill === 'H01' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H01' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H01' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H01' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H01' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H01', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H01', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H01', true),
                    alwaysBgColor: calculateColor("Sempre", 'H01', true),
                    nothingBgColor: calculateColor("N/A", 'H01', true),
                }, {
                    axisname: 'H02',

                    never: `${data.observations.filter(o => o.skill === 'H02' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H02' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H02' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H02' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H02' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H02', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H02', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H02', true),
                    alwaysBgColor: calculateColor("Sempre", 'H02', true),
                    nothingBgColor: calculateColor("N/A", 'H02', true),
                }, {
                    axisname: 'H03',

                    never: `${data.observations.filter(o => o.skill === 'H03' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H03' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H03' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H03' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H03' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H03', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H03', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H03', true),
                    alwaysBgColor: calculateColor("Sempre", 'H03', true),
                    nothingBgColor: calculateColor("N/A", 'H03', true),
                }, {
                    axisname: 'H04',

                    never: `${data.observations.filter(o => o.skill === 'H04' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H04' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H04' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H04' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H04' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H04', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H04', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H04', true),
                    alwaysBgColor: calculateColor("Sempre", 'H04', true),
                    nothingBgColor: calculateColor("N/A", 'H04', true),
                }, {
                    axisname: 'H05',

                    never: `${data.observations.filter(o => o.skill === 'H05' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H05' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H05' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H05' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H05' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H05', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H05', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H05', true),
                    alwaysBgColor: calculateColor("Sempre", 'H05', true),
                    nothingBgColor: calculateColor("N/A", 'H05', true),
                }
            ]
        }, {
            id: 2,
            axisname: 'Sentir',

            never: `${data.observations.filter(o => o.block === 'SENTIR' && o.observation === 'Nunca').length} Ocorrências`,
            occasionally: `${data.observations.filter(o => o.block === 'SENTIR' && o.observation === 'Quase Nunca').length} Ocorrências`,
            sometimes: `${data.observations.filter(o => o.block === 'SENTIR' && o.observation === 'Quase Sempre').length} Ocorrências`,
            always: `${data.observations.filter(o => o.block === 'SENTIR' && o.observation === 'Sempre').length} Ocorrências`,
            nothing: `${data.observations.filter(o => o.block === 'SENTIR' && o.observation === 'N/A').length} Ocorrências`,

            neverBgColor: calculateColor("Nunca", 'SENTIR', false),
            occasionallyBgColor: calculateColor("Quase Nunca", 'SENTIR', false),
            sometimesBgColor: calculateColor("Quase Sempre", 'SENTIR', false),
            alwaysBgColor: calculateColor("Sempre", 'SENTIR', false),
            nothingBgColor: calculateColor("N/A", 'SENTIR', false),

            subItems: [
                {
                    axisname: 'H06',

                    never: `${data.observations.filter(o => o.skill === 'H06' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H06' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H06' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H06' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H06' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H06', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H06', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H06', true),
                    alwaysBgColor: calculateColor("Sempre", 'H06', true),
                    nothingBgColor: calculateColor("N/A", 'H06', true),
                }, {
                    axisname: 'H07',

                    never: `${data.observations.filter(o => o.skill === 'H07' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H07' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H07' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H07' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H07' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H07', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H07', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H07', true),
                    alwaysBgColor: calculateColor("Sempre", 'H07', true),
                    nothingBgColor: calculateColor("N/A", 'H07', true),
                }, {
                    axisname: 'H08',

                    never: `${data.observations.filter(o => o.skill === 'H08' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H08' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H08' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H08' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H08' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H08', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H08', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H08', true),
                    alwaysBgColor: calculateColor("Sempre", 'H08', true),
                    nothingBgColor: calculateColor("N/A", 'H08', true),
                }, {
                    axisname: 'H09',

                    never: `${data.observations.filter(o => o.skill === 'H09' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H09' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H09' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H09' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H09' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H09', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H09', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H09', true),
                    alwaysBgColor: calculateColor("Sempre", 'H09', true),
                    nothingBgColor: calculateColor("N/A", 'H09', true),
                }, {
                    axisname: 'H10',

                    never: `${data.observations.filter(o => o.skill === 'H10' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H10' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H10' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H10' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H10' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H10', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H10', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H10', true),
                    alwaysBgColor: calculateColor("Sempre", 'H10', true),
                    nothingBgColor: calculateColor("N/A", 'H10', true),
                }
            ]
        }, {
            id: 3,
            axisname: 'A. Con.',

            never: `${data.observations.filter(o => o.block === 'AUTOCONHECIMENTO' && o.observation === 'Nunca').length} Ocorrências`,
            occasionally: `${data.observations.filter(o => o.block === 'AUTOCONHECIMENTO' && o.observation === 'Quase Nunca').length} Ocorrências`,
            sometimes: `${data.observations.filter(o => o.block === 'AUTOCONHECIMENTO' && o.observation === 'Quase Sempre').length} Ocorrências`,
            always: `${data.observations.filter(o => o.block === 'AUTOCONHECIMENTO' && o.observation === 'Sempre').length} Ocorrências`,
            nothing: `${data.observations.filter(o => o.block === 'AUTOCONHECIMENTO' && o.observation === 'N/A').length} Ocorrências`,

            neverBgColor: calculateColor("Nunca", 'AUTOCONHECIMENTO', false),
            occasionallyBgColor: calculateColor("Quase Nunca", 'AUTOCONHECIMENTO', false),
            sometimesBgColor: calculateColor("Quase Sempre", 'AUTOCONHECIMENTO', false),
            alwaysBgColor: calculateColor("Sempre", 'AUTOCONHECIMENTO', false),
            nothingBgColor: calculateColor("N/A", 'AUTOCONHECIMENTO', false),

            subItems: [
                {
                    axisname: 'H11',

                    never: `${data.observations.filter(o => o.skill === 'H11' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H11' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H11' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H11' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H11' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H11', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H11', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H11', true),
                    alwaysBgColor: calculateColor("Sempre", 'H11', true),
                    nothingBgColor: calculateColor("N/A", 'H11', true),
                }, {
                    axisname: 'H12',

                    never: `${data.observations.filter(o => o.skill === 'H12' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H12' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H12' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H12' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H12' && o.observation === 'N/A').length} Ocorrências`,


                    neverBgColor: calculateColor("Nunca", 'H12', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H12', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H12', true),
                    alwaysBgColor: calculateColor("Sempre", 'H12', true),
                    nothingBgColor: calculateColor("N/A", 'H12', true),
                }, {
                    axisname: 'H13',

                    never: `${data.observations.filter(o => o.skill === 'H13' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H13' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H13' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H13' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H13' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H13', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H13', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H13', true),
                    alwaysBgColor: calculateColor("Sempre", 'H13', true),
                    nothingBgColor: calculateColor("N/A", 'H13', true),
                }, {
                    axisname: 'H14',

                    never: `${data.observations.filter(o => o.skill === 'H14' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H14' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H14' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H14' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H14' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H14', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H14', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H14', true),
                    alwaysBgColor: calculateColor("Sempre", 'H14', true),
                    nothingBgColor: calculateColor("N/A", 'H14', true),
                }, {
                    axisname: 'H15',

                    never: `${data.observations.filter(o => o.skill === 'H15' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H15' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H15' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H15' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H15' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H15', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H15', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H15', true),
                    alwaysBgColor: calculateColor("Sempre", 'H15', true),
                    nothingBgColor: calculateColor("N/A", 'H15', true),
                }
            ]
        }, {
            id: 3,
            axisname: 'Con. & Retr.',

            never: `${data.observations.filter(o => o.block === 'CONTRIBUIR & RETRIBUIR' && o.observation === 'Nunca').length} Ocorrências`,
            occasionally: `${data.observations.filter(o => o.block === 'CONTRIBUIR & RETRIBUIR' && o.observation === 'Quase Nunca').length} Ocorrências`,
            sometimes: `${data.observations.filter(o => o.block === 'CONTRIBUIR & RETRIBUIR' && o.observation === 'Quase Sempre').length} Ocorrências`,
            always: `${data.observations.filter(o => o.block === 'CONTRIBUIR & RETRIBUIR' && o.observation === 'Sempre').length} Ocorrências`,
            nothing: `${data.observations.filter(o => o.block === 'CONTRIBUIR & RETRIBUIR' && o.observation === 'N/A').length} Ocorrências`,

            neverBgColor: calculateColor("Nunca", 'CONTRIBUIR & RETRIBUIR', false),
            occasionallyBgColor: calculateColor("Quase Nunca", 'CONTRIBUIR & RETRIBUIR', false),
            sometimesBgColor: calculateColor("Quase Sempre", 'CONTRIBUIR & RETRIBUIR', false),
            alwaysBgColor: calculateColor("Sempre", 'CONTRIBUIR & RETRIBUIR', false),
            nothingBgColor: calculateColor("N/A", 'CONTRIBUIR & RETRIBUIR', false),

            subItems: [
                {
                    axisname: 'H16',

                    never: `${data.observations.filter(o => o.skill === 'H16' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H16' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H16' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H16' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H16' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H16', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H16', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H16', true),
                    alwaysBgColor: calculateColor("Sempre", 'H16', true),
                    nothingBgColor: calculateColor("N/A", 'H16', true),
                }, {
                    axisname: 'H17',

                    never: `${data.observations.filter(o => o.skill === 'H17' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H17' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H17' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H17' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H17' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H17', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H17', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H17', true),
                    alwaysBgColor: calculateColor("Sempre", 'H17', true),
                    nothingBgColor: calculateColor("N/A", 'H17', true),
                }, {
                    axisname: 'H18',

                    never: `${data.observations.filter(o => o.skill === 'H18' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H18' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H18' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H18' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H18' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H18', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H18', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H18', true),
                    alwaysBgColor: calculateColor("Sempre", 'H18', true),
                    nothingBgColor: calculateColor("N/A", 'H18', true),
                }, {
                    axisname: 'H19',

                    never: `${data.observations.filter(o => o.skill === 'H19' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H19' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H19' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H19' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H19' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H19', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H19', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H19', true),
                    alwaysBgColor: calculateColor("Sempre", 'H19', true),
                    nothingBgColor: calculateColor("N/A", 'H19', true),
                }, {
                    axisname: 'H20',

                    never: `${data.observations.filter(o => o.skill === 'H20' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H20' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H20' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H20' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H20' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H20', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H20', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H20', true),
                    alwaysBgColor: calculateColor("Sempre", 'H20', true),
                    nothingBgColor: calculateColor("N/A", 'H20', true),
                }
            ]
        }, {
            id: 3,
            axisname: 'Auto Sup.',

            never: `${data.observations.filter(o => o.block === 'AUTOSSUPERAÇÃO' && o.observation === 'Nunca').length} Ocorrências`,
            occasionally: `${data.observations.filter(o => o.block === 'AUTOSSUPERAÇÃO' && o.observation === 'Quase Nunca').length} Ocorrências`,
            sometimes: `${data.observations.filter(o => o.block === 'AUTOSSUPERAÇÃO' && o.observation === 'Quase Sempre').length} Ocorrências`,
            always: `${data.observations.filter(o => o.block === 'AUTOSSUPERAÇÃO' && o.observation === 'Sempre').length} Ocorrências`,
            nothing: `${data.observations.filter(o => o.block === 'AUTOSSUPERAÇÃO' && o.observation === 'N/A').length} Ocorrências`,

            neverBgColor: calculateColor("Nunca", 'AUTOSSUPERAÇÃO', false),
            occasionallyBgColor: calculateColor("Quase Nunca", 'AUTOSSUPERAÇÃO', false),
            sometimesBgColor: calculateColor("Quase Sempre", 'AUTOSSUPERAÇÃO', false),
            alwaysBgColor: calculateColor("Sempre", 'AUTOSSUPERAÇÃO', false),
            nothingBgColor: calculateColor("N/A", 'AUTOSSUPERAÇÃO', false),

            subItems: [
                {
                    axisname: 'H21',

                    never: `${data.observations.filter(o => o.skill === 'H21' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H21' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H21' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H21' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H21' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H21', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H21', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H21', true),
                    alwaysBgColor: calculateColor("Sempre", 'H21', true),
                    nothingBgColor: calculateColor("N/A", 'H21', true),
                }, {
                    axisname: 'H22',

                    never: `${data.observations.filter(o => o.skill === 'H22' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H22' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H22' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H22' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H22' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H22', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H22', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H22', true),
                    alwaysBgColor: calculateColor("Sempre", 'H22', true),
                    nothingBgColor: calculateColor("N/A", 'H22', true),
                }, {
                    axisname: 'H23',

                    never: `${data.observations.filter(o => o.skill === 'H23' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H23' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H23' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H23' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H23' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H23', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H23', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H23', true),
                    alwaysBgColor: calculateColor("Sempre", 'H23', true),
                    nothingBgColor: calculateColor("N/A", 'H23', true),
                }, {
                    axisname: 'H24',

                    never: `${data.observations.filter(o => o.skill === 'H24' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H24' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H24' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H24' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H24' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H24', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H24', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H24', true),
                    alwaysBgColor: calculateColor("Sempre", 'H24', true),
                    nothingBgColor: calculateColor("N/A", 'H24', true),
                }, {
                    axisname: 'H25',

                    never: `${data.observations.filter(o => o.skill === 'H25' && o.observation === 'Nunca').length} Ocorrências`,
                    occasionally: `${data.observations.filter(o => o.skill === 'H25' && o.observation === 'Quase Nunca').length} Ocorrências`,
                    sometimes: `${data.observations.filter(o => o.skill === 'H25' && o.observation === 'Quase Sempre').length} Ocorrências`,
                    always: `${data.observations.filter(o => o.skill === 'H25' && o.observation === 'Sempre').length} Ocorrências`,
                    nothing: `${data.observations.filter(o => o.skill === 'H25' && o.observation === 'N/A').length} Ocorrências`,

                    neverBgColor: calculateColor("Nunca", 'H25', true),
                    occasionallyBgColor: calculateColor("Quase Nunca", 'H25', true),
                    sometimesBgColor: calculateColor("Quase Sempre", 'H25', true),
                    alwaysBgColor: calculateColor("Sempre", 'H25', true),
                    nothingBgColor: calculateColor("N/A", 'H25', true),
                }
            ]
        }
    ]

    return formated
}