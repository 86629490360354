import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Button, Checkbox, Label, Pagination, Spinner, useToast } from 'react-alicerce-components';
import { WrapperContent } from './styles';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router';
import ListPlacesService, { IListPlaceOptions } from '@modules/places/services/ListPlacesService';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import IPlace from '@modules/places/interfaces/IPlace';
import PlaceCard from '@modules/places/components/PlaceCard';
import { usePlaces } from '@modules/places/context/PlacesContext';
import { BsCheckLg } from 'react-icons/bs';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { PLACE_CATEGORY_CONSTRAINTS } from '../../utils/validData/validPlaceCategory';
import Divider from '@shared/components/Divider';
import { PLACE_STATUS_CONSTRAINTS } from '@modules/places/utils/validData/validPlaceStatus';

interface IPlaceList {
  title: string;
  btnText: string;
  pathToReturn?: string;
  pathRedirect?: string;
  canSelectPlace?: 'one' | 'many';
  handleOnClick?: (placeCurrent: any) => void;
  selectedItens?: IPlace[];
  setSelectedItens?: (placeCurrent: IPlace[]) => void;
  listOptions?: IListPlaceOptions;
  isAdvancedFilterDisabled?: boolean;
}

const PlaceList: React.FC<IPlaceList> = ({
  title,
  btnText,
  pathRedirect,
  pathToReturn,
  canSelectPlace,
  handleOnClick,
  selectedItens,
  setSelectedItens,
  listOptions = {},
  isAdvancedFilterDisabled,
}) => {
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const { clearPlaceCreate, placeCurrent } = usePlaces();
  const navigate = useNavigate();
  const placeFilter = useRef<IListPlaceOptions>(listOptions);

  const [places, setPlaces] = useState<IPlace[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalOfPages, setTotalOfPages] = useState<number>(0);
  const [inputSearch, setInputSearch] = useState<string>('');

  const listPlaces = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListPlacesService(signedUser.token, newCancelToken()).execute(inputSearch, { page, size: 10, ...placeFilter.current });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) {
        addToast({
          status: 'danger',
          title: 'Lugares',
          description: 'Error ao listar Lugares.',
        });
        return;
      }

      setPlaces(res.items);
      setCurrentPage(res.currentPage);
      setTotalOfPages(res.totalPages);
    },
    [addToast, inputSearch, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listPlaces();
  }, [listPlaces]);

  const checkSelectedPlaces = useCallback((selectedPlaces: IPlace[], clickedPlace: IPlace): false | IPlace[] => {
    const found = selectedPlaces.findIndex((p) => p.id === clickedPlace.id);
    if (found === -1) return false;
    selectedPlaces.splice(found, 1);
    return selectedPlaces;
  }, []);

  const handleClickDiv = useCallback(
    (placeClicked: IPlace) => {
      if (!canSelectPlace) navigate(`/local/${placeClicked.id}`);

      if (canSelectPlace === 'one' && selectedItens && setSelectedItens) {
        if (selectedItens[0]?.id === placeClicked.id) return setSelectedItens([]);
        setSelectedItens([placeClicked]);
      } else if (canSelectPlace === 'many' && setSelectedItens) {
        const res = checkSelectedPlaces(selectedItens || [], placeClicked);
        if (!res) return setSelectedItens([...(selectedItens || []), placeClicked]);
        setSelectedItens([...res]);
      }
    },
    [canSelectPlace, checkSelectedPlaces, navigate, selectedItens, setSelectedItens]
  );

  const handleClickBtn = useCallback(() => {
    if (canSelectPlace === 'one' && selectedItens) {
      handleOnClick && selectedItens[0] && handleOnClick(selectedItens[0]);
    } else if (canSelectPlace === 'many' && selectedItens) {
      handleOnClick && handleOnClick(selectedItens);
    } else clearPlaceCreate();
    pathRedirect ? navigate(pathRedirect) : navigate(-1);
  }, [canSelectPlace, clearPlaceCreate, handleOnClick, navigate, pathRedirect, selectedItens]);

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objKey: string) => {
      const isChecked = event.target.checked;
      const value = event.target.id;

      if (isChecked) {
        if (!placeFilter.current[objKey]) {
          placeFilter.current[objKey] = [value];
          return;
        }

        placeFilter.current[objKey].push(value);
      } else {
        if (!placeFilter.current[objKey]) return;
        const filtered = placeFilter.current[objKey].filter((foo: string) => foo !== value);
        placeFilter.current[objKey] = filtered;
      }
    },
    [placeFilter]
  );

  const handleResetFilter = () => {
    placeFilter.current = {};
    listPlaces();
  };

  const isChecked = (objKey: string, value: string) => {
    if (!placeFilter.current[objKey]) return false;
    return placeFilter.current[objKey].find((foo: string) => foo === value);
  };

  return (
    <Fragment>
      <HeaderDirectory status="primary" title={title} pathToReturn={pathToReturn} menuKebab={{ tooltip: { view: 'PlaceDirectory' } }} />
      {isLoading && <Spinner fixed />}
      <DirectoryContainer footer={true}>
        <WrapperContent>
          {/* START FILTER */}
          <AdvancedFilter
            disabled={isAdvancedFilterDisabled}
            onChangeMainInput={(newValue) => setInputSearch(newValue)}
            applyFilterButtonBackground="#F8A356"
            handleResetFilter={handleResetFilter}
            handleApplyFilter={listPlaces}
          >
            <Label>Status:</Label>
            <section className="checkbox-section">
              {PLACE_STATUS_CONSTRAINTS.map((pc) => (
                <Checkbox
                  key={`${pc.value}`}
                  id={pc.value}
                  name={pc.value}
                  label={pc.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'status');
                  }}
                  defaultChecked={isChecked('status', pc.value)}
                />
              ))}
            </section>

            <Label>Categoria:</Label>
            <section className="checkbox-section">
              {PLACE_CATEGORY_CONSTRAINTS.map((pc) => (
                <Checkbox
                  key={`${pc.value}`}
                  id={pc.value}
                  name={pc.value}
                  label={pc.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'categories');
                  }}
                  defaultChecked={isChecked('categories', pc.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Virtual:</Label>
            <section className="checkbox-section">
              <div className="radio-button">
                <input
                  type="radio"
                  name="virtual_place"
                  id="virtual"
                  onChange={() => (placeFilter.current.virtual_place = true)}
                  defaultChecked={placeFilter.current.virtual_place === true}
                />
                <label htmlFor="virtual">Virtual</label>
              </div>

              <div className="radio-button">
                <input
                  type="radio"
                  name="virtual_place"
                  id="not-virtual"
                  onChange={() => (placeFilter.current.virtual_place = false)}
                  defaultChecked={placeFilter.current.virtual_place === false}
                />
                <label htmlFor="not-virtual">Presencial</label>
              </div>

              <div className="radio-button">
                <input
                  type="radio"
                  name="virtual_place"
                  id="both"
                  onChange={() => (placeFilter.current.virtual_place = undefined)}
                  defaultChecked={placeFilter.current.virtual_place === undefined}
                />
                <label htmlFor="both">Ambos</label>
              </div>
            </section>
          </AdvancedFilter>
          {/* END FILTER */}
          <div className="container-all-places">
            {places.map((p) =>
              canSelectPlace && placeCurrent?.id === p.id ? (
                <Fragment key={p.id} />
              ) : (
                <PlaceCard key={p.id} place={p} canSelect={!!canSelectPlace} handleOnClick={handleClickDiv} placesSelected={selectedItens} />
              )
            )}
          </div>

          {!!places.length && (
            <Pagination totalOfPages={totalOfPages} currentPage={currentPage} callbackGetListData={(_, page) => listPlaces(page)} status="primary" />
          )}
        </WrapperContent>

        <PageFooter>
          <Button transform="none" status="primary" shaded onClick={handleClickBtn}>
            {btnText}
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Fragment>
  );
};

export default PlaceList;
