import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import ITag from '../../tags/interfaces/ITag';
import { ISkill } from '@shared/interfaces/ISkill';
import { ACTIVITY_PROGRAM_STATUS } from '../utils/validActivityStatus';

export type IActivityLibraryContextContextData = {
  activityLibraryCurrent: IActivityLibrary;
  setActivityLibraryCurrent: (activity: any) => void;
  clearActivityLibraryContextData: () => void;
  activityLibraryFilter: React.MutableRefObject<IActivityLibraryFilter>;
  resetActivityFilter: () => void;
};

export interface IActivityLibraryFilter {
  duration?: string;
  created_by?: number;
  age_groups?: string[];
  student_amount?: string[];
  modality?: string[];
  cognitive_tasks?: string[];
  skills?: ISkill[];

  type?: ITag;
  theme?: ITag;
  subtheme?: ITag;
  thematic?: ITag;

  subTheme?: ITag;
  mainTheme?: ITag;
}

export interface IActivityFilterFormatted extends IActivityFilterTheme {
  duration?: string;
  created_by?: number;
  age_groups?: string[];
  student_amount?: string[];
  modality?: string[];
  cognitive_tasks?: string[];
  skill_ids?: number[];
  status?: ACTIVITY_PROGRAM_STATUS;
}

export interface IActivityFilterTheme {
  type_tag_id?: number | string;
  theme_tag_id?: number | string;
  subtheme_tag_id?: number | string;
  thematic_tag_id?: number | string;
}

const ActivityLibraryContext = createContext<IActivityLibraryContextContextData>({} as IActivityLibraryContextContextData);

const ActivityLibraryProvider: React.FC = ({ children }) => {
  const [activityLibraryCurrent, setActivityLibraryCurrent] = useState<IActivityLibrary>({ moments: [''] });
  const activityLibraryFilter = useRef<IActivityLibraryFilter>({});

  const clearActivityLibraryContextData = useCallback(() => {
    setActivityLibraryCurrent({ moments: [''] });
  }, []);

  const resetActivityFilter = useCallback(() => {
    activityLibraryFilter.current = {};
  }, []);

  return (
    <ActivityLibraryContext.Provider
      value={{
        activityLibraryFilter,
        activityLibraryCurrent,
        setActivityLibraryCurrent,
        clearActivityLibraryContextData,
        resetActivityFilter,
      }}
    >
      {children}
    </ActivityLibraryContext.Provider>
  );
};

function useActivity() {
  const context = useContext(ActivityLibraryContext);

  if (!context) {
    throw new Error('useActivity must be used within a ActivityLibraryProvider');
  }

  return context;
}

export { ActivityLibraryProvider, useActivity };
