import React, { useRef, MouseEvent, useCallback } from 'react';

import { CSSProperties } from 'styled-components';
import Icon from 'react-eva-icons';

import useAuth from '@shared/store/auth/hook';

import { useHeaderMain } from '@shared/components/Headers/HeaderMain/context/HeaderMainContext';
import Navbar from '@shared/components/Headers/Navbar';
import SideBar from '@shared/components/Headers/HeaderMain/SideBar';

import headerIconDefault from '@shared/assets/capivaras/aluno.svg';
import HeaderImgDefault from '@shared/assets/bg_header.svg';

// import Notifications from '../Notifications';

import { Container, SidebarContainer, HeaderMainDefaultContent, HeaderMainUserInfo, HeaderMainUserIcon } from './styles';
import { useNavigate } from 'react-router-dom';
import { IMenuKebab, MenuKebab } from '../../../MenuKebab';

export interface IHeaderMainProps {
  left?: JSX.Element | React.ReactNode;
  right?: JSX.Element | React.ReactNode;
  useDefaultNav?: boolean;
  bgHeader?: string;
  title?: string;
  subtitle?: string;
  content?: JSX.Element | React.ReactNode;
  customStyle?: CSSProperties;
  bgColor?: string;
  type?: string;
  compressOnScroll?: boolean;
  isScrolled?: boolean;
  pathToReturn?: string;
  pathToReturnDefault?: boolean;
  menuKebab?: IMenuKebab;
}

const HeaderMainContainer: React.FC<IHeaderMainProps> = ({
  left,
  right,
  bgHeader,
  title = '',
  subtitle,
  content,
  customStyle,
  bgColor,
  pathToReturn,
  type,
  useDefaultNav = false,
  compressOnScroll = false,
  isScrolled = false,
  pathToReturnDefault,
  menuKebab,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { signedUser } = useAuth();
  const { toggleSideBar, isShowSideBar } = useHeaderMain();
  const navigate = useNavigate();

  const handleClickOut = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      let current = containerRef?.current;
      const node: Node = e.target as Node;
      if (current && current === node) {
        toggleSideBar();
      }
    },
    [toggleSideBar]
  );

  return (
    <Container
      className="header-container"
      bgHeader={bgHeader || HeaderImgDefault}
      bgColor={bgColor}
      style={customStyle}
      compressOnScroll={compressOnScroll}
      isScrolled={isScrolled}
    >
      {menuKebab && <MenuKebab {...menuKebab} />}
      {useDefaultNav && (
        <HeaderMainDefaultContent>
          {pathToReturn && (
            <div className="return-icon-left" onClick={() => navigate(pathToReturn)}>
              <Icon name="arrow-ios-back" fill="#FFFFFF" />
            </div>
          )}
          {!!pathToReturnDefault && (
            <div className="return-icon-left" onClick={() => navigate(-1)}>
              <Icon name="arrow-ios-back" fill="#FFFFFF" />
            </div>
          )}
          <HeaderMainUserInfo>
            <h6>{type ? type : 'Usuario'}</h6>
            <p>{title ? title : 'Olá,'}</p>
            <p>{subtitle ? subtitle : signedUser?.lmsUser.name}</p>
          </HeaderMainUserInfo>
          <HeaderMainUserIcon userIcon={headerIconDefault} />
        </HeaderMainDefaultContent>
      )}

      {/* {useDefaultNav && <Notifications />} */}

      {!useDefaultNav && <Navbar title={title} subtitle={subtitle} left={left} right={right} />}

      {content}

      {useDefaultNav && !pathToReturn && !pathToReturnDefault && (
        <SidebarContainer ref={containerRef} onClick={handleClickOut} isOpened={isShowSideBar}>
          <SideBar />
        </SidebarContainer>
      )}
    </Container>
  );
};

export default HeaderMainContainer;
