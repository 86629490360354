import ApiFactory from "@shared/factories/ApiFactory";
import axios, { AxiosInstance, CancelTokenSource } from "axios";
import { IUser } from "@modules/users/interfaces/IUser";

class UpdateUserService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(userId: string | number, userBody: IUser): Promise<boolean> {
    try {
      await this.api.put(`lms-user/${userId}`, userBody);
      return true;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default UpdateUserService;
