import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import ProjectReportForm from '@modules/projects/components/ProjectReportForm';
import { formatReport } from '@modules/projects/utils/formatReport';
import IProjectReport, { IFormatedReport } from '@modules/projects/interfaces/IProjectReport';
import { ProjectReportSchema } from '@modules/projects/schemas/ProjectReport';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { splitAttachmentFiles } from '@shared/utils/splitAttachmentFiles';
import UploadService from '@shared/services/UploadFile';
import { formatedFileUpload } from '@modules/activities-library/utils/formatedFileUpload';
import CreateReportService from '@modules/projects/services/Report/CreateReportService';
import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';

const ProjectReportCreate: React.FC = () => {
  
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { clearReportCreate, clearProjectCreate, reportCurrent, setReportCurrent, projectCurrent, setCurrentSprint } = useProjects();
  const { id: projectId } = useParams();
  const dirPath = `/projeto/${projectId}/steerings`

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [formatedReportBody, setFormatedReportBody] = useState<IFormatedReport>();

  const getCurrentSprint= useCallback(async (scheduleId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (scheduleId) {
      const sprint = await new GetCurrentSprintService(signedUser.token).execute({ schedule_id: Number(scheduleId) });
      if (!sprint) return;
      setCurrentSprint(sprint);
      setReportCurrent((oldState: IProjectReport) => {
        return { ...oldState, sprint_id: Number(sprint.id) }
      })
    }
    setIsLoading(false);
  }, [signedUser, setIsLoading, setCurrentSprint, setReportCurrent]);

  useEffect(() => {
    projectCurrent && getCurrentSprint(projectCurrent?.schedule_id)
  }, [getCurrentSprint, projectCurrent]);

  const handleCancel = useCallback(() => {
    clearReportCreate();
    navigate(dirPath);
  }, [clearReportCreate, dirPath, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!reportCurrent) return;
    const formatedBody = formatReport(reportCurrent, Number(projectCurrent?.id || projectId)) as IFormatedReport;
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectReportSchema);
    setErros(canSubmitForm);
    setFormatedReportBody(formatedBody)
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [projectCurrent, projectId, reportCurrent]);

  const handleCreateProjectReport = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !reportCurrent) return;
    
    setIsLoading(true);
    let formattedAttachmentsToSave: IAttachment[] = [];

    const { newFiles, oldFiles } = splitAttachmentFiles(formatedReportBody?.attachments);
    if (newFiles && newFiles.length > 0) {
      
      const res = await new UploadService(signedUser?.token).uploadFile(newFiles, 'report/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      const formattedAttachments = formatedFileUpload(newFiles);
      formattedAttachmentsToSave = formattedAttachments.map((attachment, i) => ({ ...attachment, url: res[i].dbURL }));
    }
    
    let auxFormattedBody = { ...formatedReportBody };
    if (formattedAttachmentsToSave.length > 0) auxFormattedBody.attachments = [...oldFiles, ...formattedAttachmentsToSave];
    
    const res = await new CreateReportService(signedUser.token).execute(auxFormattedBody)
    setIsLoading(false);
    if (res) {
      clearProjectCreate()
      addToast({
        status: 'success',
        title: 'Report',
        description: 'Report criada com sucesso.',
      });
      clearReportCreate();
      return navigate(`/diretorio/projetos/report/${res.id}`);
    }

    addToast({
      status: 'danger',
      title: 'Report',
      description: 'Não foi possível criar o Report.',
    });
  }, [signedUser, reportCurrent, setIsLoading, formatedReportBody, addToast, clearProjectCreate, clearReportCreate, navigate]);

  useEffect(() => {
    if (reportCurrent && reportCurrent.id) {
      clearReportCreate()
    }
  }, [clearReportCreate, reportCurrent])

  return (
    <Fragment>
      <ProjectReportForm
        headerTitle="Criar Report"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar este Report?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateProjectReport}
      />
    </Fragment>
  );
};

export default ProjectReportCreate;
