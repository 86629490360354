import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useActivity } from '../../context/ActivityContext';
import { IActivityLibrary } from '../../interfaces/IActivityLibrary';

const ActivityLibraryTagRelation: React.FC = () => {
  const navigate = useNavigate();
  const { activityLibraryCurrent, setActivityLibraryCurrent } = useActivity();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setActivityLibraryCurrent((oldState: IActivityLibrary) => {
        return { ...oldState, tags: tags };
      });
    },
    [setActivityLibraryCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={activityLibraryCurrent.tags}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
      buttonCallback={() => navigate(-1)}
    />
  );
};

export { ActivityLibraryTagRelation };
