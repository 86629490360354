import { css, styled } from 'react-alicerce-components';

export const LearningTagertFormContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;
  `}

  .icon-container {
    margin-top: 10px;
    i {
      display: flex;
      justify-content: center;
      svg {
        width: 90px;
        height: 90px;
      }
    }
  }

  .subtitle {
    text-align: center;
    padding: 0px 15px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 9px;
    color: #2e3a59;
  }

  .title {
    text-align: center;
    margin-bottom: 16px;
    font-weight: 800;
    font-size: 22px;
    line-height: 34px;
    color: #222b45;
  }

  .description {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
  }
`;

export const ButtonFooterContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    border-top: 1px solid #c5cee0;
    padding: 18px 16px;

    button {
      border: 2px solid #8d593b;
      border-radius: 16px;
    }
  `}
`;
