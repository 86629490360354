import React from 'react';
import { Calendar, ICalendarProps } from 'react-alicerce-components';

import { Container } from './styles';

const CustomCalendar: React.FC<ICalendarProps> = (props) => {
  return (
    <Container className="custom-calendar">
      <Calendar {...props} />
    </Container>
  );
};

export { CustomCalendar };
