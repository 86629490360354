import { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';

export interface IBackVersion {
  success: boolean;
  data: string;
  code: number;
}

export class GetVersionService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(): Promise<IBackVersion | false> {
    try {
      const res = await this.api.get(`/services/backend/version`);
      return res.data;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}
