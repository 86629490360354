import React, { useCallback, useState } from 'react';

import { Accordion } from 'react-alicerce-components';

import { Container, IStyledAccordion, AccordionMessageError } from './styles';

export type IAccordionProps = IStyledAccordion & {
  errorMessage?: string;
  hasErrors?: string;
};

const AccordionInfoItem: React.FC<IAccordionProps> = ({
  children,
  status = 'quaternary',
  refSize,
  handleClickOpenClose,
  isShow = false,
  errorMessage = 'Existem campos a serem preenchidos.',
  hasErrors = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isShow);

  const handleClick = useCallback(() => {
    handleClickOpenClose && handleClickOpenClose(!isOpen);
    setIsOpen(!isOpen);
  }, [handleClickOpenClose, isOpen]);

  return (
    <Container status={status} {...rest} isOpen={isOpen} className="accordion-info-item-container">
      {hasErrors && <AccordionMessageError>{errorMessage}</AccordionMessageError>}
      <Accordion ref={refSize} {...rest} handleOnHeaderClick={handleClick}>
        {children}
      </Accordion>
    </Container>
  );
};

export default AccordionInfoItem;
