import styled from "styled-components";

export const Container = styled.div.attrs(props => {
})`
    display: flex;
    border: 1px solid #C5CEE0;
    border-radius: 16px;
    flex-wrap: wrap;

    min-height: 36px;


    padding: 5px 8px;
    
    font-size: 12px;
    color: #2E3A59;

    .wrapper {
        margin-left: 1.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    label {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0 5px 0 0;
    }

    .body {
        display: flex;
        align-items: center;

        i {
            display: flex;
            align-items: center;
            padding-right: 5px;
        }
    }

    .checkbox {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-justify: center;
    }
    .checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkbox input:checked ~ .checkbox__checkmark {
        background-color: #42AAFF;
    }
    .checkbox input:checked ~ .checkbox__checkmark:after {
        opacity: 1;
    }
    .checkbox .checkbox__checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        transition: background-color 0.25s ease;
        border-radius: 4px;
        border: 1px solid #C5CEE0;
    }
    .checkbox .checkbox__checkmark:after {
        content: "";
        position: absolute;
        left: 7px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        border-radius: 1px;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.25s ease;
    }
`