import { styled } from 'react-alicerce-components';

export const SprintSelectContainer = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 14px;
        font-weight: 600;
    }

    .sprint-select-icon {
      cursor: pointer;
      margin: 0px 10px;

      i {
        display: flex;
        align-items: center;
        font-weight: 600;
        width: 16px;
        height: 16px;

        svg {
          width: 16px;
          height: 16px;
          fill: #2E3A59;
        }
      }
    }
`;
