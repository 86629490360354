import React, { useEffect, useState } from 'react';

import { StudentCardLauncherBodyContainer } from './styles';

export interface StudentCardLauncherBodyProps {
  isTrailsOpen: boolean;
}

const StudentCardLauncherBody: React.FC<StudentCardLauncherBodyProps> = (props) => {
  const [openTrails, setOpenTrails] = useState<boolean>(false);

  useEffect(() => {
    setOpenTrails(props.isTrailsOpen);
  }, [props.isTrailsOpen]);

  return <StudentCardLauncherBodyContainer isTrailsOpen={openTrails}>{props.children}</StudentCardLauncherBodyContainer>;
};

export { StudentCardLauncherBody };
