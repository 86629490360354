import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import { useTags } from '@modules/tags/context/TagsContext';
import ITag from '@modules/tags/interfaces/ITag';

const TagsDirectory: React.FC = () => {
  const navigate = useNavigate();
  const { clearTagCurrent } = useTags();

  const overideOnTagClick = useCallback(
    (tag: ITag) => {
      navigate(`/diretorio/tags/${tag.id}/informacoes`);
    },
    [navigate]
  );

  const handleButtonCallback = useCallback(() => {
    navigate('/diretorio/tags/criar');
  }, [navigate]);

  useEffect(() => {
    clearTagCurrent();
  }, [clearTagCurrent]);

  return (
    <TagsList
      overideOnTagClick={overideOnTagClick}
      canQuantitySelect={0}
      useSearch
      pathToReturn="/"
      buttonText="Criar Tag"
      buttonCallback={handleButtonCallback}
    />
  );
};

export default TagsDirectory;
