import React, { useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner, Label } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { IEvent } from '@modules/schedules/interfaces/IEvent';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import DirectoryContainer from '@shared/components/DirectoryContainer';

import ScheduleEventForm from '@modules/schedules/components/ScheduleEventForm';
import CreateScheduleEventService from '@modules/schedules/services/CreateScheduleEventService';

import { Container } from './styles';
import ButtonAdd from '@shared/components/ButtonAdd';

const TaskCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const { state, clearScheduleEventCurrent, scheduleCurrent } = useSchedules();

  const handleCreateEvent = useCallback(
    async (dataValidated: IEvent) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new CreateScheduleEventService(signedUser.token).execute(dataValidated);

      setIsLoading(false);

      if (!res) {
        addToast({
          status: 'danger',
          title: 'Tarefa',
          description: 'Falha ao tentar criar Tarefa. Tente novamente.',
        });
        return;
      }

      clearScheduleEventCurrent();
      navigate('/tarefas/pendentes', { state });
    },
    [signedUser, setIsLoading, clearScheduleEventCurrent, navigate, state, addToast]
  );

  return (
    <Container>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory title="Evento" backgroundHeader="linear-gradient(105.34deg, #6E3FA5 1.9%, #542E91 98.48%);" />
      <DirectoryContainer style={{ margin: '0', padding: '0 0 2rem 0' }} footer>
        <ScheduleEventForm
          canUserEdit={true}
          handleOnFinish={handleCreateEvent}
          formRef={formRef}
          lockType="task"
          status="quaternary"
          statusNumber={400}
          handleScheduleOnClick={() => navigate('/tarefas/selecionar/agenda')}
        />

        {!scheduleCurrent?.id && (
          <div className="label-container">
            <ButtonAdd handleOnClick={() => navigate('/tarefas/selecionar/agenda')} />
            <Label>Adicionar uma Agenda:</Label>
          </div>
        )}

        <PageFooter>
          <ButtonGradientOutline status="quaternary" onClick={() => navigate(-1)}>
            Cancelar
          </ButtonGradientOutline>

          <Button onClick={() => formRef?.current?.submitForm()} color="#fff" transform="none" status="quaternary" statusNumber={500} shaded>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export default TaskCreate;
