import React, { useCallback, useMemo, useState } from 'react';

import IClass from '@modules/classes/interfaces/IClass';
import ClassesList from '@modules/classes/components/ClassesList';
import { useWorkedHour } from '@modules/worked-hours/context/WorkedHourContext';
import { useSearchParams } from 'react-router-dom';

const WorkedHoursClassesRelation: React.FC = () => {
  const { setWorkedHourCurrent } = useWorkedHour();
  const [selectedClass, setSelectedClasses] = useState<IClass>();
  const [searchParams] = useSearchParams();

  const handleClickBtn = useCallback(() => {
    setWorkedHourCurrent((oldState) => {
      return { ...oldState, class: selectedClass };
    });
  }, [selectedClass, setWorkedHourCurrent]);

  const setSelectedItens = useCallback((classesSelected: IClass[]) => {
    setSelectedClasses(classesSelected[0]);
  }, []);

  const selectedGroups = useMemo(() => {
    let aux = [] as IClass[];
    if (selectedClass) aux.push(selectedClass);
    return aux;
  }, [selectedClass]);

  const pathToRedirect = useMemo(() => {
    const redirectTo = searchParams.get('redirect_to');
    if (redirectTo) return redirectTo;
    if (selectedClass?.id) return `/horas-trabalhadas/turma/${selectedClass?.id}/criar`;
    return undefined;
  }, [searchParams, selectedClass]);

  return (
    <ClassesList
      headerTitle="Diretório de Turmas"
      btnText="Adicionar Turma"
      selectedClass={selectedGroups}
      canSelect="one"
      setSelectedClass={setSelectedItens}
      handleOnClick={handleClickBtn}
      pathRedirect={pathToRedirect}
    />
  );
};

export { WorkedHoursClassesRelation };
