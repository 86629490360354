import { styled } from 'react-alicerce-components';

export const WrapperContent = styled.div`
  .class-advanced-filter {
    margin: 1rem 0rem;
  }

  .custom-card > div {
    margin: var(--card-distance);
  }

  .input-container {
    margin-bottom: 45px;
    div {
      border-color: #c5cee0 !important;
    }
  }
`;
