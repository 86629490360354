import GetStudentService from '@modules/students/services/GetStudentService';
import { TYPE_RESPONSIBLE, TYPE_RESPONSIBLE_FOR_PICKING } from '@modules/students/utils/validData/validResponsible';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { IStudent } from '../interfaces/IStudent';

export type IStudentContextData = {
  selectedStudent: IStudent | undefined;
  setSelectedStudent: (studentCurrent: IStudent | undefined) => void;
  studentCurrent: IStudent;
  setStudentCurrent: (value: any) => void;
  clearStudentContextData: () => void;
  getStudent: (studentId: string) => Promise<boolean>;
};

const StudentContext = createContext<IStudentContextData>({} as IStudentContextData);

const StudentProvider: React.FC = ({ children }) => {
  const { newCancelToken } = useCancelRequest();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();

  const [selectedStudent, setSelectedStudent] = useState<IStudent>();
  const [studentCurrent, setStudentCurrent] = useState<IStudent>({});

  const clearStudentContextData = useCallback(() => {
    setStudentCurrent({});
  }, []);

  const getStudent = useCallback(
    async (studentId: string) => {
      if (!studentId) return false;
      setIsLoading(true);
      const studentFound = await new GetStudentService(signedUser?.token, newCancelToken()).execute({studentId, grades: true});
      setIsLoading(false);
      if (studentFound) {
        const responsible = studentFound.responsibles?.find((responsible) => responsible.type === TYPE_RESPONSIBLE);
        const responsibleForPicking = studentFound.responsibles?.filter((responsible) => responsible.type === TYPE_RESPONSIBLE_FOR_PICKING);
        const systemTag = (studentFound.tags || []).filter((t) => t && t.type === 'system');
        const projectTag = (studentFound.tags || []).filter((t) => t && t.type === 'project');
        setStudentCurrent({
          ...studentFound,
          responsible: responsible,
          responsibles_for_picking: responsibleForPicking || [{ name: '', cpf: '' }],
          student_tags: systemTag,
          partner_organizations_tag: projectTag,
        });
        return true;
      }
      return false;
    },
    [newCancelToken, setIsLoading, setStudentCurrent, signedUser]
  );

  return (
    <StudentContext.Provider
      value={{
        studentCurrent,
        setStudentCurrent,
        clearStudentContextData,
        selectedStudent,
        setSelectedStudent,
        getStudent,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
};

function useStudent() {
  const context = useContext(StudentContext);

  if (!context) {
    throw new Error('useStudent must be used within a StudentProvider');
  }

  return context;
}

export { StudentProvider, useStudent };
