import { css, styled } from 'react-alicerce-components';

export const ValidateLearningTargerFormWarningContainer = styled.div`
${() => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow-y: auto;

    .skill-description {
        color: #222B45;
        margin-top: 0.7rem;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .radio-skill {
        border: 1px solid transparent;
    }
`}
`

export const CurrentBlockContainer = styled.div`
${() => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem .8rem;

    .current-block {
        font-size: 1.2rem !important;
        font-weight: 700;
        padding: 0.6rem;
        color: #2E3A59;
        width: 50%;
    }
`}
`

export const FieldSet = styled.fieldset<{ error?: string }>`
${({ error }) => css`  
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin: .8rem ;
    border-color: ${!!error ? '#FF3D71' : '#E4E9F2'};
    legend {
        color: #FF3D71;
    }
`}
`

export const PaginatedInMemory = styled.div`
${() => css`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 0.8rem;
    color: white;
    background-color: #B85EC4;
    border-radius: 1rem;
`}
i {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
