import { ContractCardProps } from '@modules/contracts/components/ContractCard';
import { P } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .card-header {
    padding: 1rem;
    background-color: #8D593B;
  }

  .card-body {
    border-radius: 0;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #DAD3CC;
    padding: 1rem;
  }

  .card-footer {
    border-radius: 0;
    border: none;

    .accordion-title {
      font-size: 14px;
      margin: 0px;
      font-weight: 600 !important;
    }

    .accordion-info-item-container {
      margin: 0;

      > div:first-child {
        min-height: 28px !important;
    }

     .accordion-header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.31rem;
      border-bottom-right-radius: 0.31rem;
      padding: 0 1rem;
      min-height: 28px !important;
      font-weight: 600 !important;
      background: #DAD3CC;
     }

     .accordion-header i svg {
      width: 28px !important;
      height: 28px !important;
    }

     .accordion-content {
        background-color: #DAD3CC;
        border-radius: 0;
        margin-top: -3px;
        padding: 1rem;
        border-bottom-left-radius: 0.31rem;
        border-bottom-right-radius: 0.31rem;
     }
    }
  }

  .tag {
    border-radius: 4px;
    padding: 3px 6px;
  }
  
  @media (min-width: 992px) {
    .accordion-header .accordion-title {
      font-weight: 600 !important;
    }   
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    font-size: 12px;
  }
`;

export const ContractName = styled(P)`
  font-weight: 900;
`;

export const ContractPartner = styled(P)`
  font-size: 12px;
`;

export const DurationContainer = styled.div<Pick<ContractCardProps, 'hideDescription'>>`
  display: flex;
  justify-content: center;
  background-color: #8D593B;
  flex-direction: column;
  padding: 1rem;
  border-bottom-left-radius:  ${({ hideDescription }) => hideDescription ? '6px' : 0};
  border-bottom-right-radius: ${({ hideDescription }) => hideDescription ? '6px' : 0};

  p {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  > div:last-child p {
    font-size: 12px;
  }
`;

