import React, { useState, useCallback, useEffect } from 'react';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Pagination } from 'react-alicerce-components';

import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import Layout from '@modules/schedules/components/Layout';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import WrapperContent from '@modules/schedules/components/WrapperContent';
import ScheduleEventCard from '@modules/schedules/components/ScheduleEventCard';
import ListSchedulesService, { IScheduleReponse } from '@modules/schedules/services/ListSchedulesService';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

const LocalSchedule: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { setIsLoading } = useGlobal();
  const { state, setScheduleCurrent, clearScheduleCurrent } = useSchedules();

  const [schedules, setSchedules] = useState<IScheduleReponse>();

  const listSchedules = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new ListSchedulesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        [`${state?.resource}`]: state?.id as string,
      });

      if (res === 'canceling') return;
      setIsLoading(false);

      if (!res) return;
      setSchedules(res);
    },
    [newCancelToken, setIsLoading, signedUser, state]
  );

  const showSchedule = useCallback(
    (schedule: ISchedule) => {
      setScheduleCurrent(schedule);
      navigate(`/agenda`, { state: state });
    },
    [navigate, setScheduleCurrent, state]
  );

  const handleCreateNewSchedule = useCallback(() => {
    clearScheduleCurrent();
    navigate('/agenda/criar', { state: state });
  }, [clearScheduleCurrent, navigate, state]);

  useEffect(() => {
    listSchedules();
  }, [listSchedules]);

  return (
    <Layout openTab={1}>
      <DirectoryContainer style={{ height: 'calc(100vh - 255px)', margin: '0', padding: '0' }} footer className="directory-container">
        <WrapperContent>
          <div style={{ marginBottom: '2rem' }}>
            <Input
              placeholder="Buscar"
              name="search"
              end={<Icon name="funnel" fill="#8F9BB3" />}
              onChange={(event) => listSchedules(event.target.value)}
            />
          </div>
          {schedules?.items.map((schedule, i) => (
            <ScheduleEventCard key={i} schedule={schedule} handleOnClick={() => showSchedule(schedule)} />
          ))}

          {!schedules?.items?.length && <p>Nenhuma agenda para mostrar...</p>}

          {!!schedules?.items?.length && (
            <Pagination
              totalOfPages={`${schedules?.totalPages}`}
              currentPage={`${schedules?.currentPage}`}
              callbackGetListData={listSchedules}
              status="basic"
            />
          )}
        </WrapperContent>
        <PageFooter>
          <Button transform="none" color="#fff" status="basic" statusNumber={600} shaded noBorder onClick={handleCreateNewSchedule}>
            Criar Agenda Local
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Layout>
  );
};

export default LocalSchedule;
