import React, { useCallback } from 'react';
import { P } from 'react-alicerce-components';
import CustomCard from '@shared/components/CustomCard';

import { Container } from './styles';
import IClass from '@modules/classes/interfaces/IClass';
import { CSSProperties } from 'styled-components';
import { translateString } from '@shared/utils/translateString';

interface IClassCard {
  classInfo: IClass;
  handleOnClick?: (clickedClass: IClass) => void;
  classesSelected?: IClass[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
}

const ClassCard: React.FC<IClassCard> = ({ classInfo, handleOnClick, classesSelected, canSelect, inputCard, canDeleteCard, customStyle }) => {
  const cardShadow = useCallback(() => {
    if (canSelect && classesSelected?.find((p) => p.id === classInfo.id)) return 'drop-shadow(0px 0px 28px #49C56C)';
    return 'none';
  }, [canSelect, classesSelected, classInfo.id]);

  return (
    <Container>
      <CustomCard
        handleOnClick={() => handleOnClick && handleOnClick(classInfo)}
        status="quaternary"
        header={<span>{classInfo.name}</span>}
        canDeleteCard={canDeleteCard}
        inputCard={inputCard}
        key={classInfo.id}
        customStyles={{
          border: 'none',
          filter: `${cardShadow()}`,
          ...customStyle,
        }}
      >
        <P textAlign="center">
          <span className="text-status">Status: </span>
          <span className="text-status-bold">{translateString(classInfo.status ? classInfo.status : '')}</span>
        </P>
      </CustomCard>
    </Container>
  );
};

export default ClassCard;
