import IAuth from "@modules/home/interfaces/IAuth";

export interface IAuthState {
    users: Array<IAuth>;
    signedUser: IAuth | undefined;
}

export enum Types {
    SET_AUTH_STATE = 'SET_AUTH_STATE',
    SET_USER = 'SET_USER',
    LOGOUT_SIGNED_USER = 'REMOVE_USER',
    SET_SIGNED_USER = 'SET_SIGNED_USER',
    SELECT_USER = 'SELECT_USER',
}