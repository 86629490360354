import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-enrollment-subscription-cards {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .button-cancel {
    background-color: #e9d7f9;
  }
`;
