import React from 'react';

import Layout from '@modules/contracts/components/Layout';

import { ContractDefaultStylesContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';
import { ContractProjectsFragment } from '@modules/contracts/components/ContractForm/partials/ContractProjectsFragment';

const ContractProjects: React.FC = () => {
  return (
    <Layout openTab={1}>
      <ContractDefaultStylesContainer>
        <ContractProjectsFragment disabled />
      </ContractDefaultStylesContainer>
    </Layout>
  );
};

export default ContractProjects;
