import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Question = styled.div`
  background: #edf1f7;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  background: #6749ba;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-size: 14px;
  color: #fff;
  font-weight: 800;
  justify-content: space-between;

  .compPill {
    margin-right: 15px;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  padding: 1rem;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 800;
  color: #6749ba;
  margin-bottom: 12px;
`;

export const Box = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => (bgColor ? bgColor : '#6749BA')};
  text-align: justify;
  font-size: 12px;
  font-weight: 600;
  padding: 14px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #fff;

  img {
    width: auto;
    max-width: 100%;
    background: #edf1f7;
  }
`;
