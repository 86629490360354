import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import Icon from 'react-eva-icons';

import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import DirectoryContainer from '@shared/components/DirectoryContainer';

import ScheduleEventForm from '@modules/schedules/components/ScheduleEventForm';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import ConfirmModal from '@shared/components/ConfirmModal';
import EditScheduleEventService from '@modules/schedules/services/EditScheduleEventService';
import DeleteScheduleEventService from '@modules/schedules/services/DeleteScheduleEventService';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import GetScheduleEventService from '../../services/GetScheduleEventService';

const ScheduleEventEdit: React.FC = () => {
  const { setEventCurrent, eventCurrent, state, scheduleCurrent, setScheduleCurrent } = useSchedules();
  const { isLoading, setIsLoading } = useGlobal();
  const formRef = useRef<FormHandles>(null);
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (dataValidated: ISchedule) => {
      setEventCurrent(dataValidated);
      setShowEditModal(true);
    },
    [setEventCurrent]
  );

  const handleUpdateEvent = useCallback(async () => {
    setShowEditModal(false);
    if (!signedUser || !eventCurrent?.id) return;

    setIsLoading(true);

    const res = await new EditScheduleEventService(signedUser.token).execute(eventCurrent);

    setIsLoading(false);

    addToast({
      status: res ? 'success' : 'danger',
      title: 'Evento',
      description: res ? 'Evento editado com sucesso.' : 'Não foi possível editar o Evento.',
    });

    if (res) navigate(-1);
  }, [addToast, eventCurrent, navigate, setIsLoading, signedUser]);

  const handleDeleteEvent = useCallback(async () => {
    setShowDeleteModal(false);
    if (!eventCurrent?.id) return;

    setIsLoading(true);
    const res = await new DeleteScheduleEventService(signedUser?.token).execute(eventCurrent.id);

    setIsLoading(false);

    addToast({
      status: res ? 'success' : 'danger',
      title: 'Evento',
      description: res ? 'Evento deletado com sucesso.' : 'Não foi possível deletar o Evento.',
    });

    if (res) navigate(-1);
  }, [addToast, eventCurrent, navigate, setIsLoading, signedUser]);

  const canUserEditEvent = useCallback(() => {
    if (eventCurrent?.created_by === signedUser?.lmsUser.id) return true;
    return false;
  }, [signedUser, eventCurrent]);

  const getEvent = useCallback(async () => {
    if (!eventCurrent?.id) return navigate('/agenda/consolidada', { state: state });
    setIsLoading(true);
    const res = await new GetScheduleEventService(signedUser?.token).execute(eventCurrent?.id);
    setIsLoading(false);
    if (!res || !res.schedule) return navigate('/agenda/consolidada', { state: state });
    setEventCurrent(res);
    setScheduleCurrent(res.schedule);
  }, [eventCurrent, navigate, setEventCurrent, setIsLoading, setScheduleCurrent, signedUser, state]);

  useEffect(() => {
    if (!eventCurrent?.id) return navigate('/agenda/consolidada', { state: state });
    if (!scheduleCurrent?.id) getEvent();
  }, [eventCurrent, getEvent, navigate, scheduleCurrent, state]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Editar Evento" />
      <DirectoryContainer style={{ margin: '0', padding: '0 0 1rem 0' }} footer={canUserEditEvent()}>
        <ScheduleEventForm handleOnFinish={handleSubmit} formRef={formRef} canUserEdit={canUserEditEvent()} />

        {canUserEditEvent() && (
          <PageFooter>
            <Button onClick={() => setShowDeleteModal(true)} color="#fff" transform="none" status="basic" statusNumber={600} shaded>
              Excluir
            </Button>

            <Button onClick={() => formRef?.current?.submitForm()} color="#fff" transform="none" status="basic" statusNumber={600} shaded>
              Salvar
            </Button>
          </PageFooter>
        )}
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(!showEditModal)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleUpdateEvent}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(!showDeleteModal)}
        title="Deseja Deletar o Evento?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleDeleteEvent}
      />
    </Fragment>
  );
};

export default ScheduleEventEdit;
