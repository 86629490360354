import { styled } from 'react-alicerce-components';

export const ProgramListContainer = styled.div`
  .program-card-list {
    margin: 3rem 0rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .input-container {
    margin-top: 2rem;
  }

  .work-group-checkboxes-wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c5cee0;
    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;

    .work_group_wrapper {
      display: flex;
      align-items: center;
      padding: 20px 0px 10px 0px;

      .work-group-info {
        display: flex;
        flex-direction: column;
        padding-left: 5px;

        span:first-child {
          font-weight: 700;
          font-size: 13px;
          color: #2e3a59;
        }
      }
    }
  }
`;
