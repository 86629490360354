import { styled } from 'react-alicerce-components';

export const ContainerOccurencesCheckbox = styled.div`
  .custom-card {
    > div {
      margin: 0;
    }
  }

  > div label {
    margin: 0;
    input + div {
      margin: 0;
      width: 25px;
      height: 25px;
    }
  }

  > div:first-child {
    flex: 1;
  }

  display: flex;
  margin: 2rem 0;
  column-gap: 10px;
`;

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: flex-end;
`;
