import React, { useCallback, useState } from 'react';
import { ModalWindowsChildrenProps } from '../../../shared/components/Modalv1';
import { MultiStep, MultiStepItemsProps } from '../../../shared/components/MultiStep';
import { ValidateLearningTargerFormWarningFragment } from './ValidateLearningTargerForm/ValidateLearningTargerFormWarningFragment';
import { ValidateLearningTargetFormSelectBlockFragment } from './ValidateLearningTargerForm/ValidateLearningTargetFormSelectBlockFragment';
import { ValidateLearningTargetFormSelectSkillFragment } from './ValidateLearningTargerForm/ValidateLearningTargetFormSelectSkillFragment';
import { ValidateLearningTargetFormSelectResourceFragment } from './ValidateLearningTargerForm/ValidateLearningTargetFormSelectResourceFragment';
import { IStudentWithGrades } from '../../launchers/interfaces/IStudentsWithGrades';
import { ISkill } from '../../../shared/interfaces/ISkill';
import useAuth from '../../../shared/store/auth/hook';
import { EditLearningTargetMinimapaService } from '../services/EditLearningTargetMinimapaService';
import { JustificationLessBlockTargetForm } from '../components/JustificationLessBlockTargetForm/JustificationLessBlockTargetForm';
import { useToast } from 'react-alicerce-components';
import { useGlobal } from '../../../shared/contexts/global/GlobalContext';
import DeleteApplicationService from '../../launchers/services/DeleteApplicationService';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';

type LearningTagertFormProps = {
  studentData: IStudentWithGrades;
  listStudentFromClass: () => Promise<void>;
  openConfirmModal: (open: boolean) => void;
} & ModalWindowsChildrenProps;

export interface LearningTagertForm {
  metaOption?: string;
  teacher_observation?: string;
  justification?: string;
  block_id?: string;
  new_meta_block?: NewMetaBlock;
  current_meta_block?: { order?: number };
  skill_ids?: number[];
}

export interface NewMetaBlock {
  id?: string;
  name?: string;
  color?: string;
  skills?: ISkill[];
  level_id?: string;
  order?: string;
}
export const LearningTagertForm = (props: LearningTagertFormProps) => {
  const { onCloseModal, studentData, listStudentFromClass, openConfirmModal } = props;
  const initialData = { current_meta_block: { order: Number(Number(studentData?.gradeInfo?.meta_block?.index) + 1 || 1) } };
  const [finalData, setFinalData] = useState<LearningTagertForm>(initialData);
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { setIsLoading } = useGlobal();
  const isMounted = useIsMounted();

  const handleSubmit = useCallback(async () => {
    const data = formatData(finalData, studentData);
    setIsLoading(true);
    const res = await new EditLearningTargetMinimapaService(signedUser?.token).execute(data);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Metas',
        description: 'Erro ao editar meta.',
      });
    }
    await listStudentFromClass();
    openConfirmModal(!!res);
    if (!isMounted()) return;
    onCloseModal?.();
  }, [addToast, finalData, isMounted, listStudentFromClass, onCloseModal, openConfirmModal, setIsLoading, signedUser, studentData]);

  const handleSubmitCancelApplication = useCallback(async () => {
    setIsLoading(true);
    const res = await new DeleteApplicationService(signedUser?.token).execute(String(studentData?.gradeInfo?.current_application?.id));
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Metas',
        description: 'Erro ao editar meta.',
      });
    }
    await listStudentFromClass();
    if (!isMounted()) return;
    onCloseModal?.();
  }, [addToast, isMounted, listStudentFromClass, onCloseModal, setIsLoading, signedUser, studentData]);

  const formatData = (finalData: LearningTagertForm, studentData: IStudentWithGrades) => {
    return {
      current_application_id: studentData.gradeInfo?.current_application?.id,
      new_meta_block: {
        id: finalData.new_meta_block?.id,
        skill_ids: finalData.skill_ids || [],
      },
      validation_type: finalData.metaOption,
      teacher_observation: finalData?.teacher_observation,
      justification: finalData?.justification,
    };
  };

  const setFinalDataUse = (item: any) => {
    setFinalData((oldState: any) => {
      return { ...oldState, ...item };
    });
  };

  const JustificationLessBlockTargetFormCompoStep = {
    id: 'JustificationLessBlockTargetForm',
    component: JustificationLessBlockTargetForm,
    componentProps: { onCloseModal: onCloseModal, studentData, handleSubmit },
  };
  const SelectBlockComponent = {
    id: 'ValidateLearningTargetFormSelectBlockFragment',
    component: ValidateLearningTargetFormSelectBlockFragment,
    componentProps: { onCloseModal: onCloseModal, studentData },
  };
  const SelectSkillsComponent = {
    id: 'ValidateLearningTargetFormSelectSkillFragment',
    component: ValidateLearningTargetFormSelectSkillFragment,
    componentProps: { onCloseModal: onCloseModal, studentData, finalData, handleSubmit },
  };

  const steps: MultiStepItemsProps = [
    {
      id: 'ValidateLearningTargerFormWarningFragment',
      component: ValidateLearningTargerFormWarningFragment,
      componentProps: { onCloseModal: onCloseModal },
    },
    {
      id: 'ValidateLearningTargetFormSelectResourceFragment',
      component: ValidateLearningTargetFormSelectResourceFragment,
      componentProps: { handleSubmit: handleSubmitCancelApplication, onCloseModal: onCloseModal },
    },
  ];

  if (
    !!finalData.metaOption &&
    finalData.metaOption !== 'completed_mapa' &&
    Number(finalData.new_meta_block?.order) < Number(finalData.current_meta_block?.order)
  ) {
    steps.push(SelectBlockComponent, JustificationLessBlockTargetFormCompoStep);
  } else {
    steps.push(SelectBlockComponent, SelectSkillsComponent);
  }

  return (
    <>
      <MultiStep steps={steps} setFinalData={setFinalDataUse} finalData={finalData} handleSubmitCallback={handleSubmit} />
    </>
  );
};
