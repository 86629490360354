import React, { Fragment, useCallback, useState } from 'react';
import { Button, H6, Input } from 'react-alicerce-components';
import Layout from '@modules/home/components/Layout';
import ControllerNavigationBox from '@shared/components/ControllerNavigationBox';
import NavigationBox from '@shared/components/NavigationBox';
import Divider from '@shared/components/Divider';
import GroupRender from '@modules/groups/components/GroupRender';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import ListGroupsUserManagesService from '@modules/groups/services/ListGroupsUserManagesService';
import ListGroupsService, { IGroupReponse } from '@modules/groups/services/ListGroupsService';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import Icon from 'react-eva-icons';
import { Container } from './styles';

type ICategoryGroups = 'tribe' | 'collective' | 'nation';

const MyGroups: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [groups, setGroups] = useState<IGroupReponse>();
  const [openedDiv, setOpenedDiv] = useState<number>();
  const [selectedCategory, setSelectedCategory] = useState<ICategoryGroups>();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const listFavoritedGroups = useCallback(
    async (search = '', page = 0, category?: ICategoryGroups) => {
      if (!signedUser) return false;
      setIsLoading(true);
      const groupCategory = category ? [category] : selectedCategory ? [selectedCategory] : undefined;
      const res = await new ListGroupsService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        favorited: true,
        categories: groupCategory,
      });
      if (res === 'canceling') return false;
      setIsLoading(false);
      if (!res) return false;
      isMounted() && setGroups(res);
      return !!res.items.length;
    },
    [isMounted, newCancelToken, selectedCategory, setIsLoading, signedUser]
  );

  const listMyGroups = useCallback(
    async (search = '', page = 0, category?: ICategoryGroups) => {
      if (!signedUser) return false;
      setIsLoading(true);
      const groupCategory = category ? [category] : selectedCategory ? [selectedCategory] : undefined;
      const res = await new ListGroupsService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        categories: groupCategory,
      });
      if (res === 'canceling') return false;
      setIsLoading(false);
      if (!res) return false;
      isMounted() && setGroups(res);
      return !!res.items.length;
    },
    [isMounted, newCancelToken, selectedCategory, setIsLoading, signedUser]
  );

  const listGroupsUserManages = useCallback(
    async (search = '', page = 0, category?: ICategoryGroups) => {
      if (!signedUser) return false;
      setIsLoading(true);
      const groupCategory = category ? [category] : selectedCategory ? [selectedCategory] : undefined;
      const res = await new ListGroupsUserManagesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        categories: groupCategory,
      });
      if (res === 'canceling') return false;
      setIsLoading(false);
      if (!res) return false;
      isMounted() && setGroups(res);
      return !!res.items.length;
    },
    [isMounted, newCancelToken, selectedCategory, setIsLoading, signedUser]
  );

  const handleOnClick = useCallback(
    async (isOpen: boolean, divToShow: number, category: ICategoryGroups) => {
      if (isOpen) {
        setOpenedDiv(undefined);
        return true;
      }
      setGroups(undefined);

      if (divToShow === 1) {
        const favorited = await listFavoritedGroups('', 0, category);
        if (!favorited) return false;
      } else if (divToShow === 2) {
        const res = await listMyGroups('', 0, category);
        if (!res) return false;
      } else if (divToShow === 3) {
        const managed = await listGroupsUserManages('', 0, category);
        if (!managed) return false;
      }
      setOpenedDiv(divToShow);
      setSelectedCategory(category);
      return true;
    },
    [listFavoritedGroups, listGroupsUserManages, listMyGroups]
  );

  const filterGroup = useCallback(
    async (search: string, page = 0) => {
      if (openedDiv === 1) {
        await listFavoritedGroups(search, page);
      } else if (openedDiv === 2) {
        await listMyGroups(search, page);
      } else if (openedDiv === 3) {
        await listGroupsUserManages(search, page);
      }
    },
    [listFavoritedGroups, listGroupsUserManages, listMyGroups, openedDiv]
  );

  return (
    <Fragment>
      <Layout openTab={2} footer={true}>
        <Container>
          {openedDiv && (
            <div style={{ marginBottom: '2rem' }}>
              <Input
                placeholder="Buscar"
                name="search"
                end={<Icon name="funnel" fill="#8F9BB3" />}
                onChange={(event) => filterGroup(event.target.value)}
              />
            </div>
          )}

          {(openedDiv === 1 || !openedDiv) && (
            <>
              <H6 fontWeight={800} status="tertiary" textAlign="center">
                GRUPOS FAVORITOS
              </H6>
              <ControllerNavigationBox>
                <NavigationBox title="NAÇÕES" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'nation')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
                <NavigationBox title="Tribos" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'tribe')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
                <NavigationBox title="COletivos" handleOnClick={(isOpen) => handleOnClick(isOpen, 1, 'collective')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
              </ControllerNavigationBox>
            </>
          )}

          {!openedDiv && <Divider />}

          {(openedDiv === 2 || !openedDiv) && (
            <>
              <H6 fontWeight={800} status="tertiary" textAlign="center">
                MEUS GRUPOS
              </H6>
              <ControllerNavigationBox>
                <NavigationBox title="NAÇÕES" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'nation')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
                <NavigationBox title="Tribos" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'tribe')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
                <NavigationBox title="COletivos" handleOnClick={(isOpen) => handleOnClick(isOpen, 2, 'collective')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
              </ControllerNavigationBox>
            </>
          )}

          {!openedDiv && <Divider />}

          {(openedDiv === 3 || !openedDiv) && (
            <>
              <H6 fontWeight={800} status="tertiary" textAlign="center">
                GRUPOS QUE COORDENO
              </H6>

              <ControllerNavigationBox>
                <NavigationBox title="NAÇÕES" handleOnClick={(isOpen) => handleOnClick(isOpen, 3, 'nation')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>

                <NavigationBox title="Tribos" handleOnClick={(isOpen) => handleOnClick(isOpen, 3, 'tribe')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>

                <NavigationBox title="Coletivos" handleOnClick={(isOpen) => handleOnClick(isOpen, 3, 'collective')}>
                  <GroupRender groups={groups} filterGroup={filterGroup} />
                </NavigationBox>
              </ControllerNavigationBox>
            </>
          )}

          <PageFooter>
            <Button transform="none" status="tertiary" onClick={() => navigate('/diretorio/grupos')} shaded>
              Diretório de Grupos
            </Button>
          </PageFooter>
        </Container>
      </Layout>
    </Fragment>
  );
};

export default MyGroups;
