export type TYPES_STEERINGS_TYPE = 'implantation' | 'execution' | 'results' | 'renovation' | 'start';

export const STEERING_TYPE_IMPLANTATION_CONSTRAINTS = 'implantation';
export const STEERING_TYPE_EXECUTION_CONSTRAINTS = 'execution';
export const STEERING_TYPE_RESULTS_CONSTRAINTS = 'results';
export const STEERING_TYPE_RENOVATION_CONSTRAINTS = 'renovation';
export const STEERING_TYPE_KICKOFF_CONSTRAINTS = 'kickoff';

export const STEERING_TYPE_IMPLANTATION = { name: 'Implantação', value: 'implantation' };
export const STEERING_TYPE_EXECUTION = { name: 'Execução', value: 'execution' };
export const STEERING_TYPE_RESULTS = { name: 'Resultados', value: 'results' };
export const STEERING_TYPE_RENOVATION = { name: 'Renovação', value: 'renovation' };
export const STEERING_TYPE_KICKOFF = { name: 'Kickoff', value: 'kickoff' };

export const STEERING_TYPE_OPTIONS = [
  STEERING_TYPE_IMPLANTATION,
  STEERING_TYPE_EXECUTION,
  STEERING_TYPE_RESULTS,
  STEERING_TYPE_RENOVATION,
  STEERING_TYPE_KICKOFF,
];

export const STEERING_TYPE_CONSTRAINTS = [
  STEERING_TYPE_IMPLANTATION_CONSTRAINTS,
  STEERING_TYPE_EXECUTION_CONSTRAINTS,
  STEERING_TYPE_RESULTS_CONSTRAINTS,
  STEERING_TYPE_RENOVATION_CONSTRAINTS,
  STEERING_TYPE_KICKOFF_CONSTRAINTS
];

export const TYPE_STEERING_VALUES = STEERING_TYPE_OPTIONS.map((c) => c.value);

export const defaultSteeringType = (steeringType?: string | null) => STEERING_TYPE_OPTIONS.map((i) => ({ ...i, selected: steeringType === i.value }));

export const translateSteeringType = (steeringType: string = '') => {
  let objMap = {};
  STEERING_TYPE_OPTIONS.forEach((i) => (objMap = { ...objMap, [i.value]: i.name }));
  return objMap[steeringType] || steeringType;
};
