import React from 'react';
import Icon from 'react-eva-icons';
import { QuestionMarkerContainer } from './styles';

export type IQuestionMark = {
  onClickCallback?: () => void;
  top?: string;
  right?: string;
  isAbsolute?: boolean;
};

const QuestionMark: React.FC<IQuestionMark> = ({ onClickCallback, top, right, isAbsolute = true }) => {
  return (
    <QuestionMarkerContainer onClick={onClickCallback} className="question-marker-container" top={top} right={right} isAbsolute={isAbsolute}>
      <Icon name="question-mark-circle" />
    </QuestionMarkerContainer>
  );
};

export default QuestionMark;
