import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import { ISkill } from '@shared/interfaces/ISkill';

export interface ISkillsReponse extends IPaginatedResponse {
  items: ISkill[];
}

export class ListSkillsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IQueryOptions): Promise<ISkillsReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({
        query,
        ...options,
      });

      const res = await this.api.get(`/external/skills?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}
