import styled from 'styled-components';

interface ItemProps {
  bgColor?: string;
  color?: string;
}

export const Item = styled.div<ItemProps>`
  background-color: ${({ bgColor }) => bgColor ? bgColor : '#F7F9FC' };
  height: 40px;
  width: 76px;
  font-size: 12px;
  color: ${({ bgColor, color }) => color? color : (bgColor ? '#fff' : '#222B45') };
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid #C5CEE0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-betweeen;
  gap: .5rem;
`;

export const LabelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(76px * 3 + .5rem * 2);
  max-width: calc(600px - );
  background-color: #8F9BB3;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  height: 28px;
  margin-left: auto;
  border-radius: 8px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem auto;
  max-width: fit-content;
  gap: .5rem;
`;

