import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';
import { IDaysOfWeekSelecProps } from './index';

export const DaysOfWeekSelectContainer = styled.div`
  .error-weekdays {
    color: #c53030;
    font-weight: 600;
  }
`;

export const Container = styled.div<IDaysOfWeekSelecProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e4e9f2;
  height: auto;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 20px;

  > div {
    > label {
      > span {
        position: relative;
        color: #ffffff;
      }
    }

    > div {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        z-index: 1;
        color: #fff;
        font-size: 12px;
      }
    }

    input + div {
      background-color: ${({ status, statusNumber }) => themeGet(`colors.${status}${statusNumber}`)};
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
    }

    input:checked + div {
      background-color: ${({ status, statusNumber }) => themeGet(`colors.${status}${(statusNumber || 400) - 100}`)};
      border: 2px solid ${({ status, statusNumber }) => themeGet(`colors.${status}${(statusNumber || 400) - 100}`)};
      filter: drop-shadow(0px 0px 28px #49C56C);
    }
  }
`;
