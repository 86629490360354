import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useGroup } from '@modules/groups/context/GroupContext';

const GroupTagRelation: React.FC = () => {
  const navigate = useNavigate();
  const { setGroupCurrent, groupCurrent } = useGroup();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setGroupCurrent((oldState: any) => {
        return { ...oldState, tags: tags };
      });
    },
    [setGroupCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={groupCurrent.tags}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
      buttonCallback={() => navigate(-1)}
    />
  );
};

export default GroupTagRelation;
