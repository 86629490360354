import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';
import { useTags } from '@modules/tags/context/TagsContext';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';

const TagListParentCreate: React.FC = () => {
  const navigate = useNavigate();
  const { setTagCurrent, tagCurrent } = useTags();

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      const parent = tags[0]
      const parent_id = parent ? parent.id : undefined
      const type = parent ? parent.type : undefined

      setTagCurrent((oldState: ITag) => {
        return { ...oldState, type, parent, parent_id };
      });
    },
    [setTagCurrent]
  );

  const selectedTagsCreate = useMemo(() => {
    const aux = [] as ITag[];
    if (tagCurrent?.parent) aux.push(tagCurrent?.parent);
    return aux;
  }, [tagCurrent]);

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={1}
      useSearch
      selectedTags={selectedTagsCreate}
      pathToReturn="/diretorio/tags/criar"
      buttonText="Adicionar Tags"
      buttonCallback={() => navigate('/diretorio/tags/criar')}
    />
  );
};

export default TagListParentCreate;
