import { ModalWindowsChildrenProps } from '@shared/components/Modalv1';
import { MultiStep, MultiStepItemsProps } from '@shared/components/MultiStep';
import React, { useCallback, useMemo, useState } from 'react';
// import { UnenrollmentOrDisconnectStep } from './DeleteCustomFormSteps/UnenrollmentOrDisconnectStep';
import { UnenrollmentDisconnectTeacherFormStep } from '@modules/classes/forms/DeleteCustomFormSteps/UnenrollmentDisconnectTeacherFormStep';
import { UnenrollmentDisconnectStudentFormStep } from '@modules/classes/forms/DeleteCustomFormSteps/UnenrollmentDisconnectStudentFormStep';
import { DisconnectUnenrollmentForm } from '@modules/classes/schemas/DisconnectAndUnenrollmentSchema';
import { cleanObjectToQuery } from '@shared/utils/cleanObjetctToQuery';

export type DeleteCustomFormProps = {
  category: 'update_teachers' | 'update_students';
  handleSubmit: (data: any, category: any) => void;
  userId: number;
} & ModalWindowsChildrenProps;

export const DeleteCustomForm = ({ onCloseModal, category, handleSubmit, userId }: DeleteCustomFormProps) => {
  const [selectedRadio, setSelectedRadio] = useState<'unenrollment' | 'disconnect'>('unenrollment');
  const [form, setForm] = useState<DisconnectUnenrollmentForm>();

  const executeHandleSubmit = useCallback(() => {
    handleSubmit({ ...cleanObjectToQuery(form), userId, type: selectedRadio }, category);
  }, [form, userId, selectedRadio, category, handleSubmit]);

  const steps = useMemo<MultiStepItemsProps>((): MultiStepItemsProps => {
    const formStep =
      category === 'update_students'
        ? {
            id: 'UnenrollmentDisconnectStudentFormStep',
            component: UnenrollmentDisconnectStudentFormStep,
            componentProps: { onCloseModal, selectedRadio, setSelectedRadio, category, handleSubmit: executeHandleSubmit, form, setForm },
          }
        : {
            id: 'UnenrollmentDisconnectTeacherFormStep',
            component: UnenrollmentDisconnectTeacherFormStep,
            componentProps: { onCloseModal, selectedRadio, setSelectedRadio, category, handleSubmit: executeHandleSubmit, form, setForm },
          };

    return [formStep];
  }, [category, onCloseModal, selectedRadio, executeHandleSubmit, form]);

  // @TODO: jaqueline - linhas comentadas até ser decidido o fluxo de desligamento
  // const steps = useMemo<MultiStepItemsProps>((): MultiStepItemsProps => {
  //   const aux = [
  //     {
  //       id: 'UnenrollmentOrDisconnectStep',
  //       component: UnenrollmentOrDisconnectStep,
  //       componentProps: { onCloseModal: onCloseModal, selectedRadio, setSelectedRadio, category },
  //     },
  //   ];
  //   if (category === 'update_students') aux.push(UnenrollmentDisconnectStudentFormCompoStep);
  //   if (category === 'update_teachers') aux.push(UnenrollmentDisconnectTeacherFormCompoStep);
  //   return aux;
  // }, [UnenrollmentDisconnectStudentFormCompoStep, UnenrollmentDisconnectTeacherFormCompoStep, category, onCloseModal, selectedRadio]);

  return <MultiStep steps={steps} />;
};
