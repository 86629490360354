import React, { useState } from "react"
import Icon from 'react-eva-icons';
import { ButtonFooterContainer } from "../styles";
import { Button, H1, H3, H6, P } from "react-alicerce-components"
import { CurrentBlockContainer, FieldSet, ValidateLearningTargerFormWarningContainer } from "./styles";
import { MultiStepComponentProps } from "../../../../shared/components/MultiStep";
import { PaginationInMemory } from "../../../../shared/components/PaginationInMemory";
import { RadioOption } from "../../../../shared/components/RadioOption";
import { IInputErrorsFormat, beforeSubmitForm, errorMessageBuilder } from "../../../../shared/utils/beforeSubmitForm";
import * as Yup from 'yup'
import { IBlockInfo, IStudentWithGrades } from "../../../launchers/interfaces/IStudentsWithGrades";
import { groupByLevelId } from "../../components/utils/groupByLevelId";

type ValidateLearningTargetFormSelectBlockFragmentProps = { studentData: IStudentWithGrades } & MultiStepComponentProps

export const ValidateLearningTargetFormSelectBlockFragment = (props: ValidateLearningTargetFormSelectBlockFragmentProps) => {
    const { onNext, onBack, finalData, setValueCallback, studentData } = props

    const [errors, setErrors] = useState<IInputErrorsFormat[]>()
    const [selectBlockFragmentForm, setSelectBlockFragmentForm] = useState<any>(finalData || {})

    const TargetSelectOptionSchema = Yup.object().shape({
        block_id: Yup.number().required({ name: 'block_id', message: 'O campo é obrigatório' }),
    })
    const groupedData = groupByLevelId(studentData.gradeInfo!.blocks);
    const metaBlock = studentData.gradeInfo?.meta_block
    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>, object: IBlockInfo) => {
        const id = e.target.id
        const name = e.target.name
        setSelectBlockFragmentForm((oldState: any) => {
            return { ...oldState, [name]: id, new_meta_block: object };
        });
    }

    const canGoNext = async () => {
        let formErrors = await beforeSubmitForm(selectBlockFragmentForm, TargetSelectOptionSchema);
        setErrors(formErrors);
        if (formErrors.length !== 0) return;
        setValueCallback && setValueCallback(selectBlockFragmentForm)
        onNext()
    }
    return (
        <ValidateLearningTargerFormWarningContainer>
            <div style={{ marginTop: '.8rem', marginBottom: '.8rem' }}>
                <div className='icon-container'>
                    <Icon name="alert-circle-outline" fill="#F2C94C" />
                </div>
                <H1 className='title'>Escolha Novo Bloco!</H1>
                <CurrentBlockContainer>
                    <H3 className="current-block">Bloco Atual:</H3>
                    <RadioOption name={"subtitle"} hideRadio radioWidth="fit-content"
                        optionColor={"#222B45"}
                        round
                        id={'subtitle'}
                    >
                        <H6 className="current-block">{metaBlock?.name}</H6>
                    </RadioOption>
                </CurrentBlockContainer>
                <P className='description'>Com base na sua avaliação, qual o bloco que o estudante se encontra no momento?</P>
                <FieldSet error={errorMessageBuilder('block_id', errors)}>
                    {errorMessageBuilder('block_id', errors) && <legend>{errorMessageBuilder('block_id', errors)}</legend>}
                    <PaginationInMemory data={groupedData} metaBlockName={metaBlock?.name} dataCallback={(handleChangeRadio)} defaultSelected={selectBlockFragmentForm?.block_id} />
                </FieldSet>
            </div>

            <ButtonFooterContainer>
                <Button
                    shaded
                    transform="none"
                    style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
                    onClick={() => onBack?.()}
                >
                    Voltar
                </Button>

                <Button
                    shaded
                    transform="none"
                    style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
                    onClick={canGoNext}
                >
                    Continuar
                </Button>
            </ButtonFooterContainer>
        </ValidateLearningTargerFormWarningContainer>
    );
}