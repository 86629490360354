import IRoute from '@shared/interfaces/IRoute';

import RecordingsClassesSelection from '@modules/recordings/views/RecordingsClassesSelection';
import RecordingsPlacesSelection from '@modules/recordings/views/RecordingsPlacesSelection';
import CamerasAndPlaces from '@modules/recordings/views/CamerasAndPlaces';
import WatchMedias from '@modules/recordings/views/WatchMedias';
import RecordingsUpload from '@modules/recordings/views/RecordingsUpload';
import { RecordingsProvider } from '@modules/recordings/context/RecordingsContext';
import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { classesKeys } from '@modules/classes/keys';
import { placesKeys } from '@modules/places/keys';

const routes: IRoute[] = [
  {
    path: '/gravacoes/contexto/turmas',
    key: 'RECORDINGS_CONTEXT_SELECTION_CLASSES_PATH',
    exact: true,
    component: RecordingsClassesSelection,
    permissions: [classesKeys.CLASSES_READ],
  },
  {
    path: '/gravacoes/contexto/locais',
    key: 'RECORDINGS_CONTEXT_SELECTION_PLACES_PATH',
    exact: true,
    component: RecordingsPlacesSelection,
    permissions: [placesKeys.PLACE_READ],
  },
  {
    path: '/gravacoes/cameras',
    key: 'RECORDINGS_CAMERAS_AND_PLACES_PATH',
    exact: true,
    component: CamerasAndPlaces, // TO DO
    permissions: 'public',
  },
  {
    path: '/gravacoes/cameras/assistir',
    key: 'RECORDINGS_CAMERAS_WATCH_PATH',
    exact: true,
    component: WatchMedias, // TO DO
    permissions: 'public',
  },
  {
    path: '/gravacoes/upload',
    key: 'RECORDINGS_UPLOAD_PATH',
    exact: true,
    component: RecordingsUpload, // TO DO
    permissions: 'public',
  },
];

const providers = [RecordingsProvider];

export default {
  key: 'RECORDINGS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
