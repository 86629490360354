import { H6 } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  .input-container {
    margin: 0;

    input {
      font-weight: bolder;
      color: #222b45;
    }
  }

  .container-daily-class {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  input:checked + div {
    border-color: transparent;

    svg {
      color: #fff;
    }
  }
`;

export const Label = styled(H6)`
  margin: 1.5rem 0;
  font-weight: 800;
  font-size: 14px;
  color: #482b85;
`;
