import React, { createContext, useContext, useState } from "react";

import IContract from "../interfaces/IContract";

export type IContractsContextData = {
  clearContractCreate: () => void;
  setContractCurrent: React.Dispatch<React.SetStateAction<IContract>>;
  contractCurrent?: IContract;
};

const ContractsContext = createContext<IContractsContextData>(
  {} as IContractsContextData
);

const ContractsProvider: React.FC = ({ children }) => {
  const [contractCurrent, setContractCurrent] = useState<IContract>({} as IContract);

  const clearContractCreate = () => {
    setContractCurrent({} as IContract);
  }

  return (
    <ContractsContext.Provider
      value={{
        contractCurrent,
        setContractCurrent,
        clearContractCreate
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

function useContracts() {
  const context = useContext(ContractsContext);

  if (!context) {
    throw new Error("useContracts must be used within a ContractsProvider");
  }

  return context;
}

export { ContractsProvider, useContracts };

