import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';

import { Spinner, Tab, Tabs, useToast } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import CustomTabs from '@shared/components/CustomTabs';

import ClassPlannerHeader from '@modules/class-planner/components/ClassPlannerHeader';
import ClassPlannerCalendar from '@modules/class-planner/components/ClassPlannerCalendar';
import ClassPlannerSchedule from '@modules/class-planner/components/ClassPlannerSchedule';
import ClassPlannerComments from '@modules/class-planner/components/ClassPlannerComments';

import { Container } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import GetDailyClassService from '@modules/class-planner/services/GetDailyClassService';
import useAuth from '@shared/store/auth/hook';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import { PlannedActivityHelper } from 'modules/class-planner/helpers/PlannedActivityHelper';
import ListPlannedActivityService from 'modules/class-planner/services/ListPlannedActivityService';
import { ClassPlannerSprintList } from '@modules/class-planner/views/ClassPlannerSprintList';

const ClassPlanner: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { classId, dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();
  const { addToast } = useToast();
  const { setIsLoading, isLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { canSavePlannedClass, setCanSavePlannedClass, setDailyClass, dailyClass, setPlannedActivities, setProblems } = useScheduleGrid();

  const getDailyClass = useCallback(async () => {
    if (!dailyClassId) return navigate(`/planejamento-aula/aulas/turma/${classId}`);
    setIsLoading(true);
    const res = await new GetDailyClassService(signedUser?.token).execute(dailyClassId);
    if (!res) return navigate(`/planejamento-aula/aulas/turma/${classId}`);

    if (Number(res.class_id) !== Number(classId)) {
      addToast({
        status: 'warning',
        title: 'Aula',
        description: 'A aula não pertence a essa turma, NÃO NAVEGUE PELA URL',
      });
      return navigate(`/planejamento-aula/aulas/turma/${classId}`);
    }

    setDailyClass(res);
    const dailyClassProblems = res.plan?.pending_problems ? res.plan?.pending_problems : [];
    setProblems(dailyClassProblems);
  }, [addToast, classId, dailyClassId, navigate, setDailyClass, setIsLoading, setProblems, signedUser]);

  const handleConfirmModal = useCallback(() => {
    setShowModal(false);
    setPlannedActivities(undefined);
    setDailyClass(undefined);
    setCanSavePlannedClass(false);
    navigate(`/planejamento-aula/aulas/turma/${classId}`);
  }, [classId, navigate, setCanSavePlannedClass, setDailyClass, setPlannedActivities]);

  const handleOnClickReturn = useCallback(() => {
    if (canSavePlannedClass) {
      setShowModal(true);
      return;
    }

    handleConfirmModal();
  }, [canSavePlannedClass, handleConfirmModal]);

  const listPlannedActivity = useCallback(async () => {
    if (!dailyClassId) return navigate(`/planejamento-aula/aulas/turma/${classId}`);
    setIsLoading(true);
    const res = await new ListPlannedActivityService(signedUser?.token).execute(dailyClassId);
    if (res === 'canceling') return;
    setIsLoading(false);
    if (!res) {
      navigate(`/planejamento-aula/aulas/turma/${classId}`);
      return;
    }

    const { in_calendar, in_tray } = PlannedActivityHelper.formatPlannedActivityStructure(res);

    setPlannedActivities({ in_calendar, in_tray });
  }, [classId, dailyClassId, navigate, setIsLoading, setPlannedActivities, signedUser]);

  useEffect(() => {
    listPlannedActivity();
  }, [listPlannedActivity]);

  useEffect(() => {
    if (!dailyClass || dailyClass.id !== dailyClassId) {
      getDailyClass();
    }
  }, [dailyClass, dailyClassId, getDailyClass]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}
      <Container>
        <HeaderDirectory
          onClickReturn={handleOnClickReturn}
          title="Planejador de Aula"
          backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
          menuKebab={{
            tooltip: { view: 'ClassPlanner' },
            menuBackHome: true,
          }}
        />
        <ClassPlannerHeader />
        {dailyClass && (
          <CustomTabs>
            <Tabs>
              <Tab title="CALENDÁRIO">
                <ClassPlannerCalendar />
              </Tab>
              <Tab title="CRONOGRAMA">
                <ClassPlannerSchedule />
              </Tab>
              <Tab title="PLANO DE ENSINO">
                <ClassPlannerSprintList />
              </Tab>
              <Tab title="COMENTÁRIOS">
                <ClassPlannerComments />
              </Tab>
            </Tabs>
          </CustomTabs>
        )}
      </Container>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(false)}
        title="Deseja sair sem salvar?"
        subTitle="Ao sair sem salvar, as alterações NÃO SALVAS serão perdidas."
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleConfirmModal}
      ></ConfirmModal>
    </Fragment>
  );
};

export default ClassPlanner;
