import styled from 'styled-components';

interface IContainerProps {
  selected: boolean;
  disabled?: boolean;
}

export const Container = styled.div<IContainerProps>`
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.6' : '1')};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  ${({ selected }) => selected && 'filter: drop-shadow(0px 0px 28px #FF3D71);'}

  > div {
    height: 40px;
    border-radius: 4px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    font-size: 12px;
    background: #e9d7f9;
    color: #2e3a59;
    flex: 1;
    padding: 6px;
  }

  .daily-class-time {
    font-size: 16px;
    color: #ffffff;
    flex: 3;
    background: #6749ba;
    column-gap: 4px;
  }
`;

interface IContainerAttendanceStatusProps {
  hasAttendance: boolean;
}

const getStatusColor = (status: boolean) => {
  if (status) return `background: linear-gradient(180deg, #5bb610 23.44%, #6dcc1f 100%);`;
  return `background: linear-gradient(180deg, #e44747 23.44%, #a40a0a 100%);`;
};

export const ContainerAttendanceStatus = styled.div<IContainerAttendanceStatusProps>`
  width: 40px;
  position: relative;

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
    width: 35px;
    background-color: #edeff3;
    border-radius: 3px;
    border: 1px solid #c5cee0;
  }

  input ~ .checkmark {
    background-color: #edeff3;
  }

  input:checked ~ .checkmark {
    background-color: #edeff3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    ${({ hasAttendance }) => getStatusColor(hasAttendance)}
    border-radius: 50%;
  }
`;
