import { styled } from "react-alicerce-components";

export const Container = styled.div`
    .input-container > div input::placeholder {
        font-weight: 500;
    }
`

export const CheckboxContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(3, 1fr);

   input + div {
        width: 27px;
        height: 27px;
    }
`

export const DateFilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-container {
        margin-top: .5rem !important;
        margin-bottom: 1rem !important;
        width: 45%;
        
        > div:first-child {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        input {
            width: 100%;
        }
    }

    label {
        margin: 0 4px;
        width: 10%;
        text-align: center;
    }
`

export const FilterContainer = styled.div`
    margin-bottom: 1rem;
    border: 1px solid #C5CEE0;
    border-radius: 8px;

    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        padding: 20px 16px;
        position: relative;

        h6 {
            font-weight: 800;
            font-size: 16px;
            color: #2E3A59;
        }

        label {
            font-weight: 700;
            font-size: 12px;
            color: #2E3A59;
            text-transform: none;
            font-family: var(--secondary-font);
        }

        .close {
            position: absolute;
            top: 20px;
            right: 16px;
            font-size: 1rem;
            color: #2E3A59;
        }

        .input-container {
            margin-top: 20px;
        }

        .input-container > div input {
            font-weight: 600;
            color: #C5CEE0; 
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid #C5CEE0;
    }
`