import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import IListOptions from '@shared/interfaces/IListOptions';

export interface IExternalScheduleReponse extends IPaginatedResponse {
  items: ISchedule[];
}

interface IListExternalSchedulesOptions extends IListOptions {
  group_id?: number;
  class_id?: number;
  place_id?: number;
  is_subscribed?: boolean;
}

class ListExternalSchedulesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListExternalSchedulesOptions): Promise<IExternalScheduleReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options });

      const res = await this.api.get(`/schedule/external?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListExternalSchedulesService;
