import React, { useCallback, useMemo } from 'react';
import UserList from '@modules/users/components/UsersList';
import { IUser } from '@modules/users/interfaces/IUser';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';

const ClassPlannerUsersRelationCreate: React.FC = () => {
  const { dailyClass, setDailyClass } = useScheduleGrid();

  const setSelectedItens = useCallback(
    (users: IUser[]) => {
      setDailyClass((oldState: IDailyClass) => {
        return { ...oldState, plan: { ...oldState.plan, teachers: users } };
      });
    },
    [setDailyClass]
  );

  const selectedItens = useMemo(() => {
    if (!dailyClass) return;
    return dailyClass.plan?.teachers;
  }, [dailyClass]);

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelect={'many'}
      handleOnClick={setSelectedItens}
    />
  );
};

export default ClassPlannerUsersRelationCreate;
