import React, { useCallback, useState } from 'react';
import Icon from 'react-eva-icons';

import { CLASS_TYPE_CONSTRAINTS } from '@modules/classes/utils/validData/validClassType';
import GetBlockRangeService from '@modules/subscriptions/services/GetBlockRangeService';
import { IBlockRange } from '@modules/subscriptions/interfaces/IBlockRange';
import IClass from '@modules/classes/interfaces/IClass';

import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { WEEK_DAYS_CONSTRAINTS } from '@shared/utils/validData/validWeekdays';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useToast } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';

import { initalDataBlockRange } from '@modules/subscriptions/utils/initalDataBlockRange';
import { ClassCardEnrollmentTrails } from './ClassCardEnrollmentTrails';
import { ClassCardEnrollmentBody } from './ClassCardEnrollmentBody';
import ClassCardEnrollmentBorder from './ClassCardEnrollmentBorder';
import { ClassCardEnrollmentContainer } from './styles';

interface IClassCardEnrollmentProps {
  classData?: IClass;
  className?: string;
  canDeleteCard?: { indexId: number; removeCallback: (indexId: number) => void };
  trails?: boolean;
  selectElement: number;
  onClick?: (obj: any) => void;
}

const ClassCardEnrollment: React.FC<IClassCardEnrollmentProps> = (props) => {
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const [blockRangeStudent, setBlockRangeStudent] = useState<IBlockRange[]>(initalDataBlockRange);
  const [openTrails, setOpenTrails] = useState<boolean>(false);

  const classCardHeaderData = useCallback((classData?: IClass): string[] => {
    const cardHeaderData: string[] = [];
    if (!classData) return cardHeaderData;

    const { age_groups, type, calendar_data } = classData;

    const translatedAgeGroups = age_groups.map((ag) => AGE_GROUP_CONSTRAINTS.find((agc) => agc.value === ag)?.name);
    translatedAgeGroups && cardHeaderData.push(translatedAgeGroups.join('/'));

    const translatedType = CLASS_TYPE_CONSTRAINTS.find((s) => s.value.includes(type!));
    translatedType && cardHeaderData.push(translatedType.name);

    const translatedWeekDays = calendar_data?.map((cd) =>
      cd.week_days.map((wd) => WEEK_DAYS_CONSTRAINTS.find((wdc) => wdc.value === wd)?.name).join('/')
    );

    translatedWeekDays && cardHeaderData.push(translatedWeekDays.join('/'));

    if (classData?.place?.name) cardHeaderData.push(classData.place.name);

    return cardHeaderData;
  }, []);

  const classDataFooterData = useCallback((subscription?: IClass): string[] => {
    const cardFooterData: string[] = [];
    if (!subscription) return cardFooterData;
    //TODO Revisar o que irá no footer
    /* if (!subscription.student) return cardFooterData;

    const { relationship_type, school_grade, age_group } = subscription.student;

    const translatedRelationshipType = STUDENT_RELATIONSHIP_CONSTRAINTS.find((s) => s.value.includes(relationship_type || ''));
    translatedRelationshipType && cardFooterData.push(translatedRelationshipType.name);

    const translatedSchoolGrade = SCHOOL_GRADE_CONSTRAINTS.find((s) => s.value.includes(school_grade || ''));
    translatedSchoolGrade && cardFooterData.push(translatedSchoolGrade.name);

    const translatedAgeGroup = AGE_GROUP_CONSTRAINTS.find((s) => s.value.includes(age_group || ''));
    translatedAgeGroup && cardFooterData.push(translatedAgeGroup.name); */

    return cardFooterData;
  }, []);

  const listBlockRangeStudent = useCallback(
    async (studentId?: string | number) => {
      setIsLoading(true);
      const responseBlockRange = await new GetBlockRangeService(signedUser?.token).execute(String(studentId));

      if (!responseBlockRange) {
        addToast({
          status: 'danger',
          title: 'Tamanho de block',
          description: 'Erro ao encontrar o range do bloco.',
        });
        return;
      }
      setBlockRangeStudent(responseBlockRange);
      setIsLoading(false);
    },
    [addToast, setIsLoading, signedUser]
  );

  const handleClickOpenTrail = useCallback(
    (classId?: string | number) => {
      setOpenTrails(!openTrails);
      if (!openTrails) listBlockRangeStudent(classId);
    },
    [listBlockRangeStudent, openTrails]
  );

  const handleOnClick = () => {
    !!props.onClick && props.onClick(props.classData);
  };
  const selectItem = Number(props.selectElement) === Number(props.classData?.id);
  return (
    <ClassCardEnrollmentContainer className={props.className ? props.className : ''} onClick={handleOnClick} isSelect={selectItem}>
      <div className="card-container">
        <ClassCardEnrollmentBorder data={classCardHeaderData(props.classData)} location="header" />
        <ClassCardEnrollmentBody isTrailsOpen={openTrails}>
          <b>{props.classData?.name}</b>
          {props.trails && (
            <div style={{ cursor: 'pointer' }} onClick={() => handleClickOpenTrail(props.classData?.id)}>
              <Icon name="arrow-ios-forward-outline" fill="#2E3A59" />
            </div>
          )}
        </ClassCardEnrollmentBody>
        <ClassCardEnrollmentBorder data={classDataFooterData(props.classData)} location="footer" />

        {props.trails && (
          <ClassCardEnrollmentTrails isOpen={openTrails} handleOnClick={() => setOpenTrails(false)} blockRangeStudent={blockRangeStudent} />
        )}
      </div>

      {props.canDeleteCard && (
        <div className="remove-card" onClick={() => props.canDeleteCard?.removeCallback(props.canDeleteCard.indexId)}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </ClassCardEnrollmentContainer>
  );
};

export { ClassCardEnrollment };
