import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, H6, Input, Pagination } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { IWorkedHourReponse, ListWorkedHoursService } from '@modules/worked-hours/services/ListWorkedHoursService';
import { ContainerWorkedHours, InputDateWorkedHour } from '@modules/worked-hours/components/Layout/styles';
import { GetTotalWorkedHourService } from '@modules/worked-hours/services/GetTotalWorkedHourService';
import WorkedHoursCard from '@modules/worked-hours/components/WorkedHoursCard';
import { Layout } from '@modules/worked-hours/components/Layout';

import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';

import WorkedHoursAdvancedFilter from '../../components/WorkedHoursAdvancedFilter';
import { IWorkedHourFilter, useWorkedHour } from '../../context/WorkedHourContext';
import { formatWorkedHoursFilter } from '../../utils/formatWorkedHoursFilter';
import { datePickerFormat } from '@shared/utils/datePickerFormat';

export const WorkedHoursInfoPending: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();

  const [workedHoursPending, setWorkedHoursPending] = useState<IWorkedHourReponse>();
  const { workedHoursFilter, date, setDate, handleResetFilterMonthly } = useWorkedHour();
  const [searchMonthly, setSearchMonthly] = useState<boolean>(false);
  const [totalHour, setTotalHour] = useState<string>();
  const [search, setSearch] = useState<string>('');

  const listWorkedHoursPending = useCallback(
    async (page: number = 0, filterToApply?: IWorkedHourFilter) => {
      if (!signedUser) return;
      const formattedFilter = filterToApply ? formatWorkedHoursFilter(filterToApply) : {};

      setIsLoading(true);
      const resWorkedHoursPending = await new ListWorkedHoursService(signedUser.token, newCancelToken()).execute({
        ...formattedFilter,
        query: search,
        page,
        size: 10,
        issued_by: signedUser.lmsUser.id,
        status: ['pending'],
        date: date,
      });
      if (resWorkedHoursPending === 'canceling') return;
      isMounted() && setIsLoading(false);
      if (resWorkedHoursPending && isMounted()) {
        setWorkedHoursPending(resWorkedHoursPending);
      }
    },
    [date, isMounted, newCancelToken, search, setIsLoading, signedUser]
  );

  const handleOnChangeInputDate = (newValue: string, keyName: string, objDateKey: string) => {
    const value = !!newValue.length ? newValue : undefined;
    setSearchMonthly(true);
    setDate((oldState: any) => {
      return { ...oldState, [objDateKey]: value };
    });
  };

  const getTotalWorkedHour = useCallback(async () => {
    if (!signedUser) return;
    const resTotalHour = await new GetTotalWorkedHourService(signedUser.token).execute({
      issued_by: signedUser.lmsUser.id,
      status: ['pending'],
      date: date,
    });

    if (resTotalHour && isMounted()) {
      setTotalHour(convertMinutesToHour(resTotalHour.worked_minutes_sum));
    }
  }, [date, isMounted, signedUser]);

  const formatedTotalHour = useMemo(() => {
    return totalHour ? `${totalHour}h` : undefined;
  }, [totalHour]);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      await Promise.all([listWorkedHoursPending(), getTotalWorkedHour()]);
      setIsLoading(false);
    }

    load();
  }, [getTotalWorkedHour, listWorkedHoursPending, setIsLoading]);

  const resetMonthly = useCallback(() => {
    handleResetFilterMonthly();
    setSearchMonthly(false);
  }, [handleResetFilterMonthly]);

  return (
    <Layout openTab={1} footer>
      <WorkedHoursAdvancedFilter
        onChangeMainInputCallback={(newValue) => setSearch(newValue)}
        handleOnClickApplyFilterCallback={() => listWorkedHoursPending(undefined, workedHoursFilter.current)}
      />

      <ContainerWorkedHours>
        <H6 fontWeight={800} className="h6-worked-hour" color="#2E3A59" fontSize={'16px'}>
          Período de:
        </H6>
        <InputDateWorkedHour>
          <input
            type="date"
            value={date?.start}
            name="date"
            alt={`${searchMonthly}`}
            onChange={(event) => handleOnChangeInputDate(event.target.value, event.target.name, 'start')}
          />
          <label>até</label>
          <input
            type="date"
            value={date?.end}
            name="date"
            alt={`${searchMonthly}`}
            onChange={(event) => handleOnChangeInputDate(event.target.value, event.target.name, 'end')}
          />
        </InputDateWorkedHour>
        <Divider width="100%" />

        <div className="btn-worked-hours">
          <Button
            transform="none"
            status="primary"
            statusNumber={400}
            shaded
            onClick={!searchMonthly ? () => listWorkedHoursPending(undefined, workedHoursFilter.current) : resetMonthly}
          >
            {!searchMonthly ? 'Aplicar filtros' : 'Resetar filtro'}
          </Button>
        </div>
      </ContainerWorkedHours>

      <div className="container-total-hours">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Total de Horas Pendentes de</label>
          <p>{`${datePickerFormat(date.start, '/', '-')} até ${datePickerFormat(date.end, '/', '-')}`}</p>
        </div>
        <Input placeholder="00h" disabled name="totalHours" defaultValue={formatedTotalHour} end={<Icon name="clock" fill="#8F9BB3" />} />
      </div>

      <div className="container-all-workedHours-cards">
        {workedHoursPending?.items.map((worked, index) => (
          <WorkedHoursCard key={index} workedHour={worked} handleOnClick={() => navigate(`/horas-trabalhadas/${worked.id}/pendente`)} />
        ))}
      </div>

      {!!workedHoursPending?.items.length && (
        <Pagination
          totalOfPages={workedHoursPending.totalPages}
          currentPage={workedHoursPending.currentPage}
          callbackGetListData={(_, page) => listWorkedHoursPending(page)}
          status="basic"
        />
      )}
    </Layout>
  );
};
