import { styled } from 'react-alicerce-components';

export const Container = styled.div`
   .main-navbar {
    height: 130px;
  }

  [role='tabpanel'] {
    padding: 20px;
    overflow-x: hidden;
  }

  .divider {
    margin-top: 0;
  }
`;
