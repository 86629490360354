import React from 'react';

import { ILocationStudentProviderProps, LocationStudentProvider } from './context/LocationStudentContext';

import { LocationContainer } from './partials/LocationContainer';
import { BlockDescription } from './partials/BlockDescription';
import { Legend } from './partials/Legend';
import { EditField } from './partials/EditField';
import { InfoObservation } from './partials/InfoObservation';

const LocationStudent: React.FC<ILocationStudentProviderProps> = ({
  showBlockDescription = true,
  showLegend,
  showEditable,
  isOpen = true,
  applicationIsFinalized,
  validationObservation,
  validationJustification,
  ...rest
}) => {
  const isFinalized = applicationIsFinalized ? applicationIsFinalized?.status === 'FINALIZED' : true;

  return (
    <LocationStudentProvider {...rest}>
      <LocationContainer isOpen={isOpen} />
      {showLegend && <Legend />}
      {showEditable && isFinalized && <EditField />}
      {showBlockDescription && <BlockDescription showLastApplication={showEditable} application={applicationIsFinalized} />}
      {validationObservation && <InfoObservation title={'Observação do instrutor'} description={validationObservation} />}
      {validationJustification && <InfoObservation title={'Motivo de Escolha de Bloco'} description={validationJustification} />}
    </LocationStudentProvider>
  );
};

export { LocationStudent };
