import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .main-navbar {
    height: 130px;
    align-items: flex-start;
  }

  [role='tabpanel'] {
    padding-bottom: 45px;
  }

  .pagination-content-icon {
    background-color: #34c5d0 !important;

    i svg {
      fill: #ffffff !important;
    }
  }

  .container-total-hours {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    .input-container {
      text-align: center;
      margin: 1.5rem 0;

      > div {
        width: 125px;
        cursor: default;
        margin: 10px auto 0 auto;

        input {
          cursor: default;
          width: 65px;
          text-align: center;
        }
      }
    }
    label {
      color: #2e3a59;
      font-family: var(--secondary-font);
      text-transform: unset;
      margin: 4px 0;
      -webkit-letter-spacing: 0px;
      -moz-letter-spacing: 0px;
      -ms-letter-spacing: 0px;
      letter-spacing: 0px;
      font-size: 14px;
    }

    > div p {
      font-size: 14px;
      color: #2e3a59;
      font-weight: 800;
    }
  }

  .container-filter-dates {
    margin: 1.5rem 0;

    label {
      color: #2e3a59;
      font-family: var(--secondary-font);
      text-transform: unset;
      margin: 4px 0;
      -webkit-letter-spacing: 0px;
      -moz-letter-spacing: 0px;
      -ms-letter-spacing: 0px;
      letter-spacing: 0px;
      font-size: 14px;
    }
    .container-filter-dates-input {
      display: flex;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;
      column-gap: 12px;

      > div:first-of-type {
        .calendar-picker-container {
          right: unset;
          left: 0;
        }
      }

      > div {
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: fill-available;
      }

      .input-container {
        > div {
          padding: 0.5rem;
          cursor: default;
        }
        .input-end-container {
          margin-left: 8px;
        }
        input {
          font-size: 12px;
          width: 65px;
          line-height: 0;
          cursor: default;
        }
      }
    }
  }

  .container-all-workedHours-cards {
    margin: 1.5rem 0;
  }

  .text-between-hours {
    font-family: var(--secundary);
    font-weight: bold;
    font-size: 14px;
    color: #2e3a59;
  }
`;

export const ContainerWorkedHours = styled.div`
  margin: 0 1rem;
  border-top: transparent;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  visibility: visible;
  margin-bottom: 1.5rem;
  border: 2px solid #e4e9f2;
  padding: calc(5px + 1rem) 1rem 1rem 1rem;

  .btn-worked-hours {
    display: flex;
    justify-content: center;
  }
`;

export const InputDateWorkedHour = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 14px;
  flex-direction: column;

  @media (min-width: 570px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  input {
    width: 100% !important;
    flex: 1;
    all: unset;
    font-weight: 700;
    height: 40px;
    box-sizing: border-box;
    padding: 5px;
    background: #f7f9fc;
    border: 2px solid #e4e9f2;
    border-radius: 5px;
    color: #c5cee0;

    @media (min-width: 570px) {
      max-width: 300px !important;
    }
  }

  label {
    width: 100%;
    flex: 1;
    text-align: center;

    @media (min-width: 570px) {
      max-width: 300px !important;
    }
  }
`;
