import React from 'react';
import { useNavigate } from 'react-router';

import { Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';

interface ILayoutProps {
  openTab?: number;
}

const LayoutOccurrencesContextSelection: React.FC<ILayoutProps> = ({
  openTab = 0,
  children
}) => {

  const navigate = useNavigate();

  return (
    <Container>
      <HeaderDirectory
        title='Seleção de Contexto'
      />
      <CustomTabs>
        <Tabs
          openTab={openTab}
          content={children}
          horizontalScroll
        >
          <Tab title='Turmas' onClick={() => navigate('/gravacoes/contexto/turmas')} />
          <Tab title='Locais' onClick={() => navigate('/gravacoes/contexto/locais')} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default LayoutOccurrencesContextSelection;
