import React, { useCallback } from 'react';

import Icon from 'react-eva-icons';

import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import { CSSProperties } from 'styled-components';

import Navbar from '@shared/components/Headers/Navbar';

import { Container, HeaderModuleDefaultContent, HeaderModuleTitle, HeaderModuleSubTitle, HeaderModuleAdditionalInfo } from './styles';
import { IMenuKebab, MenuKebab } from '../../MenuKebab';

type navContent = 'flex-end' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right';
export interface IHeaderModuleProps {
  title?: string;
  subtitle?: string;
  additionalInfo?: string;
  content?: JSX.Element | React.ReactNode;
  bgHeaderImg?: string;
  bgHeaderColor?: string;
  customStyle?: CSSProperties;
  useDefaultNav?: boolean;
  compressOnScroll?: boolean;
  isScrolled?: boolean;
  status?: ElementStatus;
  left?: JSX.Element | React.ReactNode;
  right?: JSX.Element | React.ReactNode;
  goBackCallback?: () => void;
  titleColor?: string;
  menuKebab?: IMenuKebab;
}

const HeaderModule: React.FC<IHeaderModuleProps> = ({
  left,
  right,
  bgHeaderImg,
  bgHeaderColor,
  content,
  customStyle,
  goBackCallback,
  title = '',
  subtitle = '',
  additionalInfo = '',
  useDefaultNav = false,
  status = 'primary',
  compressOnScroll = false,
  isScrolled = false,
  titleColor = '#222B45',
  menuKebab,
}) => {
  const GoBack = useCallback(() => {
    return (
      <div onClick={goBackCallback} style={{ cursor: 'pointer' }}>
        <Icon name="arrow-ios-back" />
      </div>
    );
  }, [goBackCallback]);

  return (
    <Container
      className="header-module-container"
      bgHeaderImg={bgHeaderImg}
      bgHeaderColor={bgHeaderColor}
      style={customStyle}
      status={status}
      compressOnScroll={compressOnScroll}
      isScrolled={isScrolled}
    >
      {useDefaultNav && <Navbar left={<GoBack />} right={<MenuKebab {...menuKebab} />} />}

      {!useDefaultNav && <Navbar title={title} subtitle={subtitle} left={left} right={right} />}

      {!content && (
        <HeaderModuleDefaultContent className="header-module-content">
          <HeaderModuleTitle titleColor={titleColor}>{title}</HeaderModuleTitle>
          <HeaderModuleSubTitle>{subtitle}</HeaderModuleSubTitle>
          <HeaderModuleAdditionalInfo className="additional-info">{additionalInfo}</HeaderModuleAdditionalInfo>
        </HeaderModuleDefaultContent>
      )}

      {content}
    </Container>
  );
};

export default HeaderModule;
