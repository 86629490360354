import headlightsConfig, { HeadlightType } from '@modules/projects/utils/headlightsConfig';
import {  styled } from 'react-alicerce-components';

export const Container = styled.div<{headlightType: HeadlightType}>`
  position: relative;
  max-width: 600px;
  margin: 0 auto;

  .custom-card > div {
    border-radius: 1.2rem;
    box-shadow: 0px 4px 8px 0px #0000001A;
  }

  .card-header {
    padding: .5rem;
    background-color: #BE3A3A;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    
    h6 {
      font-size:18px;
      color: #fff;
    }
  }

  .card-body {
    min-height: ${({ headlightType }) => headlightType !== 'basic' ? '90px' : 'fit-content' };
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #F7F9FC;
    
    span {
      position: relative;
      width: 100%;
      height: 1.5rem;
      border-radius: 2.5rem;
      border: 1px solid #8F9BB3;
      background-color: ${({ headlightType }) => headlightsConfig[headlightType].color};

      &::before {
        content: '${({ headlightType }) => headlightsConfig[headlightType].label}';
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        font-size: 1rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ headlightType }) => headlightType === 'basic' ? '#2E3A59' : '#FFF' };
      }
    }
  }
`;

export const HeadlightContainer = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: left !important;
`;