import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { ISkill } from '../../../shared/interfaces/ISkill';

type BlockSkills = {
  source_block_id: number,
  destination_block_id: number
}
export interface IListSkillsFromValidationProcess {
  source: Destination;
  destination: Destination;
}

export interface Destination {
  id: string;
  name: string;
  skills: ISkill[];
}

class ListSkillsFromValidationProcessService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(blockSkills: BlockSkills): Promise<any> {
    try {
      const res = await this.api.post('/external/mapa4/skill/block', { ...blockSkills });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListSkillsFromValidationProcessService;
