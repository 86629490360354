import React, { useCallback, useRef, useState } from 'react';
import { Spinner, Button, Checkbox, useToast } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import PageFooter from '@shared/components/PageFooter';

import { ContainerCheck, ProgramCreateContainer } from './styles';
import { ProgramForm } from '@modules/activities-library/components/ProgramForm';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { formatProgramCurrent } from '@modules/activities-library/utils/formatProgramCurrent';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { programSchema } from '@modules/activities-library/utils/programSchema';
import ConfirmModal from '@shared/components/ConfirmModal';
import CreateProgramService from '@modules/activities-library/services/CreateProgramService';
import useAuth from '@shared/store/auth/hook';
import { IFormatedProgram } from '@modules/activities-library/interfaces/IFormatedProgram';
import { useNavigate, useParams } from 'react-router-dom';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';

const ClassPlannerProgramCreate: React.FC = () => {
  const { isLoading, setIsLoading } = useGlobal();
  const { programCurrent, clearProgramContextData, setProgramCurrent } = useProgram();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const formatedBody = useRef<IFormatedProgram>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { classId, dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();

  // issue 802 rermove a opção de enviar para o pedagogico
  const showPedagogicOption = false;

  const handleSendToPedagogic = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formatedBody.current) return;
    const isChecked = event.target.checked;
    const value = isChecked ? 'pending' : 'private';
    formatedBody.current.status = value;
    setProgramCurrent((oldState: IProgram) => {
      oldState.status = value;
      return { ...oldState };
    });
  };

  const canSaveProgram = useCallback(async () => {
    if (!programCurrent || !signedUser) return;
    const programBodyFormated = formatProgramCurrent(programCurrent);
    let programErrors = await beforeSubmitForm(programBodyFormated, programSchema);
    setErrors(programErrors);
    if (programErrors.length !== 0) return;
    formatedBody.current = programBodyFormated as IFormatedProgram;
    setShowModal(true);
  }, [programCurrent, signedUser]);

  const createProgram = useCallback(async () => {
    if (!formatedBody || !formatedBody.current) return;
    setShowModal(false);
    setIsLoading(true);

    const res = await new CreateProgramService(signedUser?.token).execute(formatedBody.current);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Erro ao criar o programa.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Programas',
      description: 'Programa criado com sucesso.',
    });

    clearProgramContextData();
    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/programa/${res.id}/informacoes`);
  }, [addToast, classId, clearProgramContextData, dailyClassId, formatedBody, navigate, setIsLoading, signedUser]);

  return (
    <>
      <ProgramCreateContainer className="program-create-container">
        {isLoading && <Spinner fixed />}
        <HeaderDirectory
          title="Banco de Atividades"
          pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`}
          pathToReturnOptions={{ state: { activeTab: 'program' } }}
          backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        />

        <ProgramForm
          title="CRIAR PROGRAMA"
          pathEnvolvedTags={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/programa/selecionar/tags`}
          pathEnvolvedActivities={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/programa/selecionar/atividade`}
          errors={errors}
        />

        <PageFooter>
          <ButtonGradientOutline
            textColor="#FFF"
            onClick={() => navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`)}
            status="library_activity"
          >
            Cancelar
          </ButtonGradientOutline>
          <Button onClick={canSaveProgram} fill="#96B6F7" transform="none" shaded>
            Salvar
          </Button>
        </PageFooter>
      </ProgramCreateContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Deseja criar o Grupo?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={createProgram}
        subTitle={
          showPedagogicOption
            ? 'Ao continuar, você salvará o programa no seu banco pessoal. Caso queira também enviar para o pedagógico, selecione abaixo:'
            : undefined
        }
      >
        {showPedagogicOption && (
          <ContainerCheck>
            <Checkbox
              semiRound
              name="status"
              id="status"
              defaultChecked={programCurrent.status === 'private'}
              onChange={(event) => handleSendToPedagogic(event)}
            />
            <p>Enviar Atividade para Pedagógico</p>
          </ContainerCheck>
        )}
      </ConfirmModal>
    </>
  );
};

export { ClassPlannerProgramCreate };
