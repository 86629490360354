import { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { IHistoricEntities } from '../interfaces/IHistoricEntities';
import IListOptions from '../interfaces/IListOptions';
import { objectToQuery } from '../utils/objectToQuery';

export type IHistoric = {
  id: string;
  title: string;
  description: string;
  category: string;
  type: string;
  data: {
    en: string; // entidade       eg: Class 10 -> seria uma classe com id 10
    id: string; // id entidade
  }[];
  created_by: string;
  created_at: string;
};

export interface IHistoricReponse extends IPaginatedResponse {
  items: IHistoric[];
}

interface IListHistoricOptions extends IListOptions {
  created_at?: { start: string, end: string }
}

export class ListHistoricService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(entity: IHistoricEntities, entityId: number, options: IListHistoricOptions, query?: string): Promise<any | false> {
    try {
      let searchParams = objectToQuery({ query, order: 'created_at', sort: 'DESC', ...options }, true);
      const res = await this.api.get(`/historic/${entity}/${entityId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}
