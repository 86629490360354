import IPlace from '@modules/places/interfaces/IPlace';

export const formatPlace = (place: IPlace) => {
  const formated = {
    name: place.name,
    category: place.category,
    status: place.status,
    description: place.description?.trim() ? place.description : undefined,
    parent_id: place.parent?.id,
    virtual_place: place.virtual_place ? place.virtual_place : false,
    place_url: place.place_url,
    address_data: place.address_data,
    tag_ids: place.tags?.map((tag) => tag.id),
    lms_user_ids: place.admins?.map((ad) => ad.id),
  };

  if (!place.place_url) delete formated.place_url;
  if (!place.address_data) formated.address_data = null;

  return formated;
};
