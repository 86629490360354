import { H6, styled } from 'react-alicerce-components';

export const ClassAttendeesConatainer = styled.div`
  .accordion-info-item-container:first-of-type {
    margin-top: 10px;
  }

  .accordion-content > div h6 {
    margin: 0;
  }

  input + div {
    margin: 0;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    background: rgba(143, 155, 179, 0.16);
  }
`;

export const Label = styled(H6)`
  margin: 2rem 0 0 0;
  font-weight: 800;
  font-size: 14px;
  color: #482b85;
`;

export const ContainerAttendanceRetroactiveCheckbox = styled.div`
  .custom-card {
    > div {
      margin: 0;
    }
  }

  > div label {
    margin: 0;
  }

  > div h6 {
    margin-right: 1rem;
  }

  > div:first-child {
    flex: 1;
  }

  display: flex;
  margin: 2rem 0;
  column-gap: 10px;
`;

export const ContainerCards = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  justify-content: flex-end;
  input + div {
    width: 25px;
    height: 25px;
  }
`;
