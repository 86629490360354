import React from 'react';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';
import CustomTabs from '@shared/components/CustomTabs';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import NPSLeaderData from '@modules/dashboards/components/NPSLeaderData';
import NPSLeaderComments from '@modules/dashboards/components/NPSLeaderComments';

import { Container } from './styles';

const NPSLeader: React.FC = () => {

    const { isLoading } = useGlobal();

    return (
        <Container>
            {isLoading && <Spinner />}
            <HeaderDirectory title='NPS Líder' />
            <CustomTabs>
                <Tabs>
                    <Tab title="DADOS" >
                        <NPSLeaderData />
                    </Tab>
                    <Tab title="COMENTÁRIOS" >
                        <NPSLeaderComments />
                    </Tab>
                </Tabs>
            </CustomTabs>
        </Container>
    );
};

export default NPSLeader;
