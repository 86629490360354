import { styled } from 'react-alicerce-components';

export const Container = styled.div`

  label {
    color: #2E3A59;
    font-size: 12px;
    text-transform: none;
    font-family: var(--secondary-font);
    /* margin-top: 2rem; */
    margin-bottom: 1rem;
    display: block;
  }

  textarea, 
  input {
    color: #2E3A59;
    font-size: 12px;
    font-weight: 800;
  }
`;

