import React, { useCallback } from 'react';

import { Button } from 'react-alicerce-components';

import Divider from '@shared/components/Divider';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';

import { IndicatorsLayout as Layout } from '@modules/dashboards/components/IndicatorsLayout';
import WeekSelect, { IChangeWeek } from '@modules/dashboards/components/WeekSelect';

import { Container } from './styles';


const QualityIndicator: React.FC = () => {

    const handleOnChangeWeek = useCallback(async (data: IChangeWeek) => {
        console.log('data', data);
    }, []);

    return (
        <Layout openTab={2}>
            <Container>
                <WeekSelect handleChangeWeek={handleOnChangeWeek} />

                <Divider className='divider' width='100%' />

                <AccordionInfoItem title='Conformidade'>
                    <DonutChart
                        data={[{ name: '1', percent: 7, color: '#42AAFF' }]}
                        max={10}
                        contentCenter='7'
                        restStrokedColor='#42AAFF'
                        bottomLabel='Nota Máxima: 10'
                    >

                        <div className='donut-chart-children'>
                            <div className='box-container'>
                                <div className="box-label">MÉDIA DE OCORRÊNCIAS</div>
                                <div className="box-value"><b>1753</b>&nbsp;ocorrências</div>
                            </div>
                            <Button
                                status='basic'
                                transform="none"
                                color='#fff'
                                statusNumber={600}
                                noBorder
                                shaded
                            >
                                Ver Detalhes
                            </Button>
                        </div>
                    </DonutChart>
                </AccordionInfoItem>

                <AccordionInfoItem title='Planejamento'>
                    <DonutChart
                        data={[{ name: '1', percent: 4.5, color: '#42AAFF' }]}
                        max={10}
                        contentCenter='4.5'
                        restStrokedColor='#42AAFF'
                        bottomLabel='Nota Máxima: 10'
                    >

                        <div className='donut-chart-children'>
                            <div className='box-container'>
                                <div className="box-label">MÉDIA DE OCORRÊNCIAS</div>
                                <div className="box-value"><b>1753</b>&nbsp;ocorrências</div>

                                <div className="box-label">NUMERO DE MONITORIAS</div>
                                <div className="box-value"><b>295</b>&nbsp;monitorias</div>
                            </div>
                            <Button
                                status='basic'
                                transform="none"
                                color='#fff'
                                statusNumber={600}
                                noBorder
                                shaded
                            >
                                Ver Detalhes
                            </Button>
                        </div>
                    </DonutChart>
                </AccordionInfoItem>

                <AccordionInfoItem title='Formativo'>
                    <DonutChart
                        data={[{ name: '1', percent: 3, color: '#42AAFF' }]}
                        max={5}
                        contentCenter='3'
                        restStrokedColor='#42AAFF'
                        bottomLabel='Nota Máxima: 5'
                    >
                        <div className='donut-chart-children'>
                            <div className='box-container'>
                                <div className="box-label">NUMERO DE MONITORIAS</div>
                                <div className="box-value"><b>295</b>&nbsp;monitorias</div>
                            </div>
                            <Button
                                status='basic'
                                transform="none"
                                color='#fff'
                                statusNumber={600}
                                noBorder
                                shaded
                            >
                                Ver Detalhes
                            </Button>
                        </div>
                    </DonutChart>
                </AccordionInfoItem>

            </Container>
        </Layout>
    );
}

export default QualityIndicator;