import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .fc-view {
      overflow-x: scroll;
  }

  .fc-view > table {
      width: 200vw;
  }

  .week-select-icon i {
    width: 22px;
    height: 22px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .week-select-text {
    color: #568CF7;
    font-weight: 800;
  }

  .divider {
    margin-top: 0;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  .custom-card > div {
    margin: 10px 0;
  }
`;