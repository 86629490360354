import React, { useEffect, useState } from 'react';

import { SubscriptionCardBodyContainer } from './styles';

export interface SubscriptionCardBodyProps {
  isTrailsOpen: boolean;
}

const SubscriptionCardBody: React.FC<SubscriptionCardBodyProps> = (props) => {
  const [openTrails, setOpenTrails] = useState<boolean>(false);

  useEffect(() => {
    setOpenTrails(props.isTrailsOpen);
  }, [props.isTrailsOpen]);

  return <SubscriptionCardBodyContainer isTrailsOpen={openTrails}>{props.children}</SubscriptionCardBodyContainer>;
};

export { SubscriptionCardBody };
