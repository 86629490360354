import React, { useCallback, useState, Fragment } from 'react';
import Icon from 'react-eva-icons';

import { usePlaces } from '@modules/places/context/PlacesContext';
import { useNavigate } from 'react-router-dom';
import CreatePlaceService from '@modules/places/services/CreatePlaceService';
import useAuth from '@shared/store/auth/hook';
import { formatPlace } from '@modules/places/utils/formatPlace';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { placeSchema } from '@modules/places/utils/placeSchema';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import PlaceForm from '@modules/places/components/PlaceForm';
import { useToast } from 'react-alicerce-components';

const PlaceCreate: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { placeCurrent, clearPlaceCreate } = usePlaces();
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { addToast } = useToast();

  const handleCancel = useCallback(() => {
    clearPlaceCreate();
    navigate('/diretorio/locais');
  }, [clearPlaceCreate, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!placeCurrent) return;
    const formatedBody = formatPlace(placeCurrent);
    const canSubmitForm = await beforeSubmitForm(formatedBody, placeSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [placeCurrent]);

  const handleCreatePlace = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !placeCurrent) return;
    setIsLoading(true);
    const formatedBody = formatPlace(placeCurrent);
    const res = await new CreatePlaceService(signedUser.token).execute(formatedBody);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Locais',
        description: 'Local criado com sucesso.',
      });
      clearPlaceCreate();
      return navigate('/diretorio/locais');
    }

    addToast({
      status: 'danger',
      title: 'Locais',
      description: 'Não foi possível criar o Local.',
    });
  }, [addToast, clearPlaceCreate, navigate, placeCurrent, setIsLoading, signedUser]);

  return (
    <Fragment>
      <PlaceForm
        headerTitle="Criar Local"
        headerPathToReturn="/diretorio/locais"
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
        pathParentPlace="/diretorio/locais/criar/selecionar"
        pathAddTags="/diretorio/locais/criar/selecionar/tags"
        pathAddAdmins="/diretorio/locais/criar/selecionar/usuarios"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar este Local?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreatePlace}
      />
    </Fragment>
  );
};

export default PlaceCreate;
