import React, { useMemo, useState } from 'react';

import { P } from 'react-alicerce-components';

import Divider from '@shared/components/Divider';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';
import VerticalBarChart from '@shared/components/CustomRecharts/charts/VerticalBarChart';

import {
    BarContainer,
    Container,
    CustomLegend,
    Item,
    ItemContainer,
    ItemLabel,
} from './styles';

const NPSLeaderData: React.FC = () => {

    const [donutChartData] = useState<any[]>([
        { name: '42', percent: 42, color: '#FF3D71', label: 'Detratoras (6 a 0)' },
        { name: '88', percent: 88, color: '#FFAA00', label: 'Neutras (8 a 7)' },
        { name: '753', percent: 753, color: '#00D68F', label: 'Promotoras (10 a 9)' },
    ]);

    const [barChartData] = useState([
        { name: 'Muito Satisfeito', percent: 80 },
        { name: 'Satisfeito', percent: 10 },
        { name: 'Pouco Satisfeito', percent: 3 },
        { name: 'Insatisfeito', percent: 2 }
    ]);

    const [barsChartConfig] = useState([
        { dataKey: 'percent', color: '#42AAFF' }
    ]);

    const [defaultProps] = useState({
        data: barChartData,
        barsConfig: barsChartConfig,
        height: 70,
        margin: { top: 40, right: 50, left: 40, bottom: 40 },
        showYAxis: true
    });

    const getCustomLegendData = useMemo(() => {
        const total = donutChartData
            .map((d) => d.percent)
            .reduce((ac, value) => ac + value, 0);

        return [
            {
                name: total,
                percent: total,
                color: '#C5CEE0',
                labelColor: '#222B45',
                label: 'Total de Respostas'
            },
            ...donutChartData
        ];

    }, [donutChartData]);

    return (
        <Container>
            <DonutChart
                data={donutChartData}
                contentCenter={<p>NPS<br />0,48</p>}
            />

            <CustomLegend>
                {getCustomLegendData.map((item, i) =>
                    <ItemContainer key={i}>
                        <ItemLabel>{item.label}</ItemLabel>
                        <Item
                            color={item.color}
                            labelColor={item?.labelColor}
                        >
                            {item.name}
                        </Item>
                    </ItemContainer>
                )}
            </CustomLegend>

            <AccordionInfoItem title='Trilhas Pedagógicas'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Universidade LiderA'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Recursos Oferecidos'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Programas de Diversidade'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Banco de Atividades'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Suporte Pedagógico'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='MAPA - Avaliação Digital'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='MAPA - Habilidades para Vida'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Remuneração'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Relacionamento entre Coordenadores'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Comunicação Interna'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Suporte Administrativo e Financeiro'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Princípios e Cultura Alicerce'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <AccordionInfoItem title='Formação e Treinamento'>
                <VerticalBarChart {...defaultProps} />
            </AccordionInfoItem>

            <Divider className='divider' width='100%' />

            <P fontWeight={800} color='#8F9BB3'>MOTIVAÇÃO PARA SER LÍDER ALICERCE:</P>

            <BarContainer>
                <P>Desenvolver e aplicar técnicas de ensino</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Contribuir para Melhorar a Educação no Brasil</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Desenvolvimento Pessoal</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Criar uma Rede de Contatos</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Acelerar a Carreira</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Tornar - me um coordenador de coletivo ou gestor de tribo</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Me Tornar um Líder Administrativo</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

            <BarContainer>
                <P>Complementar ou Gerar Fonte de Renda</P>
                <VerticalBarChart
                    data={[{ percent: 70 }]}
                    barsConfig={barsChartConfig}
                    height={60}
                    isPercent={false}
                />
            </BarContainer>

        </Container>
    );
}

export default NPSLeaderData;