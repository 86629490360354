import { ISelectOption } from 'react-alicerce-components';

export const EVENT_TYPE_NORMAL = { name: 'Normal', value: 'normal' };
export const EVENT_TYPE_TASK = { name: 'Tarefa', value: 'task' };
export const EVENT_TYPE_HOLIDAY = { name: 'Dia Sem Aula', value: 'holiday' };

export const EVENT_TYPE_CONSTRAINTS = [EVENT_TYPE_NORMAL, EVENT_TYPE_TASK, EVENT_TYPE_HOLIDAY];

export const defaultEventTypes = (eventType?: string | null): ISelectOption[] | undefined => {
  return [
    { name: EVENT_TYPE_NORMAL.name, value: EVENT_TYPE_NORMAL.value, selected: eventType === EVENT_TYPE_NORMAL.value },
    { name: EVENT_TYPE_TASK.name, value: EVENT_TYPE_TASK.value, selected: eventType === EVENT_TYPE_TASK.value },
    { name: EVENT_TYPE_HOLIDAY.name, value: EVENT_TYPE_HOLIDAY.value, selected: eventType === EVENT_TYPE_HOLIDAY.value },
  ];
};
