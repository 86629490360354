import React, { useMemo } from 'react';

import Layout from '@modules/schedules/components/Layout';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import WrapperContent from '@modules/schedules/components/WrapperContent';
import Schedule from '@modules/schedules/components/Schedule';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

const ConsolidatedSchedule: React.FC = () => {
  const { state } = useSchedules();

  const filterBy = useMemo(() => ({ [`${state?.resource}`]: state?.id as string }), [state]);

  return (
    <Layout openTab={0}>
      <DirectoryContainer style={{ height: 'auto', margin: '0', overflow: 'initial', padding: '0 0 2rem 0' }} footer>
        <WrapperContent>
          <Schedule filterBy={filterBy} />
        </WrapperContent>
      </DirectoryContainer>
    </Layout>
  );
};

export default ConsolidatedSchedule;
