import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .pagination-container .pagination-content {
    label {
      background-color: #34c5d0;

      i svg {
        fill: #fff;
      }
    }
  }
`;
