import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import useAuth from '@shared/store/auth/hook';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Button, Checkbox, Label, Pagination, Spinner } from 'react-alicerce-components';
import { WrapperContent } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import StudentCard from '../StudentCard';
import ListStudentsService, { IStudentReponse } from '@modules/students/services/ListStudentsService';
import { IStudent } from '@modules/students/interfaces/IStudent';
import PageFooter from '@shared/components/PageFooter';
import { useStudent } from '@modules/students/context/StudentContext';
import { BsCheckLg } from 'react-icons/bs';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import Divider from '@shared/components/Divider';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { STUDENT_STATUS_CONSTRAINTS } from '../../utils/validData/validStatus';
import { STUDENT_RELATIONSHIP_CONSTRAINTS } from '../../utils/validData/validRelationships';

interface IStudentList {
  classId?: string;
  headerTitle: string;
  pathToReturn?: string | number;
  btnText?: string;
  pathRedirect?: string;
  canSelectStudent?: 'one' | 'many';
  handleOnClick?: (student: any) => void;
  selectedItens?: IStudent[];
  setSelectedItens?: (student: any) => void;
}

type IStudentFilter = {
  age_groups?: string[];
  status?: string[];
  relationship_types?: string[];
};

const StudentList: React.FC<IStudentList> = ({
  headerTitle,
  pathToReturn,
  btnText,
  pathRedirect,
  canSelectStudent,
  handleOnClick,
  selectedItens,
  setSelectedItens,
}) => {
  const { classId } = useParams();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const [studentList, setStudentList] = useState<IStudentReponse>();
  const [inputSearch, setInputSearch] = useState<string>('');
  const [studentSelected, setStudentSelected] = useState<IStudent[]>(selectedItens || []);
  const { clearStudentContextData } = useStudent();
  const studentFilter = useRef<IStudentFilter>({});

  const checkSelectedStudents = useCallback((selectedStudents: IStudent[], clickedStudent: IStudent): false | IStudent[] => {
    const found = selectedStudents.findIndex((p) => p.id === clickedStudent.id);
    if (found === -1) return false;
    selectedStudents.splice(found, 1);
    return selectedStudents;
  }, []);

  const handleOnClickStudentCard = useCallback(
    (student: IStudent) => {
      if (!canSelectStudent) navigate(`/aluno/${student.id}/informacoes`);

      if (canSelectStudent === 'one') {
        if (studentSelected[0]?.id === student.id) return setStudentSelected([]);
        const aux = [] as IStudent[];
        aux.push(student);
        setStudentSelected(aux);
      }

      if (canSelectStudent === 'many') {
        const res = checkSelectedStudents(studentSelected || [], student);
        if (!res) return setStudentSelected([...(studentSelected || []), student]);
        setStudentSelected([...res]);
      }
    },
    [canSelectStudent, checkSelectedStudents, navigate, studentSelected, setStudentSelected]
  );

  const handleClickBtn = useCallback(() => {
    if (canSelectStudent === 'one') {
      setSelectedItens && studentSelected[0] && setSelectedItens(studentSelected[0]);
    } else if (canSelectStudent === 'many') {
      setSelectedItens && setSelectedItens(studentSelected);
    } else clearStudentContextData();
    pathRedirect ? navigate(pathRedirect) : navigate(-1);
  }, [canSelectStudent, clearStudentContextData, setSelectedItens, navigate, pathRedirect, studentSelected]);

  const listStudents = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const students = await new ListStudentsService(signedUser.token, newCancelToken()).execute(inputSearch, {
        page,
        size: 10,
        class_id: classId,
        ...studentFilter.current,
      });
      if (students === 'canceling') return;
      setIsLoading(false);
      if (!students) return;
      setStudentList(students);
    },
    [classId, inputSearch, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listStudents();
  }, [listStudents]);

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objKey: string) => {
      const isChecked = event.target.checked;
      const value = event.target.id;

      if (isChecked) {
        if (!studentFilter.current[objKey]) {
          studentFilter.current[objKey] = [value];
          return;
        }

        studentFilter.current[objKey].push(value);
      } else {
        if (!studentFilter.current[objKey]) return;
        const filtered = studentFilter.current[objKey].filter((foo: string) => foo !== value);
        studentFilter.current[objKey] = filtered;
      }
    },
    [studentFilter]
  );

  const handleResetFilter = () => {
    studentFilter.current = {};
    listStudents();
  };

  const isChecked = (objKey: string, value: string) => {
    if (!studentFilter.current[objKey]) return false;
    return studentFilter.current[objKey].find((foo: string) => foo === value);
  };

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory
        status="secondary"
        title={headerTitle}
        pathToReturn={pathToReturn}
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'StudentDirectory' },
        }}
      />
      <DirectoryContainer footer={true}>
        <WrapperContent status="secondary">
          {/* START FILTER */}
          <AdvancedFilter
            onChangeMainInput={(newValue) => setInputSearch(newValue)}
            applyFilterButtonBackground="#35A0D6"
            handleResetFilter={handleResetFilter}
            handleApplyFilter={listStudents}
          >
            <Label>Grupo Etário:</Label>
            <section className="checkbox-section">
              {AGE_GROUP_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'age_groups');
                  }}
                  defaultChecked={isChecked('age_groups', ag.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Status:</Label>
            <section className="checkbox-section">
              {STUDENT_STATUS_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'status');
                  }}
                  defaultChecked={isChecked('status', ag.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Tipos de Relacionamento:</Label>
            <section className="checkbox-section">
              {STUDENT_RELATIONSHIP_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'relationship_types');
                  }}
                  defaultChecked={isChecked('relationship_types', ag.value)}
                />
              ))}
            </section>
          </AdvancedFilter>
          {/* END FILTER */}

          {studentList?.items.map((s: IStudent) => (
            <StudentCard
              handleOnClick={handleOnClickStudentCard}
              student={s}
              key={s.id}
              canSelect={!!canSelectStudent}
              studentsSelected={studentSelected}
            />
          ))}
          {studentList && studentList.items.length > 0 && (
            <Pagination
              totalOfPages={`${studentList.totalPages}`}
              currentPage={`${studentList.currentPage}`}
              callbackGetListData={(_, page) => listStudents(page)}
              status="secondary"
            />
          )}
        </WrapperContent>
        {btnText && (
          <PageFooter>
            <Button transform="none" status="secondary" shaded onClick={handleClickBtn}>
              {btnText}
            </Button>
          </PageFooter>
        )}
      </DirectoryContainer>
    </Fragment>
  );
};
export default StudentList;
