import { styled } from 'react-alicerce-components';

export const FilterBlocksContainer = styled.div`
  .accordion-content {
    border-radius: 0px 0px 8px 8px;
  }

  .container-accordion-content {
    border-radius: 0px 0px 8px 8px;
    border-right: solid #c5cee0 1px;
    border-left: solid #c5cee0 1px;
    border-bottom: solid #c5cee0 1px;
  }

  .container-selects {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    > .select-container {
      .select-options-list {
        height: 100px;
      }
    }
  }

  .container-buttons-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 0 1.5rem 1.5rem 1.5rem;

    button {
      background: #96b6f7;
      flex-grow: 1;
      max-width: 280px;
    }
  }
`;
