import { Spinner, Button, useToast } from 'react-alicerce-components';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Icon from 'react-eva-icons';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import PageFooter from '@shared/components/PageFooter';

import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import GetActivityService from '@modules/activities-library/services/GetActivityService';
import { IActivityLibrary } from '../../interfaces/IActivityLibrary';
import { FavoriteToggleActivityService } from '@modules/activities-library/services/FavoriteToggleActivityService';
import ConfirmModal from '@shared/components/ConfirmModal';
import DeleteActivityService from '@modules/activities-library/services/DeleteActivityService';
import { ActivityLibraryForm } from '../../components/ActivityLibraryForm';

const ActivityLibraryInfo: React.FC = () => {
  const { activityLibraryCurrent, setActivityLibraryCurrent } = useActivity();
  const { activityId } = useParams<{ activityId: string }>();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { isLoading, setIsLoading, handleDownloadAllFiles, handleDownloadSingleFile } = useGlobal();

  const getActivity = useCallback(async () => {
    if (!activityId) return;
    setIsLoading(true);
    const res = await new GetActivityService(signedUser?.token).execute(activityId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Recuperar Atividade.',
      });
      return navigate(-1);
    }

    if (!isMounted()) return;
    setActivityLibraryCurrent(res);
  }, [activityId, addToast, isMounted, navigate, setActivityLibraryCurrent, setIsLoading, signedUser]);

  const toggleFavoriteActivity = useCallback(async () => {
    setIsLoading(true);
    const res = await new FavoriteToggleActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Favoritar/Desfavoritar Atividade.',
      });
      return;
    }
    if (!isMounted()) return;
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      return { ...oldState, favorited: res.favorited };
    });
  }, [activityLibraryCurrent.id, addToast, isMounted, setActivityLibraryCurrent, setIsLoading, signedUser]);

  const deleteActivity = useCallback(async () => {
    setShowModal(false);
    setIsLoading(true);
    const res = await new DeleteActivityService(signedUser?.token).execute(`${activityLibraryCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Deletar Atividade.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Atividade',
      description: 'Atividade Deletada com Sucesso.',
    });
    navigate(-1);
    return;
  }, [activityLibraryCurrent.id, addToast, navigate, setIsLoading, signedUser]);

  const handleCopyActivity = useCallback(() => {
    navigate(`/banco-atividades/criar/atividade`);
  }, [navigate]);

  useEffect(() => {
    getActivity();
  }, [getActivity]);

  const isEditButtonDisabled = useMemo(() => {
    if (Number(activityLibraryCurrent.created_by) !== Number(signedUser?.lmsUser.id)) return true;
    if (activityLibraryCurrent.status === 'public') return true;
  }, [activityLibraryCurrent.created_by, activityLibraryCurrent.status, signedUser]);

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{
          deleteCallback: () => setShowModal(true),
          copyCallback: () => handleCopyActivity(),
          favorited: { favoritedCallback: () => toggleFavoriteActivity(), isFavorited: activityLibraryCurrent.favorited || false },
          menuBackHome: true,
        }}
      />
      <DirectoryContainer footer={true}>
        {activityId === activityLibraryCurrent.id && (
          <ActivityLibraryForm
            downlodSingleFileCallback={handleDownloadSingleFile}
            downloadAllFilesCallback={() => handleDownloadAllFiles(activityLibraryCurrent.attachments)}
            formAction="watching"
            errors={[]}
          />
        )}

        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() => navigate(`/banco-atividades/atividade/${activityLibraryCurrent.id}/editar`)}
            className="mochileiro-color"
            disabled={isEditButtonDisabled}
          >
            Editar Atividade
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Deseja Excluir Atividade?  "
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={deleteActivity}
        subTitle={'Você só pode excluir esta atividade pois ela está no seu banco pessoal e ainda não foi usada em nenhum Planejador de Aula.'}
      />
    </Fragment>
  );
};

export { ActivityLibraryInfo };
