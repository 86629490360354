import styled from "styled-components";

export const SprintDiagnosticsContainer = styled.div`
    font-size: 12px;
    font-weight: 600;
    span {
        font-weight: 700;
    }
`
export const DiagnosticContainer = styled.div`
    margin: 0.5rem 0rem 1.5rem 0rem ;
    padding: 0.3rem;
    border: 2px solid #E4E9F2;
    border-radius: 5px;
    background-color: #F7F9FC;
    text-align: justify;
    line-height: 16px
`