import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .h6-activity-library {
    color: #b85ec4;
    margin: 1.5rem 0;
  }

  .first-h6-page {
    margin-top: 0;
  }

  .container-duration-ageGroup {
    display: flex;
    column-gap: 1.5rem;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    .input-container input {
      width: inherit;
    }

    .select-container {
      margin: 1.5rem 0;
      flex: 1;

      .select-option-text {
        min-width: 200px;
      }
    }
  }

  .container-modality {
    margin: 1.5rem 0;
    .container-modality-checkbox {
      display: flex;
      align-items: center;
      column-gap: 3.5rem;
    }
  }

`;
