import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from 'react-eva-icons';
import { Button, H6, Input, Pagination } from 'react-alicerce-components';

import Layout from '@modules/home/components/Layout';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ListClassesService, { IClassesReponse } from '@modules/classes/services/ListClassesService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { Container } from './styles';
import ClassCard from '@modules/classes/components/ClassCard';
import axios, { CancelTokenSource } from 'axios';
import IClass from '@modules/classes/interfaces/IClass';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useClass } from '@modules/classes/context/ClassContext';

const MyClasses: React.FC = () => {
  const navigate = useNavigate();
  const { setPathToReturn } = useClass();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [favoritedClasses, setFavoritedClasses] = useState<IClassesReponse>();
  const isMounted = useIsMounted();
  const cancelRequestFavorited = useRef<CancelTokenSource>();
  const [searchValue, setSearchValue] = useState<string>('');

  const listFavoritedClasses = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      cancelRequestFavorited.current && cancelRequestFavorited.current.cancel('Cancelling search');
      const newCancelToken = () => {
        cancelRequestFavorited.current = axios.CancelToken.source();
        return cancelRequestFavorited.current;
      };
      const res = await new ListClassesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 4,
        favorited: true,
        order: "class.status"
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res && isMounted()) setFavoritedClasses(res);
    },
    [isMounted, setIsLoading, signedUser]
  );

  useEffect(() => {
    listFavoritedClasses();
  }, [listFavoritedClasses]);

  const filterClasses = useCallback(
    async (search: string, page = 0) => {
      await listFavoritedClasses(search, page);
      setSearchValue(search);
    },
    [listFavoritedClasses]
  );

  const handleClickClassCard = useCallback(
    (classClicked: IClass) => {
      navigate(`/turma/${classClicked.id}`, { state: { pathToReturn: '/minhas-turmas' } });
    },
    [navigate]
  );

  useEffect(() => {
    setPathToReturn('/minhas-turmas');
  }, [setPathToReturn]);

  return (
    <>
      <Layout openTab={1} footer={true}>
        <Container>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => filterClasses(event.target.value)}
          />

          <H6 fontWeight={800} status="quaternary" textAlign="center">
            TURMAS FAVORITAS
          </H6>
          <div className="container-favorited-classes">
            {favoritedClasses?.items.map((c) => (
              <ClassCard classInfo={c} key={c.id} handleOnClick={handleClickClassCard} />
            ))}
            {!!favoritedClasses?.items.length && (
              <Pagination
                totalOfPages={`${favoritedClasses?.totalPages}`}
                currentPage={`${favoritedClasses?.currentPage}`}
                callbackGetListData={listFavoritedClasses}
              />
            )}
          </div>

          <Divider />

          <ClassesSelection handleOnClick={handleClickClassCard} searchValue={searchValue} orderClassesUserManages orderMyClasses />

          <PageFooter>
            <Button transform="none" status="quaternary" shaded onClick={() => navigate('/diretorio/turmas')}>
              Diretório de Turmas
            </Button>
          </PageFooter>
        </Container>
      </Layout>
    </>
  );
};

export default MyClasses;
