import { styled } from 'react-alicerce-components';

export const PendingSubscriptionsContainer = styled.div``;

export const ContainerSelectAll = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > label {
    font-weight: bold;
    font-size: 14px;
    color: #2e3a59;
  }

  .checkbox-container {
    margin: 0;
    label {
      margin: 0;
    }
    input + div {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  gap: 1rem;
  margin: var(--card-distance);

  .checkbox-container {
    margin: 0;
    label {
      margin: 0;
    }
    input + div {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  .subscription-card {
    flex: 1;
  }
`;
