import { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IPortfolioActivity } from '../interfaces/IPortfolioActivity';
import { IFormatedPortfolioActivity } from '../interfaces/IFormatedPortfolioActivity';

type ICreatePortfolioResponse = {
  success: boolean;
  data?: IPortfolioActivity;
  errorMessage?: string;
};
class CreatePortfolioActivityService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(portfolioActivityBody: IFormatedPortfolioActivity, classId: string): Promise<ICreatePortfolioResponse> {
    try {
      const res = await this.api.post('/portfolio', { ...portfolioActivityBody, class_id: classId });
      return { success: true, data: res.data };
    } catch (error: any) {
      const { message } = error.response.data;
      console.error(`Error: ${error}`);
      return { success: false, errorMessage: message };
    }
  }
}

export { CreatePortfolioActivityService };
