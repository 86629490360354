import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spinner, Button, H6, Grid } from 'react-alicerce-components';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CurrentSprintAndPeriod from '@modules/projects/components/CurrentSprintAndPeriod';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';
import GetCurrentPeriodService from '@modules/periods/services/GetCurrentPeriodService';
import { HeadlightView, HeadlightViewHandle } from '@modules/projects/components/HeadlightView';
import GetHeadlightService from '@modules/projects/services/Headlight/GetHeadlightService';
import ConfirmModal from '@shared/components/ConfirmModal';
import Icon from 'react-eva-icons';
import { formatHeadlight } from '@modules/projects/utils/formatHeadlight';
import { beforeSubmitForm } from '@shared/utils/beforeSubmitForm';
import { headlightSchema } from '@modules/projects/schemas/Headlight';
import * as Yup from 'yup';
import { useToast } from 'react-alicerce-components';
import EditHeadlightService from '@modules/projects/services/Headlight/EditHeadlightService';

const ProjectHeadlightEdit: React.FC = () => {

  const { isLoading, setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setCurrentSprint, setCurrentPeriod, setCurrentHeadlight, currentHeadlight, setHeadlightErros } = useHeadlights();
  const { id: headlightId } = useParams<{ id: string }>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const headlightViewRef = useRef<HeadlightViewHandle>(null);
  const { addToast } = useToast();

  const getCurrentSprintAndPeriod = useCallback(async (scheduleId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (scheduleId) {
      const sprint = await new GetCurrentSprintService(signedUser.token).execute({ schedule_id: Number(scheduleId) });
      if (!sprint) return;
      setCurrentSprint(sprint);

      const period = await new GetCurrentPeriodService(signedUser.token).execute(Number(sprint.id));
      if (!period) return;
      setCurrentPeriod(period);
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser, setCurrentSprint, setCurrentPeriod]);

  useEffect(() => {
    getCurrentSprintAndPeriod(1)
  }, [getCurrentSprintAndPeriod]);

  const getHeadlight = useCallback(async (headlightId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (headlightId) {
      const headlight = await new GetHeadlightService(signedUser.token).execute(headlightId);
      if (!headlight) return;
      setCurrentHeadlight(headlight)
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser, setCurrentHeadlight]);

  useEffect(() => {
    getHeadlight(headlightId)
  }, [getHeadlight, headlightId]);

  const handleEditHeadlight = useCallback(async () => {
    if (!currentHeadlight) return;
    const formatedBody = formatHeadlight(currentHeadlight);
    const headlightCategoriesNumber = headlightViewRef.current?.getheadlightCategories().length || 0;
    const headlightCurrentSchema = headlightSchema.shape({
      data: headlightSchema.fields.data.concat(
        Yup.array().min(headlightCategoriesNumber, { name: "category_status", message: 'O campo é obrigatório' })
      )
    });
    const canSubmitForm = await beforeSubmitForm(formatedBody, headlightCurrentSchema);
    setHeadlightErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowEditModal(true);

  }, [setHeadlightErros, currentHeadlight]);

  const editHeadlight = useCallback(async () => {
    setShowEditModal(false);
    if (!signedUser || !currentHeadlight) return;
    setIsLoading(true);
    const formatedBody = formatHeadlight(currentHeadlight);

    const res = await new EditHeadlightService(signedUser.token).execute(String(currentHeadlight?.id), formatedBody);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Farol',
        description: 'Farol editado com sucesso.',
      });
      return navigate(`/projetos/farol/${headlightId}`);
    }

    addToast({
      status: 'danger',
      title: 'Farol',
      description: 'Não foi possível editar o Farol.',
    });
  }, [navigate, setIsLoading, signedUser, addToast, currentHeadlight, headlightId]);

  return (
    <ProjectDefaultStylesContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title={'Editar Farol'}
        onClickReturn={() => navigate(-1)}
        menuKebab={{ tooltip: { view: 'HeadlightEdit' }, menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        <CurrentSprintAndPeriod />
        <Grid textCenter>
          <H6>{currentHeadlight && currentHeadlight.project?.name}</H6>
        </Grid>
        <HeadlightView ref={headlightViewRef} />
        <PageFooter>
          <Button
            className='btn-cancel'
            transform="none"
            onClick={() => navigate(`/projetos/farol/${headlightId}`)}
            shaded
            outline
          >
            Cancelar
          </Button>
          <Button
            transform="none"
            shaded
            noBorder
            onClick={handleEditHeadlight}
          >
            Salvar Farol
          </Button>
        </PageFooter>
      </DirectoryContainer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(!showEditModal)}
        title="Deseja editar este Farol?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={editHeadlight}
      />
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectHeadlightEdit;
