import React from 'react';
import { Pill } from 'react-alicerce-components';
import { ClassCardEnrollmentBorderContainer } from './styles';


export interface IClassCardEnrollmentBorder {
  data: string[];
  location: 'header' | 'footer';
}

const ClassCardEnrollmentBorder: React.FC<IClassCardEnrollmentBorder> = (props) => {
  return (
    <ClassCardEnrollmentBorderContainer location={props.location}>
      {props.data.map((d, index) => (
        <Pill key={index}>{d}</Pill>
      ))}
    </ClassCardEnrollmentBorderContainer>
  );
};

export default ClassCardEnrollmentBorder;
