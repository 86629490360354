import { ISelectOption } from 'react-alicerce-components';

// dias da semana
export const SUNDAY = { name: 'Domingo', value: 'sun' }; // Domingo
export const MONDAY = { name: 'Segunda', value: 'mon' }; // Segunda
export const TUESDAY = { name: 'Terça', value: 'tue' }; // Terça
export const WEDNESDAY = { name: 'Quarta', value: 'wed' }; // Quarta
export const THURSDAY = { name: 'Quinta', value: 'thu' }; // Quinta
export const FRIDAY = { name: 'Sexta', value: 'fri' }; // Sexta
export const SATURDAY = { name: 'Sábado', value: 'sat' }; // Sábado

export const WEEK_DAYS_CONSTRAINTS = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];

export const defaultWeekdays = (weekDay?: string | null): ISelectOption[] | undefined => {
  return [
    { name: SUNDAY.name, value: SUNDAY.value, selected: weekDay === SUNDAY.value },
    { name: MONDAY.name, value: MONDAY.value, selected: weekDay === MONDAY.value },
    { name: TUESDAY.name, value: TUESDAY.value, selected: weekDay === TUESDAY.value },
    { name: WEDNESDAY.name, value: WEDNESDAY.value, selected: weekDay === WEDNESDAY.value },
    { name: THURSDAY.name, value: THURSDAY.value, selected: weekDay === THURSDAY.value },
    { name: FRIDAY.name, value: FRIDAY.value, selected: weekDay === FRIDAY.value },
    { name: SATURDAY.name, value: SATURDAY.value, selected: weekDay === SATURDAY.value },
  ];
};
