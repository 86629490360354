export const mapaStatusName = [
    { name: 'Feito', value: 'FINALIZED' },
    { name: 'Em Progresso', value: 'ONGOING' },
    { name: 'Pendente', value: 'PENDING' },
    { name: 'Sem Aplicação', value: 'true' },
  ];

export const trailsValues = [
  {
    name: 'Matemática',
    blocks: [],
    value: `3`,
  },
  {
    name: 'Leitura',
    blocks: [],
    value: `2`,
  },
      {
    name: 'Redação',
    blocks: [],
    value: `4`,
  },
  {
    name: 'Inglês',
    blocks: [],
    value: `9`,
  },
]