import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from './styles';
import { Select, Spinner } from 'react-alicerce-components';

import WrapperContent from '@modules/launchers/components/WrapperContent';
import GetClassService from '@modules/classes/services/GetClassService';
import { useLearningTargets } from '@modules/learning-targets/context/LearningTargetsContext';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import IClass from '@modules/classes/interfaces/IClass';
import { ITrails } from '@shared/interfaces/ITrail';
import useAuth from '@shared/store/auth/hook';
import { LearningTargets } from '../../components/LearningTargets';

const LearningTargetsTrails: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { trails } = useLearningTargets();
  const { newCancelToken } = useCancelRequest();
  const { setIsLoading, isLoading } = useGlobal();
  const { classId } = useParams<{ classId: string }>();
  const [currentClass, setCurrentClass] = useState<IClass>();
  const [currentTrail, setCurrentTrail] = useState<string | undefined>();

  const getClass = useCallback(async () => {
    if (!classId) return navigate(`/metasdeaprendizagem/contexto/turmas`);
    setIsLoading(true);
    const res = await new GetClassService(signedUser?.token, newCancelToken()).execute(classId);
    setIsLoading(false);
    if (!res) return navigate('/minhas-turmas');
    setCurrentClass(res);
  }, [classId, navigate, newCancelToken, setIsLoading, signedUser]);

  const normalizeStrToParams = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  const getTrailsByClassType = (currentClassType: string, trails: ITrails[]) => {
    if (currentClassType === 'english') return trails.filter((trail) => trail.name === 'Inglês');
    if (currentClassType === 'core') return trails.filter((trail) => trail.name === 'Matemática' || trail.name === 'Leitura');
    return trails;
  };
  const pathToReturn = classId ? `/turma/${classId}` : '/metasdeaprendizagem/contexto/turmas'

  useEffect(() => {
    if (!classId) return;
    getClass();
  }, [classId, getClass]);
  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Metas de Aprendizagem"
        backgroundHeader="linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)"
        pathToReturn={pathToReturn}
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'LearningTargetsTrails' },
        }}
      />

      <DirectoryContainer style={{ marginTop: '0px', paddingTop: 0 }} footer={false}>
        <WrapperContent customStyle={{ marginTop: '45px' }}>
          {currentClass?.type && (
            <Select
              label="Trilha:"
              iconName="arrow-down"
              placeholder="Trilha"
              fullWidth
              required
              options={getTrailsByClassType(currentClass?.type, trails)}
              handleSelectChange={(option) => setCurrentTrail(option?.name)}
            />
          )}

          {currentTrail && <LearningTargets id={classId} trail={normalizeStrToParams(currentTrail)} key={`${classId}-${currentTrail}`} />}
        </WrapperContent>
      </DirectoryContainer>
    </Container>
  );
};

export { LearningTargetsTrails };
