import styled from 'styled-components';

interface IContainerProps {
  selected?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  max-width: 85%;

  .container-text-ellipsis {
    line-height: normal;
    display: inline;
  }

  .selected-tag {
   /*  filter: drop-shadow(0px 0px 28px #82F210); */
    box-shadow: inset 0px 0px 5px #82F210;
    filter: drop-shadow(0px 0px 28px #82F210);
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 56vw;
`;
