import React, { useCallback } from 'react';
import DaysAndHourForm from '@modules/classes/components/DaysAndHoursForm';
import { useClass } from '@modules/classes/context/ClassContext';
import { ICalendarData } from '@modules/classes/interfaces/IClass';

const DaysAndHourRelation: React.FC = () => {
  const { setClassCurrent } = useClass();

  const handleSave = useCallback(
    (newCalendarData: ICalendarData) => {
      setClassCurrent((oldState: any) => {
        return { ...oldState, calendar_data: [...oldState.calendar_data, newCalendarData] };
      });
    },
    [setClassCurrent]
  );

  return <DaysAndHourForm handleSaveCallback={(classesDay) => handleSave(classesDay)} />;
};

export { DaysAndHourRelation };
