import { styled } from "react-alicerce-components";

export const DateFilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-container {
        margin-top: .5rem !important;
        margin-bottom: 1rem !important;
        width: 45%;
        
        > div:first-child {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        input {
            width: 100%;
        }
    }

    label {
        margin: 0 4px;
        width: 10%;
        text-align: center;
    }
`