import { styled } from 'react-alicerce-components';

export const WorkGroupAndStudentsInfoContainer = styled.div`
  .work-group-checkboxes-wrapper {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;

    .work_group_wrapper {
      display: flex;
      align-items: center;
      padding: 10px 0px;

      .work-group-info {
        display: flex;
        flex-direction: column;
        padding-left: 5px;

        span:first-child {
          font-weight: 700;
          font-size: 13px;
          color: #2e3a59;
        }
      }
    }
  }

  .select-all-students-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0rem;
    color: #2e3a59;
  }

  .student_status_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .student-checkboxes-wrapper {
    .student_wrapper {
      display: flex;
      align-items: center;
      margin: 5px;

      .student-info {
        background: #94e2f8;
        border-radius: 5px 5px 0 0;
        padding: 5px 10px;

        > span {
          font-weight: 700;
          font-size: 12px;
          color: #2e3a59;
        }
      }

      .status-info {
        background: #5dc4ea;
        border-radius: 0 0 5px 5px;
        padding: 5px 10px;

        > span {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 12px;
          color: #2e3a59;
        }
      }
    }
  }
`;
