export const MODALITY_INPERSON = { name: 'Presencial', value: 'inperson' };
export const MODALITY_DIGITAL = { name: 'Digital', value: 'digital' };

export const MODALITY_CONSTRAINTS = [MODALITY_INPERSON, MODALITY_DIGITAL];

export const defaultModality = (modality?: string | null) => {
  return [
    { name: MODALITY_INPERSON.name, value: MODALITY_INPERSON.value, selected: modality === MODALITY_INPERSON.value },
    { name: MODALITY_DIGITAL.name, value: MODALITY_DIGITAL.value, selected: modality === MODALITY_DIGITAL.value },
  ];
};
