import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
`;

interface ILine {
  color: string;
  width: string;
}

export const Line = styled.div<ILine>`
  width: ${({width}) => width};
  height: 2px;
  background: ${({color}) => color};
`;
