import { styled } from "react-alicerce-components";

export const OptionSelectBlockContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .4rem;
    margin-top: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
`