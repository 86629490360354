import React from 'react';
import { Input } from 'react-alicerce-components';
import ButtonGradient from '@shared/components/ButtonGradient';

import Layout from '@modules/report-explorer/components/Layout';

import { Container, ContainerButtons } from './styles';
import { useNavigate } from 'react-router-dom';

export const reportList = [
  { name: 'Painel Indicadores - Sprint1_23', url: 'https://lookerstudio.google.com/embed/reporting/f07c522d-0a1b-4458-a45b-875d64b21ec0/page/p_e01f85p3xc' },
  { name: 'Horas Lançadas', url: 'https://lookerstudio.google.com/embed/u/1/reporting/212627d5-4368-4af0-9459-0ab1890562fe/page/p_tzd4uqmn3c' },
];

const Reports: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout openTab={0}>
      <Container>
        <div style={{ marginBottom: '2rem' }}>
          <Input placeholder="Buscar" name="search" />
        </div>
        <ContainerButtons>
          {reportList.map((report, index) => (
            <ButtonGradient fill="linear-gradient(180deg, #FF4676 0%, #FF4676 98.12%);" onClick={() => navigate(`/relatorios/externo/${index}`)}>
              {report.name}
            </ButtonGradient>
          ))}
        </ContainerButtons>
      </Container>
    </Layout>
  );
};

export default Reports;
