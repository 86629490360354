import LoginCallback from "@modules/home/views/LoginCallback";
import SigIn from "@modules/home/views/SignIn";

import {IModuleRoutes} from "@shared/interfaces/IModuleRoutes";

const routes = [
  {
    path: "/auth/callback",
    key: "AUTH",
    exact: true,
    component: LoginCallback,
    permissions: 'public',
  },
  {
    path: "*",
    key: "SIGNIN",
    exact: true,
    component: SigIn,
    permissions: 'public',
  }
];

export default [
  {
    key: 'AUTH_MODULE',
    routes
  } as IModuleRoutes
];
