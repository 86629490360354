import React, { RefObject, useCallback, useMemo, useState } from 'react';

import * as Yup from 'yup';
import convert from 'color-convert';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiCheck } from 'react-icons/fi';
import Icon from 'react-eva-icons';

import { RBG, ColorSelect, Textarea, Checkbox, InputForm, getValidationErrors, Error } from 'react-alicerce-components';

import { useTags } from '@modules/tags/context/TagsContext';

import CustomForm from '@shared/components/CustomForm';
import TagsRender from '@modules/tags/components/TagsRender';
import ITag from '@modules/tags/interfaces/ITag';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { Container, ColorPickerContainer } from './styles';
import ConfirmModal from '@shared/components/ConfirmModal';

interface IErrors {
  [key: string]: string;
}

interface ITagFormProps {
  formRef?: RefObject<FormHandles>;
  handleOnFinish: (tag) => void;
  pathParentTag: string;
  typeService: 'Criar' | 'Editar';
}

const TagForm: React.FC<ITagFormProps> = ({ formRef, handleOnFinish, pathParentTag, typeService }) => {
  const { tagCurrent, setTagCurrent } = useTags();
  const [errors, setErrors] = useState<IErrors>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const convertedTagColorForRGB = useMemo(() => (tagCurrent?.color ? convert.hex.rgb(tagCurrent?.color) : undefined), [tagCurrent]);

  const handleClickOnConfirm = useCallback(() => {
    setShowModal(false);
    handleOnFinish(tagCurrent);
  }, [handleOnFinish, tagCurrent]);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setTagCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setTagCurrent]
  );

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const tagType = event.target.id;
      const inputName = event.target.name;
      setTagCurrent((oldState: any) => ({ ...oldState, [inputName]: tagType }));
    },
    [setTagCurrent]
  );

  const handleChangeColor = useCallback(
    (color: RBG) => {
      setTagCurrent((oldState: any) => ({ ...oldState, color: `#${convert.rgb.hex(color)}` }));
    },
    [setTagCurrent]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        if (formRef) {
          setErrors({});
          formRef.current?.setErrors({});
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da Tag é obrigatório').min(3, 'No minimo 3 caracteres').max(50, 'No maximo 50 caracteres'),
          color: Yup.string().required('Cor da Tag é obrigatório'),
          description: Yup.string().required('Descrição da Tag é obrigatória').min(3, 'No minimo 3 caracteres').max(100, 'No maximo 100 caracteres'),
          type: Yup.string().required('Tipo da Tag é obrigatório').oneOf(['project', 'system'], 'Tipo da Tag é obrigatório'),
        });

        await schema.validate({ ...data, ...tagCurrent }, { abortEarly: false });

        if (tagCurrent?.description === null || tagCurrent?.description === '') {
          setTagCurrent({ ...tagCurrent, description: undefined });
        }

        if (tagCurrent?.parent) {
          setTagCurrent({ ...tagCurrent, parent_id: tagCurrent.parent.id });
        }

        setShowModal(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError && formRef) {
          const resErrors = getValidationErrors(err);
          console.log(resErrors);
          formRef.current?.setErrors(resErrors);
          setErrors(resErrors);
        }
      }
    },
    [formRef, setTagCurrent, tagCurrent]
  );

  const handleRemoveParentTag = useCallback(() => {
    setTagCurrent((oldState: ITag) => {
      return { ...oldState, parent: null, parent_id: null };
    });
  }, [setTagCurrent]);

  console.log(' ~ tagCurrent', tagCurrent);
  return (
    <DirectoryContainer footer style={{ paddingTop: '0' }}>
      <Container>
        <CustomForm customInputColorText="#145496">
          <Form
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: tagCurrent?.name,
            }}
          >
            <InputForm
              label="Nome da Tag:"
              name="name"
              placeholder="Adicione Nome da Tag"
              onChange={(event) => handleChangeInput(event)}
              useTooltipError={false}
            />
            <div className="container-checkbox">
              <Checkbox
                id="project"
                name="type"
                label="Tag de Projeto"
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                disabled={!!tagCurrent?.parent}
                semiRound
                onChange={(event) => handleChangeCheckbox(event)}
                checked={tagCurrent?.type === 'project'}
              />
              <Checkbox
                id="system"
                name="type"
                label="Tag de Sistema"
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                disabled={!!tagCurrent?.parent}
                semiRound
                onChange={(event) => handleChangeCheckbox(event)}
                checked={tagCurrent?.type === 'system'}
              />
            </div>

            <Error>{errors && errors.type}</Error>

            <TagsRender
              canRemoveTag={true}
              tagCallback={handleRemoveParentTag}
              label="Tag Mãe:"
              tags={tagCurrent?.parent ? [tagCurrent.parent] : undefined}
              pathToRedirect={pathParentTag}
              placeholder="Adicione uma Tag mãe"
            />

            <Textarea
              label="Descrição da Tag:"
              name="description"
              placeholder="Adicione Descrição da Tag"
              value={tagCurrent?.description}
              onChange={(event) => handleChangeInput(event)}
              rows={4}
            />

            <Error>{errors && errors.description}</Error>
          </Form>

          <ColorPickerContainer>
            <ColorSelect handleOnChange={handleChangeColor} selectedColor={convertedTagColorForRGB} positionX="bottom" positionY="right" />
          </ColorPickerContainer>
          <Error>{errors && errors.color}</Error>
        </CustomForm>

        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showModal}
          closeCallback={() => setShowModal(false)}
          title={`Deseja ${typeService} esta Tag?`}
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={handleClickOnConfirm}
        />
      </Container>
    </DirectoryContainer>
  );
};

export default TagForm;
