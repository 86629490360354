import React, { useCallback, useState } from 'react';
import Layout from '@modules/occurrences/components/LayoutOccurrencesContextSelection';
import IClass from '@modules/classes/interfaces/IClass';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { useNavigate } from 'react-router-dom';
import { Input } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

const OccurrencesClassesSelection: React.FC = () => {
  const navigate = useNavigate();
  const { setOccurrenceCurrent, setContextSelected } = useOccurrences();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleClickOccurrence = useCallback(
    (classClicked: IClass) => {
      setOccurrenceCurrent({ class: classClicked });
      setContextSelected({ context: 'turmas', context_id: classClicked.id });
      navigate(`/ocorrencias/turmas/${classClicked.id}/pendentes`);
    },
    [navigate, setContextSelected, setOccurrenceCurrent]
  );

  const filterClassesOccurrence = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <Layout openTab={0}>
      <div style={{ marginBottom: '2rem' }}>
        <Input
          placeholder="Buscar"
          name="search"
          end={<Icon name="funnel" fill="#8F9BB3" />}
          onChange={(event) => filterClassesOccurrence(event.target.value)}
        />
      </div>
      <ClassesSelection handleOnClick={handleClickOccurrence} searchValue={searchValue} />
    </Layout>
  );
};

export default OccurrencesClassesSelection;
