import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

import { Button, Spinner, useToast } from 'react-alicerce-components';

import PlannedClassCard from '@modules/class-planner/components/PlannedClassCard';
import ListDailyClassesPlanService from '@modules/class-planner/services/ListDailyClassesPlanService';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import { FormatDailyClassForFullCalendar, formatDailyClassForFullCalendar } from '@modules/class-planner/utils/formatDailyClassForFullCalendar';

import WeekSelect, { IChangeWeek } from '@modules/dashboards/components/WeekSelect';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';

import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import Divider from '@shared/components/Divider';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { changeDays, DateHelper, getMonday } from '@shared/helpers/DateHelper';

import { Container, List } from './styles';

const ClassPlannerList: React.FC = () => {
  const navigate = useNavigate();
  const calendarRef = useRef<FullCalendar>(null);

  const { isLoading, setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { addToast } = useToast();
  const { classId } = useParams<{ classId: string }>();
  const { setDailyClass } = useScheduleGrid();

  const [formattedDailyClasses, setFormattedDailyClasses] = useState<FormatDailyClassForFullCalendar>();

  const handleOnClickCard = useCallback(
    (wdc: IDailyClass) => {
      navigate(`/planejamento-aula/turma/${classId}/aula/${wdc.id}`);
    },
    [classId, navigate]
  );

  const handleCreateClass = useCallback(() => {
    setDailyClass(undefined);
    navigate(`/planejamento-aula/turma/${classId}/criar/aula`);
  }, [classId, navigate, setDailyClass]);

  const listDailyClassesPlan = useCallback(
    async (date: Date) => {
      if (!classId) return navigate('/planejamento-aula/contexto/turmas');
      setIsLoading(true);

      const monday = getMonday(new Date(date));
      const options = {
        start_date: DateHelper.extractDate(monday),
        end_date: DateHelper.extractDate(changeDays(monday, 6)),
        size: 99999,
        include_activity: true,
      } as any;

      const res = await new ListDailyClassesPlanService(signedUser?.token, newCancelToken()).execute('', options, classId);

      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res)
        return addToast({
          status: 'danger',
          title: 'Aulas',
          description: 'Error ao Listar Aulas.',
        });

      setFormattedDailyClasses(formatDailyClassForFullCalendar(res.items));
    },
    [addToast, classId, navigate, newCancelToken, setIsLoading, signedUser]
  );

  const handleOnChangeWeek = useCallback(
    async (data: IChangeWeek) => {
      listDailyClassesPlan(data.startDate);
      calendarRef.current?.getApi().gotoDate(data.startDate);
    },
    [listDailyClassesPlan]
  );

  useEffect(() => {
    if (!classId) navigate('/planejamento-aula/contexto/turmas');
    listDailyClassesPlan(new Date());
  }, [classId, navigate, listDailyClassesPlan]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Planejador de Aula"
        pathToReturn={`/turma/${classId}`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%);"
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true} style={{ marginTop: '0px', paddingTop: '20px' }}>
        <WeekSelect handleChangeWeek={handleOnChangeWeek} />
        <Divider className="divider" width="100%" />
        {!!formattedDailyClasses?.dailyClasses.length && (
          <AccordionInfoItem title={`Aulas da Semana (${formattedDailyClasses?.dailyClasses.length})`} colorHeader="#DAE3F6" isShow={true}>
            <List>
              {formattedDailyClasses?.dailyClasses?.map(
                (dc) =>
                  dc.plan && <PlannedClassCard key={`class-planner-${dc.id}`} handleOnClickCard={() => handleOnClickCard(dc)} plannedClass={dc} />
              )}
            </List>
          </AccordionInfoItem>
        )}
        <FullCalendar
          key={formattedDailyClasses?.events.length}
          events={formattedDailyClasses?.events}
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          height="auto"
          locale="pt-BR"
          headerToolbar={false}
          allDayText="Dia todo"
          eventResizableFromStart
          firstDay={1}
        />

        <PageFooter>
          <Button
            className="button-class-planner"
            transform="none"
            fill="#85ABF8"
            shaded
            onClick={() => navigate(`/grupo-de-trabalho/turma/${classId}`)}
          >
            Grupos de Trabalho
          </Button>
          <Button className="button-class-planner" transform="none" fill="#85ABF8" shaded onClick={handleCreateClass}>
            Criar Aula Extra
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export default ClassPlannerList;
