import IProject from '@modules/projects/interfaces/IProject';

export const formatProject = (project: IProject) => {
  const formated = {
    name: project?.name,
    type: project?.type,
    status: project?.status,
    description: project?.description,
    janitorial_type: project?.janitorial_type,
    description_typicality: project?.description_typicality ?? undefined,
    governance_links: project?.governance_links,
    city: project?.city,
    state: project?.state,
    additional_information: project?.additional_information,
    ...(!!project?.implementation_stage && { implementation_stage: project?.implementation_stage ?? [] }),
    group_id: project?.group?.id ?? undefined,
    place_id: project?.place?.id ?? undefined,
    schedule_id: project?.schedule?.id ?? 1,
    gif_contracts_business_ids: project?.gif_contract_businesses ? project?.gif_contract_businesses?.map((c) => c.id || '').filter(Boolean) : [],
    class_ids: project?.classes ? project?.classes?.map((c) => c.id || '').filter(Boolean) : []
  }
  return formated;
};

