// constrainsts level de educação
export const STUDENT_NEVER_ATTENDED_GRADE = { name: 'Nunca Frequentou Escola', value: 'never_attended' }; // Nunca Frequentou Escola
export const STUDENT_PRE_SCHOOL_GRADE = { name: 'Pré escola', value: 'pre_school' };                      // Pré escola
export const STUDENT_ELEMENTARY_1_GRADE = { name: '1ª ano ensino Fundamental', value: 'elementary_1' };   // 1 Ano Ensino Fundamental
export const STUDENT_ELEMENTARY_2_GRADE = { name: '2ª ano ensino Fundamental', value: 'elementary_2' };   // 2 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_3_GRADE = { name: '3ª ano ensino Fundamental', value: 'elementary_3' };   // 3 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_4_GRADE = { name: '4ª ano ensino Fundamental', value: 'elementary_4' };   // 4 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_5_GRADE = { name: '5ª ano ensino Fundamental', value: 'elementary_5' };   // 5 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_6_GRADE = { name: '6ª ano ensino Fundamental', value: 'elementary_6' };   // 6 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_7_GRADE = { name: '7ª ano ensino Fundamental', value: 'elementary_7' };   // 7 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_8_GRADE = { name: '8ª ano ensino Fundamental', value: 'elementary_8' };   // 8 Ano ensino Fundamental
export const STUDENT_ELEMENTARY_9_GRADE = { name: '9ª ano ensino Fundamental', value: 'elementary_9' };   // 9 Ano ensino Fundamental
export const STUDENT_HIGH_1_GRADE = { name: '1ª ano ensino Médio', value: 'high_schoool_1' };             // 1 Ano ensino medio
export const STUDENT_HIGH_2_GRADE = { name: '2ª ano ensino Médio', value: 'high_schoool_2' };             // 2 Ano ensino medio
export const STUDENT_HIGH_3_GRADE = { name: '3ª ano ensino Médio', value: 'high_schoool_3' };             // 3 Ano ensino medio
export const STUDENT_EJA_GRADE = { name: 'Ensino de Jovens e Adultos', value: 'eja' };                    // Ensino de Jovens e Adultos (EJA)
export const STUDENT_UNIVERSITY_GRADE = { name: 'Ensino Superior', value: 'university' };                 // Ensino Superior
export const STUDENT_NOT_COLLECETED_GRADE = { name: 'Não coletado', value: 'not_collected' };             // Nao Coletado

export const SCHOOL_GRADE_CONSTRAINTS = [
  STUDENT_NEVER_ATTENDED_GRADE,
  STUDENT_PRE_SCHOOL_GRADE,
  STUDENT_ELEMENTARY_1_GRADE,
  STUDENT_ELEMENTARY_2_GRADE,
  STUDENT_ELEMENTARY_3_GRADE,
  STUDENT_ELEMENTARY_4_GRADE,
  STUDENT_ELEMENTARY_5_GRADE,
  STUDENT_ELEMENTARY_6_GRADE,
  STUDENT_ELEMENTARY_7_GRADE,
  STUDENT_ELEMENTARY_8_GRADE,
  STUDENT_ELEMENTARY_9_GRADE,
  STUDENT_HIGH_1_GRADE,
  STUDENT_HIGH_2_GRADE,
  STUDENT_HIGH_3_GRADE,
  STUDENT_EJA_GRADE,
  STUDENT_UNIVERSITY_GRADE,
  STUDENT_NOT_COLLECETED_GRADE,
];

export const defaultSchoolGrade = (studentSchoolGrade?: string | null) => {
  return SCHOOL_GRADE_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: studentSchoolGrade === foo.value };
  });
};
