import { styled } from 'react-alicerce-components';

export const StyledButtonContainer = styled.div`
  cursor: pointer;

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c5cee0;
    border-radius: 8px;

    svg {
      width: 35px;
      height: 35px;
      fill: #fff;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 30px;

  input {
    display: none;
  }

  .text-add-button {
    font-family: var(--secondary-font);
    font-weight: bold;
    font-size: 15px;
    display: flex;
    color: #2e3a59;
  }

  .container-medias-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1.5rem 0;
  }
`;

export const ContainerMediaPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1.5rem 0;
  padding: 0 10px;

  > div {
    flex: 1 0 47%;
    max-width: 220px;
  }
`;
