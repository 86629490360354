import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, H6 } from 'react-alicerce-components';

import Layout from '@modules/projects/components/Layout';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';

import PageFooter from '@shared/components/PageFooter';
import ProjectClassCard from '@modules/projects/components/ProjectClassCard';

const ProjectClasses: React.FC = () => {
  const navigate = useNavigate();
  const { projectCurrent } = useProjects();

  return (
    <Layout openTab={1} footer>
      <ProjectDefaultStylesContainer>
        <H6>
          TURMAS EM ESPERA
        </H6>
        {projectCurrent?.classes?.map((classInfo, i) =>
          <ProjectClassCard
            key={i}
            classInfo={classInfo}
          />
        )}
        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() => navigate(`/diretorio/turmas/criar`)}
          >
            Criar Turma
          </Button>
        </PageFooter>
      </ProjectDefaultStylesContainer>
    </Layout>
  );
};

export default ProjectClasses;
