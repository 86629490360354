import React from 'react'
import { Container } from './styles'

type SkillContainerProps = {
    children: React.ReactNode
}

export const SkillContainer = (props: SkillContainerProps) => {
    const { children } = props
    return (
        <Container>{children}</Container>
    )
}