import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Button, Spinner, useToast } from 'react-alicerce-components';

import { useStudent } from '@modules/students/context/StudentContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from 'react-eva-icons';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import DeleteStudentService from '@modules/students/services/DeleteStudentService';
import EditStudentService from '@modules/students/services/EditStudentService';
import { formatStudent } from '@modules/students/utils/formatStudent';
import StudentForm from '@modules/students/components/StudentForm';
import { ISubscription } from '@modules/subscriptions/interfaces/ISubscription';
import { DeleteSubscriptionsBulkService } from '@modules/students/services/DeleteSubscriptionsBulkService';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { studentSchema } from '../../utils/studentSchema';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const StudentEdit: React.FC = () => {
  const { studentCurrent, clearStudentContextData, getStudent } = useStudent();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [removedSubscriptions, setRemovedSubscriptions] = useState<ISubscription[]>([]);
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const refResponsiblePicking = React.createRef<any>();
  const refPersonalAcademicData = React.createRef<any>();
  const refAlicerceMainData = React.createRef<any>();
  const { setIsLoading, isLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { addToast } = useToast();
  const isMounted = useIsMounted();
  const studentInPath = useParams<{ id: string }>();

  const updateAllHeigths = useCallback(() => {
    refResponsiblePicking.current.updateHeight();
    refPersonalAcademicData.current.updateHeight();
    refAlicerceMainData.current.updateHeight();
  }, [refAlicerceMainData, refPersonalAcademicData, refResponsiblePicking]);

  const handleEditStudentSubscriptions = useCallback(async () => {
    if (!signedUser || removedSubscriptions.length === 0) return;
    await new DeleteSubscriptionsBulkService(signedUser.token, newCancelToken()).execute({
      subscription_ids: removedSubscriptions.map((sub) => sub.id),
    });
  }, [newCancelToken, removedSubscriptions, signedUser]);

  const handleEditStudent = useCallback(async () => {
    if (!signedUser || !studentCurrent || !studentCurrent.id) return;
    setShowEditModal(false);
    const formatedBody = formatStudent(studentCurrent, 'editing');
    console.log('edit student formated body => ', formatedBody);
    setIsLoading(true);
    const res = await new EditStudentService(signedUser.token, newCancelToken()).execute(studentCurrent.id, formatedBody);
    await handleEditStudentSubscriptions();

    if (!isMounted) return;
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Alunos',
        description: 'Aluno editado com sucesso.',
      });
      return navigate(`/aluno/${studentCurrent.id}/informacoes`);
    }

    addToast({
      status: 'danger',
      title: 'Alunos',
      description: 'Não foi possível editar o Aluno.',
    });
  }, [signedUser, studentCurrent, setIsLoading, newCancelToken, handleEditStudentSubscriptions, isMounted, addToast, navigate]);

  const handleDeleteStudent = useCallback(async () => {
    setShowDeleteModal(false);
    if (!studentCurrent.id) return;
    setIsLoading(true);
    const res = await new DeleteStudentService(signedUser?.token).execute(studentCurrent.id);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Alunos',
        description: 'Aluno deletado com sucesso.',
      });
      return navigate(`/diretorio/alunos`);
    }

    addToast({
      status: 'danger',
      title: 'Alunos',
      description: 'Não foi possível deletar o Aluno.',
    });
  }, [studentCurrent.id, setIsLoading, signedUser, addToast, navigate]);

  const handleRemoveSubscription = useCallback((removedSubscription: ISubscription) => {
    setRemovedSubscriptions((oldState: ISubscription[]) => {
      return [...oldState, removedSubscription];
    });
  }, []);

  const handleCancelEdit = useCallback(() => {
    clearStudentContextData();
    navigate(`/aluno/${studentCurrent.id}/informacoes`);
  }, [clearStudentContextData, navigate, studentCurrent.id]);

  const canSaveStudent = useCallback(async () => {
    updateAllHeigths();
    const formatedBody = formatStudent(studentCurrent, 'editing');
    const formErros = await beforeSubmitForm(formatedBody, studentSchema);
    setErros(formErros);
    console.log('students subscriptions to remove', removedSubscriptions);
    if (formErros.length === 0) setShowEditModal && setShowEditModal(true);
  }, [removedSubscriptions, studentCurrent, updateAllHeigths]);

  const getStudentFromContext = useCallback(async () => {
    if (!studentInPath.id) return;
    const res = await getStudent(studentInPath.id);
    if (!res) navigate('/diretorio/alunos');
  }, [getStudent, navigate, studentInPath.id]);

  useEffect(() => {
    if (!studentCurrent.id || !studentCurrent.responsibles) getStudentFromContext();
  }, [getStudentFromContext, studentCurrent.id, studentCurrent.responsibles]);

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory
        status="secondary"
        title="Editar Aluno"
        menuKebab={{
          deleteCallback: () => setShowDeleteModal(true),
        }}
      />
      <DirectoryContainer footer={true}>
        <StudentForm
          form_action="editing"
          removedSubscription={handleRemoveSubscription}
          errors={errors}
          refResponsiblePicking={refResponsiblePicking}
          refPersonalAcademicData={refPersonalAcademicData}
          refAlicerceMainData={refAlicerceMainData}
          pathOnboardingGroup="/diretorio/alunos/selecionar/onboarding/grupo"
          pathEnvolvedClasses="/diretorio/alunos/selecionar/turma"
        />
        <PageFooter>
          <Button transform="none" outline status="secondary" shaded onClick={handleCancelEdit}>
            Cancelar
          </Button>
          <Button transform="none" status="secondary" shaded onClick={() => canSaveStudent()}>
            Salvar
          </Button>
        </PageFooter>
        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showEditModal}
          closeCallback={() => setShowEditModal(!showEditModal)}
          title="Deseja Salvar Alterações ?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={() => handleEditStudent()}
        />
        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showDeleteModal}
          closeCallback={() => setShowDeleteModal(!showDeleteModal)}
          title="Deseja deletar o Aluno?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={() => handleDeleteStudent()}
        />
      </DirectoryContainer>
    </Fragment>
  );
};

export default StudentEdit;
