import { styled } from 'react-alicerce-components';

export const InputAgeContainer = styled.div`
  .input-age-label {
    color: #2e3a59;
    font-family: var(--secondary-font);
    margin: 4px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
`;

export const AgeContainer = styled.div`
  border-width: 2px;
  cursor: default;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fc;
  border-color: #e4e9f2 !important;
  padding: 0.4375rem 1rem;
  border-radius: 0.25rem;
  border-style: solid;

  font-weight: 800;
  color: #35a0d6;
  font-size: 0.9375rem;
  line-height: 1.5rem;
`;
