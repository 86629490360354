import styled from 'styled-components';

export const InputContainer = styled.div`

    >div{
      >div:last-child {
        margin-top: 4px;
      }
  }
`;
