import { styled } from 'react-alicerce-components';

export const ActivityListContainer = styled.div`
  .activity-library-card-list {
    margin: 3rem 0rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .advanced-filter-structure-container {
    .input-container {
      margin: 1.5rem 0rem;
    }

    .input-container > div input {
      width: inherit;
    }
    .container-filter-options {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      justify-content: space-between;

      .my-questions-container {
        display: flex;
        align-items: center;
      }
    }
  }

  .work-group-checkboxes-wrapper {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    border: 1px solid #c5cee0;
    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;

    .work_group_wrapper {
      display: flex;
      align-items: center;
      padding: 20px 0px 10px 0px;

      .work-group-info {
        display: flex;
        flex-direction: column;
        padding-left: 5px;

        span:first-child {
          font-weight: 700;
          font-size: 13px;
          color: #2e3a59;
        }
      }
    }
  }
`;
