import IRoute from '@shared/interfaces/IRoute';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { ReportExplorerProvider } from '@modules/report-explorer/context/ReportExplorerContext';

import Reports from '@modules/report-explorer/views/Reports';
import SavedReports from '@modules/report-explorer/views/SavedReports';
import ClassReportCreate from '@modules/report-explorer/views/ClassReportCreate';
import { placesKeys } from '@modules/places/keys';
import { studentsKeys } from '@modules/students/keys';
import { classesKeys } from '@modules/classes/keys';
import ExternalReport from '../views/ExternalReport';

const routes: IRoute[] = [
  {
    path: 'relatorios',
    key: 'REPORT_EXPLORER_PATH',
    exact: true,
    component: Reports, // TO DO
    permissions: 'public'
  },
  {
    path: 'relatorios/salvos',
    key: 'REPORT_EXPLORER_SAVE_PATH',
    exact: true,
    component: SavedReports, // TO DO
    permissions: 'public'
  },
  {
    path: 'relatorios/externo/:id',
    key: 'REPORT_EXPLORER_EXTERNAL',
    exact: true,
    component: ExternalReport, // TO DO
    permissions: 'public'
  },
  {
    path: 'relatorios/turmas/selecionar',
    key: 'REPORT_EXPLORER_CLASSES_PATH',
    exact: true,
    component: ClassReportCreate,
    permissions: [
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: 'relatorios/alunos/selecionar',
    key: 'REPORT_EXPLORER_STUDENTS_PATH',
    exact: true,
    component: Reports,
    permissions: [
      studentsKeys.STUDENTS_READ, // TO DO
    ]
  },
  {
    path: 'relatorios/locais/selecionar',
    key: 'REPORT_EXPLORER_PLACES_PATH',
    exact: true,
    component: Reports,
    permissions: [
      placesKeys.PLACE_READ, // TO DO
    ]
  },
];

const providers = [ReportExplorerProvider];

export default {
  key: 'REPORT_EXPLORER_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
