import React from 'react';

import { Container, ConsolidatedBox } from './styles';

interface ConsolidatedHoursProps {
    approved?: string | number
    pending?: string | number
    disapproved?: string | number
}

const ConsolidatedHours: React.FC<ConsolidatedHoursProps> = ({
    approved = 0,
    pending = 0,
    disapproved = 0
}) => {
    return (
        <Container>
            <ConsolidatedBox>{approved}</ConsolidatedBox>
            <ConsolidatedBox>{pending}</ConsolidatedBox>
            <ConsolidatedBox>{disapproved}</ConsolidatedBox>
        </Container>
    );
}

export default ConsolidatedHours;