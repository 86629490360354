export const formateDateDifferenceDay = (dateStr: string) => {
  const now = new Date();
  now.setHours(0,0,0,0)
  const formatedDate = new Date(dateStr);
  formatedDate.setHours(0, 0, 0, 0);
  const differenceInMs = formatedDate.getTime() - now.getTime()
  const differenceInDays = Math.round(differenceInMs / (24 * 60 * 60 * 1000));

  return new Intl.RelativeTimeFormat('pt-BR', {
    numeric: 'auto',
  }).format(differenceInDays, 'day');
};

/**
 * Método que retorna a diferença do fuso, para mitigar o problema de fuso diferentes.
 * Por exemplo, para browser com o horário de Brasilia retorna "-0300",
 * isso significa que está 3 horas atrás do UTC
 * @param date 
 * @returns 
 */
export const getTimezoneOffsetString = (date: Date): string => {
  const timezoneOffset = date.getTimezoneOffset()
  const offsetInHours = (timezoneOffset / 60).toString().padStart(2, '0')
  const offsetInMinutes = (timezoneOffset % 60).toString().padStart(2, '0')
  const signal = timezoneOffset < 0 ? '+' : '-'
  return `${signal}${offsetInHours}${offsetInMinutes}`
}