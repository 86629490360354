import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .card-header {
    background-color: #b85ec4;
  }

  .card-footer {
    padding: 12px 10px;
    background-color: #b85ec4;
  }

  .card-body {
    background-color: #e9d7f9;
    justify-content: flex-start;
    padding: 20px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .tag {
    border-radius: 4px;
    margin: 2.5px;
  }
`;

export const StatusContainer = styled.div<{ statusColor: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  font-size: 10px;
  color: #f7f9fc;
  font-weight: 700;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: ${({ statusColor }) => statusColor};
    margin-right: 10px;
  }
`;
