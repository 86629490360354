import React, { useMemo, useCallback, Fragment, useEffect } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { usePlaces } from '@modules/places/context/PlacesContext';

const PlaceTagsRelationEdit: React.FC = () => {
  const navigate = useNavigate();
  const { placeCurrent, setPlaceCurrent } = usePlaces();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, tags };
      });
    },
    [setPlaceCurrent]
  );

  useEffect(() => {
    if (!placeCurrent) navigate('/meus-locais');
  }, [navigate, placeCurrent]);

  if (!placeCurrent) return <Fragment />;

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={placeCurrent.tags}
      pathToReturn={`/local/${placeCurrent.id}/editar`}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? "s" : ""}`}
      buttonCallback={() => navigate(`/local/${placeCurrent.id}/editar`)}
    />
  );
};

export default PlaceTagsRelationEdit;
