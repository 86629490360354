import { ITrails } from '@shared/interfaces/ITrail';
import React, { createContext, useContext, useState } from 'react';

export type ILearningTargetsContextData = {
  trails: ITrails[];
};

interface InitialMiniMapaData {
  applications: any[];
}

const LearningTargetsContext = createContext<ILearningTargetsContextData>({} as ILearningTargetsContextData);

const LearningTargetsProvider: React.FC = ({ children }) => {
  const [trails] = useState<ITrails[]>([
    {
      name: 'Matemática',
      blocks: [],
      value: `3`,
    },
    {
      name: 'Leitura',
      blocks: [],
      value: `2`,
    },
    /*     {
      name: 'Redação',
      blocks: [],
      value: `4`,
    }, */
    {
      name: 'Inglês',
      blocks: [],
      value: `9`,
    },
  ]);

  return <LearningTargetsContext.Provider value={{ trails }}>{children}</LearningTargetsContext.Provider>;
};

function useLearningTargets() {
  const context = useContext(LearningTargetsContext);

  if (!context) {
    throw new Error('useLearningTargets must be used within a LearningTargetsProvider');
  }

  return context;
}

export { LearningTargetsProvider, useLearningTargets };
