import { PROJECT_JANITORIAL_TYPE_CONSTRAINTS } from '@modules/projects/schemas/Project/partials/JanitorialType';
import { PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS } from '@modules/projects/schemas/Project/partials/ProjectAdditionalInformation';
import { PROJECT_STATUS_CONSTRAINTS } from '@modules/projects/schemas/Project/partials/ProjectStatus';
import { PROJECT_TYPE_CONSTRAINTS } from '@modules/projects/schemas/Project/partials/ProjectType';
import * as Yup from 'yup';

export const ProjectSchema = Yup.object().shape({
  name: Yup.string().required({ name: 'name', message: 'O campo é obrigatório' }),
  type: Yup.string().oneOf(PROJECT_TYPE_CONSTRAINTS, { name: 'type', message: `Campo deve ser ${PROJECT_TYPE_CONSTRAINTS} ` }).required({ name: 'type', message: 'O campo é obrigatório' }),
  status: Yup.string().oneOf(PROJECT_STATUS_CONSTRAINTS, { name: 'status', message: `Deve ser do tipo` }).required({ name: 'status', message: 'O campo é obrigatório' }),
  description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório' }),
  janitorial_type: Yup.string()
    .oneOf(PROJECT_JANITORIAL_TYPE_CONSTRAINTS, { name: 'janitorial_type', message: `Deve ser do tipo` })
    .required({ name: 'janitorial_type', message: 'O campo é obrigatório' }),
  description_typicality: Yup.string(),
  governance_links: Yup.object().shape({
    link: Yup.string().min(3).default(undefined).required({ name: 'link', message: `O campo é obrigatório` })
  }).default(undefined).required({ name: 'link', message: 'O campo é obrigatório' }),
  city: Yup.string().required({ name: 'city', message: 'O campo é obrigatório' }).max(100),
  state: Yup.string().required({ name: 'state', message: 'O campo é obrigatório' }).max(100),
  additional_information: Yup.array()
    .of(Yup.string().oneOf(PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS, { name: 'additional_information', message: `Campo deve ser ${PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS} ` }))
    .optional(),
  reports_id: Yup.number().optional().nullable(),
  group_id: Yup.number().required({ name: 'group_id', message: 'O campo é obrigatório' }),
  place_id: Yup.number().optional().nullable(),
  schedule_id: Yup.number().required({ name: 'schedule_id', message: 'O campo é obrigatório' }),
  gif_contracts_business_ids: Yup.array()
    .of(
      Yup.number().required('ID do contrato é obrigatório')
    )
    .min(1, 'Você deve selecionar pelo menos um contrato')
    .required({ name: 'gif_contracts_business_ids', message: 'O campo é obrigatório' }),
  class_ids: Yup.array().of(Yup.number()).optional()
});


