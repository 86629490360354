import React, { useCallback } from "react";
import { Textarea } from "react-alicerce-components";
import { useHeadlights } from "@modules/projects/components/Headlights/context/HeadlightsContext";
import IHeadlight from "@modules/projects/interfaces/IHeadlight";
import { errorMessageBuilder } from "@shared/utils/beforeSubmitForm";


const CurrentHeadlightDiagnostic: React.FC = () => {
    const { setCurrentHeadlight, headlightErrors } = useHeadlights();

    const handleChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
          const inputValue = event.target.value;
          const inputName = event.target.name;
          setCurrentHeadlight((oldState: IHeadlight) => {
            return { ...oldState, [inputName]: inputValue  };
          });
        },
        [setCurrentHeadlight]
      );
    return (
        <Textarea
            name="diagnostics"
            label="Diagnóstico"
            placeholder="Adicione Diagnóstico"
            rows={13}
            onChange={(event) => handleChangeInput(event)}
            error={errorMessageBuilder('diagnostics', headlightErrors)}
            useTooltipError={false}
            required
        />
    )
};

export default CurrentHeadlightDiagnostic;