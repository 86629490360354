import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Spinner, Tab, Tabs, useToast } from 'react-alicerce-components';

import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import { Container } from './styles';
import HeaderModule from '@shared/components/Headers/HeaderModule';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { usePlaces } from '@modules/places/context/PlacesContext';
import ToggleFavoritedPlaceService from '@modules/places/services/ToggleFavoritedPlaceService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import ToggleFixedPlaceService from '@modules/places/services/ToggleFixedPlaceService';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, setIsLoading } = useGlobal();
  const { placeCurrent, getPlace, setPlaceCurrent } = usePlaces();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const isMounted = useIsMounted();
  const { state: stateLocation } = useLocation() as { state: { pathToReturn: string } };

  const getPlaceFromContext = useCallback(async () => {
    if (!id) return;
    const res = await getPlace(id);
    if (!res) navigate('/meus-locais');
  }, [getPlace, navigate, id]);

  useEffect(() => {
    if (!placeCurrent || id !== placeCurrent.id) {
      getPlaceFromContext();
    }
  }, [getPlaceFromContext, id, placeCurrent]);

  const toggleFavorite = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);
    const res = await new ToggleFavoritedPlaceService(signedUser?.token).execute(id);
    setIsLoading(false);
    if (!isMounted) return;
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Locais',
        description: 'Falha ao favoritar o local.',
      });
      return;
    }
    setPlaceCurrent((oldState: any) => {
      return { ...oldState, favorited: res.favorited };
    });
  }, [addToast, isMounted, id, setIsLoading, setPlaceCurrent, signedUser]);

  const toggleFixed = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);
    const res = await new ToggleFixedPlaceService(signedUser?.token).execute(id);
    setIsLoading(false);
    if (!isMounted) return;
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Locais',
        description: 'Falha ao fixar o local.',
      });
      return;
    }
    setPlaceCurrent((oldState: any) => {
      return { ...oldState, fixed: res.fixed };
    });
  }, [addToast, isMounted, id, setIsLoading, setPlaceCurrent, signedUser]);

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  const handleGoBack = useCallback(() => {
    const returnPathFromLocationState = stateLocation && (stateLocation.pathToReturn as string | undefined);
    navigate(returnPathFromLocationState ? returnPathFromLocationState : '/diretorio/locais');
  }, [navigate, stateLocation]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderModule
        title="LOCAL"
        subtitle={placeCurrent?.name ? placeCurrent.name : 'Carregando...'}
        additionalInfo={`${placeCurrent?.id ? placeCurrent.id : 'Carregando...'}`}
        status="primary"
        useDefaultNav
        goBackCallback={handleGoBack}
        titleColor="#751B06"
        key={`${placeCurrent && placeCurrent.favorited}`}
        isScrolled={isScrolled}
        compressOnScroll
        menuKebab={{
          favorited: { favoritedCallback: toggleFavorite, isFavorited: placeCurrent?.favorited || false },
          attached: { attachedCallback: toggleFixed, isAttached: placeCurrent?.fixed || false },
          tooltip: { view: 'PlaceHome' },
          menuBackHome: true,
        }}
      />

      <CustomTabs footer={footer || false} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
        {placeCurrent && (
          <Tabs openTab={openTab} content={children} horizontalScroll>
            <Tab title="Início" onClick={() => navigate(`/local/${id}`)} />
            <Tab title="informações" onClick={() => navigate(`/local/${id}/informacoes`)} />
            <Tab title="Agenda" onClick={() => navigate(`/local/${id}/agenda`)} />
            <Tab title="Histórico" onClick={() => navigate(`/local/${id}/historico`)} />
            <Tab title="Relatórios" onClick={() => navigate(`/local/${id}/relatorios`)} />
          </Tabs>
        )}
      </CustomTabs>
    </Container>
  );
};

export default Layout;
