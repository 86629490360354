import { styled } from 'react-alicerce-components';

export const Container = styled.div`
`;

export const MediaContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const MediaLength = styled.div`
    position: absolute;
    bottom: 14px;
    right: 22px;
    color: #fff;
    font-weight: 700;
`;

interface IRealTimeProps {
    isShowIcon?: boolean;
}

export const RealTime = styled.div<IRealTimeProps>`
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 22px;
    background-color: #E9D7F9;
    cursor: pointer;

    > div {
        width: 18px;
        height: 18px;
        border-radius: 18px;
        margin-right: 12px;
        background: linear-gradient(180deg, #F42929 0%, #F9514C 100%);
    }

    > span {
        font-size: 12px;
        font-weight: 700;
    }

    i {
        margin-right: 8px;
        
        svg {
            height: 32px;
            width: 32px;
            margin-top: 4px;
        }

        ${props => !props.isShowIcon && `
            display: none;
        `}
    }

`;

export const Info = styled.div`
    height: 100px;
    background-color: #DAE3F6;
    padding: 22px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`;

export const Date = styled.div`
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    color: #2E3A59;
`;

export const Name = styled.div`
    font-size: 14px;
    font-weight: 800;
`;

export const TagsRenderContainer = styled.div`

    .tags-render-list {
        margin: 0 !important;
   

        > div {
            background-color: transparent;
            border-color: transparent;
            margin: 0 !important;
            padding-left: 0;
        }

        .tag {
            margin-left: 0;
        }

    }

`;