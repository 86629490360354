import React, { useState, useCallback } from 'react';
import Icon from 'react-eva-icons';

import Layout from '@modules/occurrences/components/LayoutOccurrencesContextSelection';

import { Input } from 'react-alicerce-components';

import { useNavigate } from 'react-router-dom';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import IPlace from '@modules/places/interfaces/IPlace';
import PlacesSelection from '@shared/components/PlacesSelection';

const OccurrencesPlacesSelection: React.FC = () => {
  const navigate = useNavigate();

  const { setOccurrenceCurrent, setContextSelected } = useOccurrences();
  const [searchValue, setSearchValue] = useState<string>('');

  const handleOnClick = useCallback(
    (placeClicked: IPlace) => {
      setOccurrenceCurrent({ place: placeClicked });
      setContextSelected({ context: 'locais', context_id: placeClicked.id });
      navigate(`/ocorrencias/locais/${placeClicked.id}/pendentes`);
    },
    [navigate, setContextSelected, setOccurrenceCurrent]
  );

  const filterPlaces = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <Layout openTab={2}>
      <div style={{ marginBottom: '2rem' }}>
        <Input
          placeholder="Buscar"
          name="search"
          end={<Icon name="funnel" fill="#8F9BB3" />}
          onChange={(event) => filterPlaces(event.target.value)}
        />
      </div>
      <PlacesSelection handleOnClick={handleOnClick} searchValue={searchValue} />
    </Layout>
  );
};

export default OccurrencesPlacesSelection;
