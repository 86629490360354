function convertHexToHsl(hex: string, alpha?: number) {
  // Convert hex to RGB first
  // This convertion creates an Hexidadecimal number
  /* Example
   * a = 0xB8
   * 184
   */

  let red, green, blue;
  if (hex.length === 4) {
    red = '0x' + hex[1] + hex[1];
    green = '0x' + hex[2] + hex[2];
    blue = '0x' + hex[3] + hex[3];
  } else if (hex.length === 7) {
    red = '0x' + hex[1] + hex[2];
    green = '0x' + hex[3] + hex[4];
    blue = '0x' + hex[5] + hex[6];
  }

  // Then to HSL
  red /= 255 as number;
  green /= 255 as number;
  blue /= 255 as number;

  let cmin = Math.min(red, green, blue);
  let cmax = Math.max(red, green, blue);
  let delta = cmax - cmin;

  let h: number,
    s: number,
    l: number = 0;

  if (delta === 0) h = 0;
  else if (cmax === red) h = ((green - blue) / delta) % 6;
  else if (cmax === green) h = (blue - red) / delta + 2;
  else h = (red - green) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  if (!alpha) return '(' + h + ',' + s + '%,' + l + '%)';
  if (alpha) return '(' + h + ',' + s + '%,' + l + '%,' + (alpha * 100).toFixed(1) + '%)';
}

export { convertHexToHsl };
