import React from 'react';

interface StrokeLabelProps {
    x: number,
    y: number,
    cx: number,
    cy: number,
    value: string;
    dy?: number,
    fill?: string,
    fontSize?: number,
    fontWeight?: number,
    transform?: string,
    strokeOnLabelWidth?: number;
}

const StrokeLabel: React.FC<StrokeLabelProps> = ({
    x,
    y,
    cx,
    cy,
    value,
    transform,
    dy = '0em',
    fill = '#2E3A59',
    fontSize = 11,
    fontWeight = 600,
    strokeOnLabelWidth = 44,
}) => {

    return (
        <g transform={`translate(${x <= 180 ? x - strokeOnLabelWidth : x},${y - 10})`} fill='transparent'>
            <rect
                textAnchor="end"
                alignmentBaseline="auto"
                width={strokeOnLabelWidth}
                height="20"
                rx="3.5"
                fill='#fff'
                stroke="#2E3A59"
                strokeWidth="2"
            />
            <text
                cx={cx}
                cy={cy}
                fill={fill}
                textAnchor="middle"
                className="recharts-text recharts-pie-label-text"
                strokeWidth={0}
            >
                <tspan
                    x={24}
                    y={14}
                    dy={dy}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    transform={transform ? transform : ''}
                >
                    {value}
                </tspan>
            </text>
        </g>
    );
}

export default StrokeLabel;