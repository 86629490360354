import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import Layout from '@modules/occurrences/components/LayoutOccurrences';
import { Button, Input, Pagination } from 'react-alicerce-components';
import OccurrenceCard from '@modules/occurrences/components/OccurrenceCard';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import ListOccurrencesTreeService from '@modules/occurrences/services/ListOccurrencesTreeService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { translateType } from '@modules/occurrences/utils/translateType';

const OccurrencesPending: React.FC = () => {
  const navigate = useNavigate();
  const { newCancelToken } = useCancelRequest();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const params = useParams<{ context_id: string; context: string }>();
  const { context_id, context } = params;

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalOfPages, setTotalOfPages] = useState<number | string>(0);
  const [occurrencesPendingList, setOccurrencesPendingList] = useState<IOccurrence[]>([]);

  const listPendingOccurrences = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser || !context || !context_id) return;
      setIsLoading(true);
      const res = await new ListOccurrencesTreeService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        pending: true,
        ...translateType(context, context_id),
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res || !isMounted()) return;
      setOccurrencesPendingList(res.items);
      setCurrentPage(res.currentPage);
      setTotalOfPages(res.totalPages);
    },
    [signedUser, setIsLoading, newCancelToken, context, context_id, isMounted]
  );

  useEffect(() => {
    listPendingOccurrences();
  }, [listPendingOccurrences]);

  return (
    <Layout openTab={0}>
      <Input
        placeholder="Buscar"
        name="search"
        end={<Icon name="funnel" fill="#8F9BB3" />}
        onChange={(event) => listPendingOccurrences(event.target.value)}
      />
      <div>
        {occurrencesPendingList.map((o, i) => (
          <OccurrenceCard key={i} occurrence={o} />
        ))}
      </div>
      {!!occurrencesPendingList.length && (
        <Pagination totalOfPages={totalOfPages} currentPage={currentPage} callbackGetListData={listPendingOccurrences} status="basic" />
      )}
      <PageFooter>
        <Button
          style={{ background: '#568CF7', border: 'none', whiteSpace: 'break-spaces' }}
          transform="none"
          shaded
          onClick={() => navigate('/ocorrencias/criar')}
        >
          Criar Ocorrência
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default OccurrencesPending;
