import React from "react";
import { P, Divider, Grid } from "react-alicerce-components";
import { getDay } from '@shared/utils/getDay';
import { useHeadlights } from "@modules/projects/components/Headlights/context/HeadlightsContext";

const CurrentSprintAndPeriod: React.FC = () => {
    const { currentSprint, currentPeriod } = useHeadlights();

    return (
        <>
            {currentSprint && currentPeriod &&
                <Grid dFlex flexColumn alignItemsCenter>
                    <Grid dFlex textCenter>
                        <P fontWeight={700}>
                            {currentSprint?.name ? `${currentSprint.name}:` : 'Sprint: - '}
                        </P>
                        <P>
                            {currentSprint?.name && (
                                `${getDay(currentSprint.start_date)} - ${getDay(currentSprint?.end_date)}`
                            )}
                        </P>
                    </Grid>
                    <Grid dFlex textCenter>
                        <P fontWeight={700} fontSize={14}>
                            {currentPeriod?.name ? `${currentPeriod.name}:` : 'Período: - '}
                        </P>
                        <P fontSize={14}>
                            {currentPeriod?.name && (
                                `${getDay(currentPeriod.start_date)} - ${getDay(currentPeriod?.end_date)}`
                            )}
                        </P>
                    </Grid>
                </Grid>
            }
            <Divider className="divider" width="100%" />
        </>
    )
};

export default CurrentSprintAndPeriod;