import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GroupList from '@modules/groups/components/GroupsList';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

const OcurrenceGroupsRelationCreate: React.FC = () => {
  const { setOccurrenceCurrent, occurrenceCurrent } = useOccurrences();
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();

  useEffect(() => {
    if (!occurrenceCurrent) return;
    setSelectedGroup(occurrenceCurrent.group);
  }, [occurrenceCurrent]);

  const handleAddRelationGroup = useCallback(
    (groupSelect: IGroup | undefined) => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, group: groupSelect };
      });
    },
    [setOccurrenceCurrent]
  );

  const setSelectedItens = useCallback(
    (group: IGroup[]) => {
      setSelectedGroup(group[0]);
    },
    [setSelectedGroup]
  );

  const selectedItens = useMemo(() => {
    const aux = [] as IGroup[];
    selectedGroup && aux.push(selectedGroup);
    return aux;
  }, [selectedGroup]);

  return (
    <GroupList
      headerTitle="Diretório de Grupos"
      btnText="Adicionar Grupo"
      pathRedirect="/ocorrencias/criar"
      pathToReturn="/ocorrencias/criar"
      selectedGroups={selectedItens}
      setSelectedGroups={setSelectedItens}
      canSelect={'one'}
      handleOnClick={handleAddRelationGroup}
    />
  );
};

export default OcurrenceGroupsRelationCreate;
