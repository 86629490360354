import React, { RefObject, useCallback, useEffect, useState } from 'react';

import Icon from 'react-eva-icons';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Textarea, H6, Select, ISelectOption, Input, InputDatePicker } from 'react-alicerce-components';

import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import ScheduleEventCard from '@modules/schedules/components/ScheduleEventCard';
import CustomForm from '@shared/components/CustomForm';
import Divider from '@shared/components/Divider';
import { DaysOfWeekSelect } from '@shared/components/DaysOfWeekSelect';
import { beforeSubmitForm, errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { scheduleEventSchema } from '@modules/schedules/utils/scheduleEventSchema';
import { validatePeriodTime } from '@shared/helpers/DayHourHelper';
import { IEvent } from '@modules/schedules/interfaces/IEvent';
import { defaultEventTypes } from '@modules/schedules/utils/validData/validEventTypes';
import { validateDateLessThanAnother } from '@shared/helpers/DateHelper';
import { extractDate } from '@modules/schedules/utils/extractDate';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import { Container, HourContainer } from './styles';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';

interface IScheduleEventFormProps {
  formRef?: RefObject<FormHandles>;
  lockType?: string;
  status?: ElementStatus;
  statusNumber?: number;
  handleOnFinish?: (tag) => void;
  handleScheduleOnClick?: () => void;
  canUserEdit: boolean;
}

const ScheduleEventForm: React.FC<IScheduleEventFormProps> = ({
  formRef,
  handleOnFinish,
  lockType,
  status = 'basic',
  statusNumber = 600,
  handleScheduleOnClick,
  canUserEdit,
}) => {
  const { eventCurrent, setEventCurrent, scheduleCurrent } = useSchedules();
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);

  useEffect(() => {
    if (lockType) {
      setEventCurrent((oldState: any) => ({ ...oldState, type: lockType }));
    }
  }, [lockType, setEventCurrent]);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setEventCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setEventCurrent]
  );

  const handleChangeType = useCallback(
    (option: ISelectOption | null) => {
      setEventCurrent((oldState: any) => ({ ...oldState, type: option?.value }));
    },
    [setEventCurrent]
  );

  const handleCheckboxDays = useCallback(
    (value: string, status: boolean) => {
      setEventCurrent((oldState: any) => {
        const newWeekDays = status ? [...oldState.week_days, value] : oldState.week_days.filter((d) => d !== value);
        return { ...oldState, week_days: newWeekDays };
      });
    },
    [setEventCurrent]
  );

  const handleSubimit = useCallback(
    async (data) => {
      let eventEditOrCreate = {
        ...eventCurrent,
        ...data,
        start_time: eventCurrent?.start_time?.slice(0, 5),
        end_time: eventCurrent?.end_time?.slice(0, 5),
      } as IEvent;

      let formErros = await beforeSubmitForm({ ...eventEditOrCreate, schedule_id: scheduleCurrent?.id }, scheduleEventSchema);
      const isTimeInvalid = validatePeriodTime({
        start: eventEditOrCreate.start_time,
        end: eventEditOrCreate.end_time,
      });

      const isDatesInvalid = validateDateLessThanAnother({
        start: eventEditOrCreate.start_date,
        end: eventEditOrCreate.end_date,
      });

      if (isTimeInvalid) formErros = [...formErros, isTimeInvalid];
      if (isDatesInvalid) formErros = [...formErros, isDatesInvalid];

      setErros(formErros);

      if (formErros.length > 0) return;

      if (handleOnFinish) {
        handleOnFinish({
          ...eventEditOrCreate,
          schedule_id: scheduleCurrent?.id,
          start_date: extractDate(eventEditOrCreate.start_date),
          end_date: extractDate(eventEditOrCreate.end_date),
        });
      }
    },
    [eventCurrent, handleOnFinish, scheduleCurrent]
  );

  console.log(' ~ eventCurrent', eventCurrent);
  return (
    <Container>
      <CustomForm customInputColorText="#2E3A59" style={{ padding: '1rem', minHeight: '80vh' }}>
        <Form ref={formRef} onSubmit={handleSubimit} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <Input
            id="name"
            name="name"
            label="Titulo do Evento:"
            placeholder="Adicione Nome ao Evento"
            onChange={(event) => handleChangeInput(event)}
            error={errorMessageBuilder('name', errors)}
            defaultValue={eventCurrent?.name}
            useTooltipError={false}
            disabled={!canUserEdit}
            required
          />

          <Select
            label="Tipo do Entregável:"
            iconName="arrow-down"
            placeholder="Escolha um Tipo"
            handleSelectChange={handleChangeType}
            options={defaultEventTypes(lockType || eventCurrent?.type)}
            error={errorMessageBuilder('type', errors)}
            disabled={!!lockType || !canUserEdit}
            required
          />

          <Textarea
            label="Descrição do Evento:"
            name="description"
            placeholder="Adicione Descrição do Evento"
            value={eventCurrent?.description}
            onChange={(event) => handleChangeInput(event)}
            rows={4}
            error={errorMessageBuilder('description', errors)}
            useTooltipError={false}
            disabled={!canUserEdit}
            required
          />

          <Divider width="100%" />

          <H6 fontWeight={800} status={status} color={'#8F9BB3'}>
            DATAS E HORÁRIOS
          </H6>

          <InputDatePicker
            handleChangeDate={(date) => setEventCurrent((oldState: any) => ({ ...oldState, start_date: date }))}
            defaultValue={eventCurrent?.start_date}
            disabled
            input={{
              label: 'Data de Início:',
              name: 'start_date',
              placeholder: 'Dia/Mês/Ano',
              useTooltipError: false,
              required: true,
              error: errorMessageBuilder('start_date', errors),
            }}
          />

          <InputDatePicker
            handleChangeDate={(date) => setEventCurrent((oldState: any) => ({ ...oldState, end_date: date }))}
            defaultValue={eventCurrent?.end_date}
            disabled
            input={{
              label: 'Data de Fim:',
              name: 'end_date',
              placeholder: 'Dia/Mês/Ano',
              required: true,
              useTooltipError: false,
              error: errorMessageBuilder('end_date', errors),
            }}
          />

          <label className="error-dates">{errorMessageBuilder('dates', errors)}</label>

          <HourContainer>
            <Input
              id="start_time"
              name="start_time"
              label="Horário de Início:"
              placeholder="00:00"
              mask="##:##"
              end={<Icon name="clock" fill="#8F9BB3" />}
              onChange={(event) => handleChangeInput(event)}
              defaultValue={eventCurrent?.start_time ? eventCurrent?.start_time.slice(0, 5) : ''}
              useTooltipError={false}
              disabled={!canUserEdit}
              required
            />

            <Input
              id="end_time"
              name="end_time"
              label="Horário de Término:"
              placeholder="00:00"
              mask="##:##"
              end={<Icon name="clock" fill="#8F9BB3" />}
              onChange={(event) => handleChangeInput(event)}
              defaultValue={eventCurrent?.end_time ? eventCurrent?.end_time.slice(0, 5) : ''}
              useTooltipError={false}
              disabled={!canUserEdit}
              required
            />
            <label className="error-time">{errorMessageBuilder('time', errors)}</label>
          </HourContainer>

          <DaysOfWeekSelect
            status={status}
            statusNumber={statusNumber}
            label="Repetir Horários:"
            onClickCheckbox={(value, status) => handleCheckboxDays(value, status)}
            errors={errors}
            daysChecked={eventCurrent?.week_days}
            disabled={!canUserEdit}
          />

          {scheduleCurrent && scheduleCurrent.id && (
            <>
              <Divider width="100%" />
              <H6 fontWeight={800} status={status} color={'#8F9BB3'}>
                AGENDA RELACIONADA
              </H6>
              <ScheduleEventCard schedule={scheduleCurrent} handleOnClick={handleScheduleOnClick} />
            </>
          )}

          {errorMessageBuilder('schedule_id', errors) && (
            <InputErrorMessage customStyles={{ position: 'relative', top: '30px' }} error={errorMessageBuilder('schedule_id', errors)} />
          )}
        </Form>
      </CustomForm>
    </Container>
  );
};

export default ScheduleEventForm;
