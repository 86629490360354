import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import { IListWorkedHoursStatusOptions } from '@modules/worked-hours/services/ListWorkedHoursService';

interface ITotalHour {
  worked_minutes_sum: number;
}

type GetTotalWorkedHourServiceOptions = Omit<IQueryOptions, 'status'> & { status: IListWorkedHoursStatusOptions[] }
export class GetTotalWorkedHourService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options?: GetTotalWorkedHourServiceOptions): Promise<ITotalHour | false> {
    try {
      let searchParams = objectToQuery(
        {
          ...options,
        },
        true
      );

      const res = await this.api.get(`/worked-hour/sum?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}
