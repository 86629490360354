import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IFormattedPlannedActivity } from '../interfaces/IFormattedPlannedActivity';
import { IStudent } from '../../students/interfaces/IStudent';
import { IPlannedActivityData } from './ListPlannedActivityService';

interface ICreatePlannedActivityResponse {
  id: number;
  activity_id: number;
  daily_class_id: number;
  daily_class_plan_id: string;
  start_time: string;
  end_time: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;

  students: IStudent[];
  activity_data: IPlannedActivityData;
}

class CreatePlannedActivityService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(plannedActivity: IFormattedPlannedActivity): Promise<ICreatePlannedActivityResponse | false> {
    try {
      const res = await this.api.post('/daily-class-plan', { ...plannedActivity });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default CreatePlannedActivityService;
