import React from 'react';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';
import CustomTabs from '@shared/components/CustomTabs';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface IHabilidadesParaVidaGroupLayoutProps {
  openTab: number;
  footer?: boolean;
}

export const HabilidadesParaVidaGroupLayout: React.FC<IHabilidadesParaVidaGroupLayoutProps> = ({ openTab = 0, footer = false, children }) => {
  const { isLoading } = useGlobal();
  const { classId } = useParams();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Habilidades para Vida" pathToReturn={`/turma/${classId}/relatorios`} />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="ATUAL" />
          {/* <Tab title="ATUAL" onClick={() => navigate(`/dash/habilidadesparavida/grupo/atual`)} /> */}
          {/* <Tab title="EVOLUÇÃO" onClick={() => navigate(`/dash/habilidadesparavida/grupo/evolucao`)} /> */}
        </Tabs>
      </CustomTabs>
    </Container>
  );
};
