import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, H6, Input, Label, P, Textarea } from 'react-alicerce-components';

import Layout from '@modules/places/components/Layout';
import TagsRender from '@modules/tags/components/TagsRender';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import Card from '@shared/components/CustomCard';
import { usePlaces } from '@modules/places/context/PlacesContext';

import { useNavigate } from 'react-router-dom';
import UserCard from '@modules/users/components/UserCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import useAuth from '@shared/store/auth/hook';
import ListPlacesService, { IPlaceReponse } from '@modules/places/services/ListPlacesService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { translateString } from '@shared/utils/translateString';
import CustomForm from '@shared/components/CustomForm';
import InputPill from '@shared/components/InputPill';
import { FiCheck } from 'react-icons/fi';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { PlaceFormContainer } from '../../components/PlaceForm/styles';

const PlaceInfo: React.FC = () => {
  const { placeCurrent } = usePlaces();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const [placeChild, setPlaceChild] = useState<IPlaceReponse>();

  const loadPlaceChild = useCallback(async () => {
    if (!signedUser || !placeCurrent) return;
    setIsLoading(true);
    const res = await new ListPlacesService(signedUser.token, newCancelToken()).execute('', {
      page: 0,
      size: 50,
      parent_id: Number(placeCurrent.id),
    });
    if (res === 'canceling') return;
    res && setPlaceChild(res);
    setIsLoading(false);
  }, [signedUser, placeCurrent, setIsLoading, newCancelToken]);

  useEffect(() => {
    !placeChild && loadPlaceChild();
  }, [loadPlaceChild, placeChild]);

  return (
    <Layout openTab={1} footer>
      <PlaceFormContainer>
        <H6 fontWeight={800} status="primary">
          DADOS DO LOCAL
        </H6>

        <CustomForm status="primary" customInputColorText="#F47920">
          <Input disabled name="name" label="Nome do Local:" defaultValue={placeCurrent?.name} />

          <InputPill label="Categoria" inputPillData={[placeCurrent?.category ? translateString(placeCurrent.category) : '']} />

          <InputPill label="Status do Local:" inputPillData={[placeCurrent?.status ? translateString(placeCurrent.status) : '']} />

          <div className="container-parent-place">
            <Label>Local Mãe:</Label>
            {placeCurrent?.parent && <PlaceCard place={placeCurrent?.parent} isInputCard={true} customStyle={{ margin: '1rem' }} />}
            {!placeCurrent?.parent && <Input disabled={true} name="parent" />}
          </div>

          <Input disabled name="address" label="Endereço:" defaultValue={placeCurrent?.address_data?.address} />

          <div className="wrapper-input">
            <Input disabled name="city" defaultValue={placeCurrent?.address_data?.city} />
            <Input disabled name="uf" defaultValue={placeCurrent?.address_data?.uf} />
          </div>

          {placeCurrent && placeCurrent.virtual_place && (
            <div className="container-online-place">
              <Checkbox
                name="virtual_place"
                label="Sala Virtual"
                id="1"
                checked={placeCurrent.virtual_place}
                disabled={true}
                hideIconUncheck
                icon={FiCheck}
                semiRound
              />
              <Input disabled name="place_url" defaultValue={placeCurrent?.place_url} />
            </div>
          )}

          <div className="container-tags">
            <TagsRender label="Tags: " status="quaternary" tags={placeCurrent?.tags} />
          </div>

          <Textarea
            label="Descrição do Local:"
            name="description"
            value={placeCurrent?.description}
            disabled
            useTooltipError={false}
            rows={4}
            icon={undefined}
          />
        </CustomForm>
        <Divider width="100%" />

        <H6 fontWeight={800} status="primary">
          LOCAIS DESTE LOCAL
        </H6>
        <AccordionInfoItem title={`Locais ${placeChild ? '(' + placeChild.items.length + ')' : ''}`} status="primary">
          {placeChild?.items &&
            placeChild.items.map((child, index) => (
              <Card key={index} status="primary" header={<h6>{child.name}</h6>}>
                <P textAlign="center">{child.description}</P>
              </Card>
            ))}
        </AccordionInfoItem>

        <Divider width="100%" />

        <H6 fontWeight={800} status="primary">
          RESPONSÁVEIS PELO LOCAL
        </H6>

        <AccordionInfoItem title={`Usuários (${placeCurrent?.admins ? placeCurrent?.admins.length : 0})`} status="primary">
          {placeCurrent?.admins?.map((user) => (
            <div className="all-users-container" key={user.id}>
              <UserCard canSelect={false} user={user} />
            </div>
          ))}
        </AccordionInfoItem>
      </PlaceFormContainer>

      <PageFooter>
        <Button transform="none" status="primary" shaded onClick={() => navigate(`/local/${placeCurrent?.id}/editar`)}>
          Editar
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default PlaceInfo;
