import ClassForm from '@modules/classes/components/ClassForm';
import { useClass } from '@modules/classes/context/ClassContext';
import CreateClassService from '@modules/classes/services/CreateClassService';
import { formatClass } from '@modules/classes/utils/formatClass';
import ConfirmModal from '@shared/components/ConfirmModal';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import React, { Fragment, useCallback, useState } from 'react';
import { Button, useToast } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';
import { classSchema } from '../../utils/classSchema';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { IInputErrorsFormat, beforeSubmitForm } from '@shared/utils/beforeSubmitForm';

const ClassCreate: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const { setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { classCurrent, clearClassContextData } = useClass();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const handleCreateClass = useCallback(async () => {
    setShowModal(false);
    if (!signedUser) return;
    const formatedBody = formatClass(classCurrent);
    const { isCustomClass, ...body } = formatedBody;
    setIsLoading(true);
    const res = await new CreateClassService(signedUser.token).execute(body);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Turmas',
        description: 'Turma criado com sucesso.',
      });
      clearClassContextData();
      return navigate('/diretorio/turmas');
    }
    addToast({
      status: 'danger',
      title: 'Turmas',
      description: 'Não foi possível criar o Turma.',
    });
  }, [addToast, classCurrent, clearClassContextData, navigate, setIsLoading, signedUser]);

  const canSaveClass = useCallback(async () => {
    const formatedBody = formatClass(classCurrent);
    console.log(formatedBody, 'formatted classCurrent body para backend');
    let formErros = await beforeSubmitForm(formatedBody, classSchema);
    if (!classCurrent.place) formErros = [...formErros, { name: 'place', message: 'É necessário selecionar um Lugar' }];
    if (!classCurrent.group) formErros = [...formErros, { name: 'group', message: 'É necessário selecionar um Grupo' }];
    setErros(formErros);
    if (formErros.length === 0) setShowModal(true);
  }, [classCurrent]);

  const handleCancelCreate = useCallback(() => {
    clearClassContextData();
    navigate(`/diretorio/turmas`);
  }, [navigate, clearClassContextData]);

  return (
    <Fragment>
      <HeaderDirectory
        status="quaternary"
        title="Criar Turma"
        pathToReturn="/diretorio/turmas"
        onClickReturn={clearClassContextData}
        menuKebab={{ tooltip: { view: 'ClassCreate' } }}
      />
      <ClassForm
        formAction="creating"
        pathEnvolvedGroups="/diretorio/turmas/selecionar/grupos"
        pathEnvolvedPlaces="/diretorio/turmas/selecionar/locais"
        pathEnvolvedUsers="/diretorio/turmas/selecionar/lideres"
        pathEnvolvedStudents="/diretorio/turmas/selecionar/alunos"
        pathToInsertTime="/diretorio/turmas/selecionar/dias-e-horarios"
        errors={errors}
      />

      <PageFooter>
        <Button transform="none" outline status="quaternary" statusNumber={400} shaded onClick={handleCancelCreate}>
          Cancelar
        </Button>
        <Button transform="none" status="quaternary" shaded onClick={canSaveClass}>
          Salvar
        </Button>
      </PageFooter>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Deseja Criar a Turma?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateClass}
      />
    </Fragment>
  );
};

export default ClassCreate;
