import React from 'react';
import { Button } from 'react-alicerce-components';
import { ModalWindowsChildrenProps } from '../Modalv1';
import ApplicationResumeContainer from '../../../modules/applications/components/ApplicationResumeContainer';
import { WrapperContent, Footer } from './styles';

interface IpplicationResumeModalV1 extends ModalWindowsChildrenProps {
  applicationId?: string;
}

export const ApplicationResumeModalV1: React.FC<IpplicationResumeModalV1> = (props) => {
  const { onCloseModal, applicationId } = props;

  return (
    <>
      <WrapperContent>
        <ApplicationResumeContainer lastApplicationId={applicationId} showHeaderLearningTarget />
      </WrapperContent>
      <Footer>
        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
          onClick={() => onCloseModal?.()}
        >
          Fechar
        </Button>
      </Footer>
    </>
  );
};
