import IRoute from '@shared/interfaces/IRoute';

import { LauncherClassesSelection } from '@modules/launchers/views/LauncherClassesSelection';
import { MapaLauncher } from '@modules/launchers/views/MapaLauncher';
import { ApplicationSelection } from '@modules/launchers/views/ApplicationSelection';
import { TrailSelection } from '@modules/launchers/views/TrailSelection';
import { MiniMapaLauncher } from '@modules/launchers/views/MiniMapaLauncher';
import { LaunchersProvider } from '@modules/launchers/context/LaunchersContext';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { classesKeys } from '@modules/classes/keys';
import { studentsKeys } from '@modules/students/keys';
import { applicationsKeys } from '@modules/applications/keys';

const routes: IRoute[] = [
  {
    path: '/lancador/avaliacao/contexto/turmas',
    key: 'LAUNCHERS_MAPA_E_MINIMAPA_CONTEXT_SELECTION_CLASSES_PATH',
    exact: true,
    component: LauncherClassesSelection,
    permissions: [
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: '/lancador/avaliacao/:classId',
    key: 'LAUNCHERS_SELECAO_APLICACAO_PATH',
    exact: true,
    component: ApplicationSelection, 
    permissions: [
      applicationsKeys.APPLICATIONS_CREATE,
    ]
  },
  {
    path: '/lancador/mapa/:id',
    key: 'LAUNCHER_MAPA_PATH',
    exact: true,
    component: MapaLauncher,
    permissions: [
      studentsKeys.STUDENTS_READ,
      applicationsKeys.APPLICATIONS_CREATE,
    ]
  },
  {
    path: '/lancador/minimapa/selecao-trilha/:id',
    key: 'LAUNCHERS_SELECAO_TRILHA_PATH',
    exact: true,
    component: TrailSelection,
    permissions: 'public'
  },
  {
    path: '/lancador/minimapa/:trail/:id',
    key: 'LAUNCHER_MINI_MAPA_PATH',
    exact: true,
    component: MiniMapaLauncher,
    permissions: [
      studentsKeys.STUDENTS_READ,
      applicationsKeys.APPLICATIONS_CREATE,
    ]
  },
];

const providers = [LaunchersProvider];

export default {
  key: 'LAUNCHERS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
