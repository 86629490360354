import React, { useState } from 'react';

import { SprintCardBodyContainer } from './styles';

export interface SprintCardBodyProps {
  isTrailsOpen?: boolean;
}

const SprintCardBody: React.FC<SprintCardBodyProps> = (props) => {
  const [openTrails] = useState<boolean>(false);

  return <SprintCardBodyContainer isTrailsOpen={openTrails}>{props.children}</SprintCardBodyContainer>;
};

export { SprintCardBody };
