import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  margin: 1.5rem 0;
  .container-daily-class {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;
