export const initalDataZdpBlock = [
  {
    trail: {
      id: '',
      name: '',
      version: '',
    },
    block: {
      id: '',
      name: '',
      order: '',
      trail_id: '',
      level_id: '',
      color_variation: 1,
      level: {
        id: 1,
        name: '',
        order: 1,
        color: '',
      },
    },
  },
];
