import React from 'react';
import { ISkill } from '@shared/interfaces/ISkill';
import { Container } from './styles';

interface ISkillCardProps {
  skill: ISkill;
}

export const SkillCard: React.FC<ISkillCardProps> = ({ skill }) => {
  return (
    <Container className='container-skill-card'>
      <div className="container-description-skill">{skill.descriptions}</div>
      <div className="container-footer-skill">
        <div className="applied-filter">{skill.block?.name}</div>
        <div className="applied-filter">{skill.name}</div>
      </div>
    </Container>
  );
};
