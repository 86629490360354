import { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';

import { IApplicationChart } from '../interfaces/IApplicationChart';

class GetApplicationChartService {
    private api: AxiosInstance;

    constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
        if (!authToken) return;

        this.api = ApiFactory.create({
            token: authToken,
            cancelTokenSource,
        });
    }

    public async execute(applicationId: string | number): Promise<IApplicationChart | false> {
        try {
            const res = await this.api.get(`/application/${applicationId}/chart`);
            return res.data;
        } catch (error: any) {
            console.error(`Error: ${error}`);
            return false;
        }
    }
}

export default GetApplicationChartService;