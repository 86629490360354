import styled from 'styled-components';

export const ModalContainer = styled.div<{ showModal: boolean }>`
  position: fixed;
  display: ${(p) => (p.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 15;
`;

export const ContainerData = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  max-width: 90%;
  min-width: 230px;

  .error {
    textarea {
      border: 1px solid red;
    }
  }

  .badge-modal-line {
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 10px;
  }

  .icon-start-container {
    margin-top: 10px;
    i {
      display: flex;
      svg {
        width: 90px;
        height: 90px;
      }
    }
  }

  .show-close-button-confirm-modal {
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
    padding-right: 2px;
    z-index: 10;

    i {
      display: flex;
      cursor: pointer;
    }
  }

  .title-confirm-modal {
    text-align: center;
    padding: 0px 15px;
    margin-top: 25px;
    font-weight: 800;
    font-size: 22px;
    line-height: 34px;
    color: #222b45;
  }

  .margin-wihout-start {
    margin-top: 15px;
  }

  .subTitle-confirm-modal {
    color: #8f9bb3;
    width: 85%;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
  }
`;

export const ButtonModal = styled.div<{ twoButtons: boolean }>`
  display: flex;
  justify-content: ${(p) => (p.twoButtons ? 'space-between' : 'center')};
  width: 90%;
  margin-bottom: 15px;

  button {
    max-width: 130px;
    height: 40px;
    font-size: 0.75rem;
    i {
      display: flex;
      margin: 0px;
    }
  }
`;
