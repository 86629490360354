import React from 'react';
import CustomCard from '@shared/components/CustomCard';

import Tag from '@modules/tags/components/Tag';

import { CSSProperties } from 'styled-components';

import { Name, Info, Container, HeaderContainer } from './styles';

interface ProjectHourCardProps {
  selected?: any[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
  handleOnClick?: (clicked: any) => void;
}

const ProjectHourCard: React.FC<ProjectHourCardProps> = ({
  selected,
  canSelect,
  inputCard,
  canDeleteCard,
  customStyle,
  handleOnClick,
}) => {

  // const cardShadow = useCallback(() => {
  //   if (canSelect && classesSelected?.find((p) => p.id === classInfo.id)) return 'drop-shadow(0px 0px 28px #49C56C)';
  //   return 'none';
  // }, [canSelect, classesSelected, classInfo.id]);

  return (
    <Container>
      <CustomCard
        // handleOnClick={() => handleOnClick && handleOnClick({})}
        header={
          <HeaderContainer>
            <Tag tag={{ color: '#E4E9F2', name: 'Grupo Relacionado' }} />
          </HeaderContainer>
        }
        footer={
          <div>
            <Tag tag={{ color: '#FFC94D', name: 'Categoria da Hora' }} />
          </div>
        }
        canDeleteCard={canDeleteCard}
        inputCard={inputCard}
      // customStyles={{
      //   border: 'none',
      //   filter: `${cardShadow()}`,
      //   ...customStyle,
      // }}
      >
        <Name>Guilherme Chevis Meira</Name>
        <Info>23 de Novembro de 2021: <b>51 horas lançadas</b></Info>
      </CustomCard>
    </Container>
  );
};

export default ProjectHourCard;
