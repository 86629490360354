import React, { useState, useCallback } from 'react';
import Icon from 'react-eva-icons';
import { Input, Spinner } from 'react-alicerce-components';

import Layout from '@modules/recordings/components/Layout';

import PlacesSelection from '@shared/components/PlacesSelection';
import IPlace from '@modules/places/interfaces/IPlace';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useNavigate } from 'react-router-dom';

const RecordingsPlacesSelection: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  const [searchValue, setSearchValue] = useState<string>('');

  const handleOnClick = useCallback(
    (placeClicked: IPlace) => {
      console.log(placeClicked);

      navigate(`/gravacoes/cameras`);
    },
    [navigate]
  );

  const filterPlaces = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <Layout openTab={1}>
      {isLoading && <Spinner />}
      <div style={{ marginBottom: '2rem' }}>
        <Input
          placeholder="Buscar"
          name="search"
          end={<Icon name="funnel" fill="#8F9BB3" />}
          onChange={(event) => filterPlaces(event.target.value)}
        />
      </div>
      <PlacesSelection handleOnClick={handleOnClick} searchValue={searchValue} />
    </Layout>
  );
};

export default RecordingsPlacesSelection;
