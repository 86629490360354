import React from 'react';

import { ActivityLibraryCardBorderContainer } from './styles';

export interface IActivityLibraryCardBorder {
  location: 'header' | 'footer';
  handleOnClick?: () => void;
  isDescriptionOpen?: boolean
}

const ActivityLibraryCardBorder: React.FC<IActivityLibraryCardBorder> = ({ isDescriptionOpen = false, location, handleOnClick, children}) => {
  return (
    <ActivityLibraryCardBorderContainer isDescriptionOpen={isDescriptionOpen} location={location} onClick={handleOnClick}>
      {children}
    </ActivityLibraryCardBorderContainer>
  );
};

export default ActivityLibraryCardBorder;
