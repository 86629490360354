import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';

import StudentCardLauncherLocation from './StudentCardLauncherLocation';
import { StudentCardLauncherBody } from './StudentCardLauncherBody';
import StudentCardLauncherBorder from './StudentCardLauncherBorder';

import { IStudentWithGrades } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { IStudent } from '@modules/students/interfaces/IStudent';

import { ClassCardEnrollmentContainer } from './styles';
import { useGlobal } from '../../../../shared/contexts/global/GlobalContext';
import GetLastApplicationFromStudent from '../../services/GetLastApplicationFromStudent';
import useAuth from '../../../../shared/store/auth/hook';
import useCancelRequest from '../../../../shared/hooks/useCancelRequest';
import { useIsMounted } from '../../../../shared/hooks/useIsMounted';
import { useParams } from 'react-router-dom';
import { IApplication } from '../../../applications/interfaces/IApplication';
import { useLaunchers } from '../../context/LaunchersContext';
interface IStudentCardLauncherProps {
  handleOnChangeCheckbox: (event: ChangeEvent<HTMLInputElement>, student: IStudent) => void;
  cancelApplication?: (id: string) => void;
  setResetAccordion?: React.Dispatch<React.SetStateAction<boolean>>;
  resetAccordion?: boolean;
  studentData?: IStudentWithGrades;
  className?: string;
  trails?: boolean;
}

export type TSkillsAndQuestions = { id: number; questions: number }[];

const StudentCardLauncher: React.FC<IStudentCardLauncherProps> = (props) => {
  const [isApply, setIsApply] = useState<boolean>(false);
  const [isPrintble, setIsPrintble] = useState<boolean>(false);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [skillsAndQuestions, setSkillsAndQuestions] = useState<TSkillsAndQuestions>();
  const [lastApplication, setLastApplication] = useState<IApplication>();
  const { trailId } = useLaunchers();
  const { id } = useParams<{ id: string }>();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const classCardHeaderData = useCallback((studentData: any): string[] => {
    const cardHeaderData: string[] = [];
    if (!studentData.student.age_group) return cardHeaderData;
    const { age_group } = studentData.student;
    cardHeaderData.push(age_group!);
    return cardHeaderData;
  }, []);

  const handleClickOpenTrail = () => {
    setOpenLocation(!openLocation);
  };

  const getLastApplicationFromStudent = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);

    const res = await new GetLastApplicationFromStudent(signedUser?.token, newCancelToken()).execute({
      application_type: 'MINIMAPA',
      studentId: props.studentData?.student.id!,
      trail_id: +trailId!,
    });
    if (res === 'canceling') return;
    setIsLoading(false);
    if (!!!res || !isMounted()) return;
    if (!res) return;
    setLastApplication(res);
  }, [id, isMounted, newCancelToken, props.studentData, setIsLoading, signedUser, trailId]);

  useEffect(() => {
    if (!!props.resetAccordion) {
      setOpenLocation(false);
      props.setResetAccordion && props.setResetAccordion(false);
    }
  }, [getLastApplicationFromStudent, props, props.resetAccordion]);

  useEffect(() => {
    if (!props.resetAccordion) getLastApplicationFromStudent();
  }, [getLastApplicationFromStudent, props.resetAccordion]);

  return (
    <ClassCardEnrollmentContainer className={props.className || undefined}>
      <div className="card-container">
        <StudentCardLauncherBorder
          data={props.studentData ? classCardHeaderData(props.studentData) : []}
          studentData={props.studentData}
          displayCheckboxHeader
          location="header"
          isPrintble={isPrintble}
          setIsPrintble={setIsPrintble}
          isApply={isApply}
          setIsApply={setIsApply}
        />
        <div style={{ cursor: 'pointer' }} onClick={() => handleClickOpenTrail()}>
          <StudentCardLauncherBody isTrailsOpen={openLocation}>
            <b>{props.studentData?.student.name}</b>
            {props.studentData?.gradeInfo ? props.trails && <Icon name="arrow-ios-forward-outline" fill="#2E3A59" /> : ''}
          </StudentCardLauncherBody>
        </div>
        <StudentCardLauncherBorder
          checkBoxToPrint={props.studentData}
          //handleOnChangeCheckbox={props.handleOnChangeCheckbox}
          location="footer"
          skillsAndQuestions={skillsAndQuestions}
          isPrintble={isPrintble}
          lastApplication={lastApplication}
          isOpen={openLocation}
          showLastApplication
          getLastApplicationFromStudent={getLastApplicationFromStudent}
          setIsPrintble={setIsPrintble}
          isApply={isApply}
          setIsApply={setIsApply}
        />
        {props.trails && openLocation && (
          <StudentCardLauncherLocation
            blocksAndSkills={props.studentData?.gradeInfo}
            studentData={props.studentData!}
            isOpen={openLocation}
            setSkillsAndQuestions={setSkillsAndQuestions}
            handleClickOpenTrail={handleClickOpenTrail}
          />
        )}
      </div>
    </ClassCardEnrollmentContainer>
  );
};

export { StudentCardLauncher };
