import { ISelectOption } from 'react-alicerce-components';

// constraints de relacionamento
export const RELATIONSHIP_B2C = { name: 'B2C', value: 'B2C' };                      // BUSINESS TO CUSTOMER
export const RELATIONSHIP_B2B = { name: 'B2B', value: 'B2B' };                      // BUSINESS TO BUSINESS
export const RELATIONSHIP_B2G = { name: 'B2G', value: 'B2G' };                      // BUSINESS TO BUSINESS
export const RELATIONSHIP_B2B2C = { name: 'B2B2C', value: 'B2B2C' };                // BUSINESS TO BUSINESS
export const RELATIONSHIP_SCHOLARSHIP = { name: 'Bolsista', value: 'scholarship' }; // BOLSISTA
export const RELATIONSHIP_GRATUITY = { name: 'Gratuidade', value: 'gratuity' };     // GRATUIDADE
export const RELATIONSHIP_ALIPASS = { name: 'Alipass', value: 'alipass' };          // ALIPASS

export const STUDENT_RELATIONSHIP_CONSTRAINTS = [
  RELATIONSHIP_B2C,
  RELATIONSHIP_B2B,
  RELATIONSHIP_B2G,
  RELATIONSHIP_B2B2C,
  RELATIONSHIP_SCHOLARSHIP,
  RELATIONSHIP_GRATUITY,
  RELATIONSHIP_ALIPASS,
];

export const defaultRelationships = (studentRelationship?: string | null): ISelectOption[] | undefined => {
  return STUDENT_RELATIONSHIP_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: studentRelationship === foo.value };
  });
};
