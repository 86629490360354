import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect
} from "react";

import { IAttendance } from "@modules/attendances/interfaces/IAttendance";

export type IAttendancesContextData = {
  attendanceCurrent?: IAttendance | null;
  setAttendanceCurrent: (value: any) => void;
  clearAttendanceCurrent: () => void;
};

const AttendancesContext = createContext<IAttendancesContextData>({} as IAttendancesContextData);

const AttendancesProvider: React.FC = ({ children }) => {

  const [attendanceCurrent, setAttendanceCurrent] = useState<IAttendance>();
  const clearAttendanceCurrent = useCallback(() => {
    setAttendanceCurrent(undefined);
  }, [setAttendanceCurrent]);


  useEffect(() => {
    clearAttendanceCurrent();
  }, [clearAttendanceCurrent]);

  return (
    <AttendancesContext.Provider
      value={{
        attendanceCurrent,
        setAttendanceCurrent,
        clearAttendanceCurrent
      }}
    >
      {children}
    </AttendancesContext.Provider>
  );
};

function useAttendances() {
  const context = useContext(AttendancesContext);

  if (!context) {
    throw new Error("useAttendances must be used within a AttendancesProvider");
  }

  return context;
}

export { AttendancesProvider, useAttendances };
