import { changeDays, getMonday } from '@shared/helpers/DateHelper';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Icon from 'react-eva-icons';

import { Container } from './styles';

export interface IChangeWeek {
    startDate: Date;
    endDate: Date;
}

interface IWeekSelectProps {
    date?: string;
    handleChangeWeek?: (data: IChangeWeek) => Promise<void>;
}

declare type IAction = 'forward' | 'back';

const WeekSelect: React.FC<IWeekSelectProps> = ({
    date,
    handleChangeWeek
}) => {

    const [dateCurrent, setDateCurrent] = useState<Date>(new Date(date ? date : new Date()));

    const changeWeek = useCallback((action: IAction): void => {
        const monday = getMonday(dateCurrent);
        const isForward = action === 'forward';
        const newDate = changeDays(
            monday,
            isForward ? 7 : 6,
            isForward ? '+' : '-'
        );

        const newMonday = getMonday(newDate);
        if (handleChangeWeek) {
            handleChangeWeek({
                startDate: newMonday,
                endDate: changeDays(newMonday, 6),
            });
        }

        setDateCurrent(newDate);

    }, [dateCurrent, handleChangeWeek]);


    const getWeek = useMemo(() => {
        const monday = getMonday(dateCurrent);
        return `Semana: ${new Intl.DateTimeFormat('pt-BR').format(monday)} - ${new Intl.DateTimeFormat('pt-BR').format(changeDays(monday, 6))}`
    }, [dateCurrent]);

    useEffect(() => {
        setDateCurrent((oldDate) => {
            if (!date) return oldDate;
            return new Date(date);
        });
    }, [date]);

    return (
        <Container>
            <label className='week-select-icon' onClick={() => changeWeek('back')}>
                <Icon name='arrow-ios-back' />
            </label>
            <span className='week-select-text'>
                {getWeek}
            </span>
            <label className='week-select-icon' onClick={() => changeWeek('forward')}>
                <Icon name='arrow-ios-forward' />
            </label>
        </Container>
    );
}

export default WeekSelect;