import React from 'react';

import { Checkbox, IInputCheckboxProps } from 'react-alicerce-components';

import { FiCheck } from 'react-icons/fi';


import { StyledCheckboxContainer } from './styles';

export type CheckboxTagItem = IInputCheckboxProps & {
  value?: string
}

export interface CheckboxTagItemChange {
  name: string
  checked: boolean
  value?: string
  event: React.ChangeEvent<HTMLInputElement>
}

export type CheckboxTagProps = CheckboxTagItem & {
  handleOnSetState?: React.Dispatch<React.SetStateAction<any>>
  handleOnChange?: (state: CheckboxTagItemChange) => void;
}

export const CheckboxTag: React.FC<CheckboxTagProps> = ({
  value,
  handleOnChange,
  handleOnSetState,
  ...rest
}) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    if (handleOnChange) {
      handleOnChange({
        name,
        checked,
        value,
        event
      })
    }

    if (handleOnSetState && value) {
      handleOnSetState((oldState) => {
        return Array.isArray(oldState) ?
          oldState.includes(name) ? oldState.filter(i => i !== name) : [...oldState, name] :
          { ...oldState, [name]: oldState[name] && checked ? undefined : value }
      })
    }
  }

  return (
    <StyledCheckboxContainer>
      <Checkbox
        {...rest}
        onChange={handleChange}
        hideIconUncheck
        icon={FiCheck}
      />
    </StyledCheckboxContainer>
  );
};

export * from './CheckboxTagsGroup'

