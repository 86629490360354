import styled from "styled-components";

export const Container = styled.div<{type?: string}>`
    position: relative;
    display: flex;

    label {
        margin-top: 1.8rem;
    }

    .custom-card {
        width: 100%;
        margin: 0;

        > div {
            margin-bottom: 0;
        }

    }

   
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${({ type }) => type === 'MAPA' ? '#6749BA' : '#FFBA95' };

        h6 {
            color: #fff;
            font-size: 14px;
            font-weight: 800;
        }
    }

    .card-footer {
        display: none;
    }

    .card-body {
        padding: 20px;
        background-color: ${({ type }) => type === 'MAPA' ? '#E9D7F9' : '#FFEEE5' };

        p {
            font-size: 12px;
            font-weight: 600;
            color: #222B45;
        }
    }
`;