import { DateHelper } from '../../../shared/helpers/DateHelper';
import { IOccurrence } from '../interfaces/IOccurrence';

export const formatOccurrence = (occurrence: IOccurrence) => {
  const usDate = occurrence.day ? DateHelper.getUSDate(occurrence.day) : undefined;

  return {
    title: occurrence.title,
    description: occurrence.description,
    comment: !!occurrence.comment ? occurrence.comment : undefined,

    date: usDate && occurrence.time ? new Date(`${usDate}T${occurrence.time}`) : undefined,

    group_id: occurrence.group?.id,
    place_id: occurrence.place?.id,
    class_id: occurrence.class?.id,
    daily_class_id: occurrence.daily_class?.id,

    tag_ids: occurrence.tags ? occurrence.tags?.map((tag) => tag.id) : [],
    student_ids: occurrence.students ? occurrence.students?.map((student) => student.id) : [],
    lms_user_ids: occurrence.lms_users ? occurrence.lms_users?.map((lms_users) => lms_users.id) : [],
  };
};
