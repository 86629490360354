const headlightsConfig = {
    success: { label: 'Verde', color: '#00D68F' },
    warning: { label: 'Amarelo', color: '#FFAA00' },
    danger: { label: 'Vermelho', color: '#FF3D71' },
    basic: {label: 'Branco',  color: '#FFF' }
  }

export type HeadlightType = keyof typeof headlightsConfig;

export default headlightsConfig

