import React, {
  createContext,
  useContext,
  useState,
} from "react";

import IMedia from "@shared/interfaces/IMedia";

export type IRecordingsContextData = {
  mediaCurrent: IMedia;
  medias: IMedia[];
  setMediaCurrent: (value: any) => void;
  setMedias: (value: any) => void;
};

const RecordingsContext = createContext<IRecordingsContextData>({} as IRecordingsContextData);

const RecordingsProvider: React.FC = ({ children }) => {

  const [mediaCurrent, setMediaCurrent] = useState<IMedia>({
    type: 'image',
    url: require('@shared/assets/placeholder_video.png'),
    date: '15/02/2021',
    name: 'Video-08-11-2021-14h35',
    time: '12:35:29',
    tags: [{
      name: 'Turma 123',
      color: '#C8FBF2'
    }]
  });

  const [medias, setMedias] = useState<IMedia[]>(new Array(4).fill({
    type: 'image',
    url: require('@shared/assets/placeholder_video.png'),
    date: '15/02/2021',
    name: 'Video-08-11-2021-14h35',
    time: '12:35:29',
    tags: [{
      name: 'Turma 123',
      color: '#C8FBF2'
    }]
  }));

  return (
    <RecordingsContext.Provider
      value={{
        medias,
        mediaCurrent,
        setMediaCurrent,
        setMedias
      }}
    >
      {children}
    </RecordingsContext.Provider>
  );
};

function useRecordings() {
  const context = useContext(RecordingsContext);

  if (!context) {
    throw new Error("useRecordings must be used within a RecordingsProvider");
  }

  return context;
}

export { RecordingsProvider, useRecordings };
