import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, H6, Spinner, useToast } from 'react-alicerce-components';

import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import ConfirmModal from '@shared/components/ConfirmModal';
import PageFooter from '@shared/components/PageFooter';

import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';
import CreateSprintService from '@modules/sprint/services/CreateSprintService';
import { SprintBodySchema } from '@modules/sprint/utils/SprintBodySchema';
import { SprintForm } from '@modules/sprint/components/sprintForm';

import { IAwnsersStringParams, useSprint } from '@modules/sprint/context/SprintContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { getDay } from '@shared/utils/getDay';
import useAuth from '@shared/store/auth/hook';
import Icon from 'react-eva-icons';

import { Container } from './styles';
import { ISprint } from '@modules/schedules/interfaces/ISprint';

export const SprintCreate: React.FC = () => {
  const [getCurrentSprintResponse, setGetCurrentSprintResponse] = useState<ISprint>();
  const [isResponseEmpty, setIsResponseEmpty] = useState<boolean>(false);
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);

  console.log(getCurrentSprintResponse?.start_date);
  const { sprintAnswersCurrent } = useSprint();
  const { classId } = useParams<{ classId: string }>();
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const navigate = useNavigate();

  const errorMessage = { 404: 'Não Há Nenhuma Sprint!', 409: 'Há Mais de Uma Sprint!' }[getCurrentSprintResponse as unknown as number];

  const createSprint = useCallback(async () => {
    if (!signedUser || !sprintAnswersCurrent) return;

    if (typeof getCurrentSprintResponse === 'number') {
      setIsResponseEmpty(true);
      return;
    }

    let formErros = await beforeSubmitForm(sprintAnswersCurrent, SprintBodySchema);
    setErrors(formErros);
    if (formErros.length !== 0) return setIsLoading(false);

    setIsLoading(true);
    const res = await new CreateSprintService(signedUser?.token).execute(sprintAnswersCurrent as IAwnsersStringParams);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Sprint',
        description: 'Erro ao criar o Sprint.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Sprint',
      description: 'Sprint criada com sucesso.',
    });
    navigate(`/planejador-sprint/${classId}`);
  }, [addToast, classId, getCurrentSprintResponse, navigate, setIsLoading, signedUser, sprintAnswersCurrent]);

  const getCurrentSprint = useCallback(
    async (classId) => {
      if (!signedUser) return;

      setIsLoading(true);
      const res = await new GetCurrentSprintService(signedUser.token, newCancelToken()).execute({class_id: classId});
      setIsLoading(false);

      if (!res) return setIsResponseEmpty(true);
      setGetCurrentSprintResponse(res);
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    getCurrentSprint(classId);
  }, [classId, getCurrentSprint]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Plano de Ensino"
        pathToReturn={`/planejador-sprint/${classId}`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
      />
      <DirectoryContainer footer={true}>
        <div className="box-title-create">
          <H6 fontWeight={800} fontSize={'14px'} color={'#2E3A59'} status="secondary" className="title-sprint-create">
            {getCurrentSprintResponse?.name ? <>{`${getCurrentSprintResponse.name}:`}</> : <>Sprint:</>}
          </H6>
          <H6 fontWeight={600} fontSize={'14px'} color={'#2E3A59'} status="secondary" className="title-sprint-create">
            {getCurrentSprintResponse?.name && (
              <>{`${getDay(getCurrentSprintResponse.start_date)} - ${getDay(getCurrentSprintResponse?.end_date)}`}</>
            )}
          </H6>
        </div>
        <SprintForm errors={errors} />

        <PageFooter>
          <Button transform="none" status="quaternary" shaded onClick={createSprint}>
            Submeter Plano de Ensino
          </Button>
        </PageFooter>
      </DirectoryContainer>

      {!!isResponseEmpty && (
        <ConfirmModal
          start={<Icon name="close-circle-outline" fill="#DB2C66" />}
          showModal={isResponseEmpty}
          closeCallback={/* () => setIsResponseEmpty(false) */ () => navigate(`/planejador-sprint/${classId}`)}
          title={`${errorMessage}`}
          textConfirmButton="Confirmar"
          confirmCallBack={/* () => setIsResponseEmpty(false) */ () => navigate(`/planejador-sprint/${classId}`)}
        >
          <div className="container-text-modal">
            <span className="span-text-modal">
              {`Na agenda da turma, ${
                typeof getCurrentSprintResponse == 'number' && getCurrentSprintResponse === 409
                  ? ' há mais de um evento do tipo Sprint.'
                  : 'não há nenhum evento do tipo Sprint.'
              }  Corrija a agenda de forma a ter apenas um evento desse tipo.`}
            </span>
          </div>
        </ConfirmModal>
      )}
    </Container>
  );
};
