import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Bar, BarChart, LabelList, YAxis, Text } from 'recharts';

import { Container } from './styles';

interface SimpleBarChartProps {
    data: any[];
    width?: number;
    height?: number;
}

const SimpleBarChart: React.FC<SimpleBarChartProps> = ({
    data,
    width,
    height = 150
}) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const [chartSize, setChartSize] = useState<{ width: number, height: number } | undefined>();

    const renderLabel = useCallback((props) => (
        <Text
            {...props}
            y={props.y - 12}
            x={props.x + 50}
            fill='#2E3A59'
            fontSize={12}
            fontWeight={800}
            textAnchor='middle'

        >
            {`${props.value}%`}
        </Text>
    ), []);

    useEffect(() => {
        setChartSize({
            width: width || Number(containerRef.current?.clientWidth),
            height: height || Number(containerRef.current?.clientHeight),
        });
    }, [containerRef, height, width])

    return (
        <Container ref={containerRef} >
            <BarChart
                data={data}
                margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 10,
                }}

                {...chartSize}
            >
                <YAxis hide />

                <Bar dataKey="percent" fill="#C7E2FF" barSize={100} >
                    <LabelList dataKey="percent" position="top" content={renderLabel} />
                </Bar>
            </BarChart>
            <div className="border" />
        </Container>
    );
}

export default SimpleBarChart;