import React, { useCallback, useMemo } from 'react';
import { IStudent } from '@modules/students/interfaces/IStudent';
import StudentList from '@modules/students/components/StudentsList';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

const OccurrenceStudentsRelationCreate: React.FC = () => {
  const { occurrenceCurrent, setOccurrenceCurrent } = useOccurrences();


  const setSelectedItens = useCallback(
    (students: IStudent[]) => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, students: students };
      });
    },
    [setOccurrenceCurrent]
  );

  const selectedItens = useMemo(() => {
    if (!occurrenceCurrent) return;
    return occurrenceCurrent.students;
  }, [occurrenceCurrent]);

  return (
    <StudentList
      headerTitle="Diretório de Alunos"
      btnText="Adicionar Aluno"
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelectStudent={'many'}
      handleOnClick={setSelectedItens}
    />
  );
};

export default OccurrenceStudentsRelationCreate;
