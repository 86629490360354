import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Label, Spinner } from 'react-alicerce-components';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { WorkGroupStudentsRelationCreateContainer } from './styles';
import { IGradeInfo, ListClassStudentsWithGradesService, StudentsWithGrades } from '../../services/ListClassStudentsWithGradesService';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import { calcAge } from '@shared/utils/calculateAge';
import { useWorkGroup } from '../../context/WorkGroupContext';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { IWorkGroup } from '../../interfaces/IWorkGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { convertHexToHsl } from '@shared/utils/convertHexToHsl';

const WorkGroupStudentsRelationCreate: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading, isLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { workGroupCurrent, setWorkGroupCurrent } = useWorkGroup();
  const [studentsFormattedInTable, setStudentsFormattedInTable] = useState<any[]>([]);

  const { classId } = useParams<{ classId: string }>();
  const [columns] = useState<IDynamicTableColumnsGroup[]>([
    {
      columns: [{ name: 'Alunos', dataname: 'name' }],
      fixed: true,
    },
    {
      columns: [
        { name: 'Idade', dataname: 'age' },
        { name: 'Leit.', dataname: 'leitura' },
        { name: 'Mat.', dataname: 'matematica' },
        { name: 'Reda.', dataname: 'redacao' },
        { name: 'Ing.', dataname: 'ingles' },
      ],
      fixed: false,
    },
    {
      columns: [{ name: 'Grupos', dataname: 'group', notCanSort: true }],
      fixed: false,
    },
  ]);

  const getBlockBackgroundColor = useCallback((swg: StudentsWithGrades, level: string) => {
    const foundBlock = swg.grade_info.find((grade) => grade.trail.name === level)?.block;
    const colorLevel = foundBlock?.level?.color || '#E4E9F2';
    const colorVariation = foundBlock?.color_variation;
    return `hsla${convertHexToHsl(colorLevel, colorVariation)}`;
  }, []);

  const findGradeInfo = useCallback((grandesInfo: IGradeInfo[], searchBy: string) => {
    const foundGradeInfo = grandesInfo.find((grade) => grade.trail.name === searchBy);
    if (!foundGradeInfo) return '';
    if (!foundGradeInfo.block) return '';
    return `(${foundGradeInfo.block.order}) ${foundGradeInfo.block.name}`;
  }, []);

  const groupName = useCallback((groups: IWorkGroup[] | undefined) => {
    if (!groups) return;
    return groups
      .map((group) => group.name)
      .toString()
      .replaceAll(',', ' | ');
  }, []);

  const listClassStudentsWithGrades = useCallback(async () => {
    if (!classId) return navigate('/planejamento-aula/contexto/turmas');
    setIsLoading(true);
    const res = await new ListClassStudentsWithGradesService(signedUser?.token, newCancelToken()).execute(classId);
    setIsLoading(false);
    if (!res || res === 'canceling') return;
    setStudentsFormattedInTable(
      res.map((swg: StudentsWithGrades) => {
        const isStudentAlreadyChecked = workGroupCurrent?.students?.find((st) => `${st.id}` === `${swg.student.id}`);

        return {
          id: swg.student.id,
          student: swg.student,
          name: `${swg.student.name}`,
          age: calcAge(swg.student.birthdate),
          selected: isStudentAlreadyChecked ? true : false,
          leitura: findGradeInfo(swg.grade_info, 'Leitura'),
          leituraBgColor: getBlockBackgroundColor(swg, 'Leitura'),
          matematica: findGradeInfo(swg.grade_info, 'Matemática'),
          matematicaBgColor: getBlockBackgroundColor(swg, 'Matemática'),
          redacao: findGradeInfo(swg.grade_info, 'Redação'),
          redacaoBgColor: getBlockBackgroundColor(swg, 'Redação'),
          ingles: findGradeInfo(swg.grade_info, 'Inglês'),
          inglesBgColor: getBlockBackgroundColor(swg, 'Inglês'),
          group: groupName(swg?.worked_groups),
        };
      })
    );
  }, [classId, findGradeInfo, getBlockBackgroundColor, groupName, navigate, newCancelToken, setIsLoading, signedUser, workGroupCurrent]);

  const handleSelectStudent = useCallback((data: any) => {
    setStudentsFormattedInTable((oldState) => {
      return oldState.map((fst) => {
        if (fst.id === data.id) return { ...fst, selected: !fst.selected };
        return { ...fst };
      });
    });
  }, []);

  const handleSelectAll = useCallback((checked: boolean) => {
    setStudentsFormattedInTable((oldState) => {
      return oldState.map((fst) => {
        return { ...fst, selected: checked };
      });
    });
  }, []);

  const handleOnSaveStudents = useCallback(() => {
    const selectedStudents = studentsFormattedInTable.flatMap((fst) => {
      if (!fst.selected) return [];
      return fst.student;
    });
    setWorkGroupCurrent((oldState: IWorkGroup) => {
      return { ...oldState, students: selectedStudents };
    });
    navigate(-1);
  }, [navigate, setWorkGroupCurrent, studentsFormattedInTable]);

  const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
    setStudentsFormattedInTable((oldState) => {
      const orderBy = sort.sortName as string;
      if (['name', 'age'].includes(orderBy)) {
        let aux = [...oldState];
        aux.sort((a, b) => ((sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1));
        return aux;
      }

      oldState.sort((a, b) => {
        // extrai a block order caso seja filtrado por bloco
        const regexToExtractNumberInParentheses = /\(([^)]*)\)/;
        const extractedBlockA = regexToExtractNumberInParentheses.exec(`${a[orderBy]}`);
        const extractedBlockB = regexToExtractNumberInParentheses.exec(`${b[orderBy]}`);

        if (extractedBlockA && extractedBlockB) return +extractedBlockA[1] >= +extractedBlockB[1] ? 1 : -1;

        return (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1;
      });

      return [...oldState];
    });
  }, []);

  useEffect(() => {
    listClassStudentsWithGrades();
  }, [listClassStudentsWithGrades]);

  return (
    <WorkGroupStudentsRelationCreateContainer className="work-group-students-relation-container">
      {isLoading && <Spinner />}
      <HeaderDirectory title="Adicionar Alunos" backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)" />
      <DirectoryContainer footer={true}>
        <div className="select-all-students-container">
          <Checkbox
            semiRound
            icon={RiCheckboxBlankCircleFill}
            hideIconUncheck
            name={'select-all'}
            id={'select-all '}
            onChange={(event) => handleSelectAll(event.target.checked)}
          />
          <Label>Selecionar Todos</Label>
        </div>

        <DynamicTable
          columnsGroup={columns}
          data={studentsFormattedInTable}
          handleClickRow={handleSelectStudent}
          handleClickSort={handleOnClickSort}
          canSelect
        />
        <PageFooter>
          <Button transform="none" status="quaternary" shaded onClick={handleOnSaveStudents} className="mochileiro-color">
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </WorkGroupStudentsRelationCreateContainer>
  );
};

export default WorkGroupStudentsRelationCreate;
