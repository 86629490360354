import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';

import { Button, Spinner, useToast } from 'react-alicerce-components';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { useUser } from '@modules/users/context/UserContext';

import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { useGlobal } from '@shared/contexts/global/GlobalContext';

import ConfirmModal from '@shared/components/ConfirmModal';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { formatUserToUpdate } from '@modules/users/utils/formatUserToUpdate';
import { lmsUserSchema } from '@modules/users/utils/lmsUserSchema';
import { IUser } from '@modules/users/interfaces/IUser';
import UpdateUserService from '@modules/users/services/UpdateUserService';
import { UserForm } from '../../components/UserForm';

const UserEdit: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { userCurrent, setUserCurrent, getUser } = useUser();
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const isMounted = useIsMounted();
  const params = useParams();
  const refPersonalMain = React.createRef<any>();
  const refPersonalAcademy = React.createRef<any>();

  const getUserFromContext = useCallback(async () => {
    if (!params['id']) return;
    const res = await getUser(params['id']);
    if (!res) navigate('/diretorio/usuarios');
  }, [getUser, navigate, params]);

  useEffect(() => {
    if (!userCurrent || params['id'] !== userCurrent.id) {
      getUserFromContext();
    }
  }, [getUserFromContext, params, userCurrent]);

  const handleCancel = useCallback(() => {
    setUserCurrent(undefined);
    navigate(`/usuario/${userCurrent?.id}/informacoes`);
  }, [navigate, setUserCurrent, userCurrent]);

  const updateAllHeigths = useCallback(() => {
    refPersonalMain.current.updateHeight();
    refPersonalAcademy.current.updateHeight();
  }, [refPersonalAcademy, refPersonalMain]);

  const handleEditButton = useCallback(async () => {
    if (!userCurrent) return;
    updateAllHeigths();
    const formatedBody = formatUserToUpdate(userCurrent);
    const canSubmitForm = await beforeSubmitForm(formatedBody, lmsUserSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowEditModal(true);
  }, [updateAllHeigths, userCurrent]);

  const handleUpdateUser = useCallback(async () => {
    if (!signedUser || !userCurrent?.id) return;
    setIsLoading(true);
    const formatedBody = formatUserToUpdate(userCurrent) as IUser;

    const res = await new UpdateUserService(signedUser.token).execute(userCurrent.id, formatedBody);
    if (isMounted() && res) {
      setIsLoading(false);
      addToast({
        status: 'success',
        title: 'Usuarios',
        description: 'Usuario editado com sucesso.',
      });
      return navigate(`/usuario/${userCurrent.id}/informacoes`);
    }

    setIsLoading(false);
    addToast({
      status: 'danger',
      title: 'Usuarios',
      description: 'Não foi possível editar o Usuario.',
    });
  }, [addToast, navigate, isMounted, setIsLoading, signedUser, userCurrent]);

  if (!userCurrent) return <Fragment />;

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory hideGoBack={true} title="Editar Usuário" status="secondary" onClickReturn={() => setUserCurrent(undefined)} />

      <DirectoryContainer footer={true}>
        <UserForm errors={errors} refPersonalAcademy={refPersonalAcademy} refPersonalMain={refPersonalMain} />

        <PageFooter>
          <Button transform="none" status="secondary" shaded outline onClick={handleCancel}>
            Cancelar
          </Button>
          <Button transform="none" status="secondary" shaded onClick={handleEditButton}>
            Salvar
          </Button>
        </PageFooter>

        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showEditModal}
          closeCallback={() => setShowEditModal(!showEditModal)}
          title="Deseja Salvar Alterações ?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={handleUpdateUser}
        />
      </DirectoryContainer>
    </Fragment>
  );
};

export default UserEdit;
