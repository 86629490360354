import React, { Fragment, useCallback } from 'react';
import { Button } from 'react-alicerce-components';
import PageFooter from '@shared/components/PageFooter';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { WorkGroupListComponent } from '../../components/WorkGroupListComponent';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useWorkGroup } from '../../context/WorkGroupContext';

const WorkGroupList: React.FC = () => {
  const navigate = useNavigate();
  const { setWorkGroupCurrent } = useWorkGroup();
  const { classId } = useParams<{ classId: string }>();

  const handleButtonCreate = useCallback(() => {
    setWorkGroupCurrent(undefined);
    navigate(`/grupo-de-trabalho/criar/turma/${classId}`);
  }, [classId, navigate, setWorkGroupCurrent]);

  return (
    <Fragment>
      <HeaderDirectory
        title={'Grupo de Trabalho'}
        pathToReturn={`/planejamento-aula/aulas/turma/${classId}`}
        backgroundHeader="linear-gradient(284.92deg,#B390DB 0%,#70C3FC 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        <WorkGroupListComponent />

        <PageFooter>
          <Button transform="none" status="primary" shaded onClick={handleButtonCreate} className="mochileiro-color">
            Criar Grupo de Trabalho
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Fragment>
  );
};

export default WorkGroupList;
