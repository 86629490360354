import React, { useState } from 'react';
import { AdvancedFilterContainer, FilterContainer } from './styles';
import { Button } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import Divider from '@shared/components/Divider';
import { uuid } from 'uuidv4';
import { DebounceInput } from '@shared/components/DebounceInput';

export interface IAdvancedFilterBaseProps {
  handleApplyFilter: () => void;
  handleResetFilter?: () => void;
  applyFilterButtonBackground: string;
  onChangeMainInput?: (value: string) => void;
  firstRenderOpenStatus?: boolean;
  disabled?: boolean;
}

const AdvancedFilter: React.FC<IAdvancedFilterBaseProps> = ({
  handleApplyFilter,
  handleResetFilter,
  applyFilterButtonBackground,
  children,
  firstRenderOpenStatus = false,
  onChangeMainInput,
  disabled = false,
}) => {
  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState<boolean>(firstRenderOpenStatus);
  const [render, setRender] = useState<string>(uuid());

  const handleFilterStatus = () => {
    if (disabled) return;
    setIsAdvancedFilterOpen((oldState: boolean) => !oldState);
  };

  const clickOnApplyCheckedFilters = () => {
    handleApplyFilter();
    setIsAdvancedFilterOpen(false);
  };

  const clickOnResetCheckedFilters = () => {
    handleResetFilter && handleResetFilter();
    setRender(uuid());
  };

  return (
    <AdvancedFilterContainer className="advanced-filter-structure-container">
      <DebounceInput
        onChange={(event) => onChangeMainInput && onChangeMainInput(event.target.value)}
        disabled={onChangeMainInput ? false : true}
        placeholder="Filtro Avançado"
        name="search"
        key={render}
        end={
          <div onClick={handleFilterStatus}>
            <Icon name="funnel" fill="#8F9BB3" />
          </div>
        }
      />
      <FilterContainer isOpen={isAdvancedFilterOpen} backgroundColorProp={applyFilterButtonBackground}>
        <div className="filter-text">
          <span>Filtrar por:</span>
          <label onClick={handleFilterStatus}>
            <Icon name="close" />
          </label>
        </div>

        <div className="advanced-filter-children" key={render}>
          {children}
        </div>

        <Divider width="100%" />

        <div className="button-add-filters">
          {handleResetFilter && (
            <Button fill={applyFilterButtonBackground} onClick={clickOnResetCheckedFilters} transform="none" noBorder shaded>
              Resetar Filtros
            </Button>
          )}
          <Button fill={applyFilterButtonBackground} onClick={clickOnApplyCheckedFilters} transform="none" noBorder shaded>
            Aplicar Filtros
          </Button>
        </div>
      </FilterContainer>
    </AdvancedFilterContainer>
  );
};

export { AdvancedFilter };
