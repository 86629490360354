import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';

import { objectToQuery } from '@shared/utils/objectToQuery';
import { IStudent } from '@modules/students/interfaces/IStudent';

export interface IListStudentApplicationFromClassServiceReponse extends IPaginatedResponse {
  items: IStudentApplication[];
}

export type IStudentApplication = IStudent & {
  applications_pending: number;
  applications_ongoing: number;
  applications_finalized: number;
}

class ListStudentApplicationFromClassService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    query: string,
    options?: IQueryOptions
  ): Promise<IListStudentApplicationFromClassServiceReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      const res = await this.api.get(`/student/applications?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListStudentApplicationFromClassService;
