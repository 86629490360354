import { useSelector } from "react-redux";
import { RootState } from "@shared/store";
import { IAuthState } from "./types";

function useAuth(): IAuthState {
    const context = useSelector((state: RootState) => (state.auth));

    if (!context) {
      throw new Error('useAuth must be used within a Provider');
    }
  
    return context;
  }
  
  export default useAuth;
  
