import React, { createContext, useCallback, useContext, useState } from 'react';
// import useAuth from "@shared/store/auth/hook";
// import { useGlobal } from "@shared/contexts/global/GlobalContext";

import IProject from '../interfaces/IProject';
import ISteering from '../interfaces/ISteering';
import IProjectReport from '../interfaces/IProjectReport';
import GetProjectService from '@modules/projects/services/Project/GetProjectService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import { IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import GetSteeringService from '../services/Steering/GetSteeringService';
import GetReportService from '../services/Report/GetReportService';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { IAttachment } from '@shared/interfaces/IAttachment';

export type IProjectsContextData = {
  clearProjectCreate: () => void;
  clearSteeringCreate: () => void;
  clearReportCreate: () => void;
  currentSprint?: ISprint;
  setCurrentSprint: (value: any) => void;
  setProjectCurrent: React.Dispatch<React.SetStateAction<IProject>>;
  setSteeringCurrent: React.Dispatch<React.SetStateAction<ISteering>>;
  setReportCurrent: React.Dispatch<React.SetStateAction<IProjectReport>>;
  setErros: React.Dispatch<React.SetStateAction<IInputErrorsFormat[]>>;
  getSteering: (steeringId) => Promise<boolean | void>;
  getProject: (projectId) => Promise<boolean | void>;
  getReport: (reportId) => Promise<boolean | void>;
  steeringCurrent?: ISteering;
  projectCurrent?: IProject;
  reportCurrent?: IProjectReport;
  errors: IInputErrorsFormat[];
};

const ProjectsContext = createContext<IProjectsContextData>({} as IProjectsContextData);

const ProjectsProvider: React.FC = ({ children }) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [projectCurrent, setProjectCurrent] = useState<IProject>({} as IProject);
  const [currentSprint, setCurrentSprint] = useState<ISprint>();
  const [steeringCurrent, setSteeringCurrent] = useState<ISteering>({} as ISteering);
  const [reportCurrent, setReportCurrent] = useState<IProjectReport>({} as IProjectReport);
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);

  const clearProjectCreate = () => {
    setProjectCurrent({} as IProject);
  };

  const clearSteeringCreate = () => {
    setSteeringCurrent({} as ISteering);
  };

  const clearReportCreate = () => {
    setReportCurrent({} as IProjectReport);
  };

  const getProject = useCallback(
    async (projectId: string): Promise<boolean | void> => {
      if (!signedUser) return false;
      if (projectCurrent && Number(projectCurrent.id) === Number(projectId)) return;
      setIsLoading(true);
      const res = await new GetProjectService(signedUser.token).execute(projectId);
      setIsLoading(false);
      if (!res) return false;
      setProjectCurrent(res);
      return true;
    },
    [projectCurrent, setIsLoading, signedUser]
  );

  const getSteering = useCallback(
    async (steeringId: string): Promise<boolean | void> => {
      if (!signedUser) return false;
      if (steeringCurrent && Number(steeringCurrent.id) === Number(steeringId)) return;
      setIsLoading(true);
      const res = await new GetSteeringService(signedUser.token).execute(steeringId);
      setIsLoading(false);
      if (!res) return false;
      setSteeringCurrent(res);
      return true;
    },
    [setIsLoading, signedUser, steeringCurrent]
  );

  const splitAttachmentsByType = (attachments: (File | IAttachment)[] | undefined) => {
    const img_attachments: (File | IAttachment)[] = [];
    const others_attachments: (File | IAttachment)[] = [];
  
    (attachments || []).forEach((attachment) => {
      if (attachment.type.includes('image')) {
        img_attachments.push(attachment);
      } else {
        others_attachments.push(attachment);
      }
    });
  
    return { img_attachments, others_attachments };
  };

  const getReport = useCallback(
    async (reportId: string): Promise<boolean | void> => {
      if (!signedUser) return false;
      if (reportCurrent && Number(reportCurrent.id) === Number(reportId)) return;
      setIsLoading(true);
      const res = await new GetReportService(signedUser.token).execute(reportId);
      setIsLoading(false);
      if (!res) return false;

      const { attachments, ...restOfReport } = res;
      const { img_attachments, others_attachments } = splitAttachmentsByType(attachments);

      setReportCurrent({
        ...restOfReport,
        img_attachments,
        others_attachments,
      });
      return true;
    },
    [reportCurrent, setIsLoading, signedUser]
  );

  return (
    <ProjectsContext.Provider
      value={{
        projectCurrent,
        steeringCurrent,
        reportCurrent,
        errors,
        getProject,
        getSteering,
        getReport,
        currentSprint,
        setCurrentSprint,
        clearSteeringCreate,
        clearProjectCreate,
        clearReportCreate,
        setProjectCurrent,
        setSteeringCurrent,
        setReportCurrent,
        setErros,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

function useProjects() {
  const context = useContext(ProjectsContext);

  if (!context) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }

  return context;
}

export { ProjectsProvider, useProjects };
