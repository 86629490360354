import React, { useCallback, useEffect, useRef } from 'react';
import FullCalendar, { EventApi, EventChangeArg, EventClickArg, EventMountArg, EventRemoveArg } from '@fullcalendar/react';
import interactionPlugin, { DropArg } from '@fullcalendar/interaction';
import { useNavigate, useParams } from 'react-router-dom';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FiSave } from 'react-icons/fi';

import ActivitiesTray from './ActivitiesTray';

import UpdatePlannedActivitiesService from '../../services/UpdatePlannedActivitiesService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { IActivityScheduleGrid, IPlannedActivityGrid, useScheduleGrid } from '../../context/ScheduleGridContext';
import { useToast } from 'react-alicerce-components';
import { Container } from './styles';
import useAuth from '@shared/store/auth/hook';
import { DateHelper } from '@shared/helpers/DateHelper';
import { PlannedActivityHelper } from '../../helpers/PlannedActivityHelper';
import { useActivity } from '../../../activities-library/context/ActivityContext';
import { isDailyClassEditDisable } from '../../utils/isDailyClassEditDisable';

const ScheduleGrid: React.FC = () => {
  const calendarRef = useRef<FullCalendar>(null);
  const navigate = useNavigate();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { setActivityLibraryCurrent } = useActivity();
  const { dailyClassId, classId } = useParams<{ classId: string; dailyClassId: string }>();
  const { setProblems, dailyClass, setCanSavePlannedClass, canSavePlannedClass, setPlannedActivities, plannedActivities } = useScheduleGrid();

  const handleRemoveEventFromCalendar = useCallback(
    (args: EventRemoveArg) => {
      let { planned_activity_data } = args.event.extendedProps;

      if (!planned_activity_data || !planned_activity_data.planned_activity_id)
        throw new Error('!planned_activity_data || !planned_activity_data.planned_activity_id');

      setPlannedActivities((oldState: IPlannedActivityGrid) => {
        let data = planned_activity_data as IActivityScheduleGrid;
        const inCalendar = oldState['in_calendar'];
        oldState['in_calendar'] = inCalendar.filter((pa) => pa.planned_activity_id !== data.planned_activity_id);

        const inTray = oldState['in_tray'];
        data.start_time = '';
        data.end_time = '';
        oldState['in_tray'] = [...inTray, data];
        return { ...oldState };
      });

      setCanSavePlannedClass(true);
    },
    [setCanSavePlannedClass, setPlannedActivities]
  );

  const handleRemoveEvent = useCallback((event: EventApi) => {
    calendarRef?.current?.getApi().getEventById(event.id)?.remove();
  }, []);
  const isSaveButtonDisable = isDailyClassEditDisable({ dailyClass });

  const handleEventDidMount = useCallback(
    (args: EventMountArg) => {
      if (isSaveButtonDisable) return;
      const span = document.createElement('span');
      span.innerHTML = 'X';
      span.classList.add('on-remove-event');
      span.addEventListener('click', () => handleRemoveEvent(args.event));
      args.el.append(span);
    },
    [handleRemoveEvent, isSaveButtonDisable]
  );

  const handleOnSaveActivity = useCallback(async () => {
    if (isSaveButtonDisable) {
      return addToast({
        status: 'danger',
        title: 'Planejador de Aula',
        description: 'Não faz sentido replanejar aulas que já ocorreram.',
      });
    }
    if (!dailyClassId || !canSavePlannedClass || !plannedActivities?.in_calendar) return;
    const formattedPlannedActivityBody = PlannedActivityHelper.formatUpdateDisket(plannedActivities?.in_calendar);

    const body = { daily_class_id: dailyClassId, planned_activities: formattedPlannedActivityBody };

    setIsLoading(true);
    const res = await new UpdatePlannedActivitiesService(signedUser?.token).execute(body);
    setIsLoading(false);

    if (!res)
      return addToast({
        status: 'danger',
        title: 'Planejador de Aula',
        description: 'Error ao salvar planejamento, tente novamente.',
      });

    setProblems(res.pending_problems);
    setCanSavePlannedClass(false);
  }, [
    addToast,
    canSavePlannedClass,
    dailyClassId,
    isSaveButtonDisable,
    plannedActivities,
    setCanSavePlannedClass,
    setIsLoading,
    setProblems,
    signedUser,
  ]);

  const handleOnClickEvent = useCallback(
    async (arg: EventClickArg) => {
      const planned_activity_data = arg.event.extendedProps.planned_activity_data;
      const clickedTarged = arg.jsEvent.target as Element;

      if (clickedTarged.className === 'on-remove-event') return;
      if (!isSaveButtonDisable) await handleOnSaveActivity(); // case click on X to delete
      setActivityLibraryCurrent(planned_activity_data.planned_activity.activity_data);
      navigate(
        `/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${planned_activity_data.activity_id}/informacoes/atividade-planejada/${planned_activity_data.planned_activity_id}`
      );
    },
    [classId, dailyClassId, handleOnSaveActivity, isSaveButtonDisable, navigate, setActivityLibraryCurrent]
  );

  const validateStartAndEnd = useCallback(
    (eventStartsAt: Date, eventEndsAt: Date) => {
      let startTime: string;
      const dailyClassStartsAt = new Date(`${dailyClass?.start_date}T${dailyClass?.start_time}`);
      if (eventStartsAt < dailyClassStartsAt) startTime = DateHelper.extractTime(dailyClassStartsAt);
      else startTime = DateHelper.extractTime(eventStartsAt);

      let endTime: string;
      const dailyClassEndsAt = new Date(`${dailyClass?.start_date}T${dailyClass?.end_time}`);
      if (eventEndsAt > dailyClassEndsAt) endTime = DateHelper.extractTime(dailyClassEndsAt);
      else endTime = DateHelper.extractTime(eventEndsAt);

      return { startTime, endTime };
    },
    [dailyClass]
  );

  const handleOnDrop = useCallback(
    (args: DropArg) => {
      const plannedActivity = JSON.parse(args.draggedEl.dataset.planned_activity_data as string);

      const suggestedDuration = plannedActivity.planned_activity.activity_data.duration;
      const durationInMinutes = DateHelper.extractMinutesFromTime(suggestedDuration);

      const eventStartsAt = args.date;
      const eventEndsAt = DateHelper.addMinutes(eventStartsAt, durationInMinutes);

      const validatedTime = validateStartAndEnd(eventStartsAt, eventEndsAt);

      setPlannedActivities((oldState: IPlannedActivityGrid) => {
        const inTray = oldState['in_tray'];
        oldState['in_tray'] = inTray.filter((ac) => ac.planned_activity_id !== plannedActivity.planned_activity_id);

        oldState['in_calendar'].push({
          ...plannedActivity,
          start_time: validatedTime.startTime,
          end_time: validatedTime.endTime,
        });

        return { ...oldState };
      });

      setCanSavePlannedClass(true);
    },

    [setCanSavePlannedClass, setPlannedActivities, validateStartAndEnd]
  );

  const handleOnChange = useCallback(
    (args: EventChangeArg) => {
      //Criado por bug de fullCalendar de conseguir colocar horarios menores e maiores do que permitido
      const eventChanged = calendarRef?.current?.getApi().getEventById(args.event.id);
      if (!eventChanged || !eventChanged.start || !eventChanged.end) throw new Error(`eventChanged is undefined`);

      const eventStartsAt = eventChanged.start;
      const eventEndsAt = eventChanged.end;

      const validatedTime = validateStartAndEnd(eventStartsAt, eventEndsAt);

      const { planned_activity_data } = eventChanged._def.extendedProps;

      setPlannedActivities((oldState: IPlannedActivityGrid) => {
        const foundIndex = oldState['in_calendar'].findIndex((ac) => ac.planned_activity_id === planned_activity_data.planned_activity_id);

        oldState['in_calendar'][foundIndex] = {
          ...planned_activity_data,
          start_time: validatedTime.startTime,
          end_time: validatedTime.endTime,
        };

        return { ...oldState };
      });

      setCanSavePlannedClass(true);
    },
    [setCanSavePlannedClass, setPlannedActivities, validateStartAndEnd]
  );

  useEffect(() => {
    calendarRef.current?.getApi().removeAllEvents();
    if (plannedActivities?.in_calendar) {
      plannedActivities.in_calendar.forEach((plannedActivity) => {
        const qtAlunos = plannedActivity.planned_activity.students?.length || 0;
        calendarRef.current?.getApi().addEvent({
          start: new Date(`${dailyClass?.start_date}T${plannedActivity.start_time}`),
          end: new Date(`${dailyClass?.start_date}T${plannedActivity.end_time}`),
          title: `${plannedActivity.name} | ${qtAlunos} Aluno${qtAlunos && qtAlunos > 1 ? 's' : ''} `,
          planned_activity_data: plannedActivity,
          id: `${plannedActivity.planned_activity_id}`,
        });
      });
    }
  }, [dailyClass, plannedActivities]);

  return (
    <Container>
      <div className={`save-container ${!isSaveButtonDisable && canSavePlannedClass ? '' : 'disabled'}`} onClick={handleOnSaveActivity}>
        <FiSave fill="white" />
      </div>

      <FullCalendar
        key={plannedActivities?.in_calendar.length}
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridDay"
        height="auto"
        allDaySlot={false}
        longPressDelay={0}
        eventLongPressDelay={0}
        selectLongPressDelay={0}
        drop={handleOnDrop}
        eventDidMount={handleEventDidMount}
        eventResizableFromStart
        eventRemove={(args) => handleRemoveEventFromCalendar(args)}
        droppable
        eventClick={(arg: EventClickArg) => handleOnClickEvent(arg)}
        selectable
        editable={!isSaveButtonDisable}
        initialDate={dailyClass?.start_date}
        slotMinTime={dailyClass?.start_time}
        slotMaxTime={dailyClass?.end_time}
        slotDuration={'00:05:00'}
        eventChange={(args) => handleOnChange(args)}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          meridiem: false,
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          meridiem: false,
        }}
      />
      <ActivitiesTray />
    </Container>
  );
};

export default ScheduleGrid;
