import styled from 'styled-components';

export const Container = styled.div`
  .select-container .select-option-text > span {
    text-align: center;
    margin: 0 15%;
  }
`;

export const HoursContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 5%;

  .input-container input {
    display: flex;
    width: 100%;
  }

  .error-class-period {
    position: relative;
    top: -20px;
    color: #c53030;
    font-weight: 600;
    grid-column: 1/3;
  }
`;
