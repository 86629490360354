import React from 'react';
import { Header, Text } from './styles';
import { Container } from '../BlockDescription/styles';

const InfoObservation: React.FC<any> = ({ description, title }) => {
  return (
    <Container>
      <Header>
        <h6>{title}</h6>
      </Header>
      <Text>{description}</Text>
    </Container>
  );
};

export { InfoObservation };
