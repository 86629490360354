import ButtonAdd from '@shared/components/ButtonAdd';
import React from 'react';
import { Input } from 'react-alicerce-components';
import { CSSProperties } from 'styled-components';
import InputErrorMessage from '../ErrorMessages/InputErrorMessage';
import { Container } from './styles';

export interface IParentRenderProps {
  handleOnClick?: () => void;
  label: string;
  hasParent: boolean;
  customStyles?: CSSProperties;
  error?: string;
  required?: boolean;
  placeholder?: string;
}

const ParentRender: React.FC<IParentRenderProps> = ({
  error,
  required = true,
  customStyles,
  children,
  handleOnClick,
  label,
  hasParent,
  placeholder,
}) => {
  return (
    <Container className="parent-render-container" required={required} style={{ ...customStyles }}>
      <div className="container-header">
        <label className="container-header-label">{label}</label>
        {handleOnClick && <ButtonAdd handleOnClick={handleOnClick} />}
      </div>
      {hasParent && children}
      {!hasParent && <Input onClick={handleOnClick} disabled={true} name="parent_id" placeholder={placeholder} />}
      {error && <InputErrorMessage error={error} />}
    </Container>
  );
};

export default ParentRender;
