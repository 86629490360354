import axios, { AxiosInstance, CancelTokenSource } from 'axios';


import ApiFactory from '@shared/factories/ApiFactory';
import { IUserRef } from '../context/UserContext';

class CreateUserService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(userBody: IUserRef): Promise<IUserRef | false> {
    try {
      const res = await this.api.post('/user', { ...userBody });
      return res.data as IUserRef;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default CreateUserService;
