import { IDynamicTableColumnsGroup } from "@shared/components/DynamicTable";
import { formatDate } from "react-alicerce-components";
import { IGroupFrenquencyHistory } from "../interfaces/IGroupFrenquencyHistory";
import { getYearsOld } from "./getYearsOld";

export interface IFormattedGroupFrequencyHistory {
  columns: IDynamicTableColumnsGroup[],
  data: any
}  

export const formatGroupFrequencyHistory = async (items: IGroupFrenquencyHistory[]): Promise<IFormattedGroupFrequencyHistory> => { 
  
  const presenceConfig = {
    presence: { name: 'Presente', bgColor: '#00D68F' },
    ausence: { name: 'Ausente', bgColor: '#FF3D71' },
    noPresence :{ name: 'Sem Presença', bgColor: '#C5CEE0' },
  };

  const columns = [
    {
      columns: [
          { name: 'Alunos', dataname: 'name' },
          { name: 'Idade', dataname: 'age' }
      ],
        fixed: true
    },
    {
      columns: [...items.map((d, i) => ({ name: `${formatDate(new Date(`${d.end_date}`), 'numeric')} ${d.start_time.substring(0, 5)} - ${d.end_time.substring(0, 5)}`, dataname: `date${i+1}`}))],
      fixed: false
  }
  ];

  let agrouped = {};
  await items.forEach((item, i) => {
    item?.attendances?.forEach((d) => {
      const prefix = `date${i+1}`;
      const presenceKey = d.presence ? 'presence' : (!d.presence && !item.attendance_given ? 'noPresence' : 'ausence');
      const id = `${d.student?.id}`;
      agrouped[id] = agrouped[id] ? {
        ...agrouped[id],
        [prefix]: presenceConfig[presenceKey].name,
        [`${prefix}BgColor`]: presenceConfig[presenceKey].bgColor,
      } :
      {
        id, 
        name: d?.student?.name,
        age: getYearsOld(`${d.student?.birthdate}`),
        [prefix]: presenceConfig[presenceKey].name,
        [`${prefix}BgColor`]: presenceConfig[presenceKey].bgColor,
        selected: false,
      }
    })
  });

  return {
    columns,
    data: Object.entries(agrouped).map(([_key, data]) => (data))
  }
}
