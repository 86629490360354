import * as Yup from 'yup';
import { SHIFT_CONSTRAINTS } from './validData/validShift';
import { WEEK_DAYS_CONSTRAINTS } from '@shared/utils/validData/validWeekdays';
import { validTime } from '@shared/utils/validData/validTime';

const WEEKDAYS = WEEK_DAYS_CONSTRAINTS.map((c) => c.value);
const SHIFT = SHIFT_CONSTRAINTS.map((c) => c.value);

export const calendarSchema = Yup.object().shape({
  place_id: Yup.string(),
  week_days: Yup.array()
    .of(Yup.string().oneOf(WEEKDAYS, { name: 'week_days', message: `Campo deve ser ${WEEKDAYS} ` }))
    .min(1, { name: 'week_days', message: 'É necessário marcar pelo menos uma opção' })
    .required({ name: 'week_days', message: `O campo é obrigatório` }),
  shift: Yup.string()
    .oneOf(SHIFT, { name: 'shift', message: `Campo deve ser ${SHIFT} ` })
    .optional()
    .nullable(),
  break: Yup.string().optional(),
  start: validTime('start'),
  end:validTime('end'),
});
