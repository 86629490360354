import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Area, AreaChart as AreaChartRecharts, LabelList, Tooltip, XAxis, YAxis, Text, XAxisProps } from 'recharts';

import Tick from '@shared/components/CustomRecharts/elements/Tick';

import { Container } from './styles';

export type AreaChartProps = {
  data: any[];
  width?: number;
  height?: number;
  dataKey?: string;
  simbolFormat?: string;
  xAxisPadding?: XAxisProps["padding"];
}

const AreaChart: React.FC<AreaChartProps> = ({
  data,
  width,
  simbolFormat,
  children,
  height = 200,
  dataKey = 'day',
  xAxisPadding = { left: 30, right: 30 }
}) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartSize, setChartSize] = useState<{ width: number, height: number } | undefined>();

  const renderLabel = useCallback((props) => (
    <Text
      {...props}
      y={props.y - 10}
      fontSize={12}
      fontWeight={800}
    >
      {`${props.value}%`}
    </Text>
  ), []);


  const renderYAxis = useCallback(({ x, y, payload }) => (
    <Tick
      y={y - 10}
      x={x - 10}
      fontSize={12}
      width={50}
      value={`${payload.value}${simbolFormat ? simbolFormat : ''}`}
      transform='rotate(-35)'
    />
  ), [simbolFormat]);

  useEffect(() => {
    setChartSize({
      width: width || Number(containerRef.current?.clientWidth),
      height: height || Number(containerRef.current?.clientHeight),
    });
  }, [containerRef, height, width])

  return (

    <Container ref={containerRef}>
      <AreaChartRecharts
        data={data}
        {...chartSize}
      >
        <XAxis
          padding={xAxisPadding}
          dataKey={dataKey}
          tick={renderYAxis}
          strokeDasharray="3 3"
        />
        <YAxis type="number" hide />
        <Area
          dataKey="percent"
          stroke="#2E3A59"
          fill="#C7E2FF"
          strokeWidth={2}
          type='step'
        >
          <LabelList dataKey="percent" position="top" content={renderLabel} />
        </Area>
        <Tooltip />

      </AreaChartRecharts>
      {children}
    </Container>
  );
};

export default AreaChart;
