import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';
import { IDateTimeRenderProps } from './index';

export const Container = styled.div<IDateTimeRenderProps>`
  margin-top: 1rem;
  display: flex;
  gap: 25px;
  align-items: center;

  .time-container {
    display: flex;
    flex: 1;
    background-color: ${({ status }) => themeGet(`colors.${status}300`)};
    padding: 1rem;
    border-radius: 4px;

    > div:first-child {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        display: flex;
        svg {
          width: 36px;
          height: 36px;
          fill: ${({ status }) => themeGet(`colors.${status}600`)};
        }
      }
    }

    > div:last-child {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .remove-card {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: red;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

export const Time = styled.div`
  font-size: 1rem;
  color: #2e3a59;
  color: ${({ color }) => color};
  font-weight: 800;

  .end {
    &:before {
      content: '-';
      margin: 0.4rem;
    }
  }

  .break {
    &:before {
      content: '-';
      margin: 0.4rem;
    }
  }
`;

export const Break = styled.div`
  font-size: 1rem;
  color: #2e3a59;
  color: ${({ color }) => color};
  font-weight: 800;

  .break {
    &:before {
      margin: 0.4rem;
    }
  }
`;
export const Date = styled.div`
  font-size: 12px;
  font-weight: 500;
`;
