import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import useAuth from '@shared/store/auth/hook';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Button, Pagination, Spinner, Checkbox, Label } from 'react-alicerce-components';
import { WrapperContent } from './styles';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router-dom';
import ListGroupsService, { IGroupReponse, IListGroupsOptions } from '@modules/groups/services/ListGroupsService';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useGroup } from '@modules/groups/context/GroupContext';
import GroupCard from '@modules/groups/components/GroupCard';
import { BsCheckLg } from 'react-icons/bs';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { GROUP_CATEGORY_CONSTRAINTS } from '../../utils/validData/validGroupCategory';
import { GROUP_STATUS_CONSTRAINTS } from '@modules/groups/utils/validData/validGroupStatus';

interface IGroupList {
  headerTitle: string;
  pathToReturn?: string;
  handleOnClick?: (selectedGroup: any) => void;
  canSelect?: 'one' | 'many';
  btnText: string;
  selectedGroups?: IGroup[];
  setSelectedGroups?: (group: IGroup[]) => void;
  pathRedirect?: string;
  pathStateOptions?: { state: any };
  isAdvancedFilterDisabled?: boolean;
  listOptions?: IListGroupsOptions;
}
const GroupList: React.FC<IGroupList> = ({
  headerTitle,
  pathToReturn,
  canSelect,
  btnText,
  selectedGroups,
  setSelectedGroups,
  handleOnClick,
  pathRedirect,
  pathStateOptions,
  isAdvancedFilterDisabled,
  listOptions = {},
}) => {
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const { setGroupCurrent, clearGroupContextData } = useGroup();
  const navigate = useNavigate();
  const [groupList, setGroupList] = useState<IGroupReponse>();
  const [inputSearch, setInputSearch] = useState<string>('');
  const groupFilter = useRef<IListGroupsOptions>(listOptions);

  const listGroups = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const groups = await new ListGroupsService(signedUser.token, newCancelToken()).execute(inputSearch, {
        page,
        size: 10,
        ...groupFilter.current,
      });
      if (groups === 'canceling') return;
      setIsLoading(false);
      if (!groups) return;
      setGroupList(groups);
    },
    [inputSearch, newCancelToken, setIsLoading, signedUser]
  );

  const checkSelectedGroups = useCallback((selectedItens: IGroup[], clickedItem: IGroup): false | IGroup[] => {
    const found = selectedItens.findIndex((p) => p.id === clickedItem.id);
    if (found === -1) return false;
    selectedItens.splice(found, 1);
    return selectedItens;
  }, []);

  const handleClickGroupCard = useCallback(
    (groupClicked: IGroup) => {
      if (!canSelect) {
        setGroupCurrent(groupClicked);
        navigate(`/grupo/${groupClicked.id}`, pathStateOptions);
      }
      if (canSelect === 'one' && selectedGroups && setSelectedGroups) {
        if (selectedGroups[0]?.id === groupClicked.id) return setSelectedGroups([]); // desmarca o grupo
        setSelectedGroups([groupClicked]);
      } else if (canSelect === 'many' && selectedGroups && setSelectedGroups) {
        const res = checkSelectedGroups(selectedGroups, groupClicked);
        if (!res) return setSelectedGroups([...selectedGroups, groupClicked]);
        setSelectedGroups([...res]);
      }
    },
    [canSelect, checkSelectedGroups, navigate, pathStateOptions, selectedGroups, setGroupCurrent, setSelectedGroups]
  );

  const handleClickBtn = useCallback(() => {
    if (canSelect === 'one' && selectedGroups) {
      handleOnClick && handleOnClick(selectedGroups[0]);
    } else if (canSelect === 'many' && selectedGroups) {
      handleOnClick && handleOnClick(selectedGroups);
    } else clearGroupContextData();
    pathRedirect ? navigate(pathRedirect, pathStateOptions) : navigate(-1);
  }, [canSelect, clearGroupContextData, handleOnClick, navigate, pathRedirect, pathStateOptions, selectedGroups]);

  useEffect(() => {
    listGroups();
  }, [listGroups]);

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objKey: string) => {
      const isChecked = event.target.checked;
      const value = event.target.id;

      if (isChecked) {
        if (!groupFilter.current[objKey]) {
          groupFilter.current[objKey] = [value];
          return;
        }

        groupFilter.current[objKey].push(value);
      } else {
        if (!groupFilter.current[objKey]) return;
        const filtered = groupFilter.current[objKey].filter((foo: string) => foo !== value);
        groupFilter.current[objKey] = filtered;
      }
    },
    [groupFilter]
  );

  const handleResetFilter = () => {
    groupFilter.current = {};
    listGroups();
  };

  const isChecked = (objKey: string, value: string) => {
    if (!groupFilter.current[objKey]) return false;
    return groupFilter.current[objKey].find((foo: string) => foo === value);
  };

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory
        status="tertiary"
        title={headerTitle}
        pathToReturn={pathToReturn}
        pathToReturnOptions={pathStateOptions}
        menuKebab={{ tooltip: { view: 'GroupDirectory' } }}
      />
      <DirectoryContainer footer={true}>
        <WrapperContent status="tertiary" justListing={!canSelect}>
          {/* START FILTER */}
          <AdvancedFilter
            onChangeMainInput={(newValue) => setInputSearch(newValue)}
            applyFilterButtonBackground="#34C5D0"
            handleResetFilter={handleResetFilter}
            handleApplyFilter={listGroups}
            disabled={isAdvancedFilterDisabled}
          >
            <Label>Status:</Label>
            <section className="checkbox-section">
              {GROUP_STATUS_CONSTRAINTS.map((pc) => (
                <Checkbox
                  key={`${pc.value}`}
                  id={pc.value}
                  name={pc.value}
                  label={pc.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'status');
                  }}
                  defaultChecked={isChecked('status', pc.value)}
                />
              ))}
            </section>

            <Label>Categoria:</Label>
            <section className="checkbox-section">
              {GROUP_CATEGORY_CONSTRAINTS.map((gc) => (
                <Checkbox
                  key={`${gc.value}`}
                  id={gc.value}
                  name={gc.value}
                  label={gc.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'categories');
                  }}
                  defaultChecked={isChecked('categories', gc.value)}
                />
              ))}
            </section>
          </AdvancedFilter>
          {/* END FILTER */}

          {!!groupList?.items.length && (
            <Fragment>
              {groupList?.items.map((g: IGroup) => (
                <GroupCard handleOnClick={handleClickGroupCard} canSelect={!!canSelect} groupsSelected={selectedGroups} group={g} key={g.id} />
              ))}

              <Pagination
                totalOfPages={`${groupList.totalPages}`}
                currentPage={`${groupList.currentPage}`}
                callbackGetListData={(_, page) => listGroups(page)}
                status="tertiary"
              />
            </Fragment>
          )}

          <PageFooter>
            <Button transform="none" status="tertiary" shaded onClick={handleClickBtn}>
              {btnText}
            </Button>
          </PageFooter>
        </WrapperContent>
      </DirectoryContainer>
    </Fragment>
  );
};
export default GroupList;
