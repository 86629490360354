import { DBOperations, CreateObjectStore } from '../actions';
import { IKey, IObjectStoreMeta } from '../interfaces';

export interface IIndexedDBProps {
    name: string;
    version: number;
    objectStoresMeta: IObjectStoreMeta[];
}

export interface IUseIndexedDB {
  dbName: string;
  version: number;
  objectStore: string;
}

let indexeddbConfiguration: { version: number; name: string } = { version: 1, name: '' };

export function initDB({ name, version, objectStoresMeta }: IIndexedDBProps) {
  indexeddbConfiguration.name = name;
  indexeddbConfiguration.version = version;
  // Object.freeze(indexeddbConfiguration);
  CreateObjectStore(name, version, objectStoresMeta);
}

export function useIndexedDB(
  objectStore: string
): {
  add: <T = any>(value: T, key?: any) => Promise<number>;
  getByID: <T = any>(id: number | string) => Promise<T>;
  getAll: <T = any>() => Promise<T[]>;
  update: <T = any>(value: T, key?: any) => Promise<any>;
  deleteRecord: (key: IKey) => Promise<any>;
  openCursor: (cursorCallback: (event: Event) => void, keyRange?: IDBKeyRange) => Promise<void>;
  getByIndex: (indexName: string, key: any) => Promise<any>;
  clear: () => Promise<any>;
} {
  if (!indexeddbConfiguration.name || !indexeddbConfiguration.version) {
    throw new Error('Please, initialize the DB before the use.');
  }
  return { ...DBOperations(indexeddbConfiguration.name, indexeddbConfiguration.version, objectStore) };
}
