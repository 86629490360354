import IRoute from "@shared/interfaces/IRoute";

import { combineContext } from "@shared/utils/combineContext";
import { IModuleRoutes } from "@shared/interfaces/IModuleRoutes";

import { ProjectsProvider } from "@modules/projects/context/ProjectsContext";
import { ProjectGroupRelation } from "@modules/projects/views/ProjectGroupRelation";

import ProjectCreate from "@modules/projects/views/ProjectCreate";
import ProjectInfo from "@modules/projects/views/ProjectInfo";
import ProjectHeadlights from "@modules/projects/views/ProjectHeadlights";
import ProjectClassRelationCreate from "@modules/projects/views/ProjectClassRelationCreate";
import ProjectClasses from "@modules/projects/views/ProjectClasses";
import ProjectHoursSpent from "@modules/projects/views/ProjectHoursSpent";
import SteeringCreate from "@modules/projects/views/SteeringCreate";
import SteeringInfo from "@modules/projects/views/SteeringInfo";
import SteeringEdit from "@modules/projects/views/SteeringEdit";

import { projectKeys } from "@modules/projects/keys";
import { groupsKeys } from "@modules/groups/keys";
import { classesKeys } from "@modules/classes/keys";
import ProjectReportCreate from "@modules/projects/views/ProjectReportCreate";
import { ProjectContractRelation } from "@modules/projects/views/ProjectContractRelation";
import ProjectDirectory from "@modules/projects/views/ProjectDirectory";
import ProjectUpdate from "@modules/projects/views/ProjectUpdate";
import HeadlightsLaunch from "@modules/projects/views/HeadlightsLaunch";
import ProjectHeadlightLaunch from "@modules/projects/views/ProjectHeadlightLaunch";
import { HeadlightsProvider } from "@modules/projects/components/Headlights/context/HeadlightsContext";
import HeadlightsApproval from "@modules/projects/views/HeadlightsApproval";
import ConsolidateHeadlightsForApproval from "@modules/projects/components/ConsolidateHeadlightsForApproval";
import ProjectHeadlightView from "@modules/projects/views/ProjectHeadlightView";
import ProjectHeadlightEdit from "@modules/projects/views/ProjectHeadlightEdit";
import SteeringParticipantAlicerceRelationCreate from "@modules/projects/views/SteeringParticipantAlicerceRelationCreate";
import ProjectReportInfo from "@modules/projects/views/ProjectReportInfo";
import ProjectReportEdit from "@modules/projects/views/ProjectReportEdit";
import ProjectReportsSteerings from "@modules/projects/views/ProjectReportsSteerings";

const routes: IRoute[] = [
  {
    path: "/diretorio/projetos",
    key: "CONTRACTS_PATH",
    exact: true,
    component: ProjectDirectory,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projeto/:id/informacoes",
    key: "INFO_PROJECT_PATH",
    exact: true,
    component: ProjectInfo,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projeto/:id/editar",
    key: "INFO_EDIT_PROJECT_PATH",
    exact: true,
    component: ProjectUpdate,
    permissions: [
      projectKeys.PROJECT_UPDATE,
    ]
  },
  {
    path: "/projeto/:id/turmas",
    key: "CLASSES_PROJECT_PATH",
    exact: true,
    component: ProjectClasses,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projeto/:id/farois",
    key: "HEADLIGHTS_PROJECT_PATH",
    exact: true,
    component: ProjectHeadlights,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projeto/:id/steerings",
    key: "STEERINGS_PROJECT_PATH",
    exact: true,
    component: ProjectReportsSteerings,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projeto/:id/lancar-farol",
    key: "HEADLIGHT_LAUNCH_PROJECT_PATH",
    exact: true,
    component: ProjectHeadlightLaunch,
    permissions: [
      projectKeys.PROJECT_READ,
      projectKeys.PROJECT_UPDATE,
    ]
  },
  {
    path: "/projeto/:id/horas-gastas",
    key: "HOURS_SPENT_PROJECT_PATH",
    exact: true,
    component: ProjectHoursSpent,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/diretorio/projetos/criar",
    key: "CREATE_PROJECTS_PATH",
    exact: true,
    component: ProjectCreate,
    permissions: [
      projectKeys.PROJECT_CREATE,
    ]
  },
  {
    path: "/projetos/lancar-farois",
    key: "HEADLIGHTS_LAUNCH_PATH",
    exact: true,
    component: HeadlightsLaunch,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projetos/aprovar-farois",
    key: "HEADLIGHTS_APPROVAL_PATH",
    exact: true,
    component: HeadlightsApproval,
    permissions: [
      projectKeys.PROJECT_READ,
      projectKeys.PROJECT_UPDATE,
    ]
  },
  {
    path: "/projetos/grupo/:id/aprovar-farois",
    key: "HEADLIGHTS_GROUP_APPROVAL_PATH",
    exact: true,
    component: ConsolidateHeadlightsForApproval,
    permissions: [
      projectKeys.PROJECT_READ,
      projectKeys.PROJECT_UPDATE,
    ]
  },
  {
    path: "/projetos/farol/:id",
    key: "HEADLIGHTS_GROUP_APPROVAL_PATH",
    exact: true,
    component: ProjectHeadlightView,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/projetos/farol/:id/editar",
    key: "HEADLIGHTS_GROUP_APPROVAL_PATH",
    exact: true,
    component: ProjectHeadlightEdit,
    permissions: [
      projectKeys.PROJECT_READ,
      projectKeys.PROJECT_UPDATE,
    ]
  },
  {
    path: "/diretorio/projetos/:id/steering/criar",
    key: "CREATE_STEERING_PATH",
    exact: true,
    component: SteeringCreate,
    permissions: [
      projectKeys.PROJECT_CREATE,
    ]
  },
  {
    path: "/diretorio/projetos/steering/:id",
    key: "INFO_STEERING_PATH",
    exact: true,
    component: SteeringInfo,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/diretorio/projetos/steering/:id/editar",
    key: "EDIT_STEERING_PATH",
    exact: true,
    component: SteeringEdit,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/diretorio/projetos/:id/report/criar",
    key: "CREATE_PROJECT_REPORT_PATH",
    exact: true,
    component: ProjectReportCreate,
    permissions: [
      projectKeys.PROJECT_CREATE,
    ]
  },
  {
    path: "/diretorio/projetos/report/:id",
    key: "INFO_PROJECT_REPORT_PATH",
    exact: true,
    component: ProjectReportInfo,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/diretorio/projetos/report/:id/editar",
    key: "EDIT_PROJECT_REPORT_PATH",
    exact: true,
    component: ProjectReportEdit,
    permissions: [
      projectKeys.PROJECT_READ,
    ]
  },
  {
    path: "/diretorio/projetos/criar/selecionar/grupo",
    key: "ADD_PROJECTS_GROUP_PATH",
    exact: true,
    component: ProjectGroupRelation,
    permissions: [
      groupsKeys.GROUP_READ,
    ]
  },
  {
    path: "/diretorio/projetos/criar/selecionar/contrato",
    key: "ADD_PROJECTS_CONTRACT_BUSINESS_PATH",
    exact: true,
    component: ProjectContractRelation,
    permissions: [
      groupsKeys.GROUP_READ,
    ]
  },
  {
    path: "/diretorio/projetos/criar/selecionar/turmas",
    key: "ADD_PROJECTS_CLASS_PATH",
    exact: true,
    component: ProjectClassRelationCreate,
    permissions: [
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: '/diretorio/projetos/steering/criar/selecionar/participante-alicerce',
    key: 'CREATE_STEERING_PARTICIPANT_ALICERCE_PATH',
    exact: true,
    component: SteeringParticipantAlicerceRelationCreate,
    permissions: [projectKeys.PROJECT_CREATE],
  },
];

const providers = [
  ProjectsProvider, HeadlightsProvider
];

export default {
  key: 'PROJECT_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
