import React, { useEffect } from 'react';
import { Button } from 'react-alicerce-components';
import { useLocationStudent } from '../../context/LocationStudentContext';
import Icon from 'react-eva-icons';

import { Container } from './styles';
import Modalv1 from '../../../Modalv1';
import { JustificationTargetForm } from '../JustificationTargetForm/JustificationTargetForm';

const EditField: React.FC = () => {
  const { isEdit, setIsEdit, getValuesToSave, currentLocationInGrid, setCurrentLocationInGrid, blockIndex, metaBlockValided } = useLocationStudent();

  useEffect(() => {
    setCurrentLocationInGrid && setCurrentLocationInGrid(blockIndex);
  }, [blockIndex, setCurrentLocationInGrid]);

  const currentBlockIndex = currentLocationInGrid?.index;

  const handleEditTarget = () => {
    if (currentLocationInGrid === undefined) {
      return setIsEdit && setIsEdit(!isEdit);
    }

    if (!isEdit) {
      setIsEdit(true);
      return;
    }

    if (!!isEdit) {
      setIsEdit(false);
      return;
    }

    return setIsEdit && setIsEdit(!isEdit);
  };

  const handleSaveValueWithJustification = (justificationMetaBlockChange?: string) => {
    if (currentLocationInGrid === undefined) {
      return setIsEdit && setIsEdit(!isEdit);
    }

    if (!isEdit && getValuesToSave) {
      getValuesToSave(justificationMetaBlockChange);
      setIsEdit(true);
      return;
    }

    return setIsEdit && setIsEdit(!isEdit);
  };

  const handleSaveValue = () => {
    if (currentLocationInGrid === undefined) {
      return setIsEdit && setIsEdit(!isEdit);
    }

    if (!isEdit && getValuesToSave) {
      getValuesToSave();
      setIsEdit(true);
      return;
    }

    return setIsEdit && setIsEdit(!isEdit);
  };

  const OpenModalIsPrevious = () => {
    if (blockIndex && currentBlockIndex > blockIndex) {
      return (
        <Modalv1>
          <Modalv1.Open opens="MODAL">
            <Button
              size="small"
              transform="none"
              rounded
              noBorder
              fill="linear-gradient(90deg, #482B85 23.44%, #6749BA 100%)"
              start={<Icon fill="red" name="save-outline" />}
            >
              Salvar Meta
            </Button>
          </Modalv1.Open>
          <Modalv1.Window name="MODAL">
            <JustificationTargetForm handleSave={handleSaveValueWithJustification} />
          </Modalv1.Window>
        </Modalv1>
      );
    }

    if (blockIndex && currentBlockIndex < blockIndex) {
      return (
        <Modalv1>
          <Modalv1.Open opens="MODAL">
            <Button
              size="small"
              transform="none"
              rounded
              fill="linear-gradient(90deg, #482B85 23.44%, #6749BA 100%)"
              start={<Icon fill="red" name="save-outline" />}
            >
              Salvar Meta
            </Button>
          </Modalv1.Open>
          <Modalv1.Window name="MODAL">
            <JustificationTargetForm isPreviousBlock handleSave={handleSaveValueWithJustification} />
          </Modalv1.Window>
        </Modalv1>
      );
    }

    return (
      <Button
        onClick={handleSaveValue}
        size="small"
        transform="none"
        rounded
        noBorder
        // disabled={currentLocationInGrid ? false : true}
        fill="linear-gradient(90deg, #482B85 23.44%, #6749BA 100%)"
        start={<Icon fill="red" name="save-outline" />}
      >
        Salvar Meta
      </Button>
    );
  };

  return (
    <Container isEdit={isEdit!}>
      {isEdit && (
        <div className="button-container">
          <Button
            className="button-edit"
            onClick={handleEditTarget}
            size="small"
            transform="none"
            noBorder
            fill="linear-gradient(90deg, #482B85 23.44%, #6749BA 100%)"
            start={<Icon name="edit-2-outline" />}
          >
            {metaBlockValided ? 'Editar Meta' : 'Iniciar Definição de Meta'}
          </Button>
        </div>
      )}
      {!isEdit && (
        <>
          <div className="button-container">
            <Button className="cancel-button" onClick={handleEditTarget} transform="none" size="small" start={<Icon name="close" fill="#482B85" />}>
              Cancelar
            </Button>
          </div>
          <div className="button-container">
            <OpenModalIsPrevious />
          </div>
        </>
      )}
    </Container>
  );
};

export { EditField };
