import { IStudent } from '../interfaces/IStudent';

export const formatStudent = (student: IStudent, action: 'creating' | 'editing') => {
  const formattedStudentForPicking = student.responsibles_for_picking?.map((responsibleForPicking) => {
    return { name: responsibleForPicking.name, cpf: responsibleForPicking.cpf };
  });

  const formatSubscriptions = () => {
    const subsWithoutEnrollment = student.subscriptions ? student.subscriptions.flatMap((sub) => (sub.id ? sub.id : [])) : undefined;
    const subsWithEnrollment = student.enrollments
      ? student.enrollments.flatMap((enr) => (enr.subscription?.id ? enr.subscription.id : []))
      : undefined;

    if (!subsWithoutEnrollment && !subsWithEnrollment) return undefined;

    let subscriptionsIds: string[] = [];
    if (subsWithoutEnrollment) subscriptionsIds.push(...subsWithoutEnrollment);
    if (subsWithEnrollment) subscriptionsIds.push(...subsWithEnrollment);

    return subscriptionsIds;
  };

  return {
    name: student.name,
    gender: student.gender,
    phone: student.phone ? student.phone : undefined,
    address_data: {
      address: student.address_data ? student.address_data.address : '',
      city: student.address_data ? student.address_data.city : '',
      uf: student.address_data ? student.address_data.uf : '',
    },
    birthdate: student.birthdate,
    literate_status: student.literate_status,
    age_group: student.age_group,
    school_grade: student.school_grade,
    start_date: student.start_date?.length ? student.start_date : null,
    relationship_type: student.relationship_type,
    partner_organizations_tag_ids: student.partner_organizations_tag ? student.partner_organizations_tag.map((tag) => tag.id) : [],
    tag_ids: student.student_tags ? student.student_tags.map((tag) => tag.id) : [],
    status: student.status,
    canceled_at: student.canceled_at?.length ? student.canceled_at : null,
    cancellation_reason: student.cancellation_reason,
    cancellation_details: student.cancellation_details,
    cancellation_type_detail: student.cancellation_type_detail,
    responsible: {
      name: student.responsible?.name,
      cpf: !student?.responsible?.cpf || student?.responsible?.cpf?.length === 0 ? null : student.responsible?.cpf,
      phone: student.responsible?.phone ? student.responsible?.phone : undefined,
      email: student.responsible?.email ? student.responsible?.email : undefined,
    },
    responsibles_for_picking: formattedStudentForPicking ? formattedStudentForPicking : undefined,
    primary_affiliation: student.primary_affiliation,
    class_ids: student.enrollments ? student.enrollments.map((enr) => enr.class.id) : undefined,
    subscription_ids: action === 'creating' ? undefined : formatSubscriptions(),
    transport_method: student.transport_method,
    school_type: student.school_type,
    school_organization: student.school_organization,
  };
};
