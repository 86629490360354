import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';
import ProjectReportForm from '@modules/projects/components/ProjectReportForm';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { formatReport } from '@modules/projects/utils/formatReport';
import { ProjectReportSchema } from '@modules/projects/schemas/ProjectReport';
import EditReportService from '@modules/projects/services/Report/EditReportService';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { splitAttachmentFiles } from '@shared/utils/splitAttachmentFiles';
import UploadService from '@shared/services/UploadFile';
import { formatedFileUpload } from '@modules/activities-library/utils/formatedFileUpload';

const ProjectReportEdit: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { clearReportCreate, reportCurrent, clearProjectCreate, getReport } = useProjects();
  const { id: reportId } = useParams();
  const dirPath = `/diretorio/projetos/report/${reportId}`;

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    clearReportCreate();
    navigate(dirPath);
  }, [clearReportCreate, dirPath, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!reportCurrent) return;
    const formatedBody = formatReport(reportCurrent, Number(reportCurrent.project_id)) as any;
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectReportSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [reportCurrent]);

  const handleEditReport = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !reportCurrent || !reportId) return;
    setIsLoading(true);
    const formatedBody = formatReport(reportCurrent, Number(reportCurrent.project_id));
    let formattedAttachmentsToSave: IAttachment[] = [];

    const { newFiles, oldFiles } = splitAttachmentFiles(formatedBody?.attachments);
    if (newFiles && newFiles.length > 0) {
      
      const res = await new UploadService(signedUser?.token).uploadFile(newFiles, 'report/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      const formattedAttachments = formatedFileUpload(newFiles);
      formattedAttachmentsToSave = formattedAttachments.map((attachment, i) => ({ ...attachment, url: res[i].dbURL }));
    }
    
    let auxFormattedBody = { ...formatedBody };
    if (formattedAttachmentsToSave.length > 0) auxFormattedBody.attachments = [...oldFiles, ...formattedAttachmentsToSave];
    const res = await new EditReportService(signedUser.token).execute(reportId, auxFormattedBody);
    setIsLoading(false);

    if (res) {
      clearProjectCreate();
      addToast({
        status: 'success',
        title: 'Report',
        description: 'Report atualizada com sucesso.',
      });
      clearReportCreate();
      return navigate(dirPath);
    }

    addToast({
      status: 'danger',
      title: 'Report',
      description: 'Não foi possível atualizar o Report.',
    });
  }, [addToast, clearProjectCreate, clearReportCreate, dirPath, navigate, reportCurrent, reportId, setIsLoading, signedUser]);

  useEffect(() => {
    getReport(reportId);
  }, [getReport, reportId]);

  return (
    <Fragment>
      <ProjectReportForm
        editing
        headerTitle="Editar Report"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Editar este Report?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleEditReport}
      />
    </Fragment>
  );
};

export default ProjectReportEdit;
