import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .text-status {
    font-size: 13px;
  }

  .text-status-bold {
    font-size: 13px;
    font-weight: 700;
  }
`;
