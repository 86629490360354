import { styled } from 'react-alicerce-components';

interface ICustomLineChartTooltipContainer {
    color: string;
}

export const Container = styled.div`
  padding: 40px 20px 20px;
`;

export const ContainerTrails = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;


export const CustomLineChartTooltipContainer = styled.div<ICustomLineChartTooltipContainer>`
    border: 1px solid ${({ color }) => color};
    background: #fff;
    padding: 4px;

    p {
        color: ${({ color }) => color};
    }
`;

export const Trail = styled.div<{ballColor: string}>`
    font-size: 10px;
    font-weight: 800;
    color: #222B45;
    position: relative;
    margin: 0 auto;

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        top: -2px;
        left: -24px;
        border-radius: 20px;
        position: absolute;
        background-color: ${({ballColor}) => ballColor };
    }
`;