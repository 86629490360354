import { ISelectOption } from 'react-alicerce-components';

//MOTIVOS DE DESENTURMAMENTO (Enturmamento incorreto e Troca de Turma)
export const UNENROLLMENT_REASON_WRONG_ENROLLMENT = { name: 'Enturmamento Incorreto', value: 'wrong_enrollment' }; //Enturmamento Incorreto
export const UNENROLLMENT_REASON_CHANGE_CLASS = { name: 'Troca de Turma', value: 'change_class' }; //Troca de Turma
export const UNENROLLMENT_REASON_DISCONNECT_TEMP = {name: 'Desligamento', value: 'disconnect'}; // Desligamento TEMPORARIO

export const UNENROLLMENT_REASON_CONSTRAINTS = [
  UNENROLLMENT_REASON_WRONG_ENROLLMENT,
  UNENROLLMENT_REASON_CHANGE_CLASS,
  UNENROLLMENT_REASON_DISCONNECT_TEMP
];


export const defaultUnenrollmentReasons = (unenrollmentStudentReason?: any): ISelectOption[] | undefined => {
  return UNENROLLMENT_REASON_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: unenrollmentStudentReason === foo.value };
  });
};
