import React, { ReactNode } from 'react';
import { DBOperations, openDatabase } from '../actions';
import { IKey, IObjectStoreMeta, IObjectStoreSchema } from '../interfaces';

interface IIndexedDBProps {
  name: string;
  version: number;
  children: ReactNode;
  objectStoresMeta: IObjectStoreMeta[];
}

const IndexedDBContext = React.createContext<{ db: any; name: string | null; version: number | null }>({
  db: null,
  name: null,
  version: null
});

const IndexedDBProvider = IndexedDBContext.Provider;
const IndexedDBCosumer = IndexedDBContext.Consumer;

export function IndexedDB({ name, version, children, objectStoresMeta }: IIndexedDBProps) {
  objectStoresMeta.forEach(async (schema: IObjectStoreMeta) => {
    await openDatabase(name, version, (event: any) => {
      let db: IDBDatabase = event.currentTarget.result;
      let objectStore = db.createObjectStore(schema.store, schema.storeConfig);
      schema.storeSchema.forEach((newSchema: IObjectStoreSchema) => {
        objectStore.createIndex(newSchema.name, newSchema.keypath, newSchema.options);
      });
    });
  });
  return <IndexedDBProvider value={{ db: null, name, version }}>{children}</IndexedDBProvider>;
}

interface AccessDBProps {
  children: ({
    db
  }: {
    db: IDBDatabase;
    add: <T = any>(value: T, key?: any) => Promise<number>;
    getByID: <T = any>(id: number | string) => Promise<T>;
    getAll: <T = any>() => Promise<T[]>;
    update: <T = any>(value: T, key?: any) => Promise<any>;
    deleteRecord: (key: IKey) => Promise<any>;
    openCursor: (cursorCallback: (event: Event) => void, keyRange?: IDBKeyRange) => Promise<void>;
    getByIndex: (indexName: string, key: any) => Promise<any>;
    clear: () => Promise<any>;
  }) => {};
  objectStore: string;
}

export function AccessDB({ children, objectStore }: AccessDBProps) {
  return (
    <IndexedDBCosumer>
      {value => {
        const { db, name, version } = value;
        openDatabase(name as string, version as number);
        return children({ db, ...DBOperations(name || '', version || 1, objectStore) });
      }}
    </IndexedDBCosumer>
  );
}
