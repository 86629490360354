type IntlDateOptions = 'full' | 'long' | 'medium' | 'short' | undefined
type FormatDateUtcToTimeZone = {
    date: string,
    dateStyle?: IntlDateOptions
    timeStyle?: IntlDateOptions
}

export const formatDateUtcToTimeZone = ({ date, dateStyle = 'short', timeStyle = 'short' }: FormatDateUtcToTimeZone) => {
    const dateUTC = new Date(date);
    return new Intl.DateTimeFormat("pt-BR", {
        timeZone: "America/Sao_Paulo",
        dateStyle: dateStyle,
        timeStyle: timeStyle
    }).format(dateUTC);
}