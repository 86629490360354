import IRoute from '@shared/interfaces/IRoute';

import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { combineContext } from '@shared/utils/combineContext';

import { WorkedHoursInfoApproved } from '@modules/worked-hours/views/WorkedHoursInfoApproved';
import { WorkedHoursInfoPending } from '@modules/worked-hours/views/WorkedHoursInfoPending';
import { WorkedHoursCreate } from '@modules/worked-hours/views/WorkedHoursCreate';
import { WorkedHoursApprove } from '@modules/worked-hours/views/WorkedHoursApprove';
import { WorkedHoursContextApprove } from '@modules/worked-hours/views/WorkedHoursContextApprove';

import { WorkedHoursInfoPendingDetails } from '../views/WorkedHoursInfoPendingDetails';
import { WorkedHoursInfoReprovedDetails } from '../views/WorkedHoursInfoReprovedDetails';
import { WorkedHoursGroupsRelation } from '@modules/worked-hours/views/WorkedHoursGroupsRelation';
import { WorkedHourProvider } from '@modules/worked-hours/context/WorkedHourContext';
import { WorkedHoursPendingApproval } from '@modules/worked-hours/views/WorkedHoursPendingApproval';
import { WorkedHoursInfoReproved } from '@modules/worked-hours/views/WorkedHoursInfoReproved';
import { WorkedHourAdvancedFilterTagRelation } from '@modules/worked-hours/views/WorkedHourAdvancedFilterTagRelation';
import { WorkedHourAdvancedFilterGroupRelation } from '@modules/worked-hours/views/WorkedHourAdvancedFilterGroupRelation';

import { workedHoursKeys } from '@modules/worked-hours/keys';
import { groupsKeys } from '@modules/groups/keys';
import { tagsKeys } from '@modules/tags/keys';
import { WorkedHoursApprovedDetails } from '../views/WorkedHoursApprovedDetails';
import { WorkedHoursClassesRelation } from '../views/WorkedHoursClassesRelation';
import { WorkedHoursApproved } from '../views/WorkedHoursApproved';
import { WorkedHoursGroupInfoApproved } from '../views/WorkedHoursGroupInfoApproved';
import { WorkedHoursInfoGroupReproved } from '../views/WorkedHoursInfoGroupReproved';
import { WorkedHoursReproved } from '../views/WorkedHoursReproved';
import { WorkedHoursCreateByGroup } from '@modules/worked-hours/views/WorkedHoursCreateByGroup';

const routes: IRoute[] = [
  {
    path: '/horas-trabalhadas/contexto/aprovar',
    key: 'WORKED_HOUR_CONTEXT_APPROVE_PATH',
    exact: true,
    component: WorkedHoursContextApprove,
    permissions: [groupsKeys.GROUP_READ],
  },
  {
    path: '/horas-trabalhadas/informacoes/aprovados',
    key: 'WORKED_HOUR_INFO_APPROVED_PATH',
    exact: true,
    component: WorkedHoursInfoApproved,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/informacoes/pendentes',
    key: 'WORKED_HOUR_INFO_PENDING_PATH',
    exact: true,
    component: WorkedHoursInfoPending,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/informacoes/reprovados',
    key: 'WORKED_HOUR_INFO_REPROVED_PATH',
    exact: true,
    component: WorkedHoursInfoReproved,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/criar',
    key: 'WORKED_HOUR_CREATE_PATH',
    exact: true,
    component: WorkedHoursCreate,
    permissions: [workedHoursKeys.WORKED_HOURS_CREATE],
  },
  {
    path: '/horas-trabalhadas/turma/:id/criar',
    key: 'WORKED_HOUR_CREATE_PATH',
    exact: true,
    component: WorkedHoursCreateByGroup,
    permissions: [workedHoursKeys.WORKED_HOURS_CREATE],
  },
  {
    path: '/horas-trabalhadas/criar/selecionar/grupos',
    key: 'WORKED_HOUR_ADD_GROUP_PATH',
    exact: true,
    component: WorkedHoursGroupsRelation,
    permissions: [groupsKeys.GROUP_READ],
  },
  {
    path: '/horas-trabalhadas/criar/selecionar/turmas',
    key: 'WORKED_HOUR_ADD_CLASS_PATH',
    exact: true,
    component: WorkedHoursClassesRelation,
    permissions: [groupsKeys.GROUP_READ],
  },
  {
    path: '/horas-trabalhadas/selecionar/tag/projeto',
    key: 'WORKED_HOUR_ADD_ADVANCED_FILTER_TAG_PATH',
    exact: true,
    component: WorkedHourAdvancedFilterTagRelation,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/horas-trabalhadas/selecionar/grupo',
    key: 'WORKED_HOUR_ADD_ADVANCED_FILTER_GROUP_PATH',
    exact: true,
    component: WorkedHourAdvancedFilterGroupRelation,
    permissions: [groupsKeys.GROUP_READ],
  },
  {
    path: '/horas-trabalhadas/:id/aprovar',
    key: 'WORKED_HOUR_APPROVE_PATH',
    exact: true,
    component: WorkedHoursApprove,
    permissions: [workedHoursKeys.WORKED_HOURS_READ, workedHoursKeys.WORKED_HOURS_UPDATE],
  },
  {
    path: '/horas-trabalhadas/:id/aprovada/visualizar',
    key: 'WORKED_HOUR_APPROVE_PATH',
    exact: true,
    component: WorkedHoursApproved,
    permissions: [workedHoursKeys.WORKED_HOURS_READ, workedHoursKeys.WORKED_HOURS_UPDATE],
  },
  {
    path: '/horas-trabalhadas/:id/reprovada/visualizar',
    key: 'WORKED_HOUR_REPROVED_PATH',
    exact: true,
    component: WorkedHoursReproved,
    permissions: [workedHoursKeys.WORKED_HOURS_READ, workedHoursKeys.WORKED_HOURS_UPDATE],
  },
  {
    path: '/horas-trabalhadas/grupo/:id/aprovar',
    key: 'WORKED_HOUR_CONTEXT_PENDING_PATH',
    exact: true,
    component: WorkedHoursPendingApproval,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/grupo/:id/aprovadas',
    key: 'WORKED_HOUR_CONTEXT_APPROVED_PATH',
    exact: true,
    component: WorkedHoursGroupInfoApproved,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/grupo/:id/reprovadas',
    key: 'WORKED_HOUR_CONTEXT_REPROVED_PATH',
    exact: true,
    component: WorkedHoursInfoGroupReproved,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/:id/aprovada',
    key: 'WORKED_HOUR_APPROVED_INFO_PATH',
    exact: true,
    component: WorkedHoursApprovedDetails,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/:id/aprovadas',
    key: 'WORKED_HOUR_APPROVEDS_INFO_PATH',
    exact: true,
    component: WorkedHoursApprove,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/:id/pendente',
    key: 'WORKED_HOUR_PENDING_INFO_PATH',
    exact: true,
    component: WorkedHoursInfoPendingDetails,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
  {
    path: '/horas-trabalhadas/:id/reprovada',
    key: 'WORKED_HOUR_REPROVED_INFO_PATH',
    exact: true,
    component: WorkedHoursInfoReprovedDetails,
    permissions: [workedHoursKeys.WORKED_HOURS_READ],
  },
];

const providers = [WorkedHourProvider];

export default {
  key: 'WORKED_HOURS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
