import React from 'react';

import { Container, ICustomFormProps } from './styles';

const CustomForm: React.FC<ICustomFormProps> = ({
    children,
    status = 'basic',
    customInputColorText = 'var(--black)',
    className,
    ...rest
}) =>
(
    <Container
        className={`custom-form ${className || ''}`}
        customInputColorText={customInputColorText}
        status={status} {...rest}
    >
        {children}
    </Container>

)

export default CustomForm;