import { IDynamicTableColumnsGroup } from '@shared/components/DynamicTable';
import { IGroupLearningHistory } from '../interfaces/IGroupLearningHistory';
import { getYearsOld } from './getYearsOld';

export interface IFormattedGroupLearningHistory {
  columns: IDynamicTableColumnsGroup[];
  data: any;
}

export const formatGroupLearningHistory = (items: IGroupLearningHistory[]): IFormattedGroupLearningHistory => {
  const bgColorConfig = {
    '-1': '#FF3D71', // VERMELHO - REGRESSÃO
    '0': '#C5CEE0', // CINZA - SEM PROGRESSÃO
    '1': '#00D68F', // VERDE - PROGRESSÃO
  };

  let weekCount = 0;
  items.forEach((d) => (weekCount = d.applications && d.applications?.length > weekCount ? d.applications?.length /* - 1 */ : weekCount));

  const columns = [
    {
      columns: [
        { name: 'Alunos', dataname: 'name' },
        { name: 'Idade', dataname: 'age' },
      ],
      fixed: true,
    },
    {
      columns: new Array(weekCount).fill('Semana').map((week, i) => ({ name: `${week} ${i + 1}`, dataname: `week${i + 1}` })),
      fixed: false,
    },
  ];

  const data = items.map((d, i) => {
    const student = {
      id: d?.student?.id,
      name: d?.student?.name,
      age: getYearsOld(`${d.student?.birthdate}`),
    };
    //const weeks =  d?.applications && d?.applications?.length > 0 ? {...d?.applications.reduce((a, v, j) => ({...a, [`week${j}`]: v.block, [`week${j}BgColor`]: bgColorConfig[v?.block_color || '0']}))} : {};
    const weeks =
      d?.applications && d?.applications?.length > 0
        ? d?.applications.map((a, i) => ({ ...a, [`week${i + 1}`]: a.block, [`week${i + 1}BgColor`]: bgColorConfig[a?.block_color || '0'] }))
        : [];

    const studentOrganized = Object.assign({ ...student }, ...weeks);
    return studentOrganized;
  });

  return {
    columns,
    data,
  };
};
