import React, { Fragment, useCallback, useRef, MouseEvent } from 'react';

import { Label } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import { ElementSize } from 'react-alicerce-components/dist/shared/theme/theme';

import Tag from '@modules/tags/components/Tag';
import ITag from '@modules/tags/interfaces/ITag';

import { ContainerList, ContainerTagsRender } from './styles';
import ButtonAdd from '@shared/components/ButtonAdd';
import { useNavigate } from 'react-router-dom';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import { CSSProperties } from 'styled-components';

export interface ITagsRenderProps {
  tags?: Array<ITag>;
  status?: ElementStatus;
  size?: ElementSize;
  label?: string;
  tagCallback?: (indexTag: number) => void;
  canRemoveTag?: boolean;
  pathToRedirect?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  customStyles?: CSSProperties;
  required?: boolean;
}

const TagsRender: React.FC<ITagsRenderProps> = ({
  children,
  tags,
  status = 'basic',
  size = 'small',
  label = '',
  className = '',
  tagCallback,
  pathToRedirect,
  canRemoveTag = false,
  error,
  placeholder = '',
  customStyles,
  required,
}) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOut = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      let current = containerRef?.current;
      const node: Node = e.target as Node;
      if (current && current === node) {
        pathToRedirect && navigate(pathToRedirect);
      }
    },
    [navigate, pathToRedirect]
  );

  return (
    <ContainerTagsRender required={required}>
      <div className={className} style={{ marginTop: '1.5rem', ...customStyles }}>
        <div className="label-container">
          <Label>{label}</Label>
          {pathToRedirect && <ButtonAdd handleOnClick={() => navigate(pathToRedirect)} />}
        </div>

        <ContainerList status={status} canRemoveTag={canRemoveTag} ref={containerRef} onClick={handleClickOut}>
          {!!!tags?.length && <span className="input-pill-placeholder">{placeholder}</span>}
          {tags?.map((tag, i) =>
            tag ? (
              <Tag key={`${tag.id}-${i}`} tag={tag} size={size} handleOnClick={() => tagCallback && tagCallback(i)} canRemoveTag={canRemoveTag} />
            ) : (
              <Fragment key={i} />
            )
          )}
          {children}
        </ContainerList>
      </div>
      {error && <InputErrorMessage error={error} />}
    </ContainerTagsRender>
  );
};

export default TagsRender;
