import {  styled } from 'react-alicerce-components';

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;

  position: relative;
  .custom-card > div {
    border-radius: 1.2rem;
    box-shadow: 0px 4px 8px 0px #0000001A;
  }

  .card-header {
    padding: 1rem;
    background-color: #BE3A3A;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    
    h6 {
      font-size:20px;
      color: #fff;
    }
  }

  .card-body {
    padding: 0;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #F7F9FC;

    .accordion-info-item-container {
      margin: 0;

      div {
        border-radius: 0;
      }

      .accordion-title {
        font-size: 1rem;
      }

      .accordion-content {
        background-color: #F3C8C8;
        padding: 1rem;
        font-size: 1rem;
        text-align: justify;
      }
    }
  }
`;

export const HeadlightContainer = styled.div`
  width: 100%;
  padding: 1rem;
  text-align: left !important;

  span {
    color: #c53030;
    font-size: 12px;
    font-weight: 600;
  }
`;