import React, { useCallback, useEffect, useMemo } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-alicerce-components';

import ReactGA from 'react-ga';

import dbconfig from '@config/DBConfig';
import { initDB } from '@shared/infra/db/indexed-db';
import AppRouter from '@shared/infra/http/routes';
import AllActions from '@shared/store/allactions';
import LocalStorageService from '@shared/services/LocalStorageService';
import useAuth from '@shared/store/auth/hook';
import useGlobal from '@shared/store/global/hook';
import RouteChangeTracker from '@shared/infra/http/routes/RouteChangeTracker';

import { AppProvider } from './partials';
import { IAuthState } from '@shared/store/auth/types';

export const STORAGE_KEY = '@ALICERCE_LMS/AUTH';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);

const App: React.FC = () => {
  const { loading, initialized } = useGlobal();
  const dispatch = useDispatch();
  const authState = useAuth();

  const initIndexedDb = useCallback((userId?: string | number) => {
    if (!userId) return;
    initDB(dbconfig(`lms-db-${userId}`));
  }, []);

  const recoverUserData = useCallback(() => {
    try {
      let storedState = LocalStorageService.recover(STORAGE_KEY) as IAuthState | undefined;

      if (!storedState || !storedState.signedUser) {
        dispatch({ type: AllActions.auth.LOGOUT_SIGNED_USER });
      } else {
        initIndexedDb(storedState.signedUser?.id);
        dispatch({ type: AllActions.auth.SET_AUTH_STATE, payload: storedState });
      }
    } catch (error: any) {
      dispatch({ type: AllActions.auth.LOGOUT_SIGNED_USER });
    }
  }, [dispatch, initIndexedDb]);

  const updateUserData = useCallback(() => {
    LocalStorageService.create(STORAGE_KEY, authState);
    initIndexedDb(authState?.signedUser?.id);
    dispatch({ type: AllActions.global.SET_GLOBAL_STATE, payload: { loading: false, initialized: true } });
  }, [authState, dispatch, initIndexedDb]);

  useEffect(() => recoverUserData(), [recoverUserData]);
  useEffect(() => updateUserData(), [updateUserData]);

  const render = useMemo(() => {
    const isInitialized = !loading && initialized;
    if (!isInitialized) return <Spinner />;

    return (
      <Router>
        <main>
          <div className="content">
            <RouteChangeTracker />
            <AppRouter />
          </div>
        </main>
      </Router>
    );
  }, [loading, initialized]);

  return <AppProvider>{render}</AppProvider>;
};

export default App;
