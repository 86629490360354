import IRoute from '@shared/interfaces/IRoute';

import StudentInfo from '@modules/students/views/StudentInfo';
import StudentHistory from '@modules/students/views/StudentHistory';
import StudentReport from '@modules/students/views/StudentReport';
import StudentDirectory from '@modules/students/views/StudentDirectory';
import StudentEdit from '@modules/students/views/StudentEdit';
import StudentTagProjectRelation from '@modules/students/views/StudentTagProjectRelation';
import StudentTagSystemRelation from '@modules/students/views/StudentTagSystemRelation';
import StudentCreate from '@modules/students/views/StudentCreate';
import { StudentProvider } from '@modules/students/context/StudentContext';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { studentsKeys } from '@modules/students/keys';
import { tagsKeys } from '@modules/tags/keys';
import { groupsKeys } from '../../groups/keys';
import { StudentGroupsRelation } from '../views/StudentGroupsRelation';
import { classesKeys } from '../../classes/keys';
import { StudentClassesRelation } from '../views/StudentClassesRelation';


const routes: IRoute[] = [
  {
    path: 'diretorio/alunos',
    key: 'DIR_STUDENT_PATH',
    exact: true,
    component: StudentDirectory,
    permissions: [
      studentsKeys.STUDENTS_READ
    ]
  },
  {
    path: 'diretorio/aluno/criar',
    key: 'STUDENT_CREATE_PATH',
    exact: true,
    component: StudentCreate,
    permissions: [
      studentsKeys.STUDENTS_CREATE
    ]
  },
  {
    path: 'aluno/:id/informacoes',
    key: 'STUDENT_PREVIEW_INFO',
    exact: true,
    component: StudentInfo,
    permissions: [
      studentsKeys.STUDENTS_READ
    ]
  },
  {
    path: "diretorio/alunos/selecionar/onboarding/grupo",
    key: 'STUDENT_GROUP_Onboarding',
    exact: true,
    component: StudentGroupsRelation,
    permissions: [
      groupsKeys.GROUP_READ
    ]
  },
  {
    path: "diretorio/alunos/selecionar/turma",
    key: 'STUDENT_GROUP_CLASSES',
    exact: true,
    component: StudentClassesRelation,
    permissions: [
      classesKeys.CLASSES_READ
    ]
  },
  {
    path: 'aluno/:id/relatorios',
    key: 'STUDENT_PREVIEW_REPORT',
    exact: true,
    component: StudentReport, // TO DO
    permissions: 'public'
  },
  {
    path: 'aluno/:id/historico',
    key: 'STUDENT_PREVIEW_HISTORIC',
    exact: true,
    component: StudentHistory, // TO DO
    permissions: 'public'
  },
  {
    path: 'aluno/:id/editar',
    key: 'REPORT_STUDENT_PATH',
    exact: true,
    component: StudentEdit,
    permissions: [
      studentsKeys.STUDENTS_READ,
      studentsKeys.STUDENTS_UPDATE,
      studentsKeys.STUDENTS_DELETE
    ]
  },
  {
    path: 'diretorio/alunos/selecionar/tags/sistema',
    key: 'DIRECTORY_STUDENTS_SELECT_TAGS_SYSTEM_SELECT_PATH',
    exact: true,
    component: StudentTagSystemRelation,
    permissions: [
      tagsKeys.TAGS_READ
    ]
  },
  {
    path: 'diretorio/alunos/selecionar/tags/projeto',
    key: 'DIRECTORY_STUDENTS_SELECT_TAGS_PROJECT_PATH',
    exact: true,
    component: StudentTagProjectRelation,
    permissions: [
      tagsKeys.TAGS_READ
    ]
  },
];

const providers = [StudentProvider];

export default {
  key: 'STUDENTS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
