import { Button, Checkbox, P, Pagination, Spinner } from 'react-alicerce-components';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import useAuth from '@shared/store/auth/hook';
import { BsCheckLg } from 'react-icons/bs';
import { Container } from './styles';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import { ISkillsReponse, ListSkillsService } from '@modules/activities-library/services/ListSkillsService';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { FilterSkills } from '@modules/activities-library/components/FilterSkills';
import { SkillCard } from '@modules/activities-library/components/SkillCard';
import PageFooter from '@shared/components/PageFooter';
import { ISkill } from '@shared/interfaces/ISkill';

export interface IFilterSkills {
  trail_id: string | undefined;
  level_id: string | undefined;
  block_id: string | undefined;
  query: string | undefined;
}

interface IActivityLibrarySkills {
  handleSkillsSelected?: (skills: ISkill[]) => void;
  selectedSkills?: ISkill[];
  pathToReturn?: string;
  pathToReturnOptions?: { state: Object };
  buttonText: string;
  buttonCallback: () => void;
}

export const SkillsList: React.FC<IActivityLibrarySkills> = (props) => {
  const { isLoading, setIsLoading } = useGlobal();
  const { activityLibraryCurrent } = useActivity();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const [listedSkills, setListedSkills] = useState<ISkillsReponse>();
  const [selectedSkills, setSelectedSkills] = useState<ISkill[]>();

  const [filterSkills, setFilterSkills] = useState<IFilterSkills>({
    trail_id: undefined,
    level_id: undefined,
    block_id: undefined,
    query: undefined,
  });

  const listSkills = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListSkillsService(signedUser.token, newCancelToken()).execute(search, { page, size: 10, ...filterSkills });
      setIsLoading(false);
      if (!res || res === 'canceling') return;
      setListedSkills(res);
    },
    [filterSkills, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listSkills();
  }, [listSkills]);

  const handleOnChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, sub: ISkill) => {
      const checked: boolean = event.target.checked;

      const skills: ISkill[] = selectedSkills ? [...selectedSkills] : [];

      if (!checked) {
        let filtred = selectedSkills?.filter((s) => String(s.id) !== sub.id);
        setSelectedSkills(filtred);
      } else {
        skills.push(sub);
        setSelectedSkills(skills);
      }
    },
    [selectedSkills]
  );

  const handleConfirmAdd = useCallback(() => {
    props.handleSkillsSelected && props.handleSkillsSelected(selectedSkills || []);
    props.buttonCallback();
  }, [props, selectedSkills]);

  useEffect(() => {
    setSelectedSkills(props.selectedSkills);
  }, [activityLibraryCurrent, props.selectedSkills]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Competências"
        pathToReturn={props.pathToReturn}
        pathToReturnOptions={props.pathToReturnOptions}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
      />
      <DirectoryContainer footer={true}>
        <FilterSkills appliedFilters={filterSkills} callbackAppliedFilters={setFilterSkills} />

        <div className="container-all-skills">
          {listedSkills?.items.map((s) => (
            <div key={s.id} className="container-skill-checkbox">
              <SkillCard skill={s} />
              <Checkbox
                key={`${s.id}-${activityLibraryCurrent.skills}`}
                name={`${s.id}`}
                id={`${s.id}`}
                semiRound
                icon={BsCheckLg}
                hideIconUncheck
                onChange={(event) => handleOnChangeCheckbox(event, s)}
                defaultChecked={!!selectedSkills?.find((cs) => cs.id === s.id)}
              />
            </div>
          ))}

          {!listedSkills?.items.length && <P textAlign='center' marginTop='1.5rem'>Nenhum dado para exibir</P>}
        </div>

        {!!listedSkills?.items.length && (
          <Pagination totalOfPages={listedSkills.totalPages} currentPage={listedSkills.currentPage} callbackGetListData={listSkills} />
        )}

        <PageFooter>
          <Button transform="none" noBorder shaded onClick={handleConfirmAdd}>
            {props.buttonText}
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};
