import { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IIBulkResponse } from '@shared/interfaces/IBulkResponse';

export class CreateEnrollmentService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(body: any): Promise<IIBulkResponse | false> {
    try {
      const res = await this.api.post(`/enrollment/bulk`, { enrollments: body });
      return res.data;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}
