import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Spinner, Tab, Tabs, useToast } from 'react-alicerce-components';
import { useLocation } from 'react-router-dom';

import HeaderModule from '@shared/components/Headers/HeaderModule';
import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import { Container } from './styles';
import { useGroup } from '@modules/groups/context/GroupContext';
import useAuth from '@shared/store/auth/hook';
import GetGroupService from '@modules/groups/services/GetGroupService';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ToggleFavoritedGroupService from '@modules/groups/services/ToggleFavoritedGroupService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import ToggleFixedGroupService from '@modules/groups/services/ToggleFixedGroupService';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ footer, openTab = 0, children }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const { groupCurrent, setGroupCurrent } = useGroup();
  const { setIsLoading, isLoading } = useGlobal();
  const groupIdInPath = useParams<{ id: string }>();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const isMounted = useIsMounted();
  const { state: stateLocation } = useLocation() as { state: { pathToReturn: string } };

  const getGroup = useCallback(async () => {
    if (!groupIdInPath.id) return navigate('/meus-grupos');
    setIsLoading(true);
    const groupFound = await new GetGroupService(signedUser?.token, newCancelToken()).execute(groupIdInPath.id);
    setIsLoading(false);
    if (!groupFound) return navigate('/meus-grupos');
    setGroupCurrent({ ...groupFound, lms_users: groupFound.admins });
  }, [groupIdInPath.id, navigate, newCancelToken, setGroupCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    if (!groupCurrent.id || !groupCurrent.admins || groupIdInPath.id !== groupCurrent.id) getGroup();
  }, [getGroup, groupCurrent.admins, groupCurrent.id, groupIdInPath]);

  const toggleFavorite = useCallback(async () => {
    if (!groupIdInPath.id) return;
    setIsLoading(true);
    const res = await new ToggleFavoritedGroupService(signedUser?.token).execute(groupIdInPath.id);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Grupos',
        description: 'Falha ao favoritar o grupo.',
      });
      return;
    }
    setGroupCurrent((oldState: any) => {
      return { ...oldState, favorited: res.favorited };
    });
    if (!isMounted) return;
  }, [addToast, groupIdInPath.id, isMounted, setGroupCurrent, setIsLoading, signedUser]);

  const toggleFixed = useCallback(async () => {
    if (!groupIdInPath.id) return;
    setIsLoading(true);
    const res = await new ToggleFixedGroupService(signedUser?.token).execute(groupIdInPath.id);
    setIsLoading(false);
    if (!isMounted) return;
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Grupos',
        description: 'Falha ao fixar o grupo.',
      });
      return;
    }
    setGroupCurrent((oldState: any) => {
      return { ...oldState, fixed: res.fixed };
    });
  }, [addToast, groupIdInPath.id, isMounted, setGroupCurrent, setIsLoading, signedUser]);

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  const handleGoBack = useCallback(() => {
    const returnPathFromLocationState = stateLocation && (stateLocation.pathToReturn as string | undefined);
    navigate(returnPathFromLocationState ? returnPathFromLocationState : '/diretorio/grupos');
  }, [navigate, stateLocation]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderModule
        title="Grupo"
        subtitle={groupCurrent.name ? groupCurrent.name : 'Carregando...'}
        additionalInfo={`${groupCurrent.id ? groupCurrent.id : 'Carregando...'}`}
        status="secondary"
        bgHeaderColor={'linear-gradient(105.34deg, #2AB1C8 1.9%, #0097B1 98.48%)'}
        useDefaultNav
        goBackCallback={handleGoBack}
        titleColor="#002E54"
        key={`${groupCurrent.favorited}`}
        isScrolled={isScrolled}
        compressOnScroll
        menuKebab={{
          favorited: { favoritedCallback: toggleFavorite, isFavorited: groupCurrent.favorited || false },
          attached: { attachedCallback: toggleFixed, isAttached: groupCurrent.fixed || false },
          tooltip: { view: 'GroupHome' },
          menuBackHome: true,
        }}
      />
      <CustomTabs footer={footer} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
        <Tabs openTab={openTab} content={children} horizontalScroll key={groupIdInPath.id}>
          <Tab title="Início" onClick={() => navigate(`/grupo/${groupIdInPath.id}`)} />
          <Tab title="Informações" onClick={() => navigate(`/grupo/${groupIdInPath.id}/informacoes`)} />
          <Tab title="Agenda" onClick={() => navigate(`/grupo/${groupIdInPath.id}/agenda`)} />
          <Tab title="Histórico" onClick={() => navigate(`/grupo/${groupIdInPath.id}/historico`)} />
          <Tab title="Relatórios" onClick={() => navigate(`/grupo/${groupIdInPath.id}/relatorios`)} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default Layout;
