import React, { useMemo } from 'react';
import Icon from 'react-eva-icons';

import { SprintSelectContainer } from './styles';

export interface ISprintData {
  id: number;
  name: string;
  start: string;
  end: string;
  previousSprintId: number | undefined;
  nextSprintId: number | undefined;
}

interface ISprintSelectProps {
  sprintData: ISprintData;
  handleOnChangeSprint: (sprintId: number) => Promise<void>;
}

const SprintSelect: React.FC<ISprintSelectProps> = ({ sprintData, handleOnChangeSprint }) => {
  const getSprint = useMemo(() => {
    return `${sprintData?.name}: 
    ${new Intl.DateTimeFormat('pt-BR').format(new Date(sprintData.start))} - 
    ${new Intl.DateTimeFormat('pt-BR').format(new Date(sprintData.end))}`;
  }, [sprintData]);

  return (
    <SprintSelectContainer>
      {sprintData.previousSprintId && (
        <label className="sprint-select-icon" onClick={() => sprintData.previousSprintId && handleOnChangeSprint(sprintData.previousSprintId)}>
          <Icon name="arrow-ios-back" />
        </label>
      )}

      <span className="sprint-select-text">{getSprint}</span>

      {sprintData.nextSprintId && (
        <label className="sprint-select-icon" onClick={() => sprintData.nextSprintId && handleOnChangeSprint(sprintData.nextSprintId)}>
          <Icon name="arrow-ios-forward" />
        </label>
      )}
    </SprintSelectContainer>
  );
};

export default SprintSelect;
