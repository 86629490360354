import React from 'react';
import { Container, TabPickerContainer } from './styles';
interface Tab {
  value: string;
  label: string;
}

interface TabPickerProps {
  tabs: Tab[];
  selectedTab: string;
  onTabChange: (value: string) => void;
}

export const TabPicker: React.FC<TabPickerProps> = ({ tabs, selectedTab, onTabChange }) => {
  return (
    <Container>
      <TabPickerContainer>
        {tabs.map((tab) => (
          <div
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            className={`tab-item ${tab.value} ${selectedTab === tab.value ? 'selected' : ''}`}
          >
            {tab.label}
          </div>
        ))}
      </TabPickerContainer>
    </Container>
  );
};
