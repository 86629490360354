import React, { useCallback, useState, useEffect } from 'react';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';

import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import GetIndividualFrequencyPercentService from '@modules/dashboards/services/GetIndividualFrequencyPercentService';
import { formatIndividualFrequencyPercent } from '@modules/dashboards/utils/formatIndividualFrequencyPercent';

import { Container, Content } from './styles';
import { useParams } from 'react-router-dom';

const IndividualFrequencyPercent: React.FC = () => {

    const { studentId } = useParams()
    const { signedUser } = useAuth();
    const { setIsLoading } = useGlobal();
    const [classes, setClasses] = useState<any[]>([]);
    const [columns] = useState<IDynamicTableColumnsGroup[]>([
        {
            columns: [
                { name: 'Turmas', dataname: 'classname' },
                { name: 'Tipo', dataname: 'type' },
                { name: '% de Presença', dataname: 'percent' }
            ],
            fixed: true
        }
    ]);

    const load = useCallback(async () => {

        if (!signedUser || !studentId) return;

        setIsLoading(true);
        const res = await new GetIndividualFrequencyPercentService(signedUser.token)
            .execute(studentId);

        setIsLoading(false);

        if (!res) return;
        setClasses(() => res.map(formatIndividualFrequencyPercent));
    }, [setIsLoading, signedUser, studentId]);

    useEffect(() => {
        load();
    }, [load]);

    const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
        const orderBy = sort.sortName as string;

        setClasses((oldState) => {
            let aux = [...oldState]
            aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
            return aux;
        });
    }, []);

    return (
        <Container>
            <Content>
                <DynamicTable
                    columnsGroup={columns}
                    data={classes}
                    handleClickSort={handleOnClickSort}
                />
            </Content>
        </Container>
    );
}

export default IndividualFrequencyPercent;