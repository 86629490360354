export type TYPES_STEERINGS_STATUS = 'scheduled' | 'finished' | 'not_finished';

export const STEERING_STATUS_SCHEDULED_CONSTRAINTS = 'scheduled';
export const STEERING_STATUS_FINISHED_CONSTRAINTS = 'finished';
export const STEERING_STATUS_NOT_FINISHED_CONSTRAINTS = 'not_finished';

export const STEERING_STATUS_SCHEDULED = { name: 'Agendada', value: 'scheduled' };
export const STEERING_STATUS_FINISHED = { name: 'Realizada', value: 'finished' };
export const STEERING_STATUS_NOT_FINISHED = { name: 'Não Realizada', value: 'not_finished' };

export const STEERING_STATUS_CONSTRAINTS = [
  STEERING_STATUS_SCHEDULED_CONSTRAINTS,
  STEERING_STATUS_FINISHED_CONSTRAINTS,
  STEERING_STATUS_NOT_FINISHED_CONSTRAINTS,
];

export const EDITING_STEERING_STATUS_CONSTRAINTS = [
  STEERING_STATUS_FINISHED_CONSTRAINTS,
  STEERING_STATUS_NOT_FINISHED_CONSTRAINTS,
];

export const STEERING_STATUS_OPTIONS = [STEERING_STATUS_FINISHED, STEERING_STATUS_NOT_FINISHED, STEERING_STATUS_SCHEDULED];
export const STEERING_STATUS_EDITING_OPTIONS = [STEERING_STATUS_FINISHED, STEERING_STATUS_NOT_FINISHED];
export const STATUS_STEERING_VALUES = STEERING_STATUS_OPTIONS.map((c) => c.value);

export const defaultSteeringStatus = (steeringStatus?: string | null) =>
  STEERING_STATUS_OPTIONS.map((i) => ({ ...i, selected: steeringStatus === i.value }));

export const editingSteeringStatus = (steeringStatus?: string | null) =>
  STEERING_STATUS_EDITING_OPTIONS.map((i) => ({ ...i, selected: steeringStatus === i.value }));

export const translateSteeringStatus = (status: string = '') => {
  let objMap = {};
  STEERING_STATUS_OPTIONS.forEach((i) => (objMap = { ...objMap, [i.value]: i.name }));
  return objMap[status] || status;
};
