import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, H6, Input, Select, Divider } from 'react-alicerce-components';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import CustomForm from '@shared/components/CustomForm';
import { IInputErrorsFormat, errorMessageBuilder, hasError } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';

import { useSteeringForm } from '../useSteeringForm';
import { defaultSteeringType } from '@modules/projects/schemas/Steering/partials/SteeringType';
import { defaultSteeringStatus, editingSteeringStatus } from '@modules/projects/schemas/Steering/partials/SteeringStatus';
import { defaultSteeringChannel } from '@modules/projects/schemas/Steering/partials/SteeringChannel';
import returnStringFunction from '@shared/utils/returnStringFunction';
import UserCard from '@modules/users/components/UserCard';
import { RemoveOptionWrapper } from '@modules/activities-library/components/RemoveOptionWrapper';
import { ButtonAddFiles } from '@shared/components/ButtonAddFiles';
import { ThermometerTag } from '@shared/components/ThermometerTag';
import { STEERING_THERMOMETER_OPTIONS, TYPES_STEERINGS_THERMOMETER } from '@modules/projects/schemas/Steering/partials/SteeringThermometer';

interface SteeringInfoFragmentProps {
  disabled?: boolean;
  editing?: boolean;
  errors?: IInputErrorsFormat[];
  pathParticipatingAlicerce?: string;
}

export const SteeringInfoFragment: React.FC<SteeringInfoFragmentProps> = ({ errors, disabled, editing, pathParticipatingAlicerce = '' }) => {
  const navigate = useNavigate();
  const {
    handleSelectChange,
    handleChangeInput,
    handleRemoveCard,
    handleAddParticipantsToArray,
    handleOnChangeExternalParticipants,
    handleOnAddNewFile,
    handleBlur,
    handleOnRemoveFile,
    handleRemoveOption,
  } = useSteeringForm();
  const { steeringCurrent, setSteeringCurrent } = useProjects();

  const selectSteeringStatusOptions = (status) => {
    if (editing) return editingSteeringStatus(status);
    return defaultSteeringStatus(status);
  };
  
  const statusIsNotFinished = steeringCurrent?.status === 'not_finished'

  return (
    <Grid row>
      <H6>INFORMAÇÕES BÁSICAS:</H6>
      <CustomForm status="primary">
        <Grid row>
          <Grid sm={12} md={5} mt4>
            <Input
              disabled={disabled}
              label="Data da Steering"
              name="meeting_date"
              type="date"
              onBlur={(e) => handleBlur('meeting_date', e.target.value)}
              onChange={handleChangeInput}
              required
              defaultValue={returnStringFunction(steeringCurrent?.meeting_date)}
              useTooltipError={false}
              error={errorMessageBuilder('meeting_date', errors)}
            />
          </Grid>
          <Grid sm={12} md={7} mt4>
            <Select
              disabled={disabled}
              label="Tipo da Steering:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={handleSelectChange}
              options={defaultSteeringType(steeringCurrent?.type)}
              error={errorMessageBuilder('type', errors)}
              name="type"
            />
          </Grid>
        </Grid>
        <Grid row>
          {steeringCurrent?.status && !disabled && (
            <Grid sm={12} md={12} mt4>
              <Select
                disabled={disabled}
                label="Status da Steering:"
                iconName="arrow-down"
                placeholder="Selecione"
                handleSelectChange={handleSelectChange}
                options={selectSteeringStatusOptions(steeringCurrent.status)}
                error={errorMessageBuilder('status', errors)}
                name="status"
              />
            </Grid>
          )}
        </Grid>

        <Grid row>
          <Grid sm={12} md={12} mt4>
            <Select
              disabled={disabled}
              label="Canal da Reunião:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={handleSelectChange}
              options={defaultSteeringChannel(steeringCurrent?.meeting_channel)}
              error={errorMessageBuilder('meeting_channel', errors)}
              name="meeting_channel"
            />
          </Grid>
        </Grid>

        <Grid row>
          <Grid sm={12} md={12} mt4>
            <Input
              disabled={disabled}
              name="meeting_link"
              placeholder="Adicione Link do Canal da Reunião"
              label="Link do Canal da Reunião:"
              onChange={handleChangeInput}
              defaultValue={steeringCurrent?.meeting_link}
              useTooltipError={false}
              required={steeringCurrent?.meeting_channel !== 'in_person' && !statusIsNotFinished}
              error={errorMessageBuilder('meeting_link', errors)}
            />
          </Grid>
        </Grid>

        <Divider width="100%" />

        <Grid sm={12} md={5} mt4>
          <Input
            disabled={disabled}
            label="Data da Próxima Steering"
            name="meeting_next_date"
            type="date"
            onBlur={(e) => handleBlur('meeting_next_date', e.target.value)}
            onChange={handleChangeInput}
            defaultValue={returnStringFunction(steeringCurrent?.meeting_next_date)}
            useTooltipError={false}
            error={errorMessageBuilder('meeting_next_date', errors)}
          />
        </Grid>

        <AccordionInfoItem title="Anexos da Steering" isShow>
          <Grid>
            <ButtonAddFiles
              isDisabled={disabled}
              iconName="plus-outline"
              text="Adicione Anexos"
              colorBackgroundIcon="#BE3A3A"
              colorBackgroundText="#F3C8C8"
              filesToSave={steeringCurrent?.attachments}
              seeFileButton
              callbackFilesToSave={(files) => handleOnAddNewFile(files)}
              callbackFilesToRemove={disabled ? undefined : (index) => handleOnRemoveFile(index)}
            />
          </Grid>
        </AccordionInfoItem>

        {(steeringCurrent?.thermometer || editing ) && (
          <>
            <Divider width="100%" />
            <H6>TERMÔMETRO DA STEERING:</H6>
            <ThermometerTag
              hasErrors={!statusIsNotFinished && hasError(['thermometer'], errors)}
              disabled={disabled}
              handleOnChange={(value) =>
                disabled
                  ? undefined
                  : setSteeringCurrent((oldState) => {
                      return { ...oldState, thermometer: value as TYPES_STEERINGS_THERMOMETER };
                    })
              }
              currentSelected={steeringCurrent?.thermometer}
              items={STEERING_THERMOMETER_OPTIONS}
            />
          </>
        )}

        <Divider width="100%" />
        <H6>PARTICIPANTES DA STEERING:</H6>
        <AccordionInfoItem title="Participantes do Parceiro" hasErrors={hasError(['external_participants'], errors)} isShow>
          <Grid>
            <Grid>
              {!disabled && (
                <ButtonAddCard
                  text="Adicione Participante do Parceiro"
                  handleOnClick={() => (disabled ? undefined : handleAddParticipantsToArray('participants'))}
                />
              )}
              {steeringCurrent?.external_participants?.participants?.map((participant, index) => (
                <Grid key={index} sm={12} md={12} mt2>
                  <RemoveOptionWrapper
                    handleDeleteCallback={disabled ? undefined : () => handleRemoveOption(index, 'participants')}
                    key={`${index}-${steeringCurrent.external_participants.participants.length}`}
                  >
                    <Input
                      disabled={disabled}
                      name={`external_participants.participants.${index}`}
                      onChange={(e) => handleOnChangeExternalParticipants(e.target.value, 'participants', index)}
                      defaultValue={participant}
                      useTooltipError={false}
                      required
                      error={errorMessageBuilder('external_participants', errors)}
                    />
                  </RemoveOptionWrapper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </AccordionInfoItem>

        <AccordionInfoItem title="Participantes Alicerce" hasErrors={hasError(['lms_participants_ids'], errors)} isShow>
          <Grid row>
            {!disabled && (
              <ButtonAddCard
                text="Adicione Participante do Alicerce"
                handleOnClick={() => (disabled ? undefined : navigate(pathParticipatingAlicerce))}
              />
            )}
            {steeringCurrent?.lms_users?.map((user, index) => (
              <UserCard
                key={user.id}
                user={user}
                canSelect={false}
                isInputCard={false}
                canDeleteCard={disabled ? undefined : { indexId: index, removeCallback: () => handleRemoveCard(index) }}
              />
            ))}
          </Grid>
        </AccordionInfoItem>
      </CustomForm>
    </Grid>
  );
};
