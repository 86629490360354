import axios, { AxiosInstance, CancelTokenSource } from "axios";

import IProjectReport from "@modules/projects/interfaces/IProjectReport";
import ApiFactory from "@shared/factories/ApiFactory";

class CreateReportService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(reportCreate: any): Promise<IProjectReport | false> {
    try {
      const res = await this.api.post('/report', { ...reportCreate });
      return res.data as IProjectReport;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default CreateReportService;