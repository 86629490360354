import styled from 'styled-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import themeGet from '@styled-system/theme-get';

export const Container = styled.div`
    margin-top: 2rem;

    .card-header {
        height: 30px;
        padding: .2rem;

        h6 {
            text-align: left;
        }
    }
   
`;

export const Date = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
`;

export const Header = styled.div<{status?:ElementStatus}>`
    ${Date} {
        background-color: ${({status}) => themeGet(`colors.${status}300`)};
        color: ${({status}) => themeGet(`colors.${status}Contrast`)};
        font-size: 11px;
        font-weight: 800;
        height: 40px;
        width: 75px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    } 

    .received-header {
        margin-left: 80px;
    }
`;
