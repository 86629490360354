// constraints de age group
export const G0 = { name: 'G0', value: 'G0' }; // alicercinho
export const G1 = { name: 'G1', value: 'G1' }; // 8 a 9 anos
export const G2 = { name: 'G2', value: 'G2' }; // 9 a 11 anos
export const G3 = { name: 'G3', value: 'G3' }; // 12 a 14 anos
export const G4 = { name: 'G4', value: 'G4' }; // 15 a 17 anos
export const G5 = { name: 'G5', value: 'G5' }; // 18 a 24 anos
export const G6 = { name: 'G6', value: 'G6' }; // 25 anos ou mais

export type IValidAgeGroups = 'G0' | 'G1' | 'G2' | 'G3' | 'G4' | 'G5' | 'G6';

export const AGE_GROUP_CONSTRAINTS = [G0, G1, G2, G3, G4, G5, G6];

export const defaultAgeGroup = (ageGroup?: string | null) => {
  return [
    { name: G0.name, value: G0.value, selected: ageGroup === G0.value },
    { name: G1.name, value: G1.value, selected: ageGroup === G1.value },
    { name: G2.name, value: G2.value, selected: ageGroup === G2.value },
    { name: G3.name, value: G3.value, selected: ageGroup === G3.value },
    { name: G4.name, value: G4.value, selected: ageGroup === G4.value },
    { name: G5.name, value: G5.value, selected: ageGroup === G5.value },
    { name: G6.name, value: G6.value, selected: ageGroup === G6.value },
  ];
};
