import * as Yup from 'yup';

export const userCreateSchema = Yup.object().shape({
  education_level: Yup.string().required({ name: 'education_level', message: 'O campo é obrigatório' }),
  university_type: Yup.string().required({ name: 'university_type', message: 'O campo é obrigatório' }),
  contact_email: Yup.string().required({ name: 'contact_email', message: 'O campo é obrigatório' }),

  category: Yup.object().shape({
    name: Yup.string().required({ name: 'category', message: 'O campo é obrigatório' }),
    id: Yup.string().required({ name: 'category', message: 'O campo é obrigatório' }),
  }),

  gender: Yup.string().required({ name: 'gender', message: 'O campo é obrigatório' }),
  status: Yup.string().required({ name: 'status', message: 'O campo é obrigatório' }),
  email: Yup.string().required({ name: 'email', message: 'O campo é obrigatório' }),
  phone: Yup.string().required({ name: 'phone', message: 'O campo é obrigatório' }),
  name: Yup.string().required({ name: 'name', message: 'O campo é obrigatório' }),

  academic_info: Yup.object().shape({
    course_name: Yup.string().required({ name: 'academic_info', message: 'O campo é obrigatório' }),
    end_date: Yup.string().required({ name: 'academic_info', message: 'O campo é obrigatório' }),
    name: Yup.string().required({ name: 'academic_info', message: 'O campo é obrigatório' }),
  }),
  address_data: Yup.object().shape({
    address: Yup.string().required({ name: 'address', message: 'O campo é obrigatório' }),
    city: Yup.string().required({ name: 'city', message: 'O campo é obrigatório' }),
    uf: Yup.string().required({ name: 'uf', message: 'O campo é obrigatório' }),
  }),

  activation_date: Yup.date().required({ name: 'activation_date', message: 'O campo é obrigatório' }),
  birthdate: Yup.date().required({ name: 'birthdate', message: 'O campo é obrigatório' }),
});
