import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-all-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin: 1.5rem 0;
  }

  .container-skill-checkbox {
    display: flex;
    gap: 0.7rem;
    width: 100%;

    .container-skill-card {
      flex-grow: 1;
    }

    > div {
      margin: 0;
      label input + div {
        margin: 0;
      }
    }
  }

  .container-page-footer button {
    background: #96b6f7;
  }

  .pagination-container {
    .pagination-content {
      .pagination-content-icon {
        background-color: #b85ec4;
      }
    }
  }
`;
