export const PROJECT_ADDITIONAL_INFORMATION_CLUB_CONSTRAIN = 'club' //Clube
export const PROJECT_ADDITIONAL_INFORMATION_EMPLOYABILITY_CONSTRAIN = 'employability' //Empregabilidade
export const PROJECT_ADDITIONAL_INFORMATION_SNACK_CONSTRAIN = 'snack' //Lanche
export const PROJECT_ADDITIONAL_INFORMATION_PREMIUM_SNACK_CONSTRAIN = 'premium_snack' //Lanche Premium
export const PROJECT_ADDITIONAL_INFORMATION_REGULATORY_CONSTRAIN = 'regulatory' //Regulatório
export const PROJECT_ADDITIONAL_INFORMATION_HACK_WORKSHOP_CONSTRAIN = 'hack_workshop' //Oficina Hack
export const PROJECT_ADDITIONAL_INFORMATION_AID_CONSTRAIN = 'aid' //Auxílio
export const PROJECT_ADDITIONAL_INFORMATION_TABLET_CONSTRAIN = 'tablet' //Tablet

export const PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS = [
  PROJECT_ADDITIONAL_INFORMATION_CLUB_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_EMPLOYABILITY_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_SNACK_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_PREMIUM_SNACK_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_REGULATORY_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_HACK_WORKSHOP_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_AID_CONSTRAIN,
  PROJECT_ADDITIONAL_INFORMATION_TABLET_CONSTRAIN
]

export const PROJECT_ADDITIONAL_INFORMATION_CLUB_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_CLUB_CONSTRAIN, label: 'Clube' };
export const PROJECT_ADDITIONAL_INFORMATION_EMPLOYABILITY_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_EMPLOYABILITY_CONSTRAIN, label: 'Empregabilidade' };
export const PROJECT_ADDITIONAL_INFORMATION_SNACK_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_SNACK_CONSTRAIN, label: 'Lanche' };
export const PROJECT_ADDITIONAL_INFORMATION_PREMIUM_SNACK_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_PREMIUM_SNACK_CONSTRAIN, label: 'Lanche Premium' };
export const PROJECT_ADDITIONAL_INFORMATION_REGULATORY_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_REGULATORY_CONSTRAIN, label: 'Regulatório' };
export const PROJECT_ADDITIONAL_INFORMATION_HACK_WORKSHOP_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_HACK_WORKSHOP_CONSTRAIN, label: 'Oficina Hack' };
export const PROJECT_ADDITIONAL_INFORMATION_AID_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_AID_CONSTRAIN, label: 'Auxílio' };
export const PROJECT_ADDITIONAL_INFORMATION_TABLET_CONSTRAIN_OPTION = { name: PROJECT_ADDITIONAL_INFORMATION_TABLET_CONSTRAIN, label: 'Tablet' };

export const PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS_OPTIONS = [
  PROJECT_ADDITIONAL_INFORMATION_CLUB_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_EMPLOYABILITY_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_SNACK_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_PREMIUM_SNACK_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_REGULATORY_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_HACK_WORKSHOP_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_AID_CONSTRAIN_OPTION,
  PROJECT_ADDITIONAL_INFORMATION_TABLET_CONSTRAIN_OPTION
]
