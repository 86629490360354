import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { CreateSteeringSchema } from '@modules/projects/schemas/Steering/CreateSteering';
import SteeringForm from '@modules/projects/components/SteeringForm';
import { formatSteering } from '@modules/projects/utils/formatSteering';
import { IAttachment } from '@shared/interfaces/IAttachment';
import UploadService from '@shared/services/UploadFile';
import { IFormatedSteering } from '@modules/projects/interfaces/ISteering';
import CreateSteeringService from '@modules/projects/services/Steering/CreateSteeringService';
import { splitAttachmentFiles } from '@shared/utils/splitAttachmentFiles';
import { formatedFileUpload } from '@modules/activities-library/utils/formatedFileUpload';

const SteeringCreate: React.FC = () => {
  
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { clearSteeringCreate, steeringCurrent, projectCurrent, clearProjectCreate } = useProjects();
  const { id: projectId } = useParams();
  const dirPath = `/projeto/${projectId}/steerings`

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [formatedSteeringBody, setFormatedSteeringBody] = useState<IFormatedSteering>();

  const handleCancel = useCallback(() => {
    clearSteeringCreate();
    navigate(dirPath);
  }, [clearSteeringCreate, dirPath, navigate]);

  const handleCreateButton = useCallback(async () => {
    if( !projectCurrent?.id && !projectId ) return;
    if (!steeringCurrent) return;
    const formatedBody = formatSteering(steeringCurrent, Number(projectCurrent?.id || projectId)) as IFormatedSteering;
    const canSubmitForm = await beforeSubmitForm(formatedBody, CreateSteeringSchema);
    setErros(canSubmitForm);
    setFormatedSteeringBody(formatedBody)
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [projectCurrent, projectId, steeringCurrent]);
  
  const handleCreateSteering = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !steeringCurrent) return;
    setIsLoading(true);
    
    let formattedAttachmentsToSave: IAttachment[] = [];

    const { newFiles, oldFiles } = splitAttachmentFiles(steeringCurrent.attachments);

    if (newFiles && newFiles.length > 0) {
      const res = await new UploadService(signedUser?.token).uploadFile(newFiles, 'steering/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      const formattedAttachments = formatedFileUpload(newFiles);
      formattedAttachmentsToSave = formattedAttachments.map((attachment, i) => ({ ...attachment, url: res[i].dbURL }));
    }

    let auxFormattedBody = { ...formatedSteeringBody };
    if (formattedAttachmentsToSave.length > 0) auxFormattedBody.attachments = [...oldFiles, ...formattedAttachmentsToSave];

    const res = await new CreateSteeringService(signedUser.token).execute(auxFormattedBody);
    setIsLoading(false);
    if (res) {
      clearProjectCreate()
      addToast({
        status: 'success',
        title: 'Steering',
        description: 'Steering criada com sucesso.',
      });
      clearSteeringCreate();
      return navigate( `/diretorio/projetos/steering/${res.id}`);
    }

    addToast({
      status: 'danger',
      title: 'Steering',
      description: 'Não foi possível criar a Steering.',
    });
  }, [signedUser, steeringCurrent, setIsLoading, formatedSteeringBody, addToast, clearProjectCreate, clearSteeringCreate, navigate]);

  useEffect(() => {
    if (steeringCurrent && steeringCurrent.id) {
      clearSteeringCreate()
    }
  }, [clearSteeringCreate, projectCurrent, setErros, steeringCurrent])

  return (
    <Fragment>
      <SteeringForm
        headerTitle="Criar Steering"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
        pathParticipatingAlicerce="/diretorio/projetos/steering/criar/selecionar/participante-alicerce"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar esta Steering?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateSteering}
      />
    </Fragment>
  );
};

export default SteeringCreate;
