import React, { useCallback } from 'react';
import { IBlock } from '@shared/interfaces/IBlock';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';
import { useNavigate } from 'react-router-dom';
import { BlocksList } from '@modules/work-group/components/BlocksList';
import { useWorkGroup } from '@modules/work-group/context/WorkGroupContext';

export const WorkGroupBlocksRelationCreate: React.FC = () => {
  const navigate = useNavigate();
  const { setWorkGroupCurrent } = useWorkGroup();

  const handleButtonCallback = useCallback(
    (blocks: IBlock[]) => {
      setWorkGroupCurrent((oldState: IWorkGroup) => {
        return { ...oldState, blocks: blocks };
      });
      navigate(-1);
    },
    [navigate, setWorkGroupCurrent]
  );

  return <BlocksList buttonText={'Salvar Blocos'} buttonCallback={(blocks: IBlock[]) => handleButtonCallback(blocks)} />;
};
