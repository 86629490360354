import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useToast } from 'react-alicerce-components';
import { IAttachment } from '../../interfaces/IAttachment';
import { splitAttachmentFiles } from '../../utils/splitAttachmentFiles';

export type IGlobalContextData = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  handleDownloadSingleFile: (fileToDownload: File | IAttachment) => void;
  handleDownloadAllFiles: (attachments?: (File | IAttachment)[]) => void;
};

const GlobalContext = createContext<IGlobalContextData>({} as IGlobalContextData);

const GlobalProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addToast } = useToast();

  const handleDownloadSingleFile = (fileToDownload: File | IAttachment) => {
    const { oldFiles } = splitAttachmentFiles([fileToDownload]);
    axios({
      url: oldFiles[0].url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${oldFiles[0].name}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((error) => {
      if (!!error) {
        addToast({
          status: 'danger',
          title: 'Download',
          description: 'Erro ao fazer Download do Arquivo.',
        });
        return;
      }
    });
  };

  const handleDownloadAllFiles = (attachments?: (File | IAttachment)[]) => {
    const { oldFiles } = splitAttachmentFiles(attachments);
    oldFiles.forEach((att) => {
      axios({
        url: att.url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${att.name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((error) => {
        if (!!error) {
          addToast({
            status: 'danger',
            title: 'Download',
            description: 'Erro ao fazer Download dos Arquivos.',
          });
          return;
        }
      });
    });
  };

  return (
    <GlobalContext.Provider value={{ isLoading, setIsLoading, handleDownloadAllFiles, handleDownloadSingleFile }}>{children}</GlobalContext.Provider>
  );
};

function useGlobal() {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }

  return context;
}

export { GlobalProvider, useGlobal };
