import IRoute from "@shared/interfaces/IRoute";

import { combineContext } from "@shared/utils/combineContext";
import { IModuleRoutes } from "@shared/interfaces/IModuleRoutes";
import { DashboardsProvider } from "@modules/dashboards/context/DashboardsContext";

import IndividualFrequency from "@modules/dashboards/views/IndividualFrequency";
import IndividualLearning from "@modules/dashboards/views/IndividualLearning";
import ClassLearning from "@modules/dashboards/views/ClassLearning";
import ClassFrequency from "@modules/dashboards/views/ClassFrequency";
import AderenceIndicator from "@modules/dashboards/views/AderencyIndicator";
import LearningIndicator from "@modules/dashboards/views/LearningIndicator";
import QualityIndicator from "@modules/dashboards/views/QualityIndicator";
import NPSIndicator from "@modules/dashboards/views/NPSIndicator";
import NPSLeader from "@modules/dashboards/views/NPSLeader";
import NPSResponsible from "@modules/dashboards/views/NPSResponsible";
import HabilidadesParaVidaGroupCurrent from "@modules/dashboards/views/HabilidadesParaVidaGroupCurrent";
import HabilidadesParaVidaGroupEvolution from "@modules/dashboards/views/HabilidadesParaVidaGroupEvolution";
import HabilidadesParaVidaGroupEvolutionDetails from "@modules/dashboards/views/HabilidadesParaVidaGroupEvolutionDetails";
import HabilidadesParaVidaIndividualCurrent from "@modules/dashboards/views/HabilidadesParaVidaIndividualCurrent";
import HabilidadesParaVidaIndividualEvolution from "@modules/dashboards/views/HabilidadesParaVidaIndividualEvolution";
import HabilidadesParaVidaIndividualEvolutionDetails from "@modules/dashboards/views/HabilidadesParaVidaIndividualEvolutionDetails";
import DescobertaIndicator from "@modules/dashboards/views/DescobertaIndicator";

import { dashboardsKeys } from "modules/dashboards/keys";

const routes: IRoute[] = [
    {
        path: "/dash/aprendizagem/individual/:studentId",
        key: "DASHBOARDS_LEARNING_INDIVIDUAL_PATH",
        exact: true,
        component: IndividualLearning,
        permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/frequencia/individual/:studentId",
        key: "DASHBOARDS_INDIVIDUAL_FREQUENCY_PATH",
        exact: true,
        component: IndividualFrequency,
        permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/aprendizagem/grupo/:classId",
        key: "DASHBOARDS_LEARNING_CLASS_PATH",
        exact: true,
        component: ClassLearning,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/frequencia/grupo/:classId",
        key: "DASHBOARDS_CLASS_FREQUENCY_PATH",
        exact: true,
        component: ClassFrequency,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    // {
    //     path: "/dash/indicadores",
    //     key: "DASHBOARDS_HOME_INDICATORS_PATH",
    //     exact: true,
    //     component: AderenceIndicator,
    //          permissions: [
    //         dashboardsKeys.DASHBOARDS_READ,
    //     ]
    // },
    {
        path: "/dash/indicadores/aderencia/:resource/:resourceId",
        key: "DASHBOARDS_ADERENCY_INDICATOR_PATH",
        exact: true,
        component: AderenceIndicator,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/indicadores/aprendizagem/:resource/:resourceId",
        key: "DASHBOARDS_LEARNING_INDICATOR_PATH",
        exact: true,
        component: LearningIndicator,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/indicadores/qualidade/:resource/:resourceId",
        key: "DASHBOARDS_QUALITY_INDICATOR_PATH",
        exact: true,
        component: QualityIndicator,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/indicadores/nps/:resource/:resourceId",
        key: "DASHBOARDS_NPS_INDICATOR_PATH",
        exact: true,
        component: NPSIndicator,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/indicadores/nps/lider",
        key: "DASHBOARDS_NPS_INDICATOR_LEADER_PATH",
        exact: true,
        component: NPSLeader,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/indicadores/nps/responsavel",
        key: "DASHBOARDS_NPS_INDICATOR_RESPONSIBLE_PATH",
        exact: true,
        component: NPSResponsible,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/habilidadesparavida/grupo/atual/:classId",
        key: "DASHBOARDS_HABILIDADES_PARA_VIDA_GROUP_CURRENT_PATH",
        exact: true,
        component: HabilidadesParaVidaGroupCurrent,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/habilidadesparavida/grupo/evolucao/:id",
        key: "DASHBOARDS_HABILIDADES_PARA_VIDA_GROUP_EVOLUTION_PATH",
        exact: true,
        component: HabilidadesParaVidaGroupEvolution,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/habilidadesparavida/grupo/evolucao/:id/detalhes",
        key: "DASHBOARDS_HABILIDADES_PARA_VIDA_GROUP_EVOLUTION_DETAILS_PATH",
        exact: true,
        component: HabilidadesParaVidaGroupEvolutionDetails,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },

    {
        path: "/dash/habilidadesparavida/individual/atual",
        key: "DASHBOARDS_HABILIDADES_PARA_VIDA_INDIVIDUAL_CURRENT_PATH",
        exact: true,
        component: HabilidadesParaVidaIndividualCurrent,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/habilidadesparavida/individual/evolucao",
        key: "DASHBOARDS_HABILIDADES_PARA_VIDA_INDIVIDUAL_EVOLUTION_PATH",
        exact: true,
        component: HabilidadesParaVidaIndividualEvolution,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/habilidadesparavida/individual/evolucao/:id/detalhes",
        key: "DASHBOARDS_HABILIDADES_PARA_VIDA_INDIVIDUAL_EVOLUTION_DETAILS_PATH",
        exact: true,
        component: HabilidadesParaVidaIndividualEvolutionDetails,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
    {
        path: "/dash/indicadores/descoberta",
        key: "DASHBOARDS_INDICATORS_DESCOBERTA_PATH",
        exact: true,
        component: DescobertaIndicator,
             permissions: [
            dashboardsKeys.DASHBOARDS_READ,
        ]
    },
];

const providers = [DashboardsProvider];

export default {
    key: 'DASHBOARDS_MODULE',
    providers: combineContext(...providers),
    routes,
} as IModuleRoutes;