import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IProject from '@modules/projects/interfaces/IProject';
import IListOptions from '@shared/interfaces/IListOptions';

export interface IProjectResponse extends IPaginatedResponse {
  items: IProject[];
}

export interface IListProjectOptions extends IListOptions {
  contract_ids?: number[]
}
class ListProjectsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query: string = '', options: IListProjectOptions): Promise<IProjectResponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      const res = await this.api.get(`/project?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListProjectsService;
