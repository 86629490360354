import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import HeaderModule from '@shared/components/Headers/HeaderModule';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import { useContracts } from '@modules/contracts/context/ContractContext';
import { ContractDefaultStylesContainer } from '../ContractDefaultStyles/styles';
import { contractStatusTranslator } from '@modules/projects/schemas/Project/partials/ProjectStatus';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import GetContractBusinessService from '@modules/contracts/services/GetContractBusinessService';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { isLoading } = useGlobal();
  const { setIsLoading } = useGlobal();
  const { id } = useParams<{ id: string }>();
  const { newCancelToken } = useCancelRequest();
  const { setContractCurrent, contractCurrent } = useContracts();
  const { state: stateLocation } = useLocation() as { state: { pathToReturn: string } };

  const getContract = useCallback(async () => {
    if (!signedUser || !id) return;
    setIsLoading(true);
    const contracts = await new GetContractBusinessService(signedUser.token, newCancelToken()).execute(id);
    setIsLoading(false);
    if (!contracts) return;
    setContractCurrent(contracts);
  }, [id, newCancelToken, setContractCurrent, setIsLoading, signedUser])

  useEffect(() => {
    if (contractCurrent && Number(contractCurrent.id) === Number(id)) return
    getContract()
  }, [contractCurrent, getContract, id])

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  const handleGoBack = useCallback(() => {
    const returnPathFromLocationState = stateLocation && (stateLocation.pathToReturn as string | undefined);
    navigate(returnPathFromLocationState ? returnPathFromLocationState : '/diretorio/contratos');
  }, [navigate, stateLocation]);

  return (
    <ContractDefaultStylesContainer>
      {isLoading && <Spinner />}
      <HeaderModule
        title="CONTRATO"
        key={`${contractCurrent?.id}`}
        subtitle={contractCurrent?.name ? contractCurrent.name : 'Carregando...'}
        additionalInfo={`${contractCurrent?.status ? contractStatusTranslator(contractCurrent.status) : 'Carregando...'}`}
        useDefaultNav
        goBackCallback={handleGoBack}
        isScrolled={isScrolled}
        compressOnScroll
        menuKebab={{
          tooltip: { view: 'ContractInfo' },
          menuBackHome: true,
        }}
      />

      <CustomTabs footer={footer || false} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
        {contractCurrent && (
          <Tabs openTab={openTab} content={children} horizontalScroll>
            <Tab title="informações" onClick={() => navigate(`/contrato/${id}/informacoes`)} />
            <Tab title="Projetos" onClick={() => navigate(`/contrato/${id}/projetos`)} />
            <Tab title="Contratos Anteriores" onClick={() => navigate(`/contrato/${id}/contratos-anteriores`)} />
          </Tabs>
        )}
      </CustomTabs>
    </ContractDefaultStylesContainer>
  );
};

export default Layout;
