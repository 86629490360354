import React, { useCallback, useMemo, useState } from 'react';
import { Divider, Grid, P, Select, Textarea } from 'react-alicerce-components';

import { IInputErrorsFormat, beforeSubmitForm, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';
import CustomForm from '@shared/components/CustomForm';

import { defaultStudentDisconnectDetails, defaultStudentDisconnectReasons } from '@modules/classes/utils/validData/validDisconnectStudentReason';
import { DisconnectSchema, DisconnectUnenrollmentForm, UnenrollmentSchema } from '@modules/classes/schemas/DisconnectAndUnenrollmentSchema';
import { defaultUnenrollmentReasons } from '@modules/classes/utils/validData/validUnenrollmentStudentReason';
import { ClassStepModal } from '@modules/classes/components/ClassStepModal';

import { UnenrollmentOrDisconnectStepProps } from './UnenrollmentOrDisconnectStep';
import Icon from 'react-eva-icons';

export type IUnenrollmentDisconnectStudentFormStepProps = UnenrollmentOrDisconnectStepProps & {
  form?: DisconnectUnenrollmentForm;
  setForm: (value: any) => void;
};


export const UnenrollmentDisconnectStudentFormStep = ({
  onNext,
  onCloseModal,
  selectedRadio,
  handleSubmit,
  form,
  setForm,
}: IUnenrollmentDisconnectStudentFormStepProps) => {
  const [errors, setErrors] = useState<IInputErrorsFormat[]>();

  const handleSelectChange = useCallback(
    (option, selectName) => {
      if (selectName === 'reason') {
        setForm((oldState: any) => {
          if (oldState?.detail) delete oldState['detail']
          return { ...oldState, [selectName]: option.value };
        });
      }
      setForm((oldState: any) => {
        return { ...oldState, [selectName]: option.value };
      });
    },
    [setForm]
  );

  const dynamicLabel = useMemo(() => {
    return selectedRadio === 'unenrollment' ? 'do Desenturmamento' : 'da Desistência';
  }, [selectedRadio]);

  const disconnectStudentReasonDetails = defaultStudentDisconnectDetails(form?.reason, form?.detail);

  const validateForm = async () => {
    if ((form?.reason && ['inadimplence', 'project_finished', 'unit_closure'].includes(form.reason)) || selectedRadio === 'unenrollment') {
      return await beforeSubmitForm(form, UnenrollmentSchema)
    }
    return await beforeSubmitForm(form, DisconnectSchema)
  };

  const canSubmit = async () => {
    let formErrors = await validateForm();
    setErrors(formErrors);
    if (formErrors.length !== 0) return;
    handleSubmit && handleSubmit();
  };

  return (
    <ClassStepModal
      title={`Deseja ${selectedRadio === 'unenrollment' ? 'Desenturmar' : 'Desligar'} Aluno?`}
      onCloseModal={onCloseModal}
      onConfirm={canSubmit}
      saveButtonText="Confirmar"
      flag={
        <Grid textCenter>
          <Divider width="100%" />
            <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
              <Icon name="alert-triangle" fill="#F2C94C" />
              <P fontSize={'12px !important'} fontWeight={'700'}>ATENÇÃO</P>
            </div>
            <P fontSize={'12px !important'} className="description">Essa ação só terá efeito quando você clicar em <b>Salvar</b> na edição da Turma.</P>
          <Divider width="100%" />
        </Grid>
      }
      description={
        <Grid>
          {selectedRadio === 'unenrollment' ? (
            <P fontSize={'12px !important'} className="description">
              Ao prosseguir você desligará o aluno <span style={{ fontWeight: 'bold' }}>apenas desta turma</span>.
            </P>
          ) : (
            <P fontSize={'12px !important'} className="description">
              Este aluno faz parte de outras <span style={{ fontWeight: 'bold' }}>XX turmas</span>. Ao prosseguir você o desligará de todas estas
              turmas.
            </P>
          )}
          <P fontSize={'12px !important'} className="description">Antes você obrigatoriamente precisa justificar esta ação:</P>
        </Grid>
      }
    >
      <CustomForm>
        <Grid>
          <Select
            name="reason"
            label={`Motivo ${dynamicLabel}`}
            iconName="arrow-down"
            placeholder="Selecione Motivo"
            fullWidth={true}
            handleSelectChange={(option) => handleSelectChange(option, 'reason')}
            options={selectedRadio === 'disconnect' ? defaultStudentDisconnectReasons(form?.reason) : defaultUnenrollmentReasons(form?.reason)}
            required
            error={errorMessageBuilder('reason', errors)}
          />

          {selectedRadio === 'disconnect' &&
            form?.reason !== 'inadimplence' &&
            form?.reason !== 'project_finished' &&
            form?.reason !== 'unit_closure' && (
              <Select
                key={form?.detail}
                name="detail"
                label={`Detalhe ${dynamicLabel}`}
                iconName="arrow-down"
                placeholder="Selecione Detalhe"
                fullWidth={true}
                handleSelectChange={(option) => handleSelectChange(option, 'detail')}
                options={disconnectStudentReasonDetails}
                required
                error={errorMessageBuilder('detail', errors)}
              />
            )}

          <Textarea
            name="observation"
            label={`Observações ${dynamicLabel}`}
            placeholder="Adicione Observações"
            value={form?.observation || ''}
            rows={4}
            onChange={(e) => {
              setForm((oldState: any) => {
                return { ...oldState, observation: e.currentTarget.value };
              });
            }}
            error={errorMessageBuilder('observation', errors)}
          />
        </Grid>
      </CustomForm>
    </ClassStepModal>
  );
};
