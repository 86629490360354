import React, { createContext, useContext, useState } from 'react';
import { IDailyClass } from '../../attendances/interfaces/IDailyClass';
import { IStudent } from '../../students/interfaces/IStudent';
import { IWorkGroup } from '../../work-group/interfaces/IWorkGroup';
import { IPlannedActivity } from '../services/ListPlannedActivityService';

export type IProblemScheduleGrid = string;

interface IScheduleGridContextState {
  problems?: IProblemScheduleGrid[];
  dailyClass?: IDailyClass;
  canSavePlannedClass: boolean;
  plannedActivities: IPlannedActivityGrid | undefined;
  plannedActivityCurrent: IPlannedActivity | undefined;
  availableWorkGroups: IWorkGroup[];
  availableStudents: IStudent[];
}

export type IScheduleGridContextData = IScheduleGridContextState & {
  setProblems: (value: any) => void;
  setDailyClass: (value: any) => void;
  setCanSavePlannedClass: (value: any) => void;
  setPlannedActivities: (value: any) => void;
  setPlannedActivityCurrent: (value: any) => void;
  setAvailableWorkGroups: (value: any) => void;
  setAvailableStudents: (value: any) => void;
};

export interface IActivityScheduleGrid {
  activity_id?: number;
  planned_activity_id?: number;
  name: string;
  infos: string[];
  start_time?: string;
  end_time?: string;
  planned_activity: IPlannedActivity;
}

export interface IPlannedActivityGrid {
  in_calendar: IActivityScheduleGrid[];
  in_tray: Omit<IActivityScheduleGrid, 'start_time' | 'end_time'>[];
}

const ScheduleGridContext = createContext<IScheduleGridContextData>({} as IScheduleGridContextData);

const ScheduleGridProvider: React.FC = ({ children }) => {
  const [problems, setProblems] = useState<IProblemScheduleGrid[]>();
  const [plannedActivityCurrent, setPlannedActivityCurrent] = useState<IPlannedActivity>();
  const [plannedActivities, setPlannedActivities] = useState<IPlannedActivityGrid>();
  const [availableWorkGroups, setAvailableWorkGroups] = useState<IWorkGroup[]>([]);
  const [availableStudents, setAvailableStudents] = useState<IStudent[]>([]);
  const [dailyClass, setDailyClass] = useState<IDailyClass>();
  const [canSavePlannedClass, setCanSavePlannedClass] = useState<boolean>(false);

  return (
    <ScheduleGridContext.Provider
      value={{
        availableStudents,
        availableWorkGroups,
        setAvailableStudents,
        setAvailableWorkGroups,
        plannedActivityCurrent,
        setPlannedActivityCurrent,
        plannedActivities,
        setPlannedActivities,
        dailyClass,
        setDailyClass,
        canSavePlannedClass,
        setCanSavePlannedClass,
        problems,
        setProblems,
      }}
    >
      {children}
    </ScheduleGridContext.Provider>
  );
};

function useScheduleGrid() {
  const context = useContext(ScheduleGridContext);

  if (!context) {
    throw new Error('useScheduleGrid must be used within a ScheduleGridProvider');
  }

  return context;
}

export { ScheduleGridProvider, useScheduleGrid };
