import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import styled from 'styled-components';

export const GridsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Grid = styled.div`
  height: 100%;
  min-width: 110px;
  width: 110px;
  position: relative;
  border: 3px solid transparent;
  margin-top: 80px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    border: 1px dashed #8f9bb3;
    z-index: 0;
  }

  .checkbox-container {
    background: #edf1f7;
    margin-top: -1px;

    label {
      margin: 0 auto;

      input + div {
        border-radius: 5px;
        border: 2px solid #c5cee0;
      }

      input:checked + div {
        background-color: #dee3ec;
      }

      svg {
        width: 14px;
        height: 14px;
        fill: #2e3a59;
      }
    }

    &[disabled] {
      input:checked + div,
      input + div {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
`;

export const GridName = styled.div`
  position: relative;
  background-color: #8f9bb3;
  padding: 0.2rem;
  border-radius: 6px;
  text-align: center;

  h6 {
    font-size: 13px !important;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
  }

  p {
    font-size: 10px;
    text-align: center;
    color: #fff;
    font-weight: 600;
  }
`;

export const ActiveContainer = styled.div<{ periodSelected?: boolean; color?: string }>`
  padding: 0.4rem;
  margin-top: 10px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  ${(props) =>
    props.periodSelected &&
    `
      background-color: rgba(0,0,0,.1);

      ${GridName} {
        h6 {
            background-color: ${props.color};
            color: #fff;
        }
      }
    `}
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  position: relative;
`;

export const CategoryStatus = styled.div<{ status?: ElementStatus | string }>`
  padding: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => (status === 'basic' ? '#2E3A59' : 'transparent')};
  text-shadow: 0 0 0 white;
  font-weight: bold;
  font-size: 10px;
  width: 90%;
  border-radius: 8px;
  height: 45px;
  background-color: ${({ status }) => themeGet(`colors.${status}500`)};
  border: 2px solid
    ${({ status }) =>
      status === 'basic' ? '#C5CEE0' : status === 'undef' ? 'none' : status !== 'basic' || 'undef' ? themeGet(`colors.${status}500`) : 'none'};
`;

export const MarkCategory = styled.div<{ status?: ElementStatus | string }>`
  padding: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: ${({ status }) => (status === 'basic' ? '#2E3A59' : 'transparent')};
  text-shadow: 0 0 0 white;
  font-weight: bold;
  font-size: 10px;
  width: 90%;
  border-radius: 2rem;
  height: 45px;
  background-color: ${({ status }) => themeGet(`colors.${status}500`)};
  border: 2px solid
    ${({ status }) =>
      status === 'basic' ? '#C5CEE0' : status === 'undef' ? 'none' : status !== 'basic' || 'undef' ? themeGet(`colors.${status}500`) : 'none'};
`;
