export const objectToQuery = (obj: { [key: string]: any }, shouldStringfy?: boolean) => {
  const str: string[] = [];
  for (const k in obj) {
    const v = obj[k];
    const valid = Object.prototype.hasOwnProperty.call(obj, k) && v !== undefined && v !== null;

    if (valid) {
      if (shouldStringfy) {
        str.push(encodeURIComponent(k) + '=' + encodeURIComponent(JSON.stringify(v)));
      } else str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }

  return str.join('&');
};
