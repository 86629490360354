import { IFormattedWorkGroupBody } from '../interfaces/IFormattedWorkGroupBody';
import { IWorkGroup } from '../interfaces/IWorkGroup';

export const formatWorkGroup = (workGroup?: IWorkGroup): IFormattedWorkGroupBody => {
  if (!workGroup) return {};
  return {
    name: workGroup.name && !!workGroup.name.length ? workGroup.name : undefined,
    description: workGroup.description && !!workGroup.description.length ? workGroup.description : undefined,
    class_id: workGroup.class_id,
    block_ids: workGroup.blocks && !!workGroup.blocks.length ? workGroup.blocks.map((block) => block.id || '').filter(Boolean) : undefined,
    student_ids: workGroup.students && workGroup.students.length > 0 ? workGroup.students.map((student) => student.id || '').filter(Boolean) : undefined,
  };
};
