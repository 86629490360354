import React, { createContext, useCallback, useContext, useState, useMemo, useEffect } from 'react';

import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { IEvent } from '@modules/schedules/interfaces/IEvent';
import { ILocationState } from '@shared/interfaces/ILocationState';
import { useLocation, useNavigate } from 'react-router-dom';

export type ISchedulesContextData = {
  scheduleCurrent?: ISchedule | null;
  sprintCurrent?: ISprint | null;
  eventCurrent?: IEvent | null;
  state?: ILocationState | null;
  setScheduleCurrent: (value: any) => void;
  setSprintCurrent: (value: any) => void;
  setEventCurrent: (value: any) => void;
  setState: (value: any) => void;
  clearScheduleCurrent: () => void;
  clearScheduleSprintCurrent: () => void;
  clearScheduleEventCurrent: () => void;
};

const SchedulesContext = createContext<ISchedulesContextData>({} as ISchedulesContextData);

const SchedulesProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const { state: stateLocation } = useLocation() as { state: ILocationState };

  const emptySchedule = useMemo(() => {
    return {
      description: '',
      name: '',
    };
  }, []);

  const emptySprint = useMemo(() => {
    return {
      description: '',
      name: '',
      start_date: '',
      end_date: '',
    };
  }, []);

  const emptyEvent = useMemo(() => {
    return {
      name: '',
      description: '',
      end_date: '',
      end_time: '',
      start_date: '',
      start_time: '',
      type: '',
      week_days: [],
    };
  }, []);

  const [scheduleCurrent, setScheduleCurrent] = useState<ISchedule>(emptySchedule);
  const [sprintCurrent, setSprintCurrent] = useState<ISprint>(emptySprint);
  const [eventCurrent, setEventCurrent] = useState<IEvent>(emptyEvent);
  const [state, setState] = useState<ILocationState | null>(null);

  const clearScheduleCurrent = useCallback(() => {
    setScheduleCurrent(emptySchedule);
  }, [emptySchedule]);

  const clearScheduleSprintCurrent = useCallback(() => {
    setSprintCurrent(emptySprint);
  }, [emptySprint]);

  const clearScheduleEventCurrent = useCallback(() => {
    setEventCurrent(emptyEvent);
  }, [emptyEvent]);

  useEffect(() => {
    if ((!stateLocation?.id || !stateLocation?.resource) && !state) return navigate('/');

    if (!state) {
      setState(stateLocation);
    }
  }, [navigate, state, stateLocation]);

  return (
    <SchedulesContext.Provider
      value={{
        scheduleCurrent,
        sprintCurrent,
        eventCurrent,
        setScheduleCurrent,
        setSprintCurrent,
        setEventCurrent,
        clearScheduleCurrent,
        clearScheduleSprintCurrent,
        clearScheduleEventCurrent,
        state,
        setState,
      }}
    >
      {children}
    </SchedulesContext.Provider>
  );
};

function useSchedules() {
  const context = useContext(SchedulesContext);

  if (!context) {
    throw new Error('useSchedules must be used within a SchedulesProvider');
  }

  return context;
}

export { SchedulesProvider, useSchedules };
