import React, { useState, useCallback, useEffect } from 'react';
import { Button, Spinner, useToast } from 'react-alicerce-components';
import { useParams, useNavigate } from 'react-router-dom';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import Divider from '@shared/components/Divider';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';

import { IStudent } from '@modules/students/interfaces/IStudent';
import { WorkGroupAndStudentsInfo } from '@modules/class-planner/components/WorkGroupAndStudentsInfo';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import GetPlannedActivityWithWorkGroupAndStudentsService from '@modules/class-planner/services/GetPlannedActivityWithWorkGroupAndStudentsService';
import { Container } from './styles';
import { useActivity } from '../../../activities-library/context/ActivityContext';
import { ActivityLibraryForm } from '../../../activities-library/components/ActivityLibraryForm';
import { isDailyClassEditDisable } from '../../utils/isDailyClassEditDisable';

const ClassPlannerPlannedActivityInfo: React.FC = () => {
  const {
    availableStudents,
    availableWorkGroups,
    setAvailableStudents,
    setAvailableWorkGroups,
    setPlannedActivityCurrent,
    plannedActivityCurrent,
    plannedActivities,
  } = useScheduleGrid();

  const { classId, dailyClassId, plannedActivityId, activityId } = useParams<{
    dailyClassId: string;
    activityId: string;
    classId: string;
    plannedActivityId: string;
  }>();

  const [selectedStudents, setSelectedStudents] = useState<IStudent[]>([]);
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { setActivityLibraryCurrent } = useActivity();
  const { isLoading, setIsLoading, handleDownloadAllFiles, handleDownloadSingleFile } = useGlobal();
  const { dailyClass } = useScheduleGrid();
  const isButtonDisable = isDailyClassEditDisable({ dailyClass });

  const getActivityWithWorkGroupsAndStudents = useCallback(async () => {
    if (!plannedActivityId || !classId) return;

    setIsLoading(true);
    const res = await new GetPlannedActivityWithWorkGroupAndStudentsService(signedUser?.token).execute({
      planned_activity_id: plannedActivityId,
      class_id: classId,
    });
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Recuperar Atividade.',
      });
      navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`);
      return;
    }

    if (!isMounted()) return;

    const { students, workGroups, plannedActivity } = res;

    const found = plannedActivities?.in_calendar.find((pa) => Number(pa.planned_activity_id) === Number(plannedActivity?.id));
    setSelectedStudents(found?.planned_activity.students || []);
    setPlannedActivityCurrent(plannedActivity);
    setActivityLibraryCurrent(plannedActivity?.activity_data);
    setAvailableStudents(students);
    setAvailableWorkGroups(workGroups);
  }, [
    addToast,
    classId,
    dailyClassId,
    isMounted,
    navigate,
    plannedActivities,
    plannedActivityId,
    setActivityLibraryCurrent,
    setAvailableStudents,
    setAvailableWorkGroups,
    setIsLoading,
    setPlannedActivityCurrent,
    signedUser,
  ]);

  const handleOnClickEdit = () => {
    setActivityLibraryCurrent(plannedActivityCurrent?.activity_data);
    navigate(
      `/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${activityId}/editar/atividade-planejada/${plannedActivityId}`
    );
  };

  useEffect(() => {
    if (!plannedActivities?.in_calendar) return navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`);
  }, [classId, dailyClassId, navigate, plannedActivities]);

  useEffect(() => {
    getActivityWithWorkGroupsAndStudents();
  }, [getActivityWithWorkGroupsAndStudents]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`}
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        {plannedActivityId === plannedActivityCurrent?.id && (
          <>
            <ActivityLibraryForm
              downlodSingleFileCallback={handleDownloadSingleFile}
              downloadAllFilesCallback={() => handleDownloadAllFiles(plannedActivityCurrent?.activity_data.attachments)}
              errors={[]}
              formAction="watching"
            />

            <Divider width="100%" />
            {availableStudents && availableWorkGroups && (
              <WorkGroupAndStudentsInfo selectedStudents={selectedStudents} setSelectedStudents={setSelectedStudents} formAction="watching" />
            )}
          </>
        )}
        <PageFooter>
          <Button transform="none" shaded onClick={handleOnClickEdit} className="mochileiro-color" disabled={isButtonDisable}>
            Editar Atividade
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export { ClassPlannerPlannedActivityInfo };
