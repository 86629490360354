import React from 'react';

import LayoutApplication from '@modules/applications/components/LayoutApplication';

import ApplicationResumeContainer from '../../components/ApplicationResumeContainer';

const ApplicationResume: React.FC = () => {
  return (
    <LayoutApplication openTab={0}>
      <ApplicationResumeContainer />
    </LayoutApplication>
  );
};

export default ApplicationResume;
