import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import GroupCard from '@modules/groups/components/GroupCard';
import UserCard from '@modules/users/components/UserCard';
import CustomForm from '@shared/components/CustomForm';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import InputPill from '@shared/components/InputPill';
import PageFooter from '@shared/components/PageFooter';
import ParentRender from '@shared/components/ParentRender';
import { Button, Input, Spinner, Textarea, formatDate, useToast } from 'react-alicerce-components';
import { Container } from './styles';
import Icon from 'react-eva-icons';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { GetWorkedHourService } from '@modules/worked-hours/services/GetWorkedHourService';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { ApproveWorkedHourService } from '@modules/worked-hours/services/ApproveWorkedHourService';
import { ReproveWorkedHourService } from '@modules/worked-hours/services/ReproveWorkedHourService';
import ConfirmModal from '@shared/components/ConfirmModal';
import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';
import ConfirmModalJustification from '@modules/worked-hours/components/ConfirmModalJustification';
import ClassCard from '../../../classes/components/ClassCard';


export const WorkedHoursApprove: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const { addToast } = useToast();
  const { isLoading, setIsLoading } = useGlobal();
  const [workedHour, setWorkedHour] = useState<IWorkedHour>();
  const [showModalApprove, setShowModalApprove] = useState<boolean>(false);
  /*   const [showModalReprove, setShowModalReprove] = useState<boolean>(false); */
  const [showModalJustification, setShowModalJustification] = useState<boolean>(false);

  const getWorkedHour = useCallback(async () => {
    if (!signedUser || !id) return;
    setIsLoading(true);
    const res = await new GetWorkedHourService(signedUser.token).execute(id);
    if (res && isMounted()) setWorkedHour(res);
    isMounted() && setIsLoading(false);
  }, [id, isMounted, setIsLoading, signedUser]);

  useEffect(() => {
    getWorkedHour();
  }, [getWorkedHour]);

  const approveWorkedHour = useCallback(async () => {
    if (!signedUser || !workedHour?.id) return;
    setIsLoading(true);
    const res = await new ApproveWorkedHourService(signedUser.token).execute(workedHour.id, signedUser.lmsUser.id);
    if (!res) {
      setIsLoading(false);
      return addToast({
        status: 'danger',
        title: 'Horas Trabalhadas',
        description: `Houve um problema ao aprovar.`,
      });
    }

    addToast({
      status: 'success',
      title: 'Horas Trabalhadas',
      description: 'Hora trabalhada aprovada com sucesso.',
    });
    setIsLoading(false);
    navigate(-1);
  }, [addToast, navigate, setIsLoading, signedUser, workedHour]);

  const reproveWorkedHour = useCallback(async () => {
    console.log(workedHour, '<<<<<<<<<')
    if (!signedUser || !workedHour?.id || !workedHour?.justification) return;
    setIsLoading(true);
    const res = await new ReproveWorkedHourService(signedUser.token).execute(workedHour.id, signedUser.lmsUser.id, workedHour.justification);
    if (!res) {
      setIsLoading(false);
      return addToast({
        status: 'danger',
        title: 'Horas Trabalhadas',
        description: `Houve um problema ao reprovar.`,
      });
    }

    addToast({
      status: 'success',
      title: 'Horas Trabalhadas',
      description: 'Hora trabalhada reprovada com sucesso.',
    });
    setIsLoading(false);
    navigate(-1);
  }, [addToast, navigate, setIsLoading, signedUser, workedHour]);

  const handleInputChange = useCallback((keyName: string, keyValue: string | null) => {
    setWorkedHour((oldState) => {
      return { ...oldState, [keyName]: keyValue };
    });
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aprovar Horas Trabalhadas"
        status="primary"
        backgroundHeader="linear-gradient(284.92deg, #F47920 0%, #0097B1 100%);"
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        {workedHour && workedHour.issuer && workedHour.group && (
          <CustomForm status="primary">
            <InputPill label="Modalidade da Categoria" inputPillData={workedHour?.category ? [workedHour?.category.name] : []} />
            <InputPill label="Modalidade da Categoria" inputPillData={workedHour?.modality ? [workedHour?.modality.name] : []} />
            <InputPill label="Projeto" inputPillData={workedHour?.project ? [workedHour?.project.name] : []} />
            <Textarea name="description" label="Detalhes da Hora:" value={workedHour?.description} rows={4} disabled />
            <ParentRender label="Nome do Usuário:" hasParent={true}>
              <UserCard user={workedHour?.issuer} canSelect={false} isInputCard={true} />
            </ParentRender>
            <ParentRender label="Grupo Relacionado:" hasParent={true}>
              <GroupCard inputCard={true} group={workedHour?.group} customStyle={{ margin: '1rem' }} />
            </ParentRender>
            {workedHour?.class &&
              <ParentRender label="Turma Relacionado:" hasParent={true} required={false}>
                <ClassCard inputCard={true} classInfo={workedHour?.class} customStyle={{ margin: '1rem' }} />
              </ParentRender>
            }
            <Input label="Data da Hora Lançada:" name="date" disabled defaultValue={workedHour ? formatDate(new Date(`${workedHour?.date}`), 'long') : ''} />
            <div className="container-add-hours">
              <Input
                label="Horas Adicionadas:"
                name="hours"
                mask="##:##"
                placeholder="00:00h"
                defaultValue={convertMinutesToHour(workedHour?.total_minutes) + 'h'}
                end={<Icon name="clock" fill="#8F9BB3" />}
                readOnly
              />
            </div>
          </CustomForm>
        )}
      </DirectoryContainer>

      <PageFooter>
        <ButtonGradientOutline shaded={true} onClick={() => setShowModalJustification(true)} status="aviador">
          Reprovar
        </ButtonGradientOutline>
        <Button transform="none" status="primary" statusNumber={400} shaded onClick={() => setShowModalApprove(true)}>
          Aprovar
        </Button>
      </PageFooter>

      <ConfirmModalJustification
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalJustification}
        closeCallback={() => setShowModalJustification(false)}
        subTitle={'Justifique a Reprovação:'}
        title="Deseja Reprovar Hora?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={reproveWorkedHour}
        justification={(event) => handleInputChange('justification', event)}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalApprove}
        closeCallback={() => setShowModalApprove(false)}
        title="Deseja Aprovar este lançamento?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={approveWorkedHour}
      />
    </Container>
  );
};
