export const CHECKPOINT = { name: 'Checkpoint', value: 'checkpoint' };
export const PDV = { name: 'PDV', value: 'pdv' };

export const ACTIVITY_PORTFOLIO_TYPE_CONSTRAINTS = [CHECKPOINT, PDV];

export const defaultActivityPortfolioType = (activityPortfolioType?: string | null) => {
  return [
    { name: CHECKPOINT.name, value: CHECKPOINT.value, selected: activityPortfolioType === CHECKPOINT.value },
    { name: PDV.name, value: PDV.value, selected: activityPortfolioType === PDV.value },
  ];
};
