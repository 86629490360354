import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IProject from '@modules/projects/interfaces/IProject';
import IListOptions from '@shared/interfaces/IListOptions';

export interface IProjectResponse extends IPaginatedResponse {
  items: IProject[];
}

export interface IListProjectOptions extends IListOptions {
  sprint_id?: number;
  period_id?: number;
  type?: string;
}
class ListProjectsHeadlightsByGroupService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(groupId: string, options: IListProjectOptions): Promise<IProjectResponse | false> {
    try {
      let searchParams = objectToQuery({ ...options }, true);
      const res = await this.api.get(`/project/headlights/group/${groupId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListProjectsHeadlightsByGroupService;
