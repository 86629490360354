import { ISelectOption } from 'react-alicerce-components';

export const STUDENT_CANCELLATION_REASON_STUDENT_OR_FAMILY = { name: 'Pedido da Família/Aluno', value: 'student_family_request' };
export const STUDENT_CANCELLATION_REASON_PARTNET_CLOSING = { name: 'Pedido do Parceiro', value: 'partner_closing' };
export const STUDENT_CANCELLATION_REASON_ALICERCE = { name: 'Pedido do Alicerce', value: 'request_alicerce' };
export const STUDENT_CANCELLATION_REASON_ARREARS = { name: 'Inadimplência', value: 'request_arrears' };
export const STUDENT_CANCELLATION_REASON_END_OF_PROJECT = { name: 'Término do projeto', value: 'end_of_project' };
export const STUDENT_CANCELLATION_REASON_UNIT_COLSE = { name: 'Fechamento da Unidade', value: 'unit_close' };

export const STUDENT_CANCELLATION_TYPES = [
  STUDENT_CANCELLATION_REASON_STUDENT_OR_FAMILY,
  STUDENT_CANCELLATION_REASON_PARTNET_CLOSING,
  STUDENT_CANCELLATION_REASON_ALICERCE,
  STUDENT_CANCELLATION_REASON_ARREARS,
  STUDENT_CANCELLATION_REASON_END_OF_PROJECT,
  STUDENT_CANCELLATION_REASON_UNIT_COLSE,
];

export const STUDENT_CONLUSION_TYPES = [
  STUDENT_CANCELLATION_REASON_PARTNET_CLOSING,
  STUDENT_CANCELLATION_REASON_ALICERCE,
  STUDENT_CANCELLATION_REASON_END_OF_PROJECT,
];

export const defaultCancellationReason = (studentCancellationReason?: string | null): ISelectOption[] | undefined => {
  return STUDENT_CANCELLATION_TYPES.map((foo) => {
    return { name: foo.name, value: foo.value, selected: studentCancellationReason === foo.value };
  });
};

export const defaultConclusionReason = (studentConslusionReason?: string | null): ISelectOption[] | undefined => {
  return STUDENT_CONLUSION_TYPES.map((foo) => {
    return { name: foo.name, value: foo.value, selected: studentConslusionReason === foo.value };
  });
};
