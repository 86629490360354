import { css, styled } from 'react-alicerce-components';

const columnWidth = '5.625rem';
const gap = '.5rem';

export const Container = styled.div<{ totalColumnsFixed: any[]; canSelect?: boolean }>`
  overflow: auto;
  width: 100vw;
  height: 100%;

  table {
    width: 100vw;
    position: relative;
  }

  tr:not(.over-column) {
    td,
    th,
    span {
      width: ${columnWidth};
    }

    span {
      word-break: break-word;
    }

    th {
      border-radius: 5px;
      padding: 4px 0;
      font-size: 12px;
      background-color: #c5cee0;
      color: #222b45;
      text-align: center;
      cursor: pointer;

      span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 1rem;
        margin: 0 auto;

        &:after {
          content: '⌄';
          font-size: 20px;
          bottom: unset;
          right: 16px;
          position: absolute;
          height: 100%;
          transform: rotate(270deg);
        }
      }
    }
  }

  tr + tr,
  tr:not(.over-column) + tr {
    margin-top: ${gap};
  }

  tr:not(.over-column) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80px;

    th + th,
    td + td {
      margin-left: ${gap};
    }

    td {
      height: 100%;
      background: #fff;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 12px;
        height: 100%;
        background-color: #edf1f7;
        color: #222b45;
        font-weight: bold;
        padding: 10px;
      }
    }
  }

  thead tr:not(.over-column) {
    margin-bottom: calc(${gap} / 2);
    font-weight: bold;
    height: 100%;
    align-items: flex-end;
  }

  thead tr.over-column {
    display: flex;

    th:first-child {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  ${({ totalColumnsFixed }) =>
    totalColumnsFixed.map(
      (_a, i) => `
            
                th:nth-child(${i + 1}) {
                    background-color: #8F9BB3;
                    color: #fff;
                }
                
                tr.over-column {
                    th:nth-child(${i + 1}) {
                        position: unset;
                    }
                }
              

                tr:not(.over-column) {
                    td:nth-child(${i + 1}),
                    th:nth-child(${i + 1}) {
                        &:before,
                        &:after {
                            content: "";
                            background-color: #ffff;
                            position: absolute;
                            left: -${gap};
                            right: 0;
                            bottom: -${gap};
                            height: ${gap};
                        }
                        
                        &:after {
                            bottom: -${gap};
                        }

                        &:before {
                            top: -${gap};
                        }
                    }

                    th:nth-child(${i + 1}) {
                        &:after {
                            bottom: -${gap};
                        }

                        &:before {
                            top: -65px;
                            height: 65px;
                        }
                    }
                }

             

                td:nth-child(${i + 1}) {
                    span {
                        text-align: center;
                    }
                }

                th:nth-child(${i + 1}),
                td:nth-child(${i + 1}) {
                    position:sticky;
                    z-index:2;
                    width: ${columnWidth};
                    left: ${i === 0 ? 0 : columnWidth};
                }
                
                tr.contain-sub-items td:nth-child(${i + 1}) span {
                    background-color: #C5CEE0;

                    &:after {
                        content: '⌄';
                        font-size: 20px;
                        position: absolute;
                        right: 6px;
                        height: fit-content;
                        top: 25%;
                    }
                }

                tr.contain-sub-items.opened td:nth-child(${i + 1}) span {
                    &:after {
                        top: 40%;
                        transform: rotate(180deg)!important;
                    }
                }

            `
    )};

  tr:not(.over-column).selected {
    ${({ canSelect, totalColumnsFixed }) =>
      canSelect &&
      totalColumnsFixed.map(
        (a, i) => `
                td:nth-child(${i + 1}) {
                    span {
                        background-color: #8CFAC7;
                        color: #fff;
                    }
                }
            `
      )};
  }

  tbody tr {
   cursor: ${({ canSelect }) => canSelect ? 'pointer' : 'auto'};
  }
`;

export const TData = styled.td<{ bgColor?: string; groupsWidth?: boolean }>`
  ${({ bgColor, groupsWidth }) => css`
  span {
      display: block ${groupsWidth && '!important'};
      transition: all 0.5s;
      background-color: ${bgColor} !important;
      color: #2e3a59 !important;
      height: 5rem !important;
      width: 200px ${groupsWidth && '!important'};
      overflow-y: auto ${groupsWidth && '!important'};
      position: absolute ;
    }
  `}
`;

export const THead = styled.th<{ isSorted: boolean; sortByAsc?: boolean; notCanSort?: boolean; groupsWidth?: boolean }>`
  ${({ groupsWidth }) =>
    groupsWidth &&
    `
width: 200px !important;`}
  ${({ notCanSort }) =>
    notCanSort &&
    `
        span {
            &:after {
                content: '' !important;
            }
        }
    `}

  /* 
                    top: -12px; */
    ${({ isSorted, sortByAsc }) =>
    isSorted &&
    `
        span {
            &:after {
                right: 6px !important;
                transform: ${sortByAsc ? 'rotate(0deg)' : 'rotate(180deg)'} !important;
                bottom: ${sortByAsc ? 'unset' : '-12px'} !important;
                top: ${sortByAsc ? '-12px' : 'unset'} !important;
            }
        }    
    `}
`;

export const THeadOverGroup = styled.th<{ quantityColumns: number; isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: transparent !important;
  width: calc((${columnWidth} * ${({ quantityColumns }) => quantityColumns}) + (${gap})) !important;

  ${({ isShow, quantityColumns }) =>
    isShow &&
    quantityColumns &&
    `
        background-color: #C5CEE0 !important;
        border-radius: 5px !important;
        font-size: 10px !important;
        font-weight: 800 !important;
        color: #222B45 !important;
        margin-left: ${gap} !important;
        width: calc((${columnWidth} * ${quantityColumns}) + (${gap} + ${gap})) !important;
    `}

  ${({ isShow }) =>
    !isShow &&
    `
        margin-left: ${gap};
        margin-right: ${gap};
    `}
`;
