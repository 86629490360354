import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { HeadlightStatusOptions } from '@modules/projects/schemas/Headlight/partials/HeadlightStatus';

export interface IHeadlightCategories {
  id: number,
  type: string,
  status: {
    status: HeadlightStatusOptions
    description: string
  },
  category: string,
  description: string
}

export interface IListHeadlightCategoriesOptions {
  type?: string
}
class ListHeadlightCategoriesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(options: IListHeadlightCategoriesOptions): Promise<IHeadlightCategories[] | false > {
    try {
      let searchParams = objectToQuery({ ...options }, true);
      const res = await this.api.get(`/headlight/categories?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListHeadlightCategoriesService;
