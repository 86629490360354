import IRoute from "@shared/interfaces/IRoute";

import { combineContext } from "@shared/utils/combineContext";
import { IModuleRoutes } from "@shared/interfaces/IModuleRoutes";
import { contractsKeys } from "@modules/contracts/keys";

import { ContractsProvider } from "@modules/contracts/context/ContractContext";

import ContractDirectory from "@modules/contracts/views/ContractDirectory";
import ContractCreate from "@modules/contracts/views/ContractCreate";
import ContractEdit from "@modules/contracts/views/ContractEdit";
import ContractInfo from "@modules/contracts/views/ContractInfo";
import ContractProjects from "@modules/contracts/views/ContractProjects";
import BeforeContracts from "@modules/contracts/views/BeforeContracts";

const routes: IRoute[] = [
  {
    path: "/diretorio/contratos",
    key: "CONTRACTS_PATH",
    exact: true,
    component: ContractDirectory,
    permissions: [
      contractsKeys.CONTRACT_READ,
    ]
  },
  {
    path: "/diretorio/contratos/criar",
    key: "CONTRACTS_CREATE_PATH",
    exact: true,
    component: ContractCreate,
    permissions: [
      contractsKeys.CONTRACT_CREATE,
    ]
  },
  {
    path: "/contrato/:id/editar",
    key: "CONTRACTS_EDIT_PATH",
    exact: true,
    component: ContractEdit,
    permissions: [
      contractsKeys.CONTRACT_UPDATE,
    ]
  },
  {
    path: "/contrato/:id/informacoes",
    key: "CONTRACTS_INFO_PATH",
    exact: true,
    component: ContractInfo,
    permissions: [
      contractsKeys.CONTRACT_READ,
    ]
  },
  {
    path: "/contrato/:id/projetos",
    key: "CONTRACTS_PROJECTS_PATH",
    exact: true,
    component: ContractProjects,
    permissions: [
      contractsKeys.CONTRACT_READ,
    ]
  },
  {
    path: "/contrato/:id/contratos-anteriores",
    key: "CONTRACTS_BEFORE_CONTRACTS_PATH",
    exact: true,
    component: BeforeContracts,
    permissions: [
      contractsKeys.CONTRACT_READ,
    ]
  }
];

const providers = [
  ContractsProvider
];

export default {
  key: 'CONTRACT_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
