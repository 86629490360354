import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { Button, H6, Input, InputDatePicker, Label, Select, Spinner, Textarea, useToast } from 'react-alicerce-components';
import { Container } from './styles';
import PageFooter from '@shared/components/PageFooter';
import { beforeSubmitForm, errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import CustomForm from '@shared/components/CustomForm';
import GroupCard from '@modules/groups/components/GroupCard';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import ParentRender from '@shared/components/ParentRender';
import Icon from 'react-eva-icons';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import DashedBox from '@shared/components/DashedBox';
import { useNavigate } from 'react-router-dom';
import { workedHourSchema } from '@modules/worked-hours/utils/workedHourSchema';
import ConfirmModal from '@shared/components/ConfirmModal';
import { formatWorkedHour } from '@modules/worked-hours/utils/formatWorkedHour';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ListTagsService from '@modules/tags/services/ListTagsService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { CreateWorkedHourService } from '@modules/worked-hours/services/CreateWorkedHourService';
import { ICategoryHours, IWorkedHourTypes, useWorkedHour } from '@modules/worked-hours/context/WorkedHourContext';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import Divider from '@shared/components/Divider';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import ITag from '../../../tags/interfaces/ITag';
import { formatTagsSelect } from '../../utils/formatTagsSelect';
import ClassCard from '../../../classes/components/ClassCard';
import { datePickerFormat } from '@shared/utils/datePickerFormat';
import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';
import Tag from '@modules/tags/components/Tag';
import { SelectProjectTagContainer } from '@modules/worked-hours/views/WorkedHoursCreateByGroup/styles';

const categoriesToRenderGroupTags = ['Coordenador', 'Gestor']

export const WorkedHoursCreate: React.FC = () => {

  const navigate = useNavigate();

  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const isMounted = useIsMounted();
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const { clearWorkedHourState, workedHourCurrent, setWorkedHourCurrent, setSelectTags, selectTags } = useWorkedHour();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const [classProjectTags, setClassProjectTags] = useState<ITag[]>([]);
  const [groupProjectTags, setGroupProjectTags] = useState<ITag[]>([]);

  useEffect(() => {
    const groupFilteredTags = workedHourCurrent.group?.tags?.filter((t) => t.type === 'project');
    const classFilteredTags = workedHourCurrent.class?.tags?.filter((t) => t.type === 'project');

    setGroupProjectTags(groupFilteredTags || []);
    setClassProjectTags(classFilteredTags || []);

    if (workedHourCurrent.category?.name  && categoriesToRenderGroupTags.includes(workedHourCurrent.category.name)) {
      setWorkedHourCurrent(oldState => {return {...oldState, project: undefined, project_id: undefined}})
    }
  }, [workedHourCurrent.group, workedHourCurrent.class, workedHourCurrent.category, setWorkedHourCurrent]);

  useEffect(() => {
    if (workedHourCurrent.date_hour || !workedHourCurrent.date) return;
    const dateFormated = datePickerFormat(workedHourCurrent.date, '/', '-');
    const minuteToHours = convertMinutesToHour(workedHourCurrent.total_minutes);
    const newDateHourFormat = { date: dateFormated, total_hours: minuteToHours };

    setWorkedHourCurrent((oldState: any) => {
      if (!oldState.date_hour) return { ...oldState, date_hour: [newDateHourFormat] };
      return { ...oldState, date_hour: [...oldState.date_hour, newDateHourFormat] };
    });
  }, [setWorkedHourCurrent, workedHourCurrent.date, workedHourCurrent.date_hour, workedHourCurrent.total_minutes]);

  const renderGroupTags = useMemo(() => {
    if ( workedHourCurrent.category?.name && workedHourCurrent.group) {
      if (categoriesToRenderGroupTags.includes(workedHourCurrent.category.name)) return true
    }

    return false
  }, [workedHourCurrent])

  const handleDateChange = useCallback(
    (date: string, inputName, key: string, index: number) => {
      setWorkedHourCurrent((oldState: any) => {
        let aux = oldState[key];
        aux[index][inputName] = date;
        return { ...oldState, [key]: aux };
      });
    },
    [setWorkedHourCurrent]
  );

  const createWorkedHour = useCallback(async () => {
    if (!signedUser) return;
    setShowCreateModal(false);
    let formatedBody = formatWorkedHour(workedHourCurrent, signedUser.lmsUser.id);

    setIsLoading(true);
    const res = await new CreateWorkedHourService(signedUser.token).execute(formatedBody);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Horas Trabalhadas',
        description: `Houve um problema ao lançar hora.`,
      });
    }

    addToast({
      status: 'success',
      title: 'Horas Trabalhadas',
      description: 'Hora lançada com sucesso.',
    });

    clearWorkedHourState();
    navigate('/horas-trabalhadas/informacoes/aprovados');
    return;
  }, [signedUser, setIsLoading, workedHourCurrent, addToast, clearWorkedHourState, navigate]);

  const addNewHour = useCallback(() => {
    const newDateHourFormat = { date: '', total_hours: '' };
    setWorkedHourCurrent((oldState: any) => {
      if (!oldState.date_hour) return { ...oldState, date_hour: [newDateHourFormat] };
      return { ...oldState, date_hour: [...oldState.date_hour, newDateHourFormat] };
    });
  }, [setWorkedHourCurrent]);

  const removeNewHour = useCallback(
    (index: number, arrayToRemove: any[] = []) => {
      const newWorkedHours = removeItemFromArrayByIndex(index, arrayToRemove);
      setWorkedHourCurrent((oldState: IWorkedHour) => {
        return { ...oldState, date_hour: newWorkedHours };
      });
    },
    [setWorkedHourCurrent]
  );

  const handleClickSave = useCallback(async () => {
    if (!signedUser) return;
    let formatedBody = formatWorkedHour(workedHourCurrent, signedUser.lmsUser.id);

    let formErros = await beforeSubmitForm(formatedBody, workedHourSchema);

    workedHourCurrent.date_hour?.forEach((hour, index) => {
      if (!hour) return formErros = [...formErros, { name: `total_hours_${index}`, message: 'O campo é obrigatório' }]
 
      const minutes = hour.total_hours.split(':').map(Number)[1]

      if (minutes === undefined || null) formErros = [...formErros, { name: `total_hours_${index}`, message: 'Valor inválido' }]
      if (minutes > 59) formErros = [...formErros, { name: `total_hours_${index}`, message: 'O valor dos minutos deve estar entre 00 e 59' }]
    })

    if (selectTags?.modality && !workedHourCurrent.modality) {
      formErros = [...formErros, { name: 'modality_tag_id', message: 'O campo é obrigatório' }];
    }

    setErros(formErros);
    if (formErros.length === 0) setShowCreateModal(true);
  }, [signedUser, workedHourCurrent, selectTags]);

  const handleOnClickReturn = () => {
    clearWorkedHourState();
    navigate('/horas-trabalhadas/informacoes/aprovados');
    return;
  };

  const handleChangeInput = useCallback(
    (newValue: string, objKey: string) => {
      const value = !!newValue.length ? newValue : undefined;
      setWorkedHourCurrent((oldState: IWorkedHour) => {
        return { ...oldState, [objKey]: value };
      });
    },
    [setWorkedHourCurrent]
  );

  const selectTagsStateManagement = useCallback(
    (workedHourType: IWorkedHourTypes, tags: ITag[]) => {
      const foundTags = tags && tags.length > 0 ? tags : undefined;

      if (workedHourType === 'category') {
        setSelectTags({ category: foundTags });
      }

      if (workedHourType === 'modality') {
        setSelectTags((oldState: ICategoryHours) => {
          return { ...oldState, modality: foundTags };
        });
      }
    },
    [setSelectTags]
  );

  const loadTags = useCallback(
    async (search: string = '', page: number = 0, workedHourType: IWorkedHourTypes, parentId?: string) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListTagsService(signedUser.token, newCancelToken()).execute(search, {
        type: 'work_hour',
        parent_id: parentId,
        node: parentId ? undefined : 1,
        page,
        size: 9999,
      });
      setIsLoading(false);

      if (res === 'canceling' || !isMounted()) return;
      if (!res) return console.log('Error ao listar Tags');

      selectTagsStateManagement(workedHourType, res.items);
    },
    [isMounted, newCancelToken, selectTagsStateManagement, setIsLoading, signedUser]
  );

  const handleSelectChange = useCallback(
    (option, selectName: string, workedHourType: IWorkedHourTypes) => {
      if (workedHourType === 'category') {
        setWorkedHourCurrent((oldState: IWorkedHour) => {
          const foundTag = selectTags?.category?.find((tag) => Number(tag.id) === Number(option.value));
          if (!oldState) return { [selectName]: [option.value] };
          oldState['category_tag_id'] = option.value;
          oldState['category'] = foundTag;
          oldState['modality_tag_id'] = undefined;
          oldState['modality'] = undefined;
          return { ...oldState };
        });
        loadTags('', 0, 'modality', option.value);
      }

      if (workedHourType === 'modality') {
        setWorkedHourCurrent((oldState: IWorkedHour) => {
          const foundTag = selectTags?.modality?.find((tag) => Number(tag.id) === Number(option.value));
          if (!oldState) return { [selectName]: [option.value] };
          oldState['modality_tag_id'] = option.value;
          oldState['modality'] = foundTag;
          return { ...oldState };
        });
      }
    },
    [loadTags, selectTags, setWorkedHourCurrent]
  );

  const handleRemoveClass = useCallback(() => {
    setWorkedHourCurrent((oldState: IWorkedHour) => {
      return { ...oldState, class: undefined, class_id: undefined };
    });
  }, [setWorkedHourCurrent]);

  const handleOnSelectProjectTag = (clickedTag: ITag) => {
    setWorkedHourCurrent((oldState: IWorkedHour) => {
      oldState.project = oldState.project?.id === clickedTag.id ? undefined : clickedTag;
      oldState.project_tag_id = oldState.project?.id === clickedTag.id ? undefined : Number(clickedTag.id);
      return { ...oldState };
    });
  };

  useEffect(() => {
    !selectTags?.category && loadTags(undefined, 0, 'category');
  }, [loadTags, selectTags]);

  return (
    <Container>
      <HeaderDirectory
        title="Lançar Horas Trabalhadas"
        status="primary"
        backgroundHeader="linear-gradient(284.92deg, #F47920 0%, #0097B1 100%);"
        onClickReturn={handleOnClickReturn}
      />
      {isLoading && <Spinner />}
      <DirectoryContainer footer={true}>
        <CustomForm customInputColorText="#FF9F6D" style={{marginBottom: '20px'}}>
          {selectTags?.category && (
            <Select
              label="Categoria da Horas:"
              iconName="arrow-down"
              placeholder="Selecione"
              required
              handleSelectChange={(option) => handleSelectChange(option, 'category_tag_id', 'category')}
              options={formatTagsSelect(selectTags.category, workedHourCurrent.category_tag_id)}
              error={errorMessageBuilder('category_tag_id', errors)}
            />
          )}

          {selectTags?.modality && (
            <Select
              label="Modalidade da Categoria:"
              iconName="arrow-down"
              required
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'modality_tag_id', 'modality')}
              customStyle={{ marginTop: '1rem' }}
              options={formatTagsSelect(selectTags?.modality, Number(workedHourCurrent?.modality?.id))}
              error={errorMessageBuilder('modality_tag_id', errors)}
            />
          )}

          <ParentRender
            label="Turma Relacionada"
            hasParent={!!workedHourCurrent.class}
            handleOnClick={() => navigate('/horas-trabalhadas/criar/selecionar/turmas?redirect_to=/horas-trabalhadas/criar')}
            error={errorMessageBuilder('class', errors)}
            required={false}
          >
            {workedHourCurrent.class && (
              <ClassCard
                inputCard={true}
                classInfo={workedHourCurrent.class}
                customStyle={{ margin: '1rem' }}
                canDeleteCard={{ removeCallback: () => handleRemoveClass() }}
              />
            )}
          </ParentRender>

          {workedHourCurrent.class && (
            <Fragment>
              <Label>Clique para escolher uma Tag de Projeto disponivel nessa Turma:</Label>
              <SelectProjectTagContainer>
                {classProjectTags.map(
                  (tag) =>
                    tag.type === 'project' && (
                      <Tag
                        style={{
                          borderRadius: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          filter: `${tag.id === workedHourCurrent.project?.id ? 'drop-shadow(0px 0px 8px #269345)' : 'none'}`,
                        }}
                        key={tag.id}
                        tag={tag}
                        handleOnClick={() => handleOnSelectProjectTag(tag)}
                      />
                    )
                )}
              </SelectProjectTagContainer>
            </Fragment>
          )}

          <Textarea
            name="description"
            label="Detalhes da Hora:"
            placeholder="Adicione Detalhes da Hora"
            value={workedHourCurrent.description}
            rows={4}
            onChange={(event) => handleChangeInput(event.target.value, event.target.name)}
            useTooltipError={false}
            error={errorMessageBuilder('description', errors)}
          />

          <ParentRender
            label="Grupo Relacionado"
            hasParent={!!workedHourCurrent.group}
            handleOnClick={() => navigate('/horas-trabalhadas/criar/selecionar/grupos')}
            error={errorMessageBuilder('group', errors)}
          >
            {workedHourCurrent.group && <GroupCard inputCard={true} group={workedHourCurrent.group} customStyle={{ margin: '1rem' }} />}
          </ParentRender>

          {renderGroupTags && (
              <Fragment>
                <Label>Clique para escolher uma Tag de Projeto disponivel nesse Grupo:</Label>
                <SelectProjectTagContainer>
                  {groupProjectTags.map(
                    (tag) =>
                      tag.type === 'project' && (
                        <Tag
                          style={{
                            borderRadius: '4px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            filter: `${tag.id === workedHourCurrent.project?.id ? 'drop-shadow(0px 0px 8px #269345)' : 'none'}`,
                          }}
                          key={tag.id}
                          tag={tag}
                          handleOnClick={() => handleOnSelectProjectTag(tag)}
                        />
                      )
                  )}
                </SelectProjectTagContainer>
              </Fragment>
          )}

          <Divider width="100%" />

          <H6 fontWeight={800} status="primary">
            Data e Horas
          </H6>

          <ButtonAddCard text="Inserir Dia e Hora" handleOnClick={() => addNewHour()} />
          <InputErrorMessage error={errorMessageBuilder('date_hour', errors)} />

          {workedHourCurrent?.date_hour?.map((dh, index) => (
            <DashedBox
              handleDeleteCallback={() => removeNewHour(index, workedHourCurrent.date_hour)}
              key={`${index}-${workedHourCurrent.date_hour?.length}`}
            >
              <InputDatePicker
                handleChangeDate={(date) => handleDateChange(date, 'date', 'date_hour', index)}
                disabled
                defaultValue={dh.date}
                input={{
                  name: 'date',
                  label: 'Data da Hora:',
                  placeholder: 'Dia/Mês/Ano',
                  useTooltipError: false,
                }}
              />
              {<InputErrorMessage customStyles={{ position: 'relative', top: '-25px' }} error={errorMessageBuilder('date', errors)} />}

              <div className="container-add-hours">
                <Input
                  label="Adicione Horas:"
                  name="total_hours"
                  placeholder="00:00h"
                  onChange={(event) => handleDateChange(event.target.value, event.target.name, 'date_hour', index)}
                  defaultValue={dh.total_hours}
                  error={errorMessageBuilder(`total_hours_${index}`, errors)}
                  end={<Icon name="clock" fill="#8F9BB3" />}
                  mask="##:##"
                  useTooltipError={false}
                />
              </div>
            </DashedBox>
          ))}
        </CustomForm>

        <PageFooter>
          <Button transform="none" status="primary" statusNumber={400} shaded onClick={handleClickSave}>
            Lançar Horas
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Lançar horas?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={createWorkedHour}
      />
    </Container>
  );
};
