import React, { useCallback } from 'react';
import Icon from 'react-eva-icons';

import { ProgramCardContainer } from './styles';
import ProgramCardBorder from '@modules/activities-library/components/ProgramCard/ProgramCardBorder';
import { ProgramCardBody } from '@modules/activities-library/components/ProgramCard/ProgramCardBody';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';
import { ACTIVITY_PROGRAM_STATUS_CONSTRAINTS } from '@modules/activities-library/utils/validActivityStatus';

interface IProgramCardProps {
  program?: IProgram;
  className?: string;
  canDeleteCard?: { indexId: number; removeCallback: (indexId: number) => void };
}

const ProgramCard: React.FC<IProgramCardProps> = (props) => {
  const buildProgramHeaderData = useCallback((program: IProgram): string[] => {
    const cardHeaderData: string[] = [];
    const count = program.activity_count ? program.activity_count : 0;
    cardHeaderData.push(`Número de Atividades: ${count} Atividade${count !== 1 ? 's' : ''} `);
    program.status && cardHeaderData.push(`Status: ${ACTIVITY_PROGRAM_STATUS_CONSTRAINTS.find((st) => st.value === program.status)?.name}`);
    return cardHeaderData;
  }, []);

  const buildProgramFooterData = useCallback((program: IProgram): string[] => {
    if (!program.tags || program.tags.length === 0) return [];
    return program.tags.map((tag) => tag.name || '');
  }, []);

  return (
    <ProgramCardContainer className={props.className || ``}>
      <div className="card-container">
        <ProgramCardBorder data={props.program ? buildProgramHeaderData(props.program) : []} location="header" />
        <ProgramCardBody>
          <b>{props.program?.title}</b>
        </ProgramCardBody>
        <ProgramCardBorder data={props.program ? buildProgramFooterData(props.program) : []} location="footer" />
      </div>

      {props.canDeleteCard && (
        <div className="remove-card" onClick={() => props.canDeleteCard?.removeCallback(props.canDeleteCard.indexId)}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </ProgramCardContainer>
  );
};

export { ProgramCard };
