import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IStudent } from '../../students/interfaces/IStudent';
import { IAttachment } from '@shared/interfaces/IAttachment';
import ITag from '../../tags/interfaces/ITag';
import { ISkill } from '@shared/interfaces/ISkill';

export type IPlannedActivityData = {
  description: string;
  links: string[];
  title: string;
  moments: string[];
  duration: string;
  modality: string[];
  materials: string[];
  age_groups: string[];
  attachments: (File | IAttachment)[];
  preparation: string;
  cognitive_tasks: string[];
  student_amount: string;
  skills: Partial<ISkill>[];
  tags: Partial<ITag[]> | null;
  type: Partial<ITag> | null;
  theme: Partial<ITag> | null;
  subtheme: Partial<ITag> | null;
  thematic: Partial<ITag> | null;
};
export interface IPlannedActivity {
  id: number;
  activity_id: number;
  daily_class_plan_id: string;
  deleted_at?: string;
  end_time?: string;
  start_time?: string;
  updated_at?: string;
  students?: IStudent[];
  created_at: string;
  activity_data: IPlannedActivityData;
}

class ListPlannedActivityService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(dailyClassId: string): Promise<IPlannedActivity[] | false | 'canceling'> {
    try {
      const res = await this.api.get(`/daily-class-plan/${dailyClassId}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListPlannedActivityService;
