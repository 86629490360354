import React, { useMemo } from 'react';

interface TickProps {
    x: number,
    y: number,
    value: string;
    dy?: number,
    fill?: string,
    fontSize?: number,
    fontWeight?: number,
    transform?: string,
    textAnchor?: string,
    width?: number,
}

const Tick: React.FC<TickProps> = ({
    x,
    y,
    value,
    transform,
    width,
    dy = 16,
    fill = '#2E3A59',
    fontSize = 10,
    fontWeight = 800,
    textAnchor = 'end'
}) => {
    const getValue = useMemo(() => value ? value.split(" ").map((v, i) => <tspan key={i} x="0" dy="1em">{v}</tspan>) : '', [value]);

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={dy}
                fill={fill}
                fontSize={fontSize}
                fontWeight={fontWeight}
                transform={transform ? transform : ''}
                textAnchor={textAnchor}
                width={width}
            >
                {getValue}
            </text>

        </g>
    );
}

export default Tick;