import { styled } from 'react-alicerce-components';
import { IQuestionMark } from '.';

export const QuestionMarkerContainer = styled.div<Pick<IQuestionMark, 'top' | 'right' | 'isAbsolute'>>`
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'unset')};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  transform: ${({ isAbsolute }) => (isAbsolute ? 'translateY(-50%);' : '0')};
  z-index: 5;
  cursor: pointer;

  i svg {
    fill: white !important;
    width: 25px;
    height: 25px;
  }
`;
