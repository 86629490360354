import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IUser } from '@modules/users/interfaces/IUser';
import IListOptions from '../../../shared/interfaces/IListOptions';

interface IUserReponse extends IPaginatedResponse {
  items: IUser[];
}

interface IListStudentsOptions extends IListOptions {
  status?: string[];
  categories?: string[];
  class_id?:string
}

class ListUsersService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListStudentsOptions): Promise<IUserReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, order: 'lms_user.name', ...options }, true);

      const res = await this.api.get(`/lms-user?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListUsersService;
