import React, { Fragment } from 'react';
import Icon from 'react-eva-icons';

import { Label, Pill } from 'react-alicerce-components';

import { Footer, LearningTargetsStudentCardBorderContainer, ValidatedContainerLearningTarget } from './styles';
import { IStudentWithGrades } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { formateDateDifferenceDay } from '@shared/utils/formateDateDifferenceDay';
import { formatDateUtcToTimeZone } from '@shared/utils/formatDateUtcToTimeZone';

export interface ILearningTargetsStudentCardBorder {
  data?: string[];
  location: 'header' | 'footer';
  studentData?: IStudentWithGrades;
  valid?: boolean;
  isShowValid?: boolean;
}

const LearningTargetsStudentCardBorder: React.FC<ILearningTargetsStudentCardBorder> = (props) => {
  const gradeFirstValidated = props.studentData?.gradeInfo?.first_validated_at;

  const getStatus = (status): { status: string; color: string } =>
    ({
      active: { status: 'Ativo' },
      adaptation: { status: 'Em Adaptação' },
      experimentation: { status: 'Em Experimentação' },
      not_enroll_a_adapt: {
        status: 'Não matriculado após adaptação',
      },
      not_enroll_a_exp: {
        status: 'Não matriculado após experimentação',
      },
      paused: {
        status: 'Pausado',
      },
      quitter: {
        status: 'Desistente',
      },
    }[status] || { status: 'Desconhecido' });

  return (
    <LearningTargetsStudentCardBorderContainer location={props.location}>
      {props.data?.map((d, index) => (
        <Fragment key={index}>
          <Pill key={d}>{d}</Pill>
          <Pill key={props.studentData?.student.id}>{getStatus(props.studentData?.student.status).status}</Pill>
          <Label className="label-obj" color="#fff" fontSize={'12px'}>
            Obj. Ped:
          </Label>
          <Pill key={props.studentData?.gradeInfo?.meta_block?.name}>{props.studentData?.gradeInfo?.meta_block?.name}</Pill>
        </Fragment>
      ))}
      {!!props.isShowValid && (
        <Footer>
          <ValidatedContainerLearningTarget valid={props.valid}>
            {props.valid ? (
              <span>
                <p>Definição de Meta em</p>
                <b>{gradeFirstValidated && formatDateUtcToTimeZone({ date: gradeFirstValidated })}</b>-{' '}
                {gradeFirstValidated && formateDateDifferenceDay(gradeFirstValidated)}
              </span>
            ) : (
              <span className="notValidated">
                {/* DADOS DO GET */}
                <p>
                  Meta
                  <b>Não Definida</b>
                </p>
                <p>
                  <Icon name="alert-circle-outline" /> Abra o Card para Definir
                </p>
              </span>
            )}
          </ValidatedContainerLearningTarget>
        </Footer>
      )}
    </LearningTargetsStudentCardBorderContainer>
  );
};

export default LearningTargetsStudentCardBorder;
