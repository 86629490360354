import React, { useMemo } from 'react';

import { H6, Spinner } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import TagsRender from '@modules/tags/components/TagsRender';
import ButtonIcon from '@shared/components/ButtonIcon';
import Divider from '@shared/components/Divider';

import { Container, List } from './styles';

const CamerasAndPlaces: React.FC = () => {
  const { isLoading } = useGlobal();

  const tags = useMemo(
    () => [
      {
        color: '#5CE7E6',
        name: 'São Paulo',
      },
      {
        color: '#94F7EE',
        name: 'São Paulo',
      },
      {
        color: '#C8FBF2',
        name: 'Polo Vila Madalena',
      },
    ],
    []
  );

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Local: Sala 2" backgroundHeader="linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)" />

      <DirectoryContainer style={{ marginTop: '2rem', paddingTop: 0 }} footer={false}>
        <TagsRender tags={tags} />

        <H6 margin="2rem 0" color="#35A0D6">
          CÂMERAS de Sala 2
        </H6>
        <List>
          {new Array(6).fill('#B85EC4').map((bgColor, i) => (
            <ButtonIcon content={`Câmera ${i + 1}`} iconName="video-outline" bgColor={bgColor} />
          ))}
        </List>

        <Divider width="100%" />

        <H6 margin="2rem 0" color="#35A0D6">
          LOCAIS de Sala 2
        </H6>
        <List>
          {new Array(6).fill('#568CF7').map((bgColor, i) => (
            <ButtonIcon content={`Sala ${i + 1}`} iconName="home-outline" bgColor={bgColor} />
          ))}
        </List>
      </DirectoryContainer>
    </Container>
  );
};

export default CamerasAndPlaces;
