import IClass from '@modules/classes/interfaces/IClass';
import { calcAgeInYears } from '@modules/launchers/utils/formateMapaBody';
import { IStudent } from '@modules/students/interfaces/IStudent';

export const formateMinimapaBody = (
  created_by: number,
  student: IStudent,
  trail: number,
  skillsToTest,
  classEntity: IClass,
  printable?: boolean,
  notApplicable?: boolean
) => {
  //Pega duas semanas a partir de hoje.
  const twoWeeks = new Date(Date.now() + 6.048e8 * 2);

  return {
    lms_student_id: Number(student.id),
    trail_id: trail,
    state: {
      created_by: created_by,
      ...(!!printable && { printable: true }),
      ...(!printable && { printable: false }),
      miniMapaData: {
        miniMapaSkillsAndQuestions: skillsToTest,
        trailIds: [trail],
      },
      type: 'MINIMAPA',
      ...(!!notApplicable && { notApply: true }),
      ...(!!notApplicable && { status: 'FINALIZED' }),
      ...(!notApplicable && { status: 'PENDING' }),
      class_id: Number(classEntity.id),
      appliedAt: new Date(),
      sprintId: classEntity.period?.sprint_id ?? null,
      periodId: classEntity.period?.id ?? null,
      studentAge: calcAgeInYears(student?.birthdate),
      studentAgeGroup: student?.age_group ?? null,
      valideUntil: twoWeeks,
      user: {
        id: Number(student.id),
        lms_student: Number(student.id),
        atributes: [
          {
            atribute: 'Nome',
            value: `${student.name}`,
          },
          {
            atribute: 'Nascimento',
            value: `${student.birthdate}`,
          },
          {
            atribute: 'Telefone',
            value: `${student.phone}`,
          },
        ],
      },
    },
  };
};
