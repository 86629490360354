import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from 'react-eva-icons';

import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import ConfirmModal from '@shared/components/ConfirmModal';
import PageFooter from '@shared/components/PageFooter';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import ScheduleSprintForm from '@modules/schedules/components/ScheduleSprintForm';
import EditScheduleSprintService from '@modules/schedules/services/EditScheduleSprintService';
import DeleteScheduleSprintService from '@modules/schedules/services/DeleteScheduleSprintService';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

import { Container } from './styles';

const ScheduleSprintEdit: React.FC = () => {
  const { sprintCurrent, setSprintCurrent, state } = useSchedules();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();

  const handleSubmit = useCallback(
    async (dataValidated: ISprint) => {
      setSprintCurrent(dataValidated);
      setShowEditModal(true);
    },
    [setSprintCurrent]
  );

  const handleUpdateSprint = useCallback(async () => {
    setShowEditModal(false);
    if (!signedUser || !sprintCurrent?.id) return;

    setIsLoading(true);

    const res = await new EditScheduleSprintService(signedUser.token).execute(sprintCurrent);

    setIsLoading(false);

    addToast({
      status: res ? 'success' : 'danger',
      title: 'Sprint',
      description: res ? 'Sprint editada com sucesso.' : 'Não foi possível editar a sprint.',
    });

    if (res) navigate(-1);
  }, [signedUser, sprintCurrent, setIsLoading, addToast, navigate]);

  const handleDeleteSprint = useCallback(async () => {
    setShowDeleteModal(false);

    if (!sprintCurrent?.id) return;

    setIsLoading(true);
    const res = await new DeleteScheduleSprintService(signedUser?.token).execute(sprintCurrent.id);

    setIsLoading(false);

    addToast({
      status: res ? 'success' : 'danger',
      title: 'Sprint',
      description: res ? 'Sprint deletada com sucesso.' : 'Não foi possível deletar a sprint.',
    });

    if (res) navigate(-1);
  }, [addToast, navigate, setIsLoading, signedUser, sprintCurrent]);

  const canUserEditSprint = useCallback(() => {
    if (sprintCurrent?.created_by === signedUser?.lmsUser.id) return true;
    return false;
  }, [signedUser, sprintCurrent]);

  useEffect(() => {
    if (!sprintCurrent?.id) return navigate('/agenda/locais', { state: state });
  }, [navigate, sprintCurrent, state]);

  // console.log(`sprintCurrent`, sprintCurrent);
  // console.log(`canUserEditSprint`, canUserEditSprint());

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Editar Sprint" />

      <DirectoryContainer style={{ margin: '0', padding: '0 0 1rem 0' }} footer={canUserEditSprint()}>
        <ScheduleSprintForm formRef={formRef} handleOnFinish={handleSubmit} canUserEdit={canUserEditSprint()} />

        {canUserEditSprint() && (
          <PageFooter>
            <Button onClick={() => setShowDeleteModal(true)} color="#fff" transform="none" status="basic" statusNumber={600} shaded>
              Excluir
            </Button>

            <Button onClick={() => formRef?.current?.submitForm()} color="#fff" transform="none" status="basic" statusNumber={600} shaded>
              Salvar
            </Button>
          </PageFooter>
        )}
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(!showEditModal)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleUpdateSprint}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(!showDeleteModal)}
        title="Deseja Deletar a Sprint?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleDeleteSprint}
      />
    </Container>
  );
};

export default ScheduleSprintEdit;
