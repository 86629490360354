import React, { RefObject, useCallback, useState } from 'react';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { InputForm, getValidationErrors, TextareaForm } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

import { Container } from './styles';
import { ISchedule } from '../../interfaces/ISchedule';
import { formatSchedule } from '../../utils/formatSchedule';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';

interface IErrors {
  [key: string]: string;
}

interface IScheduleFormProps {
  formRef?: RefObject<FormHandles>;
  handleOnFinish?: (scheudle: ISchedule) => void;
}

const ScheduleForm: React.FC<IScheduleFormProps> = ({ formRef, handleOnFinish }) => {
  const { scheduleCurrent } = useSchedules();
  const [errors, setErrors] = useState<IErrors>();

  const handleSubimit = useCallback(
    async (data) => {
      try {
        if (formRef) {
          setErrors({});
          formRef.current?.setErrors({});
          console.log(errors);
        }

        let scheduleEditOrCreate = formatSchedule({ ...scheduleCurrent, ...data });

        const schema = Yup.object().shape({
          name: Yup.string().required('O campo é obrigatório').min(3, 'No minimo 3 caracteres').max(50, 'No maximo 50 caracteres'),
          description: Yup.string().required('O campo é obrigatório').min(3, 'No minimo 3 caracteres').max(200, 'No maximo 200 caracteres'),
        });

        await schema.validate(scheduleEditOrCreate, {
          abortEarly: false,
        });

        if (handleOnFinish) {
          handleOnFinish(scheduleEditOrCreate);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError && formRef) {
          const validationErrors = getValidationErrors(err);
          formRef.current?.setErrors(validationErrors);
          setErrors(validationErrors);
        }
      }
    },
    [errors, formRef, handleOnFinish, scheduleCurrent]
  );

  console.log(' ~ errors', errors);
  return (
    <Container>
      <CustomForm customInputColorText="#2E3A59" style={{ padding: '1rem', minHeight: '80vh' }}>
        <Form ref={formRef} onSubmit={handleSubimit} initialData={{ name: scheduleCurrent?.name, description: scheduleCurrent?.description }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <InputForm label="Nome da Agenda:" name="name" placeholder="Adicione Nome à Agenda" useTooltipError={false} />

          <TextareaForm
            label="Descrição da Agenda:"
            name="description"
            placeholder="Adicione Nome à Agenda"
            value={scheduleCurrent?.description}
            rows={4}
          />
          <InputErrorMessage error={errors?.description} />
        </Form>
      </CustomForm>
    </Container>
  );
};

export default ScheduleForm;
