import { styled } from 'react-alicerce-components';

export const Container = styled.div`
    height: 140px;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    background-color: #DAE3F6;

    .custom-media-container {
        > div {
            position: initial;

            > div {
                right: 6px;
                bottom: 6px;
            }
        }
    }
`;

export const WrapperClick = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
`;

export const MediaContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30%;
    height: 100%;
    background-color: #2B2826;

    .spinner {
        background-color: #DAE3F6;
    }
`;

export const MediaInfoContainer = styled.div`
    position: relative;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Date = styled.div`
    color: #fff;
    padding: 6px;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    background-color: #568CF7;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
`;

export const Name = styled.div`
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: var(--secondary-font);
    font-weight: 800;
    margin-top: 1rem;
`;

export const TagsRenderContainer = styled.div`
    height: 40px;
    background-color: #568CF7;

    .tags-render-list {
        margin: 0 !important;

        > div {
            background-color: transparent;
            border-color: transparent;
            margin: 0 !important;
            padding: 6px !important;
        }
    }
`;