import { styled} from "react-alicerce-components";

interface IContainerProps {
    canDelete?: boolean;
}

export const Container = styled.div<IContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 10px 0;

    .input-container {
        width: ${({ canDelete }) => canDelete ? '90%' : '100%' };
        margin: 0 !important;
    }

    input:disabled {
        font-size: 12px;
        color: #F47920;
        font-weight: 800;
    }
`;

export const DeleteIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;

    border-radius: 26px;
    background: linear-gradient(180deg, #F42929 0%, #F9514C 100%);

    i {
        line-height: 1;
        display:flex;
        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
        }
    }
`;