import React, { useCallback, useEffect, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { CheckboxForm, ICheckboxProps } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import { Container, Time, Name } from './styles';

export interface ICalendarTodayClasse {
  name?: string;
  start?: string;
  end?: string;
  checked?: boolean;
  selected?: boolean;
}

type ICalendarTodayClasseProps = ICheckboxProps & {
  todayClasse?: ICalendarTodayClasse;
  id?: string | number;
  hideIconUncheck?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  canUseCheckbox?: boolean;
  canSelect?: boolean;
  status?: ElementStatus;
  statusNumber?: number;
  handleOnClick?: (todayClasse: ICalendarTodayClasse) => void;
  isSelectedInitialState?: boolean;
};

const CalendarTodayClasse: React.FC<ICalendarTodayClasseProps> = ({
  id,
  todayClasse,
  icon,
  hideIconUncheck,
  canUseCheckbox = false,
  status = 'basic',
  statusNumber = 300,
  canSelect = false,
  handleOnClick,
  isSelectedInitialState = false,
  ...rest
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(isSelectedInitialState);
  useEffect(() => {
    setIsSelected(isSelectedInitialState);
  }, [isSelectedInitialState]);

  const handleOnClickClass = useCallback(() => {
    if (canSelect && handleOnClick && todayClasse) {
      handleOnClick({ ...todayClasse, selected: !isSelected });
      setIsSelected((oldState) => !oldState);
    }
  }, [canSelect, handleOnClick, todayClasse, isSelected]);

  return (
    <Container status={status} statusNumber={statusNumber} onClick={handleOnClickClass} canSelect={canSelect} isSelected={isSelected}>
      {todayClasse && (
        <div>
          <Time>
            {todayClasse.start?.slice(0, 5)} - {todayClasse.end?.slice(0, 5)}
          </Time>
          <Name>{todayClasse.name}</Name>
          {canUseCheckbox && <CheckboxForm {...rest} checked={todayClasse?.checked} id={id} icon={icon} hideIconUncheck={hideIconUncheck} />}
        </div>
      )}
    </Container>
  );
};

export default CalendarTodayClasse;
