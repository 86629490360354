import { ISelectOption } from 'react-alicerce-components';

export const LMSUSER_STATUS_ACTIVE = { name: 'Ativo', value: 'active' };
export const LMSUSER_STATUS_INACTIVE = { name: 'Inativo', value: 'inactive' };

export const LMSUSER_STATUS_CONSTRAINTS = [LMSUSER_STATUS_ACTIVE, LMSUSER_STATUS_INACTIVE];

export const defaultLmsUserStatus = (lmsUserStatus?: string | null): ISelectOption[] | undefined => {
  return [
    { name: LMSUSER_STATUS_ACTIVE.name, value: LMSUSER_STATUS_ACTIVE.value, selected: lmsUserStatus === LMSUSER_STATUS_ACTIVE.value },
    { name: LMSUSER_STATUS_INACTIVE.name, value: LMSUSER_STATUS_INACTIVE.value, selected: lmsUserStatus === LMSUSER_STATUS_INACTIVE.value },
  ];
};

export const LMSUSER_STATUS_VALUES = LMSUSER_STATUS_CONSTRAINTS.map((a) => a.value);
