import * as Yup from 'yup';
import { validTime } from '@shared/utils/validData/validTime';

export const dailyClassSchema = Yup.object().shape({
  start_date: Yup.string().required({ name: 'start_date', message: 'O campo é obrigatório' }),
  start_time: validTime('start_time').required('O campo é obrigatório'),
  end_time: validTime('end_time').required('O campo é obrigatório'),
  place_id: Yup.number().required({ name: 'place_id', message: 'O campo é obrigatório' }),
  class_id: Yup.number().required(),
  invalidation_reason: Yup.string().optional(),
});
