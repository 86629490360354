import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 100px;

    .custom-left-label {
        text-anchor: middle;
    }
`;

export const BottomLabel = styled.div`
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #8F9BB3;
`;