import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Label, Pagination, useToast } from 'react-alicerce-components';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import IProject from '@modules/projects/interfaces/IProject';
import ProjectCard from '@modules/projects/components/ProjectCard';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import { useNavigate } from 'react-router-dom';
import { InputDateContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';
import InputPill from '@shared/components/InputPill';
import ListProjectsService, { IProjectResponse } from '@modules/projects/services/Project/ListProjectsService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';

interface IProjectList {
  pathToReturn?: string;
  pathRedirect?: string;
  canSelectProject?: 'one' | 'many';
  selectedItens?: IProject[];
  listOptions?: any;
  pathStateOptions?: { state: any };
  isAdvancedFilterDisabled?: boolean;
  handleOnClick?: (project: any) => void;
  setSelectedItens?: (project: IProject[]) => void;
  contract_ids?: number[];
}
type IDateType = {
  start?: string;
  end?: string;
}
type IProjectFilter = {
  status?: string;
  project_duration?: IDateType;
  partner_contact_id?: number;
};

const ProjectList: React.FC<IProjectList> = ({
  isAdvancedFilterDisabled,
  selectedItens,
  canSelectProject,
  setSelectedItens,
  pathRedirect,
  contract_ids
}) => {
  const navigate = useNavigate()
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const [projectList, setProjectList] = useState<IProjectResponse>()
  const { signedUser } = useAuth();
  const [inputSearch, setInputSearch] = useState<string>('');
  const { addToast } = useToast();
  const projectFilter = useRef<IProjectFilter>({ project_duration: {} });
  
  const listProjects = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const projects = await new ListProjectsService(signedUser.token, newCancelToken()).execute(inputSearch, {
        page,
        size: 10,
        contract_ids: contract_ids,
        ...projectFilter.current
      });
      if (projects === 'canceling') return;
      setIsLoading(false);
      if (!projects) {
        addToast({
          status: 'danger',
          title: 'Projetos',
          description: 'Erro ao listar Projetos.',
        });
        return;
      }
      setProjectList(projects);
    },
    [newCancelToken, setIsLoading, signedUser, inputSearch, contract_ids, addToast]
  );

  useEffect(() => {
    listProjects()
  }, [listProjects])

  const checkSelectedProjects = useCallback((selectedItens: IProject[], clickedItem: IProject): false | IProject[] => {
    const found = selectedItens.findIndex((p) => p.id === clickedItem.id);
    if (found === -1) return false;
    selectedItens.splice(found, 1);
    return selectedItens;
  }, []);

  const handleClickProjectCard = useCallback(
    (project: IProject) => {
      if (!canSelectProject) navigate(`/projeto/${project.id}/${pathRedirect}`);

      if (canSelectProject === 'one' && selectedItens && setSelectedItens) {
        if (selectedItens[0]?.id === project.id) return setSelectedItens([]);
        setSelectedItens([project]);
      } else if (canSelectProject === 'many' && selectedItens && setSelectedItens) {
        const res = checkSelectedProjects(selectedItens, project);
        if (!res) return setSelectedItens([...selectedItens, project]);
        setSelectedItens([...res]);
      }
    },
    [canSelectProject, checkSelectedProjects, navigate, selectedItens, setSelectedItens, pathRedirect]
  );

  const handleDateChange = useCallback((event, dateType) => {
    const newDate = event.target.value;
    projectFilter.current.project_duration = {
      ...projectFilter.current.project_duration,
      [dateType]: newDate,
    };
  }, []);

  const handleApplyFilter = useCallback(() => {
    const { start, end } = projectFilter.current.project_duration || {};

    if (start && end) {
      if (new Date(start) > new Date(end)) {
        addToast({
          status: 'danger',
          title: 'Filtro de Duração',
          description: 'A data de início não pode ser posterior à data de término.',
        });
        return;
      }
    }

    listProjects();
  }, [addToast, listProjects]);
  
  const RenderProjectList = () => {
    if (projectList && projectList.items.length > 0) {
      return <Grid row>
        {projectList?.items?.map((project) => (
          <Grid sm={12} md={4} key={project.id}>
            <ProjectCard project={project} handleOnClick={handleClickProjectCard} hideDescription={true} />
          </Grid>
        ))}
      </Grid>
    }
    if (projectList && projectList.items.length === 0) {
      return <Grid><p>Sem Projetos para mostrar...</p></Grid>
    }
    return null
  }

  const handleResetFilter = () => {
    projectFilter.current = {};
    listProjects();
  };
  return (
    <ProjectDefaultStylesContainer>
      <Grid container>
        <AdvancedFilter
          disabled={isAdvancedFilterDisabled}
          applyFilterButtonBackground="#BE3A3A"
          handleApplyFilter={handleApplyFilter}
          onChangeMainInput={(newValue) => setInputSearch(newValue)}
          handleResetFilter={handleResetFilter}
        >
          <Grid row mt4>
            <Label>Status do Projeto</Label>
            <section className="checkbox-section">
              <div className="radio-button">
                <input
                  type="radio"
                  name="project_status"
                  id="execution"
                  onChange={() => (projectFilter.current.status = 'execution')}
                />
                <label htmlFor="execution">Execução</label>
              </div>

              <div className="radio-button">
                <input
                  type="radio"
                  name="project_status"
                  id="deployment"
                  onChange={() => (projectFilter.current.status = 'deployment')}
                />
                <label htmlFor="deployment">Implantação</label>
              </div>
            </section>
          </Grid>
          <Grid row mt4>
            <Label mb={2}>Duração do Projeto Entre</Label>
            <InputDateContainer>
              <input
                type="date"
                name="start"
                onChange={(event) => handleDateChange(event, 'start')}
              />
              <label>até</label>
              <input
                type="date"
                name="end"
                onChange={(event) => handleDateChange(event, 'end')}
              />

            </InputDateContainer>
          </Grid>
          <Grid row mt4>
            <InputPill
              label="Parceiro"
              placeholder="Adicione Parceiro"
              color="#C8FBF2"
            />
          </Grid>

        </AdvancedFilter>

        <RenderProjectList />
        {projectList &&
          <Pagination
            totalOfPages={projectList?.totalPages}
            currentPage={projectList?.currentPage}
            callbackGetListData={async (_, page) => listProjects?.(page)}
          />}

      </Grid>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectList;
