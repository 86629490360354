import { styled } from 'react-alicerce-components';

interface ISelectImage {
  isSelected?: boolean;
}
interface IContainerAttachButtonsProps {
  isEdit?: boolean;
  containAttach?: boolean;
  containMedia?: boolean;
}

export const StyledButtonContainer = styled.div`
  cursor: pointer;

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c5cee0;
    border-radius: 8px;

    svg {
      width: 35px;
      height: 35px;
      fill: #fff;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  input {
    display: none;
  }

  .text-add-button {
    font-family: var(--secondary-font);
    font-weight: bold;
    font-size: 15px;
    display: flex;
    color: #2e3a59;
  }

  .container-medias-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 1.5rem 0;
  }
`;

export const ContainerMediaPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #be3a3a;
  border-radius: 1rem;
  margin: 1rem 0.5rem;
  width: 100%;
  height: clamp(fit-content, 30vw, 20rem);
  max-width: 35rem;
  max-height: 20rem;
  gap: 0.5rem;
  flex-direction: row;
  padding: 1rem;
  align-items: center;

   .button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    margin: 0.5rem;
  }

  > div {
    flex: 1 0 47%;
  }

  > button {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
  }
`;

export const DowloadContainer = styled.div<IContainerAttachButtonsProps>`
  display: ${({ containMedia }) => (!containMedia ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
`;

export const ImageContainer = styled.div`
  gap: 0.5rem;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 345px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ImageEmpty = styled.div<ISelectImage>`
  background: #c5cee0;
  height: max(10vw, 4rem);
  max-height: 10vh;
  border-radius: 1rem;
  border: none;
  aspect-ratio: 1 / 1;
  border: ${({ isSelected }) => (isSelected ? '3px solid orange' : 'none')};
`;

export const ImageZoom = styled.div`
  background: #c5cee0;
  height: 50vw;
  max-height: 100%;
  border-radius: 1rem;
`;
