import React, { useCallback, useState } from 'react';
import Icon from 'react-eva-icons';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';
import { WorkGroupCardContainer, WorkGroupDescription } from '@modules/work-group/components/WorkGroupCard/styles';
import { WorkGroupCardBody } from '@modules/work-group/components/WorkGroupCard/WorkGroupCardBody';
import WorkGroupCardBorder from '@modules/work-group/components/WorkGroupCard/WorkGroupCardBorder';
import { useNavigate, useParams } from 'react-router-dom';

interface IWorkGroupCardProps {
  workGroup: IWorkGroup;
  className?: string;
  canDeleteCard?: { arrayId: number; removeCallback: (arrayId: number) => void };
  isSelected?: boolean;
  handleSelectedCardsCallback?: (activityCard: IWorkGroup) => void;
  draggable?: boolean;
}

const WorkGroupCard: React.FC<IWorkGroupCardProps> = ({ workGroup, handleSelectedCardsCallback, ...props }) => {
  const [isDescriptionOpen, setisDescriptionOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { classId } = useParams<{ classId: string }>();

  const buildWorkGroupHeaderData = useCallback((workGroup: IWorkGroup): string[] => {
    const cardHeaderData: string[] = [];
    workGroup.students_count &&
      cardHeaderData.push(
        `Número de Alunos: ${workGroup.students_count > 1 ? workGroup.students_count + ' Alunos' : workGroup.students_count + ' Aluno'} `
      );
    return cardHeaderData;
  }, []);

  const buildWorkGroupFooterData = useCallback((workGroup: IWorkGroup): string[] => {
    if (!workGroup.blocks) return [];
    return workGroup.blocks.map((block) => block.name);
  }, []);

  const handleClickCardContainer = useCallback(() => {
    // Case to click and select card
    if (handleSelectedCardsCallback) {
      handleSelectedCardsCallback(workGroup);
      return;
    }

    // Case to click and redirect
    navigate(`/grupo-de-trabalho/${workGroup.id}/informacoes/turma/${classId}`);
  }, [handleSelectedCardsCallback, navigate, workGroup, classId]);

  return (
    <WorkGroupCardContainer
      className={`activity-library-card-container ${props.className || ''}`}
      isSelected={props.isSelected}
      canDeleteCard={!!props.canDeleteCard}
    >
      <div className="card-container">
        <WorkGroupCardBorder
          handleOnClick={() => handleClickCardContainer()}
          data={workGroup ? buildWorkGroupHeaderData(workGroup) : []}
          location="header"
        />
        <WorkGroupCardBody isDescriptionOpen={isDescriptionOpen}>
          <b onClick={() => handleClickCardContainer()}>{workGroup?.name}</b>
          <div className="icon-container" onClick={() => setisDescriptionOpen((oldState) => !oldState)}>
            <Icon name="arrow-ios-forward-outline" fill="#2E3A59" />
          </div>
        </WorkGroupCardBody>
        <WorkGroupCardBorder
          handleOnClick={() => handleClickCardContainer()}
          data={workGroup ? buildWorkGroupFooterData(workGroup) : []}
          location="footer"
        />
        <WorkGroupDescription isDescriptionOpen={isDescriptionOpen}>
          <label>{workGroup?.description}</label>
        </WorkGroupDescription>
      </div>

      {props.canDeleteCard && (
        <div className="remove-card" onClick={() => props.canDeleteCard?.removeCallback(props.canDeleteCard.arrayId)}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </WorkGroupCardContainer>
  );
};

export { WorkGroupCard };
