import React, { useCallback, Fragment, useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import Icon from 'react-eva-icons';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import { ActivityPortfolioForm } from '@modules/activities-portfolio/components/ActivityPortfolioForm';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import ConfirmModal from '@shared/components/ConfirmModal';
import { IFormatedPortfolioActivity } from '../../interfaces/IFormatedPortfolioActivity';
import UploadService from '@shared/services/UploadFile';
import { useActivitiesPortfolio } from '../../context/ActivitiesPortfolioContext';
import { formatPortfolioActivity } from '../../utils/formatPortfolioActivity';
import { portfolioActivitySchema } from '../../utils/portfolioActivitySchema';
import { EditPortfolioActivityService } from '../../services/EditPortfolioActivityService';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { GetPortfolioActivitiesService } from '../../services/GetPortfolioActivitiesService';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { splitAttachmentFiles } from '@shared/utils/splitAttachmentFiles';

const ActivitiesPortfolioEdit: React.FC = () => {
  const { activityPortfolioCurrent, clearPortfolioActivityCurrent, setActivityPortfolioCurrent } = useActivitiesPortfolio();
  const { classId, portfolioActivityId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();

  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formatedBody, setFormatedBody] = useState<IFormatedPortfolioActivity>();

  const handleGoBack = useCallback(() => {
    clearPortfolioActivityCurrent();
    navigate(`/portfolio-atividades/${portfolioActivityId}/turma/${classId}/informacoes`);
  }, [classId, clearPortfolioActivityCurrent, navigate, portfolioActivityId]);

  const canSaveForm = useCallback(async () => {
    if (!activityPortfolioCurrent) return;
    const formatedBody = formatPortfolioActivity(activityPortfolioCurrent) as IFormatedPortfolioActivity;
    let formErros = await beforeSubmitForm(formatedBody, portfolioActivitySchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    setShowModal(true);
    setFormatedBody(formatedBody);
  }, [activityPortfolioCurrent]);

  const handleEditPortfolioActivity = useCallback(async () => {
    if (!formatedBody || !signedUser || !classId || !activityPortfolioCurrent.id) return;
    setShowModal(false);
    setIsLoading(true);
    let formattedAttachmentsToSave: IAttachment[] = [];

    const { newFiles, oldFiles } = splitAttachmentFiles(activityPortfolioCurrent.attachments);

    if (newFiles && newFiles.length > 0) {
      console.log(`newFiles => `, newFiles);

      const uploadService = new UploadService(signedUser?.token);
      const res = await uploadService.uploadFile(newFiles, 'activities-portfolio/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      const formattedAttachments = uploadService.formatedFileUpload(newFiles);
      formattedAttachmentsToSave = formattedAttachments.map((attachment, i) => ({ ...attachment, url: res[i].dbURL }));
    }

    let auxFormattedBody = { ...formatedBody };
    if (formattedAttachmentsToSave.length > 0) {
      auxFormattedBody = {
        ...auxFormattedBody,
        attachments: [...oldFiles, ...formattedAttachmentsToSave],
      } as IFormatedPortfolioActivity;
    }

    const res = await new EditPortfolioActivityService(signedUser?.token).execute(auxFormattedBody, `${activityPortfolioCurrent.id}`);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Portifólio',
        description: 'Erro ao editar o Portifólio.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Portifólio',
      description: 'Portifólio editado com sucesso.',
    });

    navigate(`/portfolio-atividades/${portfolioActivityId}/turma/${classId}/informacoes`);
  }, [
    activityPortfolioCurrent.attachments,
    activityPortfolioCurrent.id,
    addToast,
    classId,
    formatedBody,
    navigate,
    portfolioActivityId,
    setIsLoading,
    signedUser,
  ]);

  const getPortfolioActivity = useCallback(async () => {
    if (!portfolioActivityId) return;
    setIsLoading(true);
    const res = await new GetPortfolioActivitiesService(signedUser?.token, newCancelToken()).execute(portfolioActivityId);
    if (res === 'canceling' || !isMounted()) return;
    setIsLoading(false);

    if (!res) return console.log('Error ao Recuperar Atividade do Portfolio');
    setActivityPortfolioCurrent(res);
  }, [isMounted, newCancelToken, portfolioActivityId, setActivityPortfolioCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    if (!activityPortfolioCurrent.id) getPortfolioActivity();
  }, [activityPortfolioCurrent.id, getPortfolioActivity, portfolioActivityId]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Portfólio" backgroundHeader="linear-gradient(135deg, #FFBC6F 0%, #43A2E4 100%)" onClickReturn={handleGoBack} />

      <DirectoryContainer style={{ marginTop: '1rem', paddingTop: 0 }} footer>
        <ActivityPortfolioForm formAction="editing" errors={errors} />

        <PageFooter>
          <Button onClick={handleGoBack} status="info" transform="none" noBorder shaded>
            Cancelar
          </Button>
          <Button onClick={() => canSaveForm()} status="info" transform="none" noBorder shaded>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(false)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleEditPortfolioActivity}
      />
    </Fragment>
  );
};

export default ActivitiesPortfolioEdit;
