import React, { Fragment, useCallback, useEffect } from 'react';
import { Textarea, Input, H6, InputDatePicker, formatDate, Checkbox, Label as LabelForm } from 'react-alicerce-components';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import CustomForm from '@shared/components/CustomForm';
import CardsRender from '@shared/components/CardsRender';
import TagsRender from '@modules/tags/components/TagsRender';
import ClassCard from '@modules/classes/components/ClassCard';
import GroupCard from '@modules/groups/components/GroupCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import Divider from '@shared/components/Divider';
import UserCard from '@modules/users/components/UserCard';
import StudentCard from '@modules/students/components/StudentCard';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { DailyClassList } from '@modules/attendances/components/DailyClassList';
import { BsCheckLg } from 'react-icons/bs';
import { IUser } from '@modules/users/interfaces/IUser';
import { IStudent } from '@modules/students/interfaces/IStudent';
import { ContainerCards, ContainerOccurencesCheckbox } from './styles';
interface IOccurrenceFormProps {
  handleOnFinish?: (occurence: IOccurrence) => void;
  pathEnvolvedStudents: string;
  pathEnvolvedTags: string;
  errors: IInputErrorsFormat[];
}

export const AttendanceOccurrenceForm: React.FC<IOccurrenceFormProps> = (props) => {
  const { occurrenceCurrent, setOccurrenceCurrent } = useOccurrences();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setOccurrenceCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setOccurrenceCurrent]
  );

  const handleSelectUsers = useCallback(
    (user: IUser, event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const auxiliar = occurrenceCurrent?.lms_users ? occurrenceCurrent?.lms_users : [];

      if (checked) {
        setOccurrenceCurrent((oldState: IOccurrence) => {
          return { ...oldState, lms_users: [...auxiliar, user] };
        });
      } else {
        const filtered = auxiliar.filter((aux) => aux.id !== user.id);
        setOccurrenceCurrent((oldState: IOccurrence) => {
          return { ...oldState, lms_users: filtered };
        });
      }
    },
    [occurrenceCurrent, setOccurrenceCurrent]
  );

  const handleSelectStudents = useCallback(
    (student: IStudent, event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const auxiliar = occurrenceCurrent?.students ? occurrenceCurrent?.students : [];

      if (checked) {
        setOccurrenceCurrent((oldState: IOccurrence) => {
          return { ...oldState, students: [...auxiliar, student] };
        });
      } else {
        const filtered = auxiliar.filter((aux) => aux.id !== student.id);
        setOccurrenceCurrent((oldState: IOccurrence) => {
          return { ...oldState, students: filtered };
        });
      }
    },
    [occurrenceCurrent, setOccurrenceCurrent]
  );

  const handleUserSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setOccurrenceCurrent((oldState: IOccurrence) => {
        return { ...oldState, lms_users: occurrenceCurrent?.class?.teachers };
      });
    } else {
      setOccurrenceCurrent((oldState: IOccurrence) => {
        return { ...oldState, lms_users: [] };
      });
    }
  };

  const handleStudentsSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      setOccurrenceCurrent((oldState: IOccurrence) => {
        return { ...oldState, students: occurrenceCurrent?.class?.enrollments?.map((enrollment) => enrollment.student) };
      });
    } else {
      setOccurrenceCurrent((oldState: IOccurrence) => {
        return { ...oldState, students: [] };
      });
    }
  };

  const removeTagFromOccurence = useCallback(
    (indexTag: number) => {
      if (!occurrenceCurrent) return;
      const newTags = removeItemFromArrayByIndex(indexTag, occurrenceCurrent.tags || []);
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, tags: newTags };
      });
    },
    [occurrenceCurrent, setOccurrenceCurrent]
  );

  useEffect(() => {
    if (occurrenceCurrent?.day) return;
    setOccurrenceCurrent((oldState) => {
      return { ...oldState, day: formatDate(new Date(), `numeric`) };
    });
  }, [occurrenceCurrent, setOccurrenceCurrent]);

  return (
    <Fragment>
      <CustomForm style={{ padding: '1rem', height: 'calc(100vh - 180px)', overflowY: 'auto' }} customInputColorText="#568CF7">
        <Input
          label="Titulo da Ocorrência:"
          name="title"
          placeholder="Adicionar Título da Ocorrência"
          onChange={(event) => handleChangeInput(event)}
          useTooltipError={false}
          error={errorMessageBuilder('title', props.errors)}
          defaultValue={occurrenceCurrent?.title}
        />

        <InputDatePicker
          handleChangeDate={(date) =>
            setOccurrenceCurrent((oldState) => {
              return { ...oldState, day: date, daily_class: undefined };
            })
          }
          defaultValue={occurrenceCurrent?.day || formatDate(new Date(), `numeric`)}
          disabled
          input={{
            label: 'Data da Ocorrência:',
            name: 'day',
            placeholder: 'Dia/Mês/Ano',
            useTooltipError: false,
            error: errorMessageBuilder('date', props.errors),
          }}
        />

        <Input
          label="Horário da Ocorrência:"
          name="time"
          onChange={(event) => handleChangeInput(event)}
          useTooltipError={false}
          error={errorMessageBuilder('date', props.errors)}
          defaultValue={occurrenceCurrent?.time}
          type="time"
          min="00:00"
          required
          max="23:59"
        />

        <Textarea
          label="Descrição da Ocorrência:"
          name="description"
          placeholder="Adicione Descrição da Ocorrência"
          value={occurrenceCurrent?.description}
          onChange={(event) => handleChangeInput(event)}
          error={errorMessageBuilder('description', props.errors)}
          useTooltipError={false}
          rows={4}
          icon={undefined}
        />

        <CardsRender error={errorMessageBuilder('context', props.errors)} label="Locais Envolvidos:">
          {occurrenceCurrent?.place && <PlaceCard place={occurrenceCurrent.place} />}
        </CardsRender>

        <CardsRender error={errorMessageBuilder('context', props.errors)} label="Grupos Envolvidos:">
          {occurrenceCurrent?.group && <GroupCard group={occurrenceCurrent.group} canSelect />}
        </CardsRender>

        <CardsRender error={errorMessageBuilder('context', props.errors)} label="Turmas Envolvidas:">
          {occurrenceCurrent?.class && <ClassCard classInfo={occurrenceCurrent.class} />}
        </CardsRender>

        {occurrenceCurrent?.class?.id && occurrenceCurrent.day && (
          <DailyClassList
            classId={occurrenceCurrent.class.id}
            day={occurrenceCurrent?.day.split('/').reverse().join('-')}
            selectedDailyClass={occurrenceCurrent.daily_class}
          />
        )}

        <TagsRender
          pathToRedirect={props.pathEnvolvedTags}
          canRemoveTag={true}
          tagCallback={removeTagFromOccurence}
          label="Tags Relacionadas:"
          tags={occurrenceCurrent?.tags}
          placeholder="Adicionar Tags Relacionadas"
        ></TagsRender>

        <Textarea
          label="Comentários:"
          name="comment"
          placeholder="Adicionar Comentários"
          value={occurrenceCurrent?.comment}
          onChange={(event) => handleChangeInput(event)}
          error={errorMessageBuilder('comments', props.errors)}
          useTooltipError={false}
          rows={4}
        />

        {occurrenceCurrent && occurrenceCurrent.class && (
          <>
            <Divider width="100%" />

            <H6 fontWeight={800} color="#568CF7">
              PESSOAS ENVOLVIDAS
            </H6>

            <AccordionInfoItem
              colorHeader="#DAE3F6"
              title={`Usuários Envolvidos (${occurrenceCurrent.lms_users ? occurrenceCurrent.lms_users.length : 0})`}
            >
              <ContainerCards>
                <LabelForm>Selecionar Todos</LabelForm>
                <Checkbox
                  name="select-all"
                  id="select-all"
                  semiRound
                  onChange={(event) => handleUserSelectAll(event)}
                  icon={BsCheckLg}
                  hideIconUncheck
                />
              </ContainerCards>

              <div className="cards-container">
                {occurrenceCurrent?.class?.teachers?.map((lmsUser, index) => (
                  <ContainerOccurencesCheckbox key={`${lmsUser?.id}-teacher-${lmsUser.name}}`}>
                    <UserCard key={lmsUser.id} user={lmsUser} canSelect />
                    <Checkbox
                      semiRound
                      name={`user-${lmsUser.id}`}
                      key={`user-${lmsUser.id}`}
                      id={`user-${lmsUser.id}`}
                      icon={BsCheckLg}
                      hideIconUncheck
                      checked={!!occurrenceCurrent?.lms_users?.find((user) => String(user.id) === String(lmsUser.id))}
                      onChange={(event) => handleSelectUsers(lmsUser, event)}
                    />
                  </ContainerOccurencesCheckbox>
                ))}
              </div>
            </AccordionInfoItem>

            <AccordionInfoItem
              colorHeader="#DAE3F6"
              title={`Alunos Envolvidos (${occurrenceCurrent.students ? occurrenceCurrent.students.length : 0})`}
            >
              <ContainerCards>
                <LabelForm>Selecionar Todos</LabelForm>
                <Checkbox
                  semiRound
                  name="select-all-st"
                  id="select-all-st"
                  onChange={(event) => handleStudentsSelectAll(event)}
                  icon={BsCheckLg}
                  hideIconUncheck
                />
              </ContainerCards>

              <div className="cards-container">
                {occurrenceCurrent?.class?.enrollments?.map((enrollment) => (
                  <ContainerOccurencesCheckbox key={`${enrollment.student?.id}-student-${enrollment.student?.name}}`}>
                    <StudentCard key={enrollment.student?.id} canSelect={false} student={enrollment.student!} />
                    <Checkbox
                      semiRound
                      name={`st-${enrollment.student?.id}`}
                      key={`st-${enrollment.student?.id}`}
                      id={`st-${enrollment.student?.id}`}
                      onChange={(event) => handleSelectStudents(enrollment.student!, event)}
                      icon={BsCheckLg}
                      hideIconUncheck
                      checked={!!occurrenceCurrent?.students?.find((st) => String(st.id) === String(enrollment?.student?.id))}
                    />
                  </ContainerOccurencesCheckbox>
                ))}
              </div>
            </AccordionInfoItem>
          </>
        )}
      </CustomForm>
    </Fragment>
  );
};
