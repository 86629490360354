import { styled } from 'react-alicerce-components';

export const ProgramCardBodyContainer = styled.div`
  background: #e9d7f9;
  min-height: 60px;
  padding: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #222b45;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
