import moment from "moment";
import { IDailyClass } from "../../attendances/interfaces/IDailyClass";

/**
 * Verifica se o botão de salvar deve ser desabilitado.
 *
 * @param dailyClass A DailyClass.
 * @returns True se o botão de salvar deve ser desabilitado, false caso contrário.
 */
export const isDailyClassEditDisable = ({ dailyClass }: { dailyClass: IDailyClass | undefined }) => {
    if (!dailyClass?.end_date || !dailyClass.end_time) return false;
    const today = moment();
    const endTime = moment(dailyClass.end_date);
    const finalEndDate = endTime.add(dailyClass.end_time);
    return finalEndDate <= today;
};
