import React, { useCallback } from 'react';
import { Button, H6, Input, useToast } from 'react-alicerce-components';
import { useParams } from 'react-router-dom';

import Layout from '@modules/groups/components/Layout';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';
import TagsRender from '@modules/tags/components/TagsRender';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { useGroup } from '@modules/groups/context/GroupContext';
import GroupCard from '@modules/groups/components/GroupCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import CustomForm from '@shared/components/CustomForm';
import { useNavigate } from 'react-router-dom';
import UserCard from '@modules/users/components/UserCard';
import { translateString } from '@shared/utils/translateString';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import InputPill from '@shared/components/InputPill';
import ListClassesService from '@modules/classes/services/ListClassesService';
import ClassCard from '@modules/classes/components/ClassCard';
import ParentRender from '@shared/components/ParentRender';

const GroupInfo: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { addToast } = useToast();
  const { setGroupCurrent, groupCurrent } = useGroup();
  const { id } = useParams<{ id: string }>();

  const listGroupClasses = useCallback(async () => {
    setIsLoading(true);
    const res = await new ListClassesService(signedUser?.token).execute('', { group_id: id, size: 9999 });
    if (res === 'canceling') return;
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Grupos',
        description: 'Não foi possivel listar Grupos Filhos.',
      });
      return;
    }
    setGroupCurrent((oldState: any) => {
      return { ...oldState, classes: res.items };
    });
  }, [addToast, id, setGroupCurrent, setIsLoading, signedUser]);

  // @todo(felipe) Utilizar groupForm aqui nesse componente (lembrando que em info existe 2 accordions extra (Grupos Filhos e Turmas))
  return (
    <Layout openTab={1} footer>
      <H6 fontWeight={800} marginBottom={15} status="tertiary">
        DADOS DO GRUPO
      </H6>
      <CustomForm status="tertiary" customInputColorText="#0097B1">
        <Input disabled name="name" label="Nome do Grupo:" defaultValue={groupCurrent?.name} />

        <InputPill label="Categoria" inputPillData={[translateString(groupCurrent?.category)]} />

        <InputPill label="Status do Local:" inputPillData={[groupCurrent?.status ? translateString(groupCurrent.status) : '']} />

        <ParentRender required={false} label="Grupo Mãe:" hasParent={!!groupCurrent.parent} customStyles={{ margin: '1.5rem 0' }}>
          {groupCurrent.parent && (
            <GroupCard
              handleOnClick={() => navigate(`/grupo/${groupCurrent.parent?.id}`)}
              key={groupCurrent.parent?.id}
              canSelect={false}
              group={groupCurrent.parent}
              inputCard={true}
              customStyle={{ margin: '1rem', cursor: 'pointer' }}
            />
          )}
        </ParentRender>

        <TagsRender label="Tags: " status="tertiary" tags={groupCurrent.tags} />

        <Divider width="100%" />
        <H6 fontWeight={800} status="tertiary">
          RELACIONADOS
        </H6>

        <AccordionInfoItem status="tertiary" title={`Grupos Filhos ${groupCurrent.childs ? '(' + groupCurrent.childs.length + ')' : ''}`}>
          <div className="cards-container">
            {groupCurrent.childs &&
              groupCurrent.childs?.map((child) => (
                <GroupCard handleOnClick={() => navigate(`/grupo/${child.id}`)} key={child.id} canSelect={false} group={child} />
              ))}
          </div>
        </AccordionInfoItem>

        <AccordionInfoItem
          status="tertiary"
          title={`Turmas ${groupCurrent.classes ? '(' + groupCurrent.classes.length + ')' : ''}`}
          handleClickOpenClose={(isOpen: boolean) => isOpen && !groupCurrent.classes && listGroupClasses()}
        >
          {groupCurrent.classes?.map((c) => (
            <ClassCard handleOnClick={() => navigate(`/turma/${c.id}`)} classInfo={c} key={c.id} />
          ))}
        </AccordionInfoItem>

        <AccordionInfoItem status="tertiary" title={`Locais Englobados (${groupCurrent.places ? groupCurrent.places.length : 0})`}>
          {groupCurrent.places &&
            groupCurrent.places.map((place) => <PlaceCard handleOnClick={() => navigate(`/local/${place.id}`)} place={place} key={place.id} />)}
        </AccordionInfoItem>

        <Divider width="100%" />
        <H6 fontWeight={800} status="tertiary">
          ADMINISTRADORES
        </H6>

        <AccordionInfoItem status="tertiary" title={`Usuários (${groupCurrent.lms_users ? groupCurrent.lms_users.length : 0})`}>
          <div className="cards-container">
            {groupCurrent.lms_users &&
              groupCurrent.lms_users.map((user) => (
                <UserCard handleOnClick={() => navigate(`/usuario/${user.id}/informacoes`)} key={user.id} canSelect={false} user={user} />
              ))}
          </div>
        </AccordionInfoItem>
      </CustomForm>

      <PageFooter>
        <Button transform="none" status="tertiary" shaded onClick={() => navigate(`/grupo/${groupCurrent.id}/editar`)}>
          Editar
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default GroupInfo;
