import { styled } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import themeGet from '@styled-system/theme-get';

interface IWrapperContentProps {
  status: ElementStatus;
  justListing: boolean;
}

export const WrapperContent = styled.div<IWrapperContentProps>`
  div .card-body {
    background-color: ${({ status }) => themeGet(`colors.${status}100`)};
  }

  .custom-card > div {
    margin: var(--card-distance)
  }

  div .card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ status }) => themeGet(`colors.${status}400`)};
  }

  .input-container {
    margin-bottom: 2.81rem;
    div {
      border-color: #c5cee0 !important;
    }
  }

  .card-container {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    ${(p) => !p.justListing && `
      > div {
        cursor: pointer;
      }
    `}


    .check {
      position: absolute;
      top: 2px;
      right: -20px;
    }
  }
`;
