import { validTime } from '@shared/utils/validData/validTime';
import * as Yup from 'yup';

export const activityLibrarySchema = Yup.object().shape({
  title: Yup.string().required({ name: 'title', message: 'O campo é obrigatório' }),

  description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório' }),

  duration: validTime('duration')
    .notOneOf(['00:00'], { name: 'duration', message: 'O valor deve ser maior que zero (0)' })
    .required('O campo é obrigatório'),

  age_groups: Yup.array()
    .min(1, { name: 'age_groups', message: 'Necessário selecionar pelo menos uma opção' })
    .of(Yup.string().required({ name: 'age_groups', message: 'O campo é obrigatório' }))
    .required({ name: 'age_groups', message: 'O campo é obrigatório' }),

  student_amount: Yup.string().required({ name: 'student_amount', message: 'O campo é obrigatório' }),

  modality: Yup.array()
    .min(1, { name: 'modality', message: 'Necessário selecionar pelo menos uma opção' })
    .of(Yup.string())
    .required({ name: 'modality', message: 'O campo é obrigatório' }),

  type_tag_id: Yup.string().required({ name: 'type', message: 'O campo é obrigatório' }),

  skills: Yup.array().of(Yup.number()).optional(),

  cognitive_tasks: Yup.array()
    .of(Yup.string().required({ name: 'cognitive_tasks', message: 'O campo é obrigatório' }))
    .required({ name: 'cognitive_tasks', message: 'O campo é obrigatório' }),

  materials: Yup.array()
    .of(Yup.string().required({ name: 'materials', message: 'O campo é obrigatório' }))
    .optional(),

  moments: Yup.array()
    .min(1, { name: 'moments', message: 'É necessário adicionar pelo menos 1 momento' })
    .of(Yup.string().required({ name: 'moments', message: 'Preencha todos os momentos' }))
    .required({ name: 'moments', message: 'É necessário adicionar pelo menos 1 momento' }),

  preparation: Yup.string().optional(),

  links: Yup.array()
    .of(Yup.string().required({ name: 'links', message: 'O campo é obrigatório' }))
    .optional(),

  status: Yup.string().optional(),
});
