import StudentListComponent from '@modules/students/components/StudentsList';

import React, { useEffect } from 'react';
import { useStudent } from '../../context/StudentContext';

const StudentDirectory: React.FC = () => {
  const { clearStudentContextData } = useStudent();

  useEffect(() => {
    clearStudentContextData();
  }, [clearStudentContextData]);

  return <StudentListComponent headerTitle="Diretório de Alunos" pathRedirect="/diretorio/aluno/criar" btnText="Criar Aluno" />;
};

export default StudentDirectory;
