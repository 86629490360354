import * as Yup from 'yup';

export const plannedActivitySchema = Yup.object().shape({
  title: Yup.string().required({ name: 'title', message: 'O campo é obrigatório' }),

  description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório' }),

  duration: Yup.string()
    .notOneOf(['00:00'], { name: 'duration', message: 'O valor deve ser maior que zero (0)' })
    .required({ name: 'duration', message: 'O campo é obrigatório' }),

  age_groups: Yup.array()
    .min(1, { name: 'age_groups', message: 'Necessário selecionar pelo menos uma opção' })
    .of(Yup.string().required({ name: 'age_groups', message: 'O campo é obrigatório' }))
    .required({ name: 'age_groups', message: 'O campo é obrigatório' }),

  student_amount: Yup.string().required({ name: 'student_amount', message: 'O campo é obrigatório' }),

  modality: Yup.array()
    .min(1, { name: 'modality', message: 'Necessário selecionar pelo menos uma opção' })
    .of(Yup.string())
    .required({ name: 'modality', message: 'O campo é obrigatório' }),

  cognitive_tasks: Yup.array()
    .min(1, { name: 'cognitive_tasks', message: 'Necessário selecionar pelo menos uma opção' })
    .of(Yup.string())
    .required({ name: 'cognitive_tasks', message: 'O campo é obrigatório' }),

  materials: Yup.array()
    .of(Yup.string().required({ name: 'materials', message: 'O campo é obrigatório' }))
    .optional(),

  moments: Yup.array()
    .min(1, { name: 'moments', message: 'É necessário adicionar pelo menos 1 momento' })
    .of(Yup.string().required({ name: 'moments', message: 'Preencha todos os momentos' }))
    .required({ name: 'moments', message: 'É necessário adicionar pelo menos 1 momento' }),

  preparation: Yup.string().nullable(),

  links: Yup.array()
    .of(Yup.string().required({ name: 'links', message: 'O campo é obrigatório' }))
    .optional(),

  status: Yup.string().optional(),

  type: Yup.object()
    .shape({
      id: Yup.number().required({ name: 'type', message: 'O campo é obrigatório - id' }),
      name: Yup.string().required({ name: 'type', message: 'O campo é obrigatório - name' }),
      color: Yup.string().required({ name: 'type', message: 'O campo é obrigatório - color' }),
    })
    .required({ name: 'type', message: 'O campo é obrigatório' }),

  theme: Yup.object()
    .shape({
      id: Yup.number().required({ name: 'theme', message: 'O campo é obrigatório - id' }),
      name: Yup.string().required({ name: 'theme', message: 'O campo é obrigatório - name' }),
      color: Yup.string().required({ name: 'type', message: 'O campo é obrigatório - color' }),
    })
    .nullable(),

  skills: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required({ name: 'skills', message: 'O campo é obrigatório - id' }),
        name: Yup.string().required({ name: 'skills', message: 'O campo é obrigatório - name' }),
        descriptions: Yup.string().required({ name: 'skills', message: 'O campo é obrigatório - descriptions' }),
        block: Yup.object({
          id: Yup.number().required({ name: 'skills', message: 'O campo é obrigatório - block.id' }),
          name: Yup.string().required({ name: 'skills', message: 'O campo é obrigatório - block.name' }),
        }),
      })
    )
    .required({ name: 'skills', message: 'O campo é obrigatório' }),
});
