import { HtmlHTMLAttributes } from 'react';

import { styled } from 'react-alicerce-components';
import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

export type ICustomFormProps = HtmlHTMLAttributes<HTMLDivElement> & {
  status?: ElementStatus;
  customInputColorText?: string;
};

export const Container = styled.div<ICustomFormProps>`
  .input-container {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0; // @TODO - REMOVER COM GRID NÃO PRECISA MAIS DISSO
 
    > div {
      border-width: 2px;
      cursor: default;
      height: 40px;
    }
  }

  .checkbox-container {
    margin: 0;
    > label {
      margin: 0;
      > div {
        margin: 0 4px 0 0;
      }
    }
  }

  input:checked + div {
    border-color: transparent;
    svg {
      color: #ffffff;
    }
  }

  .form-select-container {
    width: 100%;
    height: 40px;
  }

  label {
    color: ${themeGet('colors.basic700')};
    font-family: var(--secondary-font);
    text-transform: unset;
    margin: 4px 0;
    letter-spacing: 0px;
    font-size: 14px;
  }

  label.required:after {
    content: '*';
    margin-left: 4px;
    color: red;
  }

  input {
    color: ${({ status, customInputColorText }) => (customInputColorText ? customInputColorText : themeGet(`colors.${status}500`))};
    font-weight: 800;
    ::placeholder {
      font-family: var(--secondary-font);
      font-weight: 600;
      font-size: 12px;
      color: #c5cee0;
    }
  }

  input:disabled {
    cursor: default;
  }

  textarea {
    color: ${({ status, customInputColorText }) => (customInputColorText ? customInputColorText : themeGet(`colors.${status}500`))};
    font-weight: 800;
    border: none;
    ::placeholder {
      font-family: var(--secondary-font);
      font-weight: 800;
      font-size: 14px;
      color: #c5cee0;
    }
  }

  .textarea-container {
    div {
      width: 100%;
      border-radius: 4px;
      background-color: #f7f9fc;
      border: 2px solid #e4e9f2;
      margin-top: 4px;
    }
  }

  .input-container > div {
    // Hide Calendar Icon
    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
      /* display: none; */
      /* -webkit-appearance: none;
      -moz-appearance: none; */
    }
  }

  .simple-tooltip {
    display: none;
  }

  .select-container {
    > div {
      height: 40px;
    }

    .select-option.selected {
      background-color: #c5cee0 !important;
    }

    .select-option-text {
      border-width: 2px;
      border-color: #e4e9f2;

      > span {
        font-weight: 700;
        padding: 0.1rem 0.8rem;
        border-radius: 6px;
        background-color: #c5cee0;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
      }

      .select-icon {
        outline: 2px solid #e4e9f2;
        background-color: #c5cee0;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        cursor: pointer;
        border-radius: 6px;

        i svg {
          width: 35px;
          height: 35px;
          margin-top: 2px;
        }
      }
    }
  }
`;
