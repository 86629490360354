import React, { useMemo } from 'react';
import Icon from 'react-eva-icons';

import { Button, Grid, H6 } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ButtonAddFiles } from '@shared/components/ButtonAddFiles';
import { useProjectReportForm } from '../useProjectReportForm';
import { ButtonAddImages } from '@shared/components/ButtonAddImages';
import { DowloadContainer } from '@shared/components/ButtonAddImages/styles';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

interface ProjectReportAttachmentsProps {
  disabled?: boolean;
}

export const ProjectReportAttachments: React.FC<ProjectReportAttachmentsProps> = ({ disabled }) => {
  const { reportCurrent } = useProjects();
  const { handleDownloadAllFiles, handleDownloadSingleFile } = useGlobal();
  const { handleOnAddNewFile, handleOnRemoveFile } = useProjectReportForm();

  const containImages = useMemo(() => !!reportCurrent?.img_attachments?.length, [reportCurrent]);
  const containFiles = useMemo(() => !!reportCurrent?.others_attachments?.length, [reportCurrent]);

  return (
    <Grid row>
      <Grid row>
        <H6>ANEXOS DE IMAGENS:</H6>
        <ButtonAddImages
          isDisabled={disabled}
          iconName="plus-outline"
          text="Adicione Imagens"
          filesToSave={reportCurrent?.img_attachments}
          callbackFilesToSave={(files) => handleOnAddNewFile('img_attachments', files)}
          callbackFilesToRemove={disabled ? undefined : (index) => handleOnRemoveFile('img_attachments', index)}
          downloadSingleFileCallback={handleDownloadSingleFile}
        />

        {disabled && (
          <DowloadContainer containMedia={containImages}>
            <Button
              start={<Icon name="download" />}
              onClick={() => handleDownloadAllFiles(reportCurrent?.img_attachments)}
              status="primary"
              transform="none"
              noBorder
              rounded
            >
              Baixar Todas as Imagens
            </Button>
          </DowloadContainer>
        )}
      </Grid>

      <Grid row mt4>
        <H6>DEMAIS ANEXOS:</H6>
        <ButtonAddFiles
          isDisabled={disabled}
          iconName="plus-outline"
          text="Adicione Anexos"
          colorBackgroundIcon="#BE3A3A"
          colorBackgroundText="#F3C8C8"
          filesToSave={reportCurrent?.others_attachments}
          seeFileButton
          callbackFilesToSave={(files) => handleOnAddNewFile('others_attachments', files)}
          callbackFilesToRemove={disabled ? undefined : (index) => handleOnRemoveFile('others_attachments', index)}
          downloadSingleFile
          downloadSingleFileCallback={handleDownloadSingleFile}
        />

        {disabled && (
          <DowloadContainer containMedia={containFiles}>
            <Button
              start={<Icon name="download" />}
              onClick={() => handleDownloadAllFiles(reportCurrent?.others_attachments)}
              status="primary"
              transform="none"
              noBorder
              rounded
            >
              Baixar Todos os Arquivos
            </Button>
          </DowloadContainer>
        )}
      </Grid>
    </Grid>
  );
};
