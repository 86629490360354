import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .main-navbar {
    height: 130px;
    align-items: flex-start;
  }

  [role='tabpanel'] {
    height: calc(100vh - 120px);
  }
`;
