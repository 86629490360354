import React, { useState } from 'react';
import Icon from 'react-eva-icons';

import { Input, Pagination } from 'react-alicerce-components';

import {
    Container,
    Comment,
    Footer
} from './styles';

const NPSLeaderComments: React.FC = () => {

    const [comments] = useState<any[]>(new Array(6).fill('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sodales a erat vel ullamcorper. Maecenas ac faucibus mauris. Integer egestas vel justo sed viverra. Fusce ut euismod enim, nec malesuada elit. Phasellus blandit lorem ut tincidunt blandit. Morbi ipsum sem, vehicula a fermentum eu, consectetur eu massa. Etiam et tempor diam.'));

    return (
        <Container>
            <div style={{ marginBottom: '2rem', width: '100%' }}>
                <Input placeholder="Buscar" name="search" end={<Icon name="funnel" fill="#8F9BB3" />} />
            </div>
            {comments.map((content, i) => <Comment key={i}>{content}</Comment>)}

            <Footer>
                <Pagination
                    totalOfPages={100}
                    currentPage={2}
                    callbackGetListData={async () => { }}
                />
            </Footer>

        </Container>
    );
}

export default NPSLeaderComments;