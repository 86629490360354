import React from 'react';

import Icon from 'react-eva-icons';


import { Container } from './styles';

interface IButtonAddProps {
    handleOnClick?: () => void;
}

const ButtonAdd: React.FC<IButtonAddProps> = ({
    handleOnClick
}) => {
    return (
        <Container className='button-add' onClick={handleOnClick}>
            <Icon name="plus-outline" />
        </Container>
    );
}

export default ButtonAdd;