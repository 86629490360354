import styled from 'styled-components';

export const Container = styled.div`
  .input-container {
    margin: 1.5rem 0 0 0;

    > div input::placeholder {
        font-weight: 500;
        color: #C5CEE0;
        font-size: 12px;
    }
  }

  label {
    font-size: 12px !important;
  }

  .container-page-footer button:first-child {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.88) 2.26%, #568CF7 100%);
    color: #fff;
    border-color: #568CF7;

    &:hover {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.88) 2.26%, #568CF7 100%);
      color: #fff;
      border-color: #568CF7;
    }
  }
`;

export const HoursContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10%;

  .input-container input {
    display: flex;
    width: 100%;
  }
`;


export const FileInputContainer = styled.div`
  width: 100%;
  margin-top: 4rem;

  button {
    background: #568CF7;
    border-color: #568CF7;

    &:hover {
      background: #568CF7;
      border-color: #568CF7;
    }
  }

  > div > div {
    background-color: transparent;
    border-color: transparent;
    padding: 0;

    &:hover {
      border-color: transparent;
    }
    
    label {
      width: fit-content;
      display: flex;
      align-items: center;


      > div {
        position: initial !important;
        background-color: #C5CEE0;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        margin-right: 1rem;
        
        i svg {
          width: 40px;
          height: 40px;
        }
      }

      > span {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        color: #2E3A59;
      }
    }

  }
`;

