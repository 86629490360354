import React, { Fragment } from 'react';

import { Button } from 'react-alicerce-components';
import PageFooter from '@shared/components/PageFooter';

import Layout from '@modules/students/components/Layout';

import { useStudent } from '@modules/students/context/StudentContext';
import { useNavigate } from 'react-router-dom';
import StudentForm from '../../components/StudentForm';

const StudentInfo: React.FC = () => {
  const navigate = useNavigate();
  const { studentCurrent } = useStudent();
  const refResponsiblePicking = React.createRef<any>();
  const refPersonalAcademicData = React.createRef<any>();
  const refAlicerceMainData = React.createRef<any>();

  return (
    <Fragment>
      <Layout openTab={0} footer={true}>
        <StudentForm
          form_action="watching"
          errors={[]}
          refResponsiblePicking={refResponsiblePicking}
          refPersonalAcademicData={refPersonalAcademicData}
          refAlicerceMainData={refAlicerceMainData}
        />
        <PageFooter>
          <Button transform="none" status="secondary" shaded size="large" onClick={() => navigate(`/aluno/${studentCurrent.id}/editar`)}>
            Editar
          </Button>
        </PageFooter>
      </Layout>
    </Fragment>
  );
};

export default StudentInfo;
