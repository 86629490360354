import { styled } from "react-alicerce-components";

export const Container = styled.div`
  .input-container > div input::placeholder {
    font-weight: 500;
    color: #C5CEE0;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7rem;

  button {
    flex: 0 0 calc(50% - 11px);
    min-width: 120px;
  }
`;
