import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { Input, Spinner } from 'react-alicerce-components';

import IClass from '@modules/classes/interfaces/IClass';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';

const ClassPlannerClassesSelection: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const { isLoading } = useGlobal();

  const handleClickActivities = useCallback(
    (classClicked: IClass) => {
      navigate(`/planejamento-aula/aulas/turma/${classClicked.id}`);
    },
    [navigate]
  );

  const filterClassesActivities = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <>
      <HeaderDirectory title="Seleção de Contexto" status="basic" pathToReturn="/" backgroundHeader="#8F9BB3" menuKebab={{ menuBackHome: true }} />
      <DirectoryContainer footer={false}>
        {isLoading && <Spinner />}
        <div style={{ marginBottom: '2rem' }}>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => filterClassesActivities(event.target.value)}
          />
        </div>
        <ClassesSelection handleOnClick={handleClickActivities} searchValue={searchValue} />
      </DirectoryContainer>
    </>
  );
};

export default ClassPlannerClassesSelection;
