import IProjectReport from "../interfaces/IProjectReport"
import { getLocalTimezoneDate } from "@shared/utils/getLocalTimezoneDate";

export const formatReport = (report: IProjectReport, projectId: number) => {
  const imgAttachments = report.img_attachments ?? [];
  const otherAttachments = report.others_attachments ?? [];
  
  const attachmentsFiles = [...imgAttachments, ...otherAttachments];

  const formated = {
    report_date: report.report_date ? getLocalTimezoneDate(report.report_date).startOfDay().toISOString() : undefined,
    description: report.description,
    attachments: attachmentsFiles,
    project_id: projectId,
    sprint_id: report.sprint_id
  }
  return formated
  
}