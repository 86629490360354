import axios, { AxiosRequestConfig } from 'axios';
import IApi from '@shared/interfaces/IApi';
import LocalStorageService from '@shared/services/LocalStorageService';
import { STORAGE_KEY } from '@shared/components/App';
import { appendReadOffToLogout, redirectToAuth } from '@shared/utils/authRedirect';

const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : null;

class ApiFactory {
  create(apiParams?: IApi) {
    if (!apiUrl) {
      throw new Error('REACT_APP_API_URL is not setup.');
    }

    const { token, cancelTokenSource, config } = apiParams as IApi;

    let headers: any = {
      'Content-Type': 'application/json',
      ...config?.headers,
    };

    if (token) headers.Authorization = `Bearer ${token}`;

    let options: AxiosRequestConfig = {
      baseURL: apiUrl,
      headers,
      ...config,
    };

    if (cancelTokenSource) options.cancelToken = cancelTokenSource.token;
    const api = axios.create(options);

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.data.originSystem === 'alicerce-auth-authenticator') {
          LocalStorageService.remove(STORAGE_KEY);
          appendReadOffToLogout('keep');
          redirectToAuth();
        }

        return Promise.reject(error);
      }
    );

    return api;
  }
}

export default new ApiFactory();
