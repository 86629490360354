import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { useAdvancedFilterController } from '../../context/AdvancedFilterControllerContext';

const ProgramTagsAdvancedFilter: React.FC = () => {
  const navigate = useNavigate();
  const { programFilter } = useProgram();
  const [choosenTags, setChoosenTags] = useState<ITag[]>([]);
  const { setOpenTab, setIsAdvancedFilterOpen } = useAdvancedFilterController();

  const handleOnChangeTagsSelected = useCallback((tags: ITag[]) => {
    setChoosenTags(tags);
  }, []);

  const handleClickButton = useCallback(() => {
    setIsAdvancedFilterOpen(true);
    setOpenTab('program');
    programFilter.current.tags = choosenTags;
    navigate(-1);
  }, [choosenTags, navigate, programFilter, setIsAdvancedFilterOpen, setOpenTab]);

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect="many"
      useSearch
      selectedTags={programFilter.current.tags}
      buttonCallback={handleClickButton}
      buttonText="Adicionar Tag"
    />
  );
};

export { ProgramTagsAdvancedFilter };
