export class DateHelper {
  static extractDate(date: Date = new Date()) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  }

  static extractTime(date: Date = new Date()) {
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');
    const seconds = `${date.getSeconds()}`.padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  static addMinutes(date: Date = new Date(), nMinutes: number = 0) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setTime(dateCopy.getTime() + nMinutes * 60000);
    return dateCopy;
  }

  static extractMinutesFromTime = (time: string) => {
    //@todo -- arrumar esse regex para validar 00:00:00 e 00:00 string
    let isTimeValid = time.match(/^\d{2}:\d{2}:\d{2}$/) || time.match(/^\d{2}:\d{2}$/);
    if (!isTimeValid) throw new Error(`parametro time está incorreto na função extractMinutesFromTime, >>> ${time}`);

    const [hours, minutes] = time.split(':');
    return Number(hours) * 60 + Number(minutes);
  };

  static getUSDate = (date: string): string => {
    if (!date) return '';
    const ds = date.split('/');
    if (ds.length === 1) return ds[0];
    return `${ds[2]}-${ds[1]}-${ds[0]}`;
  };

  static getUTCDate = (date: Date) => {
    const offset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() + offset);
    return date;
  };

  static isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  };
}

export const validateDateLessThanAnother = ({ start, end }): { name: string; message: string } | undefined => {
  const ds = start.split('/');
  const de = end.split('/');
  if (new Date(`${ds[2]}-${parseInt(ds[1]) - 1}-${ds[0]}`) > new Date(`${de[2]}-${parseInt(de[1]) - 1}-${de[0]}`))
    return { name: 'dates', message: 'Data de início é maior que data final.' };
};

export const getBrDate = (date?: string): string => {
  if (!date) return '';
  const ds = date.split('-');
  if (ds.length === 1) return ds[0];
  return `${ds[2]}/${ds[1]}/${ds[0]}`;
};

export const getMonday = (date: Date): Date => {
  const d = new Date(date);
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

export const changeDays = (originalDate, days, operator = '+'): Date => {
  const cloneDate = new Date(originalDate.valueOf());
  const d = operator === '+' ? cloneDate.getDate() + days : cloneDate.getDate() - days;
  cloneDate.setDate(d);
  return cloneDate;
};
