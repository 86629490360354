import React, { useCallback } from 'react';

import ISteering from '@modules/projects/interfaces/ISteering';

import { ISelectOption } from 'react-alicerce-components';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { IAttachment } from '@shared/interfaces/IAttachment';

export const useSteeringForm = () => {
  const { setSteeringCurrent, steeringCurrent } = useProjects();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setSteeringCurrent((oldState: ISteering) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setSteeringCurrent]
  );

  const handleBlur = (fieldName: string, date?: string) => {
    if (date === '') {
      setSteeringCurrent((oldState: ISteering) => ({ ...oldState, [fieldName]: '' }))
    }
  };

  const handleSelectChange = useCallback(
    (option: ISelectOption | null) => {
      if (!option) return;
      setSteeringCurrent((oldState: ISteering) => ({ ...oldState, [String(option?.selectname)]: option.value }));
    },
    [setSteeringCurrent]
  );

  const handleRemoveCard = (index: number) => {
    if (steeringCurrent) {
      const newUsers = steeringCurrent.lms_users.filter((_, i) => i !== index);
      setSteeringCurrent((oldState: ISteering) => ({ ...oldState, lms_users: newUsers}));
    }
  };

  const handleRemoveOption = useCallback(
    (indexArray: number, objectKey: string) => {
      if (steeringCurrent) {
        const filtered = removeItemFromArrayByIndex(indexArray, steeringCurrent.external_participants[objectKey] || []);
        setSteeringCurrent((oldState: ISteering) => {
          return { ...oldState, external_participants: { ...oldState.external_participants, [objectKey]: filtered } };
        });
      }
    },
    [setSteeringCurrent, steeringCurrent]
  );

  const handleOnChangeExternalParticipants = useCallback(
    (value: string, objKey: string, index: number) => {
      setSteeringCurrent((oldState: ISteering) => {
        const externalParticipants = oldState.external_participants || {};

        let aux = [...(externalParticipants[objKey] || [])];
        aux[index] = value;

        return {
          ...oldState,
          external_participants: {
            ...externalParticipants,
            [objKey]: aux,
          },
        };
      });
    },
    [setSteeringCurrent]
  );

  const handleAddParticipantsToArray = useCallback(
    (participantsKey) => {
      setSteeringCurrent((oldState) => {
        const externalParticipants = oldState.external_participants || {};

        const updatedParticipants = externalParticipants[participantsKey] ? [...externalParticipants[participantsKey], ''] : [''];

        return {
          ...oldState,
          external_participants: {
            ...externalParticipants,
            [participantsKey]: updatedParticipants,
          },
        };
      });
    },
    [setSteeringCurrent]
  );

  const handleOnAddNewFile = (files: (File | IAttachment)[]) => {
    setSteeringCurrent((oldState: ISteering) => {
      oldState['attachments'] = files;
      return { ...oldState };
    });
  };

  const handleOnRemoveFile = (indexToRemove: number) => {
    setSteeringCurrent((oldState: ISteering) => {
      oldState['attachments'] = oldState['attachments']?.filter((_, index) => Number(index) !== Number(indexToRemove));
      return { ...oldState };
    });
  };

  return {
    handleChangeInput,
    handleSelectChange,
    handleRemoveCard,
    handleRemoveOption,
    handleOnAddNewFile,
    handleOnRemoveFile,
    handleBlur,
    handleOnChangeExternalParticipants,
    handleAddParticipantsToArray
  };
};
