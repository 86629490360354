import { styled } from 'react-alicerce-components';

export const Container = styled.div<{ canSelect: boolean }>`
  margin-top: 2rem;
  color: var(--black);
  display: flex;
  justify-content: center;
  ${({ canSelect }) => canSelect && 'cursor: pointer;'}

  > div {
    width: 100%;
  }

  .card-header,
  .card-footer {
    padding: 0;
  }

  .card {
    padding: 10px;
    background-color: #ff9f6d;
  }

  .card.header {
    border-radius: 5px 5px 0px 0px;
  }
  .card-body {
    font-size: 12px;
    background-color: #ffeee5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    .container-date {
      font-weight: 600;
    }

    .container-hours {
      font-weight: 700;
    }
  }
  .card.footer {
    border-radius: 0px 0px 5px 5px;
  }

  .tag {
    color: inherit;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .container-worked-hour-issuer {
    font-weight: 800;
    font-size: 14px;
    color: #222b45;
  }
`;
