import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { useToast } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { LocationStudent } from '@shared/components/LocationStudent';

import GetIndividualGradeMapService from '@modules/dashboards/services/GetIndividualGradeMapService';
import { IIndividualGrades } from '@modules/launchers/interfaces/IStudentsWithGrades';

import { Container } from './styles';

const IndividualLearningCurrent: React.FC = () => {
  const [individualGrades, setIndividualGrades] = useState<IIndividualGrades[]>();
  const { studentId } = useParams<{ studentId: string }>();

  const { setIsLoading } = useGlobal();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const getIndividualGradeMapService = useCallback(async () => {
    if (!studentId) return;
    setIsLoading(true);
    const res = await new GetIndividualGradeMapService(signedUser?.token).execute(studentId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Aprendizagem individual',
        description: 'Error ao Recuperar Aprendizagem Individual.',
      });
      return;
    }

    if (!isMounted()) return;
    setIndividualGrades(res);
  }, [addToast, isMounted, setIsLoading, signedUser, studentId]);

  useEffect(() => {
    getIndividualGradeMapService();
  }, [getIndividualGradeMapService]);

  const transformaArray = (individualGrades) => {
    const sortValuesAsc = JSON.parse(JSON.stringify(individualGrades))?.gradeInfo?.blocks.sort((a, b) => {
      if (a.skills.length < b.skills.length) return 1;
      if (a.skills.length > b.skills.length) return -1;
      return 0;
    });

    const maxLengthOfArray = sortValuesAsc[0].skills.length;

    individualGrades?.gradeInfo?.blocks?.forEach((el) => {
      while (el.skills.length < maxLengthOfArray) {
        el.skills.push({ id: -1, status: 'undef' });
      }
    });
  };

  useEffect(() => {
    individualGrades?.forEach((individualInfo) => {
      if (!!individualInfo.gradeInfo) {
        transformaArray(individualInfo);
      }
    });
  }, [individualGrades]);

  return (
    <Container>
      {individualGrades?.map((studentData, i) => (
        <AccordionInfoItem key={i} status="basic" title={studentData.trail.name} colorHeader="#E4E9F2">
          {studentData.gradeInfo && (
            <LocationStudent
              showLabel={false}
              groupMarkers={[
                {
                  id: '1',
                  color: '#0097B1',
                  isFixed: true,
                  isMain: true,
                  gridIndex: studentData.gradeInfo?.zdp_block?.index!,
                  markers: [{ name: 'M.A.P.A' }],
                },
                {
                  id: '2',
                  color: '#F47920',
                  gridIndex: studentData.gradeInfo?.meta_block?.index!,
                  markers: [{ name: 'Objetivo Pedagógico' }, { name: 'miniM.A.P.A' }],
                },
              ]}
              trail={{
                blocks: studentData.gradeInfo?.blocks,
              }}
              showBlockDescription={false}
              showLegend={false}
            />
          )}
        </AccordionInfoItem>
      ))}
    </Container>
  );
};

export default IndividualLearningCurrent;
