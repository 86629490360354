import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid } from 'react-alicerce-components';

import ButtonAddCard from '@shared/components/ButtonAddCard';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import ClassCard from '@modules/classes/components/ClassCard';

import { useProjectForm } from '../useProjectForm';


interface ProjectClassesFragmentProps {
    disabled?: boolean
    pathAddClasses?: string
}

export const ProjectClassesFragment: React.FC<ProjectClassesFragmentProps> = ({
    disabled,
    pathAddClasses = ''
}) => {

    const navigate = useNavigate();

    const { handleRemoveClass } = useProjectForm()
    const { projectCurrent } = useProjects();
    return (
        <Grid row mt4>
            <ButtonAddCard text="Adicione Turma" handleOnClick={() => disabled ? undefined : navigate(pathAddClasses)} />
            {projectCurrent?.classes?.map(c => (
                <ClassCard
                    key={`class-card-${c.id}`}
                    classInfo={c}
                    canSelect={false}
                    inputCard={true}
                    customStyle={{ margin: '1rem' }}
                    canDeleteCard={{ removeCallback: () => disabled ? undefined : handleRemoveClass(c) }}
                />
            ))}
        </Grid>
    );
}
