import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from '@unform/web';
import Icon from 'react-eva-icons';

import { Button, InputForm, Spinner, InputDatePicker, FileInput } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import CustomForm from '@shared/components/CustomForm';
import PageFooter from '@shared/components/PageFooter';

import { Container, HoursContainer, FileInputContainer } from './styles';

const RecordingsUpload: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Upload de Aula" backgroundHeader="linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)" />

      <DirectoryContainer style={{ marginTop: '1rem', paddingTop: 0 }} footer>
        <CustomForm>
          <Form onSubmit={() => { } } onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <InputForm label="Título da Aula:" name="classTitle" placeholder="Adicionar Título da Aula" useTooltipError={false} />

            <InputDatePicker
              input={{
                name: 'date',
                placeholder: 'Dia/Mês/Ano',
                label: 'Data da Aula:',
              }}
            />

            <HoursContainer>
              <InputForm
                id="start"
                name="start"
                label="Início da Gravação:"
                mask="##:##"
                placeholder="00:00"
                end={<Icon name="clock" fill="#8F9BB3" />}
                useTooltipError={false}
              />
              <InputForm
                id="end"
                name="end"
                label="Fim da Gravação:"
                mask="##:##"
                placeholder="00:00"
                end={<Icon name="clock" fill="#8F9BB3" />}
                useTooltipError={false}
              />
            </HoursContainer>

            <InputForm label="Link do Video: " name="videLink" placeholder="Adicione Link do Vídeo" useTooltipError={false} />
            <FileInputContainer>
              <FileInput id="upload" label="Fazer Upload de Video" icon={<Icon name="upload-outline" />} required multiple onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </FileInputContainer>
          </Form>
        </CustomForm>
        <PageFooter>
          <Button transform="none" onClick={() => navigate(-1)} outline shaded>
            Cancelar
          </Button>
          <Button fill="#568CF7" transform="none" shaded>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export default RecordingsUpload;
