import { styled } from 'react-alicerce-components';

export const Container = styled.div`

    .divider {
        margin-top: 0;
    }

    .accordion-header {
        justify-content: flex-start;
        background-color: #E4E9F2;
    }

    .accordion-content {
        padding-top: 1rem;
    }
    
    .donut-chart-bottom-label {
        font-size: 14px;
        color: #222B45;
        font-weight: 700;
    }

    .donut-chart-children {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        
        .box-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
        }

        .box-label {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
            background-color: #E4E9F2;
            text-align: center;
            font-size: 12px;
            font-weight: 800;
            color: #222B45;
            border-radius: 5px;
            padding: 10px;
        }

        .box-value {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
            background-color: #C5CEE0;
            text-align: center;
            font-size: 16px;
            color: #222B45;
            border-radius: 5px;
        }

        button {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

`;


