import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { Cell, LabelList, Pie, PieChart } from 'recharts';

import StrokeLabel from '@shared/components/CustomRecharts/charts/DonutChart/StrokeLabel';

import Legend, { ILegendType } from '@shared/components/CustomRecharts/elements/Legend';

import { BottomLabel, Container } from './styles';

export type DonutChartProps = {
  data: any[];
  width?: number | string;
  height?: number | string;
  contentCenter?: string | JSX.Element;
  showStrokedPercent?: boolean,
  showPercentOnParts?: boolean,
  outerRadius?: number;
  innerRadius?: number;
  startAngle?: number
  max?: number;
  bottomLabel?: string;
  restStrokedColor?: string;
  legendType?: ILegendType;
  dataKey?: string;
  isPercent?: boolean,
}

const DonutChart: React.FC<DonutChartProps> = ({
  data: dataProps,
  contentCenter,
  width,
  height = 300,
  outerRadius = 120,
  innerRadius = 60,
  showStrokedPercent = false,
  showPercentOnParts = false,
  legendType,
  bottomLabel,
  children,
  max = 100,
  restStrokedColor = 'transparent',
  dataKey = 'percent',
  isPercent = true,
  startAngle = -270,
}) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const [chartSize, setChartSize] = useState<{ width: number, height: number } | undefined>();

  const data = useMemo(() => {
    const ac = dataProps.reduce((a, b) => a + b.percent, 0)
    const rest = max - ac;
    return rest > 0 ? [{ name: 'rest', color: 'transparent', percent: rest }, ...dataProps] : dataProps;
  }, [dataProps, max]);

  const renderStrokeLabel = useCallback((props) => (
    <StrokeLabel
      x={props.x}
      y={props.y}
      cx={props.cx}
      cy={props.cy}
      value={`${props.payload.percent}${isPercent ? '%' : ''}`}
    />
  ), [isPercent]);

  useEffect(() => {
    setChartSize({
      width: Number(containerRef.current?.clientWidth),
      height: Number(containerRef.current?.clientHeight),
    });
  }, [containerRef, height, width])

  return (
    <>
      <Container
        ref={containerRef}
        showStrokedPercent={showStrokedPercent}
        showPercentOnParts={showPercentOnParts}
        width={width}
        height={height}
      >
        <PieChart {...chartSize}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            dataKey={dataKey}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            label={showStrokedPercent ? renderStrokeLabel : false}
            startAngle={startAngle}
          >
            {data.map((item, index) => (<Cell key={`cell-${index}`} fill={item.color} stroke={item.name === 'rest' ? restStrokedColor : ''} strokeWidth={1} />))}
            {showPercentOnParts && <LabelList dataKey="percent" position="top" formatter={v => `${v}${isPercent && v ? '%' : ''}`} />}
          </Pie>
        </PieChart>
        <span className="donut-chart-content-center">{contentCenter}</span>
      </Container>
      {bottomLabel && <BottomLabel className="donut-chart-bottom-label">{bottomLabel}</BottomLabel>}
      <Legend items={data} type={legendType} />
      {children}
    </>
  );
};

export default DonutChart;
