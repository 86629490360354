import IRoute from '@shared/interfaces/IRoute';

import Home from '@modules/home/views/Home';
import MyClasses from '@modules/home/views/MyClasses';
import MyGroups from '@modules/home/views/MyGroups';
import MyPlaces from '@modules/home/views/MyPlaces';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { combineContext } from '@shared/utils/combineContext';
import { HomeProvider } from '@modules/home/context/HomeContext';

const routes: IRoute[] = [
  {
    path: '/',
    key: 'HOME_PATH',
    exact: true,
    component: Home,
    permissions: 'public',
  },
  {
    path: '/minhas-turmas',
    key: 'MY_CLASSES',
    exact: true,
    component: MyClasses,
    permissions: 'public',
  },
  {
    path: '/meus-grupos',
    key: 'MY_GROUPS',
    exact: true,
    component: MyGroups,
    permissions: 'public',
  },
  {
    path: '/meus-locais',
    key: 'MY_PLACES',
    exact: true,
    component: MyPlaces,
    permissions: 'public',
  },
];

const providers = [HomeProvider];

export default {
  key: 'HOME_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
