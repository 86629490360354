import React from 'react';
import { Pill } from 'react-alicerce-components';
import { WorkGroupCardCardBorderContainer } from './styles';


export interface IActivityLibraryCardBorder {
  data: string[];
  location: 'header' | 'footer';
  handleOnClick?: () => void;
}

const ActivityLibraryCardBorder: React.FC<IActivityLibraryCardBorder> = (props) => {
  return (
    <WorkGroupCardCardBorderContainer location={props.location} onClick={props.handleOnClick}>
      {props.data.map((d, index) => (
        <Pill key={index}>{d}</Pill>
      ))}
    </WorkGroupCardCardBorderContainer>
  );
};

export default ActivityLibraryCardBorder;
