import React, { useCallback, useEffect, useRef } from 'react';
import { Spinner, Button, H6, Grid } from 'react-alicerce-components';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CurrentSprintAndPeriod from '@modules/projects/components/CurrentSprintAndPeriod';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';
import GetCurrentPeriodService from '@modules/periods/services/GetCurrentPeriodService';
import { HeadlightView, HeadlightViewHandle } from '@modules/projects/components/HeadlightView';
import GetHeadlightService from '@modules/projects/services/Headlight/GetHeadlightService';

const ProjectHeadlightView: React.FC = () => {

  const { isLoading, setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setCurrentSprint, setCurrentPeriod, setCurrentHeadlight, currentHeadlight } = useHeadlights();
  const { id: headlightId } = useParams<{ id: string }>();
  const headlightViewRef = useRef<HeadlightViewHandle>(null);

  const getCurrentSprintAndPeriod = useCallback(async (scheduleId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (scheduleId) {
      const sprint = await new GetCurrentSprintService(signedUser.token).execute({ schedule_id: Number(scheduleId) });
      if (!sprint) return;
      setCurrentSprint(sprint);

      const period = await new GetCurrentPeriodService(signedUser.token).execute(Number(sprint.id));
      if (!period) return;
      setCurrentPeriod(period);
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser, setCurrentSprint, setCurrentPeriod]);

  useEffect(() => {
    getCurrentSprintAndPeriod(1)
  }, [getCurrentSprintAndPeriod]);

  const getHeadlight = useCallback(async (headlightId) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (headlightId) {
      const headlight = await new GetHeadlightService(signedUser.token).execute(headlightId);
      if (!headlight) return;
      setCurrentHeadlight(headlight)
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser, setCurrentHeadlight]);

  useEffect(() => {
    getHeadlight(headlightId)
  }, [getHeadlight, headlightId]);

  return (
    <ProjectDefaultStylesContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title={'Visualizar Farol'}
        onClickReturn={() => navigate(-1)}
        menuKebab={{ tooltip: { view: 'HeadlightView' }, menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        <CurrentSprintAndPeriod />
        <Grid textCenter>
          <H6>{currentHeadlight && currentHeadlight.project?.name}</H6>
        </Grid>
        <HeadlightView ref={headlightViewRef} readOnly/>
        <PageFooter>
          <Button
            transform="none"
            shaded
            noBorder
            onClick={() => navigate(`/projetos/farol/${headlightId}/editar`)}
          >
            Editar Farol
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectHeadlightView;
