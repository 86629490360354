import React, { useEffect, useRef } from 'react';
import { IInputProps, Input } from 'react-alicerce-components';

type DebounceInputProps = IInputProps & {
  debounceTime?: number;
};

export const DebounceInput: React.FC<DebounceInputProps> = ({ debounceTime = 500, ...props }) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    
    if (timeout.current) clearTimeout(timeout.current);

    const currentTimeout = setTimeout(() => {
      props.onChange && props.onChange(e);
    }, debounceTime);

    timeout.current = currentTimeout;
  }

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  return <Input {...props} onChange={handleOnChange} />;
};
