import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Pagination } from 'react-alicerce-components';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import { IApplication, IApplicationStatus } from '@modules/applications/interfaces/IApplication';
import InputFilter, { IInputFilterData } from '@modules/applications/components/InputFilter';
import ApplicationCard from '@modules/applications/components/ApplicationCard';
import ListApplicationsService, { IListApplicationsServiceReponse } from '@modules/applications/services/ListApplicationsService';
import GetClassService from '@modules/classes/services/GetClassService';

import { Container } from './styles';

interface StudentApplicationListManagerProps {
  status?: IApplicationStatus;
  filters?: string[]; // 'age_group' | 'applied_between' | 'started_between' | 'type' | 'trails',
  studentIds?: string[];
}

const StudentApplicationListManager: React.FC<StudentApplicationListManagerProps> = ({ status = 'PENDING', filters, studentIds }) => {
  const { studentId, classId } = useParams();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const [applications, setApplications] = useState<IListApplicationsServiceReponse>();
  const [inputSearch, setInputSearch] = useState<string>('');

  const getClassType = useCallback(async () => {
    if (!signedUser || !classId) return;
    const res = await new GetClassService(signedUser?.token).execute(classId);
    if (!res) return;
    return res?.type;
  }, [classId, signedUser]);

  const listApplications = useCallback(
    async (page = 0, additionalOptions?: any) => {
      if (!signedUser) return;

      setIsLoading(true);
      const classType = await getClassType();
      const trailType = getTrailIdByClassType(classType);

      const opt = {
        ...additionalOptions,
        student_ids: studentIds,
        trail_ids: trailType,
        status: [status],
        page,
      };

      const res = await new ListApplicationsService(signedUser.token, newCancelToken()).execute(inputSearch, opt);

      setIsLoading(false);
      if (res === 'canceling' || !res) return;
      setApplications(res);
    },
    [getClassType, inputSearch, newCancelToken, setIsLoading, signedUser, status, studentIds]
  );

  const getTrailIdByClassType = (classType: string | undefined) => {
    // trailsID :
    // 1: Inglês - 2: Leitura - 3: Matemática - 4: Redação - 5: Hab. para Vida
    if (classType === 'english') return ['9'];
    if (classType === 'core') return ['2', '3', '4', '5'];
    return;
  };

  const handleSetApplication = useCallback(
    (application: IApplication) => {
      if (status !== 'FINALIZED') return;
      if (!!application?.state?.notApply) return;
      navigate(`/aplicacoes/${application.id}/turma/${classId}/aluno/${studentId}/gabarito`);
    },
    [classId, navigate, status, studentId]
  );

  const handleOnChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(ev.target.value);
  }, []);

  const handleOnFilter = useCallback(
    (data: IInputFilterData) => {
      listApplications(0, data);
    },
    [listApplications]
  );

  useEffect(() => {
    listApplications();
  }, [listApplications]);

  return (
    <Container>
      <InputFilter handleOnChange={handleOnChange} handleOnFilterOptions={handleOnFilter} filters={filters} />

      {applications?.items.map((application, i) => (
        <ApplicationCard
          key={i}
          application={application}
          handleOnClick={!application.deleted_at ? () => handleSetApplication(application) : () => {}}
          status="quaternary"
        />
      ))}

      {!applications?.items.length && <p style={{ marginTop: '1rem' }}>Nenhuma aplicação para mostrar...</p>}

      {!!applications?.totalPages && applications?.totalPages > 1 && (
        <Pagination
          totalOfPages={applications?.totalPages}
          currentPage={applications?.currentPage}
          callbackGetListData={(_, page) => listApplications(page)}
          status="quaternary"
        />
      )}
    </Container>
  );
};

export default StudentApplicationListManager;
