import IQuestion from '@shared/interfaces/IQuestion';
import { IApplication, IResponse, IState } from '../interfaces/IApplication';
import { questionsHV } from '../mocks/questionsHV';

export type IFormatQuestion = IQuestion & {
  response?: IResponse;
};

export interface IFormattedApplication {
  questions: IFormatQuestion[];
  totalTestTime?: string;
  type?: string;
  finalized?: string;
}

export interface IQuestionHV {
  block: string;
  name: string;
  id: number;
  question: string;
}

export const formatApplication = (data: IApplication): IFormattedApplication => {
  const responses = data.state?.type === 'MAPA' ? data.state?.mapaData?.localVariables?.responses : data.state?.miniMapaData?.responses;
  const questions = !data?.questions
    ? []
    : data?.questions.map((question) => ({
        ...question,
        response: responses?.find((response) => `${response.questionId}` === `${question.id}`),
        statement_data: typeof question.statement_data === 'string' ? JSON.parse(question.statement_data) : question.statement_data,
        answerTime: getAnswerTime(data?.state as IState, question),
      }));
  return {
    questions,
    totalTestTime: formatSeconds((new Date(`${data?.state?.finalizedAt}`).getTime() - new Date(`${data?.state?.startedAt}`).getTime()) / 1000),
    type: data.state?.type,
    finalized: data.state?.finalizedAt,
  };
};

export const formatApplicationHv = (data: IApplication): IFormattedApplication => {
  const responses = data.state && data.state.HVData?.responses;
  const questions = !questionsHV
    ? []
    : questionsHV.map((question, index) => ({
        ...question,
        id: `${index + 1}`,
        response: responses?.find((response) => `${response.questionId}` === `${question.id}`),
        statement_data: `${question.question}`.includes(`html`) ? JSON.parse(`${question.question}`) : `${question.question}`,
        answerTime: getAnswerTimeHV(data?.state as IState, question),
      }));
  return {
    questions,
    totalTestTime: formatSeconds((new Date(`${data?.state?.finalizedAt}`).getTime() - new Date(`${data?.state?.startedAt}`).getTime()) / 1000),
  };
};

export const formatApplicationDissertative = (data: IApplication): IFormattedApplication => {
  const responses = data.state && data.state.dissertativeData?.responses;
  const questions = !data?.questions
    ? []
    : data?.questions.map((question) => ({
        ...question,
        response: responses?.find((response) => `${response.questionId}` === `${question.id}`),
        statement_data: typeof question.statement_data === 'string' ? JSON.parse(question.statement_data) : question.statement_data,
        answerTime: getAnswerTime(data?.state as IState, question),
      }));
  return {
    questions,
    totalTestTime: formatSeconds((new Date(`${data?.state?.revisionAt}`).getTime() - new Date(`${data?.state?.startedAt}`).getTime()) / 1000),
  };
};

export const getAnswerTimeHV = (state: IState, question: IQuestionHV): string => {
  const responses = state?.HVData?.responses && state.HVData.responses;

  const responseFounded = responses?.find((response) => `${response.questionId}` === `${question.id}`);

  if (!responseFounded?.responseTime) return '';

  return formatSeconds(responseFounded.responseTime / 1000);
};

export const getAnswerTime = (state: IState, question: IQuestion): string => {
  let responses: IResponse[] | undefined;
  if (state.type === 'MAPA') responses = state?.mapaData?.localVariables.responses;

  if (state.type === 'MINIMAPA') responses = state?.miniMapaData?.responses;

  if (state.type === 'DISSERTATIVE') responses = state?.dissertativeData?.responses;

  if (state.type === 'OBSERVATION') responses = state?.HVData?.responses;

  const responseFounded = responses?.find((response) => `${response.questionId}` === `${question.id}`);

  if (!responseFounded?.responseTime) return '';

  return formatSeconds(responseFounded.responseTime / 1000);
};

export const formatSeconds = (seconds: number): string => {
  if (seconds < 60) return `${seconds.toFixed(2)} segundo${seconds > 1 ? 's' : ''}`;
  const minutes = Math.round(seconds / 60);
  if (minutes < 60) return `${minutes} minuto${minutes > 1 ? 's' : ''}`;
  const hour = Math.round(minutes / 60);
  return `${hour} hora${hour > 1 ? 's' : ''}`;
};
