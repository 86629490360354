import React, { Fragment, useCallback, useEffect, useMemo } from "react";

import PlaceList from "@modules/places/components/PlaceList";
import { usePlaces } from "@modules/places/context/PlacesContext";
import IPlace from "@modules/places/interfaces/IPlace";
import { useNavigate } from "react-router-dom";

const PlaceListParentEdit: React.FC = () => {
  const { setPlaceCurrent, placeCurrent } = usePlaces();
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (newPlace: IPlace) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, parent: newPlace };
      });
    },
    [setPlaceCurrent]
  );

  const setSelectedItens = useCallback(
    (newPlaces: IPlace[]) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, parent: newPlaces[0], parent_id: newPlaces[0].id };
      });
    },
    [setPlaceCurrent]
  );

  const selectItens = useMemo(() => {
    const aux = [] as IPlace[];
    placeCurrent?.parent && aux.push(placeCurrent.parent);
    return aux;
  }, [placeCurrent]);

  useEffect(() => {
    if (!placeCurrent) navigate("/meus-locais");
  }, [navigate, placeCurrent]);

  if (!placeCurrent) return <Fragment />;

  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Editar Local Mãe"
      pathRedirect={`/local/${placeCurrent.id}/editar`}
      pathToReturn={`/local/${placeCurrent.id}/editar`}
      canSelectPlace={"one"}
      handleOnClick={handleOnClick}
      selectedItens={selectItens}
      setSelectedItens={setSelectedItens}
    />
  );
};

export default PlaceListParentEdit;
