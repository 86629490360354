import React from 'react';

import { Grid, IInputRadioProps, Radio } from 'react-alicerce-components';

import { Container, Wrapper } from './styles';
import headlightsConfig, { HeadlightType } from '@modules/projects/utils/headlightsConfig';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { HeadlightStatusOptions } from '@modules/projects/schemas/Headlight/partials/HeadlightStatus';

export type HeadlightSelectProps = IInputRadioProps & {
  status: {
    status: HeadlightStatusOptions
    description: string
  };
  category: string;
  categoryDescription: string;
  checkedStatus?: HeadlightStatusOptions;
  readOnly?: boolean
}

const HeadlightSelect: React.FC<HeadlightSelectProps> = ({
  category,
  status,
  name,
  categoryDescription,
  checkedStatus,
  readOnly = false
}) => {
  const { setCurrentHeadlight } = useHeadlights();
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, subLevel: string) => {

    const [, inputCategory, inputValue, inputStatusDescription] = event.target.id.split('_');

    setCurrentHeadlight((oldState: IHeadlight) => {
      const oldData = Array.isArray(oldState[subLevel]) ? oldState[subLevel] : [];
      
      const oldDataFormatted = oldData.filter((obj) => obj.category !== inputCategory)
      const newData = {
        status: inputValue,
        category: category,
        category_description: categoryDescription || '',
        status_description: inputStatusDescription || ''
      };
      const updatedData = [...oldDataFormatted, newData];
      return { ...oldState, [subLevel]: updatedData };
    });
  };

  return (
    <Container>
      {Object.keys(status)?.map((key) => {
        if (key in headlightsConfig) {
          return (
            <Wrapper
              key={key}
              headlightType={key as HeadlightType}
            >
              <Radio
                id={`radio_${category}_${key}_${status[key]}`}
                value={key}
                round
                name={name}
                label={headlightsConfig[key as HeadlightType].label}
                onChange={(event) => handleChangeRadio(event, 'data')}
                defaultChecked={key === checkedStatus}
                disabled={readOnly}
              />
              <Grid ml5>
                {status[key]}
              </Grid>
            </Wrapper>
          );
        }
      })}
    </Container>
  );
};

export default HeadlightSelect;
