import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IQueryOptions from '@shared/interfaces/IQueryOptions';
import { IBlock } from '@shared/interfaces/IBlock';
import { ITrail } from '@shared/interfaces/ITrail';
import { IStudent } from '../../students/interfaces/IStudent';
import { IWorkGroup } from '../interfaces/IWorkGroup';

export interface StudentsWithGrades {
  grade_info: IGradeInfo[];
  student: IStudent;
  worked_groups?: IWorkGroup[]
}

export interface IGradeInfo {
  block: IBlock;
  trail: ITrail;
}

export class ListClassStudentsWithGradesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(classId: string, query?: string, options?: IQueryOptions): Promise<StudentsWithGrades[] | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options });
      const res = await this.api.get(`/external/mapa4/grade/class/${classId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      console.error(`Error: ${error}`);
      return false;
    }
  }
}
