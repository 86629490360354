import React, { useCallback, useState, useEffect } from 'react';

import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';

import GetClassLearningCurrentService from '@modules/dashboards/services/GetClassLearningCurrentService';
import { formatGroupLearningCurrent, IFormattedGroupLearningCurrent } from '@modules/dashboards/utils/formatGroupLearningCurrent';

import { Container, Content } from './styles';

interface IGroupLearningCurrentProps {
  classId?: string;
}

const ClassLearningCurrent: React.FC<IGroupLearningCurrentProps> = ({
  classId
}) => {
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();

  const [state, setState] = useState<IFormattedGroupLearningCurrent>();

  const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
    const orderBy = sort.sortName as string;

    setState((oldState) => {
      let aux = [...oldState?.data]
      aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
      return {
        ...oldState,
        data: aux
      } as IFormattedGroupLearningCurrent;
    });
  }, []);

  const load = useCallback(async (options?: any) => {

    if (!signedUser) return;

    setIsLoading(true);

    const res = await new GetClassLearningCurrentService(signedUser.token).execute(`${classId}`, { ...options });
    setState(res ? formatGroupLearningCurrent(res) : undefined);

    setIsLoading(false);

  }, [classId, setIsLoading, signedUser]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Container className="group-learning-current-container">
      <Content>
        {state?.columns && state?.data &&
          <DynamicTable
            columnsGroup={state?.columns as IDynamicTableColumnsGroup[]}
            data={state?.data}
            handleClickSort={handleOnClickSort}
          />
        }
      </Content>
    </Container>
  );
};

export default ClassLearningCurrent;
