import { WEEK_DAYS_CONSTRAINTS } from '@shared/utils/validData/validWeekdays';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import React, { useCallback } from 'react';

import { Checkbox, Label } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

import { Container, DaysOfWeekSelectContainer } from './styles';

interface IDay {
  name: string;
  value: string;
  selected?: boolean;
}

export interface IDaysOfWeekSelecProps {
  status?: ElementStatus;
  statusNumber?: number;
  label?: string;
  days?: IDay[];
  daysChecked?: string[];
  errors?: IInputErrorsFormat[];
  onClickCheckbox?: (value: string, status: boolean) => void;
  disabled?: boolean;
}

const DaysOfWeekSelect: React.FC<IDaysOfWeekSelecProps> = ({
  errors = [],
  onClickCheckbox,
  label,
  status = 'quaternary',
  statusNumber = 400,
  days = WEEK_DAYS_CONSTRAINTS,
  daysChecked,
  disabled,
}) => {
  const handleOnChangeCheckbox = useCallback(
    (event) => {
      onClickCheckbox && onClickCheckbox(event.target.name, event.target.checked);
    },
    [onClickCheckbox]
  );

  return (
    <DaysOfWeekSelectContainer>
      <Label fontSize="12px">{label}</Label>
      <Container status={status} statusNumber={statusNumber}>
        {days.map((day: IDay, i: number) => (
          <Checkbox
            key={i}
            id={day.value}
            name={day.value}
            value={day.value}
            label={day.name.slice(0, 3)}
            checked={daysChecked?.includes(day.value)}
            onChange={(event) => handleOnChangeCheckbox(event)}
            disabled={disabled}
          />
        ))}
      </Container>
      {errorMessageBuilder('week_days', errors) && <label className="error-weekdays">{errorMessageBuilder('week_days', errors)}</label>}
    </DaysOfWeekSelectContainer>
  );
};

export { DaysOfWeekSelect };
