import * as Yup from 'yup';

export const placeSchema = Yup.object().shape({
  name: Yup.string().required({ name: 'name', message: 'O campo é obrigatório' }),
  category: Yup.string()
    .oneOf(['region', 'city', 'location', 'room'], { name: 'category', message: "Deve ser do tipo 'region','city','location' ou 'room'" })
    .required({ name: 'category', message: 'O campo é obrigatório' }),
  status: Yup.string()
    .oneOf(['implantation', 'closed', 'execution'], { name: 'status', message: "Deve ser do tipo 'implantation', 'closed', 'execution'" })
    .required({ name: 'status', message: 'O campo é obrigatório' })
    .typeError({ name: 'status', message: "Por favor selecione o status." }),
  description: Yup.string().optional(),
  parent_id: Yup.number().optional(),
  virtual_place: Yup.boolean().required({ name: 'virtual_place', message: 'O campo é obrigatório' }),
  place_url: Yup.string().when('virtual_place', {
    is: true,
    then: Yup.string()
      .url({ name: 'place_url', message: 'Deve ser uma url valida' })
      .required({ name: 'place_url', message: 'O campo é obrigatório' }),
    otherwise: Yup.string().url({ name: 'place_url', message: 'Deve ser uma url valida' }).optional(),
  }),
  // TODO: address_data necessario somente para location
  address_data: Yup.object({
    address: Yup.string().required({ name: 'address', message: 'O campo é obrigatório' }),
    city: Yup.string().required({ name: 'city', message: 'O campo é obrigatório' }),
    uf: Yup.string().required({ name: 'uf', message: 'Obrigatório' }),
  })
    .nullable()
    .required({ name: 'address_data', message: 'É necessário preencher os campos Endereço, Cidade e UF' }),
  tag_ids: Yup.array().of(Yup.number()).optional(),
  lms_user_ids: Yup.array().of(Yup.number()).optional(),
});
