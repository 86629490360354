import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IContract from '@modules/contracts/interfaces/IContract';
import IListOptions from '@shared/interfaces/IListOptions';

export interface IContractResponse extends IPaginatedResponse {
  items: IContract[];
}

export interface IListContractBusinessOptions extends IListOptions {
  status?: string[]
  gic_contact_company_id?: number[]
  end_date: { start: string | undefined, end: string | undefined }
}
class ListContractsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query: string = '', options: IListContractBusinessOptions = { end_date: { end: undefined, start: undefined } }): Promise<IContractResponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      this.api.defaults.baseURL = process.env.REACT_APP_GIF_API_URL || ''
      const res = await this.api.get(`/contract-business?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListContractsService;
