import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';
import { uuid } from 'uuidv4';
import { LocationStudent } from '@shared/components/LocationStudent';
import { IStudent } from '@modules/students/interfaces/IStudent';
import { EditLearningTargetService } from '@modules/learning-targets/services/EditLearningTargetService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import { useToast } from 'react-alicerce-components';
import { IGradeInfo } from '../../../../launchers/interfaces/IStudentsWithGrades';

export interface ILearningTargetsStudentCardLocation {
  isOpen?: boolean;
  initialColor?: string;
  finalColor?: string;
  colorVariation?: number;
  blocksAndSkills: any;
  studentData: IStudent;
  handleOnClick?: (value: any) => void;
  setValidClick?: (value: any) => void;
  listStudentFromClass: () => Promise<void>;
}

type MetaDataNumber = {
  meta_block_id: number | undefined;
};

const LearningTargetsStudentCardLocation: React.FC<ILearningTargetsStudentCardLocation> = ({
  isOpen,
  handleOnClick,
  blocksAndSkills,
  setValidClick,
  listStudentFromClass,
}) => {
  const [heightState, setHeightState] = useState<string>('0px');
  const [metaBlock, setMetaBlock] = useState<MetaDataNumber>();
  const [gradeValidated, setGradeValidated] = useState<IGradeInfo | undefined>();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const block = blocksAndSkills?.blocks;
  const blockIndex = blocksAndSkills?.meta_block.index;
  const currentApplicationStatus = blocksAndSkills?.current_application;

  const handleMoveMarker = (targetId) => {
    setMetaBlock({ meta_block_id: Number(targetId) });
  };

  const handleClickSave = (justificationMetaBlockChange?: string) => {
    editLearningTarget(justificationMetaBlockChange);
  };

  const editLearningTarget = useCallback(
    async (justificationMetaBlockChange?: string) => {
      if (!blocksAndSkills.id) return;
      setIsLoading(true);
      const res = await new EditLearningTargetService(signedUser?.token).execute(blocksAndSkills.id, {
        ...metaBlock,
        justification: justificationMetaBlockChange,
      });
      setIsLoading(false);

      if (!res) {
        addToast({
          status: 'danger',
          title: 'Metas de aprendizagem',
          description: 'Erro ao Salvar metas de aprendizagem.',
        });
        return;
      }

      addToast({
        status: 'success',
        title: 'Metas de aprendizagem',
        description: 'Meta de Aprendizagem Salva com Sucesso!',
      });
      await listStudentFromClass();
      setValidClick && setValidClick(true);
      setGradeValidated(res);
    },
    [addToast, blocksAndSkills.id, listStudentFromClass, metaBlock, setIsLoading, setValidClick, signedUser]
  );

  useEffect(() => {
    if (isOpen) {
      setHeightState(`max-content`);
    } else {
      setHeightState('0px');
    }

    if (!block) return;
    const isMetaBlockAlreadyDefined = metaBlock?.meta_block_id;
    if (!isMetaBlockAlreadyDefined) setMetaBlock({ meta_block_id: Number(block[blockIndex]?.id) });
  }, [block, blockIndex, isOpen, metaBlock]);

  useEffect(() => {
    const transformaArray = (blocksAndSkills) => {
      const sortValuesAsc = JSON.parse(JSON.stringify(blocksAndSkills))?.blocks.sort((a, b) => {
        if (a.skills.length < b.skills.length) return 1;
        if (a.skills.length > b.skills.length) return -1;
        return 0;
      });

      const maxLengthOfArray = sortValuesAsc[0].skills.length;

      blocksAndSkills?.blocks?.forEach((el) => {
        while (el.skills.length < maxLengthOfArray) {
          el.skills.push({ id: -1, status: 'undef' });
        }
      });
    };

    !!blocksAndSkills && transformaArray(blocksAndSkills);
  }, [blocksAndSkills]);

  const ZDPBlockIndex = blocksAndSkills?.zdp_block?.index;
  const metaBlockIndex = blocksAndSkills?.meta_block.index;
  const lastMinimapaIndex = blocksAndSkills?.last_minimapa_block?.index;
  const validationType = blocksAndSkills?.validation_type;
  const validationMetaBlockIndex = blocksAndSkills?.validation_meta_block?.index;

  const infoMetaGradeMap = (value: string) => {
    const infoMap = {
      printed_minimapa: 'miniM.A.P.A Impr.',
      learning_cards: 'Fich. Aprendz.',
      teacher_observation: 'Obs. do Instrutor',
      completed_mapa: 'completed_mapa',
    };
    const result = infoMap[value];
    return result || 'miniM.A.P.A';
  };

  // obs e minimapa info nova,
  // dentro do gradeDate,
  // minimapa > grade Data ultima location
  // obs instrutor ultimo block q ele definiou > grade data.
  return (
    <Container heightState={heightState} onClick={handleOnClick}>
      {!!blocksAndSkills && isOpen && (
        <LocationStudent
          key={`${metaBlockIndex}${ZDPBlockIndex}`}
          lastApplicationId={blocksAndSkills?.last_application_id}
          metaBlockValided={blocksAndSkills?.is_meta_block_validated || gradeValidated?.is_meta_block_validated}
          blockIndex={blockIndex}
          showEditable
          applicationIsFinalized={currentApplicationStatus}
          canSelect={false}
          showLabel={false}
          showLegend
          validationObservation={blocksAndSkills?.validation_observation}
          validationJustification={blocksAndSkills?.validation_justification}
          groupMarkers={[
            {
              id: uuid(),
              color: '#328DA1',
              isMain: true,
              isFixed: true,
              gridIndex: ZDPBlockIndex ? ZDPBlockIndex : 0,
              markers: [{ name: 'M.A.P.A' }],
            },
            {
              id: uuid(),
              color: '#6749BA',
              gridIndex: metaBlockIndex,
              markers: [{ name: 'Objetivo Pedagógico' }],
            },
            {
              id: uuid(),
              color: '#F7652B',
              gridIndex: lastMinimapaIndex ?? metaBlockIndex,
              isFixed: true,
              markers: [{ name: 'miniM.A.P.A' }],
            },
            {
              id: uuid(),
              color: '#F7652B',
              isFixed: true,
              gridIndex: validationMetaBlockIndex ?? -1,
              markers: [{ name: `${infoMetaGradeMap(validationType)}` }],
            },
          ]}
          trail={{
            name: 'DESBRAVADOR',
            blocks: blocksAndSkills.blocks,
            skill_descriptions: blocksAndSkills.skill_descriptions,
            meta_block: blocksAndSkills.meta_block,
            zdp_block: blocksAndSkills.zdp_block,
          }}
          handleSelectChange={(e) => handleMoveMarker(e)}
          getValuesToSave={handleClickSave}
        />
      )}
    </Container>
  );
};
export { LearningTargetsStudentCardLocation };
