import React, { useCallback, useMemo } from 'react';

import PlaceList from '@modules/places/components/PlaceList';
import IPlace from '@modules/places/interfaces/IPlace';
import { useScheduleGrid } from '../../context/ScheduleGridContext';

const ClassPlannerPlaceRelationCreate: React.FC = () => {
  const { setDailyClass, dailyClass } = useScheduleGrid();

  const handleOnClick = useCallback(
    (newPlace: IPlace) => {
      setDailyClass((oldState: any) => {
        return { ...oldState, place: newPlace, place_id: newPlace.id };
      });
    },
    [setDailyClass]
  );

  const setSelectedItens = useCallback(
    (newPlaces: IPlace[]) => {
      setDailyClass((oldState: any) => {
        return { ...oldState, place: newPlaces[0] };
      });
    },
    [setDailyClass]
  );

  const selectItens = useMemo(() => {
    const aux = [] as IPlace[];
    dailyClass?.place && aux.push(dailyClass.place);
    return aux;
  }, [dailyClass]);

  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Adicionar Local"
      canSelectPlace={'one'}
      handleOnClick={handleOnClick}
      selectedItens={selectItens}
      setSelectedItens={setSelectedItens}
    />
  );
};

export default ClassPlannerPlaceRelationCreate;
