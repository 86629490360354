export const PROJECT_TYPE_B2B_CONTRAINS = 'b2b' //B2B
export const PROJECT_TYPE_B2G_CONTRAINS = 'b2g' //B2G
export const PROJECT_TYPE_B2C_CONTRAINS = 'b2c' //B2C
export const PROJECT_TYPE_B2B2C_CONTRAINS = 'b2b2c' //B2B2C
export const PROJECT_TYPE_LEARNER_CONTRAINS = 'learner' //Aprendiz

export const PROJECT_TYPE_B2B = { name: 'B2B', value: PROJECT_TYPE_B2B_CONTRAINS };
export const PROJECT_TYPE_B2G = { name: 'B2G', value: PROJECT_TYPE_B2G_CONTRAINS };
export const PROJECT_TYPE_B2C = { name: 'B2C', value: PROJECT_TYPE_B2C_CONTRAINS };
export const PROJECT_TYPE_B2B2C = { name: 'B2B2C', value: PROJECT_TYPE_B2B2C_CONTRAINS };
export const PROJECT_TYPE_LEARNER = { name: 'Aprendiz', value: PROJECT_TYPE_LEARNER_CONTRAINS };

export const PROJECT_TYPE_CONSTRAINTS_OPTIONS = [PROJECT_TYPE_B2B,
    PROJECT_TYPE_B2G,
    PROJECT_TYPE_B2C,
    PROJECT_TYPE_B2B2C,
    PROJECT_TYPE_LEARNER];

export const PROJECT_TYPE_CONSTRAINTS = [
    PROJECT_TYPE_B2B_CONTRAINS,
    PROJECT_TYPE_B2G_CONTRAINS,
    PROJECT_TYPE_B2C_CONTRAINS,
    PROJECT_TYPE_B2B2C_CONTRAINS,
    PROJECT_TYPE_LEARNER_CONTRAINS
];

export const TYPE_PROJECT_VALUES = PROJECT_TYPE_CONSTRAINTS_OPTIONS.map((c) => c.value);

export const defaultProjectType = (projectType?: string | null) => PROJECT_TYPE_CONSTRAINTS_OPTIONS.map(i => ({ ...i, selected: projectType === i.value }))
