import React, { useCallback, useEffect, useState } from 'react';

import Divider from '@shared/components/Divider';

import { HabilidadesParaVidaGroupLayout as Layout } from '@modules/dashboards/components/HabilidadesParaVidaGroupLayout';
import WeekSelect, { IChangeWeek } from '@modules/dashboards/components/WeekSelect';
import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';

import { Container } from './styles';
import { formatHVGradeGroupData, IFormatedHVGroupGrades } from '../../utils/formatHVGradeGroup';
import GetClassHVGradesService from '../../services/GetClassHVGradesService';
import useAuth from '../../../../shared/store/auth/hook';
import { useParams } from 'react-router-dom';
import ButtonBackHome from '@shared/components/ButtonBackHome/ButtonBackHome';

const HabilidadesParaVidaGroupCurrent: React.FC = () => {
  const [axis, setAxis] = useState<IFormatedHVGroupGrades[]>([]);
  const [columns] = useState<IDynamicTableColumnsGroup[]>([
    {
      columns: [{ name: 'Eixos', dataname: 'axisname' }],
      fixed: true,
    },
    {
      columns: [
        { name: 'Nunca', dataname: 'never' },
        { name: 'Quase Nunca', dataname: 'occasionally' },
        { name: 'Quase Sempre', dataname: 'sometimes' },
        { name: 'Sempre', dataname: 'always' },
        { name: 'N/A', dataname: 'nothing' },
      ],
      fixed: false,
    },
  ]);

  const handleOnChangeWeek = useCallback(async (data: IChangeWeek) => {
    console.log('data', data);
  }, []);

  const hadleOnSelectRowData = useCallback((data: any) => {
    console.log(data);
    setAxis((oldState) => oldState.map((a) => ({ ...a, selected: a.id === data?.id ? !Boolean(a.selected) : a.selected })));
  }, []);

  const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
    const orderBy = sort.sortName as string;

    setAxis((oldState) => {
      let aux = [...oldState];
      aux.sort((a, b) => ((sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1));
      return aux;
    });
  }, []);

  const handleSubItems = useCallback((data: any) => {
    console.log(data);
    setAxis((oldState) => oldState.map((a) => ({ ...a, isOpen: a.id === data?.id ? !Boolean(a.isOpen) : a.isOpen })));
  }, []);

  const { signedUser } = useAuth();
  const { classId } = useParams();

  const load = useCallback(async () => {
    if (!signedUser) return;
    const data = await new GetClassHVGradesService(signedUser.token).execute(`${classId}`);
    if (!data) return;
    setAxis(formatHVGradeGroupData(data));
  }, [signedUser, classId]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Container>
      <ButtonBackHome positionTop="42px" positionRight="20px" />
      <Layout openTab={0}>
        <WeekSelect handleChangeWeek={handleOnChangeWeek} />

        <Divider className="divider" width="100%" />

        <DynamicTable
          columnsGroup={columns}
          data={axis}
          handleClickRow={hadleOnSelectRowData}
          handleClickSort={handleOnClickSort}
          handleSubItems={handleSubItems}
          canSelect
          containSubItems
        />
      </Layout>
    </Container>
  );
};

export default HabilidadesParaVidaGroupCurrent;
