import React from 'react';
import ReactDOM from 'react-dom';

import { createStore } from 'redux';

import { Provider } from 'react-redux';

import rootReducer from '@shared/store';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from '@shared/components/App';

import { ThemeProvider, BaseStyles } from 'react-alicerce-components';

import GlobalStyle from '@shared/theme/globalStyle';

import { DetectProvider } from '@shared/contexts/detect';

const store = createStore(rootReducer);

ReactDOM.render(
  <React.StrictMode>
    <DetectProvider>
      <Provider store={store}>
        <ThemeProvider>
          <BaseStyles />
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </Provider>
    </DetectProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

reportWebVitals();