import { Action } from "./actions";
import { IGlobalState, Types } from "./types";

const initialState: IGlobalState = {
  loading: false,
  initialized: false
}

 const reducer = (state: IGlobalState = initialState, action: Action) => {
    switch (action.type) {
      case Types.SET_GLOBAL_STATE:
        return {
          ...action.payload
        };
      case Types.SET_LOADING:
        return {
          ...state,
          loading: action.payload
        };
      case Types.SET_INITIALIZED:
        return {
          ...state,
          initialized: action.payload
        }; 
      default:
        return state;
    }
  };
  
  export default reducer;