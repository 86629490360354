import React from 'react';
import { Pill } from 'react-alicerce-components';

import { SubscriptionCardBorderContainer } from './styles';

export interface ISubscriptionCardBorder {
  data: string[];
  location: 'header' | 'footer';
}

const SubscriptionCardBorder: React.FC<ISubscriptionCardBorder> = (props) => {
  return (
    <SubscriptionCardBorderContainer location={props.location}>
      {props.data.map((d, index) => (
        <Pill key={index}>{d}</Pill>
      ))}
    </SubscriptionCardBorderContainer>
  );
};

export default SubscriptionCardBorder;
