import styled from 'styled-components';

export const BottomLabel = styled.div`
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #8F9BB3;
`;

export const Container = styled.div<{
  showStrokedPercent?: boolean,
  showPercentOnParts?: boolean,
  width?: string | number;
  height?: string | number;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({width}) => width ? (Number.isInteger(width) ? `${width}px` :  width) : '100%'};
  height: ${({height}) => height ? (Number.isInteger(height) ? `${height}px` :  height) : '100%'};

  
  span.donut-chart-content-center {
    position: absolute;
    font-size: 20px;
    font-weight: 800;
    color: #2E3A59;

    p {
      font-weight: 800;
      color: #2E3A59;
      font-size: 20px;
    }
  }

  .recharts-pie-sector {
    stroke-width: 0;
  }

  ${props => props.showStrokedPercent && `
    .recharts-pie-label-line {
      stroke: #2E3A59;
      font-family: var(--primary-font);
    }
  `}

  ${props => props.showPercentOnParts && `
   .recharts-label-list text {
      font-size: 12px;
      color: #fff;
      fill: #fff;
      stroke: #fff;
      font-family: var(--primary-font);
    }
  `}  
`;