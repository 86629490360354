import React from 'react';
import { useLocationStudent } from '@shared/components/LocationStudent/context/LocationStudentContext';
import Icon from 'react-eva-icons';

import { BoxContainer, Container, Header, Info, Name, Color, Description, ContainerList, TitleSkillName } from './styles';
import Modalv1 from '../../../Modalv1';
import { ApplicationResumeModalV1 } from '../../../ApplicationResumeModalV1';
import { Button } from 'react-alicerce-components';
import { IApplication } from '../../../../../modules/applications/interfaces/IApplication';

type BlockDescriptionProps = {
  showLastApplication?: boolean;
  application?: IApplication | null;
};

const BlockDescription: React.FC<BlockDescriptionProps> = ({ showLastApplication, application }) => {
  const { trailCurrent } = useLocationStudent();
  const isValidApplication = !application?.state?.user_defined_grade && !!application?.id;

  return (
    <Container>
      <Header>
        <h6>{trailCurrent?.meta_block?.name!}</h6>
      </Header>
      <ContainerList>
        {trailCurrent?.skill_descriptions?.map((skill, i) => (
          <BoxContainer key={`block-description-${i}`}>
            <Color status={skill?.status} />
            <Info>
              <TitleSkillName>
                <Name>
                  {skill?.name}
                  {skill?.is_essential && <Icon name="star" fill="#F2C94C" />}
                </Name>
              </TitleSkillName>
              <Description>{skill?.desc}</Description>
            </Info>
          </BoxContainer>
        ))}
        {showLastApplication && isValidApplication && (
          <Modalv1>
            <Modalv1.Open opens="MODAL">
              <Button
                size="small"
                transform="none"
                rounded
                noBorder
                fill="linear-gradient(90deg, #482B85 23.44%, #6749BA 100%)"
                start={<Icon name="search" fill="#2E3A59" />}
              >
                Ir para Aplicação
              </Button>
            </Modalv1.Open>
            <Modalv1.Window name="MODAL">
              <ApplicationResumeModalV1 applicationId={String(application?.id)} />
            </Modalv1.Window>
          </Modalv1>
        )}
      </ContainerList>
    </Container>
  );
};

export { BlockDescription };
