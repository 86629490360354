export const CONTRACT_BUSINESS_STATUS_NEW = "new"; // Novo -> Jurídico recebe informações do contrato do comercial
export const CONTRACT_BUSINESS_STATUS_DRAFT = "draft"; // Elaboração -> Quando todas as informações são preenchidas pelo comercial ou gestor durante renovação
export const CONTRACT_BUSINESS_STATUS_APPROVAL = "approval"; // Aprovação -> Parceiro valida contrato elaborado
export const CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE = "waiting_for_signature"; // Aguardando Assinatura -> Contrato enviado para parceiro assinar
export const CONTRACT_BUSINESS_STATUS_COMPLETED = "completed"; // Concluído -> Assinado
export const CONTRACT_BUSINESS_STATUS_LOST = "lost"; // Perdido -> Parceiro desiste de contrato (durante a elaboração/aprovação ou antes do término de contrato)

export const CONTRACT_BUSINESS_STATUS_NEW_OPTION = "Novo";
export const CONTRACT_BUSINESS_STATUS_DRAFT_OPTION = "Elaboração";
export const CONTRACT_BUSINESS_STATUS_APPROVAL_OPTION = "Aprovação";
export const CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE_OPTION = "Aguardando Assinatura";
export const CONTRACT_BUSINESS_STATUS_COMPLETED_OPTION = "Concluído";
export const CONTRACT_BUSINESS_STATUS_LOST_OPTION = "Perdido";

export const CONTRACT_BUSINESS_STATUS_CONSTRAINTS = [
  CONTRACT_BUSINESS_STATUS_NEW,
  CONTRACT_BUSINESS_STATUS_DRAFT,
  CONTRACT_BUSINESS_STATUS_APPROVAL,
  CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE,
  CONTRACT_BUSINESS_STATUS_COMPLETED,
  CONTRACT_BUSINESS_STATUS_LOST
] as const;

export const CONTRACT_BUSINESS_STATUS_OPTIONS_VALUES_CONSTRAINTS = [
  {
    value: CONTRACT_BUSINESS_STATUS_NEW,
    name: CONTRACT_BUSINESS_STATUS_NEW_OPTION
  },
  {
    value: CONTRACT_BUSINESS_STATUS_DRAFT,
    name: CONTRACT_BUSINESS_STATUS_DRAFT_OPTION
  },
  { value: CONTRACT_BUSINESS_STATUS_APPROVAL, name: CONTRACT_BUSINESS_STATUS_APPROVAL_OPTION },
  { value: CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE, name: CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE_OPTION },
  { value: CONTRACT_BUSINESS_STATUS_COMPLETED, name: CONTRACT_BUSINESS_STATUS_COMPLETED_OPTION },
  { value: CONTRACT_BUSINESS_STATUS_LOST, name: CONTRACT_BUSINESS_STATUS_LOST_OPTION }
] as const

export const CONTRACT_BUSINESS_STATUS__FILTER_OPTIONS_VALUES_CONSTRAINTS = [
  {
    name: CONTRACT_BUSINESS_STATUS_NEW,
    label: CONTRACT_BUSINESS_STATUS_NEW_OPTION
  },
  {
    name: CONTRACT_BUSINESS_STATUS_DRAFT,
    label: CONTRACT_BUSINESS_STATUS_DRAFT_OPTION
  },
  { name: CONTRACT_BUSINESS_STATUS_APPROVAL, label: CONTRACT_BUSINESS_STATUS_APPROVAL_OPTION },
  { name: CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE, label: CONTRACT_BUSINESS_STATUS_WAITING_FOR_SIGNATURE_OPTION },
  { name: CONTRACT_BUSINESS_STATUS_COMPLETED, label: CONTRACT_BUSINESS_STATUS_COMPLETED_OPTION },
  { name: CONTRACT_BUSINESS_STATUS_LOST, label: CONTRACT_BUSINESS_STATUS_LOST_OPTION }
]

export const contractStatusTranslator = (status?: string) => {
  const contractOption = CONTRACT_BUSINESS_STATUS_OPTIONS_VALUES_CONSTRAINTS.find(
    (contractOption) => {
      return contractOption.value === status
    }
  )
  if (contractOption) return contractOption.name
  if (status) return status
  return 'Valor não mapeado'
}

export const validContractStatusOptions = (status?: string | null) => {
  return CONTRACT_BUSINESS_STATUS_OPTIONS_VALUES_CONSTRAINTS.map((number) => ({
    name: number.name,
    value: number.value,
    selected: status === number.value,
  }));
};


export const PROJECT_STATUS_PAUSED_CONTRAINS = 'paused' //pausado
export const PROJECT_STATUS_AWAITING_RENEWAL_CONTRAINS = 'awaiting_renewal' //aguardando_renovacao
export const PROJECT_STATUS_COMPLETED_CONTRAINS = 'completed' // finalizado
export const PROJECT_STATUS_AWAITING_KICK_OFF_CONTRAINS = 'awaiting_kick_off' // aguardando_kick_off
export const PROJECT_STATUS_DEPLOYMENT_CONTRAINS = 'deployment' // implantacao
export const PROJECT_STATUS_EXECUTION_CONTRAINS = 'execution' // execucao

export const PROJECT_STATUS_PAUSED = { name: 'Pausado', value: PROJECT_STATUS_PAUSED_CONTRAINS };
export const PROJECT_STATUS_AWAITING_RENEWAL = { name: 'Aguardando Renovação', value: PROJECT_STATUS_AWAITING_RENEWAL_CONTRAINS };
export const PROJECT_STATUS_COMPLETED = { name: 'Finalizado', value: PROJECT_STATUS_COMPLETED_CONTRAINS };
export const PROJECT_STATUS_AWAITING_KICK_OFF = { name: 'Aguardando Kick Off', value: PROJECT_STATUS_AWAITING_KICK_OFF_CONTRAINS };
export const PROJECT_STATUS_DEPLOYMENT = { name: 'Implantação', value: PROJECT_STATUS_DEPLOYMENT_CONTRAINS };
export const PROJECT_STATUS_EXECUTION = { name: 'Execução', value: PROJECT_STATUS_EXECUTION_CONTRAINS };

export const PROJECT_STATUS_CONSTRAINTS = [
  PROJECT_STATUS_PAUSED_CONTRAINS,
  PROJECT_STATUS_AWAITING_RENEWAL_CONTRAINS,
  PROJECT_STATUS_COMPLETED_CONTRAINS,
  PROJECT_STATUS_AWAITING_KICK_OFF_CONTRAINS,
  PROJECT_STATUS_DEPLOYMENT_CONTRAINS,
  PROJECT_STATUS_EXECUTION_CONTRAINS
];

export const PROJECT_STATUS_CONSTRAINTS_OPTIONS = [PROJECT_STATUS_PAUSED,
  PROJECT_STATUS_AWAITING_RENEWAL,
  PROJECT_STATUS_COMPLETED,
  PROJECT_STATUS_AWAITING_KICK_OFF,
  PROJECT_STATUS_DEPLOYMENT,
  PROJECT_STATUS_EXECUTION
];
export const STATUS_PROJECT_VALUES = PROJECT_STATUS_CONSTRAINTS_OPTIONS.map((c) => c.value);

export const defaultProjectStatus = (projectStatus?: string | null) => PROJECT_STATUS_CONSTRAINTS_OPTIONS.map(i => ({ ...i, selected: projectStatus === i.value }))
export const translateProjectStatus = (status: string = '') => {
  let objMap = {}
  PROJECT_STATUS_CONSTRAINTS_OPTIONS.forEach(i => objMap = { ...objMap, [i.value]: i.name })
  return objMap[status] || status
}