import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

import { IGroupMarker } from '@shared/components/LocationStudent/interfaces/IGroupMarker';
import { IGradeInfo } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { IApplication } from '../../../../modules/applications/interfaces/IApplication';

export interface ILocationStudentProviderProps {
  trail: IGradeInfo;
  groupMarkers: IGroupMarker[];
  isOpen?: boolean;
  validationObservation?: string;
  validationJustification?: string;

  canSelect?: boolean;
  showBlockDescription?: boolean;
  showLegend?: boolean;
  handleSelectChange?: (obj: any) => void;
  showLabel?: boolean;
  showEditable?: boolean;
  getValuesToSave?: (e?: any) => void;
  getValuesToCancel?: (e: any) => void;
  resetGroupMarkers?: (e: any) => void;
  lastApplicationId?: string;
  blockIndex?: number;
  applicationIsFinalized?: IApplication | null;
  metaBlockValided?: boolean;
}

type ILocationStudentContext = {
  groupIdCurrent: string | null;
  trailCurrent: IGradeInfo | null;
  trail: IGradeInfo;
  groupMarkersCurrent: IGroupMarker[] | null;
  lockScroll: boolean;
  isCanSelect: boolean;
  showBlockDescription?: boolean;
  showLegend?: boolean;
  showEditable?: boolean;
  isShowLabel?: boolean;
  isEdit: boolean;
  currentLocationInGrid?: any;
  wrapperRef: React.RefObject<HTMLDivElement> | null;
  isMain: (gridIndex: number, valid?: boolean) => boolean;
  setGroupIdCurrent: (value: any) => void;
  setWrapperRef: (value: any) => void;
  setTrailCurrent: (value: any) => void;
  setLockScroll: (value: any) => void;
  setGroupMarkersCurrent: (value: any) => void;
  handleSelectChange?: (obj: any) => void;
  setIsShowLabel?: (value: boolean) => void;
  setIsEdit: (event: boolean) => void;
  getValuesToSave?: (e?: any) => void;
  resetGroupMarkers?: (e: any) => void;
  setCurrentLocationInGrid?: (e: any) => void;
  lastApplicationId?: string;
  blockIndex?: number;
  applicationIsFinalized?: IApplication | null;
  metaBlockValided?: boolean;
};

const LocationStudentContext = createContext<ILocationStudentContext>({} as ILocationStudentContext);

const LocationStudentProvider: React.FC<ILocationStudentProviderProps> = ({
  children,
  trail,
  groupMarkers,
  canSelect = false,
  handleSelectChange,
  getValuesToSave,
  showLabel = true,
  lastApplicationId,
  blockIndex,
  applicationIsFinalized,
  metaBlockValided,
}) => {
  const [currentLocationInGrid, setCurrentLocationInGrid] = useState<number | undefined>(undefined);
  const [groupMarkersCurrent, setGroupMarkersCurrent] = useState<IGroupMarker[]>(groupMarkers);
  const [wrapperRef, setWrapperRef] = useState<React.RefObject<HTMLDivElement> | null>(null);
  const [trailCurrent, setTrailCurrent] = useState<IGradeInfo | null>(null);
  const [groupIdCurrent, setGroupIdCurrent] = useState<string | null>(null);
  const [isCanSelect, setIsCanSelect] = useState<boolean>(false);
  const [isShowLabel, setIsShowLabel] = useState<boolean>(true);
  const [lockScroll, setLockScroll] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);

  useEffect(() => {
    if (!trailCurrent) setTrailCurrent(trail);
  }, [trail, trailCurrent]);

  useEffect(() => {
    setIsCanSelect(canSelect);
  }, [canSelect]);

  useEffect(() => {
    setIsShowLabel(showLabel);
  }, [showLabel]);

  const isMain = useCallback(
    (gridIndex: number, valid?: boolean) => {
      if (valid) return false;
      return !!(groupMarkers.filter((g) => g.isMain && g.gridIndex === gridIndex).length > 0);
    },
    [groupMarkers]
  );
  return (
    <LocationStudentContext.Provider
      value={{
        groupIdCurrent,
        trailCurrent,
        trail,
        groupMarkersCurrent,
        setGroupIdCurrent,
        setTrailCurrent,
        setGroupMarkersCurrent,
        setLockScroll,
        wrapperRef,
        setWrapperRef,
        lockScroll,
        isMain,
        isCanSelect,
        handleSelectChange,
        isShowLabel,
        setIsEdit,
        isEdit,
        getValuesToSave,
        currentLocationInGrid,
        setCurrentLocationInGrid,
        lastApplicationId,
        blockIndex,
        applicationIsFinalized,
        metaBlockValided,
      }}
    >
      {children}
    </LocationStudentContext.Provider>
  );
};

function useLocationStudent() {
  const context = useContext(LocationStudentContext);

  if (!context) {
    throw new Error('useLocationStudent must be used within a LocationStudentProvider');
  }

  return context;
}

export { LocationStudentProvider, useLocationStudent };
