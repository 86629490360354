import { ISelectOption } from 'react-alicerce-components';

export const STUDENT_CANCELLATION_DETAILS_SHIFT = {
  name: 'Turno',
  value: 'shift',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_NEW_JOB = {
  name: 'Novo Emprego',
  value: 'new_job',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_PERSONAL_PROBLEMS = {
  name: 'Problemas Pessoais',
  value: 'personal_problems',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_HEALTH = {
  name: 'Saúde',
  value: 'health',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_DISLIKED_PROJECT = {
  name: 'Não Gostou do Projeto',
  value: 'disliked_project',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_DISTANCE = {
  name: 'Distância',
  value: 'distance',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_SCHOOL_SHIFT_CHANGED = {
  name: 'Turno da Escola Mudou',
  value: 'school_shift_changed',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_OTHER_COURSE_OR_SPORT_ACTIVITY = {
  name: 'Entrou em Outro Curso ou Atividade Esportiva',
  value: 'other_course_or_sport_activity',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_MOVED_CITY = {
  name: 'Mudou de Cidade',
  value: 'moved_city',
  category: 'student_family_request',
};
export const STUDENT_CANCELLATION_DETAILS_IRREGULAR_ATTENDANCE_REQUEST_ALICERCE = {
  name: 'Infrequência',
  value: 'irregular_attendance',
  category: 'request_alicerce',
};

export const STUDENT_CANCELLATION_DETAILS_IRREGULAR_ATTENDANCE_STUDENT_FAMILY_REQUEST = {
  name: 'Infrequência',
  value: 'irregular_attendance',
  category: 'student_family_request',
};

export const STUDENT_CANCELLATION_DETAILS_DISCIPLINARY_COMMITTEE = {
  name: 'Comitê Disciplinar',
  value: 'disciplinary_committee',
  category: 'request_alicerce',
};
export const STUDENT_CANCELLATION_DETAILS_NEW_JOB_DETAIL = {
  name: 'Novo Emprego',
  value: 'new_job',
  category: 'partner_closing',
};

export const CANCELLATION_DETAILS_TYPES = [
  STUDENT_CANCELLATION_DETAILS_SHIFT,
  STUDENT_CANCELLATION_DETAILS_NEW_JOB,
  STUDENT_CANCELLATION_DETAILS_PERSONAL_PROBLEMS,
  STUDENT_CANCELLATION_DETAILS_HEALTH,
  STUDENT_CANCELLATION_DETAILS_DISLIKED_PROJECT,
  STUDENT_CANCELLATION_DETAILS_DISTANCE,
  STUDENT_CANCELLATION_DETAILS_SCHOOL_SHIFT_CHANGED,
  STUDENT_CANCELLATION_DETAILS_OTHER_COURSE_OR_SPORT_ACTIVITY,
  STUDENT_CANCELLATION_DETAILS_MOVED_CITY,
  STUDENT_CANCELLATION_DETAILS_IRREGULAR_ATTENDANCE_STUDENT_FAMILY_REQUEST,
  STUDENT_CANCELLATION_DETAILS_IRREGULAR_ATTENDANCE_REQUEST_ALICERCE,
  STUDENT_CANCELLATION_DETAILS_DISCIPLINARY_COMMITTEE,
  STUDENT_CANCELLATION_DETAILS_NEW_JOB_DETAIL,
];

export const defaultCancellationDetails = (
  studentCancellationReason?: string,
  studentCancellationDetail?: string | null
): ISelectOption[] | undefined => {
  return CANCELLATION_DETAILS_TYPES.filter((option) => option.category === studentCancellationReason).map((foo) => ({
    name: foo.name,
    value: foo.value,
    selected: studentCancellationDetail === foo.value,
  }));
};
