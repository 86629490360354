import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Spinner, Button, Checkbox, useToast } from 'react-alicerce-components';
import { ContainerCheck } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';

import { FavoriteToggleProgramsService } from '@modules/activities-library/services/FavoriteToggleProgramsService';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { formatProgramCurrent } from '@modules/activities-library/utils/formatProgramCurrent';
import DeleteProgramService from '@modules/activities-library/services/DeleteProgramService';
import { IFormatedProgram } from '@modules/activities-library/interfaces/IFormatedProgram';
import { EditProgramService } from '@modules/activities-library/services/EditProgramService';
import GetProgramService from '@modules/activities-library/services/GetProgramService';
import { ProgramForm } from '@modules/activities-library/components/ProgramForm';
import { programSchema } from '@modules/activities-library/utils/programSchema';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';

const ProgramEdit: React.FC = () => {
  const { isLoading, setIsLoading } = useGlobal();
  const { programCurrent, clearProgramContextData, setProgramCurrent } = useProgram();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const formatedBody = useRef<IFormatedProgram>();
  const { programId } = useParams<{ programId: string }>();
  const isMounted = useIsMounted();

  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  // issue 802 rermove a opção de enviar para o pedagogico
  const showPedagogicOption = false;

  const handleSendToPedagogic = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formatedBody.current) return;
    const isChecked = event.target.checked;
    const value = isChecked ? 'pending' : 'private';
    formatedBody.current.status = value;
    setProgramCurrent((oldState: IProgram) => {
      oldState.status = value;
      return { ...oldState };
    });
  };

  const canSaveProgram = useCallback(async () => {
    if (!programCurrent || !signedUser) return;
    const programBodyFormated = formatProgramCurrent(programCurrent);
    let programErrors = await beforeSubmitForm(programBodyFormated, programSchema);
    setErrors(programErrors);
    if (programErrors.length !== 0) return;
    formatedBody.current = programBodyFormated as IFormatedProgram;
    setShowModalEdit(true);
  }, [programCurrent, signedUser]);

  const editProgram = useCallback(async () => {
    if (!formatedBody || !programId || !formatedBody.current) return;
    setShowModalEdit(false);
    setIsLoading(true);

    const res = await new EditProgramService(signedUser?.token).execute(programId, formatedBody.current);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Erro ao Editar o Programa.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Programas',
      description: 'Programa Editado com Sucesso.',
    });

    navigate(-2);
    clearProgramContextData();
  }, [addToast, clearProgramContextData, formatedBody, navigate, programId, setIsLoading, signedUser]);

  const getProgram = useCallback(async () => {
    if (!programId) return;
    setIsLoading(true);
    const res = await new GetProgramService(signedUser?.token).execute(programId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Editar o Programa.',
      });
      return navigate(-2);
    }

    if (!isMounted()) return;
    setProgramCurrent(res);
  }, [addToast, isMounted, navigate, programId, setIsLoading, setProgramCurrent, signedUser]);

  const toggleFavoriteProgram = useCallback(async () => {
    setIsLoading(true);
    const res = await new FavoriteToggleProgramsService(signedUser?.token).execute(`${programCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Favoritar/Desfavoritar Programa.',
      });
      return;
    }
    if (!isMounted()) return;
    setProgramCurrent((oldState: IProgram) => {
      return { ...oldState, favorited: res.favorited };
    });
  }, [addToast, isMounted, programCurrent.id, setIsLoading, setProgramCurrent, signedUser]);

  const deleteProgram = useCallback(async () => {
    setShowModalDelete(false);
    setIsLoading(true);
    const res = await new DeleteProgramService(signedUser?.token).execute(`${programCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Deletar Programa.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Programas',
      description: 'Programa Deletado com Sucesso.',
    });
    navigate(`/banco-atividades`);
    return;
  }, [addToast, navigate, programCurrent.id, setIsLoading, signedUser]);

  useEffect(() => {
    if (!programCurrent.id) getProgram();
  }, [getProgram, programCurrent.id, programId]);

  return (
    <>
      <Fragment>
        {isLoading && <Spinner fixed />}
        <HeaderDirectory
          title="Banco de Atividades"
          menuKebab={{
            deleteCallback: () => setShowModalDelete(true),
            favorited: { favoritedCallback: () => toggleFavoriteProgram(), isFavorited: programCurrent.favorited || false },
          }}
          backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        />

        <ProgramForm
          title="EDITAR PROGRAMA"
          pathEnvolvedTags={`/banco-atividades/editar/programa/${programId}/selecionar/tags`}
          pathEnvolvedActivities={`/banco-atividades/editar/programa/${programId}/selecionar/atividade`}
          errors={errors}
        />

        <PageFooter>
          <ButtonGradientOutline textColor="#FFF" onClick={() => navigate(-1)} status="library_activity">
            Cancelar
          </ButtonGradientOutline>
          <Button className="button-program" onClick={canSaveProgram} fill="#96B6F7" transform="none" shaded>
            Salvar
          </Button>
        </PageFooter>
      </Fragment>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalDelete}
        closeCallback={() => setShowModalDelete(!showModalDelete)}
        title="Deseja Excluir Programa?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={deleteProgram}
        subTitle={'Você só pode excluir programas do seu banco pessoal. Ao fazer isso, você não exclui as atividades que pertencem a esse programa.'}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalEdit}
        closeCallback={() => setShowModalEdit(!showModalEdit)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={editProgram}
        subTitle={
          showPedagogicOption
            ? 'Ao continuar, você salvará o programa no seu banco pessoal. Caso queira também enviar para o pedagógico, selecione abaixo:'
            : undefined
        }
      >
        {showPedagogicOption && (
          <ContainerCheck>
            <Checkbox
              semiRound
              name="status"
              id="status"
              defaultChecked={programCurrent.status === 'private'}
              onChange={(event) => handleSendToPedagogic(event)}
            />
            <p>Enviar Atividade para Pedagógico</p>
          </ContainerCheck>
        )}
      </ConfirmModal>
    </>
  );
};

export { ProgramEdit };
