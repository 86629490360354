import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;

  .custom-card {
    width: 100%;
    margin: 0;

    > div {
      margin-bottom: 0;
      border: none;
    }
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7652b;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    h6 {
      color: #fff;
      font-size: 14px;
      font-weight: 800;

      > div {
        border-radius: 6px;
      }

      > i {
        height: 18px;
        transform: rotate(45deg);
      }
    }
  }
  .attach-icon {
    transform: rotate(45deg);
  }

  .card-body {
    padding: 20px;
    justify-content: start;
    background-color: #e9c4b6;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    p {
      font-size: 12px;
      font-weight: 600;
      color: #222b45;

      span {
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
`;

export const AttachmentCounter = styled.p`
    background: #FFC94D;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    color: #2E3A59;
    font-size: 12px;
    font-weight: 700;
`