import { css, styled } from 'react-alicerce-components';

export const ButtonFooterContainer = styled.div`
${() => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    border-top: 1px solid #c5cee0;
    padding: 18px 0px;

    button {
    border: 2px solid #8D593B;
    border-radius: 16px;
  }
`}
`