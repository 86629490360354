import { styled } from 'react-alicerce-components';
import { ClassCardEnrollmentBodyProps } from '.';

export const ClassCardEnrollmentBodyContainer = styled.div<ClassCardEnrollmentBodyProps>`
  background: #e9d7f9;
  min-height: 60px;
  padding: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #222b45;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    transform: ${(p) => (p.isTrailsOpen ? `rotate(90deg)` : `rotate(0deg)`)};
    transition: 0.3s;
    display: flex;
    cursor: pointer;
    svg {
      height: 25px;
      width: 25px;
    }
  }
`;
