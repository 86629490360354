import { Button, Input, formatDate, useToast } from 'react-alicerce-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import { Container, Label } from './styles';

import { useAttendances } from '@modules/attendances/context/AttendancesContext';
import { ListDailyClassesService } from '@modules/attendances/services/ListDailyClassesService';
import { ListAttendancesService } from '@modules/attendances/services/ListAttendancesService';
import { formatBodyAttendance } from '@modules/attendances/utils/formatBodyAttendance';
import UpdateAttendanceService from '@modules/attendances/services/UpdateAttendanceService';
import GetClassService from '@modules/classes/services/GetClassService';
import { ClassAttendees } from '@modules/attendances/components/ClassAttendees';
import IClass from '@modules/classes/interfaces/IClass';

import CustomForm from '@shared/components/CustomForm';
import Divider from '@shared/components/Divider';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import { IAttendance } from '@modules/attendances/interfaces/IAttendance';
import { TodayClassCard } from '@modules/attendances/components/TodayClassCard';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { DateHelper } from '@shared/helpers/DateHelper';
import PageFooter from '@shared/components/PageFooter';
import ConfirmModal from '@shared/components/ConfirmModal';
import { IGenericLocationState } from '@shared/interfaces/ILocationState';

const AttendanceForm: React.FC = () => {
  const navigate = useNavigate();
  const currentClassInPath = useParams<{ id: string }>();
  const location = useLocation();
  const state = location.state as IGenericLocationState<IClass>;

  const { attendanceCurrent, setAttendanceCurrent } = useAttendances();
  const { setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const [showAttendanceModal, setShowAttendanceModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const [attendances, setAttendances] = useState<IAttendance[]>();
  const [nameClass, setNameClass] = useState<string>('Nome Turma');
  const [selectedDailyClass, setSelectedDailyClass] = useState<IDailyClass>();

  const handleClickOnDailyClass = useCallback(
    async (todayClass: IDailyClass) => {
      setIsLoading(true);
      const res = await new ListAttendancesService(signedUser?.token, newCancelToken()).execute(undefined, {
        size: 9999,
        daily_class_id: todayClass.id,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return console.log(`>>> ERROR NA CONSULTA`);
      setSelectedDailyClass(todayClass);
      setAttendances(res.items);
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  const getClassName = useCallback(async () => {
    if (!signedUser || !currentClassInPath.id) return;
    setIsLoading(true);
    const res = await new GetClassService(signedUser.token).execute(currentClassInPath.id);
    if (res) {
      setNameClass(res.name);
      setIsLoading(false);
      return;
    }
    setNameClass('Nome Turma');
  }, [currentClassInPath.id, setIsLoading, signedUser]);

  useEffect(() => {
    if (!state || !state.genericStateData) {
      getClassName();
      return;
    }
    setNameClass(state.genericStateData?.name as string);
  }, [getClassName, state]);

  const listDailyClasses = useCallback(async () => {
    setIsLoading(true);
    const today = DateHelper.extractDate();
    const res = await new ListDailyClassesService(signedUser?.token, newCancelToken()).execute(undefined, {
      size: 9999,
      class_id: currentClassInPath.id,
      date: today,
    });
    if (res === 'canceling') return;
    setIsLoading(false);
    if (!res) return console.log(`>>> ERROR NA CONSULTA`);
    setAttendanceCurrent((oldState: any) => {
      return { ...oldState, today_classes: res.items };
    });
  }, [currentClassInPath.id, newCancelToken, setAttendanceCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    listDailyClasses();
  }, [listDailyClasses]);

  const handleAttendenceGiven = useCallback(
    (id: number, checked: boolean) => {
      if (!attendances) return;
      const found = attendances.findIndex((at) => Number(at.id) === Number(id));
      setAttendances((oldState: any) => {
        oldState[found] = { ...oldState[found], presence: checked };
        return [...oldState];
      });
    },
    [attendances]
  );

  const handleAttendenceGivenSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, changed: 'student' | 'lms_user') => {
      if (!attendances) return;
      const checked = event.target.checked;
      setAttendances((oldState: any) => {
        if (changed === 'lms_user') {
          return oldState.map((att: IAttendance) => {
            if (att.student_id) return att;
            return { ...att, presence: checked };
          });
        } else {
          return oldState.map((att: IAttendance) => {
            if (att.lms_user_id) return att;
            return { ...att, presence: checked };
          });
        }
      });
    },
    [attendances]
  );

  const attendanceRetroactive = useCallback(
    (event) => {
      event.preventDefault();
      navigate(`/presencas/${currentClassInPath.id}/criar/retroativa`);
    },
    [currentClassInPath, navigate]
  );

  const handleUpdateAttendance = useCallback(async () => {
    if (!signedUser || !attendances) return;
    setShowAttendanceModal(false);
    const formatedBody = formatBodyAttendance(attendances);

    setIsLoading(true);
    const res = await new UpdateAttendanceService(signedUser.token).execute(formatedBody);
    setIsLoading(false);

    if (!res) {
      setIsLoading(false);
      return addToast({
        status: 'danger',
        title: 'Presenças',
        description: 'Falha ao tentar fazer chamada. Tente novamente.',
      });
    }

    if (!!res?.errors?.length) {
      addToast({
        status: 'warning',
        title: 'Presenças',
        description: `Presença lançada, porém ocorreu um erro com a presença de ${res.errors.length} alunos.`,
      });
    } else {
      addToast({
        status: 'success',
        title: 'Presenças',
        description: 'Presença lançada com sucesso.',
      });
    }
    setAttendances(undefined);
    listDailyClasses();
  }, [signedUser, attendances, setIsLoading, listDailyClasses, addToast]);

  const isDailyClassAvailableToGiveAttendance = (dailyClass: IDailyClass) => {
    const dailyClassStart = new Date(`${dailyClass.start_date}T${dailyClass.start_time}`);
    if (new Date() < dailyClassStart) return true;
    return false;
  };

  return (
    <Container>
      <CustomForm>
        <div className="title-name-classes">{nameClass}</div>

        <Label>DATA DA AULA</Label>
        <div className="container-retroactive-button">
          <Input
            style={{ width: '30%', margin: 0 }}
            name="date"
            useTooltipError={false}
            placeholder="Data da aula"
            disabled
            defaultValue={formatDate(new Date(), `long`)}
          />
          <Button onClick={attendanceRetroactive} transform="none" statusNumber={500} status="quaternary" color="#fff" shaded>
            Presença <br />
            Retroativa
          </Button>
        </div>

        <Divider width="100%" />

        <Label>AULAS DO DIA</Label>
        <div className="container-daily-class">
          {attendanceCurrent?.today_classes &&
            attendanceCurrent?.today_classes.map((dailyClass, i) => (
              <TodayClassCard
                todayClass={dailyClass}
                todayClassesSelected={selectedDailyClass}
                canSelect
                showAttendanceStatus
                key={i}
                handleOnClick={() => handleClickOnDailyClass(dailyClass)}
                disabled={isDailyClassAvailableToGiveAttendance(dailyClass)}
              />
            ))}
        </div>

        {attendances && (
          <ClassAttendees
            attendances={attendances.map((a) => ({ ...a, id: Number(a.id) }))}
            handleOnClickOnSelectAllStudents={(event) => handleAttendenceGivenSelectAll(event, 'student')}
            handleOnClickOnSelectAllLmsUsers={(event) => handleAttendenceGivenSelectAll(event, 'lms_user')}
            handleOnToggleAttendance={(attendanceId, presence) => handleAttendenceGiven(attendanceId, presence)}
          />
        )}
      </CustomForm>

      <PageFooter height={'fit-content'}>
        <Button
          start={<Icon name="checkmark" />}
          onClick={() => setShowAttendanceModal(true)}
          style={{ transform: 'none', borderRadius: '16px' }}
          status={'success'}
          statusNumber={500}
          color="#fff"
          disabled={!attendances}
        >
          Salvar Presença
        </Button>

        {selectedDailyClass && (
          <Button
            start={<Icon name="edit-2" />}
            onClick={() => navigate(`/planejamento-aula/turma/${currentClassInPath.id}/editar/aula/${selectedDailyClass.id}`)}
            style={{ transform: 'none', borderRadius: '16px' }}
            fill="linear-gradient(to top right, #482B85 0.24%, #6749BA 99.76%)"
            color="#fff"
            noBorder
          >
            Editar Aula
          </Button>
        )}

        <Button
          className="button-class-planner"
          transform="none"
          fill="#85ABF8"
          shaded
          onClick={() => navigate(`/planejamento-aula/turma/${currentClassInPath.id}/criar/aula`)}
        >
          Criar Aula Extra
        </Button>
      </PageFooter>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showAttendanceModal}
        closeCallback={() => setShowAttendanceModal(false)}
        title="Deseja Confirmar a Presença?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleUpdateAttendance}
      />
    </Container>
  );
};

export default AttendanceForm;
