import { styled } from 'react-alicerce-components';

export const ActivityLibraryEditContainer = styled.div`

  .container-text-modal {
    .span-text-modal {
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #222b45;
      padding: 3px 15px;
    }

    > div {
      margin: 4px auto;
      width: max-content;

      label span {
        margin-left: 10px;
      }
    }
  }
`;
