import React from "react";
import piquito404 from "./piquito404.png";

import { Wrapper, Image, Text } from "./styles";

interface IError404Props {
  topMessage?: string;
  bottomMessage?: string;
}

const Error404: React.FC<IError404Props> = ({
  topMessage = "Oops...",
  bottomMessage = "Página não encontrada..."
}) => {
  return (
    <Wrapper>
      <Text>{topMessage}</Text>
      <Image src={piquito404} alt="Erro 404" />
      <Text>{bottomMessage}</Text>
    </Wrapper>
  );
};

export default Error404;
