import { styled } from 'react-alicerce-components';

export const WrapperContent = styled.div`
  .custom-card > div {
    margin: var(--card-distance);
  }

  .input-container {
    margin-bottom: 45px;
    div {
      border-color: #c5cee0 !important;
    }
  }

  .container-all-places {
    > div {
      cursor: pointer;
    }
  }
`;
