import { styled } from 'react-alicerce-components';

import {
    SpaceProps,
    space,
} from 'styled-system';

type IContainerProps = SpaceProps & {
    bgColor: string
    borderRadius: boolean;
}
export const Container = styled.div<IContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ bgColor }) => bgColor};
    color: #fff;
    height: 110px;
    width: 100%;
    border-radius: ${({ borderRadius }) => borderRadius ? '5px' : 'none'};
    padding: 1rem 2rem;
    position: relative;
    ${space}

    .arrow-back{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    svg {
        height: 30px;
        width: 30px;
        cursor: pointer;
    }
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    height: 100%;
`;


export const Img = styled.div<{src: string}>`
    background: url(${({ src }) => src }); 
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
    height: 100%;
`;

export const Title = styled.div`
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: .8rem;
    color: #fff;
`;

export const TrailName = styled.div<{color: string}>`
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: 1rem;
    color:  ${({ color }) => color};
    text-transform: uppercase;
`;