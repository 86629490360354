import * as Yup from 'yup';
import { STEERING_TYPE_CONSTRAINTS } from '../partials/SteeringType';
import { STEERING_THERMOMETER_CONSTRAINTS } from '../partials/SteeringThermometer';
import { STEERING_STATUS_CONSTRAINTS } from '../partials/SteeringStatus';
import { STEERING_CHANNEL_CONSTRAINTS } from '../partials/SteeringChannel';

export const CreatingInfoSteeringSchema = Yup.object().shape({
  meeting_date: Yup.date().required({ name: 'meeting_date', message: 'O campo é obrigatório' }),
  type: Yup.string()
    .oneOf(STEERING_TYPE_CONSTRAINTS, { name: 'type', message: `Campo deve ser ${STEERING_TYPE_CONSTRAINTS} ` })
    .required({ name: 'type', message: 'O campo é obrigatório' }),
  status: Yup.string()
    .default('scheduled')
    .oneOf(STEERING_STATUS_CONSTRAINTS, { name: 'status', message: `Campo deve ser ${STEERING_STATUS_CONSTRAINTS} ` })
    .required({ name: 'status', message: 'O campo é obrigatório' }),
  meeting_channel: Yup.string()
    .oneOf(STEERING_CHANNEL_CONSTRAINTS, { name: 'meeting_channel', message: `Campo deve ser ${STEERING_CHANNEL_CONSTRAINTS} ` })
    .required({ name: 'meeting_channel', message: 'O campo é obrigatório' }),
  meeting_link: Yup.string().when('meeting_channel', {
    is: (val) => val !== 'in_person',
    then: Yup.string().url('Digite uma URL válida').required({ name: 'meeting_link', message: 'O campo é obrigatório' }),
    otherwise: Yup.string().url('Digite uma URL válida').optional(),
  }),
  meeting_next_date: Yup.date()
    .optional()
    .nullable()
    .test('is-future', function (value, context) {
      const { meeting_date } = context.parent;
      if (value && meeting_date && value <= meeting_date) {
        return context.createError({
          message: { name: 'meeting_next_date', message: 'Campo deve ser uma data futura.' },
        });
      }
      return true;
    }),
  external_participants: Yup.object({
    participants: Yup.array()
      .of(Yup.string().required('Cada participante é obrigatório'))
      .min(1, 'Pelo menos um participante é necessário')
      .required({ name: 'external_participants', message: 'O campo participante é obrigatório' }),
  }).required('O campo é obrigatório'),
  thermometer: Yup.string()
    .oneOf(STEERING_THERMOMETER_CONSTRAINTS, { name: 'thermometer', message: `Campo deve ser ${STEERING_THERMOMETER_CONSTRAINTS} ` })
    .optional(),
  lms_participants_ids: Yup.array()
    .of(Yup.number())
    .min(1, 'Pelo menos um participante é necessário')
    .required({ name: 'lms_participants_ids', message: 'O campo é obrigatório' }),
  project_id: Yup.number().required({ name: 'project_id', message: 'O campo é obrigatório' }),
});

export const PointsOfMeetingSchema = Yup.object().shape({
  points_of_meeting: Yup.string().optional().nullable(),
});

export const ActionPlanOfMeetingSchema = Yup.object().shape({
  action_plan_of_meeting: Yup.string().optional().nullable(),
});

export const CreateSteeringSchema = CreatingInfoSteeringSchema.concat(PointsOfMeetingSchema).concat(ActionPlanOfMeetingSchema);