import React from 'react';
import { Outlet } from 'react-router-dom';

export function ProviderRender({ provider: Provider }) {
  return (
    <div>
      <Provider>
        <Outlet />
      </Provider>
    </div>
  );
}
