import { styled } from 'react-alicerce-components';

export const BlockCardContainer = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

  .container-description-block {
    background: #96b6f7;
    border-radius: 5px 5px 0px 0px;
    padding: 7px 15px;
  }

  .container-footer-block {
    background: #dae3f6;
    border-radius: 0px 0px 5px 5px;
    min-height: 50px;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 0 0.5rem;
  }

  .applied-filter {
    background: #c8fbf2;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 12px;
    color: #222b45;
    font-family: var(--primary-font);
  }
`;
