import React, { Fragment, useState } from 'react';
import { FiCopy, FiHeart, FiTrash2 } from 'react-icons/fi';

import { MenuKebabContainer, MoreOptionsStyles } from './styles';
import Icon from 'react-eva-icons';
import ButtonBackHome from '../ButtonBackHome/ButtonBackHome';
import TooltipModal from '../TooltipModal';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';

export type IMenuKebab = {
  deleteCallback?: (() => void) | undefined;
  copyCallback?: (() => void) | undefined;
  menuBackHome?: boolean;
  tooltip?: { view: string };
  attached?: { isAttached: boolean; attachedCallback: () => void };
  favorited?: { isFavorited: boolean; favoritedCallback: () => void };
};

const MenuKebab: React.FC<IMenuKebab> = (props) => {
  const { favorited, attached, deleteCallback, copyCallback, menuBackHome, tooltip } = props;

  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  return (
    <MenuKebabContainer className="menu-kebab">
      {menuBackHome && <ButtonBackHome isAbsolute={false} />}
      {tooltip && <TooltipModal isAbsolute={false} view={tooltip.view} />}
      {(favorited || deleteCallback || copyCallback || attached) && (
        <MoreOptionsStyles>
          <div onClick={() => setIsDropDownOpen((oldState) => !oldState)}>
            <Icon name="more-vertical" fill="#FFF" />
          </div>
          {isDropDownOpen && (
            <Fragment>
              <div className="dropdown-more-options">
                {attached && (
                  <div className="option" onClick={() => attached.attachedCallback()}>
                    {attached.isAttached ? <BsPinAngleFill /> : <BsPinAngle />}
                    <span>{attached.isAttached ? 'Desfixar' : 'Fixar'}</span>
                  </div>
                )}

                {favorited && (
                  <div className="option" onClick={() => favorited.favoritedCallback()}>
                    <FiHeart fill={`${favorited.isFavorited ? '#2E3A59' : '#FFFFFF'}`} />
                    <span>{favorited.isFavorited ? 'Desfavoritar' : 'Favoritar'}</span>
                  </div>
                )}

                {copyCallback && (
                  <div className="option" onClick={() => copyCallback()}>
                    <FiCopy fill="#FFFFFF" />
                    <span>Copiar</span>
                  </div>
                )}

                {deleteCallback && (
                  <div className="option" onClick={() => deleteCallback()}>
                    <FiTrash2 />
                    <span>Excluir</span>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </MoreOptionsStyles>
      )}
    </MenuKebabContainer>
  );
};

export { MenuKebab };
