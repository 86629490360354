import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';

export interface UnsubscribeScheduleDTO {
  schedule_ids: number[],
  class_id?: string;
  group_id?: string;
  place_id?: string;
}

class UnsubscribeScheduleService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(data: UnsubscribeScheduleDTO): Promise<boolean> {
    try {
      await this.api.post('/schedule/external/unsubscribe', data);
      return true;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default UnsubscribeScheduleService;
