import React, { useMemo } from 'react';
import { IconBaseProps } from 'react-icons';
import { uuid } from 'uuidv4';

import { CheckboxForm, formatDate, ICheckboxProps, P } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';
import Tag from '@modules/tags/components/Tag';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';

import { Container, Header, Date, ListContainer } from './styles';

type IScheduleEventCardProps = ICustomCardProps & {
  schedule?: ISchedule;
  id?: string | number;
  hideIconUncheck?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  checkbox?: ICheckboxProps;
  canSelect?: boolean;
  statusNumber?: number;
};

const ScheduleEventCard: React.FC<IScheduleEventCardProps> = ({
  schedule,
  id,
  icon,
  hideIconUncheck,
  checkbox,
  status = 'basic',
  canSelect = false,
  statusNumber = 600,
  ...rest
}) => {
  const containFooter = useMemo(() => !!(schedule?.class_id || schedule?.group_id || schedule?.place_id), [schedule]);

  return (
    <Container status={status} canSelect={canSelect} containFooter={containFooter} statusNumber={statusNumber}>
      <CustomCard
        status={status}
        statusFooter={status}
        header={
          <Header status={status} statusNumber={statusNumber}>
            <Date>Criada em: {formatDate(schedule?.created_at as Date, 'numeric')}</Date>
            <div className="schedule-name">{schedule?.name}</div>
          </Header>
        }
        footer={
          <ListContainer>
            {schedule?.group_id && schedule.group && (
              <Tag key={uuid()} tag={{ name: `Grupo: ${schedule.group.name}` }} backgroundColor="#FFC94D" color="#222B45" size="small" />
            )}

            {schedule?.class_id && schedule.class && (
              <Tag key={uuid()} tag={{ name: `Turma: ${schedule.class.name}` }} backgroundColor="#FFC94D" color="#222B45" size="small" />
            )}

            {schedule?.place_id && schedule.place && (
              <Tag key={uuid()} tag={{ name: `Local: ${schedule.place.name}` }} backgroundColor="#FFC94D" color="#222B45" size="small" />
            )}
          </ListContainer>
        }
        {...rest}
      >
        <P fontSize={14}>{schedule?.description}</P>
      </CustomCard>
      {canSelect && (
        <div className="checkBoxExternal">
          <CheckboxForm {...checkbox} id={id} icon={icon} hideIconUncheck={hideIconUncheck} />
        </div>
      )}
    </Container>
  );
};

export default ScheduleEventCard;
