import { IFormatedGroup } from '@modules/groups/interfaces/IFormatedGroup';
import { IGroup } from '../interfaces/IGroup';

export const formatGroup = (group: IGroup): IFormatedGroup => {
  return {
    name: group.name,
    category: group.category,
    status: group.status,
    parent_id: group.parent?.id,
    tag_ids: group.tags ? group.tags?.map((tag) => (tag.id ? tag.id : '')).filter(Boolean) : [],
    lms_user_ids: group.lms_users ? group.lms_users.map((user) => (user.id ? `${user.id}` : '')).filter(Boolean) : [],
    place_ids: group.places ? group.places.map((place) => (place.id ? `${place.id}` : '')).filter(Boolean) : [],
  };
};

/*
  var arr = [ 0, 1, '', undefined, false, 2, undefined, null, , 3, NaN ];
  var filtered = arr.filter(Boolean);
  Output: [ 1, 2, 3 ]
*/
