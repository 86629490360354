import { IDetectContextState } from './DetectContext';

export enum Types {
  SET_STATE = "SET_STATE",
  SET_IS_ONLINE = "SET_IS_ONLINE",
}

type Action =
| { type: Types.SET_STATE; payload: IDetectContextState }
| { type: Types.SET_IS_ONLINE; payload: boolean };

const reducer = (state: IDetectContextState, action: Action) => {
  switch (action.type) {
    case Types.SET_STATE:
      return {
        ...action.payload
      };
      
      case Types.SET_IS_ONLINE:
        return {
          ...state,
          isOnline: action.payload
        };
        
    default:
      return state;
  }
};

export default reducer;
