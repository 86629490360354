import React from 'react';

import { Container, Row, Item, LabelHeader } from './styles';

interface ConsolidatedHoursCategories {
    category: string;
    approved?: string | number
    pending?: string | number
    disapproved?: string | number
}

interface ConsolidatedHoursDetailsProps {
    categories: ConsolidatedHoursCategories[]
}

const headers = [
    { label: 'Categorias de Horas', bgColor: '#8F9BB3' },
    { label: 'Aprovadas', bgColor: '#00D68F' },
    { label: 'Pendentes', bgColor: '#FFAA00' },
    { label: 'Reprovadas', bgColor: '#FF3D71' },
]
const ConsolidatedHoursDetails: React.FC<ConsolidatedHoursDetailsProps> = ({
    categories
}) => {

    return (
        <Container>
            <LabelHeader>Status das Horas</LabelHeader>
            <Row>{headers.map((i, k) => <Item key={k} bgColor={i.bgColor}>{i.label}</Item>)}</Row>
            {categories.map((i, k) =>
                <Row key={k}>
                    <Item bgColor='#E4E9F2' color='#2E3A59'>{i.category}</Item>
                    <Item>{i.approved}</Item>
                    <Item>{i.pending}</Item>
                    <Item>{i.disapproved}</Item>
                </Row>
            )}
        </Container>
    );
}

export default ConsolidatedHoursDetails;