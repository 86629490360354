import React, { useState, useCallback } from 'react';
import Layout from '@modules/occurrences/components/LayoutOccurrencesContextSelection';
import { Input } from 'react-alicerce-components';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';
import GroupsSelection from '@shared/components/GroupsSelection';

const OccurrencesGroupsSelection: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const { setOccurrenceCurrent, setContextSelected } = useOccurrences();

  const filterGroups = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  const handleOnClick = useCallback(
    (groupClicked: IGroup) => {
      setOccurrenceCurrent({ group: groupClicked });
      setContextSelected({ context: 'grupos', context_id: groupClicked.id });
      navigate(`/ocorrencias/grupos/${groupClicked.id}/pendentes`);
    },
    [navigate, setContextSelected, setOccurrenceCurrent]
  );

  return (
    <Layout openTab={1}>
      <div style={{ marginBottom: '2rem' }}>
        <Input
          placeholder="Buscar"
          name="search"
          end={<Icon name="funnel" fill="#8F9BB3" />}
          onChange={(event) => filterGroups(event.target.value)}
        />
      </div>

      <GroupsSelection handleOnClick={handleOnClick} searchValue={searchValue} />
    </Layout>
  );
};

export default OccurrencesGroupsSelection;
