import React from 'react';
import { ILegend } from '../index';

import { Container, Item } from './styles';

export interface DotProps {
    items: ILegend[];
}

const Dot: React.FC<DotProps> = ({ items }) => {
    return (
        <Container className='legend-dot-container'>
            {items.map((item, i) => <Item key={i} color={item.color} >{item.name}</Item>)}
        </Container>
    )
}

export default Dot;