import { styled } from 'react-alicerce-components';

export const ButtonAddCardContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 30px;

  > div:first-child {
    cursor: pointer;
  }

  i {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c5cee0;
    border-radius: 8px;

    svg {
      width: 35px;
      height: 35px;
      fill: #fff;
    }
  }

  .text-add-button {
    font-family: var(--secondary-font);
    font-weight: bold;
    font-size: 15px;
    display: flex;
    color: #2e3a59;
  }
`;
