interface FormatDefaultDateParams {
  date?: Date | string | null
  typeMonth?: 'numeric' | 'long'
  readOnly?: boolean
}

export const formatDefaultDate = ({
  date,
  typeMonth = 'numeric',
  readOnly = true
}: FormatDefaultDateParams): string | undefined => {
  const d = handleDateArg(date)
  if (!d || isNaN(d.valueOf())) return undefined

  return readOnly
    ? formatDate(d, typeMonth)
    : formatDate(d, 'numeric')?.split('/').reverse().join('-')
}

export const formatDate = (
  date?: Date | string | null,
  typeMonth?: 'numeric' | 'long'
) => {
  date = handleDateArg(date)
  if (!date) return undefined

  return new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: typeMonth,
    day: 'numeric'
  }).format(date)
}

function handleDateArg(date: FormatDefaultDateParams['date']): Date | null {
  if (date instanceof Date) return date

  if (typeof date === 'string') {
    if (date.length === 0) return null

    const splittedDate = date.split('T')

    if (splittedDate[1]) return new Date(date)
    return new Date(splittedDate[0] + ' ')
  }

  return null
}

export const validateDateRange = (
  initialDate?: string,
  finalDate?: string
): boolean => {
  if (!initialDate || !finalDate) return false
  const initalDateFormated = new Date(initialDate)
  const finalDateFormated = new Date(finalDate)
  return finalDateFormated < initalDateFormated
}
