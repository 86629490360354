import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IPortfolioActivity } from '../interfaces/IPortfolioActivity';

class GetPortfolioActivitiesService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(portfolioActivityId: string): Promise<IPortfolioActivity | false | 'canceling'> {
    try {
      const res = await this.api.get(`/portfolio/${portfolioActivityId}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export { GetPortfolioActivitiesService };
