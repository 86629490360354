import { formatDateUtcToTimeZone } from "../../../shared/utils/formatDateUtcToTimeZone";

export const formateDate = (date: string) => {
  return date.slice(0, 10).split('-').reverse().join('/');
};

export const applicationDate = (date) => {
  if (!!date) {
    if (!!date.created_at && date.created_at === date.updated_at)
      return `Enviado em:  ${formatDateUtcToTimeZone({ date: date.created_at })}`;
    if (!!date.updated_at && date.status === 'FINALIZED')
      return `Última Aplicação em: ${formatDateUtcToTimeZone({ date: date.updated_at })}`;
    if (!!date.updated_at) return `Iniciado em: ${formatDateUtcToTimeZone({ date: date.updated_at })}`;
  }
};
