import React, { Fragment, useCallback, useState } from 'react';
import { Input, Spinner } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomForm from '@shared/components/CustomForm';

import IClass from '@modules/classes/interfaces/IClass';

export const SprintClassSelection: React.FC = () => {
  const { isLoading } = useGlobal();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>('');

  const handleClickOnClass = useCallback(
    (classSelected: IClass) => {
      navigate(`/planejador-sprint/${classSelected.id}`);
    },
    [navigate]
  );

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title={'Seleção de Contexto'}
        backgroundHeader=" #8F9BB3"
        pathToReturn="/"
        menuKebab={{
          menuBackHome: true,
        }}
      />
      <DirectoryContainer footer={false}>
        <CustomForm>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => setSearchInput(event.target.value)}
          />

          <ClassesSelection handleOnClick={(classSelected) => handleClickOnClass(classSelected)} searchValue={searchInput} />
        </CustomForm>
      </DirectoryContainer>
    </Fragment>
  );
};
