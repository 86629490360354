import { styled } from 'react-alicerce-components';

export const Container = styled.div`
    .divider {
        margin: 0;
    }

    .pagination-container {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const Content = styled.div`
    margin-top: 40px;
    height: calc(100vh - 130px - 80px - 40px - 60px); // screen height - header height - footer height - margin top - week selection
`;
