import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #edf1f7;
  padding: 1rem;
`;

export const Header = styled.div<{ isCanSelect?: boolean }>`
  background-color: #a35ec0;
  padding: 0.6rem;
  border-radius: 5px;
  z-index: 3;
  margin-bottom: 10px;

  h6 {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }
`;

export const ContainerList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
  align-items: flex-start;

  button {
    min-width: 140px;
    padding: 1rem 0;
    margin: auto;
    margin-top: 1rem;
    border-radius: 16px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 700;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  + div {
    margin-top: 1.2rem;
  }
`;

export const Color = styled.div<{ status?: ElementStatus; borderStatus?: ElementStatus }>`
  margin: 4px;
  width: 16px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid ${({ status, borderStatus }) => (status === 'basic' ? '#8F9BB3' : themeGet(`colors.${borderStatus || status}500`))};
  background: ${({ status }) => themeGet(`colors.${status}500`)};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 4px;
  justify-content: space-evenly;
`;

export const TitleSkillName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 800;
  color: #222b45;
  display: flex;
  gap: 5px;

  i {
    display: flex;
    align-items: center;
    svg {
      stroke-width: 0.5px;
      stroke: #f7652b;
      width: 14px;
      height: 14px;
    }
  }
`;

export const Description = styled.div`
  font-size: 12px;
  color: #222b45;
  text-align: justify;
`;
