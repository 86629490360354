import React, { Fragment, useCallback, useEffect } from 'react';
import { Spinner, Button } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { TagInfoForm } from '@modules/tags/components/TagInfoForm';
import { useTags } from '@modules/tags/context/TagsContext';
import GetTagService from '@modules/tags/services/GetTagService';

const TagInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading } = useGlobal();
  const { signedUser } = useAuth();
  const navigte = useNavigate();
  const { setIsLoading } = useGlobal();
  const { tagCurrent, setTagCurrent } = useTags();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const getTag = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);
    const res = await new GetTagService(signedUser?.token, newCancelToken()).execute(id);
    setIsLoading(false);
    if (!isMounted() || !res) return;
    setTagCurrent(res);
  }, [id, isMounted, newCancelToken, setIsLoading, setTagCurrent, signedUser]);

  useEffect(() => {
    if (!tagCurrent || !tagCurrent.id) getTag();
  }, [getTag, tagCurrent]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory
        title="Diretório de Tags"
        pathToReturn="/diretorio/tags"
        status="secondary"
        statusNumber={800}
        backgroundHeader="linear-gradient(45deg, #282674 0%, #145496 100%)"
        menuKebab={{ menuBackHome: true }}
      />

      <TagInfoForm />
      <PageFooter>
        <Button onClick={() => navigte(`/diretorio/tags/${id}/editar`)} transform="none" status="secondary" statusNumber={800} shaded>
          Editar
        </Button>
      </PageFooter>
    </Fragment>
  );
};

export { TagInfo };
