import { styled } from 'react-alicerce-components';

interface IColorProps {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > img {
    height: max(10vw, 4rem);
    max-height: 10vh;
    border-radius: 1rem;
    border: none;
    aspect-ratio: 1 / 1;
    transition: all 0.2s ease-in-out;

    @media (max-width: 320px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const DeleteIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 26px;
  height: 26px;
  cursor: pointer;

  border-radius: 50%;
  background: linear-gradient(180deg, #f42929 0%, #f9514c 100%);

  @media (min-width: 570px) {
    top: -7px;
  }

  i {
    display: flex;
    line-height: 1;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const IconContainer = styled.div<IColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  background-color: ${({ color }) => (color ? color : '#ffba95')};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  i {
    display: flex;
    svg {
      width: 32px;
      height: 32px;
      fill: #ffe7d9;
    }
  }
`;

export const InfoContainer = styled.div`
  height: 100%;
  width: 70%;
  padding: 6px;
  background-color: ${({ color }) => (color ? color : '#ffe7d9')};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;

  .container-text-ellipsis {
    line-height: normal;
    word-break: break-word;
  }
`;

export const Name = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #2e3a59;
`;
