import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IApplication } from '../../applications/interfaces/IApplication';

type IGetLastApplicationFromStudent = {
  studentId: string,
  trail_id: number,
  application_type: string
}
class GetLastApplicationFromStudent {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(
    params: IGetLastApplicationFromStudent
  ): Promise<IApplication | false | 'canceling'> {
    try {
      const { studentId, ...rest } = params
      let searchParams = objectToQuery({ ...rest },false);

      const res = await this.api.get(`/application/last/${studentId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default GetLastApplicationFromStudent;
