import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import GroupCard from '@modules/groups/components/GroupCard';
import UserCard from '@modules/users/components/UserCard';
import CustomForm from '@shared/components/CustomForm';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import InputPill from '@shared/components/InputPill';
import ParentRender from '@shared/components/ParentRender';
import { Input, Spinner, Textarea, formatDate, useToast } from 'react-alicerce-components';
import { Container } from './styles';
import Icon from 'react-eva-icons';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { GetWorkedHourService } from '@modules/worked-hours/services/GetWorkedHourService';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';
import ClassCard from '../../../classes/components/ClassCard';
import { getTime } from '../../../../shared/utils/getTime';
import Divider from '../../../../shared/components/Divider';
import { ReproveWorkedHourService } from '../../services/ReproveWorkedHourService';
import ButtonGradientOutline from '../../../../shared/components/ButtonGradientOutline';
import PageFooter from '../../../../shared/components/PageFooter';
import ConfirmModalJustification from '../../components/ConfirmModalJustification';

export const WorkedHoursApproved: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const [showModalJustification, setShowModalJustification] = useState<boolean>(false);

  const { isLoading, setIsLoading } = useGlobal();
  const [workedHour, setWorkedHour] = useState<IWorkedHour>();

  const getWorkedHour = useCallback(async () => {
    if (!signedUser || !id) return;
    setIsLoading(true);
    const res = await new GetWorkedHourService(signedUser.token).execute(id);
    if (res && isMounted()) setWorkedHour(res);
    isMounted() && setIsLoading(false);
  }, [id, isMounted, setIsLoading, signedUser]);

  useEffect(() => {
    getWorkedHour();
  }, [getWorkedHour]);

  const reproveWorkedHour = useCallback(async () => {
    if (!signedUser || !workedHour?.id || !workedHour?.justification) return;
    setIsLoading(true);
    const res = await new ReproveWorkedHourService(signedUser.token).execute(workedHour.id, signedUser.lmsUser.id, workedHour.justification);
    if (!res) {
      setIsLoading(false);
      return addToast({
        status: 'danger',
        title: 'Horas Trabalhadas',
        description: `Houve um problema ao reprovar.`,
      });
    }

    addToast({
      status: 'success',
      title: 'Horas Trabalhadas',
      description: 'Hora trabalhada reprovada com sucesso.',
    });
    setIsLoading(false);
    navigate(-1);
  }, [addToast, navigate, setIsLoading, signedUser, workedHour]);

  const handleInputChange = useCallback((keyName: string, keyValue: string | null) => {
    setWorkedHour((oldState) => {
      return { ...oldState, [keyName]: keyValue };
    });
  }, []);

  console.log(workedHour, 'workedHour')

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aprovar Horas Trabalhadas"
        status="primary"
        backgroundHeader="linear-gradient(284.92deg, #F47920 0%, #0097B1 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        {workedHour && workedHour.issuer && workedHour.group && (
          <CustomForm status="primary">
            <ParentRender label="Hora Aprovada Por:" hasParent={true} required={false}>
              <UserCard user={workedHour?.approver!} canSelect={false} isInputCard={true} />
            </ParentRender>
            <div className="container-date-hours">
              <div className="date-container">
                <Input
                  label="Data da Aprovação:"
                  name="date"
                  disabled
                  defaultValue={workedHour ? formatDate(new Date(`${workedHour?.completed_at}`), 'long') : ''}
                />
              </div>
              <div className="hour-container">
                <Input
                  disabled
                  label="Horário da Aprovação:"
                  name="hours"
                  mask="##:##"
                  placeholder="00:00h"
                  defaultValue={getTime(new Date(`${workedHour.completed_at}`))}
                  end={<Icon name="clock" fill="#8F9BB3" />}
                />
              </div>
            </div>
            <Divider />
            <InputPill label="Modalidade da Categoria" inputPillData={workedHour?.category ? [workedHour?.category.name] : []} />
            {workedHour.modality && (
              <InputPill label="Modalidade da Categoria" inputPillData={workedHour?.modality ? [workedHour?.modality.name] : []} />
            )}
            <InputPill label="Projeto" inputPillData={workedHour?.project ? [workedHour?.project.name] : []} />
            <Textarea name="description" label="Detalhes da Hora:" value={workedHour?.description} rows={4} disabled />
            <ParentRender label="Nome do Usuário:" hasParent={true}>
              <UserCard user={workedHour?.issuer} canSelect={false} isInputCard={true} />
            </ParentRender>
            <ParentRender label="Grupo Relacionado:" hasParent={true} required={false}>
              <GroupCard inputCard={true} group={workedHour?.group} customStyle={{ margin: '1rem' }} />
            </ParentRender>
            {workedHour?.class &&
              <ParentRender label="Turma Relacionado:" hasParent={true} required={false}>
                <ClassCard inputCard={true} classInfo={workedHour?.class} customStyle={{ margin: '1rem' }} />
              </ParentRender>
            }
            <Input
              label="Data da Hora Lançada:"
              name="date"
              disabled
              defaultValue={workedHour ? formatDate(new Date(`${workedHour?.date}`), 'long') : ''}
            />
            <div className="container-add-hours">
              <Input
                disabled
                label="Horas Adicionadas:"
                name="hours"
                mask="##:##"
                placeholder="00:00h"
                defaultValue={convertMinutesToHour(workedHour?.total_minutes) + 'h'}
                end={<Icon name="clock" fill="#8F9BB3" />}
              />
            </div>
          </CustomForm>
        )}
      </DirectoryContainer>

      <PageFooter>
        <ButtonGradientOutline shaded={true} onClick={() => setShowModalJustification(true)} status="aviador">
          Reprovar
        </ButtonGradientOutline>
      </PageFooter>

      <ConfirmModalJustification
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalJustification}
        closeCallback={() => setShowModalJustification(false)}
        subTitle={'Justifique a Reprovação:'}
        title="Deseja Reprovar Hora?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={reproveWorkedHour}
        justification={(event) => handleInputChange('justification', event)}
      />
    </Container>
  );
};
