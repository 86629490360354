import styled from 'styled-components';

export const ClassPlannerFormContainer = styled.div`
  .input-container:first-child {
    margin-top: 0px;
  }

  .container-initial-final-date {
    display: flex;
    gap: 32px;

    .input-container {
      margin: 0;
    }

    > div {
      width: calc(50% - 16px);
    }
  }
`;
