import React, { CSSProperties, useMemo } from 'react';
import { P } from 'react-alicerce-components';
import CustomCard from '@shared/components/CustomCard';

import IPlace from '@modules/places/interfaces/IPlace';
import { Container } from './styles';

interface IPlaceCard {
  place: IPlace;
  placesSelected?: IPlace[];
  canSelect?: boolean;
  isInputCard?: boolean;
  handleOnClick?: (place: IPlace) => void;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
}

const PlaceCard: React.FC<IPlaceCard> = ({ place, canSelect, handleOnClick, placesSelected, canDeleteCard, isInputCard = false, customStyle }) => {
  const isSelected = useMemo(() => {
    return placesSelected?.find((p) => p.id === place.id) && canSelect ? true : false;
  }, [canSelect, place.id, placesSelected]);

  return (
    <Container onClick={handleOnClick ? () => handleOnClick(place) : undefined} selected={isSelected}>
      <CustomCard
        status="primary"
        header={<span>{place.name}</span>}
        canDeleteCard={canDeleteCard}
        inputCard={isInputCard}
        customStyles={{ ...customStyle }}
      >
        <P textAlign="center">{place.description}</P>
      </CustomCard>
    </Container>
  );
};

export default PlaceCard;
