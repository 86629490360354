import { styled } from "react-alicerce-components";

export const Container = styled.div`
    .card-header .date-container {
        line-height: 1.22;
    }

    .select-container {
        display: flex;
        max-width: 80%;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        margin-top: 30px;

        label {
            margin-right: 8px;
            font-family: var(--secondary-font);
        }

        .select-option-text {
            flex: 1;
        }
    }
`