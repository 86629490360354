import { P } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .card-header {
    padding: .5rem;
  }

  .card-body {
    border-radius: 0;
    justify-content: flex-start;
    flex-direction: column;
  }

  .card-footer {
    border-color: transparent;

    > div {
      padding: .5rem;
    }

    > div:first-child {
      display: flex;
      align-items: center;
      background-color: #855CBD;
    }

    > div:last-child {
      background-color: #E9D7F9;
      border-bottom-left-radius: 0.31rem;
      border-bottom-right-radius: 0.31rem;

      p {
        font-size: 12px;
        margin-left: 4px;
      }

      > div {
        margin: .5rem 0;
        > div {
          background: #6749BA;
          height: 1px;
        }
      }
    }
  }

  .tag {
    border-radius: 4px;
    padding: 3px 6px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    font-size: 12px;
  }
`;

export const ClassName = styled(P)`
  font-weight: 900;
`;

export const ClassPlace = styled(P)`
  font-size: 12px;
`;