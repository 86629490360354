import React, { useState } from 'react';
import * as Yup from 'yup';
import Icon from 'react-eva-icons';
import { ButtonFooterContainer } from '../styles';
import { Button, H1, P, Textarea } from 'react-alicerce-components';
import { RadioOption } from '../../../../shared/components/RadioOption';
import { FieldSet, ValidateLearningTargerFormWarningContainer } from './styles';
import { MultiStepComponentProps } from '../../../../shared/components/MultiStep';
import { IInputErrorsFormat, beforeSubmitForm, errorMessageBuilder } from '../../../../shared/utils/beforeSubmitForm';
type ResourceFragmentForm = {
  metaOption: string;
  teacher_observation?: string;
};
export const ValidateLearningTargetFormSelectResourceFragment = (props: MultiStepComponentProps) => {
  const { onBack, finalData, setValueCallback, onNext, handleSubmit } = props;
  const [errors, setErrors] = useState<IInputErrorsFormat[]>();
  const [resourceFragmentForm, setResourceFragmentForm] = useState<ResourceFragmentForm>(finalData || {});

  const TargetResourceSchema = Yup.object().shape({
    metaOption: Yup.string().required({ name: 'metaOption', message: 'O campo é obrigatório' }),
    teacher_observation: Yup.string().when('metaOption', {
      is: 'teacher_observation',
      then: Yup.string().required({ name: 'teacher_observation', message: 'O campo é obrigatório' }),
    }),
  });

  const canGoNext = async () => {
    let formErrors = await beforeSubmitForm(resourceFragmentForm, TargetResourceSchema);
    setErrors(formErrors);
    if (formErrors.length !== 0) return;

    if (resourceFragmentForm.metaOption === 'completed_mapa') {
      return handleSubmit && handleSubmit();
    }

    setValueCallback && setValueCallback(resourceFragmentForm);
    onNext();
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setResourceFragmentForm((oldState: any) => {
      return { ...oldState, [inputName]: inputValue };
    });
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.id;
    const inputName = event.target.name;
    setResourceFragmentForm((oldState: any) => {
      return { ...oldState, [inputName]: inputValue };
    });
  };

  const openTextArea = resourceFragmentForm.metaOption === 'teacher_observation';
  return (
    <ValidateLearningTargerFormWarningContainer>
      <div style={{ marginTop: '.8rem' }}>
        <div className="icon-container">
          <Icon name="alert-circle-outline" fill="#F2C94C" />
        </div>
        <H1 className="title">Selecione Recurso de Definição de Meta!</H1>
        <P className="description">
          Qual foi o embasamento para a escolha do bloco que você está definindo como meta a ser trabalhada na próxima quinzena.
        </P>
      </div>
      <FieldSet error={errorMessageBuilder('metaOption', errors)}>
        {errorMessageBuilder('metaOption', errors) && <legend>{errorMessageBuilder('metaOption', errors)}</legend>}
        <RadioOption
          defaultChecked={resourceFragmentForm.metaOption === 'printed_minimapa'}
          id="printed_minimapa"
          name="metaOption"
          round
          label="Mini MAPA Impresso"
          value="printed_minimapa"
          onChange={handleChangeRadio}
        />
        <RadioOption
          defaultChecked={resourceFragmentForm.metaOption === 'learning_cards'}
          id="learning_cards"
          name="metaOption"
          round
          label="Fichas de Aprendizagem"
          value="learning_cards"
          onChange={handleChangeRadio}
        />
        <div>
          <RadioOption
            defaultChecked={resourceFragmentForm.metaOption === 'teacher_observation'}
            id="teacher_observation"
            name="metaOption"
            round
            label="Observações do Instrutor"
            value={'teacher_observation'}
            onChange={handleChangeRadio}
          />
          {openTextArea && (
            <Textarea
              name="teacher_observation"
              useTooltipError={false}
              rows={6}
              placeholder="Adicione Observações"
              value={resourceFragmentForm.teacher_observation}
              onChange={handleChangeInput}
              error={errorMessageBuilder('teacher_observation', errors)}
            />
          )}
        </div>
        <RadioOption
          defaultChecked={resourceFragmentForm.metaOption === 'completed_mapa'}
          id="completed_mapa"
          name="metaOption"
          round
          label="Aluno Fez o MAPA na Quinzena"
          value={'completed_mapa'}
          onChange={handleChangeRadio}
        />
      </FieldSet>

      <ButtonFooterContainer>
        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '3px solid #482B85' }}
          onClick={() => onBack?.()}
        >
          Voltar
        </Button>

        <Button
          shaded
          transform="none"
          style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
          onClick={canGoNext}
        >
          Continuar
        </Button>
      </ButtonFooterContainer>
    </ValidateLearningTargerFormWarningContainer>
  );
};
