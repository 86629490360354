import * as Yup from 'yup';

export const SprintBodySchema = Yup.object().shape({
  class_id: Yup.string().required({ name: 'class_id', message: 'O campo é obrigatório' }),
  plan: Yup.array()
    .of(
      Yup.object({
        answer: Yup.string().required({ name: 'answer', message: 'O campo é obrigatório' }),
      })
    )
    .required(),
});
