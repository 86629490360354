import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import IClass from '@modules/classes/interfaces/IClass';
import ClassesList from '@modules/classes/components/ClassesList';

const OcurrenceClassRelationCreate: React.FC = () => {
  const { setOccurrenceCurrent, occurrenceCurrent } = useOccurrences();
  const [selectedClass, setSelectedClass] = useState<IClass>();

  useEffect(() => {
    if (!occurrenceCurrent) return;
    setSelectedClass(occurrenceCurrent.class);
  }, [occurrenceCurrent]);

  const handleAddRelationClass = useCallback(
    (classSelected: IClass | undefined) => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, class: classSelected };
      });
    },
    [setOccurrenceCurrent]
  );

  const setSelectedItens = useCallback(
    (c: IClass[]) => {
      setSelectedClass(c[0]);
    },
    [setSelectedClass]
  );

  const selectedItens = useMemo(() => {
    const aux = [] as IClass[];
    selectedClass && aux.push(selectedClass);
    return aux;
  }, [selectedClass]);

  return (
    <ClassesList
      headerTitle="Diretório de Turmas"
      btnText="Adicionar Turma"
      pathRedirect="/ocorrencias/criar"
      pathToReturn="/ocorrencias/criar"
      selectedClass={selectedItens}
      setSelectedClass={setSelectedItens}
      canSelect={'one'}
      handleOnClick={handleAddRelationClass}
    />
  );
};

export default OcurrenceClassRelationCreate;
