import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  .calendar-picker-container {
    flex: 1;
    width: 100%;
    height: 100%;

    .day-block .inner {
      width: inherit;
      height: 32px;
      font-size: 15px;
    }

    .day-block.marked::after {
      bottom: 2px;
      top: initial;
      right: initial;
    }

    .container-day {
      grid-gap: 5px;
    }

    .day-block.selected .inner:hover {
      border-radius: 4px;
      background-color: rgba(255, 61, 113, 0.24);
      border: 1px solid #db2c66;
      color: #000;
    }

    .day-block.active .inner {
      background: rgba(248, 163, 86, 0.08);
      border: 1px solid #f47920;
      color: #222b45;
      width: inherit;
      height: 32px;
    }
  }
`;
