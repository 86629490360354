import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { usePlaces } from "@modules/places/context/PlacesContext";
import UserList from "@modules/users/components/UsersList";
import { IUser } from "@modules/users/interfaces/IUser";
import { useNavigate } from "react-router-dom";

const PlaceUsersRelationEdit: React.FC = () => {
  const { setPlaceCurrent, placeCurrent } = usePlaces();
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (users: IUser[]) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, admins: users };
      });
    },
    [setPlaceCurrent]
  );

  const selectedItens = useMemo(() => {
    return placeCurrent?.admins;
  }, [placeCurrent]);

  useEffect(() => {
    if(!placeCurrent) navigate("/meus-locais")
  },[navigate, placeCurrent])

  if(!placeCurrent) return <Fragment />

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      pathRedirect={`/local/${placeCurrent.id}/editar`}
      pathToReturn={`/local/${placeCurrent.id}/editar`}
      selectedItens={selectedItens}
      setSelectedItens={handleOnClick}
      canSelect={"many"}
      handleOnClick={handleOnClick}
    />
  );
};

export default PlaceUsersRelationEdit;
