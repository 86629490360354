import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import Icon from 'react-eva-icons';

import { IStudent } from '@modules/students/interfaces/IStudent';

import { Checkbox, Button } from 'react-alicerce-components';

import { Container, Header, Body, Status, StatusContainer, StatusItem, TrailName, StatusInfo, StatusColor, When, Footer } from './styles';
import { FiCheck } from 'react-icons/fi';
import { useLaunchers } from '@modules/launchers/context/LaunchersContext';
import { formateMapaBody } from '@modules/launchers/utils/formateMapaBody';
import InitialCreateApplication from '@modules/launchers/views/MapaLauncher';
import ConfirmModal from '@shared/components/ConfirmModal';
import useAuth from '@shared/store/auth/hook';
import { ITrailItems } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { applicationDate } from '../../utils/formateDate';
import IClass from '@modules/classes/interfaces/IClass';

export interface ICustomTrail {
  trail_id: number;
  last_application: any;
  zdp_block_name?: string | null;
}

interface IMapaCardProps {
  student: IStudent;
  applicationTrails: ICustomTrail[];
  cancelApplication?: (id: string) => void;
  currentClass: IClass | undefined
}

const MapaCard: React.FC<IMapaCardProps> = ({ applicationTrails, student, cancelApplication, currentClass }) => {
  const { signedUser } = useAuth();
  const { trailItems, selectedStudentsMapa, setSelectedStudentsMapa } = useLaunchers();
  const mapa4AL = process.env.REACT_APP_LINK_MAPA4_AL ? process.env.REACT_APP_LINK_MAPA4_AL : null;
  //const [individualCheck, setIndividualCheck] = useState<string[]>([]);
  const individualCheckAux = useRef<string[]>([]);
  const [showCancelApplicationModal, setShowCancelApplicationModal] = useState<boolean>(false);
  const [aplicationIdCancel, setAplicationIdCancel] = useState<string>('');

  const lmsUserId = Number(signedUser?.lmsUser.id);

  const getStatus = (status): { status: string; color: string } =>
    ({
      FINALIZED: { status: 'Feito', color: 'linear-gradient(180deg, #5BB610 23.44%, #6DCC1F 100%)' },
      ONGOING: { status: 'Em Progresso', color: 'linear-gradient(180deg, #FAC50D 23.44%, #FFD74E 100%)' },
      REVISION: { status: 'Em Progresso', color: 'linear-gradient(180deg, #FAC50D 23.44%, #FFD74E 100%)' },
      PENDING: { status: 'Pendente', color: 'linear-gradient(180deg, #F63E3C 23.44%, #FF7773 100%)' },
      undefined: { status: 'Sem Aplicação', color: '#8F9BB3' },
    }[status]);

  const getTrailsValuesByClassType = (currentClassType: string, trails: ITrailItems[]) => {
    if (currentClassType === 'english') return trails.filter((trail) => trail.name === 'Inglês');
    if (currentClassType === 'core')
      return trails.filter(
        (trail) => trail.name === 'Matemática' || trail.name === 'Redação' || trail.name === 'Hab. para vida' || trail.name === 'Leitura'
      );
    return trails;
  };

  const getTrail = useCallback((status) => ({ 9: 'Inglês', 2: 'Leitura', 3: 'Matemática', 4: 'Redação', 5: 'Hab. para vida' }[status]), []);

  const handleCopyToClipBoard = () => {
    if (!student.id) return;
    navigator.clipboard.writeText(`${mapa4AL}/${student.id}`);
  };

  const handleOnChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, student: IStudent, trailId: number, applicationTrail: ICustomTrail[]) => {
      const checked: boolean = event.target.checked;
      const auxiliar: any[] = selectedStudentsMapa['applications'] ? [...selectedStudentsMapa['applications']] : [];

      const zpdName = applicationTrail.filter((ap) => Number(trailId) === Number(ap.trail_id));
      console.log(zpdName, 'zpdName');

      const finalZdp = zpdName[0]?.zdp_block_name;
      if (!checked) {
        individualCheckAux.current = individualCheckAux.current.filter((id) => `${id}` !== `${trailId}`);

        let filtered = auxiliar.filter((item) => item.lms_student_id !== Number(student.id) || item.trail_id !== Number(trailId));
        setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
          return { ...oldState, applications: filtered };
        });
      } else {
        individualCheckAux.current.push(`${trailId}`);

        const formatedMapaBody = formateMapaBody(student, trailId, finalZdp, currentClass!, lmsUserId);

        auxiliar.push(formatedMapaBody);
        console.log(auxiliar, ' auxiliar');
        setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
          return { ...oldState, applications: auxiliar };
        });
      }
    },
    [currentClass, lmsUserId, selectedStudentsMapa, setSelectedStudentsMapa]
  );

  const handleCancelApplicationModal = (id: string) => {
    console.log(id, 'id');
    cancelApplication && cancelApplication(id);
    setShowCancelApplicationModal(false);
  };

  const handleIdApplication = (id: string) => {
    setShowCancelApplicationModal(true);
    setAplicationIdCancel(id);
  };
  return (
    <Container>
      <Header>
        {currentClass?.type &&
          getTrailsValuesByClassType(currentClass?.type, trailItems).map((trail) => (
            <Checkbox
              hideIconUncheck
              icon={FiCheck}
              key={`${trail.name}`}
              name={`${trail.name}`}
              checked={selectedStudentsMapa.applications.find((app) => +app.trail_id === trail.trailId && +app.lms_student_id === +student?.id!)}
              id={`${trail.name}${student.name}`}
              label={trail.name}
              onChange={(event) => handleOnChangeCheckbox(event, student, trail.trailId, applicationTrails)}
            />
          ))}
      </Header>
      <Body>{student.name}</Body>
      <Status>Status dos MAPAs</Status>
      <StatusContainer>
        {applicationTrails.map((trail) => (
          <StatusItem key={`${trail.trail_id}`}>
            <TrailName>{getTrail(trail.trail_id)}:</TrailName>
            <StatusInfo>
              <StatusColor {...getStatus(trail.last_application?.status)} />
              {trail.last_application && (
                <When>
                  {applicationDate(trail.last_application)}
                  {(trail.last_application.status === 'PENDING' || trail.last_application.status === 'ONGOING') && (
                    <Button
                      start={<Icon name="close" size={'medium'} />}
                      fill="#FF3D71"
                      onClick={() => handleIdApplication(trail.last_application.id)}
                      size={'tiny'}
                      rounded
                      status={'danger'}
                      className="button-cancel-activity"
                    >
                      Cancelar
                    </Button>
                  )}
                </When>
              )}
            </StatusInfo>
          </StatusItem>
        ))}
      </StatusContainer>
      <Footer>
        <Button transform="none" status="ghost" shaded onClick={handleCopyToClipBoard}>
          Copiar Link do Aluno
        </Button>
      </Footer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCancelApplicationModal}
        closeCallback={() => setShowCancelApplicationModal(false)}
        title="Deseja Cancelar Aplicação?"
        subTitle="ATENÇÃO: Ao cancelar aplicação atual, o estado de “feito” da aplicação anterior volta a aparecer."
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        status="quaternary"
        confirmCallBack={() => handleCancelApplicationModal(aplicationIdCancel)}
      />
    </Container>
  );
};

export default MapaCard;
