import { ISelectOption } from 'react-alicerce-components';

export const LMSUSER_CATEGORY_TEACHER = { name: 'Líder', value: 'teacher' };
export const LMSUSER_CATEGORY_MANAGER = { name: 'Manager', value: 'manager' };

export const LMSUSER_CATEGORY_CONSTRAINTS = [LMSUSER_CATEGORY_TEACHER, LMSUSER_CATEGORY_MANAGER];

export const defaultLmsUserCategory = (lmsUserCategory?: string | null): ISelectOption[] | undefined => {
  return [
    { name: LMSUSER_CATEGORY_TEACHER.name, value: LMSUSER_CATEGORY_TEACHER.value, selected: lmsUserCategory === LMSUSER_CATEGORY_TEACHER.value },
    { name: LMSUSER_CATEGORY_MANAGER.name, value: LMSUSER_CATEGORY_MANAGER.value, selected: lmsUserCategory === LMSUSER_CATEGORY_MANAGER.value },
  ];
};

export const LMSUSER_CATEGORY_VALUES = LMSUSER_CATEGORY_CONSTRAINTS.map((a) => a.value);
