import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import {
  Button,
  Input,
  Pagination,
  Spinner
} from 'react-alicerce-components';

import { useGlobal } from "@shared/contexts/global/GlobalContext";
import { useRecordings } from '@modules/recordings/context/RecordingsContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import MediaListItem from '@modules/recordings/components/MediaListItem';
import Media from '@modules/recordings/components/Media';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import {
  Container,
  WrapperContent,
  MediaCurrentContainer,
  List
} from './styles';

const WatchMedias: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  const [isLoadingCurrentVideo, setIsLoadingCurrentVideo] = useState<boolean>(true);

  const { mediaCurrent, medias } = useRecordings();

  const [currentPage] = useState<number>(0);
  const [totalOfPages] = useState<number | string>(0);

  const list = useCallback(async (search = '', page = 0) => { }, []);

  useEffect(() => {
    setTimeout(() => setIsLoadingCurrentVideo(false), 2000);
  }, []);

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Sala 2 Câmera 01"
        backgroundHeader='linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)'
      />

      <DirectoryContainer style={{ margin: 0, padding: 0 }} footer>
        <MediaCurrentContainer>
          {isLoadingCurrentVideo && <Spinner className="spinner" />}
          <Media media={mediaCurrent} showRealTimeAlert />
        </MediaCurrentContainer>

        <WrapperContent>
          <div>
            <Input
              placeholder="Buscar"
              name="search"
              end={<Icon name="funnel" fill="#8F9BB3" />}
            />
          </div>
          <List>
            {medias.map((media, i) => (<MediaListItem key={i} media={media} />))}
          </List>

          <Pagination
            totalOfPages={totalOfPages}
            currentPage={currentPage}
            callbackGetListData={list}
            status="basic"
          />

        </WrapperContent>
        <PageFooter>
          <Button
            onClick={() => navigate('/gravacoes/upload')}
            className='button-open-upload'
            color="#fff"
            transform="none"
            noBorder
            shaded
          >
            Fazer Upload
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export default WatchMedias;
