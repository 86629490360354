import React, { useCallback, useState } from 'react';

import { Button } from 'react-alicerce-components';

import Divider from '@shared/components/Divider';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';

import { IndicatorsLayout as Layout } from '@modules/dashboards/components/IndicatorsLayout';
import WeekSelect, { IChangeWeek } from '@modules/dashboards/components/WeekSelect';

import AreaChart from '@shared/components/CustomRecharts/charts/AreaChart';

import { BoxColor, Container } from './styles';
import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';


const NPSIndicator: React.FC = () => {

    const [areaChartData] = useState([
        { name: '1º Ap.', percent: 87 },
        { name: '2º Ap.', percent: 79 },
    ]);

    const handleOnChangeWeek = useCallback(async (data: IChangeWeek) => {
        console.log('data', data);
    }, []);

    return (
        <Layout openTab={3}>
            <Container>
                <WeekSelect handleChangeWeek={handleOnChangeWeek} />

                <Divider className='divider' width='100%' />

                <AccordionInfoItem title='NPS Aluno'>
                    <AreaChart data={areaChartData} dataKey='name' />

                    <div className='box-container'>
                        <div className="box-label">Promotores</div>
                        <div className="box-value"><b>753</b></div>
                        <BoxColor color='#00D68F'>85%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Neutros</div>
                        <div className="box-value"><b>88</b></div>
                        <BoxColor color='#FFAA00'>10%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Detratores</div>
                        <div className="box-value"><b>42</b></div>
                        <BoxColor color='#FF3D71'>5%</BoxColor>
                    </div>

                    <div className="nps-media-value-container">
                        <div className="nps-media-value-info">
                            <div>Média valores de nps</div>
                            <div>81</div>
                            <div>Zona de Excelencia | 75 a 100</div>
                        </div>
                        <DonutChart
                            width='100%'
                            height={150}
                            outerRadius={60}
                            innerRadius={30}
                            data={[
                                { percent: 81, color: '#00D68F' },
                                { percent: 14, color: '#FFAA00' },
                                { percent: 5, color: '#FF3D71' },
                            ]}
                        />
                    </div>
                    <div className="buttons-container">
                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G1 E G2
                        </Button>

                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G3 E G4
                        </Button>
                    </div>

                </AccordionInfoItem>

                <AccordionInfoItem title='NPS Líder'>
                    <AreaChart data={areaChartData} dataKey='name' />

                    <div className='box-container'>
                        <div className="box-label">Promotores</div>
                        <div className="box-value"><b>753</b></div>
                        <BoxColor color='#00D68F'>85%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Neutros</div>
                        <div className="box-value"><b>88</b></div>
                        <BoxColor color='#FFAA00'>10%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Detratores</div>
                        <div className="box-value"><b>42</b></div>
                        <BoxColor color='#FF3D71'>5%</BoxColor>
                    </div>

                    <div className="nps-media-value-container">
                        <div className="nps-media-value-info">
                            <div>Média valores de nps</div>
                            <div>81</div>
                            <div>Zona de Excelencia | 75 a 100</div>
                        </div>
                        <DonutChart
                            width='100%'
                            height={150}
                            outerRadius={60}
                            innerRadius={30}
                            data={[
                                { percent: 81, color: '#00D68F' },
                                { percent: 14, color: '#FFAA00' },
                                { percent: 5, color: '#FF3D71' },
                            ]}
                        />
                    </div>
                    <div className="buttons-container">
                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G1 E G2
                        </Button>

                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G3 E G4
                        </Button>
                    </div>

                </AccordionInfoItem>

                <AccordionInfoItem title='NPS Gratuito'>
                    <AreaChart data={areaChartData} dataKey='name' />

                    <div className='box-container'>
                        <div className="box-label">Promotores</div>
                        <div className="box-value"><b>753</b></div>
                        <BoxColor color='#00D68F'>85%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Neutros</div>
                        <div className="box-value"><b>88</b></div>
                        <BoxColor color='#FFAA00'>10%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Detratores</div>
                        <div className="box-value"><b>42</b></div>
                        <BoxColor color='#FF3D71'>5%</BoxColor>
                    </div>

                    <div className="nps-media-value-container">
                        <div className="nps-media-value-info">
                            <div>Média valores de nps</div>
                            <div>81</div>
                            <div>Zona de Excelencia | 75 a 100</div>
                        </div>
                        <DonutChart
                            width='100%'
                            height={150}
                            outerRadius={60}
                            innerRadius={30}
                            data={[
                                { percent: 81, color: '#00D68F' },
                                { percent: 14, color: '#FFAA00' },
                                { percent: 5, color: '#FF3D71' },
                            ]}
                        />
                    </div>
                    <div className="buttons-container">
                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G1 E G2
                        </Button>

                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G3 E G4
                        </Button>
                    </div>

                </AccordionInfoItem>

                <AccordionInfoItem title='NPS Aluno'>
                    <AreaChart data={areaChartData} dataKey='name' />

                    <div className='box-container'>
                        <div className="box-label">Promotores</div>
                        <div className="box-value"><b>753</b></div>
                        <BoxColor color='#00D68F'>85%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Neutros</div>
                        <div className="box-value"><b>88</b></div>
                        <BoxColor color='#FFAA00'>10%</BoxColor>
                    </div>

                    <div className='box-container'>
                        <div className="box-label">Detratores</div>
                        <div className="box-value"><b>42</b></div>
                        <BoxColor color='#FF3D71'>5%</BoxColor>
                    </div>

                    <div className="nps-media-value-container">
                        <div className="nps-media-value-info">
                            <div>Média valores de nps</div>
                            <div>81</div>
                            <div>Zona de Excelencia | 75 a 100</div>
                        </div>
                        <DonutChart
                            width='100%'
                            height={150}
                            outerRadius={60}
                            innerRadius={30}
                            data={[
                                { percent: 81, color: '#00D68F' },
                                { percent: 14, color: '#FFAA00' },
                                { percent: 5, color: '#FF3D71' },
                            ]}
                        />
                    </div>
                    <div className="buttons-container">
                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G1 E G2
                        </Button>

                        <Button
                            status='basic'
                            transform="none"
                            color='#fff'
                            statusNumber={600}
                            noBorder
                            shaded
                            size='small'
                        >
                            Ver Detalhes | G3 E G4
                        </Button>
                    </div>

                </AccordionInfoItem>
            </Container>
        </Layout>
    );
}

export default NPSIndicator;