import React, { cloneElement, createContext, useContext, useMemo, useState } from 'react'
import {
    AccordionWrapper,
    AccordionContentContainer, AccordionHeaderContainer
} from './styles'

type CustomAccordionProps = { children: any } & React.HTMLAttributes<HTMLDivElement>
type AccordionHeaderProps = {
    children: any
} & React.HTMLAttributes<HTMLDivElement>

type AccordionContentProps = {
    children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

type AccordionContextProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: React.ReactNode
}

//Ele poderá receber um header.
//Ele poderá receber um children.
//Botão de fechar será opcional.

//@TODO trocar esse any
const CustomAccordionContext = createContext<any | null>(null);

const CustomAccordion = (props: CustomAccordionProps) => {
    const { children, ...rest } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const CustomAccordionProviderValue = useMemo(
        () => ({ isOpen, setIsOpen }),
        [isOpen]
    );
    return (
        <CustomAccordionContext.Provider value={CustomAccordionProviderValue}>
            <AccordionWrapper {...rest}>
                {children}
            </AccordionWrapper>
        </CustomAccordionContext.Provider>
    )
}

export const AccordionHeader = (props: AccordionHeaderProps) => {
    const { children, ...rest } = props
    const { isOpen, setIsOpen } = useContext(CustomAccordionContext) as AccordionContextProps;

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <AccordionHeaderContainer onClick={toggleAccordion} {...rest}>
            {cloneElement(children, { isOpen })}
        </AccordionHeaderContainer>
    )
}

export const AccordionContent = (props: AccordionContentProps) => {
    const { children, ...rest } = props
    const { isOpen } = useContext(CustomAccordionContext) as AccordionContextProps;
    return (
        <AccordionContentContainer isOpen={isOpen} {...rest}>
            {children}
        </AccordionContentContainer>
    )
}

CustomAccordion.AccordionHeader = AccordionHeader
CustomAccordion.AccordionContent = AccordionContent

export default CustomAccordion