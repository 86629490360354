import { styled } from 'react-alicerce-components';

export const ProgramFormContainer = styled.div`
  .draggable {
    padding: 1rem;
    background-color: white;
    border: 1px solid black;
    cursor: move;
  }

  .draggable.dragging {
    opacity: 0.5;
  }
`;

export const DragMe = styled.div`
  width: 100px;
  aspect-ratio: 1;
`;

export const CardsContainer = styled.div<{ lockScroll?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  ${({ lockScroll }) =>
    lockScroll &&
    `
    overflow-y: auto;
    height: 550px;
  `}

  .over {
    border: 3px dotted #666;
  }

  .drag-over-container {
    gap: 5px;
    margin-bottom: 10px;

    .grabbing {
      cursor: grabbing;
    }

    .drag {
      cursor: move;
      position: relative;
      > svg {
        position: absolute;
        right: 2px;
        z-index: 2;
        cursor: grab;
        width: 30px;
        height: 30px;
      }
      .move-card-hambuguer {
        top: 50%;
        transform: translateY(-15%);
      }
      .move-card-top {
        top: 50%;
        transform: translateY(-110%);
        cursor: pointer;
      }
      .move-card-bottom {
        top: 50%;
        transform: rotate(180deg) translateY(-80%);
        cursor: pointer;
      }

      .activity-library-card-drag {
        justify-content: space-between;
        .card-container {
          cursor: grab;
        }
      }
    }
  }
`;
