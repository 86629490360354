import React, { useCallback, useEffect, useState } from 'react';

import { H6, Select, Input } from 'react-alicerce-components';
import { FixedTools } from '../../interfaces/IUser';

import { errorMessageBuilder, hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { defaultEducationLevel } from '@shared/utils/validData/validEducationLevel';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { defaultGender } from '@shared/utils/validData/validGender';
import CustomForm from '@shared/components/CustomForm';
import Divider from '@shared/components/Divider';

import { IGroup } from '@modules/groups/interfaces/IGroup';
import IClass from '@modules/classes/interfaces/IClass';
import IPlace from '@modules/places/interfaces/IPlace';
import IAuth from '@modules/home/interfaces/IAuth';

import { defaultLmsUserStatus } from '../../utils/validData/validUserStatus';

import { UserFormContainer } from './styles';
import { useUser } from '@modules/users/context/UserContext';
import { IProfile } from '@modules/users/interfaces/IProfile';
import { defaultLmsUserCategoryLms } from '@modules/users/utils/validData/validUserCategoriesLms';
import { calcAge } from '@shared/utils/calculateAge';

interface IUserCreateForm {
  errors: IInputErrorsFormat[];
  refPersonalMain: React.RefObject<any>;
  refPersonalAcademy: React.RefObject<any>;
  profilesLms?: IProfile[];
}

export interface IUserRef {
  name?: string;
  contact_email?: string;
  perfil?: any;
  email?: string;
  phone?: string;
  address_data?: {
    address: string;
    city: string;
    uf: string;
  } | null;
  birthdate?: Date;
  gender?: string;
  education_level?: string;
  university_type?: string;
  academic_info?: {
    name: string;
    course_name: string;
    end_date: string;
  };
  activation_date?: Date;
  user_id?: string | number;
  user?: IAuth;
  status?: string;
  category?: string;
  fixed_tools?: FixedTools;

  groupsAdmin?: IGroup[];
  classes?: IClass[];
  places?: IPlace[];

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

const UserCreateForm: React.FC<IUserCreateForm> = ({ errors, refPersonalMain, refPersonalAcademy, profilesLms }) => {
  const { userRef } = useUser();
  const [birthDateVar, setBirthDateVar] = useState<string>();
  const [age, setAge] = useState<string>();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, subLevel?: string) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;

      if (subLevel === 'address_data') {
        userRef.current.address_data[inputName] = inputValue;
      } else if (subLevel === 'academic_info') {
        userRef.current.academic_info[inputName] = inputValue;
      } else {
        userRef.current[inputName] = inputValue;
      }

      if (inputName === 'birthdate') setBirthDateVar(inputValue);
      console.log(userRef.current, 'userRef.current');
    },
    [userRef]
  );

  const handleSelectChange = useCallback(
    (option, fieldName: string) => {
      //fix name conflict db
      const categoryObj = {
        'Lideres LMS': 'teacher',
        'Administrador LMS': 'manager',
      };

      if (fieldName === 'category') {
        userRef.current[fieldName] = { name: categoryObj[option.name], id: option.value };
      } else {
        userRef.current[fieldName] = option.value;
      }

      console.log(userRef.current, 'userRef.currentd');
    },
    [userRef]
  );

  useEffect(() => {
    const calculatedAge = calcAge(birthDateVar);
    setAge(String(calculatedAge));
  }, [birthDateVar]);

  return (
    <UserFormContainer>
      <H6 fontWeight={800} status="secondary">
        DADOS ALICERCE
      </H6>
      <AccordionInfoItem title="Dados Principais" status="secondary" hasErrors={hasError(['status', 'activation_date'], errors)}>
        <CustomForm status="secondary" customInputColorText="#5DC4EA">
          <div className="container-accordion-input">
            <Input
              label="Email Alicerce:"
              name="email"
              placeholder="usuário@alicerceedu.com.br"
              onChange={(event) => handleChangeInput(event)}
              defaultValue={userRef?.current?.email}
              useTooltipError={false}
              required
              error={errorMessageBuilder('email', errors)}
            />
            <Input
              name="activation_date"
              placeholder="Dia/Mês/Ano"
              label="Data de Ativação:"
              onChange={(event) => handleChangeInput(event)}
              defaultValue={userRef?.current?.activation_date ? `${userRef?.current?.activation_date}` : ''}
              type="date"
              useTooltipError={false}
              error={errorMessageBuilder('activation_date', errors)}
            />
            <Select
              label="Perfil do Usuário:"
              iconName="arrow-down"
              placeholder="Escolha Perfil do Usuário"
              handleSelectChange={(option) => handleSelectChange(option, 'category')}
              options={profilesLms && defaultLmsUserCategoryLms(profilesLms)}
              required
              error={errorMessageBuilder('category', errors)}
            />
            <Select
              label="Status do Usuário:"
              iconName="arrow-down"
              placeholder="Escolha Status"
              handleSelectChange={(option) => handleSelectChange(option, 'status')}
              options={defaultLmsUserStatus(userRef?.current?.status)}
              required
              error={errorMessageBuilder('status', errors)}
            />
          </div>
        </CustomForm>
      </AccordionInfoItem>

      <Divider width="100%" />

      <H6 fontWeight={800} status="secondary">
        DADOS PESSOAIS
      </H6>
      <AccordionInfoItem
        refSize={refPersonalMain}
        title="Dados Principais"
        status="secondary"
        hasErrors={hasError(
          ['name', 'contact_email', 'phone', 'address_data.address', 'address_data.city', 'address_data.uf', 'gender', 'birthdate'],
          errors
        )}
      >
        <CustomForm status="secondary" customInputColorText="#5DC4EA">
          <Input
            label="Nome do Usuário:"
            name="name"
            placeholder="Nome do Usuário"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userRef?.current?.name}
            useTooltipError={false}
            required
            error={errorMessageBuilder('name', errors)}
          />
          <Input
            label="Email Pessoal:"
            name="contact_email"
            placeholder="Email do Usuário"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userRef?.current?.contact_email}
            useTooltipError={false}
            required
            error={errorMessageBuilder('contact_email', errors)}
          />

          <Input
            label="Telefone:"
            name="phone"
            placeholder="Telefone do Usuário"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userRef?.current?.phone}
            useTooltipError={false}
            required
            error={errorMessageBuilder('phone', errors)}
          />

          <Input
            label="Endereço:"
            name="address"
            placeholder="Logradouro, Rua e Bairro"
            onChange={(event) => handleChangeInput(event, 'address_data')}
            defaultValue={userRef?.current?.address_data?.address}
            useTooltipError={false}
            required
            error={errorMessageBuilder('address_data', errors)}
          />

          <div className="wrapper-input">
            <Input
              name="city"
              placeholder="Cidade"
              label="Cidade:"
              onChange={(event) => handleChangeInput(event, 'address_data')}
              defaultValue={userRef?.current?.address_data?.city}
              useTooltipError={false}
              required
              error={errorMessageBuilder('address_data', errors)}
            />
            <Input
              name="uf"
              placeholder="UF"
              label="UF:"
              onChange={(event) => handleChangeInput(event, 'address_data')}
              defaultValue={userRef?.current?.address_data?.uf}
              mask="##"
              useTooltipError={false}
              required
              error={errorMessageBuilder('address_data', errors)}
            />
          </div>

          <div className="wrapper-input">
            <Input
              name="birthdate"
              placeholder="Dia/Mês/Ano"
              label="Data de Nascimento:"
              onChange={(event) => handleChangeInput(event)}
              defaultValue={userRef?.current?.birthdate ? `${userRef?.current?.birthdate}` : ''}
              type="date"
              useTooltipError={false}
              error={errorMessageBuilder('birthdate', errors)}
            />
            <Input name="calculated_age" label={` `} placeholder="Idade Calculada" defaultValue={age} mask="##" useTooltipError={false} disabled />
          </div>

          <Select
            label="Identidade de Gênero:"
            iconName="arrow-down"
            placeholder="Selecione"
            handleSelectChange={(option) => handleSelectChange(option, 'gender')}
            options={defaultGender(userRef?.current?.gender)}
            required
            error={errorMessageBuilder('gender', errors)}
          />
        </CustomForm>
      </AccordionInfoItem>

      <AccordionInfoItem
        refSize={refPersonalAcademy}
        title="Dados Acadêmicos"
        status="secondary"
        hasErrors={hasError(['education_level', 'university_type', 'academic_info'], errors)}
      >
        <CustomForm status="secondary" customInputColorText="#5DC4EA">
          <div className="container-accordion-input">
            <Select
              label="Grau de Escolaridade:"
              iconName="arrow-down"
              placeholder="Escolha Escolaridade"
              handleSelectChange={(option) => handleSelectChange(option, 'education_level')}
              options={defaultEducationLevel(userRef?.current?.education_level)}
              required
              error={errorMessageBuilder('education_level', errors)}
            />

            <Select
              label="Tipo de Universidade:"
              iconName="arrow-down"
              placeholder="Escolha Perfil do Usuário"
              handleSelectChange={(option) => handleSelectChange(option, 'university_type')}
              options={[
                {
                  name: 'Particular',
                  value: 'private',
                  selected: userRef?.current?.university_type === 'private',
                },
                {
                  name: 'Pública',
                  value: 'public',
                  selected: userRef?.current?.university_type === 'public',
                },
              ]}
              required
              error={errorMessageBuilder('university_type', errors)}
            />

            <Input
              label="Informações Acadêmicas:"
              name="name"
              placeholder="Nome da Instituição"
              onChange={(event) => handleChangeInput(event, 'academic_info')}
              defaultValue={userRef?.current?.academic_info?.name}
              useTooltipError={false}
              required
              error={errorMessageBuilder('academic_info.name', errors)}
            />

            <div className="wrapper-input">
              <Input
                name="course_name"
                label="Curso:"
                placeholder="Nome do Curso"
                onChange={(event) => handleChangeInput(event, 'academic_info')}
                defaultValue={userRef?.current?.academic_info?.course_name}
                useTooltipError={false}
                required
                error={errorMessageBuilder('academic_info.course_name', errors)}
              />
              <Input
                label="Término:"
                name="end_date"
                placeholder="Término"
                onChange={(event) => handleChangeInput(event, 'academic_info')}
                defaultValue={userRef?.current?.academic_info?.end_date}
                useTooltipError={false}
                required
                error={errorMessageBuilder('academic_info.end_date', errors)}
              />
            </div>
          </div>
        </CustomForm>
      </AccordionInfoItem>
    </UserFormContainer>
  );
};

export { UserCreateForm };
