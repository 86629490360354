import React, { createContext, useContext, useState } from 'react';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { ISprintItemsResponse, ISprintResponse } from '@modules/sprint/interfaces/ISprintResponse';

export interface IAwnsersStringParams {
  next_sprint?: boolean;
  class_id: string;
  plan: {
    question: string;
    answer: string;
  }[];
}

export interface IAwnsersString {
  question: string;
  answer: string;
}

export type ISprintContextData = {
  sprintAnswersCurrent: IAwnsersStringParams | undefined;
  setSprintAnswersCurrent: (answers: any) => void;
  sprintResponseCurrent: ISprintResponse | undefined;
  setSprintResponseCurrent: (response: any) => void;
  currentSprint: ISprintItemsResponse | undefined;
  setCurrentprint: (sprint: any) => void;
  classIdCurent: string | number;
  setClassIdCurent: (classId: string) => void;
  getCurrentSprintResponse: ISprint | undefined;
  setGetCurrentSprintResponse: (sprint: any) => void;
};

const SprintContext = createContext<ISprintContextData>({} as ISprintContextData);

const initialData: IAwnsersStringParams = {
  class_id: '',
  plan: [
    { question: '', answer: '' },
    { question: '', answer: '' },
    { question: '', answer: '' },
  ],
};
const SprintContextProvider: React.FC = ({ children }) => {
  const [sprintAnswersCurrent, setSprintAnswersCurrent] = useState<IAwnsersStringParams | undefined>(initialData);
  const [sprintResponseCurrent, setSprintResponseCurrent] = useState<ISprintResponse | undefined>();
  const [currentSprint, setCurrentprint] = useState<ISprintItemsResponse>();
  const [getCurrentSprintResponse, setGetCurrentSprintResponse] = useState<ISprint | undefined>();
  const [classIdCurent, setClassIdCurent] = useState<string>('');

  return (
    <SprintContext.Provider
      value={{
        sprintAnswersCurrent,
        setSprintAnswersCurrent,
        sprintResponseCurrent,
        setSprintResponseCurrent,
        currentSprint,
        setCurrentprint,
        classIdCurent,
        setClassIdCurent,
        getCurrentSprintResponse,
        setGetCurrentSprintResponse,
      }}
    >
      {children}
    </SprintContext.Provider>
  );
};

function useSprint() {
  const context = useContext(SprintContext);

  if (!context) {
    throw new Error('useSprint must be used within a useSprint Provider');
  }

  return context;
}

export { SprintContextProvider, useSprint };
