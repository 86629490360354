import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .controlled-boxes-list {
    margin: var(--card-distance);
    row-gap: 20px;
    display: flex;
    flex-direction: column;
  }

  .pagination-content-icon {
    background-color: #f8a356 !important;
  }
`;
