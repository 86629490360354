import { styled } from 'react-alicerce-components';

export const Container = styled.div`
`;

export const Subtitle = styled.div`
  color: #2E3A59;
  font-size: 16px;
  font-family: var(--secondary-font);
  font-weight: 700;
  margin-top: 1rem;
`;
