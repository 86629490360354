import React, { useState } from 'react';

import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { BoxColor, Container } from './styles';

const DescobertaIndicator: React.FC = () => {


    const [donutChartData] = useState([
        { name: 'Tematica 7', percent: 5, color: '#35A0D6', value: '15 minutos' },
        { name: 'Temática 1 Super Legal e Bacana Exegética', percent: 20, color: '#94E2F8', value: '01h00min' },
        { name: 'Tematica 8', percent: 4, color: '#FFE59E', value: '12 minutos' },
        { name: 'Tematica 5', percent: 12, color: '#FFA8B4', value: '36 minutos' },
        { name: 'Tematica 4', percent: 16, color: '#FF3D71', value: '48 minutos' },
        { name: 'Tematica 6', percent: 6, color: '#0095FF', value: '18 minutos' },
        { name: 'Tematica 3', percent: 18, color: '#FFAA00', value: '54 minutos' },
        { name: 'Tematica 2', percent: 19, color: '#00D68F', value: '57 minutos' },
    ]);

    return (
        <Container>
            <HeaderDirectory title='Descoberta' />
            <div className="content">
                <DonutChart
                    data={donutChartData}
                    contentCenter='5 horas'
                    showStrokedPercent
                />


                {donutChartData.map((d, i) => (
                    <div className='box-container' key={i}>
                        <BoxColor color={d.color}>{d.name}</BoxColor>
                        <div className="box-value"><b>{d.value}</b></div>
                    </div>
                ))}

            </div>
        </Container>
    );
}

export default DescobertaIndicator;