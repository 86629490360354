import { styled } from "react-alicerce-components";

export const Container = styled.div`
  .box-children {
    width: 100%;
  }

  .box-select .box-children .custom-card > div {
    margin-top: 0.5rem;
  }
`;
