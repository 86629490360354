import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  width: fit-content;
  cursor: pointer !important;
  
  i {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c5cee0;
    border-radius: 8px;

    svg {
      width: 35px;
      height: 35px;
      fill: #fff;
    }
  }
`;
