import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';

import { Container } from './styles';

import { Button, Checkbox, Label, Spinner, useToast } from 'react-alicerce-components';

import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { TrailDesign } from '@shared/components/TrailDesign';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import PageFooter from '@shared/components/PageFooter';
import { ITrail } from '@shared/interfaces/ITrail';
import useAuth from '@shared/store/auth/hook';

import { StudentCardLauncher } from '@modules/launchers/components/StudentCardLauncher';
import { IStudentWithGradesCharts } from '@modules/launchers/interfaces/IStudentsWithGrades';
import { formateMinimapaBody } from '@modules/launchers/utils/formateMinimapaBody';
import { useLaunchers } from '@modules/launchers/context/LaunchersContext';
import WrapperContent from '@modules/launchers/components/WrapperContent';
import { IStudent } from '@modules/students/interfaces/IStudent';
import CreateApplicationService from '@modules/launchers/services/CreateApplicationsService';
import { getTrailId } from '@modules/launchers/utils/getTrail';
import { normalizeStrToParams } from '@modules/launchers/utils/normalizeStringToParams';
import ListStudentFromClassService from '@modules/launchers/services/ListStudentFromClassService';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import GetClassService from '../../../classes/services/GetClassService';

interface InitialData {
  applications: any[];
}

interface IMiniMapaFilter {
  age_groups?: string[];
}

const MiniMapaLauncher: React.FC = () => {
  const { trails, selectedStudentsMapaRef, currentClass, setCurrentClass } = useLaunchers();
  const [trailName, setTrailName] = useState<ITrail | undefined>(trails[0]);
  const [studentFromClass, setStudentFromClass] = useState<IStudentWithGradesCharts>();
  const miniMapaFilter = useRef<IMiniMapaFilter>({});
  const { trailId, setTrailId, setIsGlobalChecked } = useLaunchers();
  const [resetAccordion, setResetAccordion] = useState<boolean>(false);
  const { id, trail } = useParams<{ id: string; trail: string }>();
  const { isLoading, setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const { state } = useLocation();
  const { addToast } = useToast();

  const mapa4Tr = process.env.REACT_APP_LINK_MAPA4_TR ? process.env.REACT_APP_LINK_MAPA4_TR : null;

  const lmsUserId = Number(signedUser?.lmsUser.id);

  const listStudentFromClass = useCallback(
    async (search = '', trail_id = getTrailId(trail)) => {
      if (!id) return;
      setIsLoading(true);

      const res = await new ListStudentFromClassService(signedUser?.token, newCancelToken()).execute(search, id, true, {
        trail_id: trail_id,
        ...miniMapaFilter.current,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!!!res || !isMounted()) return;
      if (!res) return;
      setStudentFromClass(res);
    },
    [id, isMounted, newCancelToken, setIsLoading, signedUser, trail]
  );

  const findErrorType = (errors: any) => {
    // TODO Revistar tratamento de erros
    const errorsFilter = errors.map((erro) => erro.code);
    // Dic ERRORS:
    // 404 aluno não validado
    // 400 aluno com minimapa já lançado
    const countsErrors = {
      400: 0,
      404: 0,
    };
    errorsFilter.forEach(function (x) {
      countsErrors[x] = (countsErrors[x] || 0) + 1;
    });
    return countsErrors;
  };

  const getClass = useCallback(async () => {
    if (!id) return
    const res = await new GetClassService(signedUser?.token, newCancelToken()).execute(id);
    if (!res) {
      return addToast({
        status: 'danger',
        title: 'Classe',
        description: 'Falha ao tentar ao Recuperar Classe. Tente novamente.',
      });
    }
    setCurrentClass(res);
  }, [addToast, id, newCancelToken, setCurrentClass, signedUser]);

  const creteMapaLauncher = useCallback(async () => {
    if (!selectedStudentsMapaRef.current.applications.length) return;
    if (!signedUser) return;
    console.log(selectedStudentsMapaRef, 'ENVIAR PARA A BACKEND 😎');
    setIsLoading(true);
    const res = await new CreateApplicationService(signedUser.token).execute(selectedStudentsMapaRef.current);
    setIsLoading(false);
    setResetAccordion(true);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'MiniMAPA',
        description: 'Falha ao tentar ao criar o MiniMAPA. Tente novamente.',
      });
    }

    const errorsQuantity = findErrorType(res.errors);

    if (!res.errors?.length && !!res.success?.length) {
      addToast({
        status: 'success',
        title: 'MiniMAPA',
        description: 'MiniMAPA lançado com sucesso.',
      });
      return;
    }

    if (!!res.errors?.length && !!res.success?.length) {
      addToast({
        status: 'warning',
        title: 'MiniMAPA',
        description: `${res.success?.length} MiniMAPAs lançados. Porém ${res.errors?.length} alunos ainda tem o MiniMAPA pendente ou não validados.`,
      });
      return;
    }

    if (errorsQuantity[400] && errorsQuantity[404] > 0) {
      addToast({
        status: 'danger',
        title: 'MiniMAPA',
        description: 'Verique a validação ou se o aluno já têm MAPA ou MiniMAPA pendente',
        duration: 6000,
      });
      return;
    }

    if (errorsQuantity[404] > 0) {
      addToast({
        status: 'danger',
        title: 'MiniMAPA',
        description: 'O Aluno está sem definição de meta: Para lançar o MiniMAPA, definir a meta primeiro',
        duration: 6000,
      });
      return;
    }

    if (errorsQuantity[400] > 0) {
      addToast({
        status: 'danger',
        title: 'MiniMAPA',
        description: 'Todos os estudantes têm MAPA ou MiniMAPA pendentes',
      });
      return;
    }
  }, [addToast, selectedStudentsMapaRef, setIsLoading, signedUser]);

  const handleResetFilter = useCallback(() => {
    miniMapaFilter.current = {};
    listStudentFromClass();
  }, [listStudentFromClass]);

  const getTrail = trails.find((trailItem: ITrail) => {
    const trailNormalized: string = normalizeStrToParams(trailItem.name);
    if (trailNormalized === trail) return trailNormalized;
    return false;
  });

  const handleChangeCheckboxFilter = useCallback((event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string) => {
    const isChecked = event.target.checked;
    const value = event.target.id;
    const auxiliar = miniMapaFilter.current[objFilterKey] || [];

    if (isChecked) {
      miniMapaFilter.current[objFilterKey] = [...auxiliar, value];
    } else if (!isChecked) {
      const filtered = auxiliar.filter((aux) => aux !== value);
      miniMapaFilter.current[objFilterKey] = filtered;
    }
  }, []);

  const handleOnChangeCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, student: IStudent) => {
      const checked: boolean = event.target.checked;

      const studentFound = selectedStudentsMapaRef.current.applications.find(
        (studentApp) => Number(studentApp.lms_student_id) === Number(student.id)
      );
      if (!studentFound) {
        const studentWithoutMiniMapa = studentFromClass?.students_charts.find(
          (studentResponse) => Number(studentResponse.student.id) === Number(student.id)
        );
        const skillsAndQuestions: { id: number; questions: number }[] = [];
        studentWithoutMiniMapa?.gradeInfo?.skill_descriptions?.forEach((skills) => {
          const obj = { id: Number(skills.id), questions: 2 };
          skillsAndQuestions.push(obj);
        });
        if (!studentWithoutMiniMapa?.student || !trailId || !currentClass) return;
        const studentWithMiniMapaPrintble = formateMinimapaBody(lmsUserId, studentWithoutMiniMapa?.student, +trailId, skillsAndQuestions, currentClass, true);

        selectedStudentsMapaRef.current.applications.push(studentWithMiniMapaPrintble);
      }

      if (!checked) {
        selectedStudentsMapaRef.current.applications.map((st) => {
          if (Number(st.lms_student_id) === Number(student.id)) {
            st.state.printable = false;
          }
          return st;
        });
      } else {
        selectedStudentsMapaRef.current.applications.map((st) => {
          if (Number(st.lms_student_id) === Number(student.id)) {
            st.state.printable = checked;
          }
          return st;
        });
      }
    },
    [currentClass, lmsUserId, selectedStudentsMapaRef, studentFromClass, trailId]
  );

  const handleCheckboxSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked: boolean = event.target.checked;
      const auxiliar: any[] = [];

      const trailId = getTrailId(trail);
      if (!!checked) {
        setIsGlobalChecked(true);
        if (!studentFromClass || !currentClass) return;

        for (let index = 0; index < studentFromClass?.students_charts?.length; index++) {
          const element = studentFromClass.students_charts[index];
          const skillsAndQuestions: { id: number; questions: number }[] = [];

          if (!element.gradeInfo?.skill_descriptions) return;
          element.gradeInfo.skill_descriptions.forEach((skills) => {
            const obj = { id: Number(skills.id), questions: 2 };
            skillsAndQuestions.push(obj);
          });

          auxiliar.push(formateMinimapaBody(lmsUserId, element.student, trailId, skillsAndQuestions, currentClass));

          selectedStudentsMapaRef.current.applications = auxiliar;
        }
      }

      if (!checked) {
        setIsGlobalChecked(false);

        selectedStudentsMapaRef.current.applications = [];
      }
    },
    [currentClass, lmsUserId, selectedStudentsMapaRef, setIsGlobalChecked, studentFromClass, trail]
  );

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    setTrailId(getTrailId(trail));
  }, [setTrailId, trail]);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentClass) {
        await getClass();
      }
      setTrailName(getTrail);
      if (!state) {
        await listStudentFromClass();
        return;
      }
    };
    fetchData();
  }, [getTrail, getClass, listStudentFromClass, id, state, currentClass]);



  return (
    <Container>
      {isLoading && <Spinner fixed />}
      <TrailDesign key={`trail-design-1`} title={`Lançador de MiniMapa`} trail={trailName!} marginBottom="none" arrowBack borderRadius={false} />
      <DirectoryContainer style={{ marginTop: '0', marginBottom: '0rem', padding: '22px' }} footer>
        {/* START FILTER */}
        <AdvancedFilter
          handleApplyFilter={listStudentFromClass}
          handleResetFilter={handleResetFilter}
          onChangeMainInput={(newValue) => listStudentFromClass(newValue)}
          applyFilterButtonBackground="#96B6F7"
        >
          <Label>Grupos Etários:</Label>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {AGE_GROUP_CONSTRAINTS.map((ageGroup) => (
              <Checkbox
                key={`${ageGroup.name}`}
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                semiRound
                id={ageGroup.name}
                name={ageGroup.name}
                label={ageGroup.name}
                defaultChecked={!!miniMapaFilter.current.age_groups?.includes(ageGroup.value)}
                onChange={(event) => handleChangeCheckboxFilter(event, 'age_groups')}
              />
            ))}
          </div>
        </AdvancedFilter>
        {/* END FILTER */}
        <WrapperContent customStyle={{ paddingBottom: '2rem' }}>
          <div className="student-container">
            <div className="checkbox-invert">
              <Checkbox
                semiRound
                hideIconUncheck
                icon={FiCheck}
                status="basic"
                onChange={handleCheckboxSelectAll}
                key={`teste`}
                name={`teste`}
                id={`teste`}
                label={`Selecionar todos`}
                className={'invert-pill'}
              />
            </div>
            {studentFromClass?.students_charts?.map((studentData) => (
              <StudentCardLauncher
                key={studentData.student.id}
                studentData={studentData}
                className="subscription-card"
                trails
                resetAccordion={resetAccordion}
                setResetAccordion={setResetAccordion}
                handleOnChangeCheckbox={handleOnChangeCheckbox}
              />
            ))}
          </div>
        </WrapperContent>

        <PageFooter>
          <Button onClick={() => openInNewTab(`${mapa4Tr}/${id}`)} status="quaternary" transform="none" noBorder shaded>
            Aplicador da Turma
          </Button>
          <Button onClick={() => creteMapaLauncher()} status="quaternary" transform="none" noBorder shaded>
            Lançar Mini Mapa
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export { MiniMapaLauncher };
