import React, { useCallback, useState, useEffect } from 'react';
import { Pagination } from 'react-alicerce-components';

import DynamicTable, { IDynamicTableColumnsGroup, IDynamicTableSort } from '@shared/components/DynamicTable';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import GetIndividualFrequencyHistoryService, { IIndividualFrequencyHistoryReponse } from '@modules/dashboards/services/GetIndividualFrequencyHistoryService';
import { formatIndividualFrequencyHistory, IFormattedIndividualFrequencyHistory } from '@modules/dashboards/utils/formatIndividualFrequencyHistory';

import { Container, Content } from './styles';
import { useParams } from 'react-router-dom';

type IIndividualFrequencyHistoryReponseFormatted = IIndividualFrequencyHistoryReponse & {
    items: IFormattedIndividualFrequencyHistory[];
}
const IndividualFrequencyHistory: React.FC = () => {

    const { signedUser } = useAuth();
    const { studentId } = useParams();
    const { setIsLoading } = useGlobal();

    const [classes, setClasses] = useState<IIndividualFrequencyHistoryReponseFormatted>();
    const [columns] = useState<IDynamicTableColumnsGroup[]>([
        {
            columns: [
                { name: 'Turmas', dataname: 'classname' },
                { name: 'Tipo', dataname: 'type' },
                { name: 'Aulas', dataname: 'lessons' },
            ],
            fixed: true
        },
        {
            columns: [
                { name: 'Presença', dataname: 'presence' }
            ],
            fixed: false
        }
    ]);

    const load = useCallback(async (search?: string, page?: number, option?: any) => {

        if (!signedUser || !studentId) return;

        setIsLoading(true);

        const res = await new GetIndividualFrequencyHistoryService(signedUser.token)
            .execute(studentId, { query: search, page, ...option });

        setIsLoading(false);

        if (!res) return;
        setClasses(() => ({
            ...res,
            items: res?.items.map(formatIndividualFrequencyHistory)
        } as IIndividualFrequencyHistoryReponseFormatted));

    }, [setIsLoading, signedUser, studentId]);

    useEffect(() => {
        load();
    }, [load]);

    const handleOnClickSort = useCallback((sort: IDynamicTableSort) => {
        const orderBy = sort.sortName as string;

        setClasses((oldState) => {
            if (!oldState) return;
            let aux = [...oldState.items]
            aux.sort((a, b) => (sort.sortByAsc ? a[orderBy] >= b[orderBy] : a[orderBy] <= b[orderBy]) ? 1 : -1);
            return {
                ...oldState,
                items: aux,
            };
        });
    }, []);

    return (
        <Container>
            <Content>
                <DynamicTable
                    columnsGroup={columns}
                    data={classes?.items || []}
                    handleClickSort={handleOnClickSort}
                />
            </Content>

            <Pagination
                totalOfPages={`${classes?.totalPages}`}
                currentPage={`${classes?.currentPage}`}
                callbackGetListData={load}
            />
        </Container>
    );
}

export default IndividualFrequencyHistory;