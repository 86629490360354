import React, { useContext, useReducer } from 'react';

import HeaderMainReducer, { Types as HeaderMainReducerTypes } from './HeaderMainReducer';

export interface IHeaderMainState {
  isShowSideBar: boolean;
  isShowNotifications: boolean;
}

const initialState: IHeaderMainState = {
  isShowSideBar: false,
  isShowNotifications: false,
};

export type IHeaderMainContextData = IHeaderMainState & {
  toggleSideBar: () => void;
  toggleNotifications: () => void;
}

const HeaderMainContext = React.createContext<IHeaderMainContextData>({} as IHeaderMainContextData);

const HeaderMainProvider: React.FC = ({ children }) => {
  const [headerMainState, dispatch] = useReducer(HeaderMainReducer, initialState);

  const toggleSideBar = () => {
    dispatch({ type: HeaderMainReducerTypes.TOGGLE_SIDEBAR, payload: !headerMainState.isShowSideBar });
  };

  const toggleNotifications = () => {
    dispatch({ type: HeaderMainReducerTypes.TOGGLE_NOTIFICATIONS, payload: !headerMainState.isShowNotifications });
  };

  return (
    <HeaderMainContext.Provider
      value={{
        ...headerMainState,
        toggleSideBar,
        toggleNotifications
      }}
    >
      {children}
    </HeaderMainContext.Provider>
  );
};

function useHeaderMain() {
  const context = useContext(HeaderMainContext);

  if (!context) {
    throw new Error('useHeaderMain must be used within a HeaderMainProvider');
  }

  return context;
}

export { HeaderMainProvider, useHeaderMain };
