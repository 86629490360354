
import { styled } from 'react-alicerce-components';

import headlightsConfig, { HeadlightType } from '@modules/projects/utils/headlightsConfig'

export const Container = styled.div``;

export const Wrapper = styled.div<{headlightType: HeadlightType}>`

  & + div {
    margin-top: 36px;
  }

  .radio-container {
    width: 100%;
    label {
      width: 100%;
      justify-content: flex-start;

      input + div {
        width: 2.5rem;
        height: 2.2rem;
        margin-right: .5rem;
        background-color: #E6EAF0;
      }

      input:checked + div {
        background-color: #BE3A3A;
        border: 6px solid #E6EAF0;
        outline: 2px solid #BE3A3A;
      }
      
      span {
        position: relative;
        width: 100%;
        height: 2.2rem;
        border-radius: 2.5rem;
        border: 1px solid #8F9BB3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: ${({ headlightType }) => headlightType === 'basic' ? '#2E3A59' : '#FFF' };
        font-weight: 700;
        background-color: ${({ headlightType }) => headlightsConfig[headlightType].color};
      }

      input:checked + div + span {
        filter: drop-shadow(0px 0px 4px ${({ headlightType }) => headlightsConfig[headlightType].color});
      }
    }
  }
`;