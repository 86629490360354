import { combineContext } from '@shared/utils/combineContext';

import { GlobalProvider } from '@shared/contexts/global/GlobalContext';
import { OccurrencesProvider } from '@modules/occurrences/context/OccurrencesContext';
import { ActivityLibraryProvider } from '@modules/activities-library/context/ActivityContext';
import { ActivityLibraryProgramProvider } from '@modules/activities-library/context/ProgramContext';
import { ListControllerProvider } from '@modules/activities-library/context/AdvancedFilterControllerContext';
import { ClassProvider } from '../../../modules/classes/context/ClassContext';
import { ScheduleGridProvider } from '../../../modules/class-planner/context/ScheduleGridContext';

const providers = [
  GlobalProvider,
  ActivityLibraryProvider,
  ActivityLibraryProgramProvider,
  ListControllerProvider,
  OccurrencesProvider,
  ClassProvider,
  ScheduleGridProvider
];

export const AppProvider = combineContext(...providers);
