import React, { useState, useCallback, useEffect, Fragment } from 'react';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';
import { Input, Pagination, Spinner } from 'react-alicerce-components';

import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';

import WrapperContent from '@modules/schedules/components/WrapperContent';
import ScheduleEventCard from '@modules/schedules/components/ScheduleEventCard';
import ListSchedulesService, { IScheduleReponse } from '@modules/schedules/services/ListSchedulesService';
import { ISchedule } from '@modules/schedules/interfaces/ISchedule';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const LocalScheduleSelect: React.FC = () => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const { state, setScheduleCurrent } = useSchedules();

  const [schedules, setSchedules] = useState<IScheduleReponse>();

  const listSchedules = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new ListSchedulesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
        [`${state?.resource}`]: state?.id as string,
      });

      if (res === 'canceling') return;
      setIsLoading(false);

      if (!res) return;
      setSchedules(res);
    },
    [newCancelToken, setIsLoading, signedUser, state]
  );

  const handleSetSchedule = useCallback(
    (schedule: ISchedule) => {
      setScheduleCurrent(schedule);
      navigate(`/tarefas/criar`, { state: state });
    },
    [navigate, setScheduleCurrent, state]
  );

  useEffect(() => {
    listSchedules();
  }, [listSchedules]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Evento" backgroundHeader="linear-gradient(105.34deg, #6E3FA5 1.9%, #542E91 98.48%);" />

      <WrapperContent customStyle={{ padding: '40px 20px 20px 20px' }}>
        <div style={{ marginBottom: '2rem' }}>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => listSchedules(event.target.value)}
          />
        </div>

        {schedules?.items.map((schedule, i) => (
          <ScheduleEventCard key={i} schedule={schedule} handleOnClick={() => handleSetSchedule(schedule)} status="quaternary" statusNumber={400} />
        ))}

        {!schedules?.items.length && <p>Nenhuma agenda para mostrar...</p>}

        {!!schedules?.items.length && schedules?.totalPages > 1 && (
          <Pagination
            totalOfPages={`${schedules?.totalPages}`}
            currentPage={`${schedules?.currentPage}`}
            callbackGetListData={listSchedules}
            status="quaternary"
          />
        )}
      </WrapperContent>
    </Fragment>
  );
};

export default LocalScheduleSelect;
