import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import GetCurrentSprintService from '@modules/sprint/services/GetCurrentSprintService';

import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { getDay } from '@shared/utils/getDay';
import { Textarea } from 'react-alicerce-components';
import Divider from '@shared/components/Divider';

const ClassPlannerSprintList: React.FC = () => {
  const { classId } = useParams();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const [classSprint, setClassSprint] = useState<ISprint>();

  const getCurrentSprint = useCallback(async () => {
    setIsLoading(true);

    const res = await new GetCurrentSprintService(signedUser?.token).execute({class_id: Number(classId)});
    if (!res) return;
    setClassSprint(res);

    setIsLoading(false);
  }, [classId, setIsLoading, signedUser]);

  const getPlannedSprint = useMemo(() => {
    if (classSprint && classSprint.planned_sprints && classSprint.planned_sprints[0]) {
      return (
        <Fragment>
          <div style={{ display: 'flex', justifyContent: 'center' }}>{`${getDay(classSprint?.start_date)} - ${getDay(classSprint?.end_date)}`}</div>
          <Divider width="100%" />
          {classSprint.planned_sprints[0].sprint_plan?.map((sp) => (
            <Textarea key={sp.question} rows={4} name={sp.question || ''} label={sp.question} value={sp.answer} />
          ))}
        </Fragment>
      );
    } else {
      return <div style={{ marginTop: '2rem' }}>Não há nenhum Plano de Ensino para sprint atual.</div>;
    }
  }, [classSprint]);

  useEffect(() => {
    getCurrentSprint();
  }, [getCurrentSprint]);

  return <Fragment>{!classSprint ? 'Plano de Ensino não encontrado.' : <Fragment>{getPlannedSprint}</Fragment>}</Fragment>;
};

export { ClassPlannerSprintList };
