import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .label-container {
    display: flex;
    align-items: center;
    padding: 20px;
    color: #2e3a59;
    font-family: var(--secondary-font);
    font-weight: 700;
    text-transform: initial;
    letter-spacing: 0;
    font-size: 13px;

    .button-add {
      margin-right: 18px;

      i {
        width: 32px;
        height: 32px;
      }
    }
  }

`;

export const HourContainer = styled.div`
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 5%;

    .input-container input {
        display: flex;
        width: 100%;
    }
`;

export const ConcluderContainer = styled.div`
  .custom-card {
    background-color: #F7F9FC;
    margin: 1rem 0 0 0;
    padding: 12px;
    border: 2px solid #E4E9F2;

    > div {
      margin: 0;
    }
  }

`;


export const ScheduleContainer = styled.div`

`;