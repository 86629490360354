import React, { useCallback, useEffect, useState } from 'react';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';
import { ListDailyClassesService } from '@modules/attendances/services/ListDailyClassesService';
import { TodayClassCard } from '@modules/attendances/components/TodayClassCard';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { Container } from './styles';
import { Label } from 'react-alicerce-components';

interface IDailyClassList {
  selectedDailyClass?: IDailyClass;
  classId: string | number;
  day: string;
}

export const DailyClassList: React.FC<IDailyClassList> = ({ selectedDailyClass, classId, day }) => {
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { setIsLoading } = useGlobal();
  const [dailyClasses, setDailyClasses] = useState<IDailyClass[]>();
  const { setOccurrenceCurrent, occurrenceCurrent } = useOccurrences();

  const listDailyClasses = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListDailyClassesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 9999,
        class_id: classId,
        date: day,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return;
      setDailyClasses(res.items);
    },
    [classId, day, newCancelToken, setIsLoading, signedUser]
  );

  const handleAddRelationGroup = useCallback(
    (dailyClass: IDailyClass | undefined) => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, daily_class: dailyClass };
      });
    },
    [setOccurrenceCurrent]
  );

  const handleClickCard = useCallback(
    async (dailyClassClicked: IDailyClass) => {
      if (occurrenceCurrent?.daily_class?.id === dailyClassClicked.id) return handleAddRelationGroup(undefined);
      handleAddRelationGroup(dailyClassClicked);
    },
    [handleAddRelationGroup, occurrenceCurrent]
  );

  useEffect(() => {
    listDailyClasses();
  }, [listDailyClasses]);

  return (
    <>
      {!!dailyClasses?.length && (
        <Container>
          <Label>Aula Envolvida:</Label>
          <div className="container-daily-class">
            {dailyClasses.map((dc: IDailyClass) => (
              <TodayClassCard
                todayClass={dc}
                key={dc.id}
                handleOnClick={() => handleClickCard(dc)}
                canSelect={true}
                todayClassesSelected={selectedDailyClass}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};
