import themeGet from '@styled-system/theme-get';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import styled from 'styled-components';

export const Container = styled.div<{ status?: ElementStatus; canSelect?: boolean; containFooter?: boolean; statusNumber?: number }>`
  position: relative;
  display: flex;

  label {
    margin-top: 1.8rem;
  }

  .custom-card {
    width: ${({ canSelect }) => (canSelect ? '90%' : '100%')};
    margin: 0;
  }

  .card-header {
    height: 30px;
    padding: 0.2rem;

    h6 {
      text-align: left;
    }
  }

  .card-body {
    padding: 20px;
  }

  .card-footer {
    display: none;
  }

  .checkBoxExternal {
    > div label {
      margin-top: 1.8rem;
      input + div {
        width: 30px;
        height: 30px;
      }
    }
  }

  ${(props) =>
    props.containFooter &&
    `
       .card-footer {
            display: initial;
            padding: 6px 10px;
            background-color: ${themeGet(`colors.${props.status}${props.statusNumber}`)(props)};
        }
    `}
`;

export const Date = styled.div`
  position: absolute;
  bottom: -1px;
  left: -1px;
`;

export const Header = styled.div<{ status?: ElementStatus; statusNumber?: number }>`
  ${Date} {
    background-color: ${({ status, statusNumber }) => themeGet(`colors.${status}${Number.parseInt(`${statusNumber}`) - 100}`)};
    color: #fff;
    font-size: 11px;
    font-weight: 800;
    height: 40px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px 0 0;
    line-height: 14px;
  }

  .schedule-name {
    text-align: center;
    margin-left: 75px;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .tag {
    border-radius: 4px;
    margin: 2.5px;
  }
`;
