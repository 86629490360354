import { styled } from 'react-alicerce-components';

export const CardDirectoryContentContainer = styled.div`
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  width:fit-content;

  .content {
    padding: 0px 5px;
    margin-bottom:2px;

    span {
      font-size: 13px;
      text-transform: capitalize;
    }
    .content-bold {
      font-weight: 700;
    }
  }

  div:last-child {
    border-right: none;
  }
`;
