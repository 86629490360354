import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, H6, Input, P, Pagination } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Icon from 'react-eva-icons';

import { IWorkedHourReponse, ListWorkedHoursService } from '@modules/worked-hours/services/ListWorkedHoursService';
import { ContainerWorkedHours, InputDateWorkedHour } from '@modules/worked-hours/components/Layout/styles';
import { GetTotalWorkedHourService } from '@modules/worked-hours/services/GetTotalWorkedHourService';
import WorkedHoursCard from '@modules/worked-hours/components/WorkedHoursCard';

import WorkedHoursAdvancedFilter from '../../components/WorkedHoursAdvancedFilter';
import { formatWorkedHoursFilter } from '../../utils/formatWorkedHoursFilter';
import { LayoutApproved } from '../../components/LayoutApproved';
import { useWorkedHour } from '../../context/WorkedHourContext';

import { convertMinutesToHour } from '@shared/helpers/DayHourHelper';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { datePickerFormat } from '@shared/utils/datePickerFormat';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';

export const WorkedHoursPendingApproval: React.FC = () => {
  const { setIsLoading, isLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [workedHoursApproved, setWorkedHoursApproved] = useState<IWorkedHourReponse>();
  const [searchMonthly, setSearchMonthly] = useState<boolean>(false);
  const [totalHour, setTotalHour] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const { id } = useParams();

  const { workedHoursFilter, handleResetFilterMonthly, date, setDate } = useWorkedHour();

  const listWorkedHoursApproved = useCallback(
    async (page: number = 0) => {
      if (!signedUser) return;
      const workedHourFilter = workedHoursFilter.current;
      const formattedFilter = workedHourFilter ? formatWorkedHoursFilter(workedHourFilter) : {};
      setIsLoading(true);
      const res = await new ListWorkedHoursService(signedUser.token, newCancelToken()).execute({
        ...formattedFilter,
        query: search,
        page,
        size: 10,
        status: ['pending'],
        group_id: id,
        date: date,
      });
      if (res === 'canceling') return;
      setIsLoading(false);

      if (res && isMounted()) {
        setWorkedHoursApproved(res);
      }
    },
    [date, id, isMounted, newCancelToken, search, setIsLoading, signedUser, workedHoursFilter]
  );

  const handleOnChangeInputDate = (newValue: string, keyName: string, objDateKey: string) => {
    const value = !!newValue.length ? newValue : undefined;
    setSearchMonthly(true);
    setDate((oldState: any) => {
      return { ...oldState, [objDateKey]: value };
    });
  };

  const getTotalWorkedHour = useCallback(async () => {
    if (!signedUser) return;
    const workedHourFilter = workedHoursFilter.current;
    const formattedFilter: any = workedHourFilter ? formatWorkedHoursFilter(workedHourFilter) : {};
    const { completed_at, group_id, date: dateFilter, ...rest } = formattedFilter;
    const resTotalHour = await new GetTotalWorkedHourService(signedUser.token).execute({
      ...rest,
      status: ['pending'],
      date: date,
      group_id: id,
    });
    if (resTotalHour && isMounted()) {
      setTotalHour(convertMinutesToHour(resTotalHour.worked_minutes_sum));
    }
  }, [date, id, isMounted, signedUser, workedHoursFilter]);

  const resetMonthly = useCallback(() => {
    handleResetFilterMonthly();
    setSearchMonthly(false);
  }, [handleResetFilterMonthly]);

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      await Promise.all([listWorkedHoursApproved(), getTotalWorkedHour()]);
      setIsLoading(false);
    }

    load();
  }, [getTotalWorkedHour, listWorkedHoursApproved, setIsLoading]);

  const formatedTotalHour = useMemo(() => {
    return totalHour ? `${totalHour}h` : undefined;
  }, [totalHour]);

  const WorkedHoursList = () => {
    const hasItems = workedHoursApproved?.items && workedHoursApproved.items.length > 0;

    return (
      <>
        {!hasItems && !isLoading && (
          <P textAlign="center" marginTop="3rem">
            Nenhum dado para exibir
          </P>
        )}
        {hasItems && !isLoading && (
          <>
            <div className="container-all-workedHours-cards">
              {workedHoursApproved.items.map((worked, index) => (
                <WorkedHoursCard key={index} workedHour={worked} handleOnClick={() => navigate(`/horas-trabalhadas/${worked.id}/aprovar`)} showName />
              ))}
            </div>
            <Pagination
              totalOfPages={workedHoursApproved.totalPages}
              currentPage={workedHoursApproved.currentPage}
              callbackGetListData={(_, page) => listWorkedHoursApproved(page)}
              status="basic"
            />
          </>
        )}
      </>
    );
  };

  const TotalHours = () => {
    return (
      <div className="container-total-hours">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Total de Horas Pendentes de</label>
          <p>{`${datePickerFormat(date.start, '/', '-') || '-'} até ${datePickerFormat(date.end, '/', '-') || '-'}`}</p>
        </div>
        <Input placeholder="00h" disabled name="totalHours" defaultValue={formatedTotalHour} end={<Icon name="clock" fill="#8F9BB3" />} />
      </div>
    );
  };

  return (
    <LayoutApproved openTab={0}>
      <WorkedHoursAdvancedFilter
        onChangeMainInputCallback={(newValue) => setSearch(newValue)}
        status="pending"
        handleOnClickApplyFilterCallback={() => listWorkedHoursApproved(undefined)}
      />

      <ContainerWorkedHours>
        <H6 fontWeight={800} className="h6-worked-hour" color="#2E3A59" fontSize={'16px'}>
          Período de:
        </H6>
        <InputDateWorkedHour>
          <input
            type="date"
            value={date?.start || ''}
            name="date"
            alt={`${searchMonthly}`}
            onChange={(event) => handleOnChangeInputDate(event.target.value, event.target.name, 'start')}
          />
          <label>até</label>
          <input
            type="date"
            value={date?.end || ''}
            name="date"
            alt={`${searchMonthly}`}
            onChange={(event) => handleOnChangeInputDate(event.target.value, event.target.name, 'end')}
          />
        </InputDateWorkedHour>
        <Divider width="100%" />

        <div className="btn-worked-hours">
          <Button
            transform="none"
            status="primary"
            statusNumber={400}
            shaded
            onClick={!searchMonthly ? () => listWorkedHoursApproved(undefined) : resetMonthly}
          >
            {!searchMonthly ? 'Aplicar filtros' : 'Resetar filtro'}
          </Button>
        </div>
      </ContainerWorkedHours>

      <TotalHours />

      <WorkedHoursList />
    </LayoutApproved>
  );
};
