import React from 'react';
import { Input, Textarea } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import InputPill from '@shared/components/InputPill';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';

import { useWorkGroup } from '@modules/work-group/context/WorkGroupContext';
import StudentCard from '@modules/students/components/StudentCard';
import { WorkGroupFormContainer } from '../WorkGroupForm/styles';

const WorkGroupInfoComponent: React.FC = () => {
  const { workGroupCurrent } = useWorkGroup();

  return (
    <WorkGroupFormContainer>
      <CustomForm>
        <Input label="Nome do Grupo:" defaultValue={workGroupCurrent ? workGroupCurrent.name : undefined} name="name" disabled />

        <InputPill label="Blocos" inputPillData={workGroupCurrent?.blocks?.map((block) => block.name)} color="#C8FBF2" />

        <Textarea
          name="description"
          label="Descrição do Grupo:"
          value={workGroupCurrent ? workGroupCurrent.description : undefined}
          rows={4}
          disabled
        />

        <AccordionInfoItem colorHeader="#DAE3F6" title={`Membros (${workGroupCurrent ? workGroupCurrent.students?.length : 0})`}>
          {workGroupCurrent && workGroupCurrent.students?.map((student, index) => <StudentCard student={student} key={student.id} />)}
        </AccordionInfoItem>
      </CustomForm>
    </WorkGroupFormContainer>
  );
};

export { WorkGroupInfoComponent };
