import { ITrail } from "@shared/interfaces/ITrail";


interface ITrailDesignInfo {
    color: string,
    bgColor: string,
    img: string,
}

export const getTrailDesignInfo = (trail: ITrail): ITrailDesignInfo => {

    if(trail.name === 'Matemática') {
        return {
            color: '#002E6D',
            bgColor: 'linear-gradient(45deg, #BE3A3A 0%, #F7652B 100%);',
            img: require('@shared/assets/animais/eagle.svg')
        }
    }

    if(trail.name === 'Leitura') {
        return {
            color: '#002E6D',
            bgColor: 'linear-gradient(45deg, #FF9900 0%, #FFCA2B 100%);',
            img: require('@shared/assets/animais/owl.svg')
        }
    }

    if(trail.name === 'Escrita') {
        return {
            color: '#F8A356',
            bgColor: 'linear-gradient(105.34deg, #6E3FA5 1.9%, #542E91 98.48%);',
            img: require('@shared/assets/animais/toucan.svg')
        }
    }

    if(trail.name === 'Inglês') {
        return {
            color: '#FFBC6F',
            bgColor: 'linear-gradient(45deg, #8D593B 0%, #AF8B6B 100%);',
            img: require('@shared/assets/animais/parrot.svg')
        }
    }

    if(trail.name === 'Descoberta') {
        return {
            color: '#00215A',
            bgColor: 'linear-gradient(45deg, #328DA1 0%, #25BBAB 100%);',
            img: require('@shared/assets/animais/penguin.svg')
        }
    }


    if(trail.name === 'Habilidades para Vida') {
        return {
            color: '#F47920',
            bgColor: 'linear-gradient(45deg, #282674 0%, #145496 100%);',
            img: require('@shared/assets/animais/bird.svg')
        }
    }

    return {
        color: '#002E6D',
        bgColor: 'linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%);',
        img: require('@shared/assets/capivaras/aluno.svg')
    }
};