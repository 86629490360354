import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import AttendanceRetroactiveForm from '@modules/attendances/components/AttendanceRetroactiveForm';

import { Container } from './styles';
import { IGenericLocationState } from '@shared/interfaces/ILocationState';

export const AttendancesCreateRetroactive: React.FC = () => {
  const navigate = useNavigate();
  const currentClassInPath = useParams<{ id: string }>();
  const { isLoading } = useGlobal();

  const handleGoBack = useCallback(() => {
    const stateData = {
      pathToReturn: '/presencas/contexto',
    } as IGenericLocationState;

    navigate(`/presencas/${currentClassInPath.id}/criar`, { state: stateData });
  }, [currentClassInPath.id, navigate]);

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Presença" onClickReturn={handleGoBack} backgroundHeader="linear-gradient(45deg, #482B85 0%, #6749BA 100%)" />

      <DirectoryContainer footer style={{ margin: 0 }}>
        <AttendanceRetroactiveForm />
      </DirectoryContainer>
    </Container>
  );
};
