import { ISelectOption } from 'react-alicerce-components';

//MOTIVOS DE DESENTURMAMENTO E DESLIGAMENTO
export const DISCONNECT_REASON_STUDENT_REQUEST = { name: 'Pedido da Família/ Alunos',value: 'student_request' }; //Pedido do Aluno/Familia

export const DISCONNECT_REASON_PARTNER_REQUEST = { name: 'Pedido do Parceiro', value: 'partner_request' }; //Pedido do Parceiro

export const DISCONNECT_REASON_ALICERCE_REQUEST = { name: 'Pedido do Alicerce', value: 'alicerce_request'}; //Pedido do Alicerce

export const DISCONNECT_REASON_INADIMPLENCE = { name: 'Inadimplência',value: 'inadimplence'}; //Inadimplência

export const DISCONNECT_REASON_PROJECT_FINISHED = { name: 'Término de Projeto', value: 'project_finished' }; //Término de Projeto

export const DISCONNECT_REASON_UNIT_CLOSURE = { name: 'Fechamento da Unidade', value: 'unit_closure' }; //Fechamento da Unidade

export const DISCONNECT_REASON_STUDENT_CONSTRAINTS = [
  DISCONNECT_REASON_STUDENT_REQUEST,
  DISCONNECT_REASON_PARTNER_REQUEST,
  DISCONNECT_REASON_ALICERCE_REQUEST,
  DISCONNECT_REASON_INADIMPLENCE,
  DISCONNECT_REASON_PROJECT_FINISHED,
  DISCONNECT_REASON_UNIT_CLOSURE,
];

//DETALHES DE MOTIVOS DE DESLIGAMENTOS DE PEDIDO DO INSTRUTOR
export const STUDENT_REQUEST_DETAIL_NEW_SHIFT = { name: 'Turno', value: 'new_shift', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_NEW_JOB = { name: 'Novo Emprego', value: 'new_job', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_PERSONAL = { name: 'Problemas Pessoais', value: 'personal', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_HEALTH = { name: 'Saúde', value: 'health', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_DO_NOT_LIKED = { name: 'Não gostou do Projeto', value: 'do_not_liked', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_DISTANCE = { name: 'Distância', value: 'distance', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_NEW_SCHOOL_SHIFT = { name: 'Turno da Escola Mudou', value: 'new_school_shift', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_NEW_COURSE = { name: 'Entrou em Outro Curso ou Atividade Esportiva', value: 'new_course', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_NEW_CITY = { name: 'Mudou de Cidade', value: 'new_city', category: 'student_request' };
export const STUDENT_REQUEST_DETAIL_INFREQUENCY = { name: 'Infrequência', value: 'infrequency', category: 'student_request' };

export const DISCONNECT_REASON_STUDENT_REQUEST_DETAIL_CONSTRAINTS = [
  STUDENT_REQUEST_DETAIL_NEW_SHIFT,
  STUDENT_REQUEST_DETAIL_NEW_JOB,
  STUDENT_REQUEST_DETAIL_PERSONAL,
  STUDENT_REQUEST_DETAIL_HEALTH,
  STUDENT_REQUEST_DETAIL_DO_NOT_LIKED,
  STUDENT_REQUEST_DETAIL_DISTANCE,
  STUDENT_REQUEST_DETAIL_NEW_SCHOOL_SHIFT,
  STUDENT_REQUEST_DETAIL_NEW_COURSE,
  STUDENT_REQUEST_DETAIL_NEW_CITY,
  STUDENT_REQUEST_DETAIL_INFREQUENCY,
];

//DETALHES DE MOTIVOS DE DESLIGAMENTOS DE PEDIDO DO PARCEIRO
export const DISCONNECT_PARTNER_REQUEST_NEW_JOB = { name: 'Novo Emprego', value: 'new_job', category: 'partner_request' };

export const DISCONNECT_PARTNER_REQUEST_DETAIL_CONSTRAINTS = [
  DISCONNECT_PARTNER_REQUEST_NEW_JOB,
];

//DETALHES DE MOTIVOS DE DESLIGAMENTOS DE PEDIDO DO ALICERCE
export const ALICERCE_REQUEST_INFREQUENCY = { name: 'Infrequência', value: 'infrequency', category: 'alicerce_request' };
export const ALICERCE_REQUEST_DISCIPLINARY_COMMITTEE = { name: 'Comitê Disciplinar', value: 'disciplinary_committee', category: 'alicerce_request' };	

export const DISCONNECT_REASON_ALICERCE_REQUEST_DETAIL_CONSTRAINTS = [
  ALICERCE_REQUEST_INFREQUENCY,
  ALICERCE_REQUEST_DISCIPLINARY_COMMITTEE,
];

const optionsDetails = [
  ...DISCONNECT_REASON_STUDENT_REQUEST_DETAIL_CONSTRAINTS,
  ...DISCONNECT_REASON_ALICERCE_REQUEST_DETAIL_CONSTRAINTS,
  ...DISCONNECT_PARTNER_REQUEST_DETAIL_CONSTRAINTS
];

export const defaultStudentDisconnectReasons = (disconnectStudentReason?: any): ISelectOption[] | undefined => {
  return DISCONNECT_REASON_STUDENT_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: disconnectStudentReason === foo.value };
  });
};

export const defaultStudentDisconnectDetails = (disconnectStudentReason?: string, disconnectStudentReasonDetails?: string | null): ISelectOption[] | undefined => {
  return optionsDetails.filter((option) => option.category === disconnectStudentReason).map((foo) => ({
    name: foo.name,
    value: foo.value,
    selected: disconnectStudentReasonDetails === foo.value,
  }));
};
