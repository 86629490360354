import React from 'react';
import { Clickable } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useNavigate } from 'react-router-dom';

interface INavbarIconLink {
  iconName: string;
  iconFill?: string;
  to?: string | (() => void);
  callback?: () => void;
}

const NavbarIconLink: React.FC<INavbarIconLink> = ({ iconName, to, iconFill = 'black' }) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    if (!to) {
      navigate(-1);
      return;
    }

    if (typeof to == 'string') {
      navigate(to);
    } else {
      to();
    }
  };

  return (
    <Clickable onClick={clickHandler}>
      <Icon name={iconName} size="large" fill={iconFill} />
    </Clickable>
  );
};

export default NavbarIconLink;
