import { DateHelper } from '../../../shared/helpers/DateHelper';
import { convertStringTimeHourToMinutes } from '../../../shared/helpers/DayHourHelper';
import { IWorkedHourFilter } from '../context/WorkedHourContext';

export interface IWorkedHourFilterFormatted {
  total_minutes?: number;
  group_id?: string;
  category_tag_id?: string;
  modality_tag_id?: string;
  project_tag_id?: string;
  date?: {
    start?: string;
    end?: string;
  };
  completed_at?: {
    start?: Date;
    end?: Date;
  };
}

export const formatWorkedHoursFilter = (filterData: IWorkedHourFilter) => {
  return {
    total_minutes: !filterData.total_hours ? undefined : convertStringTimeHourToMinutes(filterData.total_hours),
    group_id: filterData.group?.id,
    category_tag_id: filterData.category?.id,
    modality_tag_id: filterData.modality?.id,
    project_tag_id: filterData.project ? filterData.project?.id : filterData.project,
    date: {
      start: !filterData?.date?.start ? undefined : DateHelper.getUTCDate(new Date(`${filterData?.date?.start}`)),
      end: !filterData?.date?.end ? undefined : DateHelper.getUTCDate(new Date(`${filterData?.date?.end}`)),
    },
    completed_at: {
      start: !filterData?.completed_at?.start ? undefined : DateHelper.getUTCDate(new Date(`${filterData?.completed_at?.start}`)),
      end: !filterData?.completed_at?.end ? undefined : DateHelper.getUTCDate(new Date(`${filterData?.completed_at?.end}`)),
    },
  };
};
