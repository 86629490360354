import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 4rem;
`;

export const Header = styled.div`
  background: #6749ba;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem;
  flex-wrap: wrap;

  > div {
    > label {
      > input:checked + div {
        background-color: #9da5b5;
      }
    }
  }

  label {
    font-weight: 700;
    font-size: 12px;
    color: #f7f9fc;
    font-family: var(--secondary-font);
  }

  input + div {
    background: rgba(143, 155, 179, 0.48);
    border: 1px solid #c5cee0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    margin-right: 1rem;
  }
`;

export const Body = styled.div`
  min-height: 80px;
  padding: 1rem;
  font-size: 14px;
  font-weight: 800;
  color: #222b45;
  background: #e9d7f9;
  display: flex;
  align-items: center;
`;

export const Status = styled.div`
  background: #6749ba;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 700;
  color: #f7f9fc;
  min-height: 50px;
`;

export const StatusContainer = styled.div`
  padding: 1rem;
  background: #e9d7f9;
`;

export const StatusItem = styled.div`
  min-height: 40px;
  padding: 1rem 0;

  & + div {
    border-top: 2px solid #c5cee0;
  }
`;

export const TrailName = styled.div`
  font-size: 12px;
  font-weight: 700;
  font-family: var(--secondary-font);
  color: #2e3a59;
`;

export const StatusInfo = styled.div`
  display: flex;
`;

export const StatusColor = styled.div<{ color: string; status: string }>`
  width: calc(40% - 2px);
  border-right: 2px solid #c5cee0;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 3.5rem;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: ${({ color }) => color};
  }

  &:after {
    content: '${({ status }) => status}';
    margin-left: 30px;
    font-size: 12px;
    font-weight: 700;
    color: #2e3a59;
  }
`;

export const When = styled.div`
  gap: 0.5rem;
  display: flex;
  width: 60%;
  font-size: 10px;
  font-weight: 700;
  color: #2e3a59;
  text-align: right;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const Footer = styled.div`
  background: #6749ba;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`;
