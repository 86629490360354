import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityProgramHomeListContainer } from './styles';
import { Spinner } from 'react-alicerce-components';

import { ListActivityLibrariesService } from '@modules/activities-library/services/ListActivityLibrariesService';
import { IActivityFilterFormatted, useActivity } from '@modules/activities-library/context/ActivityContext';
import { ActivityList } from '@modules/activities-library/components/ActivityList';
import { ProgramList } from '@modules/activities-library/components/ProgramList';
import { ListProgramsService } from '@modules/activities-library/services/ListProgramsService';
import { useAdvancedFilterController } from '@modules/activities-library/context/AdvancedFilterControllerContext';
import { IProgramFilterFormatted, useProgram } from '@modules/activities-library/context/ProgramContext';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';

type IActivityLibraryList = {
  listFavorites?: boolean;
  pathToClickButtonActivity: string;
  pathClickCardActivity: string;
  activityTextButton: string;
  pathToClickButtonProgram: string;
  pathClickCardProgram: string;
  programTextButton: string;
  filterByWorkgroup?: boolean;
};

const ActivityProgramList: React.FC<IActivityLibraryList> = (props) => {
  const { openTab, setOpenTab, setIsAdvancedFilterOpen } = useAdvancedFilterController();
  const { clearProgramContextData } = useProgram()
  const { clearActivityLibraryContextData } = useActivity();
  const [selectedTab, setSelectedTab] = useState<string>(openTab ? openTab : 'activity');
  const { setIsLoading, isLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();

  const listActivities = useCallback(
    async (search: string = '', page: number = 0, fomattedFilter: IActivityFilterFormatted = {}) => {
      setIsLoading(true);
      const res = await new ListActivityLibrariesService(signedUser?.token, newCancelToken()).execute(search, {
        size: 10,
        page,
        ...fomattedFilter,
        favorited: props.listFavorites || undefined,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res || !isMounted()) return;
      return res;
    },
    [isMounted, newCancelToken, props.listFavorites, setIsLoading, signedUser]
  );

  const listPrograms = useCallback(
    async (searchInput: string = '', page: number = 0, filteredPrograms: IProgramFilterFormatted = {}) => {
      setIsLoading(true);
      const res = await new ListProgramsService(signedUser?.token, newCancelToken()).execute(searchInput, {
        page,
        size: 10,
        ...filteredPrograms,
        favorited: props.listFavorites || undefined,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res || !isMounted()) return;
      return res;
    },
    [isMounted, newCancelToken, props.listFavorites, setIsLoading, signedUser]
  );

  useEffect(() => {
    clearActivityLibraryContextData()
    clearProgramContextData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const renderedComponent = useMemo(() => {
    if (selectedTab === 'activity') {
      return (
        <ActivityList
          filterByWorkGroup={props.filterByWorkgroup}
          listActivitiesCallback={(search, page, formattedFilter) => listActivities(search, page, formattedFilter)}
          pathOnClickCard={props.pathClickCardActivity}
          pathOnClickButton={props.pathToClickButtonActivity}
          buttonText={props.activityTextButton}
        />
      );
    } else if (selectedTab === 'program') {
      return (
        <ProgramList
          listProgramsCallback={(search, page, filteredActivities) => listPrograms(search, page, filteredActivities)}
          pathOnClickCard={props.pathClickCardProgram}
          pathOnClickButton={props.pathToClickButtonProgram}
          buttonText={props.programTextButton}
          filterByWorkGroup={props.filterByWorkgroup}
        />
      );
    }
  }, [
    listActivities,
    listPrograms,
    props.activityTextButton,
    props.filterByWorkgroup,
    props.pathClickCardActivity,
    props.pathClickCardProgram,
    props.pathToClickButtonActivity,
    props.pathToClickButtonProgram,
    props.programTextButton,
    selectedTab,
  ]);

  const handleChangeTab = useCallback(
    (tabToRender: 'activity' | 'program') => {
      setOpenTab(undefined);
      setIsAdvancedFilterOpen(false);
      setSelectedTab(tabToRender);
    },
    [setIsAdvancedFilterOpen, setOpenTab]
  );

  return (
    <ActivityProgramHomeListContainer>
      {isLoading && <Spinner fixed />}
      <div className="activity-picker">
        <div className="activity-picker-container">
          <div onClick={() => handleChangeTab('activity')} className={`activity-tab activity ${selectedTab === 'activity' ? 'selected' : ''}`}>
            Atividade
          </div>
          <div onClick={() => handleChangeTab('program')} className={`activity-tab program ${selectedTab === 'program' ? 'selected' : ''}`}>
            Programas
          </div>
        </div>
      </div>

      {renderedComponent}
    </ActivityProgramHomeListContainer>
  );
};

export { ActivityProgramList };
