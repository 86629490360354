import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import HeaderMain from '@shared/components/Headers/HeaderMain';
import { useStudent } from '@modules/students/context/StudentContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import { Container } from './styles';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer = false }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const { studentCurrent, getStudent } = useStudent();
  const { isLoading } = useGlobal();
  const studentInPath = useParams<{ id: string }>();

  const getStudentFromContext = useCallback(async () => {
    if (!studentInPath.id) return;
    const res = await getStudent(studentInPath.id);
    if (!res) navigate('/diretorio/alunos');
  }, [getStudent, navigate, studentInPath.id]);

  useEffect(() => {
    getStudentFromContext();
  }, [getStudentFromContext]);

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderMain
        useDefaultNav
        bgColor="linear-gradient(90deg,#f38331 0%,#2487c7 98.12%)"
        type={`ALUNO | ID: #${studentCurrent.name ? studentCurrent.id : 'Carregando...'}`}
        title="Você está vendo o perfil de"
        subtitle={`${studentCurrent.name && studentCurrent.name.length > 0 ? studentCurrent.name : 'Carregando...'}`}
        isScrolled={isScrolled}
        compressOnScroll
        pathToReturnDefault
        menuKebab={{
          tooltip: { view: 'StudentInfo' },
          menuBackHome: true,
        }}
      />
      {studentCurrent.id && studentCurrent.responsibles_for_picking && (
        <CustomTabs footer={footer} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
          <Tabs openTab={openTab} content={children} horizontalScroll>
            <Tab title="Informações" onClick={() => navigate(`/aluno/${studentCurrent.id}/informacoes`)} />
            <Tab title="Histórico" onClick={() => navigate(`/aluno/${studentCurrent.id}/historico`)} />
            <Tab title="Relatórios" onClick={() => navigate(`/aluno/${studentCurrent.id}/relatorios`)} />
          </Tabs>
        </CustomTabs>
      )}
    </Container>
  );
};

export default Layout;
