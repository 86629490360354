import React from 'react';
import GroupCard from '@modules/groups/components/GroupCard';
import { IGroupReponse } from '@modules/groups/services/ListGroupsService';
import { Pagination } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';

interface IGroupRenderProps {
  groups: IGroupReponse | undefined;
  filterGroup: (search: string, page?: any) => Promise<void>;
}

const GroupRender: React.FC<IGroupRenderProps> = ({ groups, filterGroup }) => {
  const navigate = useNavigate();

  return (
    <div>
      {groups?.items.map((g) => (
        <GroupCard group={g} key={g.id} handleOnClick={() => navigate(`/grupo/${g.id}`, { state: { pathToReturn: '/' } })} />
      ))}
      {!!groups?.items && (
        <Pagination totalOfPages={`${groups?.totalPages}`} currentPage={`${groups?.currentPage}`} callbackGetListData={filterGroup} />
      )}
    </div>
  );
};

export default GroupRender;
