import { ISelectOption } from 'react-alicerce-components';

//MOTIVOS DE DESENTURMAMENTO
export const UNENROLLMENT_REASON_TEACHER_REQUEST = { name: 'Pedido do Instrutor', value: 'teacher_request' }; //Pedido do Instrutor
export const UNENROLLMENT_REASON_ALICERCE_REQUEST = { name: 'Pedido do Alicerce', value: 'alicerce_request' }; //Pedido do Alicerce
export const UNENROLLMENT_REASON_PROJECT_CLOSURE = { name: 'Encerramento do Projeto', value: 'project_closure' }; //Encerramento do Projeto
export const UNENROLLMENT_REASON_PROMOTED_TEACHER = { name: 'Instrutor Promovido', value: 'promoted_teacher' }; //Instutor Promovido

export const UNENROLLMENT_REASON_TEACHER_CONSTRAINTS = [
  UNENROLLMENT_REASON_TEACHER_REQUEST,
  UNENROLLMENT_REASON_ALICERCE_REQUEST,
  UNENROLLMENT_REASON_PROJECT_CLOSURE,
  UNENROLLMENT_REASON_PROMOTED_TEACHER,
];

//DETALHES DE MOTIVOS DE DESENTURMAMENTOS DE PEDIDO DO INSTRUTOR/LÍDER
export const TEACHER_REQUEST_DETAIL_DO_NOT_LIKED = { name: 'Não Gostou', value: 'do_not_liked', category: 'teacher_request'  };
export const TEACHER_REQUEST_DETAIL_PSYCHOLOGICAL = { name: 'Questões Psicológicas', value: 'psychological', category: 'teacher_request' };
export const TEACHER_REQUEST_DETAIL_CLASS_SCHEDULE = { name: 'Horário das aulas', value: 'class_schedule', category: 'teacher_request' };
export const TEACHER_REQUEST_DETAIL_DISTANCE = { name: 'Distância', value: 'distance', category: 'teacher_request' };
export const TEACHER_REQUEST_DETAIL_JOB_CHANGE = { name: 'Troca de Trabalho', value: 'job_change', category: 'teacher_request' };
export const TEACHER_REQUEST_DETAIL_HEALTH = { name: 'Saúde', value: 'health', category: 'teacher_request' };
export const TEACHER_REQUEST_DETAIL_PAYMENT = { name: 'Remuneração', value: 'payment', category: 'teacher_request' };
export const TEACHER_REQUEST_DETAIL_DISAGREES_METHODOLOGY = { name: 'Discorda da Metodologia', value: 'disagrees_methodology', category: 'teacher_request' };

export const UNENROLLMENT_REASON_TEACHER_REQUEST_DETAIL_CONSTRAINTS = [
  TEACHER_REQUEST_DETAIL_DO_NOT_LIKED,
  TEACHER_REQUEST_DETAIL_PSYCHOLOGICAL,
  TEACHER_REQUEST_DETAIL_CLASS_SCHEDULE,
  TEACHER_REQUEST_DETAIL_DISTANCE,
  TEACHER_REQUEST_DETAIL_JOB_CHANGE,
  TEACHER_REQUEST_DETAIL_HEALTH,
  TEACHER_REQUEST_DETAIL_PAYMENT,
  TEACHER_REQUEST_DETAIL_DISAGREES_METHODOLOGY,
];

//DETALHES DE MOTIVOS DE DESENTURMAMENTOS DE PEDIDO DO ALICERCE
export const ALICERCE_REQUEST_PERFORMANCE = { name: 'Desempenho', value: 'performance', category: 'alicerce_request' };
export const ALICERCE_REQUEST_PARTNER_REQUEST = { name: 'Pedido do Parceiro', value: 'partner_request', category: 'alicerce_request'}
export const ALICERCE_REQUEST_MOVAA_LEVEL = { name: 'Nível MOVAA', value: 'movaa_level', category: 'alicerce_request'}

export const UNENROLLMENT_REASON_ALICERCE_REQUEST_DETAIL_CONSTRAINTS = [
  ALICERCE_REQUEST_PERFORMANCE,
  ALICERCE_REQUEST_PARTNER_REQUEST,
  ALICERCE_REQUEST_MOVAA_LEVEL
]

const optionsDetails = [
  ...UNENROLLMENT_REASON_TEACHER_REQUEST_DETAIL_CONSTRAINTS,
  ...UNENROLLMENT_REASON_ALICERCE_REQUEST_DETAIL_CONSTRAINTS
];

export const defaultUnenrollmentReasons = (unenrollmentTeacherReason?: any): ISelectOption[] | undefined => {
  return UNENROLLMENT_REASON_TEACHER_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: unenrollmentTeacherReason === foo.value };
  });
};

export const defaultTeacherRequestDetails = (unenrollmentTeacherReason?: string, unnenrollmentTeacherReasonDetails?: string | null): ISelectOption[] | undefined => {
  return optionsDetails.filter((option) => option.category === unenrollmentTeacherReason).map((foo) => ({
    name: foo.name,
    value: foo.value,
    selected: unnenrollmentTeacherReasonDetails === foo.value,
  }))

};
