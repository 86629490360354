export const STATUS_ACTIVE = { name: 'Turma Ativa', value: 'active' };
export const STATUS_INACTIVE = { name: 'Turma Encerrada', value: 'inactive' };
export const STATUS_WAITING = { name: 'Turma em Espera', value: 'waiting' };
export const STATUS_PAUSED = { name: 'Turma em Pausa', value: 'paused' };
export const STATUS_ADAPTION = { name: 'Turma em Adaptação', value: 'adaption' };

export type IClassValidStatus = 'active' | 'inactive' | 'waiting' | 'paused' | 'adaption';

export const CLASS_STATUS_CONSTRAINTS = [STATUS_ACTIVE, STATUS_INACTIVE, STATUS_WAITING, STATUS_PAUSED, STATUS_ADAPTION];

export const defaultClassStatus = (classStatus?: string | null) => {
  return [
    { name: STATUS_ACTIVE.name, value: STATUS_ACTIVE.value, selected: classStatus === STATUS_ACTIVE.value },
    { name: STATUS_INACTIVE.name, value: STATUS_INACTIVE.value, selected: classStatus === STATUS_INACTIVE.value },
    { name: STATUS_WAITING.name, value: STATUS_WAITING.value, selected: classStatus === STATUS_WAITING.value },
    { name: STATUS_PAUSED.name, value: STATUS_PAUSED.value, selected: classStatus === STATUS_PAUSED.value },
    { name: STATUS_ADAPTION.name, value: STATUS_ADAPTION.value, selected: classStatus === STATUS_ADAPTION.value },
  ];
};
