import React, { useCallback, useState } from 'react';
import Icon from 'react-eva-icons';

import { ClassCardEnrollmentContainer } from './styles';
import { IStudentWithGrades } from '@modules/launchers/interfaces/IStudentsWithGrades';
import LearningTargetsStudentCardBorder from './LearningTargetsStudentCardBorder';
import { LearningTargetsStudentCardBody } from './LearningTargetsStudentCardBody';
import { LearningTargetsStudentCardLocation } from './LearningTargetsStudentCardLocation';
import LearningTargetsStudentCardStatus from './LearningTargetsStudentCardStatus';

interface ILearningTargetsStudentCardProps {
  studentData?: IStudentWithGrades;
  className?: string;
  trails?: boolean;
  listStudentFromClass: () => Promise<void>;
}

const LearningTargetsStudentCard: React.FC<ILearningTargetsStudentCardProps> = (props) => {
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [validClick, setValidClick] = useState<boolean | undefined>(props.studentData?.gradeInfo?.is_meta_block_validated);

  const classCardHeaderData = useCallback((studentData: any): string[] => {
    const cardHeaderData: string[] = [];
    if (!studentData.student.age_group) return cardHeaderData;
    const { age_group } = studentData.student;
    cardHeaderData.push(age_group!);
    return cardHeaderData;
  }, []);

  const handleClickOpenTrail = () => {
    setOpenLocation(!openLocation);
  };

  return (
    <ClassCardEnrollmentContainer className={props.className || undefined}>
      <div className="card-container">
        <LearningTargetsStudentCardBorder
          data={props.studentData ? classCardHeaderData(props.studentData) : []}
          location="header"
          studentData={props.studentData}
        />
        <div style={{ cursor: 'pointer' }} onClick={() => handleClickOpenTrail()}>
          <LearningTargetsStudentCardBody isTrailsOpen={openLocation}>
            <b>{props.studentData?.student.name}</b>
            {props.trails && <Icon name="arrow-ios-forward-outline" fill="#2E3A59" />}
          </LearningTargetsStudentCardBody>
        </div>

        <LearningTargetsStudentCardBorder studentData={props.studentData} location="footer" valid={validClick} isShowValid />
        <LearningTargetsStudentCardStatus studentData={props.studentData!} listStudentFromClass={props.listStudentFromClass} />

        {props.trails && props.studentData && (
          <LearningTargetsStudentCardLocation
            listStudentFromClass={props.listStudentFromClass}
            isOpen={openLocation}
            blocksAndSkills={props.studentData?.gradeInfo}
            studentData={props.studentData?.student!}
            setValidClick={setValidClick}
          />
        )}
      </div>
    </ClassCardEnrollmentContainer>
  );
};

export { LearningTargetsStudentCard };
