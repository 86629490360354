import { ISelectOption } from 'react-alicerce-components';

// constraints de meios de transporte - transport_method
export const STUDENT_WALKING_TRANSPORT = { name: 'A pé/bicicleta', value: 'walking' }; // A pé/bicicleta;
export const STUDENT_PUBLIC_TRANSPORT = { name: 'Transporte Publico (Metrô, Ônibus, ETC)', value: 'public_transport' }; // Transporte Publico (Metrô, Ônibus, ETC);
export const STUDENT_SELF_TRANSPORT = { name: 'Transporte Proprio (Carro, Moto, ETC)', value: 'self_transport' }; // Transporte Proprio (Carro, Moto, ETC);
export const STUDENT_SCHOOL_TRANSPORT = { name: 'Transporte escolar', value: 'school_transport' }; // Transporte escolar
export const STUDENT_NOT_COLLECTED_TRANSPORT = { name: 'Nao Coletado', value: 'not_collected' }; // Nao Coletado (Padrao)

export const STUDENT_TRANSPORT_CONSTRAINTS = [
  STUDENT_WALKING_TRANSPORT,
  STUDENT_PUBLIC_TRANSPORT,
  STUDENT_SELF_TRANSPORT,
  STUDENT_SCHOOL_TRANSPORT,
  STUDENT_NOT_COLLECTED_TRANSPORT,
];

export const defaultSchoolTransport = (schoolTransport?: string | null): ISelectOption[] | undefined => {
  return STUDENT_TRANSPORT_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: schoolTransport === foo.value };
  });
};
