import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    gap: 4px;
    width: 80%;
    margin: 12px auto;
`;

export const Item = styled.div<{color: string}>`
    height: 54px;
    width: 100%;
    background-color: ${({color}) => color};

    font-size: 15px;
    color: #fff;
    font-weight: 800;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;