import React, { Fragment, useCallback } from 'react';
import { formatTime } from '@shared/interfaces/formatTime';
import { useScheduleGrid } from '../../context/ScheduleGridContext';
import ClassPlannerScheduleItem from './ClassPlannerScheduleItem';

import { Container } from './styles';

const ClassPlannerSchedule: React.FC = () => {
  const { plannedActivities } = useScheduleGrid();

  const sortActivitiesByTime = useCallback(() => {
    if (!plannedActivities) return [];

    plannedActivities.in_calendar.sort((a, b) => {
      if (!a.start_time || !b.start_time) return 1;
      const startTimeA = formatTime(a.start_time);
      const startTimeB = formatTime(b.start_time);

      if (startTimeA > startTimeB) return 1;
      else if (startTimeA === startTimeB) return 0;
      else return -1;
    });

    return plannedActivities.in_calendar;
  }, [plannedActivities]);

  return (
    <Fragment>
      {!plannedActivities || plannedActivities.in_calendar.length === 0 ? (
        `Não existe nenhuma atividade planejada`
      ) : (
        <Container>
          {sortActivitiesByTime().map((activity, id) => (
            <ClassPlannerScheduleItem
              key={id}
              classPlannerSchedule={{
                id,
                name: activity.name,
                start: formatTime(activity.start_time),
                end: formatTime(activity.end_time),
              }}
            />
          ))}
        </Container>
      )}
    </Fragment>
  );
};

export default ClassPlannerSchedule;
