export function translateTitles(title: string): string {
  switch (title) {
    case 'checkpoint':
      return 'Checkpoint';
    case 'pdv':
      return 'PDV';
    case 'meta_definition':
      return 'Definição de Metas';
    case 'mini_mapa_realization':
      return 'Realização MiniMAPA';
    case 'attedance_given':
      return 'Chamada Realizada';
    case 'student_presence':
      return 'Frequência';
    case 'sprint_planning':
      return 'Planejamento Bimestral';
    case 'weekly_planning':
      return 'Planejamento Semanal';
    default:
      return `${title} - Tradução não mapeada`;
  }
}
