import { styled } from 'react-alicerce-components';

export const WorkGroupFormContainer = styled.div`
  .input-container:first-child {
    margin: 0 0 1.5rem 0;
  }
  
  .accordion-info-item-container {
    margin-bottom: 0;
  }
`;
