import home from '@modules/home/routes';
import classes from '@modules/classes/routes';
import groups from '@modules/groups/routes';
import tags from '@modules/tags/routes';
import student from '@modules/students/routes';
import places from '@modules/places/routes';
import users from '@modules/users/routes';
import occurrences from '@modules/occurrences/routes';
import attendances from '@modules/attendances/routes';
import workedHours from '@modules/worked-hours/routes';
import recordings from '@modules/recordings/routes';
import activitiesPortfolio from '@modules/activities-portfolio/routes';
import schedules from '@modules/schedules/routes';
import launchers from '@modules/launchers/routes';
import learningTargets from '@modules/learning-targets/routes';
import subscriptions from '@modules/subscriptions/routes';
import sprint from '@modules/sprint/routes';
import library from '@modules/activities-library/routes';
import classPlanner from '@modules/class-planner/routes';
import workGroup from '@modules/work-group/routes';
import dashboards from '@modules/dashboards/routes';
import tasks from '@modules/tasks/routes';
import applications from '@modules/applications/routes';
import projects from '@modules/projects/routes';
import reportExplorer from '@modules/report-explorer/routes';
import contracts from '@modules/contracts/routes';

import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

const modulesRoutes: IModuleRoutes[] = [];

export default modulesRoutes.concat(
  home,
  classes,
  groups,
  tags,
  student,
  places,
  users,
  occurrences,
  attendances,
  workedHours,
  recordings,
  schedules,
  activitiesPortfolio,
  launchers,
  learningTargets,
  subscriptions,
  sprint,
  library,
  classPlanner,
  workGroup,
  dashboards,
  tasks,
  applications,
  reportExplorer,
  projects,
  contracts
);
