import React, { useMemo, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { ActivityList } from '../../components/ActivityList';
import { ListActivityLibrariesService } from '../../services/ListActivityLibrariesService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { IActivityFilterFormatted } from '../../context/ActivityContext';
import { Spinner } from 'react-alicerce-components';

const ProgramActivityRelationEdit: React.FC = () => {
  const { setProgramCurrent } = useProgram();
  const { programId } = useParams<{ programId: string }>();
  const { setIsLoading, isLoading } = useGlobal();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeActivitysSelected = useCallback(
    (activities: IActivityLibrary[]) => {
      setProgramCurrent((oldState: IProgram) => {
        return { ...oldState, activities: activities };
      });
    },
    [setProgramCurrent]
  );

  const listActivities = useCallback(
    async (search: string = '', page: number = 0, formattedFilter: IActivityFilterFormatted = {}) => {
      setIsLoading(true);
      const res = await new ListActivityLibrariesService(signedUser?.token, newCancelToken()).execute(search, {
        size: 10,
        page,
        ...formattedFilter,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res || !isMounted()) return;
      return res;
    },
    [isMounted, newCancelToken, setIsLoading, signedUser]
  );

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Banco de Atividades" backgroundHeader="linear-gradient(284.92deg,#B390DB 0%,#70C3FC 100%)" />
      <DirectoryContainer footer={true}>
        <ActivityList
          handleOnClickButton={handleOnChangeActivitysSelected}
          canSelectCard="many"
          buttonText={`Adicionar Atividade${canQuantitySelect > 1 ? 's' : ''}`}
          listActivitiesCallback={listActivities}
          pathOnClickButton={`/banco-atividades/programa/${programId}/editar`}
          statusToFilter="public"
        />
      </DirectoryContainer>
    </Fragment>
  );
};

export { ProgramActivityRelationEdit };
