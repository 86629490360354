import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IIBulkResponse } from '@shared/interfaces/IBulkResponse';

export interface IAttendanceBody {
  daily_class?: string;
  attendances: {
    retroactive_atendance_reason?: string;
    presence?: boolean;
    attendance_id?: number | string;
  }[];
}

class UpdateAttendanceService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(attendanceBody: IAttendanceBody): Promise<IIBulkResponse | false> {
    try {
      const res = await this.api.put(`/attendance`, { ...attendanceBody });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default UpdateAttendanceService;
