import React, { useCallback, useState } from 'react';
import Icon from 'react-eva-icons';

import { ISubscription } from '@modules/subscriptions/interfaces/ISubscription';

import { SubscriptionCardTrails } from './SubscriptionCardTrails';
import { SubscriptionCardBody } from './SubscriptionCardBody';
import SubscriptionCardBorder from './SubscriptionCardBorder';

import { STUDENT_RELATIONSHIP_CONSTRAINTS } from '@modules/students/utils/validData/validRelationships';
import { SCHOOL_GRADE_CONSTRAINTS } from '@modules/students/utils/validData/validSchoolgrade';
import { CLASS_TYPE_CONSTRAINTS } from '@modules/classes/utils/validData/validClassType';
import { SHIFT_CONSTRAINTS } from '@modules/classes/utils/validData/validShift';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { WEEK_DAYS_CONSTRAINTS } from '@shared/utils/validData/validWeekdays';

import GetZdpBlockStudentService from '@modules/subscriptions/services/GetZdpBlockStudentService';
import { IZdpBlockStudent } from '@modules/subscriptions/interfaces/IZdpBlockStudent';
import { initalDataZdpBlock } from '@modules/subscriptions/utils/initialDataZdpBlock';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useToast } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';

import { SubscriptionCardContainer } from './styles';

interface ISubscriptionCardProps {
  subscription?: ISubscription;
  className?: string;
  canDeleteCard?: { indexId: number; removeCallback: (indexId: number) => void };
  trails?: boolean;
}

const SubscriptionCard: React.FC<ISubscriptionCardProps> = (props) => {
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const [zdpBlockStudent, setZdpBlockStudent] = useState<IZdpBlockStudent[]>(initalDataZdpBlock);
  const [openTrails, setOpenTrails] = useState<boolean>(false);

  const subscriptionHeaderData = useCallback((subscription: ISubscription): string[] => {
    const cardHeaderData: string[] = [];
    if (!subscription.class_data) return cardHeaderData;

    const { type, shift, week_days } = subscription.class_data;

    if (shift) {
      const translatedShift = SHIFT_CONSTRAINTS?.find((s) => s.value.includes(shift));
      translatedShift && cardHeaderData.push(translatedShift.name);
    }

    if (type) {
      const translatedCategory = CLASS_TYPE_CONSTRAINTS.find((s) => s.value.includes(type));
      translatedCategory && cardHeaderData.push(translatedCategory.name);
    }

    if (week_days) {
      const translatedWeekDays = week_days.map((wd) => WEEK_DAYS_CONSTRAINTS.find((wdc) => wdc.value === wd)?.name);
      translatedWeekDays && cardHeaderData.push(translatedWeekDays.join('/'));
    }

    subscription.places && subscription.places.name && cardHeaderData.push(subscription.places.name);

    return cardHeaderData;
  }, []);

  const subscriptionFooterData = useCallback((subscription: ISubscription): string[] => {
    const cardFooterData: string[] = [];
    if (!subscription.student) return cardFooterData;

    const { relationship_type, school_grade, age_group } = subscription.student;

    const translatedRelationshipType = STUDENT_RELATIONSHIP_CONSTRAINTS.find((s) => s.value.includes(relationship_type || ''));
    translatedRelationshipType && cardFooterData.push(translatedRelationshipType.name);

    const translatedSchoolGrade = SCHOOL_GRADE_CONSTRAINTS.find((s) => s.value.includes(school_grade || ''));
    translatedSchoolGrade && cardFooterData.push(translatedSchoolGrade.name);

    const translatedAgeGroup = AGE_GROUP_CONSTRAINTS.find((s) => s.value.includes(age_group || ''));
    translatedAgeGroup && cardFooterData.push(translatedAgeGroup.name);

    return cardFooterData;
  }, []);

  const getZdpBlockStudent = useCallback(
    async (studentId?: string | number) => {
      setIsLoading(true);
      const res = await new GetZdpBlockStudentService(signedUser?.token).execute(String(studentId));

      if (!res) {
        addToast({
          status: 'danger',
          title: 'Tamanho de block',
          description: 'Erro ao encontrar o range do bloco.',
        });
        return;
      }
      setZdpBlockStudent(res);
      setIsLoading(false);
    },
    [addToast, setIsLoading, signedUser]
  );

  const handleClickOpenTrail = useCallback(() => {
    setOpenTrails(!openTrails);
    if (!openTrails) getZdpBlockStudent(props.subscription?.student_id);
  }, [getZdpBlockStudent, openTrails, props.subscription]);

  return (
    <SubscriptionCardContainer className={props.className || undefined}>
      <div className="card-container">
        <SubscriptionCardBorder data={props.subscription ? subscriptionHeaderData(props.subscription) : []} location="header" />
        <SubscriptionCardBody isTrailsOpen={openTrails}>
          <b>{props.subscription?.student?.name}</b>
          {props.trails && (
            <div style={{ cursor: 'pointer' }} onClick={handleClickOpenTrail}>
              <Icon name="arrow-ios-forward-outline" fill="#2E3A59" />
            </div>
          )}
        </SubscriptionCardBody>
        <SubscriptionCardBorder data={props.subscription ? subscriptionFooterData(props.subscription) : []} location="footer" />

        {props.trails && <SubscriptionCardTrails isOpen={openTrails} handleOnClick={() => setOpenTrails(false)} zdpBlockStudent={zdpBlockStudent} />}
      </div>

      {props.canDeleteCard && (
        <div className="remove-card" onClick={() => props.canDeleteCard?.removeCallback(props.canDeleteCard.indexId)}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </SubscriptionCardContainer>
  );
};

export { SubscriptionCard };
