import React, { Fragment, useCallback, useMemo } from 'react';

import { P, Pill } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';

import { Container, ListContainer, StatusContainer } from './styles';
import { IDailyClass } from '../../../attendances/interfaces/IDailyClass';
import { datePickerFormat } from '@shared/utils/datePickerFormat';
import { getWeekDay } from '@shared/utils/getWeekDay';
import { STATUS_PLANNED, STATUS_PARTIAL_PLANNED, STATUS_NOT_PLANNED, STATUS_PLANNED_WITH_PENDINGS } from '../../utils/planned_daily_class_status';

interface IPlannedClassCardProps extends ICustomCardProps {
  plannedClass: IDailyClass;
  handleOnClickCard: () => void;
}

const PlannedClassCard: React.FC<IPlannedClassCardProps> = ({ plannedClass, handleOnClickCard, ...rest }) => {
  const cardHeaderFormatter = useMemo(() => {
    return (
      <Fragment>
        <Pill className="tag" backgroundColor="#E4E9F2" color="#222B45">
          {plannedClass.start_time?.slice(0, 5)} - {plannedClass.end_time && plannedClass.end_time.slice(0, 5)}
        </Pill>

        {plannedClass.place && (
          <Pill className="tag" backgroundColor="#E4E9F2" color="#222B45">
            {plannedClass.place.name}
          </Pill>
        )}
      </Fragment>
    );
  }, [plannedClass.end_time, plannedClass.place, plannedClass.start_time]);

  const plannedDailyClassStatusColor = useCallback((status?: string): string => {
    if (status === STATUS_PLANNED) return 'linear-gradient(180deg, #6DCC1F 36.46%, #5BB610 97.4%)';
    if (status === STATUS_PARTIAL_PLANNED) return 'linear-gradient(180deg, #FFD74E 0%, #FAC50D 100%)';
    if (status === STATUS_PLANNED_WITH_PENDINGS) return 'linear-gradient(180deg, #F9514C 36.46%, #F42727 97.4%)';
    if (status === STATUS_NOT_PLANNED) return '#EDF1F7';
    return 'black';
  }, []);

  const translateStatus = useCallback((statusText?: string) => {
    if (statusText === STATUS_PLANNED) return 'Aula Planejada';
    if (statusText === STATUS_PARTIAL_PLANNED) return 'Aula Planejada Parcialmente';
    if (statusText === STATUS_PLANNED_WITH_PENDINGS) return 'Aula Planejada com Pendências';
    if (statusText === STATUS_NOT_PLANNED) return 'Sem Planejamento';
    return 'Aula sem nenhum status definido';
  }, []);

  return (
    <Container>
      <CustomCard
        header={<ListContainer>{cardHeaderFormatter}</ListContainer>}
        footer={
          <StatusContainer statusColor={plannedDailyClassStatusColor(plannedClass.plan?.status)}>
            {translateStatus(plannedClass.plan?.status)}
          </StatusContainer>
        }
        handleOnClick={() => handleOnClickCard()}
        {...rest}
      >
        <P fontSize={14} fontWeight={800}>
          {plannedClass.start_date && datePickerFormat(plannedClass.start_date, '/', '-')}:
        </P>
        <span style={{ marginLeft: '5px' }}>{getWeekDay(plannedClass.start_date)}</span>
      </CustomCard>
    </Container>
  );
};

export default PlannedClassCard;
