export const UF_CODES = {
    AC: 'AC',
    AL: 'AL',
    AP: 'AP',
    AM: 'AM',
    BA: 'BA',
    CE: 'CE',
    DF: 'DF',
    ES: 'ES',
    GO: 'GO',
    MA: 'MA',
    MT: 'MT',
    MS: 'MS',
    MG: 'MG',
    PA: 'PA',
    PB: 'PB',
    PR: 'PR',
    PE: 'PE',
    PI: 'PI',
    RJ: 'RJ',
    RN: 'RN',
    RS: 'RS',
    RO: 'RO',
    RR: 'RR',
    SC: 'SC',
    SP: 'SP',
    SE: 'SE',
    TO: 'TO'
  }
  
  export const UfOptions = Object.entries(UF_CODES).map(([uf, value]) => ({
    value: uf,
    name: value
  }))

  export const defaultUfOptions = (uf?: string | null) => UfOptions.map(i => ({ ...i, selected: uf === i.value }))
