import React, { useCallback, useEffect, useState } from 'react';
import { H6, Input, Pagination, Spinner } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import DirectoryContainer from '@shared/components/DirectoryContainer';

import { SubscriptionGroupSelectionContainer } from './styles';
import CustomForm from '@shared/components/CustomForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '@modules/subscriptions/context/SubscriptionContext';
import useCancelRequest from '../../../../shared/hooks/useCancelRequest';
import useAuth from '../../../../shared/store/auth/hook';
import ListGroupsService, { IGroupReponse } from '../../../groups/services/ListGroupsService';
import GroupCard from '../../../groups/components/GroupCard';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const SubscriptionGroupSelection: React.FC = () => {
  const { setCheckedSubscriptions } = useSubscription();

  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useGlobal();
  const [groupList, setGroupList] = useState<IGroupReponse>();
  const [inputSearch, setInputSearch] = useState<string>('');

  const handleClickOnGroup = useCallback(
    (group: IGroup) => {
      navigate(`/assinaturas/grupo/${group.id}/pendentes`);
    },
    [navigate]
  );

  const listGroups = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const groups = await new ListGroupsService(signedUser.token, newCancelToken()).execute(inputSearch, {
        page: page,
        size: 10,
        admin_id: Number(signedUser.lmsUser.id),
      });
      if (groups === 'canceling') return;
      setIsLoading(false);
      if (!groups) return;
      setGroupList(groups);
    },
    [inputSearch, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listGroups();
  }, [listGroups]);

  useEffect(() => {
    return () => {
      setCheckedSubscriptions([]);
    };
  }, [setCheckedSubscriptions]);

  return (
    <SubscriptionGroupSelectionContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory title={'Seleção de Contexto'} backgroundHeader=" #8F9BB3" pathToReturn="/" menuKebab={{ menuBackHome: true }} />
      <DirectoryContainer footer={false} style={{ padding: '20px 22px' }}>
        <CustomForm>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => setInputSearch(event.target.value)}
          />

          <H6 textAlign="center" status="tertiary" fontWeight={800}>
            GRUPOS QUE EU ADMINISTRO
          </H6>

          {groupList?.items &&
            groupList.items.map((group) => <GroupCard handleOnClick={() => handleClickOnGroup(group)} key={group.id} group={group} />)}

          {groupList && (
            <Pagination
              status="tertiary"
              totalOfPages={groupList.totalPages}
              currentPage={groupList.currentPage}
              callbackGetListData={(_, page) => listGroups(page)}
            />
          )}
        </CustomForm>
      </DirectoryContainer>
    </SubscriptionGroupSelectionContainer>
  );
};

export { SubscriptionGroupSelection };
