import IProjectReport from "@modules/projects/interfaces/IProjectReport";
import ApiFactory from "@shared/factories/ApiFactory";
import IPaginatedResponse from "@shared/interfaces/IPaginatedResponse";
import IQueryOptions from "@shared/interfaces/IQueryOptions";
import { objectToQuery } from "@shared/utils/objectToQuery";
import axios, { AxiosInstance, CancelTokenSource } from "axios";

export interface IReportResponse extends IPaginatedResponse {
  items: IProjectReport[];
}

class ListReportsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(options?: IQueryOptions | object): Promise<IReportResponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({
        ...options
      });

      const res = await this.api.get(`/report?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListReportsService;