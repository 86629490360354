import React from 'react';
import Icon from 'react-eva-icons';
import { ButtonAddCardContainer } from './styles';

interface IButtonAddCard {
  handleOnClick: () => void;
  text: string;
  iconName?: string;
}

const ButtonAddCard: React.FC<IButtonAddCard> = ({
  handleOnClick,
  text,
  iconName = 'plus-outline'
}) => {

  return (
    <ButtonAddCardContainer className="button-add-card-container">
      <div onClick={handleOnClick}>
        <Icon name={iconName} />
      </div>
      <div className="text-add-button">{text}</div>
    </ButtonAddCardContainer>
  );
};

export default ButtonAddCard;
