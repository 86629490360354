import React from 'react';
import { useNavigate } from 'react-router';

import { Button, Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import { Container } from './styles';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useWorkedHour } from '../../context/WorkedHourContext';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

export const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  const { workedHoursFilter, setSelectTags } = useWorkedHour();

  const handleOnClickTab = (pathToRedirect: string) => {
    workedHoursFilter.current = {};
    setSelectTags({});
    navigate(pathToRedirect);
  };

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Lançar Horas Trabalhadas"
        backgroundHeader="linear-gradient(284.92deg, #F47920 0%, #0097B1 100%);"
        status="primary"
        pathToReturn="/"
        menuKebab={{
          tooltip: { view: 'WorkedHoursInfoApproved' },
          menuBackHome: true,
        }}
      />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Horas Aprovadas" onClick={() => handleOnClickTab('/horas-trabalhadas/informacoes/aprovados')} />
          <Tab title="Horas Pendentes" onClick={() => handleOnClickTab('/horas-trabalhadas/informacoes/pendentes')} />
          <Tab title="Horas Reprovadas" onClick={() => handleOnClickTab('/horas-trabalhadas/informacoes/reprovados')} />
        </Tabs>
      </CustomTabs>

      <PageFooter>
        <Button transform="none" status="primary" statusNumber={400} shaded onClick={() => navigate(`/horas-trabalhadas/criar`)}>
          Fazer Lançamento
        </Button>
      </PageFooter>
    </Container>
  );
};
