import React, { useEffect, useRef, useState } from 'react';

import { IBlockRange } from '@modules/subscriptions/interfaces/IBlockRange';

import { ConnectLevels, Container, FinalLevel, InitialLevel } from './styles';

export interface IClassCardEnrollmentTrails {
  isOpen?: boolean;
  handleOnClick: (value: any) => void;
  blockRangeStudent: IBlockRange[];
  initialColor?: string;
  finalColor?: string;
  colorVariation?: number;
}

const ClassCardEnrollmentTrails: React.FC<IClassCardEnrollmentTrails> = ({ isOpen, handleOnClick, blockRangeStudent }) => {
  const [heightState, setHeightState] = useState<string>('0px');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setHeightState(`max-content`);
    } else {
      setHeightState('0px');
    }
  }, [isOpen]);

  return (
    <Container ref={ref} heightState={heightState} onClick={handleOnClick}>
      {blockRangeStudent.map((block) => (
        <div className="trail" key={block.trail.id}>
          <div className="trail-content">
            <div className="trail-name">{block.trail.name}</div>
            <div className="trail-level">
              {block.block_min && block.block_min.color_variation && (
                <InitialLevel initialColor={block.block_min.level.color} colorVariation={block.block_min.color_variation}>
                  {block.block_min.name}
                </InitialLevel>
              )}

              {block.block_min && block.block_min && (
                <ConnectLevels
                  initialColor={block.block_min?.level?.color}
                  finalColor={block.block_max.level.color}
                  colorVariation={0.5}
                ></ConnectLevels>
              )}

              {block.block_max && block.block_max.color_variation && (
                <FinalLevel finalColor={block.block_max.level.color} colorVariation={block.block_max.color_variation}>
                  {block.block_max.name}
                </FinalLevel>
              )}

              {(!block.block_min || !block.block_max) && (
                <h1 className='empty-block'>Turma sem MAPAs realizados</h1>
              ) }
            </div>
          </div>
        </div>
      ))}
    </Container>
  );
};
export { ClassCardEnrollmentTrails };
