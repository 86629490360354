import { css, styled } from "react-alicerce-components";

type ContainerWrapper = {
    isOpen?: boolean
}


const accordionModifiers = {
    closed: () => css`
        max-height: 0;
        transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
      `,
}

export const AccordionWrapper = styled.div<ContainerWrapper>`
    border: 1px solid #C5CEE0;
    border-radius: 1rem;
`

export const AccordionHeaderContainer = styled.div<ContainerWrapper>`
    display: flex;
    cursor: pointer;
`

export const AccordionContentContainer = styled.div<ContainerWrapper>`
    ${({ isOpen }) => css`
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;
        ${!isOpen && accordionModifiers.closed};
    `}`