import React, { createContext, useContext } from 'react';

export type IReportExplorerContexttData = {
};

// const initialState = { name: '', age_groups: [], calendar_data: [] };

const ReportExplorerContext = createContext<IReportExplorerContexttData>({} as IReportExplorerContexttData);

const ReportExplorerProvider: React.FC = ({ children }) => {

  return <ReportExplorerContext.Provider value={{}}>{children}</ReportExplorerContext.Provider>;
};

function useReportExplorer() {
  const context = useContext(ReportExplorerContext);

  if (!context) {
    throw new Error('useReportExplorer must be used within a ReportExplorerProvider');
  }

  return context;
}

export { ReportExplorerProvider, useReportExplorer };
