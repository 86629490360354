
import React, { useCallback, useState } from 'react';

import Dot from '@shared/components/CustomRecharts/elements/Legend/Dot';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import {
    Container,
    GridWrapper,
    FixedContainer,
    ContentContainer,
    GridsContainer,
    GridHeader,
    GH,
    GF,
    GR,
    GD,
    Arrow,
    Ball,
} from './styles';

export interface IColumn {
    name: string;
}

interface IData {
    category: string;
    start: number;
    end?: number;
}

const HabilidadesParaVidaIndividualEvolutionDetails: React.FC = () => {


    const [columns] = useState<IColumn[]>([
        { name: 'Nunca' },
        { name: 'Quase Nunca' },
        { name: 'Quase Sempre' },
        { name: 'Sempre' },
        { name: 'Sem Identif.' },
    ]);

    const [barsChartConfig] = useState([
        { name: '01/02/2021', color: '#C7E2FF' },
        { name: '02/03/2021', color: '#42AAFF' },
    ]);

    const [data] = useState<IData[]>([
        {
            category: 'Categoria 1',
            start: 0,
            end: 1
        },
        {
            category: 'Categoria 2',
            start: 1,
            end: 3
        },
        {
            category: 'Categoria 3',
            start: 3,
            end: 0
        },
        {
            category: 'Categoria 4',
            start: 4
        },
        {
            category: 'Categoria 5',
            start: 2,
            end: 2
        }
    ]);

    const getArrow = useCallback((d: IData, index: number) => {
        const invert = d.end !== undefined ? d.end < d.start : false;
        const conditionOne = !invert && d.end && index >= d.start && index + 1 <= d.end;
        const conditionTwo = invert && d.end !== undefined && d.end <= index && index < d.start;
        if (conditionOne || conditionTwo) {
            return <Arrow invert={invert} />;
        }

    }, []);

    const getBallProps = useCallback((d: IData, index: number) => {
        return {
            bgColor: d.start === 4 ? '#FFA8B4' : (d.start === index ? '#C7E2FF' : (d.end === index ? '#94CBFF' : '')),
            showBall: d.start === index || d.end === index,
            isDouble: d.start === d.end
        };
    }, []);

    return (
        <Container>
            <HeaderDirectory title='Detalhes' />
            <div className='legend-container'>
                <Dot items={barsChartConfig} />
            </div>
            <GridWrapper>
                <FixedContainer>
                    {data?.map((d, i) => (<GF key={`grid-data-fixed-${i}`}>{d.category}</GF>))}
                </FixedContainer>
                <ContentContainer>
                    <div>
                        <GridHeader>
                            {columns?.map((c, i) => (<GH key={`grid-header-${i}`}>{c.name}</GH>))}
                        </GridHeader>
                        <GridsContainer>
                            {data?.map((d, i) => (
                                <GR key={`grid-row-${i}`}>
                                    {columns?.map((c, j) => (
                                        <GD key={`grid-data-${c.name}-${i}`}>
                                            {getArrow(d, j)}
                                            <Ball {...getBallProps(d, j)} />
                                        </GD>
                                    ))}
                                </GR>
                            ))}
                        </GridsContainer>
                    </div>
                </ContentContainer>
            </GridWrapper>
        </Container >
    );
}

export default HabilidadesParaVidaIndividualEvolutionDetails;