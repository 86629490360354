export const translateString = (stringToTranslate: string) => {
  switch (stringToTranslate) {
    case 'root':
      return 'Alicerce';
    case 'tribe':
      return 'Tribo';
    case 'collective':
      return 'Coletivo';
    case 'nation':
      return 'Nação';
    case 'room':
      return 'Sala';
    case 'region':
      return 'Região';
    case 'city':
      return 'Cidade';
    case 'location':
      return 'Localização';
    case 'fundamental_completo':
      return 'Ensino Fundamental Completo';
    case 'fundamental_incompleto':
      return 'Ensino Fundamental Incompleto';
    case 'medio_completo':
      return 'Ensino Médio Completo';
    case 'medio_incompleto':
      return 'Ensino Médio Incompleto';
    case 'superior_completo':
      return 'Ensino Superior Completo';
    case 'superior_incompleto':
      return 'Ensino Superior Incompleto';
    case 'male':
      return 'Masculino';
    case 'female':
      return 'Feminino';
    case 'intersexual':
      return 'Intersexual';
    case 'public':
      return 'Pública';
    case 'private':
      return 'Privada';
    case 'active':
      return 'Ativo';
    case 'inactive':
      return 'Inativo';
    case 'waiting':
      return 'Em Espera';
    case 'paused':
      return 'Pausado';
    case 'teacher':
      return 'Líder';
    case 'manager':
      return 'Coordernador';
    case 'mon':
      return 'seg';
    case 'tue':
      return 'ter';
    case 'wed':
      return 'qua';
    case 'thu':
      return 'qui';
    case 'fri':
      return 'sex';
    case 'sat':
      return 'sab';
    case 'sun':
      return 'dom';
    case 'implantation':
      return 'Implantação';
    case 'closed':
      return 'Fechado';
    case 'execution':
      return 'Execução';
    case 'adaption':
      return 'Adaptação';
    case 'deployment':
      return 'Implantação';
  }
};
