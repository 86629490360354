import { formatDefaultDate } from "@shared/utils/formatDate"
import { inputDateRegex, inputTimeRegex } from "@shared/utils/schemas/utils"

function validateDateFormat(dateString: string): boolean {
  return inputDateRegex.test(dateString)
}

function validateTimeFormat(timeString: string): boolean {
  return inputTimeRegex.test(timeString)
}

export function getLocalTimezoneDate(dateString?: string, timeString?: string) {
  if (dateString && !validateDateFormat(dateString)) {
    throw new Error('Formato de data inválido')
  }

  if (timeString && !validateTimeFormat(timeString)) {
    throw new Error('Formato de hora inválido')
  }

  if (!dateString) {
    dateString = formatDefaultDate({ date: new Date(), readOnly: false })
  }

  if (!dateString) throw new Error('DateString não pode ser undefined.')
  // const parts = dateString.split('-')
  // const year = parseInt(parts[0])
  // const month = parseInt(parts[1]) - 1 // Months are 0-indexed in JavaScript
  // const day = parseInt(parts[2])

  // const date = new Date(Date.UTC(year, month, day))
  // const localTimezoneOffset = new Date().getTimezoneOffset()

  return {
    now: (): Date => {
      if (timeString) {
        const [hours, minutes] = timeString.split(':')
        return new Date(`${dateString} ${hours}:${minutes}:00`)
      } else {
        const now = new Date()
        const minutes = now.getMinutes()
        const seconds = now.getSeconds()
        const hours = now.getHours()
        return new Date(`${dateString} ${hours}:${minutes}:${seconds}`)
      }
    },
    startOfDay: (): Date => {
      const startOfDay = new Date(dateString + ' ')
      // startOfDay.setMinutes(startOfDay.getMinutes() + localTimezoneOffset)
      // startOfDay.setHours(0, 0, 0, 0) // Set the time to 00:00:00
      return startOfDay
    },
    endOfDay: (): Date => {
      const endOfDay = new Date(`${dateString} 23:59:59:999`)
      // endOfDay.setDate(endOfDay.getDate() + 1)
      // endOfDay.setMinutes(endOfDay.getMinutes() + localTimezoneOffset - 1)
      // endOfDay.setHours(23, 59, 59) // Set the time to 23:59:59
      return endOfDay
    }
  }
}