import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IGradeIndividualHistory } from '../interfaces/IGradeIndividualHistory';

class GetGradeIndividualHistoryService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(studentId: string): Promise<IGradeIndividualHistory[] | false> {
    try {
      const res = await this.api.get(`/dashboard/grade/indivual/history/${studentId}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetGradeIndividualHistoryService;
