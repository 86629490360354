import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import SteeringForm from '@modules/projects/components/SteeringForm';
import { formatSteering } from '@modules/projects/utils/formatSteering';
import EditSteeringService from '@modules/projects/services/Steering/EditSteeringService';
import { EditSteeringSchema } from '@modules/projects/schemas/Steering/EditSteering';

const SteeringEdit: React.FC = () => {
  
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { clearSteeringCreate, steeringCurrent, getSteering, clearProjectCreate } = useProjects();
  const { id: steeringId } = useParams<{ id: string }>();
  const dirPath = `/diretorio/projetos/steering/${steeringCurrent?.id}`

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    clearSteeringCreate();
    navigate(dirPath);
  }, [clearSteeringCreate, dirPath, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!steeringCurrent) return;
    const formatedBody = formatSteering(steeringCurrent, Number(steeringCurrent.project_id)) as any;
    const canSubmitForm = await beforeSubmitForm(formatedBody, EditSteeringSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [steeringCurrent]);

  const handleEditSteering = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !steeringCurrent || !steeringId) return;
    setIsLoading(true);
    const formatedBody = formatSteering(steeringCurrent, Number(steeringCurrent.project_id));
    const res = await new EditSteeringService(signedUser.token).execute(steeringId, formatedBody);
    setIsLoading(false);
    if (res) {
      clearProjectCreate()
      addToast({
        status: 'success',
        title: 'Steering',
        description: 'Steering atualizada com sucesso.',
      });
      clearSteeringCreate();
      return navigate(dirPath);
    }

    addToast({
      status: 'danger',
      title: 'Steering',
      description: 'Não foi possível atualizar a Steering.',
    });
  }, [signedUser, steeringCurrent, steeringId, setIsLoading, addToast, clearProjectCreate, clearSteeringCreate, navigate, dirPath]);

  useEffect(() => {
    getSteering(steeringId)
  },[getSteering, steeringId])

  return (
    <Fragment>
      <SteeringForm
        editing
        headerTitle="Editar Steering"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
        pathParticipatingAlicerce="/diretorio/projetos/steering/criar/selecionar/participante-alicerce"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Editar esta Steering?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleEditSteering}
      />
    </Fragment>
  );
};

export default SteeringEdit;
