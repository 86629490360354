import styled from 'styled-components';

export const ConsolidatedBox = styled.div`
  height: 4rem;
  background: #fff;
  flex: 1;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 800;
  color: #222B45;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  overflow: hidden;
  gap: .5rem;
  
  &::after {
    content: 'Aprovadas';
    background: #00D68F;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    height: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

`;

export const Container = styled.div`
  background-color: #E4E9F2;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-betweeen;
  gap: 1rem;
  margin: 1rem auto;
  max-width: 600px;

  > div:nth-child(2) {
    &::after {
      content: 'Pendentes';
      background: #FFAA00;
    } 
  }

  > div:nth-child(3) {
    &::after {
      content: 'Reprovadas';
      background: #FF3D71;
    } 
  }
`;

