
import themeGet from "@styled-system/theme-get"
import { css, styled } from "react-alicerce-components"

//@TODO Gabriel, trocar todos os pixel para rem
type DotStepProps = { status?: string, active?: boolean }

const dotStepModifiers = {
  active: () => css`
      width: 40px;
      border-radius: 3px;
      background: linear-gradient(45deg, #482B85 0%, #6749BA 100%);
      transition: all .5s;
    `,
}

export const DotStep = styled.div<DotStepProps>`
  ${({ active }) => css`
    padding: 4px;
    background: ${() => themeGet(`colors.basic500`)};
    border-radius: 100%;
    width: fit-content;
    ${!!active && dotStepModifiers.active};
  `}
`

export const DotStepContainer = styled.div`
${() => css`
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`}
`

//#############
//@TODO Gabriel- Verificar as classes .title e .description
export const FormContainer = styled.div`
${() => css`
display: flex;
flex-direction: column;
overflow: hidden;
justify-content: space-between;
.icon-container {
    margin-top: 10px;
    i {
      display: flex;
      justify-content: center;
      svg {
        width: 92px;
        height: 92px;
      }
    }
  }

  //@Todo Gabriel
  .subtitle {
    text-align: center;
    padding: 0px 15px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 9px;
    color: #2E3A59;
  }

  .title {
    text-align: center;
    padding: 0px 15px;
    margin-bottom: 16px;
    font-weight: 800;
    font-size: 22px;
    line-height: 34px;
    color: #222b45;
  }

  .description {
    //font-size: 12px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem;
    text-align: center;
    color:#222B45;
  }
`}
`