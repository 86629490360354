import React, { createContext, useCallback, useContext, useState } from 'react';

import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { useToast } from 'react-alicerce-components';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CreateOccurrenceService from '@modules/occurrences/services/CreateOccurrenceService';

export type IOccurrencesContextData = {
  occurrenceCurrent?: IOccurrence | null;
  contextSelected: IContextSelected | undefined;
  setContextSelected: (value: any) => void;
  setOccurrenceCurrent: (value: any) => void;
  clearOccurrenceCurrent: () => void;
  createOccurrence: (occurrenceBody: IOccurrence) => Promise<void>;
};

interface IContextSelected {
  context: string;
  context_id: string;
}

const OccurrencesContext = createContext<IOccurrencesContextData>({} as IOccurrencesContextData);

const OccurrencesProvider: React.FC = ({ children }) => {
  const [occurrenceCurrent, setOccurrenceCurrent] = useState<IOccurrence>();
  const [contextSelected, setContextSelected] = useState<IContextSelected>();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();

  const clearOccurrenceCurrent = useCallback(() => {
    setOccurrenceCurrent(undefined);
  }, []);

  const createOccurrence = useCallback(
    async (occurrenceBody: IOccurrence) => {
      if (!signedUser) return;

      setIsLoading(true);
      const res = await new CreateOccurrenceService(signedUser.token).execute(occurrenceBody);
      setIsLoading(false);
      
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Ocorrências',
          description: 'Falha ao tentar criar Ocorrência. Tente novamente.',
        });
        return;
      }
      addToast({
        status: 'success',
        title: 'Ocorrências',
        description: 'Ocorrência criada com sucesso .',
      });
      clearOccurrenceCurrent();
    },
    [addToast, clearOccurrenceCurrent, setIsLoading, signedUser]
  );

  return (
    <OccurrencesContext.Provider
      value={{
        occurrenceCurrent,
        contextSelected,
        setContextSelected,
        setOccurrenceCurrent,
        clearOccurrenceCurrent,
        createOccurrence,
      }}
    >
      {children}
    </OccurrencesContext.Provider>
  );
};

function useOccurrences() {
  const context = useContext(OccurrencesContext);

  if (!context) {
    throw new Error('useOccurrences must be used within a OccurrencesProvider');
  }

  return context;
}

export { OccurrencesProvider, useOccurrences };
