import React, { useEffect, useState } from 'react';

import { LearningTargetsStudentCardContainer } from './styles';

export interface ILearningTargetsStudentCardBody {
  isTrailsOpen: boolean;
  onClick?: (e: any) => void
}

const LearningTargetsStudentCardBody: React.FC<ILearningTargetsStudentCardBody> = (props) => {
  const [openTrails, setOpenTrails] = useState<boolean>(false);

  useEffect(() => {
    setOpenTrails(props.isTrailsOpen);
  }, [props.isTrailsOpen]);

  return <LearningTargetsStudentCardContainer  isTrailsOpen={openTrails}>{props.children}</LearningTargetsStudentCardContainer>;
};

export { LearningTargetsStudentCardBody };
