import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Divider, H6 } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import SteeringCard from '@modules/projects/components/SteeringCard';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import PageFooter from '@shared/components/PageFooter';
import ISteering from '@modules/projects/interfaces/ISteering';

const ProjectSteerings: React.FC = () => {
  const navigate = useNavigate();
  const { projectCurrent, getProject } = useProjects();
  const steerings = projectCurrent?.steerings || [];
  const { id: projectId } = useParams<{ id: string }>();

  const { nextSteering, lastSteering } = steerings.reduce(
    (acc: { nextSteering: ISteering[]; lastSteering: ISteering[] }, steering) => {
      if (steering.status === 'scheduled' || new Date(steering.meeting_date) > new Date()) {
        acc.nextSteering.push(steering);
      } else if (new Date(steering.meeting_date) <= new Date()) {
        acc.lastSteering.push(steering);
      }
      return acc;
    },
    { nextSteering: [], lastSteering: [] }
  );

  const handleClickSteeringCard = useCallback(
    (steering: ISteering) => {
      return navigate(`/diretorio/projetos/steering/${steering?.id}`);
    },
    [navigate]
  );

  const renderSteeringCard = (steering: ISteering, i: number) => {
    return <SteeringCard key={i} steering={steering} handleOnClick={handleClickSteeringCard} />;
  };

  useEffect(() => {
    getProject(projectId);
  }, [getProject, projectId]);

  return (
    <>
      <H6>PRÓXIMAS STEERINGS:</H6>
      {nextSteering.length ? nextSteering.map((steering, i) => renderSteeringCard(steering, i)) : ''}
      <H6 marginTop={5}>ÚLTIMA STEERING:</H6>
      {lastSteering[0] && <SteeringCard steering={lastSteering[0]} handleOnClick={handleClickSteeringCard} />}

      <Divider width="100%" />

      <AccordionInfoItem title="Demais Steerings" isShow>
        {lastSteering.length ? lastSteering.slice(1).map((steering, i) => renderSteeringCard(steering, i)) : ''}
      </AccordionInfoItem>
      <PageFooter>
        <Button transform="none" shaded onClick={() => navigate(`/diretorio/projetos/${projectCurrent?.id}/steering/criar`)}>
          Criar Steering
        </Button>
      </PageFooter>
    </>
  );
};

export default ProjectSteerings;
