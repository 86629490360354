import { Button, Checkbox, Pagination, Spinner } from 'react-alicerce-components';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import useAuth from '@shared/store/auth/hook';
import { BsCheckLg } from 'react-icons/bs';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import { IBlocksReponse, ListBlocksService } from '@modules/work-group/services/ListBlocksService';
import PageFooter from '@shared/components/PageFooter';
import { BlockCard } from '@modules/work-group/components/BlockCard';
import { IBlock } from '@shared/interfaces/IBlock';
import { FilterBlocks } from '@modules/work-group/components/FilterBlocks';
import { useWorkGroup } from '@modules/work-group/context/WorkGroupContext';
import { BlockListContainer } from './styles';

export interface IFilterBlocks {
  trail_id: string | undefined;
  level_id: string | undefined;
}

interface IActivityLibraryBlocks {
  pathToReturn?: string;
  pathToReturnOptions?: { state: Object };
  buttonText: string;
  buttonCallback: (blocks: IBlock[]) => void;
}

export const BlocksList: React.FC<IActivityLibraryBlocks> = (props) => {
  const { isLoading, setIsLoading } = useGlobal();
  const { workGroupCurrent } = useWorkGroup();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const [listedBlocks, setListedBlocks] = useState<IBlocksReponse>();
  const [selectedBlocks, setSelectedBlocks] = useState<IBlock[]>(workGroupCurrent?.blocks || []);
  const [filterBlocks, setFilterBlocks] = useState<IFilterBlocks>({ trail_id: undefined, level_id: undefined });

  const listBlocks = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListBlocksService(signedUser.token, newCancelToken()).execute(search, { page, size: 10, ...filterBlocks });
      setIsLoading(false);
      if (!res || res === 'canceling') return;
      setListedBlocks(res);
    },
    [filterBlocks, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listBlocks();
  }, [listBlocks]);

  const handleOnChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>, sub: IBlock) => {
    const checked: boolean = event.target.checked;

    setSelectedBlocks((oldState: IBlock[]) => {
      const blocks: IBlock[] = oldState ? [...oldState] : [];
      if (!checked) {
        return oldState?.filter((s) => String(s.id) !== sub.id);
      } else {
        blocks.push(sub);
        return blocks;
      }
    });
  }, []);

  const handleConfirmAdd = useCallback(() => {
    props.buttonCallback(selectedBlocks || []);
  }, [props, selectedBlocks]);

  return (
    <BlockListContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Blocos"
        pathToReturn={props.pathToReturn}
        pathToReturnOptions={props.pathToReturnOptions}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
      />
      <DirectoryContainer footer={true}>
        <FilterBlocks appliedFilters={filterBlocks} callbackAppliedFilters={setFilterBlocks} />

        <div className="container-all-blocks">
          {listedBlocks?.items.map((b) => (
            <div key={b.id} className="container-block-checkbox">
              <BlockCard block={b} />
              <Checkbox
                key={`${b.id}`}
                name={`${b.id}`}
                id={`${b.id}`}
                semiRound
                icon={BsCheckLg}
                hideIconUncheck
                onChange={(event) => handleOnChangeCheckbox(event, b)}
                defaultChecked={!!selectedBlocks?.find((cs) => cs.id === b.id)}
              />
            </div>
          ))}
        </div>

        {!!listedBlocks?.items.length && (
          <Pagination totalOfPages={listedBlocks.totalPages} currentPage={listedBlocks.currentPage} callbackGetListData={listBlocks} />
        )}

        <PageFooter>
          <Button transform="none" noBorder shaded onClick={handleConfirmAdd}>
            {props.buttonText}
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </BlockListContainer>
  );
};
