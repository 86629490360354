import * as Yup from 'yup';

import { STUDENT_RELATIONSHIP_CONSTRAINTS } from '@modules/students/utils/validData/validRelationships';
import { SCHOOL_GRADE_CONSTRAINTS } from '@modules/students/utils/validData/validSchoolgrade';
import { STUDENT_STATUS_CONSTRAINTS } from '@modules/students/utils/validData/validStatus';

import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { GENDERS_VALUES } from '@shared/utils/validData/validGender';
import { LITERATE_VALUES } from '@shared/utils/validData/validLiterate';
import { STUDENT_CANCELLATION_TYPES } from '@modules/students/utils/validData/validCancellationReasons';
import { CANCELLATION_DETAILS_TYPES } from './validData/validCancellationDetails';

const AGE_GROUPS = AGE_GROUP_CONSTRAINTS.map((a) => a.value);
const SCHOOL_GRADES = SCHOOL_GRADE_CONSTRAINTS.map((a) => a.value);
const RELATIONSHIPS = STUDENT_RELATIONSHIP_CONSTRAINTS.map((a) => a.value);
const STUDENT_CANCELLATION_DETAIL = CANCELLATION_DETAILS_TYPES.map((a) => a.value);
const STATUS = STUDENT_STATUS_CONSTRAINTS.map((a) => a.value);
const CANCELLATION = STUDENT_CANCELLATION_TYPES.map((a) => a.value);

export const studentSchema = Yup.object().shape({
  name: Yup.string().max(100).required({ name: 'name', message: 'O campo é obrigatório' }),

  gender: Yup.string()
    .oneOf(GENDERS_VALUES, {
      name: 'gender',
      message: `Identidade de Gênero inválida`,
    })
    .required({ name: 'gender', message: 'O campo é obrigatório' })
    .typeError({ name: 'gender', message: 'O campo é obrigatório' }),

  phone: Yup.string(),

  address_data: Yup.object({
    address: Yup.string().required({ name: 'address', message: 'O campo é obrigatório' }),
    city: Yup.string().required({ name: 'city', message: 'O campo é obrigatório' }),
    uf: Yup.string().required({ name: 'uf', message: 'O campo é obrigatório' }),
  }).optional(),

  birthdate: Yup.string()
    .required({ name: 'birthdate', message: 'O campo é obrigatório' })
    .typeError({ name: 'birthdate', message: 'O campo é obrigatório' }),

  literate_status: Yup.string()
    .oneOf(LITERATE_VALUES, {
      name: 'literate_status',
      message: `Campo inválido`,
    })
    .required({ name: 'literate_status', message: 'O campo é obrigatório' })
    .typeError({ name: 'literate_status', message: 'O campo é obrigatório' }),

  age_group: Yup.string()
    .oneOf(AGE_GROUPS, {
      name: 'age_group',
      message: `Campo inválido`,
    })
    .required({ name: 'age_group', message: 'O campo é obrigatório' })
    .typeError({ name: 'age_group', message: 'O campo é obrigatório' }),

  school_grade: Yup.string()
    .oneOf(SCHOOL_GRADES, {
      name: 'school_grade',
      message: `Campo inválido`,
    })
    .required({ name: 'school_grade', message: 'O campo é obrigatório' })
    .typeError({ name: 'school_grade', message: 'O campo é obrigatório' }),

  start_date: Yup.string()
    .when('status', {
      is: (status) => status === 'paused',
      then: Yup.string().required({ name: 'start_date', message: 'O campo é obrigatório' }).nullable(),
    })
    .nullable(),

  relationship_type: Yup.string()
    .oneOf(RELATIONSHIPS, {
      name: 'relationship_type',
      message: `Campo inválido`,
    })
    .required({ name: 'relationship_type', message: 'O campo é obrigatório' })
    .typeError({ name: 'relationship_type', message: 'O campo é obrigatório' }),

  partner_organizations_tag_ids: Yup.array().of(Yup.number()).optional(),

  tag_ids: Yup.array().of(Yup.number()).optional(),

  status: Yup.string()
    .oneOf(STATUS, {
      name: 'status',
      message: `Campo inválido`,
    })
    .required({ name: 'status', message: 'O campo é obrigatório' })
    .typeError({ name: 'status', message: 'O campo é obrigatório' }),

  cancellation_details: Yup.string()
    .when('status', {
      is: (status) => status === 'quitter' || status === 'not_enroll_a_exp' || status === 'not_enroll_a_adapt',
      then: Yup.string().optional().nullable(),
    })
    .nullable(),

  cancellation_reason: Yup.string()
    .when('status', {
      is: (status) => status === 'quitter' || status === 'not_enroll_a_exp' || status === 'not_enroll_a_adapt',
      then: Yup.string()
        .oneOf(CANCELLATION, {
          name: 'cancellation_reason',
          message: `O campo é obrigatório`,
        })
        .nullable(),
    })
    .nullable(),

  cancellation_type_detail: Yup.string().when('$exist', {
    is: exist => exist,
    then: Yup.string()
      .oneOf(STUDENT_CANCELLATION_DETAIL, {
        name: 'cancellation_type_detail',
        message: `O campo é obrigatório`,
      }),
    otherwise: Yup.string().nullable()
  }),

  canceled_at: Yup.string()
    .when('status', {
      is: (status) => status === 'quitter' || status === 'not_enroll_a_exp' || status === 'not_enroll_a_adapt',
      then: Yup.string().required({ name: 'canceled_at', message: 'O campo é obrigatório' }).nullable(),
    })
    .nullable(),

  responsible: Yup.object({
    name: Yup.string().required({ name: 'responsible_name', message: 'O campo é obrigatório' }),
    // cpf: Yup.string( ).optional(),
    phone: Yup.string().required({ name: 'responsible_phone', message: 'O campo é obrigatório' }),
    email: Yup.string(),
  }).required(),

  responsibles_for_picking: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required({ name: 'responsible_picking_name', message: 'O campo é obrigatório' }),
        cpf: Yup.string().required({ name: 'responsible_picking_cpf', message: 'O campo é obrigatório' }),
      })
    )
    .optional(),

  primary_affiliation: Yup.number()
    .required({ name: 'primary_affiliation', message: 'O campo é obrigatório' })
    .typeError({ name: 'primary_affiliation', message: 'O campo é obrigatório' }),

  transport_method: Yup.string().required({ name: 'transport_method', message: 'O campo é obrigatório' }),
  school_type: Yup.string().required({ name: 'school_type', message: 'O campo é obrigatório' }),
  school_organization: Yup.string()
    .required({ name: 'school_organization', message: 'O campo é obrigatório' })
    .typeError({ name: 'school_organization', message: 'O campo é obrigatório' }),
});
