import React, { useCallback } from 'react';

import Layout from '@modules/groups/components/Layout';
import ButtonGradient from '@shared/components/ButtonGradient';
import { useNavigate, useParams } from 'react-router-dom';
import { useGroup } from '@modules/groups/context/GroupContext';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

const GroupHome: React.FC = () => {
  const navigate = useNavigate();
  const { groupCurrent } = useGroup();
  const { setOccurrenceCurrent, setContextSelected } = useOccurrences();
  const { id } = useParams();

  const handleClickOccurrences = useCallback(() => {
    setOccurrenceCurrent({ group: groupCurrent });
    setContextSelected({ context: 'grupos', context_id: id });
    navigate(`/ocorrencias/grupos/${id}/pendentes`, { state: { goBack: true } });
  }, [groupCurrent, id, navigate, setContextSelected, setOccurrenceCurrent]);

  return (
    <Layout openTab={0}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1rem',
        }}
      >
        <ButtonGradient type={13} onClick={() => navigate(`/assinaturas/grupo/${id}/pendentes`)}>
          Enturmamento
        </ButtonGradient>

        <ButtonGradient type={15}>Lançador de NPS</ButtonGradient>

        <ButtonGradient type={5} onClick={() => navigate(`/horas-trabalhadas/grupo/${id}/aprovar`)}>
          Aprovar Horas Trabalhadas
        </ButtonGradient>

        <ButtonGradient type={15} onClick={handleClickOccurrences}>
          Ocorrências
        </ButtonGradient>
      </div>
    </Layout>
  );
};

export default GroupHome;
