import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .custom-tabs {
    [role='tabpanel'] {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 600px;
    }
  }
`;