import { styled } from 'react-alicerce-components';

import AccordionInfoItem, { IAccordionProps } from '@shared/components/AccordionInfoItem';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .input-container {
    margin: 1.5rem 0 0 0;

    > div input::placeholder {
      font-weight: 500;
      color: #C5CEE0;
    }
  }

  .accordion-header {
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: start;
    justify-content: space-between;
    background: #EDF1F7 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > div:first-child {
      max-width: 90%;
      width: 90%;

      .accordion-title {
        background: #8F9BB3;
        margin: 0;
        padding: 10px 0 10px 18px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
        color: #fff;
        font-weight: 800;
      }

      .accordion-sub-title {
        background: #EDF1F7;
        margin: 0;
        padding: 10px 0 10px 18px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
        color: #2E3A59;
      }
    }

    > div.styled-icon {
      margin: 0;
      height: 60px;
      background: #8F9BB3;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      i {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
          border: none;
          fill: #fff;
        }
      }
    }
  }

  .accordion-content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    > div:first-child {
      background: #8F9BB3;
      padding: 18px;
      margin-top: -22px;

      > span {
        display: block;
        margin: 12px 0;
        font-size: 12px;
        font-weight: 800;
        color: #EDF1F7;
      }
    }
  }

`;

export const Text = styled.div`
  background: #EDF1F7;
  border: 1px solid #C5CEE0;
  border-radius: 5px;
  font-size: 12;
  font-weight: 600;
  color: #2E3A59;
  padding: 12px;
`;

export const AccordionComment = styled(AccordionInfoItem)<IAccordionProps>`
    margin: 1rem auto;
`;

export const Footer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-content-icon {
    background-color: #8F9BB3 !important;
    
    i svg {
        fill: #FFF !important;
    }
  }
`;