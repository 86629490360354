import React, { useCallback, useEffect, useRef, useState } from 'react';
import { H6, Pagination } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import GroupCard from '@modules/groups/components/GroupCard';
import ListGroupsService, { IGroupReponse } from '@modules/groups/services/ListGroupsService';
import ListGroupsUserManagesService from '@modules/groups/services/ListGroupsUserManagesService';
import Divider from '@shared/components/Divider';
import axios, { CancelTokenSource } from 'axios';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { Container } from './styles';

interface IGroupsSelectionProps {
  handleOnClick: (groupClicked: IGroup) => void;
  searchValue: string;
  canSelect?: boolean;
  selectedGroups?: IGroup[];
}

const GroupsSelection: React.FC<IGroupsSelectionProps> = ({ handleOnClick, searchValue, canSelect, selectedGroups }) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const isMounted = useIsMounted();
  const [myGroups, setMyGroups] = useState<IGroupReponse>();
  const [managedGroups, setManagedGroups] = useState<IGroupReponse>();
  const cancelRequest = useRef<CancelTokenSource>();
  const cancelRequestManaged = useRef<CancelTokenSource>();

  const listMyGroups = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      cancelRequest.current && cancelRequest.current.cancel('Cancelling search');
      const newCancelToken = () => {
        cancelRequest.current = axios.CancelToken.source();
        return cancelRequest.current;
      };
      const res = await new ListGroupsService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 4,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res && isMounted()) setMyGroups(res);
    },
    [isMounted, setIsLoading, signedUser]
  );

  const listGroupsUserManages = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      cancelRequestManaged.current && cancelRequestManaged.current.cancel('Cancelling search');
      const newCancelToken = () => {
        cancelRequestManaged.current = axios.CancelToken.source();
        return cancelRequestManaged.current;
      };
      const res = await new ListGroupsUserManagesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 4,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res && isMounted()) setManagedGroups(res);
    },
    [isMounted, setIsLoading, signedUser]
  );

  useEffect(() => {
    listMyGroups();
    listGroupsUserManages();
  }, [listGroupsUserManages, listMyGroups]);

  useEffect(() => {
    listMyGroups(searchValue);
    listGroupsUserManages(searchValue);
  }, [listGroupsUserManages, listMyGroups, searchValue]);

  return (
    <Container>
      <H6 textAlign="center" status="tertiary" fontWeight={800}>
        GRUPOS QUE LIDERO
      </H6>
      {myGroups?.items &&
        myGroups?.items.map((g, i) => (
          <GroupCard key={g.id} group={g} handleOnClick={handleOnClick} canSelect={canSelect} groupsSelected={selectedGroups} />
        ))}
      {!!myGroups?.items.length && (
        <Pagination totalOfPages={myGroups.totalPages} currentPage={myGroups.currentPage} callbackGetListData={listMyGroups} status="basic" />
      )}

      <Divider />

      <H6 textAlign="center" status="tertiary" fontWeight={800}>
        GRUPOS QUE COORDENO
      </H6>
      {managedGroups?.items &&
        managedGroups?.items.map((g, i) => (
          <GroupCard key={g.id} group={g} canSelect={canSelect} handleOnClick={handleOnClick} groupsSelected={selectedGroups} />
        ))}
      {!!managedGroups?.items.length && (
        <Pagination
          totalOfPages={managedGroups.totalPages}
          currentPage={managedGroups.currentPage}
          callbackGetListData={listGroupsUserManages}
          status="basic"
        />
      )}
    </Container>
  );
};

export default GroupsSelection;
