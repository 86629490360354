export const activityLibraryKeys = {
    ACTIVITIES_LIBRARY_CREATE: 'activity/create',
    ACTIVITIES_LIBRARY_UPDATE: 'activity/update',
    ACTIVITIES_LIBRARY_DELETE: 'activity/delete',
    ACTIVITIES_LIBRARY_READ: 'activity/read',

    ACTIVITIES_LIBRARY_PROGRAM_CREATE: 'program/create',
    ACTIVITIES_LIBRARY_PROGRAM_UPDATE: 'program/update',
    ACTIVITIES_LIBRARY_PROGRAM_DELETE: 'program/delete',
    ACTIVITIES_LIBRARY_PROGRAM_READ: 'program/read',
};