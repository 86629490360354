import React from 'react';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
import { useGlobal } from '../../../../shared/contexts/global/GlobalContext';
interface ILayoutOccurrencesContextSelectionProps {
  openTab?: number;
}

const LayoutOccurrencesContextSelection: React.FC<ILayoutOccurrencesContextSelectionProps> = ({ openTab = 0, children }) => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Seleção de Contexto" backgroundHeader='#8F9BB3' pathToReturn="/" menuKebab={{ menuBackHome: true }} />
      <CustomTabs>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Turmas" onClick={() => navigate('/ocorrencias/contexto/turmas')} />
          <Tab title="Grupos" onClick={() => navigate('/ocorrencias/contexto/grupos')} />
          <Tab title="Locais" onClick={() => navigate('/ocorrencias/contexto/locais')} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default LayoutOccurrencesContextSelection;
