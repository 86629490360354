import React from 'react';

import { HeaderMainProvider } from './context/HeaderMainContext';

import HeaderMainContainer, { IHeaderMainProps } from './HeaderMainContainer';

const HeaderMain: React.FC<IHeaderMainProps> = (props) => {
    return (
        <HeaderMainProvider>
            <HeaderMainContainer {...props} />
        </HeaderMainProvider>
    );
}

export default HeaderMain;