import React, { useCallback, useState } from 'react';

// import { useNavigate } from 'react-router-dom';

import { Button, H6, Input, Label, useToast } from 'react-alicerce-components';

import returnStringFunction from '@shared/utils/returnStringFunction';

import Layout from '@modules/projects/components/Layout';
// import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import ConsolidatedHours from '@modules/projects/components/ConsolidatedHours';
import ConsolidatedHoursDetails from '@modules/projects/components/ConsolidatedHoursDatails';
import DistributionOfAprrovedHours from '@modules/projects/components/DistributionOfAprrovedHours';

import { DateFilterContainer, FilterContainer } from './styles';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ProjectHourCard from '@modules/projects/components/ProjectHourCard';

export interface IInputDateFilter {
  period: { start?: string; end?: string; };
}

const ProjectHoursSpent: React.FC = () => {
  // const navigate = useNavigate();
  const { addToast } = useToast();
  // const { projectCurrent } = useProjects();

  const [dateFilter, setDateFilter] = useState<IInputDateFilter>({
    period: { start: undefined, end: undefined },
  });

  const handleFilter = useCallback(() => {

    let isValidDates = true;
    Object.entries(dateFilter).forEach(([_k, dates]) => {

      const toastError = {
        status: 'danger',
        title: `Periodo`
      }

      if (!dates.start || !dates.end) {
        addToast({ ...toastError, description: 'Data em branco.' });
        isValidDates = false;
        return;
      }

      if (new Date(dates.start) > new Date(dates.end)) {
        addToast({ ...toastError, description: 'Data de início é maior que data final.' });
        isValidDates = false;
        return;
      }
    });


    if (isValidDates) { }

  }, [addToast, dateFilter]);

  const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    event.persist();
    setDateFilter((oldState: any) => ({ ...oldState, [name]: { ...oldState[name], [event.target.name]: event.target.value } }));
  }, []);

  const Filter = () => (
    <FilterContainer>
      <div className='content'>
        <h6>Filtrar por: </h6>
        <Label>Periodo:</Label>
        <DateFilterContainer>
          <Input
            name="start"
            type="date"
            useTooltipError={false}
            onChange={(event) => handleChangeInput(event, 'period')}
            defaultValue={returnStringFunction(dateFilter?.period.start)}
            required
          />
          <Label>Até</Label>
          <Input
            name="end"
            type="date"
            useTooltipError={false}
            onChange={(event) => handleChangeInput(event, 'period')}
            defaultValue={returnStringFunction(dateFilter?.period.end)}
            required
          />
        </DateFilterContainer>
      </div>
      <div className="footer">
        <Button
          className='btn-project'
          transform="none"
          onClick={handleFilter}
          noBorder
          shaded
        >
          Aplicar Filtros
        </Button>
      </div>
    </FilterContainer>
  )

  return (
    <Layout openTab={4}>
      <ProjectDefaultStylesContainer>
        <Filter />

        <H6>CONSOLIDADO DE HORAS:</H6>
        <ConsolidatedHours approved={10.653} pending={10.653} disapproved={10.653} />

        <H6>CONSOLIDAÇÃO DE HORAS DETALHADA:</H6>
        <ConsolidatedHoursDetails
          categories={[
            { category: 'Aula Regular', approved: '16.530h', disapproved: '16.530h', pending: '16.530h' },
            { category: 'Outras Funções', approved: '16.530h', disapproved: '16.530h', pending: '16.530h' },
            { category: 'Zeladoria', approved: '16.530h', disapproved: '16.530h', pending: '16.530h' },
          ]}
        />

        <H6>DISTRIBUIÇÃO DE HORAS APROVADAS:</H6>
        <DistributionOfAprrovedHours />

        <H6>HORAS LANÇADAS:</H6>

        <AccordionInfoItem title='Aprovadas'>
          {new Array(10).fill('').map((_o, i) => <ProjectHourCard key={i} />)}
        </AccordionInfoItem>

        <AccordionInfoItem title='Pendentes'>
          {new Array(10).fill('').map((_o, i) => <ProjectHourCard key={i} />)}
        </AccordionInfoItem>
      </ProjectDefaultStylesContainer>
    </Layout>
  );
};

export default ProjectHoursSpent;
