import styled, { css } from 'styled-components';
import { ITagsRenderProps } from './index';

export const ContainerTagsRender = styled.div<Pick<ITagsRenderProps, 'required'>>`
  .label-container {
    > label {
      display: flex;
      align-items: flex-end;
      line-height: 1.5rem;

      ${(p) =>
        p.required &&
        css`
          ::after {
            content: '*';
            margin-left: 4px;
            color: red;
          }
        `}
    }

    display: flex;
    justify-content: space-between;
  }
`;

export const ContainerList = styled.div<ITagsRenderProps>`
  display: flex;
  width: 100%;
  min-height: 40px;
  padding: 7px 16px;
  border-radius: 4px;
  background-color: #f7f9fc;
  border: 2px solid #e4e9f2;
  margin-top: 4px;
  flex-wrap: wrap;
  row-gap: 4px;

  .input-pill-placeholder {
    font-weight: 600;
    font-size: 12px;
    color: #c5cee0;
    padding: 2px;
    font-weight: 800;
  }

  .tag {
    border-radius: 4px;
    cursor: ${(p) => (p.canRemoveTag ? 'pointer' : 'default')};

    ${(p) =>
      p.canRemoveTag &&
      `
      display: flex;
      padding-right: 2px;
      i {
        display: flex;
        margin-left: 5px;
      }
    `}
  }
`;
