import React, { useCallback } from 'react';
import CustomCard from '@shared/components/CustomCard';

import Tag from '@modules/tags/components/Tag';

import { CSSProperties } from 'styled-components';

import { Name, Info, Container, HeaderContainer, DurationContainer } from './styles';
import IProject from '@modules/projects/interfaces/IProject';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { translateProjectStatus } from '@modules/projects/schemas/Project/partials/ProjectStatus';

export interface ProjectCardProps {
  project?: IProject;
  selected?: IProject[];
  canSelect?: boolean;
  inputCard?: boolean;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  customStyle?: CSSProperties;
  handleOnClick?: (clicked: any) => void;
  hideDescription?: boolean
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  selected,
  canSelect,
  inputCard,
  canDeleteCard,
  customStyle,
  handleOnClick,
  hideDescription = false
}) => {

  const cardShadow = useCallback(() => {
    if (canSelect && selected?.find((p) => p.id === project?.id)) return 'drop-shadow(0px 0px 28px #49C56C)';
    return 'none';
  }, [canSelect, project, selected]);

  const getTotalStudents = (contracts) => {
    if (!contracts || contracts.length === 0) return 'Sem informação';
    const totalStudents = contracts.reduce((total, contract) => total + (contract.number_of_students || 0), 0);
    return totalStudents > 0 ? totalStudents : 'Sem informação';
  };

  return (
    <Container>
      <CustomCard
        handleOnClick={() => handleOnClick && handleOnClick(project)}
        header={
          <HeaderContainer canDeleteCard={!!canDeleteCard}>
            <Tag tag={{ color: '#E4E9F2', name: translateProjectStatus(project?.status) }} />
            <p>Alunos Contratados: <b>{getTotalStudents(project?.gif_contract_businesses)} </b></p>
          </HeaderContainer>
        }
        footer={
          <>
            <DurationContainer hideDescription={hideDescription}>
            </DurationContainer>
            {!hideDescription && <AccordionInfoItem title='Descrição do Projeto'>
              {project?.description || ''}
            </AccordionInfoItem>}

          </>
        }
        canDeleteCard={canDeleteCard}
        inputCard={inputCard}
        customStyles={{
          border: 'none',
          filter: `${cardShadow()}`,
          ...customStyle,
        }}
      >
        <Name>{project?.name || ''}</Name>
        <Info>{project?.group?.name}</Info>
      </CustomCard>
    </Container>
  );
};

export default ProjectCard;
