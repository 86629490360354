import IRoute from '@shared/interfaces/IRoute';

import OccurrencesClassesSelection from '@modules/occurrences/views/OccurrencesClassesSelection';
import OccurrencesGroupsSelection from '@modules/occurrences/views/OccurrencesGroupsSelection';
import OccurrencesPlacesSelection from '@modules/occurrences/views/OccurrencesPlacesSelection';
import OccurrencesSolved from '@modules/occurrences/views/OccurrencesSolved';
import OccurrencesPending from '@modules/occurrences/views/OccurrencesPending';
import OccurrenceCreate from '@modules/occurrences/views/OccurrenceCreate';
import OcurrencesGroupsRelationCreate from '@modules/occurrences/views/OcurrenceGroupsRelationCreate';
import OcurrencesClassRelationCreate from '@modules/occurrences/views/OcurrenceClassRelationCreate';
import OccurencesPlacesRelationCreate from '@modules/occurrences/views/OccurencePlacesRelationCreate';
import OccurrenceStudentsRelationCreate from '@modules/occurrences/views/OccurrenceStudentsRelationCreate';
import OccurrenceUsersRelationCreate from '@modules/occurrences/views/OccurrenceUsersRelationCreate';
import OccurrenceTagRelation from '@modules/occurrences/views/OccurrenceTagRelation';
import OccurenceInfo from '@modules/occurrences/views/OccurenceInfo';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { occurrencesKeys } from '@modules/occurrences/keys';
import { classesKeys } from '@modules/classes/keys';
import { groupsKeys } from '@modules/groups/keys';
import { placesKeys } from '@modules/places/keys';
import { studentsKeys } from '@modules/students/keys';
import { tagsKeys } from '@modules/tags/keys';
import OccurrencesInvalid from '../views/OccurrencesInvalid';

const routes: IRoute[] = [
  {
    path: 'ocorrencias/contexto/turmas',
    key: 'OCCURRENCES_CONTEXT_SELECTION_CLASSES_PATH',
    exact: true,
    component: OccurrencesClassesSelection,
    permissions: [
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: 'ocorrencias/contexto/grupos',
    key: 'OCCURRENCES_CONTEXT_SELECTION_GROUPS_PATH',
    exact: true,
    component: OccurrencesGroupsSelection,
    permissions: [
      groupsKeys.GROUP_READ,
    ]
  },
  {
    path: 'ocorrencias/contexto/locais',
    key: 'OCCURRENCES_CONTEXT_SELECTION_PLACES_PATH',
    exact: true,
    component: OccurrencesPlacesSelection,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: 'ocorrencias/:context/:context_id/pendentes',
    key: 'OCCURRENCES_PENDING_PATH',
    exact: true,
    component: OccurrencesPending,
    permissions: [
      occurrencesKeys.OCCURRENCES_READ,
    ]
  },
  {
    path: 'ocorrencias/:context/:context_id/solucionadas',
    key: 'OCCURRENCES_SOLVED_PATH',
    exact: true,
    component: OccurrencesSolved,
    permissions: [
      occurrencesKeys.OCCURRENCES_READ,
    ]
  },
  {
    path: 'ocorrencias/:context/:context_id/invalidadas',
    key: 'OCCURRENCES_INVALID_PATH',
    exact: true,
    component: OccurrencesInvalid,
    permissions: [
      occurrencesKeys.OCCURRENCES_READ,
    ]
  },
  {
    path: 'ocorrencias/criar',
    key: 'OCCURRENCES_CREATE_PATH',
    exact: true,
    component: OccurrenceCreate,
    permissions: [
      occurrencesKeys.OCCURRENCES_CREATE,
    ]
  },
  {
    path: 'ocorrencias/criar/selecionar/turmas',
    key: 'OCCURRENCES_CREATE_SELECT_CLASSES_PATH',
    exact: true,
    component: OcurrencesClassRelationCreate,
    permissions: [
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: 'ocorrencias/criar/selecionar/grupos',
    key: 'OCCURRENCES_CREATE_SELECT_GROUPS_PATH',
    exact: true,
    component: OcurrencesGroupsRelationCreate,
    permissions: [
      groupsKeys.GROUP_READ,
    ]
  },
  {
    path: 'ocorrencias/criar/selecionar/locais',
    key: 'OCCURRENCES_CREATE_SELECT_PLACE_PATH',
    exact: true,
    component: OccurencesPlacesRelationCreate,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: 'ocorrencias/criar/selecionar/usuarios',
    key: 'ADD_OCCURRENCE_USER_PATH',
    exact: true,
    component: OccurrenceUsersRelationCreate,
    permissions: [
      placesKeys.PLACE_READ,
    ]
  },
  {
    path: 'ocorrencias/criar/selecionar/alunos',
    key: 'ADD_OCCURRENCE_STUDENT_PATH',
    exact: true,
    component: OccurrenceStudentsRelationCreate,
    permissions: [
      studentsKeys.STUDENTS_READ
    ]
  },
  {
    path: 'ocorrencias/criar/selecionar/tags',
    key: 'ADD_OCCURRENCE_TAG_PATH',
    exact: true,
    component: OccurrenceTagRelation,
    permissions: [
      tagsKeys.TAGS_READ
    ]
  },
  {
    path: 'ocorrencia/:id/informacoes',
    key: 'OCCURRENCE_INFO',
    exact: true,
    component: OccurenceInfo,
    permissions: [
      occurrencesKeys.OCCURRENCES_READ,
    ]
  },
];

export default {
  key: 'OCURRENCES_MODULE',
  routes,
} as IModuleRoutes;
