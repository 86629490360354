import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, useToast, Spinner } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { occurrenceSchema } from '@modules/occurrences/utils/occurrenceSchema';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { formatOccurrence } from '@modules/occurrences/utils/formatOccurrence';
import ConfirmModal from '@shared/components/ConfirmModal';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { AttendanceOccurrenceForm } from '@modules/attendances/components/AttendanceOccurrenceForm';
import { Container } from './styles';
import GetClassService from '@modules/classes/services/GetClassService';
import { DateHelper } from '@shared/helpers/DateHelper';

export const AttendancesOccurrences: React.FC = () => {
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModalConfirmCrate, setShowModalConfirmCrate] = useState<boolean>(false);
  const { occurrenceCurrent, setOccurrenceCurrent, createOccurrence } = useOccurrences();
  const [occurrenceValidated, setOccurrenceValidated] = useState<IOccurrence>();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();

  const setInitialOccurrenceData = useCallback(async () => {
    if (!signedUser || !id) return;
    setIsLoading(true);
    const res = await new GetClassService(signedUser.token).execute(id);
    setIsLoading(false);

    if (!res) {
      setIsLoading(false);
      addToast({
        status: 'danger',
        title: 'Presenças',
        description: 'Falha ao tentar fazer chamada. Tente novamente.',
      });
      return;
    }

    setOccurrenceCurrent((oldState: IOccurrence) => {
      const { group, place, ...rest } = res;
      if (!oldState) return { class: rest, group, place };
      return { ...oldState, class: rest, group, place };
    });
  }, [addToast, id, setIsLoading, setOccurrenceCurrent, signedUser]);

  useEffect(() => {
    setInitialOccurrenceData();
  }, [setInitialOccurrenceData]);

  const canSaveOccurence = useCallback(async () => {
    if (!occurrenceCurrent) return;
    const formatedBody = formatOccurrence(occurrenceCurrent) as IOccurrence;
    console.log(formatedBody, 'create occurrence body para backend');
    let formErros = await beforeSubmitForm(formatedBody, occurrenceSchema);
    if (!formatedBody.group_id && !formatedBody.place_id && !formatedBody.class_id)
      formErros = [...formErros, { name: 'context', message: 'É necessário adicionar pelo menos uma Turma, Grupo ou Lugar' }];

    if (formatedBody.date && formatedBody.date > DateHelper.getUTCDate(new Date())) {
      formErros = [...formErros, { name: 'date', message: 'A combinação do Dia e Hora não devem ser maior do que tempo atual' }];
    }

    setErrors(formErros);
    if (formErros.length === 0) {
      setOccurrenceValidated(formatedBody);
      setShowModalConfirmCrate(true);
    }
  }, [occurrenceCurrent]);

  const handleCreateOccurrence = useCallback(
    async (occurrenceBody: IOccurrence) => {
      setShowModalConfirmCrate(false);
      await createOccurrence(occurrenceBody);
      navigate(`/presencas/${id}/criar`);
    },
    [createOccurrence, navigate, id]
  );

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Ocorrências" backgroundHeader="linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)" />

      <AttendanceOccurrenceForm
        errors={errors}
        pathEnvolvedTags="/ocorrencias/criar/selecionar/tags"
        pathEnvolvedStudents="/ocorrencias/criar/selecionar/alunos"
        handleOnFinish={handleCreateOccurrence}
      />

      <PageFooter>
        <ButtonGradientOutline onClick={() => navigate(`/presencas/${id}/criar`)} status="occurrence" textColor="#fff">
          Cancelar
        </ButtonGradientOutline>

        <Button onClick={canSaveOccurence} style={{ background: '#568CF7', border: 'none', whiteSpace: 'break-spaces' }} transform="none" shaded>
          Salvar
        </Button>
      </PageFooter>

      {occurrenceValidated && (
        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showModalConfirmCrate}
          closeCallback={() => setShowModalConfirmCrate(false)}
          title="Deseja criar a Ocorrencia?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={() => handleCreateOccurrence(occurrenceValidated)}
        />
      )}
    </Container>
  );
};
