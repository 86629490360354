import styled from 'styled-components';

export const DashedBoxContainer = styled.div`
  position: relative;
  padding: 20px 5px;
  border: 2px dashed #e4e9f2;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 10px 0px 10px;

  .remove-card {
    transform: translate(50%, -50%);
    top: 0px;
    right: 0px;
    cursor: pointer;
    position: absolute;
    width: 30px;
    aspect-ratio: 1;
    background: red;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
