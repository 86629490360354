import themeGet from "@styled-system/theme-get";
import { ElementStatus } from "react-alicerce-components/dist/shared/theme/colors";
import styled from "styled-components";

export const Container = styled.div<{status?:ElementStatus, canConclude?: boolean}>`
    position: relative;
    display: flex;

    label {
        margin-top: 1.8rem;
    }

    .custom-card {
        width: ${({ canConclude }) => canConclude ? '90%' : '100%'};
        margin: 0;
    }

    .card-header {
        height: 30px;
        padding: .2rem;

        h6 {
            text-align: left;
        }
    }

    .card-body {
        padding: 20px;
    }

    .card-footer {
        display: ${({ canConclude }) => canConclude ? 'none' : 'initial'};
        padding: 10px;
    }

    input + div {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background: rgba(143, 155, 179, 0.16);
        border: 1px solid #C5CEE0;
    }

    input:checked + div {
        border: 1px solid #C5CEE0;
        border-color: transparent;

        svg {
            color: #fff;
        }
    }
`;

export const DateRender = styled.div`
    position: absolute;
    bottom: -1px;
    left: -1px;
`;

export const Header = styled.div<{status?:ElementStatus; dateBgColor?: string}>`
    ${DateRender} {
        background-color: ${({ status, dateBgColor }) => dateBgColor ? dateBgColor : themeGet(`colors.${status}300`)};
        color: #fff;
        font-size: 11px;
        font-weight: 800;
        height: 40px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px 4px 0 0;
        line-height: 14px;
    }
`;

export const Footer = styled.div`
    font-size: 10px;
    font-weight: 600;
    color: #fff;
`;