import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { IFormattedWorkGroupBody } from '../interfaces/IFormattedWorkGroupBody';

class EditWorkGroupService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(workGroupId: string, workGroupBody: IFormattedWorkGroupBody): Promise<IGroup | false> {
    const { class_id, ...workGroupBodyWithoutClassId } = workGroupBody;
    try {
      const res = await this.api.put(`/work-group/${workGroupId}`, { ...workGroupBodyWithoutClassId });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditWorkGroupService;
