import React, { createContext, useContext, useState } from 'react';

export type IListControllerContextContextData = {
  openTab?: 'program' | 'activity';
  setOpenTab: (value?: 'program' | 'activity') => void;
  isAdvancedFilterOpen: boolean;
  setIsAdvancedFilterOpen: (value: boolean) => void;
};

const ListControllerContext = createContext<IListControllerContextContextData>({} as IListControllerContextContextData);

const ListControllerProvider: React.FC = ({ children }) => {
  const [openTab, setOpenTab] = useState<'program' | 'activity'>();
  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState<boolean>(false);

  return (
    <ListControllerContext.Provider
      value={{
        openTab,
        setIsAdvancedFilterOpen,
        setOpenTab,
        isAdvancedFilterOpen,
      }}
    >
      {children}
    </ListControllerContext.Provider>
  );
};

function useAdvancedFilterController() {
  const context = useContext(ListControllerContext);

  if (!context) {
    throw new Error('useAdvancedFilterController must be used within a ListControllerProvider');
  }

  return context;
}

export { ListControllerProvider, useAdvancedFilterController };
