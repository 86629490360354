export type TYPES_STEERINGS_THERMOMETER = 'negative' | 'positive' | 'neutral';

export const STEERING_THERMOMETER_POSITIVE_CONSTRAINTS = 'positive';
export const STEERING_THERMOMETER_NEGATIVE_CONSTRAINTS = 'negative';
export const STEERING_THERMOMETER_NEUTRAL_CONSTRAINTS = 'neutral';

export const STEERING_THERMOMETER_POSITIVE = { label: 'Positiva', value: 'positive', statusColor: { backgroundColor: '#00d68f', textColor: '#ffffff' } };
export const STEERING_THERMOMETER_NEGATIVE = { label: 'Negativa', value: 'negative', statusColor: { backgroundColor: '#db2c66', textColor: '#ffffff' } };
export const STEERING_THERMOMETER_NEUTRAL = { label: 'Neutra', value: 'neutral', statusColor: { backgroundColor: '#ffffff', textColor: '#2e3a59' } };

export const STEERING_THERMOMETER_CONSTRAINTS = [
  STEERING_THERMOMETER_POSITIVE_CONSTRAINTS,
  STEERING_THERMOMETER_NEGATIVE_CONSTRAINTS,
  STEERING_THERMOMETER_NEUTRAL_CONSTRAINTS,
];
export const STEERING_THERMOMETER_OPTIONS = [STEERING_THERMOMETER_POSITIVE, STEERING_THERMOMETER_NEGATIVE, STEERING_THERMOMETER_NEUTRAL];
export const STEERING_THERMOMETER_VALUES = STEERING_THERMOMETER_OPTIONS.map((c) => c.value);

export const defaultSteeringThermometerStatus = (thermometerStatus?: string | null) =>
  STEERING_THERMOMETER_OPTIONS.map((i) => ({ ...i, selected: thermometerStatus === i.value }));

export const translateSteeringThermometerStatus = (status: string = '') => {
  let objMap = {};
  STEERING_THERMOMETER_OPTIONS.forEach((i) => (objMap = { ...objMap, [i.value]: i.label }));
  return objMap[status] || status;
};

export const getSteeringThermometerColor = (status: string = '') => {
  const statusObj = STEERING_THERMOMETER_OPTIONS.find((option) => option.value === status);
  return statusObj ? statusObj.statusColor.backgroundColor : '#ffffff'
};
