import themeGet from "@styled-system/theme-get";
import { styled } from "react-alicerce-components";

export const Container = styled.div`
    display: flex; 
    justify-content: space-between;
    border: 1px solid #BE3A3A;
    border-radius: 8px;

`
export const ImplementationContent = styled.div`
text-align: center;
flex-grow: 1;

.title-implementation-content {
    padding: 8px 16px;
}

.footer-implementation-content {
    padding: 8px 16px;
    background-color:  #BE3A3A;
    color: white;
    border-bottom-left-radius: 6px;

}
`

export const ImplementationCheckBoxContainer = styled.div`
    background-color:${themeGet(`colors.basic300`)};
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 18px;

    .checkbox-container > label > div {
        height: 32px;
        width: 32px;
    }
`


