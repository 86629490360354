import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, H6, Input, Select, Textarea, Divider } from 'react-alicerce-components';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import CustomForm from '@shared/components/CustomForm';
import { CheckboxTag, CheckboxTagsGroup } from '@shared/components/CheckboxTag';
import { IInputErrorsFormat, errorMessageBuilder, hasError } from '@shared/utils/beforeSubmitForm';

import GroupCard from '@modules/groups/components/GroupCard';
import { useProjects } from '@modules/projects/context/ProjectsContext';

import { useProjectForm } from '../useProjectForm';
import { defaultProjectType } from '@modules/projects/schemas/Project/partials/ProjectType';
import { defaultProjectStatus } from '@modules/projects/schemas/Project/partials/ProjectStatus';
import { defaultProjectJanitorialType } from '@modules/projects/schemas/Project/partials/JanitorialType';
import { defaultUfOptions } from '@shared/utils/cepInfo';
import { PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS_OPTIONS } from '@modules/projects/schemas/Project/partials/ProjectAdditionalInformation';
import ContractCard from '@modules/contracts/components/ContractCard';
import { ImplemetationStageCard } from '@modules/projects/components/ImplemetationStageCard';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';

interface ProjectInfoFragmentProps {
    disabled?: boolean
    errors?: IInputErrorsFormat[];
    pathAddGroup?: string;
    pathAddContract?: string
}

export const ProjectInfoFragment: React.FC<ProjectInfoFragmentProps> = ({
    disabled,
    pathAddGroup = '',
    pathAddContract = '',
}) => {

    const navigate = useNavigate();
    const { projectCurrent, errors } = useProjects();
    const [isAtipicaly, setIsAtipicaly] = useState(Boolean(projectCurrent?.description_typicality) ?? false)
    const {
        handleSelectChange,
        handleChangeInput,
        handleRemoveField,
        handleRemoveFieldArray,
        handleOnChangeCheckbox,
        handleCheckboxImplementationStage
    } = useProjectForm()

    const handleChangeIsAtipicaly = () => {
        setIsAtipicaly(!isAtipicaly)
        handleRemoveField('description_typicality')
    }
    const RenderImplementationStages = () => {
        if (projectCurrent?.implementation_stage && projectCurrent.implementation_stage.length > 0) {
            return (
                <AccordionInfoItem title='Capítulos da Implantação'>
                    <Grid dFlex flexColumn mt4 g2>
                        {projectCurrent.implementation_stage.map((stage) => (
                            <ImplemetationStageCard
                                implementationStage={stage}
                                disable={disabled}
                                handleOnChange={handleCheckboxImplementationStage}
                            />))}
                    </Grid>

                </AccordionInfoItem>)
        }

        return null
    }

    return (
        <Grid row>
            <H6>INFORMAÇÕES BÁSICAS:</H6>
            <CustomForm status="primary">
                <Grid row>
                    <Grid sm={12} md={4} mt4>
                        <Input
                            disabled={disabled}
                            label="Nome do Projeto:"
                            name="name"
                            placeholder="Adicione o nome do projeto"
                            onChange={handleChangeInput}
                            defaultValue={projectCurrent?.name}
                            useTooltipError={false}
                            error={errorMessageBuilder('name', errors)}
                            required
                        />
                    </Grid>
                    <Grid sm={12} md={4} mt4>
                        <Select
                            disabled={disabled}
                            label="Tipo do Projeto:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultProjectType(projectCurrent?.type)}
                            error={errorMessageBuilder('type', errors)}
                            name='type'
                            required
                        />
                    </Grid>
                    <Grid sm={12} md={4} mt4>
                        <Select
                            disabled={disabled}
                            label="Status do Projeto:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultProjectStatus(projectCurrent?.status)}
                            error={errorMessageBuilder('status', errors)}
                            name='status'
                            required
                        />
                    </Grid>
                </Grid>

                <Grid row>
                    <Grid sm={9} md={6} mt4>
                        <Input
                            disabled={disabled}
                            name="city"
                            placeholder="Cidade"
                            label="Cidade:"
                            onChange={handleChangeInput}
                            defaultValue={projectCurrent?.city}
                            useTooltipError={false}
                            required
                            error={errorMessageBuilder('city', errors)}
                        />
                    </Grid>
                    <Grid sm={3} md={3} mt4>
                        <Select
                            disabled={disabled}
                            name="state"
                            iconName="arrow-down"
                            placeholder="UF"
                            label="UF:"
                            handleSelectChange={handleSelectChange}
                            options={defaultUfOptions(projectCurrent?.state)}
                            required
                            error={errorMessageBuilder('state', errors)}
                        />
                    </Grid>
                </Grid>

                <Grid row mt4>
                    <Textarea
                        disabled={disabled}
                        name="description"
                        label="Descrição do Projeto:"
                        placeholder="Adicione uma descrição"
                        onChange={handleChangeInput}
                        value={projectCurrent?.description}
                        rows={8}
                        required
                        error={errorMessageBuilder('description', errors)}
                    />
                </Grid>
                <RenderImplementationStages />
                <AccordionInfoItem
                    title='Dados de Executor'
                    hasErrors={hasError(['janitorial_type'], errors)}
                >
                    <Grid row>
                        {!disabled && <ButtonAddCard text="Adicione Grupo" handleOnClick={() => disabled ? undefined : navigate(pathAddGroup)} />}

                        {projectCurrent?.group &&
                            <GroupCard
                                group={projectCurrent?.group}
                                inputCard={true}
                                customStyle={{ margin: '1rem' }}
                                canDeleteCard={disabled ? undefined : { removeCallback: () => handleRemoveField('group') }}
                            />
                        }
                    </Grid>

                    <Divider width="100%" />


                    <Grid row >
                        <Grid sm={9} md={6} mt4>
                            <Select
                                label="Tipo de Zeladoria:"
                                iconName="arrow-down"
                                placeholder="Selecione"
                                handleSelectChange={handleSelectChange}
                                options={defaultProjectJanitorialType(projectCurrent?.janitorial_type)}
                                error={errorMessageBuilder('janitorial_type', errors)}
                                name='janitorial_type'
                                disabled={disabled}
                                required
                            />
                        </Grid>
                        <Grid sm={3} md={6} mt4>
                            <CheckboxTag
                                value='atypicality'
                                name='atypicality'
                                label='Projeto com Atipicidade'
                                key={projectCurrent?.description_typicality}
                                defaultChecked={isAtipicaly || Boolean(projectCurrent?.description_typicality)}
                                disabled={disabled}
                                handleOnSetState={handleChangeIsAtipicaly}
                            />
                        </Grid>
                    </Grid>

                    {(projectCurrent?.description_typicality || isAtipicaly) && <Grid row mt4>
                        <Textarea
                            disabled={disabled}
                            name="description_typicality"
                            label="Descrição da atipicidade:"
                            placeholder="Adicione uma descrição"
                            onChange={handleChangeInput}
                            value={projectCurrent?.description_typicality || undefined}
                            rows={8}
                        />
                    </Grid>}
                </AccordionInfoItem>

                <AccordionInfoItem title='Dados de Governança'>
                    <Grid row>
                        <Grid sm={12} md={6} mt4>
                            <Input
                                disabled={disabled}
                                name="link"
                                placeholder="Adicione Link"
                                label="Link:"
                                onChange={(event) => handleChangeInput(event, 'governance_links')}
                                defaultValue={projectCurrent?.governance_links?.link}
                                useTooltipError={false}
                                required
                                error={errorMessageBuilder('link', errors)}
                            />
                        </Grid>
                    </Grid>
                </AccordionInfoItem>

                <AccordionInfoItem title='Dados Adicionais'
                    hasErrors={hasError(['additional_information', 'gif_contracts_business_id'], errors)}
                >
                    <Grid row mt4>
                        <CheckboxTagsGroup
                            handleOnChange={(state) => handleOnChangeCheckbox('additional_information', state)}
                            disabled={disabled}
                            itens={PROJECT_ADDITIONAL_INFORMATION_CONSTRAINS_OPTIONS}
                            defaultValues={projectCurrent?.additional_information || undefined}
                        />
                        {!disabled && <InputErrorMessage error={errorMessageBuilder('additional_information', errors)} />}
                    </Grid>

                    <Divider width="100%" />

                    <Grid row mt4>
                        <H6>CONTRATOS:</H6>
                        {!disabled && <InputErrorMessage error={errorMessageBuilder('gif_contracts_business_ids', errors)} />}
                        {!disabled && (
                            <ButtonAddCard
                                text="Adicione Contrato"
                                handleOnClick={() => (disabled ? undefined : navigate(pathAddContract))}
                            />
                        )}
                        {projectCurrent && projectCurrent?.gif_contract_businesses && projectCurrent?.gif_contract_businesses?.length > 0 && (
                            projectCurrent.gif_contract_businesses.map((contract, index) => (
                                <ContractCard
                                    key={contract.id || index}
                                    contract={contract}
                                    canDeleteCard={disabled ? undefined : {
                                        removeCallback: () => handleRemoveFieldArray('gif_contract_businesses', index)
                                    }}
                                    hideDescription
                                />
                            ))
                        )}
                    </Grid>
                </AccordionInfoItem>
            </CustomForm>
        </Grid>
    );
}
