import React, { useCallback, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import { useNavigate } from 'react-router-dom';

import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { IEvent } from '@modules/schedules/interfaces/IEvent';
import { formatEventsForFullCalendar } from '@modules/schedules/utils/formatEventsForFullCalendar';
import { formatSprintsForFullCalendar } from '@modules/schedules/utils/formatSprintsForFullCalendar';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';
import { getBrDate } from '@shared/helpers/DateHelper';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import ListSprintsService from '@modules/schedules/services/ListSprintsService';
import ListEventsService from '@modules/schedules/services/ListEventsService';

import { Container } from './styles';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

interface IScheduleProps {
  filterBy?: { [key: string]: string };
  search?: string;
}

const Schedule: React.FC<IScheduleProps> = ({ filterBy, search = '' }) => {
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const { setEventCurrent, setSprintCurrent, scheduleCurrent, state } = useSchedules();
  const { setIsLoading } = useGlobal();

  const [query, setQuery] = useState<string>('');
  const [events, setEvents] = useState<IEvent[]>([]);
  const [sprints, setSprints] = useState<ISprint[]>([]);

  const handleEventOnClick = useCallback(
    async (data) => {
      const clickedSprintOrEvent = data.event.extendedProps.data;
      const clickedType = data.event.extendedProps.type;

      if (clickedType === 'event') {
        setEventCurrent({
          ...clickedSprintOrEvent,
          start_date: getBrDate(clickedSprintOrEvent.start_date),
          end_date: getBrDate(clickedSprintOrEvent.end_date),
        });
        navigate('/agenda/evento/editar', { state: state });
        return;
      }

      if (clickedType === 'sprint') {
        setSprintCurrent({
          ...clickedSprintOrEvent,
          start_date: getBrDate(clickedSprintOrEvent.start_date),
          end_date: getBrDate(clickedSprintOrEvent.end_date),
        });
        navigate('/agenda/sprint/editar', { state: state });
        return;
      }
    },
    [navigate, setEventCurrent, setSprintCurrent, state]
  );

  const listEvents = useCallback(async () => {
    if (!signedUser) return;

    setIsLoading(true);

    const cancel = newCancelToken();
    const filter = scheduleCurrent?.id ? { schedule_id: scheduleCurrent?.id } : filterBy;

    const events = await new ListEventsService(signedUser.token, cancel).execute(query, { ...filter, size: 9999 });
    const sprints = await new ListSprintsService(signedUser.token, cancel).execute(query, filter);

    if (events === 'canceling' || sprints === 'canceling') return;
    setIsLoading(false);

    if (!events || !sprints) return;

    setEvents(events.items);
    setSprints(sprints.items);
  }, [filterBy, newCancelToken, query, scheduleCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    listEvents();
  }, [listEvents, navigate, scheduleCurrent, state]);

  useEffect(() => {
    setQuery(search);
  }, [search]);

  return (
    <Container>
      <FullCalendar
        // timeZone="UTC"
        eventClick={handleEventOnClick}
        plugins={[listPlugin]}
        initialView="listWeek"
        height={'calc(100vh - 230px)'}
        events={[...formatEventsForFullCalendar(events), ...formatSprintsForFullCalendar(sprints)]}
        locale="pt-BR" // locale="en-GB" resolve o problema de 24:00 -> 00:00
        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
        views={{
          listDay: { buttonText: 'Dia' },
          listWeek: { buttonText: 'Semana' },
          listMonth: { buttonText: 'Mês' },
        }}
        allDayText="Dia todo"
        buttonText={{ today: 'Hoje' }}
        headerToolbar={{
          left: 'prev next,today',
          center: 'title',
          right: 'listDay,listWeek,listMonth',
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          meridiem: false,
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          meridiem: false,
        }}
      />
    </Container>
  );
};

export default Schedule;
