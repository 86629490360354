import { IDynamicTableColumnsGroup } from "@shared/components/DynamicTable";
import { IBlock } from "@shared/interfaces/IBlock";
import { ITrail } from "@shared/interfaces/ITrail";
import { convertHexToHsl } from "@shared/utils/convertHexToHsl";
import { IGroupLearningCurrent } from "../interfaces/IGroupLearningCurrent";
import { getYearsOld } from "./getYearsOld";

export interface IFormattedGroupLearningCurrent {
  columns: IDynamicTableColumnsGroup[],
  data: any;
}

interface ITrails  {
  [key:number]: ITrail;
}

export const getBlockBackgroundColor = (block?: IBlock) => {
  const colorLevel = block?.level?.color || '#E4E9F2';
  const colorVariation = block?.color_variation;
  return `hsla${convertHexToHsl(colorLevel, colorVariation)}`;
}

export const formatGroupLearningCurrent = (items: IGroupLearningCurrent[]): IFormattedGroupLearningCurrent => { 

  let trails: ITrails = {};

  items.forEach(d => d?.grades?.map(grade => {
    const trailId = grade?.trail?.id as string;
    if(!trails[trailId] && trailId !== '' && grade.block) {
      trails[trailId] = grade.trail as ITrail;
    }
  }));

  const columns = [
    {
      columns: [
          { name: 'Alunos', dataname: 'name' },
          { name: 'Idade', dataname: 'age' }
      ],
        fixed: true
    },
    {
      columns: Object.entries(trails).map(([_key, trail]) => ({ name: `${trail?.name.substring(0, 4)}.`, dataname: `trail${trail?.id}` })),
      fixed: false
  }
  ];

  const data = items.map((d) => {
   
    const student = {
      id: d?.student?.id, 
      name: d?.student?.name,
      age: getYearsOld(`${d.student?.birthdate}`),
    }
    
    const organized = d?.grades?.filter((v) => v.block && v.trail.id).map((v) => ({[`trail${v.trail.id}`]: `${v.block?.name}`, [`trail${v.trail.id}BgColor`]: getBlockBackgroundColor(v.block)}));
    return organized ?  Object.assign({...student}, ...organized ) : student;
  });

  return {
    columns,
    data
  }
}