import React, { useMemo } from 'react';

import { IButtonProps } from 'react-alicerce-components';

import { StyledButton } from './styles';

export type IButtonGradientOutlineProps = IButtonProps & {
    textColor?: string;
};

interface IStatusColor {
    bgColor: string;
    color: string;
}

const ButtonGradientOutline: React.FC<IButtonGradientOutlineProps> = ({
    style,
    transform = 'none',
    fill,
    shaded = true,
    className = '',
    status = 'basic',
    children,
    textColor,
    ...rest
}) => {

    const get = useMemo(() => {
        const gradients: { [key: string]: IStatusColor } = {
            basic: {
                bgColor: 'linear-gradient(180deg, #FEFDFF 0%, #D3DCEE 100%) !important',
                color: '#8F9BB3',
            },
            primary: {
                bgColor: 'linear-gradient(180deg, #FEFDFF 0%, #FEEED2 100%) !important',
                color: '#F8A356',
            },
            secondary: {
                bgColor: 'linear-gradient(180deg, #FEFDFF 0%, #C9F3FB 100%) !important',
                color: '#35A0D6',
            },
            tertiary: {
                bgColor: 'linear-gradient(180deg, #FEFDFF 0%, #C8FBF2 100%) !important',
                color: '#34C5D0',
            },
            quaternary: {
                bgColor: 'linear-gradient(180deg, #FEFDFF 0%, #EDD9FF 100%) !important',
                color: '#6E3FA5',
            },
            aviador: { 
                bgColor: 'linear-gradient(180deg,rgba(244,244,244, 0.8) 2.26%,#FF9F6D 68.23%) !important',
                color: '#FF9F6D',
            },
            tags: {
                bgColor: 'linear-gradient(180deg, rgba(255, 255, 255, 0.88) 2.26%, #145496 100%) !important',
                color: '#145496',
            },
            occurrence: {
                bgColor: 'linear-gradient(180deg, rgba(255, 255, 255, 0.88) 2.26%, #568CF7 100%) !important',
                color: '#568CF7',
            },
            library_activity: {
                bgColor: 'linear-gradient(180deg, rgba(255, 255, 255, 0.88) 2.26%, #96B6F7 100%) !important',
                color: '#96B6F7',
            }
        };
        return gradients[status];
    }, [status]);

    return (
        <StyledButton
            style={{
                whiteSpace: 'pre-wrap',
                color: textColor ? textColor : get.color,
                border: `2px solid ${get.color}`,
                ...style
            }}
            className={`button-gradient-outline ${className}`}
            transform={transform}
            fill={get.bgColor}
            shaded={shaded}
            noBorder
            {...rest}
        >
            {children}
        </StyledButton>
    );
}

export default ButtonGradientOutline;