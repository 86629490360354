import React, { useCallback, useMemo } from "react";
import PlaceList from "@modules/places/components/PlaceList";
import IPlace from "@modules/places/interfaces/IPlace";
import { useGroup } from "@modules/groups/context/GroupContext";

const GroupPlacesRelationCreate: React.FC = () => {
  const { setGroupCurrent, groupCurrent } = useGroup();

  const handleOnClick = useCallback(
    (places: IPlace[]) => {
      setGroupCurrent((oldState: any) => {
        return { ...oldState, places: places };
      });
    },
    [setGroupCurrent]
  );

  const selectedItens = useMemo(() => {
    return groupCurrent.places;
  }, [groupCurrent]);

  return (
    <PlaceList
      title="Diretório de Locais"
      btnText="Adicionar Local"
      pathRedirect="/diretorio/grupos/criar"
      pathToReturn="/diretorio/grupos/criar"
      canSelectPlace={'many'}
      handleOnClick={handleOnClick}
      selectedItens={selectedItens}
      setSelectedItens={handleOnClick}
    />
  );
};

export default GroupPlacesRelationCreate;
