import React, { useCallback } from 'react';

import { P } from 'react-alicerce-components';
import CustomCard from '@shared/components/CustomCard';
import { IPortfolioActivity } from '@modules/activities-portfolio/interfaces/IPortfolioActivity';
import Tag from '@modules/tags/components/Tag';

import { ActivityPortfolioCardContainer, ListContainer } from './styles';
import { getBrDate } from '@shared/helpers/DateHelper';
import { useNavigate, useParams } from 'react-router-dom';

interface IPortfolioActivityCardProps {
  portfolio_activity: IPortfolioActivity;
}

const ActivityPortfolioCard: React.FC<IPortfolioActivityCardProps> = ({ portfolio_activity }) => {
  const navigate = useNavigate();
  const { classId } = useParams();

  const handleOnClickCard = useCallback(() => {
    navigate(`/portfolio-atividades/${portfolio_activity.id}/turma/${classId}/informacoes`);
  }, [classId, navigate, portfolio_activity.id]);

  return (
    <ActivityPortfolioCardContainer>
      <CustomCard
        status="info"
        statusFooter="info"
        header={
          <ListContainer>
            {portfolio_activity.created_at && (
              <Tag
                tag={{ name: `Entrega: ${new Intl.DateTimeFormat('pt-br').format(new Date(portfolio_activity.created_at))}` }}
                backgroundColor="#E4E9F2"
                color="#222B45"
                size="small"
              />
            )}

            <Tag
              tag={{ name: `Realização: ${getBrDate(portfolio_activity.delivery_date)}` }}
              backgroundColor="#E4E9F2"
              color="#222B45"
              size="small"
            />

            <Tag tag={{ name: `${portfolio_activity.sprint?.name}` }} backgroundColor="#E4E9F2" color="#222B45" size="small" />

            <Tag
              tag={{ name: `${portfolio_activity.students_count || 0} Alunos Participantes` }}
              backgroundColor="#E4E9F2"
              color="#222B45"
              size="small"
            />
          </ListContainer>
        }
        footer={
          <ListContainer>
            <Tag tag={{ name: portfolio_activity?.type?.toUpperCase() }} backgroundColor="#FFC94D" color="#222B45" size="small" />
          </ListContainer>
        }
        handleOnClick={() => handleOnClickCard()}
      >
        <P fontSize={14} fontWeight={800}>
          {portfolio_activity.activity_name}
        </P>
      </CustomCard>
    </ActivityPortfolioCardContainer>
  );
};

export { ActivityPortfolioCard };
