import React, { useState } from 'react';

import DynamicTable, { IData } from '@shared/components/DynamicTable';

import { Container } from './styles';

interface DistributionOfAprrovedHoursProps { }

const DistributionOfAprrovedHours: React.FC<DistributionOfAprrovedHoursProps> = () => {
    const [state, setState] = useState<IData[]>([
        { id: 1, category: 'Aulas Regulares', hours: '12h', bgColor: '#C5CEE0' },
        { id: 2, category: 'Growth', hours: '12h', bgColor: '#C5CEE0', subItems: [{ category: 'Growth 1', hours: '6h' }, { category: 'Growth 2', hours: '6h' }] },
        { id: 3, category: 'Eventos', hours: '6h' },
        { id: 4, category: 'Semana Gratuita', hours: '6h' },
        { id: 4, category: 'Zeladoria', hours: '6h', bgColor: '#C5CEE0' },
        { id: 5, category: 'Outras Funções', hours: '12h', bgColor: '#C5CEE0', subItems: [{ category: 'Outras Funções 1', hours: '6h' }, { category: 'Outras Funções 2', hours: '6h' }] },
        { id: 6, category: 'Captação de Estudante', hours: '2h' },
        { id: 7, category: 'Recrutamento', hours: '2h' },
        { id: 8, category: 'Formação', hours: '2h' },
        { id: 9, category: 'Líder de Apoio Online', hours: '2h' },
        { id: 10, category: 'Líder Embaixador', hours: '2h' },
        { id: 11, category: 'Suporte a Comunidade', hours: '2h' },
        { id: 12, category: 'Tabulação de Mini Mapa', hours: '2h' },
        { id: 13, category: 'Conversão de Lead', hours: '2h' },
        { id: 14, category: 'Outros', hours: '2h' }
    ])

    const handleSubItems = (data: any) => {
        setState((oldState) => oldState.map((a) => ({ ...a, isOpen: a.id === data?.id ? !Boolean(a.isOpen) : a.isOpen })));
    };


    return (
        <Container>
            <DynamicTable
                columnsGroup={[
                    {
                        columns: [
                            { name: 'Categoria das Horas', dataname: 'category' },
                            { name: 'Horas', dataname: 'hours' }
                        ],
                        fixed: true
                    }
                ]}
                data={state}
                handleSubItems={handleSubItems}
                containSubItems
            />
        </Container>
    );
}

export default DistributionOfAprrovedHours;