import styled from "styled-components";

export const Container = styled.div`
`;

export const ContainerScheduleButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2rem 0;
`;