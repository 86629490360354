import axios, { AxiosInstance, CancelTokenSource } from "axios";
import ApiFactory from "@shared/factories/ApiFactory";
import IPlace from "@modules/places/interfaces/IPlace";

class CreatePlaceService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(placeBody: IPlace): Promise<boolean> {
    try {
      await this.api.post("/place", { ...placeBody });
      return true;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default CreatePlaceService;
