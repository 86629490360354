import { styled } from 'react-alicerce-components';
import galiaImage from '../../assets/galia.png'

export const StyledGaliaGptContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #FF9900;
  border-radius: 99999px;
  cursor: pointer;
  background-image: url(${galiaImage});
  background-size: 90%;
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
  top: 80%;
  left: 80%;
  z-index: 99;
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

export const StyledGaliaTooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  background-color: #8D593B;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 6px;
  position: absolute;
  transition: opacity 0.2s;
  font-size: 1.2rem;
  font-weight: ${props => props.theme.fontWeights.bold};
  padding: ${props => props.theme.space[2]} ${props => props.theme.space[1]};
  transform: translate(-60px, -125%);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: #8D593B transparent transparent transparent;
  }
`;