import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Spinner } from 'react-alicerce-components';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { ITrail } from '@shared/interfaces/ITrail';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';

import { ILevel } from '@modules/activities-library/interfaces/ILevel';
import { IFilterBlocks } from '@modules/work-group/components/BlocksList';
import { ListAllMapa4ModulesService } from '@shared/services/ListAllMapa4ModulesService';
import { formatOptions } from '@modules/activities-library/utils/formatOptions';
import { FilterBlocksContainer } from './styles';

interface IFilterBlocksProps {
  appliedFilters: IFilterBlocks;
  callbackAppliedFilters: (filterToApply: IFilterBlocks) => void;
}

const FilterBlocks: React.FC<IFilterBlocksProps> = ({ appliedFilters, callbackAppliedFilters }) => {
  const [insideFilter, setInsideFilter] = useState<IFilterBlocks>(appliedFilters);
  const [allTrails, setAllTrails] = useState<ITrail[]>();
  const [allLevels, setAllLevels] = useState<ILevel[]>();
  const refTrail = React.createRef<any>();
  const refLevel = React.createRef<any>();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const isMounted = useIsMounted();

  const handleSelectChange = useCallback((keyName: string, keyValue: any | null) => {
    if (!keyValue) return;
    setInsideFilter((oldState: IFilterBlocks) => {
      return { ...oldState, [keyName]: keyValue['value'] };
    });
  }, []);

  const handleReset = useCallback(() => {
    refTrail.current.reset();
    refLevel.current.reset();
    setInsideFilter({ trail_id: undefined, level_id: undefined });
  }, [refLevel, refTrail]);

  const handleApplyFilters = useCallback(() => {
    callbackAppliedFilters(insideFilter);
  }, [callbackAppliedFilters, insideFilter]);

  const listAllSelectOptions = useCallback(async () => {
    if (!signedUser) return;
    setIsLoading(true);
    const res = await new ListAllMapa4ModulesService(signedUser.token).execute({ block: false });
    setIsLoading(false);
    if (!res) return;
    if (isMounted()) {
      setAllTrails(res.trails);
      setAllLevels(res.levels);
    }
  }, [isMounted, setIsLoading, signedUser]);

  useEffect(() => {
    listAllSelectOptions();
  }, [listAllSelectOptions]);

  useEffect(() => {
    setInsideFilter(appliedFilters);
  }, [appliedFilters]);

  return (
    <FilterBlocksContainer>
      {isLoading && <Spinner />}
      <AccordionInfoItem status="info" title="Filtrar Competências" colorHeader="#DAE3F6">
        <div className="container-accordion-content">
          <div className="container-selects">
            <Select
              label="Trilha"
              iconName="arrow-down"
              placeholder="Selecione"
              fullWidth
              ref={refTrail}
              options={formatOptions(allTrails)}
              handleSelectChange={(option) => handleSelectChange('trail_id', option)}
            />

            <Select
              label="Nivel"
              iconName="arrow-down"
              placeholder="Selecione"
              fullWidth
              ref={refLevel}
              options={formatOptions(allLevels)}
              disabled={!!!insideFilter.trail_id}
              handleSelectChange={(option) => handleSelectChange('level_id', option)}
            />
          </div>

          <Divider width="100%" />

          <div className="container-buttons-filter">
            <Button transform="none" noBorder shaded onClick={handleReset} disabled={!!!insideFilter.trail_id?.length}>
              Resetar Filtros
            </Button>
            <Button transform="none" noBorder shaded onClick={handleApplyFilters}>
              Aplicar Filtros
            </Button>
          </div>
        </div>
      </AccordionInfoItem>
    </FilterBlocksContainer>
  );
};

export { FilterBlocks };
