import { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IResourceFormatted } from '../utils/formatResource';

export interface IIndicatorResponse {
  donut_info: {
    name: string;
    percent: number;
    color: string;
  }[];
  sprint: {
    id: number;
    name: string;
    start: string;
    end: string;
  };
  general_info: {
    title: string;
    week: number | undefined;
    sprint: number | undefined;
  }[];
  detailed_info: {
    title: string;
    data: { day: number; percent: number | undefined }[];
  }[];
  previousSprintId: number | undefined;
  nextSprintId: number | undefined;
}

interface IGetIndicatorsOptions {
  sprint_id?: number;
}

class GetIndicatorsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(resource: IResourceFormatted, resourceId: number, options: IGetIndicatorsOptions): Promise<IIndicatorResponse | false> {
    let searchParams = objectToQuery({ ...options });

    try {
      const res = await this.api.get(`/dashboard/indicadores/${resource}/${resourceId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      console.error(`Error: ${error}`);
      return false;
    }
  }
}

export { GetIndicatorsService };
