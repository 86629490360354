import React from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import CustomTabs from '@shared/components/CustomTabs';
import { ILocationState } from '@shared/interfaces/ILocationState';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import { Container } from './styles';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, footer = false, children }) => {
  const { state } = useLocation() as { state: ILocationState };

  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Explorador de Relatórios" pathToReturn="/" backgroundHeader="linear-gradient(45deg, #328DA1 0%, #25BBAB 100%);" />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Relatórios" onClick={() => navigate(`/relatorios`, { state })} />
          {/* <Tab title="Relatórios Salvos" onClick={() => navigate(`/relatorios/salvos`, { state })} /> */}
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default Layout;
