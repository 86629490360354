import { styled } from 'react-alicerce-components';

export const StudentCardLauncherBorderContainer = styled.div<{ location: 'header' | 'footer' }>`
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;

  > div {
    padding: 5px 10px;
    color: #222b45;
    font-weight: 600;
    font-size: 12px;
  }

  ${(p) =>
    p.location === 'header' &&
    `
    border-radius:5px 5px 0px 0px; 
   
    `}

  ${(p) =>
    p.location === 'footer' &&
    `
    border-radius:0px 0px 5px 5px;

  `}
`;

export const Header = styled.div`
  background: #6749ba;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;

  .pill {
    background-color: #e4e9f2;
    color: #222b45;
    padding: 5px 10px;
  }

  .divisor {
    width: 2px;
    content: '';
    height: 60%;
    border: 1px solid #ffffff;
  }
  div {
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
  }

  label {
    font-weight: 700;
    font-size: 12px;
    color: #f7f9fc;
    font-family: var(--secondary-font);
  }

  input + div {
    background: rgba(143, 155, 179, 0.48);
    border: 1px solid #c5cee0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    margin-right: 1rem;
  }

  .pill {
    > div {
      border-radius: 5px;
      background: #ffc94d;
      color: black;
    }
  }
`;

export const Footer = styled.div`
  background: #6749ba;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  width: 100%;
  gap: 12px;
  min-height: 56px;

  .checkbox-container {
    > label > span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
    }
  }

  .divisor {
    width: 2px;
    content: '';
    height: 60%;
    border: 1px solid #ffffff;
  }

  label {
    font-weight: 700;
    font-size: 12px;
    color: #f7f9fc;
    font-family: var(--secondary-font);
  }

  input + div {
    background: rgba(143, 155, 179, 0.48);
    border: 1px solid #c5cee0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    margin-right: 1rem;
  }

  .pill {
    > div {
      border-radius: 5px;
      background: #ffc94d;
      color: black;
    }
  }
`;

export const StudentCardLauncherFooterContainer = styled.div`
  background: #e9d7f9;
  min-height: 60px;
  padding: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #222b45;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 5px 5px;
  width: 100%;
`;

export const When = styled.div`
  gap: 0.5rem;
  display: flex;
  width: 70%;
  font-size: 10px;
  font-weight: 700;
  color: #2e3a59;
  text-align: right;
  flex-direction: column;
  align-items: flex-start;

  > p {
    font-weight: 700;
    font-size: 11px;
    line-height: 10px;
    color: #2e3a59;
  }
`;
