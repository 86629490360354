import React, { createContext, useCallback, useContext, useState } from "react";
import IPlace from "@modules/places/interfaces/IPlace";
import { useGlobal } from "@shared/contexts/global/GlobalContext";
import useAuth from "@shared/store/auth/hook";
import GetPlaceService from "@modules/places/services/GetPlaceService";

export type IPlacesContextData = {
  clearPlaceCreate: () => void;
  placeCurrent?: IPlace;
  setPlaceCurrent: (value: any) => void;
  getPlace: (placeId: string) => Promise<boolean>;
};


const PlacesContext = createContext<IPlacesContextData>(
  {} as IPlacesContextData
);

const PlacesProvider: React.FC = ({ children }) => {
  const [placeCurrent, setPlaceCurrent] = useState<IPlace>({});
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();

  const clearPlaceCreate = useCallback(() => {
    setPlaceCurrent({});
  }, []);

  const getPlace = useCallback(async (placeId: string) => {
    if (!signedUser) return false;
    setIsLoading(true);
    const res = await new GetPlaceService(signedUser.token).execute(placeId);
    setIsLoading(false);
    if (!res) return false;
    setPlaceCurrent(res);
    return true;
  }, [setIsLoading, signedUser]);

  return (
    <PlacesContext.Provider
      value={{
        clearPlaceCreate,
        placeCurrent,
        setPlaceCurrent,
        getPlace
      }}
    >
      {children}
    </PlacesContext.Provider>
  );
};

function usePlaces() {
  const context = useContext(PlacesContext);

  if (!context) {
    throw new Error("usePlacesContext must be used within a PlacesProvider");
  }

  return context;
}

export { PlacesProvider, usePlaces };
