import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'react-alicerce-components';

import { ILocationState } from '@shared/interfaces/ILocationState';
import { useSchedules } from '@modules/schedules/context/SchedulesContext';

import { ITask } from '@modules/tasks/interfaces/ITask';

import Layout from '@modules/tasks/components/Layout';
import TasksListManager from '@modules/tasks/components/TasksListManager';
import PageFooter from '@shared/components/PageFooter';

const TasksDoneList: React.FC = () => {
  const navigate = useNavigate();
  const { setEventCurrent, setScheduleCurrent } = useSchedules();

  const { state } = useLocation() as { state: ILocationState };

  const handleOnClickDoned = useCallback(
    (task: ITask) => {
      if (task.done_by !== null && task.concluder !== null) {
        setEventCurrent(task.event);
        setScheduleCurrent(task.event?.schedule);
        navigate('/tarefas/visualizar', { state: { ...state, task } });
      }
    },
    [navigate, setEventCurrent, setScheduleCurrent, state]
  );

  return (
    <Layout openTab={2}>
      <TasksListManager
        {...state}
        dateKey={'updated_at'}
        prefixDate={'Concluido em:'}
        status="DONE"
        dateBgColor="#00D68F"
        canConclude={false}
        filterByConcluder
        handleOnClickTask={handleOnClickDoned}
      />
      <PageFooter>
        <Button status="quaternary" transform="none" noBorder shaded onClick={() => navigate(`/tarefas/criar`, { state })}>
          Criar Tarefa
        </Button>
      </PageFooter>
    </Layout>
  );
};

export default TasksDoneList;
