import { styled } from 'react-alicerce-components';

export const ClassCardEnrollmentContainer = styled.div`
  display: flex;
  gap: 1rem;

  .card-container {
    flex: 1;
    width: 100%;
  }

  .remove-card {
    cursor: pointer;
    width: 30px;
    height: 30px;
    aspect-ratio: 1;
    background: red;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
export const DotStatus = styled.div`
  width: calc(40% - 2px);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #f63e3c;
`;
