import React, { createContext, useContext, useState, useCallback } from 'react';
import { IPortfolioActivity } from '../interfaces/IPortfolioActivity';

export type IActivitiesPortfolioContextData = {
  activityPortfolioCurrent: IPortfolioActivity;
  setActivityPortfolioCurrent: (value: any) => void;
  clearPortfolioActivityCurrent: () => void;
};

const ActivitiesPortfolioContext = createContext<IActivitiesPortfolioContextData>({} as IActivitiesPortfolioContextData);

const ActivitiesPortfolioProvider: React.FC = ({ children }) => {
  const [activityPortfolioCurrent, setActivityPortfolioCurrent] = useState<IPortfolioActivity>({});
  const clearPortfolioActivityCurrent = useCallback(() => {
    setActivityPortfolioCurrent({});
  }, [setActivityPortfolioCurrent]);

  return (
    <ActivitiesPortfolioContext.Provider
      value={{
        activityPortfolioCurrent,
        setActivityPortfolioCurrent,
        clearPortfolioActivityCurrent,
      }}
    >
      {children}
    </ActivitiesPortfolioContext.Provider>
  );
};

function useActivitiesPortfolio() {
  const context = useContext(ActivitiesPortfolioContext);

  if (!context) {
    throw new Error('useActivitiesPortfolio must be used within a ActivitiesPortfolioProvider');
  }

  return context;
}

export { ActivitiesPortfolioProvider, useActivitiesPortfolio };
