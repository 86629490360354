import { styled } from 'react-alicerce-components';
import { css } from 'styled-components';
import { IParentRenderProps } from '.';

export const Container = styled.div<Pick<IParentRenderProps, 'required'>>`
  position: relative;
  margin: var(--card-distance);

  .container-header {
    display: flex;
    justify-content: space-between;

    .container-header-label {
      font-family: var(--secondary-font);
      color: #2e3a59;
      font-size: 14px;
      font-weight: 700;

      ${(p) =>
        p.required &&
        css`
          ::after {
            content: '*';
            margin-left: 4px;
            color: red;
          }
        `}
    }
  }

  .input-container {
    margin: 4px 0px 0px 0px;
  }
`;
