import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import Icon from 'react-eva-icons';

import { useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useTags } from '@modules/tags/context/TagsContext';

import ConfirmModal from '@shared/components/ConfirmModal';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import ITag from '@modules/tags/interfaces/ITag';
import UpdateTagService from '@modules/tags/services/UpdateTagService';
import DeleteTagService from '@modules/tags/services/DeleteTagService';
import TagForm from '@modules/tags/components/TagForm';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import GetTagService from '../../services/GetTagService';

const TagEdit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { tagCurrent, setTagCurrent } = useTags();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const handleEditTag = useCallback(
    async (tagValidated: ITag) => {
      if (!signedUser) return;

      console.log(tagValidated, `tagValidated,`);

      setIsLoading(true);
      const res = await new UpdateTagService(signedUser.token).execute(tagValidated);

      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Tag',
          description: 'Falha ao tentar atualizar Tag. Tente novamente.',
        });
        return;
      }

      addToast({
        status: 'success',
        title: 'Tag',
        description: 'Tag editada com sucesso.',
      });

      navigate('/diretorio/tags');
    },
    [navigate, signedUser, addToast, setIsLoading]
  );

  const handleDeleteTag = useCallback(async () => {
    setShowDeleteModal(false);

    if (!tagCurrent || !tagCurrent.id) return;

    setIsLoading(true);

    const res = await new DeleteTagService(signedUser?.token).execute(tagCurrent.id);

    setIsLoading(false);

    if (res) {
      addToast({
        status: 'success',
        title: 'Tag',
        description: 'Tag deletada com sucesso.',
      });

      return navigate(`/diretorio/tags`);
    }

    addToast({
      status: 'danger',
      title: 'Tag',
      description: 'Não foi possível deletar a Tag.',
    });
  }, [tagCurrent, navigate, signedUser, addToast, setIsLoading]);

  const getTag = useCallback(async () => {
    if (!id) return;
    setIsLoading(true);
    const res = await new GetTagService(signedUser?.token, newCancelToken()).execute(id);
    setIsLoading(false);
    if (!isMounted() || !res) return;
    setTagCurrent(res);
  }, [id, isMounted, newCancelToken, setIsLoading, setTagCurrent, signedUser]);

  useEffect(() => {
    if (!tagCurrent || !tagCurrent.id) getTag();
  }, [getTag, tagCurrent]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        status="basic"
        statusNumber={600}
        backgroundHeader="linear-gradient(45deg, #282674 0%, #145496 100%)"
        title="Diretório de Tags"
        pathToReturn={`/diretorio/tags/${id}/informacoes`}
        menuKebab={{
          deleteCallback: () => setShowDeleteModal(true),
        }}
      />

      <TagForm formRef={formRef} handleOnFinish={handleEditTag} pathParentTag="/diretorio/tags/editar/selecionar" typeService="Editar" />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(!showDeleteModal)}
        title="Deseja Deletar a Tag?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleDeleteTag}
      />

      <PageFooter>
        <ButtonGradientOutline status="tags" onClick={() => navigate(`/diretorio/tags/${id}/informacoes`)} textColor="#ffffff">
          Cancelar
        </ButtonGradientOutline>

        <Button onClick={() => formRef?.current?.submitForm()} color="#fff" transform="none" status="secondary" statusNumber={800} shaded>
          Salvar
        </Button>
      </PageFooter>
    </Fragment>
  );
};

export default TagEdit;
