import React, { Fragment, useCallback, useState } from 'react';
import { Button, H6, Input, formatDate } from 'react-alicerce-components';

import { useNavigate } from 'react-router-dom';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import Layout from '@modules/users/components/Layout';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';

import { Container, Modifier, WrapperInput } from './styles';
import { useUser } from '@modules/users/context/UserContext';
import { translateString } from '@shared/utils/translateString';
import { calcAge } from '@shared/utils/calculateAge';
import CustomForm from '@shared/components/CustomForm';
import useAuth from '@shared/store/auth/hook';
import ListGroupsService, { IGroupReponse } from '@modules/groups/services/ListGroupsService';
import GroupCard from '@modules/groups/components/GroupCard';
import ListClassesService, { IClassesReponse } from '@modules/classes/services/ListClassesService';
import ListPlacesService, { IPlaceReponse } from '@modules/places/services/ListPlacesService';
import ClassCard from '@modules/classes/components/ClassCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const { userCurrent } = useUser();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [relatedGroups, setRelatedGroups] = useState<IGroupReponse>();
  const [relatedClasses, setRelatedClasses] = useState<IClassesReponse>();
  const [relatedPlaces, setRelatedPlaces] = useState<IPlaceReponse>();

  const listRelatedGroups = useCallback(async () => {
    if (!signedUser || !userCurrent) return;
    setIsLoading(true);
    const res = await new ListGroupsService(signedUser.token).execute('', { admin_id: Number(userCurrent.id) });
    if (res === 'canceling') return;
    setIsLoading(false);
    if (res) setRelatedGroups(res);
  }, [setIsLoading, signedUser, userCurrent]);

  const listRelatedClasses = useCallback(async () => {
    if (!signedUser || !userCurrent) return;
    setIsLoading(true);
    const res = await new ListClassesService(signedUser.token).execute('', { teacher_id: userCurrent.id });
    if (res === 'canceling') return;
    if (res) setRelatedClasses(res);
    setIsLoading(false);
  }, [setIsLoading, signedUser, userCurrent]);

  const listRelatedPlaces = useCallback(async () => {
    if (!signedUser || !userCurrent) return;
    setIsLoading(true);
    const res = await new ListPlacesService(signedUser.token).execute('', { admin_id: Number(userCurrent.id) });
    if (res === 'canceling') return;
    if (res) setRelatedPlaces(res);
    setIsLoading(false);
  }, [setIsLoading, signedUser, userCurrent]);

  return (
    <Fragment>
      <Layout openTab={0} footer={true} pathToReturn={'/diretorio/usuarios'}>
        <Container>
          <H6 fontWeight={800} status="secondary">
            DADOS ALICERCE
          </H6>
          <AccordionInfoItem title="Dados Principais" status="secondary">
            <Modifier>
              <CustomForm status="secondary">
                <Input disabled name="email_alicerce" label="Email Alicerce:" defaultValue={userCurrent?.user?.email} />

                <Input
                  disabled
                  name="activation_date"
                  label="Data de Ativação:"
                  defaultValue={userCurrent?.activation_date ? formatDate(userCurrent.activation_date, 'numeric') : ''}
                />
                <Input
                  disabled
                  name="email_alicerce"
                  label="Perfils do Usuário:"
                  defaultValue={userCurrent?.category ? translateString(userCurrent?.category) : ''}
                />
                <Input
                  disabled
                  name="email_alicerce"
                  label="Status do Usuário:"
                  defaultValue={userCurrent?.status ? translateString(userCurrent?.status) : ''}
                />
              </CustomForm>
            </Modifier>
          </AccordionInfoItem>

          <AccordionInfoItem
            status="secondary"
            title={`Grupos Vinculados (${relatedGroups ? relatedGroups.items.length : userCurrent?.groupsAdmin?.length})`}
            handleClickOpenClose={(isOpen: boolean) => isOpen && !relatedGroups && listRelatedGroups()}
          >
            <div className="container-related-groups">
              {relatedGroups?.items && relatedGroups?.items.map((child) => <GroupCard key={child.id} canSelect={false} group={child} />)}
            </div>
          </AccordionInfoItem>

          <AccordionInfoItem
            status="secondary"
            title={`Turmas Vinculadas (${relatedClasses ? relatedClasses.items.length : userCurrent?.classes?.length})`}
            handleClickOpenClose={(isOpen: boolean) => isOpen && !relatedClasses && listRelatedClasses()}
          >
            <div className="container-related-classes">
              {relatedClasses?.items && relatedClasses?.items.map((child) => <ClassCard key={child.id} classInfo={child} />)}
            </div>
          </AccordionInfoItem>

          <AccordionInfoItem
            status="secondary"
            title={`Locais Vinculadas (${relatedPlaces ? relatedPlaces.items.length : userCurrent?.places?.length})`}
            handleClickOpenClose={(isOpen: boolean) => isOpen && !relatedPlaces && listRelatedPlaces()}
          >
            <div className="container-related-places">
              {relatedPlaces?.items && relatedPlaces?.items.map((child) => <PlaceCard key={child.id} canSelect={false} place={child} />)}
            </div>
          </AccordionInfoItem>

          <Divider width="100%" />

          <H6 fontWeight={800} status="secondary">
            DADOS PESSOAIS
          </H6>

          <AccordionInfoItem title="Dados Principais" status="secondary">
            <Modifier>
              <CustomForm status="secondary">
                <Input disabled name="name" label="Nome do Usuário:" defaultValue={userCurrent?.name} />

                <Input disabled name="email" label="Email Pessoal:" defaultValue={userCurrent?.user?.contact_email} />

                <Input disabled name="phone" label="Telefone:" defaultValue={userCurrent?.phone} />

                <Input disabled name="address" label="Endereço:" defaultValue={userCurrent?.address_data?.address} />

                <WrapperInput className="WrapperInput">
                  <Input disabled name="city" defaultValue={userCurrent?.address_data?.city} label="Cidade:" />
                  <Input disabled name="uf" defaultValue={userCurrent?.address_data?.uf} label="UF:" />
                </WrapperInput>

                <WrapperInput className="WrapperInput">
                  <Input
                    disabled
                    name="birthdate"
                    label="Data de Nascimento:"
                    defaultValue={userCurrent?.birthdate ? formatDate(userCurrent.birthdate, 'long') : ''}
                  />
                  <Input disabled name="age" defaultValue={userCurrent?.birthdate ? calcAge(String(userCurrent.birthdate)) : ''} />
                </WrapperInput>

                <Input
                  disabled
                  name="gender"
                  label="Identidade de Gênero:"
                  defaultValue={userCurrent?.gender ? translateString(userCurrent?.gender) : ''}
                />
              </CustomForm>
            </Modifier>
          </AccordionInfoItem>

          <AccordionInfoItem title="Dados Acadêmicos" status="secondary">
            <Modifier>
              <CustomForm status="secondary">
                <Input
                  disabled
                  name="education_level"
                  label="Grau de Escolaridade:"
                  defaultValue={userCurrent?.education_level ? translateString(userCurrent.education_level) : ''}
                />

                <Input
                  disabled
                  name="university_type"
                  label="Tipo de Universidade:"
                  defaultValue={userCurrent?.university_type ? translateString(userCurrent.university_type) : ''}
                />

                <Input disabled name="info" label="Informações Acadêmicas:" defaultValue={userCurrent?.academic_info?.name} />

                <WrapperInput className="WrapperInput">
                  <Input disabled name="course_name" label="Curso:" defaultValue={userCurrent?.academic_info?.course_name} />
                  <Input disabled name="end_date" label="Término:" defaultValue={userCurrent?.academic_info?.end_date} />
                </WrapperInput>
              </CustomForm>
            </Modifier>
          </AccordionInfoItem>
        </Container>

        <PageFooter>
          <Button transform="none" status="secondary" shaded onClick={() => navigate(`/usuario/${userCurrent?.id}/editar`)}>
            Editar
          </Button>
        </PageFooter>
      </Layout>
    </Fragment>
  );
};

export default UserInfo;
