import React from 'react';

import Icon from 'react-eva-icons';

import { Grid, Input, Select } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import { IInputErrorsFormat, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';

import { useContracts } from '@modules/contracts/context/ContractContext';
import { useContractForm } from '../useContractForm';
import { defaultReponsibleContract } from '@modules/contracts/schemas/Contract/partials/defaultReponsibleContract';

interface FocalPointFragmentProps {
    disabled?: boolean
    errors?: IInputErrorsFormat[];
    canRemoveCallback?: () => void
}

export const FocalPointFragment: React.FC<FocalPointFragmentProps> = ({
    errors,
    canRemoveCallback,
    disabled = false,
}) => {

    const { contractCurrent } = useContracts();

    const {
        handleSelectChange,
        handleChangeInput,
    } = useContractForm()

    return (
        <Grid row className='focal-point-container'>
            {canRemoveCallback && (
                <div className="remove-focal-point-container" onClick={canRemoveCallback}>
                    <Icon name="close" fill="#FFFFFF" />
                </div>
            )}
            {contractCurrent?.responsible_contacts?.map((responsible) => {
                return (
                    <CustomForm key={responsible.date_id}>
                        <Grid row>
                            <Grid sm={12} md={6} mt4>
                                <Input
                                    disabled={disabled}
                                    label="Ponto Focal"
                                    placeholder="Adicione o ponto focal"
                                    onChange={handleChangeInput}
                                    useTooltipError={false}
                                    name="point_focal_name"
                                    defaultValue={responsible?.name}
                                    error={errorMessageBuilder('point_focal_name', errors)}
                                />
                            </Grid>
                            <Grid sm={12} md={6} mt4>
                                <Select
                                    disabled={disabled}
                                    label="Tipo do Relacionamento:"
                                    iconName="arrow-down"
                                    placeholder="Selecione"
                                    handleSelectChange={handleSelectChange}
                                    options={defaultReponsibleContract(responsible?.relationship_type)}
                                    error={errorMessageBuilder('type_of_relationship', errors)}
                                    name='type_of_relationship'
                                />
                            </Grid>
                        </Grid>

                        <Grid row>
                            <Grid column={6} mt4>
                                <Input
                                    disabled={disabled}
                                    label="Celular"
                                    name="cellphone"
                                    placeholder="(00) 00000 - 0000"
                                    onChange={handleChangeInput}
                                    defaultValue={responsible?.mobile || ''}
                                    useTooltipError={false}
                                    error={errorMessageBuilder('cellphone', errors)}
                                />
                            </Grid>

                            <Grid column={6} mt4>
                                <Input
                                    disabled={disabled}
                                    label="Telefone"
                                    placeholder="(00) 00000 - 0000"
                                    onChange={handleChangeInput}
                                    useTooltipError={false}
                                    name="phone"
                                    defaultValue={responsible?.phone}
                                    error={errorMessageBuilder('phone', errors)}
                                />
                            </Grid>
                        </Grid>


                        <Grid row>
                            <Grid column={12} mt4>
                                <Input
                                    disabled={disabled}
                                    label="Email"
                                    placeholder="Adicione Email"
                                    onChange={handleChangeInput}
                                    useTooltipError={false}
                                    name="email"
                                    defaultValue={responsible?.email || ''}
                                    error={errorMessageBuilder('email', errors)}
                                />
                            </Grid>
                        </Grid>
                    </CustomForm>)
            })}

        </Grid>
    );
}