export const CATEGORY_REGION = { name: 'Região', value: 'region' };
export const CATEGORY_CITY = { name: 'Cidade', value: 'city' };
export const CATEGORY_LOCATION = { name: 'Localização', value: 'location' };
export const CATEGORY_ROOM = { name: 'Sala', value: 'room' };

export type IGroupValidCategories = 'region' | 'city' | 'location' | 'room';

export const PLACE_CATEGORY_CONSTRAINTS = [CATEGORY_REGION, CATEGORY_CITY, CATEGORY_LOCATION, CATEGORY_ROOM];

export const defaultPlaceCategory = (placeCategory?: string | null) => {
  return [
    { name: CATEGORY_REGION.name, value: CATEGORY_REGION.value, selected: placeCategory === CATEGORY_REGION.value },
    { name: CATEGORY_CITY.name, value: CATEGORY_CITY.value, selected: placeCategory === CATEGORY_CITY.value },
    { name: CATEGORY_LOCATION.name, value: CATEGORY_LOCATION.value, selected: placeCategory === CATEGORY_LOCATION.value },
    { name: CATEGORY_ROOM.name, value: CATEGORY_ROOM.value, selected: placeCategory === CATEGORY_ROOM.value },
  ];
};

export const CATEGORY_PLACE_VALUES = PLACE_CATEGORY_CONSTRAINTS.map((c) => c.value);