import { styled } from "react-alicerce-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 450px;
`;

export const Text = styled.div`
  font-size: 30px;
  color: #8f9bb3;
  font-weight: 300;
  width: 100%;
  text-align: center;
`;
