import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';


interface ILayoutOccurrencesProps {
  openTab?: number;
}

type LayoutOccurrencesLocation = {
  goBack?: boolean;
};

const LayoutOccurrences: React.FC<ILayoutOccurrencesProps> = ({ openTab = 0, children }) => {
  const navigate = useNavigate();
  const { contextSelected } = useOccurrences();
  const { isLoading } = useGlobal();
  const { state: stateLocation } = useLocation() as { state: LayoutOccurrencesLocation };

  const handleGoBackCallback = useCallback(() => {
    if (stateLocation && stateLocation.goBack) {
      navigate(-1);
      return;
    }

    navigate(`/ocorrencias/contexto/turmas`);
  }, [navigate, stateLocation]);

  useEffect(() => {
    if (contextSelected === undefined) navigate('/ocorrencias/contexto/turmas');
  }, [contextSelected, navigate]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Ocorrências"
        onClickReturn={handleGoBackCallback}
        backgroundHeader="linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'OccurrencesPending' },
        }}
      />

      <CustomTabs>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Pendentes" onClick={() => navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/pendentes`)} />
          <Tab
            title="Solucionadas"
            onClick={() => navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/solucionadas`)}
          />
          <Tab title="Invalidadas" onClick={() => navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/invalidadas`)} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default LayoutOccurrences;
