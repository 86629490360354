import { styled } from 'react-alicerce-components';

export const Container = styled.div`
    .content {
        padding: 40px 20px 20px 20px;
    }

    .box-container {
        display: grid;
        grid-template-columns: 70% 1fr;
        gap: 12px;
        margin-top: 1.5rem;
    }

    .box-value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        background-color: #F2F8FF;
        text-align: center;
        font-size: 14px;
        color: #222B45;
        border-radius: 5px;
        padding: 6px;
        border: 1px solid #E4E9F2;
    }
`;


export const BoxColor = styled.div<{color: string}>`
    height: 60px;
    background-color: ${({color}) => color};

    font-size: 12px;
    color: #fff;
    font-weight: 800;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 10px 14px;
`;
