export const schedulesKeys = {
    SCHEDULES_CREATE: 'schedule/create',
    SCHEDULES_UPDATE: 'schedule/update',
    SCHEDULES_DELETE: 'schedule/delete',
    SCHEDULES_READ: 'schedule/read',

    SCHEDULES_EVENT_CREATE: 'event/create',
    SCHEDULES_EVENT_UPDATE: 'event/update',
    SCHEDULES_EVENT_DELETE: 'event/delete',
    SCHEDULES_EVENT_READ: 'event/read',
};