import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  padding-top: 1rem;
  padding-bottom: 45px;
  width: 100%;
  background-color: #dae3f6;
  display: flex;
  align-items: center;
  flex-direction: column;

  .accordion-info-item-container {
    width: 100%;
    padding: 0px 22px;
    > div {
      background-color: #dae3f6;

      .accordion-header {
        z-index: 0;
        .accordion-title {
          color: white;
        }
        i svg {
          fill: white;
        }
      }

      .accordion-content {
        background-color: #dae3f6;

        .problems {
          background: #bfa4c1;
          padding: 10px;
        }
      }
    }
  }
`;

export const DateComponent = styled.h6`
  font-size: 16px;
  font-weight: 800;
  color: #222b45;
`;

export const WeekDay = styled.p`
  font-size: 14px;
  color: #222b45;
  margin-bottom: 1rem;
`;

export const AccordionProblemItem = styled.p`
  padding: 22px 0;
  font-size: 12px;
  font-weight: 600;
  color: #2e3a59;
  text-align: justify;
  display: flex;
  align-items: center;

  & + p {
    border-top: 2px solid #edf1f7;
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 8px;
    display: inline-block;
    background: linear-gradient(180deg, #f63e3c 23.44%, #ff7773 100%);
  }
`;
