import React, { useCallback, useEffect, useState } from 'react';
import GroupList from '@modules/groups/components/GroupsList';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useStudent } from '../../context/StudentContext';
import { IStudent } from '../../interfaces/IStudent';

const StudentGroupsRelation: React.FC = () => {
  const { setStudentCurrent, studentCurrent } = useStudent();
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();

  const handleAddRelationGroup = useCallback(() => {
    setStudentCurrent((oldState: IStudent) => {
      if (!selectedGroup) return { ...oldState, onboarding_group: undefined, primary_affiliation: undefined };
      return { ...oldState, onboarding_group: selectedGroup, primary_affiliation: selectedGroup.id };
    });
  }, [selectedGroup, setStudentCurrent]);

  const setSelectedItens = useCallback(
    (group: IGroup[]) => {
      setSelectedGroup(group[0]);
    },
    [setSelectedGroup]
  );

  useEffect(() => {
    setSelectedGroup(studentCurrent.onboarding_group);
  }, [studentCurrent.onboarding_group]);

  return (
    <GroupList
      headerTitle="Diretório de Grupos"
      btnText="Adicionar Grupo"
      selectedGroups={selectedGroup ? [selectedGroup] : []}
      setSelectedGroups={setSelectedItens}
      canSelect={'one'}
      handleOnClick={handleAddRelationGroup}
      isAdvancedFilterDisabled={true}
      listOptions={{ categories: ['collective'] }}
    />
  );
};

export { StudentGroupsRelation };
