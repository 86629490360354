import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IListOptions from '@shared/interfaces/IListOptions';
import { IContractResponse } from '@modules/contracts/services/ListContractService';

export interface IListContractsByPreviousContractIdOptions extends IListOptions {
  parent_id: number
  status?: string[]
  gic_contact_company_id?: number[]
  end_date: { start: string | undefined, end: string | undefined }
}
class ListContractsByPreviousContractIdService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query: string = '', options: IListContractsByPreviousContractIdOptions): Promise<IContractResponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      this.api.defaults.baseURL = process.env.REACT_APP_GIF_API_URL || ''
      const res = await this.api.get(`/contract-business/tree?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return 'canceling';
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ListContractsByPreviousContractIdService;
