import { ActivityLibraryCardBodyProps } from '@modules/activities-library/components/ActivityLibraryCard/ActivityLibraryCardBody';
import { Pill, styled } from 'react-alicerce-components';

export const ActivityLibraryCardBodyContainer = styled.div<ActivityLibraryCardBodyProps>`
  background: #dae3f6;
  min-height: 60px;
  padding: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #222b45;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .skills-container {
    display: flex;
    width: 90%;
    justify-content: space-around;
  }

  .activity-title {
    max-width: none !important;
    width: 90%
  }

`;

export const CardTag = styled(Pill)`
  display: flex;
  gap: 3px;
  borderRadius: 2rem; 
  alignItems: center; 
  justify-content: center; 
  padding: auto; 
  color: #FFFFF; 
  background-color: #568CF7;
  border: none;
  padding: 5px 10px
`