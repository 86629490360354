import { Spinner, Button, Checkbox, useToast } from 'react-alicerce-components';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsCheckLg } from 'react-icons/bs';
import { Container } from './styles';
import Icon from 'react-eva-icons';

import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import UploadService from '@shared/services/UploadFile';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';

import { IFormatedActivity } from '@modules/activities-library/interfaces/IFormatedActivity';
import CreateActivityService from '@modules/activities-library/services/CreateActivityService';
import { ActivityLibraryForm } from '@modules/activities-library/components/ActivityLibraryForm';
import { useActivity } from '@modules/activities-library/context/ActivityContext';
import { activityLibrarySchema } from '@modules/activities-library/utils/activityLibrarySchema';
import { formatActivityLibrary } from '@modules/activities-library/utils/formatActivityLibrary';
import { formatedFileUpload } from '@modules/activities-library/utils/formatedFileUpload';
import { IAttachment } from '@shared/interfaces/IAttachment';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';

const ClassPlannerActivityCreate: React.FC = () => {
  const { isLoading } = useGlobal();
  const { activityLibraryCurrent, clearActivityLibraryContextData, setActivityLibraryCurrent } = useActivity();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModalConfirmCrate, setShowModalConfirmCrate] = useState<boolean>(false);
  const formatedActivityBody = useRef<IFormatedActivity>();
  const { resetActivityFilter } = useActivity();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { classId, dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();

  // issue 802 rermove a opção de enviar para o pedagogico
  const showPedagogicOption = false;

  const handleSendToPedagogic = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!formatedActivityBody.current) return;
    const isChecked = event.target.checked;
    const value = isChecked ? 'pending' : 'private';
    formatedActivityBody.current.status = value;
    setActivityLibraryCurrent((oldState: IActivityLibrary) => {
      oldState.status = value;
      return { ...oldState };
    });
  };

  const handleCancel = useCallback(() => {
    clearActivityLibraryContextData();
    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`);
  }, [classId, clearActivityLibraryContextData, dailyClassId, navigate]);

  const canSaveNewActivity = useCallback(async () => {
    if (!activityLibraryCurrent) return;
    const formatedBody = formatActivityLibrary(activityLibraryCurrent);
    let formErros = await beforeSubmitForm(formatedBody, activityLibrarySchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    const auxFormattedBody = formatedBody as IFormatedActivity;
    setShowModalConfirmCrate(true);
    formatedActivityBody.current = auxFormattedBody;
  }, [activityLibraryCurrent]);

  const createActivity = useCallback(async () => {
    if (!formatedActivityBody || !signedUser || !formatedActivityBody.current) return;
    setShowModalConfirmCrate(false);
    setIsLoading(true);
    let formattedAttachmentsToSave: IAttachment[] = [];

    const newFiles = activityLibraryCurrent.attachments?.flatMap((fts: any) => (fts.url ? [] : fts)) as File[];
    if (newFiles && newFiles.length > 0) {
      const formattedAttachments = formatedFileUpload(newFiles);
      const res = await new UploadService(signedUser?.token).uploadFile(newFiles, 'activities_library/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      formattedAttachmentsToSave = formattedAttachments.map((oldState, i) => ({ ...oldState, url: res[i].dbURL }));
    }

    if (formattedAttachmentsToSave.length > 0) formatedActivityBody.current.attachments = formattedAttachmentsToSave;

    const res = await new CreateActivityService(signedUser?.token).execute(formatedActivityBody.current);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividades',
        description: 'Erro ao criar o atividade.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Atividades',
      description: 'Atividade criada com sucesso.',
    });
    resetActivityFilter();

    clearActivityLibraryContextData();
    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${res.id}/informacoes`);
  }, [
    activityLibraryCurrent.attachments,
    addToast,
    classId,
    clearActivityLibraryContextData,
    dailyClassId,
    formatedActivityBody,
    navigate,
    resetActivityFilter,
    setIsLoading,
    signedUser,
  ]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Banco de Atividades"
        pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
      />
      <DirectoryContainer footer={true}>
        <ActivityLibraryForm
          formAction="creating"
          errors={errors}
          pathToCreateTagsRelation={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/selecionar/tags`}
          pathToCreateSkillRelation={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/criar/competencias`}
        />

        <PageFooter>
          <Button transform="none" outline shaded onClick={handleCancel} className="button-cancel-activity">
            Cancelar
          </Button>
          <Button transform="none" status="quaternary" shaded onClick={canSaveNewActivity} className="mochileiro-color">
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModalConfirmCrate}
        closeCallback={() => setShowModalConfirmCrate(false)}
        title="Salvar no Meu Banco!"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={createActivity}
      >
        {showPedagogicOption && (
          <div className="container-text-modal">
            <span className="span-text-modal">
              Ao continuar, você salvará a atividade no seu banco pessoal. Caso queira também enviar para o pedagógico, selecione abaixo:
            </span>
            <Checkbox
              name="Pedagógico"
              id="Pedagógico"
              label="Enviar Atividade para Pedagógico"
              semiRound
              defaultChecked={activityLibraryCurrent.status === 'pending'}
              onChange={(event) => handleSendToPedagogic(event)}
              icon={BsCheckLg}
              hideIconUncheck
            />
          </div>
        )}
      </ConfirmModal>
    </Container>
  );
};

export { ClassPlannerActivityCreate };
