import * as Yup from 'yup';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { CLASS_STATUS_CONSTRAINTS, STATUS_INACTIVE, STATUS_PAUSED } from './validData/validClassStatus';
import { CLASS_TYPE_CONSTRAINTS } from './validData/validClassType';
import { SHIFT_CONSTRAINTS } from './validData/validShift';
import { WEEK_DAYS_CONSTRAINTS } from '@shared/utils/validData/validWeekdays';

const TYPES = CLASS_TYPE_CONSTRAINTS.map((c) => c.value);
const WEEKDAYS = WEEK_DAYS_CONSTRAINTS.map((c) => c.value);
const SHIFT = SHIFT_CONSTRAINTS.map((c) => c.value);
const STATUS = CLASS_STATUS_CONSTRAINTS.map((c) => c.value);
const AGE_GROUP = AGE_GROUP_CONSTRAINTS.map((c) => c.value);

export const classSchema = Yup.object().shape({
  name: Yup.string().required({ name: 'name', message: 'O campo é obrigatório' }),

  type: Yup.string()
    .oneOf(TYPES, { name: 'type', message: `Campo deve ser ${TYPES} ` })
    .required({ name: 'type', message: `O campo é obrigatório` }),

  age_groups: Yup.array()
    .of(Yup.string().oneOf(AGE_GROUP, { name: 'age_groups', message: `Campo deve ser ${AGE_GROUP} ` }))
    .min(1, { name: 'age_groups', message: 'É necessário marcar pelo menos uma opção' })
    .required({ name: 'age_groups', message: 'O campo é obrigatório' }),

  start_date: Yup.string().required({ name: 'start_date', message: `Adicione uma data de início.` }),

  isCustomClass: Yup.boolean(),
  customization: Yup.string().when('isCustomClass', {
    is: true,
    then: Yup.string()
      .min(1)
      .required()
      .test('empty-or-1-characters-check', (customization, { createError }) => {
        if (!customization || customization === '') {
          return createError({
            message: { name: 'customization', message: 'Adicione as Modificações da Turma' },
          });
        } else return true;
      }),
    otherwise: Yup.string().optional().nullable(),
  }),

  calendar_data: Yup.array()
    .of(
      Yup.object({
        // place_id: Yup.number().required(),
        week_days: Yup.array()
          .of(Yup.string().oneOf(WEEKDAYS, { name: 'week_days', message: `Campo deve ser ${WEEKDAYS} ` }))
          .required(),
        shift: Yup.string()
          .oneOf(SHIFT, { name: 'shift', message: `Campo deve ser ${SHIFT} ` })
          .optional()
          .nullable(),
        start: Yup.string().required(),
        end: Yup.string().required(),
      })
    )
    .optional()
    .nullable(),

  status: Yup.string()
    .oneOf(STATUS, { name: 'status', message: `Campo deve ser ${STATUS} ` })
    .required({ name: 'status', message: 'O campo é obrigatório' }),

  group_ids: Yup.array().of(Yup.number()).optional().nullable(),

  place_ids: Yup.array().of(Yup.number()).optional().nullable(),

  tag_ids: Yup.array().of(Yup.number()).optional().nullable(),

  teacher_ids: Yup.array()
    .of(Yup.number())
    .when('status', (status, schema) => {
      const isValidStatusTeacher = status !== STATUS_PAUSED.value && status !== STATUS_INACTIVE.value;
      if (isValidStatusTeacher)
        return schema.test('is-valid-status', (teacher_ids, { createError }) => {
          if (teacher_ids?.length === 0 && isValidStatusTeacher) {
            return createError({
              message: { name: 'teacher_ids', message: 'A Turma está Ativa ou em Espera, adicione pelo menos um Líder na Turma' },
            });
          } else return true;
        });
    })
    .optional()
    .nullable(),

  expected_students: Yup.number().optional().nullable().default(20),
});
