import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo
} from "react";

import ITag from "@modules/tags/interfaces/ITag";

export type ITagsContextData = {
  tagCurrent?: ITag;
  setTagCurrent: (tag: any) => void;
  clearTagCurrent: () => void;
};

const TagsContext = createContext<ITagsContextData>({} as ITagsContextData);

const TagsProvider: React.FC = ({ children }) => {

  const [tagCurrent, setTagCurrent] = useState<ITag>();


  const emptyTag = useMemo(() => {
    return {
      description: '',
      name: '',
      color: '',
      type: ''
    }
  }, []);

  const clearTagCurrent = useCallback(() => {
    setTagCurrent(emptyTag);
  }, [emptyTag]);

  return (
    <TagsContext.Provider
      value={{
        tagCurrent,
        clearTagCurrent,
        setTagCurrent
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

function useTags() {
  const context = useContext(TagsContext);

  if (!context) {
    throw new Error("useTags must be used within a TagsProvider");
  }

  return context;
}

export { TagsProvider, useTags };
