import styled from 'styled-components';

export const StyledCheckboxContainer = styled.div`
  .checkbox-container {
    background: transparent;
    border: 1px solid #8F9BB3;
    color: #2E3A59;
    border-radius: 16px;
    margin: 2px;
    width: fit-content;
    padding: 6px 18px 6px 6px;
    transition: .2s all;

    label > div {
      border: none;
      width: 14px;
      height: 14px;
    }

    input + div svg {
      width: 14px;
      height: 14px;
      margin-top: 4px;
    }

   input:checked + div {
    background: transparent;
   }

   span {
    text-align: center;
   }
  }

  .checkbox-container:has(input:checked) {
    color: #2E3A59;
    white-space: initial;
    background: #8CFAC7;
    border: 1px solid #00D68F;
    text-align: center;
    svg {
      color: #2E3A59;
    }
  }
`;
