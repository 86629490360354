import React, { Fragment, useMemo } from 'react';

import Dot from './Dot';
import Square from './Square';

export interface ILegend {
    name: string;
    color: string;
}

export interface LegendProps {
    items: ILegend[];
    type?: ILegendType;
}

export type ILegendType = 'dot' | 'square' | undefined;

const Legend: React.FC<LegendProps> = ({
    items: itemsProps,
    type
}) => {

    const items = useMemo(() => itemsProps.filter(d => d.name !== 'rest'), [itemsProps]);

    const getLegend = useMemo(() => {
        const legends = {
            dot: <Dot items={items} />,
            square: <Square items={items} />
        }
        return type ? legends[type] : <Fragment />;
    }, [items, type]);

    return (
        <div>
            {getLegend}
        </div>
    )
}

export default Legend;