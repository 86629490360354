import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .input-container {
    margin-bottom: 1.5rem;
  }

  .pagination-content-icon {
    background-color: #855cbd !important;
    i svg {
      fill: #fff !important;
    }
  }
`;
