import { useSelector } from "react-redux";
import { RootState } from "@shared/store";
import { IGlobalState } from "./types";

function useGlobal(): IGlobalState {
    const context = useSelector((state: RootState) => (state.global));

    if (!context) {
      throw new Error('useGlobal must be used within a Provider');
    }
  
    return context;
  }
  
  export default useGlobal;
  
