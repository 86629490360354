import React from 'react';
import { useNavigate, useParams } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import { Container } from './styles';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useWorkedHour } from '../../context/WorkedHourContext';

interface ILayoutApprovedProps {
  openTab?: number;
  footer?: boolean;
}

export const LayoutApproved: React.FC<ILayoutApprovedProps> = ({ openTab = 0, children, footer }) => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();
  const { setSelectTags } = useWorkedHour();
  const { id } = useParams<{ id: string }>();

  const handleOnClickTab = (pathToRedirect: string) => {
    setSelectTags({});
    navigate(pathToRedirect);
  };

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aprovar Horas Trabalhadas"
        backgroundHeader="linear-gradient(284.92deg, #F47920 0%, #0097B1 100%);"
        status="primary"
        pathToReturn="/"
        menuKebab={{
          tooltip: { view: 'WorkedHoursInfoApproved' },
          menuBackHome: true,
        }}
      />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Horas Pendentes" onClick={() => handleOnClickTab(`/horas-trabalhadas/grupo/${id}/aprovar`)} />
          <Tab title="Horas Aprovadas" onClick={() => handleOnClickTab(`/horas-trabalhadas/grupo/${id}/aprovadas`)} />
          <Tab title="Horas Reprovadas" onClick={() => handleOnClickTab(`/horas-trabalhadas/grupo/${id}/reprovadas`)} />
        </Tabs>
      </CustomTabs>

    </Container>
  );
};
