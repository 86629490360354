import React from 'react';
import { Pagination } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import PlaceCard from '@modules/places/components/PlaceCard';
import { IPlaceReponse } from '@modules/places/services/ListPlacesService';

interface IPlaceRenderProps {
  places: IPlaceReponse | undefined;
  filterPlaces: (search: string, page?: any) => Promise<void>;
}

const PlaceRender: React.FC<IPlaceRenderProps> = ({ places, filterPlaces }) => {
  const navigate = useNavigate();

  return (
    <div>
      {places?.items.map((p) => (
        <PlaceCard key={p.id} place={p} handleOnClick={() => navigate(`/local/${p.id}`, { state: { pathToReturn: '/' } })} />
      ))}
      {!!places?.items && (
        <Pagination totalOfPages={`${places?.totalPages}`} currentPage={`${places?.currentPage}`} callbackGetListData={filterPlaces} />
      )}
    </div>
  );
};

export default PlaceRender;
