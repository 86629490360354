import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .container-all-tags {
    overflow: hidden;
    margin-bottom: 40px;
  }

  .input-container {
    margin-bottom: 2rem;
    div {
      border-color: #c5cee0 !important;
    }
  }

  .pagination-content-icon {
    background-color: #004287 !important;
  }
`;
