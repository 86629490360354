import React from 'react';
import { Input, Checkbox, Textarea } from 'react-alicerce-components';
import { FiCheck } from 'react-icons/fi';
import CustomForm from '@shared/components/CustomForm';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useTags } from '../../context/TagsContext';
import ITag from '../../interfaces/ITag';
import TagsRender from '../TagsRender';
import { TagInfoFormContainer } from './styles';

const TagInfoForm: React.FC = () => {
  const { tagCurrent } = useTags();

  return (
    <DirectoryContainer footer style={{ paddingTop: '0' }}>
      <TagInfoFormContainer className="tag-info-form-container">
        <CustomForm customInputColorText="#145496">
          <Input label="Nome da Tag:" name="name" disabled defaultValue={tagCurrent?.name} />



          <div className="container-checkbox">
            <Checkbox
              id="project"
              name="type"
              label="Tag de Projeto"
              hideIconUncheck
              icon={FiCheck}
              status="basic"
              semiRound
              checked={tagCurrent?.type === 'project'}
              disabled
            />
            <Checkbox
              id="system"
              name="type"
              label="Tag de Sistema"
              hideIconUncheck
              icon={FiCheck}
              status="basic"
              semiRound
              checked={tagCurrent?.type === 'system'}
              disabled
            />
          </div>

          <TagsRender label="Tag Mãe:" tags={[tagCurrent?.parent as ITag]} />

          <Textarea
            label="Descrição da Tag:"
            name="description"
            placeholder="Adicione Descrição da Tag"
            value={tagCurrent?.description}
            rows={4}
            disabled
          />
        </CustomForm>
      </TagInfoFormContainer>
    </DirectoryContainer>
  );
};

export { TagInfoForm };
