import React, { Fragment, useCallback, useState } from 'react';
import Icon from 'react-eva-icons';

import { Button, Input } from 'react-alicerce-components';
import returnStringFunction from '@shared/utils/returnStringFunction';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { Container, FilterContainer } from './styles';
import Divider from '@shared/components/Divider';

export interface IInputFilterDate {
    start_date: string;
    end_date: string;
}

interface InputFilterProps {
    filterByConcluder?: boolean;
    handleOnFilterDate?: (date: IInputFilterDate) => void;
    handleOnFilterConcluder?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    handleOnChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFilter: React.FC<InputFilterProps> = ({
    handleOnFilterDate,
    handleOnChange,
    handleOnFilterConcluder,
    filterByConcluder = false
}) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const [errors] = useState<IInputErrorsFormat[]>([]);
    const [date, setDate] = useState<IInputFilterDate>({ start_date: '', end_date: '' });

    const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setDate((oldState: any) => ({ ...oldState, [event.target.name]: event.target.value }));
    }, []);

    const handleFilter = useCallback(() => {
        if (handleOnFilterDate) {
            handleOnFilterDate(date);
        }
    }, [date, handleOnFilterDate]);

    return (
        <Container>
            <Input
                name="search"
                type="text"
                placeholder="Filtro Avançado"
                onChange={handleOnChange}
                end={
                    <div onClick={() => setIsShow(oldState => !oldState)}>
                        <Icon name="funnel" size="large" fill="#8F9BB3" />
                    </div>
                }
            />
            {isShow &&
                <FilterContainer>
                    <div className="content">
                        <h6>Filtrar por: </h6>
                        {filterByConcluder &&
                            <Fragment>
                                <Input
                                    type="text"
                                    name="search-concluder"
                                    placeholder="Digitar o nome do usuario"
                                    label='Tarefa concluida por: '
                                    onChange={handleOnFilterConcluder}
                                />
                                <Divider width="100%" />
                            </Fragment>
                        }

                        <div className='close' onClick={() => setIsShow(false)}>
                            <Icon name="close" size="large" fill="#2E3A59" />
                        </div>
                        <Input
                            label="Tarefas com Prazo de Conclusão entre:"
                            name="start_date"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event)}
                            defaultValue={returnStringFunction(date.start_date)}
                            error={errorMessageBuilder('birthdate', errors)}
                            required
                        />
                        <Input
                            label="Até:"
                            name="end_date"
                            type="date"
                            useTooltipError={false}
                            onChange={(event) => handleChangeInput(event)}
                            defaultValue={returnStringFunction(date.end_date)}
                            error={errorMessageBuilder('birthdate', errors)}
                            required
                        />
                    </div>
                    <div className="footer">
                        <Button
                            status='quaternary'
                            transform="none"
                            noBorder
                            shaded
                            onClick={handleFilter}
                        >
                            Aplicar Filtros
                        </Button>
                    </div>
                </FilterContainer>
            }
        </Container>
    );
}

export default InputFilter;