const generateNumbers = (start, end) => {
  const numbers: { name: string, value: string }[] = [];
  for (let i = start; i <= end; i++) {
    numbers.push({ name: i.toString(), value: i.toString() });
  }
  return numbers;
};

const startNumber = 1;
const endNumber = 14;

const generatedNumbers = generateNumbers(startNumber, endNumber);

export const CONTRACT_WEEK_DAY_CONTRAINS = generatedNumbers;

export const validContractWeekDays = (activityPortfolioType?: string | null) => {
  return generatedNumbers.map((number) => ({
    name: number.name,
    value: number.value,
    selected: activityPortfolioType === number.value,
  }));
};