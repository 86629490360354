export type TYPES_STEERINGS_MEETING_CHANNEL = 'zoom' | 'meet' | 'teams' | 'in_person';

export const STEERING_CHANNEL_ZOOM_CONSTRAINTS = 'zoom';
export const STEERING_CHANNEL_MEET_CONSTRAINTS = 'meet';
export const STEERING_CHANNEL_TEAMS_CONSTRAINTS = 'teams';
export const STEERING_CHANNEL_IN_PERSON_CONSTRAINTS = 'in_person';

export const STEERING_CHANNEL_ZOOM = { name: 'Zoom', value: 'zoom' };
export const STEERING_CHANNEL_MEET = { name: 'Meet', value: 'meet' };
export const STEERING_CHANNEL_TEAMS = { name: 'Teams', value: 'teams' };
export const STEERING_CHANNEL_IN_PERSON = { name: 'Presencial', value: 'in_person' };

export const STEERING_CHANNEL_CONSTRAINTS = [
  STEERING_CHANNEL_ZOOM_CONSTRAINTS,
  STEERING_CHANNEL_MEET_CONSTRAINTS,
  STEERING_CHANNEL_TEAMS_CONSTRAINTS,
  STEERING_CHANNEL_IN_PERSON_CONSTRAINTS,
];
export const STEERING_CHANNEL_OPTIONS = [STEERING_CHANNEL_ZOOM, STEERING_CHANNEL_MEET, STEERING_CHANNEL_TEAMS, STEERING_CHANNEL_IN_PERSON];

export const CHANNEL_STEERING_VALUES = STEERING_CHANNEL_OPTIONS.map((c) => c.value);

export const defaultSteeringChannel = (steeringChannel?: string | null) =>
  STEERING_CHANNEL_OPTIONS.map((i) => ({ ...i, selected: steeringChannel === i.value }));

export const translateSteeringChannel = (steeringChannel: string = '') => {
  let objMap = {};
  STEERING_CHANNEL_OPTIONS.forEach((i) => (objMap = { ...objMap, [i.value]: i.name }));
  return objMap[steeringChannel] || steeringChannel;
};
