import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IDailyClassPlannComment } from '../interfaces/IDailyClassPlannComment';

class CreateDailyClassPlanCommentService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(dailyClassId: string, newComment: string): Promise<IDailyClassPlannComment | false> {
    const body = { daily_class_id: dailyClassId, comment: newComment };
    try {
      const res = await this.api.post('/daily-class-plan/comment', body);
      return res.data 
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default CreateDailyClassPlanCommentService;
