import { styled } from 'react-alicerce-components';

export const ActivityProgramHomeListContainer = styled.div`
  .input-container {
    margin-bottom: 1.5rem;
  }

  .pagination-content .pagination-content-icon {
    background-color: #b85ec4;
  }

  .container-page-footer button {
    border-color: #96b6f7;
    background: #96b6f7;
  }

  .activity-picker {
    margin: 0rem 0rem 2.5rem 0rem;

    .activity-picker-container {
      display: flex;
      justify-content: center;

      .activity-tab {
        border: 1px solid #c5cee0;
        cursor: pointer;
        min-width: 120px;
        padding: 0.5rem 1rem;
        text-align: center;
        font-weight: 800;
        font-size: 12px;
        background: #edf1f7;
        color: #c5cee0;
      }

      .selected {
        color: white;
      }

      .activity-tab.activity {
        border: 1px solid #c5cee0;
        border-radius: 16px 0px 0px 16px;
      }
      .activity-tab.activity.selected {
        background: #a4bff7;
      }

      .activity-tab.program {
        border: 1px solid #c5cee0;
        border-radius: 0px 16px 16px 0px;
      }
      .activity-tab.program.selected {
        background: #ad83de;
      }
    }
  }
`;
