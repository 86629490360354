import React from 'react';
import { useParams } from 'react-router-dom';

import LayoutStudentApplication from '@modules/applications/components/LayoutStudentApplication';

import StudentApplicationListManager from '@modules/applications/components/StudentApplicationListManager';

const StudentOnGoingApplication: React.FC = () => {
  const { studentId } = useParams();

  return (
    <LayoutStudentApplication openTab={1}>
      <StudentApplicationListManager
        status="ONGOING"
        filters={['type', 'trails', 'applied_between', 'started_between']}
        studentIds={[studentId as string]}
      />
    </LayoutStudentApplication>
  );
};

export default StudentOnGoingApplication;
