import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';

import { Button, Spinner, useToast } from 'react-alicerce-components';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { useGlobal } from '@shared/contexts/global/GlobalContext';

import ConfirmModal from '@shared/components/ConfirmModal';
import { UserCreateForm } from '@modules/users/components/UserCreateForm';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { IProfile } from '@modules/users/interfaces/IProfile';
import { profilesMockedData } from '@modules/users/utils/formatProfilesSelectOption';
import { initalDataUserCreate, useUser } from '@modules/users/context/UserContext';
import { userCreateSchema } from '@modules/users/utils/userCreateSchema';
import { useNavigate } from 'react-router-dom';
import GetProfilesService from '@modules/users/services/GetProfilesService';
import CreateUserService from '@modules/users/services/CreateUserService';

const UserCreate: React.FC = () => {
  const { isLoading, setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const { addToast } = useToast();
  const { userRef } = useUser();
  const navigate = useNavigate();

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [profilesLms, setProfilesLms] = useState<IProfile[]>();

  const refPersonalAcademy = React.createRef<any>();
  const refPersonalMain = React.createRef<any>();

  const getProfiles = useCallback(async () => {
    setIsLoading(true);
    const res = await new GetProfilesService(signedUser?.token).execute();
    setIsLoading(false);
    if (!res) {
      return addToast({
        status: 'danger',
        title: 'Perfis',
        description: 'Error ao Recuperar Perfis.',
      });
    }

    if (!isMounted()) return;
    setProfilesLms(profilesMockedData);
  }, [addToast, isMounted, setIsLoading, signedUser]);

  const handleCreateUser = useCallback(async () => {
    if (!userRef.current) return;
    setIsLoading(true);
    const res = await new CreateUserService(signedUser?.token).execute(userRef.current);
    setIsLoading(false);
    setShowEditModal(false)
    if (!res) {
      return addToast({
        status: 'danger',
        title: 'Usuario',
        description: 'Error ao Criar Usuario.',
      });
    }

    if (res) {
       addToast({
        status: 'success',
        title: 'Usuario',
        description: 'Usuario Criado Com Sucesso',
      });
      return navigate('/diretorio/usuarios');
    }
  }, [addToast, navigate, setIsLoading, signedUser, userRef]);

  const handleCanSubmit = useCallback(async () => {
    let formErros = await beforeSubmitForm(userRef.current, userCreateSchema);
    var regexp = /[a-zA-Z]+\s+[a-zA-Z]+/g;
    if (!regexp.test(userRef.current.name!)) {
      formErros.push({
        name: 'name',
        message: 'Nome e Sobrenome',
      });
    }

    if (new Date(userRef.current.birthdate!) > new Date()) {
      console.log('pasei por aqui');
      formErros.push({
        name: 'birthdate',
        message: 'Data de nascimento é maior que a data atual',
      });
    }
    setErros(formErros);
    console.log(formErros);
    if (formErros.length !== 0) return;
    setShowEditModal(true);
  }, [userRef]);

  const handleCancel = useCallback(async () => {
    userRef.current = initalDataUserCreate;
    navigate('/diretorio/usuarios');
  }, [navigate, userRef]);

  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory title="Criar Usuário" status="secondary" pathToReturn={`/diretorio/usuarios`} />

      <DirectoryContainer footer={true}>
        <UserCreateForm errors={errors} refPersonalAcademy={refPersonalAcademy} refPersonalMain={refPersonalMain} profilesLms={profilesLms} />

        <PageFooter>
          <Button transform="none" status="secondary" shaded outline onClick={handleCancel}>
            Cancelar
          </Button>
          <Button transform="none" status="secondary" shaded onClick={handleCanSubmit}>
            Salvar
          </Button>
        </PageFooter>

        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showEditModal}
          closeCallback={() => setShowEditModal(!showEditModal)}
          title="Deseja Criar Usuário ?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={handleCreateUser}
        />
      </DirectoryContainer>
    </Fragment>
  );
};

export default UserCreate;
