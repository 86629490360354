import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';
import { IProgramFilterFormatted } from '../context/ProgramContext';
import IListOptions from '../../../shared/interfaces/IListOptions';

export interface IProgramsReponse extends IPaginatedResponse {
  items: IProgram[];
}

interface ListProgramsOptions extends IListOptions, IProgramFilterFormatted {
  favorited?: boolean;
}

export class ListProgramsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: ListProgramsOptions): Promise<IProgramsReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({ query, ...options }, true);
      const res = await this.api.get(`/program?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}
