import React, { FC } from 'react';
import { createContext, useState, useMemo, useContext, cloneElement } from 'react'
import { createPortal } from 'react-dom';
import { ModalContainer, Overlay } from './styles';

export type ModalWindowsChildrenProps = {
  onCloseModal?: (() => void)
}
export type ModalProps = { children: React.ReactNode };
export type OpenProps = { children: React.ReactElement; opens: string };

export type WindowProps = { children: any; name: string };
export type ModalContextProps = {
  openName: string;
  close: () => void;
  open: React.Dispatch<React.SetStateAction<string>>;
};

const ModalContext = createContext<ModalContextProps | null>(null);

const Modalv1: FC<ModalProps> & {
  Window: FC<WindowProps>,
  Open: FC<OpenProps>,
} = (props) => {
  const { children } = props;
  const [openName, setOpenName] = useState('');

  const close = () => setOpenName('');
  const open = setOpenName;

  const ModalProviderValue = useMemo(
    () => ({ openName, close, open }),
    [openName, open]
  );

  return (
    <ModalContext.Provider value={ModalProviderValue}>
      {children}
    </ModalContext.Provider>
  );
};

const Open = (props: OpenProps) => {
  const { children, opens: opensWindowName } = props;
  const { open } = useContext(ModalContext) as ModalContextProps;
  return cloneElement(children, { onClick: () => open(opensWindowName) });
};

const Window = (props: WindowProps) => {
  const { children, name } = props;
  const { openName, close } = useContext(ModalContext) as ModalContextProps;

  if (name !== openName) return null;
  return createPortal(
    <Overlay>
      <ModalContainer>
        {cloneElement(children, { onCloseModal: close })}
      </ModalContainer>
    </Overlay>,
    document.body
  );
};

Modalv1.Window = Window;
Modalv1.Open = Open;

export default Modalv1;