import React, { useState, useCallback } from 'react';
import Icon from 'react-eva-icons';

import { Input, Spinner } from 'react-alicerce-components';

import Layout from '@modules/recordings/components/Layout';
import IClass from '@modules/classes/interfaces/IClass';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useNavigate } from 'react-router-dom';

const RecordingsClassesSelection: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const { isLoading } = useGlobal();

  const handleClickRecording = useCallback(
    (classClicked: IClass) => {
      console.log(classClicked);
      // TODO: redirecionar pra o local correto e set estado necessario
      navigate(`/gravacoes/cameras`);
    },
    [navigate]
  );

  const filterClassesRecordings = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <Layout openTab={0}>
      {isLoading && <Spinner />}
      <div style={{ marginBottom: '2rem' }}>
        <Input
          placeholder="Buscar"
          name="search"
          end={<Icon name="funnel" fill="#8F9BB3" />}
          onChange={(event) => filterClassesRecordings(event.target.value)}
        />
      </div>
      <ClassesSelection handleOnClick={handleClickRecording} searchValue={searchValue} />
    </Layout>
  );
};

export default RecordingsClassesSelection;
