import React, { useCallback } from 'react';
import { Checkbox, Label, Pill } from 'react-alicerce-components';
import { FiCheck } from 'react-icons/fi';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { IStudent } from '@modules/students/interfaces/IStudent';
import { useScheduleGrid } from '../../context/ScheduleGridContext';

import { WorkGroupAndStudentsInfoContainer } from './styles';
import { removeDuplicatesFromArray } from '@shared/utils/removeDuplicatesFromArray';
import { hasError, IInputErrorsFormat } from '../../../../shared/utils/beforeSubmitForm';
import Divider from '@shared/components/Divider';
import { STUDENT_STATUS_CONSTRAINTS } from '@modules/students/utils/validData/validStatus';
import { findConstraintName } from '@modules/students/utils/constraintName';

interface IWorkGroupAndStudentsInfo {
  selectedStudents: IStudent[];
  setSelectedStudents: React.Dispatch<React.SetStateAction<IStudent[]>>;
  formAction?: 'editing' | 'watching';
  errors?: IInputErrorsFormat[];
}
const WorkGroupAndStudentsInfo: React.FC<IWorkGroupAndStudentsInfo> = ({ selectedStudents, setSelectedStudents, formAction = 'editing', errors }) => {
  const { availableStudents, availableWorkGroups } = useScheduleGrid();

  const sortSelectedStudents = selectedStudents.sort((a, b) => (b.status === 'active' ? 1 : -1));
  const sortAvailableStudents = availableStudents.sort((a, b) => (b.status === 'active' ? 1 : -1));
  const filteredStudentsnoCheck = sortAvailableStudents.filter((obj1) => !selectedStudents.some((obj2) => obj1.id === obj2.id));

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      setSelectedStudents(() => {
        if (checked) return availableStudents;
        return [];
      });
    },
    [availableStudents, setSelectedStudents]
  );

  const handleOnClickWorkGroupCheckbox = useCallback(
    (checked: boolean, students: IStudent[] = []) => {
      setSelectedStudents((oldState) => {
        if (checked) return removeDuplicatesFromArray([...oldState, ...students]);
        const ids = students.map((s) => s.id);
        return oldState.filter((os) => !ids.includes(os.id));
      });
    },
    [setSelectedStudents]
  );

  const handleSelectStudent = useCallback(
    (checked: boolean, student: IStudent) => {
      setSelectedStudents((oldState) => {
        if (checked) return removeDuplicatesFromArray([...oldState, student]);
        return oldState.filter((os) => os.id !== student.id);
      });
    },
    [setSelectedStudents]
  );

  return (
    <WorkGroupAndStudentsInfoContainer>
      {formAction !== 'watching' && (
        <AccordionInfoItem title={`Grupos de Trabalho (${availableWorkGroups.length}) `} colorHeader="#DAE3F6">
          <div className="work-group-checkboxes-wrapper">
            {availableWorkGroups.map((wg) => (
              <div className="work_group_wrapper" key={wg.id}>
                <Checkbox
                  key={`${wg.id}`}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  id={`${wg.id}`}
                  name={`${wg.name}`}
                  className="checkbox-container"
                  onChange={(event) => handleOnClickWorkGroupCheckbox(event.target.checked, wg.students)}
                />
                <div className="work-group-info">
                  <span>{wg.name}</span>
                  <span>{wg.students?.length} membros</span>
                </div>
              </div>
            ))}
          </div>
        </AccordionInfoItem>
      )}

      <AccordionInfoItem title={`Alunos (${availableStudents.length}) `} colorHeader="#DAE3F6" hasErrors={hasError(['students'], errors)}>
        {formAction !== 'watching' && (
          <div className="select-all-students-container">
            <Checkbox
              semiRound
              icon={RiCheckboxBlankCircleFill}
              hideIconUncheck
              name={'select-all'}
              id={'select-all '}
              onChange={(event) => handleSelectAll(event.target.checked)}
            />
            <Label>Selecionar Todos</Label>
          </div>
        )}
        {sortSelectedStudents && (
          <div className="student-checkboxes-wrapper">
            {sortSelectedStudents.map((student) => (
              <div className="student_wrapper" key={`${student.id}`}>
                <div className="student_status_wrapper">
                  <div className="student-info">
                    <span>{student.name}</span>
                  </div>
                  <div className="status-info">
                    <span>
                      Status:
                      <Pill backgroundColor={student.status === 'active' ? '#2CE69B' : '#FFBC6F'} color="#222B45" size="tiny" marginLeft={'10px'}>
                        {findConstraintName(STUDENT_STATUS_CONSTRAINTS, student.status)}
                      </Pill>
                    </span>
                  </div>
                </div>
                <Checkbox
                  onChange={(event) => handleSelectStudent(event.target.checked, student)}
                  defaultChecked={!!selectedStudents.find((st) => Number(st.id) === Number(student.id))}
                  key={student.id}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  disabled={formAction === 'watching'}
                  id={`${student.id}`}
                  name={`${student.name}`}
                  className="checkbox-container"
                />
              </div>
            ))}
          </div>
        )}
        <Divider />

        {filteredStudentsnoCheck && (
          <div className="student-checkboxes-wrapper">
            {filteredStudentsnoCheck.map((student) => (
              <div className="student_wrapper" key={`${student.id}`}>
                <div className="student_status_wrapper">
                  <div className="student-info">
                    <span>{student.name}</span>
                  </div>
                  <div className="status-info">
                    <span>
                      Status:
                      <Pill backgroundColor={student.status === 'active' ? '#2CE69B' : '#FFBC6F'} color="#222B45" size="tiny" marginLeft={'10px'}>
                        {findConstraintName(STUDENT_STATUS_CONSTRAINTS, student.status)}
                      </Pill>
                    </span>
                  </div>
                </div>
                <Checkbox
                  onChange={(event) => handleSelectStudent(event.target.checked, student)}
                  defaultChecked={!!selectedStudents.find((st) => Number(st.id) === Number(student.id))}
                  key={student.id}
                  hideIconUncheck
                  icon={FiCheck}
                  status="basic"
                  semiRound
                  disabled={formAction === 'watching'}
                  id={`${student.id}`}
                  name={`${student.name}`}
                  className="checkbox-container"
                />
              </div>
            ))}
          </div>
        )}
      </AccordionInfoItem>
    </WorkGroupAndStudentsInfoContainer>
  );
};

export { WorkGroupAndStudentsInfo };
