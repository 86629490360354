import React from "react";
import { Container, Line } from "./styles";

interface IDividerProps {
  color?: string;
  width?: string;
  className?: string;
}

const Divider: React.FC<IDividerProps> = ({ color = "#E4E9F2", width = "90%", className }) => (
  <Container className={className}>
    <Line color={color} width={width} />
  </Container>
);

export default Divider;
