import React, { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Textarea, Input, H6, InputDatePicker } from 'react-alicerce-components';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import CustomForm from '@shared/components/CustomForm';
import ClassCard from '@modules/classes/components/ClassCard';
import GroupCard from '@modules/groups/components/GroupCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import Divider from '@shared/components/Divider';
import UserCard from '@modules/users/components/UserCard';
import StudentCard from '@modules/students/components/StudentCard';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { DailyClassList } from '@modules/attendances/components/DailyClassList';
import ParentRender from '@shared/components/ParentRender';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import TagsRender from '@modules/tags/components/TagsRender';
import { InputContainer } from './styles';

interface IOccurrenceFormProps {
  handleOnFinish?: (occurence: IOccurrence) => void;
  errors: IInputErrorsFormat[];
}

const OccurrenceForm: React.FC<IOccurrenceFormProps> = ({ errors, handleOnFinish }) => {
  const { occurrenceCurrent, setOccurrenceCurrent } = useOccurrences();

  const navigate = useNavigate();

  const removeUserCard = useCallback(
    (index: number) => {
      if (!occurrenceCurrent || !occurrenceCurrent.lms_users) return;
      const newUsers = occurrenceCurrent?.lms_users.filter((_, id) => id !== index);
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, lms_users: newUsers };
      });
    },
    [occurrenceCurrent, setOccurrenceCurrent]
  );

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputName = event.target.name;
      const inputValue = event.target.value;
      setOccurrenceCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setOccurrenceCurrent]
  );

  const removeStudentCard = useCallback(
    (index: number) => {
      if (!occurrenceCurrent || !occurrenceCurrent.students) return;
      const newStudents = occurrenceCurrent.students.filter((_, id) => id !== index);
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, students: newStudents };
      });
    },
    [occurrenceCurrent, setOccurrenceCurrent]
  );

  const removeTagFromOccurence = useCallback(
    (indexTag: number) => {
      if (!occurrenceCurrent) return;
      const newTags = removeItemFromArrayByIndex(indexTag, occurrenceCurrent.tags || []);
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, tags: newTags };
      });
    },
    [occurrenceCurrent, setOccurrenceCurrent]
  );

  const handleAddUsersButton = useCallback(() => {
    if (occurrenceCurrent?.daily_class) {
      navigate(`/ocorrencias/criar/${occurrenceCurrent.daily_class.id}/adicionar/lideres`);
    } else {
      navigate('/ocorrencias/criar/selecionar/usuarios');
    }
  }, [navigate, occurrenceCurrent]);

  const handleAddStudentsButton = useCallback(() => {
    if (occurrenceCurrent?.daily_class) {
      navigate(`/ocorrencias/criar/${occurrenceCurrent.daily_class.id}/adicionar/estudantes`);
    } else {
      navigate('/ocorrencias/criar/selecionar/alunos');
    }
  }, [navigate, occurrenceCurrent]);

  const handleRemoveEnvolved = useCallback(
    (envolved: 'place' | 'group' | 'class') => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, [envolved]: undefined, [`${envolved}_id`]: undefined };
      });
    },
    [setOccurrenceCurrent]
  );

  console.log(`ocurrenceCurrent 👉🏻`, occurrenceCurrent);
  return (
    <Fragment>
      <CustomForm style={{ padding: '1rem', height: 'calc(100vh - 180px)', overflowY: 'auto' }} customInputColorText="#568CF7">
        <Input
          label="Titulo da Ocorrência:"
          name="title"
          placeholder="Adicionar Título da Ocorrência"
          onChange={(event) => handleChangeInput(event)}
          useTooltipError={false}
          required
          error={errorMessageBuilder('title', errors)}
          defaultValue={occurrenceCurrent?.title}
        />

        <InputDatePicker
          handleChangeDate={(date) =>
            setOccurrenceCurrent((oldState) => {
              return { ...oldState, day: date };
            })
          }
          defaultValue={occurrenceCurrent?.day}
          disabled
          input={{
            label: 'Data da Ocorrência:',
            name: 'day',
            placeholder: 'Dia/Mês/Ano',
            useTooltipError: false,
            required: true,
            error: errorMessageBuilder('date', errors),
          }}
        />

        <Input
          label="Horário da Ocorrência:"
          name="time"
          onChange={(event) => handleChangeInput(event)}
          useTooltipError={false}
          error={errorMessageBuilder('time', errors)}
          defaultValue={occurrenceCurrent?.time}
          mask="##:##"
          placeholder="00:00"
          required
        />

        <Textarea
          label="Descrição da Ocorrência:"
          name="description"
          placeholder="Adicione Descrição da Ocorrência"
          value={occurrenceCurrent?.description}
          onChange={(event) => handleChangeInput(event)}
          required
          error={errorMessageBuilder('description', errors)}
          useTooltipError={false}
          rows={4}
          icon={undefined}
        />

        <InputContainer>
          <ParentRender
            customStyles={{ margin: '1.5rem 0 0 0' }}
            required
            handleOnClick={() => navigate('/ocorrencias/criar/selecionar/locais')}
            label="Local Envolvido:"
            hasParent={!!occurrenceCurrent?.place}
          >
            {occurrenceCurrent?.place && (
              <PlaceCard
                place={occurrenceCurrent.place}
                canSelect={false}
                isInputCard={true}
                customStyle={{ margin: '1rem' }}
                canDeleteCard={{ removeCallback: () => handleRemoveEnvolved('place') }}
              />
            )}
          </ParentRender>
        </InputContainer>
        <InputErrorMessage error={errorMessageBuilder('context', errors) || ''} />

        <InputContainer>
          <ParentRender
            customStyles={{ margin: '1.5rem 0 0 0' }}
            required
            handleOnClick={() => navigate('/ocorrencias/criar/selecionar/grupos')}
            label="Grupo Envolvido:"
            hasParent={!!occurrenceCurrent?.group}
          >
            {occurrenceCurrent?.group && (
              <GroupCard
                group={occurrenceCurrent.group}
                canSelect={false}
                inputCard={true}
                customStyle={{ margin: '1rem' }}
                canDeleteCard={{ removeCallback: () => handleRemoveEnvolved('group') }}
              />
            )}
          </ParentRender>
        </InputContainer>
        <InputErrorMessage error={errorMessageBuilder('context', errors) || ''} />

        <InputContainer>
          <ParentRender
            customStyles={{ margin: '1.5rem 0 0 0' }}
            required
            handleOnClick={() => navigate('/ocorrencias/criar/selecionar/turmas')}
            label="Turma Envolvida:"
            hasParent={!!occurrenceCurrent?.class}
          >
            {occurrenceCurrent?.class && (
              <ClassCard
                classInfo={occurrenceCurrent.class}
                canSelect={false}
                inputCard={true}
                customStyle={{ margin: '1rem' }}
                canDeleteCard={{ removeCallback: () => handleRemoveEnvolved('class') }}
              />
            )}
          </ParentRender>
        </InputContainer>
        <InputErrorMessage error={errorMessageBuilder('context', errors) || ''} />

        {occurrenceCurrent?.class?.id && occurrenceCurrent.day && (
          <DailyClassList
            classId={occurrenceCurrent.class.id}
            day={occurrenceCurrent?.day.split('/').reverse().join('-')}
            selectedDailyClass={occurrenceCurrent.daily_class}
          />
        )}

        <TagsRender
          pathToRedirect="/ocorrencias/criar/selecionar/tags"
          canRemoveTag={true}
          tagCallback={removeTagFromOccurence}
          label="Tags Relacionadas:"
          tags={occurrenceCurrent?.tags}
          placeholder="Adicionar Tags Relacionadas"
          customStyles={{ margin: '1.5rem 0rem' }}
        />

        <Textarea
          label="Comentários:"
          name="comment"
          placeholder="Adicionar Comentários"
          value={occurrenceCurrent?.comment}
          onChange={(event) => handleChangeInput(event)}
          error={errorMessageBuilder('comments', errors)}
          useTooltipError={false}
          rows={4}
        />

        <Divider width="100%" />

        <H6 fontWeight={800} color="#568CF7">
          PESSOAS ENVOLVIDAS
        </H6>

        <AccordionInfoItem
          colorHeader="#DAE3F6"
          title={`Usuários Envolvidos (${occurrenceCurrent && occurrenceCurrent.lms_users ? occurrenceCurrent.lms_users?.length : 0})`}
        >
          <ButtonAddCard text="Inserir Usuários" handleOnClick={handleAddUsersButton} />
          {occurrenceCurrent?.lms_users?.map((lmsUser, index) => (
            <UserCard
              key={lmsUser.id}
              canSelect={false}
              user={lmsUser}
              canDeleteCard={{ indexId: index, removeCallback: () => removeUserCard(index) }}
            />
          ))}
        </AccordionInfoItem>

        <AccordionInfoItem
          colorHeader="#DAE3F6"
          title={`Alunos Envolvidos (${occurrenceCurrent && occurrenceCurrent.students ? occurrenceCurrent.students?.length : 0})`}
        >
          <ButtonAddCard text="Inserir Alunos" handleOnClick={handleAddStudentsButton} />
          <div className="cards-container">
            {occurrenceCurrent?.students?.map((student, index) => (
              <StudentCard
                key={student.id}
                canSelect={false}
                student={student}
                canDeleteCard={{ indexId: index, removeCallback: () => removeStudentCard(index) }}
              />
            ))}
          </div>
        </AccordionInfoItem>
      </CustomForm>
    </Fragment>
  );
};

export default OccurrenceForm;
