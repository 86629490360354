import React, { createContext, useCallback, useContext, useState } from 'react';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { IPeriod } from '@modules/schedules/interfaces/IPeriod';
import { ICategoryGroups } from '@modules/projects/views/HeadlightsApproval';
export interface IHeadlightsProviderProps {
  isOpen?: boolean
  canSelect?: boolean;
  showLegend?: boolean;
  showLabel?: boolean;
  showEditable?: boolean;
  getValuesToSave?: (e?: any) => void;
  getValuesToCancel?: (e: any) => void;
  resetGroupMarkers?: (e: any) => void;
}

type IHeadlightsContext = {
  clearHeadlightCreate: () => void;
  currentHeadlight?: IHeadlight;
  setCurrentHeadlight: (value: any) => void;
  setHeadlightErros: (value: any) => void;
  headlightErrors: IInputErrorsFormat[];
  currentSprint?: ISprint;
  setCurrentSprint: (value: any) => void;
  currentPeriod?: IPeriod;
  setCurrentPeriod: (value: any) => void;
  lockScroll: boolean;
  setLockScroll: (value: any) => void;
  groupCategoryHeadlightApproval?: ICategoryGroups;
  setGroupCategoryHeadlightApproval: (value: any) => void;
};

const HeadlightsContext = createContext<IHeadlightsContext>({} as IHeadlightsContext);

const HeadlightsProvider: React.FC<IHeadlightsProviderProps> = ({
  children
}) => {
  const [currentHeadlight, setCurrentHeadlight] = useState<IHeadlight>({});
  const [headlightErrors, setHeadlightErros] = useState<IInputErrorsFormat[]>([]);
  const [currentSprint, setCurrentSprint] = useState<ISprint>();
  const [currentPeriod, setCurrentPeriod] = useState<IPeriod>();
  const [lockScroll, setLockScroll] = useState<boolean>(false);
  const [groupCategoryHeadlightApproval, setGroupCategoryHeadlightApproval] = useState<ICategoryGroups>();

  const clearHeadlightCreate = useCallback(() => {
    setCurrentHeadlight({});
  }, []);
  
  return (
    <HeadlightsContext.Provider
      value={{
        setLockScroll,
        lockScroll,
        clearHeadlightCreate,
        currentHeadlight,
        setCurrentHeadlight,
        headlightErrors,
        setHeadlightErros,
        currentSprint,
        setCurrentSprint,
        currentPeriod,
        setCurrentPeriod,
        groupCategoryHeadlightApproval,
        setGroupCategoryHeadlightApproval
      }}
    >
      {children}
    </HeadlightsContext.Provider>
  );
};

function useHeadlights() {
  const context = useContext(HeadlightsContext);

  if (!context) {
    throw new Error('useHeadlights must be used within a HeadlightsContext');
  }

  return context;
}

export { HeadlightsProvider, useHeadlights };
