import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { combineContext } from '@shared/utils/combineContext';
import IRoute from '@shared/interfaces/IRoute';

import WorkGroupList from '@modules/work-group/views/WorkGroupList';
import WorkGroupInfo from '@modules/work-group/views/WorkGroupInfo';
import WorkGroupStudentsRelationCreate from '@modules/work-group/views/WorkGroupStudentsRelationCreate';
import { WorkGroupBlocksRelationCreate } from '@modules/work-group/views/WorkGroupBlocksRelationCreate';
import { WorkGroupCreate } from '@modules/work-group/views/WorkGroupCreate';
import { WorkGroupEdit } from '@modules/work-group/views/WorkGroupEdit';
import { WorkGroupProvider } from '@modules/work-group/context/WorkGroupContext';

import { workGroupKeys } from '@modules/work-group/keys';

const routes: IRoute[] = [
  {
    path: '/grupo-de-trabalho/turma/:classId',
    key: 'WORK_GROUP_LIST_PATH',
    exact: true,
    component: WorkGroupList,
    permissions: [
      workGroupKeys.WORK_GROUP_READ
    ]
  },
  {
    path: '/grupo-de-trabalho/criar/turma/:classId',
    key: 'WORK_GROUP_CREATE_PATH',
    exact: true,
    component: WorkGroupCreate,
    permissions: [
      workGroupKeys.WORK_GROUP_CREATE
    ]
  },
  {
    path: '/grupo-de-trabalho/:workGroupId/editar/turma/:classId',
    key: 'WORK_GROUP_EDIT_PATH',
    exact: true,
    component: WorkGroupEdit,
    permissions: [
      workGroupKeys.WORK_GROUP_UPDATE
    ]
  },
  {
    path: '/grupo-de-trabalho/:workGroupId/informacoes/turma/:classId',
    key: 'WORK_GROUP_INFO_PATH',
    exact: true,
    component: WorkGroupInfo,
    permissions: [
      workGroupKeys.WORK_GROUP_READ
    ]
  },
  {
    path: '/grupo-de-trabalho/selecionar/blocos/turma/:classId',
    key: 'WORK_GROUP_SELECT_BLOCKS_PATH',
    exact: true,
    component: WorkGroupBlocksRelationCreate,
    permissions: 'public'
  },
  {
    path: '/grupo-de-trabalho/selecionar/alunos/turma/:classId',
    key: 'WORK_GROUP_SELECT_STUDENTS_PATH',
    exact: true,
    component: WorkGroupStudentsRelationCreate,
    permissions: 'public'
  },
];

const providers = [WorkGroupProvider];

export default {
  key: 'WORK_GROUP_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
