import React, { useCallback, useEffect, useState } from 'react';
import { Button, H6, Textarea, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ClassPlannerForm from '@modules/class-planner/components/ClassPlannerForm';
import { ClassPlannerEditContainer } from './styles';
import { useScheduleGrid } from '../../context/ScheduleGridContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ConfirmModal from '@shared/components/ConfirmModal';
import Icon from 'react-eva-icons';
import useAuth from '@shared/store/auth/hook';
import { IInputErrorsFormat, beforeSubmitForm } from '@shared/utils/beforeSubmitForm';
import { dailyClassSchema } from '../../utils/dailyClassSchema';
import { formatDailyClass } from '../../utils/formatDailyClass';
import UpdateDailyClassService, { CustomErrorMessage } from '../../services/UpdateDailyClassService';
import { IFormattedDailyClass } from '../../interfaces/IFormattedDailyClass';
import CustomForm from '@shared/components/CustomForm';
import Divider from '@shared/components/Divider';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import UserCard from '@modules/users/components/UserCard';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';
import InvalidateDailyClassService from '@modules/class-planner/services/InvalidateDailyClassService';
import GetDailyClassService from '@modules/class-planner/services/GetDailyClassService';

const ClassPlannerEdit: React.FC = () => {
  const { classId, dailyClassId } = useParams<{ classId: string; dailyClassId: string }>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { dailyClass, setDailyClass, setProblems } = useScheduleGrid();
  const [formattedDailyClass, setFormattedDailyClass] = useState<IFormattedDailyClass>();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [editOrInvalidate, setEditOrInvalidate] = useState<'Invalidar' | 'Editar'>('Editar');
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();

  const getDailyClass = useCallback(async () => {
    if (!dailyClassId) return navigate(-1);
    setIsLoading(true);
    const res = await new GetDailyClassService(signedUser?.token).execute(dailyClassId);
    if (!res) return navigate((-1));

    setIsLoading(false);

    if (Number(res.class_id) !== Number(classId)) {
      addToast({
        status: 'warning',
        title: 'Aula',
        description: 'A aula não pertence a essa turma',
      });
      return navigate(-1);
    }

    setDailyClass(res);
    const dailyClassProblems = res.plan?.pending_problems ? res.plan?.pending_problems : [];
    setProblems(dailyClassProblems);
  }, [addToast, classId, dailyClassId, navigate, setDailyClass, setIsLoading, setProblems, signedUser]);

  const canSaveDailyClass = useCallback(async () => {
    const formatedBody = formatDailyClass(dailyClass);
    let formErros = await beforeSubmitForm({ ...formatedBody, class_id: classId }, dailyClassSchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    const auxFormattedBody = formatedBody as IFormattedDailyClass;
    setShowConfirmModal(true);
    setFormattedDailyClass(auxFormattedBody);
  }, [classId, dailyClass]);

  const handleInvalidate = useCallback(async () => {
    if (!dailyClassId) return console.log(`dailyClassId is undefined`);

    if (!dailyClass?.invalidation_reason)
      return addToast({
        status: 'danger',
        title: 'Invalidação',
        description: 'O motivo da invalidação é obrigatório',
      });

    setShowConfirmModal(false);
    setIsLoading(true);

    const res = await new InvalidateDailyClassService(signedUser?.token).execute(dailyClass.invalidation_reason, dailyClassId);

    setIsLoading(false);

    const resWithMessage = res as CustomErrorMessage;
    if (resWithMessage.isFailed) {
      addToast({
        status: 'danger',
        title: 'Aula',
        description: resWithMessage?.message,
      });
      return;
    }

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Aula',
        description: 'Erro ao invalidar a Aula.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Aula',
      description: 'Aula Invalidada com sucesso.',
    });

    navigate(-2);
  }, [addToast, dailyClass, dailyClassId, navigate, setIsLoading, signedUser]);

  const handleEdit = useCallback(async () => {
    if (!classId) return console.log(`classId is undefined`);
    if (!dailyClassId) return console.log(`dailyClassId is undefined`);
    if (!formattedDailyClass) return console.log('formattedDailyClass id undefined');

    setShowConfirmModal(false);
    setIsLoading(true);

    const res = await new UpdateDailyClassService(signedUser?.token).execute(formattedDailyClass, dailyClassId);

    setIsLoading(false);
    const resWithMessage = res as CustomErrorMessage;
    if (resWithMessage.isFailed) {
      addToast({
        status: 'danger',
        title: 'Aula',
        description: resWithMessage?.message,
      });
      return;
    }

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Aula',
        description: 'Erro ao editar o Aula.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Aula',
      description: 'Aula editada com sucesso.',
    });

    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`);
  }, [addToast, classId, dailyClassId, formattedDailyClass, navigate, setIsLoading, signedUser]);

  useEffect(() => {
    //@TODO Remover esse get e enviar para página da turma quando a invalidação de aula for lançada no novo planejador(GIP)
    if (!dailyClass?.id) getDailyClass()
  }, [dailyClass, getDailyClass]);

  const handleChangeInvalidationReason = useCallback(
    (value: string) => {
      setDailyClass((oldState) => {
        return { ...oldState, invalidation_reason: value.length > 0 ? value : undefined };
      });
    },
    [setDailyClass]
  );

  const removeUserCard = useCallback(
    (index: number) => {
      if (!dailyClass || !dailyClass.plan?.teachers) return;
      const newUsers = dailyClass.plan?.teachers.filter((_, id) => id !== index);
      setDailyClass((oldState: IDailyClass) => {
        return { ...oldState, plan: { ...oldState.plan, teachers: newUsers } };
      });
    },
    [dailyClass, setDailyClass]
  );

  const handleAddUsersButton = useCallback(() => {
    if (!dailyClass) return;
    navigate('/planejamento-aula/criar/selecionar/usuarios');
  }, [dailyClass, navigate]);

  return (
    <ClassPlannerEditContainer className="class-planner-edit-container">
      <HeaderDirectory
        title="Editar Aula"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
      />
      <DirectoryContainer footer={true} className="class-planner-edit-directory-container">
        <ClassPlannerForm errors={errors} pathEnvolvedPlace={`/planejamento-aula/turma/${classId}/selecionar/lugar`} />

        <Divider width="100%" />

        <H6 fontWeight={800} color="#B85EC4">
          PARTICIPANTES
        </H6>

        <AccordionInfoItem
          colorHeader="#DAE3F6"
          title={`Líderes (${dailyClass && dailyClass.plan?.teachers ? dailyClass.plan?.teachers.length : 0})`}
        >
          <ButtonAddCard text="Inserir Usuários" handleOnClick={handleAddUsersButton} />
          {dailyClass?.plan?.teachers?.map((lmsUser, index) => (
            <UserCard
              key={lmsUser.id}
              canSelect={false}
              user={lmsUser}
              canDeleteCard={{ indexId: index, removeCallback: () => removeUserCard(index) }}
            />
          ))}
        </AccordionInfoItem>

        <Divider width="100%" />

        <Button
          transform="none"
          status={'danger'}
          start={<Icon name="close" fill="#FFFFFF" />}
          onClick={() => {
            setEditOrInvalidate('Invalidar');
            setShowConfirmModal(true);
          }}
          className="invalidation-button"
        >
          Invalidar Aula
        </Button>

        <PageFooter>
          <Button
            transform="none"
            outline
            shaded
            onClick={() => navigate(-1)}
            className="button-cancel-activity"
          >
            Cancelar
          </Button>
          <Button transform="none" shaded onClick={canSaveDailyClass} className="mochileiro-color">
            Salvar
          </Button>
        </PageFooter>

        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showConfirmModal}
          closeCallback={() => {
            setEditOrInvalidate('Editar');
            setShowConfirmModal(false);
          }}
          title={`Deseja ${editOrInvalidate} Aula?`}
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={editOrInvalidate === 'Invalidar' ? handleInvalidate : handleEdit}
          status="secondary"
        >
          {editOrInvalidate === 'Invalidar' && (
            <CustomForm style={{ width: '90%' }}>
              <Textarea
                onChange={(event) => handleChangeInvalidationReason(event.target.value)}
                placeholder="Adicionar Motivo"
                name={'invalidation_reason'}
                required
                label="Motivo para Invalidação de Aula:"
                value={dailyClass?.invalidation_reason}
                rows={4}
              />
            </CustomForm>
          )}
        </ConfirmModal>
      </DirectoryContainer>
    </ClassPlannerEditContainer>
  );
};

export default ClassPlannerEdit;
