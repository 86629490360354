import React, { useCallback, useEffect, useState } from 'react';
import { H6, Pagination } from 'react-alicerce-components';
import IPlace from '@modules/places/interfaces/IPlace';
import PlaceCard from '@modules/places/components/PlaceCard';
import { IPlaceReponse } from '@modules/places/services/ListPlacesService';
import ListPlacesUserManagesService from '@modules/places/services/ListPlacesUserManagesService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';

interface IPlacesSelectionProps {
  handleOnClick: (placeClicked: IPlace) => void;
  searchValue: string;
}

const PlacesSelection: React.FC<IPlacesSelectionProps> = ({ handleOnClick, searchValue }) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [places, setPlaces] = useState<IPlaceReponse>();
  const isMounted = useIsMounted();
  const { newCancelToken } = useCancelRequest();

  const listPlacesUserManages = useCallback(
    async (search = '', page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new ListPlacesUserManagesService(signedUser.token, newCancelToken()).execute(search, {
        page,
        size: 10,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (res && isMounted()) setPlaces(res);
    },
    [isMounted, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listPlacesUserManages();
  }, [listPlacesUserManages]);

  useEffect(() => {
    listPlacesUserManages(searchValue);
  }, [listPlacesUserManages, searchValue]);

  return (
    <>
      <H6 textAlign="center" status="primary" fontWeight={800}>
        LOCAIS QUE LIDERO
      </H6>
      <div>{places && places.items.map((place) => <PlaceCard key={place.id} place={place} handleOnClick={handleOnClick} />)}</div>

      {!!places?.items.length && (
        <Pagination totalOfPages={places.totalPages} currentPage={places.currentPage} callbackGetListData={listPlacesUserManages} status="basic" />
      )}
    </>
  );
};

export default PlacesSelection;
