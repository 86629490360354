import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IDailyClass } from '../../attendances/interfaces/IDailyClass';
import { IFormattedDailyClass } from '../interfaces/IFormattedDailyClass';

class CreateDailyClassService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(dailyClassBody: IFormattedDailyClass): Promise<IDailyClass | false> {
    try {
      const res = await this.api.post('/daily-class', { ...dailyClassBody });
      return res.data as IDailyClass;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default CreateDailyClassService;
