import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, H6, Pagination } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';

import { IProgramsReponse } from '@modules/activities-library/services/ListProgramsService';
import { IProgramFilterFormatted, useProgram } from '@modules/activities-library/context/ProgramContext';
import { ProgramListContainer } from '@modules/activities-library/components/ProgramList/styles';
import { ProgramCard } from '@modules/activities-library/components/ProgramCard';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';
import TagsRender from '@modules/tags/components/TagsRender';

import PageFooter from '@shared/components/PageFooter';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { useAdvancedFilterController } from '../../context/AdvancedFilterControllerContext';
import { IWorkGroup } from '../../../work-group/interfaces/IWorkGroup';
import { ListWorkGroupsWithSkillsService } from '../../../work-group/services/ListWorkGroupsWithSkillsService';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { FiCheck } from 'react-icons/fi';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import { extractSkillsFromWorkGroup } from '../../utils/extractSkillsFromWorkGroup';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { formatProgramFilter } from '../../utils/formatProgramFilter';

interface IProgramList {
  pathOnClickCard?: string;
  buttonText: string;
  pathOnClickButton: string;
  handleOnClickButton?: (value: any[]) => void;
  listProgramsCallback: (search: string, page: number, filteredPrograms?: IProgramFilterFormatted) => Promise<IProgramsReponse | undefined>;
  filterByWorkGroup?: boolean;
}

const ProgramList: React.FC<IProgramList> = ({ listProgramsCallback, ...props }) => {
  const [programs, setPrograms] = useState<IProgramsReponse>();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const { isAdvancedFilterOpen } = useAdvancedFilterController();
  const { clearProgramContextData, programFilter } = useProgram();
  const { setOpenTab } = useAdvancedFilterController();
  const [workGroups, setWorkGroups] = useState<IWorkGroup[]>();
  const { classId } = useParams<{ classId: string }>();
  const [search, setSearch] = useState<string>('');
  const [filterParams, setFilterParams] = useState<IProgramFilterFormatted>();

  const isWorkGroupCheckboxChecked = useCallback(
    (workGroup: IWorkGroup) => {
      let areAllSkillsFiltered: boolean = true;
      const extractedSkillIds = extractSkillsFromWorkGroup(workGroup).map((sk) => String(sk.id));
      const find = programFilter.current.skills?.filter((filteredSkill) => !extractedSkillIds.includes(String(filteredSkill.id)));
      if (!find) areAllSkillsFiltered = false;
      return areAllSkillsFiltered;
    },
    [programFilter]
  );

  const listPrograms = useCallback(
    async (page: number = 0) => {
      const formatted = filterParams ? filterParams : undefined;
      const res = await listProgramsCallback(search, page, formatted);
      if (!res || !isMounted()) return;
      setPrograms(res);
    },
    [filterParams, isMounted, listProgramsCallback, search]
  );

  const handleButtonCreate = useCallback(() => {
    clearProgramContextData();
    navigate(props.pathOnClickButton);
  }, [clearProgramContextData, navigate, props.pathOnClickButton]);

  const handleOnClickCard = useCallback(
    (program: IProgram) => {
      setOpenTab('program');
      props.pathOnClickCard && navigate(props.pathOnClickCard.replace(/\/:[A-z]+\//, `/${program.id}/`));
    },
    [navigate, props.pathOnClickCard, setOpenTab]
  );

  const handleChangeWorkGroupCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, workGroup: IWorkGroup) => {
      const isChecked = event.target.checked;
      const extractedSkills = extractSkillsFromWorkGroup(workGroup);

      if (isChecked) {
        programFilter.current.skills = [...(programFilter.current.skills || []), ...extractedSkills];
        const params = formatProgramFilter(programFilter.current);
        setFilterParams(params);
      } else {
        const workGroupSkillIds = extractedSkills.map((sk) => String(sk.id));
        const filtered = programFilter.current.skills?.filter((filteredSkill) => !workGroupSkillIds.includes(String(filteredSkill.id)));
        programFilter.current.skills = filtered;
        const params = formatProgramFilter(programFilter.current);
        setFilterParams(params);
      }
    },
    [programFilter]
  );

  const listWorkGroups = useCallback(
    async (search: string = '', page: number = 0) => {
      if (!classId) return console.log('classId is undefined', 'classId >> ', classId);
      const res = await new ListWorkGroupsWithSkillsService(signedUser?.token, newCancelToken()).execute(search, { class_id: `${classId}`, page });
      if (!res) return console.log('Error ao listar WORKGROUPS');
      if (res === 'canceling') return;
      if (!isMounted()) return;
      setWorkGroups(res.items);
    },
    [classId, isMounted, newCancelToken, signedUser]
  );

  const handleResetFilter = () => {
    programFilter.current = {};
    setFilterParams({});
    setSearch('');
    listPrograms();
  };

  const handleApplyFilter = () => {
    const params = formatProgramFilter(programFilter.current);
    setFilterParams(params);
  };

  useEffect(() => {
    listPrograms();
  }, [listPrograms]);

  useEffect(() => {
    props.filterByWorkGroup && !workGroups && listWorkGroups();
  }, [listWorkGroups, props.filterByWorkGroup, workGroups]);

  return (
    <Fragment>
      <ProgramListContainer>
        {/* START FILTER */}
        <AdvancedFilter
          onChangeMainInput={(newValue) => setSearch(newValue)}
          firstRenderOpenStatus={isAdvancedFilterOpen}
          applyFilterButtonBackground="#96B6F7"
          handleResetFilter={handleResetFilter}
          handleApplyFilter={handleApplyFilter}
        >
          <TagsRender
            placeholder="Adicione Tags ao Programa"
            tags={programFilter.current.tags}
            label="Tags do Programa:"
            pathToRedirect="/banco-atividades/selecionar/programa/tags"
          />
        </AdvancedFilter>
        {/* END FILTER */}

        {/* START FILTER WORKEDGROUP */}
        {props.filterByWorkGroup && (
          <Fragment>
            <H6 fontWeight={800} color="#B85EC4">
              FILTRAR POR GRUPOS DE TRABALHO
            </H6>
            <AccordionInfoItem title={`Grupos de Trabalho (${workGroups ? workGroups.length : 0}) `} colorHeader="#DAE3F6">
              <div className="work-group-checkboxes-wrapper">
                {(workGroups || []).map((wg) => (
                  <div className="work_group_wrapper" key={wg.id}>
                    <Checkbox
                      key={wg.id}
                      hideIconUncheck
                      icon={FiCheck}
                      status="basic"
                      semiRound
                      id={`${wg.id}`}
                      name={`${wg.name}`}
                      defaultChecked={isWorkGroupCheckboxChecked(wg)}
                      className="checkbox-container"
                      onChange={(event) => handleChangeWorkGroupCheckbox(event, wg)}
                    />

                    <div className="work-group-info">
                      <span>{wg.name}</span>
                      <span>{wg.students_count} membros</span>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionInfoItem>
          </Fragment>
        )}
        {/* END FILTER WORKEDGROUP */}

        {programs && (
          <Fragment>
            <div className="program-card-list">
              {programs.items.map((program: IProgram, index: number) => (
                <div onClick={() => handleOnClickCard(program)} style={{ cursor: 'pointer' }} key={index}>
                  <ProgramCard program={program} />
                </div>
              ))}
            </div>

            <Pagination totalOfPages={programs.totalPages} currentPage={programs.currentPage} callbackGetListData={(_, page) => listPrograms(page)} />
          </Fragment>
        )}
      </ProgramListContainer>

      <PageFooter>
        <Button transform="none" status="primary" shaded onClick={handleButtonCreate}>
          {props.buttonText}
        </Button>
      </PageFooter>
    </Fragment>
  );
};

export { ProgramList };
