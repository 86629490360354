import { styled } from 'react-alicerce-components';

type ActivityLibraryCardContainerProps = { isSelected?: boolean; canDeleteCard: boolean, isDescriptionOpen: boolean };

export const ActivityLibraryCardContainer = styled.div<ActivityLibraryCardContainerProps>`
  gap: 0.5rem;
  cursor: pointer;
  filter: ${(props) => (props.isSelected ? 'drop-shadow(0px 0px 28px #49C56C)' : 'none')};
  display: flex;

  .card-container {
    width: ${(props) => (props.canDeleteCard ? 'calc(100vw - 100px)' : '100%')};

    b {
      max-width: 80%;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
  }

  .remove-card {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: red;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  
  .icon-container {
    transform: ${(p) => (p.isDescriptionOpen ? `rotate(90deg)` : `rotate(0deg)`)};
    transition: 0.3s;
    i {
      svg {
        height: 20px;
        width: 20px;
      }
    }
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    aspect-ratio: 1;
  }

  .eva-hover {
    display: flex;
    align-items: center;
  }

  .separator {
    height: 1px;
    width: 90%;
    margin: 16px 0;
    background-color: #568CF7;
  }
`;

export const ActivityDescription = styled.div<{ isDescriptionOpen: boolean }>`
  max-height: ${({ isDescriptionOpen }) => (isDescriptionOpen ? '1000px' : '0px')};
  transition: 0.5s 0s ease;
  transition-property: max-height, padding;
  background: #96b6f7;
  padding: ${({ isDescriptionOpen }) => (isDescriptionOpen ? '10px' : '0px 10px')};
  position: relative;
  top: -5px;
  border-radius: 0 0 5px 5px;

  label {
    word-break: break-all;
    font-weight: 600;
    font-size: 14px;
    color: #192038;
    opacity: ${({ isDescriptionOpen }) => (isDescriptionOpen ? '1' : '0')};
    visibility: ${({ isDescriptionOpen }) => (isDescriptionOpen ? 'visible' : 'hidden')};
    transition: 0.2s;
    transition-property: opacity, visibility;
  }
`;
