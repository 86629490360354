import { styled } from 'react-alicerce-components';

export const ProgramCreateContainer = styled.div`
  .container-page-footer {
    button {
      :not(.button-gradient-outline) {
        &:hover {
          background-color: #96b6f7;
          border-color: #96b6f7;
        }
      }
    }
  }
`;

export const ContainerCheck = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.563rem;
  }
`;
