import React from 'react';

import { Tab, Tabs, useTabs } from 'react-alicerce-components';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import CustomTabs from '@shared/components/CustomTabs';

import { Container } from './styles';
import { ActivityProgramList } from '@modules/activities-library/views/ActivityProgramList';

const ActivityLibraryContextSelection: React.FC = () => {
  const { handleSetTabCurrent, tabCurrent } = useTabs();

  return (
    <Container>
      <HeaderDirectory
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{ menuBackHome: true, tooltip: { view: 'ActivityLibraryContextSelection' } }}
      />
      <CustomTabs footer>
        <Tabs openTab={tabCurrent} handleTabChange={(tab) => handleSetTabCurrent(tab.index)} horizontalScroll>
          <Tab title="Início">
            <ActivityProgramList
              pathToClickButtonActivity={'/banco-atividades/criar/atividade'}
              pathClickCardActivity={'/banco-atividades/atividade/:activityId/informacoes'}
              activityTextButton={'Criar Atividade'}
              pathToClickButtonProgram={'/banco-atividades/criar/programa'}
              pathClickCardProgram={'/banco-atividades/programa/:programId/informacoes'}
              programTextButton={'Criar Programa '}
            />
          </Tab>
          <Tab title="Favoritas">
            <ActivityProgramList
              listFavorites={true}
              pathToClickButtonActivity={'/banco-atividades/criar/atividade'}
              pathClickCardActivity={'/banco-atividades/atividade/:activityId/informacoes'}
              activityTextButton={'Criar Atividade'}
              pathToClickButtonProgram={'/banco-atividades/criar/programa'}
              pathClickCardProgram={'/banco-atividades/programa/:programId/informacoes'}
              programTextButton={'Criar Programa'}
            />
          </Tab>
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export { ActivityLibraryContextSelection };
