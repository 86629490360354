import React, { useCallback} from 'react';
import { useLocationStudent } from '@shared/components/LocationStudent/context/LocationStudentContext';
import { dragEnter, dragLeave, dragOver } from '@shared/components/LocationStudent/utils/dragFunctions';
import { getGridIndex } from '@shared/components/LocationStudent/utils/getGridIndex';
import { removeAllClassesOver } from '@shared/components/LocationStudent/utils/removeAllClassesOver';
import { addClassOver } from '@shared/components/LocationStudent/utils/addClassOver';

import { LocationContainer, GroupMarker, Marker } from './styles';

interface IMarkersProps {
  gridIndex: number;
  blockName?: string;
}

const Markers: React.FC<IMarkersProps> = ({ gridIndex, blockName }) => {
  const {
    groupIdCurrent,
    groupMarkersCurrent,
    setGroupIdCurrent,
    setGroupMarkersCurrent,
    setLockScroll,
    trailCurrent,
    wrapperRef,
    isCanSelect,
    handleSelectChange,
    isEdit,
    setCurrentLocationInGrid,
  } = useLocationStudent();

  const handleDragStart = useCallback(
    (e) => {
      const groupId = e.target.id;
      setGroupIdCurrent(groupId);
      e.target.style.opacity = '.4';
    },
    [setGroupIdCurrent]
  );

  const handleDragEnd = useCallback(
    (e) => {
      setGroupIdCurrent(null);
      e.target.style.opacity = '1';
    },
    [setGroupIdCurrent]
  );

  const handleDragEnter = useCallback(
    (e) => {
      dragEnter(e, groupIdCurrent);
    },
    [groupIdCurrent]
  );

  const handleDrop = useCallback(
    (e, groupMarker) => {
      if (!trailCurrent) return;
      e.stopPropagation();

      const { grid_index } = e.target.dataset;

      const blockValues = { block: trailCurrent.blocks[gridIndex], index: gridIndex };
      handleSelectChange && handleSelectChange(blockValues.block.id);
      setCurrentLocationInGrid && setCurrentLocationInGrid(blockValues);

      setGroupMarkersCurrent((oldState) => {
        const founded = oldState.findIndex((g) => groupMarker.id === g.id);
        if (founded !== -1) {
          oldState[founded].gridIndex = Number.parseInt(grid_index);
        }
        return [...oldState];
      });

      e.target.classList.remove('over');
    },
    [gridIndex, handleSelectChange, setCurrentLocationInGrid, setGroupMarkersCurrent, trailCurrent]
  );

  const handleTouchStart = useCallback(
    (e) => {
      if (!!isCanSelect) return;
      const groupId = e.target.id;
      setLockScroll(true);
      setGroupIdCurrent(groupId);
      e.target.style.opacity = '.4';
    },
    [isCanSelect, setGroupIdCurrent, setLockScroll]
  );

  const handleTouchMove = useCallback(
    (event) => {
      if (!trailCurrent || !!isCanSelect) return;
      removeAllClassesOver();

      const t = event.changedTouches[0];
      const markerWidth = t.target.offsetWidth;
      if (t.clientX > Number(wrapperRef?.current?.offsetWidth) - markerWidth) wrapperRef?.current?.scrollBy(10, 0);
      if (t.clientX < markerWidth) wrapperRef?.current?.scrollBy(-10, 0);

      addClassOver(
        `grid-${getGridIndex({
          event,
          countGrids: trailCurrent.blocks.length,
          scrollLeft: wrapperRef?.current?.scrollLeft as number,
        })}`
      );
    },
    [trailCurrent, isCanSelect, wrapperRef]
  );

  const handleTouchEnd = useCallback(
    (event, groupMarker) => {
      if (!trailCurrent || !!isCanSelect) return;

      const gridIndex = getGridIndex({
        event,
        countGrids: trailCurrent.blocks.length,
        scrollLeft: wrapperRef?.current?.scrollLeft as number,
      });

      const blockValues = { block: trailCurrent.blocks[gridIndex], index: gridIndex };

      handleSelectChange && handleSelectChange(blockValues.block.id);

      setCurrentLocationInGrid && setCurrentLocationInGrid(blockValues);

      setGroupMarkersCurrent((oldState) => {
        const founded = oldState.findIndex((g) => groupMarker.id === g.id);
        if (founded !== -1) {
          oldState[founded].gridIndex = gridIndex;
        }
        return [...oldState];
      });

      setLockScroll(false);
      removeAllClassesOver();
      event.target.style.opacity = '1';
    },
    [trailCurrent, isCanSelect, wrapperRef, handleSelectChange, setCurrentLocationInGrid, setGroupMarkersCurrent, setLockScroll]
  );
  return (
    <LocationContainer className="location-container">
      {isEdit ? (
        <>
          {groupMarkersCurrent?.map((groupMaker, groupMarkerIndex) => {
            return (
              <GroupMarker
                id={groupMaker.id}
                key={`group-marker-${groupMarkerIndex}`}
                data-grid_index={gridIndex}
                data-group_marker_index={groupMarkerIndex}
                color={groupMaker.color}
                onDragStart={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : handleDragStart}
                onDragEnd={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : handleDragEnd}
                onDragEnter={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : handleDragEnter}
                onDragOver={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : dragOver}
                onDragLeave={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : dragLeave}
                onDrop={(e) => handleDrop(e, groupMaker)}
                onTouchStart={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : handleTouchStart}
                onTouchMove={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : handleTouchMove}
                onTouchEnd={(groupMaker.isFixed ? groupMaker.isFixed : isEdit) ? () => {} : (e) => handleTouchEnd(e, groupMaker)}
                draggable={!isCanSelect}
                isFixed={groupMaker.isFixed ? groupMaker.isFixed : isEdit}
                isHere={groupMaker.gridIndex === gridIndex}
              >
                {groupMaker.markers.map((marker, markerIndex) => (
                  <Marker key={`marker-${markerIndex}`} name={marker.name} isAfter={!!(groupMaker.gridIndex < gridIndex)} />
                ))}
              </GroupMarker>
            );
          })}
        </>
      ) : (
        <>
          {groupMarkersCurrent?.map((groupMaker, groupMarkerIndex) => {
            return (
              <GroupMarker
                id={groupMaker.id}
                key={`group-marker-${groupMarkerIndex}`}
                data-grid_index={gridIndex}
                data-group_marker_index={groupMarkerIndex}
                color={groupMaker.color}
                onDragStart={!groupMaker.isFixed ? handleDragStart : () => {}}
                onDragEnd={!groupMaker.isFixed ? handleDragEnd : () => {}}
                onDragEnter={!groupMaker.isFixed ? handleDragEnter : () => {}}
                onDragOver={!groupMaker.isFixed ? dragOver : () => {}}
                onDragLeave={!groupMaker.isFixed ? dragLeave : () => {}}
                onDrop={(e) => handleDrop(e, groupMaker)}
                onTouchStart={!groupMaker.isFixed ? handleTouchStart : () => {}}
                onTouchMove={!groupMaker.isFixed ? handleTouchMove : () => {}}
                onTouchEnd={!groupMaker.isFixed ? (e) => handleTouchEnd(e, groupMaker) : () => {}}
                draggable={!isCanSelect}
                isFixed={groupMaker.isFixed}
                isHere={groupMaker.gridIndex === gridIndex}
              >
                {groupMaker.markers.map((marker, markerIndex) => (
                  <Marker key={`marker-${markerIndex}`} name={marker.name} isAfter={!!(groupMaker.gridIndex < gridIndex)} />
                ))}
              </GroupMarker>
            );
          })}
        </>
      )}
    </LocationContainer>
  );
};

export default Markers;
