import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs from '@shared/components/CustomTabs';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';
interface ILayoutStudentApplicationProps {
  openTab?: number;
  footer?: boolean;
}

const LayoutStudentApplication: React.FC<ILayoutStudentApplicationProps> = ({ openTab = 0, footer = false, children }) => {
  const { classId, studentId } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Aplicações do Aluno"
        pathToReturn={`/aplicacoes/turma/${classId}`}
        backgroundHeader="linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Pendendo" onClick={() => navigate(`/aplicacoes/turma/${classId}/aluno/${studentId}/pendentes`)} />
          <Tab title="Em Andamento" onClick={() => navigate(`/aplicacoes/turma/${classId}/aluno/${studentId}/andamento`)} />
          <Tab title="Finalizado" onClick={() => navigate(`/aplicacoes/turma/${classId}/aluno/${studentId}/finalizado`)} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default LayoutStudentApplication;
