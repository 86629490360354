import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import { Margin } from 'recharts/types/util/types';

import Tick from '@shared/components/CustomRecharts/elements/Tick';

import { BottomLabel, Container } from './styles';

interface IBarsConfig {
    dataKey: string;
    color: string;
}

interface VerticalBarChartProps {
    data: any[];
    barsConfig: IBarsConfig[];
    width?: number;
    height?: number;
    leftLabel?: string;
    bottomLabel?: string;
    showXAxis?: boolean,
    showYAxis?: boolean,
    isPercent?: boolean,
    margin?: Margin;
    dataKey?: string;
}

const VerticalBarChart: React.FC<VerticalBarChartProps> = ({
    data,
    barsConfig,
    width,
    bottomLabel,
    leftLabel: leftLabelProps,
    height = 100,
    showXAxis = false,
    showYAxis = false,
    isPercent = true,
    margin = { top: 0, right: 50, left: 0, bottom: 0 }
}) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const [chartSize, setChartSize] = useState<{ width: number, height: number } | undefined>();

    const renderLabel = useCallback(({ x, y, fill, value }) => (
        <Tick
            y={y}
            x={Number(chartSize?.width) - 40}
            fontSize={14}
            fill={fill}
            value={`${value}${isPercent ? '%' : ''}`}
            textAnchor='start'
        />
    ), [chartSize, isPercent]);

    const renderYAxis = useCallback(({ x, y, payload }) => (
        <Tick
            y={y - 10}
            x={x - 10}
            fontSize={12}
            value={payload.value}
            transform='rotate(-35)'
        />
    ), []);

    const leftLabel = useMemo(() =>
        leftLabelProps ?
            {
                value: leftLabelProps,
                angle: -90,
                position: 'insideLeft',
                fill: '#8F9BB3',
                fontSize: 12,
                fontWeight: 700,
                className: 'custom-left-label'
            } :
            undefined,
        [leftLabelProps]);

    useEffect(() => {
        setChartSize({
            width: width || Number(containerRef.current?.clientWidth),
            height: height * data.length || Number(containerRef.current?.clientHeight),
        });
    }, [containerRef, data, height, width])


    return (
        <Container
            ref={containerRef}
            className='container-vertical-bar-chart'
        >
            <BarChart
                data={data}
                layout="vertical"
                barGap={14}
                margin={margin}
                {...chartSize}
            >
                <XAxis type="number" hide={!showXAxis} />
                <YAxis
                    type="category"
                    dataKey="name"
                    strokeDasharray="3 3"
                    hide={!showYAxis}
                    tick={renderYAxis}
                    label={leftLabel}
                />

                {barsConfig.map((b, i) =>
                    <Bar
                        key={i}
                        dataKey={b.dataKey}
                        fill={b.color}
                        label={renderLabel}
                        radius={[0, 5, 5, 0]}
                        barSize={28}
                    />
                )}

            </BarChart>
            {bottomLabel && <BottomLabel>{bottomLabel}</BottomLabel>}
        </Container>
    );
}

export default VerticalBarChart;