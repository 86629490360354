import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Pagination, Spinner } from 'react-alicerce-components';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';

import InputFilter, { IInputFilterData } from '@modules/applications/components/InputFilter';
import StudentApplicationCard from '@modules/applications/components/StudentApplicationCard';
import ListStudentApplicationFromClassService, {
  IListStudentApplicationFromClassServiceReponse,
  IStudentApplication,
} from '@modules/applications/services/ListStudentApplicationFromClassService';

const StudentsApplications: React.FC = () => {
  const navigate = useNavigate();

  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();
  const { classId } = useParams();
  const [studentsApplicationsResponse, setStudentsApplicationsResponse] = useState<IListStudentApplicationFromClassServiceReponse>();

  const listStudentsApplications = useCallback(
    async (search = '', page = 0, additionalOptions?: any) => {
      if (!signedUser) return;
      setIsLoading(true);
      const opt = {
        page,
        class_id: classId,
        ...additionalOptions,
      };

      const res = await new ListStudentApplicationFromClassService(signedUser.token, newCancelToken()).execute(search, opt);

      if (res === 'canceling' || !res) return;
      setStudentsApplicationsResponse(res);
      setIsLoading(false);
    },
    [classId, newCancelToken, setIsLoading, signedUser]
  );

  const handleSetStudentApplication = useCallback(
    (studentApplication: IStudentApplication) => {
      navigate(`/aplicacoes/turma/${classId}/aluno/${studentApplication.id}/pendentes`);
    },
    [classId, navigate]
  );

  const handleOnChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      listStudentsApplications(ev.target.value);
    },
    [listStudentsApplications]
  );

  const handleOnFilter = useCallback(
    (data: IInputFilterData) => {
      listStudentsApplications('', 0, data);
    },
    [listStudentsApplications]
  );

  useEffect(() => {
    listStudentsApplications();
  }, [listStudentsApplications]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        pathToReturn={`/lancador/avaliacao/${classId}`}
        title="Aplicações"
        backgroundHeader="linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%);"
        menuKebab={{
          tooltip: { view: 'StudentsApplications' },
          menuBackHome: true,
        }}
      />

      <div style={{ padding: '40px 20px' }}>
        <InputFilter handleOnChange={handleOnChange} handleOnFilterOptions={handleOnFilter} filters={['age_group']} />

        {studentsApplicationsResponse?.items.map((application, i) => (
          <StudentApplicationCard
            key={i}
            application={application}
            handleOnClick={() => handleSetStudentApplication(application)}
            status="quaternary"
          />
        ))}

        {studentsApplicationsResponse?.items.length === 0 && (
          <Fragment>
            <br />
            <p>Nenhuma estudante com aplicação para mostrar...</p>
          </Fragment>
        )}

        {studentsApplicationsResponse && studentsApplicationsResponse.totalPages > 0 && (
          <Pagination
            totalOfPages={studentsApplicationsResponse.totalPages}
            currentPage={studentsApplicationsResponse.currentPage}
            callbackGetListData={listStudentsApplications}
            status="quaternary"
          />
        )}
      </div>
    </Fragment>
  );
};

export default StudentsApplications;
