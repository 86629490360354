import React from 'react';

import {
  Button,
  Spinner,
  Tab,
  Tabs,
} from 'react-alicerce-components';

import { IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectDefaultStylesContainer } from '../ProjectDefaultStyles/styles';

import { ProjectInfoFragment } from './partials/ProjectInfoFragment';
import { ProjectClassesFragment } from './partials/ProjectClassesFragment';

import { ProjectFormContainer } from './styles';

interface ProjectFormProps {
  headerTitle: string;
  headerPathToReturn: string;
  pathAddClasses: string;
  pathAddContract: string;
  pathAddGroup: string;
  errors?: IInputErrorsFormat[];
  handleCancel: () => void;
  deleteCallback?: () => void;
  handleConfirmButton: () => Promise<void>;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  headerTitle,
  headerPathToReturn,
  pathAddClasses,
  pathAddContract,
  pathAddGroup,
  handleCancel,
  deleteCallback,
  handleConfirmButton,
}) => {
  const { clearProjectCreate } = useProjects();
  const { isLoading } = useGlobal();
  return (
    <ProjectDefaultStylesContainer>
      <ProjectFormContainer>
        {isLoading && <Spinner />}
        <HeaderDirectory
          title={headerTitle}
          pathToReturn={headerPathToReturn}
          onClickReturn={clearProjectCreate}
          menuKebab={{ deleteCallback }}
        />
        <DirectoryContainer footer={true}>
          <CustomTabs footer>
            <Tabs horizontalScroll>
              <Tab title="Informação">
                <ProjectInfoFragment
                  pathAddContract={pathAddContract}
                  pathAddGroup={pathAddGroup}
                />
              </Tab>
              <Tab title="Turmas">
                <ProjectClassesFragment pathAddClasses={pathAddClasses} />
              </Tab>
            </Tabs>
          </CustomTabs>
          <PageFooter>
            <Button
              className='btn-cancel'
              transform="none"
              onClick={handleCancel}
              shaded
              outline
            >
              Cancelar
            </Button>
            <Button
              transform="none"
              shaded
              noBorder
              onClick={handleConfirmButton}
            >
              Salvar
            </Button>
          </PageFooter>
        </DirectoryContainer>
      </ProjectFormContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectForm;
