// constraints de age group

export const SEX_MALE = { name: 'Masculino', value: 'male' }; // Masculino
export const SEX_FEMALE = { name: 'Feminino', value: 'female' }; // Feminino
export const SEX_NOT_INFORMED = { name: 'Não informado', value: 'not_informed' }; // Não informado
export const SEX_NOT_COLLECTED = { name: 'Não coletado', value: 'not_collected' }; // Não coletado.

export const GENDER_CONSTRAINTS = [SEX_MALE, SEX_FEMALE, SEX_NOT_INFORMED, SEX_NOT_COLLECTED];

export const defaultGender = (gender?: string | null) => {
  return GENDER_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: gender === foo.value };
  });
};

export const GENDERS_VALUES = GENDER_CONSTRAINTS.map((a) => a.value);
