import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import React from 'react';
import { Pill } from 'react-alicerce-components';
import { Container } from './styles';

interface IWorkedHoursCardFooterProps {
  workedHour: IWorkedHour;
}

export const WorkedHoursCardFooter: React.FC<IWorkedHoursCardFooterProps> = ({ workedHour }) => {
  return (
    <Container className="card footer">
      {
        <Pill
          size="small"
          marginLeft="4px"
          className="tag"
          key={workedHour?.category?.id}
          backgroundColor={workedHour?.category?.color ? workedHour?.category?.color : `#E4E9F2`}
        >
          {workedHour?.category?.name ? workedHour?.category?.name : `Categoria não definida`}
        </Pill>
      }

      {
        <Pill
          size="small"
          marginLeft="4px"
          className="tag"
          key={workedHour?.modality?.id}
          backgroundColor={workedHour?.modality?.color ? workedHour?.modality?.color : `#E4E9F2`}
        >
          {workedHour?.modality?.name ? workedHour?.modality?.name : `Modalidade não definida`}
        </Pill>
      }

      {
        <Pill
          size="small"
          marginLeft="4px"
          className="tag"
          key={workedHour?.project?.id}
          backgroundColor={workedHour?.project?.color ? workedHour?.project?.color : `#E4E9F2`}
        >
          {workedHour?.project?.name ? workedHour.project.name : `Projeto não definido`}
        </Pill>
      }
    </Container>
  );
};
