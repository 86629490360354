import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, H6, Input, Spinner, Textarea, useToast } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

import CustomForm from '@shared/components/CustomForm';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import ClassCard from '@modules/classes/components/ClassCard';
import GroupCard from '@modules/groups/components/GroupCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import StudentCard from '@modules/students/components/StudentCard';
import TagsRender from '@modules/tags/components/TagsRender';
import UserCard from '@modules/users/components/UserCard';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import CardsRender from '@shared/components/CardsRender';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';
import ConfirmModal from '@shared/components/ConfirmModal';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useNavigate, useParams } from 'react-router-dom';
import InvalidateOccurrenceService from '@modules/occurrences/services/InvalidateOccurrenceService';
import SolveOccurrenceService from '@modules/occurrences/services/SolveOccurrenceService';
import GetOccurrenceService from '@modules/occurrences/services/GetOccurrenceService';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { getDay } from '@shared/utils/getDay';
import { getTime } from '@shared/utils/getTime';
import { IOccurrence } from '@modules/occurrences/interfaces/IOccurrence';
import { TodayClassCard } from '@modules/attendances/components/TodayClassCard';

const OccurenceInfo: React.FC = () => {
  const [showInvalidateModal, setShowInvalidateModal] = useState<boolean>(false);
  const [showSolveModal, setShowSolveModal] = useState<boolean>(false);
  const { contextSelected } = useOccurrences();
  const [occurrence, setOccurrence] = useState<IOccurrence>();
  const { signedUser } = useAuth();
  const { setIsLoading, isLoading } = useGlobal();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const occurrenceIdInPath = useParams<{ id: string }>();
  const { newCancelToken } = useCancelRequest();

  const handleInvalidateOccurrence = useCallback(async () => {
    if (!signedUser || !occurrence || !occurrence.id) return;
    setShowInvalidateModal(false);
    setIsLoading(true);
    const res = await new InvalidateOccurrenceService(signedUser.token).execute(occurrence.id);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Ocorrências',
        description: 'Falha ao invalidar a Ocorrência. Tente novamente.',
      });
      return;
    }
    addToast({
      status: 'success',
      title: 'Ocorrências',
      description: 'Ocorrência invalidada com sucesso .',
    });
    navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/pendentes`);
  }, [addToast, contextSelected, navigate, occurrence, setIsLoading, signedUser]);

  const handleSolveOccurrence = useCallback(async () => {
    if (!signedUser || !occurrence || !occurrence.id) return;
    setShowSolveModal(false);
    setIsLoading(true);
    const res = await new SolveOccurrenceService(signedUser.token).execute(occurrence.id);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Ocorrências',
        description: 'Falha ao solucionar a Ocorrência. Tente novamente.',
      });
      return;
    }
    addToast({
      status: 'success',
      title: 'Ocorrências',
      description: 'Ocorrência solucionada com sucesso .',
    });
    navigate(`/ocorrencias/${contextSelected?.context}/${contextSelected?.context_id}/pendentes`);
  }, [addToast, contextSelected, navigate, occurrence, setIsLoading, signedUser]);

  const getOccurrence = useCallback(async () => {
    if (!occurrenceIdInPath.id) return navigate('/occorrencias');
    setIsLoading(true);
    const occurrenceFound = await new GetOccurrenceService(signedUser?.token, newCancelToken()).execute(occurrenceIdInPath.id);
    setIsLoading(false);
    if (!occurrenceFound) return navigate('/occorrencias');
    setOccurrence(occurrenceFound);
  }, [navigate, newCancelToken, occurrenceIdInPath.id, setIsLoading, setOccurrence, signedUser]);

  useEffect(() => {
    getOccurrence();
  }, [getOccurrence]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory
        title="Ocorrências"
        backgroundHeader="linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)"
        menuKebab={{ menuBackHome: true }}
      />
      {occurrence && (
        <DirectoryContainer footer={occurrence.pending && !occurrence.invalid ? true : false}>
          <CustomForm customInputColorText="#568CF7">
            {occurrence.creator && (
              <CardsRender margin="0" label="Ocorrência Criada Por:">
                {occurrence.creator && <UserCard user={occurrence.creator} canSelect={false} />}
              </CardsRender>
            )}

            {(!occurrence.pending || !!occurrence.invalid) && (
              <Fragment>
                <CardsRender margin="2rem 0" label={`${occurrence.invalid ? 'Ocorrência Invalidada Por:' : 'Ocorrência Solucionada Por:'}`}>
                  {occurrence?.responsible_closing && <UserCard user={occurrence.responsible_closing} canSelect={false} />}
                </CardsRender>

                <Input
                  name="date"
                  label={`${occurrence.invalid ? 'Data da Invalidação:' : 'Data da Solução:'}`}
                  defaultValue={getDay(occurrence.updated_at)}
                  disabled
                />

                <Input
                  name="time"
                  label={`${occurrence.invalid ? 'Horário da Invalidação:' : 'Horário da Solução:'}`}
                  end={<Icon name="clock" fill="#8F9BB3" />}
                  disabled
                  defaultValue={getTime(occurrence.updated_at)}
                />

                <Divider width="100%" />
              </Fragment>
            )}

            <Input label="Titulo da Ocorrência:" name="title" defaultValue={occurrence.title} disabled />

            <Input
              name="date"
              label="Data da Ocorrência:"
              defaultValue={getDay(occurrence.date)}
              end={<Icon name="calendar" fill="#8F9BB3" />}
              disabled
            />

            <Input
              name="time"
              label="Horário da Ocorrência:"
              end={<Icon name="clock" fill="#8F9BB3" />}
              disabled
              defaultValue={getTime(occurrence.date)}
            />

            <Textarea label="Descrição da Ocorrência:" name="description" value={occurrence?.description} rows={4} disabled />

            <CardsRender label="Locais Envolvidos:">{occurrence?.place && <PlaceCard place={occurrence.place} />}</CardsRender>

            <CardsRender label="Grupos Envolvidos:">{occurrence?.group && <GroupCard group={occurrence.group} canSelect />}</CardsRender>

            <CardsRender label="Turmas Envolvidas:">{occurrence?.class && <ClassCard classInfo={occurrence.class} />}</CardsRender>

            <CardsRender label="Aulas Envolvidas:">
              {occurrence?.daily_class && <TodayClassCard todayClass={occurrence.daily_class} canSelect={true} />}
            </CardsRender>

            <TagsRender label="Tags Relacionadas:" tags={occurrence?.tags}></TagsRender>

            <Textarea label="Comentários:" name="comment" value={occurrence?.comment} rows={4} disabled />

            <Divider />

            <H6 fontWeight={800} status="tertiary">
              PESSOAS ENVOLVIDAS
            </H6>

            <AccordionInfoItem
              status="tertiary"
              title={`Usuários envolvidos (${occurrence && occurrence.lms_users ? occurrence.lms_users?.length : 0})`}
            >
              <div className="cards-container">
                {occurrence?.lms_users?.map((lmsUser) => (
                  <UserCard key={lmsUser.id} canSelect={false} user={lmsUser} />
                ))}
              </div>
            </AccordionInfoItem>

            <AccordionInfoItem status="tertiary" title={`Alunos envolvidos (${occurrence && occurrence.students ? occurrence.students?.length : 0})`}>
              <div className="cards-container">
                {occurrence?.students?.map((student) => (
                  <StudentCard key={student.id} canSelect={false} student={student} />
                ))}
              </div>
            </AccordionInfoItem>
          </CustomForm>

          {occurrence.pending && !occurrence.invalid && (
            <PageFooter>
              <Button
                style={{ background: 'linear-gradient(359.73deg, #F9514C 0.24%, #F42929 99.76%)', border: 'none', whiteSpace: 'break-spaces' }}
                transform="none"
                shaded
                onClick={() => setShowInvalidateModal(true)}
              >
                Invalidar Ocorrência
              </Button>
              <Button
                style={{ background: '#568CF7', border: 'none', whiteSpace: 'break-spaces' }}
                transform="none"
                shaded
                onClick={() => setShowSolveModal(true)}
              >
                Solucionar Ocorrência
              </Button>
            </PageFooter>
          )}
        </DirectoryContainer>
      )}

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showInvalidateModal}
        closeCallback={() => setShowInvalidateModal(!showInvalidateModal)}
        subTitle="Tem certeza mesmo ? 😅"
        title="Deseja invalidar a Ocorrencia?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleInvalidateOccurrence}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showSolveModal}
        closeCallback={() => setShowSolveModal(!showSolveModal)}
        title="Deseja Solucionar a Ocorrencia?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleSolveOccurrence}
      />
    </Fragment>
  );
};

export default OccurenceInfo;
