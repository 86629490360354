import * as Yup from 'yup';
import { EDUCATION_LEVEL_CONSTRAINTS } from '@shared/utils/validData/validEducationLevel';
import { GENDERS_VALUES } from '@shared/utils/validData/validGender';

const EDUCATION_LEVEL = EDUCATION_LEVEL_CONSTRAINTS.map((el) => el.value);

export const lmsUserSchema = Yup.object().shape({
  name: Yup.string().max(100).required({ name: 'name', message: 'O campo é obrigatório' }),

  email: Yup.string().email({ name: 'email', message: 'O campo deve ser no formato email' }).nullable(),

  phone: Yup.string().max(20).required({ name: 'phone', message: 'O campo é obrigatório' }),

  address_data: Yup.object({
    address: Yup.string().required({ name: 'address_data.address', message: 'O campo é obrigatório' }),
    city: Yup.string().required({ name: 'address_data.city', message: 'O campo é obrigatório' }),
    uf: Yup.string()
      .max(2, { name: 'address_data.uf', message: 'O campo é deve ter 2 caracteres' })
      .required({ name: 'address_data.uf', message: 'O campo é obrigatório' }),
  }).required({ name: 'address_data', message: 'O campo é obrigatório' }),

  activation_date: Yup.string().required({ name: 'activation_date', message: 'O campo é obrigatório' }),

  birthdate: Yup.string().required({ name: 'birthdate', message: 'O campo é obrigatório' }),

  gender: Yup.string()
    .oneOf(GENDERS_VALUES, {
      name: 'gender',
      message: 'O campo é inválido',
    })
    .required({ name: 'gender', message: 'O campo é obrigatório' }),

  education_level: Yup.string()
    .oneOf(EDUCATION_LEVEL, {
      name: 'education_level',
      message: 'O campo é obrigatório',
    })
    .required({ name: 'education_level', message: 'O campo é obrigatório' }),

  university_type: Yup.string()
    .oneOf(['private', 'public'], {
      name: 'university_type',
      message: 'O campo é obrigatório',
    })
    .required({ name: 'university_type', message: 'O campo é obrigatório' }),

  academic_info: Yup.object({
    name: Yup.string().required({ name: 'academic_info.name', message: 'O campo é obrigatório' }),
    course_name: Yup.string().required({ name: 'academic_info.course_name', message: 'O campo é obrigatório' }),
    end_date: Yup.number()
      .typeError({ name: 'academic_info.end_date', message: 'Deve ser um numero' })
      .required({ name: 'academic_info.end_date', message: 'O campo é obrigatório' }),
  }).required({ name: 'academic_info', message: 'O campo é obrigatório' }),
});
