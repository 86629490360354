import React from 'react';
import { Spinner, Grid, Button } from 'react-alicerce-components';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import IProject from '@modules/projects/interfaces/IProject';
import ProjectList from '@modules/projects/components/ProjectList';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';

import { useNavigate } from 'react-router-dom';

interface ProjectDirectoryProps {
  title: string;
  btnText: string;
  pathToReturn?: string;
  pathRedirect?: string;
  canSelectPlace?: 'one' | 'many';
  selectedItens?: IProject[];
  listOptions?: any;
  isAdvancedFilterDisabled?: boolean;
  handleOnClick?: (placeCurrent: any) => void;
  setSelectedItens?: (placeCurrent: IProject[]) => void;
}

const ProjectDirectory: React.FC<ProjectDirectoryProps> = ({
  pathToReturn = '/',
  btnText = 'Criar Projeto',
  title = 'Diretório de Projetos'
}) => {
  const { isLoading } = useGlobal();
  const navigate = useNavigate();
  const handleClickBtn = () => navigate('/diretorio/projetos/criar')

  return (
    <ProjectDefaultStylesContainer>
      <HeaderDirectory
        title={title}
        pathToReturn={pathToReturn}
        menuKebab={{ tooltip: { view: 'ProjectDirectory' }, menuBackHome: true }}
      />
      {isLoading && <Spinner fixed />}
      <DirectoryContainer footer={true}>
        <Grid container>
          <ProjectList
            pathRedirect='informacoes'
          />
          <PageFooter>
            <Button
              shaded
              transform="none"
              style={{ margin: '0 auto' }}
              onClick={handleClickBtn}
            >
              {btnText}
            </Button>
          </PageFooter>
        </Grid>
      </DirectoryContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectDirectory;
