import { IActivityLibraryFilter, IActivityFilterFormatted } from '../context/ActivityContext';

export function formatActivityLibraryFilter(activityLibraryFilter: IActivityLibraryFilter): IActivityFilterFormatted {
  return {
    duration: activityLibraryFilter.duration ? activityLibraryFilter.duration : undefined,
    created_by: activityLibraryFilter.created_by ? activityLibraryFilter.created_by : undefined,
    age_groups: activityLibraryFilter.age_groups ? activityLibraryFilter.age_groups : undefined,
    student_amount: activityLibraryFilter.student_amount ? activityLibraryFilter.student_amount : undefined,
    modality: activityLibraryFilter.modality ? activityLibraryFilter.modality : undefined,
    cognitive_tasks: activityLibraryFilter.cognitive_tasks ? activityLibraryFilter.cognitive_tasks : undefined,
    type_tag_id: activityLibraryFilter.type ? Number(activityLibraryFilter.type.id) : undefined,
    theme_tag_id: activityLibraryFilter.theme ? Number(activityLibraryFilter.theme.id) : undefined,
    subtheme_tag_id: activityLibraryFilter.subtheme ? Number(activityLibraryFilter.subtheme.id) : undefined,
    thematic_tag_id: activityLibraryFilter.thematic ? Number(activityLibraryFilter.thematic.id) : undefined,
    skill_ids: activityLibraryFilter.skills ? activityLibraryFilter.skills.map((s) => Number(s.id)).filter(Boolean) : undefined,
  };
}
