import React, { useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import PageFooter from '@shared/components/PageFooter';
import ButtonGradientOutline from '@shared/components/ButtonGradientOutline';
import ITag from '@modules/tags/interfaces/ITag';
import CreateTagService from '@modules/tags/services/CreateTagService';
import TagForm from '@modules/tags/components/TagForm';

import { Container } from './styles';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const TagCreate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();

  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();

  const handleCreateTag = useCallback(
    async (tagValidated: ITag) => {
      if (!signedUser) return;

      setIsLoading(true);

      const res = await new CreateTagService(signedUser.token).execute(tagValidated);

      if (!res.success) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Tag',
          description: `Error: ${res.errMessage}`,
        });
        return;
      }

      addToast({
        status: 'success',
        title: 'Tag',
        description: 'Tag criada com sucesso.',
      });

      navigate('/diretorio/tags');
    },
    [navigate, signedUser, addToast, setIsLoading]
  );

  return (
    <Container>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory
        title="Diretório de Tags"
        pathToReturn="/diretorio/tags"
        status="secondary"
        statusNumber={800}
        backgroundHeader="linear-gradient(45deg, #282674 0%, #145496 100%)"
      />

      <TagForm formRef={formRef} handleOnFinish={handleCreateTag} pathParentTag="/diretorio/tags/criar/selecionar" typeService="Criar" />

      <PageFooter>
        <ButtonGradientOutline onClick={() => navigate('/diretorio/tags')} status="secondary">
          Cancelar
        </ButtonGradientOutline>
        <Button onClick={() => formRef?.current?.submitForm()} transform="none" status="secondary" statusNumber={800} shaded>
          Salvar
        </Button>
      </PageFooter>
    </Container>
  );
};

export default TagCreate;
