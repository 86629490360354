import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { P, useToast } from 'react-alicerce-components';

import Divider from '@shared/components/Divider';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import DonutChart from '@shared/components/CustomRecharts/charts/DonutChart';
import VerticalBarChart from '@shared/components/CustomRecharts/charts/VerticalBarChart';
import AreaChart from '@shared/components/CustomRecharts/charts/AreaChart';
import SimpleBarChart from '@shared/components/CustomRecharts/charts/SimpleBarChart';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';

import { IndicatorsLayout as Layout } from '@modules/dashboards/components/IndicatorsLayout';
import { GetIndicatorsService, IIndicatorResponse } from '@modules/dashboards/services/GetIndicatorsService';
import { formatResource } from '@modules/dashboards/utils/formatResource';
import { translateTitles } from '@modules/dashboards/utils/translateTitles';
import SprintSelect from '@modules/dashboards/components/SprintSelect';

import { Container } from './styles';

const AderenceIndicator: React.FC = () => {
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { resource, resourceId } = useParams();
  const { addToast } = useToast();
  const [dashData, setDashData] = useState<IIndicatorResponse>();

  const [barsChartConfig] = useState([
    { dataKey: 'week', color: '#C7E2FF' },
    { dataKey: 'sprint', color: '#42AAFF' },
  ]);

  const getIndicators = useCallback(
    async (sprintId?: number) => {
      if (!resourceId || !Number(resourceId) || !resource) return;
      const formattedResource = formatResource(resource);
      if (!formattedResource) return console.log(`invalid resource`);

      let options = {};
      if (sprintId) options['sprint_id'] = sprintId;

      setIsLoading(true);
      const res = await new GetIndicatorsService(signedUser?.token).execute(formattedResource, Number(resourceId), options);
      setIsLoading(false);
      if (!res) {
        addToast({
          status: 'danger',
          title: 'Indicadores',
          description: 'Erro recuperar indicatores.',
        });
        return;
      }
      setDashData(res);
    },
    [addToast, resource, resourceId, setIsLoading, signedUser]
  );

  useEffect(() => {
    getIndicators();
  }, [getIndicators]);

  return (
    <Container>
      <Layout openTab={0}>
        {!dashData ? (
          <span>Carregando ... </span>
        ) : (
          <Fragment>
            {dashData?.sprint && (
              <SprintSelect
                sprintData={{ ...dashData?.sprint, nextSprintId: dashData?.nextSprintId, previousSprintId: dashData?.previousSprintId }}
                handleOnChangeSprint={getIndicators}
              />
            )}

            <Divider className="divider" width="100%" />

            <P fontWeight={800} color="#8F9BB3">
              INDICADOR GERAL DA CATEGORIA:
            </P>

            {dashData?.donut_info && (
              <DonutChart
                data={dashData.donut_info}
                contentCenter={`${dashData.donut_info.reduce((prev, curr) => prev + Number(curr.percent), 0)}%`}
                legendType="dot"
              />
            )}

            {dashData?.general_info.map((genInfo) => (
              <AccordionInfoItem title={translateTitles(genInfo.title)} key={genInfo.title}>
                <VerticalBarChart data={[{ week: genInfo.week, sprint: genInfo.sprint }]} barsConfig={barsChartConfig} />
              </AccordionInfoItem>
            ))}

            <Divider className="divider" width="100%" />

            <P fontWeight={800} color="#8F9BB3">
              INFORMAÇÕES DETALHADAS:
            </P>

            {dashData?.detailed_info.map((detailedInfo) => {
              if (detailedInfo.title === 'pdv' || detailedInfo.title === 'sprint_planning') {
                return (
                  <AccordionInfoItem title={translateTitles(detailedInfo.title)} key={detailedInfo.title}>
                    <SimpleBarChart data={detailedInfo.data} />
                  </AccordionInfoItem>
                );
              } else {
                return (
                  <AccordionInfoItem title={translateTitles(detailedInfo.title)} key={detailedInfo.title}>
                    <AreaChart data={detailedInfo.data} />
                  </AccordionInfoItem>
                );
              }
            })}

            {/* <DonutChart
              data={[
                  { name: '1', percent: 43.6, color: '#00D68F' },
                  { name: '2', percent: 31.7, color: '#FFAA00' },
                  { name: '3', percent: 13.3, color: '#0095FF' },
                  { name: '4', percent: 11.4, color: '#FF3D71' },
              ]}

              showStrokedPercent
              contentCenter='1990'
        /> */}
          </Fragment>
        )}
      </Layout>
    </Container>
  );
};
export default AderenceIndicator;
