import IRoute from '@shared/interfaces/IRoute';
import { TabsProvider } from 'react-alicerce-components';

import { ClassPlannerActivityCreate } from '@modules/class-planner/views/ClassPlannerActivityCreate';
import { ActivityLibraryThemeRelationCreate } from '@modules/activities-library/views/ActivityLibraryThemeRelationCreate';
import { ActivityLibraryTypeRelationCreate } from '@modules/activities-library/views/ActivityLibraryTypeRelationCreate';
import { ProgramTagRelation } from '@modules/activities-library/views/ProgramTagRelation';
import { ProgramActivityRelationCreate } from '@modules/activities-library/views/ProgramActivityRelationCreate';

import ClassPlannerClassesSelection from '@modules/class-planner/views/ClassPlannerClassesSelection';
import ClassPlanner from '@modules/class-planner/views/ClassPlanner';
import ClassPlannerList from '@modules/class-planner/views/ClassPlannerList';
import ClassPlannerCreate from '@modules/class-planner/views/ClassPlannerCreate';
import ClassPlannerEdit from '@modules/class-planner/views/ClassPlannerEdit';
import ClassPlannerPlaceRelationCreate from '@modules/class-planner/views/ClassPlannerPlaceRelationCreate';

import { ClassPlannerActivityLibraryContextSelection } from '@modules/class-planner/views/ClassPlannerActivityLibraryContextSelection';
import { ClassPlannerActivityLibraryInfo } from '@modules/class-planner/views/ClassPlannerActivityLibraryInfo';
import { ClassPlannerProgramInfo } from '@modules/class-planner/views/ClassPlannerProgramInfo';
import { ClassPlannerProgramCreate } from '@modules/class-planner/views/ClassPlannerProgramCreate';
import { ClassPlannerActivityEdit } from '@modules/class-planner/views/ClassPlannerActivityEdit';
import { ClassPlannerProgramEdit } from '@modules/class-planner/views/ClassPlannerProgramEdit';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { dailyClassKeys } from '@modules/class-planner/keys';
import { placesKeys } from '@modules/places/keys';
import { classesKeys } from '@modules/classes/keys';
import { activityLibraryKeys } from '@modules/activities-library/keys';
import { tagsKeys } from '@modules/tags/keys';
import { ClassPlannerPlannedActivityInfo } from '../views/ClassPlannerPlannedActivityInfo';
import { ClassPlannerPlannedActivityEdit } from '../views/ClassPlannerPlannedActivityEdit';
import ClassPlannerUsersRelationCreate from '../views/ClassPlannerUsersRelationCreate';
import { ActivityLibrarySkillsRelation } from '../../activities-library/views/ActivityLibrarySkillsRelation';
import { ActivityLibraryTagRelation } from '../../activities-library/views/ActivityLibraryTagRelation';

// https://lms.alicerceedu.com.br/planejamento-aula/turma/1/aula/20116
const routes: IRoute[] = [
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId',
    key: 'CLASS_PLANNER_PATH',
    exact: true,
    component: ClassPlanner,
    permissions: [dailyClassKeys.DAILY_CLASS_READ, dailyClassKeys.PLANNED_ACTIVITY_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/criar/aula',
    key: 'CLASS_PLANNER_CREATE_DAIL_CLASS',
    exact: true,
    component: ClassPlannerCreate,
    permissions: [dailyClassKeys.DAILY_CLASS_CREATE, dailyClassKeys.PLANNED_ACTIVITY_CREATE],
  },
  {
    path: '/planejamento-aula/turma/:classId/selecionar/lugar',
    key: 'CLASS_PLANNER_CLASS_SELECT_PLACE',
    exact: true,
    component: ClassPlannerPlaceRelationCreate,
    permissions: [placesKeys.PLACE_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/editar/aula/:dailyClassId',
    key: 'CLASS_PLANNER_CREATE_DAILY_CLASS',
    exact: true,
    component: ClassPlannerEdit,
    permissions: [dailyClassKeys.DAILY_CLASS_UPDATE, dailyClassKeys.PLANNED_ACTIVITY_UPDATE],
  },
  {
    path: '/planejamento-aula/contexto/turmas',
    key: 'CLASS_PLANNER_CONTEXT_SELECTION_CLASSES_PATH',
    exact: true,
    component: ClassPlannerClassesSelection,
    permissions: [classesKeys.CLASSES_READ],
  },
  {
    path: '/planejamento-aula/aulas/turma/:classId',
    key: 'CLASS_PLANNER_CONTEXT_SELECTION_CLASSES_LIST',
    exact: true,
    component: ClassPlannerList,
    permissions: [dailyClassKeys.PLANNED_ACTIVITY_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_ADD_PATH',
    exact: true,
    component: ClassPlannerActivityLibraryContextSelection,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ, activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/atividade',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_CREATE_ACTIVITY_PATH',
    exact: true,
    component: ClassPlannerActivityCreate,
    permissions: [dailyClassKeys.PLANNED_ACTIVITY_CREATE],
  },

  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/atividade/:activityId/informacoes/atividade-planejada/:plannedActivityId',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_INFO_PATH',
    exact: true,
    component: ClassPlannerPlannedActivityInfo,
    permissions: [dailyClassKeys.DAILY_CLASS_READ, dailyClassKeys.PLANNED_ACTIVITY_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/atividade/:activityId/editar/atividade-planejada/:plannedActivityId',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_EDIT_PATH',
    exact: true,
    component: ClassPlannerPlannedActivityEdit,
    permissions: [
      dailyClassKeys.DAILY_CLASS_READ,
      dailyClassKeys.PLANNED_ACTIVITY_READ,
      dailyClassKeys.PLANNED_ACTIVITY_UPDATE,
      dailyClassKeys.PLANNED_ACTIVITY_READ,
    ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/atividade/:activityId/informacoes',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_INFO_PATH',
    exact: true,
    component: ClassPlannerActivityLibraryInfo,
    permissions: [dailyClassKeys.DAILY_CLASS_READ, dailyClassKeys.PLANNED_ACTIVITY_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/atividade/:activityId/editar/atividade-planejada/:plannedActivityId/skills',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_SKILL_RELATION',
    exact: true,
    component: ActivityLibrarySkillsRelation,
    permissions: 'public',
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/atividade/selecionar/tags',
    key: 'CLASS_PLANNER_LIBRARY_ACTIVITY_TAGS_RELATION',
    exact: true,
    component: ActivityLibraryTagRelation,
    permissions: 'public',
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/competencias',
    key: 'ACTIVITIES_LIBRARY_SKILL_RELATION_PATH',
    exact: true,
    component: ActivityLibrarySkillsRelation,
    permissions: 'public',
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/categoria/tipo',
    key: 'ACTIVITIES_LIBRARY_CREATE_TYPE_PATH',
    exact: true,
    component: ActivityLibraryTypeRelationCreate,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/categoria/tema',
    key: 'ACTIVITIES_LIBRARY_CREATE_THEME_PATH',
    exact: true,
    component: ActivityLibraryThemeRelationCreate,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/programa',
    key: 'CLASS_PLANNER_PROGRAM_CREATE_PATH',
    exact: true,
    component: ClassPlannerProgramCreate,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_CREATE],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/programa/:programId/informacoes',
    key: 'CLASS_PLANNER_PROGRAM_INFO_PATH',
    exact: true,
    component: ClassPlannerProgramInfo,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/programa/selecionar/tags',
    key: 'CLASS_PLANNER_PROGRAM_CREATE_TAGS_RELATION',
    exact: true,
    component: ProgramTagRelation,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/criar/programa/selecionar/atividade',
    key: 'CLASS_PLANNER_PROGRAM_CREATE_ACTIVITIES_RELATION',
    exact: true,
    component: ProgramActivityRelationCreate,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/atividade/:activityId/clonar',
    key: 'CLASS_PLANNER_EDIT_ACTIVITY',
    exact: true,
    component: ClassPlannerActivityEdit,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_UPDATE],
  },
  {
    path: '/planejamento-aula/turma/:classId/aula/:dailyClassId/banco-atividades/programa/:programId/clonar',
    key: 'CLASS_PLANNER_PROGRAM_INFO_PATH',
    exact: true,
    component: ClassPlannerProgramEdit,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_UPDATE],
  },
  {
    path: 'planejamento-aula/criar/selecionar/usuarios',
    key: 'ADD_OCCURRENCE_USER_PATH',
    exact: true,
    component: ClassPlannerUsersRelationCreate,
    permissions: [placesKeys.PLACE_READ],
  },
];

const providers = [TabsProvider];

export default {
  key: 'CLASS_PLANNER_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
