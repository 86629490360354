import React from 'react';

import { Grid, H6, Textarea } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { useSteeringForm } from '../useSteeringForm';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

interface SteeringAtaFragmentProps {
  disabled?: boolean;
  editing?: boolean;
  errors?: IInputErrorsFormat[];
}

export const SteeringAtaFragment: React.FC<SteeringAtaFragmentProps> = ({ disabled, editing, errors }) => {
  const { steeringCurrent } = useProjects();
  const { handleChangeInput } = useSteeringForm();
  const statusIsNotFinished = steeringCurrent?.status === 'not_finished'

  return (
    <Grid row mt4>
      <H6>ATA DA STEERING:</H6>
        <Textarea
          disabled={disabled}
          required={editing && !statusIsNotFinished}
          name="points_of_meeting"
          label="Ata:"
          placeholder="Adicione a ata da Steering"
          onChange={handleChangeInput}
          value={steeringCurrent?.points_of_meeting}
          rows={25}
          error={errorMessageBuilder('points_of_meeting', errors)}
        />
    </Grid>
  );
};
