import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  overflow-x: hidden;

  .main-navbar {
    height: 130px;
    align-items: center;
  }

  [role='tabpanel'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 !important;
    height: fit-content;
    overflow: hidden;
  }

  .dynamic-table-container table tbody tr td span {
    flex-wrap: wrap;
    white-space: break-spaces;
    text-align: center;
    padding: 1rem;
  }
`;
