import React, { useState } from 'react';

import {
  AplicationInfoContainer,
  ApplicationTypeContainer,
  ApplicationStatusContainer,
  LearningTargetsStudentCardBorderContainer,
  ValidatedDays,
  ValidationTypeStatusContainer,
} from './styles';
import { Button, Pill } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import Modalv1 from '../../../../../shared/components/Modalv1';
import { LearningTagertForm } from '../../../forms/LearningTagertForm';
import { IStudentWithGrades } from '../../../../launchers/interfaces/IStudentsWithGrades';
import { formateDateDifferenceDay } from '../../../../../shared/utils/formateDateDifferenceDay';
import ConfirmModal from '../../../../../shared/components/ConfirmModal';

export interface LearningTargetsStudentCardStatus {
  data?: string[];
  studentData: IStudentWithGrades;
  listStudentFromClass: () => Promise<void>;
}

const LearningTargetsStudentCardStatus: React.FC<LearningTargetsStudentCardStatus> = (props) => {
  const { studentData, listStudentFromClass } = props;
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const applicationCreatedAt = studentData.gradeInfo?.current_application?.created_at;
  const applicationType = studentData.gradeInfo?.current_application?.state?.type;
  const applicationStatus = studentData.gradeInfo?.current_application?.status;
  const validationType = studentData.gradeInfo?.validation_type;

  const getStatus = (status): { status: string; bgColor: string; border: string; color: string } =>
    ({
      FINALIZED: { status: 'Finalizado', bgColor: 'none', border: 'rgba(34, 43, 69, 1)', color: 'rgba(34, 43, 69, 1)' },
      ONGOING: { status: 'Em Progresso', bgColor: 'linear-gradient(180deg, #FAC50D 23.44%, #FFD74E 100%)', border: 'none', color: 'white' },
      REVISION: { status: 'Em Progresso', bgColor: 'FFD74E', border: 'none', color: 'white' },
      PENDING: { status: 'Pendente', bgColor: 'linear-gradient(180deg, #F63E3C 23.44%, #FF7773 100%)', border: 'none', color: 'white' },
      undefined: { status: 'Sem Aplicação', bgColor: '#8F9BB3', color: 'white' },
    }[status]);

  const getStatusValidation = (status): { status: string } =>
    ({
      printed_minimapa: { status: 'Mini M.A.P.A Impresso' },
      learning_cards: { status: 'Ficha Aprendizagem' },
      teacher_observation: { status: 'Obs. do Instrutor' },
      completed_mapa: { status: 'Aluno Fez o Mapa' },
    }[status]);

  const ApplicationData = () => {
    if (!applicationCreatedAt)
      return (
        <Pill color="rgba(34, 43, 69, 1)" backgroundColor="none" size={'small'} marginLeft={'10px'} borderColor="rgba(34, 43, 69, 1)">
          Sem Aplicação
        </Pill>
      );

    return (
      <>
        <ValidatedDays>
          {/* DADOS DO GET */}
          <p>Última Aplicação</p>

          <b>{applicationCreatedAt && formateDateDifferenceDay(`${applicationCreatedAt}`)}</b>
        </ValidatedDays>

        <ApplicationTypeContainer>
          <Pill color="rgba(34, 43, 69, 1)" backgroundColor="none" size={'small'} marginLeft={'10px'} borderColor="rgba(34, 43, 69, 1)">
            {applicationType}
          </Pill>
        </ApplicationTypeContainer>

        <ApplicationStatusContainer>
          <Pill
            style={{ background: getStatus(applicationStatus).bgColor }}
            color={getStatus(applicationStatus).color}
            borderColor={getStatus(applicationStatus).border}
            size={'small'}
            marginLeft={'10px'}
          >
            {getStatus(applicationStatus).status}
          </Pill>
        </ApplicationStatusContainer>
      </>
    );
  };

  const DisplayApplicationNotFinalized = () => {
    if (applicationStatus === 'FINALIZED' || !applicationCreatedAt || applicationType === 'MAPA') return null;
    return (
      <div className="button-application-pending">
        <Modalv1>
          <Modalv1.Open opens="MODAL">
            <Button
              size="small"
              transform="none"
              rounded
              noBorder
              fill="linear-gradient(180deg, #F68A3C 23.44%, #FFAB2D 100%)"
              start={<Icon name="alert-circle-outline" />}
            >
              Clique para Definir Meta sem Finalizar Aplicação
            </Button>
          </Modalv1.Open>
          <Modalv1.Window name="MODAL">
            <LearningTagertForm
              studentData={studentData}
              listStudentFromClass={listStudentFromClass}
              openConfirmModal={(open) => setOpenConfirmModal(open)}
            />
          </Modalv1.Window>
        </Modalv1>
      </div>
    );
  };

  const ShowValidationType = () => {
    if (!validationType) return null;
    return (
      <ValidationTypeStatusContainer>
        <b>Recurso Utilizado:</b>
        <Pill color="rgba(34, 43, 69, 1)" backgroundColor="none" size={'small'} marginLeft={'10px'} borderColor="#F7652B">
          {getStatusValidation(validationType).status}
        </Pill>
      </ValidationTypeStatusContainer>
    );
  };

  return (
    <LearningTargetsStudentCardBorderContainer>
      <AplicationInfoContainer>
        <ApplicationData />
      </AplicationInfoContainer>
      <ShowValidationType />
      <DisplayApplicationNotFinalized />
      <ConfirmModal
        start={<Icon name="checkmark-circle-2-outline" fill="#00D68F" />}
        showModal={openConfirmModal}
        closeCallback={() => setOpenConfirmModal(false)}
        title="Sucesso!"
        subTitle="Você selecionou com sucesso as competências já dominadas. Este bloco foi definido como a meta do aluno para a próxima quinzena. Se necessário, você pode editar a meta e selecionar outro bloco para o aluno."
        textConfirmButton="Confirmar"
        showButtonReturn={false}
        confirmCallBack={() => setOpenConfirmModal(false)}
      />
    </LearningTargetsStudentCardBorderContainer>
  );
};

export default LearningTargetsStudentCardStatus;
