import styled, { css } from 'styled-components';

export type TagItemProps = {
  selected?: boolean;
  label: string;
  value: string;
  disabled?: boolean;
  statusColor?: {
    backgroundColor?: string;
    textColor?: string;
  }
}

export const TagMessageError = styled.label`
  color: red !important;
`;

export const TagContainer = styled.div`
  display: flex;
  border-radius: 5rem;
  border: 2px solid #c5cee0;
  width: fit-content;
  height: 3.5rem;
  margin: 1rem 0;
  overflow: hidden;
`;

export const TagItem = styled.div<TagItemProps>`
  font-size: 1rem !important;
  font-weight: 800;
  font-family: var(--primary-font);
  padding: 1rem;
  border: 1px solid #c5cee0;
  color: #ffffff;
  cursor: pointer;

  ${({ selected, statusColor, disabled }) => css`
    background-color: ${selected ? statusColor?.backgroundColor || '#00d68f' : '#edf1f7'};
    color: ${selected ? statusColor?.textColor : '#c5cee0'};
    cursor: ${disabled ? 'not-allowed' : 'pointer' }
  `}
`;

