import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';



interface FileDataPost {
  path: string;
  attachments: { name: string; size: number; type: string }[];
}

export type FileToUpload = {
  name: string;
  size: number;
  type: string;
};

export type urlS3 = {
  url: string;
};

export class UploadService {
  static formatedFileUpload(filesToSave: any) {
    throw new Error('Method not implemented.');
  }
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async getUploadFileUrl(fileData: FileDataPost): Promise<any> {
    try {
      const response = await this.api.post(`/services/s3upload`, { ...fileData });
      return response;
    } catch (err) {
      return {
        uploadURL: '',
        dbURL: '',
        error: true,
      };
    }
  }

  async uploadToS3(file: File, strUrl: string) {
    try {
      await axios.put(strUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  public async uploadFile(file: File[], uploadPath: string): Promise<any | false> {
    const formatedFiles = this.formatedFileUpload(file);
    if (!formatedFiles) return;
    try {
      let fileData = {
        path: uploadPath,
        attachments: formatedFiles,
      };
      if (!fileData) return;
      const strUrl = await this.getUploadFileUrl(fileData);
      if (strUrl.error) throw new Error('Erro ao gerar a url de upload.');

      for (let i = 0; i < file.length; i++) {
        const uploadedFile = await this.uploadToS3(file[i], `${strUrl.data[i].uploadURL}`);
        if (!uploadedFile) {
          throw new Error(`Error uploading file ${file[i].name}`);
        }
      }
      return strUrl.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public formatedFileUpload = (item: File[]) => {
    return item.map((attr) => {
      return {
        name: attr.name,
        size: attr.size,
        type: attr.type,
      };
    });
  };
}

export default UploadService;
