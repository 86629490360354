import React, { useCallback, useRef, useState } from 'react';

import { CardsContainer, ProgramFormContainer } from './styles';
import { H6, Input } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';

// import { calculateDragAndDropFinalndex } from '@modules/activities-library/utils/calculateDragAndDropFinalndex';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { ActivityLibraryCard } from '@modules/activities-library/components/ActivityLibraryCard';
import { IActivityLibrary } from '@modules/activities-library/interfaces/IActivityLibrary';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';
import TagsRender from '@modules/tags/components/TagsRender';

import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import CustomForm from '@shared/components/CustomForm';
import { FiMenu, FiTriangle } from 'react-icons/fi';

interface IProgramForm {
  title: string;
  pathEnvolvedTags: string;
  pathEnvolvedActivities: string;
  errors: IInputErrorsFormat[];
}

const ProgramForm: React.FC<IProgramForm> = (props) => {
  const navigate = useNavigate();
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const directoryContainerRef = useRef<HTMLDivElement>(null);
  const { programCurrent, setProgramCurrent } = useProgram();
  const [draggedItem, setDraggedItem] = useState<IActivityLibrary>();
  const [overedIndex, setOveredIndex] = useState<number>();

  /**
    const [lockScroll, setLockScroll] = useState<boolean>(false);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const [isUserScrolling, setIsUserScrolling] = useState<boolean>(true);
  */

  const removeSelectedTag = useCallback(
    (indexToRemove: number) => {
      const newTags = removeItemFromArrayByIndex(indexToRemove, programCurrent.tags);
      setProgramCurrent((oldState: IProgram) => {
        return { ...oldState, tags: newTags };
      });
    },
    [programCurrent.tags, setProgramCurrent]
  );

  const removeCardFromProgram = useCallback(
    (arrayId: number) => {
      const activities = removeItemFromArrayByIndex(arrayId, programCurrent.activities);
      setProgramCurrent((oldState) => {
        return { ...oldState, activities };
      });
    },
    [programCurrent.activities, setProgramCurrent]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      setProgramCurrent((oldState: IProgram) => {
        return { ...oldState, title: inputValue };
      });
    },
    [setProgramCurrent]
  );

  const onDrag = useCallback((event) => {
    // if (event.clientY > window.innerHeight - 90) directoryContainerRef.current?.scrollBy(0, 10);
    // if (event.clientY < 90) directoryContainerRef.current?.scrollBy(0, -10);
  }, []);

  const onDragStart = useCallback(
    (event, index) => {
      if (!programCurrent.activities) return;
      setDraggedItem(programCurrent.activities[index]);
      // const parentNode = event.target.parentNode
      // event.dataTransfer.effectAllowed = 'move';
      // event.dataTransfer.setData('text/html', parentNode);
      // event.dataTransfer.setDragImage(parentNode, 20, 20);
    },
    [programCurrent.activities]
  );

  const onDragOver = useCallback(
    (arrayId) => {
      if (!draggedItem) return;
      for (let item of document.getElementsByClassName('over')) item.classList.remove('over');
      document.getElementById(`activity-${arrayId}`)?.classList?.add('over');
      setOveredIndex(arrayId);
    },
    [draggedItem]
  );

  const onDragEnd = useCallback(
    (arrayId) => {
      if (!draggedItem) return;
      for (let item of document.getElementsByClassName('over')) item.classList.remove('over');
      setProgramCurrent((oldState: IProgram) => {
        if (!oldState.activities) return { ...oldState };
        let auxActitivies = oldState.activities;
        auxActitivies.splice(arrayId, 1);
        auxActitivies.splice(overedIndex || 0, 0, draggedItem);

        return { ...oldState, activities: auxActitivies };
      });
    },
    [draggedItem, overedIndex, setProgramCurrent]
  );

  const moveActivityCardOrderUp = useCallback(
    (arrayId: number, indexToGo: number) => {
      if (indexToGo < 0) return;

      setProgramCurrent((oldState: IProgram) => {
        if (!oldState.activities) return { ...oldState };
        const auxActitivies = [...oldState.activities];
        const cardToMove = auxActitivies.splice(arrayId, 1);
        auxActitivies.splice(indexToGo, 0, ...cardToMove);
        return { ...oldState, activities: auxActitivies };
      });
    },
    [setProgramCurrent]
  );

  const moveActivityCardOrderDown = useCallback(
    (arrayId: number, indexToGo: number) => {
      if (programCurrent.activities && indexToGo > programCurrent.activities.length) return;

      setProgramCurrent((oldState: IProgram) => {
        if (!oldState.activities) return { ...oldState };
        const auxActitivies = [...oldState.activities];
        const cardToMove = auxActitivies.splice(arrayId, 1);
        auxActitivies.splice(indexToGo, 0, ...cardToMove);
        return { ...oldState, activities: auxActitivies };
      });
    },
    [programCurrent.activities, setProgramCurrent]
  );

  /**
   * < Logica do touch mobile, mantenha ela fechada por enquanto. 
   * 
   const onTouchStart = useCallback(
     (event, index) => {
      if (!programCurrent.activities) return;
      setDraggedItem(programCurrent.activities[index]);
      // const finalIndex = calculateDragAndDropFinalndex(cardsContainerRef, directoryContainerRef, event, programCurrent.activities.length);
      // document.getElementById(`activity-${finalIndex}`)?.classList?.add('over');
      
      var intervalID = setInterval(() => {
        setIsUserScrolling(false);
        setLockScroll(true);
      }, 650);
      
      setIntervalId(intervalID);
    },
    [programCurrent.activities]
    );

    const onTouchMove = useCallback(
      (event) => {
      if (!programCurrent.activities) return;
      if (isUserScrolling) return;

      for (let item of document.getElementsByClassName('over')) item.classList.remove('over');
      const finalIndex = calculateDragAndDropFinalndex(cardsContainerRef, directoryContainerRef, event, programCurrent.activities.length);
      document.getElementById(`activity-${finalIndex}`)?.classList?.add('over');
    },
    [isUserScrolling, programCurrent.activities]
    );
    
    const onTouchEnd = useCallback(
      (event, arrayId) => {
        if (!draggedItem) return;
      intervalId && clearInterval(intervalId);
      setIsUserScrolling(true);

      setProgramCurrent((oldState: IProgram) => {
        if (!oldState.activities) return { ...oldState };
        const finalIndex = calculateDragAndDropFinalndex(cardsContainerRef, directoryContainerRef, event, oldState.activities.length);
        const auxActitivies = oldState.activities;
        auxActitivies.splice(arrayId, 1);
        auxActitivies.splice(finalIndex || 0, 0, draggedItem);
        return { ...oldState, activities: auxActitivies };
      });
      
      for (let item of document.getElementsByClassName('over')) item.classList.remove('over');
      setLockScroll(false);
    },
    [draggedItem, intervalId, setProgramCurrent]
    );
  */
  console.log('🚀 ~ programCurrent', programCurrent);
  return (
    <DirectoryContainer dirRef={directoryContainerRef} footer={true} isScrollBlocked={false}>
      <ProgramFormContainer>
        <CustomForm customInputColorText="#2E3A59">
          <H6 color="#B85EC4" fontWeight={800}>
            {props.title}
          </H6>
          <Input
            onChange={handleInputChange}
            defaultValue={programCurrent.title}
            label="Nome do Programa:"
            name="title"
            required
            placeholder="Adcione Nome do Programa"
            useTooltipError={false}
            error={errorMessageBuilder('title', props.errors)}
          />

          <TagsRender
            label="Tags do Programa:"
            tags={programCurrent.tags}
            pathToRedirect={props.pathEnvolvedTags}
            canRemoveTag
            tagCallback={(indexToRemove) => removeSelectedTag(indexToRemove)}
          />

          <AccordionInfoItem
            colorHeader="#DAE3F6"
            status="tertiary"
            title={`Atividades do Programa (${programCurrent.activities ? programCurrent.activities.length : 0})`}
          >
            <ButtonAddCard text="Adicionar Atividades" handleOnClick={() => navigate(props.pathEnvolvedActivities)} />

            <CardsContainer ref={cardsContainerRef} lockScroll={false} className="cards-container">
              {programCurrent.activities &&
                programCurrent.activities.map((ac, arrayId) => (
                  <div id={`activity-${arrayId}`} onDragOver={() => onDragOver(arrayId)} className="drag-over-container" key={arrayId}>
                    <div
                      className="drag"
                      draggable
                      onDrag={(event) => onDrag(event)}
                      onDragStart={(event) => onDragStart(event, arrayId)}
                      onDragEnd={() => onDragEnd(arrayId)}
                      // onTouchStart={(event) => onTouchStart(event, arrayId)}
                      // onTouchMove={(event) => onTouchMove(event)}
                      // onTouchEnd={(event) => onTouchEnd(event, arrayId)}
                    >
                      <FiTriangle color="#2E3A59" className="move-card-top" onClick={() => moveActivityCardOrderUp(arrayId, arrayId - 1)} />
                      <FiMenu color="#2E3A59" className="move-card-hambuguer" />
                      <FiTriangle color="#2E3A59" className="move-card-bottom" onClick={() => moveActivityCardOrderDown(arrayId, arrayId + 1)} />
                      <ActivityLibraryCard
                        draggable
                        className="activity-library-card-drag"
                        activityLibraryCurrent={ac}
                        canDeleteCard={{ arrayId, removeCallback: removeCardFromProgram }}
                      />
                    </div>
                  </div>
                ))}
            </CardsContainer>
          </AccordionInfoItem>
        </CustomForm>
      </ProgramFormContainer>
    </DirectoryContainer>
  );
};

export { ProgramForm };
