import { styled } from 'react-alicerce-components';

export const HeadlightsPicker = styled.div`
 margin: 0rem 0rem 2.0rem 0rem;

 .headlight-picker-container {
      display: flex;
      justify-content: center;

      .headlight-tab {
        border: 1px solid #c5cee0;
        cursor: pointer;
        min-width: 120px;
        padding: 0.5rem 1rem;
        text-align: center;
        font-weight: 800;
        font-size: 12px;
        background: #edf1f7;
        color: #c5cee0;
      }

      .selected {
        color: white;
      }

      .headlight-tab.headlight {
        border: 1px solid #c5cee0;
        border-radius: 16px 0px 0px 16px;
      }
      .headlight-tab.headlight.selected {
        background: #F7652B;
      }

      .headlight-tab.diagnostic {
        border: 1px solid #c5cee0;
        border-radius: 0px 16px 16px 0px;
      }
      .headlight-tab.diagnostic.selected {
        background: #F7652B;
      }
    }
`

export const Container = styled.div`
  margin-bottom:90px;
`