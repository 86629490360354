import { ISelectOption } from 'react-alicerce-components';

// constraints student de status
export const STATUS_ACTIVE = { name: 'Ativo', value: 'active' }; // ATIVO
export const STATUS_ADAPTATION = { name: 'Em Adaptação', value: 'adaptation' }; // EM ADAPTAÇÃO
export const STATUS_EXPERIMENTATION = { name: 'Em Experimentação', value: 'experimentation' }; // EXPERIMENTAÇÃO
export const STATUS_NOT_ENROLLED_AFTER_ADAPTATION = { name: 'Não matriculado após adaptação', value: 'not_enroll_a_adapt' }; // NÃO MATRICULADO APÓS ADAPTAÇÃO
export const STATUS_NOT_ENROLLED_AFTER_EXPERIMENTATION = { name: 'Não matriculado após experimentação', value: 'not_enroll_a_exp' }; // NÃO MATRICULADO APÓS EXPERIMENTAÇÃO
export const STATUS_PAUSED = { name: 'Pausado', value: 'paused' }; // PAUSADO
export const STATUS_QUITTER = { name: 'Desistente', value: 'quitter' }; // DESISTENTE
export const STATUS_COMPLETER = { name: 'Concluinte', value: 'completer' }; // CONCLUINTE

export const STUDENT_STATUS_CONSTRAINTS = [
  STATUS_ACTIVE,
  STATUS_ADAPTATION,
  STATUS_EXPERIMENTATION,
  STATUS_NOT_ENROLLED_AFTER_ADAPTATION,
  STATUS_NOT_ENROLLED_AFTER_EXPERIMENTATION,
  STATUS_PAUSED,
  STATUS_QUITTER,
  STATUS_COMPLETER
];

export const defaultStatus = (studentStatus?: string | null): ISelectOption[] | undefined => {
  return STUDENT_STATUS_CONSTRAINTS.map((foo) => {
    return { name: foo.name, value: foo.value, selected: studentStatus === foo.value };
  });
};
