import React from 'react';

import { Label } from 'react-alicerce-components';

import { CheckboxTag, CheckboxTagItem, CheckboxTagItemChange } from '..';

import { ContainerList, ContainerCheckboxTagsGroup } from './styles';

export interface ICheckboxTagsGroupProps {
  itens?: CheckboxTagItem[];
  handleOnChange?: (state: CheckboxTagItemChange) => void;
  handleOnSetState?: React.Dispatch<React.SetStateAction<any>>
  defaultValues?: string[]
  label?: string;
  disabled?: boolean;
}

export const CheckboxTagsGroup: React.FC<ICheckboxTagsGroupProps> = ({
  children,
  itens,
  label,
  disabled,
  defaultValues: d,
  handleOnChange,
  handleOnSetState,
}) => {
  return (
    <ContainerCheckboxTagsGroup className='checkbox-tags-group-container'>
      <Label>{label}</Label>
      <ContainerList className='checkbox-tags-group-container_list'>
        {itens?.map((i, k) => (
          <CheckboxTag
            {...i}
            disabled={disabled}
            key={`${i}-${i.name}`}
            handleOnChange={handleOnChange}
            handleOnSetState={handleOnSetState}
            defaultChecked={d && (d?.includes(i.name) || d?.includes(`${i?.value}`) || d[i.name])}
          />
        ))}
        {children}
      </ContainerList>
    </ContainerCheckboxTagsGroup>
  );
};

