export interface IInputErrorsFormat {
  name: string;
  message: string;
}

export const errorMessageBuilder = (inputName: string, errors: IInputErrorsFormat[] = []): string | undefined => {
  const found = errors.find((error) => error.name === inputName);
  if (found) return found.message;
  return undefined;
};

export const hasError = (inputKeys: string[], errors: IInputErrorsFormat[] = []): boolean => {
  const foundInputError = inputKeys.find((k) => errors.find((ke) => ke.name === k));
  return foundInputError ? true : false;
};

export const beforeSubmitForm = async (inputData: any, schema: any) => {
  let auxErrors = [] as IInputErrorsFormat[];
  try {
    await schema.validate(inputData, { abortEarly: false });
    return auxErrors;
  } catch (err: any) {
    console.log(err.errors);
    if (err.errors) err.errors.map((er) => auxErrors.push(er));
    return auxErrors;
  }
};

export const errorMessagesFinder = (inputKeys: string[], errors: IInputErrorsFormat[]) => {
  return inputKeys.flatMap((key) => {
    const error = errorMessageBuilder(key, errors);
    return error ? error : [];
  });
};
