import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import DirectoryContainer from '@shared/components/DirectoryContainer';

import { Container, Modifier } from './styles';
import ClassesSelection from '@shared/components/ClassesSelection';
import IClass from '@modules/classes/interfaces/IClass';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { Input, Spinner } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { IGenericLocationState } from '@shared/interfaces/ILocationState';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

const AttendancesHome: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const { isLoading } = useGlobal();

  const handleOnClickClass = useCallback(
    (classClicked: IClass) => {
      const stateData = {
        genericStateData: classClicked,
        pathToReturn: '/presencas/contexto',
      } as IGenericLocationState<IClass>;

      navigate(`/presencas/${classClicked.id}/criar`, { state: stateData });
    },
    [navigate]
  );

  const filterClasses = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <Container>
      <HeaderDirectory title="Seleção de Contexto" status="basic" pathToReturn="/" backgroundHeader="#8F9BB3" menuKebab={{ menuBackHome: true }} />
      <DirectoryContainer footer={false}>
        {isLoading && <Spinner />}
        <Modifier>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => filterClasses(event.target.value)}
          />
        </Modifier>
        <ClassesSelection handleOnClick={handleOnClickClass} searchValue={searchValue} />
      </DirectoryContainer>
    </Container>
  );
};

export default AttendancesHome;
