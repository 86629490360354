import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useClass } from '@modules/classes/context/ClassContext';

const ClassTagRelation: React.FC = () => {
  const navigate = useNavigate();
  const { classCurrent, setClassCurrent } = useClass();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setClassCurrent((oldState: any) => {
        return { ...oldState, tags: tags };
      });
    },
    [setClassCurrent]
  );
  
  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={classCurrent.tags}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
      buttonCallback={() => navigate(-1)}
    />
  );
};

export default ClassTagRelation;
