import React, { useMemo } from 'react';

import { Pill as StyledTag, IPillProps, ITextEllipsis, TextEllipsis } from 'react-alicerce-components';

import { getColorContrast } from '@shared/utils/getColorContrast';

import Icon from 'react-eva-icons';

import ITag from '@modules/tags/interfaces/ITag';

import { Container, ContainerContent } from './styles';

export type ITagProps = IPillProps &
  ITextEllipsis & {
    tag: ITag;
    isSelected?: boolean;
    canRemoveTag?: boolean;
    handleOnClick?: (tag?: ITag) => void;
  };

const Tag: React.FC<ITagProps> = ({
  children,
  isSelected,
  status,
  backgroundColor,
  color,
  tag,
  canRemoveTag,
  lineClamp = 1,
  lineHeight = 1,
  boxOrient = 'vertical',
  size = 'small',
  handleOnClick,
  ...rest
}) => {

  const colorContrast = useMemo(() => tag.color ? getColorContrast(tag.color) : color, [color, tag]);

  return (
    <Container onClick={handleOnClick}>
      <StyledTag
        status={status}
        size={size}
        backgroundColor={backgroundColor || tag.color}
        color={color || colorContrast}
        marginLeft="4px"
        className={`tag ${isSelected ? 'selected-tag' : ''}`}
        {...rest}
      >
        <ContainerContent>
          <TextEllipsis lineClamp={lineClamp} lineHeight={lineHeight} boxOrient={boxOrient}>
            {tag.name}
          </TextEllipsis>
          {children}
        </ContainerContent>
        {canRemoveTag && <Icon name="close" fill="222b45" />}
      </StyledTag>
    </Container>
  );
};

export default Tag;
