import IHeadlight from "@modules/projects/interfaces/IHeadlight";

export const formatHeadlight = (headlight: IHeadlight) => {
  const formated = {
    type: headlight.type,
    diagnostics: headlight.diagnostics,
    data: headlight.data,
    lms_user_id: headlight.lms_user_id ?? undefined,
    approved_lms_user_id: headlight.approved_lms_user_id ?? undefined,
    previous_headlights_id: headlight.previous_headlights_id ?? undefined,
    project_id: headlight.project_id,
    sprint_id: headlight.sprint_id,
    period_id: headlight.period_id
  };
  return formated;
};
