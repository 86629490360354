import React, { useRef, useEffect } from 'react';

import { useLocationStudent } from '@shared/components/LocationStudent/context/LocationStudentContext';

import { MemoGrids } from '@shared/components/LocationStudent/partials/Grids';

import { Container, Wrapper, Header, CanSelectInfo } from './styles';

type LocationContainerProps = {
  isOpen?: boolean;
};

const LocationContainer: React.FC<LocationContainerProps> = ({ isOpen }) => {
  const wRef = useRef<HTMLDivElement>(null);

  const { trailCurrent, lockScroll, setWrapperRef, wrapperRef, isCanSelect, isShowLabel } = useLocationStudent();

  useEffect(() => {
    if (wRef.current && wrapperRef === null) {
      setWrapperRef(wRef);
    }
  }, [setWrapperRef, wrapperRef]);

  return (
    <Container className="location-student">
      <Wrapper className="location-student-wrapper" ref={wRef} lockedScroll={lockScroll}>
        {!!isShowLabel && (
          <Header isCanSelect={isCanSelect}>
            <h6>{trailCurrent?.name}</h6>
          </Header>
        )}
        {isCanSelect && <CanSelectInfo content="Escolha até 3 blocos consecutivos para lançamento do MiniMAPA:" />}
        <MemoGrids isOpen={isOpen}/>
      </Wrapper>
    </Container>
  );
};

export { LocationContainer };
