import React, { useCallback } from 'react';
import UserList from '@modules/users/components/UsersList';
import { IUser } from '@modules/users/interfaces/IUser';
import { IUpdateTeachersStudentsForm, useClass } from '@modules/classes/context/ClassContext';
import IClass from '@modules/classes/interfaces/IClass';

const ClassUsersRelation: React.FC = () => {
  const { setClassCurrent, classCurrent } = useClass();
  const setSelectedItens = useCallback(
    (teachers: IUser[]) => {
      setClassCurrent((oldState: IClass & IUpdateTeachersStudentsForm) => {
        const updateTeachers = oldState.update_teachers;

        const newTeachers = teachers.filter((t) => !oldState.teachers?.some((oldTeacherId) => Number(oldTeacherId.id) === Number(t.id)));
        const newTeachersIds = newTeachers.map((newTeacher) => Number(newTeacher.id));

        oldState.teachers = oldState.teachers ? [...oldState.teachers, ...newTeachers] : newTeachers

        return { ...oldState, update_teachers: { ...updateTeachers, enroll: newTeachersIds } };
      });
    },
    [setClassCurrent]
  );


  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      // pathRedirect="/diretorio/turmas/criar"
      // pathToReturn=""
      selectedItens={classCurrent.teachers ? classCurrent.teachers.map((teacher) => teacher) : []}
      setSelectedItens={setSelectedItens}
      canSelect="many"
      handleOnClick={setSelectedItens}
      // pathRedirect={''}
    />
  );
};

export { ClassUsersRelation };
