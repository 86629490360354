import React from 'react';
import { DashedBoxContainer } from './styles';
import Icon from 'react-eva-icons';

interface IDashedBoxProps {
  handleDeleteCallback?: () => void;
}

const DashedBox: React.FC<IDashedBoxProps> = ({ handleDeleteCallback, children }) => {
  return (
    <DashedBoxContainer className='container-dashed-box'>
      {handleDeleteCallback && (
        <div className="remove-card" onClick={() => handleDeleteCallback()}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
      {children}
    </DashedBoxContainer>
  );
};

export default DashedBox;
