import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderMain from '@shared/components/Headers/HeaderMain';
import { useUser } from '@modules/users/context/UserContext';

import { Container } from './styles';
import TooltipModal from '@shared/components/TooltipModal';
import ButtonBackHome from '@shared/components/ButtonBackHome/ButtonBackHome';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
  pathToReturn?: string;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer, pathToReturn = '/' }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { isLoading } = useGlobal();
  const { userCurrent, getUser } = useUser();

  const getUserFromContext = useCallback(async () => {
    if (!params['id']) return;
    const res = await getUser(params['id']);
    if (!res) navigate('/diretorio/usuarios');
  }, [getUser, navigate, params]);

  useEffect(() => {
    if (!userCurrent || params['id'] !== userCurrent.id) {
      getUserFromContext();
    }
  }, [getUserFromContext, params, userCurrent]);

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}
      <TooltipModal view={'UserInfo'} top={'42px'} right={'calc(100vw - 110px)'} />
      <ButtonBackHome positionTop="21px" positionRight="calc(100vw - 150px)" />
      <HeaderMain
        useDefaultNav
        title="Você está vendo o perfil de"
        subtitle={userCurrent ? userCurrent.name : 'Carregando...'}
        bgColor="linear-gradient(90deg,#f38331 0%,#2487c7 98.12%);"
        isScrolled={isScrolled}
        compressOnScroll
        pathToReturn={pathToReturn}
      />

      {userCurrent && userCurrent.id && (
        <CustomTabs footer={footer} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
          <Tabs openTab={openTab} content={children} horizontalScroll>
            <Tab title="Informações" onClick={() => navigate(`/usuario/${params['id']}/informacoes`)} />

            <Tab title="Histórico" onClick={() => navigate(`/usuario/${params['id']}/historico`)} />
          </Tabs>
        </CustomTabs>
      )}
    </Container>
  );
};

export default Layout;
