import * as Yup from 'yup';

export const occurrenceSchema = Yup.object().shape({
  title: Yup.string().required({ name: 'title', message: 'O campo é obrigatório' }),
  description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório' }),
  comment: Yup.string(),

  date: Yup.string().required({ name: 'date', message: 'O campo data e hora são obrigatórios' }),

  group_id: Yup.number(),
  place_id: Yup.number(),
  class_id: Yup.number(),

  tag_ids: Yup.array().of(Yup.number()),
  student_ids: Yup.array().of(Yup.number()),
  lms_user_ids: Yup.array().of(Yup.number()),
});
