import React from "react";
import { Grid, Spinner } from "react-alicerce-components";
import DirectoryContainer from "@shared/components/DirectoryContainer";
import HeaderDirectory from "@shared/components/Headers/HeaderDirectory";
import { useGlobal } from "@shared/contexts/global/GlobalContext";
import { ProjectDefaultStylesContainer } from "@modules/projects/components/ProjectDefaultStyles/styles";
import ProjectList from "@modules/projects/components/ProjectList";
import { useNavigate } from "react-router-dom";

const HeadlightsLaunch: React.FC = () => {
    const { isLoading } = useGlobal();
    const navigate = useNavigate();

    return (
        <ProjectDefaultStylesContainer>
            <HeaderDirectory
                title={'Lançador de Faróis'}
                onClickReturn={() => navigate('/')}
                menuKebab={{ tooltip: { view: 'HeadlightsLaunch' }, menuBackHome: true }}
            />
            {isLoading && <Spinner fixed />}
            <DirectoryContainer footer={false}>
                <Grid container>
                    <ProjectList
                        pathRedirect="lancar-farol"
                    />
                </Grid>
            </DirectoryContainer>
        </ProjectDefaultStylesContainer>
    );
}

export default HeadlightsLaunch;