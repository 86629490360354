import styled from 'styled-components';

export const Container = styled.div`
  margin: 1rem auto;
  max-width: 600px;
  
  .dynamic-table-container {
    overflow: hidden;
    width: 100%;

    table {
      width: 100%;
    }

    tr:not(.over-column) td, tr:not(.over-column) th {
      width: 50%;
    }

    tr:not(.over-column) span {
      width: 100%;
    }
  }
`;

