import { IHeaderMainState } from './HeaderMainContext';

export enum Types {
  SET_STATE = "SET_STATE",
  TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
  TOGGLE_NOTIFICATIONS = "TOGGLE_NOTIFICATIONS",
}

type Action =
  | { type: Types.SET_STATE; payload: IHeaderMainState }
  | { type: Types.TOGGLE_SIDEBAR; payload: boolean }
  | { type: Types.TOGGLE_NOTIFICATIONS; payload: boolean };

export default (state: IHeaderMainState, action: Action) => {
  switch (action.type) {
    case Types.SET_STATE:
      return {
        ...action.payload
      };

    case Types.TOGGLE_SIDEBAR:
    return {
      ...state,
      isShowSideBar: action.payload
    };
    
    case Types.TOGGLE_NOTIFICATIONS:
    return {
      ...state,
      isShowNotifications: action.payload
    };

    default:
      return state;
  }
};
