import { styled } from "react-alicerce-components";

export const DirectoryContainer = styled.div`
    padding: 0px;

`

export const Container = styled.div`

  margin: 0px;
  padding: 0px

  .input-container > div input::placeholder {
    font-weight: 500;
    color: #C5CEE0;
  }

  iframe {
    width: 100%;
    height: 100vh;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7rem;
`;
