import React, { useMemo, useCallback } from 'react';

import { useNavigate } from 'react-router';

import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';
import { useStudent } from '@modules/students/context/StudentContext';

const StudentTagProjectRelation: React.FC = () => {
  const navigate = useNavigate();
  const { setStudentCurrent, studentCurrent } = useStudent();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setStudentCurrent((oldState: any) => {
        return { ...oldState, partner_organizations_tag: tags };
      });
    },
    [setStudentCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      tag_type="project"
      selectedTags={studentCurrent.partner_organizations_tag}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
      buttonCallback={() => navigate(-1)}
    />
  );
};

export default StudentTagProjectRelation;
