import React, { useCallback, useEffect, useRef, useState } from 'react';

import useAuth from '@shared/store/auth/hook';
import { Spinner } from 'react-alicerce-components';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import Layout from '@modules/contracts/components/Layout';
import ContractList from '@modules/contracts/components/ContractList';
import { getLocalTimezoneDate } from '@shared/utils/getLocalTimezoneDate';
import { useContracts } from '@modules/contracts/context/ContractContext';
import { ContractDefaultStylesContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';
import { IContractResponse, IListContractBusinessOptions } from '@modules/contracts/services/ListContractService';
import ListContractsByPreviousContractIdService from '@modules/contracts/services/ListContractServiceByPreviousContractId';

const BeforeContracts: React.FC = () => {
  const { signedUser } = useAuth();
  const { contractCurrent } = useContracts();
  const { newCancelToken } = useCancelRequest();
  const { isLoading, setIsLoading } = useGlobal();
  const [contractList, setContractList] = useState<IContractResponse>();
  const contractBusinessFilter = useRef<IListContractBusinessOptions>({ end_date: { end: undefined, start: undefined } });

  const listContractsTree = useCallback(
    async (page = 0) => {
      if (!signedUser || !contractCurrent?.previous_contract_business_id) return;
      setIsLoading(true);
      const start = contractBusinessFilter.current.end_date?.start
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.start).startOfDay().toISOString()
        : undefined

      const end = contractBusinessFilter.current.end_date?.end
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.end).endOfDay().toISOString()
        : undefined
      const contracts = await new ListContractsByPreviousContractIdService(signedUser.token, newCancelToken()).execute('', {
        page,
        size: 10,
        parent_id: Number(contractCurrent?.previous_contract_business_id),
        ...contractBusinessFilter.current,
        end_date: { start, end },
      });
      if (contracts === 'canceling') return;
      setIsLoading(false);
      if (!contracts) return;
      setContractList(contracts);
    },
    [contractCurrent, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listContractsTree()
  }, [listContractsTree])
  return (
    <Layout openTab={2}>
      <ContractDefaultStylesContainer>
        {isLoading && <Spinner fixed />}
        <ContractList contractList={contractList} contractBusinessFilter={contractBusinessFilter} listContracts={listContractsTree} />
      </ContractDefaultStylesContainer>
    </Layout>
  );
};

export default BeforeContracts;
