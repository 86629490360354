import React from 'react';
import { P, H6 } from 'react-alicerce-components';
import { StyledNavbar } from './styles';

export type INavbar = {
  title?: string;
  subtitle?: string;
  left?: any;
  right?: any;
};

const Navbar: React.FC<INavbar> = ({ title, subtitle, left, right }) => {
  return (
    <StyledNavbar className="main-navbar">
      {left && <div className="left">{left}</div>}

      <div className="middle">
        {title && <H6>{title}</H6>}
        {subtitle && (
          <P color="#8F9BB3" fontSize="12px">
            {subtitle}
          </P>
        )}
      </div>

      {right && <div className="right">{right}</div>}
    </StyledNavbar>
  );
};

export default Navbar;
