import React from 'react';
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import HeaderDirectory from '../../../../shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '../../../../shared/components/DirectoryContainer';
import {reportList} from '../Reports'

const ExternalReport: React.FC = () => {
  const {id} = useParams<{id: string}>()
  const url = reportList[Number(id)].url
  const name = reportList[Number(id)].name
  return (
    <>
      <HeaderDirectory title={name} pathToReturn={'/relatorios'} backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)" />
      <DirectoryContainer style={{ padding: '0px' }} footer={false} isScrollBlocked>
        <Container>
          <iframe src={url} title={name} />
        </Container>
      </DirectoryContainer>
    </>
  );
};

export default ExternalReport;
