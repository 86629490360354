import React from 'react';

import Layout from '@modules/classes/components/Layout';
import ButtonGradient from '@shared/components/ButtonGradient';
import { useNavigate, useParams } from 'react-router-dom';

const ClassesReport: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Layout openTab={4}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '1rem' }}>
        <ButtonGradient type={3} onClick={() => navigate(`/dash/aprendizagem/grupo/${id}`)}>
          Dash de Aprendizagem
        </ButtonGradient>
        <ButtonGradient type={12} onClick={() => navigate(`/dash/frequencia/grupo/${id}`)}>
          Dash de Frequência
        </ButtonGradient>
        {/* <ButtonGradient type={12} onClick={() => navigate(`/dash/indicadores/aderencia/turma/${id}`)}>
          Dash de Indicadores
        </ButtonGradient> */}
        <ButtonGradient type={5} onClick={() => navigate(`/dash/habilidadesparavida/grupo/atual/${id}`)}>
          Habilidades para Vida
        </ButtonGradient>
      </div>
    </Layout>
  );
};

export default ClassesReport;
