import { styled } from 'react-alicerce-components';

export const AttachButton = styled.div`
  background-color: #c5cee0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  padding: 4px 6px;
  margin: -20px 0rem 1rem auto;
  cursor: pointer;

  i {
    display: flex;
    svg {
      width: 25px;
      height: 25px;
    }
  }

  :hover {
    background: #222b45;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7rem;

  button {
    flex: 0 0 calc(50% - 11px);
    min-width: 120px;
  }
`;
