import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .input-container {
    margin: 1.5rem 0 0 0;

    > div input::placeholder {
      font-weight: 500;
      color: #c5cee0;
    }
  }

  .pagination-content-icon {
    background-color: #ffba95 !important;

    i svg {
      fill: #fff !important;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  .custom-card > div {
    margin: 10px 0;
  }
`;

export const ContainerButtons = styled.div`
  margin: 44px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.7rem;

  button {
    flex: 0 0 calc(50% - 11px);
    min-width: 120px;
  }
`;
