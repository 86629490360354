export const questionsHV = [
    { block: 'PENSAR', name: 'H01', id: -2, question: 'É capaz de lembrar e seguir as instruções e combinados do grupo.' },
    { block: 'PENSAR', name: 'H02', id: -3, question: 'É capaz de inibir comportamentos impulsivos.' },
    { block: 'PENSAR', name: 'H03', id: -4, question: 'É capaz de manter a atenção numa tarefa, até finalizá-la.' },
    { block: 'PENSAR', name: 'H04', id: -5, question: 'Consegue falar sobre seus pensamentos e ações, demonstrando consciência sobre situações vividas.' },
    { block: 'PENSAR', name: 'H05', id: -6, question: 'Consegue ajustar seu comportamento e suas expectativas às mudanças do ambiente.' },
    { block: 'SENTIR', name: 'H06', id: -7, question: 'Consegue reconhecer e nomear emoções que sente.' },
    { block: 'SENTIR', name: 'H07', id: -8, question: 'Consegue reconhecer e nomear as emoções que os outros estão sentindo.' },
    { block: 'SENTIR', name: 'H08', id: -9, question: 'Consegue reconhecer o efeito que as emoções que sente tem sobre o comportamento dos outros.' },
    { block: 'SENTIR', name: 'H09', id: -10, question: 'Demonstra a capacidade de controlar as suas emoções.' },
    { block: 'SENTIR', name: 'H10', id: -11, question: 'Identifica a relação entre pensamentos, emoções e ações.' },
    { block: 'AUTOCONHECIMENTO', name: 'H11', id: -12, question: 'Conhece e explora pontos fortes e consegue traçar estratégias para desenvolver suas limitações.' },
    { block: 'AUTOCONHECIMENTO', name: 'H12', id: -13, question: 'Demonstra interesse genuíno e entusiasmo por novos desafios ou novas propostas.' },
    { block: 'AUTOCONHECIMENTO', name: 'H13', id: -14, question: 'Demonstra engajamento com a aprendizagem de novos conceitos e novas práticas.' },
    { block: 'AUTOCONHECIMENTO', name: 'H14', id: -15, question: 'Demonstra a habilidade de persistir em tarefas difíceis.' },
    { block: 'AUTOCONHECIMENTO', name: 'H15', id: -16, question: 'Consegue manter atitude positiva diante das adversidades.' },
    { block: 'CONTRIBUIR & RETRIBUIR', name: 'H16', id: -17, question: 'Consegue perceber indicativos de como os outros estão se sentindo, e atuar para ajudar, quando necessário.' },
    { block: 'CONTRIBUIR & RETRIBUIR', name: 'H17', id: -18, question: 'Reconhece a contribuição de pessoas ou grupo de pessoas que são diferentes de si mesmo na aparência, jeito de pensar, jeito de falar, etc.' },
    { block: 'CONTRIBUIR & RETRIBUIR', name: 'H18', id: -19, question: 'Reconhece o valor de diferentes culturas e grupos sociais.' },
    { block: 'CONTRIBUIR & RETRIBUIR', name: 'H19', id: -20, question: 'Explica como suas decisões e comportamentos afetam o bem-estar de sua escola, casa e comunidade.' },
    { block: 'CONTRIBUIR & RETRIBUIR', name: 'H20', id: -21, question: 'Reconhece como as crenças são moldadas por experiências sociais e culturais.' },
    { block: 'AUTOSSUPERAÇÃO', name: 'H21', id: -22, question: 'Identifica e pratica comportamentos adequados que resultam em relacionamentos positivos.' },
    { block: 'AUTOSSUPERAÇÃO', name: 'H22', id: -23, question: 'Identifica as perspectivas de indivíduos em conflito e entende sua própria responsabilidade em alcançar a resolução.' },
    { block: 'AUTOSSUPERAÇÃO', name: 'H23', id: -24, question: 'Desenvolve e pratica o uso de habilidades de resolução de problemas e resolução de conflitos.' },
    { block: 'AUTOSSUPERAÇÃO', name: 'H24', id: -25, question: 'O aluno demonstra capacidade de desempenhar diferentes funções em um grupo cooperativo para atingir os objetivos do grupo.' },
    { block: 'AUTOSSUPERAÇÃO', name: 'H25', id: -26, question: 'O aluno desenvolve estratégias de tomada de decisão para evitar comportamentos de risco.' }
  ]