import React from 'react';

import UserList from '@modules/users/components/UsersList';

const UserDirectory: React.FC = () => {
  return (
    <UserList title="Diretório de Usuários" canSelect={undefined} pathRedirect="/diretorio/usuario/criar" pathToReturn="/" btnText="Criar Usuário" />
  );
};

export default UserDirectory;
