import { getRgbaToHex } from "@shared/utils/getRgbaToHex";
import { IIndividualFrenquencyPercent } from "../interfaces/IIndividualFrenquencyPercent";
import { translateClassType } from "./translateClassType";

export interface IFormattedIndividualFrequencyPercent {
  id?: string | number;
  classname?: string;
  type?: string;
  selected?: boolean;
  percent?: number | string;
  percentBgColor?: string | number;
  typeBgColor?: string;
}  

export const formatIndividualFrequencyPercent = (data: IIndividualFrenquencyPercent): IFormattedIndividualFrequencyPercent => {
  const transparency = data.percentage <= 10 ? .1 :  Number(data.percentage / 100);
  const percentBgColor = getRgbaToHex([0, 214, 143, transparency]);
  return {
    id: data?.class?.id,
    classname: data?.class?.name,
    type: translateClassType(`${data?.class?.type}`),
    selected: false,
    percent: `${data.percentage}%`,
    typeBgColor: '#C5CEE0',
    percentBgColor,
  }
}

