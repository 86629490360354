import IRoute from '@shared/interfaces/IRoute';

import AttendancesClassesSelection from '@modules/attendances/views/AttendancesClassesSelection';
import { AttendancesCreate } from '@modules/attendances/views/AttendancesCreate';
import { AttendancesCreateRetroactive } from '@modules/attendances/views/AttendancesCreateRetroactive';
import { AttendancesProvider } from '@modules/attendances/context/AttendancesContext';
import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { AttendancesOccurrences } from '@modules/attendances/views/AttendancesOccurrences';
import { attendancesKeys } from '@modules/attendances/keys';
import { classesKeys } from '@modules/classes/keys';
import { dailyClassKeys } from '@modules/class-planner/keys';
import { usersKeys } from '@modules/users/keys';

import OccurrenceUsersRelationCreate from '@modules/occurrences/views/OccurrenceUsersRelationCreate';
import OccurrenceStudentsRelationCreate from '@modules/occurrences/views/OccurrenceStudentsRelationCreate';


const routes: IRoute[] = [
  {
    path: 'presencas/contexto',
    key: 'HOME_ATTENDANCES_PATH',
    exact: true,
    component: AttendancesClassesSelection,
    permissions: [
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: 'presencas/:id/criar',
    key: 'HOME_ATTENDANCES_CREATE_PATH',
    exact: true,
    component: AttendancesCreate,
    permissions: [
      attendancesKeys.ATTENDANCES_READ,
      attendancesKeys.ATTENDANCES_CREATE,
      attendancesKeys.ATTENDANCES_UPDATE,
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: 'presencas/:id/criar/retroativa',
    key: 'HOME_ATTENDANCES_RETROACTIVE_PATH',
    exact: true,
    component: AttendancesCreateRetroactive,
    permissions: [
      attendancesKeys.ATTENDANCES_READ,
      attendancesKeys.ATTENDANCES_CREATE,
      attendancesKeys.ATTENDANCES_UPDATE,
      classesKeys.CLASSES_READ,
    ]
  },
  {
    path: 'presencas/:id/ocorrencia',
    key: 'ATTENDANCES_OCCURRENCE_PATH',
    exact: true,
    component: AttendancesOccurrences,
    permissions: [
      attendancesKeys.ATTENDANCES_READ,
      attendancesKeys.ATTENDANCES_CREATE
    ]
  },
  {
    path: 'presencas/ocorrencia/turma/:classId/adicionar/lideres',
    key: 'ATTENDANCES_OCCURRENCE_ADD_USER_PATH',
    exact: true,
    component: OccurrenceUsersRelationCreate,
    permissions: [
      attendancesKeys.ATTENDANCES_READ,
      usersKeys.LMS_USER_READ,
      dailyClassKeys.DAILY_CLASS_READ
    ]
  },
  {
    path: 'presencas/ocorrencia/turma/:classId/adicionar/estudantes',
    key: 'ATTENDANCES_OCCURRENCE_ADD_STUDENTS_PATH',
    exact: true,
    component: OccurrenceStudentsRelationCreate,
    permissions: [
      attendancesKeys.ATTENDANCES_READ,
      usersKeys.LMS_USER_READ,
      dailyClassKeys.DAILY_CLASS_READ
    ]
  },
];

const providers = [AttendancesProvider];

export default {
  key: 'ATTENDANCES_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
