import { IAuthState, Types } from './types';
import { Action } from './actions';

const initialState: IAuthState = {
  users: [],
  signedUser: undefined,
};

const reducer = (state: IAuthState = initialState, action: Action) => {
  switch (action.type) {
    case Types.SET_AUTH_STATE:
      return {
        ...action.payload,
      };
    case Types.SET_USER:
      const users = state.users || [];

      var indexFound = users.findIndex((u) => u.id === action.payload.id);
      if (indexFound !== -1) {
        let auxUsers = state.users.slice();
        auxUsers[indexFound] = action.payload;
        return {
          ...state,
          signedUser: action.payload,
          users: auxUsers,
        };
      }

      return {
        ...state,
        users: users.concat(action.payload),
        signedUser: action.payload,
      };

    case Types.SET_SIGNED_USER:
      return {
        ...state,
        signedUser: action.payload,
      };

    case Types.SELECT_USER:
      const user = state.users.find((u) => u.id === action.payload);
      if (user) {
        return {
          ...state,
          signedUser: user,
        };
      }

      return { ...state };

    case Types.LOGOUT_SIGNED_USER:
      return {
        ...state,
        signedUser: null,
      };

    default:
      return state;
  }
};

export default reducer;
