import React, { useMemo } from 'react';
import CustomCard from '@shared/components/CustomCard';

import { IUser } from '@modules/users/interfaces/IUser';
import { Container } from './styles';
import { translateString } from '@shared/utils/translateString';

interface IUserCard {
  user: IUser;
  usersSelected?: IUser[];
  canSelect: boolean;
  isInputCard?: boolean;
  handleOnClick?: (user: IUser) => void;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
  userSelected?: boolean;
  customForm?: JSX.Element;
  isDisabled?: JSX.Element | boolean;
}

const UserCard: React.FC<IUserCard> = ({
  user,
  canSelect,
  handleOnClick,
  usersSelected,
  canDeleteCard,
  isInputCard = false,
  userSelected = false,
  customForm,
  isDisabled,
}) => {
  const isSelected = useMemo(() => {
    return usersSelected?.find((p) => p.id === user.id) && canSelect ? true : false;
  }, [canSelect, user.id, usersSelected]);

  return (
    <Container onClick={handleOnClick ? () => handleOnClick(user) : undefined} selected={isSelected || userSelected} isInputCard={isInputCard} isDisabled={isDisabled}>
      <CustomCard
        status="info"
        header={<h6>{user.name}</h6>}
        canDeleteCard={canDeleteCard}
        inputCard={isInputCard}
        customForm={!isDisabled && customForm}
      >
        <div className="container-user-card-info">
          <div>
            <span>ID: </span>
            <span className="data-user-card-info">{user.user_id}</span>
          </div>
          <div>
            <span>Status: </span>
            <span className="data-user-card-info">{translateString(user.status)}</span>
          </div>
          <div>
            <span>Categoria: </span>
            <span className="data-user-card-info">{translateString(user.category)}</span>
          </div>
        </div>
      </CustomCard>
    </Container>
  );
};

export default UserCard;
