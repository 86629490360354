import React from 'react';
import Layout from '@modules/students/components/Layout';
import ButtonGradient from '@shared/components/ButtonGradient';
import { useNavigate, useParams } from 'react-router-dom';

const StudentReport: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Layout openTab={2}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1rem',
          padding: '1rem',
        }}
      >
        <ButtonGradient onClick={() => navigate(`/dash/aprendizagem/individual/${id}`)} type={3}>
          Aprendizagem Individual
        </ButtonGradient>

        <ButtonGradient onClick={() => navigate(`/dash/frequencia/individual/${id}`)} type={2}>
          Frequência do Aluno
        </ButtonGradient>
      </div>
    </Layout>
  );
};

export default StudentReport;
