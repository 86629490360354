import { styled } from 'react-alicerce-components';

export const ProjectDefaultStylesContainer = styled.div`
  [role='tablist'],
  .header-navigation-container .main-navbar {
    background: linear-gradient(45deg, #BE3A3A 0%, #F7652B 100%);
  }

  h6 {
    color: #BE3A3A;
    font-weight: 800;
  }

  .accordion-header {
    background: #F3C8C8;
  }

  textarea,
  input {
    color: #BE3A3A !important;
  }

  .btn-project,
  .container-page-footer button {
    background: linear-gradient(45deg, #BE3A3A 0%, #F7652B 100%)
  }
  
  .container-page-footer {
    .btn-cancel {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.88) 2.26%, #BE3A3A 100%);
      border: 2px solid #BE3A3A;
      .button-inner {
        color: #fff;
      }
    }

  }
`;
