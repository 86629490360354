import React, { useState } from 'react';

import { Grid, H6, Input, Select, Textarea, Divider } from 'react-alicerce-components';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import CustomForm from '@shared/components/CustomForm';
import { IInputErrorsFormat, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';

import { validContractStatusOptions } from '@modules/projects/schemas/Project/partials/ProjectStatus';
import { useContracts } from '@modules/contracts/context/ContractContext';
import TagsRender from '@modules/tags/components/TagsRender';

import { useContractForm } from '../useContractForm';
import { FocalPointFragment } from './FocalPointFragment';
import { validContractWeekDays } from '@modules/contracts/schemas/Contract/partials/validContractWeekDays';
import { defaultType } from '@modules/classes/utils/validData/validClassType';
import { differenceInMonths, format, parseISO } from 'date-fns';

interface ContractInfoFragmentProps {
    disabled?: boolean
    errors?: IInputErrorsFormat[];
}

export const ContractInfoFragment: React.FC<ContractInfoFragmentProps> = ({
    errors,
    disabled,
}) => {
    const [, setAddFocalPoint] = useState<boolean>(false)
    const { contractCurrent } = useContracts();

    const {
        handleSelectChange,
        handleChangeInput,
    } = useContractForm()

    const RenderContractDuration = () => {
        if (contractCurrent?.signature_date && contractCurrent.end_date) {
            const initialDate = parseISO(String(contractCurrent?.signature_date));
            const endDate = parseISO(String(contractCurrent.end_date));
            const differenceInMonthsValue = differenceInMonths(endDate, initialDate)
            return <Input
                defaultValue={`${differenceInMonthsValue} ${differenceInMonthsValue > 1 ? 'Meses' : "Mês"}` || ''}
                label="Duração do Contrato:"
                placeholder="00/00/0000"
                name="contract_duration"
                useTooltipError={false}
                disabled={true}
            />
        }
        return <Input
            disabled={true}
            label="Duração do Contrato:"
            placeholder="00/00/0000"
            useTooltipError={false}
            name="contract_duration"
        />
    }

    const RenderDaysLeft = () => {
        if (contractCurrent?.signature_date && contractCurrent.end_date) {
            const initialDate = new Date()
            const endDate = parseISO(String(contractCurrent.end_date));
            const differenceInMonthsValue = differenceInMonths(endDate, initialDate)
            return <Input
                defaultValue={`${differenceInMonthsValue} ${differenceInMonthsValue > 1 ? 'Dias' : "Dia"}` || ''}
                label="Dias para o Fim:"
                placeholder="00/00/0000"
                name="days_left"
                useTooltipError={false}
                disabled={true}
            />
        }
        return <Input
            disabled={true}
            label="Dias para o Fim:"
            placeholder="00/00/0000"
            useTooltipError={false}
            name="days_left"
        />
    }

    return (
        <Grid row>
            <H6>INFORMAÇÕES DO CONTRATO:</H6>
            <CustomForm>
                <Grid row>
                    <Grid sm={12} md={4} mt4>
                        <Input
                            disabled={disabled}
                            label="Nome do Contrato:"
                            name="name"
                            placeholder="Adicione o nome do contrato"
                            onChange={handleChangeInput}
                            defaultValue={contractCurrent?.name}
                            useTooltipError={false}
                            error={errorMessageBuilder('name', errors)}
                        />
                    </Grid>
                    <Grid sm={12} md={8} mt4>
                        <Select
                            disabled={disabled}
                            label="Status do Contrato:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={validContractStatusOptions(contractCurrent?.status)}
                            error={errorMessageBuilder('status', errors)}
                            name='status'
                        />
                    </Grid>
                    <Grid sm={12} md={4} mt4>
                        <Input
                            disabled={disabled}
                            label="ID Externo"
                            name="external_id"
                            placeholder="Adicione o id externo"
                            onChange={handleChangeInput}
                            defaultValue={contractCurrent?.external_data?.id}
                            useTooltipError={false}
                            error={errorMessageBuilder('external_id', errors)}
                        />
                    </Grid>
                    <Grid sm={12} md={8} mt4>
                        <Input
                            disabled={disabled}
                            label="Origem ID Externo:"
                            name="external_id"
                            placeholder="Adicione o origem id externo"
                            onChange={handleChangeInput}
                            defaultValue={contractCurrent?.external_data?.source}
                            useTooltipError={false}
                            error={errorMessageBuilder('external_id', errors)}
                        />
                    </Grid>
                </Grid>

                <Grid row mt4>
                    <Textarea
                        disabled={disabled}
                        name="description"
                        label="Descrição do Contrato:"
                        placeholder="Adicione uma descrição"
                        value={contractCurrent?.description}
                        rows={8}
                    />
                </Grid>

                <Divider width='100%' />

                <Grid row>
                    <Grid sm={12} md={4} mt4>
                        <Input
                            disabled={disabled}
                            label="Alunos Contratados"
                            placeholder="0000 Alunos"
                            onChange={handleChangeInput}
                            useTooltipError={false}
                            name="hired_students"
                            defaultValue={contractCurrent?.number_of_students || ''}
                            error={errorMessageBuilder('hired_students', errors)}
                        />
                    </Grid>
                    <Grid sm={12} md={4} mt4>
                        <Select
                            disabled={disabled}
                            label="Modalidade das Aulas:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultType(contractCurrent?.class_type)}
                            error={errorMessageBuilder('modality_of_classes', errors)}
                            name='modality_of_classes'
                        />
                    </Grid>

                    <Grid sm={12} md={4} mt4>
                        <Select
                            disabled={disabled}
                            label="Qt. de dias da Semana:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={validContractWeekDays(String(contractCurrent?.days_per_week))}
                            error={errorMessageBuilder('days', errors)}
                            name='days'
                        />
                    </Grid>

                    <Grid sm={12} md={4} mt4>
                        <Input
                            disabled={disabled}
                            label="Qt. de horas da Semana"
                            placeholder="00 horas"
                            useTooltipError={false}
                            name="hours"
                            defaultValue={contractCurrent?.weekly_hours || ''}
                            error={errorMessageBuilder('hours', errors)}
                        />
                    </Grid>
                </Grid>

                <AccordionInfoItem title='Prazos do Contrato'>
                    <Grid row>
                        <Grid column={6} mt4>
                            <Input
                                label="Início (Data da Assinatura):"
                                placeholder="00/00/0000"
                                useTooltipError={false}
                                name="hired_students"
                                defaultValue={contractCurrent?.signature_date && format(new Date(contractCurrent?.signature_date), "dd/MM/yyyy")}
                                error={errorMessageBuilder('hired_students', errors)}
                            />
                        </Grid>
                        <Grid column={6} mt4>
                            <Input
                                disabled={disabled}
                                label="Fim (Aulas + Contrato):"
                                placeholder="00/00/0000"
                                useTooltipError={false}
                                name="hired_students"
                                defaultValue={contractCurrent?.end_date && format(new Date(contractCurrent?.end_date), "dd/MM/yyyy")}
                                //defaultValue={contractCurrent?.hired_students}
                                error={errorMessageBuilder('hired_students', errors)}
                            />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid column={6} mt4>
                            <Input
                                disabled={disabled}
                                label="Início das Aulas:"
                                placeholder="00/00/0000"
                                useTooltipError={false}
                                name="hired_students"
                                defaultValue={contractCurrent?.start_class_date && format(new Date(contractCurrent?.start_class_date), "dd/MM/yyyy")} error={errorMessageBuilder('hired_students', errors)}
                            />
                        </Grid>
                        <Grid column={6} mt4>
                            <RenderContractDuration />
                        </Grid>
                    </Grid>
                    <Grid row>
                        <Grid column={6} mt4>
                            <RenderDaysLeft />
                        </Grid>
                        <Grid column={6} mt4>
                            <Input
                                disabled={disabled}
                                label="Data de Gatilho de Renovação:"
                                placeholder="00/00/0000"
                                useTooltipError={false}
                                name="hired_students"
                                defaultValue={contractCurrent?.renewal_trigger_date ? format(new Date(contractCurrent?.end_date), "dd/MM/yyyy") : ''} error={errorMessageBuilder('hired_students', errors)}
                            />
                        </Grid>
                    </Grid>

                </AccordionInfoItem>

                <Divider width='100%' />

                <H6>INFORMAÇÕES DO CONTRATO:</H6>

                <Grid row>
                    <Grid sm={12} md={4} mt4>
                        <Input
                            disabled={disabled}
                            label="Nome da Empresa:"
                            placeholder="Adicione o nome da empresa"
                            useTooltipError={false}
                            name="company_name"
                            defaultValue={contractCurrent?.gic_contact_company?.name}
                            error={errorMessageBuilder('company_name', errors)}
                        />
                    </Grid>
                </Grid>

                <AccordionInfoItem title='Pontos Focais'>
                    {!disabled && <ButtonAddCard text="Adicione ponto Focal" handleOnClick={() => setAddFocalPoint((oldState) => !oldState)} />}
                    {contractCurrent?.responsible_contacts && contractCurrent?.responsible_contacts.length > 0 && <FocalPointFragment disabled={disabled} />}
                </AccordionInfoItem>

                <Divider width='100%' />

                <TagsRender
                    label="Tags:"
                    placeholder='Tags'
                    tags={contractCurrent?.tags || []}
                    canRemoveTag={!disabled}
                />

            </CustomForm>
        </Grid>
    );
}