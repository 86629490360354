import React, { useCallback } from 'react';
import CustomForm from '@shared/components/CustomForm';
import { useUser } from '../../context/UserContext';
import { IUser } from '../../interfaces/IUser';
import { UserFormContainer } from './styles';
import { H6, Select, Input } from 'react-alicerce-components';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import Divider from '@shared/components/Divider';
import { errorMessageBuilder, hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { defaultGender } from '@shared/utils/validData/validGender';
import { defaultEducationLevel } from '@shared/utils/validData/validEducationLevel';
import { defaultLmsUserStatus } from '../../utils/validData/validUserStatus';

interface IUserForm {
  errors: IInputErrorsFormat[];
  refPersonalMain: React.RefObject<any>;
  refPersonalAcademy: React.RefObject<any>;
}
const UserForm: React.FC<IUserForm> = ({ errors, refPersonalMain, refPersonalAcademy }) => {
  const { userCurrent, setUserCurrent } = useUser();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, subLevel?: string) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setUserCurrent((oldState: IUser) => {
        if (subLevel) return { ...oldState, [subLevel]: { ...oldState[subLevel], [inputName]: inputValue } };
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setUserCurrent]
  );

  const handleSelectChange = useCallback(
    (option, fieldName: string) => {
      setUserCurrent((oldState: any) => {
        return { ...oldState, [fieldName]: option.value };
      });
    },
    [setUserCurrent]
  );

  console.log('userCurrent => ', userCurrent);
  return (
    <UserFormContainer>
      <H6 fontWeight={800} status="secondary">
        DADOS ALICERCE
      </H6>
      <AccordionInfoItem title="Dados Principais" status="secondary" hasErrors={hasError(['status', 'activation_date'], errors)}>
        <CustomForm status="secondary" customInputColorText="#5DC4EA">
          <div className="container-accordion-input">
            <Select
              label="Status do Usuário:"
              iconName="arrow-down"
              placeholder="Escolha Status"
              handleSelectChange={(option) => handleSelectChange(option, 'status')}
              options={defaultLmsUserStatus(userCurrent?.status)}
              required
              error={errorMessageBuilder('status', errors)}
            />
            <Input
              name="activation_date"
              placeholder="Dia/Mês/Ano"
              label="Data de Ativação:"
              onChange={(event) => handleChangeInput(event)}
              defaultValue={userCurrent?.activation_date ? `${userCurrent?.activation_date}` : ''}
              type="date"
              useTooltipError={false}
              error={errorMessageBuilder('activation_date', errors)}
            />
          </div>
        </CustomForm>
      </AccordionInfoItem>

      <Divider width="100%" />

      <H6 fontWeight={800} status="secondary">
        DADOS PESSOAIS
      </H6>
      <AccordionInfoItem
        refSize={refPersonalMain}
        title="Dados Principais"
        status="secondary"
        hasErrors={hasError(
          ['name', 'contact_email', 'phone', 'address_data.address', 'address_data.city', 'address_data.uf', 'gender', 'birthdate'],
          errors
        )}
      >
        <CustomForm status="secondary" customInputColorText="#5DC4EA">
          <Input
            label="Nome do Usuário:"
            name="name"
            placeholder="Nome do Usuário"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userCurrent?.name}
            useTooltipError={false}
            required
            error={errorMessageBuilder('name', errors)}
          />

          <Input
            label="Email Pessoal:"
            name="contact_email"
            placeholder="Email do Usuário"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userCurrent?.user?.contact_email}
            useTooltipError={false}
            disabled
            error={errorMessageBuilder('contact_email', errors)}
          />

          <Input
            label="Telefone:"
            name="phone"
            placeholder="Telefone do Usuário"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userCurrent?.phone}
            useTooltipError={false}
            required
            error={errorMessageBuilder('phone', errors)}
          />

          <Input
            label="Endereço:"
            name="address"
            placeholder="Logradouro, Rua e Bairro"
            onChange={(event) => handleChangeInput(event, 'address_data')}
            defaultValue={userCurrent?.address_data?.address}
            useTooltipError={false}
            required
            error={errorMessageBuilder('address_data.address', errors)}
          />

          <div className="wrapper-input">
            <Input
              name="city"
              placeholder="Cidade"
              label="Cidade:"
              onChange={(event) => handleChangeInput(event, 'address_data')}
              defaultValue={userCurrent?.address_data?.city}
              useTooltipError={false}
              required
              error={errorMessageBuilder('address_data.city', errors)}
            />
            <Input
              name="uf"
              placeholder="UF"
              label="UF:"
              onChange={(event) => handleChangeInput(event, 'address_data')}
              defaultValue={userCurrent?.address_data?.uf}
              mask="##"
              useTooltipError={false}
              required
              error={errorMessageBuilder('address_data.uf', errors)}
            />
          </div>

          <Select
            label="Identidade de Gênero:"
            iconName="arrow-down"
            placeholder="Selecione"
            handleSelectChange={(option) => handleSelectChange(option, 'gender')}
            options={defaultGender(userCurrent?.gender)}
            required
            error={errorMessageBuilder('gender', errors)}
          />

          <Input
            label="Data de Nascimento:"
            name="birthdate"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={userCurrent?.birthdate ? `${userCurrent?.birthdate}` : ''}
            type="date"
            useTooltipError={false}
            required
            error={errorMessageBuilder('birthdate', errors)}
          />
        </CustomForm>
      </AccordionInfoItem>

      <AccordionInfoItem
        refSize={refPersonalAcademy}
        title="Dados Acadêmicos"
        status="secondary"
        hasErrors={hasError(
          ['education_level', 'university_type', 'academic_info.name', 'academic_info.course_name', 'academic_info.end_date'],
          errors
        )}
      >
        <CustomForm status="secondary" customInputColorText="#5DC4EA">
          <div className="container-accordion-input">
            <Select
              label="Grau de Escolaridade:"
              iconName="arrow-down"
              placeholder="Escolha Escolaridade"
              handleSelectChange={(option) => handleSelectChange(option, 'education_level')}
              options={defaultEducationLevel(userCurrent?.education_level)}
              required
              error={errorMessageBuilder('education_level', errors)}
            />

            <Select
              label="Tipo de Universidade:"
              iconName="arrow-down"
              placeholder="Escolha o Tipo"
              handleSelectChange={(option) => handleSelectChange(option, 'university_type')}
              options={[
                {
                  name: 'Particular',
                  value: 'private',
                  selected: userCurrent?.university_type === 'private',
                },
                {
                  name: 'Pública',
                  value: 'public',
                  selected: userCurrent?.university_type === 'public',
                },
              ]}
              required
              error={errorMessageBuilder('university_type', errors)}
            />

            <Input
              label="Informações Acadêmicas:"
              name="name"
              placeholder="Nome da Instituição"
              onChange={(event) => handleChangeInput(event, 'academic_info')}
              defaultValue={userCurrent?.academic_info?.name}
              useTooltipError={false}
              required
              error={errorMessageBuilder('academic_info.name', errors)}
            />

            <div className="wrapper-input">
              <Input
                name="course_name"
                label="Curso:"
                placeholder="Nome do Curso"
                onChange={(event) => handleChangeInput(event, 'academic_info')}
                defaultValue={userCurrent?.academic_info?.course_name}
                useTooltipError={false}
                required
                error={errorMessageBuilder('academic_info.course_name', errors)}
              />
              <Input
                label="Término:"
                name="end_date"
                placeholder="Término"
                onChange={(event) => handleChangeInput(event, 'academic_info')}
                defaultValue={userCurrent?.academic_info?.end_date}
                useTooltipError={false}
                required
                error={errorMessageBuilder('academic_info.end_date', errors)}
              />
            </div>
          </div>
        </CustomForm>
      </AccordionInfoItem>
    </UserFormContainer>
  );
};

export { UserForm };
