import { styled } from 'react-alicerce-components';

const StyledNavbar = styled.nav`
  display: flex;
  flex-direction: row;
  height: 40px;
  box-sizing: border-box;

  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #222b45;

  .left,
  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .right {
    justify-content: flex-end;
  }
  .middle {
    margin-top: 0.9rem;
  }
  .left {
    i {
      display: flex;
      padding: 0.2rem;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    justify-content: flex-start;
  }
`;

export { StyledNavbar };
