import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Button, Spinner, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { useProgram } from '@modules/activities-library/context/ProgramContext';
import GetProgramService from '@modules/activities-library/services/GetProgramService';
import { FavoriteToggleProgramsService } from '../../services/FavoriteToggleProgramsService';
import { IProgram } from '../../interfaces/IProgram';
import DeleteProgramService from '../../services/DeleteProgramService';
import ConfirmModal from '@shared/components/ConfirmModal';
import Icon from 'react-eva-icons';
import { ProgramFormInfo } from '../../components/ProgramFormInfo';

const ProgramInfo: React.FC = () => {
  const { programCurrent, setProgramCurrent } = useProgram();
  const { programId } = useParams<{ programId: string }>();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { isLoading, setIsLoading } = useGlobal();

  const getProgram = useCallback(async () => {
    if (!programId) return;
    setIsLoading(true);
    const res = await new GetProgramService(signedUser?.token).execute(programId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Recuperar Programa.',
      });
      return navigate('/banco-atividades');
    }

    if (!isMounted()) return;
    setProgramCurrent(res);
  }, [addToast, isMounted, navigate, programId, setIsLoading, setProgramCurrent, signedUser]);

  const toggleFavoriteProgram = useCallback(async () => {
    setIsLoading(true);
    const res = await new FavoriteToggleProgramsService(signedUser?.token).execute(`${programCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Favoritar/Desfavoritar Programa.',
      });
      return;
    }
    if (!isMounted()) return;
    setProgramCurrent((oldState: IProgram) => {
      return { ...oldState, favorited: res.favorited };
    });
  }, [addToast, isMounted, programCurrent.id, setIsLoading, setProgramCurrent, signedUser]);

  const deleteProgram = useCallback(async () => {
    setShowModal(false);
    setIsLoading(true);
    const res = await new DeleteProgramService(signedUser?.token).execute(`${programCurrent.id}`);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Programas',
        description: 'Error ao Deletar Programa.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Programas',
      description: 'Programa Deletado com Sucesso.',
    });
    navigate(`/banco-atividades`);
    return;
  }, [addToast, navigate, programCurrent.id, setIsLoading, signedUser]);

  const handleCopyProgram = useCallback(() => {
    navigate(`/banco-atividades/criar/programa`);
  }, [navigate]);

  useEffect(() => {
    getProgram();
  }, [getProgram]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory
        title="Programa"
        pathToReturn="/banco-atividades"
        pathToReturnOptions={{ state: { activeTab: 'program' } }}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        menuKebab={{
          copyCallback: () => handleCopyProgram(),
          deleteCallback: () => setShowModal(true),
          favorited: { favoritedCallback: () => toggleFavoriteProgram(), isFavorited: programCurrent.favorited || false },
        }}
      />
      <DirectoryContainer footer={true}>
        {programId === programCurrent.id && <ProgramFormInfo />}
        <PageFooter>
          <Button
            className="button-program"
            transform="none"
            noBorder
            shaded
            onClick={() => navigate(`/banco-atividades/programa/${programCurrent.id}/editar`)}
          >
            Editar Programa
          </Button>
        </PageFooter>
      </DirectoryContainer>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(!showModal)}
        title="Deseja Excluir Programa?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={deleteProgram}
        subTitle={'Você só pode excluir programas do seu banco pessoal. Ao fazer isso, você não exclui as atividades que pertencem a esse programa.'}
      />
    </Fragment>
  );
};

export { ProgramInfo };
