export const CATEGORY_NATION = { name: 'Nação', value: 'nation' };
export const CATEGORY_TRIBE = { name: 'Tribo', value: 'tribe' };
export const CATEGORY_COLLECTIVE = { name: 'Coletivo', value: 'collective' };

export type IGroupValidCategories = 'nation' | 'tribe' | 'collective';

export const GROUP_CATEGORY_CONSTRAINTS = [CATEGORY_NATION, CATEGORY_TRIBE, CATEGORY_COLLECTIVE];

export const defaultGroupCategory = (groupCategory?: string | null) => {
  return [
    { name: CATEGORY_NATION.name, value: CATEGORY_NATION.value, selected: groupCategory === CATEGORY_NATION.value },
    { name: CATEGORY_TRIBE.name, value: CATEGORY_TRIBE.value, selected: groupCategory === CATEGORY_TRIBE.value },
    { name: CATEGORY_COLLECTIVE.name, value: CATEGORY_COLLECTIVE.value, selected: groupCategory === CATEGORY_COLLECTIVE.value },
  ];
};

export const CATEGORY_VALUES = GROUP_CATEGORY_CONSTRAINTS.map((c) => c.value);
