import React, { useCallback } from 'react';
import { Textarea } from 'react-alicerce-components';

interface ICommentCreate {
  setNewCommentCallback: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CommentCreate: React.FC<ICommentCreate> = ({ setNewCommentCallback }) => {
  const handleOnChangeNewComment = useCallback(
    (newComment: string) => {
      if (newComment.length === 0) {
        setNewCommentCallback(undefined);
        return;
      }
      
      setNewCommentCallback(newComment);
    },
    [setNewCommentCallback]
  );

  return (
    <div>
      <Textarea
        label="Comentário:"
        name="comment"
        placeholder="Adicione Seu Comentário"
        rows={4}
        onChange={(event) => handleOnChangeNewComment(event?.target.value)}
      />
    </div>
  );
};

export default CommentCreate;
