export const getRgbaToHex = (rgba: number[]) => {
    let outParts = [
        rgba[0].toString(16),
        rgba[1].toString(16),
        rgba[2].toString(16),
        Math.round(rgba[3] * 255).toString(16).substring(0, 2)
    ];
    
    outParts.forEach(function (part, i) {
    if (part.length === 1) {
        outParts[i] = '0' + part;
    }
    })
    
    return ('#' + outParts.join(''));
};