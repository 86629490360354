import GroupListComponent from '@modules/groups/components/GroupsList';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import React, { useCallback, useState } from 'react';
import { useWorkedHour } from '../../context/WorkedHourContext';

const WorkedHourAdvancedFilterGroupRelation: React.FC = () => {
  const { workedHoursFilter } = useWorkedHour();
  const [selectedGroups, setSelectedGroups] = useState<IGroup>();

  const handleAddParentGroup = useCallback(
    (groupSelected?: IGroup) => {
      const value = groupSelected ? groupSelected : undefined;
      workedHoursFilter.current.group = value;
    },
    [workedHoursFilter]
  );

  return (
    <GroupListComponent
      headerTitle="Selecione um Grupo"
      canSelect="one"
      handleOnClick={handleAddParentGroup}
      btnText="Adicionar Grupo Mãe"
      selectedGroups={selectedGroups ? [selectedGroups] : []}
      setSelectedGroups={(groups) => setSelectedGroups(groups[0])}
    />
  );
};

export { WorkedHourAdvancedFilterGroupRelation };
