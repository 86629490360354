import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { ISubscription } from '@modules/subscriptions/interfaces/ISubscription';
import useAuth from '../../../shared/store/auth/hook';
import ListPlacesService from '../../places/services/ListPlacesService';
import useCancelRequest from '../../../shared/hooks/useCancelRequest';
import IPlace from '../../places/interfaces/IPlace';

type ISubscriptionContextData = {
  checkedSubscriptions: ISubscription[];
  setCheckedSubscriptions: (value: any) => void;
  subscriptionsFilter: React.MutableRefObject<ISubscriptionFilter>;
  listPlacesFilter: (search: string) => Promise<IPlace[]>;
};

const SubscriptionContext = createContext<ISubscriptionContextData>({} as ISubscriptionContextData);

export interface ISubscriptionFilter {
  shifts?: string[];
  types?: string[];
  age_groups?: string[];
}

const SubscriptionProvider: React.FC = ({ children }) => {
  const [checkedSubscriptions, setCheckedSubscriptions] = useState<ISubscription[]>([]);
  const subscriptionsFilter = useRef<ISubscriptionFilter>({});
  const { signedUser } = useAuth();
  const { newCancelToken } = useCancelRequest();

  const listPlacesFilter = useCallback(
    async (search: string) => {
      if (!signedUser) return [];
      const places = await new ListPlacesService(signedUser.token, newCancelToken()).execute(search);
      if (!places || places === 'canceling') return [];
      return places.items;
    },
    [newCancelToken, signedUser]
  );

  return (
    <SubscriptionContext.Provider
      value={{
        checkedSubscriptions,
        subscriptionsFilter,
        setCheckedSubscriptions,
        listPlacesFilter,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

function useSubscription() {
  const context = useContext(SubscriptionContext);

  if (!context) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }

  return context;
}

export { SubscriptionProvider, useSubscription };
