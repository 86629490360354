import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IDailyClass } from '../../attendances/interfaces/IDailyClass';
import { IFormattedDailyClass } from '../interfaces/IFormattedDailyClass';
type ErrorMessageMap = {
  [key: string]: string;
};

export type CustomErrorMessage = { isFailed: boolean; message: string }

const updateDailyClassErrorsMessages: ErrorMessageMap = {
  attendance_given: "Uma aula que já teve a presença enviada não pode ser editada.",
};
class UpdateDailyClassService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(dailyClassBody: IFormattedDailyClass, dailyClassId: string): Promise<IDailyClass | false | CustomErrorMessage> {
    try {
      const res = await this.api.put(`/daily-class/${dailyClassId}`, { ...dailyClassBody });
      return res.data as IDailyClass;
    } catch (error: any) {
      const existsErrorPath = error.response.data.path
      if (existsErrorPath) {
        return { message: updateDailyClassErrorsMessages[existsErrorPath] || 'Erro ao editar o Aula.', isFailed: true } || false
      }
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default UpdateDailyClassService;
