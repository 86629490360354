import React, { useCallback } from 'react';

import Layout from '@modules/places/components/Layout';
import ButtonGradient from '@shared/components/ButtonGradient';
import { ContainerButtons } from './styles';
import { usePlaces } from '@modules/places/context/PlacesContext';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { useNavigate, useParams } from 'react-router-dom';

const PlaceHome: React.FC = () => {
  const navigate = useNavigate();
  const { setOccurrenceCurrent, setContextSelected } = useOccurrences();
  const { placeCurrent } = usePlaces();
  const { id } = useParams();

  const handleClickOccurrences = useCallback(() => {
    if (!placeCurrent) return;
    setOccurrenceCurrent({ place: placeCurrent });
    setContextSelected({ context: 'locais', context_id: id });
    navigate(`/ocorrencias/locais/${id}/pendentes`, { state: { goBack: true } });
  }, [placeCurrent, setOccurrenceCurrent, setContextSelected, id, navigate]);

  return (
    <Layout openTab={0}>
      <ContainerButtons>
        <ButtonGradient type={15} onClick={handleClickOccurrences}>
          Ocorrências
        </ButtonGradient>

        {/* <ButtonGradient type={15}>Gravações de Aula</ButtonGradient> */}
      </ContainerButtons>
    </Layout>
  );
};

export default PlaceHome;
