import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import IQueryOptions from '@shared/interfaces/IQueryOptions';

import { IIndividualFrenquencyHistory } from '../interfaces/IIndividualFrenquencyHistory';

export interface IIndividualFrequencyHistoryReponse extends IPaginatedResponse {
  items: IIndividualFrenquencyHistory[];
}

class GetIndividualFrequencyHistoryService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(studentId: string | number, options?: IQueryOptions): Promise<IIndividualFrequencyHistoryReponse | false> {
    try {
      const searchParams = objectToQuery({...options}, true);
      const res = await this.api.get(`/dashboard/attendance/individual/history/${studentId}?${searchParams}`);
      console.log(res.data)
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetIndividualFrequencyHistoryService;
