// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Spinner, useToast } from 'react-alicerce-components';
import { useParams, useNavigate } from 'react-router-dom';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import Divider from '@shared/components/Divider';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';

import { IStudent } from '@modules/students/interfaces/IStudent';
import { WorkGroupAndStudentsInfo } from '@modules/class-planner/components/WorkGroupAndStudentsInfo';
import { useScheduleGrid } from '@modules/class-planner/context/ScheduleGridContext';
import GetPlannedActivityWithWorkGroupAndStudentsService from '@modules/class-planner/services/GetPlannedActivityWithWorkGroupAndStudentsService';
import { Container } from './styles';
import { PlannedActivityHelper } from '../../helpers/PlannedActivityHelper';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { IAttachment } from '@shared/interfaces/IAttachment';
import UploadService from '@shared/services/UploadFile';
import { plannedActivitySchema } from '../../utils/plannedActivitySchema';
import { splitAttachmentFiles } from '@shared/utils/splitAttachmentFiles';
import { formatedFileUpload } from '@modules/activities-library/utils/formatedFileUpload';
import UpdatePlannedActivitiesService from '../../services/UpdatePlannedActivitiesService';
import { ActivityLibraryForm } from '@modules/activities-library/components/ActivityLibraryForm';
import { useActivity } from '@modules/activities-library/context/ActivityContext';

const ClassPlannerPlannedActivityEdit: React.FC = () => {
  const {
    availableStudents,
    availableWorkGroups,
    setAvailableStudents,
    setAvailableWorkGroups,
    setPlannedActivityCurrent,
    plannedActivityCurrent,
    plannedActivities,
    setProblems,
    setCanSavePlannedClass,
    setPlannedActivities,
  } = useScheduleGrid();

  const { classId, dailyClassId, plannedActivityId, activityId } = useParams<{
    dailyClassId: string;
    activityId: string;
    classId: string;
    plannedActivityId: string;
  }>();

  const [selectedStudents, setSelectedStudents] = useState<IStudent[]>([]);
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { isLoading, setIsLoading } = useGlobal();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const { activityLibraryCurrent } = useActivity();

  const handleSavePlannedActivity = useCallback(async () => {
    if (!plannedActivities?.in_calendar) throw new Error('plannedActivities?.in_calendar não pode ser undefined');
    if (!plannedActivityCurrent) throw new Error('plannedActivityCurrent não pode ser undefined');
    if (!dailyClassId) throw new Error('dailyClassId não pode ser undefined');
    if (!classId) throw new Error('classId não pode ser undefined');

    const found = plannedActivities.in_calendar.find((pa) => Number(pa.planned_activity_id) === Number(plannedActivityCurrent.id));
    if (!found) throw new Error('plannedActivityCurrent não encontrada em plannedActivities.in_calendar');

    // removo a atividade sendo alterada de  plannedActivities.in_calendar para nao duplicar o objeto antes de enviar para backend
    const filtered = plannedActivities.in_calendar.filter((pa) => Number(pa.planned_activity_id) !== Number(plannedActivityCurrent.id));

    const formattedPlannedActivityBody = PlannedActivityHelper.formatUpdateDisket(filtered);

    let plannedActivityCurrentFormatted = PlannedActivityHelper.formatUpdatePlannedActivityCurrent(
      { ...plannedActivityCurrent, activity_data: activityLibraryCurrent },
      selectedStudents
    );

    let formErros = await beforeSubmitForm(plannedActivityCurrentFormatted.activity_data, plannedActivitySchema);

    if (selectedStudents.length === 0) {
      formErros = [...formErros, { name: 'students', message: 'É necessário selecionar pelo menos um aluno' }];
    }

    setErrors(formErros);
    if (formErros.length > 0) return;

    let formattedAttachmentsToSave: IAttachment[] = [];

    const { newFiles, oldFiles } = splitAttachmentFiles(plannedActivityCurrentFormatted.activity_data.attachments);

    setIsLoading(true);

    if (newFiles && newFiles.length > 0) {
      const res = await new UploadService(signedUser?.token).uploadFile(newFiles, 'activities_library/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      const formattedAttachments = formatedFileUpload(newFiles);
      formattedAttachmentsToSave = formattedAttachments.map((attachment, i) => ({ ...attachment, url: res[i].dbURL }));
    }

    if (formattedAttachmentsToSave.length > 0 && plannedActivityCurrentFormatted.activity_data?.attachments) {
      plannedActivityCurrentFormatted.activity_data.attachments = [...oldFiles, ...formattedAttachmentsToSave];
    }

    const body = { daily_class_id: dailyClassId, planned_activities: [...formattedPlannedActivityBody, plannedActivityCurrentFormatted] };

    const res = await new UpdatePlannedActivitiesService(signedUser?.token).execute(body);
    setIsLoading(false);

    if (!res)
      return addToast({
        status: 'danger',
        title: 'Planejador de Aula',
        description: 'Error ao salvar planejamento, tente novamente.',
      });

    // ℹ️ - O estado de plannedActivities é zerado para que seja feita uma nova consulta ao retornar para o calendário.
    setPlannedActivities(undefined);

    setProblems(res.pending_problems);
    setCanSavePlannedClass(false);
    navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`);
    return;
  }, [
    addToast,
    classId,
    dailyClassId,
    navigate,
    plannedActivities,
    plannedActivityCurrent,
    selectedStudents,
    setCanSavePlannedClass,
    setIsLoading,
    activityLibraryCurrent,
    setPlannedActivities,
    setProblems,
    signedUser,
  ]);

  const getActivityWithWorkGroupsAndStudents = useCallback(async () => {
    if (!plannedActivityId || !classId) return;

    setIsLoading(true);
    const res = await new GetPlannedActivityWithWorkGroupAndStudentsService(signedUser?.token).execute({
      planned_activity_id: plannedActivityId,
      class_id: classId,
    });
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Atividade',
        description: 'Error ao Recuperar Atividade.',
      });
      navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades`);
      return;
    }

    if (!isMounted()) return;

    const { students, workGroups, plannedActivity } = res;

    setPlannedActivityCurrent(plannedActivity);
    setAvailableStudents(students);
    setAvailableWorkGroups(workGroups);
  }, [
    addToast,
    classId,
    dailyClassId,
    isMounted,
    navigate,
    plannedActivityId,
    setAvailableStudents,
    setAvailableWorkGroups,
    setIsLoading,
    setPlannedActivityCurrent,
    signedUser,
  ]);

  useEffect(() => {
    if (!plannedActivities?.in_calendar) return navigate(`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`);
  }, [classId, dailyClassId, navigate, plannedActivities]);

  useEffect(() => {
    if (!plannedActivityCurrent) getActivityWithWorkGroupsAndStudents();
  }, [getActivityWithWorkGroupsAndStudents, plannedActivityCurrent]);

  useEffect(() => {
    const found = plannedActivities?.in_calendar.find((pa) => Number(pa.planned_activity_id) === Number(plannedActivityId));
    setSelectedStudents(found?.planned_activity.students || []);
  }, [plannedActivities, plannedActivityId]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Banco de Atividades"
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
        pathToReturn={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}`}
        menuKebab={{ menuBackHome: true }}
      />
      <DirectoryContainer footer={true}>
        {plannedActivityId === plannedActivityCurrent?.id && (
          <ActivityLibraryForm
            errors={errors}
            pathToCreateTagsRelation={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/selecionar/tags`}
            pathToCreateSkillRelation={`/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${activityId}/editar/atividade-planejada/${plannedActivityId}/skills`}
          />
        )}

        <Divider width="100%" />
        {availableStudents && availableWorkGroups && (
          <WorkGroupAndStudentsInfo
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            formAction="editing"
            errors={errors}
          />
        )}

        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() =>
              navigate(
                `/planejamento-aula/turma/${classId}/aula/${dailyClassId}/banco-atividades/atividade/${activityId}/informacoes/atividade-planejada/${plannedActivityId}`
              )
            }
            className="mochileiro-color"
          >
            Cancelar
          </Button>
          <Button transform="none" shaded onClick={handleSavePlannedActivity} className="mochileiro-color">
            Salvar Atividade
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export { ClassPlannerPlannedActivityEdit };
