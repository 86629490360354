import * as Yup from 'yup';

export function validTime(fieldName: string) {
  return Yup.string().test('max-hours', function (value) {
    if (!value) return true;
    const [hours, minutes] = value.split(':').map(Number);
    if (hours > 23) {
      return this.createError({
        message: { name: fieldName, message: 'O valor das horas deve estar entre 00 e 23. (Ex: 23h59)' },
      });
    }

    if (minutes > 59) {
      return this.createError({
        message: { name: fieldName, message: 'O valor dos minutos deve estar entre 00 e 59. (Ex: 23h59)' },
      });
    }
    return true;
  });
}