import { P } from 'react-alicerce-components';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .card-header {
    padding: .5rem;
    background-color: #FF9F6D;
  }

  .card-body {
    border-radius: 0;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #FFEEE5;
  }

  .card-footer {
    border-color: transparent;

    > div:first-child {
      display: flex;
      align-items: center;
      padding: .5rem;
      background-color: #FF9F6D;
    }
  }

  .tag {
    border-radius: 4px;
    padding: 3px 6px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Name = styled(P)`
  font-weight: 900;
`;

export const Info = styled(P)`
  font-size: 12px;
`;