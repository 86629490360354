import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .label-container {
    display: flex;
    align-items: center;
    padding: 20px;
    color: #2e3a59;
    font-family: var(--secondary-font);
    font-weight: 700;
    text-transform: initial;
    letter-spacing: 0;
    font-size: 13px;

    .button-add {
      margin-right: 18px;

      i {
        width: 32px;
        height: 32px;
      }
    }

    .card-header {
      background-color: red !important;
    }

    .card-footer > div {
      background-color: #855CBD !important;
    }
 
  }

`;