import { styled } from 'react-alicerce-components';

export const TabPickerContainer = styled.div`
  display: flex;
  justify-content: center;

  .tab-item {
    border: 1px solid #c5cee0;
    cursor: pointer;
    min-width: 120px;
    padding: 0.5rem 1rem;
    text-align: center;
    font-weight: 800;
    font-size: 12px;
    background: #edf1f7;
    color: #c5cee0;
    transition: all 0.3s;

    &:first-child {
      border-radius: 16px 0px 0px 16px;
    }

    &:last-child {
      border-radius: 0px 16px 16px 0px;
    }

    &.selected {
      background: #F7652B;
      color: white;
      border: 1px solid #F7652B;
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 2rem;
`;