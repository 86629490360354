import { redirectToAuth } from '@shared/utils/authRedirect';
import React, { Fragment, useEffect } from 'react';
import { Spinner } from 'react-alicerce-components';
import { useLocation } from 'react-router-dom';

const SigIn: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/auth/callback') return;
    redirectToAuth();
  }, [location.pathname]);

  return (
    <Fragment>
      <Spinner />
    </Fragment>
  );
};

export default SigIn;
