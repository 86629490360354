import React from 'react';
import { useNavigate } from 'react-router';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';
import CustomTabs from '@shared/components/CustomTabs';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Container } from './styles';

interface IHabilidadesParaVidaIndividualLayoutProps {
  openTab: number;
  footer?: boolean
}

export const HabilidadesParaVidaIndividualLayout: React.FC<IHabilidadesParaVidaIndividualLayoutProps> = ({
  openTab = 0,
  footer = false,
  children
}) => {
  const navigate = useNavigate();
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory title='Habilidades para Vida' />
      <CustomTabs footer={footer}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="ATUAL" onClick={() => navigate(`/dash/habilidadesparavida/individual/atual`)} />
          <Tab title="EVOLUÇÃO" onClick={() => navigate(`/dash/habilidadesparavida/individual/evolucao`)} />
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

