import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Button, Textarea, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Label } from './styles';
import Icon from 'react-eva-icons';

import { CustomCalendar as Calendar } from '@shared/components/CustomCalendar';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import ConfirmModal from '@shared/components/ConfirmModal';
import { DateHelper } from '@shared/helpers/DateHelper';
import CustomForm from '@shared/components/CustomForm';
import PageFooter from '@shared/components/PageFooter';
import Divider from '@shared/components/Divider';
import useAuth from '@shared/store/auth/hook';

import { ListDailyClassesService } from '@modules/attendances/services/ListDailyClassesService';
import { ListAttendancesService } from '@modules/attendances/services/ListAttendancesService';
import UpdateAttendanceService from '@modules/attendances/services/UpdateAttendanceService';
import { formatBodyAttendance } from '@modules/attendances/utils/formatBodyAttendance';
import { useAttendances } from '@modules/attendances/context/AttendancesContext';
import { TodayClassCard } from '@modules/attendances/components/TodayClassCard';
import { IAttendance } from '@modules/attendances/interfaces/IAttendance';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';
import { ClassAttendees } from '@modules/attendances/components/ClassAttendees';

const AttendanceRetroactiveForm: React.FC = () => {
  const navigate = useNavigate();

  const { attendanceCurrent, setAttendanceCurrent } = useAttendances();
  const currentClassInPath = useParams<{ id: string }>();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [showAttendanceModal, setShowAttendanceModal] = useState<boolean>(false);
  const { addToast } = useToast();
  const [retroAttendances, setRetroAttendances] = useState<IAttendance[]>();
  const [reason, setReason] = useState<string>();
  const refTextArea = useRef<HTMLTextAreaElement>(null);
  const [selectedDailyClass, setSelectedDailyClass] = useState<IDailyClass>();

  const listDailyClasses = useCallback(
    async (day: string) => {
      setIsLoading(true);
      const res = await new ListDailyClassesService(signedUser?.token, newCancelToken()).execute(undefined, {
        size: 9999,
        class_id: currentClassInPath.id,
        date: day,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return console.log(`>>> ERROR NA CONSULTA`);
      setAttendanceCurrent((oldState: any) => {
        return { ...oldState, today_classes: res.items };
      });
      setRetroAttendances(undefined);
      setReason(undefined);
      if (refTextArea.current) refTextArea.current.value = '';
    },
    [currentClassInPath.id, newCancelToken, setAttendanceCurrent, setIsLoading, signedUser]
  );

  const handleClickOnDailyClass = useCallback(
    async (todayClass: IDailyClass) => {
      setIsLoading(true);
      const res = await new ListAttendancesService(signedUser?.token, newCancelToken()).execute(undefined, {
        size: 9999,
        daily_class_id: todayClass.id,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res) return console.log(`>>> ERROR NA CONSULTA`);
      const itens = res.items.map((a) => {
        return { ...a, initial_presence: a.presence };
      });
      setSelectedDailyClass(todayClass)
      setRetroAttendances(itens);
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    if (!attendanceCurrent) listDailyClasses(DateHelper.extractDate());
  }, [attendanceCurrent, listDailyClasses]);

  const handleAttendenceGiven = useCallback((id: number, checked: boolean) => {
    setRetroAttendances((oldState: any) => {
      const found = oldState.findIndex((at) => Number(at.id) === Number(id));
      oldState[found] = { ...oldState[found], presence: checked };
      return [...oldState];
    });
  }, []);

  const handleUpdateAttendance = useCallback(async () => {
    if (!signedUser || !retroAttendances) return;
    setShowAttendanceModal(false);

    const filtredAttendances = retroAttendances.filter((at) => at.presence !== at.initial_presence);

    if (!!!filtredAttendances.length) {
      setShowAttendanceModal(false);
      return addToast({
        status: 'warning',
        title: 'Presenças',
        description: 'Nenhuma presença alterada.',
      });
    }

    if (!reason || reason?.trim().length < 2) {
      setShowAttendanceModal(false);
      return addToast({
        status: 'danger',
        title: 'Presenças',
        description: 'Nenhuma razão foi definida.',
      });
    }
    const formatedBody = formatBodyAttendance(filtredAttendances, reason);

    setIsLoading(true);
    const updated = await new UpdateAttendanceService(signedUser.token).execute(formatedBody);
    setIsLoading(false);

    if (!updated) {
      setShowAttendanceModal(false);
      addToast({
        status: 'danger',
        title: 'Presenças',
        description: 'Falha ao tentar fazer chamada retroativa. Tente novamente.',
      });
      return;
    } else if (!!updated.errors.length) {
      addToast({
        status: 'warning',
        title: 'Presenças',
        description: `Presença lançada, porém ocorreu um erro com a presença de ${updated.errors.length} alunos.`,
      });
    } else {
      addToast({
        status: 'success',
        title: 'Presenças',
        description: 'Presença retroativa lançada com sucesso.',
      });
    }

    const dcId = updated?.success[0]?.daily_class_id;

    setAttendanceCurrent((oldState: IAttendance) => {
      const foundIndex = oldState?.today_classes?.findIndex((ac) => ac.id === dcId);
      if (foundIndex === undefined || !oldState.today_classes) return oldState;
      oldState.today_classes[foundIndex] = { ...oldState.today_classes[foundIndex], attendance_given: true };
      return oldState;
    });

    setIsLoading(false);
    setRetroAttendances(undefined);
    setReason(undefined);
    if (refTextArea.current) refTextArea.current.value = '';
  }, [signedUser, retroAttendances, reason, setIsLoading, setAttendanceCurrent, addToast]);

  const selectAllTeachers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setRetroAttendances((oldState: any) => {
      const found = oldState.map((generic) => {
        if (generic.lmsUser) {
          return { ...generic, presence: checked };
        }
        return generic;
      });

      return found;
    });
  };

  const selectAllStudents = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setRetroAttendances((oldState: any) => {
      const found = oldState.map((generic) => {
        if (generic.student) {
          return { ...generic, presence: checked };
        }
        return generic;
      });

      return found;
    });
  };

  const isDailyClassDisabled = useCallback((dailyClass: IDailyClass) => {
    const now = new Date();
    const dailyClassStart = new Date(`${dailyClass.start_date}T${dailyClass.start_time}`);
    const today = DateHelper.isToday(new Date(`${dailyClass.start_date}T${dailyClass.start_time}`));

    if (today) return true;
    if (dailyClassStart > now) return true;

    return false;
  }, []);

  const isReasonDisable = (reason: string | undefined): boolean => {
    if (!reason) return true
    const trimmedReason = reason?.trim();
    return trimmedReason?.length < 2;
  }

  useEffect(() => {
    console.log(retroAttendances, 'retroAttendances');
  }, [retroAttendances]);

  return (
    <Container>
      <CustomForm>
        <Label>PRESENÇA RETROATIVA</Label>
        <Calendar handleSelectDay={(day) => listDailyClasses(day)} />

        <Divider width="100%" />

        <Label>AULAS DO DIA</Label>
        <div className="container-daily-class">
          {attendanceCurrent?.today_classes &&
            attendanceCurrent.today_classes.map((dailyClass, i) => (
              <TodayClassCard
                disabled={isDailyClassDisabled(dailyClass)}
                todayClass={dailyClass}
                todayClassesSelected={selectedDailyClass}
                canSelect
                showAttendanceStatus
                key={dailyClass.id}
                handleOnClick={() => handleClickOnDailyClass(dailyClass)}
              />
            ))}
        </div>

        {retroAttendances && (
          <Fragment>
            <Textarea
              textareaRef={refTextArea}
              name="justify"
              label="Justifique Presença:"
              placeholder="Adicione Justificativa de Presença Retroativa Obrigatória"
              rows={4}
              onChange={(event) => setReason(event.target.value)}
            />

            <Divider width="100%" />

            <ClassAttendees
              attendances={retroAttendances.map((a) => ({ ...a, id: Number(a.id) }))}
              handleOnClickOnSelectAllStudents={selectAllStudents}
              handleOnClickOnSelectAllLmsUsers={selectAllTeachers}
              handleOnToggleAttendance={(attendanceId, presence) => handleAttendenceGiven(attendanceId, presence)}
            />
          </Fragment>
        )}
      </CustomForm>

      <PageFooter>
        {selectedDailyClass && (
          <Button
            start={<Icon name="edit-2" />}
            onClick={() => navigate(`/planejamento-aula/turma/${currentClassInPath.id}/editar/aula/${selectedDailyClass.id}`)}
            style={{ transform: 'none', borderRadius: '16px' }}
            fill="linear-gradient(to top right, #482B85 0.24%, #6749BA 99.76%)"
            color="#fff"
            noBorder
          >
            Editar Aula
          </Button>
        )}

        <Button
          start={<Icon name="checkmark" />}
          onClick={() => setShowAttendanceModal(true)}
          style={{ transform: 'none', borderRadius: '16px' }}
          status={'success'}
          statusNumber={500}
          color="#fff"
          disabled={!retroAttendances || isReasonDisable(reason)}
        >
          Salvar Presença
        </Button>
      </PageFooter>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showAttendanceModal}
        closeCallback={() => setShowAttendanceModal(false)}
        title="Deseja Confirmar a Presença?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleUpdateAttendance}
      />
    </Container>
  );
};

export default AttendanceRetroactiveForm;
