import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '@modules/classes/components/Layout';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';
import { useClass } from '@modules/classes/context/ClassContext';
import ButtonGradient from '@shared/components/ButtonGradient';

import { ContainerButtons } from './styles';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { classCurrent } = useClass();
  const { setOccurrenceCurrent, setContextSelected } = useOccurrences();
  const { id } = useParams();

  const handleClickAttendance = useCallback(() => {
    navigate(`/presencas/${id}/criar`, { state: classCurrent.name });
  }, [classCurrent.name, id, navigate]);

  const handleClickOccurrences = useCallback(() => {
    setOccurrenceCurrent({ class: classCurrent });
    setContextSelected({ context: 'turmas', context_id: classCurrent.id });
    navigate(`/ocorrencias/turmas/${id}/pendentes`, { state: { goBack: true } });
  }, [classCurrent, id, navigate, setContextSelected, setOccurrenceCurrent]);

  function openGIP(to: string) {
    const env = process.env.REACT_APP_LINK_GIP;
    if (!env) {
      throw new Error('REACT_APP_LINK_GIP is not setup.');
    }
    window.location.href = `${env}${to}`;
  }

  return (
    <Layout openTab={0}>
      <ContainerButtons>
        <ButtonGradient type={13} onClick={handleClickAttendance}>
          Presença
        </ButtonGradient>

        <ButtonGradient type={3} onClick={() => navigate(`/metasdeaprendizagem/selecao-trilha/${id}`)}>
          Metas de Aprendizagem
        </ButtonGradient>

        <ButtonGradient onClick={() => openGIP(`/planejamento-aula/turma/${id}`)} type={7}>
          Planejador de Aula
        </ButtonGradient>

        <ButtonGradient type={3} onClick={() => navigate(`/lancador/avaliacao/${id}`)}>
          Lançador de MiniMAPA e MAPA
        </ButtonGradient>

        <ButtonGradient onClick={() => navigate(`/horas-trabalhadas/turma/${id}/criar`)} type={14}>
          Lançar Horas Trabalhadas para Turma
        </ButtonGradient>

        <ButtonGradient onClick={() => navigate(`/portfolio-atividades/turma/${id}`)} type={14}>
          Portfólio
        </ButtonGradient>

        {/* <ButtonGradient type={3}>Registro de MiniMAPA Impresso</ButtonGradient> */}

        {/* <ButtonGradient type={15}>Lançador de NPS</ButtonGradient> */}

        <ButtonGradient type={7} onClick={() => navigate(`/planejador-sprint/${id}`)}>
          Plano de Ensino
        </ButtonGradient>

        <ButtonGradient type={15} onClick={handleClickOccurrences}>
          Ocorrências
        </ButtonGradient>

        <ButtonGradient onClick={() => openGIP(`/estagios-de-vida/turma/${id}`)} type={3}>
          Estágios de Vida (beta)
        </ButtonGradient>

        {/* <ButtonGradient type={15}>Gravações de Aula</ButtonGradient> */}
      </ContainerButtons>
    </Layout>
  );
};

export default Home;
