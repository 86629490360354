import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    margin-bottom: 30px;

    .location-student .location-student-wrapper {
        min-height: 440px;

        .grids-container .active-container {
            /* margin-top: 5rem; */

            .skills-container {
                margin-top: 30px;
            }
        }
    }
`;

export const Header = styled.div<{type?: string}>`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${({ type }) => type === 'MAPA' ? '#6749BA' : '#FFBA95' };
    border-radius: 5px 5px 0px 0px;
    padding-left: 10px;

    h6 {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
    }
`;