import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { uuid } from 'uuidv4';
import { format } from 'date-fns';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

import Divider from '@shared/components/Divider';

import Tick from '@shared/components/CustomRecharts/elements/Tick';

import useAuth from '@shared/store/auth/hook';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import { useToast } from 'react-alicerce-components';
import { useParams } from 'react-router-dom';

import GetGradeIndividualHistoryService from '@modules/dashboards/services/GetGradeIndividualHistoryService';
import { formatIndividualLearningHistory, IFormattedIndividualLearningHistory } from '@modules/dashboards/utils/formatIndividualLearningHistory';

import { Container, ContainerTrails, CustomLineChartTooltipContainer, Trail } from './styles';

const IndividualLearningHistory: React.FC = () => {
  const [individualGradesHistory, setIndividualGradesHistory] = useState<IFormattedIndividualLearningHistory>();
  const { studentId } = useParams<{ studentId: string }>();

  const isMounted = useIsMounted();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { addToast } = useToast();

  const getGradeIndividualHistoryService = useCallback(async () => {
    if (!studentId) return;
    setIsLoading(true);
    const res = await new GetGradeIndividualHistoryService(signedUser?.token).execute(studentId);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Histórico de Aprendizagem ',
        description: 'Error ao Recuperar Histórico de Aprendizagem.',
      });
      return;
    }

    if (!isMounted()) return;

    setIndividualGradesHistory(formatIndividualLearningHistory(res));
  }, [addToast, isMounted, setIsLoading, signedUser, studentId]);

  const renderYAxis = useCallback(({ x, y, payload }) => {
    const levels = ['DESB', 'MOCH', 'NAVE', 'MERG', 'ALPI', 'AVIA', 'ASTR'];
    return <Tick y={y} x={x} dy={4} value={levels[Math.round(payload.value / 10)]} />;
  }, []);

  const renderXAxis = useCallback(({ x, y, payload }) => {
    return <Tick y={y} x={x} dy={16} value={format(new Date(payload.value), 'dd/MM')} transform="rotate(-35)" />;
  }, []);

  const dateFormatter = useCallback((date) => format(new Date(date), 'dd/MM'), []);

  useEffect(() => {
    getGradeIndividualHistoryService();
  }, [getGradeIndividualHistoryService]);

  const CustomTooltip = (props) => {
    const { active, payload } = props;

    if (active) {
      const data = payload && payload.length ? payload[0].payload : null;
      return (
        <CustomLineChartTooltipContainer color={data.color}>
          <p>{data ? format(new Date(data.date), 'dd/MM/yyyy') : ' -- '}</p>
          <p>
            {'Level : '}
            <em>{data ? data.level : ' -- '}</em>
          </p>
        </CustomLineChartTooltipContainer>
      );
    }

    return null;
  };

  const ticks = useMemo(() => {
    const t: number[] = [];
    if (individualGradesHistory) {
      individualGradesHistory.forEach((d) => d.data && d.data.map((j) => t.push(j.date)));
    }
    return t.sort((a, b) => a - b);
  }, [individualGradesHistory]);

  return (
    <Container>
      {!!individualGradesHistory && (
        <>
          <LineChart
            width={375}
            height={320}
            margin={{
              top: 5,
              right: 20,
              bottom: 5,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

            <XAxis
              dataKey="date"
              type="number"
              strokeWidth={3}
              tickFormatter={dateFormatter}
              tick={renderXAxis}
              ticks={ticks}
              domain={[(dataMin) => dataMin, (dataMax) => dataMax]}
            />

            <YAxis dataKey="level" ticks={[0, 10, 20, 30, 40, 50, 60]} tick={renderYAxis} strokeWidth={3} />

            <Tooltip content={<CustomTooltip />} />

            {individualGradesHistory?.map((trail) => (
              <Line key={uuid()} dataKey="level" data={trail?.data} stroke={trail?.color} fill={trail?.color} />
            ))}
          </LineChart>

          <Divider width="100%" />

          <ContainerTrails>
            {individualGradesHistory?.map((trail, i) => (
              <Trail key={i} ballColor={trail.color}>
                {trail.name}
              </Trail>
            ))}
          </ContainerTrails>
        </>
      )}
    </Container>
  );
};

export default IndividualLearningHistory;
