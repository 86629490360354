import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .accordion-header {
    height: 58px;
    .accordion-title {
      white-space: break-spaces;
    }
  }
`;

export const Subtitle = styled.div`
  color: #2E3A59;
  font-size: 16px;
  font-family: var(--secondary-font);
  font-weight: 700;
  margin-top: 1rem;
`;

export const CustomLegend = styled.div`
    display: grid;
    grid-template-columns: repeat(4, calc(100%/4));
    flex-wrap: wrap;
    gap: 4px;
    width: 80%;
    margin: 12px auto;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemLabel = styled.div`
    text-align: center;
    padding: 4px;
    width: 100%;
    background-color: #EDF1F7;
    color: #222B45;
    border: 1px solid #E4E9F2;

    font-size: 12px;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 4px;
    margin-bottom: 1rem;
`;


export const Item = styled.div<{color: string; labelColor?: string}>`
    height: 54px;
    width: 100%;
    background-color: ${({ color }) => color};
    color: ${({ labelColor }) => labelColor ? labelColor : '#fff'};

    font-size: 15px;
    font-weight: 800;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;

export const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  p {
    font-size: 11px;
    font-weight: 700;
    color: #2E3A59;
  }

  .container-vertical-bar-chart {
    min-height: 100%;
  } 
`;