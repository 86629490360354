import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import { Button, Checkbox, Label, Pagination, Spinner } from 'react-alicerce-components';
import { WrapperContent } from './styles';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router';
import useAuth from '@shared/store/auth/hook';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { IUser } from '@modules/users/interfaces/IUser';
import ListUsersService from '@modules/users/services/ListUsersService';
import UserCard from '@modules/users/components/UserCard';
import { useParams } from 'react-router-dom';
import { BsCheckLg } from 'react-icons/bs';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import Divider from '@shared/components/Divider';
import { LMSUSER_STATUS_CONSTRAINTS } from '../../utils/validData/validUserStatus';
import { LMSUSER_CATEGORY_CONSTRAINTS } from '../../utils/validData/validUserCategories';

interface IUserList {
  title: string;
  btnText?: string;
  pathToReturn?: string;
  pathRedirect?: string;
  canSelect?: 'one' | 'many';
  handleOnClick?: (user: any) => void;
  selectedItens?: IUser[];
  setSelectedItens?: (place: IUser[]) => void;
}

type ILmsUserFilter = {
  status?: string[];
  categories?: string[];
};

const UserList: React.FC<IUserList> = ({
  title,
  btnText,
  pathToReturn,
  pathRedirect,
  canSelect,
  handleOnClick = undefined,
  selectedItens,
  setSelectedItens,
}) => {
  const { signedUser } = useAuth();
  const navigate = useNavigate();
  const { newCancelToken } = useCancelRequest();
  const { classId } = useParams();
  const { isLoading, setIsLoading } = useGlobal();
  const [listUsers, setListUsers] = useState<IUser[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalOfPages, setTotalOfPages] = useState<number | string>(0);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [teacherSelected, setTeacherSelected] = useState<IUser[]>(selectedItens || []);
  const lmsUserFilter = useRef<ILmsUserFilter>({});

  const listLmsUsers = useCallback(
    async (page = 0) => {
      if (!signedUser) return;
      setIsLoading(true);
      const users = await new ListUsersService(signedUser.token, newCancelToken()).execute(inputSearch, {
        page,
        size: 10,
        class_id: classId,
        ...lmsUserFilter.current,
      });
      if (users === 'canceling') return;
      setIsLoading(false);
      if (users) {
        setListUsers(users.items);
        setCurrentPage(users.currentPage);
        setTotalOfPages(users.totalPages);
      }
    },
    [classId, inputSearch, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listLmsUsers();
  }, [listLmsUsers]);

  const checkSelectedPlaces = useCallback((selectedUsers: IUser[], clickedPlace: IUser): false | IUser[] => {
    const found = selectedUsers.findIndex((p) => p.id === clickedPlace.id);
    if (found === -1) return false;
    selectedUsers.splice(found, 1);
    return selectedUsers;
  }, []);

  const handleClickDiv = useCallback(
    (user: IUser) => {
      if (!canSelect) navigate(`/usuario/${user.id}/informacoes`);
      if (canSelect === 'one') {
        if (teacherSelected[0]?.id === user.id) return setTeacherSelected([]);
        const aux = [] as IUser[];
        aux.push(user);
        setTeacherSelected(aux);
      }

      if (canSelect === 'many') {
        const res = checkSelectedPlaces(teacherSelected || [], user);
        if (!res) return setTeacherSelected([...(teacherSelected || []), user]);
        setTeacherSelected([...res]);
      }
    },
    [canSelect, checkSelectedPlaces, navigate, teacherSelected, setTeacherSelected]
  );

  const handleClickBtn = useCallback(() => {
    if (canSelect === 'one' && selectedItens) {
      handleOnClick && selectedItens[0] && handleOnClick(selectedItens[0]);
    } else if (canSelect === 'many') {
      setSelectedItens && setSelectedItens(teacherSelected);
    }
    pathRedirect ? navigate(pathRedirect) : navigate(-1);
  }, [canSelect, handleOnClick, navigate, pathRedirect, selectedItens, setSelectedItens, teacherSelected]);

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objKey: string) => {
      const isChecked = event.target.checked;
      const value = event.target.id;

      if (isChecked) {
        if (!lmsUserFilter.current[objKey]) {
          lmsUserFilter.current[objKey] = [value];
          return;
        }

        lmsUserFilter.current[objKey].push(value);
      } else {
        if (!lmsUserFilter.current[objKey]) return;
        const filtered = lmsUserFilter.current[objKey].filter((foo: string) => foo !== value);
        lmsUserFilter.current[objKey] = filtered;
      }
    },
    [lmsUserFilter]
  );

  const handleResetFilter = () => {
    lmsUserFilter.current = {};
    listLmsUsers();
  };

  const isChecked = (objKey: string, value: string) => {
    if (!lmsUserFilter.current[objKey]) return false;
    return lmsUserFilter.current[objKey].find((foo: string) => foo === value);
  };

  return (
    <Fragment>
      <HeaderDirectory
        status="info"
        title={title}
        pathToReturn={pathToReturn}
        menuKebab={{
          tooltip: { view: 'UserDirectory' },
          menuBackHome: true,
        }}
      />
      {isLoading && <Spinner fixed />}
      <DirectoryContainer footer={!!handleOnClick && !!btnText}>
        <WrapperContent>
          {/* START FILTER */}
          <AdvancedFilter
            onChangeMainInput={(newValue) => setInputSearch(newValue)}
            applyFilterButtonBackground="#42aaff"
            handleResetFilter={handleResetFilter}
            handleApplyFilter={listLmsUsers}
          >
            <Label>Status:</Label>
            <section className="checkbox-section">
              {LMSUSER_STATUS_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'status');
                  }}
                  defaultChecked={isChecked('status', ag.value)}
                />
              ))}
            </section>

            <Divider width="100%" />

            <Label>Categoria:</Label>
            <section className="checkbox-section">
              {LMSUSER_CATEGORY_CONSTRAINTS.map((ag) => (
                <Checkbox
                  key={`${ag.value}`}
                  id={ag.value}
                  name={ag.value}
                  label={ag.name}
                  semiRound
                  icon={BsCheckLg}
                  hideIconUncheck
                  onChange={(event) => {
                    handleChangeCheckbox(event, 'categories');
                  }}
                  defaultChecked={isChecked('categories', ag.value)}
                />
              ))}
            </section>
          </AdvancedFilter>
          {/* END FILTER */}

          <div className="container-all-places">
            {listUsers.map((user) => (
              <UserCard key={user.id} user={user} canSelect={!!canSelect} handleOnClick={handleClickDiv} usersSelected={teacherSelected} />
            ))}
          </div>

          {!!listUsers.length && (
            <Pagination totalOfPages={totalOfPages} currentPage={currentPage} callbackGetListData={(_, page) => listLmsUsers(page)} status="info" />
          )}
        </WrapperContent>

        {btnText && (
          <PageFooter>
            <Button transform="none" status="info" shaded onClick={handleClickBtn}>
              {btnText}
            </Button>
          </PageFooter>
        )}
      </DirectoryContainer>
    </Fragment>
  );
};

export default UserList;
