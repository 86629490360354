import ProjectReportForm from '@modules/projects/components/ProjectReportForm';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import React, { useCallback, useEffect } from 'react';
import { Button } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';

const ProjectReportInfo: React.FC = () => {
  const navigate = useNavigate();
  const { getReport, reportCurrent, clearReportCreate } = useProjects();
  const { id: reportId } = useParams<{ id: string }>();
  const dirPath = `/projeto/${reportCurrent?.project_id}/steerings`

  const handleCancel = useCallback(() => {
    clearReportCreate();
    navigate(dirPath);
  }, [clearReportCreate, dirPath, navigate]);

  useEffect(() => {
    getReport(reportId)
  }, [getReport, reportId])

  return (
    <ProjectReportForm
      headerPathToReturn={dirPath}
      handleCancel={handleCancel}
      headerTitle="Report"
      disabled
      footer={
        <Button transform="none" shaded onClick={() => navigate(`/diretorio/projetos/report/${reportId}/editar`)}>
          Editar
        </Button>
      }
    />
  );
};

export default ProjectReportInfo;
