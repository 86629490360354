import React from 'react';

import { Grid, Input, Textarea } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import { IInputErrorsFormat, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';

import { useProjectReportForm } from '../useProjectReportForm';
import returnStringFunction from '@shared/utils/returnStringFunction';

interface ProjectReportInfoFragmentProps {
  disabled?: boolean;
  editing?: boolean;
  errors?: IInputErrorsFormat[];
}

export const ProjectReportInfoFragment: React.FC<ProjectReportInfoFragmentProps> = ({ errors, disabled, editing }) => {
  const { reportCurrent } = useProjects();

  const { handleChangeInput, handleBlur } = useProjectReportForm();

  return (
    <Grid row>
      <CustomForm status="primary">
        {!editing && (
          <Grid row>
            <Input
              disabled={disabled}
              label="Data do Report:"
              name="report_date"
              type="date"
              onBlur={(e) => handleBlur('report_date', e.target.value)}
              onChange={handleChangeInput}
              required
              defaultValue={returnStringFunction(reportCurrent?.report_date)}
              useTooltipError={false}
              error={errorMessageBuilder('report_date', errors)}
            />
          </Grid>
        )}

        <Grid row mt4={!editing}>
          <Textarea
            disabled={disabled}
            name="description"
            label="Descrição do Report:"
            placeholder="Adicionar Descrição do Report"
            onChange={handleChangeInput}
            required
            value={reportCurrent?.description}
            rows={25}
            error={errorMessageBuilder('description', errors)}
          />
        </Grid>
      </CustomForm>
    </Grid>
  );
};
