import React from 'react'
import { IInputRadioProps, Radio } from 'react-alicerce-components'
import { Container, RadioOptionProps } from './styles'

type RadioOptionComponentProp = IInputRadioProps & RadioOptionProps & { hideRadio?: boolean, children?: any }

export const RadioOption = (props: RadioOptionComponentProp) => {
    const { radioWidth, optionColor, hideRadio, children, ...rest } = props

    return !hideRadio ? (
        <Container radioWidth={radioWidth} optionColor={optionColor}>
            <Radio {...rest} />
        </Container>
    ) : (
        <Container radioWidth={radioWidth} optionColor={optionColor}>
            {children}
        </Container>
    )
}