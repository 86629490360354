import React from 'react';
import { FiX } from 'react-icons/fi';

import { RemoveOptionWrapperContainer } from './styles';

interface IRemoveOptionWrapper {
  handleDeleteCallback?: () => void;
}

const RemoveOptionWrapper: React.FC<IRemoveOptionWrapper> = ({ children, handleDeleteCallback }) => {
  return (
    <RemoveOptionWrapperContainer>
      {children}
      {handleDeleteCallback && (
        <div className="remove" onClick={handleDeleteCallback}>
          <FiX color="#FFF" />
        </div>
      )}
    </RemoveOptionWrapperContainer>
  );
};

export { RemoveOptionWrapper };
