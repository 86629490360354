import styled, { css } from 'styled-components';

type ConteinerProps = {
  isEdit: boolean;
};

export const Container = styled.div<ConteinerProps>`
  ${({ isEdit }) => css`
    display: grid;
    grid-template-columns: ${isEdit ? '100%' : '50% 50%'};
    grid-row-gap: 2rem;
    align-items: center;
    padding: 1rem;
    background-color: #edf1f7;
    margin-top: 4rem;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: -25%;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 2px;
      background-color: #482b85;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: ${isEdit ? '33%' : '70%'};
        min-width: 100px;
        padding: 1rem 0;
        margin: auto;
        border-radius: 16px;
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 700;

        i {
          display: flex;
          align-items: center;
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      .button-edit {
        min-width: 210px;
      }

      .cancel-button {
        background: none;
        border: 3px solid #482b85;
        color: #482b85;
        i svg {
          fill: #482b85;
        }
      }
    }
  `}
`;

export const Box = styled.div<{ value?: string; borderColor?: string; bgColor?: string }>`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';

    margin: 4px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid ${({ bgColor, borderColor }) => borderColor || bgColor};
    background: ${({ bgColor }) => bgColor};
  }

  &::after {
    content: '${({ value }) => value}';
    font-size: 12px;
    font-weight: 700;
    color: #222b45;
  }
`;
