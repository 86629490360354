import { styled } from "react-alicerce-components";

export const Container = styled.div`
    .input-container > div input::placeholder {
        font-weight: 500;
    }
`

export const FilterContainer = styled.div`
    margin-top: -1px;
    border: 1px solid #C5CEE0;
    border-radius: 0px 0px 8px 8px;

    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        padding: 20px 16px;
        position: relative;

        h6 {
            font-weight: 800;
            font-size: 16px;
            color: #2E3A59;
        }

        label {
            font-weight: 700;
            font-size: 12px;
            color: #2E3A59;
            text-transform: none;
            font-family: var(--secondary-font);
        }

        .close {
            position: absolute;
            top: 20px;
            right: 16px;
            font-size: 1rem;
            color: #2E3A59;
        }

        .input-container {
            margin-top: 20px;
        }

        .input-container > div input {
            font-weight: 600;
            color: #C5CEE0; 
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-top: 1px solid #C5CEE0;
    }
`