import React, { useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';

import { IActivityScheduleGrid, IPlannedActivityGrid, useScheduleGrid } from '../../../context/ScheduleGridContext';
import ActivityCard from '../ActivityCard';

import { Container, ContainerActivityCardInTray } from './styles';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import DeletePlannedActivityService from '../../../services/DeletePlannedActivityService';
import { isDailyClassEditDisable } from '../../../utils/isDailyClassEditDisable';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { Draggable } from '@fullcalendar/interaction';

const ActivitiesTray: React.FC = () => {
  const { plannedActivities, setPlannedActivities, dailyClass } = useScheduleGrid();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(!plannedActivities?.in_tray || plannedActivities.in_tray.length === 0 ? false : true);
  const [isDraging, setIsDraging] = useState<boolean>(false);

  const isDragDisabled = isDailyClassEditDisable({ dailyClass })
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) body.style.overflowY = isDraging ? 'hidden' : 'auto';
  }, [isDraging]);

  useEffect(() => {
    if (isDragDisabled) return
    new Draggable(document.getElementById('external-events') as HTMLElement, {
      itemSelector: '.fc-event',
      appendTo: document.getElementById('model') as HTMLElement,
      eventData: function (eventEl) {
        const planned_activity_data = JSON.parse(`${eventEl.dataset?.planned_activity_data}`);
        const suggestedDuration = planned_activity_data.planned_activity.activity_data.duration;

        return {
          ...eventEl,
          duration: suggestedDuration ? suggestedDuration : '00:05:00',
          title: eventEl.dataset?.name || eventEl.innerText,
          id: eventEl.dataset.id,
          planned_activity_data: planned_activity_data,
        };
      },
    });
  }, [isDragDisabled]);

  const handleOnDragStart = useCallback(() => {
    if (isDragDisabled) return
    setIsDraging(true);
    setIsOpen(false);
  }, [isDragDisabled]);

  const handleOnDragEnd = useCallback(() => {
    if (isDragDisabled) return
    setIsDraging(false);
  }, [isDragDisabled]);

  const removeCardFromTray = useCallback(
    async (index: number, activity: IActivityScheduleGrid) => {
      setIsLoading(true);
      const res = await new DeletePlannedActivityService(signedUser?.token).execute(`${activity.planned_activity_id}`);
      setIsLoading(false);
      if (!res) return console.log(`Error ao deletar atividade planejada`);

      setPlannedActivities((oldState: IPlannedActivityGrid) => {
        oldState['in_tray'] = removeItemFromArrayByIndex(index, oldState['in_tray']);
        return { ...oldState };
      });
    },
    [setIsLoading, setPlannedActivities, signedUser]
  );

  const handleOnClickTray = useCallback((event) => {
    setIsOpen((oldState) => !oldState);
  }, []);

  return (
    <Container id="external-events" isOpen={isOpen} onClick={(event) => handleOnClickTray(event)}>
      <div className="activities-tray-message">
        {plannedActivities?.in_tray && plannedActivities?.in_tray?.length > 0
          ? 'Clique no card e segure para adiciona-lo ao calendário'
          : 'Não existem atividades na bandeja'}
      </div>

      {plannedActivities?.in_tray &&
        plannedActivities?.in_tray.map((activity, i) => (
          <ContainerActivityCardInTray className="container-activity-card-in-tray" key={`${activity.activity_id}-${i}`}>
            <ActivityCard
              onMouseDown={handleOnDragStart}
              className="fc-event"
              key={`${activity.activity_id}-${i}`}
              activity={activity}
              onDragEnd={handleOnDragEnd}
              onTouchStart={handleOnDragStart}
              onTouchEnd={handleOnDragEnd}
              draggable
            />
            {!isDragDisabled && <div className="remove-card" onClick={() => removeCardFromTray(i, activity)}>
              <Icon name="close" fill="#FFFFFF" />
            </div>}
          </ContainerActivityCardInTray>
        ))}
      <div id="model" />
    </Container>
  );
};

export default ActivitiesTray;
