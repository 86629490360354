import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { objectToQuery } from '@shared/utils/objectToQuery';
import ApiFactory from '@shared/factories/ApiFactory';
import { IStudent } from '../interfaces/IStudent';

class GetStudentService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute({studentId, ...options}): Promise<IStudent | false> {
    try {
      const searchParams = objectToQuery(options, true);
      const res = await this.api.get(`/student/${studentId}?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetStudentService;
