import IRoute from '@shared/interfaces/IRoute';

import { LearningTargetsProvider } from '@modules/learning-targets/context/LearningTargetsContext';
import { LearningTargetsTrails } from '@modules/learning-targets/views/LearningTargetsTrails';
import { LearningTargetsClassesSelection } from '@modules/learning-targets/views/LearningTargetsClassesSelection';

import { combineContext } from '@shared/utils/combineContext';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';

import { classesKeys } from '@modules/classes/keys';
import { studentsKeys } from '@modules/students/keys';

const routes: IRoute[] = [
  {
    path: '/metasdeaprendizagem/contexto/turmas',
    key: 'LEARNING_TARGETS_CONTEXT_SELECTION_CLASSES_PATH',
    exact: true,
    component: LearningTargetsClassesSelection,
    permissions: [classesKeys.CLASSES_READ],
  },
  {
    path: '/metasdeaprendizagem/selecao-trilha/:classId',
    key: 'LEARNING_SELECTION_PATH_PATH',
    exact: true,
    component: LearningTargetsTrails,
    permissions: [studentsKeys.STUDENTS_READ],
  },
];

const providers = [LearningTargetsProvider];

export default {
  key: 'LEARNING_TARGETS_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
