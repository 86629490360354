import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  .pagination-content .pagination-content-icon {
    background-color: #85abf8;
  }

  .container-page-footer button {
    border-color: #85abf8;
    background: #85abf8;
  }

  .sprint-card-list {
    gap: 30px;
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 40px;
  }

  .directory-container-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .input-modifier {
    width: 100%;
  }
`;

export const InputDateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 14px;
  margin-top: 1rem;

  input {
    all: unset;
    font-weight: 700;
    height: 40px;
    box-sizing: border-box;
    padding: 5px;
    background: #f7f9fc;
    border: 2px solid #e4e9f2;
    border-radius: 5px;
    color: #c5cee0;
  }
`;
