import { styled } from 'react-alicerce-components';

export const CommentsContainer = styled.div`
  .comment-user-name-container {
    position: relative;
    p {
      position: absolute;
      right: 0;
      font-size: 12px;
      color: #2e3a59;
      font-weight: 700;
    }
  }
`;
