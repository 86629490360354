import React, { useCallback, useMemo } from 'react';
import UserList from '@modules/users/components/UsersList';
import { IUser } from '@modules/users/interfaces/IUser';
import { useOccurrences } from '@modules/occurrences/context/OccurrencesContext';

const OccurrenceUsersRelationCreate: React.FC = () => {
  const { occurrenceCurrent, setOccurrenceCurrent } = useOccurrences();

  const setSelectedItens = useCallback(
    (users: IUser[]) => {
      setOccurrenceCurrent((oldState: any) => {
        return { ...oldState, lms_users: users };
      });
    },
    [setOccurrenceCurrent]
  );

  const selectedItens = useMemo(() => {
    if (!occurrenceCurrent) return;
    return occurrenceCurrent.lms_users;
  }, [occurrenceCurrent]);

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelect={'many'}
      handleOnClick={setSelectedItens}
    />
  );
};

export default OccurrenceUsersRelationCreate;
