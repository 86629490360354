import React from 'react';
import { useParams } from 'react-router-dom';

import LayoutStudentApplication from '@modules/applications/components/LayoutStudentApplication';

import StudentApplicationListManager from '@modules/applications/components/StudentApplicationListManager';

const StudentPedingApplication: React.FC = () => {
  const { studentId } = useParams();

  return (
    <LayoutStudentApplication openTab={0}>
      <StudentApplicationListManager status="PENDING" filters={['type', 'trails', 'applied_between']} studentIds={[studentId as string]} />
    </LayoutStudentApplication>
  );
};

export default StudentPedingApplication;
