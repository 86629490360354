class LocalStorageService {
    public create(key: string, data: object ) {
        const encryptedData = btoa(encodeURIComponent(JSON.stringify(data)));
        localStorage.setItem(key, encryptedData);
    }

    public recover(key: string) {
        const encryptedData = localStorage.getItem(key);
        if(!encryptedData)
            return false;

        return JSON.parse(decodeURIComponent(atob(encryptedData)));
    }

    public remove(key: string) {
        localStorage.removeItem(key);
    }
}

export default new LocalStorageService();