import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Pagination, Input, Spinner } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { IWorkGroup } from '@modules/work-group/interfaces/IWorkGroup';
import { WorkGroupCard } from '@modules/work-group/components/WorkGroupCard';
import Icon from 'react-eva-icons';
import { IWorkGroupReponse, ListWorkGroupsWithSkillsService } from '@modules/work-group/services/ListWorkGroupsWithSkillsService';
import { WorkGroupListComponentContainer } from './styles';
import { useParams } from 'react-router-dom';

const WorkGroupListComponent: React.FC = () => {
  const [workGroups, setWorkGroups] = useState<IWorkGroupReponse>();
  const { signedUser } = useAuth();
  const { setIsLoading, isLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { classId } = useParams<{ classId: string }>();

  const isMounted = useIsMounted();

  const listWorkGroups = useCallback(
    async (search: string = '', page: number = 0) => {
      setIsLoading(true);
      const res = await new ListWorkGroupsWithSkillsService(signedUser?.token, newCancelToken()).execute(search, {
        page,
        size: 5,
        class_id: classId,
      });
      if (!res) throw new Error('Error ao Listar Work Groups');
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!isMounted()) return;
      setWorkGroups(res);
    },
    [classId, isMounted, newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listWorkGroups();
  }, [listWorkGroups]);

  return (
    <WorkGroupListComponentContainer>
      {isLoading && <Spinner fixed />}
      <Input
        placeholder="Buscar"
        name="search"
        end={<Icon name="funnel" fill="#8F9BB3" />}
        onChange={(event) => listWorkGroups(event.target.value)}
      />

      {workGroups && (
        <Fragment>
          <div className="work-group-card-list">
            {workGroups.items.map((workGroup: IWorkGroup, index: number) => (
              <WorkGroupCard workGroup={workGroup} key={workGroup.id} />
            ))}
          </div>

          <Pagination
            status="secondary"
            totalOfPages={workGroups.totalPages}
            currentPage={workGroups.currentPage}
            callbackGetListData={listWorkGroups}
          />
        </Fragment>
      )}
    </WorkGroupListComponentContainer>
  );
};

export { WorkGroupListComponent };
