import React, {
    useCallback,
    useState,
    useMemo
} from 'react';

import Icon from 'react-eva-icons';
import { useToast } from "react-alicerce-components";

import Tag, { ITagProps } from '../Tag';

import { useGlobal } from "@shared/contexts/global/GlobalContext";
import useAuth from "@shared/store/auth/hook";
import useCancelRequest from "@shared/hooks/useCancelRequest";
import { useTagsList } from '../TagsList/context/TagsListContext';

import ITag from '@modules/tags/interfaces/ITag';

import ListTagsService from "@modules/tags/services/ListTagsService";  

import {
    Container,
    ContainerExpand,
    ContainerIcon
} from './styles';

export type ITagListItemProps = ITagProps & {
    overideOnTagClick?: (tag: ITag) => void;
    handleOnClickExpandIcon?: (tag?: ITag) => void;
}

const TagListItem: React.FC<ITagListItemProps> = ({
    tag,
    overideOnTagClick,
    handleOnClickExpandIcon,
    ...rest
}) => {

    const { signedUser } = useAuth();
    const { newCancelToken } = useCancelRequest();
    const { setIsLoading } = useGlobal();
    const { addToast } = useToast();
    
    const { canSelect, canQuantitySelect, tagsSelected, changeTagsSelected } = useTagsList();
    const [isShowChild, setIsShowChild] = useState<boolean>(false);
    const [child, setChild] = useState<ITag[]>([]);
    
    const iamSelected = useMemo(() => !!tagsSelected.find((t) => Number(t.id) === Number(tag.id)), [tagsSelected, tag]);

    const handleClickExpandIcon = useCallback(async () => {
        if (!child.length && signedUser) {

            setIsLoading(true);

            const res = await new ListTagsService(
                signedUser.token,
                newCancelToken()
            ).execute('', { parent_id: tag.id, size: 9999 });
            if (res === 'canceling') return;

            setIsLoading(false);

            if (res) {
                setChild(res.items);
            }
        }

        setIsShowChild((oldState) => !oldState);
        handleOnClickExpandIcon && handleOnClickExpandIcon(tag);

    }, [
        tag, 
        child, 
        signedUser, 
        newCancelToken,
        setIsLoading,
        setIsShowChild,
        handleOnClickExpandIcon
    ]);

    const handleClickTag = useCallback((selectedTag: ITag) => { 

        if(overideOnTagClick) {
            return overideOnTagClick(selectedTag);
        }

        const foundIndex = tagsSelected.findIndex((t) => t.id === selectedTag.id);

        if (foundIndex !== -1) {
            tagsSelected.splice(foundIndex, 1);
            changeTagsSelected([...tagsSelected]);
            return;
        }
    
        if (!canSelect && canQuantitySelect > 0) {
            addToast({
                status: "danger",
                title: "Tag",
                description: `Você pode adicionar apenas ${canQuantitySelect} tag${canQuantitySelect > 1 ? 's' : ''}.`,
            });
            return;
        }  

        if (canSelect && foundIndex === -1) {
            changeTagsSelected([...tagsSelected, selectedTag]);
        }

      }, [
          canSelect,
          canQuantitySelect,
          tagsSelected,
          addToast,
          changeTagsSelected,
          overideOnTagClick
        ]);

    return (
        <Container>
            <ContainerExpand>
                {tag.has_childs && 
                    <ContainerIcon 
                        isShow={isShowChild}
                        onClick={handleClickExpandIcon}
                    >
                        <Icon name='chevron-right' />
                    </ContainerIcon>
                }
                <Tag
                    tag={tag} 
                    handleOnClick={() => handleClickTag(tag)}
                    isSelected={!!iamSelected}
                    {...rest}
                />
            </ContainerExpand>

            <div className="container-child-tag">
                {isShowChild && child.map(childTag => {
                    return (
                        <TagListItem
                            key={childTag.id}
                            tag={childTag}
                            clickable={canSelect}
                            overideOnTagClick={overideOnTagClick}
                        />
                    )
                })}
            </div>
        </Container>
    );
}

export default TagListItem;