import React, { useState, useCallback } from 'react';
import Icon from 'react-eva-icons';

import { Input, Spinner } from 'react-alicerce-components';

import IClass from '@modules/classes/interfaces/IClass';
import ClassesSelection from '@shared/components/ClassesSelection';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { useNavigate } from 'react-router-dom';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';

const PortfoliosClassesSelection: React.FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const { isLoading } = useGlobal();

  const handleClickClassCard = useCallback(
    (classClicked: IClass) => {
      navigate(`/portfolio-atividades/turma/${classClicked.id}`, { state: { pathToReturn: '/portfolio-atividades/contexto/turmas' } });
    },
    [navigate]
  );

  const filterClassesActivities = useCallback((search: string) => {
    setSearchValue(search);
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Seleção de Contexto"
        status="basic"
        pathToReturn="/"
        backgroundHeader="#8F9BB3"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'ActivitiesPortfolioClassesSelection' },
        }}
      />
      <DirectoryContainer footer={false}>
        <div style={{ marginBottom: '2rem' }}>
          <Input
            placeholder="Buscar"
            name="search"
            end={<Icon name="funnel" fill="#8F9BB3" />}
            onChange={(event) => filterClassesActivities(event.target.value)}
          />
        </div>
        <ClassesSelection handleOnClick={handleClickClassCard} searchValue={searchValue} />
      </DirectoryContainer>
    </>
  );
};

export default PortfoliosClassesSelection;
