import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import ApiFactory from '@shared/factories/ApiFactory';
import { IGroupFrenquencyPercent } from '../interfaces/IGroupFrenquencyPercent';

class GetClassFrequencyPercentService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(classId: string | number): Promise<IGroupFrenquencyPercent[] | false> {
    try {
      const res = await this.api.get(`/dashboard/attendance/class/percentage/${classId}`);
      console.log(res.data);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default GetClassFrequencyPercentService;
