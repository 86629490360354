import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import { IAccordionProps } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';

export type IStyledAccordion = IAccordionProps & {
  status?: ElementStatus;
  isOpen?: boolean;
  colorHeader?: string;
};

export const AccordionMessageError = styled.label`
  color: red !important;
`;

export const Container = styled.div<IStyledAccordion>`
  margin: 1.6rem auto;

  > div:first-of-type {
    box-shadow: none;
    border-radius: 6px;
  }

  .accordion-header {
    z-index: 1;
    position: relative;
    border-radius: 6px;
    background-color: ${({ status }) => themeGet(`colors.${status}100`)};
    background-color: ${({ colorHeader }) => colorHeader};
    display: flex;
    justify-content: space-between;

    > div:first-child {
      max-width: 50%;
      width: 50%;
    }
  }

  .accordion-content {
    max-height: ${(p) => (p.isOpen ? ` 10000px` : `0px`)};
    display: ${(p) => (p.isOpen ? ` block` : `none`)};
    overflow: unset;
  }

  .accordion-title {
    color: ${themeGet(`colors.basic800`)};
    font-weight: 600;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .styled-icon {
    margin-top: 4px;

    i svg {
      width: 40px;
      height: 40px;
      fill: ${themeGet(`colors.basic700`)};
    }
  }
`;
