import * as Yup from 'yup';
import { CATEGORY_VALUES } from './validData/validGroupCategory';
import { GROUP_STATUS_CONSTRAINTS } from './validData/validGroupStatus';

const STATUS_GROUP = GROUP_STATUS_CONSTRAINTS.map((a) => a.value);

export const groupSchema = Yup.object().shape({
  name: Yup.string().required({ name: 'name', message: 'O campo é obrigatório' }),
  category: Yup.string()
    .oneOf(CATEGORY_VALUES, {
      name: 'category',
      message: `Campo deve ser ${CATEGORY_VALUES}`,
    })
    .required({ name: 'category', message: 'O campo é obrigatório' }),
  status: Yup.string()
    .oneOf(STATUS_GROUP, { name: 'status', message: 'Campo inválido' })
    .required({ name: 'status', message: 'O campo é obrigatório' })
    .typeError({ name: 'status', message: 'O campo é obrigatório' }),
  parent_id: Yup.number().optional(),
  place_ids: Yup.array().of(Yup.number()).optional(),
  tag_ids: Yup.array().of(Yup.number()).optional(),
  lms_user_ids: Yup.array().of(Yup.number()).optional(),
});
