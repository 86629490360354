import React from 'react';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';

import IndividualFrequencyPercent from '@modules/dashboards/components/IndividualFrequencyPercent';
import IndividualFrequencyHistory from '@modules/dashboards/components/IndividualFrequencyHistory';

import { Container } from './styles';

const IndividualFrequency: React.FC = () => {
  const { isLoading } = useGlobal();

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title="Frequência Individual"
        backgroundHeader='#8F9BB3'
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'IndividualFrequency' },
        }}
      />
      <CustomTabs>
        <Tabs>
          <Tab title="PERCENTUAL">
            <IndividualFrequencyPercent />
          </Tab>
          <Tab title="HISTÓRICO">
            <IndividualFrequencyHistory />
          </Tab>
        </Tabs>
      </CustomTabs>
    </Container>
  );
};

export default IndividualFrequency;
