import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, useToast } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import { InvalidateApplicationService } from '@modules/applications/services/InvalidateApplicationService';
import ConfirmModalJustification from '@modules/applications/components/ConfirmModalJustification';
import GetApplicationChartService from '@modules/applications/services/GetApplicationChartService';
import { IApplicationChart } from '@modules/applications/interfaces/IApplicationChart';
import LayoutApplication from '@modules/applications/components/LayoutApplication';
import StudentGrade from '@modules/applications/components/StudentGrade';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';

import { Container } from './styles';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { applicationInvalidateSchema } from '@modules/applications/utils/applicationInvalidateSchema';

const ApplicationGrades: React.FC = () => {
  const [studentWithGradeApplication, setStudentWithGradeApplication] = useState<IApplicationChart>();
  const justificationRef = useRef<{ invalidation_reason: string }>({ invalidation_reason: '' });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);

  const { newCancelToken } = useCancelRequest();
  const { applicationId, studentId, classId } = useParams();
  const { setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const isMounted = useIsMounted();

  const getApplicationChart = useCallback(async () => {
    setIsLoading(true);
    if (!applicationId) return;
    const res = await new GetApplicationChartService(signedUser?.token, newCancelToken()).execute(applicationId);
    setIsLoading(false);
    if (!!!res || !isMounted()) return;
    if (!res) return;

    setStudentWithGradeApplication(res);
  }, [applicationId, isMounted, newCancelToken, setIsLoading, signedUser]);

  const invalidateApplication = useCallback(async () => {
    let formErros = await beforeSubmitForm(justificationRef.current, applicationInvalidateSchema);
    setErrors(formErros);
    console.log(formErros, 'formErros');
    if (formErros.length !== 0) return;
    setIsModalOpen(false);
    setIsLoading(true);
    const res = await new InvalidateApplicationService(signedUser?.token).execute(Number(applicationId), justificationRef.current);
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Aplicaçōes',
        description: 'Error ao invalidar Aplicação.',
      });
      return navigate(`/aplicacoes/turma/${classId}/aluno/${studentId}/finalizado`);
    }

    addToast({
      status: 'success',
      title: 'Aplicaçōes',
      description: 'Aplicação invalidada com sucesso.',
    });

    navigate(`/aplicacoes/turma/${classId}/aluno/${studentId}/finalizado`);
  }, [addToast, applicationId, classId, navigate, setIsLoading, signedUser, studentId]);

  useEffect(() => {
    getApplicationChart();
  }, [getApplicationChart]);


  console.log(' ~ studentWithGradeApplication', studentWithGradeApplication);
  return (
    <LayoutApplication openTab={1} footer>
      <Container>
        {studentWithGradeApplication && <StudentGrade applicationData={studentWithGradeApplication} />}

        <PageFooter>
          <Button
            disabled={!!studentWithGradeApplication?.application_data.application.invalidationReason}
            status="quaternary"
            transform="none"
            noBorder
            shaded
            onClick={() => setIsModalOpen(true)}
          >
            Solicitar Invalidação
          </Button>
        </PageFooter>
      </Container>

      {isModalOpen && (
        <ConfirmModalJustification
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={isModalOpen}
          closeCallback={() => setIsModalOpen(false)}
          subTitle={'Justifique a Solicitação'}
          title="Tem certeza que deseja solicitar a invalidação de uma aplicação?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={invalidateApplication}
          justification={(event) => (justificationRef.current.invalidation_reason = event)}
          errors={errors}
        />
      )}
    </LayoutApplication>
  );
};

export default ApplicationGrades;
