import React from 'react';

import {
  Button,
  Grid,
  Spinner,
  Tab,
  Tabs,
} from 'react-alicerce-components';

import { useContracts } from '@modules/contracts/context/ContractContext';

import { IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { ContractInfoFragment } from './partials/ContractInfoFragment';
import { ContractProjectsFragment } from './partials/ContractProjectsFragment';

import { ContractDefaultStylesContainer } from '../ContractDefaultStyles/styles';
import { ContractFormContainer } from './styles';

interface ContractFormProps {
  headerTitle: string;
  headerPathToReturn: string;
  pathAddProjects: string;
  errors: IInputErrorsFormat[];
  handleCancel: () => void;
  deleteCallback?: () => void;
  handleConfirmButton: () => Promise<void>;
}

const ContractForm: React.FC<ContractFormProps> = ({
  headerTitle,
  headerPathToReturn,
  pathAddProjects,
  errors,
  handleCancel,
  deleteCallback,
  handleConfirmButton,
}) => {
  const { contractCurrent, clearContractCreate } = useContracts();

  const { isLoading } = useGlobal();

  console.log(' ~ contractCurrent', contractCurrent);
  return (
    <ContractDefaultStylesContainer>
      <ContractFormContainer>
        {isLoading && <Spinner />}
        <HeaderDirectory
          title={headerTitle}
          pathToReturn={headerPathToReturn}
          onClickReturn={clearContractCreate}
          menuKebab={{ deleteCallback }}
        />
        <DirectoryContainer footer={true}>
          <CustomTabs footer>
            <Tabs horizontalScroll>
              <Tab title="Informação">
                <Grid container>
                  <ContractInfoFragment errors={errors} />
                </Grid>
              </Tab>
              <Tab title="Turmas">
                <Grid container>
                  <ContractProjectsFragment pathAddProjects={pathAddProjects} />
                </Grid>
              </Tab>
            </Tabs>
          </CustomTabs>
          <PageFooter>
            <Button
              className='btn-cancel'
              transform="none"
              onClick={handleCancel}
              shaded
              outline
            >
              Cancelar
            </Button>
            <Button
              transform="none"
              shaded
              noBorder
              onClick={handleConfirmButton}
            >
              Salvar
            </Button>
          </PageFooter>
        </DirectoryContainer>
      </ContractFormContainer>
    </ContractDefaultStylesContainer>
  );
};

export default ContractForm;
