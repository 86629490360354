import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import IPaginatedResponse from '@shared/interfaces/IPaginatedResponse';
import { objectToQuery } from '@shared/utils/objectToQuery';
import ITag from '@modules/tags/interfaces/ITag';
import IListOptions from '@shared/interfaces/IListOptions';

export interface IListTagsReponse extends IPaginatedResponse {
  items: ITag[];
}

interface IListTagsOptions extends IListOptions {
  parent_id?: string;
  type?: string;
  node?: number;
}

class ListTagsService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(query?: string, options?: IListTagsOptions): Promise<IListTagsReponse | false | 'canceling'> {
    try {
      let searchParams = objectToQuery({
        query,
        ...options,
      });

      const res = await this.api.get(`/tag?${searchParams}`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return 'canceling';
      } else {
        console.error(`Error: ${error}`);
        return false;
      }
    }
  }
}

export default ListTagsService;
