import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from '@modules/projects/components/Layout';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';

import { TabPicker } from '@shared/components/TabPicker';
import ProjectReports from '../ProjectReports';
import ProjectSteerings from '../ProjectSteerings';

const ProjectReportsSteerings: React.FC = () => {
  const { projectCurrent, getProject } = useProjects();
  const [openTab, setOpenTab] = useState<'steering' | 'report'>();
  const [selectedTab, setSelectedTab] = useState<string>(openTab ? openTab : 'steering');
  const isExecution = projectCurrent?.status === 'execution';
  const { id: projectId } = useParams<{ id: string }>();

  const handleChangeTab = useCallback(
    (tabToRender: string) => {
      if (tabToRender === 'steering' || tabToRender === 'report') {
        setOpenTab(undefined);
        setSelectedTab(tabToRender);
      }
    },
    [setOpenTab]
  );

  const renderedComponent = useMemo(() => {
    if (selectedTab === 'steering') return <ProjectSteerings />
    return <ProjectReports />
  }, [selectedTab])

  useEffect(() => {
    getProject(projectId);
  }, [getProject, projectId]);

  return (
    <Layout openTab={3} footer>
      <ProjectDefaultStylesContainer>
        {isExecution && (
          <TabPicker
            tabs={[
              { value: 'steering', label: 'Steerings' },
              { value: 'report', label: 'Reports' },
            ]}
            selectedTab={selectedTab}
            onTabChange={handleChangeTab}
          />
        )}
        {renderedComponent}
      </ProjectDefaultStylesContainer>
    </Layout>
  );
};

export default ProjectReportsSteerings;
