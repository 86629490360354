import { EventInput } from '@fullcalendar/react';
import { DateHelper } from '@shared/helpers/DateHelper';
import { IEvent } from '../interfaces/IEvent';

export const formatEventsForFullCalendar = (events: IEvent[]): any => {
  const formatted: EventInput = [];
  events.forEach((e: IEvent) => {
    const start = new Date(`${e.start_date}T${e.start_time}`);
    const end = new Date(`${e.end_date}T${e.end_time}`);

    const doesEventRepeat = e.week_days && e.week_days.length > 0;
    const newEvent = {
      id: e.id,
      title: e.name,
      description: e.description,
      type: 'event',
      data: e,
      start: start,
      end: end,
    };

    if (doesEventRepeat) {
      const weekDaysNumbers = getWeekDaysNumbers(e.week_days as string[]);

      formatted.push({
        ...newEvent,
        daysOfWeek: weekDaysNumbers,
        startRecur: start,
        endRecur: end,
        startTime: DateHelper.extractTime(start),
        endTime: DateHelper.extractTime(end),
      });
    }

    if (!doesEventRepeat) {
      formatted.push(newEvent);
    }
  });

  return formatted;
};

const getWeekDaysNumbers = (weekdays: string[]): number[] => {
  const mockWeek = {
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
  };
  return weekdays.map((_w) => mockWeek[_w]);
};
