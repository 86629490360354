import React, { useCallback, Fragment, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Button, useToast, Spinner } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';
import { useActivitiesPortfolio } from '@modules/activities-portfolio/context/ActivitiesPortfolioContext';
import { ActivityPortfolioForm } from '../../components/ActivityPortfolioForm';
import Icon from 'react-eva-icons';
import ConfirmModal from '@shared/components/ConfirmModal';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { portfolioActivitySchema } from '../../utils/portfolioActivitySchema';
import { formatPortfolioActivity } from '../../utils/formatPortfolioActivity';
import { IFormatedPortfolioActivity } from '../../interfaces/IFormatedPortfolioActivity';
import UploadService from '@shared/services/UploadFile';
import { CreatePortfolioActivityService } from '../../services/CreatePortfolioActivityService';
import { IAttachment } from '@shared/interfaces/IAttachment';

const ActivitiesPortfolioCreate: React.FC = () => {
  const { activityPortfolioCurrent, clearPortfolioActivityCurrent } = useActivitiesPortfolio();
  const { classId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { isLoading, setIsLoading } = useGlobal();

  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formatedBody, setFormatedBody] = useState<IFormatedPortfolioActivity>();

  const handleCancel = useCallback(() => {
    clearPortfolioActivityCurrent();
    navigate(`/portfolio-atividades/turma/${classId}`);
  }, [classId, clearPortfolioActivityCurrent, navigate]);

  const canSaveForm = useCallback(async () => {
    if (!activityPortfolioCurrent) return;
    const formatedBody = formatPortfolioActivity(activityPortfolioCurrent) as IFormatedPortfolioActivity;
    
    let formErros = await beforeSubmitForm(formatedBody, portfolioActivitySchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    setShowModal(true);
    setFormatedBody(formatedBody);
  }, [activityPortfolioCurrent]);

  const handleSavePortfolioActivity = useCallback(async () => {
    if (!formatedBody || !signedUser || !classId) return;
    setShowModal(false);
    setIsLoading(true);
    let formattedAttachmentsToSave: IAttachment[] = [];

    const newFiles = activityPortfolioCurrent.attachments?.flatMap((fts: any) => (fts.url ? [] : fts)) as File[];
    if (newFiles && newFiles.length > 0) {
      const uploadService = new UploadService(signedUser?.token);
      const formattedAttachments = uploadService.formatedFileUpload(newFiles);
      const res = await uploadService.uploadFile(newFiles, 'activities-portfolio/attachments');
      if (!res) {
        setIsLoading(false);
        addToast({
          status: 'danger',
          title: 'Upload',
          description: 'Erro ao fazer upload.',
        });
        return;
      }
      formattedAttachmentsToSave = formattedAttachments.map((oldState, i) => ({ ...oldState, url: res[i].dbURL }));
    }

    let auxFormattedBody = { ...formatedBody };
    if (formattedAttachmentsToSave.length > 0)
      auxFormattedBody = { ...auxFormattedBody, attachments: formattedAttachmentsToSave } as IFormatedPortfolioActivity;

    const { success, data, errorMessage } = await new CreatePortfolioActivityService(signedUser?.token).execute(auxFormattedBody, classId);
    setIsLoading(false);

    if (!success || !data) {
      addToast({
        status: 'danger',
        title: 'Portifólio',
        description: `Error: ${errorMessage}`,
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Portifólio',
      description: 'Portifólio criado com sucesso.',
    });

    navigate(`/portfolio-atividades/${data.id}/turma/${classId}/informacoes`);
  }, [activityPortfolioCurrent.attachments, addToast, classId, formatedBody, navigate, setIsLoading, signedUser]);

  const studentsSelected = activityPortfolioCurrent?.students?.length;
  const hasAttachments = activityPortfolioCurrent?.attachments?.length || activityPortfolioCurrent?.links?.length;

  const noStudentsSelectedMessage = studentsSelected ? '' : 'Nenhum estudante foi selecionado.\n';
  const noAttachmentsMessage = hasAttachments ? '' : 'Envio de portfólio sem link ou anexo não será contabilizado nos indicadores.\n';
  const continueMessage = !!noStudentsSelectedMessage || !!noAttachmentsMessage ? 'Deseja continuar com sua entrega?' : '';

  const subtitle = `${noStudentsSelectedMessage}${noAttachmentsMessage}${continueMessage}`;
  return (
    <Fragment>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory title="Portfólio" backgroundHeader="linear-gradient(135deg, #FFBC6F 0%, #43A2E4 100%)" />

      <DirectoryContainer style={{ marginTop: '1rem', paddingTop: 0 }} footer>
        <ActivityPortfolioForm formAction="creating" errors={errors} />

        <PageFooter>
          <Button onClick={handleCancel} status="info" transform="none" noBorder shaded>
            Cancelar
          </Button>
          <Button onClick={() => canSaveForm()} status="info" transform="none" noBorder shaded>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showModal}
        closeCallback={() => setShowModal(false)}
        title="Deseja Criar a Atividade do Portifólio?"
        subTitle={subtitle}
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleSavePortfolioActivity}
      />
    </Fragment>
  );
};

export default ActivitiesPortfolioCreate;
