import React, { useCallback } from 'react';
import Icon from 'react-eva-icons';

import { Image } from 'react-alicerce-components';

import { Container, DeleteIconContainer } from './styles';
import { IAttachment } from '../../interfaces/IAttachment';

export interface IMediaAttachProps {
  media: File | IAttachment;
  handleOnDelete?: () => void;
  handleOnClick?: () => void;
  url?: string;
  onClick?: (event: Event) => void;
  isSelected?: boolean;
}

const ImageAttach: React.FC<IMediaAttachProps> = ({ url, media, handleOnDelete, handleOnClick, isSelected }) => {
  const handleDelete = useCallback(() => {
    if (handleOnDelete) {
      handleOnDelete();
    }
  }, [handleOnDelete]);

  return (
    <Container style={{ cursor: handleOnClick ? 'pointer' : 'default' }} onClick={handleOnClick}>
      {handleOnDelete && (
        <DeleteIconContainer onClick={handleDelete}>
          <Icon name="close" />
        </DeleteIconContainer>
      )}

      {media.type.includes('image' || '') && url && (
        <Image
          src={url}
          alt="foto"
          style={{
            border: isSelected ? '4px solid #F7652B' : 'none',
          }}
        />
      )}
    </Container>
  );
};

export default ImageAttach;
