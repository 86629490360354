import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';

import { Container, ContainerTrails, Header } from './styles';

import { Button, Checkbox, Label, Pagination, Spinner, useToast } from 'react-alicerce-components';

import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import PageFooter from '@shared/components/PageFooter';
import useAuth from '@shared/store/auth/hook';

import CreateApplicationService from '@modules/launchers/services/CreateApplicationsService';
import ListStudentFromClassWithApplicationService, {
  IListStudentClassWithApplicationReponse,
} from '@modules/launchers/services/ListStudentFromClassWithApplicationService';
import { formateMapaBody } from '@modules/launchers/utils/formateMapaBody';
import WrapperContent from '@modules/launchers/components/WrapperContent';
import MapaCard from '@modules/launchers/components/MapaCard';

import { mapaStatusName, trailsValues } from '@modules/launchers/utils/mapaStatusName';
import Divider from '@shared/components/Divider';
import { AdvancedFilter } from '@shared/components/AdvancedFilter';
import { formatMapaFilter } from '@modules/launchers/utils/formatMapaFilter';

import { useLaunchers } from '@modules/launchers/context/LaunchersContext';
import { ITrailItems } from '@modules/launchers/interfaces/IStudentsWithGrades';
import DeleteApplicationService from '@modules/launchers/services/DeleteApplicationService';
import GetClassService from '@modules/classes/services/GetClassService';
import IClass from '@modules/classes/interfaces/IClass';
import { ITrails } from '@shared/interfaces/ITrail';

export default interface InitialCreateApplication {
  applications: any[];
}

export interface IMapaFilter {
  status?: string[];
  trails?: number[];
  first_application?: boolean;
}

const MapaLauncher: React.FC = () => {
  const initialData = {
    applications: [],
  };

  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useGlobal();
  const { trailItems, setSelectedStudentsMapa, selectedStudentsMapa } = useLaunchers();
  const [trailItemsClassType, setTrailItemsClassType] = useState<ITrailItems[]>([]);
  const { newCancelToken } = useCancelRequest();
  const { id } = useParams<{ id: string }>();
  const { signedUser } = useAuth();
  const isMounted = useIsMounted();
  const { addToast } = useToast();
  const [classStudent, setClassStudent] = useState<IListStudentClassWithApplicationReponse>();
  const [mapaFilterState, setMapaFilterState] = useState<IMapaFilter>({});
  const [selectAllCheckBox, setSelectAllCheckBox] = useState<boolean>(false);
  const [selectTrailName, setTrailName] = useState<string | boolean>();
  const [currentClass, setCurrentClass] = useState<IClass>();

  const mapa4Tr = process.env.REACT_APP_LINK_MAPA4_TR ? process.env.REACT_APP_LINK_MAPA4_TR : null;
  const lmsUserId = Number(signedUser?.lmsUser.id);
  const classType = currentClass?.type;
  const filteredStudentApplications = classStudent?.items.map((ap) => {
    return {
      applications: ap.applications.filter((ap) => trailItemsClassType.some((id) => id.trailId === ap.trail_id)),
      student: ap.student,
    };
  });

  const getClass = useCallback(async () => {
    if (!id) return navigate(`/lancador/avaliacao/contexto/turmas`);
    setIsLoading(true);
    const res = await new GetClassService(signedUser?.token, newCancelToken()).execute(id);
    setIsLoading(false);
    if (!res) return navigate('/minhas-turmas');
    setCurrentClass(res);
  }, [id, navigate, newCancelToken, setIsLoading, signedUser]);

  const listStudentFromClassWithApplication = useCallback(
    async (search = '', page: number = 0) => {
      if (!id) return;
      const formattedFilter = formatMapaFilter(mapaFilterState);
      setIsLoading(true);
      const res = await new ListStudentFromClassWithApplicationService(signedUser?.token, newCancelToken()).execute(id, search, {
        size: 9999,
        page,
        application_type: 'MAPA',
        ...formattedFilter,
      });
      if (res === 'canceling') return;
      setIsLoading(false);
      if (!res || !isMounted()) return;
      getClass();
      setClassStudent(res);
    },
    [getClass, id, isMounted, mapaFilterState, newCancelToken, setIsLoading, signedUser]
  );

  const getTrailsByClassType = useCallback(
    (currentClassType: string, trails: ITrailItems[]) => {
      if (currentClassType === 'english') return setTrailItemsClassType(trails.filter((trail) => trail.name === 'Inglês'));
      if (currentClassType === 'core')
        return setTrailItemsClassType(
          trails.filter(
            (trail) => trail.name === 'Matemática' || trail.name === 'Redação' || trail.name === 'Hab. para vida' || trail.name === 'Leitura'
          )
        );
      return setTrailItemsClassType(trails);
    },
    [setTrailItemsClassType]
  );

  const getTrailsValuesByClassType = (currentClassType: string, trails: ITrails[]) => {
    if (currentClassType === 'english') return trails.filter((trail) => trail.name === 'Inglês');
    if (currentClassType === 'core')
      return trails.filter(
        (trail) => trail.name === 'Matemática' || trail.name === 'Redação' || trail.name === 'Hab. para vida' || trail.name === 'Leitura'
      );
    return trails;
  };

  const creteMapaLauncher = useCallback(async () => {
    const filteredApplications = {
      applications: selectedStudentsMapa.applications.filter((ap) => trailItemsClassType.some((id) => id.trailId === ap.trail_id)),
    };
    console.log(filteredApplications, 'ENVIAR PARA A BACKEND 😎');
    if (!signedUser) return;
    setIsLoading(true);
    const res = await new CreateApplicationService(signedUser.token).execute(filteredApplications);

    setSelectedStudentsMapa(initialData);
    listStudentFromClassWithApplication('', classStudent?.currentPage);

    setIsLoading(false);
    if (!res) {
      return addToast({
        status: 'danger',
        title: 'MAPA',
        description: 'Falha ao tentar ao criar o MAPA. Tente novamente.',
      });
    }
    if (!!res?.errors?.length && !res?.success?.length) {
      return addToast({
        status: 'danger',
        title: 'MAPA',
        description: `Todos os M.A.P.As selecionados já foram lançados.`,
      });
    }
    if (!!res?.success?.length && !!res?.errors?.length) {
      return addToast({
        status: 'warning',
        title: 'MAPA',
        description: `${res?.success?.length} M.A.P.As foram lançados com sucesso! Porém, ${res?.errors?.length} M.A.P.As não foram lançados.`,
      });
    }
    if (res?.success?.length > 1 && !!res?.success?.length && !res?.errors?.length) {
      return addToast({
        status: 'success',
        title: 'MAPA',
        description: 'Todos os M.A.P.As foram lançados com sucesso.',
      });
    }

    if (res?.success?.length === 1 && !!res?.success?.length && !res?.errors?.length) {
      return addToast({
        status: 'success',
        title: 'MAPA',
        description: 'M.A.P.A lançado com sucesso.',
      });
    }
  }, [
    addToast,
    classStudent,
    initialData,
    listStudentFromClassWithApplication,
    selectedStudentsMapa,
    setIsLoading,
    setSelectedStudentsMapa,
    signedUser,
    trailItemsClassType,
  ]);

  const deleteApplication = useCallback(
    async (AplicationId: string) => {
      if (!signedUser) return;
      setIsLoading(true);
      const res = await new DeleteApplicationService(signedUser?.token, newCancelToken()).execute(AplicationId);
      setSelectedStudentsMapa(initialData);
      listStudentFromClassWithApplication('', classStudent?.currentPage);

      if (res) {
        return addToast({
          status: 'success',
          title: 'MAPA',
          description: 'M.A.P.A cancelado com sucesso.',
        });
      }

      if (!res) {
        return addToast({
          status: 'danger',
          title: 'MAPA',
          description: 'Falha ao tentar cancelar M.A.P.A.',
        });
      }
      setIsLoading(false);
    },
    [addToast, classStudent, initialData, listStudentFromClassWithApplication, newCancelToken, setIsLoading, setSelectedStudentsMapa, signedUser]
  );

  const handleResetFilter = () => {
    setMapaFilterState({});
    listStudentFromClassWithApplication();
  };

  const handleChangeCheckboxFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, objFilterKey: string | number) => {
      const isChecked = event.target.checked;
      const name = event.target.name;
      const value = objFilterKey === 'trails' ? Number(event.target.id) : event.target.id;

      let auxiliar;
      if (objFilterKey === 'trails') {
        auxiliar = mapaFilterState?.trails ? mapaFilterState[objFilterKey] : [];
      }

      if (objFilterKey !== 'trails') {
        auxiliar = mapaFilterState?.status ? mapaFilterState[objFilterKey] : [];
      }

      if (name === 'Sem Aplicação') {
        if (isChecked) {
          setMapaFilterState((oldState: IMapaFilter | undefined) => {
            return { first_application: true, trails: oldState?.trails };
          });
          return;
        }

        if (!isChecked)
          setMapaFilterState((oldState: IMapaFilter | undefined) => {
            return { trails: oldState?.trails, first_application: false };
          });
        return;
      }

      if (objFilterKey === 'trails') {
        if (isChecked) {
          setMapaFilterState((oldState: IMapaFilter | undefined) => {
            return { ...oldState, [objFilterKey]: [...auxiliar, value] };
          });
        } else if (!isChecked) {
          const filtered = auxiliar.filter((aux) => aux !== value);
          setMapaFilterState((oldState: IMapaFilter | undefined) => {
            return { ...oldState, [objFilterKey]: filtered };
          });
        }
        return;
      }

      if (isChecked) {
        setMapaFilterState((oldState: IMapaFilter | undefined) => {
          return { ...oldState, [objFilterKey]: [...auxiliar, value], first_application: false };
        });
      } else if (!isChecked) {
        const filtered = auxiliar.filter((aux) => aux !== value);
        setMapaFilterState((oldState: IMapaFilter | undefined) => {
          return { ...oldState, [objFilterKey]: filtered, first_application: false };
        });
      }
    },
    [mapaFilterState]
  );

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    listStudentFromClassWithApplication();
  }, [listStudentFromClassWithApplication]);

  const selectAllTrails = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      const trailId = event.target.name;
      const trailName = event.target.id;

      if (trailName !== 'Todos') {
        if (checked) {
          setTrailItemsClassType((oldState: ITrailItems[]) => {
            const fixedObj = oldState.map((trail) => {
              if (`${trail.trailId}` === `${trailId}`) {
                let trailModieed = trail;
                trailModieed.checked = checked;
                return trailModieed;
              }
              return trail;
            });
            return fixedObj;
          });
          classStudent?.items.forEach((student) => {
            if (!currentClass) return;
            const aplicationFound = student.applications.filter((aplication) => +aplication.trail_id === +trailId);
            const finalZdp = aplicationFound[0]?.zdp_block_name;
            const formatedStudent = formateMapaBody(student.student, trailId, finalZdp, currentClass, lmsUserId);
            const applicationFoundAlreadySaved = !!selectedStudentsMapa.applications.find(
              (app) => +app.trail_id === +formatedStudent.trail_id && +app.lms_student_id === +formatedStudent.lms_student_id
            );
            if (applicationFoundAlreadySaved) return;
            setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
              return { ...oldState, applications: [...oldState.applications, formatedStudent] };
            });
          });
        }

        if (!checked) {
          setTrailItemsClassType((oldState: ITrailItems[]) => {
            const fixedObj = oldState.map((trail) => {
              if (`${trail.trailId}` === `${trailId}`) {
                let trailModieed = trail;
                trailModieed.checked = false;
                return trailModieed;
              }
              return trail;
            });
            return fixedObj;
          });
          setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
            return { ...oldState, applications: oldState.applications.filter((application) => +application.trail_id !== +trailId) };
          });
        }
      }

      if (trailName === 'Todos') {
        if (checked) {
          setTrailItemsClassType((oldState: ITrailItems[]) => {
            const newObj = oldState.map((item) => ({ ...item, checked: checked }));
            return newObj;
          });
          setSelectAllCheckBox(true);
          setTrailName(trailName);
          setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
            return { ...oldState, applications: [] };
          });
          classStudent?.items.forEach((student) => {
            if (!currentClass) return;
            student.applications.forEach((aplication) => {
              console.log(aplication, 'aplication');
              const finalZdp = aplication?.zdp_block_name;
              const trailIdAll = aplication.trail_id;
              const formatedStudent = formateMapaBody(student.student, trailIdAll, finalZdp, currentClass, lmsUserId);
              setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
                return { ...oldState, applications: [...oldState.applications, formatedStudent] };
              });
            });
          });
          return;
        }

        if (!checked) {
          setTrailItemsClassType((oldState: ITrailItems[]) => {
            const newObj = oldState.map((item) => ({ ...item, checked: checked }));
            return newObj;
          });
          setSelectAllCheckBox(false);
          setTrailName(false);
          setSelectedStudentsMapa((oldState: InitialCreateApplication) => {
            return { ...oldState, applications: [] };
          });
        }
      }
    },
    [classStudent, currentClass, lmsUserId, selectedStudentsMapa.applications, setSelectedStudentsMapa]
  );

  useEffect(() => {
    if (!classType) return;
    classType && getTrailsByClassType(classType, trailItems);
  }, [id, classType, getTrailsByClassType, trailItems]);

  useEffect(() => {
    console.log(selectedStudentsMapa, 'selectedStudentsMapa');
  }, [selectedStudentsMapa]);

  return (
    <Container>
      {isLoading && <Spinner fixed />}

      <HeaderDirectory
        title="Lançador de MAPA"
        backgroundHeader=" linear-gradient(135deg, #FFA33A 0%, #6E3FA5 100%)"
        menuKebab={{
          menuBackHome: true,
          tooltip: { view: 'MapaLauncher' },
        }}
      />
      <DirectoryContainer style={{ marginTop: '0rem', padding: 0, marginBottom: '0rem' }} footer>
        <WrapperContent>
          <div style={{ padding: '1rem' }}>
            {/* START FILTER */}
            <AdvancedFilter
              applyFilterButtonBackground="#96B6F7"
              onChangeMainInput={(newValue) => listStudentFromClassWithApplication(newValue)}
              handleResetFilter={handleResetFilter}
              handleApplyFilter={listStudentFromClassWithApplication}
            >
              <Label>Status:</Label>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {mapaStatusName.map((status) => (
                  <Checkbox
                    key={`${status.value}`}
                    hideIconUncheck
                    icon={FiCheck}
                    status="basic"
                    semiRound
                    id={status.value}
                    name={status.name}
                    label={status.name}
                    checked={
                      status.name !== 'Sem Aplicação' ? !!mapaFilterState?.status?.includes(status.value) : !!mapaFilterState?.first_application
                    }
                    onChange={(event) => handleChangeCheckboxFilter(event, 'status')}
                  />
                ))}
              </div>

              <Divider width="100%" />

              <Label>Trilhas do MAPA:</Label>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {classType &&
                  getTrailsValuesByClassType(classType, trailsValues).map((trail) => (
                    <Checkbox
                      key={`${trail.value}`}
                      hideIconUncheck
                      icon={FiCheck}
                      status="basic"
                      semiRound
                      id={trail.value}
                      name={trail.name}
                      label={trail.name}
                      defaultChecked={!!mapaFilterState?.trails?.includes(Number(trail.value))}
                      onChange={(event) => handleChangeCheckboxFilter(event, 'trails')}
                    />
                  ))}
              </div>
            </AdvancedFilter>
            {/* END FILTER */}
          </div>

          <ContainerTrails>
            <Label>Selecionar para todos:</Label>
            <Header>
              {trailItemsClassType.map((trail) => (
                <Checkbox
                  hideIconUncheck
                  icon={FiCheck}
                  disabled={selectAllCheckBox && trail.trailId !== -1}
                  checked={selectTrailName === 'Todos' || undefined}
                  key={`${trail.name}a`}
                  name={`${trail.trailId}`}
                  id={`${trail.name}`}
                  label={trail.name}
                  onChange={(event) => selectAllTrails(event)}
                />
              ))}

              {trailItemsClassType.length > 1 && (
                <Checkbox
                  hideIconUncheck
                  icon={FiCheck}
                  key={`Todos`}
                  name={`-1`}
                  id={`Todos`}
                  label={`Todas as trilhas`}
                  onChange={(event) => selectAllTrails(event)}
                />
              )}
            </Header>
          </ContainerTrails>
          {trailItemsClassType.length > 0 &&
            filteredStudentApplications?.map(
              (studentData) =>
                studentData.applications.length > 0 && (
                  <MapaCard
                    currentClass={currentClass}
                    cancelApplication={(id) => deleteApplication(id)}
                    key={studentData.student.id}
                    student={studentData.student}
                    applicationTrails={studentData.applications.map((application) => {
                      return application;
                    })}
                  />
                )
            )}
          <Pagination
            totalOfPages={classStudent?.totalPages ? Number(classStudent?.totalPages) : 0}
            currentPage={classStudent?.currentPage ? Number(classStudent?.currentPage) : 0}
            callbackGetListData={listStudentFromClassWithApplication}
          />
        </WrapperContent>

        <PageFooter>
          <Button onClick={() => openInNewTab(`${mapa4Tr}/${id}`)} status="quaternary" transform="none" noBorder shaded>
            Aplicador da Turma
          </Button>
          <Button
            onClick={() => creteMapaLauncher()}
            status="quaternary"
            transform="none"
            noBorder
            shaded
            disabled={!selectedStudentsMapa.applications.length}
          >
            Lançar MAPA
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </Container>
  );
};

export { MapaLauncher };
