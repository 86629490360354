interface IMenuItems {
  resource: string;
  title: string;
  icon: string; // React Eva Icons Name
  color: string;
}

interface ILayoutConfig {
  menu: Array<IMenuItems>;
  sideBarWidth: number;
}

export default {
  menu: [
    {
      resource: '/horas-trabalhadas/informacoes/aprovados',
      title: 'Lançar Horas Trabalhadas',
      icon: 'clock',
      color: 'linear-gradient(284.92deg, #F47920 0%, #0097B1 100%)',
    },
    {
      resource: '/horas-trabalhadas/contexto/aprovar',
      title: 'Aprovar Horas Trabalhadas',
      icon: 'clock',
      color: 'linear-gradient(284.92deg, #F47920 0%, #0097B1 100%)',
    },
    {
      resource: '/banco-atividades',
      title: 'Banco de Atividades',
      icon: 'book-open',
      color: 'linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)',
    },
    {
      resource: '/diretorio/tags',
      title: 'Diretório de Tags',
      icon: 'pricetags',
      color: 'linear-gradient(45deg, #282674 0%, #145496 100%)',
    },
    {
      resource: '/relatorios',
      title: 'Relatórios',
      icon: 'clipboard',
      color: 'linear-gradient(45deg, #284674 0%, #245496 100%)',
    },
    {
      resource: '/diretorio/alunos',
      title: 'Diretório de Alunos',
      icon: 'people',
      color: 'linear-gradient(135deg, #57CCE0 0%, #43A2E4 100%)',
    },
    {
      resource: '/diretorio/usuarios',
      title: 'Diretório de Usuários',
      icon: 'clipboard',
      color: 'linear-gradient(135deg, #2AB1C8 0%, #2484C7 100%)',
    },
    // @TODO Botão desabilitado até a conclusão de Projetos
    // {
    //   resource: '/diretorio/projetos',
    //   title: 'Diretório de Projetos',
    //   icon: 'archive',
    //   color: 'linear-gradient(135deg, #BE3A3A 0%, #F7652B 100%)',
    // },
    {
      resource: '/perfis-acesso',
      title: 'Perfis de Acesso',
      icon: 'person',
      color: 'linear-gradient(284.92deg, #6E3FA5 0%, #2484C7 100%)',
    },
  ],
  sideBarWidth: 270,
} as ILayoutConfig;
