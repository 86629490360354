import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  .card-header {
    background-color: #96B6F7;
  }

  .card-body {
    background-color: #CDDAF6;
    justify-content: flex-start;
    padding: 20px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .tag {
    border-radius: 4px;
    margin: 2.5px;
  }
`;