import * as Yup from 'yup';

export const portfolioActivitySchema = Yup.object()
  .shape({
    activity_name: Yup.string().required({ name: 'activity_name', message: 'O campo é obrigatório' }),

    description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório' }),

    comments: Yup.string().required({ name: 'comments', message: 'O campo é obrigatório' }),

    delivery_date: Yup.string().required({ name: 'delivery_date', message: 'O campo é obrigatório' }),

    type: Yup.string().oneOf(['checkpoint', 'pdv']).required({ name: 'type', message: 'O campo é obrigatório' }),

    students_ids: Yup.array().of(Yup.string()).required({ name: 'students_ids', message: 'O campo é obrigatório' }) /* .optional() */,

    links: Yup.array().of(
      Yup.string().url({ name: 'links', message: 'Insira um link válido' }).min(1, { name: 'links', message: 'Insira um link válido' })
    ),
    attachments: Yup.array(Yup.mixed()), // Aqui chega File[] or IAttachment[]
  })
  .test({
    name: 'at-least-one-field-required',
    message: 'Pelo menos um dos campos (links ou attachments) deve ser preenchido',
    test: function (value, { createError }) {
      const { links, attachments } = value;
      // Verifica se pelo menos um dos campos (links ou attachments) está preenchido
      if (!(links && links.length > 0) && !(attachments && attachments.length > 0)) {
        return createError({
          path: 'links',
          message: { name: 'links', message: 'Pelo menos um dos campos (links ou attachments) deve ser preenchido' },
        });
      }
      return true;
    },
  })
  .test({
    name: 'at-least-one-field-required',
    message: 'Pelo menos um dos campos (links ou attachments) deve ser preenchido',
    test: function (value, { createError }) {
      const { links, attachments } = value;
      // Verifica se pelo menos um dos campos (links ou attachments) está preenchido
      if (!(links && links.length > 0) && !(attachments && attachments.length > 0)) {
        return createError({
          path: 'attachments',
          message: { name: 'attachments', message: 'Pelo menos um dos campos (links ou attachments) deve ser preenchido' },
        });
      }
      return true;
    },
  });
