import React, { useMemo } from 'react';
import { Button } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import Icon from 'react-eva-icons';

import { ContainerData, ButtonModal, ModalContainer } from './styles';

interface IConfirmModal {
  showModal: boolean;
  showClose?: boolean;
  closeCallback: () => void;
  title: string;
  subTitle?: string;
  start?: JSX.Element;
  textConfirmButton: string;
  textCloseButton?: string;
  confirmCallBack: () => any;
  showButtonReturn?: boolean;
  status?: ElementStatus;
}

const ConfirmModal: React.FC<IConfirmModal> = ({
  showModal,
  showClose = false,
  closeCallback,
  confirmCallBack,
  title,
  subTitle,
  start,
  textConfirmButton,
  textCloseButton = 'Voltar',
  showButtonReturn = true,
  status = 'basic',
  ...rest
}) => {
  const classnameWithoutStart = useMemo(() => {
    return start ? '' : 'margin-wihout-start';
  }, [start]);

  return (
    <ModalContainer showModal={showModal}>
      <ContainerData className="container-confirm-modal">
        {showClose && (
          <div className="show-close-button-confirm-modal">
            <div onClick={closeCallback}>
              <Icon name="close" size="xlarge" fill="#2E3A59" />
            </div>
          </div>
        )}
        {start && <div className="icon-start-container">{start}</div>}
        <div className={`title-confirm-modal ${classnameWithoutStart}`}>{title}</div>
        {subTitle && <div className="subTitle-confirm-modal">{subTitle}</div>}
        {rest.children}
        <div className="badge-modal-line" />
        <ButtonModal className="buttons-confirm-modal" twoButtons={showButtonReturn}>
          {showButtonReturn && (
            <Button
              start={<Icon name="close-outline" />}
              className="button-cancel-confirm-modal"
              onClick={closeCallback}
              transform="none"
              style={{ margin: '0 auto', background: 'white', color: '#482B85', border: '2px solid #482B85' }}
              status={status}
            >
              {textCloseButton}
            </Button>
          )}
          <Button
            start={<Icon name="checkmark-outline" />}
            className="button-accept-confirm-modal"
            onClick={confirmCallBack}
            transform="none"
            color="#FFF"
            style={{ margin: '0 auto', background: 'linear-gradient(45deg, #482B85 0%, #6749BA 100%)' }}
            status={status}
          >
            {textConfirmButton}
          </Button>
        </ButtonModal>
      </ContainerData>
    </ModalContainer>
  );
};

export default ConfirmModal;
