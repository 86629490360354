export const dailyClassKeys = {
    DAILY_CLASS_CREATE: 'dailyClass/create',
    DAILY_CLASS_UPDATE: 'dailyClass/update',
    DAILY_CLASS_DELETE: 'dailyClass/delete',
    DAILY_CLASS_READ: 'dailyClass/read',

    PLANNED_ACTIVITY_CREATE: 'plannedActivity/create',
    PLANNED_ACTIVITY_UPDATE: 'plannedActivity/update',
    PLANNED_ACTIVITY_DELETE: 'plannedActivity/delete',
    PLANNED_ACTIVITY_READ: 'plannedActivity/read',
};