import styled from "styled-components";
interface IContainerIconProps {
  isShow?: boolean;
}

export const Container = styled.div`
  margin: 0 .5rem;
  padding: 1rem;
  border-bottom: 2px solid #E4E9F2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .tag {
    border-radius: 4px;
  }

  .container-child-tag  {
    overflow: hidden;
    width: 100%;
    margin-top: 4px;
    margin-left: 8px;

    > div {
      border-bottom: transparent;  
    }
  }
`;

export const ContainerExpand = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    cursor: pointer;
  }
`;

export const ContainerIcon = styled.div<IContainerIconProps>`
  margin-left: 4px;
  background-color: #004287;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: ${({ isShow}) => isShow ? 'rotate(90deg)' : 'rotate(0deg)'};

  i {
    line-height: 1;
    margin-top: 1px;

    svg {
      width: 28px;
      height: 28px;
    }
  }
  
`;

