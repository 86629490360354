import React, { useCallback, useEffect, useState } from 'react';
import GroupList from '@modules/groups/components/GroupsList';
import { IGroup } from '@modules/groups/interfaces/IGroup';
import { useProjects } from '@modules/projects/context/ProjectsContext';

const ProjectGroupRelation: React.FC = () => {
  const { setProjectCurrent, projectCurrent } = useProjects();
  const [selectedGroup, setSelectedGroupCreate] = useState<IGroup>();

  useEffect(() => {
    setSelectedGroupCreate(projectCurrent?.group);
  }, [projectCurrent]);

  const handleAddRelationGroup = useCallback(
    (groupSelect: IGroup | undefined) => {
      setProjectCurrent((oldState: any) => ({ ...oldState, group: groupSelect }));
    },
    [setProjectCurrent]
  );

  const setSelectedItens = useCallback(
    (group: IGroup[]) => {
      setSelectedGroupCreate(group[0]);
    },
    [setSelectedGroupCreate]
  );

  return (
    <GroupList
      headerTitle="Diretório de Grupos"
      btnText="Adicionar Grupo"
      selectedGroups={selectedGroup ? [selectedGroup] : []}
      setSelectedGroups={setSelectedItens}
      handleOnClick={handleAddRelationGroup}
      canSelect="one"
    />
  );
};

export { ProjectGroupRelation };
