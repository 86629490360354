import { styled } from 'react-alicerce-components';

export const Container = styled.div`
  h6 {
    margin-bottom: 15px;
  }

  .container-accordion-input {
    margin-top: 10px;
  }
`;

export const Modifier = styled.div`
  input{
    color: #5DC4EA;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0.8rem;
  max-width: 100%;
  margin: 1.5rem 0;

  .input-container {
    margin: 0;
  }

  input {
    word-break: unset;
    overflow-x: scroll;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  .input-container::-webkit-scrollbar {
    display: none;
  }

  > div:first-child {
    width: 70%;
  }
  > div:last-child {
    width: 25%;
  }
`;
