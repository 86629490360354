export interface IAttatchedButtons {
  name: string;
  key: string;
  colorType: number;
  path: string;
  disabled?: boolean;
}

export const ButtonInfos = [
  {
    name: 'Enturmamento',
    key: 'people_grouping',
    colorType: 13,
    path: '/assinaturas/contexto/grupos',
  },
  {
    name: 'Aprovar Horas Trabalhadas',
    key: 'approve_worked_hours',
    colorType: 5,
    path: '/horas-trabalhadas/contexto/aprovar',
  },
  {
    name: 'Presença',
    key: 'presence',
    colorType: 13,
    path: '/presencas/contexto',
  },
  {
    name: 'Metas de Aprendizagem',
    key: 'learning_goals',
    colorType: 3,
    path: '/metasdeaprendizagem/contexto/turmas',
  },
  {
    name: 'Planejador de Aula',
    key: 'class_planning',
    colorType: 7,
    path: '/planejamento-aula/contexto/turmas',
  },
  {
    name: 'Lançar MAPA/MiniMAPA',
    key: 'launcher_mapa',
    colorType: 3,
    path: '/lancador/avaliacao/contexto/turmas',
  },
  {
    name: 'Portfólio',
    key: 'portfolio',
    colorType: 14,
    path: '/portfolio-atividades/contexto/turmas',
  },
  // {
  //   name: 'Registro de MiniMAPA Impresso',
  //   key: 'register_mini_mapa',
  //   colorType: 3,
  //   path: '/',
  //   disabled: true,
  // },
  // {
  //   name: 'Lançador de NPS',
  //   key: 'launcher_nps',
  //   colorType: 15,
  //   path: '/',
  //   disabled: true,
  // },
  {
    name: 'Plano de Ensino',
    key: 'sprint_planning',
    colorType: 7,
    path: '/planejador-sprint/contexto',
  },
  {
    name: 'Ocorrências',
    key: 'occurrences',
    colorType: 15,
    path: '/ocorrencias/contexto/turmas',
  },
  // @TODO Botões de Faróis desabilitados até a conclusão/lançamento de Projetos/Faróis
  // {
  //   name: 'Lançar Faróis',
  //   key: 'headlights_launcher',
  //   colorType: 18,
  //   path: '/projetos/lancar-farois',
  // },
  // {
  //   name: 'Aprovar Faróis',
  //   key: 'headlights_approval',
  //   colorType: 18,
  //   path: '/projetos/aprovar-farois',
  // },
  // {
  //   name: 'Gravações',
  //   key: 'recordings',
  //   colorType: 15,
  //   path: '/',
  //   disabled: true,
  // },
] as IAttatchedButtons[];
