import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { ISchedule } from '../interfaces/ISchedule';

class EditScheduleService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(schedule: ISchedule): Promise<ISchedule | false> {
    try {
      const res = await this.api.put(`/schedule/${schedule.id}`, {
        name: schedule.name,
        description: schedule.description
      });
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default EditScheduleService;
