import React from 'react';
import { IBlock } from '@shared/interfaces/IBlock';
import { BlockCardContainer } from './styles';

interface ISkillCardProps {
  block: IBlock;
}

export const BlockCard: React.FC<ISkillCardProps> = ({ block }) => {
  return (
    <BlockCardContainer className='container-block-card'>
      <div className="container-description-block">{block.name}</div>
      <div className="container-footer-block">
        <div className="applied-filter">ID da Trilha: {block.trail_id}</div>
        <div className="applied-filter">ID do Level: {block.level_id}</div>
      </div>
    </BlockCardContainer>
  );
};
