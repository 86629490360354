import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';


import { useProgram } from '@modules/activities-library/context/ProgramContext';
import { IProgram } from '@modules/activities-library/interfaces/IProgram';
import TagsList from '@modules/tags/components/TagsList';
import ITag from '@modules/tags/interfaces/ITag';

const ProgramTagRelation: React.FC = () => {
  const navigate = useNavigate();
  const { programCurrent, setProgramCurrent } = useProgram();

  const canQuantitySelect = useMemo(() => 100, []);

  const handleOnChangeTagsSelected = useCallback(
    (tags: ITag[]) => {
      setProgramCurrent((oldState: IProgram) => {
        return { ...oldState, tags: tags };
      });
    },
    [setProgramCurrent]
  );

  return (
    <TagsList
      handleOnChangeTagsSelected={handleOnChangeTagsSelected}
      canQuantitySelect={canQuantitySelect}
      useSearch
      selectedTags={programCurrent.tags}
      buttonText={`Adicionar Tag${canQuantitySelect > 1 ? 's' : ''}`}
      buttonCallback={() => navigate(-1)}
    />
  );
};

export { ProgramTagRelation };
