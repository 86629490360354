export const getGridIndex = ({ event, countGrids, scrollLeft }) => {
  const touchLocation = event.changedTouches[0];
  /* const markerWidth = touchLocation.target.offsetWidth; */
  const gridWidth = 110;
  const gridsContainerWidth = gridWidth * countGrids;
  const calculatePosition = touchLocation.clientX + scrollLeft;
  const position = calculatePosition > gridsContainerWidth ? gridsContainerWidth : calculatePosition;
  const foundGridIndex = Math.round((position * countGrids) / gridsContainerWidth);
  return foundGridIndex <= 0 ? 0 : foundGridIndex - 1;
};
