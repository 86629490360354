import * as Yup from 'yup';
import { STEERING_TYPE_CONSTRAINTS } from '../partials/SteeringType';
import { STEERING_THERMOMETER_CONSTRAINTS } from '../partials/SteeringThermometer';
import { EDITING_STEERING_STATUS_CONSTRAINTS } from '../partials/SteeringStatus';
import { STEERING_CHANNEL_CONSTRAINTS } from '../partials/SteeringChannel';

export const EditingInfoSteeringSchema = Yup.object().shape({
  meeting_date: Yup.date()
    .typeError(({ path }) => ({ name: path, message: 'O campo deve ser uma data válida' }))
    .required(({ path }) => ({ name: path, message: 'O campo é obrigatório' }))
    .test(
      'not-empty',
      ({ path }) => ({ name: path, message: 'O campo é obrigatório' }),
      (value) => value !== null
    ),
  type: Yup.string()
    .oneOf(STEERING_TYPE_CONSTRAINTS, { name: 'type', message: `Campo deve ser ${STEERING_TYPE_CONSTRAINTS} ` })
    .required({ name: 'type', message: 'O campo é obrigatório' }),
  status: Yup.string()
    .oneOf(EDITING_STEERING_STATUS_CONSTRAINTS, { name: 'status', message: 'Campo deve ser Realizada ou Não Realizada' })
    .test('future-date', function (value, context) {
      const { meeting_date } = context.parent;
      if (meeting_date && meeting_date >= new Date() && value === 'finished') {
        return context.createError({
          message: { name: 'status', message: 'Status não pode ser Realizada se a Data da Steering é futura.' }
        });
      }
      return true;
    }),
  meeting_channel: Yup.string()
    .oneOf(STEERING_CHANNEL_CONSTRAINTS, { name: 'meeting_channel', message: `Campo deve ser ${STEERING_CHANNEL_CONSTRAINTS} ` })
    .required({ name: 'meeting_channel', message: 'O campo é obrigatório' }),
  meeting_link: Yup.string()
    .when(['meeting_channel', 'status'], {
      is: (meeting_channel, status) => meeting_channel !== 'in_person' && status !== 'not_finished',
      then: Yup.string().url('Digite uma URL válida').required({ name: 'meeting_link', message: 'O campo é obrigatório' }).nullable(),
      otherwise: Yup.string().url('Digite uma URL válida').nullable(),
    })
    .nullable(),
  meeting_next_date: Yup.date()
    .test('is-future', function (value, context) {
      const { meeting_date } = context.parent;
      if (value && meeting_date && value <= meeting_date) {
        return context.createError({
          message: { name: 'meeting_next_date', message: 'Campo deve ser uma data futura.' },
        });
      }
      return true;
    })
    .optional()
    .nullable(),
  external_participants: Yup.object({
    participants: Yup.array()
      .of(Yup.string().required('Cada participante é obrigatório'))
      .min(1, { name: 'external_participants', message: 'Pelo menos um participante é necessário' })
      .nullable()
      .required({ name: 'external_participants', message: 'O campo participante é obrigatório' }),
  }).required('O campo é obrigatório'),
  thermometer: Yup.string()
    .when('status', {
      is: (status) => status !== 'not_finished',
      then: Yup.string()
        .oneOf(STEERING_THERMOMETER_CONSTRAINTS, { name: 'thermometer', message: `Campo deve ser ${STEERING_THERMOMETER_CONSTRAINTS} ` })
        .nullable(),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
  lms_participants_ids: Yup.array()
    .of(Yup.number())
    .min(1, { name: 'lms_participants_ids', message: 'Pelo menos um participante é necessário' })
    .nullable()
    .required({ name: 'lms_participants_ids', message: 'O campo é obrigatório' }),
  project_id: Yup.number().required({ name: 'project_id', message: 'O campo é obrigatório' }),
});

export const PointsOfMeetingSchema = Yup.object().shape({
  points_of_meeting: Yup.string()
    .when('status', {
      is: (status) => status !== 'not_finished',
      then: Yup.string().required({ name: 'points_of_meeting', message: 'O campo é obrigatório' }).nullable(),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
});

export const ActionPlanOfMeetingSchema = Yup.object().shape({
  action_plan_of_meeting: Yup.string()
    .when('status', {
      is: (status) => status !== 'not_finished',
      then: Yup.string().required({ name: 'action_plan_of_meeting', message: 'O campo é obrigatório' }).nullable(),
      otherwise: Yup.string().nullable(),
    })
    .nullable(),
});

export const EditSteeringSchema = EditingInfoSteeringSchema.concat(PointsOfMeetingSchema).concat(ActionPlanOfMeetingSchema);
