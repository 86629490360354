import React from "react";

import Layout from "@modules/places/components/Layout";
import ButtonGradient from "@shared/components/ButtonGradient";
import { ContainerButtons } from "./styles";

const PlaceReport: React.FC = () => {
  return (
    <Layout openTab={4}>
      <ContainerButtons>
        <ButtonGradient disabled type={12}>Dash de Ops (Place Holter)</ButtonGradient>
      </ContainerButtons>
    </Layout>
  );
};

export default PlaceReport;
