import { TabsProvider } from 'react-alicerce-components';

import { ActivityLibrarySkillsAdvancedFilter } from '@modules/activities-library/views/ActivityLibrarySkillsAdvancedFilter';
import { ActivityLibraryThemeAdvancedFilter } from '@modules/activities-library/views/ActivityLibraryThemeAdvancedFilter';
import { ActivityLibraryContextSelection } from '@modules/activities-library/views/ActivityLibraryContextSelection';
import { ActivityLibraryThemeRelationCreate } from '@modules/activities-library/views/ActivityLibraryThemeRelationCreate';
import { ActivityLibraryTypeAdvancedFilter } from '@modules/activities-library/views/ActivityLibraryTypeAdvancedFilter';
import { ActivityLibraryTypeRelationCreate } from '@modules/activities-library/views/ActivityLibraryTypeRelationCreate';
import { ProgramActivityRelationCreate } from '@modules/activities-library/views/ProgramActivityRelationCreate';
import { ProgramActivityRelationEdit } from '@modules/activities-library/views/ProgramActivityRelationEdit';
import { ProgramTagsAdvancedFilter } from '@modules/activities-library/views/ProgramTagsAdvancedFilter';
import { ActivityLibraryCreate } from '@modules/activities-library/views/ActivityLibraryCreate';
import { ProgramTagRelation } from '@modules/activities-library/views/ProgramTagRelation';
import { ProgramCreate } from '@modules/activities-library/views/ProgramCreate';
import { ProgramEdit } from '@modules/activities-library/views/ProgramEdit/Index';
import { ProgramInfo } from '@modules/activities-library/views/ProgramInfo';
import { ActivityLibraryInfo } from '@modules/activities-library/views/ActivityLibraryInfo';
import { ActivityLibraryEdit } from '@modules/activities-library/views/ActivityLibraryEdit';

import { tagsKeys } from '@modules/tags/keys';
import { activityLibraryKeys } from '@modules/activities-library/keys';

import IRoute from '@shared/interfaces/IRoute';
import { IModuleRoutes } from '@shared/interfaces/IModuleRoutes';
import { combineContext } from '@shared/utils/combineContext';
import { ActivityLibraryTagRelation } from '../views/ActivityLibraryTagRelation';
import { ActivityLibrarySkillsRelation } from '../views/ActivityLibrarySkillsRelation';

const routes: IRoute[] = [
  {
    path: '/banco-atividades',
    key: 'ACTIVITIES_LIBRARY_PATH',
    exact: true,
    component: ActivityLibraryContextSelection,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ, activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ],
  },
  {
    path: '/banco-atividades/programa/:programId/informacoes',
    key: 'ACTIVITIES_LIBRARY_PROGRAM_INFO_PATH',
    exact: true,
    component: ProgramInfo,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ],
  },
  {
    path: '/banco-atividades/atividade/:activityId/informacoes',
    key: 'ACTIVITIES_LIBRARY_ACTIVITY_INFO_PATH',
    exact: true,
    component: ActivityLibraryInfo,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ],
  },
  {
    path: '/banco-atividades/programa/:programId/editar',
    key: 'ACTIVITIES_LIBRARY_PROGRAM_EDIT',
    exact: true,
    component: ProgramEdit,
    permissions: [
      activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ,
      activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_DELETE,
      activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_UPDATE,
    ],
  },
  {
    path: '/banco-atividades/atividade/:activityId/editar',
    key: 'ACTIVITIES_LIBRARY_EDIT',
    exact: true,
    component: ActivityLibraryEdit,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ, activityLibraryKeys.ACTIVITIES_LIBRARY_UPDATE],
  },
  {
    path: '/banco-atividades/criar/atividade',
    key: 'ACTIVITIES_LIBRARY_CREATE_ACITIVITY_PATH',
    exact: true,
    component: ActivityLibraryCreate,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_CREATE],
  },
  {
    path: '/banco-atividades/criar/programa',
    key: 'ACTIVITIES_LIBRARY_CREATE_PROGRAM_PATH',
    exact: true,
    component: ProgramCreate,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_CREATE],
  },
  {
    path: '/banco-atividades/criar/programa/selecionar/tags',
    key: 'ACTIVITIES_LIBRARY_CREATE_TAGS_RELATION',
    exact: true,
    component: ProgramTagRelation,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/atividade/selecionar/tags',
    key: 'ACTIVITIES_LIBRARY_TAGS_RELATION',
    exact: true,
    component: ActivityLibraryTagRelation,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/editar/programa/:programId/selecionar/tags',
    key: 'ACTIVITIES_LIBRARY_EDIT_TAGS_RELATION',
    exact: true,
    component: ProgramTagRelation,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/criar/programa/selecionar/atividade',
    key: 'ACTIVITIES_LIBRARY_CREATE_ACTIVITIES_RELATION',
    exact: true,
    component: ProgramActivityRelationCreate,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ, activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ],
  },
  {
    path: '/banco-atividades/editar/programa/:programId/selecionar/atividade',
    key: 'ACTIVITIES_LIBRARY_EDIT_ACTIVITIES_RELATION',
    exact: true,
    component: ProgramActivityRelationEdit,
    permissions: [activityLibraryKeys.ACTIVITIES_LIBRARY_READ, activityLibraryKeys.ACTIVITIES_LIBRARY_PROGRAM_READ],
  },
  {
    path: '/banco-atividades/criar/competencias',
    key: 'ACTIVITIES_LIBRARY_SKILL_RELATION_PATH',
    exact: true,
    component: ActivityLibrarySkillsRelation,
    permissions: 'public',
  },
  {
    path: '/banco-atividades/criar/categoria/tipo',
    key: 'ACTIVITIES_LIBRARY_CREATE_TYPE_PATH',
    exact: true,
    component: ActivityLibraryTypeRelationCreate,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/criar/categoria/tema',
    key: 'ACTIVITIES_LIBRARY_CREATE_THEME_PATH',
    exact: true,
    component: ActivityLibraryThemeRelationCreate,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/selecionar/competencias',
    key: 'ACTIVITIES_LIBRARY_SELECT_PATH',
    exact: true,
    component: ActivityLibrarySkillsAdvancedFilter,
    permissions: 'public',
  },
  {
    path: '/banco-atividades/selecionar/categoria/tipo',
    key: 'ACTIVITIES_LIBRARY_SELECT_TYPE_PATH',
    exact: true,
    component: ActivityLibraryTypeAdvancedFilter,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/selecionar/categoria/tema',
    key: 'ACTIVITIES_LIBRARY_SELECT_THEME_PATH',
    exact: true,
    component: ActivityLibraryThemeAdvancedFilter,
    permissions: [tagsKeys.TAGS_READ],
  },
  {
    path: '/banco-atividades/selecionar/programa/tags',
    key: 'ACTIVITIES_LIBRARY_PROGRAM_SELECT_TAGS_PATH',
    exact: true,
    component: ProgramTagsAdvancedFilter,
    permissions: [tagsKeys.TAGS_READ],
  },
];

const providers = [TabsProvider];

export default {
  key: 'ACTIVITIES_LIBRARY_MODULE',
  providers: combineContext(...providers),
  routes,
} as IModuleRoutes;
