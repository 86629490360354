import React, { FC } from 'react';
import Icon from 'react-eva-icons';
import { AlertContainer } from './styles'

interface IAlertMessage {
  text: string; 
  iconName?: string;
  iconFill?: string;
  process?: 'enrollment' | 'unenrollment'
}

const AlertMessage: FC<IAlertMessage> = ({
  text,
  iconName='alert-triangle',
  iconFill='#8F9BB3',
  process
}) => {
  return (
    <AlertContainer process={process}>
      <Icon name={iconName} fill={iconFill} />
      <div>{text}</div>
    </AlertContainer>
  )
}

export default AlertMessage;