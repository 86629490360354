import styled, { css } from "styled-components";
type CustomAccordionArrowProps = {
    isOpen?: boolean
}
export const AccordionSkillsHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .8rem .6rem;
    width: 100%;

    .current-block {
        font-size: 1rem !important;
        font-weight: 700;
        padding: 0.6rem;
        color: #2E3A59;
        /* width: 50%; */
    }
    .block-wrapper {
        display: flex;
        max-width: 90%;
        
        @media (max-width: 420px) {
            flex-wrap: wrap;
    }
    }
`;

export const AccordionSkillsContentContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: .6rem;

    .skill-pill {
        padding: .4rem .6rem;
        font-size: 1rem;
    }
`;

const CustomAccordionArrowModifiers = {
    active: () => css`
    &::before{
        transform: rotate(-135deg);
        right: -2px;
        width: 15px;

    }
    &::after{
        width: 15px;
        transform: rotate(135deg);
        left: -2px;
    }
      `,
}

export const CustomAccordionArrow = styled.div<CustomAccordionArrowProps>`
    ${({ isOpen }) => css`
    width: 20px;
    height: 20px;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    color: #2E3A59;

    &::after,
    &::before {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        background-color: #2E3A59;
        width: 20px;
        top: 50%;
        transition: all .2s ease-in-out; 
        right: 0;
    }
    &::before{
        transform: rotate(-45deg);
        right: -2px;
        width: 15px;
    }
    &::after{
        width: 15px;
        transform: rotate(45deg);
        left: -2px;
    }

    ${isOpen && CustomAccordionArrowModifiers.active};
`}
`;