import React from 'react';
import { StudentCardDirectoryContentContainer } from './styles';
import { IStudent } from '@modules/students/interfaces/IStudent';
import { findConstraintName } from '@modules/students/utils/constraintName';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import { Pill } from 'react-alicerce-components';
import { LITERATE_CONSTRAINTS } from '@shared/utils/validData/validLiterate';

interface ICardDirectory {
  student: IStudent;
}

const StudentCardDirectoryContent: React.FC<ICardDirectory> = ({ student }) => {
  return (
    <StudentCardDirectoryContentContainer>
      <div className="content">
        <span>ID do Aluno: </span>
        <span className="content-bold">{student.id}</span> | <span>Grupo Etário:</span>
        <span className="content-bold"> {findConstraintName(AGE_GROUP_CONSTRAINTS, student.age_group)}</span>
      </div>

      <div className="content">
        <span>Coletivo: </span>
        <span className="content-bold">{student.onboarding_group?.name ? student.onboarding_group?.name : 'Estudante sem coletivo'}</span>
      </div>
      <div className="content">
        <Pill
          color={student.literate_status === 'is_literate' ? '#222B45' : '#ffff'}
          size="small"
          marginTop="4px"
          className="tag"
          backgroundColor={student.literate_status === 'is_literate' ? '#00D68F' : '#FF3D71'}
        >
          {findConstraintName(LITERATE_CONSTRAINTS, student.literate_status)}
        </Pill>
      </div>
    </StudentCardDirectoryContentContainer>
  );
};

export default StudentCardDirectoryContent;
