export const AMOUNT_INDIVIDUAL = { name: 'Individual', value: 'individual' };
export const AMOUNT_DUO = { name: 'Em Dupla', value: 'duo' };
export const AMOUNT_GROUP = { name: 'Grupo', value: 'group' };
export const AMOUNT_CLASS = { name: 'Turma', value: 'class' };

export const STUDENT_AMOUNT_CONSTRAINTS = [AMOUNT_INDIVIDUAL, AMOUNT_DUO, AMOUNT_GROUP, AMOUNT_CLASS];

export const defaultStudentAmmount = (studentAmount?: string | null) => {
  return [
    { name: AMOUNT_INDIVIDUAL.name, value: AMOUNT_INDIVIDUAL.value, selected: studentAmount === AMOUNT_INDIVIDUAL.value },
    { name: AMOUNT_DUO.name, value: AMOUNT_DUO.value, selected: studentAmount === AMOUNT_DUO.value },
    { name: AMOUNT_GROUP.name, value: AMOUNT_GROUP.value, selected: studentAmount === AMOUNT_GROUP.value },
    { name: AMOUNT_CLASS.name, value: AMOUNT_CLASS.value, selected: studentAmount === AMOUNT_CLASS.value },
  ];
};
