import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Spinner, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '@shared/components/ConfirmModal';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import { WorkGroupInfoComponent } from '@modules/work-group/components/WorkGroupInfoComponent';
import { useWorkGroup } from '@modules/work-group/context/WorkGroupContext';
import GetWorkGroupService from '@modules/work-group/services/GetWorkGroupService';
import Icon from 'react-eva-icons';
import DeleteWorkGroupService from '../../services/DeleteWorkGroupService';

const WorkGroupInfo: React.FC = () => {
  const { workGroupId, classId } = useParams<{ workGroupId: string; classId: string }>();
  const { signedUser } = useAuth();
  const { setIsLoading, isLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const { setWorkGroupCurrent } = useWorkGroup();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { addToast } = useToast();

  const getWorkGroup = useCallback(async () => {
    if (!workGroupId) throw new Error('workGroupId is null, false or undefined');
    setIsLoading(true);
    const res = await new GetWorkGroupService(signedUser?.token, newCancelToken()).execute(workGroupId);
    if (!res) throw new Error('Error ao Recuperar Work Groups');
    setIsLoading(false);
    if (!isMounted()) return;
    setWorkGroupCurrent(res);
  }, [isMounted, newCancelToken, setIsLoading, setWorkGroupCurrent, signedUser, workGroupId]);

  const handleEditWorkGroup = useCallback(() => {
    navigate(`/grupo-de-trabalho/${workGroupId}/editar/turma/${classId}`);
  }, [classId, navigate, workGroupId]);

  const deleteWorkGroup = useCallback(async () => {
    if (!workGroupId) return;
    setShowDeleteModal(false);
    setIsLoading(true);
    const res = await new DeleteWorkGroupService(signedUser?.token, newCancelToken()).execute(workGroupId);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Grupo de Trabalho',
        description: 'Error ao Deletar Grupo De Trabalho.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Grupo de Trabalho',
      description: 'Grupo de Trabalho Deletado com Sucesso.',
    });
    navigate(`/grupo-de-trabalho/turma/${classId}`);
  }, [addToast, classId, navigate, newCancelToken, setIsLoading, signedUser, workGroupId]);

  useEffect(() => {
    getWorkGroup();
  }, [getWorkGroup]);

  return (
    <Fragment>
      {isLoading && <Spinner fixed />}
      <HeaderDirectory
        title={'Grupo de Trabalho'}
        backgroundHeader="linear-gradient(284.92deg,#B390DB 0%,#70C3FC 100%)"
        pathToReturn={`/grupo-de-trabalho/turma/${classId}`}
      />
      <DirectoryContainer footer={true}>
        <WorkGroupInfoComponent />
        <PageFooter>
          <Button transform="none" status="primary" shaded onClick={() => setShowDeleteModal(true)} className="mochileiro-color">
            Excluir
          </Button>
          <Button transform="none" status="primary" shaded onClick={handleEditWorkGroup} className="button-edit-work-groups">
            Editar
          </Button>
        </PageFooter>
      </DirectoryContainer>
      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(false)}
        title="Deseja Excluir Grupo de Trabalho?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={deleteWorkGroup}
      />
    </Fragment>
  );
};

export default WorkGroupInfo;
