import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { IWorkedHour } from '@modules/worked-hours/interfaces/IWorkedHour';
import ITag from '../../tags/interfaces/ITag';
import { IGroup } from '../../groups/interfaces/IGroup';

export type IWorkedHourContextData = {
  clearWorkedHourState: () => void;
  handleResetFilterMonthly: () => void;
  workedHourCurrent: IWorkedHour;
  setWorkedHourCurrent: (value: any) => void;
  selectTags?: ICategoryHours;
  setSelectTags: (tags: any) => void;
  workedHoursFilter: React.MutableRefObject<IWorkedHourFilter>;
  setDate: (obj: any) => void;
  date: { start: string; end: string };
  searchMonthly: boolean;
  setSearchMonthly: (obj: any) => void;
};

interface IWorkedHourStringParams {}

const WorkedHourContext = createContext<IWorkedHourContextData>({} as IWorkedHourContextData);
export interface ICategoryHours {
  category?: ITag[];
  modality?: ITag[];
  project?: ITag[];
}

export type IWorkedHourTypes = 'category' | 'modality' | 'project';
export type ITagType = 'project' | 'system' | 'work_hour';

export interface IWorkedHourFilter {
  date?: {
    start?: string;
    end?: string;
  };
  completed_at?: {
    start?: string;
    end?: string;
  };
  total_hours?: string;
  group?: IGroup;
  category?: ITag;
  modality?: ITag;
  project?: ITag | null
}

const WorkedHourProvider: React.FC = ({ children }) => {
  const [workedHourCurrent, setWorkedHourCurrent] = useState<IWorkedHour>({});
  const [selectTags, setSelectTags] = useState<ICategoryHours>({});

  const getDate = new Date();
  const firstDay = new Date(getDate.getFullYear(), getDate.getMonth(), 1).toISOString().split('T')[0];
  const lastDay = new Date(getDate.getFullYear(), getDate.getMonth() + 1, 0).toISOString().split('T')[0];

  // const [workedHoursFilter, setWorkedHoursFilter] = useState<IWorkedHourFilter>({});
  const workedHoursFilter = useRef<IWorkedHourFilter>({ date: { end: lastDay, start: firstDay } });
  const [searchMonthly, setSearchMonthly] = useState<boolean>(false);

  const [date, setDate] = useState<{
    start: string;
    end: string;
  }>({ end: lastDay, start: firstDay });

  const handleResetFilterMonthly = useCallback(() => {
    setDate({ end: lastDay, start: firstDay });
  }, [firstDay, lastDay]);

  const clearWorkedHourState = useCallback(() => {
    setWorkedHourCurrent({});
  }, []);

  return (
    <WorkedHourContext.Provider
      value={{
        workedHoursFilter,
        searchMonthly,
        setSearchMonthly,
        setDate,
        date,
        selectTags,
        setSelectTags,
        clearWorkedHourState,
        handleResetFilterMonthly,
        workedHourCurrent,
        setWorkedHourCurrent,
      }}
    >
      {children}
    </WorkedHourContext.Provider>
  );
};

function useWorkedHour() {
  const context = useContext(WorkedHourContext);

  if (!context) {
    throw new Error('useWorkedHourContext must be used within a WorkedHourProvider');
  }

  return context;
}

export { WorkedHourProvider, useWorkedHour };
