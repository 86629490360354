import axios, { AxiosInstance, CancelTokenSource } from 'axios';
import ApiFactory from '@shared/factories/ApiFactory';
import { IToggleFavoritedReturn } from '@shared/interfaces/IToggleReturn';

class ToggleFavoritedClassService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource,
    });
  }

  public async execute(classId: string): Promise<false | IToggleFavoritedReturn> {
    try {
      const res = await this.api.patch(`/class/${classId}/favorited`);
      return res.data;
    } catch (error: any) {
      if (axios.isCancel(error)) return false;
      else console.error(`Error: ${error}`);
      return false;
    }
  }
}

export default ToggleFavoritedClassService;
