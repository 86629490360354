import React, { HTMLAttributes } from 'react';

import { P } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';
import Tag from '@modules/tags/components/Tag';

import { Container, ListContainer } from './styles';
import { IActivityScheduleGrid } from '../../../context/ScheduleGridContext';

export interface IActivity {
  index?: number;
  id?: string | number;
  name: string;
  infos: string[];
}

interface IActivityCardProps extends HTMLAttributes<HTMLDivElement> {
  activity: IActivityScheduleGrid;
  cardProps?: ICustomCardProps;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
}

const ActivityCard: React.FC<IActivityCardProps> = ({ activity, cardProps, ...rest }) => {
  return (
    <Container data-name={activity.name} data-planned_activity_data={JSON.stringify(activity)} data-id={activity.planned_activity_id} {...rest}>
      <CustomCard
        header={
          <P fontSize={14} fontWeight={800}>
            {activity.name}
          </P>
        }
        {...cardProps}
        canDeleteCard={rest.canDeleteCard}
      >
        <ListContainer onDragStart={rest.onDragStart}>
          {activity?.infos &&
            activity?.infos.map((info, i) => (
              <Tag key={`planned-class-info-${i}`} tag={{ name: info }} backgroundColor="#E4E9F2" color="#222B45" size="small" />
            ))}
        </ListContainer>
      </CustomCard>
    </Container>
  );
};

export default ActivityCard;
