import { styled } from 'react-alicerce-components';

export const ContractDefaultStylesContainer = styled.div`
  [role='tabpanel'] {
    padding: 22px 1rem;
  }

  [role='tablist'],
  .header-module-container::after,
  .header-navigation-container .main-navbar {
    background: #8D593B;
  }

  h6 {
    color: #8D593B;
    font-weight: 800;
  }

  .header-module-content h6 {
    color: #D1C4B8;
  }

  .accordion-header {
    background: #DAD3CC;
  }

  textarea,
  input {
    color: #8D593B !important;
  }

  .btn-contract,
  .container-page-footer button {
    background: linear-gradient(45deg, #8D593B 100%, #AF8B6B 100%);
    border: 2px solid #8D593B;
    border-radius: 16px;
  }
  
  .container-page-footer {
    .btn-cancel {
      border-radius: 16px;
      background: linear-gradient(180deg, #D1C4B8 40.1%, #AF8B6B 56.77%), linear-gradient(45deg, #8D593B 0%, #AF8B6B 100%);
      border: 2px solid #8D593B;
      .button-inner {
        color: #fff;
      }

    }
  }

  .pagination-content .pagination-content-icon {
    background: linear-gradient(45deg, #8D593B 0%, #AF8B6B 100%);
  }

  .custom-card {
    margin-top: 2rem;

    > div {
      margin: 0;
    }
  }

`;

export const InputDateContainer = styled.div`
width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 14px;
  
  @media (max-width: 465px) {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
  }

  input {
    width: 100%;
    all: unset;
    font-weight: 700;
    height: 40px;
    box-sizing: border-box;
    padding: 5px;
    background: #f7f9fc;
    border: 2px solid #e4e9f2;
    border-radius: 5px;
    color: #c5cee0;
    flex-grow: 1;
  }
`;