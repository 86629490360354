import styled from "styled-components";

interface IContainer {
  selected: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  cursor: pointer;
  
  .custom-card {
    ${({ selected }) =>
      selected && "filter: drop-shadow(0px 0px 28px #49C56C);"}
  }
`;
