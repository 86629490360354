import { IProgram } from '@modules/activities-library/interfaces/IProgram';
import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { ISkill } from '@shared/interfaces/ISkill';
import ITag from '../../tags/interfaces/ITag';

export type IActivityLibraryProgramContextContextData = {
  programCurrent: IProgram;
  setProgramCurrent: (program: any) => void;
  clearProgramContextData: () => void;
  programFilter: React.MutableRefObject<IProgramFilter>;
};

export interface IProgramFilter {
  tags?: ITag[];
  skills?: ISkill[];
}

export interface IProgramFilterFormatted {
  tag_ids?: number[];
  skill_ids?: number[];
}

const ActivityLibraryProgramContext = createContext<IActivityLibraryProgramContextContextData>({} as IActivityLibraryProgramContextContextData);

const ActivityLibraryProgramProvider: React.FC = ({ children }) => {
  const [programCurrent, setProgramCurrent] = useState<IProgram>({});
  const programFilter = useRef<IProgramFilter>({});

  const clearProgramContextData = useCallback(() => {
    setProgramCurrent({});
  }, []);

  return (
    <ActivityLibraryProgramContext.Provider
      value={{
        programFilter,
        clearProgramContextData,
        setProgramCurrent,
        programCurrent,
      }}
    >
      {children}
    </ActivityLibraryProgramContext.Provider>
  );
};

function useProgram() {
  const context = useContext(ActivityLibraryProgramContext);

  if (!context) {
    throw new Error('useProgram must be used within a ActivityLibraryProgramProvider');
  }

  return context;
}

export { ActivityLibraryProgramProvider, useProgram };
