import React, { useCallback } from 'react';
import { ISelectOption } from 'react-alicerce-components';

import IProjectReport from '@modules/projects/interfaces/IProjectReport';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { IAttachment } from '@shared/interfaces/IAttachment';

export const useProjectReportForm = () => {
  const { setReportCurrent } = useProjects();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setReportCurrent((oldState: IProjectReport) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setReportCurrent]
  );

  const handleBlur = (fieldName: string, date?: string) => {
    if (date === '') {
      setReportCurrent((oldState: IProjectReport) => ({ ...oldState, [fieldName]: '' }));
    }
  };

  const handleSelectChange = useCallback(
    (option: ISelectOption | null) => {
      if (!option) return;
      setReportCurrent((oldState: IProjectReport) => ({ ...oldState, [String(option?.selectname)]: option.value }));
    },
    [setReportCurrent]
  );

  const handleOnAddNewFile = (filedName: string, files: (File | IAttachment)[]) => {
    setReportCurrent((oldState: IProjectReport) => {
      oldState[filedName] = files;
      return { ...oldState };
    });
  };

  const handleOnRemoveFile = (fieldName: string, indexToRemove: number) => {
    setReportCurrent((oldState: IProjectReport) => {
      oldState[fieldName] = oldState[fieldName]?.filter((_, index) => Number(index) !== Number(indexToRemove));
      return { ...oldState };
    });
  };

  return {
    handleChangeInput,
    handleSelectChange,
    handleOnAddNewFile,
    handleBlur,
    handleOnRemoveFile,
  };
};
