import * as Yup from 'yup';

export const ProjectReportSchema = Yup.object().shape({
  report_date: Yup.date()
    .required({ name: 'report_date', message: 'O campo é obrigatório' })
    .max(new Date(), { name: 'report_date', message: 'A data não pode ser futura.' }),
  description: Yup.string().required({ name: 'description', message: 'O campo é obrigatório' }),
  attachments: Yup.array(Yup.mixed()).optional(),
  img_attachments: Yup.array(Yup.mixed()).optional(),
  others_attachments: Yup.array(Yup.mixed()).optional(),
  sprint_id: Yup.number().optional(),
  project_id: Yup.number().required({ name: 'project_id', message: 'O campo é obrigatório' }),
});
